import roleService from '../../services/role.service';
import {
  SET_ALL_ROLES,
  SET_ALL_FUNCTIONS,
  SET_ROLE,
  SET_USER_FUNCTIONS,
  SET_USER_HOME,
} from './type.mutation';

const state = {
  roles: [],
  functions: [],
  childrenFunctions: {},
  userRole: {},
  userFunctions: {},
  homePage: '',
};

/* eslint-disable no-param-reassign */
const mutations = {
  [SET_ALL_ROLES](_state, users) {
    _state.roles = users;
  },
  [SET_ALL_FUNCTIONS](_state, payload) {
    _state.functions = payload;
    let childrenFunctions = {};
    _state.functions.forEach(mod => {
      mod.functions.forEach(fn => {
        if (fn.parentFnId !== undefined && fn.parentFnId !== "") {
          childrenFunctions[fn.id] = fn;
        }
      })
    })
    _state.childrenFunctions = childrenFunctions;
  },
  [SET_ROLE](_state, payload) {
    _state.userRole = payload;
  },
  [SET_USER_FUNCTIONS](_state, payload) {
    _state.userFunctions.functions = payload.functions;
    _state.userFunctions.nav = payload.nav;
    _state.userFunctions.actions = payload.functions.map(f => f.functionId);
  },
  [SET_USER_HOME](_state, payload) {
    _state.homePage = payload;
  },
};

const actions = {
  getAllRoles({ commit }, clinicCode) {
    roleService.getAll({ clinicCode })
      .then(
        users => commit(SET_ALL_ROLES, users),
      );
  },
  resetAllRoles({ commit }) {
    commit(SET_ALL_ROLES, []);
  },
  getAllFunctions({ commit }) {
    roleService.getFuctionByGroup()
      .then(
        functions => commit(SET_ALL_FUNCTIONS, functions),
      );
  },
  createNewRole({ commit }, data) {
    return roleService.createNew(data)
      .then((response) => {
        if (response.ok) {
          commit(SET_ROLE, response.data);
        }
        return response;
      });
  },
  updateRole({ commit }, data) {
    return roleService.update(data)
      .then((response) => {
        if (response.ok) {
          commit(SET_ROLE, response.data);
        }
        return response;
      });
  },
  bulkDestroy({ commit }, data) {
    commit('setLoading', true, { root: true });
    return roleService.bulkDestroy(data)
      .then((response) => {
        commit(SET_ALL_ROLES, []);
        commit('setLoading', false, { root: true });
        return response;
      });
  },
  setUserFunctions({ commit }, payload) {
    commit(SET_USER_FUNCTIONS, payload);
  },
  setSelectedRole({ commit }, role) {
    commit(SET_ROLE, role);
  },
  setHomePage({ commit }, home) {
    commit(SET_USER_HOME, home);
  },
};

const getters = {
  roles: _state => _state.roles,
  functions: _state => _state.functions,
  childrenFunctions: _state => _state.childrenFunctions,
  userRole: _state => _state.userRole,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
