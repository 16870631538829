<template>
  <div class="container-fluid">
    <prescription-form-print
      :selectedPatient="selectedPatient"
      :patientConfig="patient"
      :generalConfig="general"
      :selectedPrescription="selectedPrescription"
    />
  </div>
</template>

<script>
import PrescriptionFormPrint from '@/components/Print/PrescriptionFormPrint.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'PrescriptionForm',
  components: {
    PrescriptionFormPrint,
  },
  data() {
    return {
      selectedPrescription: {},
    };
  },
  computed: {
    ...mapGetters('patient', ['selectedPatient']),
    ...mapGetters('invoice', ['print']),
    ...mapGetters('config', ['general', 'patient']),
  },
  created() {
    const vuex = JSON.parse(localStorage.getItem('vuex'))
    const print = vuex.invoice.print
    this.selectedPrescription = Object.assign({}, print);
  },
};
</script>
