<template>
  <div>
    <div v-if="printSummary">
      <summary-page
        :isCategorized='true'
        :selectedPatient="selectedPatient"
        :print="print"
        :general="general"
        :printEN="printEN"
        :tableItems="itemsByBillingGroup"
        :payments="payments"
        :group="group"
        :corpName="corpName"
        >
      </summary-page>
    </div>
    <div v-else-if="group === 'group1' || group === 'group2'" class="row no-gutters">
        <div class="col">
          <sub-page key="bgrimm-sub-page-0"
            :isCategorized='true'
            :selectedPatient="selectedPatient"
            :print="print"
            :general="general"
            :tableItems="itemsByBillingGroup"
            :typeOfPayments="typeOfPayments"
            :payments="payments"
            :printEN="printEN"
            :group="group"
            :corpName="corpName"
            :docCopyString="pageType[0]"
          />
        </div>
        <div class="col">
          <sub-page key="bgrimm-sub-page-1"
            :isCategorized='true'
            :selectedPatient="selectedPatient"
            :print="print"
            :general="general"
            :tableItems="itemsByBillingGroup"
            :typeOfPayments="typeOfPayments"
            :payments="payments"
            :printEN="printEN"
            :group="group"
            :corpName="corpName"
            :docCopyString="pageType[1]"
          />
        </div>
    </div>
    <div v-else-if="group === 'group3'">
      <div class="row no-gutters">
        <div class="col">
          <sub-page key="bgrimm-sub-page-0"
            :isCategorized='true'
            :selectedPatient="selectedPatient"
            :print="print"
            :general="general"
            :tableItems="itemsByBillingGroup"
            :typeOfPayments="typeOfPayments"
            :payments="payments"
            :printEN="printEN"
            :group="group"
            :corpName="corpName"
            :docCopyString="pageType[0]"
            :pageIndex="0"
          />
        </div>
        <div class="col">
          <sub-page key="bgrimm-sub-page-1"
            :isCategorized='true'
            :selectedPatient="selectedPatient"
            :print="print"
            :general="general"
            :tableItems="itemsByBillingGroup"
            :typeOfPayments="typeOfPayments"
            :payments="payments"
            :printEN="printEN"
            :group="group"
            :corpName="corpName"
            :docCopyString="pageType[1]"
            :pageIndex="1"
          />
        </div>
      </div>
      <div style='page-break-after:always'></div>
      <div class="row no-gutters mt-1">
        <div class="col">
          <sub-page key="bgrimm-sub-page-2"
            :isCategorized='true'
            :selectedPatient="selectedPatient"
            :print="print"
            :general="general"
            :tableItems="itemsByBillingGroup"
            :typeOfPayments="typeOfPayments"
            :payments="payments"
            :printEN="printEN"
            :group="group"
            :corpName="corpName"
            :docCopyString="pageType[2]"
            :pageIndex="2"
          />
        </div>
        <div class="col">
          <sub-page key="bgrimm-sub-page-3"
            :isCategorized='true'
            :selectedPatient="selectedPatient"
            :print="print"
            :general="general"
            :tableItems="itemsByBillingGroup"
            :typeOfPayments="typeOfPayments"
            :payments="payments"
            :printEN="printEN"
            :group="group"
            :corpName="corpName"
            :docCopyString="pageType[3]"
            :pageIndex="3"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { isEmpty as _isEmpty } from 'lodash';
import BGrimmTaxInvoiceSubPage from '@/components/Print/BGrimmTaxInvoiceSubPage.vue';
import BGrimmTaxInvoiceSummary from '@/components/Print/BGrimmTaxInvoiceSummary.vue';
import categoryInvoiceMixin from '@/components/mixins/print.invoice.category';

export default {
  name: 'BGrimmTaxInvoiceComponent',
  mixins: [categoryInvoiceMixin],
  components: {
    'sub-page': BGrimmTaxInvoiceSubPage,
    'summary-page': BGrimmTaxInvoiceSummary,
  },
  props: {
    selectedPatient: {
      type: Object,
      default: () => ({ corpInsurance: [] }),
    },
    print: {
      type: Object,
      default: () => ({}),
    },
    general: {
      type: Object,
      default: () => ({}),
    },
    virtualCurrencyConfig: {
      type: Object,
      default: () => ({}),
    },
    convertToVirtualCurrency: {
      type: Boolean,
      default: false,
    },
    printConfig: [Array, Object],
    preview: {
      type: Boolean,
      default: false,
    },
    includeGST: [Boolean, Object],
    roundOff: [String, Object],
  },
  computed: {
    withDiagnosis() {
      if (this.$route && this.$route.query) {
        return this.$route.query.diagnosis === 'true';
      }
      return this.printConfig && this.printConfig.diagnosis;
    },
    pageType() {
      const original = 'ต้นฉบับ/Original';
      const copy = 'สำเนา/Copy';
      const typeMap = {
        origin: original,
        copy,
      };
      let left = original;
      let right = copy;
      if (this.$route && this.$route.query && this.$route.query.pageType) {
        const [leftType, rightType] = this.$route.query.pageType.split(',');
        left = typeMap[leftType] || original;
        right = typeMap[rightType] || copy;
      }
      return [left, right, left, right];
    },
    printSummary() {
      if (this.$route && this.$route.query) {
        return this.$route.query.summary === 'true';
      }
      return false;
    },
    group() {
      const hasCorpPayments = this.payments.some(p => p.isCorpPayment);
      const allCorpPayments = this.payments.every(p => p.isCorpPayment);
      if (allCorpPayments) return 'group2';
      if (hasCorpPayments) return 'group3';
      return 'group1';
    },
    payments() {
      const selfPayments = {};
      const corpPayments = {};
      if (Array.isArray(this.print.payments) && this.print.payments.length) {
        // Loop through the payment records
        this.print.payments.filter(p => (!p.voided)).forEach((p) => {
          if (Array.isArray(p.payments) && p.payments.length) {
            // Loop through the patient payments records
            p.payments.forEach((pp) => {
              const { mode } = pp;
              if (_isEmpty(selfPayments[mode])) {
                selfPayments[mode] = [pp];
              } else {
                selfPayments[mode].push(pp);
              }
            });
          }
          if (Array.isArray(p.corpPayments) && p.corpPayments.length) {
            // Loop through the patient payments records
            p.corpPayments.forEach((pp) => {
              const { label } = pp;
              if (_isEmpty(corpPayments[label])) {
                corpPayments[label] = [pp];
              } else {
                corpPayments[label].push(pp);
              }
            });
          }
        });
      }
      // group and sum up each payments
      const allPayments = [];
      Object.keys(selfPayments).forEach((name) => {
        const payments = selfPayments[name];
        const subtotal = payments.reduce((r, c) => r + (c.amount || 0), 0);
        allPayments.push({
          label: name,
          amount: subtotal,
          isCorpPayment: false,
        });
      });
      Object.keys(corpPayments).forEach((name) => {
        const payments = corpPayments[name];
        const subtotal = payments.reduce((r, c) => r + (c.amount || 0), 0);
        allPayments.push({
          label: name,
          amount: subtotal,
          isCorpPayment: true,
        });
      });
      const ret = allPayments.sort((a, b) => {
        if (a.amount > b.amount) return -1;
        if (a.amount < b.amount) return 1;
        return 0;
      });
      return ret;
    },
    corpName() {
      if (this.group === 'group1') return '';
      const firstCorpPayment = this.payments.find(p => p.isCorpPayment);
      if (!firstCorpPayment) return '';
      return firstCorpPayment.label;
    },
  },
  mounted() {
    if (!this.preview) {
      setTimeout(() => {
        window.print();
      }, 2000);
    }
  },
};
</script>
