import api from './api';

export default {
  translateErrorMsg(error) {
    return error;
  },
  fetchAll(params) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);

    Object.keys(params).forEach((key) => {
      q.push(`${key}=${params[key]}`);
    });
    
    const url = `/inventory/?${q.join('&')}`;
    return api.get(url);
  },
  fetchAllInventoriesWithBatch(params) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);

    Object.keys(params).forEach((key) => {
      q.push(`${key}=${params[key]}`);
    });
    const url = `/inventory/inventoriesWithBatch?${q.join('&')}`;
    return api.get(url);
  },
  fetchWoMovement(params, pateint = '') {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);

    Object.keys(params).forEach((key) => {
      q.push(`${key}=${params[key]}`);
    });
    let url = `/inventory/allWoMovement/?${q.join('&')}`;
    if (pateint) {
      url = `/inventory/allWoMovement/${pateint}?${q.join('&')}`;
    }

    return api.get(url);
  },
  fetchSimplify(params) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);

    Object.keys(params).forEach((key) => {
      q.push(`${key}=${params[key]}`);
    });

    const url = `/inventory/allSimplify?${q.join('&')}`;
    return api.get(url);
  },
  fetchInventoryForDispense(patient, id, clinicCode) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);

    const url = `/inventory/dispense/${patient}/${id}?clinicCode=${clinicCode}`;
    return api.get(url);
  },
  fetchExpendables(params) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);

    Object.keys(params).forEach((key) => {
      q.push(`${key}=${params[key]}`);
    });

    const url = `/inventory/expendables?${q.join('&')}&fields=name,trademark`;
    return api.get(url);
  },
  fetchInventoryForDispenseProvider(provider, id, clinicCode) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);
    const url = `/inventory/dispenseProvider/${provider}/${id}?clinicCode=${clinicCode}`;
    return api.get(url);
  },
  fetchProcedures(params) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);

    Object.keys(params).forEach((key) => {
      q.push(`${key}=${params[key]}`);
    });

    const url = `/inventory/procedures?${q.join('&')}&fields=name,price,anaesthesiaCharge`;
    return api.get(url);
  },
  fetchMedicines() {
    const url = '/inventory/medicines';
    return api.get(url);
  },
  createNew(data) {
    if (data.serviceTags && data.serviceTags.length === 0) {
      delete data.serviceTags;
    }
    return api.post('/inventory', data);
  },
  createNewWithImage(data, imageFile) {
    if (data.serviceTags && data.serviceTags.length === 0) {
      delete data.serviceTags;
    }
    return api.multiPartPost(
      '/inventory',
      data,
      { picture: imageFile },
    );
  },
  updateInventory(id, data) {
    if (data.serviceTags && data.serviceTags.length === 0) {
      delete data.serviceTags;
    }
    return api.put(`/inventory/update/${id}`, data);
  },
  updateInventoryWithImage(id, data, imageFile) {
    if (data.serviceTags && data.serviceTags.length === 0) {
      delete data.serviceTags;
    }
    console.log('updating inventory data ', data);
    return api.multiPartPut(
      `/inventory/update/${id}`,
      data,
      { picture: imageFile },
    );
  },
  updateBulkInventory(data) {
    return api.put('/inventory/updateBulk', data);
  },
  delete(id) {
    return api.delete(`/inventory/${id}`);
  },
  deleteBulk(ids) {
    return api.post('/inventory/deleteBulk', ids);
  },
  getInventory(id) {
    return api.get(`/inventory/${id}`);
  },
  getHeldInventory(id) {
    return api.get(`/inventory/${id}/held`);
  },
  count(query) {
    const params = Object.keys(query)
      .map(k => `${k}=${query[k]}`)
      .join('&');
    return api.get(`/inventory/count?${params}`);
  },
  selectBatch(inventoryId) {
    return api.get(`/inventory-movement/${inventoryId}/batch`);
  },
  importInventory(data) {
    return api.post('/inventory/import', { items: data });
  },
};
