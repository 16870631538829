<template>
    <div id="signature-pad-form">
      <div class="signature-date mb-2" >
        <span v-if="signatureDate">{{ $t('general.date') }}</span>: {{ signatureDate }}
        <!-- <el-date-picker
          readonly
          v-model="signatureDate"
          :editable="false"
          type="date"
          format="yyyy MMM dd"
          value-format="yyyyMMdd"
          size="small">
        </el-date-picker> -->
      </div>
      <vd-signature-pad
        v-if="!isAccepted"
        width="500px"
        height="300px"
        @accept="acceptSignature"
        :viewing="viewing"
        ></vd-signature-pad>

        <div v-else>
          <img
            v-if="signature"
            :src="signature"
            alt=""
            class="signature-image"
            width="500px"
            height="300px"/><br/>
          <button class="btn btn-success"
            @click="isAccepted = false" :disabled="viewing"  >Re-sign</button>
        </div>
    </div>
</template>

<script>
import VdSignaturePad from '@/components/VdSignaturePad.vue';
import patientService from '@/services/patient.service';
import moment from 'moment';

export default {
  name: 'SignatureForm',
  components: {
    VdSignaturePad,
  },
  props: ['formData',
    'patientForm',
    'isEditing',
    'patientConfig',
    'noteEditor',
    'containerName',
    'qns',
    'viewing'],
  data() {
    return {
      isAccepted: false,
      signature: '',
      today: moment().format('DD MMM YYYY'),
    };
  },
  watch: {
    signature() {
      this.$emit('onFormValidityChanged', this.formValid);
    },
  },
  computed: {
    formValid() {
      if (this.noteEditor) return true;
      return this.patientConfig.mandatoryFields.patientSig ? !!this.signature : true;
    },
    signatureDate: {
      get() {
        // requirement change; this.patientForm.signatureDate no longer needed.
        if (this.patientForm && this.patientForm.signatureDate) {
          // return this.patientForm.signatureDate;
          return moment(this.patientForm.signatureDate).format('DD MMM YYYY');
        }
        if (this.patientForm && this.patientForm.signature && this.patientForm.signature.lud) {
          return moment(this.patientForm.signature.lud).format('DD MMM YYYY');
        }
        if (this.qns !== undefined && this.formData.answer !== undefined && this.formData.answer.lud) {
          return moment(this.formData.answer.lud).format('DD MMM YYYY');
        }
        return '';
      },
      set(val) {
        this.patientForm.signatureDate = val;
      },
    },
  },
  methods: {
    acceptSignature(sign) {
      // console.log("sign ", sign);
      this.signature = sign.data;
      if (this.patientForm) {
        this.patientForm.signature = sign.data;
      }
      this.isAccepted = true;
      // this condition is for requireSignature key
      if (this.noteEditor && this.patientForm && this.patientForm.requireSignature) {
        // console.log("this.containerName ", this.containerName);
        this.$store.commit('note/UPDATE_TYPING_FORM_SIGNATURE',
          {
            payload: sign.data,
            qns: this.qns,
            containerName: this.containerName,
          });
        // console.log("this.patientForm.signature ", this.patientForm.signature);

        if (this.isEditing) {
          // let backend knows patient has updated his signature
          this.patientForm.signature = this.patientForm.signature || {};
          this.patientForm.signature.base64 = sign.data;
          this.patientForm.signature.updating = this.isEditing;
        }
        return;
      }
      /**
         * * this condition is for multiple signature from schema
         * * "q1":{"label":"someone signature", "type":"signaturePad"},
         * * "q2":{"label":"someone2 signature", "type":"signaturePad"}
         */
      if (this.qns !== undefined) {
        console.log('qns ', {
          payload: sign.data,
          qns: this.qns,
          containerName: this.containerName,
        });
        this.$store.commit('note/UPDATE_TYPING_FORM_SIGNATURE',
          {
            payload: sign.data,
            qns: this.qns,
            containerName: this.containerName,
          });
      }
    },
    getSignatureBase64() {
      if (this.patientForm.signature.key) {
        // console.log('this.patientForm.signature.key ', this.patientForm.signature.key);
        patientService.getSignature(this.patientForm.signature.key)
          .then((response) => {
            // console.log('response ', response);
            if (response.ok) {
              this.signature = response.data;
              this.isAccepted = !!this.signature;
            }
          });
      }
      // console.log('this.patientForm.formData ', this.patientForm.formData);
    },
  },
  created() {
    console.log('this.containerName ', this.containerName);
    if (this.patientForm !== undefined) {
      console.log('this.patientForm ', this.patientForm.signature);
      if (this.noteEditor) {
        if (this.patientForm.signature) this.getSignatureBase64();
      } else {
        if (this.isEditing && this.patientForm.signature && !this.patientForm.signature.base64) {
          this.getSignatureBase64();
        } else if (this.patientForm.signature && this.patientForm.signature.base64) {
          this.signature = this.patientForm.signature.base64;
          this.isAccepted = true;
        } else if (this.patientForm.signature && (typeof this.patientForm.signature) === 'string') {
          this.signature = this.patientForm.signature;
          this.isAccepted = true;
        }
        this.$emit('onFormLoad', this.formValid);
      }
    } else if (this.formData.answer) {
      console.log('this.formData ', this.formData);
      this.isAccepted = true;
      const { answer } = this.formData;
      if (typeof answer === 'string' && answer.startsWith('data:image')) {
        this.signature = answer;
      } else {
        patientService.getSignature(this.formData.answer.key)
          .then((response) => {
            console.log('response ', response);
            if (response.ok && response.data !== '') {
              this.signature = response.data;
              this.isAccepted = !!this.signature;
            }
          });
      }
    }
  },
};
</script>

<style>
  .signature-image {
    border: 1px solid #666;
    margin-bottom: 15px;
  }
  .signature-date {
    font-size: 14px;
  }
  @media print {
#signature-pad-form button{
  display: none;
}
}
</style>
