<template>
  <b-form-group
    :label="item.label"
    :disabled="
      viewing ||
        item.disabled ||
        item.display === 'hide'
    "
    :class="getClass(formData, rowIdx, qns)"
    :id="`${qns}group_link`"
  >
    <b-form-input
      :id="`${qns}input`"
      :value="
        item.dateFormat &&
        item.dateFormat.length > 0
          ? parseDateValue(
              item.answer,
              item.dateFormat
            )
          : item.answer
      "
      :type="item.inputType"
      @input="updateAnswer($event)"
      :plaintext="item.plaintext"
      :readonly="item.readonly"
      :placeholder="item.placeholder"
    ></b-form-input>
  </b-form-group>
</template>
<script>
import DateFormatter from '@/components/mixins/dateformatter';

export default {
  name: 'LinkInputWidget',
  mixins: [DateFormatter],
  data() {
    return {
      checked: false,
    };
  },
  props: {
    item: {
      type: Object,
      default: function fn() {},
    },
    formData: {
      type: Object,
      default: function fn() {},
    },
    viewing: {
      type: Boolean,
      default: false,
    },
    printing: {
      type: Boolean,
      default: false,
    },
    qns: {
      type: String,
    },
    rowIdx: {
      type: [String, Number],
    },
    getClass: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    updateAnswer(value) {
      const { linkTo } = this.item;
      let linkedQnsIds = [];
      if (typeof linkTo === 'string') {
        linkedQnsIds = [linkTo];
      } else if (Array.isArray(linkTo)) {
        linkedQnsIds = linkTo;
      }
      linkedQnsIds.push(this.qns);
      this.$emit('update', linkedQnsIds, value, 'lmp');
    },
  },
};
</script>
