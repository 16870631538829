var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "table",
    {
      class: [
        "table table-bordered note-dynamic",
        {
          readonly: _vm.viewing || _vm.printing,
          "ipt-border": _vm.showInputBorder
        }
      ]
    },
    [
      _vm.tableHeader.length === 2
        ? _c("thead", { staticClass: "complex" }, [
            _c(
              "tr",
              [
                _vm._l(_vm.tableHeader[0], function(labelItem, idx) {
                  return _c(
                    "th",
                    {
                      key: idx,
                      attrs: {
                        colspan: labelItem.colSpan,
                        rowspan: labelItem.rowSpan
                      }
                    },
                    [_vm._v("\n        " + _vm._s(labelItem.text) + "\n      ")]
                  )
                }),
                _c("th", { attrs: { rowspan: "2" } })
              ],
              2
            ),
            _c(
              "tr",
              _vm._l(_vm.tableHeader[1], function(labelItem, idx) {
                return _c("th", { key: idx }, [
                  _vm._v("\n        " + _vm._s(labelItem.text) + "\n      ")
                ])
              }),
              0
            )
          ])
        : _vm.tableHeader.length === 1
        ? _c("thead", [
            _c(
              "tr",
              [
                _vm._l(_vm.tableHeader[0], function(labelText, index) {
                  return _c("th", { key: index }, [
                    _vm._v("\n        " + _vm._s(labelText) + "\n      ")
                  ])
                }),
                _c("th")
              ],
              2
            )
          ])
        : _vm._e(),
      _c(
        "tbody",
        { attrs: { "data-tick": _vm.tick } },
        [
          _vm._l(_vm.item.answer, function(row, rowIdx) {
            return [
              _c(
                "tr",
                { key: rowIdx },
                [
                  _vm._l(row, function(answerText, idx) {
                    return _c(
                      "td",
                      {
                        key: idx,
                        class: (_vm.item.class && _vm.item.class[idx]) || "",
                        style: (_vm.item.style && _vm.item.style[idx]) || ""
                      },
                      [
                        _vm.item.inputType[idx] === "date"
                          ? _c(
                              "div",
                              [
                                _c("date-picker", {
                                  attrs: {
                                    value: answerText,
                                    "value-type": "timestamp",
                                    format:
                                      (_vm.item.dateFormat &&
                                        _vm.item.dateFormat[idx]) ||
                                      "DD-MMM-YYYY",
                                    type: "date",
                                    placeholder:
                                      _vm.item.placeholder &&
                                      _vm.item.placeholder[idx],
                                    disabled:
                                      (_vm.item.readonly &&
                                        _vm.item.readonly[idx]) ||
                                      _vm.viewing
                                  },
                                  on: {
                                    input: function($event) {
                                      return _vm.updateTableCell(
                                        $event,
                                        _vm.qns,
                                        rowIdx,
                                        idx
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          : _vm.item.type[idx] === "input"
                          ? _c("b-form-input", {
                              class:
                                "ipt " +
                                ((_vm.item.class && _vm.item.class[idx]) || ""),
                              attrs: {
                                id:
                                  _vm.qns + "_" + rowIdx + "_" + idx + "_input",
                                value:
                                  _vm.item.dateFormat &&
                                  _vm.item.dateFormat[idx] &&
                                  _vm.item.dateFormat[idx].length > 0
                                    ? _vm.parseDateValue(
                                        answerText,
                                        _vm.item.dateFormat[idx]
                                      )
                                    : answerText,
                                type:
                                  _vm.item.inputType && _vm.item.inputType[idx],
                                plaintext:
                                  _vm.item.plaintext && _vm.item.plaintext[idx],
                                readonly:
                                  (_vm.item.readonly &&
                                    _vm.item.readonly[idx]) ||
                                  _vm.viewing,
                                debounce: "500",
                                placeholder:
                                  _vm.item.placeholder &&
                                  _vm.item.placeholder[idx]
                              },
                              on: {
                                input: function($event) {
                                  return _vm.updateTableCell(
                                    $event,
                                    _vm.qns,
                                    rowIdx,
                                    idx
                                  )
                                }
                              }
                            })
                          : _vm.item.type[idx] === "oneclick-button"
                          ? _c("one-click-button", {
                              attrs: {
                                item: {
                                  label: "Click to Get",
                                  answer: answerText,
                                  source: _vm.item.source
                                },
                                qns: _vm.qns,
                                viewing: _vm.viewing,
                                print: _vm.printing,
                                formData: _vm.formData,
                                coords: [rowIdx, idx],
                                updateTypingFormData: function(newAnswer) {
                                  return _vm.updateTableCellTick(
                                    newAnswer,
                                    _vm.qns,
                                    rowIdx,
                                    idx
                                  )
                                }
                              }
                            })
                          : _vm.item.type[idx] === "textarea"
                          ? _c("typed-form", {
                              staticStyle: { overflow: "hidden" },
                              attrs: {
                                qns: _vm.qns,
                                value: answerText,
                                rows: _vm.item.rows && _vm.item.rows[idx],
                                updateTypingFormData: function(newAnswer) {
                                  return _vm.updateTableCellTick(
                                    newAnswer,
                                    _vm.qns,
                                    rowIdx,
                                    idx
                                  )
                                },
                                disabled:
                                  (_vm.item.disabled &&
                                    _vm.item.disabled[idx]) ||
                                  _vm.viewing
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  }),
                  _c(
                    "td",
                    { staticClass: "action-td" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "remove",
                          attrs: { disabled: _vm.viewing },
                          on: {
                            click: function($event) {
                              return _vm.removeTableRow(rowIdx, _vm.qns)
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { name: "times", scale: "1" } })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                2
              )
            ]
          }),
          _c("tr", { staticClass: "last-row" }, [
            _c(
              "td",
              { attrs: { colspan: _vm.colsCount } },
              [
                _c(
                  "b-button",
                  {
                    attrs: { disabled: _vm.viewing },
                    on: {
                      click: function($event) {
                        return _vm.addTableRow(_vm.qns)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("forms.addNewRow")))]
                )
              ],
              1
            ),
            _c("td")
          ])
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }