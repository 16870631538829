var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "b-summary-container" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c("h5", { staticClass: "clinic-local-name" }, [
          _vm._v(
            "\n        " + _vm._s(_vm.general.clinicLocalName) + "\n      "
          )
        ]),
        _c("h5", { staticClass: "clinic-name" }, [
          _vm._v("\n        " + _vm._s(_vm.general.clinicName) + "\n      ")
        ]),
        _c("p", { staticClass: "mb-0 clinic-campony" }, [
          _vm._v("\n        " + _vm._s(_vm.clinicCorpName) + "\n      ")
        ]),
        _c("div", { staticClass: "clinic-addr" }, [
          _c("div", { staticClass: "hint" }, [_vm._v("ที่อยู่")]),
          _c("div", { staticClass: "addr-line" }, [
            _c("div", { staticClass: "thai" }, [
              _vm._v(_vm._s(_vm.general.clinicLocalAddress))
            ]),
            _c("div", { staticClass: "en" }, [
              _vm._v(_vm._s(_vm.general.address))
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "col flex-grow-0 logo-container" }, [
        _c("img", {
          attrs: { src: _vm.general.logoURL, alt: _vm.general.clinicName }
        })
      ])
    ]),
    _c("h4", { staticClass: "doc-title text-center mt-2" }, [
      _vm._v("เอกสารสรุปค่าใช้จ่าย / SUMMARY INVOICE")
    ]),
    _c("h6", { staticClass: "patient-cn" }, [
      _vm._v("\n    เลขประจำตัวผู้ป่วย\n    "),
      _c("span", { staticClass: "ml-4" }, [
        _vm._v("\n      CN: "),
        _c("span", { staticClass: "ml-2" }, [
          _vm._v(
            _vm._s(_vm.selectedPatient.givenIdPrefix) +
              _vm._s(_vm.selectedPatient.givenId)
          )
        ])
      ])
    ]),
    _c("div", { staticClass: "row t-bold" }, [
      _c("div", { staticClass: "col-8" }, [
        _c("div", { staticClass: "patient-name" }, [
          _vm._v("\n        ชื่อ/Name: "),
          _c("span", { staticClass: "ml-2" }, [
            _vm._v(_vm._s(_vm.patientNameField))
          ])
        ])
      ]),
      _c("div", { staticClass: "col-4" }, [
        _c("div", { staticClass: "doc-number" }, [
          _vm._v("\n        เลขที่เอกสาร/Document No. :\n        "),
          _c("span", [_vm._v(_vm._s(_vm.print.invoiceNo))])
        ])
      ])
    ]),
    _c("div", { staticClass: "row t-bold" }, [
      _c("div", { staticClass: "col-8" }, [
        _c("div", { staticClass: "corp-company" }, [
          _vm._v("\n        บริษัท / Corporate company :"),
          _c("span", { staticClass: "ml-2" }, [
            _vm._v(" " + _vm._s(_vm.corpName) + " ")
          ])
        ])
      ]),
      _c("div", { staticClass: "col-4" }, [
        _c("div", { staticClass: "invoice-date" }, [
          _vm._v("\n        วันที่/ Date : "),
          _c("span", [
            _vm._v(
              " " +
                _vm._s(
                  _vm._f("formatVDDate")(_vm.print.createdAt, {
                    lang: _vm.$i18n.locale,
                    format: "DD/MM/YYYY"
                  })
                )
            )
          ])
        ])
      ])
    ]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c("table", { staticClass: "table invoice-table" }, [
          _vm._m(0),
          _c(
            "tbody",
            [
              _vm._l(_vm.tableItems, function(item, idx) {
                return [
                  item.isTitle
                    ? _c(
                        "tr",
                        {
                          key: "invoice_item_" + idx,
                          staticClass: "title-row"
                        },
                        [
                          _c(
                            "td",
                            {
                              staticClass: "category-title",
                              attrs: { colspan: "3" }
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(item.description) +
                                  "\n              "
                              )
                            ]
                          ),
                          _c("td"),
                          _c("td"),
                          _c("td")
                        ]
                      )
                    : _c(
                        "tr",
                        { key: "invoice_item_" + idx, staticClass: "item-row" },
                        [
                          _c(
                            "td",
                            {
                              staticClass: "category-subtitle",
                              attrs: { colspan: "3" }
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(item.description) +
                                  "\n              "
                              )
                            ]
                          ),
                          _c("td", { staticClass: "currency" }, [
                            _vm._v(_vm._s(item.amount))
                          ]),
                          _c("td", { staticClass: "currency" }, [
                            _vm._v(_vm._s(item.discount))
                          ]),
                          _c("td", { staticClass: "currency" }, [
                            _vm._v(_vm._s(item.netAmount))
                          ])
                        ]
                      )
                ]
              }),
              _c("tr", { staticClass: "subtotal-tr top-row" }, [
                _c("td"),
                _c("td", { staticClass: "t-bold", attrs: { colspan: "3" } }, [
                  _vm._v("\n              รวมทั้งสิ้น / Subtotal\n            ")
                ]),
                _c("td"),
                _c("td", { staticClass: "currency" }, [
                  _vm._v(_vm._s(_vm._f("to2Decimal")(_vm.totalAmountNumber)))
                ])
              ]),
              _c("tr", { staticClass: "subtotal-tr" }, [
                _c("td"),
                _c("td", { staticClass: "t-bold", attrs: { colspan: "3" } }, [
                  _vm._v(
                    "\n              ส่วนลดทั้งหมด / Total Discount\n            "
                  )
                ]),
                _c("td"),
                _c("td", { staticClass: "currency" }, [
                  _vm._v(_vm._s(_vm._f("to2Decimal")(_vm.totalDiscountNumber)))
                ])
              ]),
              _c("tr", { staticClass: "subtotal-tr" }, [
                _c("td"),
                _c("td", { staticClass: "t-bold", attrs: { colspan: "3" } }, [
                  _vm._v(
                    "\n              ยอดรวมสุทธิ / Net Amount\n            "
                  )
                ]),
                _c("td"),
                _c("td", { staticClass: "currency" }, [
                  _vm._v(_vm._s(_vm._f("to2Decimal")(_vm.totalNetAmountNumber)))
                ])
              ]),
              _vm._l(_vm.payments, function(payment, idx) {
                return [
                  _c(
                    "tr",
                    {
                      key: idx,
                      class: [
                        "subtotal-tr payments",
                        {
                          "no-border": idx !== 0,
                          "first-payment-row": idx === 0
                        }
                      ]
                    },
                    [
                      _c("td"),
                      _c(
                        "td",
                        { staticClass: "t-bold", attrs: { colspan: "2" } },
                        [
                          idx === 0
                            ? _c("span", [
                                _vm._v(
                                  "\n                  ชำระโดย / Mode of payment :\n                "
                                )
                              ])
                            : _c("span", [_vm._v(" ")])
                        ]
                      ),
                      _c("td", { attrs: { colspan: "2" } }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(payment.label) +
                            "\n              "
                        )
                      ]),
                      _c("td", { staticClass: "currency" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm._f("to2Decimal")(payment.amount)) +
                            "\n              "
                        )
                      ])
                    ]
                  )
                ]
              })
            ],
            2
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", { staticClass: "text-center" }, [
        _c("th", { attrs: { colspan: "3" } }, [
          _c("p", { staticClass: "intableTitle thai" }, [_vm._v("รายการ")]),
          _c("p", { staticClass: "intableTitle en" }, [_vm._v("Description")])
        ]),
        _c("th", [
          _c("p", { staticClass: "intableTitle thai" }, [_vm._v("จำนวนเงิน")]),
          _c("p", { staticClass: "intableTitle en" }, [_vm._v("Amount")])
        ]),
        _c("th", [
          _c("p", { staticClass: "intableTitle thai" }, [_vm._v("ส่วนลด")]),
          _c("p", { staticClass: "intableTitle en" }, [_vm._v("Discount")])
        ]),
        _c("th", [
          _c("p", { staticClass: "intableTitle thai" }, [
            _vm._v("จำนวนเงินสุทธิ")
          ]),
          _c("p", { staticClass: "intableTitle en" }, [_vm._v("Net Amount")])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }