const state = {
  setting: {
    medicalLabelHeight: 200,
    logoMarginLeft: -10,
    logoWidth: 300,
  },
};

const getters = {
  setting: _state => _state.setting,
};

const actions = {
  savePrintSetting(context, payload) {
    context.commit('savePrintSetting', payload);
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  savePrintSetting(_state, payload) {
    _state.setting = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
