/* eslint-disable */
import AdjustmentService from '@/services/adjustment.service';

const SET_ALL_ADJUSTMENTS = 'SET_ALL_ADJUSTMENTS';
const SET_ADJUSTMENT = 'SET_ADJUSTMENT';

const state = {
  all: [],
  selectedAdjustment: {},
  formValid: false,
  filter: {},
};

/* eslint-disable no-param-reassign */
const mutations = {
  [SET_ALL_ADJUSTMENTS](_state, payload) {
    _state.all = payload;
  },
  [SET_ADJUSTMENT](_state, payload) {
    _state.selectedAdjustment = payload;
  },
};

const actions = {
  fetchAllAdjustments({ commit }, param = {}) {
    return AdjustmentService.fetchAll(param)
      .then((response) => {
        commit(SET_ALL_ADJUSTMENTS, response.data);
      });
  },
  setSelectedAdjustment({ commit }, payload) {
    commit(SET_ADJUSTMENT, payload);
  },
  setAdjustment({ commit }, payload) {
    commit(UPDATE_Adjustment, payload);
  },
  // eslint-disable-next-line no-shadow
  createNewAdjustment({ commit }, newAdjustment) {
    return AdjustmentService.createNew(newAdjustment)
      .then((response) => {
        if (response.ok) {
          commit(SET_ADJUSTMENT, response.data);
        }
        return response;
      });
  },
  // eslint-disable-next-line no-shadow
  updateAdjustment({ commit }, updateAdjustment) {
    // console.log('vuex action updateAdjustment')
    return AdjustmentService.updateAdjustment(updateAdjustment._id, updateAdjustment)
      .then((response) => {
        if (response.ok) {
          commit(SET_ADJUSTMENT, response.data);
        }
        return response;
      });
  },
  bulkUpdateAdjustment({ commit }, data) {
    return AdjustmentService.updateBulkAdjustment(data)
      .then(response => response);
  },
  bulkDeleteAdjustment({ commit }, data) {
    return AdjustmentService.deleteBulk(data)
      .then(response => response);
  },
  deleteAdjustment({ commit }, id) {
    return AdjustmentService.delete(id)
      .then((response) => {
        if (response.ok) {
          commit(SET_ADJUSTMENT, {});
        }
        return response;
      });
  },
  getAdjustment({ commit }, id) {
    return AdjustmentService.getAdjustment(id)
      .then((response) => {
        if (response.ok) {
          commit(SET_ADJUSTMENT, response.data);
        }
        return response;
      });
  },
};

const getters = {
  all: _state => _state.all,
  selectedAdjustment: _state => _state.selectedAdjustment,
  formValid: _state => _state.formValid,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
