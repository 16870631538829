<template>
  <lab-label
    :clinic="generalConfig"
    :patient="patient"
    :printLabelConfig="printLabelConfig"
  />
</template>

<script>
import LabLabel from '@/components/Print/LabLabel.vue';
import computedMixins from '@/components/mixins/computed';
import { createNamespacedHelpers } from 'vuex';

const { mapGetters: mapPatientGetters,
        mapActions: mapPatientActions 
} = createNamespacedHelpers('patient');
const {
  mapGetters: mapConfigGetters,
} = createNamespacedHelpers('config');

export default {
  name: 'PrintLabLabel',
  components: {
    LabLabel,
  },
  mixins: [computedMixins],
  watch: {
    patientConfig(val) {
      this.printLabelConfig = this.patientConfig.printLabel;
    },
  },
  data() {
    return {
      printLabelConfig: {},
    };
  },
  computed: {
    ...mapConfigGetters({
      generalConfig: 'general',
      patientConfig: 'patient'
    }),
    ...mapPatientGetters({
      patient: 'selectedPatient'
    }),
  },
};
</script>
