<template>
  <div class="container mb-5">
    <div class="row mb-3">
      <div class="col-10">
        <img
          :src="generalConfig.logoURL"
          style="width: 300px; margin-left: 0"
        />
      </div>
      <div class="col-2" style="margin-top: 70px; margin-bottom: 50px">
        <p
          style="font-weight: 600; font-size: 20px"
          class="mb-1 mt-3 address-font"
        >
          {{ $t('general.creditNotes') }}
        </p>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-7">
        <p class="mb-1 fontWeight address-font">
           {{ $t('general.customer') }} : {{ patient.name || '-' }}
        </p>
        <p class="mb-1 fontWeight address-font">
          {{ $t('general.InvoiceNumber') }}: {{ creditNote.invoice.invoiceNo || '-' }}
        </p>
        <p class="mb-1 fontWeight address-font">
          {{ $t('general.CreditDate') }}: {{ formatTime(creditNote.date * 1000) }}
        </p>
        <p class="mb-1 fontWeight address-font">
          {{ $t('general.VisitBranch') }}: {{ clinicsById[creditNote.clinic].name || '-' }}
        </p>
        <p class="mb-1 fontWeight address-font">
          {{ $t('general.TelFax') }}: {{ generalConfig.telephone }}
        </p>
        <p class="mb-1 fontWeight address-font">
          {{ $t('general.AddressInvoice') }} : {{ clinicsById[creditNote.clinic].address || '-' }}
        </p>
      </div>
      <div class="col-5">
        <p class="mb-1 fontWeight address-font">
          {{ $t('general.CustomerIC') }} : {{ patient.nric || '-' }}
        </p>
        <p class="mb-1 fontWeight address-font">
          {{ $t('general.PrintDate') }} : {{ formatTime(Date.now()) }}
        </p>
        <p class="mb-1 fontWeight address-font">
           {{ $t('general.ConsulatantAsst') }} :
          {{
            (creditNote.invoice.therapist &&
              creditNote.invoice.therapist.name) ||
            '-'
          }}
        </p>
        <p class="mb-1 fontWeight address-font">Pages : {{ pageCount }}</p>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12">
        <table class="table">
          <thead>
            <tr>
              <td colspan="2" class="font-weight-bold"> {{ $t('general.InvoiceDescription') }} </td>
              <td class="font-weight-bold">{{ $t('general.QuantityInvoice') }}</td>
              <td class="font-weight-bold">{{ $t('general.AmountInvoice') }}</td>
            </tr>
          </thead>
          <tbody style="border-top : 0px ;" v-for="(category, idx) in unCategorized" :key="category.key">
            <tr>
              <td style="font-weight: 600" colspan="2">
                {{ idx == 'undefined' ? 'Others' : idx }}
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr v-for="(items, idxS) in category" :key="items.key">
              <td colspan="2">{{ idxS }}</td>
              <td>{{ getTotalQuantity(items) }}</td>
              <td>
                {{
                  getTotalPricePerInvoiceCategory(items)
                    | to2Decimal(region.decimal)
                    | thousandSeparator(region.thousandSeparator)
                }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td class="font-weight-bold">{{ $t('general.DiscountInvoice') }}</td>
              <td colspan="2">&nbsp;</td>
              <td>
                {{ region.currency
                }}{{
                  (
                    Number(creditNote.invoice.totalDiscount) +
                      creditNote.invoice.promotionDiscount
                  ).toFixed(2)
                    | to2Decimal(region.decimal)
                    | thousandSeparator(region.thousandSeparator)
                }}
              </td>
            </tr>
            <tr>
              <td class="font-weight-bold">{{ $t('general.TotalAmountInvoice') }}</td>
              <td colspan="2">&nbsp;</td>
              <td>
                {{ region.currency
                }}{{
                  Math.abs(
                    creditNote.invoice.total - creditNote.invoice.totalTax
                  ).toFixed(2)
                    | to2Decimal(region.decimal)
                    | thousandSeparator(region.thousandSeparator)
                }}
              </td>
            </tr>
            <tr>
              <td class="font-weight-bold">
                {{
                  creditNote.invoice.taxName +
                  ' ' +
                  invoiceConfig.taxPercentageAmount +
                  ' %'
                }}
              </td>
              <td colspan="2">&nbsp;</td>
              <td>
                {{ region.currency
                }}{{
                  creditNote.invoice.totalTax
                    | to2Decimal(region.decimal)
                    | thousandSeparator(region.thousandSeparator)
                }}
              </td>
            </tr>
            <tr>
              <td class="font-weight-bold">{{ $t('general.RoundingAdjustment') }}</td>
              <td colspan="2">&nbsp;</td>
              <td>
                {{ region.currency
                }}{{
                  creditNote.invoice.roundingAdjustment
                    | to2Decimal(region.decimal)
                    | thousandSeparator(region.thousandSeparator)
                }}
              </td>
            </tr>
            <tr>
              <td class="font-weight-bold">{{ $t('general.TotalDue') }}</td>
              <td colspan="2">&nbsp;</td>
              <td>
                {{ region.currency
                }}{{
                  creditNote.invoice.total
                    | to2Decimal(region.decimal)
                    | thousandSeparator(region.thousandSeparator)
                }}
              </td>
            </tr>
            <tr>
              <td class="font-weight-bold">{{ $t('general.ModeOfCredit') }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold">
                {{
                  creditNote.paymentType
                    ? creditNote.paymentType
                    : creditNote.mode
                }}
              </td>
              <td colspan="2">&nbsp;</td>
              <td>
                {{ region.currency
                }}{{
                  creditNote.creditTotal
                    | to2Decimal(region.decimal)
                    | thousandSeparator(region.thousandSeparator)
                }}
              </td>
            </tr>
          </tfoot>
        </table>
        <div class="row">
          <div class="col-2 font-weight-bold">{{ $t('general.ReasonForRefund') }}</div>
          <div class="row col-10">
            <div v-for="item in refundReasonOptions" :key="item" class="col-3">
              <b-form-checkbox
                v-model="creditNote.refundReason"
                :value="item"
                >{{ item }}</b-form-checkbox
              >
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-8">{{ $t('general.AgreeReceiveRefund') }}</div>
          <div class="col-4">{{ $t('general.CustomerSignature') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>	

<script>
import dateFormatter from '@/components/mixins/dateformatter';
import moneyFormatter from '@/components/mixins/money';
import invoiceMethodsMixin from '@/components/mixins/invoice';
import computedMixins from '@/components/mixins/computed';
import constant from '@/services/constant';
import moment from 'moment';
export default {
  name: 'CreditNotePrint',
  mixins: [dateFormatter, moneyFormatter, invoiceMethodsMixin, computedMixins],
  props: ['creditNote', 'patient', 'invoiceConfig'],
  data() {
    return {
      refundReasonOptions: constant.REFUND_REASONS,
      pageCount: 0,
    };
  },
  computed: {
    creditNoteAddress() {
      if (this.generalConfig.useLocationAddress) {
        const location = this.clinics.find(
          (location) => location._id === this.creditNote.clinic
        );
        return location ? location.address : '';
      }
      return this.generalConfig.address;
    },
    newCategoried() {
      this.unCategorized();
      return _.mapValues(
        _.groupBy(this.creditNote.invoice.items, 'inventory'),
        (itemList) => itemList.map((item) => _.omit(item, 'inventory'))
      );
    },

    unCategorized() {
      const print = this.creditNote.invoice.items.reduce((result, a) => {
        const r = result;
        r[a.inventory] = r[a.inventory] || [];
        r[a.inventory].push(a);
        return r;
      }, Object.create(null));
      // add dispense items into the default sorted inventory by type
      const sortedInvType = Object.assign({}, this.sortedInventoryByType);
      const dispensedInvTypes = Object.keys(print);
      for (let i = 0; i < dispensedInvTypes.length; i += 1) {
        sortedInvType[dispensedInvTypes[i]] = print[dispensedInvTypes[i]];
      }
      // remove empty array of inventory type
      const sortedInvTypeKeys = Object.keys(sortedInvType);
      for (let j = 0; j < sortedInvTypeKeys.length; j += 1) {
        if (sortedInvType[sortedInvTypeKeys[j]].length < 1) {
          delete sortedInvType[sortedInvTypeKeys[j]];
        }
      }
      console.log(sortedInvType, 'sortedInvType');

      let tempArr = JSON.parse(JSON.stringify(sortedInvType));
      let invoiceCategories = this.invoiceConfig.invoiceCategory;
      let availableCategories = [];
      let orderCat = {};
      invoiceCategories.map((ite) => {
        if (ite.combined) {
          availableCategories.push(ite.category);
          orderCat[ite.category] = 1;
        }
      });
      Object.keys(tempArr).map((ite) => {
        if (tempArr[ite].length > 0) {
          let itemListTemp = [];
          let itemListHash = {};
          tempArr[ite].map((itemList) => {
            if (
              itemList.category &&
              availableCategories.includes(itemList.category)
            ) {
              if (itemListHash[itemList.category] == undefined) {
                itemListHash[itemList.category] = [];
                itemListHash[itemList.category].push(itemList);
              } else {
                itemListHash[itemList.category].push(itemList);
              }
            } else {
              if (itemListHash[itemList.name] == undefined) {
                itemListHash[itemList.name] = [];
                itemListHash[itemList.name].push(itemList);
              } else {
                itemListHash[itemList.name].push(itemList);
              }
            }
          });
          tempArr[ite] = itemListHash;
        }
      });
      Object.keys(tempArr).map((ite) => {
        let unsorted = {};
        Object.keys(orderCat).map((it) => {
          if (tempArr[ite][it] != undefined) {
            unsorted[it] = tempArr[ite][it];
          }
        });
        Object.keys(tempArr[ite]).map((it) => {
          if (!Object.keys(orderCat).includes(it)) {
            unsorted[it] = tempArr[ite][it];
          }
        });
        tempArr[ite] = unsorted;
      });
      console.log(tempArr, 'tempArr');
      return tempArr;
    },
    colspan() {
      return 2;
    },
    totalPaidAmount() {
      const payments = [];
      if (
        Array.isArray(this.creditNote.invoice.payments) &&
        this.creditNote.invoice.payments.length
      ) {
        // Loop through the payment records
        this.creditNote.invoice.payments
          .filter((p) => !p.voided)
          .forEach((p) => {
            if (Array.isArray(p.payments) && p.payments.length) {
              this.processFees = +this.processFees + +p.totalProcessFees;
              // Loop through the patient payments records
              p.payments.forEach((pp) => {
                let l = pp.label;
                if (pp.defaultPayment) {
                  l = this.$t(`paymentMode.${pp.mode}`);
                  if (pp.mode === 'offset') {
                    l = pp.label.replace(
                      'Offset',
                      this.$t('paymentMode.offset')
                    );
                  }
                  pp.labelTrans = l;
                } else {
                  pp.labelTrans = l;
                }
                const patientPayment = {
                  paymentId: pp._id,
                  paymentRef: p.paymentRef,
                  label: pp.labelTrans,
                  amount: pp.amount,
                  change: pp.changeAmount,
                };
                payments.push({
                  ...patientPayment,
                });
              });
            }
          });
      }
      return payments.reduce((total, ite) => (total += ite.amount), 0);
    },
    generalConfig() {
      return this.$store.state.config.general;
    },
    isRefund() {
      return this.creditNote.mode === 'Refund';
    },
    subTotal() {
      return this.creditNote.creditReturns.reduce(
        (a, b) => a + (+b.amount || 0),
        0
      );
    },
    totalTax() {
      return this.creditNote.creditReturns.reduce(
        (a, b) => a + (+b.tax || 0),
        0
      );
    },
    total() {
      return this.subTotal + this.totalTax;
    },
  },
  methods: {
    formatTime(time) {
      return moment(new Date(time)).format('DD MMMM YYYY');
    },
    getTotalPricePerInvoiceCategory(items) {
      return items.reduce((a, ite) => (a += ite.afterTaxPrice), 0);
    },
    getTotalQuantity(items) {
      return items.reduce((a, ite) => (a += Number(ite.qty)), 0);
    },
   
  },
  mounted() {
    const size = 1587;
    this.pageCount = Math.ceil(document.body.clientHeight / size) ;
    if(this.pageCount == 0){
      this.pageCount = 1
    }
    setTimeout(function () {
      window.print();
    }, 2000);
  },
};
</script>	

<style>
.fontWeight {
  font-weight: bolder;
  font-size: 16px;
}
@media print {
  input[type='checkbox'],
  input[type='radio'] {
    opacity: 1 !important;
  }
}
</style>	
