<template>
<b-navbar
  class="navbar navbar-expand-lg navbar-light bg-light main-nav"
  toggleable="lg">
  <span v-if="shouldShowEnvBadge" class="envBadge">{{ envBadge }}</span>
  <acl-navbar-brand v-if="bootswatchTheme === 'konsuldok'" to="/">
    <img src="../assets/logo_konsuldok.png" width="42"
    class="d-inline-block align-top" alt="Logo" data-automation="logo-konsuldok">
  </acl-navbar-brand>
  <acl-navbar-brand v-else to="/">
    <img src="../assets/logo.png" width="42"
    class="d-inline-block align-top" alt="Logo" data-automation="logo">
  </acl-navbar-brand>
  <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

  <b-collapse id="nav-collapse" is-nav>
    <b-navbar-nav class="navbar-nav mr-auto module-menu">
      <template v-if="operatingMode === 'inpatient'">
        <acl-nav-item to="/admission/list" data-automation="admission-list">{{ $t('general.admission') }}</acl-nav-item>
        <acl-nav-item to="/nursing/list" data-automation="nursing-list">{{ $t('general.nursing') }}</acl-nav-item>
        <acl-nav-item to="/bed/list" data-automation="bed-list">{{ $t('general.bed') }}</acl-nav-item>
        <acl-nav-item to="/ot" data-automation="ot-list">{{ $t('general.ot') }}</acl-nav-item>
      </template>
      <template v-if="operatingMode === 'outpatient'">
        <acl-nav-item to="/queue/list" exact data-automation="queue-list">{{ $t('general.queue') }}</acl-nav-item>
        <div>
          <acl-nav-item-dropdown
            v-if="featureFlags.dragoncareIntegration || (appointmentConfig.otherConfig && appointmentConfig.otherConfig.scheduler)"
            idName="appointment-dropdown"
            :text="$t('general.appointment')"
            :className="{'appointmentActive': appointmentChildrenSelected}"
            :dropdown="appointmentDropdown"
          />
          <acl-nav-item v-else to="/appointment/list" data-automation="appointment-list">
            {{ $t('general.appointment') }}
          </acl-nav-item>
        </div>
      </template>

        <acl-nav-item to="/patient/list" data-automation="patient-list">
          {{ patientLabel }}
        </acl-nav-item>

      <acl-nav-item-dropdown
        v-if="operatingMode === 'outpatient'"
        idName="invoice-dropdown"        
        :text="$t('general.invoice')"
        :className="{'invoiceActive': invoiceChildrenSelected}"
        :dropdown="showInvoiceClassificationDropDown? [
          { label: $tc('general.corpInvoice', 1), to: '/corporate-invoice/list','data-automation':'corporate-invoice-list'},
          { label: $tc('moduleFunction.doctor_remuneration', 2), to: '/doctor-remuneration','data-automation':'doctor-remuneration'},
          { label: $t('general.japaneseCorpInvoice'), to: '/corporate-invoice/classification-list','data-automation':'classification-list'},
          { label: $t('general.providerInvoice'), to: '/provider-invoice/provider-list','data-automation':'provider-list'}
        ]:[
          { label: $tc('general.corpInvoice', 1), to: '/corporate-invoice/list','data-automation':'corporate-invoice-list'},
          { label: $tc('moduleFunction.doctor_remuneration', 2), to: '/doctor-remuneration','data-automation':'doctor-remuneration'}
        ]"
        data-automation="invoice-dropdown"
      />
      <acl-nav-item-dropdown
        idName="inventory-dropdown"
        :text="$t('general.inventory')"
        :className="{'inventoryActive': inventoryChildrenSelected}"
        :dropdown="[
          { label: $t('general.inventory'), to: '/inventory/list','data-automation':'inventory-list'},
          { label: $t('general.inventoryAdjustment'), to: '/adjustment/list','data-automation':'adjustment-list'},
          {  label: $t('general.transferBetweenBranch'), to: '/transfer/list','data-automation':'transfer-list'},
          'divider',
          { label: $t('general.purchaseOrder'), to: '/purchaseorder/list','data-automation':'purchaseorder-list'},
          { label: $t('general.deliveryOrder'), to: '/order/list','data-automation':'order-list'},
          'divider',
          { label: $t('general.stockCard'), to: '/stockcard/list','data-automation':'stockcard-list'}
        ]"
      />
      <acl-nav-item to="/pharmacy" exact v-if="operatingMode === 'outpatient'" data-automation='pharmacy'>
        {{ $t('pharmacy.title') }}
        <span class="notifCount">
          <span v-show="pharmacyNotif > 0"
            class="badge badge-pill badge-primary">
            {{ pharmacyNotif }}
          </span>
          <span v-show="ipdPharmacyNotif > 0"
            class="badge badge-pill badge-success">
            {{ ipdPharmacyNotif }}
          </span>
        </span>
      </acl-nav-item>

      <acl-nav-item to="/occupationalService/list" >
        {{ $t('general.occupationalService') }}
      </acl-nav-item>

      <acl-nav-item-dropdown
        idName="integratedTest-dropdown"
        :text="$t('general.tests')"
        :className="{'integratedTestActive': integratedTestsChildrenSelected}"
        :dropdown="testDropDownOptions"
        v-if="featureFlags.healthScreeningReport || featureFlags.labTest"
      />
      <acl-nav-item to="/integrated-test"  data-automation='integrated-test' exact v-else>
        {{ $t('general.tests') }}
      </acl-nav-item>
      <acl-nav-item
        v-if="operatingMode === 'inpatient'"
        to="/ipd-pharmacy/list" data-automation='ipd-pharmacy-list'>
        {{ $t('general.ipdPharmacy') }}
      </acl-nav-item>
      <acl-nav-item
        v-if="operatingMode === 'inpatient'"
        to="" 
        data-automation='office'>
        {{ $t('general.office') }}
      </acl-nav-item>
      <acl-nav-item
        v-if="operatingMode === 'outpatient' || featureFlags.inpatient"
        to="/reports/list"  data-automation='reports-list'>{{ $t('general.reports') }}
      </acl-nav-item>
      <template v-if="operatingMode === 'outpatient'">
        <acl-nav-item to="/campaign/list" data-automation='campaign-list'>{{ $tc('general.campaign') }}</acl-nav-item>
        <b-nav-item
          v-if="featureFlags.dragoncareIntegration"
          to="/messages/list"
          data-automation='messages-list'>
          {{ $t('messages.messages') }}
        </b-nav-item>
        <acl-nav-item-dropdown
          idName="hr-dropdown"
          :text="$t('general.hr')"
          :className="{'hrActive': hrChildrenSelected}"
          :dropdown="[
            { label: $t('general.dashboard'), to: '/hr/dashboard','data-automation':'hr-dashboard'},
            { label: $t('general.settings'), to: '/hr/settings','data-automation':'hr-settings'},
            { label: $t('general.internalNotification'), to: '/hr/internal-notification','data-automation':'hr-internal-notification'},
          ]"
        />
        <b-nav-item-dropdown :text="$t('general.marketing')" v-if="featureFlags.dragoncareIntegration" left>
          <acl-dropdown-item to="/marketing/list" data-automation="marketing-list">{{ $t('general.marketingTag') }}</acl-dropdown-item>
          <acl-dropdown-item to="/marketing/mass-sending" data-automation="marketing-mass-sending">{{ $t('general.massSendingSMSEmail') }}</acl-dropdown-item>
        </b-nav-item-dropdown>
      </template>
      <b-nav-item-dropdown text="Settings" class="d-xl-none d-lg-none" left>
        <acl-dropdown-item to="/config">{{ $t('setting.system') }}</acl-dropdown-item>
        <acl-dropdown-item v-if="featureFlags.inpatient" to="/area/list" >{{ $t('general.area') }}</acl-dropdown-item>
        <acl-dropdown-item to="/room/list" >{{ $t('setting.room') }}</acl-dropdown-item>
        <acl-dropdown-item v-if="featureFlags.inpatient" to="/ipd-room/list">{{ $t('setting.ipdRoom') }}</acl-dropdown-item>
        <acl-dropdown-item to="/corporate/list" >{{ $t('general.corpInsurance') }}</acl-dropdown-item>
        <acl-dropdown-item to="/chartingbuilder/list">{{ $t('setting.chartingTemplate') }}</acl-dropdown-item>
        <acl-dropdown-item to="/letterbuilder/list">{{ $t('setting.letter') }}</acl-dropdown-item>
        <acl-dropdown-item to="/provider/list">{{ $t('setting.provider') }}</acl-dropdown-item>
        <acl-dropdown-item to="/medservice/list" >{{ $t('setting.service') }}</acl-dropdown-item>
        <acl-dropdown-item to="/supplier/list">{{ $t('setting.supplier') }}</acl-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <acl-dropdown-item to="/user/list" >{{ $t('setting.user') }}</acl-dropdown-item>
        <acl-dropdown-item to="/role/list" >{{ $t('setting.role') }}</acl-dropdown-item>
        <acl-dropdown-item to="/smsLogs/list" >{{ $t('setting.smsLogs') }}</acl-dropdown-item>
        <acl-dropdown-item to="/lineLogs/list" >{{ $t('setting.lineLogs') }}</acl-dropdown-item>
        <acl-dropdown-item to="/emailLogs/list" >{{ $t('setting.emailLogs') }}</acl-dropdown-item>
        <acl-dropdown-item to="/auditlog/list" >{{ $t('setting.audit') }}</acl-dropdown-item>
      </b-nav-item-dropdown>
      <b-nav-item-dropdown text="Change Clinic" class="d-xl-none d-lg-none" left>
        <b-dropdown-item href="#"
          v-for="c in clinics" :key="c._id"
          :class="{'selectedClinic': c._id == selectedClinic._id}"
          @click="toggleClinic(c)">{{ c.name | capitalize }}
        </b-dropdown-item>
      </b-nav-item-dropdown>
      <b-nav-item to="/profile/password" class="d-xl-none d-lg-none">Change Password</b-nav-item>
      <b-nav-item to="/logout" class="d-xl-none d-lg-none">Sign Out</b-nav-item>
    </b-navbar-nav>

    <form id="navformcontrols" class="form-inline my-2 my-lg-0 d-none d-lg-block">
      <!-- <div id="searchbar-wrapper">
        <v-icon id="searchbar-icon" name="search" scale="1.1"/>
        <input id="searchbar" class="form-control mr-sm-2" type="text" :placeholder="$t('general.search')">
      </div>
      <a id="searchbar-md" class="btn" href="#">
        <v-icon name="search" class="searchbar-md-button__icon" scale="1.1"/>
      </a> -->
      <b-dropdown id="newbutton">
        <template slot="button-content">
          <v-icon name="plus" class="settingsbutton__icon" scale="1.1"/>
        </template>
        <acl-dropdown-item to="/patient/new" data-automation="create-patient">{{ createPatientLabel }}</acl-dropdown-item>
        <acl-dropdown-item to="/appointment/new" data-automation="create-appointment">{{ $t('general.addNewAppointment') }}</acl-dropdown-item>
        <acl-dropdown-item to="/task/upsert/new" data-automation="create-task">{{ $t('general.addNewTask') }}</acl-dropdown-item>
        <acl-dropdown-item to="/transfer/point" data-automation="transfer-point">{{ $t('transferPoint.transferCreditPoint') }}</acl-dropdown-item>
        <acl-dropdown-item to="/transfer/package" data-automation="transfer-package">{{ $t('transferPoint.transferPackage') }}</acl-dropdown-item>
        <acl-dropdown-item to="/lab-tests-create" data-automation="create-lab-test-order">{{ $t('labTest.createLabTestOrder') }}</acl-dropdown-item>
      </b-dropdown>
      <!-- <a id="notifbutton" data-content="notifcations" class="btn" href="#">
        <v-icon name="bell" class="notifbutton__icon" scale="1.1"/>
      </a> -->
      <b-dropdown id="bell-dropdown-right" right variant="link" toggle-class="text-decoration-none" menu-class="notification-menu" no-caret>
        <span style="color: grey;cursor:pointer;float: right;margin-right: 15px;" v-if="totalUnreadNotif > 0" @click.stop.prevent="markAllRead()">Mark All as Read</span>
        <template v-slot:button-content>
          <v-icon name="bell" />
          <span v-show="totalUnreadNotif > 0"
            class="badge badge-pill badge-primary"
            style="position: absolute; top: 4px;">
            {{ totalUnreadNotif }}
          </span>
        </template>
        <b-dropdown-item v-for="notif in systemNotif" :key="notif._id">
          <div @click="() => clickNotif(notif)" style="position: relative; padding: 5px;">
            <div
              style="position: absolute; left: 100%; top: 0%; color: red;"
              @click.stop.prevent="updateNotif({_id: notif._id, isDeleted: true})"
              >X</div>
            <div style="color: black;">{{notif.message}}</div>
            <div class="d-flex justify-content-between">
              <span style="color: grey;">{{notif.date | date}}</span>
              <span style="color: grey;" v-if="notif.isRead" @click.stop.prevent="updateNotif({_id: notif._id, isRead: false})">(Mark as Unread)</span>
              <span style="color: grey;" v-if="!notif.isRead" @click.stop.prevent="updateNotif({_id: notif._id, isRead: true})">(Mark as Read)</span>
            </div>
          </div>
        </b-dropdown-item>
      </b-dropdown>
        <router-link id="taskbutton" class="btn" style="position: relative;padding: 2px 0 0 5px;" to="/task/main">
        <v-icon name="tasks" class="taskbutton__icon" scale="1.1"/>
        <span v-show="todaysTaskAndReceiveHrNotificationUnCompletedTotal > 0"
          class="badge badge-pill badge-primary task-notification"
          style="position: absolute; top: 0%">
          {{ todaysTaskAndReceiveHrNotificationUnCompletedTotal }}
        </span>
      </router-link>
      <b-dropdown id="settingsbutton" data-content="notifcations">
        <template slot="button-content">
          <v-icon name="cog" class="settingsbutton__icon" scale="1.1"/>
        </template>
        <acl-dropdown-item to="/config" data-automation="config">{{ $t('setting.system') }}</acl-dropdown-item>
        <acl-dropdown-item v-if="featureFlags.inpatient" to="/area/list" data-automation="area-list">{{ $t('general.area') }}</acl-dropdown-item>
        <acl-dropdown-item to="/room/list" data-automation="room-list">{{ $t('setting.room') }}</acl-dropdown-item>
        <acl-dropdown-item v-if="featureFlags.inpatient" to="/ipd-room/list"  data-automation="ipd-room-list">{{ $t('setting.ipdRoom') }}</acl-dropdown-item>
        <acl-dropdown-item to="/corporate/list"  data-automation="corporate-list">{{ $t('general.corpInsurance') }}</acl-dropdown-item>
        <acl-dropdown-item to="/chartingbuilder/list"  data-automation="chartingbuilder-list">{{ $t('setting.chartingTemplate') }}</acl-dropdown-item>
        <acl-dropdown-item to="/letterbuilder/list"  data-automation="letterbuilder-list">{{ $t('setting.letter') }}</acl-dropdown-item>
        <acl-dropdown-item to="/provider/list"  data-automation="provider-list">{{ $t('setting.provider') }}</acl-dropdown-item>
        <acl-dropdown-item to="/medservice/list"  data-automation="medservice-list">{{ $t('setting.service') }}</acl-dropdown-item>
        <acl-dropdown-item to="/supplier/list"  data-automation="supplier-list">{{ $t('setting.supplier') }}</acl-dropdown-item>
        <acl-dropdown-item
          v-if="region.code === 'KH'"
          to="/exchange-rate"
           data-automation="exchange-rate"
           >
          {{ $t('setting.exchangeRate') }}
        </acl-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <acl-dropdown-item to="/user/list" data-automation="user-list">{{ $t('setting.user') }}</acl-dropdown-item>
        <acl-dropdown-item to="/role/list" data-automation="role-list">{{ $t('setting.role') }}</acl-dropdown-item>
        <acl-dropdown-item to="/smsLogs/list" data-automation="smslogs-list">{{ $t('setting.smsLogs') }}</acl-dropdown-item>
        <acl-dropdown-item to="/lineLogs/list" data-automation="linelogs-list">{{ $t('setting.lineLogs') }}</acl-dropdown-item>
        <acl-dropdown-item to="/emailLogs/list" data-automation="emaillogs-list">{{ $t('setting.emailLogs') }}</acl-dropdown-item>
        <acl-dropdown-item to="/auditlog/list" data-automation="auditlog-list">{{ $t('setting.audit') }}</acl-dropdown-item>
      </b-dropdown>
    </form>
    <div class="divider-vertical d-none d-lg-block"></div>
    <img
      id="userphotobutton"
      alt="150x150"
      src="../assets/user_placeholder.svg"
      data-holder-rendered="true"
      class="img-thumbnail rounded-circle d-none d-lg-block"
      style="width: 45px; height: 45px;"
    />
    <div id="userclinic-section" class="d-none d-lg-block">
      <b-dropdown id="userclinic__dropdown">
        <template slot="button-content">
          <div class="userclinic__user">
            <span>{{ user.name }}</span>
            <!-- <v-icon name="sort-down"/> -->
          </div>
          <span class="userclinic__clinic">
            {{ selectedClinic.name }}
          </span>
        </template>
        <b-dropdown-text id="photo-details__wrapper">
          <div id="photo-details">
          <img
            id="userphotobutton2"
            alt="150x150"
            src="../assets/user_placeholder.svg"
            data-holder-rendered="true"
            class="img-thumbnail rounded-circle"
            style="width: 45px; height: 45px;"
          />
          <h6>
            {{ user.name | capitalize }}
            <br/>
            <small>{{ user.email }}</small>
          </h6>
          </div>
        </b-dropdown-text>
        <b-dropdown-divider></b-dropdown-divider>
        <el-select
          :value="selectedClinic"
          value-key="_id"
          placeholder="Select clinic"
          filterable
          class="select-clinic"
          data-automation="select-user-clinic"
          @change="toggleClinic($event)"
          ref="elSelect">
          <el-option
            v-for="item in clinics"
           :data-automation="kebabCase('selected-clinic-'+item.name)"
            :key="item._id"
            :label="item.name"
            :value="item">
          </el-option>
        </el-select>
        <b-dropdown-divider></b-dropdown-divider>
        <b-nav-text
          v-if="accountSwitcher && accountSwitcher.length > 1"
          class="d-flex align-items-center mx-4">
            <v-icon name="user" class="mr-2" scale="1.5"/>
            <b-form-select
              v-model="selectedOrgCode"
              :options="accountSwitcher"
              @change="switchAccount">
            </b-form-select>
        </b-nav-text>
        <b-dropdown-divider v-if="accountSwitcher && accountSwitcher.length > 1"></b-dropdown-divider>
        <b-nav-text
          v-if="featureFlags.inpatient"
          class="d-flex align-items-center mx-4">
            <v-icon name="exchange-alt" class="mr-2" scale="1.5"/>
            <b-form-select
              v-model="selectedOperatingMode"
              :options="operatingModeOptions"
              @change="setOperatingMode">
            </b-form-select>
        </b-nav-text>
        <b-dropdown-item href="#">
          <v-icon name="bell"/>
          <span class="link__text" @click="$router.push('/profile/notifications-settings')">
          &nbsp; {{ $t('general.editNotificationsSettings') }}
          </span>
        </b-dropdown-item>
        <b-dropdown-item href="#">
          <v-icon name="key"/>
          <span class="link__text" @click="$router.push('/profile/password')">
          &nbsp; {{ $t('general.changePassword') }}
          </span>
        </b-dropdown-item>
        <b-nav-text id="selectlang__wrapper">
            <v-icon id="#selectlang__icon" name="globe" scale="1.5"/>
            <b-form-select
              id="selectlang__ctrl"
              v-model="$i18n.locale"
              :options="optionLang"
              @change="setLocale">
            </b-form-select>
        </b-nav-text>
        <b-dropdown-item href="#">
          <v-icon name="door-closed"/>
          <span class="link__text">
          &nbsp; {{ $t('general.logoutOtherSessions') }}
          </span>
        </b-dropdown-item>
        <b-dropdown-item class="versionMenu">
          <small> Version {{ appVersion }}</small>
          <b-button size="sm" variant="info"
            v-if="needsAppUpdate"
            @click="appUpdate">
            {{ $t('general.update') }}
          </b-button>
        </b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item to="/logout">
          <v-icon name="sign-out-alt"/>
          &nbsp; {{ $t('general.signOut') }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </b-collapse>
</b-navbar>
</template>
<script>
import { createNamespacedHelpers, mapGetters } from 'vuex';
import computedMixin from '@/components/mixins/computed';
import transformerMixin from '@/components/mixins/transformer';
import AclNavBrand from '@/components/ACL/Nav/NavBrand.vue';
import AclNavItem from '@/components/ACL/Nav/NavItem.vue';
import AclNavItemDropdown from '@/components/ACL/Nav/NavItemDropdown.vue';
import AclNavItemDropdownItem from '@/components/ACL/Nav/NavItemDropdownItem.vue';
import moment from '@/helpers/moment';
import _ from 'lodash';
import { live as live_versions, staging as staging_versions } from '../../package.json';
const client_version = process.env.NODE_ENV === 'production' ? live_versions.client_version : staging_versions.client_version;

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

export default {
  name: 'MainNav',
  props: ['mainNavDropdown'],
  components: {
    'acl-navbar-brand': AclNavBrand,
    'acl-nav-item': AclNavItem,
    'acl-nav-item-dropdown': AclNavItemDropdown,
    'acl-dropdown-item': AclNavItemDropdownItem,
  },
  mixins: [computedMixin,transformerMixin],
  data() {
    return {
      selectedLang: 'EN',
      optionLang: [
        { value: 'en', text: 'English' },
        { value: 'th', text: 'ภาษาไทย' },
        { value: 'id', text: 'Indonesia' },
        { value: 'ja', text: '日本语' },
        { value: 'ko', text: '韓国語' },
        { value: 'zh-cn', text: '简体中文' },
        { value: 'zh-cn-tr', text: '繁體中文' },
      ],
      appVersion: client_version,
      selectedOperatingMode: localStorage.getItem('operatingMode') || 'outpatient',
      selectedOrgCode: localStorage.getItem('orgCode'),
      accountSwitcher: false,
    };
  },
  watch: {
    selectedClinic(clinic) {
      this.flash(`Selected clinic ${clinic.name}`, 'success', { timeout: 3000 });
    },
  },
  computed: {
    ...mapConfigGetters({
      featureFlags: 'featureFlags',
      appointmentConfig: 'appointment',
    }),
    ...mapGetters('task', { taskNotif: 'todaysTaskCount' }),
    ...mapGetters('receiveHrNotification', ["receiveHrNotificationUnCompletedCount"]),
    todaysTaskAndReceiveHrNotificationUnCompletedTotal() {
      return this.taskNotif + this.receiveHrNotificationUnCompletedCount;
    },
    appointmentDropdown() {
      const withScheduler = this.appointmentConfig.otherConfig && this.appointmentConfig.otherConfig.scheduler;
      const withDragonCare = this.featureFlags.dragoncareIntegration;
      const dropdownItems = [
        { label: this.$t('general.appointment'), to: '/appointment/list' },
      ];
      if (withDragonCare) {
        dropdownItems.push({ label: this.$t('general.waitlist'), to: '/waitlist/list' });
      }
      if (withScheduler) {
        dropdownItems.push({ label: this.$t('general.scheduler'), to: '/scheduler/templates/list' });
      }
      return dropdownItems;
    },   
    latestAppVersion() {
      return this.$store.state.latestAppVersion;
    },
    needsAppUpdate() {
      return this.$store.state.verCheckerEnabled && this.$store.state.needsAppUpdate;
    },
    inventoryChildrenSelected() {
      switch (this.$route.name) {
        case 'inventory':
        case 'inventory-list':
        case 'inventory-new':
        case 'inventory-edit':
        case 'adjustment':
        case 'adjustment-list':
        case 'adjustment-new':
        case 'adjustment-edit':
        case 'order':
        case 'order-list':
        case 'order-new':
        case 'order-edit':
        case 'purchase-order-list':
          return true;
        default:
          return false;
      }
    },
    invoiceChildrenSelected() {
      switch (this.$route.name) {
        case 'corporate-invoice-list':
          return true;
        default:
          return false;
      }
    },
    integratedTestsChildrenSelected() {
      switch (this.$route.name) {
        case 'integrated-test':
        case 'health-screening':
          return true;
        default:
          return false;
      }
    },
    appointmentChildrenSelected() {
      switch (this.$route.name) {
        case 'appointmentList':
        case 'waitlistList':
          return true;
        default:
          return false;
      }
    },
    hrChildrenSelected() {
      switch (this.$route.name) {
        case 'hr-dashboard':
        case 'hr-settings':
        case 'hr-internal-notification':
          return true;
        default:
          return false;
      }
    },
    pharmacyNotif() {
      return this.$store.getters['pharmacy/todaysTaskCount'];
    },
    ipdPharmacyNotif() {
      return this.$store.getters['pharmacy/todaysTaskCountIPD'];
    },
    systemNotif() {
      return this.$store.getters['notification/all'];
    },
    totalUnreadNotif() {
      return this.$store.state.notification.totalUnread;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    operatingModeOptions() {
      return [
        { value: 'outpatient', text: this.$t('general.outpatient') },
        { value: 'inpatient', text: this.$t('general.inpatient') },
      ];
    },
    operatingMode() {
      if (this.featureFlags.inpatient && this.selectedOperatingMode === 'inpatient') {
        return 'inpatient';
      }
      return 'outpatient';
    },
    showInvoiceClassificationDropDown() {
      return this.$store.state.config.featureFlags.peaceclinic;
    },
    testDropDownOptions() {
      const options = [{ label: this.$t('general.tests'), to: '/integrated-test', 'data-automation': 'integrated-test' }];

      if (this.featureFlags.healthScreeningReport) {
        options.push({ label: this.$t('general.healthScreening'), to: '/health-screening', 'data-automation': 'health-screening' });
      }

      if (this.featureFlags.labTest) {
        options.push({ label: this.$t('general.labTests'), to: '/lab-tests', 'data-automation': 'lab-test' });
      }

      return options;
    },
    patientLabel() {
      if (this.featureFlags.occupationalService) {
        return this.$t('occupationalService.employeeSection.employee');
      }

      return this.$t('general.patient');
    },
    createPatientLabel() {
      if (this.featureFlags.occupationalService) {
        return this.$t('occupationalService.employeeSection.create');
      }

      return this.$t('general.addNewPatient');
    },
    shouldShowEnvBadge() {
      return process.env.NODE_ENV !== 'production';
    },
    envBadge() {
      return process.env.NODE_ENV;
    },
  },
  filters: {
    date(value) {
      return value ? moment(value, 'X').format('LLLL') : '';
    },
  },
  methods: {
    loadAccountSwitcher() {
      const prevAccountSwitcher = localStorage.getItem('accountSwitcher')
      if (prevAccountSwitcher) {
        try {
          const parsed = JSON.parse(prevAccountSwitcher);
          this.accountSwitcher = parsed.map(item => ({
            text: item.name,
            value: item.orgCode,
          }));
        } catch (error) {
          this.accountSwitcher = false;
        }
      } else {
        this.accountSwitcher = false;
      }
    },
    toggleClinic(clinic) {
      this.$store.dispatch('clinic/selectClinic', clinic);
      this.$store.dispatch('config/loadConfig', { clinicCode: clinic._id });
    },
    appUpdate() {
      window.location.reload(true);
    },
    setLocale(value) {
      localStorage.setItem('locale', value);
      if (value) {
        moment.locale(value);
      }
    },
    updateNotif(params) {
      this.$store.dispatch('notification/patch', params);
    },
    markAllRead() {
      this.$store.dispatch('notification/updateAll', { _id: this.user.id, isRead: true });
    },
    clickNotif({ _id, url, collectionDocumentId }) {
      this.updateNotif({ _id, isRead: true });
      if (this.$route.path === '/integrated-test') {
        this.$router.replace({ query: { id: collectionDocumentId } });
      } else {
        this.$router.push(url);
      }
    },
    setOperatingMode(value) {
      localStorage.setItem('operatingMode', value);
    },
    switchAccount(value) {
      localStorage.setItem('orgCode',value);
      this.$store.dispatch('auth/switchAccount', {orgCode: value, username: this.user.email});
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.$store.dispatch('pharmacy/getTodaysToDo', { clinic: this.selectedClinic._id });
      this.$store.dispatch('pharmacy/getTodaysToDoIPD', { clinic: this.selectedClinic._id });
      this.$store.dispatch('task/listTodaysTask', { user: this.user.id, isCompleted: false, isRejected: false });
      this.$store.dispatch('notification/getActive', this.user.id);
      this.$store.dispatch('receiveHrNotification/getUnCompletedCount');
    }
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
    });
  },
  created() {
    this.loadAccountSwitcher();
    moment.locale(this.$i18n.locale);
  },
  sockets: {
    NOTIFICATION(d) {
      // const forMe = d.userId !== this.user.id;
      if (d.module && d.module.name) {
        if (d.module.name === 'pharmacy') {
          setTimeout(() => {
            this.$store.dispatch('pharmacy/getTodaysToDo', { clinic: this.selectedClinic._id });
            this.$store.dispatch('pharmacy/getTodaysToDoIPD', { clinic: this.selectedClinic._id });
          }, 1000);
        }
      }
    },
  },
};
</script>

<style lang="scss">
$navbar-hover-bgcolor: #E1565B;
$navbar-hover-color: #FFF;

#nav {
  position: fixed;
  width: 100vw;
  z-index: 1023;
  border-bottom: 1px solid rgba(0,0,0,0.125);

  .module-menu {
    & .nav-item > a:hover,
    & .nav-item > a[aria-expanded="true"] {
      color: #E1565B !important;
    }

    & .nav-item {
      .nav-link.router-link-exact-active,
      .nav-link.router-link-active {
        color: #E1565B !important;
      }
    }
  }

  @media (max-width: 1120px) {
    .navbar {
      font-size: 14px;
    }
    .btn {
      padding: 0.375rem 0.75rem;
    }
  }
}

#inventory-dropdown {
  & .dropdown-toggle {
    &::after {
      display: inline-block;
      content: "";
      /* uncomment to enable dropdown arrow
      margin-left: 0.255em;
      vertical-align: 0.255em;
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
      */
      border:none;
    }
  }

  & .dropdown-menu a {
    text-transform: none;
    font-weight: 400;
    font-size: 1rem;
    color: #222;

    &:hover {
      background: $navbar-hover-bgcolor !important;
      color: $navbar-hover-color !important;
    }
  }

  &.inventoryActive {
    & .dropdown-toggle {
      color: #E1565B !important;
    }
  }
}

#searchbar-wrapper {
  display: inline-block;
  & > #searchbar-icon {
    position: absolute;
    margin-top: 12px;
    margin-left: 10px;
    // color: #757575;
    color: lightgray;
  }

  #searchbar {
    background: #EFEFEF;
    color: #757575;
    border-radius: 8px;
    border: none;
    text-align: left;
    text-indent: 15px;
  }

  @media only screen
  and (min-device-width : 768px)
  and (max-device-width : 1024px)
  and (orientation : landscape) {
    display: none;
  }

  @media only screen
  and (min-device-width : 768px)
  and (max-device-width : 1024px)
  and (orientation : portrait) {
    display: none;
  }
}
#appointment-dropdown {
  & .dropdown-toggle {
    &::after {
      display: inline-block;
      content: "";
      border:none;
    }
  }

  &.appointmentActive {
    & .dropdown-toggle {
      color: #E1565B !important;
    }
  }

  & .dropdown-menu a {
    text-transform: none;
    font-weight: 400;
    font-size: 1rem;
    color: #222;

    &:hover {
      background: $navbar-hover-bgcolor !important;
      color: $navbar-hover-color !important;
    }
  }
}

#invoice-dropdown {
  & .dropdown-toggle {
    &::after {
      display: inline-block;
      content: "";
      /* uncomment to enable dropdown arrow
      margin-left: 0.255em;
      vertical-align: 0.255em;
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
      */
      border:none;
    }
  }

  &.invoiceActive {
    & .dropdown-toggle {
      color: #E1565B !important;
    }
  }

  & .dropdown-menu a {
    text-transform: none;
    font-weight: 400;
    font-size: 1rem;
    color: #222;

    &:hover {
      background: $navbar-hover-bgcolor !important;
      color: $navbar-hover-color !important;
    }
  }
}

#integratedTest-dropdown {
  & .dropdown-toggle {
    &::after {
      display: inline-block;
      content: "";
      /* uncomment to enable dropdown arrow
      margin-left: 0.255em;
      vertical-align: 0.255em;
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
      */
      border:none;
    }
  }

  &.integratedTestActive {
    & .dropdown-toggle {
      color: #E1565B !important;
    }
  }

  & .dropdown-menu a {
    text-transform: none;
    font-weight: 400;
    font-size: 1rem;
    color: #222;

    &:hover {
      background: $navbar-hover-bgcolor !important;
      color: $navbar-hover-color !important;
    }
  }
}

#newbutton {
  text-align: center;
  color: #E1565B;

  & button {
    color: #E1565B;
    background-color: transparent;
    line-height: 1.5;
    border-radius: 0.25rem;
    border: none;

    &:focus {
      /* remove border when focused*/
      outline: none;
      border-color: inherit;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }

  & button:hover,
  & button[aria-expanded="true"] {
    color: #E1565B;
    background-color: rgb(255, 224, 224);
  }

  & .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0;
    vertical-align: 0.255em;
    content: "";
    border: none;
  }

  & .dropdown-menu a {
    text-transform: none;
    font-weight: 400;
    font-size: 1rem;
    color: #222;

    &:hover {
      background: $navbar-hover-bgcolor !important;
      color: $navbar-hover-color !important;
    }
  }
}

#searchbar-md {
  display: none;
  width: 48px;
  // border: 1px dashed plum;
  text-align: center;
  color: #aaa;
  margin-right: 5px;

  @media only screen
  and (min-device-width : 768px)
  and (max-device-width : 992px)
  and (orientation : landscape) {
    display: inline-block;
  }

  @media only screen
  and (min-device-width : 768px)
  and (max-device-width : 992px)
  and (orientation : portrait) {
    display: inline-block;
  }
}

#notifbutton {
  width: 48px;
  // border: 1px dashed plum;
  text-align: center;
  color: #aaa;

  &:hover {
    color: #232323;
    background-color: #EFEFEF;
  }

  .notifbutton__icon {
    position: relative;
    left: -1px;
  }
}

#taskbutton {
  width: 48px;
  // border: 1px dashed plum;
  text-align: center;
  color: #aaa;

  &:hover {
    color: #232323;
    background-color: #EFEFEF;
  }

  .taskbutton__icon {
    position: relative;
    left: -4px;
  }
}
.select-clinic {
  display: block !important;
  padding: 20px;
  margin: 0 auto;
}
#settingsbutton {
  // width: 48px;
  // border: 1px dashed palegreen;
  text-align: center;
  color: #aaa;
  // margin-right: 5px;

  &:hover {
    color: #232323;
  }

  & button {
    color: #aaa;
    background-color: transparent;
    line-height: 1.5;
    border-radius: 0.25rem;
    border: none;

    &:focus {
      /* remove border when focused*/
      outline: none;
      border-color: inherit;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }

  & button:hover,
  & button[aria-expanded="true"] {
    color: #232323;
    background-color: #EFEFEF;
  }

  & .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0;
    vertical-align: 0.255em;
    content: "";
    border: none;
    /*
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    */
  }

  & .dropdown-menu a {
    text-transform: none;
    font-weight: 400;
    font-size: 1rem;
    color: #222;

    &:hover {
      background: $navbar-hover-bgcolor !important;
      color: $navbar-hover-color !important;
    }
  }
}

.divider-vertical {
  height: 40px;
  margin: 0 9px;
  border-left: 1px solid #979797;
  border-right: 1px solid #ffffff;
}

#userphotobutton {
  border: 2px solid #319996;

  &:hover {
    // border: 1px solid #dee2e6;
  }
}


#userclinic__dropdown {
  .selectedClinic > a {
    color: #E1565B !important;
    font-weight: 600;
  }

  & button.dropdown-toggle {
    background: transparent;
    //height: 30px;
    padding: 0px;
    border: none;
    display: flex;
    flex-direction: column;
    // min-width: 150px;
    padding-left: 8px;
    padding-right: 8px;

    &:focus {
      /* remove border when focused*/
      outline: none;
      border-color: inherit;
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    &::after {
      display: inline-block;
      content: "";
      /* uncomment to enable dropdown arrow
      margin-left: 0.255em;
      vertical-align: 0.255em;
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
      */
      border:none;
    }

    .userclinic__user {
      color: #141B26;
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-content: center;
    }
    .userclinic__clinic {
      color: #E1565B;
      font-size: 0.9rem;
    }
  }

  & .dropdown-menu a {
    text-transform: none;
    font-weight: 400;
    font-size: 1rem;
    color: #222;

    &:hover {
      background: $navbar-hover-bgcolor !important;
      color: $navbar-hover-color !important;
    }
  }
}
/*
#userclinic-section {
  display: flex;
  flex-direction: column;
  min-width: 150px;
  padding-left: 8px;
  padding-right: 8px;

  .userclinic__user {
    color: #141B26;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }
  .userclinic__clinic {
    color: #E1565B;
    font-size: 0.9rem;
  }
}
*/

#photo-details__wrapper {
  min-width: 240px;
  padding-left: 15px;
  padding-right: 15px;

  #photo-details {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    padding-top: 10px;
    padding-bottom: 10px;

    background: #EFEFEF;
    // color: #757575;
    border-radius: 8px;
    border: none;

    img {
      margin-left: 10px;
      border: 2px solid #319996;
    }

    h6 {
      margin-left: 10px;
    }
  }
}

#selectlang__wrapper {
  display: flex;
  padding-left: 24px;
  padding-right: 24px;
  align-items: center;

  #selectlang__ctrl {
    margin-left: 10px !important;
  }
}

.btn .badge .task-notification {
  position: absolute;
  top: 10px;
}

#hr-dropdown {
  & .dropdown-toggle {
    &::after {
      display: inline-block;
      content: "";
      /* uncomment to enable dropdown arrow
      margin-left: 0.255em;
      vertical-align: 0.255em;
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
      */
      border:none;
    }
  }

  & .dropdown-menu a {
    text-transform: none;
    font-weight: 400;
    font-size: 1rem;
    color: #222;

    &:hover {
      background: $navbar-hover-bgcolor !important;
      color: $navbar-hover-color !important;
    }
  }

  &.hrActive {
    & .dropdown-toggle {
      color: #E1565B !important;
    }
  }
}
.versionMenu {
  a {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
  }
}
.notifCount {
  display: inline-flex;
  flex-direction: column;
}

.notification-menu {
  max-height: 90vh;
  overflow-y: auto;
}

.envBadge {
  display: inline-block;
  text-transform: uppercase;
  transform: rotate(-45deg);
  position: absolute;
  top: 1.6rem;
  left: -2.3rem;
  font-size: 0.75rem;
  background: blue;
  width: 9rem;
  text-align: center;
  color: white;
}
</style>
