<template>
    <div class="container mb-5">
      <div class="row mb-3">
        <div class="col-10">
          <img :src="generalConfig.logoURL"
            style="width: 300px; margin-left: 0"/>
        </div>
        <div class="col-2">
            <p class="mb-1 mt-3 address-font">
                Credit Note No: #{{ creditNote.refNo }}
            </p>
            <p class="mb-1 address-font">
                Date: {{ creditNote.date | ddMonthYYYYFromX }}
            </p>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-6">
          <h5>{{generalConfig.clinicName}}</h5>
          <p
            class="mb-1 address-font">
            {{ creditNoteAddress }}
          </p>
          <p class="mb-3 address-font" v-if="generalConfig.telephone">
            Tel: {{ generalConfig.telephone }}</p>
        </div>
      </div>
      <div class="row mb-3">
          <div class="col-8">
          </div>
          <div class="col-4">
            <h5>Reference Details:</h5>
            <p class="mb-1 address-font">
              Invoice No: {{ creditNote.invoice.invoiceNo }}
            </p>
            <p class="mb -1 address-font">
              Invoice Date: {{ creditNote.invoice.date | ddMonthYYYYFromX }}
            </p>
          </div>
      </div>
      <div class="row mb-5">
        <div class="col">
            <p class="mb-1 address-font">
              Patient Name: {{ patient.name }}
            </p>
            <p class="mb-1 address-font">
              Patient NRIC/Passport: {{ patient.nric }}
            </p>
            <p class="mb-1 address-font">
              Patient Address: {{ patient.address }}
            </p>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col">
            <h5>Credit Note Details:</h5>
            <p class="mb-1 address-font">
              Reason for Credit Note: {{ creditNote.remarks }}
            </p>
            <p class="mb-1 address-font">
              Credit Mode: {{ creditNote.mode }}
              <span v-if="isRefund">
              (By {{ creditNote.paymentType }})</span>
            </p>
        </div>
      </div>
      <div class="row mb-3">
          <div class="col-12">
              <table class="table">
                <thead>
                  <tr>
                      <td class="font-weight-bold">Item Name</td>
                      <td class="font-weight-bold">Qty</td>
                      <td class="font-weight-bold">Amount</td>
                      <td class="font-weight-bold"
                        v-if="creditNote.invoice.includeGST">Tax</td>
                      <td class="font-weight-bold">Total</td>
                  </tr>
                </thead>
                <tbody>
                    <tr v-for="crReturn in creditNote.creditReturns"
                      :key="crReturn._id">
                        <td>{{ crReturn.returning.name }}</td>
                        <td>{{ crReturn.qty }}{{ crReturn.returning.unit }}</td>
                        <td>{{ region.currency }}{{ crReturn.amount | to2Decimal(region.decimal) | thousandSeparator(region.thousandSeparator) }}</td>
                        <td v-if="creditNote.invoice.includeGST">{{ region.currency }}{{ crReturn.tax | to2Decimal(region.decimal) | thousandSeparator(region.thousandSeparator) }}</td>
                        <td>{{ region.currency }}{{ crReturn.total | to2Decimal(region.decimal) | thousandSeparator(region.thousandSeparator) }}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td :colspan="colspan">&nbsp;</td>
                        <td class="font-weight-bold">
                            Subtotal
                        </td>
                        <td>{{ region.currency }}{{ subTotal | to2Decimal(region.decimal) | thousandSeparator(region.thousandSeparator) }}</td>
                    </tr>
                    <tr v-if="creditNote.invoice.includeGST">
                        <td :colspan="colspan">&nbsp;</td>
                        <td class="font-weight-bold">
                            Tax
                        </td>
                        <td>{{ region.currency }}{{ totalTax | to2Decimal(region.decimal) | thousandSeparator(region.thousandSeparator) }}</td>
                    </tr>
                    <tr>
                        <td :colspan="colspan">&nbsp;</td>
                        <td class="font-weight-bold">
                            Invoice Total
                        </td>
                        <td>{{ region.currency }}{{ total | to2Decimal(region.decimal) | thousandSeparator(region.thousandSeparator) }}</td>
                    </tr>
                </tfoot>
              </table>
          </div>
      </div>
    </div>
</template>

<script>
import dateFormatter from '@/components/mixins/dateformatter';
import moneyFormatter from '@/components/mixins/money';
import invoiceMethodsMixin from '@/components/mixins/invoice';
import computedMixins from '@/components/mixins/computed';

export default {
  name: 'CreditNotePrint',
  mixins: [dateFormatter, moneyFormatter, invoiceMethodsMixin, computedMixins],
  props: ['creditNote', 'patient'],
  computed: {
    creditNoteAddress() {
      if (this.generalConfig.useLocationAddress) {
        const location = this.clinics.find(location => location._id === this.creditNote.clinic );
        return location ? location.address : '';
      }
      return this.generalConfig.address;
    },
    colspan() {
      if (this.creditNote.invoice.includeGST) {
        return 3;
      }
      return 2;
    },
    generalConfig() {
      return this.$store.state.config.general;
    },
    isRefund() {
      return this.creditNote.mode === 'Refund';
    },
    subTotal() {
      return this.creditNote.creditReturns
        .reduce((a, b) => a + (+b.amount || 0), 0);
    },
    totalTax() {
      return this.creditNote.creditReturns
        .reduce((a, b) => a + (+b.tax || 0), 0);
    },
    total() {
      return this.subTotal + this.totalTax;
    },
  },
  mounted() {
    setTimeout(function(){ 
      window.print();
    }, 2000);
  },
};
</script>
