<template>
    <div class="container mb-5 pdfContainer">
      <div class="row mb-3">
        <div class="col-9">
          <template v-if="!disablePrint">
            <img :src="generalConfig.logoURL"
              style="width: 300px; margin-left: 0"/>
          </template>
          <template v-else-if="disablePrint && creditNote.logoBase64">
            <img :src="creditNote.logoBase64"
              style="width: 300px; margin-left: 0"/>
          </template>
        </div>
        <div class="col-3">
            <p class="mb-1 mt-3 address-font">
                Customer Feedback
            </p>
            <p class="mb-1 address-font">
                Date: {{ creditNote.feedback && creditNote.feedback.submitted | formatVDDate({format: 'DD MMM YYYY'}) }}
            </p>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-6">
          <h5>{{generalConfig.clinicName}}</h5>
          <p
            class="mb-1 address-font">
            {{ creditNoteAddress }}
          </p>
          <p class="mb-3 address-font" v-if="generalConfig.telephone">
            Tel: {{ generalConfig.telephone }}</p>
        </div>
      </div>
      <div class="row mb-3">
          <div class="col-7">
          </div>
          <div class="col-5">
            <h5>Reference Details:</h5>
            <p class="mb-1 address-font">
              Invoice No: {{ creditNote.invoice && creditNote.invoice.invoiceNo }}
            </p>
            <p class="mb -1 address-font">
              Invoice Date: {{ creditNote.invoice && creditNote.invoice.date | ddMonthYYYYFromX }}
            </p>
          </div>
      </div>
      <div class="row mb-3 dynamicContent">
          <div class="col-12">
            <json-schema-form
              :schema="feedbackForm.schema"
              :schemaData="this.creditNote.feedback"
            />
          </div>
      </div>

      <template v-if="!disablePrint">
        <section
          v-if="creditNote.customerSignatureUrl"
          class="feedback-signature-url-section"
        >
          <div class="feedback-signature-url-section-img">
            <img
              :src="creditNote.customerSignatureUrl"
              class="feedback-signature-image"
              width="460px"
              height="260px"
            />
            <br>
            <div class="feedback-signature-label">{{ $t('credit.customerAcknowledgement') }}</div>
          </div>
        </section>
      </template>
      <template v-else-if="disablePrint && creditNote.signatureBase64">
        <section
          v-if="creditNote.signatureBase64"
          class="feedback-signature-url-section"
        >
          <div class="feedback-signature-url-section-img">
            <img
              :src="creditNote.signatureBase64"
              class="feedback-signature-image"
              width="460px"
              height="260px"
            />
            <br>
            <div class="feedback-signature-label">{{ $t('credit.customerAcknowledgement') }}</div>
          </div>
        </section>
      </template>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dateFormatter from '@/components/mixins/dateformatter';
import computedMixins from '@/components/mixins/computed';
import JsonSchemaForm from '@/components/Print/FormBuilder/JsonSchemaForm.vue';

export default {
  name: 'CreditNotePrint',
  components: {
    JsonSchemaForm,
  },
  mixins: [dateFormatter, computedMixins],
  props: {
    creditNote: { type: Object },
    patient: { type: Object },
    disablePrint: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters('config', ['feedbackForm']),
    creditNoteAddress() {
      if (this.generalConfig.useLocationAddress) {
        const location = this.clinics.find(l => l._id === this.creditNote.clinic);
        return location ? location.address : '';
      }
      return this.generalConfig.address;
    },
    generalConfig() {
      return this.$store.state.config.general;
    },
  },
  mounted() {
    if (this.disablePrint) {
      return;
    }

    setTimeout(() => {
      window.print();
    }, 2000);
  },
};
</script>
<style lang="scss" scoped>
.pdfContainer {
  font-size: 0.85rem;
  margin-left: 1rem;
}
.dynamicContent {
  word-wrap: break-word;
  max-width: 90%;
}

.feedback-signature {
  width: 460px;
}
.feedback-signature-label {
  width: 460px;
  text-align: center;
}
/deep/ .feedback-signature-url-section-img {
  text-align: left;
}
</style>
