var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.parentComponent !== "canvas-editor"
        ? _c("div", { staticClass: "row mt-3 mb-3" }, [
            _c("div", { staticClass: "col" }, [
              _c("div", { staticClass: "action-btn-container" }, [
                _vm.selectedNotes.length && _vm.isOnSameClinicWithInvoice
                  ? _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true }
                          },
                          {
                            name: "acl",
                            rawName: "v-acl",
                            value: "consultation_delete_note",
                            expression: "'consultation_delete_note'"
                          }
                        ],
                        staticClass: "btn btn-outline-primary",
                        attrs: { title: "Delete" },
                        on: { click: _vm.showCasenoteActionModal }
                      },
                      [
                        _c("v-icon", { attrs: { name: "trash", scale: "1" } }),
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("general.delete")) +
                            "\n          "
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.inPatientFeatureFlag &&
                _vm.selectedNotes.length &&
                _vm.isOnSameClinicWithInvoice
                  ? _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true }
                          }
                        ],
                        staticClass: "btn btn-outline-primary",
                        attrs: { title: "Tag Selected Notes" },
                        on: {
                          click: function() {
                            return _vm.toggleTagSelectedCaseNotes(true)
                          }
                        }
                      },
                      [
                        _c("v-icon", { attrs: { name: "tag", scale: "1" } }),
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("general.tag")) +
                            "\n          "
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.inPatientFeatureFlag &&
                _vm.selectedNotes.length &&
                _vm.isOnSameClinicWithInvoice
                  ? _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true }
                          }
                        ],
                        staticClass: "btn btn-outline-primary",
                        attrs: { title: "Untag Selected Notes" },
                        on: {
                          click: function() {
                            return _vm.toggleTagSelectedCaseNotes(false)
                          }
                        }
                      },
                      [
                        _c("v-icon", {
                          attrs: { name: "tag", scale: "1", color: "grey" }
                        }),
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("general.untag")) +
                            "\n          "
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectedNotes.length
                  ? _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true }
                          },
                          {
                            name: "acl",
                            rawName: "v-acl",
                            value: "consultation_download_pdf",
                            expression: "'consultation_download_pdf'"
                          }
                        ],
                        staticClass: "btn btn-outline-primary",
                        attrs: { title: "Download Selected Notes" },
                        on: { click: _vm.downloadSelectedCaseNotes }
                      },
                      [
                        _c("v-icon", {
                          attrs: { name: "download", scale: "1" }
                        }),
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("general.download")) +
                            "\n          "
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectedNotes.length && _vm.isOnSameClinicWithInvoice
                  ? _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "acl",
                            rawName: "v-acl",
                            value: "consultation_bulk_move_notes",
                            expression: "'consultation_bulk_move_notes'"
                          }
                        ],
                        staticClass: "btn btn-outline-primary",
                        on: {
                          click: function() {
                            return _vm.showCaseNoteActionModal(
                              "casenotes-move-modal"
                            )
                          }
                        }
                      },
                      [
                        _c("v-icon", {
                          attrs: { name: "arrows-alt", scale: "1" }
                        }),
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("general.move")) +
                            "\n          "
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectedNotes.length === 1 &&
                _vm.notesMapped[_vm.selectedNotes[0]].otherPagesId.length > 0 &&
                _vm.isOnSameClinicWithInvoice
                  ? _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true }
                          },
                          {
                            name: "acl",
                            rawName: "v-acl",
                            value: "consultation_split_note",
                            expression: "'consultation_split_note'"
                          }
                        ],
                        staticClass: "btn btn-outline-primary",
                        attrs: { title: "Split Selected Note" },
                        on: {
                          click: function() {
                            return _vm.showCaseNoteActionModal(
                              "casenote-split-modal"
                            )
                          }
                        }
                      },
                      [
                        _c("v-icon", { attrs: { name: "copy", scale: "1" } }),
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("general.split")) +
                            "\n          "
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.consultNoteLock() && _vm.isOnSameClinicWithInvoice
                  ? _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true }
                          },
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.parentComponent !== "tcm-charting-view",
                            expression:
                              "parentComponent !== 'tcm-charting-view'"
                          },
                          {
                            name: "acl",
                            rawName: "v-acl",
                            value: "consultation_upload_files",
                            expression: "'consultation_upload_files'"
                          }
                        ],
                        staticClass: "btn btn-outline-primary",
                        attrs: {
                          disabled:
                            (_vm.parentComponent === "charting-view" &&
                              _vm.noVisitsYet) ||
                            (_vm.parentComponent === "consult-note-container" &&
                              !_vm.mainForm.draft),
                          "data-automation": "charting-upload-files",
                          title: _vm.$t("general.uploadFiles")
                        }
                      },
                      [
                        _c(
                          "form",
                          {
                            staticStyle: { position: "relative" },
                            attrs: { enctype: "multipart/form-data" }
                          },
                          [
                            _c("input", {
                              ref: "uploadFilesButton",
                              staticClass: "input-file",
                              attrs: {
                                type: "file",
                                multiple: "",
                                disabled:
                                  (_vm.parentComponent === "charting-view" &&
                                    _vm.noVisitsYet) ||
                                  (_vm.parentComponent ===
                                    "consult-note-container" &&
                                    !_vm.mainForm.draft),
                                accept: "image/jpeg,image/png,application/pdf"
                              },
                              domProps: { value: _vm.inputFileValue },
                              on: {
                                change: function($event) {
                                  return _vm.filesChange($event.target.files)
                                }
                              }
                            }),
                            _c("v-icon", {
                              staticStyle: { "margin-right": "5px" },
                              attrs: { name: "upload" }
                            }),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("general.uploadFiles")))
                            ])
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e(),
                !_vm.consultNoteLock() && _vm.isOnSameClinicWithInvoice
                  ? _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "acl",
                            rawName: "v-acl",
                            value: "consultation_add_charting",
                            expression: "'consultation_add_charting'"
                          },
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.parentComponent !== "tcm-charting-view",
                            expression:
                              "parentComponent !== 'tcm-charting-view'"
                          }
                        ],
                        staticClass: "btn btn-outline-primary",
                        staticStyle: { "margin-left": "10px" },
                        attrs: {
                          "data-automation": "add-charting",
                          disabled:
                            (_vm.parentComponent === "charting-view" &&
                              _vm.noVisitsYet) ||
                            (_vm.parentComponent === "consult-note-container" &&
                              !_vm.mainForm.draft) ||
                            _vm.lock
                        },
                        on: { click: _vm.openTemplateSelector }
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "vertical-align" },
                          [
                            _c("v-icon", {
                              staticStyle: { "margin-right": "5px" },
                              attrs: { name: "plus" }
                            }),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("general.addCharting")))
                            ])
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e()
              ])
            ])
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "row chartings-container" },
        _vm._l(
          _vm.parentComponent === "charting-view"
            ? _vm.draggableNotes
            : _vm.filteredChartingNotes,
          function(day, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "col-sm-12",
                staticStyle: { width: "auto" }
              },
              [
                _c("div", { staticClass: "text-center mb-2" }, [
                  _vm.presetChartingSortType == "name"
                    ? _c("strong", [
                        _vm._v(
                          "------ " +
                            _vm._s(
                              (day[0].name || "Unknown")[0].toUpperCase()
                            ) +
                            "  ------"
                        )
                      ])
                    : _vm.presetChartingSortType == "order"
                    ? _c("strong", [
                        _vm._v(
                          "------ Drag notes to change their order  ------"
                        )
                      ])
                    : _c("strong", [
                        _vm._v(
                          "------ " +
                            _vm._s(_vm.sortType) +
                            _vm._s(
                              _vm.formatVDDate(day[0].dateDividerUnix, {
                                format: "MMM DD, YYYY",
                                lang: _vm.$i18n.locale
                              })
                            ) +
                            " ------"
                        )
                      ])
                ]),
                _c(
                  "draggable",
                  _vm._b(
                    {
                      staticClass: "row mb-5",
                      attrs: {
                        id: "chart-" + index,
                        disabled: _vm.presetChartingSortType !== "order"
                      },
                      on: {
                        change: function(evt, originalEvent) {
                          return _vm.onDragMoved(evt, originalEvent, index)
                        }
                      },
                      model: {
                        value: _vm.draggableNotes[index],
                        callback: function($$v) {
                          _vm.$set(_vm.draggableNotes, index, $$v)
                        },
                        expression: "draggableNotes[index]"
                      }
                    },
                    "draggable",
                    _vm.dragOptions,
                    false
                  ),
                  _vm._l(day, function(charting) {
                    return _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "acl",
                            rawName: "v-acl:disabled.isDynamic",
                            value: charting.categoryId,
                            expression: "charting.categoryId",
                            arg: "disabled",
                            modifiers: { isDynamic: true }
                          }
                        ],
                        key: charting._id,
                        staticClass: "charting-card m-4 mt-5",
                        class: { selected: _vm.hasSelected(charting._id) },
                        style: _vm.chartingBodyStyle,
                        attrs: { id: "chartingcard-" + charting._id }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "charting-card-label space-between",
                            style: {
                              "background-color": _vm.getColorLabel(charting)
                            }
                          },
                          [
                            _c("span", { staticClass: "left-icons" }, [
                              _vm.isOnSameClinicWithInvoice
                                ? _c(
                                    "span",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.toggleStar(charting._id)
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", {
                                        staticClass: "star",
                                        attrs: {
                                          name: "star",
                                          color: charting.starred
                                            ? "gold"
                                            : "white",
                                          scale: "1.6"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]),
                            _c(
                              "span",
                              { staticClass: "right-icons novideodrop" },
                              [
                                _vm.parentComponent !== "canvas-editor" &&
                                _vm.showAddendum(charting) &&
                                _vm.isOnSameClinicWithInvoice
                                  ? _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "acl",
                                            rawName: "v-acl",
                                            value: "consultation_view_note",
                                            expression:
                                              "'consultation_view_note'"
                                          }
                                        ],
                                        on: {
                                          click: function($event) {
                                            return _vm.onClickAddendum(charting)
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "addendum-count rounded-circle"
                                          },
                                          [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  (charting.addendums || [])
                                                    .length > 0
                                                    ? charting.addendums.length
                                                    : ""
                                                ) +
                                                "\n                    "
                                            )
                                          ]
                                        ),
                                        _c("v-icon", {
                                          staticClass: "paperclip",
                                          attrs: {
                                            name: "paperclip",
                                            scale: "1.5"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.parentComponent !== "canvas-editor"
                                  ? _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "acl",
                                            rawName: "v-acl",
                                            value: "consultation_view_note",
                                            expression:
                                              "'consultation_view_note'"
                                          }
                                        ],
                                        on: {
                                          click: function($event) {
                                            return _vm.viewCharting(charting)
                                          }
                                        }
                                      },
                                      [
                                        _c("v-icon", {
                                          staticClass: "search",
                                          attrs: {
                                            name: "search",
                                            scale: "1.5"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                !_vm.notesLock(charting) &&
                                _vm.isOnSameClinicWithInvoice &&
                                _vm.isEditable(charting)
                                  ? _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "acl",
                                            rawName: "v-acl",
                                            value: "consultation_edit_note",
                                            expression:
                                              "'consultation_edit_note'"
                                          }
                                        ],
                                        class: {
                                          "disabled-icon": !_vm.checkPermissions(
                                            charting,
                                            "update"
                                          )
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.editCharting(
                                              charting,
                                              "maximized"
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("v-icon", {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip.hover.top",
                                              value: _vm.getChartingPermissionsTooltip(
                                                charting
                                              ),
                                              expression:
                                                "getChartingPermissionsTooltip(charting)",
                                              modifiers: {
                                                hover: true,
                                                top: true
                                              }
                                            }
                                          ],
                                          staticClass: "edit",
                                          attrs: { name: "edit", scale: "1.6" }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.parentComponent !== "canvas-editor"
                                  ? _c(
                                      "b-dropdown",
                                      {
                                        staticClass: "more-icons",
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "button-content",
                                              fn: function() {
                                                return [
                                                  _c("v-icon", {
                                                    staticClass: "more-actions",
                                                    attrs: {
                                                      name: "ellipsis-h",
                                                      scale: "1.3"
                                                    }
                                                  })
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _vm.aclHasAccess(
                                          "consultation_print_note"
                                        ) && _vm.featureFlags.printTPH
                                          ? _c(
                                              "b-dropdown-item",
                                              {
                                                staticClass: "print-dropdown",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.prePrintNote(
                                                      charting
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.$t("general.printTPH")
                                                    ) +
                                                    "\n                      "
                                                ),
                                                _c("v-icon", {
                                                  attrs: {
                                                    name: "print",
                                                    scale: "0.9"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.aclHasAccess(
                                          "consultation_print_note"
                                        )
                                          ? _c(
                                              "b-dropdown-item",
                                              {
                                                staticClass: "print-dropdown",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.prePrintNote(
                                                      charting,
                                                      true
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.$t("general.print")
                                                    ) +
                                                    "\n                      "
                                                ),
                                                _c("v-icon", {
                                                  attrs: {
                                                    name: "print",
                                                    scale: "0.9"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.aclHasAccess(
                                          "consultation_download_pdf"
                                        )
                                          ? _c(
                                              "b-dropdown-item",
                                              {
                                                on: {
                                                  click: function($event) {
                                                    return _vm.downloadNoteAsPDF(
                                                      charting
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "general.noteDownloadPDF"
                                                      )
                                                    ) +
                                                    "\n                    "
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.aclHasAccess(
                                          "consultation_split_note"
                                        ) &&
                                        _vm.notesMapped[charting._id]
                                          .otherPagesId &&
                                        _vm.notesMapped[charting._id]
                                          .otherPagesId.length > 0 &&
                                        _vm.isOnSameClinicWithInvoice
                                          ? _c(
                                              "b-dropdown-item",
                                              {
                                                on: {
                                                  click: function($event) {
                                                    _vm.selectedNotes.push(
                                                      charting._id
                                                    )
                                                    _vm.showSplitCaseNoteModal()
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "general.noteSplit"
                                                      )
                                                    ) +
                                                    "\n                    "
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.aclHasAccess(
                                          "consultation_duplicate_note"
                                        ) && _vm.isOnSameClinicWithInvoice
                                          ? _c("b-dropdown-item", [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "general.noteDuplicate"
                                                    )
                                                  ) +
                                                  "\n                    "
                                              )
                                            ])
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "charting-card-body",
                            style: _vm.chartingCardBodyStyle,
                            on: {
                              click: function($event) {
                                return _vm.selectNotesForAction(
                                  charting._id,
                                  index
                                )
                              }
                            }
                          },
                          [
                            _c("img", {
                              style:
                                charting.otherPagesId &&
                                charting.otherPagesId.length > 0 &&
                                _vm.imageStyle,
                              attrs: {
                                src: charting.signedThumbnailKey,
                                alt: "",
                                id: "charting-" + charting._id
                              }
                            }),
                            _c(
                              "div",
                              { staticClass: "selectionnum-container" },
                              [
                                _c("p", { staticClass: "selectionnum" }, [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.selectedNotes.findIndex(function(
                                          id
                                        ) {
                                          return id == charting._id
                                        }) + 1
                                      ) +
                                      "\n                  "
                                  )
                                ])
                              ]
                            ),
                            _c(
                              "span",
                              { staticClass: "info-icon" },
                              [
                                _c("v-icon", {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.bottom",
                                      value: function() {
                                        return _vm.getChartingMetadataTooltip(
                                          charting
                                        )
                                      },
                                      expression:
                                        "() => getChartingMetadataTooltip(charting)",
                                      modifiers: { hover: true, bottom: true }
                                    }
                                  ],
                                  attrs: { name: "info-circle", scale: "1.5" }
                                })
                              ],
                              1
                            )
                          ]
                        ),
                        _vm.isInLargeView &&
                        _vm.enabledAddendum &&
                        charting.addendums &&
                        charting.addendums.length > 0
                          ? _c(
                              "div",
                              { staticClass: "addendum-container" },
                              [
                                _c("h5", [_vm._v("Addendum Added")]),
                                _vm._l(charting.addendums || [], function(
                                  addm
                                ) {
                                  return _c(
                                    "div",
                                    { key: addm._id, staticClass: "addendum" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "mb-1" },
                                        _vm._l(
                                          _vm.breakHtmlParagraph(addm.text),
                                          function(addmp, index) {
                                            return _c("p", { key: index }, [
                                              _c(
                                                "span",
                                                {
                                                  class: {
                                                    blank: addmp === "&nbsp;"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                          " +
                                                      _vm._s(addmp) +
                                                      "\n                        "
                                                  )
                                                ]
                                              )
                                            ])
                                          }
                                        ),
                                        0
                                      ),
                                      _c("v-icon", {
                                        staticClass: "addm-icon",
                                        attrs: { name: "user", scale: "0.8" }
                                      }),
                                      _c("span", { staticClass: "mt-1" }, [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(addm.username) +
                                            "\n                    "
                                        )
                                      ]),
                                      _vm._v("  \n                    "),
                                      _c("v-icon", {
                                        staticClass: "addm-icon",
                                        attrs: {
                                          name: "calendar-alt",
                                          scale: "0.8"
                                        }
                                      }),
                                      _c("span", [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(
                                              _vm._f(
                                                "formatDateTime_DDdMMMYYYYFromX"
                                              )(addm.date)
                                            ) +
                                            "\n                    "
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                })
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm.showAddendumInput &&
                        charting._id === _vm.selectedNoteForAddendum
                          ? _c("div", { staticClass: "addendum-input-field" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _c("label", [_vm._v("Add Addendum")]),
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.addendumDataMap[charting._id],
                                      expression:
                                        "addendumDataMap[charting._id]"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  domProps: {
                                    value: _vm.addendumDataMap[charting._id]
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.addendumDataMap,
                                        charting._id,
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]),
                              _c("div", { staticClass: "form-group" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary",
                                    attrs: {
                                      disabled:
                                        !_vm.addendumDataMap[charting._id] ||
                                        (_vm.addendumDataMap[charting._id] &&
                                          !_vm.addendumDataMap[
                                            charting._id
                                          ].trim())
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.handleAddAddendum(charting)
                                      }
                                    }
                                  },
                                  [_vm._v("Save")]
                                ),
                                _vm._v(" \n                  "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary",
                                    on: {
                                      click: function($event) {
                                        return _vm.closeAddendum(charting)
                                      }
                                    }
                                  },
                                  [_vm._v("Cancel")]
                                )
                              ])
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "charting-footer mt-1" }, [
                          _c("div", { staticClass: "name" }, [
                            _c("span", { staticClass: "ellipses" }, [
                              _vm._v(_vm._s(charting.name))
                            ])
                          ]),
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatVDDate")(charting.createdAt, {
                                  format: "DD MMM YYYY, h:mm:ssa"
                                })
                              )
                            )
                          ]),
                          charting.taggedTab || charting.otId
                            ? _c("small", { staticClass: "text-muted" }, [
                                _vm._v(
                                  "\n                  (Tagged\n                  "
                                ),
                                charting.otId
                                  ? _c("span", [
                                      _vm._v(
                                        " to " +
                                          _vm._s(
                                            _vm.otMappedIdToName[charting.otId]
                                          )
                                      )
                                    ])
                                  : _vm._e(),
                                charting.taggedTab
                                  ? _c("span", [
                                      _vm._v(
                                        " under " +
                                          _vm._s(
                                            _vm._f("translateTabName")(
                                              charting.taggedTab
                                            )
                                          )
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(")\n                ")
                              ])
                            : _vm._e()
                        ])
                      ]
                    )
                  }),
                  0
                )
              ],
              1
            )
          }
        ),
        0
      ),
      _c(
        "b-modal",
        {
          ref: "casenotes-action-modal",
          attrs: { size: "md", title: _vm.actionTitle, "hide-footer": "" }
        },
        [
          _c("p", [
            _vm._v(
              "\n            Please confirm the following action:\n        "
            )
          ]),
          _c("p", { staticClass: "font-weight-bold" }, [
            _vm._v("Deletion of:")
          ]),
          _c(
            "ul",
            _vm._l(_vm.selectedNotes, function(id) {
              return _c("li", { key: id }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.notesMapped[id].name) +
                    "\n                (" +
                    _vm._s(_vm.notesMapped[id].otherPagesId.length + 1) +
                    " pages)\n            "
                )
              ])
            }),
            0
          ),
          _c(
            "b-button",
            {
              staticClass: "float-right my-3",
              attrs: { variant: "outline-primary" },
              on: { click: _vm.onConfirmDelete }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("pharmacy.confirm")) +
                  "\n        "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "casenote-split-modal",
          attrs: { size: "md", title: "Split", "hide-footer": "" }
        },
        [
          _c(
            "b-form-group",
            {
              staticClass: "mt-2",
              attrs: {
                label:
                  "How do you want to arrange the resulting pages of the split note?"
              }
            },
            [
              _c(
                "b-form-radio-group",
                {
                  staticClass: "mt-4",
                  attrs: {
                    id: "radio-group-2",
                    name: "split-radio-sub-component"
                  },
                  model: {
                    value: _vm.splittingOrder,
                    callback: function($$v) {
                      _vm.splittingOrder = $$v
                    },
                    expression: "splittingOrder"
                  }
                },
                [
                  _c("b-form-radio", { attrs: { value: "ascending" } }, [
                    _vm._v("Ascending order (page 1, 2, 3, ..., x)")
                  ]),
                  _c("b-form-radio", { attrs: { value: "descending" } }, [
                    _vm._v("Descending order (page x, ..., 3, 2, 1)")
                  ])
                ],
                1
              ),
              _c(
                "b-form-checkbox",
                {
                  staticClass: "mt-5",
                  attrs: { name: "split-last-remembered-checkbox" },
                  model: {
                    value: _vm.shouldRememberSplittingOrder,
                    callback: function($$v) {
                      _vm.shouldRememberSplittingOrder = $$v
                    },
                    expression: "shouldRememberSplittingOrder"
                  }
                },
                [
                  _c("div", [
                    _vm._v("Remember this splitting arrangement on this device")
                  ]),
                  _vm.lastRememberedSplittingOrder
                    ? _c("div", [
                        _vm._v("(current remembered order: "),
                        _c("strong", [
                          _vm._v(_vm._s(_vm.lastRememberedSplittingOrder))
                        ]),
                        _vm._v(")")
                      ])
                    : _vm._e()
                ]
              )
            ],
            1
          ),
          _c(
            "b-button-group",
            { staticClass: "float-right" },
            [
              _c(
                "b-button",
                {
                  staticClass: "mx-1",
                  attrs: { variant: "outline-primary" },
                  on: { click: _vm.splitSelectedCaseNote }
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("pharmacy.confirm")) +
                      "\n        "
                  )
                ]
              ),
              _c(
                "b-button",
                {
                  staticClass: "mx-1",
                  attrs: { variant: "outline-secondary" },
                  on: {
                    click: function() {
                      return _vm.hideCaseNoteActionModal("casenote-split-modal")
                    }
                  }
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("general.cancel")) +
                      "\n        "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "casenotes-move-modal",
          attrs: { size: "md", title: "Bulk Move", "hide-footer": "" }
        },
        [
          _c(
            "b-form-group",
            {
              staticClass: "mt-3",
              attrs: {
                label:
                  "Which category do you want to bulk move the selected note(s) to?"
              }
            },
            [
              _c("div", [
                _vm._v("(current category:\n          "),
                _vm.originateFromSameCategory.length == 1
                  ? _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.categoriesMapped[_vm.originateFromSameCategory[0]]
                            .name
                        )
                      )
                    ])
                  : _c("span", [_c("i", [_vm._v("multiple values")])]),
                _vm._v(")\n        ")
              ]),
              _c(
                "b-list-group",
                { staticClass: "mt-3" },
                _vm._l(_vm.filteredCategoryIds, function(catId, index) {
                  return _c(
                    "b-list-group-item",
                    {
                      directives: [
                        {
                          name: "acl",
                          rawName: "v-acl:disabled.isDynamic",
                          value: catId,
                          expression: "catId",
                          arg: "disabled",
                          modifiers: { isDynamic: true }
                        }
                      ],
                      key: index,
                      attrs: {
                        active: _vm.selectedMoveDestination == catId,
                        disabled:
                          _vm.originateFromSameCategory.length == 1 &&
                          _vm.originateFromSameCategory[0] == catId
                      },
                      on: {
                        click: function($event) {
                          _vm.selectedMoveDestination = catId
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.categoriesMapped[catId].name) +
                          "\n          "
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "b-button-group",
            { staticClass: "float-right mt-3" },
            [
              _c(
                "b-button",
                {
                  staticClass: "mx-2",
                  attrs: { variant: "outline-secondary" },
                  on: {
                    click: function() {
                      return _vm.hideCaseNoteActionModal("casenotes-move-modal")
                    }
                  }
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("general.cancel")) +
                      "\n        "
                  )
                ]
              ),
              _c(
                "b-button",
                {
                  staticClass: "mx-2",
                  attrs: {
                    variant: "outline-primary",
                    disabled: !_vm.selectedMoveDestination
                  },
                  on: { click: _vm.moveSelectedCaseNotes }
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("pharmacy.confirm")) +
                      "\n        "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "upload-manager",
        {
          attrs: {
            showUploadManager: _vm.showUploadManager,
            closeUploadManager: _vm.closeUploadManager,
            files: _vm.filesToBeUploaded,
            uploadFiles: _vm.submitFiles,
            updateUploadLineItem: _vm.updateUploadLineItem,
            categoriesOptions: _vm.categoriesOptions,
            uploading: _vm.uploading,
            extraValidation: _vm.selectedInvoice !== null
          }
        },
        [
          _c("template", { slot: "extra" }, [
            _c("div", { staticClass: "form-group row" }, [
              _c(
                "label",
                {
                  staticClass: "col-sm-3 col-form-label",
                  attrs: { for: "staticEmail" }
                },
                [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.$t("general.visitDate")) +
                      "\n            "
                  )
                ]
              ),
              _c(
                "div",
                { staticClass: "col-sm-9" },
                [
                  _c("b-form-select", {
                    staticClass: "mb-3",
                    attrs: { id: "staticEmail" },
                    scopedSlots: _vm._u([
                      {
                        key: "first",
                        fn: function() {
                          return [
                            _c(
                              "option",
                              {
                                attrs: {
                                  "data-automation": "visit-date-select-default"
                                },
                                domProps: { value: null }
                              },
                              [_vm._v("-- Select Visit Date --")]
                            ),
                            _vm._l(_vm.invoiceOptions, function(
                              invoice,
                              indexOpt
                            ) {
                              return _c(
                                "option",
                                {
                                  key: indexOpt,
                                  attrs: {
                                    "data-automation":
                                      "invoice-options-" + indexOpt
                                  },
                                  domProps: { value: invoice.value }
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.formatVDDate(
                                          new Date(invoice.text),
                                          {
                                            format: "DD MMM YYYY",
                                            lang: _vm.$i18n.locale
                                          }
                                        )
                                      ) +
                                      " -- " +
                                      _vm._s(
                                        _vm.CLINIC_MAP[invoice.value.clinic]
                                          .name
                                      ) +
                                      "\n                  "
                                  )
                                ]
                              )
                            })
                          ]
                        },
                        proxy: true
                      }
                    ]),
                    model: {
                      value: _vm.selectedInvoice,
                      callback: function($$v) {
                        _vm.selectedInvoice = $$v
                      },
                      expression: "selectedInvoice"
                    }
                  })
                ],
                1
              )
            ])
          ])
        ],
        2
      ),
      _c(
        "b-modal",
        {
          ref: "tag-notes-modal",
          attrs: {
            id: "tagging-modal-" + (_vm.mainForm ? _vm.mainForm._id : ""),
            title: _vm.tagModalTitle
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: { click: _vm.hideTagModal }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("general.cancel")) +
                          "\n        "
                      )
                    ]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                      on: { click: _vm.handleTagModalOk }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("general.ok")) +
                          "\n        "
                      )
                    ]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm.tagParams.action === "Tag"
            ? _c(
                "template",
                { slot: "default" },
                [
                  _c(
                    "b-form-group",
                    [
                      _c("b-form-select", {
                        attrs: { options: _vm.tagOptions.tabs },
                        model: {
                          value: _vm.tagParams.tab,
                          callback: function($$v) {
                            _vm.$set(_vm.tagParams, "tab", $$v)
                          },
                          expression: "tagParams.tab"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    [
                      _c("b-form-select", {
                        attrs: { options: _vm.otOptions },
                        model: {
                          value: _vm.tagParams.ot,
                          callback: function($$v) {
                            _vm.$set(_vm.tagParams, "ot", $$v)
                          },
                          expression: "tagParams.ot"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        2
      ),
      _c(
        "b-modal",
        {
          staticClass: "bv-modal-example",
          attrs: {
            "header-class": "label",
            "hide-footer": _vm.hideFooter,
            title: _vm.$t("general.selectChartingTemplate"),
            size: "xl"
          },
          model: {
            value: _vm.showTemplateSelector,
            callback: function($$v) {
              _vm.showTemplateSelector = $$v
            },
            expression: "showTemplateSelector"
          }
        },
        [
          _c(
            "template",
            { slot: "default" },
            [
              _c("div", { staticClass: "form-group row" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-3 col-form-label",
                    attrs: { for: "staticEmail" }
                  },
                  [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("general.visitDate")) +
                        "\n            "
                    )
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "col-sm-9" },
                  [
                    _c("b-form-select", {
                      staticClass: "mb-3",
                      attrs: { id: "staticEmail" },
                      scopedSlots: _vm._u([
                        {
                          key: "first",
                          fn: function() {
                            return [
                              _c(
                                "option",
                                {
                                  attrs: {
                                    "data-automation":
                                      "visit-date-default-select"
                                  },
                                  domProps: { value: null }
                                },
                                [_vm._v("-- Select Visit Date --")]
                              ),
                              _vm._l(_vm.invoiceOptions, function(
                                invoice,
                                indexOpt
                              ) {
                                return _c(
                                  "option",
                                  {
                                    key: indexOpt,
                                    attrs: {
                                      "data-automation":
                                        "invoice-options-" + indexOpt
                                    },
                                    domProps: { value: invoice.value }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.$d(
                                            new Date(invoice.text),
                                            "short",
                                            _vm.$i18n.locale
                                          )
                                        ) +
                                        " -- " +
                                        _vm._s(
                                          _vm.CLINIC_MAP[invoice.value.clinic]
                                            .name
                                        ) +
                                        "\n                  "
                                    )
                                  ]
                                )
                              })
                            ]
                          },
                          proxy: true
                        }
                      ]),
                      model: {
                        value: _vm.selectedInvoice,
                        callback: function($$v) {
                          _vm.selectedInvoice = $$v
                        },
                        expression: "selectedInvoice"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("templates-container", {
                attrs: {
                  topRightIconFunction: _vm.submitNoteTemplate,
                  topRightIcon: "brush",
                  parentComponent: "consult-note-container",
                  extraValidation: _vm.selectedInvoice !== null
                }
              })
            ],
            1
          )
        ],
        2
      ),
      _c(
        "b-modal",
        {
          ref: "no-visit-prompt-modal",
          attrs: {
            "hide-footer": _vm.hideFooter,
            title: _vm.$t("general.nopatientvisittitle")
          }
        },
        [
          _c("template", { slot: "default" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("general.nopatientvisits")) +
                "\n        " +
                _vm._s(_vm.invoiceFetchReady) +
                "\n      "
            )
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }