import _ from "lodash";
export default {
  templateOptions: [
    { value: "T1", text: "福安模版" },
    { value: "T2", text: "特製模版" }
  ],
  readonlyTemplateData: {
    patientName: { label: "patientName", key: "name" },
    patientId: { label: "patientId", key: "givenId" },
    dateOfBirth: { label: "dateOfBirth", key: "dateOfBirth" },
    departmentName: { label: "departmentName", key: "departmentName" },
    occupation: { label: "occupation", key: "occupation" },
    jobPosition: { label: "jobPosition", key: "jobPosition" },
    seniority: { label: "seniority", key: "seniority" }
  },
  normalTemplateData: {
    reason: { label: "reason", answer: "", rows: 5 },
    type: { label: "type", answer: "", rows: 5 },
    content: { label: "content", answer: "", rows: 5 },
    advice: { label: "advice", answer: "", rows: 5 },
    medicalHistory: { label: "medicalHistory", answer: "", rows: 5 },
    foodAllergyHistory: { label: "foodAllergyHistory", answer: "", rows: 5 },
    drugAllergyHistory: { label: "drugAllergyHistory", answer: "", rows: 5 },
    educationFood: { label: "educationFood", answer: [], rows: 5 },
    educationDisease: { label: "educationDisease", answer: [], rows: 5 },
    educationHabit: { label: "educationHabit", answer: [], rows: 5 },
    educationSpecial: { label: "educationSpecial", answer: [], rows: 5 },
    reasonNote: { label: "reasonNote", answer: "", rows: 5 },
    typeNote: { label: "typeNote", answer: "", rows: 5 },
    educationFoodNote: { label: "educationFoodNote", answer: "", rows: 5 },
    educationDiseaseNote: {
      label: "educationDiseaseNote",
      answer: "",
      rows: 5
    },
    educationHabitNote: { label: "educationHabitNote", answer: "", rows: 5 },
    educationSpecialNote: { label: "educationSpecialNote", answer: "", rows: 5 }
  },
  fixTemplateData: {
    startConsultTime: { label: "startConsultTime", answer: "", rows: 5 },
    endConsultTime: { label: "endConsultTime", answer: "", rows: 5 },
    track: { label: "track", answer: "", rows: 5 },
    trackDate: { label: "trackDate", answer: "", rows: 5 },
    seniority: { label: "seniority", answer: "", rows: 5 }
  },
  templateFormDataOrder: [
    "reason",
    "type",
    "content",
    "advice",
    "medicalHistory",
    "foodAllergyHistory",
    "drugAllergyHistory",
    "educationFood",
    "educationFoodNote",
    "educationDiseaseNote",
    "educationHabitNote",
    "educationSpecialNote",
    "seniority"
  ],
  normalTemplateDataOption: {
    reason: [
      { value: "", text: "請選擇" },
      { value: "R1", text: "新進員工到職健康教育" },
      { value: "R2", text: "健康管理計畫衛教執行" },
      { value: "R3", text: "復工後適工性評估訪談" },
      { value: "R4", text: "年度健檢後醫療建議" },
      { value: "R5", text: "健康行為輔導矯正衛教" },
      { value: "R6", text: "防護具使用宣導衛教" },
      { value: "R7", text: "傷病後健康關懷衛教" },
      { value: "other", text: "其他" }
    ],
    type: [
      { value: "", text: "請選擇" },
      { value: "T1", text: "面談" },
      { value: "T2", text: "電話訪問" },
      { value: "T3", text: "電子信箱" },
      { value: "other", text: "其他" }
    ],
    educationFood: [
      { value: "F1", text: "低膽固醇飲食" },
      { value: "F2", text: "低熱量飲食" },
      { value: "F3", text: "痛風飲食" },
      { value: "other", text: "其他" }
    ],
    educationDisease: [
      { value: "D1", text: "高血壓" },
      { value: "D2", text: "高血脂" },
      { value: "D3", text: "糖尿病" },
      { value: "D4", text: "肝炎" },
      { value: "other", text: "其他" }
    ],
    educationHabit: [
      { value: "H1", text: "戒檳手冊" },
      { value: "H2", text: "戒菸手冊" },
      { value: "H3", text: "減重手冊" },
      { value: "other", text: "其他" }
    ],
    educationSpecial: [
      { value: "S1", text: "聽力保護" },
      { value: "S2", text: "如何正確佩帶噪音防護具" },
      { value: "other", text: "其他" }
    ],
    track: [
      { value: "close", text: "結案" },
      { value: "track", text: "追蹤" }
    ]
  },
  templateSettings: {
    T1: {
      patientName: true,
      patientId: true,
      dateOfBirth: true,
      departmentName: true,
      occupation: true,
      jobPosition: true,
      seniority: true,
      medicalHistory: true,
      foodAllergyHistory: true,
      drugAllergyHistory: true,
      reason: true,
      type: true,
      content: true,
      advice: true,
      educationFood: true,
      educationDisease: true,
      educationHabit: true,
      educationSpecial: true,
      trackDate: true,
      seniority: true
    },
    T2: {
      patientName: true,
      patientId: true,
      dateOfBirth: true,
      departmentName: true,
      occupation: true,
      jobPosition: true,
      seniority: false,
      medicalHistory: true,
      foodAllergyHistory: true,
      drugAllergyHistory: true,
      reason: true,
      type: true,
      content: true,
      advice: false,
      educationFood: true,
      educationDisease: true,
      educationHabit: true,
      educationSpecial: true,
      trackDate: true,
      seniority: true
    }
  },
  getTemplateName(templateId) {
    let name = "";

    this.templateOptions.forEach(option => {
      if (option.value == templateId) {
        name = option.text;
      }
    });

    return name;
  },
  hasTemplateId(templateId) {
    let isExist = false;

    this.templateOptions.forEach(option => {
      if (option.value == templateId) {
        isExist = true;
      }
    });

    return isExist;
  },
  getInitFormData(templateId) {
    let formData = {};
    if (this.hasTemplateId(templateId)) {
      Object.keys(this.normalTemplateData).forEach(field => {
        if (this.templateSettings[templateId][field]) {
          formData[field] = JSON.parse(
            JSON.stringify(this.normalTemplateData[field])
          );
          if (
            this.normalTemplateDataOption[field] &&
            this.normalTemplateDataOption[field].filter(
              option => option.value == "other"
            ).length > 0
          ) {
            formData[`${field}Note`] = JSON.parse(
              JSON.stringify(this.normalTemplateData[`${field}Note`])
            );
          }
        }
      });
      Object.keys(this.fixTemplateData).forEach(field => {
        formData[field] = JSON.parse(
          JSON.stringify(this.fixTemplateData[field])
        );
      });
    }

    return formData;
  },
  getInitFormDataOrder(templateId) {
    let formDataOrder = [];
    if (this.hasTemplateId(templateId)) {
      formDataOrder = this.templateFormDataOrder.filter(
        field => this.templateSettings[templateId][field]
      );
      formDataOrder = formDataOrder.concat(Object.keys(this.fixTemplateData));
    }

    return formDataOrder;
  },
  getReadonlyFormData(templateId) {
    let formDataList = [];

    if (this.hasTemplateId(templateId)) {
      Object.keys(this.readonlyTemplateData).forEach(field => {
        if (this.templateSettings[templateId][field]) {
          formDataList.push(
            JSON.parse(JSON.stringify(this.readonlyTemplateData[field]))
          );
        }
      });
    }

    return formDataList;
  },
  getFormDataOptions(field) {
    let options = [];
    if (Object.keys(this.normalTemplateDataOption).includes(field)) {
      options = JSON.parse(
        JSON.stringify(this.normalTemplateDataOption[field])
      );
    }
    return options;
  },
  getEducationTypeList() {
    return [
      "educationFood",
      "educationDisease",
      "educationHabit",
      "educationSpecial"
    ];
  },
  reportDisplayFuncton: {
    betelNutAnswerDispaly(data) {
      if (data && data.items) {
        //fuan display
        const findBeteNutItem = data.items.find(
          item => item.questionLabel === "betelNut"
        );
        if (!_.isUndefined(findBeteNutItem)) {
          const answer = findBeteNutItem.answers[0].answer[0];
          const answerValues = findBeteNutItem.answers[0].answerValues[0];
          if (answer === "已戒") {
            return `${answer},${answerValues}年`;
          } else if (answer === "有") {
            return `${answer},${answerValues}顆/天`;
          } else if (answer === "無") {
            return answer;
          }
        }
      }

      return "";
    },
    alcoholAnswerDisplay(data) {
      if (data && data.items) {
        //fuan display
        const item = data.items.find(item => item.questionLabel === "alcohol");
        if (!_.isUndefined(item)) {
          const answer = item.answers[0].answer[0];
          const answerValues = item.answers[0].answerValues[0];
          if (answer === "已戒") {
            return `${answer},${answerValues}年`;
          } else if (answer === "有") {
            if (answerValues === "Greater") {
              return `${answer},大於10杯/天`;
            }
            return `${answer},${answerValues}杯/天`;
          } else if (answer === "無") {
            return answer;
          }
        }
      }
      return "";
    },
    travelHistoryDisplay(data) {
      if (data && data.items) {
        //fuan display
        const item = data.items.find(
          item => item.questionLabel === "travelHistory"
        );
        if (!_.isUndefined(item)) {
          const answer = item.answers[0].answer[0];
          const answerValues = item.answers[0].answerValues;
          if (answer === "有") {
            return `${answer},國家:${answerValues[0]},起始日期:${answerValues[1]},結束日期:${answerValues[2]}`;
          } else if (answer === "無") {
            return answer;
          }
        }
      }
      return "";
    },
    allergyHistoryDisplay(data) {
      if (data && data.items) {
        //fuan display
        const item = data.items.find(
          item => item.questionLabel === "allergyHistory"
        );
        if (!_.isUndefined(item)) {
          const answers = item.answers;
          let answersArray = [];
          answers.forEach(answer => {
            if (answer.answer[0] === "無") {
              answersArray.push(answer.answer[0]);
            } else {
              const answerValues = answer.answerValues.filter(
                value => value != answer.answer[0]
              );
              answersArray.push(`${answer.answer[0]}:${answerValues}`);
            }
          });

          if (answersArray.length > 0) {
            return answersArray.join(",");
          }
        }
      }
      return "";
    },
    nmqDisplay(data) {
      const keyArray = [
        "q1",
        "q2",
        "q3",
        "q4",
        "neck",
        "leftShoulder",
        "rightShoulder",
        "upperBack",
        "lowerBack",
        "leftArm",
        "rightArm",
        "leftWrist",
        "rightWrist",
        "leftTight",
        "rightTight",
        "leftKnee",
        "rightKnee",
        "leftAnkle",
        "rightAnkle",
        "other",
        "description"
      ];
      let returnObj = {};
      keyArray.forEach(key => {
        if (_.has(data, key)) {
          returnObj = {
            ...returnObj,
            [key]: _.isEmpty(data[key]) ? "無不適" : data[key]
          };
        }
      });

      return returnObj;
    }
  }
};