import packagesService from '@/services/packages.service';

import {
  PACKAGE_COUNT,
} from './type.mutation';

const state = {
  packageCount: 0,
};

/* eslint-disable no-param-reassign */
const mutations = {
  [PACKAGE_COUNT](_state, payload) {
    _state.packageCount = payload;
  },
};
const actions = {
  fetchPackage({ commit }, { patientId, clinicCode }) {
    return packagesService.list({ patientId, clinicCode })
      .then((response) => {
        if (response.success) {
          return response.data;
        }
      });
  },
  fetchProviderPackage({ commit }, { providerId, clinicCode }) {
    return packagesService.listProviderPackage({ providerId, clinicCode })
      .then((response) => {
        if (response.success) {
          return response.data;
        }
      });
  },
  signRedeemPreProduct({ commit }, {
    id, item, data,
  }) {
    return packagesService.signRedeemPreProduct(id, item, data)
      .then(response => response.data);
  },
  redeemPreProduct({ commit }, {
    id, item, name, inventoryId,
  }) {
    return packagesService.redeemPreProduct(id, item, name, inventoryId)
      .then(response => response.data);
  },
  undoPreRedeem({ commit }, { id, item, inventoryId }) {
    return packagesService
      .undoPreRedeem(id, item, inventoryId)
      .then(resp => resp.data);
  },
  fetchPackageItems({ commit }, { patientId, clinicCode }) {
    return packagesService.itemList({ patientId, clinicCode })
      .then((response) => {
        if (response.success) {
          return response.data;
        }
      });
  },
  fetchProviderPackageItems({ commit }, { providerId, clinicCode }) {
    return packagesService.itemListProvider({ providerId, clinicCode })
      .then((response) => {
        if (response.success) {
          return response.data;
        }
      });
  },
  redeemItemsProduct({ commit }, {
    items, patient, name, invoiceId, userId,
  }) {
    return packagesService.redeemItemsProduct(items, patient, name, invoiceId, userId)
      .then(response => response.data);
  },
  redeemItemsProductProvider({ commit }, {
    items, provider, name, invoiceId, userId,
  }) {
    return packagesService.redeemItemsProductProvider(items, provider, name, invoiceId, userId)
      .then(response => response.data);
  },
  transferItemsProduct({ commit }, {
    items, patient, toPatientId, name, clinic,
  }) {
    return packagesService.transferItemsProduct(items, patient, toPatientId, name, clinic)
      .then(response => response.data);
  },
  transferItemsProductProvider({ commit }, {
    items, provider, toProviderId, name, clinic,
  }) {
    return packagesService.transferItemsProductProvider(items, provider, toProviderId, name, clinic)
      .then(response => response.data);
  },
  fetchPackageCount({ commit }, { patientId, clinicCode }) {
    packagesService.count({ patientId, clinicCode })
      .then((response) => {
        if (response.success) {
          commit(PACKAGE_COUNT, response.data);
          return response.data;
        }
        commit(PACKAGE_COUNT, 0);
        return 0;
      });
  },
  fetchPackageCountProvider({ commit }, { providerId, clinicCode }) {
    packagesService.countByProvider({ providerId, clinicCode })
      .then((response) => {
        if (response.success) {
          commit(PACKAGE_COUNT, response.data);
          return response.data;
        }
        commit(PACKAGE_COUNT, 0);
        return 0;
      });
  },
  redeemInvoiceItemsProduct({ commit }, {
    items, patient, name, invoiceId, clinic,
  }) {
    return packagesService.redeemInvoiceItemsProduct(items, patient, name, invoiceId, clinic)
      .then(response => response.data);
  },
};

const getters = {
  packageCount: _state => _state.packageCount,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
