<template>
  <div class="editor">
    <editor-menu-bubble
      :editor="editor" v-slot="{ commands, isActive, menu }" id="editor-menu-bubble">
        <div
            class="menububble"
            :class="{ 'is-active': menu.isActive }"
            :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
        >

            <span
                class="menububble__button"
                :class="{ 'is-active': isActive.bold() }"
                @click="commands.bold"
            >
                <v-icon name="bold" />
            </span>

            <span
                class="menububble__button"
                :class="{ 'is-active': isActive.italic() }"
                @click="commands.italic"
            >
                <v-icon name="italic" />
            </span>

            <span
                class="menububble__button"
                :class="{ 'is-active': isActive.underline() }"
                @click="commands.underline"
            >
                <v-icon name="underline" />
            </span>
        </div>
    </editor-menu-bubble>
    <editor-content :editor="editor" />
  </div>
</template>

<script>
// Import the editor
import { Editor, EditorContent, EditorMenuBubble } from 'tiptap';
import {
  Bold,
  Italic,
  Underline,
} from 'tiptap-extensions';

export default {
  components: {
    EditorContent,
    EditorMenuBubble,
  },
  props: ['pos', 'html', 'updateMainFormData', 'editorBlur'],
  data() {
    return {
      keepInBounds: true,
      localHtml: null,
      editor: null,
      json: 'Update content to see changes',
      // html: 'Update content to see changes',
    };
  },
  created() {
    this.localHtml = this.html;
    this.editor = new Editor({
      editable: true,
      extensions: [
        new Bold(),
        new Italic(),
        new Underline(),
      ],
      content: this.localHtml,
      onUpdate: ({ /* getJSON, */ getHTML }) => {
        this.localHtml = getHTML();
        this.updateMainFormData(this.localHtml, this.pos);
      },
      onFocus: () => {
      },
      onBlur: () => {
      },
    });
  },
  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>

<style lang="scss" scoped>

$color-white: #ffffff;
$color-black: #000000;

.menububble {
  position: absolute;
  display: flex;
  z-index: 20;
  background: $color-black;
  border-radius: 5px;
  padding: 0.3rem;
  margin-bottom: 0.5rem;
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s, visibility 0.2s;

  &.is-active {
    opacity: 1;
    visibility: visible;
  }

  &__button {
    display: inline-flex;
    background: transparent;
    border: 0;
    color: $color-white;
    padding: 0.2rem 0.5rem;
    margin-right: 0.2rem;
    border-radius: 3px;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      background-color: rgba($color-white, 0.1);
    }

    &.is-active {
      background-color: rgba($color-white, 0.2);
    }
  }

  &__form {
    display: flex;
    align-items: center;
  }

  &__input {
    font: inherit;
    border: none;
    background: transparent;
    color: $color-white;
  }
}
</style>
