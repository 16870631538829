var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "print-container mt-3" }, [
    _vm._m(0),
    _c("div", { staticClass: "row mb-5" }, [
      _c("div", { staticClass: "col-9" }, [
        _c("div", { staticClass: "clinic-name font-weight-bold" }, [
          _vm._v(_vm._s(_vm.corpData.name))
        ]),
        _c("div", { staticClass: "clinic-address text-secondary" }, [
          _vm._v(_vm._s(_vm.corpData.address))
        ])
      ]),
      _c("div", { staticClass: "col-3" }, [
        _c("div", { staticClass: "tax-invoice-no" }, [
          _c("span", { staticClass: "font-weight-bold" }, [
            _vm._v("Tax Invoice No: ")
          ]),
          _c("span", [_vm._v(_vm._s(_vm.getInvNoRange()))])
        ]),
        _c("div", { staticClass: "visit date" }, [
          _c("span", { staticClass: "font-weight-bold" }, [
            _vm._v("Invoice Date: ")
          ]),
          _c("span", [_vm._v(_vm._s(_vm._f("ddMonthYYYY")(new Date())))])
        ])
      ])
    ]),
    _c("table", { staticClass: "table table-sm" }, [
      _vm._m(1),
      _c(
        "tbody",
        [
          _vm._l(_vm.corpPayments, function(cp) {
            return _c("tr", { key: cp._id }, [
              _c("td", { staticClass: "border" }, [
                _vm._v(_vm._s(_vm._f("ddMonthYYYYFromX")(cp.invoiceDate)))
              ]),
              _c("td", { staticClass: "border" }, [_vm._v(_vm._s(cp.invoice))]),
              _c("td", { staticClass: "border" }, [
                _vm._v(_vm._s(cp.patientName.toUpperCase()))
              ]),
              _c(
                "td",
                { staticClass: "border" },
                _vm._l(cp.invoiceItems, function(item) {
                  return _c("p", { key: item.givenId }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(item.name) +
                        "(" +
                        _vm._s(item.givenId) +
                        ")\n          "
                    )
                  ])
                }),
                0
              ),
              _c("td", { staticClass: "border" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.generalConfig.region.currency) +
                    _vm._s(
                      _vm._f("thousandSeparator")(
                        _vm._f("to2Decimal")(cp.corporateInvoice)
                      )
                    ) +
                    "\n        "
                )
              ])
            ])
          }),
          _c("tr", [
            _c("td", { staticClass: "no-border", attrs: { colspan: "3" } }),
            _c("td", { staticClass: "border bg-light" }, [_vm._v("Total")]),
            _c("td", { staticClass: "border bg-light" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.generalConfig.region.currency) +
                  _vm._s(
                    _vm._f("thousandSeparator")(
                      _vm._f("to2Decimal")(_vm.computeTotal())
                    )
                  ) +
                  "\n        "
              )
            ])
          ]),
          _c("tr", [
            _c("td", { staticClass: "no-border", attrs: { colspan: "3" } }, [
              _vm._v(" ")
            ]),
            _c("td", { staticClass: "border bg-light" }, [
              _vm._v("Outstanding")
            ]),
            _c("td", { staticClass: "border text-danger bg-light" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.generalConfig.region.currency) +
                  _vm._s(
                    _vm._f("thousandSeparator")(
                      _vm._f("to2Decimal")(_vm.computeTotalOutstanding())
                    )
                  ) +
                  "\n        "
              )
            ])
          ])
        ],
        2
      )
    ]),
    _c("footer", { staticClass: "footer mt-2 py-3" }, [
      _c("div", [
        _vm._m(2),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-10" }),
          _c("div", { staticClass: "col" }, [
            _c("div", [
              _c("img", {
                staticClass: "img-fluid float-right",
                attrs: { src: _vm.generalConfig.logoURL }
              })
            ])
          ])
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-8" }),
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "text-right" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.generalConfig.clinicName) +
                  "\n          "
              )
            ]),
            _c("div", { staticClass: "text-right" }, [
              _vm._v(" " + _vm._s(_vm.generalConfig.address) + " ")
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row mb-3" }, [
      _c("div", { staticClass: "col" }, [
        _c("h6", { staticClass: "bill-to" }, [_vm._v("Bill To / Address")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticClass: "thead-dark" }, [
      _c("tr", [
        _c("th", { staticClass: "border" }, [_vm._v("Visit Date")]),
        _c("th", { staticClass: "border" }, [_vm._v("Invoice No")]),
        _c("th", { staticClass: "border" }, [_vm._v("Patient")]),
        _c("th", { staticClass: "border" }, [_vm._v("Item")]),
        _c("th", { staticClass: "border" }, [_vm._v("Subtotal")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c("h6", [_vm._v("Remarks:")]),
        _c("p", { staticClass: "text-secondary" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }