import api from './api';


export default {
  getCategoryByPatientId(params) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);
    Object.keys(params).forEach((key) => {
      q.push(`${key}=${params[key]}`);
    });
    const url = `/note/categoryByPatientId?${q.join('&')}`;
    return api.get(url);
  },
  count(params) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);

    Object.keys(params).forEach((key) => {
      q.push(`${key}=${params[key]}`);
    });
    const url = `/note/count?${q.join('&')}`;
    return api.get(url);
  },
  get(params) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);

    Object.keys(params).forEach((key) => {
      q.push(`${key}=${params[key]}`);
    });
    const url = `/note/?${q.join('&')}`;
    return api.get(url);
  },
  getPaginated(params) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);

    Object.keys(params).forEach((key) => {
      q.push(`${key}=${params[key]}`);
    });

    const url = `/note/paginated/?${q.join('&')}`;
    console.log('url is ', url);
    return api.get(url);
  },
  fetchOne(params) {
    let url = `/note/${params.id}?keys=${params.keys}`;
    if (params.returnFullDoc) {
      url += `&returnFullDoc=${params.returnFullDoc}`;
    }
    return api.get(url);
  },
  fetchOtherPages(params) {
    let url = `/note/${params.id}?keys=${params.keys}`;
    if (params.returnFullDoc) {
      url += `&returnFullDoc=${params.returnFullDoc}`;
    }
    if (params.showOtherPages) {
      url += `&showOtherPages=${params.showOtherPages}`;
    }
    return api.get(url);
  },
  fetchAll() {
    return api.get('/note');
  },
  search(params) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);

    Object.keys(params).forEach((key) => {
      q.push(`${key}=${params[key]}`);
    });

    const url = `/note/search?${q.join('&')}`;
    return api.get(url);
  },
  createNew(data) {
    return api.post('/note', data);
  },
  updateNotes(data) {
    return api.put('/note', data);
  },
  updateNote(id, data) {
    return api.put(`/note/${id}`, data);
  },
  patchNotes(data) {
    return api.patch('/note', data);
  },
  patchNote(id, data) {
    return api.patch(`/note/${id}`, data);
  },
  patchNoteOrders(data) {
    return api.patch('/note/change-order', data);
  },
  splitNote(id, data) {
    return api.put(`/note/split/${id}`, data);
  },
  createChartingNote(id, data) { // data is { templateId, consultNoteId, info }
    return api.put(`/note/${id}/createCharting`, data);
  },
  // this method is for creating chartings not associated with mainforms
  createChartingNoteWithoutMainform(data) {
    return api.put('/note/createCharting', data);
  },
  // createChartingNote2(id, data) { // data is { templateId, consultNoteId, info }
  //   return api.put(`/note/${id}/createCharting2`, data);
  // },
  addPagesFromTemplate(id, data) { // data is { templateId, getSignedImageUrlForNewPages: Boolean }
    return api.put(`/note/${id}/addPagesFromTemplate`, data);
  },
  createNotesFromIntegratedTestResults(id) {
    return api.put(`/note/${id}/createChartingFromTestResults`);
  },
  createChartingFromTestResultsWithAdmission(id) {
    return api.put(`/note/${id}/createChartingFromTestResultsWithAdmission`);
  },
  delete(id) {
    return api.delete(`/note/${id}`);
  },
  createNewCategory(data) {
    return api.post('/note/newCategory', data);
  },
  deleteCategory(id) {
    return api.put(`/note/category/delete/${id}`);
  },
  patchNotecategory(id, data) {
    return api.patch(`/note/category/${id}`, data);
  },
  reorderNotecategories(id, data) {
    return api.put(`/note/category/reorder/${id}`, data);
  },
  bulkDelete(data) {
    return api.put('/note/bulk', data);
  },
  toggleLock(id, data) {
    return api.put(`/note/${id}/toggleLock`, data);
  },
  checkPublishProvider(data) {
    return api.post('/note/checkPublish', data);
  },
  printNote(params) {
    console.log('data ', params);
    //    return api.get('/note/printNote', data);
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push({ noteHTML: JSON.stringify(params) });
    // Object.keys(params).forEach((key) => {
    //   q.push(`${key}=${params[key]}`);
    // });
    //   const replacedParam = params.toString().replace(/%/g, '~~pct~~');
    // console.log('replacedparams', replacedParam);
    const url = `/note/printNote/?noteId=${params._id}`;
    // console.log('url', url);
    return api.get(url);
  },
  uploadNote(queryString, formData) {
    const url = `/note/uploadNotes?${queryString}`;

    return api.uploadImageApi(url, formData);
  },
};
