import noteService from '@/services/note.service';
import Vue from 'vue';
import notecategoryService from '@/services/notecategory.service';
import uuid from 'uuid';
import moment from 'moment';
import _ from 'lodash';

import {
  BEFORE_SET_ALL_NOTES,
  SET_ALL_NOTES,
  BEFORE_SET_ALL_NOTE_CATEGORIES,
  SET_ALL_NOTE_CATEGORIES,
  BEFORE_SET_ALL_NOTE_CATEGORIES_BY_PATIENTID,
  SET_ALL_NOTE_CATEGORIES_BY_PATIENTID,
  ADD_NEW_CATEGORY,
  GO_TO_PAGE,
  ADD_NEW_NOTE,
  NOTE_SAVED,
  UPDATE_NOTE_LIST,
  PATCH_NOTES,
  REORDER_NOTE_CATEGORIES,
  BEFORE_UPDATE_CATEGORIES,
  UPDATE_CATEGORIES_SUCCESS,
  UPDATE_CATEGORIES_ERROR,
  DELETE_CATEGORY,
  DELETE_NOTES,
  UPDATE_CHARTING_SORT_OPTIONS,
  SET_MAIN_FORMS_CHANGE_LOGS,
  RESET_MAIN_FORMS_CHANGE_LOGS,
  UPDATE_NOTE_LOCK_STATUS,
  SET_PAGINATED_NOTES,
  RESET_IPD_CONTAINERS_SETTINGS,
  ADD_IPD_CONTAINER_PAGE,
  SET_IPD_CONTAINER_VALUE,
  SET_IPD_CONTAINER_NOTES,
  SET_IPD_CONTAINER_NOTETEMPLATES,
  SET_PRINT,
} from './type.mutation';

const state = {
  all: [],
  notes: [],
  noteIds: [],
  notesMapped: {},
  notesByCategories: {},
  notesByVisitId: {}, // TODO: 20191112
  notesWithNoVisitId: [],
  notecategories: [],
  deleteNotesCoverPagesOnlyOrdered: [],
  deletedNotesMappedById: {},
  categoriesMapped: {},
  categoriesMappedByPatientId: {},
  categoriesOptions: [],
  categoriesIdsOnly: [],
  activeCategoriesIdsOnly: [],
  categoriesLoading: false,
  categoriesLoadingError: false,
  trashCategoryId: '',
  uncategorizedCategoryId: '',
  extractedNote: {},
  editor: {
    open: false,
    openSize: null,
    name: null,
    categoryId: null,
    currentPageId: null,
    pages: {},
    pagesIdOrder: [],
    pagesWithChanges: {}, // { id1: true, id2: true, etc }
    formData: {},
    formDataOrder: {},
    permissions: null,
    hasPermissionChanges: false,
    requireSignature: false,
    signature: '',
    signatureDate: '',
  },
  originalEditorName: null,
  histories: {},
  historyStep: {},
  viewer: {
    open: false,
    name: null,
    categoryId: null,
    currentPageId: null,
    pages: {},
    pagesIdOrder: [],
  },
  selectedTemplateId: 'All',
  presetChartingSortOptions: null,
  presetChartingSortType: null,
  presetChartingSortOrder: null,
  cachedPermissions: {},
  mainFormsChangeLogs: {},
  cont1: {
    page: 1,
    limit: 15,
    listPanel: 'notetemplate',
    // category: localStorage.getItem('cont1_default_category') || 'All',
    // LeiLi: new feature, use default all
    category: 'All',
    chartings: [],
    templates: [],
    editor: {},
  },
  cont2: {
    page: 1,
    limit: 15,
    listPanel: 'note',
    // category: localStorage.getItem('cont2_default_category') || 'All',
    // LeiLi: new feature, use default all
    category: 'All',
    chartings: [],
    templates: [],
    editor: {},
  },
  print: {},
};

/* eslint-disable no-param-reassign */
const mutations = {
  [RESET_IPD_CONTAINERS_SETTINGS](_state) {
    _state.cont1 = {
      page: 1,
      limit: 15,
      listPanel: 'notetemplate',
      category: localStorage.getItem('cont1_default_category') || 'All',
      chartings: [],
      templates: [],
      editor: {},
    };
    _state.cont2 = {
      page: 1,
      limit: 15,
      listPanel: 'note',
      category: localStorage.getItem('cont2_default_category') || 'All',
      chartings: [],
      templates: [],
      editor: {},
    };
  },
  [ADD_IPD_CONTAINER_PAGE](_state, containerName) {
    if (_state.cont1.category === _state.cont2.category) {
      _state.cont1.page += 1;
      _state.cont2.page += 1;
    } else {
      _state[containerName].page += 1;
    }
  },
  [SET_IPD_CONTAINER_VALUE](_state, { containerName, key, value }) {
    Vue.set(_state[containerName], key, value);
  },
  [SET_IPD_CONTAINER_NOTETEMPLATES](_state, containerNames) {
    const container = _state[containerNames[0]];
    let otherContainerName = 'cont2';
    if (containerNames[0] === 'cont2') {
      otherContainerName = 'cont1';
    }
    const numberOfDocs = container.page * container.limit;
    console.log('hoho mtn SET_IPD_CONTAINER_NOTETEMPLATES - containerNames', containerNames);
    container.templates = (this.state.notetemplate.notetemplatesByCategories[container.category] || [])
      .filter(templateId => !this.state.notetemplate.notetemplatesMappedById[templateId].coverPageId);

    if (containerNames[1]) {
      _state[containerNames[1]].templates = container.templates;
    }
    if (
      _state.cont1.category === _state.cont2.category
      && _state.cont1.listPanel === _state.cont2.listPanel
    ) {
      _state[otherContainerName].templates = container.templates;
    }
    console.log('two comps are ', _state.cont1, _state.cont2);
  },
  [SET_IPD_CONTAINER_NOTES](_state, containerNames) {
    console.log('inside SET_IPD_CONTAINER_NOTES mtn - containerNames ', containerNames);
    const container = _state[containerNames[0]];
    let otherContainerName = 'cont2';
    if (containerNames[0] === 'cont2') {
      otherContainerName = 'cont1';
    }
    container.chartings = (_state.notesByCategories[container.category] || [])
      .filter(noteId => !_state.notesMapped[noteId].coverPageId);

    if (containerNames[1]) {
      _state[containerNames[1]].chartings = container.chartings;
    }
    if (
      _state.cont1.category === _state.cont2.category
      && _state.cont1.listPanel === _state.cont2.listPanel
    ) {
      _state[otherContainerName].chartings = container.chartings;
    }
  },
  EDIT_IPD_NOTE(_state, payload) {
    _state[payload.containerName].editor = payload.chartingEditInfo;
  },
  UPDATE_IPD_TYPING_FORM_DATA(_state, { containerName, qns, val }) {
    _state[containerName].editor.formData[qns].answer = val;
    const { currentPageId } = _state[containerName].editor;
    if (!_state[containerName].editor.pagesWithChanges[currentPageId]) {
      _state[containerName].editor.pagesWithChanges[currentPageId] = true;
    }
    console.log('_state[containerName].editor ', _state[containerName].editor);
  },
  // UPDATE_TYPING_FORM_SIGNATURE(_state, payload) {
  //   _state.editor.signature = payload;
  // },
  UPDATE_IPD_TYPING_FORM_MAIN_CHECKBOX(_state, {
    containerName, qns, checked, effects,
  }) {
    // console.log('in VUEX - UPDATE_TYPING_FORM_MAIN_CHECKBOX params are ', qns, checked, effects);
    if (!checked) {
      _state[containerName].editor.formData[qns].mainAnswer = '';
    } else {
      _state[containerName].editor.formData[qns].mainAnswer = checked;
    }
    effects.forEach((effect) => {
      if (effect == 'check_all') {
        _state[containerName].editor.formData[qns].options.forEach((opt) => {
          _state[containerName].editor.formData[qns].answer = [];
          _state[containerName].editor.formData[qns].answer.push(opt.value);
        });
      } else if (effect == 'uncheck_all') {
        _state[containerName].editor.formData[qns].answer = [];
      } else if (effect == 'enable_all') {
        _state[containerName].editor.formData[qns].options.forEach((opt) => {
          delete opt.disabled;
        });
      } else if (effect == 'disable_all') {
        _state[containerName].editor.formData[qns].options.forEach((opt) => {
          opt.disabled = true;
        });
      }
    });
    _state[containerName].editor.formData[qns].lastChangedKey = +new Date();
    if (!_state[containerName].editor.pagesWithChanges[_state[containerName].editor.currentPageId]) {
      _state[containerName].editor.pagesWithChanges[_state[containerName].editor.currentPageId] = true;
    }
  },
  updateIPDEditorMetadata(_state, { containerName, key, value }) {
    // console.log('updateEditorMetadata...k v ', key, value);
    if (key === 'name' && value === '') {
      _state[containerName].originalEditorName = _state[containerName].editor.name;
    }
    _state[containerName].editor[key] = value;
    // _state[containerName].editor.pagesWithChanges = true;
    const { currentPageId } = _state[containerName].editor;
    _state[containerName].editor.pagesWithChanges[currentPageId] = true;
  },
  [BEFORE_SET_ALL_NOTES](_state) {
    _state.notes = new Array();
    _state.noteIds = new Array();
    _state.notesMapped = new Object();
    _state.notesByCategories = new Object();
    _state.notesByVisitId = new Object();
    _state.notesWithNoVisitId = new Array();
    _state.deleteNotesCoverPagesOnlyOrdered = new Array();
    _state.deletedNotesMappedById = new Object();
  },
  [SET_ALL_NOTES](_state, payload) {
    const notesMapped = {};
    const notesByCategories = {};
    const notesByVisitId = {};
    const notesWithNoVisitId = [];
    payload.forEach((note) => {
      _state.notes.push(note);
      notesMapped[note._id] = note;
      if (note.type !== 'mainForm') {
        if (notesByCategories[note.categoryId] === undefined) {
          notesByCategories[note.categoryId] = [];
        }
        notesByCategories[note.categoryId].push(note._id);
      }
      if (note.typingData && typeof note.typingData === 'string') {
        note.typingData = JSON.parse(note.typingData);
      }
      if (note.visitId) {
        // console.log('note type >>> ', note.type);
        const { visitId } = note;
        if (notesByVisitId[visitId] === undefined) {
          notesByVisitId[visitId] = [];
        }
        notesByVisitId[visitId].push(note._id);
        if (note.type === 'mainForm') {
          _state.mainFormsChangeLogs[note._id] = false;
        }
      } else if (note.type === 'mainForm') {
        notesWithNoVisitId.push(note._id);
      }
    });
    _state.notesMapped = notesMapped;
    _state.notesByCategories = notesByCategories;
    _state.notesByVisitId = notesByVisitId;
    _state.notesWithNoVisitId = notesWithNoVisitId;
    _state.extractedNote = new Object();
  },
  CLEAR_NOTESBYCATEGORIES_BY_CATEGORY(_state, category) {
    _state.notesByCategories[category] = [];
  },
  [SET_PAGINATED_NOTES](_state, payload) {
    console.log('inside SET_PAGINATED_NOTES mtn');
    const { notesMapped, notesByCategories } = _state;
    for (let i = 0; i < payload.length; i += 1) {
      const note = payload[i];
      if (notesByCategories[note.categoryId] === undefined) {
        notesByCategories[note.categoryId] = [];
      }
      if (notesByCategories[note.categoryId].indexOf(note._id) === -1) {
        notesByCategories[note.categoryId].push(note._id);
      }
      if (notesByCategories.All === undefined) {
        notesByCategories.All = [];
      }
      if (notesByCategories.All.indexOf(note._id) === -1) {
        notesByCategories.All.push(note._id);
      }
      if (note.starred) {
        if (notesByCategories.Starred === undefined) {
          notesByCategories.Starred = [];
        }
        if (notesByCategories.Starred.indexOf(note._id) === -1) {
          notesByCategories.Starred.push(note._id);
        }
      }
      if (!notesMapped[note._id]) {
        // if (notesByCategories[note.categoryId] === undefined) {
        //   notesByCategories[note.categoryId] = [];
        // }
        // notesByCategories[note.categoryId].push(note._id);
        // if (notesByCategories.All === undefined) {
        //   notesByCategories.All = [];
        // }
        // notesByCategories.All.push(note._id);
        // if (note.starred) {
        //   if (notesByCategories.Starred === undefined) {
        //     notesByCategories.Starred = [];
        //   }
        //   notesByCategories.Starred.push(note._id);
        // }

        if (note.isDeleted) {
          _state.deleteNotesCoverPagesOnlyOrdered.push(note._id);
          _state.deletedNotesMappedById[note._id] = note;
        } else {
          _state.noteIds.push(note._id);
          notesMapped[note._id] = note;
        }
      }
    }
    _state.notesMapped = notesMapped;
    _state.notesByCategories = notesByCategories;
  },
  UPDATE_MAIN_FORM_DATA(_state, { noteId, qns, val }) {
    _state.notesMapped[noteId].formData[qns].answer = val;
    _state.notesMapped[noteId].hasChanges = true;
    // const formData = _state.notesMapped[noteId].formData.map((qns, i) => {
    //   if (pos === i) {
    //     return { ...qns, answer: val };
    //   }
    //   return qns;
    // });
    // _state.notesMapped = { ..._state.notesMapped, [noteId]: { ..._state.notesMapped[noteId], formData, hasChanges: true } };
  },
  UPDATE_MAIN_FORM_TEMPLATE(_state, {
    noteId,
    template: {
      _id: templateId, name, categoryId, formDataBuilder, formDataBuilderOrder,
    },
  }) {
    const oldCategoryId = _state.notesMapped[noteId].categoryId;
    _state.notesMapped = {
      ..._state.notesMapped,
      [noteId]: {
      //  ..._state.notesMapped[noteId], name, formData: formDataBuilder, templateId, categoryId
        ..._state.notesMapped[noteId], name, formData: formDataBuilder, formDataOrder: formDataBuilderOrder, templateId, categoryId,
      },
    };
  },
  UPDATE_TYPING_FORM_DATA(_state, { qns, val }) {
    _state.editor.formData[qns].answer = val;
    if (!_state.editor.pagesWithChanges[_state.editor.currentPageId]) {
      _state.editor.pagesWithChanges[_state.editor.currentPageId] = true;
    }
  },
  UPDATE_TYPING_FORM_SIGNATURE(_state, { containerName, qns, payload }) {
    // console.log('containerName', containerName, '\n qns', qns, '\n payload', payload);
    if (qns && containerName === undefined) {
      _state.editor.formData[qns].answer = payload || '';
    } else if (!qns && containerName === undefined) {
      _state.editor.signature = payload || '';
      // console.log('_state.editor.formData[qns] ', _state.editor.signature);
    } else if (!qns && containerName !== undefined) {
      _state[containerName].editor.signature = payload || '';
    } else {
      _state[containerName].editor.formData[qns].answer = payload || '';
    }
    // console.log("_state.editor.signature ", _state.editor.signature);
  },
  UPDATE_TYPING_FORM_MAIN_CHECKBOX(_state, { qns, checked, effects }) {
    // console.log('in VUEX - UPDATE_TYPING_FORM_MAIN_CHECKBOX params are ', qns, checked, effects);
    if (!checked) {
      _state.editor.formData[qns].mainAnswer = '';
    } else {
      _state.editor.formData[qns].mainAnswer = checked;
    }
    effects.forEach((effect) => {
      if (effect === 'check_all') {
        _state.editor.formData[qns].options.forEach((opt) => {
          _state.editor.formData[qns].answer = [];
          _state.editor.formData[qns].answer.push(opt.value);
        });
      } else if (effect === 'uncheck_all') {
        _state.editor.formData[qns].answer = [];
      } else if (effect === 'enable_all') {
        _state.editor.formData[qns].options.forEach((opt) => {
          delete opt.disabled;
        });
      } else if (effect === 'disable_all') {
        _state.editor.formData[qns].options.forEach((opt) => {
          opt.disabled = true;
        });
      }
    });
    _state.editor.formData[qns].lastChangedKey = +new Date();
    if (!_state.editor.pagesWithChanges[_state.editor.currentPageId]) {
      _state.editor.pagesWithChanges[_state.editor.currentPageId] = true;
    }
  },
  UPDATE_TYPING_FORM_MAIN_RADIO(_state, {
    containerName, e, qns, mainRadioboxControl,
  }) {
    console.log('in VUEX - UPDATE_TYPING_FORM_MAIN_RADIO params are ', e, qns, _state);
    let handleContainerState = {};
    if (!containerName) {
      _state.editor.formData[qns].answer = e || '';
      handleContainerState = _state;
    } else {
      _state[containerName].editor.formData[qns].answer = e || '';
      handleContainerState = _state[containerName];
    }
    const getId = subField => (subField ? subField + handleContainerState.editor.formData[subField].type : '');
    if (mainRadioboxControl.effects === 'hide_show_all_sub_fields') {
      mainRadioboxControl.sub_fields.forEach((subField) => {
        if (e === 'hide') {
          // console.log('document.getElementById(subField) ', getId(subField), handleContainerState.editor.formData[subField]);
          document.getElementById(`${subField}group`).style.display = 'none';
          document.getElementById(getId(subField)).disabled = true;
          handleContainerState.editor.formData[subField].display = 'hide';
        } else {
          document.getElementById(`${subField}group`).style.display = 'block';
          document.getElementById(getId(subField)).disabled = false;
          handleContainerState.editor.formData[subField].display = 'show';
        }
      });
    } else if (mainRadioboxControl.effects === 'accordian') {
      const subFields = new Set([]);
      handleContainerState.editor.formData[qns].options.map(
        radioOption => radioOption.sub_fields.map(
          element => subFields.add(element),
        ),
      );
      handleContainerState.editor.formData[qns].options.forEach((radioOption) => {
        if (radioOption.value === e) {
          radioOption.sub_fields.forEach((element) => {
            subFields.delete(element);
            document.getElementById(`${element}group`).style.display = 'none';
            document.getElementById(getId(element)).disabled = true;
            handleContainerState.editor.formData[element].display = 'hide';
          });
        } else {
          radioOption.sub_fields.forEach((element) => {
            if (subFields.has(element)) {
              document.getElementById(`${element}group`).style.display = 'block';
              document.getElementById(getId(element)).disabled = false;
              handleContainerState.editor.formData[element].display = 'show';
            }
          });
        }
      });
    }
    handleContainerState.editor.formData[qns].lastChangedKey = +new Date();
    if (!handleContainerState.editor.pagesWithChanges[handleContainerState.editor.currentPageId]) {
      handleContainerState.editor.pagesWithChanges[handleContainerState.editor.currentPageId] = true;
    }
  },
  [UPDATE_NOTE_LOCK_STATUS](_state, { noteId, isLocked }) {
    _state.notesMapped[noteId].isLocked = isLocked;
  },
  [BEFORE_SET_ALL_NOTE_CATEGORIES](_state) {
    _state.notecategories = [];
    _state.categoriesMapped = {};
    _state.categoriesOptions = [];
    _state.categoriesIdsOnly = [];
    _state.activeCategoriesIdsOnly = [];
    _state.trashCategoryId = '';
    _state.uncategorizedCategoryId = '';
    _state.categoriesLoading = false;
    _state.categoriesLoadingError = false;
  },
  [SET_ALL_NOTE_CATEGORIES](_state, payload) {
    _state.notecategories = payload;
    const categoriesMapped = {};
    const categoriesIdsOnly = [];
    const activeCategoriesIdsOnly = [];
    const categoriesOptions = [];
    const clone = Object.assign({}, _state.notesByCategories);
    payload.forEach(({
      _id, name, color, colorLabel, isDeleted,
    }) => {
      categoriesMapped[_id] = {
        name, color, colorLabel, isDeleted,
      };
      if (clone[_id] === undefined) {
        clone[_id] = [];
      }
      if (name === 'Trash') {
        _state.trashCategoryId = _id;
      } else {
        if (name === 'Uncategorized') {
          _state.uncategorizedCategoryId = _id;
        }
        categoriesIdsOnly.push(_id);
        if (!isDeleted) {
          activeCategoriesIdsOnly.push(_id);
          categoriesOptions.push({ value: _id, text: name });
        }
      }
    });
    categoriesIdsOnly.push(_state.trashCategoryId);
    activeCategoriesIdsOnly.push(_state.trashCategoryId);
    _state.categoriesMapped = categoriesMapped;
    _state.notesByCategories = clone;
    _state.categoriesIdsOnly = categoriesIdsOnly;
    _state.activeCategoriesIdsOnly = activeCategoriesIdsOnly;
    _state.categoriesOptions = categoriesOptions;
  },
  [BEFORE_SET_ALL_NOTE_CATEGORIES_BY_PATIENTID](_state) {
    _state.categoriesMappedByPatientId = {};
    _state.categoriesLoading = false;
    _state.categoriesLoadingError = false;
  },
  [SET_ALL_NOTE_CATEGORIES_BY_PATIENTID](_state, payload) {
    const categoriesMappedByPatientId = {};
    payload.forEach(({
      _id, name, color, colorLabel,
    }) => {
      categoriesMappedByPatientId[_id] = {
        name, color, colorLabel,
      };
    });
    _state.categoriesMappedByPatientId = categoriesMappedByPatientId;
  },
  [REORDER_NOTE_CATEGORIES](_state, payload) {
    _state.activeCategoriesIdsOnly = payload;
    _state.activeCategoriesIdsOnly.push(_state.trashCategoryId);
  },
  [BEFORE_UPDATE_CATEGORIES](_state) {
    _state.categoriesLoading = true;
  },
  [UPDATE_CATEGORIES_SUCCESS](_state, payload) {
    _state.categoriesLoading = false;
    if (payload) {
      _state.categoriesMapped[payload._id] = payload;
    }
  },
  [UPDATE_CATEGORIES_ERROR](_state, error) {
    _state.categoriesLoadingError = error.message || 'Pls refresh';
  },
  [DELETE_CATEGORY](_state, catId) {
    _state.activeCategoriesIdsOnly = _state.activeCategoriesIdsOnly.filter(id => id !== catId);
  },
  [ADD_NEW_CATEGORY](_state, newCategory) {
    _state.categoriesMapped[newCategory._id] = newCategory;
    _state.activeCategoriesIdsOnly.unshift(newCategory._id);
  },
  selectTemplateCat(_state, selectedTemplateId) {
    _state.selectedTemplateId = selectedTemplateId;
  },
  [UPDATE_CHARTING_SORT_OPTIONS](_state, value) {
    localStorage.setItem('lastChartingSortOptions', value);
    _state.presetChartingSortOptions = value;
    [_state.presetChartingSortType, _state.presetChartingSortOrder] = value.split('.');
  },
  [UPDATE_NOTE_LIST](_state, updatedNotes) { // updatedNotes shld be [noteTemplate, noteTemplate2, etc...]
    const notesMappedCloned = _.cloneDeep(_state.notesMapped);
    for (let i = 0; i < updatedNotes.length; i++) {
      const note = updatedNotes[i];
      if (!notesMappedCloned[note._id]) { // new addition
        _state.notes.push(note._id);
      }
      notesMappedCloned[note._id] = note;
      if (!_state.notesByCategories[note.categoryId]) {
        _state.notesByCategories[note.categoryId] = [];
      }
      _state.notesByCategories[note.categoryId].push(note._id);
    }
    _state.notesMapped = notesMappedCloned;
    _state.editor.pagesWithChanges = new Object();
    _state.editor.hasPermissionChanges = false;
  },
  /**
    FOR PATCH_NOTES
    data can be either
    (1) an array of -
    [
      {
        id: noteId1,
        updates: {
          key1: updatedVal1,
          key2: updatedVal2
        },
        deletedKeys: ['key3', 'key4'] // this is optional
      }
    ]
    OR
    (2) an object -
    {
      ids: ['id1', 'id2', 'id3'],
      updates: {
        key1: value1,
        key2: value2
      }
    }
   */
  [PATCH_NOTES](_state, data) {
    const notesMappedCloned = _.cloneDeep(_state.notesMapped);
    if (Array.isArray(data)) {
      for (let i = 0; i < data.length; i++) {
        const elem = data[i];
        notesMappedCloned[elem.id] = { ...notesMappedCloned[elem.id], ...elem.updates };
        if (elem.deletedKeys) {
          elem.deletedKeys.forEach((k) => {
            delete notesMappedCloned[elem.id][k];
          });
        }
      }
    } else if (_.isObject(data)) {
      for (let i = 0; i < data.ids.length; i++) {
        const id = data.ids[i];
        notesMappedCloned[id] = { ...notesMappedCloned[id], ...data.updates };
      }
    }
    _state.notesMapped = notesMappedCloned;
  },
  addPage(_state, {
    shortUuid, type, key, tempBase64,
  }) {
    _state.editor.pages[shortUuid] = {
      shortUuid, type, key, tempBase64, strokeStore: {}, strokesIdOrder: [],
    };
    _state.editor.pagesIdOrder.push(shortUuid);

    if (_state.editor.currentPageId === null) {
      _state.editor.currentPageId = shortUuid;
    }
    if (!_state.editor.name) {
      _state.editor.name = 'Untitled Template';
    }
  },
  addHistory(_state, { pageId, stroke }) {
    if (_state.historyStep[pageId] + 1 < _state.histories[pageId].length) {
      _state.histories[pageId] = _state.histories[pageId].slice(0, _state.historyStep[pageId] + 1);
    }
    _state.histories[pageId].push(stroke);
    _state.historyStep[pageId] += 1;
  },
  undoHistory(_state) {
    const pageId = _state.editor.currentPageId;
    const strokeToUndo = _state.histories[pageId][_state.historyStep[pageId]];
    const page = _.cloneDeep(_state.editor.pages[pageId]);
    delete page.strokeStore[strokeToUndo.id];
    page.strokesIdOrder = page.strokesIdOrder.filter(sId => sId !== strokeToUndo.id);
    // delete _state.editor.pages[pageId].strokeStore[strokeToUndo.targetId];
    _state.editor.pages[pageId] = page;
    _state.editor.pagesWithChanges[pageId] = true;
    _state.historyStep[pageId] -= 1;
  },
  redoHistory(_state) {
    const pageId = _state.editor.currentPageId;
    const strokeToRedo = _state.histories[pageId][_state.historyStep[pageId] + 1];
    const page = _.cloneDeep(_state.editor.pages[pageId]);
    page.strokeStore[strokeToRedo.id] = strokeToRedo;
    page.strokesIdOrder.push(strokeToRedo.id);
    _state.editor.pages[pageId] = page;
    _state.editor.pagesWithChanges[pageId] = true;
    _state.historyStep[pageId] += 1;
  },
  updateDoc(_state, { key, value }) {
    const clonePages = {};
    const clonePagesWithChanges = Object.assign({}, _state.editor.pagesWithChanges);
    _state.editor.pagesIdOrder.forEach((pageId, i) => {
      const page = _state.editor.pages[pageId];
      if (pageId === _state.editor.currentPageId) {
        const pageClone = Object.assign({}, page);
        if (key === 'strokeStore') {
          if (!pageClone.strokeStore) {
            pageClone.strokeStore = {};
          }
          // let id = shortUuid();
          pageClone.strokeStore[value.id] = value;
          if (!pageClone.strokesIdOrder) {
            pageClone.strokesIdOrder = [];
          }
          pageClone.strokesIdOrder.push(value.id);
        }
        clonePagesWithChanges[pageClone._id] = true;
        clonePages[pageId] = pageClone;
      } else {
        clonePages[pageId] = page;
      }
    });
    _state.editor.pages = clonePages;
    _state.editor.pagesWithChanges = clonePagesWithChanges;
  },
  updateStrokeStore(_state, {
    id, key, value, isRemoval,
  }) {
    const clonePagesWithChanges = Object.assign({}, _state.editor.pagesWithChanges);
    const page = _.cloneDeep(_state.editor.pages[_state.editor.currentPageId]);
    if (isRemoval) {
      delete page.strokeStore[id];
      page.strokesIdOrder = page.strokesIdOrder.filter(sId => sId !== id);
      clonePagesWithChanges[page._id] = true;
      _state.editor.pages[_state.editor.currentPageId] = page;
    } else {
      _state.editor.pages[_state.editor.currentPageId].strokeStore[id][key] = value;
      clonePagesWithChanges[page._id] = true;
    }
    _state.editor.pagesWithChanges = clonePagesWithChanges;
  },
  updateNote(_state, { id, key, value }) {
    if (key === 'categoryId') {
      const clone = Object.assign({}, _state.notesByCategories);
      clone[_state.notesMapped[id].categoryId] = clone[_state.notesMapped[id].categoryId].filter(n => n !== id);
      if (clone[_state.notesMapped[id].categoryId].length === 0) {
        delete clone[_state.notesMapped[id].categoryId];
      }
      if (clone[value] === undefined) {
        clone[value] = [];
      }
      clone[value].push(id);
      _state.notesByCategories = clone;
    }
    if (key === 'typingData') {
      _state.notesMapped = {
        ..._state.notesMapped,
        [id]: { // note id
          ..._state.notesMapped[id],
          [key]: [...value],
        },
      };
    } else {
      _state.notesMapped = {
        ..._state.notesMapped,
        [id]: { // note id
          ..._state.notesMapped[id],
          [key]: value,
        },
      };
      if (key === 'otherPagesId') {
        // edit the state.editor also to reflect the new thumbnails
        if (_state.editor.open) {
          const updatedPagesIdOrder = _.cloneDeep(value);
          updatedPagesIdOrder.unshift(id);
          for (const pageId of updatedPagesIdOrder) {
            if (!_state.histories[pageId]) {
              _state.histories[pageId] = [];
              _state.historyStep[pageId] = -1;
            }
          }
          // console.log('updatedPagesIdOrder ', updatedPagesIdOrder);
          _state.editor.pagesIdOrder = updatedPagesIdOrder;
        }
      }
    }
    // console.log('_state.editor is ', _state.editor);
  },
  updateNoteKeys(_state, { id, updatedKeyValues }) {
    if (updatedKeyValues.categoryId !== undefined) {
      const newCatId = updatedKeyValues.categoryId;
      const clone = Object.assign({}, _state.notesByCategories);
      clone[_state.notesMapped[id].categoryId] = clone[_state.notesMapped[id].categoryId].filter(n => n !== id);
      if (clone[_state.notesMapped[id].categoryId].length === 0) {
        delete clone[_state.notesMapped[id].categoryId];
      }
      if (clone[newCatId] === undefined) {
        clone[newCatId] = [];
      }
      clone[newCatId].push(id);
      _state.notesByCategories = clone;
    }
    _state.notesMapped[id] = Object.assign({}, _state.notesMapped[id], updatedKeyValues);
  },
  updateNoteKeysV2(_state, { id, updatedKeyValues }) {
    _state.notesMapped[id] = { ..._state.notesMapped[id], ...updatedKeyValues };
  },
  [NOTE_SAVED](_state, note) {
    _state.notes = _state.notes.map(n => (n._id !== note._id ? n : note));
    _state.notesMapped = { ..._state.notesMapped, [note._id]: note };
    if (_state.editor) {
      if (_state.editor.pagesWithChanges) _state.editor.pagesWithChanges = {};
      if (_state.editor.hasPermissionChanges) _state.editor.hasPermissionChanges = false;
    }
  },
  [ADD_NEW_NOTE](_state, notes) {
    // console.log('mutation ADD_NEW_NOTE');
    _state.notes = [..._state.notes].concat(notes);
    // console.log('_state.notes is ', _state.notes);
    const clone = Object.assign({}, _state.notesMapped);
    notes.forEach((note) => {
      // console.log('each new note is *********************** ', note);
      clone[note._id] = note;
      if (_state.editor.open) {
        _state.editor.pages[note._id] = note;
      }
      if (!note.coverPageId) {
        if (_state.notesByCategories[note.categoryId] === undefined) {
          _state.notesByCategories[note.categoryId] = [];
        }
        _state.notesByCategories[note.categoryId].unshift(note._id);
        if (_state.notesByCategories.All === undefined) {
          _state.notesByCategories.All = [];
        }
        _state.notesByCategories.All.unshift(note._id);
      }
    });
    _state.notesMapped = clone;
    // console.log('_state.notesMapped is ', _state.notesMapped);
  },
  openViewer(_state, payload) {
    _state.viewer = Object.assign({}, _state.viewer, payload);
  },
  closeViewer(_state) {
    _state.viewer = {
      open: false,
      name: null,
      categoryId: null,
      currentPageId: null,
      pages: {},
      pagesIdOrder: [],
    };
  },
  goToPageViewer(_state, newPageIndex) {
    const clone = Object.assign({}, _state.viewer);
    clone.currentPageId = clone.pagesIdOrder[newPageIndex];
    _state.viewer = Object.assign({}, clone);
  },
  openEditor(_state, payload) {
    if (!payload) {
      _state.editor = {
        name: null,
        categoryId: null,
        mainFormId: null,
        noteId: null,
        type: null,
        open: false,
        openSize: null,
        currentPageId: null,
        pages: {},
        pagesIdOrder: [],
        invoiceId: null,
        pagesWithChanges: {},
        formData: {},
        formDataOrder: [],
        fromIPD: false,
        IPDContainerName: '',
      };
    } else {
      _state.editor.open = payload.open;
      _state.editor.openSize = payload.openSize;
      _state.editor.type = payload.type;
      if (payload.categoryId) {
        _state.editor.categoryId = payload.categoryId;
      }
      _state.editor.currentPageId = payload.currentPageId || null;
      if (!_.isEmpty(payload.pages)) {
        const currentPageId = payload.pagesIdOrder[0];
        _state.editor.mainFormId = payload.mainFormId;
        _state.editor.noteId = payload.noteId;
        _state.editor.invoiceId = payload.invoiceId;
        _state.editor.pages = _.cloneDeep(payload.pages);
        _state.editor.pagesIdOrder = payload.pagesIdOrder;
        _state.editor.currentPageId = currentPageId;
        _state.editor.name = payload.pages[currentPageId].name;
        _state.editor.pagesWithChanges = {};
        for (const pageId in _state.editor.pages) {
          _state.histories[pageId] = [];
          _state.historyStep[pageId] = -1;
        }
      }
      if (payload.fromIPD) {
        _state.editor.fromIPD = true;
        _state.editor.IPDContainerName = payload.IPDContainerName;
        if (payload.pagesWithChanges) {
          _state.editor.pagesWithChanges = payload.pagesWithChanges;
        }
        _state.editor.isTemplate = payload.isTemplate || false;
      }
      // console.log('AFT openEditor - _state.editor is ', _state.editor);
      // console.log('AFT openEditor - _state is ', _state);
    }
    if (payload.permissions) {
      _state.editor.permissions = payload.permissions;
      _state.editor.hasPermissionChanges = false;
    }
    if (payload.publishable) _state.editor.publishable = payload.publishable;
    if (payload.draft !== undefined) _state.editor.draft = payload.draft;
  },
  updateEditorMetadata(_state, { key, value }) {
    // console.log('updateEditorMetadata...k v ', key, value);
    if (key === 'name' && value === '') {
      _state.originalEditorName = _state.editor.name;
    }
    _state.editor[key] = value;
  },
  closeEditor(_state) {
    // console.log('in note/closeEditor');
    // if (!_.isEmpty(_state.editor.pagesWithChanges)) {
    //   let confirm = window.confirm('There are unsaved edits, exiting will result in loss of these edits. \nProceed?');
    //   if (!confirm) return;
    // }
    _state.editor = {
      name: null,
      categoryId: null,
      mainFormId: null,
      noteId: null,
      type: null,
      open: false,
      openSize: null,
      currentPageId: null,
      pages: {},
      pagesIdOrder: [],
      invoiceId: null,
      pagesWithChanges: {},
      formData: {},
      formDataOrder: [],
      permissions: null,
      hasPermissionChanges: false,
      fromIPD: false,
      IPDContainerName: '',
    };
  },
  EXTRACT_NOTE(_state, payload) {
    // if (_state.extractedNote) {
    // do some checks here, if have previously extracted note, warn user you will lose all unsaved date
    // }
    // console.log('extracted note - payload is ', payload);
    payload.pagesWithChanges = {};
    _state.editor = payload;
  },
  RESIZE_EXTRACTED_NOTE(_state, { openSize }) {
    // console.log('in RESIZE_EXTRACTED_NOTE openSize is ', openSize);
    // console.log('bef _state.editor is ', _state.editor);
    _state.editor = { ..._state.editor, openSize };
    // console.log('_state.editor is ', _state.editor);
  },
  MINIMIZE_EXTRACTED_IPD_NOTE(_state, { openSize, containerName }) {
    _state[containerName].editor = { ..._state.editor, openSize };
    _state[containerName].histories = { ..._state.histories };
    _state[containerName].historyStep = { ..._state.historyStep };
  },
  CLEAR_IPD_CONTAINER_EDITOR(_state, containerName) {
    _state[containerName].editor = new Object();
    _state[containerName].histories = new Object();
    _state[containerName].historyStep = new Object();
  },
  CLOSE_EXTRACTED_NOTE(_state) {
    _state.editor = {
      name: null,
      mainFormId: null,
      noteId: null,
      type: null,
      open: false,
      openSize: null,
      currentPageId: null,
      pages: {},
      pagesIdOrder: [],
      invoiceId: null,
      pagesWithChanges: {},
      formData: {},
      formDataOrder: [],
      permissions: null,
      hasPermissionChanges: false,
    };
  },
  [GO_TO_PAGE](_state, newPageIndex) {
    // console.log('-------------------------- START GO_TO_PAGE MUTATION ---------------------------');
    // console.log('BEF _state.editor is ', _state.editor);
    // console.log('in gotopage newPageIndex is ', newPageIndex);
    const clone = Object.assign({}, _state.editor);
    // console.log('clone is ', clone);
    clone.currentPageId = clone.pagesIdOrder[newPageIndex];
    _state.editor = Object.assign({}, clone);
    // console.log('AFT _state.editor is ', _state.editor);
    // console.log('-------------------------- END GO_TO_PAGE MUTATION ---------------------------');
  },
  [DELETE_NOTES](_state, deletedNoteIds) {
    const cloneMapped = { ..._state.notesMapped };
    deletedNoteIds.map((id) => {
      const clone = cloneMapped[id];
      clone.prevCategoryId = clone.categoryId;
      clone.categoryId = _state.trashCategoryId;
      cloneMapped[id] = clone;
      return id;
    });
    _state.notesMapped = cloneMapped;
  },
  updateCachedPermissions(_state, { chartingId, action, toggle }) {
    if (!_state.cachedPermissions[chartingId]) _state.cachedPermissions[chartingId] = {};
    _state.cachedPermissions[chartingId][action] = toggle;
  },
  deleteOneCachedPermission(_state, { chartingId }) {
    delete _state.cachedPermissions[chartingId];
  },
  resetCachedPermissions(_state) {
    _state.cachedPermissions = new Object();
  },
  [SET_MAIN_FORMS_CHANGE_LOGS](_state, { mainFormId, flag }) {
    Vue.set(_state.mainFormsChangeLogs, mainFormId, flag);
  },
  [RESET_MAIN_FORMS_CHANGE_LOGS](_state) {
    _state.mainFormsChangeLogs = {};
  },
  [SET_PRINT](_state, payload) {
    _state.print = payload;
  },
};

const actions = {
  resetMainFormChangeLogs({ commit }) {
    commit(RESET_MAIN_FORMS_CHANGE_LOGS);
  },
  socket_uploading({ commit }, params) {
    console.log('in uploading action - params ', params);
    Vue.prototype.$flashStorage.flash(`${params.uploader} is uploading note(s)...`, 'info', { timeout: 3000 });
  },
  socket_uploaded({ commit }, params) {
    console.log('in uploaded action - params ', params);
    const res = params.output;
    const self = this;
    const updatedConsultNote = res[0];
    const newChartingNotes = res[1]; // an array of a single or multiple new notes
    // if (self.parentComponent !== 'charting-view') {
    // const mainFormCloned = _.cloneDeep(self.mainForm);
    commit('updateNote', { id: updatedConsultNote._id, key: 'chartingNoteIds', value: updatedConsultNote.chartingNoteIds });
    // if (!Array.isArray(newChartingNotes)) {
    //     mainFormCloned.chartingNoteIds.push(newChartingNotes)
    //     newChartingNotes = [newChartingNotes];
    // } else {
    //     mainFormCloned.chartingNoteIds.push(newChartingNotes[0]);
    // }
    commit('invoice/PATCH_INVOICE', { id: updatedConsultNote.visitId, key: 'mainFormId', value: updatedConsultNote }, { root: true });
    // }
    commit('updateNote', { id: updatedConsultNote._id, key: 'chartingNoteIds', value: updatedConsultNote.chartingNoteIds });
    // if (!Array.isArray(newChartingNotes)) {
    //     mainFormCloned.chartingNoteIds.push(newChartingNotes)
    //     newChartingNotes = [newChartingNotes];
    // }
    commit('ADD_NEW_NOTE', newChartingNotes);
    Vue.prototype.$flashStorage.flash('Note uploaded and created successfully', 'success', { timeout: 3000 });
  },
  fetchAll({ commit }, params) {
    // console.log('note action fetchAll notes');
    commit(BEFORE_SET_ALL_NOTES);
    return noteService.get(params)
      .then((response) => {
        // console.log('fetchAll notes response is ', response);
        const notes = response.body;
        commit(SET_ALL_NOTES, notes);
        return notes;
      });
  },
  patchNoteOrders({ commit }, params) {
    // console.log('note action fetchAll notes');
    // commit(BEFORE_SET_ALL_NOTES);
    return noteService.patchNoteOrders(params)
      .then((response) => {
        console.log(response);
        return response;
        // console.log('fetchAll notes response is ', response);
        // const notes = response.body;
        // commit(SET_ALL_NOTES, notes);
        // return notes;
      });
  },
  fetchOtherPages({ commit }, params) {
    return new Promise((resolve, reject) => noteService.fetchOtherPages(params)
      .then((response) => {
        if (params.returnFullDoc) {
          if (response.body._id) {
            response.body = [response.body];
          }
          commit(ADD_NEW_NOTE, response.body);
        }
        return resolve(response);
      }));
  },
  fetchPaginated({ commit }, params) {
    return noteService.getPaginated(params)
      .then((response) => {
        console.log('fetchPaginated notes response is ', response);
        if (response.ok) {
          const notes = response.body;
          console.log('notes fetchPaginated ', notes);
          commit(SET_PAGINATED_NOTES, notes);
          return response;
        }
      });
  },
  getLatestImage({ commit }, params) {
    // commit(BEFORE_SET_ALL_NOTES);
    return new Promise((resolve, reject) => noteService.fetchOne(params)
      .then((response) => {
        // console.log('getLatestImage response and keys is ', response, params.keys);
        if (params.returnFullDoc) {
          commit(ADD_NEW_NOTE, [response.body]);
        } else {
          commit('updateNoteKeys', { id: params.id, updatedKeyValues: response.body });
        }
        // let updatedKeyValues = response.body;
        // commit('updateNoteKeys', {id: params.id, updatedKeyValues});
        // commit('updateNote', {id, key, value: note[keys]});
        // commit(ADD_NEW_NOTE, [note]);
        return resolve(response);
      }));
  },
  create({ commit }, params) {
    // console.log('in note_create, params is ', params);
    return noteService.createNew(params)
      .then((response) => {
        // console.log('note create response is ', response);
        const note = response.body;
        commit(ADD_NEW_NOTE, [note]);
        commit('invoice/PATCH_INVOICE', { id: note.visitId, key: 'mainFormId', value: note }, { root: true });
        // commit note._id to invoice
        return response;
      })
      .catch((err) => {
        console.log('note create err is ', err);
        return err;
      });
  },
  saveEditorUpdates({ commit, state }, { permissions, containerName }) {
    // console.log('*************** state.editor ', state.editor);
    let updateParams = {};
    if (containerName) {
      updateParams = Object.assign({}, state[containerName].editor);
    } else {
      updateParams = Object.assign({}, state.editor);
    }
    if (permissions) updateParams.permissions = permissions;
    return noteService.updateNotes(updateParams)
      .then((response) => {
        // console.log('note saveEditorUpdates response is ', response);
        const updatedNotes = response.body;
        // commit(ADD_NEW_NOTE, note);
        commit(UPDATE_NOTE_LIST, updatedNotes);
        // commit note._id to invoice
        return response;
      })
      .catch((err) => {
        console.log('note saveEditorUpdates err is ', err);
        return err;
      });
  },
  fetchAllCategories({ commit }) {
    commit(BEFORE_SET_ALL_NOTE_CATEGORIES);
    return notecategoryService.get()
      .then((response) => {
        // console.log('note cate response is ', response);
        const notecategories = response.body;
        commit(SET_ALL_NOTE_CATEGORIES, notecategories);
        return response;
      })
      .catch((err) => {
        console.log('note cate err is ', err);
        return err;
      });
  },
  fetchCategoriesByPatientId({ commit }, params) {
    commit(BEFORE_SET_ALL_NOTE_CATEGORIES_BY_PATIENTID);
    return noteService.getCategoryByPatientId(params)
      .then((response) => {
        const notecategories = response.body;
        commit(SET_ALL_NOTE_CATEGORIES_BY_PATIENTID, notecategories);
        return response;
      })
      .catch((err) => {
        console.log('note cate err is ', err);
        return err;
      });
  },
  createNewCategory({ commit }, params) {
    return new Promise((resolve, reject) => noteService.createNewCategory(params)
      .then((response) => {
        if (response.ok) {
          // console.log('createNewCategory newCategory is ', response.body);
          const newCategory = response.body;
          commit(ADD_NEW_CATEGORY, newCategory);
        } else {
          const errorMsg = response.body;
          console.log('err respones is ', response);
        }
        return resolve(response);
      })
      .catch((err) => {
        console.log('err is ', err);
      }));
  },
  deleteCategory({ commit, rootState }, catId) {
    return new Promise((resolve, reject) => noteService.deleteCategory(catId)
      .then((response) => {
        // console.log('response ', response);
        if (response.ok) {
          // console.log('deleteCategory response.body is ', response.body);
          if (rootState.notetemplate.notetemplatesByCategories[catId]) {
            for (let i = 0; i < rootState.notetemplate.notetemplatesByCategories[catId].length; i++) {
              const templateId = rootState.notetemplate.notetemplatesByCategories[catId][i];
              commit('notetemplate/DELETE_NOTETEMPLATE', templateId, { root: true });
            }
          }
          commit(DELETE_CATEGORY, catId);
        } else {
          const errorMsg = response.body;
          console.log('err respones is ', response);
        }
        return resolve(response);
      })
      .catch((err) => {
        console.log('err is ', err);
      }));
  },
  submitNote({ commit, state }, {
    containerName, noteId, publishing, base64,
  }) {
    const note = Object.assign({}, state.notesMapped[noteId]);
    // console.log('state[containerName].editor ', state[containerName].editor);
    // return;
    if (publishing) {
      note.draft = false;
      if (containerName) state[containerName].editor.viewing = true;
    }
    // console.log('state is ', state);
    // console.log('about to submit note! note is ', note);
    // if (note.typingData) {
    //   note.typingData = JSON.stringify(note.typingData);
    // }
    if (base64) {
      note.base64 = base64;
    }
    if (note.type === 'typing') {
      let { editor } = state;
      if (containerName) editor = state[containerName].editor;
      note.formDataOrder = editor.formDataOrder;
      note.formData = editor.formData;
      note.name = editor.name;
      note.categoryId = editor.categoryId;
      console.log('editor.signature ', editor.signature);
      if (editor.signature === undefined) {
        delete note.signature;
      } else {
        note.signature = editor.signature;
      }
    }
    // console.log('before saving note... note is ', note);
    // return;
    if (note.creatorId && (note.creatorId._id || note.creatorId.id)) {
      note.creatorId = note.creatorId._id || note.creatorId.id;
    }

    // validation for form

    if (note.formData && note.formDataOrder) {
      note.formDataOrder.forEach((element) => {
        if (note.formData[element].required && (note.formData[element].answer === undefined || note.formData[element].answer.length === 0) && note.formData[element].display !== 'hide') {
          console.log(`"${note.formData[element].label}" is mandatory field`);
          // throw new Error(`"${note.formData[element].label}" is mandatory field`);
        }
      });
    }
    return noteService.updateNote(note._id, note)
      .then((response) => {
        if (containerName) {
          commit(SET_IPD_CONTAINER_VALUE, {
            containerName,
            key: 'editor',
            value: {
              ...state[containerName].editor,
              pagesWithChanges: {},
              hasPermissionChanges: false,
            },
          });
        }
        const updatedNote = {
          _id: response.body._id || note._id,
          ...(response.body),
        };
        commit(NOTE_SAVED, updatedNote);
        console.log('response ', response);
        return response;
      })
      .catch((err) => {
        console.log('updateNote - err is ', err);
        throw new Error(err);
      });
  },
  createChartingNote({ commit }, {
    consultNoteId, creatorId, patientId, templateId, info,
  }) {
    // console.log('in createChartingNote atn - templateId, consultNoteId are ', templateId, consultNoteId);
    return noteService.createChartingNote(consultNoteId, {
      creatorId, patientId, templateId, info,
    })
      .then((res) => {
        // console.log('create charting note res is ', res);
        if (res.ok) {
          const updatedConsultNote = res.body[0];
          const newChartingNotes = res.body[1]; // an array of a single or multiple new notes
          commit('updateNote', { id: updatedConsultNote._id, key: 'chartingNoteIds', value: updatedConsultNote.chartingNoteIds });
          commit(ADD_NEW_NOTE, newChartingNotes);
          return newChartingNotes[0];
        }
      });
  },
  createChartingNoteWithoutMainform({ commit }, params) {
    return noteService.createChartingNoteWithoutMainform(params)
      .then((res) => {
        // console.log('create charting note res is ', res);
        if (res.ok) {
          // const updatedConsultNote = res.body[0];
          const newChartingNotes = res.body[0]; // an array of a single or multiple new notes
          // commit('updateNote', { id: updatedConsultNote._id, key: 'chartingNoteIds', value: updatedConsultNote.chartingNoteIds });
          commit(ADD_NEW_NOTE, newChartingNotes);
          return newChartingNotes[0];
        }
      });
  },
  addPagesFromTemplate({ commit }, { noteId, ...params }) {
    // console.log('in addPagesFromTemplate atn - templateId, noteId are ', templateId, noteId);
    return noteService.addPagesFromTemplate(noteId, { ...params, getSignedImageUrlForNewPages: true })
      .then((res) => {
        // console.log('addPagesFromTemplate res is ', _.cloneDeep(res));
        if (res.ok) {
          const updatedNote = res.body[0];
          const newNoteFromTemplate = res.body[1]; // an array of a single or multiple new notes (from the template)
          commit('updateNote', { id: updatedNote._id, key: 'otherPagesId', value: updatedNote.otherPagesId });
          commit(ADD_NEW_NOTE, newNoteFromTemplate);
          return res;
        }
        // else throw error
      });
  },
  createNotesFromIntegratedTestResults({ commit }, integratedTestId) {
    return noteService.createNotesFromIntegratedTestResults(integratedTestId)
      .then((res) => {
        console.log('createNotesFromIntegratedTestResults res is ', res);
        return res;
      });
  },
  createChartingFromTestResultsWithAdmission({ commit }, integratedTestId) {
    return noteService.createChartingFromTestResultsWithAdmission(integratedTestId)
      .then(res => res);
  },
  patchCategory({ commit }, { id, patchKeyValue }) { // patchKeyValue can be { colorLabel: '#777777' }
    commit(BEFORE_UPDATE_CATEGORIES);
    return new Promise((resolve, reject) => noteService.patchNotecategory(id, patchKeyValue)
      .then((response) => {
        // console.log('patchCategory response is ', response);
        if (response.ok) {
          commit(UPDATE_CATEGORIES_SUCCESS, response.body);
        }
        return resolve(response);
      })
      .catch((err) => {
        commit(UPDATE_CATEGORIES_ERROR, err);
      }));
  },
  updateCategoriesOrder({ commit }, { id, oldSiblingId, newSiblingId }) {
    // console.log('in updateCategoriesOrder action - params are ', id, oldSiblingId, newSiblingId);
    commit(BEFORE_UPDATE_CATEGORIES);
    return noteService.reorderNotecategories(id, { oldSiblingId, newSiblingId })
      .then((res) => {
        commit(UPDATE_CATEGORIES_SUCCESS);
        // console.log('updateNotecategories res is ', res);
      })
      .catch((err) => {
        commit(UPDATE_CATEGORIES_ERROR, err);
      });
  },
  patchNote({ commit }, { id, data }) {
    noteService.patchNote(id, data)
      .then((res) => {
        if (res.ok) {
          commit('updateNoteKeys', { id, updatedKeyValues: data });
        } else {
          console.log('patchNote > res:', res);
        }
      })
      .catch((err) => {
        console.log('patchNote > err:', err);
      });
  },
  patchNotes({ commit }, data) {
    return noteService.patchNotes(data)
      .then((res) => {
        if (res.ok) {
          commit(PATCH_NOTES, data);
        } else {
          console.log('patchNote > res:', res);
        }
        return res;
      })
      .catch((err) => {
        console.log('patchNote > err:', err);
      });
  },
  splitNote({ commit }, { id, data }) {
    return noteService.splitNote(id, data)
      .then((res) => {
        if (res.ok) {
          commit(ADD_NEW_NOTE, res.data[0]);
          commit(PATCH_NOTES, res.data[1]);
          return res;
        }
        return res;
      })
      .catch((err) => {
        console.log('splitNote > err:', err);
      });
  },
  bulkDeleteNotes({ commit }, payload) {
    return noteService.bulkDelete(payload)
      .then((res) => {
        commit(DELETE_NOTES, payload.noteIds);
        return res.data;
      });
  },
  updateNoteById({ commit }, params) {
    return new Promise((resolve, reject) => noteService.fetchOne(params)
      .then((response) => {
        commit('updateNoteKeysV2', { id: params.id, updatedKeyValues: response.body });
        return resolve(response);
      })
      .catch(reject));
  },
  print({ commit }, payload) {
    commit(SET_PRINT, payload);
  },
};

const getters = {
  all: _state => _state.all,
  notes: _state => _state.notes,
  notesMapped: _state => _state.notesMapped,
  notesByCategories2: (_state) => {
    const output = {};
    Object.values(_state.notesMapped).forEach((note) => {
      if (note.type !== 'mainForm' && !note.coverPageId) {
        if (!output[note.categoryId]) {
          output[note.categoryId] = [];
        }
        output[note.categoryId].push(note._id);
      }
    });
    return output;
  },
  notesByCategories: _state => _state.notesByCategories,
  notesByVisitId: _state => _state.notesByVisitId,
  notesWithNoVisitId: _state => _state.notesWithNoVisitId,
  notecategories: _state => _state.notecategories,
  categoriesIdsOnly: _state => _state.categoriesIdsOnly,
  categoriesMapped: _state => _state.categoriesMapped,
  categoriesMappedByPatientId: _state => _state.categoriesMappedByPatientId,
  categoriesOptions: _state => _state.categoriesOptions,
  trashCategoryId: _state => _state.trashCategoryId,
  uncategorizedCategoryId: _state => _state.uncategorizedCategoryId,
  selectedTemplateId: _state => _state.selectedTemplateId,
  presetChartingSortOptions: _state => _state.presetChartingSortOptions,
  mainFormsChangeLogs: _state => _state.mainFormsChangeLogs,
  cont1: _state => _state.cont1,
  cont2: _state => _state.cont2,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
