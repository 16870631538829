var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.featureFlags.isMaKuang
        ? _c("TaxInvoiceMakaung", {
            attrs: {
              isCategorized: true,
              selectedPatient: _vm.selectedPatient,
              print: _vm.print,
              general: _vm.generalConfig,
              printConfig: _vm.invoiceConfig.printing,
              virtualCurrencyConfig: _vm.virtualCurrencyConfig,
              convertToVirtualCurrency: _vm.convertToVirtualCurrency,
              includeGST: _vm.invoiceConfig.includeGST,
              invoiceConfig: _vm.invoiceConfig,
              roundOff: _vm.roundingMethod,
              redeem: _vm.redeem,
              multipleICD10Enabled: _vm.patientConfig.multipleICD10Recording,
              inventoryConfig: _vm.inventoryConfig
            }
          })
        : _vm.featureFlags.tcmMode
        ? _c("tax-invoice-tcm-component", {
            attrs: {
              isCategorized: true,
              selectedPatient: _vm.selectedPatient,
              print: _vm.print,
              general: _vm.generalConfig,
              printConfig: _vm.invoiceConfig.printing,
              virtualCurrencyConfig: _vm.virtualCurrencyConfig,
              convertToVirtualCurrency: _vm.convertToVirtualCurrency,
              includeGST: _vm.invoiceConfig.includeGST,
              invoiceConfig: _vm.invoiceConfig,
              roundOff: _vm.roundingMethod,
              redeem: _vm.redeem,
              multipleICD10Enabled: _vm.patientConfig.multipleICD10Recording,
              categoryBased: _vm.invoiceConfig.categoryBased || false,
              invoiceCategories: _vm.invoiceConfig.invoiceCategory || []
            }
          })
        : _vm.useBillingGroupTemplate
        ? _c("bgrimm-tax-invoice-component", {
            attrs: {
              isCategorized: true,
              selectedPatient: _vm.selectedPatient,
              print: _vm.print,
              general: _vm.generalConfig,
              printConfig: _vm.invoiceConfig.printing,
              virtualCurrencyConfig: _vm.virtualCurrencyConfig,
              convertToVirtualCurrency: _vm.convertToVirtualCurrency,
              includeGST: _vm.invoiceConfig.includeGST,
              roundOff: _vm.roundingMethod,
              redeem: _vm.redeem,
              multipleICD10Enabled: _vm.patientConfig.multipleICD10Recording
            }
          })
        : _vm.featureFlags.tphInvoice
        ? _c("tax-invoice-tph", {
            attrs: {
              isCategorized: true,
              selectedPatient: _vm.selectedPatient,
              print: _vm.print,
              general: _vm.generalConfig,
              printConfig: _vm.invoiceConfig.printing,
              virtualCurrencyConfig: _vm.virtualCurrencyConfig,
              convertToVirtualCurrency: _vm.convertToVirtualCurrency,
              includeGST: _vm.invoiceConfig.includeGST,
              roundOff: _vm.roundingMethod,
              redeem: _vm.redeem,
              multipleICD10Enabled: _vm.patientConfig.multipleICD10Recording
            }
          })
        : _vm.featureFlags.drclinique
        ? _c("tax-invoice-dr-clinique", {
            attrs: {
              isCategorized: true,
              selectedPatient: _vm.selectedPatient,
              print: _vm.print,
              general: _vm.generalConfig,
              printConfig: _vm.invoiceConfig.printing,
              virtualCurrencyConfig: _vm.virtualCurrencyConfig,
              convertToVirtualCurrency: _vm.convertToVirtualCurrency,
              includeGST: _vm.invoiceConfig.includeGST,
              roundOff: _vm.roundingMethod,
              redeem: _vm.redeem,
              multipleICD10Enabled: _vm.patientConfig.multipleICD10Recording
            }
          })
        : _vm.selectedProvider
        ? _c("tax-invoice-component-provider", {
            attrs: {
              isCategorized: true,
              selectedProvider: _vm.selectedProvider,
              print: _vm.print,
              general: _vm.generalConfig,
              printConfig: _vm.invoiceConfig.printing,
              virtualCurrencyConfig: _vm.virtualCurrencyConfig,
              convertToVirtualCurrency: _vm.convertToVirtualCurrency,
              includeGST: _vm.invoiceConfig.includeGST,
              roundOff: _vm.roundingMethod,
              redeem: _vm.redeem,
              multipleICD10Enabled: _vm.patientConfig.multipleICD10Recording
            }
          })
        : _c("tax-invoice-component", {
            attrs: {
              isCategorized: true,
              selectedPatient: _vm.selectedPatient,
              print: _vm.print,
              general: _vm.generalConfig,
              printConfig: _vm.invoiceConfig.printing,
              virtualCurrencyConfig: _vm.virtualCurrencyConfig,
              convertToVirtualCurrency: _vm.convertToVirtualCurrency,
              includeGST: _vm.invoiceConfig.includeGST,
              roundOff: _vm.roundingMethod,
              redeem: _vm.redeem,
              multipleICD10Enabled: _vm.patientConfig.multipleICD10Recording
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }