var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("typing-note-print", {
        attrs: {
          generalConfig: _vm.general,
          integratedTestConfig: _vm.integratedTest,
          print: _vm.print.note,
          featureFlags: _vm.featureFlags
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }