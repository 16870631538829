// import config from '../config';
import api from './api';


function getAll(params) {
  const q = [];
  Object.keys(params).forEach((key) => {
    q.push(`${key}=${params[key]}`);
  });
  console.log('q is ', q);
  const url = `/notification/?${q.join('&')}`;
  return api.get(url);
}

function getAllNotificationTypes() {
  console.log('in getAllNotificationTypes...');
  const url = '/notification-type';
  return api.get(url);
}

function patch({ _id, ...param }) {
  console.log('id, param ', _id, param);
  const url = `/notification/${_id}`;
  return api.patch(url, param);
}
function updateAll({ _id, ...param }) {
  console.log('id, param1 ', _id, param);
  const url = `/notification/updateAll/${_id}`;
  return api.patch(url, param);
}
export default {
  getAll,
  getAllNotificationTypes,
  patch,
  updateAll,
};
