// import config from '../config';
import api from './api';

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
  localStorage.removeItem('access_token');
  localStorage.removeItem('xsrf');
  localStorage.removeItem('orgCode');
  localStorage.removeItem('accountSwitcher');
  let queueFilter = localStorage.getItem('queueFilter');
  if (queueFilter) {
    queueFilter = JSON.parse(queueFilter);
    queueFilter.clinicCode = '';
    localStorage.setItem('queueFilter', JSON.stringify(queueFilter));
  }
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    let error = (data && data.message) || response.statusText;

    if (!response.ok) {
      if (response.status === 401) {
        error = 'Invalid Credentials';
        // auto logout if 401 response returned from api
        logout();
        // eslint-disable-next-line
        // location.reload(true);
      }
      return Promise.reject(error);
    }
    return data;
  });
}

function login(orgcode, username, password) {
  return api.post('/auth/login', { orgCode: orgcode, email: username, password })
    .then(handleResponse)
    .then((res) => {
      const {
        orgCode, token, user, accountSwitcher, functions, nav, grabscan = {},
      } = res;
      localStorage.setItem('orgCode', orgCode);
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('access_token', token);
      localStorage.setItem('xsrf', token);
      localStorage.setItem('acl__nav', JSON.stringify(nav));
      localStorage.setItem('grabscan', JSON.stringify(grabscan));
      localStorage.setItem('acl__urls', JSON.stringify(functions.filter(func => func.url).map(func => func.url)));
      localStorage.setItem('accountSwitcher', JSON.stringify(accountSwitcher));
      const hasLimitProviderAccess = functions.filter(func => func.functionName === 'Limit Provider Access');
      localStorage.setItem('hasLimitProviderAccess', (hasLimitProviderAccess.length > 0 ? 1 : 0));
      const acl__childrenFnIds = {};
      localStorage.setItem('acl__fnids', JSON.stringify(functions.map((func) => {
        if (func.parentFnId && func.parentFnId !== undefined && func.parentFnId !== '') {
          acl__childrenFnIds[func.functionId] = 1;
          return false;
        }
        return func.functionId;
      })));
      localStorage.setItem('acl__childrenFnIds', JSON.stringify(acl__childrenFnIds));
      return res;
    });
}
function switchAccount(orgcode, username) {
  return api.post('/auth/switch-account', { orgCode: orgcode, email: username })
    .then(handleResponse)
    .then((res) => {
      const {
        orgCode, token, user, functions, nav, grabscan = {},
      } = res;
      localStorage.setItem('orgCode', orgCode);
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('access_token', token);
      localStorage.setItem('xsrf', token);
      localStorage.setItem('acl__nav', JSON.stringify(nav));
      localStorage.setItem('grabscan', JSON.stringify(grabscan));
      localStorage.setItem('acl__urls', JSON.stringify(functions.filter(func => func.url).map(func => func.url)));
      const hasLimitProviderAccess = functions.filter(func => func.functionName === 'Limit Provider Access');
      localStorage.setItem('hasLimitProviderAccess', (hasLimitProviderAccess.length > 0 ? 1 : 0));
      const acl__childrenFnIds = {};
      localStorage.setItem('acl__fnids', JSON.stringify(functions.map((func) => {
        if (func.parentFnId && func.parentFnId !== undefined && func.parentFnId !== '') {
          acl__childrenFnIds[func.functionId] = 1;
          return false;
        }
        return func.functionId;
      })));
      localStorage.setItem('acl__childrenFnIds', JSON.stringify(acl__childrenFnIds));
      return res;
    });
}
function validateOTP(otp) {
  // eslint-disable-next-line
  let userInfo = JSON.parse(localStorage.getItem('user'));
  const { email } = userInfo;

  return api.get(`/two-factor/${email}/${otp}`)
    .then(handleResponse)
    .then(res => res);
}

// function register(user) {
//   const requestOptions = {
//     method: 'POST',
//     headers: { 'Content-Type': 'application/json' },
//     body: JSON.stringify(user),
//   };

//   return fetch(`${config.API_BASE_URL}/users/register`, requestOptions).then(handleResponse);
// }

function getAll(params) {
  const t = `t=${new Date().getTime()}`;
  const q = [];
  q.push(t);
  Object.keys(params).forEach((key) => {
    q.push(`${key}=${params[key]}`);
  });
  const url = `/users/?${q.join('&')}`;
  return api.get(url)
    // .then(handleResponse)
    .then(res => res.data);
}

function patchUser(id, data) {
  return api.patch(`/users/${id}`, data);
}

function updateUser(data) {
  return api.put(`/users/${data.id}`, data);
}

function createNew(data) {
  return api.post('/users', data);
}

function changePassword(data) {
  return api.put('/users/me/password', data);
}

function bulkDestroy(data) {
  return api.post('/users/bulk', data);
}

function sendResetPasswordLink(data) {
  return api.post('/password-resets', data);
}

function resetPassword(data) {
  return api.put('/password-resets', data);
}

function approveFunctionAccess(username, password, functionId) {
  return api.post('/auth/approval', { orgCode: localStorage.getItem('orgCode'), email: username, password })
    .then((res) => {
      if (res.ok) {
        const { functions } = res.body;
        return functions.find(f => f.functionId === functionId);
      }
      return false;
    });
}

const reAuthForPayment = (username, password) => {
  return api.post('/auth/payment', { orgCode: localStorage.getItem('orgCode'), email: username, password })
    .then((res) => {
      if (res.ok) {
        return res.body;
      }
      return false;
    });
}

function digitalSignatureVerification(username, password) {
  return api.post('/auth/approval', { orgCode: localStorage.getItem('orgCode'), email: username, password })
    .then((res) => {
      if (res.ok) {
        return res.body;
      }
      return false;
    });
}
// function getById(id) {
//   const requestOptions = {
//     method: 'GET',
//     headers: authHeader(),
//   };

//   return fetch(`${config.API_BASE_URL}/users/${id}`, requestOptions).then(handleResponse);
// }

// function update(user) {
//   const requestOptions = {
//     method: 'PUT',
//     headers: { ...authHeader(), 'Content-Type': 'application/json' },
//     body: JSON.stringify(user),
//   };

//   return fetch(`${config.API_BASE_URL}/users/${user.id}`, requestOptions).then(handleResponse);
// }

// // prefixed function name with underscore because delete is a reserved word in javascript
// function udelete(id) {
//   const requestOptions = {
//     method: 'DELETE',
//     headers: authHeader(),
//   };

//   return fetch(`${config.API_BASE_URL}/users/${id}`, requestOptions).then(handleResponse);
// }

export default {
  login,
  validateOTP,
  logout,
  createNew,
  // register,
  getAll,
  changePassword,
  patchUser,
  updateUser,
  bulkDestroy,
  sendResetPasswordLink,
  resetPassword,
  approveFunctionAccess,
  digitalSignatureVerification,
  reAuthForPayment,
  // getById,
  // update,
  // delete: udelete,
  switchAccount,
};
