<template>
  <CreditNotePrintMakuang
    v-if="featureFlags.isMaKuang"
    :patient="patient"
    :creditNote="selectedCreditNote"
    :invoiceConfig="invoiceConfig"
  />
  <credit-note-print
    v-else
    :patient="patient"
    :creditNote="selectedCreditNote"
  />
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import CreditNotePrint from '../../components/Print/CreditNotePrint.vue';
import CreditNotePrintMakuang from '../../components/Print/CreditNotePrintMakaung.vue'

import { mapGetters } from 'vuex';
const creditMapGetters = createNamespacedHelpers('credit');

export default {
  name: 'CreditNotePrintIndex',
  components: {
    CreditNotePrint,
    CreditNotePrintMakuang
  },
  computed: {
    ...creditMapGetters.mapGetters(['selectedCreditNote']),
    ...mapGetters('config', {
      invoiceConfig: 'invoice',
      featureFlags: 'featureFlags',
      virtualCurrencyConfig: 'virtualCurrency',
    }),
    patient() {
      return this.$store.state.patient.selectedPatient;
    },
  },
  //   created() {
  //     this.$store.dispatch('patient/getPatient', this.selectedCreditNote.patient)
  //       .then(() => {
  //         this.patientDataReady = true;
  //       });
  //   },
};
</script>
