<template>
  <div>
    <drug-label-component
      v-if="featureFlags.bgrimm"
      :printLocal='printLocal'
      :disableAutoPrint="false"
      :medItem='print'
      :generalConfig='general'
      :patient='selectedPatient'
    />
    <template v-else-if="featureFlags.drclinique">
      <div class="container-fluid">
        <medical-label-dr-clinique
          :clinicName='general.clinicName'
          :clinicAddress='general.address'
          :medName='print.name'
          :medQty='print.qty'
          :medStrength='print.strength'
          :medUnit='print.unit'
          :medDosage='print.dosage'
          :medExpiry='print.selectedBatch.expiry || ""'
          :medPrecautionOne='print.precaution1'
          :medPrecautionTwo='print.precaution2'
          :patientName='selectedPatient.name'
          :patientNric='selectedPatient.nric'
        />
      </div>
    </template>
    <template v-else>
      <div class="container-fluid">
        <medical-label-component
          v-if="!print.adHocCompounding"
          :clinicName='general.clinicName'
          :description="print.description"
          :clinicAddress='general.address'
          :medName='print.name'
          :medQty='print.qty'
          :medStrength='print.strength'
          :medUnit='print.unit'
          :medDosage='print.dosage'
          :medExpiry='print.selectedBatch.expiry || ""'
          :selectedBatch="print.selectedBatch"
          :medPrecautionOne='print.precaution1'
          :medPrecautionTwo='print.precaution2'
          :patientName='selectedPatient.name'
          :patientNric='selectedPatient.nric'
          :patientDetails='selectedPatient'
        />
        <medical-label-component
        v-else
          :clinicName='general.clinicName'
          :clinicAddress='general.address'
          :medName='print.adHocCompoundName'
          :medQty='print.qty'
          :medStrength='print.strength'
          :medUnit='print.adHocCompoundUnit'
          :medDosage='print.adHocCompoundDosage'
          :medExpiry='print.selectedBatch.expiry || ""'
          :selectedBatch="print.selectedBatch"
          :medPrecautionOne='print.adHocCompoundPrecaution1'
          :medPrecautionTwo='print.adHocCompoundPrecaution2'
          :patientName='selectedPatient.name'
          :patientNric='selectedPatient.nric'
          :patientDetails='selectedPatient'
        />
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MedicalLabelComponent from '@/components/Print/MedicalLabelComponent.vue';
import MedicalLabelDrClinique from '@/components/Print/MedicalLabelDrClinique.vue';
import DrugLabelComponent from '@/components/Print/BGrimmDrugLabelV2.vue';

export default {
  name: 'PrintMedicalLabel',
  data() {
    return {
      printLocal: false,
    };
  },
  computed: {
    ...mapGetters('patient', ['selectedPatient']),
    ...mapGetters('invoice', ['print']),
    ...mapGetters('config', ['general', 'featureFlags']),
    isTCMMode() {
      return !!this.featureFlags.tcmMode;
    },
  },
  components: {
    MedicalLabelComponent,
    MedicalLabelDrClinique,
    DrugLabelComponent,
  },
  created() {
    if (this.$route.query && this.$route.query['print-local']) {
      this.printLocal = true;
    } else {
      this.printLocal = false;
    }
  },
};
</script>
