import _ from 'lodash';
import appointmentService from '@/services/appointment.service';
import {
  SET_APPOINTMENT,
  UPDATE_APPOINTMENT,
  UPDATE_EMAIL_CONTENT,
  UPDATE_SMS_CONTENT,
  UPDATE_NOTIFY_CONFIG,
  // below is for scheduler (templates and rolled-out schedules)
  SET_SCHEDULING_TEMPLATE,
  SET_ALL_WEEKLY_SCHEDULE_TEMPLATES,
  UPDATE_WEEKLY_SCHEDULE_TEMPLATE,
  PATCH_WEEKLY_SCHEDULE_TEMPLATE,
  SET_ALL_WEEKLY_SCHEDULES,
  SET_SELECTED_SLOT,
  SET_SELECTED_SCHEDULE,
} from './type.mutation';

const state = {
  selectedAppointment: {},
  emailContent: '',
  smsContent: '',
  notificationData: {},
  // below are for scheduler
  allTemplates: [],
  selectedWeeklyScheduleTemplate: {},
  selectedAppointmentSlot: {},
  allSchedules: [],
  selectedSchedule: [],
};

/* eslint-disable no-param-reassign */
const mutations = {
  [SET_SELECTED_SLOT](_state, payload) {
    _state.selectedAppointmentSlot = { ...(payload || {}) };
  },
  [SET_SELECTED_SCHEDULE](_state, payload = {}) {
    _state.selectedSchedule = _.cloneDeep(payload);
  },
  [SET_APPOINTMENT](_state, payload) {
    _state.selectedAppointment = payload;
  },
  [UPDATE_APPOINTMENT](_state, payload) {
    if (Array.isArray(_state.selectedAppointment)) {
      if (payload.self) {
        _state.selectedAppointment = _state.selectedAppointment.map((element, idx) => {
          if(idx === payload.index) {
            element[payload.key] = payload.value;
          }
          return element
        })
      } else {
        _state.selectedAppointment = _state.selectedAppointment.map((element, idx) => {
          element[payload.key] = payload.value;
          return element
        })
      }
    } else {
      _state.selectedAppointment = { ..._state.selectedAppointment, [payload.key]: payload.value };
    }
  },
  [UPDATE_EMAIL_CONTENT](_state, payload) {
    _state.emailContent = payload;
  },
  [UPDATE_SMS_CONTENT](_state, payload) {
    _state.smsContent = payload;
  },
  [UPDATE_NOTIFY_CONFIG](_state, payload) {
    _state.notificationData = { ..._state.notificationData, [payload.key]: payload.value };
  },
  /*
  BELOW ARE FOR WEEKLY SCHEDULES (AND TEMPLATES)
  */
  [SET_ALL_WEEKLY_SCHEDULE_TEMPLATES](_state, payload) {
    _state.allTemplates = payload;
  },
  [UPDATE_WEEKLY_SCHEDULE_TEMPLATE](_state, payload) {
    _state.selectedWeeklyScheduleTemplate = payload;
  },
  [PATCH_WEEKLY_SCHEDULE_TEMPLATE](_state, payload) {
    _state.selectedWeeklyScheduleTemplate = {
      ..._state.selectedWeeklyScheduleTemplate,
      [payload.key]: payload.value,
    };
  },
  [SET_ALL_WEEKLY_SCHEDULES](_state, payload) {
    _state.allSchedules = payload;
  },
};

const actions = {
  async selectAppointment({ commit }, id) {
    commit(SET_APPOINTMENT, {});
    const resp = await appointmentService.getById(id);
    if (resp.success) {
      commit(SET_APPOINTMENT, resp.data);
    }
  },
  async selectAppointments({ commit }, ids) {
    commit(SET_APPOINTMENT, []);
    const appintment = [];
    for (const id in ids) {
      const resp = await appointmentService.getById(ids[id]);
      if (resp.success) {
        appintment.push(resp.data);
      }
    }
    commit(SET_APPOINTMENT, appintment);
  },
  setAppointmentSlot({ commit }, payload) {
    commit(SET_SELECTED_SLOT, payload);
  },
  setAppointment({ commit }, payload) {
    commit(UPDATE_APPOINTMENT, payload);
  },
  clearSelectedAppointment({ commit }) {
    commit(SET_APPOINTMENT, {});
  },
  setNotificationEmailContent({ commit }, payload) {
    commit(UPDATE_EMAIL_CONTENT, payload);
  },
  setNotificationSmsContent({ commit }, payload) {
    commit(UPDATE_SMS_CONTENT, payload);
  },
  setNotificationData({ commit }, payload) {
    commit(UPDATE_NOTIFY_CONFIG, payload);
  },
  /*

  BELOW ARE FOR APPT WEEKLY SCHEDULER

  */
  listScheduleTemplates({ commit }, query) {
    const filter = query || {};
    appointmentService.listScheduleTemplates(filter)
      .then((res) => {
        commit(SET_ALL_WEEKLY_SCHEDULE_TEMPLATES, res.data);
      });
  },
  async selectSchedulingTemplate({ commit }, event) {
    commit(SET_SCHEDULING_TEMPLATE, {});
    commit(SET_SCHEDULING_TEMPLATE, event.id);
  },
  setScheduleTemplate({ commit }, payload) {
    commit(UPDATE_WEEKLY_SCHEDULE_TEMPLATE, payload);
  },
  setSelectedSchedule({ commit }, payload) {
    commit(SET_SELECTED_SCHEDULE, payload);
  },
  patchSchedule({ commit }, payload) {
    return appointmentService.patchSchedule(payload._id, payload)
      .then((result) => {
        commit(SET_SELECTED_SCHEDULE, result.data);
        return result;
      }).catch(e => ({ success: false }));
  },
  createScheduleTemplate({ commit, state: _state }) {
    const data = {
      ..._state.selectedWeeklyScheduleTemplate,
      services: (_state.selectedWeeklyScheduleTemplate).services.map(d => d._id),
    };
    return appointmentService.createScheduleTemplate(data)
      .then((res) => {
        commit(UPDATE_WEEKLY_SCHEDULE_TEMPLATE, res.data);
        return res;
      });
  },
  saveScheduleTemplate({ commit, state: _state }) {
    const data = {
      ..._state.selectedWeeklyScheduleTemplate,
      services: (_state.selectedWeeklyScheduleTemplate).services.map(d => d._id),
    };
    return appointmentService.saveScheduleTemplate(_state.selectedWeeklyScheduleTemplate._id, data)
      .then((res) => {
        commit(UPDATE_WEEKLY_SCHEDULE_TEMPLATE, res.data);
        return res;
      });
  },
  listSchedules({ commit }, query) {
    const filter = query || {};
    return appointmentService.listSchedules(filter)
      .then((res) => {
        commit(SET_ALL_WEEKLY_SCHEDULES, res.data);
        return res;
      });
  },
};

const getters = {
  SELECTED_SCHEDULE: _state => _state.selectedSchedule,
  SELECTED_APPOINTMENT_SLOT: _state => _state.selectedAppointmentSlot,
  SELECTED_APPOINTMENT: _state => _state.selectedAppointment,
  NOTIFICATION_DATA: _state => _state.notificationData,
  EMAIL_CONTENT: _state => _state.emailContent,
  SMS_CONTENT: _state => _state.smsContent,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
