<template>
  <div class="mb-5" style="margin: 20px"
    :class="{'previewPrint': preview}">
    <div class="row mb-3">
      <div class="col-12">
        <img v-if="printConfig.clinicLogo"
          :style="{ marginLeft:`${logoMarginLeft}px`, width:`${logoWidth}px` }"
          :src="this.companyClinicLogo">
        <h5>{{ this.companyClinicName }}</h5>
        <h6 class="mt-2" v-if="printConfig.clinicLocalName">{{ this.companyClinicLocalName }}</h6>
        <p v-if="printConfig.clinicAdd"
          class="mb-1 address-font"
          style="white-space: pre-line;">
          {{ invoiceAddress }}
        </p>
        <p v-if="printConfig.clinicLocalAddress"
          class="mb-3 address-font"
          style="white-space: pre-line; line-height: .5rem;">
          {{ invoiceLocalAddress }}
        </p>

        <p class="mb-3 address-font" v-if="general.telephone">Tel: {{ general.telephone }}</p>
        <h3 class="mb-3 text-center text-uppercase">
          <u>
            <span v-if="withTaxes">{{ $t('general.tax') }}</span>
            {{ $t('general.invoice') }}
          </u>
        </h3>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-5">
        <div class="weight-600 mb-2">{{ selectedPatient.name }} - {{ selectedPatient.nric }}</div>
        <template v-if="printConfig.patientLocalName">
          <div class="weight-600 mb-2">{{ selectedPatient.localName }}</div>
        </template>
        <div class="weight-600 mb-2" v-if="print.pricingScheme">{{ print.pricingScheme.corpName }} - {{ print.pricingScheme.schemeName }}</div>
        <template v-if="printConfig.patientAdd">
          <div v-for="address in splitAddress" :key="address.id">
            {{ address }}
          </div>
        </template>
      </div>
      <div class="col-4 text-right">
        <div v-for="category in invoiceCategory" :key="category.id">
          {{ category.label }}
        </div>
      </div>
      <div class="col-3">
        <div>:&nbsp; {{ print.invoiceNo }} </div>
        <div>:&nbsp; {{ print.date | formatVDDate({ sourceFormat: 'X', lang: $i18n.locale, format: 'DD MMM YYYY'}) }}</div>
        <div v-if="printConfig.invoiceDateBE">:&nbsp; {{ print.date | formatVDDate({ sourceFormat: 'X', lang: 'th', format: 'DD MMM YYYY'}) }}</div>
        <div v-if="general.useLocationAddress">:&nbsp; {{ this.selectedClinic.regNo }}</div>
        <div v-if="!!general.companyRegNo && !general.useLocationAddress">:&nbsp; {{ general.companyRegNo }} </div>
        <div v-if="general.useLocationAddress">:&nbsp; {{ this.selectedClinic.taxNo }}</div>
        <div v-if="!!printConfig.regNumber && !general.useLocationAddress">:&nbsp; {{ general.gstRegNo }}</div>
        <div v-if="printConfig.docName">:&nbsp; {{ provider.name }}</div>
        <div v-if="printConfig.docQual">:&nbsp; {{ provider.qualifications }}</div>
        <div v-if="printConfig.therapistName">:&nbsp; {{ print.therapist && print.therapist.name }}</div>
      </div>
    </div>

    <!-- table header -->
    <div class="row weight-600 bd-top bd-bottom">
      <div :class="{'col-2': printConfig.lineItemsDesc, 'col-4': !printConfig.lineItemsDesc}">
        {{ $t('general.itemName') }}
      </div>
      <div v-if="printConfig.lineItemsDesc"
        class="col-2">
        {{ $t('general.description') }}
      </div>
      <div  class="col-2 text-center">
        {{ $t('general.qty') }}
      </div>
      <div class="col-2 text-right">{{ $t('general.unitPrice') }}</div>
      <div v-if="hasAtLeastOneItemWithDiscount" class="col-1 text-right">{{ $t('general.discount') }}</div>
      <div v-else class="col-1 text-right"></div>
      <div v-if="withTaxes"
        class="col-1 text-right">
        {{ $t('general.tax') }}
      </div>
      <div v-else class="col-1 text-right"></div>
      <div class="col-2 text-right">{{ $t('general.total') }}</div>
    </div>

    <!-- table body for categorized invoice -->
    <div v-if="isCategorized">
      <div v-for="(category, idx) in categorized" :key="category.key">
        <div class="mt-3 row">
          <div v-if="!instructionsBuilderFeatureFlag" class="col-12 weight-600 mb-2">
            {{ $t(categorizedNameMapTranslateId[idx]) }}
          </div>
          <div v-if="instructionsBuilderFeatureFlag" class="col-2 weight-600 mb-2" style="padding-right:0px">{{idx}}</div>
          <div
            v-if="instructionsBuilderFeatureFlag"
            class="weight-600 mb-2"
            style="padding-right:0px"
            :class="{'col-3': printConfig.lineItemsDesc, 'col-2': !printConfig.lineItemsDesc}"
          />
          <div
            v-if="instructionsBuilderFeatureFlag"
            style="padding-right:0px"
            :class="{'col-1 text-left': printConfig.lineItemsDesc, 'col-2 text-center': !printConfig.lineItemsDesc }"
          />
          <div v-if="instructionsBuilderFeatureFlag && (idx === 'Medicine' || idx === 'Wyn')" class="col-2 text-right">{{ region.currency }}{{totalMedicinePrice | to2Decimal}}</div>
          <div v-if="instructionsBuilderFeatureFlag" class="col-1 text-right"></div>
          <!-- <div v-if="instructionsBuilderFeatureFlag && !hasAtLeastOneItemWithDiscount" class="col-1 text-right"></div> -->
          <div v-if="instructionsBuilderFeatureFlag" class="col-1 text-right"></div>
          <div v-if="instructionsBuilderFeatureFlag && (idx === 'Medicine' || idx === 'Wyn')" class="col-2 text-right">{{region.currency}}{{totalMedicineAfterDiscountPrice | to2Decimal}}</div>
        </div>
        <span v-if="!instructionsBuilderFeatureFlag || (instructionsBuilderFeatureFlag && (idx !== 'Medicine' || idx !== 'Wyn'))">
        <div class="row" v-for="item in category" :key="item.key">
          <div :class="{'col-2': printConfig.lineItemsDesc, 'col-4': !printConfig.lineItemsDesc}">
            {{ item.name }}
            <template v-if="['Medicine', 'Wyn','Consumables', 'Expendables', 'Product'].includes(item.inventory)">
              <br/>
              <span>
                - {{ (item.selectedBatch && item.selectedBatch.number) || '' }}
              </span>
            </template>
            <div v-for="(i, k) in item.subInventory" :key="k">
              <span v-if="i.givenId">- <small>{{ i.givenId }} / {{ i.name }}</small></span>
            </div>
          </div>
          <div v-if="printConfig.lineItemsDesc"
            class="col-2">
            {{ item.description }}
          </div>
          <div class="col-1 text-right" style="padding-right:0px">{{ item.qty }}</div>
          <div class="col-1 text-left" style="padding-left:5px">{{ item.unit }}</div>
          <div class="col-2 text-right">
            {{ item.price |
              toVirtualCurrency(virtualCurrencyConfig.exchangeRate, convertToVirtualCurrency) |
              to2Decimal(region.decimal) |
              thousandSeparator(region.thousandSeparator) }}
          </div>
          <div v-if="hasAtLeastOneItemWithDiscount" class="col-1 text-right">
            <span v-if="item.discountType === '%'">
              {{ item.discount | to2Decimal(region.decimal) | thousandSeparator(region.thousandSeparator) }}%
            </span>
            <span v-else-if="item.discountType === '$'">
              {{ convertToVirtualCurrency ? '' : region.currency }}
              {{ item.discount |
                toVirtualCurrency(virtualCurrencyConfig.exchangeRate, convertToVirtualCurrency) |
                to2Decimal(region.decimal) |
                thousandSeparator(region.thousandSeparator) }}
            </span>
          </div>
          <div v-else class="col-1 text-right" />
          <div v-if="withTaxes"
            class="col-1 text-right">
            {{ item.tax }}%
          </div>
          <div v-else class="col-1 text-right"></div>
          <div class="col-2 text-right">
            {{ item.afterTaxPrice |
              toVirtualCurrency(virtualCurrencyConfig.exchangeRate, convertToVirtualCurrency) |
              to2Decimal(region.decimal) |
              thousandSeparator(region.thousandSeparator) }}
          </div>
        </div>
        </span>
      </div>
    </div>

    <!-- table body for uncategorized invoice -->
    <div v-else>
      <div v-for="item in print.items" :key="item.key" class="row">
        <div :class="{'col-2': printConfig.lineItemsDesc, 'col-4': !printConfig.lineItemsDesc}">
          {{ item.name }}
          <template v-if="['Medicine', 'Wyn', 'Consumables', 'Expendables', 'Product'].includes(item.inventory)">
            <br/>
            <span>
              - {{ (item.selectedBatch && item.selectedBatch.number) || '' }}
            </span>
          </template>
          <div v-for="(i, k) in item.subInventory" :key="k">
            <span v-if="i.givenId">- <small>{{ i.givenId }} / {{ i.name }}</small></span>
          </div>
        </div>
        <div v-if="printConfig.lineItemsDesc"
          class="col-2">
          {{ item.description }}
        </div>
        <div class="col-1 text-right" style="padding-right:0px">{{ item.qty }}</div>
        <div class="col-1 text-left" style="padding-left:5px">{{ item.unit }}</div>
        <div class="col-2 text-right">
          {{ item.price |
            toVirtualCurrency(virtualCurrencyConfig.exchangeRate, convertToVirtualCurrency) |
            to2Decimal |
            thousandSeparator }}
        </div>
        <div v-if="hasAtLeastOneItemWithDiscount" class="col-1 text-right">
          <span v-if="item.discountType === '%'">
            {{ item.discount | to2Decimal(region.decimal) | thousandSeparator(region.thousandSeparator) }}%
          </span>
          <span v-else-if="item.discountType === '$'">
            {{ convertToVirtualCurrency ? '' : region.currency }}
            {{ item.discount |
              toVirtualCurrency(virtualCurrencyConfig.exchangeRate, convertToVirtualCurrency) |
              to2Decimal(region.decimal) |
              thousandSeparator(region.thousandSeparator)}}
          </span>
        </div>
        <div v-else class="col-1 text-right" />
        <div v-if="withTaxes" class="col-1 text-right">
          {{ item.tax }}%
        </div>
        <div v-else class="col-1 text-right"></div>
        <div class="col-2 text-right">
          {{ item.afterTaxPrice |
          toVirtualCurrency(virtualCurrencyConfig.exchangeRate, convertToVirtualCurrency) |
          to2Decimal(region.decimal) |
          thousandSeparator(region.thousandSeparator) }}
        </div>
      </div>
    </div>

    <div v-if="redeem && redeem.point > 0">
      <div class="row weight-600">
        <div class="col-10 text-left">
          {{ $t('membership.redeemOfPoint') }} {{ region.currency }}
        </div>
        <div class="col-2 text-right">
          -{{ redeem.amount | to2Decimal(region.decimal) | thousandSeparator(region.thousandSeparator)}}
        </div>
      </div>
    </div>

    <div class="row mt-3 bd-top">
      <div class="col-10 text-right">
        {{ (withTaxes && printConfig.showGSTLabel) ? $t('general.totalBeforeGST') : $t('general.subtotal') }} {{ region.currency | shouldHideCurrencyIfConvertingToVC(convertToVirtualCurrency) }}
      </div>
      <div class="col-2 text-right">
        {{ print.subtotal |
          toVirtualCurrency(virtualCurrencyConfig.exchangeRate, convertToVirtualCurrency) |
          to2Decimal(region.decimal) |
          thousandSeparator(region.thousandSeparator) }}
      </div>
    </div>

    <div class="row" v-if="parseFloat(print.promotionDiscount) > 0">
      <div class="col-10 text-right">
        {{ $t('general.promotionDiscount') }} {{ region.currency | shouldHideCurrencyIfConvertingToVC(convertToVirtualCurrency) }}
      </div>
      <div class="col-2 text-right">
        -{{ print.promotionDiscount |
          toVirtualCurrency(virtualCurrencyConfig.exchangeRate, convertToVirtualCurrency) |
          to2Decimal(region.decimal) |
          thousandSeparator(region.thousandSeparator) }}
      </div>
    </div>

    <div class="row" v-if="parseFloat(print.totalDiscount) > 0">
      <div class="col-10 text-right">
        {{ $t('general.totalDiscount') }} {{ region.currency | shouldHideCurrencyIfConvertingToVC(convertToVirtualCurrency) }}
      </div>
      <div class="col-2 text-right">
        -{{ print.totalDiscount |
          toVirtualCurrency(virtualCurrencyConfig.exchangeRate, convertToVirtualCurrency) |
          to2Decimal(region.decimal) |
          thousandSeparator(region.thousandSeparator) }}
      </div>
    </div>

    <div v-if="withTaxes"
      class="row">
      <div class="col-10 text-right">
        {{ (withTaxes && printConfig.showGSTLabel) ? $t('general.gst') : $t('general.tax') }} {{ region.currency | shouldHideCurrencyIfConvertingToVC(convertToVirtualCurrency) }}
      </div>
      <div class="col-2 text-right">
        {{ print.totalTax |
          toVirtualCurrency(virtualCurrencyConfig.exchangeRate, convertToVirtualCurrency) |
          to2Decimal(region.decimal) |
          thousandSeparator(region.thousandSeparator) }}
      </div>
    </div>

    <div class="row" v-if="print.totalVirtualCurrencyRedeemed">
      <div class="col-4"></div>
      <div class="col-3"></div>
      <div class="col-3 bd-top text-right">
        {{ $t('membership.redeemed') }}
        <span> ({{virtualCurrencyConfig.name}}<span v-if="!convertToVirtualCurrency"> in {{region.currency}}</span>)</span>
      </div>
      <div class="col-2 bd-top text-right">
        {{ print.totalVirtualCurrencyRedeemed |
          toVirtualCurrency(virtualCurrencyConfig.exchangeRate, convertToVirtualCurrency) |
          to2Decimal(region.decimal) |
          thousandSeparator(region.thousandSeparator)}}
      </div>
    </div>
    <div class="row">
      <div class="col-4"></div>
      <div class="col-3"></div>
      <div class="col-3 bd-top text-right">
        {{ (withTaxes && printConfig.showGSTLabel) ? $t('general.totalAfterGST') :  $t('general.invoiceTotal') }} {{ region.currency | shouldHideCurrencyIfConvertingToVC(convertToVirtualCurrency) }}
      </div>
      <div class="col-2 bd-top text-right">
        {{ print.totalBeforeRounding |
          toVirtualCurrency(virtualCurrencyConfig.exchangeRate, convertToVirtualCurrency) |
          to2Decimal(region.decimal) |
          thousandSeparator(region.thousandSeparator) }}
      </div>
    </div>
    <div v-if="processFees" class="row">
      <div class="col-4"></div>
      <div class="col-3"></div>
      <div class="col-3 bd-top text-right">
        {{ $t('paymentMode.processValue') }} {{ region.currency | shouldHideCurrencyIfConvertingToVC(convertToVirtualCurrency) }}
      </div>
      <div class="col-2 bd-top text-right">
        <div v-if="processFees">
          {{ processFees |
            toVirtualCurrency(virtualCurrencyConfig.exchangeRate, convertToVirtualCurrency) |
            to2Decimal(region.decimal) |
            thousandSeparator(region.thousandSeparator) }}
        </div>
      </div>
    </div>

    <div class="row" v-if="roundOff !== 'NONE' && parseFloat(print.roundingAdjustment) > 0">
      <div class="col-3"></div>
      <div class="col-7 text-right">
        {{ $t('general.roundingAdjustment') }} {{ region.currency | shouldHideCurrencyIfConvertingToVC(convertToVirtualCurrency) }}
      </div>
      <div class="col-2 text-right">
        {{print.roundingAdjustment |
          toVirtualCurrency(virtualCurrencyConfig.exchangeRate, convertToVirtualCurrency) |
          to2Decimal(region.decimal) |
          thousandSeparator(region.thousandSeparator) }}
      </div>
    </div>
    <div class="row weight-600">
      <div class="col-4"></div>
      <div class="col-3">
        <template v-if="region.code === 'KH'">
          {{ $t('setting.exchangeRate') }}: $1 = R {{ print.exchangeRate }}
        </template>
      </div>
      <div class="col-3 bd-top bd-bottom text-right">
        {{ $t('general.totalAmountPayable') }} {{ region.currency | shouldHideCurrencyIfConvertingToVC(convertToVirtualCurrency) }}
      </div>
      <div class="col-2 bd-top bd-bottom text-right">
        {{ (print.total + processFees) |
          toVirtualCurrency(virtualCurrencyConfig.exchangeRate, convertToVirtualCurrency) |
          to2Decimal(region.decimal) |
          thousandSeparator(region.thousandSeparator) }}
      </div>
    </div>

    <div class="row mt-4" v-for="payment in patientPayments" :key="payment.paymentId">
      <div class="col-10 text-right">
        {{ payment.label }} {{ $t('general.paymentReceived') }} -- {{ $t('general.paymentRef') }}: {{ payment.paymentRef }} {{ region.currency }}
      </div>
      <div class="col-2 text-right">
        {{ payment.amount | to2Decimal(region.decimal) | thousandSeparator(region.thousandSeparator) }}
      </div>
    </div>

    <div class="row mt-4" v-for="payment in creditPayments" :key="payment.paymentId">
      <div class="col-10 text-right">
        {{ payment.mode }} -- {{ payment.paymentType}} : {{ region.currency }}
      </div>
      <div class="col-2 text-right">
        {{ payment.amount | to2Decimal(region.decimal) | thousandSeparator(region.thousandSeparator) }}
      </div>
    </div>
    <div class="row mt-4 weight-600">
      <div class="col-10 text-right">
        {{ $t('general.outstandingBalance') }} {{ region.currency | shouldHideCurrencyIfConvertingToVC(convertToVirtualCurrency) }}
      </div>
      <div class="col-2 text-right">
        {{ print.ostBalance |
          toVirtualCurrency(virtualCurrencyConfig.exchangeRate, convertToVirtualCurrency) |
          to2Decimal(region.decimal) |
          thousandSeparator(region.thousandSeparator) }}
      </div>
    </div>

    <div class="row mt-3 weight-600" v-if="virtualCurrencyConfig.enable">
      <div class="col-10 text-right">
        {{ $t('general.current') }} {{ virtualCurrencyConfig.name }} {{ $t('general.balance') }}
      </div>
      <div class="col-2 text-right">
        {{print.virtualCurrencyBalanceRightAfterInvoiceRedemption}} {{virtualCurrencyConfig.currencyCode}}
      </div>
    </div>
        <div class="row mt-3 weight-600" v-if="promoCode">
      <div class="col-10 text-right">
        {{ $t('campaign.promotionCode') }}
      </div>
      <div class="col-2 text-right">
        {{promoCode}}
      </div>
    </div>
    <br>
    <br>
    <template v-if="withDiagnosis || printConfig.diagnosis">
      <div class="row weight-600">
        <div class="col-12">{{ $t('general.diagnosis') }}:</div>
      </div>
      <div v-for="(item, index) in print.diagnosis" :key="index">
        <div v-if="multipleICD10Enabled" class="row">
          <div class="col-3">
            {{ index === 0 ? 'Primary Diagnosis' :
               index === 1 ? 'Secondary Diagnosis' :
               'Additional Diagnosis' }}
          </div>
          <div class="col-9">
            <p v-for="icd in item" :key="icd.uuid">
              <i>{{ icd && icd.text }}</i>
            </p>
          </div>
        </div>
        <div v-else>
          <p v-for="icd in item" :key="icd.uuid">
            <i>{{ icd && icd.text }}</i>
          </p>
        </div>
        <hr v-if="multipleICD10Enabled">
      </div>
    </template>
    <br>
    <template v-if="printConfig.notes">
      <div class="weight-600"> {{ $t('general.notes') }}: </div>
      <p style="white-space: pre-line;"> {{ print.notes }} </p>
    </template>
    <br>
    <template v-if="corpPayables.length">
      <div class="row weight-600">
        <div class="col-12">{{ $t('general.amountDueFrom') }}:</div>
      </div>

      <div class="row weight-600 bd-top bd-bottom">
        <div class="col-6">{{ $t('general.others') }}</div>
        <div class="col-6">{{ $t('general.amount') }}</div>
      </div>

      <div class="row" v-for="payable in corpPayables" :key="payable.paymentId">
        <div class="col-6">{{ payable.label }}</div>
        <div class="col-6">
          {{ region.currency }}{{ payable.amount | to2Decimal(region.decimal) | thousandSeparator(region.thousandSeparator) }}
        </div>
      </div>
    </template>
    <br>
    <template v-if="corpSettled.length">
      <div class="row weight-600">
        <div class="col-12">{{ $t('general.amountSettledFrom') }}:</div>
      </div>

      <div class="row weight-600 bd-top bd-bottom">
        <div class="col-6">{{ $t('general.others') }}</div>
        <div class="col-6">{{ $t('general.amount') }}</div>
      </div>

      <div class="row" v-for="payable in corpSettled" :key="payable.paymentId">
        <div class="col-6">{{ payable.label }}</div>
        <div class="col-6">
          {{ region.currency }}{{ payable.amount | to2Decimal(region.decimal) | thousandSeparator(region.thousandSeparator) }}
        </div>
      </div>
    </template>
    <div class="row" v-if="isCustomNote">
        <div class="col-7"></div>
        <div class="col-5 text-left" style="white-space: pre-line; color: red">
          注意事項：<br>定型化契約<br>1. 此療程為優惠方案如因故無法使用，除非有身體傷害之狀況，否則不得要求退費，已使用之課程需以原售價扣除。因特殊因素，得允許將已付金頟轉換其他療程。依現行法令規定意退費時，將扣除已接受服務之費用、已提領並拆封之附屬商品金額、終止契約手續費（剩餘金額之百分之10）後退子消費者本人（依現行法令公告規定辦理）已使用部分療程/保養，須以單堂原價計算之。<br>2.手術訂金於術前5天，個人因素而無法手術時得轉换其他療程產品。<br>於手術當天因個人因素取消手術時，訂金不退還也不可轉換其他療程及產品。
        </div>
    </div>
    <br>
    <div class="row mb-3">
      <div class="col-7 weight-600">
        <template v-if="printConfig.clinicHospital">
          {{ $t('setting.footerClinicHospital') }} :
        </template>
      </div>
      <div class="col-5 weight-600">
        <template v-if="printConfig.patientSignature">
          {{ $t('setting.footerPatientSignature') }} :
        </template>
      </div>
    </div>
    <p v-if="printConfig.footer" style="white-space: pre-line;">
      {{ printConfig.footerContent }}
    </p>
  </div>
</template>
<script>
import dateFormatter from '@/components/mixins/dateformatter';
import moneyFormatter from '@/components/mixins/money';
import invoiceMethodsMixin from '@/components/mixins/invoice';
import { mapGetters } from 'vuex';
import invoiceService from '@/services/invoice.service';

export default {
  name: 'TaxInvoiceComponent',
  mixins: [dateFormatter, moneyFormatter, invoiceMethodsMixin],
  props: {
    selectedPatient: {
      type: Object,
      default: () => ({ corpInsurance: [] }),
    },
    print: {
      type: Object,
      default: () => ({}),
    },
    general: {
      type: Object,
      default: () => ({}),
    },
    virtualCurrencyConfig: {
      type: Object,
      default: () => ({}),
    },
    convertToVirtualCurrency: {
      type: Boolean,
      default: false,
    },
    printConfig: [Array, Object],
    preview: {
      type: Boolean,
      default: false,
    },
    includeGST: [Boolean, Object],
    roundOff: [String, Object],
    isCategorized: {
      type: Boolean,
    },
    redeem: {
      type: Object,
      default: () => ({}),
    },
    processFees: { type: Number, default: 0 },
    multipleICD10Enabled: { type: Boolean, default: false },
  },
  data() {
    return {
      sortedInventoryByType: { // for categorized invoice printing
        'Professional Fees Consult': [],
        'Professional Fees Procedures': [],
        Facilities: [],
        Teleconsult: [],
        Consultation: [],
        Laboratory: [],
        Radiology: [],
        Investigation: [],
        Medicine: [],
        Wyn: [],
        Compounded: [],
        Product: [],
        Vaccination: [],
        Injection: [],
        Procedure: [],
        'Nursing Procedure': [],
        Consumables: [],
        Package: [],
        'Health Screening': [],
        Referral: [],
        Credit: [],
        'Virtual Currency': [],
        Misc: [],
        'Order Set': [],
      },
      // Because the key of the sortedInventoryByType object above is directly displayed on the page, and not translate,
      // so add this categorizedNameMapTranslateId variable to map the key of the sortedInventoryByType -> translateId,
      // if you add another key in sortedInventoryByType, please add the translateId in categorizedNameMapTranslateId
      categorizedNameMapTranslateId: {
        'Professional Fees Consult': 'setting.inventoryType.professionalFeesConsult',
        'Professional Fees Procedures': 'setting.inventoryType.professionalFeesProcedures',
        Facilities: 'setting.inventoryType.facilities',
        Teleconsult: 'setting.inventoryType.teleconsult',
        Consultation: 'setting.inventoryType.consultation',
        Laboratory: 'setting.inventoryType.laboratory',
        Radiology: 'setting.inventoryType.radiology',
        Investigation: 'setting.inventoryType.investigation',
        Medicine: 'setting.inventoryType.medicine',
        Wyn: 'setting.inventoryType.wyn',
        Compounded: 'setting.inventoryType.compounded',
        Product: 'setting.inventoryType.product',
        Vaccination: 'setting.inventoryType.vaccination',
        Injection: 'setting.inventoryType.injection',
        Procedure: 'setting.inventoryType.procedure',
        'Nursing Procedure': 'setting.inventoryType.nursingProcedure',
        Consumables: 'setting.inventoryType.consumables',
        Package: 'setting.inventoryType.package',
        Referral: 'setting.inventoryType.referral',
        Credit: 'setting.inventoryType.credit',
        'Virtual Currency': 'setting.inventoryType.virtualCurrency',
        Misc: 'setting.inventoryType.misc',
        'Order Set': 'setting.inventoryType.orderSet',
      },
    };
  },
  methods: {
    async sendEmailSMS() {
      const email = localStorage.getItem('sendEmail');
      const sms = localStorage.getItem('sendSMS');
      const idFrom = this.print.id;
      let typeFrom = null;
      if (email === idFrom) {
        typeFrom = 'email';
        localStorage.removeItem('sendEmail');
      } else if (sms === idFrom) {
        typeFrom = 'sms';
        localStorage.removeItem('sendSMS');
      } else return;
      let htmlFile = document.documentElement.outerHTML;
      // Below is a fix for email not formatted well on production because production uses external css
      htmlFile = htmlFile.replace(/href="\/css\//g, `href="${window.location.origin}/css/`);
      const payload = {
        id: idFrom,
        html: htmlFile,
        type: typeFrom,
      };
      invoiceService.sendReportLink(payload).then((resp) => {
        if (resp.data.result === 'ok') {
          if (typeFrom.toLowerCase() === 'email') {
            // eslint-disable-next-line no-alert
            window.alert('Email is being sent. Please wait for serveral minutes and then check the email.');
          } else {
            // eslint-disable-next-line no-alert
            window.alert('SMS has been sent.');
          }
        // eslint-disable-next-line no-alert
        } else window.alert('Email/SMS failed to send');
      });
    },
  },
  computed: {
    isCustomNote() {
      return ["eunicepoint"].includes(localStorage.getItem('orgCode'))
    },
    companyClinicLogo() {
      if (this.general.useLocationAddress) {
        const location = this.clinics.find(location => location._id === this.print.clinic);
        return location ? location.clinicLogo : '';
      }
      return this.general.logoURL;
    },
    companyClinicName() {
      if (this.general.useLocationAddress) {
        const location = this.clinics.find(location => location._id === this.print.clinic);
        return location ? location.name : '';
      }
      return this.general.clinicName;
    },
    companyClinicLocalName() {
      if (this.general.useLocationAddress) {
        const location = this.clinics.find(clinicLocation => clinicLocation._id === this.print.clinic);
        return location ? location.localName : '';
      }

      return this.general.clinicLocalName || '';
    },
    promoCode() {
      if (!this.print.promotions) return;
      return this.print.promotions.map(p => p.promoCode)[0];
    },
    ...mapGetters('config', ['featureFlags']),
    invoiceAddress() {
      if (this.general.useLocationAddress) {
        const location = this.clinics.find(location => location._id === this.print.clinic);
        return location ? location.address : '';
      }
      return this.general.address;
    },
    invoiceLocalAddress() {
      if (this.general.useLocationAddress) {
        const location = this.clinics.find(clinicLocation => clinicLocation._id === this.print.clinic);
        return location ? location.localAddress : '';
      }

      return this.general.clinicLocalAddress || '';
    },
    withTaxes() {
      return this.isInvoiceWithGST(this.print, { includeGST: this.includeGST });
    },
    withDiagnosis() {
      if (this.$route && this.$route.query) {
        return this.$route.query.diagnosis === 'true';
      }
      return this.printConfig && this.printConfig.diagnosis;
    },
    invoiceCategory() {
      const columns = [
        { label: this.$t('general.invoiceNumber') },
        { label: this.$t('general.invoiceDate') },
      ];

      if (this.printConfig.invoiceDateBE) {
        columns.push({ label: this.$t('setting.invoiceDateBE') });
      }

      if (this.general.useLocationAddress) {
        columns.push({ label: this.$t('general.acraNo') });
      }

      if (this.general.companyRegNo && !this.general.useLocationAddress) {
        columns.push({ label: this.$t('general.acraNo') });
      }

      if (this.general.useLocationAddress) {
        this.printConfig.showGSTLabel ? columns.push({ label: this.$t('setting.gstRegNoLabel') }) : columns.push({ label: this.$t('setting.gstRegNo') });
      }

      if (this.printConfig.regNumber && !this.general.useLocationAddress) {
        this.printConfig.showGSTLabel ? columns.push({ label: this.$t('setting.gstRegNoLabel') }) : columns.push({ label: this.$t('setting.gstRegNo') });
      }

      if (this.printConfig.docName) {
        this.featureFlags.doctorToConsultant ? columns.push({ label: this.$t('general.attendingDoctortoConsult') }) : columns.push({ label: this.$t('general.attendingDoctor') });
      }

      if (this.printConfig.docQual) {
        columns.push({ label: this.$t('general.qualifications') });
      }

      if (this.printConfig.therapistName) {
        columns.push({ label: this.$t('general.attendingTherapist') });
      }

      return columns;
    },
    splitAddress() {
      const address = this.selectedPatient.address.split(',');
      if (this.selectedPatient.unit) {
        address.splice(-1, 0, this.selectedPatient.unit);
      }
      return address;
    },
    splitLocalAddress() {
      const localAddress = this.selectedPatient.localAddress && this.selectedPatient.localAddress.split(',');
      if (this.selectedPatient.unit) {
        localAddress.splice(-1, 0, this.selectedPatient.unit);
      }

      return localAddress;
    },
    categorized() {
      // convert data to object with inventory type as key
      // example: { Medicine: [{...}, {...}], Procedure: [{...}, {...}] }
      const print = this.print.items.reduce((result, a) => {
        const r = result;
        r[a.inventory] = r[a.inventory] || [];
        r[a.inventory].push(a);
        return r;
      }, Object.create(null));

      // add dispense items into the default sorted inventory by type
      const sortedInvType = Object.assign({}, this.sortedInventoryByType);
      const dispensedInvTypes = Object.keys(print);
      for (let i = 0; i < dispensedInvTypes.length; i += 1) {
        sortedInvType[dispensedInvTypes[i]] = print[dispensedInvTypes[i]];
      }

      // remove empty array of inventory type
      const sortedInvTypeKeys = Object.keys(sortedInvType);
      for (let j = 0; j < sortedInvTypeKeys.length; j += 1) {
        if (sortedInvType[sortedInvTypeKeys[j]].length < 1) {
          delete sortedInvType[sortedInvTypeKeys[j]];
        }
      }

      return sortedInvType;
    },
    totalMedicinePrice() {
      const dosageMultiplier = (this.print.instructions.timesPerDay || 2) * (this.print.instructions.noOfDays || 3);
      return this.categorized.Medicine.reduce((accum, med) => {
        const weight = med.qty / dosageMultiplier;
        return accum + (parseFloat(med.price) * weight);
      }, 0);
    },
    totalMedicineAfterDiscountPrice() {
      return this.categorized.Medicine.reduce((accum, med) => accum + parseFloat(med.afterTaxPrice), 0);
    },
    logoMarginLeft() {
      if (localStorage.getItem('printSetup')) {
        return JSON.parse(localStorage.getItem('printSetup')).logoMarginLeft;
      }
      return 0;
    },
    logoWidth() {
      if (localStorage.getItem('printSetup')) {
        return JSON.parse(localStorage.getItem('printSetup')).logoWidth;
      }
      return 150;
    },
    provider() {
      const invoiceProvider = this.print.provider && typeof this.print.provider === 'object'
        ? this.print.provider._id : this.print.provider;
      const queueProvider = this.print.queue && this.print.queue.provider;
      const providerId = invoiceProvider || queueProvider;
      if (providerId) {
        const printProvider = this.providers.find(p => p._id === providerId);
        if (printProvider) return printProvider;
        return {};
      }
      return {};
    },
    patientPayments() {
      const payments = [];
      if (Array.isArray(this.print.payments) && this.print.payments.length) {
        // Loop through the payment records
        this.print.payments.filter(p => (!p.voided)).forEach((p) => {
          if (Array.isArray(p.payments) && p.payments.length) {
            this.processFees = (+this.processFees) + (+p.totalProcessFees);
            // Loop through the patient payments records
            p.payments.forEach((pp) => {
              let l = pp.label;
              if (pp.defaultPayment) {
                l = this.$t(`paymentMode.${pp.mode}`);
                if (pp.mode === 'offset') {
                  l = pp.label.replace('Offset', this.$t('paymentMode.offset'));
                }
                pp.labelTrans = l;
              } else {
                pp.labelTrans = l;
              }
              const patientPayment = {
                paymentId: pp._id,
                paymentRef: p.paymentRef,
                label: pp.labelTrans,
                amount: pp.amount,
              };
              payments.push({ ...patientPayment });
            });
          }
        });
      }
      return payments;
    },
    creditPayments() {
      const creditPyments = [];
      if (Array.isArray(this.print.credits) && this.print.credits.length > 0) {
        this.print.credits.filter((i) => {
          const creditPay = {
            mode: i.mode,
            amount: i.creditTotal,
            paymentType: i.paymentType,
            paymentId: i._id,
          };
          creditPyments.push({ ...creditPay });
        });
      }
      return creditPyments;
    },
    corpPayables() {
      const payments = [];
      if (Array.isArray(this.print.payments) && this.print.payments.length) {
        // Loop through the payment records
        this.print.payments.filter(p => !p.voided).forEach((p) => {
          if (Array.isArray(p.corpPayments) && p.corpPayments.length) {
            // Loop through the corp payments records
            p.corpPayments.forEach((cp) => {
              const corpPayment = {
                paymentId: cp._id,
                paymentRef: p.paymentRef,
                label: cp.label,
                amount: cp.amount,
                mode: cp.mode,
              };
              payments.push({ ...corpPayment });
            });
          }
        });
      }
      const settlePymt = [];
      if (payments.length > 0 && this.print.corpSettlement.length > 0) {
        for (let i = 0; i < payments.length; i++) {
          for (let j = 0; j < this.print.corpSettlement.length; j++) {
            if (this.print.corpSettlement[j].corporate === payments[i].mode) {
              settlePymt.push({ ...payments[i] });
            }
          }
        }
      }
      if (settlePymt.length > 0) {
        for (let i = payments.length - 1; i >= 0; i--) {
 	        for (let j = 0; j < settlePymt.length; j++) {
 	          if (payments[i] && (payments[i].paymentId === settlePymt[j].paymentId)) {
    		        payments.splice(i, 1);
    	      }
          }
        }
      }
      return payments;
    },
    corpSettled() {
      const settledPayments = [];
      if (Array.isArray(this.print.corpSettlement) && this.print.corpSettlement.length > 0) {
        this.print.corpSettlement.filter((f) => {
          if (f.recordedAmount > 0) {
            const corpSettle = {
              label: f.corporateName,
              amount: f.recordedAmount,
            };
            settledPayments.push({ ...corpSettle });
          }
        });
      }
      return settledPayments;
    },
    hasAtLeastOneItemWithDiscount() {
      return this.print.items.some(item => item.discount && parseFloat(item.discount) > 0);
    },
    instructionsBuilderFeatureFlag() {
      return this.$store.state.config.featureFlags.instructionsBuilder;
    },
  },
  async mounted() {
    if (!this.preview && !localStorage.getItem('sendEmail') && !localStorage.getItem('sendSMS')) {
      setTimeout(() => {
        window.print();
      }, 2000);
    }
    await this.sendEmailSMS();
  },
};
</script>

<style scoped src="../../assets/print.css"></style>
<style>
  .clinicLogo{
    width: setting.medicalLabel
  }

  .previewPrint {
    background-color: white;
    font-size: 0.1em !important;
    padding: 20px;
    padding-left: 35px;
    min-height: 60em;
    margin-bottom: 0px !important;
  }
  .previewPrint .address-font {
    font-size: 0.1rem !important;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
</style>
