<template>
    <div class="viewer-container">
        <div class="flex-container">
            <div class="metadata-container">
                <div class="row">
                    <span class="label col-sm-5">Title:</span>
                    <span class="data col-sm-7">{{viewer.name}}</span>
                </div>
                <div class="row">
                    <span class="label col-sm-5">Created by / Created date:</span>
                    <span class="data col-sm-7">{{viewer.createdBy || 'Unknown'}} / {{formattedCreatedOn}}</span>
                </div>
            </div>
            <div class="top-right-icons-container">
                <button class="btn btn-default"
                    v-if="viewer.draft &&
                        !hasPassedNoteLockTimes(notesMapped[viewer.currentPageId]) &&
                        checkPermissions(notesMapped[viewer.currentPageId], 'update')"
                    @click="editIndividualPage(notesMapped[viewer.currentPageId])"
                    v-acl="'consultation_edit_note'">
                    <v-icon name="edit" class="edit" scale="1.6" />
                </button>
                <div class="page-number-container">
                    <span>Page {{currentPageIndex + 1}} / {{viewer.pagesIdOrder.length}}</span>
                </div>
                <div class="close-button2" @click="closeChartingViewer"><v-icon name="times-circle" scale="2" /></div>
            </div>
        </div>
        <div class="middle-container">
            <div class="arrow-container">
                <span @click="goToPageViewer(currentPageIndex - 1)" v-if="currentPageIndex !== 0 && viewer.pagesIdOrder.length !== 1">
                    <v-icon name="angle-left" scale="6" />
                </span>
                <span v-else>
                    <v-icon name="angle-left" scale="6" color="grey" />
                </span>
            </div>
            <div class="image-container" id="image-container">
                <img :src="imageURL" @load="imageLoaded" :height="finalImageHeight" :width="finalImageWidth" />
            </div>
            <div class="arrow-container">
                <span @click="goToPageViewer(currentPageIndex + 1)" v-if="currentPageIndex !== viewer.pagesIdOrder.length - 1 && viewer.pagesIdOrder.length !== 1">
                    <v-icon name="angle-right" scale="6" />
                </span>
                <span v-else>
                    <v-icon name="angle-right" scale="6" color="grey" />
                </span>
            </div>
        </div>
        <div class="flex-container">
            <div style="flex: 1;"></div>
            <div style="flex: 1;"></div>
            <div style="flex: 1.4;" class="helper-container">
                <div class="row">
                    <span class="col-sm-6">
                        <span class="icon"><v-icon scale="1.4" name="info-circle"/></span>
                        <span class="icon-text">View Revision History</span>
                    </span>
                    <span class="print-note-btn col-sm-6"
                      v-acl="'consultation_download_pdf'"
                      @click="downloadNoteAsPDF(notesMapped[viewer.currentPageId])">
                        <span class="icon"><v-icon scale="1.4" name="download"/></span>
                        <span class="icon-text">Download this Page</span>
                    </span>
                </div>
                <div class="row">
                    <span class="print-note-btn col-sm-6"
                      @click="printNote(notesMapped[viewer.currentPageId])">
                        <span class="icon"><v-icon scale="1.4" name="print"/></span>
                        <span class="icon-text">Print this Page</span>
                    </span>
                    <span class="col-sm-6">
                        <span class="icon"><v-icon scale="1.4" name="trash"/></span>
                        <span class="icon-text">Delete this Page</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import print from 'print-js';
import {
  mapState, mapMutations,
} from 'vuex';
import { bus } from '@/main';

export default {
  name: 'ChartingViewer',
  props: {
    mainForm: {
      default: null,
      type: Object,
    },
    currentNoteId: {
      default: null,
      type: String,
    },
    invoice: {
      default: null,
      type: Object,
    },
    categoriesMapped: {
      default: null,
      type: Object,
    },
    parentComponent: {
      default: null,
      type: String,
    },
    filesDropped: {
      default: null,
      type: Function,
    },
    refreshCanvasEditor: {
      default: null,
      type: Function,
    },
    selectedCategoryFilter: {
      default: null,
      type: String,
    },
  },
  components: {},
  mixins: [],
  data() {
    return {
      defaultCardHeight: 300,
      keysDown: {},
      naturalImageHeight: null,
      naturalImageWidth: null,
      finalImageHeight: null,
      finalImageWidth: null,
    };
  },
  computed: {
    cardStyle() {
      return {
        height: `${this.defaultCardHeight}px`,
        'min-width': '200px',
      };
    },
    formattedCreatedOn() {
      return moment(this.viewer.createdAt).format('DD MMM YYYY');
    },
    currentPageIndex() {
      return this.viewer.pagesIdOrder.indexOf(this.viewer.currentPageId);
    },
    imageURL() {
      return this.notesMapped[this.viewer.currentPageId].signedPrintKey
            || this.notesMapped[this.viewer.currentPageId].signedThumbnailKey
            || '';
    },
    imageContainerHeight() {
      return document.getElementById('image-container').offsetHeight;
    },
    imageContainerWidth() {
      return document.getElementById('image-container').offsetWidth;
    },
    ...mapState('note', ['viewer', 'notesMapped', 'cachedPermissions']),
    patientConfig() {
      return this.$store.state.config.patient;
    },
  },
  methods: {
    imageLoaded(e) {
      this.checkAndFixImageDimensions(e.target.naturalWidth, e.target.naturalHeight);
    },
    shortcutKeys(e) {
      const { key } = e;
      this.keysDown[key] = e.type === 'keydown';
      if (this.keysDown[key]) {
        if (key === 'ArrowLeft') {
          if (this.currentPageIndex === 0) return;
          this.goToPageViewer(this.currentPageIndex - 1); // currentPage, allPages
        } else if (key === 'ArrowRight') {
          if (this.currentPageIndex === this.viewer.pagesIdOrder.length - 1) return;
          this.goToPageViewer(this.currentPageIndex + 1);
        }
      }
    },
    async printNote(note) {
      const key = 'signedPrintKey';
      const lowreskey = 'signedThumbnailKey';
      const res = await this.$store.dispatch('note/getLatestImage', { id: note._id, keys: [key, lowreskey] });
      if (res.body) {
        const image = res.body[key] ? res.body[key] : res.body[lowreskey];
        console.warn('IMAGE', image);
        print({
          printable: image,
          type: 'image',
        });
      }
    },
    downloadNoteAsPDF(note) {
      console.warn('CHAN!!', note);
      const apiurl = process.env.VUE_APP_apiUrl
        ? process.env.VUE_APP_apiUrl
        : `${window.location.origin}/api`;
      this.$http({
        method: 'get',
        url: `${apiurl}/note/pdf/${note._id}?all=false`,
        responseType: 'arraybuffer',
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${note.key}.pdf`); // or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => alert('could not download notes'));
    },
    editIndividualPage(note) {
      //   bus.$emit('pageEdit', note);
      const editingNote = this.notesMapped[this.viewer.noteId];
      // this.closeViewer();
      this.editCharting(editingNote, note._id);
    },
    async editCharting(selectedCharting, editingPageId) {
      const payload = {
        noteId: selectedCharting._id,
        invoiceId: (this.invoice || {})._id,
        openSize: 'maximized',
        currentPageId: selectedCharting._id,
        type: selectedCharting.type,
        name: selectedCharting.name,
      };
      if (selectedCharting.type === 'drawing') {
        const res = await this.$store.dispatch('note/getLatestImage', {
          id: editingPageId || selectedCharting._id,
          keys: ['signedThumbnailKey', 'signedPrintKey', 'signedOriginalImageKey', 'strokeStore'],
        });
        // console.log('editCharting - getLatestImage res is ', res);
        // console.log('selectedCharting is ', selectedCharting);
        const payload = {
          open: true,
          type: 'drawing',
          mainFormId: (this.mainForm || {})._id || null,
          noteId: selectedCharting._id,
          openSize: 'maximized',
          categoryId: selectedCharting.categoryId,
          pages: { [selectedCharting._id]: this.notesMapped[selectedCharting._id] },
          pagesIdOrder: [selectedCharting._id],
          currentPageId: selectedCharting._id,
          name: selectedCharting.name,
          invoiceId: (this.invoice || {})._id || null,
        };
        if (selectedCharting.otherPagesId && selectedCharting.otherPagesId.length > 0) {
          // payload.totalPages = selectedCharting.otherPagesId.length + 1; // other pages plus the cover page itself
          // payload.currentPage = 1; // always start with 1
          // payload.allPages = [selectedCharting._id, ...selectedCharting.otherPagesId];
          for (let i = 0; i < selectedCharting.otherPagesId.length; i++) {
            const nextPageId = selectedCharting.otherPagesId[i];
            if (nextPageId !== editingPageId) {
              await this.$store.dispatch('note/getLatestImage', { id: nextPageId, keys: ['signedThumbnailKey', 'signedPrintKey', 'strokeStore'], returnFullDoc: true });
            }
            payload.pages[nextPageId] = this.notesMapped[nextPageId];
            payload.pagesIdOrder.push(nextPageId);
          }
          // console.log('1 payload is ', payload);
        }
        this.$store.commit('note/openEditor', payload);
        if (this.parentComponent === 'canvas-editor') {
          this.refreshCanvasEditor();
        }
        if (editingPageId) {
          const editingPageIndex = payload.pagesIdOrder.findIndex(p => p === editingPageId);
          this.goToPage(editingPageIndex);
        }
      } else if (selectedCharting.type === 'typing') {
        // console.log('yes maximising...selectedCharting.type is typing');
        payload.type = 'typing';
        this.$store.commit('note/EXTRACT_NOTE', payload);
        this.$root.$emit('bv::show::modal', 'bv-modal-note');
      }
    },
    closeChartingViewer() {
      bus.$emit('canvas_saved'); // this event to let chartingcontainer to refetch the chartings
      this.closeViewer();
    },
    checkAndFixImageDimensions(naturalWidth, naturalHeight, nodeWidth, nodeHeight) {
      this.naturalImageHeight = naturalHeight;
      this.naturalImageWidth = naturalWidth;
      const ratio = naturalHeight / naturalWidth;
      const containerWidth = nodeWidth || this.imageContainerWidth;
      const containerHeight = nodeHeight || this.imageContainerHeight;

      this.hoho = containerWidth;
      if (naturalWidth <= containerWidth && naturalHeight <= containerHeight) {
        this.finalImageHeight = naturalHeight;
        this.finalImageWidth = naturalWidth;
      } else if (naturalWidth > containerWidth && naturalHeight > containerHeight) {
        if (naturalWidth > naturalHeight) {
          this.finalImageHeight = containerHeight;
          this.finalImageWidth = containerHeight / ratio;
        } else {
          this.finalImageWidth = containerWidth;
          this.finalImageHeight = containerWidth * ratio;
        }
      } else if (naturalWidth > containerWidth) {
        this.finalImageWidth = containerWidth;
        this.finalImageHeight = containerWidth * ratio;
      } else if (naturalHeight > containerHeight) {
        this.finalImageHeight = containerHeight;
        this.finalImageWidth = containerHeight / ratio;
      }
      if (this.finalImageHeight > containerHeight) {
        this.finalImageHeight = containerHeight;
        this.finalImageWidth = containerHeight / ratio;
      }
      if (this.finalImageWidth > containerWidth) {
        this.finalImageWidth = containerWidth;
        this.finalImageHeight = containerWidth * ratio;
      }
    },
    reOrientate() {
      const self = this;
      self.orientationChanged().then((node) => {
        self.checkAndFixImageDimensions(self.naturalImageWidth, self.naturalImageHeight, node.offsetWidth, node.offsetHeight);
      });
    },
    orientationChanged() {
      const timeout = 120;
      return new window.Promise(((resolve) => {
        const go = (i, height0) => {
          window.innerHeight != height0 || i >= timeout
            ? resolve(document.getElementById('image-container'))
            : window.requestAnimationFrame(() => go(i + 1, height0));
        };
        go(0, window.innerHeight);
      }));
    },
    hasPassedNoteLockTimes(charting) {
      if (!this.patientConfig.lockConsultNoteAt) return false;
      const mainForm = this.notesMapped[charting.mainFormId];
      const [hour, minute] = this.patientConfig.lockConsultNoteTime.split(':');
      const cutoff = moment(mainForm.consultationDate || 0, 'X')
        .set({ hour, minute, second: '59' }).unix();
      return moment().unix() > cutoff;
    },
    checkPermissions(charting, action) {
      const chartingId = charting._id;
      const { permissions } = charting;
      if (!permissions) return true;
      if (this.cachedPermissions[chartingId] && this.cachedPermissions[chartingId][action] !== undefined) {
        return this.cachedPermissions[chartingId][action];
      }
      if (!this.cachedPermissions[chartingId]) this.cachedPermissions[chartingId] = {};
      if (permissions.anyone && permissions.anyone[action]) {
        this.updateCachedPermissions({ chartingId, action, toggle: true });
        return true;
      }
      if (permissions[this.user.id] && permissions[this.user.id][action]) {
        this.updateCachedPermissions({ chartingId, action, toggle: true });
        return true;
      }
      for (let i = 0; i < this.user.roles.length; i++) {
        const role = this.user.roles[i];
        if (permissions[role._id] && permissions[role._id][action]) {
          this.updateCachedPermissions({ chartingId, action, toggle: true });
          return true;
        }
      }
      this.updateCachedPermissions({ chartingId, action, toggle: false });
      return false;
    },
    ...mapMutations('note', ['closeViewer', 'goToPageViewer', 'goToPage', 'updateCachedPermissions']),
  },
  mounted() {
    window.addEventListener('keydown', this.shortcutKeys, true);
    window.addEventListener('keyup', this.shortcutKeys, true);
    window.addEventListener('orientationchange', this.reOrientate, true);
    window.addEventListener('unload', this.closeViewer, true);
  },
  destroyed() {
    console.log('CEC destroyed');
    window.removeEventListener('keydown', this.shortcutKeys, true);
    window.removeEventListener('keyup', this.shortcutKeys, true);
    window.removeEventListener('orientationchange', this.reOrientate, true);
    window.removeEventListener('unload', this.closeViewer, true);
    this.closeViewer();
  },
};
</script>

<style lang="scss" scoped>
.viewer-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    .flex-container {
        flex: 1;
        height: 7%;
        display: flex;
        justify-content: space-between;
        padding: 6px;
        .metadata-container {
            flex: 1.4;
            .label {
                font-size: 16px;
                font-weight: 400;
            }
            .data {
                font-size: 16px;
                font-weight: 800;
            }
        }
        .top-right-icons-container {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            div {
                margin: 0 8px;
            }
            .page-number-container {
                background-color: rgb(190, 140, 140);
                text-align: center;
                vertical-align: middle;
                border: 1px solid black;
                border-radius: 4px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                padding: 0 3px;
            }
        }
        .helper-container {
            .icon-text {
                font-size: 16px;
                margin-left: 10px;
            }
        }
    }
    .middle-container {
        display: flex;
        flex: 18;
        height: 86%;
        flex-direction: row;
        max-width: 100%;
        max-height: 100%;
        text-align: center;
        .arrow-container {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
        }
        .image-container {
            flex: 12;
            max-width: inherit;
            max-height: inherit;
            display: flex;
            justify-content: center; /* align horizontal */
            align-items: center; /* align vertical */
            img {
                max-width: inherit;
                max-height: inherit;
            }
        }
    }
}
.print-note-btn {
  cursor: pointer;
}
</style>
