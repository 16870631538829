import roomService from '@/services/room.service';

import {
  SET_CLINIC_ROOMS,
  SET_ALL_ROOMS,
  SET_ROOM,
} from './type.mutation';

const state = {
  all: [],
  clinicRooms: [],
  selectedRoom: null,
};

/* eslint-disable no-param-reassign */
const mutations = {
  [SET_ALL_ROOMS](_state, payload) {
    _state.all = payload;
  },
  [SET_ROOM](_state, payload) {
    _state.selectedRoom = payload ? { ...payload } : null;
  },
  [SET_CLINIC_ROOMS](_state, payload) {
    _state.clinicRooms = payload;
  },
};

const actions = {
  listClinicRooms({ commit }, param = {}) {
    return roomService.list({
      ...param,
      isClinicFiltered: true,
    })
      .then((response) => {
        commit(SET_CLINIC_ROOMS, response.data);
        return response.data;
      });
  },
  listRooms({ commit }, param = {}) {
    return roomService.list(param)
      .then((response) => {
        commit(SET_ALL_ROOMS, response.data);
        return response.data;
      });
  },
  resetAll({ commit }) {
    commit(SET_ALL_ROOMS, []);
  },
  setSelectedRoom({ commit }, payload) {
    commit(SET_ROOM, payload);
  },
  unsetSelectedRoom({ commit }) {
    commit(SET_ROOM, null);
  },
  createNewRoom({ commit }, payload) {
    commit('setLoading', true, { root: true });
    return roomService.create(payload)
      .then((response) => {
        if (response.success) {
          commit(SET_ROOM, response.data);
        }
        commit('setLoading', false, { root: true });
        return response;
      })
      .catch((error) => {
        throw error;
      });
  },
  updateRoom({ commit }, payload) {
    commit('setLoading', true, { root: true });
    return roomService.update(payload._id, payload)
      .then((response) => {
        if (response.success) {
          commit(SET_ROOM, response.data);
        }
        commit('setLoading', false, { root: true });
        return response;
      });
  },
  destroyRoom({ commit }, payload) {
    commit('setLoading', true, { root: true });
    return roomService.destroy(payload._id)
      .then((response) => {
        if (response.success) {
          commit(SET_ROOM, null);
        }
        commit('setLoading', false, { root: true });
        return response;
      });
  },
};

const getters = {
  all: _state => _state.all,
  clinicRooms: _state => _state.clinicRooms,
  selectedRoom: _state => _state.selectedRoom,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
