var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      staticClass: "bv-modal-example",
      attrs: { "header-class": "label", size: "xl" },
      on: { hide: _vm.closeUploadManager },
      model: {
        value: _vm.showUploadManager,
        callback: function($$v) {
          _vm.showUploadManager = $$v
        },
        expression: "showUploadManager"
      }
    },
    [
      _c("template", { slot: "modal-header" }, [
        _c("span", [_vm._v(_vm._s(_vm.$t("general.uploadManager")))])
      ]),
      _vm._t("extra"),
      _c(
        "template",
        { slot: "default" },
        [
          _vm.filesMapped.length > 0
            ? _c("b-table", {
                attrs: {
                  striped: "",
                  hover: "",
                  fields: _vm.fields,
                  items: _vm.filesMapped
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "fileName",
                      fn: function(row) {
                        return [
                          _c(
                            "span",
                            [
                              _c("b-input", {
                                staticClass: "mb-2 mr-sm-2 mb-sm-0",
                                attrs: { size: "sm", value: row.value },
                                on: {
                                  input: function($event) {
                                    return _vm.updateUploadLineItem(
                                      $event,
                                      row.item.uuid,
                                      "name"
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "category",
                      fn: function(row) {
                        return [
                          _c(
                            "span",
                            [
                              _c("b-form-select", {
                                staticClass: "mb-2 mr-sm-2 mb-sm-0",
                                attrs: {
                                  size: "sm",
                                  value: row.value,
                                  options: _vm.filteredCategoriesOptions
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.updateUploadLineItem(
                                      $event,
                                      row.item.uuid,
                                      "categoryId"
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3968114710
                )
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "template",
        { staticClass: "space-between", slot: "modal-footer" },
        [
          _c(
            "b-button",
            {
              attrs: { variant: "danger" },
              on: { click: _vm.closeUploadManager }
            },
            [_vm._v(_vm._s(_vm.$t("general.close")))]
          ),
          _c(
            "b-button",
            {
              attrs: {
                variant: "success",
                disabled: !_vm.externallyValid || _vm.uploading
              },
              on: { click: _vm.uploadFiles }
            },
            [_vm._v(_vm._s(_vm.$t("general.upload")))]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }