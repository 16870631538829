import api from './api';

export default {
  fetchAll(params) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);

    Object.keys(params).forEach((key) => {
      q.push(`${key}=${params[key]}`);
    });

    const url = `/purchaseorder/?${q.join('&')}`;
    return api.get(url);
  },
  createNew(data) {
    return api.post('/purchaseorder', data);
  },
  patchOrder(id, data) {
    return api.patch(`/purchaseorder/${id}`, data);
  },
  updateOrder(id, data) {
    return api.put(`/purchaseorder/${id}`, data);
  },
  savePOPDFFile(id, data, imageFile) {
    return api.multiPartPost(
      `/purchaseorder/pdf-upload/${id}`,
      data,
      // imageFile,
      { picture: imageFile },
    );
  },
  savePOImageFile(id, data, imageFile) {
    return api.multiPartPut(
      `/purchaseorder/image-upload/${id}`,
      data,
      { picture: imageFile },
    );
  },
  downloadUploadedDocument(id, uploadedDocType) {
    const url = `http://localhost:3000/purchaseorder/download/${id}?uploadedDocType=${uploadedDocType}`;
    return fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        'X-CSRF': localStorage.getItem('xsrf'),
        'x-org': localStorage.getItem('orgCode'),
        'x-clinic': localStorage.getItem('xclinic'),
      },
    });
  },
  // updateBulkOrder(data) {
  //   return api.put('/purchaseorder/updateBulk', data);
  // },
  // delete(id) {
  //   return api.delete(`/purchaseorder/${id}`);
  // },
  // deleteBulk(ids) {
  //   return api.post('/purchaseorder/deleteBulk', ids);
  // },
  // getOrder(id) {
  //   return api.get(`/purchaseorder/${id}`);
  // },
  count(query) {
    const params = Object.keys(query)
      .map(k => `${k}=${query[k]}`)
      .join('&');
    return api.get(`/purchaseorder/count?${params}`);
  },
};
