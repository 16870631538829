<template>
  <div style="margin:20px" :class="{'previewPrint': preview}">
    <div class="row mb-3">
      <div class="col-9">
        <!-- <img v-if="printConfig.clinicLogo"
          :style="{ marginLeft:`${logoMarginLeft}px`, width:`${logoWidth}px` }"
          :src="general.logoURL"> -->
        <strong>{{ general.clinicName }}</strong>
        <p v-if="printConfig.clinicAdd"
          style="white-space: pre-line;">
          {{ invoiceAddress }}
        </p>
        <p class="mb-3 address-font" v-if="general.telephone">Tel: {{ general.telephone }}</p>
      </div>
      <div class="col-3">
        <strong>To: {{ selectedPatient.name }}</strong>
        <template v-if="printConfig.patientAdd">
          <div v-for="address in splitAddress" :key="address.id">
            {{ address }}
          </div>
        </template>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="mb-3 text-center text-uppercase font-weight-bold">
          {{ $t('general.invoice') }}
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-2">
        <div>{{ $t('general.invoiceNumber')}}</div>
        <div>{{ $t('general.invoiceDate')}}</div>
      </div>
      <div class="col-4">
        <div>:&nbsp; {{ print.invoiceNo }}</div>
        <div>:&nbsp; {{ print.date | ddMonthYYYYFromX }}</div>
      </div>
      <div class="col-1"></div>
      <div class="col-2">
        <div>{{ $t('general.doctor') }}</div>
        <div>{{ $t('general.beautician') }}</div>
      </div>
      <div class="col-3">
        <div>:&nbsp; {{ print.provider && print.provider.name }}</div>
        <div>:&nbsp; {{ print.therapist && print.therapist.name }}</div>
      </div>
    </div>

    <!-- invoice table header -->
    <div class="row weight-600 bd-top bd-bottom">
      <div :class="{
        'col-2 text-left': printConfig.lineItemsDesc,
        'col-4 text-left': !printConfig.lineItemsDesc}">
        {{ $t('general.itemName') }}
      </div>
      <div v-if="printConfig.lineItemsDesc"
        class="col-2">
        {{ $t('general.description') }}
      </div>
      <div  class="col-1 text-right">
        {{ $t('general.qty') }}
      </div>
      <div class="col-2 text-right">{{ $t('general.unitPrice') }}</div>
      <div class="col-2 text-right">{{ $t('general.discount') }}</div>
      <div v-if="withTaxes"
        class="col-1 text-right">
        {{ $t('general.tax') }}
      </div>
      <div v-else class="col-1 text-right"></div>
      <div class="col-2 text-right">{{ $t('general.total') }}</div>
    </div>

    <!-- invoice body for categorized invoice -->
    <div v-if="false">
      <div v-for="(category,idx) in categorized" :key="category.key">
        <div class="mt-3 row">
          <div class="col-12 weight-600 mb-2">
            {{ idx }}
          </div>
        </div>
        <div class="row" v-for="item in category" :key="item.key">
          <div class="col-2">
            {{ item.name }}
            <template v-if="['Medicine', 'Consumables', 'Expendables', 'Product'].includes(item.inventory)">
              <br/>
              <span>
                - {{ (item.selectedBatch && item.selectedBatch.number) || '' }}
              </span>
            </template>
          </div>
          <div v-if="printConfig.lineItemsDesc"
            class="col-2">
            {{ item.description }}
          </div>
          <div :class="{'col-1 text-right': printConfig.lineItemsDesc, 'col-3 text-center': !printConfig.lineItemsDesc}"
            style="padding-right:0px">{{ item.qty }}</div>
          <div class="col-1 text-left" style="padding-left:5px">{{ item.unit }}</div>
          <div class="col-2 text-right">
            {{ item.price |
              toVirtualCurrency(virtualCurrencyConfig.exchangeRate, convertToVirtualCurrency) |
              to2Decimal |
              thousandSeparator }}
          </div>
          <div class="col-1 text-right">
            <span v-if="item.discountType === '%'">
              {{ item.discountAmount | to2Decimal | thousandSeparator }}%
            </span>
            <span v-else-if="item.discountType === '$'">
              {{ convertToVirtualCurrency ? '' : region.currency }}
              {{ item.discountAmount |
                toVirtualCurrency(virtualCurrencyConfig.exchangeRate, convertToVirtualCurrency) |
                to2Decimal |
                thousandSeparator }}
            </span>
          </div>
          <div v-if="withTaxes"
            class="col-1 text-right">
            {{ item.tax }}%
          </div>
          <div v-else class="col-1 text-right"></div>
          <div class="col-2 text-right">
            {{ item.afterDiscountPrice |
              toVirtualCurrency(virtualCurrencyConfig.exchangeRate, convertToVirtualCurrency) |
              to2Decimal |
              thousandSeparator }}
          </div>
        </div>
      </div>
    </div>

    <!-- invoice body for uncategorized invoice -->
    <div v-else>
      <div v-for="item in print.items" :key="item.key" class="row">
        <div :class="{
          'col-2 text-left': printConfig.lineItemsDesc,
          'col-4 text-left': !printConfig.lineItemsDesc}">
          {{ item.name }}
          <template v-if="['Medicine', 'Consumables', 'Expendables', 'Product'].includes(item.inventory)">
            <br/>
            <span>
              - {{ (item.selectedBatch && item.selectedBatch.number) || '' }}
            </span>
          </template>
        </div>
        <div v-if="printConfig.lineItemsDesc"
          class="col-2">
          {{ item.description }}
        </div>
        <div class="col-1 text-right">
          {{ item.qty }} {{ item.unit }}
        </div>
        <div class="col-2 text-right">
          {{ item.price |
            toVirtualCurrency(virtualCurrencyConfig.exchangeRate, convertToVirtualCurrency) |
            to2Decimal(region.decimal) |
            thousandSeparator(region.thousandSeparator) }}
        </div>
        <div class="col-2 text-right">
          <span v-if="item.discountType === '%'">
              {{ item.discount |
                to2Decimal(region.decimal) |
                thousandSeparator(region.thousandSeparator) }}%
            </span>
            <span v-else-if="item.discountType === '$'">
              {{ convertToVirtualCurrency ? '' : region.currency }}
              {{ item.discount |
                toVirtualCurrency(virtualCurrencyConfig.exchangeRate, convertToVirtualCurrency) |
                to2Decimal(region.decimal) |
                thousandSeparator(region.thousandSeparator) }}
            </span>
        </div>
        <div v-if="withTaxes" class="col-1 text-right">
          {{ item.tax }}%
        </div>
        <div v-else class="col-1 text-right"></div>
        <div class="col-2 text-right">
          {{ item.afterTaxPrice |
            toVirtualCurrency(virtualCurrencyConfig.exchangeRate, convertToVirtualCurrency) |
            to2Decimal(region.decimal) |
            thousandSeparator(region.thousandSeparator) }}
        </div>
      </div>
    </div>

    <div v-if="redeem && redeem.point > 0">
      <div class="row weight-600">
        <div class="col-10 text-left">
          {{ $t('membership.redeemOfPoint') }} {{ region.currency }}
        </div>
        <div class="col-2 text-right">
          -{{ redeem.amount |
            to2Decimal(region.decimal) |
            thousandSeparator(region.thousandSeparator) }}
        </div>
      </div>
    </div>

    <div class="row mt-3 bd-top">
      <div class="col-10 text-right">
        {{ $t('general.subtotal') }} {{ region.currency | shouldHideCurrencyIfConvertingToVC(convertToVirtualCurrency) }}
      </div>
      <div class="col-2 text-right">
        {{ print.subtotal |
          toVirtualCurrency(virtualCurrencyConfig.exchangeRate, convertToVirtualCurrency) |
          to2Decimal(region.decimal) |
          thousandSeparator(region.thousandSeparator) }}
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <div class="weight-600"> {{ $t('general.notes') }}: </div>
        <p style="white-space: pre-line;"> {{ print.notes }} </p>
        <br>
        <p> {{$t('general.printedBy')}}: {{ user.name }}, {{ new Date() | HHmmddMonthYYYY }}</p>
        <p v-if="printConfig.footer" style="white-space: pre-line;">
          {{ printConfig.footerContent }}
        </p>
      </div>
      <div class="col-6">

        <div class="row">
          <div class="col-8 text-right">
            {{ $t('general.promotionDiscount') }} {{ region.currency | shouldHideCurrencyIfConvertingToVC(convertToVirtualCurrency) }}
          </div>
          <div class="col-4 text-right">
            -{{ print.promotionDiscount |
              toVirtualCurrency(virtualCurrencyConfig.exchangeRate, convertToVirtualCurrency) |
              to2Decimal(region.decimal) |
              thousandSeparator(region.thousandSeparator) }}
          </div>
        </div>

        <div class="row">
          <div class="col-8 text-right">
            {{ $t('general.totalDiscount') }} {{ region.currency | shouldHideCurrencyIfConvertingToVC(convertToVirtualCurrency) }}
          </div>
          <div class="col-4 text-right">
            -{{ print.totalDiscount |
              toVirtualCurrency(virtualCurrencyConfig.exchangeRate, convertToVirtualCurrency) |
              to2Decimal(region.decimal) |
              thousandSeparator(region.thousandSeparator) }}
          </div>
        </div>

        <div v-if="withTaxes" class="row">
          <div class="col-8 text-right">
            {{ $t('general.tax') }} {{ region.currency | shouldHideCurrencyIfConvertingToVC(convertToVirtualCurrency) }}
          </div>
          <div class="col-4 text-right">
            {{ print.totalTax |
              toVirtualCurrency(virtualCurrencyConfig.exchangeRate, convertToVirtualCurrency) |
              to2Decimal(region.decimal) |
              thousandSeparator(region.thousandSeparator) }}
          </div>
        </div>

        <div class="row">
          <div class="col-2"></div>
          <div class="col-6 bd-top text-right">
            {{ $t('general.invoiceTotal') }} {{ region.currency | shouldHideCurrencyIfConvertingToVC(convertToVirtualCurrency) }}
          </div>
          <div class="col-4 bd-top text-right">
            {{ print.totalBeforeRounding |
              toVirtualCurrency(virtualCurrencyConfig.exchangeRate, convertToVirtualCurrency) |
              to2Decimal(region.decimal) |
              thousandSeparator(region.thousandSeparator) }}
          </div>
        </div>

        <div v-if="processFees" class="row">
        <div class="col-2"></div>
          <div class="col-6 bd-top text-right">
            {{ $t('paymentMode.processValue') }} {{ region.currency | shouldHideCurrencyIfConvertingToVC(convertToVirtualCurrency) }}
          </div>
          <div class="col-4 bd-top text-right">
            {{ processFees |
              toVirtualCurrency(virtualCurrencyConfig.exchangeRate, convertToVirtualCurrency) |
              to2Decimal(region.decimal) |
              thousandSeparator(region.thousandSeparator) }}
          </div>
        </div>

        <div class="row" v-if="roundOff !== 'NONE'">
          <div class="col-8 text-right">
            {{ $t('general.roundingAdjustment') }} {{ region.currency | shouldHideCurrencyIfConvertingToVC(convertToVirtualCurrency) }}
          </div>
          <div class="col-4 text-right">
            {{print.roundingAdjustment |
              toVirtualCurrency(virtualCurrencyConfig.exchangeRate, convertToVirtualCurrency) |
              to2Decimal(region.decimal) |
              thousandSeparator(region.thousandSeparator)}}
          </div>
        </div>

        <div class="row weight-600">
          <div class="col-2"></div>
          <div class="col-6 bd-top bd-bottom text-right">
            {{ $t('general.totalAmountPayable') }} {{ region.currency | shouldHideCurrencyIfConvertingToVC(convertToVirtualCurrency) }}
          </div>
          <div class="col-4 bd-top bd-bottom text-right">
            {{ (print.total + processFees) |
              toVirtualCurrency(virtualCurrencyConfig.exchangeRate, convertToVirtualCurrency) |
              to2Decimal(region.decimal) |
              thousandSeparator(region.thousandSeparator) }}
          </div>
        </div>
        <br>
        <div class="row" v-for="payment in patientPayments" :key="payment.paymentId">
          <small class="col-8 text-right">
            {{ payment.label }} {{ $t('general.paymentReceived') }} --
            {{ $t('general.paymentRef') }}: {{ payment.paymentRef }} {{ region.currency }}
          </small>
          <div class="col-4 text-right">
            {{ payment.amount |
              to2Decimal(region.decimal) |
              thousandSeparator(region.thousandSeparator) }}
          </div>
        </div>
        <div class="row weight-600">
          <div class="col-8 text-right">
            {{ $t('general.outstandingBalance') }} {{ region.currency | shouldHideCurrencyIfConvertingToVC(convertToVirtualCurrency) }}
          </div>
          <div class="col-4 text-right">
            {{ print.ostBalance |
              toVirtualCurrency(virtualCurrencyConfig.exchangeRate, convertToVirtualCurrency) |
              to2Decimal(region.decimal) |
              thousandSeparator(region.thousandSeparator) }}
          </div>
        </div>
        <div class="row weight-600" v-if="virtualCurrencyConfig.enable">
          <div class="col-8 text-right">
            {{ $t('general.current') }} {{ virtualCurrencyConfig.name }} {{ $t('general.balance') }}
          </div>
          <div class="col-4 text-right">
            {{print.virtualCurrencyBalanceRightAfterInvoiceRedemption}}{{virtualCurrencyConfig.currencyCode}}
          </div>
        </div>

        <div class="row mt-3 weight-600" v-if="promoCode">
          <div class="col-8 text-right">
            {{ $t('campaign.promotionCode') }}
          </div>
          <div class="col-4 text-right">
            {{promoCode}}
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import dateFormatter from '@/components/mixins/dateformatter';
import moneyFormatter from '@/components/mixins/money';
import invoiceMethodsMixin from '@/components/mixins/invoice';

export default {
  name: 'TaxInvoiceComponent',
  mixins: [dateFormatter, moneyFormatter, invoiceMethodsMixin],
  props: {
    selectedPatient: {
      type: Object,
      default: () => ({ corpInsurance: [] }),
    },
    print: {
      type: Object,
      default: () => ({}),
    },
    general: {
      type: Object,
      default: () => ({}),
    },
    printConfig: [Array, Object],
    preview: {
      type: Boolean,
      default: false,
    },
    includeGST: [Boolean, Object],
    roundOff: [String, Object],
    isCategorized: {
      type: Boolean,
    },
    redeem: {
      type: Object,
      default: () => ({}),
    },
    processFees: { type: Number, default: 0 },
    multipleICD10Enabled: { type: Boolean, default: false },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('config', {
      virtualCurrencyConfig: 'virtualCurrency',
    }),
    promoCode() {
      return this.print.promotions.map(p => p.promoCode)[0];
    },
    withTaxes() {
      return this.isInvoiceWithGST(this.print, { includeGST: this.includeGST });
    },
    withDiagnosis() {
      if (this.$route && this.$route.query) {
        return this.$route.query.diagnosis === 'true';
      }
      return this.printConfig && this.printConfig.diagnosis;
    },
    invoiceCategory() {
      const columns = [
        { label: this.$t('general.invoiceNumber') },
        { label: this.$t('general.invoiceDate') },
      ];

      if (this.general.companyRegNo) {
        columns.push({ label: this.$t('general.acraNo') });
      }

      if (this.printConfig.regNumber) {
        columns.push({ label: this.$t('setting.gstRegNo') });
      }

      if (this.printConfig.docName) {
        columns.push({ label: this.$t('general.attendingDoctor') });
      }

      if (this.printConfig.docQual) {
        columns.push({ label: this.$t('general.qualifications') });
      }

      if (this.printConfig.therapistName) {
        columns.push({ label: this.$t('general.attendingTherapist') });
      }

      return columns;
    },
    invoiceAddress() {
      return (this.general.useLocationAddress)
        ? (this.clinicsById[this.print.clinic] && this.clinicsById[this.print.clinic].address) || ''
        : this.general.address;
    },
    splitAddress() {
      const address = this.selectedPatient.address.split(',');
      if (this.selectedPatient.unit) {
        address.splice(-1, 0, this.selectedPatient.unit);
      }
      return address;
    },
    categorized() {
      const print = this.print.items.reduce((result, a) => {
        const r = result;
        r[a.inventory] = r[a.inventory] || [];
        r[a.inventory].push(a);
        return r;
      }, Object.create(null));
      return print;
    },
    logoMarginLeft() {
      if (localStorage.getItem('printSetup')) {
        return JSON.parse(localStorage.getItem('printSetup')).logoMarginLeft;
      }
      return 0;
    },
    logoWidth() {
      if (localStorage.getItem('printSetup')) {
        return JSON.parse(localStorage.getItem('printSetup')).logoWidth;
      }
      return 300;
    },
    provider() {
      const providerId = this.print.queue && this.print.queue.provider; // not all inv has queue
      if (providerId) {
        const invoiceProvider = this.providers.find(p => p._id === providerId);
        if (invoiceProvider) return invoiceProvider;
        return {};
      }
      return {};
    },
    patientPayments() {
      const payments = [];
      if (Array.isArray(this.print.payments) && this.print.payments.length) {
        // Loop through the payment records
        this.print.payments.filter(p => (!p.voided)).forEach((p) => {
          if (Array.isArray(p.payments) && p.payments.length) {
            this.processFees = (+this.processFees) + (+p.totalProcessFees);
            // Loop through the patient payments records
            p.payments.forEach((pp) => {
              const patientPayment = {
                paymentId: pp._id,
                paymentRef: p.paymentRef,
                label: pp.label,
                amount: pp.amount,
              };
              payments.push({ ...patientPayment });
            });
          }
        });
      }
      return payments;
    },
    corpPayables() {
      const payments = [];
      if (Array.isArray(this.print.payments) && this.print.payments.length) {
        // Loop through the payment records
        this.print.payments.filter(p => !p.voided).forEach((p) => {
          if (Array.isArray(p.corpPayments) && p.corpPayments.length) {
            // Loop through the corp payments records
            p.corpPayments.forEach((cp) => {
              const corpPayment = {
                paymentId: cp._id,
                paymentRef: p.paymentRef,
                label: cp.label,
                amount: cp.amount,
              };
              payments.push({ ...corpPayment });
            });
          }
        });
      }
      return payments;
    },

  },
  mounted() {
    if (!this.preview) {
      setTimeout(() => {
        window.print();
      }, 2000);
    }
  },
};
</script>

<style scoped src="../../assets/print.css"></style>
<style>
  .clinicLogo{
    width: setting.medicalLabel
  }

  .previewPrint {
    background-color: white;
    font-size: 0.1em !important;
    padding: 20px;
    padding-left: 35px;
    min-height: 60em;
    margin-bottom: 0px !important;
  }
</style>
