<template>
    <div>
        <div class="form-group row" :class="formSchema[group].containerClass" v-for="group in formGroups" :key="group" :data-tik="tik">
           <section class="pdf-item">
            <label class="col-sm-12" :class="formSchema[group].labelClass" v-if="isFreetext(formSchema[group].type)">
                {{ formSchema[group].i18nId ? $t(formSchema[group].i18nId) : formSchema[group].label }}
            </label>
            <label class="col-sm-12"
              :class="[{ multiline: formSchema[group].multiline }, formSchema[group].labelClass]"
              v-if="!isFreetext(formSchema[group].type)"
            >
              {{ formSchema[group].i18nId ? $t(formSchema[group].i18nId) : formSchema[group].label }}
              <span v-if="formSchema[group].required" class="badge badge-pill badge-danger">
                {{ $t('general.required') }}
              </span>
            </label>
            <div class="answer" v-if="isInput(formSchema[group].type)">
              <span
                v-text="formData[group] || '-'"
                :class="formSchema[group].inputClass"
              />
            </div>
            <div class="answer" v-if="isTextArea(formSchema[group].type)">
              <span
                v-html="formData[group] || '-'"
                class="textarea"
                :class="formSchema[group].inputClass"
              />
            </div>
            <div class="answer" v-if="isDateTime(formSchema[group].type)">
              <span
                v-text="formatDate(formData[group])"
                :inputClass="['form-control', formSchema[group].inputClass]"
              />
            </div>
            <!-- Group Inputs -->
            <div class="col-sm-12" v-if="isGroup(formSchema[group].type)">
                <div
                  class="group_input col-sm-4"
                  v-for="(input, index) in formSchema[group].groups"
                  :key="index"
                  :class="formSchema[group].groupClass"
                >
                  <div class="answer custom-control custom-radio custom-control-inline"
                    :class="input.groupClass"
                    v-if="isRadio(input.type)"
                  >
                    <template v-if="formData[group].includes(input.value)">
                      <v-icon name="check-circle" />
                      &nbsp;
                    </template>
                    <template v-else>
                      <v-icon name="circle" />
                      &nbsp;
                    </template>
                    <label :class="input.labelClass">
                      {{ input.i18nId ? $t(input.i18nId) : input.label }}
                    </label>
                  </div>
                  <div :class="input.groupClass" v-if="isCheckbox(input.type)">
                      <template v-if="formData[group].includes(input.value)">
                        <v-icon name="check-square" />
                        &nbsp;
                      </template>
                      <template v-else>
                        <v-icon name="square-full" />
                        &nbsp;
                      </template>
                      <label :class="input.labelClass" >
                        {{ input.i18nId ? $t(input.i18nId) : input.label }}
                      </label>
                  </div>
                  <div class="form-group" :class="input.groupClass" v-if="isInput(input.type)">
                      <label class="form-label" :class="input.labelClass">
                        {{ input.i18nId ? $t(input.i18nId) : input.label }}</label>
                      <div class="answer">
                        <span
                          :class="input.inputClass"
                          v-text="formData[input.model] || '-'" />
                      </div>
                  </div>
                  <div class="form-group" :class="input.groupClass" v-if="isDateTime(input.type)">
                      <label
                        class="form-label"
                        :class="input.labelClass"
                      >
                        {{ input.i18nId ? $t(input.i18nId) : input.label }}</label>
                      <div class="answer">
                        <span
                          :inputClass="['form-control', input.inputClass]"
                          v-text="formatDate(formData[input.model])"
                        ></span>
                      </div>
                  </div>
                  <div class="form-group" :class="input.groupClass" v-if="isFreetext(input.type)">
                    <label class="form-label" :class="input.labelClass">
                        {{ input.i18nId ? $t(input.i18nId) : input.label }}
                    </label>
                  </div>
                </div>
            </div>
          </section>
        </div>
    </div>
</template>
<script>
import _ from 'lodash';
import DateFormatter from '@/components/mixins/dateformatter';

export default {
  name: 'JsonSchemaForm',
  props: {
    schema: {
      type: Object,
      required: true,
    },
    schemaData: {
      type: Object,
      required: true,
    },
    sourceData: {
      type: Object,
      default: function fn() {
        return {};
      },
    },
  },
  mixins: [DateFormatter],
  data() {
    return {
      formData: {},
      tik: 0,
    };
  },
  watch: {
    sourceData: {
      deep: true,
      immediate: true,
      handler(v) {
        this.updateFormData();
      },
    },
  },
  computed: {
    formGroups() {
      return this.schema ? Object.keys(this.schema.properties) : [];
    },
    formSchema() {
      return this.schema ? this.schema.properties : {};
    },
    formGroupCheckbox() {
      return this.formGroups.filter((group) => {
        const isGroup = this.isGroup(this.formSchema[group].type);
        const isWithGroup = this.formSchema[group].groups
          && this.formSchema[group].groups.length > 0;
        return isGroup && isWithGroup && this.isCheckbox(this.formSchema[group].groups[0].type);
      });
    },
  },
  methods: {
    isInput(val) {
      return !this.isFreetext(val) && val === 'text';
    },
    isDateTime(val) {
      return !this.isFreetext(val) && val === 'date';
    },
    isSelect(val) {
      return !this.isFreetext(val) && val === 'select';
    },
    isRadio(val) {
      return !this.isFreetext(val) && val === 'radio';
    },
    isCheckbox(val) {
      return !this.isFreetext(val) && val === 'checkbox';
    },
    isTextArea(val) {
      return !this.isFreetext(val) && val === 'textarea';
    },
    isGroup(val) {
      return !this.isFreetext(val) && val === 'group';
    },
    isFreetext(val) {
      return val === 'freetext';
    },
    isYesNoSpecify(val) {
      return val === 'yesNoSpecify';
    },
    updateFormData() {
      const output = this.schemaData || {};
      this.formGroupCheckbox.forEach((checkbox) => {
        output[checkbox] = (checkbox in output && Array.isArray(output[checkbox])
          ? output[checkbox]
          : [output[checkbox]]);
      });
      // auto populate from sourceData
      if (!_.isEmpty(this.sourceData)) {
        this.formGroups.forEach((key) => {
          const item = this.formSchema[key];
          if (!item) return;
          if (this.isGroup(item.type)) {
            const toPopulate = item.groups.filter(i => i.type === 'input' && i.populateKey);
            toPopulate.forEach((p) => {
              output[p.model] = _.get(this.sourceData, p.populateKey);
            });
          }
          if (item.populateKey) {
            output[key] = _.get(this.sourceData, item.populateKey);
          }
        });
      }
      this.formData = output;
      this.tik += 1;
    },
    formatDate(value) {
      if (!value) {
        return '-';
      }

      return this.formatVDDate(value, { format: 'DD MMM YYYY' });
    },
  },
};
</script>
<style scoped lang="scss">
  .answer {
    font-weight: bold;
    margin-left: 2rem;
    margin-right: 5rem;
    width: 100%;
  }
  .textarea {
    white-space: break-spaces;
  }
  .group_input {
    display: inline-block;
  };
  input {
    border: 1px solid #ced4da !important;
  }
  label.multiline {
    white-space: pre-line;
    /*
     Due to HTML template and CSS pre-line, we need to remove the first line.
     NOTE: https://stackoverflow.com/questions/34857373/how-to-hide-first-line-for-white-spacepre-line-in-firefox-browser
     */
    &:first-line {
      line-height: 0px;
    }
  }
</style>
