<template>
  <!-- eslint-disable max-len -->
  <table :class="['table table-bordered note-dynamic', { readonly: viewing || printing, 'ipt-border': showInputBorder }]">
    <thead v-if="tableHeader.length === 2" class="complex">
      <tr>
        <th v-for="(labelItem, idx) in tableHeader[0]"
          :key="idx" :colspan="labelItem.colSpan"
          :rowspan="labelItem.rowSpan"
          >
          {{labelItem.text}}
        </th>
        <th rowspan="2"></th><!-- table row action -->
      </tr>
      <tr>
        <th v-for="(labelItem, idx) in tableHeader[1]" :key="idx">
          {{labelItem.text}}
        </th>
      </tr>
    </thead>
    <thead v-else-if="tableHeader.length === 1">
      <tr>
        <th v-for="(labelText, index) in tableHeader[0]" :key="index">
          {{ labelText }}
        </th>
        <th></th><!-- table row action -->
      </tr>
    </thead>
    <tbody :data-tick="tick">
      <template v-for="(row, rowIdx) in item.answer">
        <tr :key="rowIdx">
          <td v-for="(answerText, idx) in row" :key="idx" :style="(item.style && item.style[idx]) || ''" :class="(item.class && item.class[idx]) || ''">
            <div
              v-if="item.inputType[idx] === 'date'"
              >
              <!--
              <datepicker
                :value="answerText"
                :format="(item.dateFormat && item.dateFormat[idx]) || 'dd-MM-yyyy'"
                @input="updateTableCell($event, qns, rowIdx, idx)"
                type="date"
                @closed="onPickerClose(idx)"
                calendar-class="note-date-cale"
                input-class="form-control ipt note-date-ipt"
                :placeholder="item.placeholder && item.placeholder[idx]"
              ></datepicker>
              -->
              <date-picker
                :value="answerText"
                value-type="timestamp"
                :format="(item.dateFormat && item.dateFormat[idx]) || 'DD-MMM-YYYY'"
                @input="updateTableCell($event, qns, rowIdx, idx)"
                type="date"
                :placeholder="item.placeholder && item.placeholder[idx]"
                :disabled="item.readonly && item.readonly[idx] || viewing"
              ></date-picker>
            </div>
            <b-form-input
              v-else-if="item.type[idx] === 'input'"
              :class="`ipt ${ (item.class && item.class[idx]) || ''}`"
              :id="`${qns}_${rowIdx}_${idx}_input`"
              :value="item.dateFormat && item.dateFormat[idx] && item.dateFormat[idx].length > 0 ? parseDateValue(answerText, item.dateFormat[idx]) : answerText"
              :type="item.inputType && item.inputType[idx]"
              :plaintext="item.plaintext && item.plaintext[idx]"
              @input="updateTableCell($event, qns, rowIdx, idx)"
              :readonly="item.readonly && item.readonly[idx] || viewing"
              debounce="500"
              :placeholder="item.placeholder && item.placeholder[idx]"
            ></b-form-input>
            <one-click-button
              v-else-if="item.type[idx] === 'oneclick-button'"
              :item="{ label: 'Click to Get', answer: answerText, source: item.source }"
              :qns="qns"
              :viewing="viewing"
              :print="printing"
              :formData="formData"
              :coords="[rowIdx, idx]"
              :updateTypingFormData="(newAnswer) => updateTableCellTick(newAnswer, qns, rowIdx, idx)"
            />
            <typed-form
              v-else-if="item.type[idx] === 'textarea'" style="overflow: hidden"
              :qns="qns"
              :value="answerText"
              :rows="item.rows && item.rows[idx]"
              :updateTypingFormData="(newAnswer) => updateTableCellTick(newAnswer, qns, rowIdx, idx)"
              :disabled="item.disabled && item.disabled[idx] || viewing"
            />
          </td>
          <td class="action-td">
            <b-button @click="removeTableRow(rowIdx, qns)" :disabled="viewing" class="remove">
              <v-icon name="times" scale="1"/>
            </b-button>
          </td>
        </tr>
      </template>
      <tr class="last-row">
        <td :colspan="colsCount">
          <b-button @click="addTableRow(qns)" :disabled="viewing">{{ $t('forms.addNewRow') }}</b-button>
        </td>
        <td></td>
      </tr>
    </tbody>
  </table>

</template>
<script>
/*
DynamicTable Spec:

1. Normal table (one row in header):
"q3": {
  "label": ["Name", "Age", "Password"],
  "type": ["input", "input", "input"],
  "answer": [
    ["", "18", ""],
    ["", "19", ""]
  ],
  "inputType": ["text", "number", "password"],
  "populateKey": [
    ["patient.name", "patient.age", ["patient.name", "patient.age"]],
    [null, "patient.age", null],
    ["note.createdAt.year", "patient.age", null]
  ],
  "dynamictable": "true",
  "colSize": "col-sm-12"
}

2. Two-rows header table (two rows in header):

"q3": {
  "label": ["Name", ["Detail", ["Age", "Gender"]], "Password"],
  "type": ["input", "input", "input", "input"],
  "answer": [
    ["", "18", "f", ""],
    ["", "19", "f", ""]
  ],
  "inputType": ["text", "number", "text", "password"],
  "populateKey": [
    [
      "patient.name",
      "patient.age",
      "patient.gender",
      ["patient.name", "patient.age"]
    ],
    [null, "patient.age", null, null],
    ["note.createdAt.year", "patient.age", "patient.title", null]
  ],
  "dateFormat": ["DD-MMM-YYYY", ],
  "dynamictable": "true",
  "colSize": "col-sm-12"
}

answer.length determines how many rows to show in tbody;
answer.$.length determines how many cols to show in one row;
populateKey[n] determines the nth row answer;
type.length, inputType.length, populateKey[n].length should equal to
answer[n].length;
*/
import DateFormatter from '@/components/mixins/dateformatter';
import DatePicker from 'vue2-datepicker';
import TypedForm from '@/components/TypedForm.vue';
import OneClickButton from './OneClickButton.vue';
import 'vue2-datepicker/index.css';

export default {
  name: 'DynamicTable',
  components: {
    OneClickButton,
    DatePicker,
    TypedForm,
  },
  mixins: [DateFormatter],
  props: {
    item: {
      type: Object,
      default: function fn() {},
    },
    qns: {
      type: String,
    },
    updateTypingFormData: {
      type: Function,
      default: function fn(/* answer, qns */) {},
    },
    viewing: {
      type: Boolean,
      default: false,
    },
    printing: {
      type: Boolean,
      default: false,
    },
    updateTick: {
      type: Function,
      default: function fn(/* answer, qns */) {},
    },
    formData: {
      type: Object,
      default: function fn() {},
    },
  },
  data() {
    return {
      isMultiHeaderRow: false,
      tableHeader: [],
      colsCount: 0,
      smapleRowForAdd: [],
      tick: 0,
    };
  },
  computed: {
    showInputBorder() {
      // show input border when there's textarea
      return this.item.type.some(t => t === 'textarea');
    },
  },
  mounted() {
    this.generateHeader();
  },
  methods: {
    generateHeader() {
      const { label, answer } = this.item;
      if (!label) {
        // no header table
        this.tableHeader = [];
        this.colsCount = (answer && answer[0] && answer[0].length) || 1;
        return;
      }

      // Currently we only support table header that depth = 2

      // if all the elements of `label` is string, return an array of this label
      const isLabelEleAllString = label.every(l => typeof l === 'string');
      if (isLabelEleAllString) {
        this.tableHeader = [label];
        this.colsCount = label.length;
        return;
      }
      let colsCount = 0;
      const firstRow = [];
      let secondRow = [];

      label.forEach((labelItem) => {
        let text;
        let colSpan;
        let rowSpan;
        if (Array.isArray(labelItem)) {
          const [firstRowHeader, secondRowItems] = labelItem;
          colsCount += labelItem.length;
          colSpan = secondRowItems.length;
          text = firstRowHeader;
          const sec = secondRowItems.map(t => ({ text: t }));
          secondRow = secondRow.concat(sec);
          rowSpan = 1;
        } else if (typeof labelItem === 'string') {
          text = labelItem;
          colsCount += 1;
          rowSpan = 2;
        }
        firstRow.push({
          rowSpan,
          colSpan,
          text,
        });
      });
      this.tableHeader = [firstRow, secondRow];
      this.colsCount = colsCount;
    },
    removeTableRow(rowIdx, qnsIdx) {
      const { answer } = this.item;
      const updated = Array.isArray(answer) ? answer.slice() : [];
      updated.splice(rowIdx, 1);
      this.updateTypingFormData(updated, qnsIdx);
    },
    addTableRow(qnsIdx) {
      const { answer, inputType } = this.item;
      const updated = Array.isArray(answer) ? answer.slice() : [];
      const lastRow = inputType.map(() => '');
      updated.push(lastRow.slice());
      this.updateTypingFormData(updated, qnsIdx);
    },
    updateTableCell(value, qnsIdx, rowIdx, idx) {
      const { answer } = this.item;
      const updated = Array.isArray(answer) ? answer.slice() : [];
      updated[rowIdx][idx] = value;
      this.updateTypingFormData(updated, qnsIdx);
    },
    updateTableCellTick(value, qnsIdx, rowIdx, idx) {
      const { answer } = this.item;
      this.tick += 1;
      const updated = Array.isArray(answer) ? answer.slice() : [];
      updated[rowIdx][idx] = value;
      this.updateTypingFormData(updated, qnsIdx);
    },
  },
};
</script>
<style>
.form-control.zz-date:disabled, .zz-date.form-control[readonly] {
  background: transparent !important;
}
.p-fixed {
  position: absolute;
}
</style>
<style lang="scss" scoped>
.table.note-dynamic {
  &.readonly {
    input, textarea {
      background: #eee;
    }
    input {
      text-align: center;
    }
    button {
      display: none;
    }
  }
  &.ipt-border {
    td {
      input {
        border: 1px solid #ced4da;
      }
    }
  }
  td {
    padding: 2px;
    text-align: center;
    vertical-align: middle;
    input {
      border: none;
      padding: 2px;
      &:disabled, &[readonly] {
        background: transparent;
      }

    }
    button.remove {
      color: black;
      background: transparent;
      border: none;
    }
    .mx-datepicker {
      width: 130px;
    }
  }
  &.table-bordered {
    border-style: none;
    td:last-child {
      border-style: none;
    }
    th:last-child {
      border-style: none;
    }
    tr:last-child td {
      border-style: none;
    }
  }

  .last-row td {
    padding-top: .5rem;
    text-align: left;
  }
  .action-td {
    text-align: center;
  }
  thead.complex {
    th {
      vertical-align: middle;
      text-align: center;
      padding: 2px;
    }
    th:nth-child(1){
      width:5%;
    }
    th:nth-child(2){
      width:6%;
    }
    th:nth-child(3){
      width:14%;
    }
    th:nth-child(4){
      width:8%;
    }
    th:nth-child(5){
      width:10%;
    }
    th:nth-child(6){
      width:13%;
    }
    th:nth-child(8){
      width:7%;
    }
  }
}
</style>
<style>
.form-control.note-date-ipt:disabled, .note-date-ipt.form-control[readonly] {
  background: transparent !important;
}
</style>
