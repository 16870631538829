<template>
<div id="queue-print-section">
  <div>
    <h4>总部</h4>
    <p>挂号证</p>
  </div>
  <div>
    <p>挂号编号:{{ visitId }}</p>
  </div>
  <hr />
  <div class="detail__main">
    <p>挂号证:{{ patientId }}</p>
    <p>病患姓名:{{ patientName }}</p>
  </div>
  <br />
  <div class="detail__patient">
    <p>身份别:{{ patientType }}</p>
    <p>复诊:{{ isFollowUp ? '是' : '否' }}</p>
  </div>
  <div class="detail__queue">
    <p>诊间:{{ roomName }}</p>
    <p>候诊号:{{ queueNumber }}</p>
    <p>挂号员:{{ registerName }}</p>
  </div>
  <hr />
  <div class="detail__date">
    <p>挂号日期:{{ registeredDateTime | dateOnlyFormat }}</p>
    <p>挂号时间:{{ registeredDateTime| timeOnlyFormat }}</p>
  </div>
</div>
</template>

<script>
import _ from 'lodash';
import constant from '@/services/constant';
import Dateformatter from '@/components/mixins/dateformatter';
import { createNamespacedHelpers } from 'vuex';
import print from 'print-js';

const { mapGetters: mapQueueGetters } = createNamespacedHelpers('queue');

const PATIENT_TYPE = {
  normal: '一般',
};

export default {
  name: 'PrintQueueCardMaKuang',
  mixins: [Dateformatter],
  data() {
    return {
      printLabelConfig: {},
    };
  },
  computed: {
    ...mapQueueGetters({
      queueObj: 'queueForPrint',
    }),
    QUEUE_CALLNUMBER_PREFIX() {
      return constant.QUEUE_CALLNUMBER_PREFIX;
    },
    queueNumber() {
      const { QUEUE_CALLNUMBER_PREFIX, queueObj } = this;
      return `${QUEUE_CALLNUMBER_PREFIX[queueObj.call_number_status]} ${queueObj.call_number}`;
    },
    patientName() {
      return _.chain(this).get('queueObj.patient.name', '').value();
    },
    registerName() {
      return _.chain(this).get('user.name', '').value();
    },
    isFollowUp() {
      // TODO: isFollowUp flag is not implemented in DB yet
      return _.chain(this).get('queueObj.patient.isFollowUp', false).value();
    },
    patientType() {
      // TODO: patientType is not implemented in DB yet
      const defaultType = _.chain(PATIENT_TYPE).get('normal');
      return _.chain(this).get('queueObj.patient.patientType', defaultType).value();
    },
    registeredDateTime() {
      return _.chain(this).get('queueObj.createdAt', '').value();
    },
    roomName() {
      return _.chain(this).get('queueObj.room.name', '').value();
    },
    patientId() {
      return _.chain(this).get('queueObj.patient.givenId', '').value();
    },
    visitId() {
      return _.chain(this).get('queueObj.tcmConsultation', '').value();
    },
  },
  mounted() {
    print({
      printable: 'queue-print-section',
      type: 'html',
      ignoreElements: ['app'],
      targetStyles: ['*'],
      onPrintDialogClose() {
        window.close();
      },
    });
  },
};
</script>


<style lang="scss">
#queue-print-section {
  size: 80mm 80mm;
  margin: 0mm;

  width: 80mm;
  height: 80mm;
  box-sizing: border-box;
  // TODO: check MK paper size
  // overflow: hidden;

  background-color: white;

  font-size: 3.5mm;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  padding-top: 0.4em;
  padding-bottom: 0.4em;

  p {
    width: 100%;
    text-align: center;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    font-weight: 300;
  }

  h4 {
    text-align: center;
    margin-top: 0.5rem;
    margin-bottom: 0px;
    font-size: 4mm;
  }

  hr {
    border-top: 2px solid #666;
    margin: 0.5rem;
  }
}

@mixin detail-wording {
  & > * {
    text-align: left!important;
    white-space: nowrap;
    // TODO: check MK paper size
    // overflow: hidden;
    // text-overflow: ellipsis;
  }
}

.detail {
  &__main {
    @include detail-wording;

    display: grid;
    grid-template-columns: 1fr;
  }

  &__patient {
    @include detail-wording;

    display: grid;
    grid-template-columns: 1fr 120px;
  }

  &__queue {
    @include detail-wording;

    display: grid;
    grid-template-columns: 1fr 1fr 120px;
  }

  &__date {
    @include detail-wording;

    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
</style>
