<template>
<div class="d-flex flex-column" id="medicine-label">
    <div class="text-center font-weight-bold">Instalasi Farmasi Dr's Clinique</div>
    <div class="text-right">{{new Date() | ddMonthYYYYHHmm}}</div>
    <div>{{ patientName }}</div>
    <div>{{ medName }}</div>
    <div>{{ medDosage }}</div>
    <div>{{ medStrength }}</div>
</div>
</template>
<script>
// import print from 'print-js';
import dateformatter from '@/components/mixins/dateformatter';

export default {
  name: 'MedicalLabelDrClinique',
  mixins: [dateformatter],
  props: [
    'clinicName',
    'clinicAddress',
    'medName',
    'medStrength',
    'medQty',
    'medDosage',
    'medUnit',
    'medExpiry',
    'medPrecautionOne',
    'medPrecautionTwo',
    'patientName',
    'patientNric',
  ],
  mounted() {
    setTimeout(function(){ 
      window.print();
    }, 2000);
    // print({
    //   printable: 'medicine-label',
    //   type: 'html',
    //   targetStyles: ['*'],
    //   onPrintDialogClose() {
    //     window.close();
    //   },
    // });
  },
};
</script>

<style lang="scss">
#medicine-label {
    // size: 50mm 25mm;
    width: 282px;
    // height: 141px;
    margin-left: -30px;
    margin-right: -30px;
    padding: 8px;
    box-sizing: border-box;
    font-size: 10px;
}

@page {
  size: 50mm 25mm;
  margin: 0mm;
}

@media print {
  #medicine-label {page-break-after: always;}
}
</style>
