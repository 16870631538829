import { cloneDeep } from 'lodash';

import iuiChartService from '@/services/iui-chart.service';
import {
  SET_ACTIVE_IUI_CHART,
  SET_IUI_LIST,
  UPDATE_IUI_LIST,
} from './type.mutation';

const state = {
  activeIuiChart: {},
  iuiList: [],
};

const mutations = {
  [SET_ACTIVE_IUI_CHART](_state, payload) {
    // eslint-disable-next-line no-param-reassign
    _state.activeIuiChart = payload;
  },
  [SET_IUI_LIST](_state, payload) {
    // eslint-disable-next-line no-param-reassign
    _state.iuiList = payload;
  },
  [UPDATE_IUI_LIST](_state, payload) {
    // eslint-disable-next-line no-param-reassign
    _state.iuiList = _state.iuiList.map((s) => {
      if (s._id !== payload._id) {
        return s;
      }
      return payload;
    });
  },
};

const actions = {
  fetchIuiChartList({ commit }, params = {}) {
    return iuiChartService.list(params)
      .then((resp) => {
        commit(SET_IUI_LIST, resp.data);
        commit(SET_ACTIVE_IUI_CHART, resp.data[0] || {});
        return resp.data;
      });
  },
  setActiveIuiChart({ commit }, payload) {
    commit(SET_ACTIVE_IUI_CHART, payload);
  },
  createIuiChart({ commit }, data) {
    return iuiChartService.create(data)
      .then((resp) => {
        const chart = resp.data;
        commit(SET_ACTIVE_IUI_CHART, chart);
        // commit(ADD_TO_IUI_LIST, chart);
        return chart;
      });
  },
  updateNotes({ commit }, data) {
    return iuiChartService.updateNotes(data)
      .then((resp) => {
        const chart = resp.data;
        commit(SET_ACTIVE_IUI_CHART, chart);
        commit(UPDATE_IUI_LIST, chart);
        return chart;
      });
  },
  updateBetaHCGDate({ commit }, data) {
    return iuiChartService.updateBetaHCGDate(data)
      .then((resp) => {
        const chart = resp.data;
        commit(SET_ACTIVE_IUI_CHART, chart);
        commit(UPDATE_IUI_LIST, chart);
        return chart;
      });
  },
  createVisitItem({ commit }, data) {
    return iuiChartService.createVisitItem(data)
      .then((resp) => {
        const chart = resp.data;
        commit(SET_ACTIVE_IUI_CHART, chart);
        commit(UPDATE_IUI_LIST, chart);
        return chart;
      });
  },
  updateVisitItem({ commit }, data) {
    return iuiChartService.updateVisitItem(data)
      .then((resp) => {
        const chart = resp.data;
        commit(SET_ACTIVE_IUI_CHART, chart);
        commit(UPDATE_IUI_LIST, chart);
        return chart;
      });
  },
  patchIuiChart({ commit }, payload) {
    const data = cloneDeep(payload);
    const { id } = data;
    delete data.id;
    delete data.createdAt;
    return iuiChartService.patch(id, data)
      .then((resp) => {
        const chart = resp.data;
        commit(SET_ACTIVE_IUI_CHART, chart);
        commit(UPDATE_IUI_LIST, chart);
        return chart;
      });
  },
};

const getters = {
  activeIuiChart: _state => _state.activeIuiChart,
  iuiList: _state => _state.iuiList,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
