import api from './api';

export default {
  translateErrorMsg(error) {
    return error;
  },
  fetchAll(params) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);

    Object.keys(params).forEach((key) => {
      q.push(`${key}=${params[key]}`);
    });

    const url = `/inventory-transfer/?${q.join('&')}`;
    return api.get(url);
  },
  getInventoryTransfer(id) {
    return api.get(`/inventory-transfer/${id}`);
  },
  createNew(data) {
    if (data.serviceTags && data.serviceTags.length === 0) {
      delete data.serviceTags;
    }
    return api.post('/inventory-transfer', data);
  },
  count(query) {
    const params = Object.keys(query)
      .map(k => `${k}=${query[k]}`)
      .join('&');
    return api.get(`/inventory-transfer/count?${params}`);
  },
};
