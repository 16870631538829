<!-- TODO: to remove what is in ChartingContainer that also duplicates here -->
<template>
    <div id="invoice-note-container" class="invoice-note-container" :class="isExtracted && 'grey-bottom-border2'">
        <div class="note-label bold space-between grey-bottom-border" :class="publishedCSSClass" v-if="!isExtracted && invoice" @click="toggleNote">
            <span class="note-label-name" v-if="invoice">
                <span v-if="invoice.queue.teleConsultSession">{{ invoice.queue.queue_time | ddMonthYYYY }}</span>
                <span v-else>{{ invoice.createdAt | formatVDDate({ format: 'DD MMM YYYY'}) }}</span>
                <span v-if="invoice.invoiceNo && invoice.invoiceNo !== 'draft'">#{{invoice.invoiceNo}}</span>
                {{ $t('general.visitNotes') }}
                <span v-if="configConsultNotePublishMandatory">
                    {{mainForm && mainForm.draft ? `(${$t('general.draft')})` : `(${$t('general.published')})`}}
                </span>
            </span>
            <span v-if="selectedQData">
                <b-button class="ml-1" variant="outline-info" size="sm"
                data-automation="consult-note-start-tele-consult"
                    @click.stop="startTeleConsult"
                    :disabled="selectedQData.teleConsultSession &&
                      selectedQData.teleConsultSession.endConsultTime ||
                      invoice.clinic !== selectedClinic._id"
                    v-acl="'patient_consultation_start'"
                    v-if="isTeleConsultQueue && featureFlags.teleconsult">
                    <v-icon name="phone"/>
                </b-button>
                <b-button class="ml-1" variant="outline-info" size="sm"
                data-automation="consult-note-end-tele-consult"
                    :disabled="invoice.clinic !== selectedClinic._id  ||
                      (selectedQData.teleConsultSession &&
                      (selectedQData.teleConsultSession.endConsultTime ||
                      !selectedQData.teleConsultSession.startConsultTime))"
                    @click.stop="endTeleConsult"
                    v-acl="'patient_consultation_start'"
                    v-if="isTeleConsultQueue && featureFlags.teleconsult">
                    <phone-down size="18"/>&nbsp;&nbsp;
                    {{ $t('general.endTeleConsult') }}
                </b-button>
                <b-button class="ml-1" variant="outline-info" size="sm"
                data-automation="consult-note-start-consult"
                    @click.stop="updateQueue('start_consult')"
                    v-acl="'patient_consultation_start'"
                    :disabled="qStartedConsult ||
                      invoice.clinic !== selectedClinic._id">
                    {{ $t('general.startConsult') }}
                </b-button>
                <b-button class="ml-1" variant="outline-info" size="sm"
                data-automation="consult-note-end-consult"
                    @click.stop="updateQueue('end_consult')"
                    v-acl="'patient_consultation_end'"
                    :disabled="invoice.clinic !== selectedClinic._id ||
                      (!qStartedConsult || qEndedConsult)">
                    {{ $t('general.endConsult') }}
                </b-button>
                <b-button class="ml-1" variant="outline-info" size="sm"
                  data-automation="consult-note-start-treatment"
                    @click.stop="updateQueue('start_treatment')"
                    v-acl="'patient_consultation_treatment_start'"
                    :disabled="qStartedTreatment ||
                      invoice.clinic !== selectedClinic._id">
                    {{ $t('queuestatus.startTreatment') }}
                </b-button>
                <b-button class="ml-1" variant="outline-info" size="sm"
                  data-automation="consult-note-end-treatment"
                    @click.stop="updateQueue('end_treatment')"
                    v-acl="'patient_consultation_treatment_end'"
                    :disabled="invoice.clinic !== selectedClinic._id ||
                      (!qStartedTreatment || qEndedTreatment)">
                    {{ $t('queuestatus.endTreatment') }}
                </b-button>
                <b-button class="ml-1" variant="outline-dark" size="sm"
                 data-automation="consult-note-photo-taken"
                    @click.stop="updateQueue('photo_taken')"
                    v-acl="'patient_consultation_photo_take'"
                    :disabled="qPhotoTaken ||
                      invoice.clinic !== selectedClinic._id">
                    {{ $t('queuestatus.photoTaken') }}
                </b-button>
                <b-button class="ml-1" variant="outline-dark" size="sm"
                  data-automation="consult-note-print-consultNote"
                    @click.stop="printConsultNote({name: 'patientConsultNote'})"
                    :disabled="mainForm.draft">
                    {{ $t('general.printConsultNote') }}
                </b-button>
            </span>
            <span v-else>No queue for this visit</span>
            <span class="note-label-status">
                <span v-if="mainForm && !mainForm.draft">{{ $t('note.publishedOn') }} {{ mainForm.publishedAt | formatDateTimeEng }}</span>
                <span v-else @click.stop="extractNote(invoice)" class="cursor-pointer"><v-icon name="external-link-alt" flip="vertical" scale="1.3" /></span>
            </span>
        </div>
        <div class="note-details grey-bottom-border" v-if="!isExtracted" v-show="expanded">
            <div class="note-details-creator">
                <v-icon name="user" />
                <span>{{ $t('general.createdBy') }}: {{ mainForm ? mainForm.creator : 'unknown' }}</span>
            </div>
            <div class="note-details-last-updated">
                <v-icon name="clock" />
                <span v-if="mainForm">
                    {{ $t('general.lastUpdatedAt') }}: {{ mainForm.updatedAt | formatVDDate({ format: 'YYYY/MM/DD HH:mm A' }) }}
                    <span v-if="mainForm.lastEditedBy"> by {{mainForm.lastEditedBy}}</span>
                </span>
            </div>
            <div class="note-details-location" v-if="invoice">
                <v-icon name="map-marker-alt" />
                <span>&nbsp;{{ $t('general.visitLocation') }}: {{getClinicName}}</span>
            </div>
        </div>
        <div class="note-body" :class="isExtracted && 'is-extracted'" v-show="expanded">
            <div class="space-between">
              <span v-if="invoice" @click="shouldHideConsultNote = !shouldHideConsultNote" data-automation="consult-note">
                <span>
                  <span class="mr-2">
                    <span class="bold mr-2">{{ $t('general.consultNoteTemplate') }}</span>
                    <span v-if="shouldHideConsultNote"><v-icon name="caret-up"/></span>
                    <span v-else><v-icon name="caret-down"/></span>
                  </span>
                  <span class="mr-2" v-if="featureFlags.inpatient">
                    <b-button size="sm mx-1" variant="outline-success"
                     data-automation="consult-note-tag"
                      :disabled="invoice.clinic !== selectedClinic._id"
                      @click.stop="() => toggleTagSelectedCaseNotes(true)">{{ $t('general.tag') }}</b-button>
                    <b-button size="sm mx-1" variant="outline-danger"
                      :disabled="invoice.clinic !== selectedClinic._id"
                         data-automation="consult-note-untag"
                      @click.stop="() => toggleTagSelectedCaseNotes(false)">{{ $t('general.untag') }}</b-button>
                  </span>
                </span>
                <div>
                  <small v-if="mainForm.taggedTab || mainForm.otId" class="text-muted">
                    (Tagged
                    <span v-if="mainForm.otId"> to {{otMappedIdToName[mainForm.otId]}}</span>
                    <span v-if="mainForm.taggedTab"> under {{mainForm.taggedTab | translateTabName}}</span>)
                  </small>
                </div>
              </span>
              <span v-if="invoice && isEnableConsultNote">
                <button
                  v-if="!lockConsultNote && mainForm && mainForm.draft && invoice.clinic === selectedClinic._id"
                  @click="noteSaveDraft(false)" class="btn btn-outline-info btn-sm mr-2">
                  Save
                </button>
                <span @click="toggleConsultNoteLock">
                  <span v-if="lockConsultNote"><v-icon name="lock"/></span>
                  <span v-else><v-icon name="lock-open"/></span>
                </span>
              </span>
            </div>
            <b-collapse id="collapse-4" v-model="shouldHideConsultNote" class="mt-2">
                <div v-if="invoice && mainForm && mainForm.draft">
                    <b-form-select
                     data-automation="consult-note-soap-select"
                        :value="mainForm.templateId"
                        @change="updateFormTemplate($event)"
                        :options="consultNotetemplatesSelectOptions" size="sm" class="mt-3"
                      :disabled="lock || hasPassedNoteLockTimes || lockConsultNote || invoice.clinic !== selectedClinic._id"></b-form-select>
                </div>
                <br />
                <b-form v-if="invoice && mainForm && mainForm.formDataOrder">
                    <b-form-group
                        v-for="(qns, i) in mainForm.formDataOrder"
                        :key="i"
                        :label="mainForm.formData[qns].label"
                    >
                        <b-form-textarea
                        :data-automation="kebabCase('consult-notes-'+mainForm.formData[qns].label)"
                        :disabled="!mainForm.draft || lock || hasPassedNoteLockTimes || lockConsultNote || invoice.clinic !== selectedClinic._id"
                        :value="mainForm.formData[qns].answer"
                        @input="updateMainFormData($event, qns)"
                        rows="2"
                        max-rows="100"
                        required
                        no-resize
                        ></b-form-textarea>
                    </b-form-group>
                    <template v-if="mainForm && !mainForm.draft">
                        <div v-if="mainForm.addendums.length" class="mb-2">
                            <label>{{ $t('note.addendumAdded') }}</label>
                            <ul class="list-group">
                                <li class="list-group-item border"
                                    v-for="(addendum, index) in mainForm.addendums"
                                    :key="index">
                                    <p style="white-space: pre-line;"> {{ addendum.text }} </p>
                                    <div class="d-flex align-items-center">
                                        <v-icon name="user" class="text-muted mr-2"/>
                                        <div class="mr-3"> {{ addendum.username }} </div>
                                        <v-icon name="calendar-alt" class="text-muted mr-2"/>
                                        <div> {{ addendum.date | formatDateTime_DDdMMMYYYYFromX }} </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="form-group d-flex flex-column">
                            <textarea
                                class="form-control mb-2"
                                rows="2"
                                :placeholder="$t('note.enterAddendum')"
                                v-model="addendumNote"
																:disabled="lockConsultNote || invoice.clinic !== selectedClinic._id">
                            </textarea>
                            <button
                                type="button"
                                data-automation="consult-note-save-addendum"
                                class="btn btn-primary align-self-end"
                                @click="saveAddendum"
                                :disabled="!addendumNote || addendumNote && !addendumNote.trim() || lockConsultNote || invoice.clinic !== selectedClinic._id">
                                {{ $t('note.saveAddendum') }}
                            </button>
                        </div>
                    </template>
                </b-form>
            </b-collapse>
            <hr style="margin: 30px 0;" />
            <div>
                <div class="title bold space-between" v-if="invoice">
                  <span>
                    {{ $t('general.dispense' )}}
                  </span>
                  <span v-if="invoice && isEnableConsultNote">
                    <button v-if="!lockInvoice && mainForm && mainForm.draft && invoice && !invoice.finalized && invoice.clinic === selectedClinic._id"
                      @click="invoiceSaveDraft" class="btn btn-outline-info btn-sm mr-2 mb-1">
                      Save
                    </button>
                    <span @click="toggleInvoiceLock">
                      <span v-if="lockInvoice"><v-icon name="lock"/></span>
                      <span v-else><v-icon name="lock-open"/></span>
                    </span>
                  </span>
                </div>
                <div class="my-3 flex-fill"
                  v-if="invoice && !invoice.finalized && mainForm && mainForm.draft && invoice.clinic === selectedClinic._id">
                  <div v-if="!lock && !lockInvoice">
                    <vue-bootstrap-typeahead
                        v-if="!lock"
                        :data="searchInventoryResults"
                        :disabled="lock"
                        v-model="itemSearch"
                        class="wrapper"
                        inputClass="form-control"
                        :maxMatches="maxSearchResults"
                        :serializer="s => {
                            return `${s.name} ${s.category} (${s.givenId}), Type: ${s.inventory} ${s.brandName}`
                        }"
                        :placeholder="$t('general.searchInventoryItems')"
                        data-automation="search-inventory-items"
                        @hit="selectedItem($event)"
                        :clearOnHit="true"
                        textVariant="light"
                        backgroundVariant="primary">
                        <template slot="suggestion" slot-scope="{ data, htmlText }">
                            <b-container class="bv-example-row">
                                <b-row>
                                    <b-col>{{ data.inventory }}</b-col>
                                    <b-col>{{ data.category }}</b-col>
                                    <b-col>{{ data.givenId }}</b-col>
                                    <b-col>{{ data.name }}</b-col>
                                    <b-col>{{ data.brandName }}</b-col>
                                    <b-col>{{ data.strength }}</b-col>
                                </b-row>
                            </b-container>
                        </template>
                    </vue-bootstrap-typeahead>
                  </div>
                  <div v-else>
                      <input type="text" class="form-control" :placeholder="$t('general.searchInventoryItems')" disabled />
                  </div>
                </div>
                <div v-if="invoice">
                    <b-table
                        ref="invoicetable"
                        striped
                        hover
                        :fields="fields"
                        :items="invoiceItemsMapped"
                        v-if="invoiceItemsMapped.length > 0"
                    >
                        <template slot="name" slot-scope="row">
                            <div>{{row.value}}</div>
                            <template v-if="['Medicine', 'Consumables', 'Expendables', 'Product','Wyn'].includes(row.item.inventory)">
                              <b-badge class="batchnumber">
                                {{ row.item.selectedBatch }}
                              </b-badge>
                            </template>

                            <small v-if="row.item.testStatusId">
                                (Status: {{integratedTestStatusesMapped[row.item.testStatusId].name}}) {{integratedTestStatusesMapped[row.item.testStatusId].code}}
                            </small>
                            <!-- <small v-if="row.item.testStatus">(Status: {{row.item.testStatus}})</small> -->
                        </template>
                        <template slot="description" slot-scope="row">
                            <span v-if="!invoice.finalized && mainForm && mainForm.draft">
                                <b-input class="mb-2 mr-sm-2 mb-sm-0" size="sm" :value="row.value" @input="updateLineItem($event, row.item.uuid, 'description', invoiceConfig)" />
                            </span>
                            <span v-else>{{row.value}}</span>
                        </template>
                        <template slot="quantity" slot-scope="row">
                            <div>
                                <span class="space-between" v-if="!invoice.finalized && mainForm && mainForm.draft">
                                    <b-input-group size="sm" :append="row.item.unit" class="mr-sm-2">
                                    <b-input
                                        v-if="instructionsBuilderFeatureFlag && (row.item.inventory === 'Medicine' || row.item.inventory === 'Wyn')"
                                        class="mb-2 mb-sm-0"
                                        size="sm"
                                        :disabled="invoice.clinic !== selectedClinic._id"
                                        :value="(row.value / (invoice.instructions.timesPerDay * invoice.instructions.noOfDays)) | to2Decimal(region.decimal)"
                                        @input="preUpdateLineItem($event, row.item.uuid, 'qty', invoiceConfig)" />
                                    <b-input
                                        v-else-if="row.item.inventory === 'Package'"
                                        class="mb-2 mb-sm-0"
                                        size="sm"
                                        :disabled="invoice.clinic !== selectedClinic._id"
                                        :value="row.value"
                                        :formatter="qtyFormat"
                                        @input="updateLineItem($event, row.item.uuid, 'qty', invoiceConfig)" />
                                     <b-form-input
                                        v-else
                                        class="mb-2 mb-sm-0 qty-input"
                                        size="sm"
                                        type="number"
                                        :disabled="invoice.clinic !== selectedClinic._id"
                                        :value="row.value"
                                        debounce="500"
                                        @input="checkInputThenUpdateLineItem($event, row.item.uuid, 'qty', invoiceConfig)" />
                                    </b-input-group>
                                </span>
                                <span v-else>
                                    <span v-if="instructionsBuilderFeatureFlag && (row.item.inventory === 'Medicine' || row.item.inventory === 'Wyn')">
                                        {{(row.value / (invoice.instructions.timesPerDay * invoice.instructions.noOfDays)) | to2Decimal(region.decimal)}}
                                    </span>
                                    <span v-else>{{row.value}}</span>
                                </span>
                            </div>
                            <small class="text-primary"
                                v-if="(row.item.inventory === 'Medicine' || row.item.inventory === 'Wyn' ) &&
                                instructionsBuilderFeatureFlag &&
                                invoice.instructions.timesPerDay !== undefined &&
                                invoice.instructions.noOfDays !== undefined">
                                ({{ $t('general.total2') }}: {{row.item.quantity | to2Decimal(region.decimal)}} {{row.item.unit ? ` ${row.item.unit}`: ''}})
                            </small>
                        </template>
                        <template slot="dosage" slot-scope="row">
                            <span v-if="!invoice.finalized && mainForm && mainForm.draft && invoice.clinic === selectedClinic._id">
                                <b-input class="mb-2 mr-sm-2 mb-sm-0" size="sm" style="width:280px" :value="row.value" @input="updateLineItem($event, row.item.uuid, 'dosage', invoiceConfig)" />
                            </span>
                            <span v-else>{{row.value}}</span>
                        </template>
                        <template slot="dosageInstr" slot-scope="row">
                            <span v-if="!invoice.finalized && mainForm && mainForm.draft && invoice.clinic === selectedClinic._id">
                                <b-input class="mb-2 mr-sm-2 mb-sm-0" size="sm" style="width:280px" :value="row.value" @input="updateLineItem($event, row.item.uuid, 'dosageInstr', invoiceConfig)" />
                            </span>
                            <span v-else>{{row.value}}</span>
                        </template>
                        <template slot="action" slot-scope="row">
                            <div class="space-between">
                                <span
                                  v-if="!lockInvoice && mainForm && mainForm.draft && invoice.clinic === selectedClinic._id"
                                  style="display:flex; align-items: center; margin-right: 5px;"
                                  data-automation="dispense-builder-edit"
                                  @click="toggleDispenseBuilder(true, invoice, row.item.uuid)">
                                  <v-icon name="edit" />
                                </span>
                                <span
                                    style="display:flex; align-items: center;"
                                     data-automation="dispense-builder-times"
                                    v-if="
                                      row.item.testStatusId &&
                                      (
                                        (row.item.inventory === 'Radiology' && integratedTestStatusesMapped[row.item.testStatusId].code !== '00') ||
                                        (row.item.inventory === 'Laboratory' && integratedTestStatusesMapped[row.item.testStatusId].code !== '10')
                                      ) &&
                                      invoice.clinic === selectedClinic._id
                                    ">
                                    <v-icon name="times" color="grey" />
                                </span>
                                <span
                                    style="display:flex; align-items: center;"
                                    data-automation="dispense-builder-remove-btn"
                                    v-else-if="!lockInvoice && mainForm && mainForm.draft && invoice.clinic === selectedClinic._id"
                                    @click="removeItem(row.item.uuid)">
                                    <v-icon name="times" />
                                </span>
                                <div v-if="integratedTestConfig && integratedTestConfig.statuses && integratedTestConfig.statuses[row.item.inventory] && row.item.dispensedAt && invoice.clinic === selectedClinic._id">
                                    <b-button size="sm"
                                        class="mt-1"
                                        variant="outline-info"
                                        v-if="!row.item.testStatusId"
                                        :disabled="lockInvoice"
                                        v-acl:disabled.isDynamic.disabled="integratedTestConfig.statuses[row.item.inventory]['Confirmed'][0]._id"
                                        @click="confirmIntegrationTest(row.item)">
                                        {{integratedTestConfig.statuses[row.item.inventory]['Confirmed'][0].labelName || 'Confirm Test'}}
                                    </b-button>
                                    <b-button size="sm"
                                        class="mt-1"
                                        variant="outline-danger"
                                        :disabled="lockInvoice"
                                        v-acl:disabled.isDynamic.disabled="integratedTestConfig.statuses[row.item.inventory]['Cancelled'][0]._id"
                                        v-else-if="integratedTestStatusesMapped[row.item.testStatusId].statusGroup === 'Confirmed'"
                                        @click="cancelIntegrationTest(row.item)">
                                        {{integratedTestConfig.statuses[row.item.inventory]['Cancelled'][0].labelName || 'Cancel Test'}}
                                    </b-button>
                                    <span v-else></span>
                                </div>
                                <div v-else></div>
                            </div>
                        </template>
                    </b-table>
                </div>
                <hr style="margin: 30px 0;" />
                <div>
                    <div class="title space-between">
                        <span class="bold">{{ $t('general.charting') }}</span>
                        <span>
                            <!-- TODO: not working on ipad -->
                            <!-- <b-button variant="outline-primary" @click="increaseChartingNoteWidth" style="margin-left: 10px;" :disabled="defaultCardHeight > 500">
                                <span class="vertical-align">
                                    <v-icon name="plus" />
                                </span>
                            </b-button>
                            <b-button variant="outline-primary" @click="decreaseChartingNoteWidth" style="margin-left: 10px;" :disabled="defaultCardHeight < 250">
                                <span class="vertical-align">
                                    <v-icon name="minus" />
                                </span>
                            </b-button> -->
                            <!-- <b-button
                                v-if="mainForm && mainForm.draft"
                                v-acl="'consultation_upload_files'"
                                variant="outline-primary"
                                style="position: relative;">
                                    <form enctype="multipart/form-data">
                                        <input type="file" multiple @change="filesChange($event.target.files)" :value="inputFileValue"
                                            accept="image/jpeg,image/png,application/pdf" class="input-file">
                                            <v-icon name="upload" style="margin-right: 5px;"/>
                                            <span>{{ $t('general.uploadFiles') }}</span>
                                    </form>
                            </b-button>
                            <b-button
                                v-if="mainForm && mainForm.draft"
                                v-acl="'consultation_add_charting'"
                                variant="outline-primary"
                                @click="toggleTemplateSelector(true, invoice._id, invoice.mainFormId)"
                                style="margin-left: 10px;">
                                <span class="vertical-align">
                                    <v-icon name="plus" style="margin-right: 5px;"/>
                                    <span>{{ $t('general.addCharting') }}</span>
                                </span>
                            </b-button> -->
                        </span>
                    </div>
                    <charting-container
                        :info="info"
                        :patient="patient"
                        :parentComponent="'consult-note-container'"
                        :mainForm="mainForm"
                        :invoice="invoice || {}"
                        :invoices="invoices"
                        :notesMapped="notesMapped"
                        :categoriesMapped="categoriesMapped"
                        :selectedCategoryFilter="selectedCategoryFilter"
                        :fetchAllNoteTemplates="fetchAllNoteTemplates"
                        :invoiceFetchReady="invoiceFetchReady"
                        :presetChartingCardWidth="presetChartingCardWidth"
                        :presetChartingCardSize="presetChartingCardSize"
                        :lock="lock"
                        :inPatientFeatureFlag="inPatientFeatureFlag"
                        :otOptions="otOptions"
                        :otMappedIdToName="otMappedIdToName"
                        @chartingAddendumChange="checkMainFormChanges"
                        @notesmoved="(destinationCategory) => selectCategoryFilter(null, destinationCategory)"
                    />
                </div>
              <div v-if="!patientConfig.hideRecordICD10Section">
                <hr style="margin: 30px 0;" />
                <div class="title bold" v-if="invoice">
                    {{ $t('general.recordICD10') }}
                    <span v-if="patientConfig.mandatoryICD10Recording" class="text-primary">*</span>
                </div>
                <div class="my-3 p-4 flex-fill">

                  <table class="table diagnosis-table"
                    v-for="(diagnose,i) in diagnosis"
                    :key="i">
                    <tbody>
                      <tr  :key="i">
                        <th :rowspan="diagnose.length">
                          {{ i === 0 ? 'Primary Diagnosis' :
                              i === 1 ? 'Secondary Diagnosis' :
                                'Additional Diagnosis'}}
                        </th>
                        <td>
                          <el-autocomplete
                            class="diagnose-search"
                            :data-automation="setDataAutomationField(i)"
                            v-model="diagnose[0].text"
                            :debounce="500"
                            :clearable="true"
                            :fetch-suggestions="icd10SearchQuery"
                            :placeholder="$t('general.recordICD10')"
                            :disabled="(diagnose.length > 0 && diagnose[0].valid) || !allowEditDiagnosis || lock || hasPassedNoteLockTimes || lockInvoice || invoice.clinic !== selectedClinic._id"
                            @select="($event) => selectedICD10Diagnosis($event, i, 0)"
                            @input="($event) => changeDiagnoseSearch($event, i, 0)"
                            @clear="() => clearDiagnoseSearch(i, 0)"
                          >
                            <template slot-scope="props">
                              <icd10-slot :item="props.item" />
                            </template>
                          </el-autocomplete>
                        </td>
                        <td class="delete-column">
                          <button class="btn btn-secondary"
                            :disabled="!allowEditDiagnosis || lock || !diagnose[0].valid || hasPassedNoteLockTimes || lockInvoice || invoice.clinic !== selectedClinic._id"
                            @click="removeDiagnosis(i, 0)">
                            <v-icon name="times"/>
                          </button>
                        </td>
                      </tr>
                      <tr v-for="(entry, j) in diagnose.slice(1)"
                        :key="`${i}-${j+1}`">
                        <td>
                          <el-autocomplete
                            class="diagnose-search"
                            v-model="diagnose[j+1].text"
                            :debounce="500"
                            :clearable="true"
                            :fetch-suggestions="icd10SearchQuery"
                            :placeholder="$t('general.recordICD10')"
                            :disabled="(diagnose.length > 0 && diagnose[j+1].valid) ||!allowEditDiagnosis || lock || hasPassedNoteLockTimes || lockInvoice  || invoice.clinic !== selectedClinic._id"
                            @select="($event) => selectedICD10Diagnosis($event, i, j+1)"
                            @input="($event) => changeDiagnoseSearch($event, i, j+1)"
                            @clear="() => clearDiagnoseSearch(i, j+1)"
                          >
                            <template slot-scope="props">
                              <icd10-slot :item="props.item" />
                            </template>
                          </el-autocomplete>
                        </td>
                        <td class="delete-column">
                          <button class="btn btn-secondary"
                            :disabled="!allowEditDiagnosis || lock || !diagnose[j+1].valid || hasPassedNoteLockTimes || lockInvoice || invoice.clinic !== selectedClinic._id"
                            @click="removeDiagnosis(i, j+1)">
                            <v-icon name="times"/>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                </div>
                <div v-if="invoice">
                    <div class="title bold">
                        {{ $t('general.letterMC') }}
                    </div>
                    <div v-if="!lock && !hasPassedNoteLockTimes && !lockInvoice && mainForm.draft && invoice.clinic === selectedClinic._id">
                        <vue-bootstrap-typeahead
                            :data="letters"
                            v-model="letterSearch"
                            class="wrapper"
                            inputClass="form-control"
                            :serializer="s => {
                                return `${s.name} (${s.givenId})`
                            }"
                            data-automation="issue-lettermc-select"
                            :placeholder="$t('general.issueLetterMC')"
                            @hit="selectedLetter($event)"
                            :clearOnHit="true"
                            textVariant="light"
                            backgroundVariant="primary">
                            <template slot="suggestion" slot-scope="{ data, htmlText }">
                                <div class="d-flex">
                                    <span class="ml-2" v-html="htmlText"></span>
                                </div>
                            </template>
                        </vue-bootstrap-typeahead>
                    </div>
                    <div v-else>
                        <input type="text"  data-automation="issue-lettermc-text" disabled class="form-control" :placeholder="$t('general.issueLetterMC')" />
                    </div>
                    <div>
                        <b-table
                            striped
                            hover
                            :fields="letterFields"
                            :items="invoiceLettersMapped"
                            v-if="invoiceLettersMapped.length > 0"
                        >
                            <template slot="type" slot-scope="row">
                                <span class="space-between" v-if="mainForm && mainForm.draft && !hasPassedNoteLockTimes && !lockInvoice">
                                    <span>{{row.value}}</span>
                                    <span style="display:flex; align-items: center; margin-right: 5px;">
                                        <span class="mr-2" :data-automation="'print-letter-'+row.id" @click="printLetter(row.item)"><v-icon name="print" /></span>
                                        <span class="mr-2"
                                          v-if="invoice.clinic === selectedClinic._id"
                                          :data-automation="'toggle-letter-builder'+row.id"
                                          @click="toggleLetterBuilder(true, invoice, row.item)"><v-icon name="edit" /></span>
                                        <span class="mr-1"
                                          v-if="invoice.clinic === selectedClinic._id"
                                            :data-automation="'remove-letter-'+row.id"
                                          @click="removeLetter(row.item.uuid)"><v-icon name="times" /></span>
                                    </span>
                                </span>
                                <span v-else class="space-between">
                                    <span>{{row.value}}</span>
                                    <span class="mr-2" :data-automation="'print-letter-'+row.id" @click="printLetter(row.item)"><v-icon name="print" /></span>
                                </span>
                            </template>
                        </b-table>
                    </div>
                </div>
            </div>
        </div>
        <div class="note-footer space-between" v-if="mainForm && mainForm.draft && !isExtracted" v-show="expanded">
            <span></span>
            <span v-if="configConsultNotePublishMandatory">
                <b-button v-if="!isEnableConsultNote"
                :disabled="invoice.clinic !== selectedClinic._id"
                data-automation="consult-note-save-draft"
                class="mx-1 save-draft-button" @click="saveNote(false)">
                  {{ $t('general.saveDraft') }}
                </b-button>
                <b-button v-if="checkPublishValid && invoice.clinic === selectedClinic._id" disabled
                data-automation="consult-note-publish"
                title="Please unlock both notes and invoice to enable this button"
                class="mx-1 publish-button" @click="saveNote(true)">
                  {{ $t('general.publish') }}
                </b-button>
                <b-button v-else class="mx-1 publish-button" @click="saveNote(true)"
                  data-automation="consult-note-publish-disable"
                  :disabled="invoice.clinic !== selectedClinic._id">
                  {{ $t('general.publish') }}
                </b-button>
            </span>
            <span v-else>
              <b-button
                v-if="!$store.state.config.patient.enableConsultNoteSectioning"
                :disabled="invoice.clinic !== selectedClinic._id"
                class="mx-1"
                data-automation="consult-save-note"
                @click="saveNote(false)">
                {{ $t('general.save') }}
              </b-button>
            </span>
        </div>
        <b-modal ref="teleconsult-session-loading"
          title="TeleConsult"
          no-close-on-backdrop
          no-close-on-esc
          :visible="startingTeleConsulting"
          hide-footer>
          <p>
            Please wait. Connecting to the server ....
          </p>
        </b-modal>
        <b-modal ref="teleconsult-session-error"
          title="TeleConsult"
          no-close-on-backdrop
          no-close-on-esc
          :visible="inValidToStartSession"
          hide-footer>
          <p>
            {{ invalidReason }}
          </p>
        </b-modal>
        <b-modal
          :id="`${mainForm._id}-tagging-modal`"
          :title="`${tagParams.action} Consult Note`"
        >
          <template slot="default" v-if="tagParams.action === 'Tag'">
            <b-form-group>
              <b-form-select
                v-model="tagParams.tab"
                :options="tagOptions.tabs"
              ></b-form-select>
            </b-form-group>
            <b-form-group>
              <b-form-select
                v-model="tagParams.ot"
                :options="otOptions"
              ></b-form-select>
            </b-form-group>

          </template>
          <template v-slot:modal-footer>
            <button type="button"
              class="btn btn-secondary"
              @click="hideTagModal">
              {{ $t('general.cancel') }}
            </button>
            <button type="button"
              class="btn btn-primary"
              @click="handleTagModalOk">
              {{ $t('general.ok') }}
            </button>
          </template>
        </b-modal>
    </div>
</template>
<script>
import ChartingContainer from '@/components/ChartingContainer.vue';
import Dateformatter from '@/components/mixins/dateformatter';
import computedMixins from '@/components/mixins/computed';
import invoiceMethodsMixin from '@/components/mixins/invoice';
import moneyFormatter from '@/components/mixins/money';
import membership from '@/components/mixins/membership';
import inventoryService from '@/services/inventory.service';
import noteService from '@/services/note.service';
import invoiceService from '@/services/invoice.service';
import api from '@/services/api';
import moment from 'moment';
import uuid, { v4 as generateID } from 'uuid';
import _ from 'lodash';
import constant from '@/services/constant';
import PhoneDown from '@/components/Icons/PhoneDown.vue';
import ICD10AutoCompleteSlot from '@/components/Consultation/ICD10AutoCompleteSlot.vue';
import transformerMixin from '@/components/mixins/transformer';

import {
  mapGetters, mapActions, mapMutations, mapState,
} from 'vuex';
import { bus } from '@/main.js';

const validateDiagnosis = value => value[0][0].valid;

export default {
  name: 'ConsultNoteContainer',
  props: [
    'info',
    'patient',
    'invoice',
    'invoices',
    'mainForm',
    'isExtracted',
    // 'toggleTemplateSelector',
    'toggleDispenseBuilder',
    'toggleLetterBuilder',
    'selectedCategoryFilter',
    'configConsultNotePublishMandatory',
    'publishedCSSClass',
    'invoiceFetchReady',
    'presetChartingCardWidth',
    'selectCategoryFilter',
    'presetChartingCardSize',
    'isEnableConsultNote',
    'fetchInvoices',
    'isFirstConsultNote',
    'isInitiallyExpanded',
    'inPatientFeatureFlag',
    'otOptions',
    'otMappedIdToName',
  ],
  data() {
    return {
      isLoadedItems: false,
      diagnosis: [],
      form: {
        subjective: '',
        objective: '',
        assessment: '',
        plan: '',
      },
      formData: null,
      inventories: [],
      itemSearch: '',
      icd10: [],
      icd10Search: '',
      letters: [],
      letterSearch: '',
      shouldHideConsultNote: true,
      chartingNoteScale: 1,
      defaultCardHeight: 300,
      // showUploadManager: false,
      // filesToBeUploaded: [],
      // uploading: false,
      inputFileValue: null,
      selectedQData: {},
      // withUpload: false
      itemField: constant.INVOICE_FIELD.LINEITEM,
      addendumNote: '',
      mainFormClone: {},
      invoiceClone: {},
      diagnosisClone: [],
      lettersClone: [],
      chartingAddendumMap: {},
      maxSearchResults: constant.MAX_DISPENSE_SEARCHRESULTS,
      searchInventoryResults: [],
      lockConsultNote: true,
      lockInvoice: true,
      expanded: false,
      startingTeleConsulting: false,
      consultNotetemplatesSelectOptions: [],
      tagParams: {
        action: '',
        tab: '',
        ot: '',
      },
      tagOptions: {
        tabs: [
          { text: 'Medical Notes', value: 'medical notes' },
          { text: 'Consents', value: 'consents' },
        ],
      },
    };
  },
  watch: {
    expanded(newVal) {
      // alert(newVal);
      if (newVal && !this.isLoadedItems) {
        this.loadInvoice();
      }
    },
    itemSearch: _.debounce(function Fn(addr) {
      // console.log('debounce - addr is ', addr);
      this.simpleSearchInventories(addr);
    }, 500),
    icd10Search: _.debounce(function Fn(addr) {
      this.searchICD10(addr);
    }, 500),
    letterSearch: _.debounce(function Fn(addr) {
      // console.log('letterSearch debounce - addr is ', addr);
      this.searchLetters(addr);
    }, 500),
    consultNotetemplatesSelectOptions(newVal, oldVal) {
      if (newVal.length && !oldVal.length) {
        // console.log('updating this mainForm is ', this.mainForm);
        if (!this.mainForm.templateId) {
          this.mainForm.templateId = this.templatesSelectOptions[0].value;
          if (!this.mainForm.formData || !this.mainForm.formData.length) {
            this.mainForm.formData = this.consultNoteTemplatesOnly[this.mainForm.templateId].formDataBuilder;
          }
        }
      }
    },
    invoice: {
      handler() {
        console.log('Invoices changes');
        this.checkMainFormChanges();
      },
      deep: true,
    },
    'mainForm.formData': {
      handler() {
        console.log('Form Data changes');
        this.checkMainFormChanges();
      },
      deep: true,
    },
    addendumNote() {
      this.checkMainFormChanges();
    },
    teleConsultSession: {
      handler(updated) {
        console.log('Start consult time updated ', updated);
        this.selectedQData.teleConsultSession = updated;
      },
      deep: true,
    },
  },
  components: { ChartingContainer, PhoneDown, 'icd10-slot': ICD10AutoCompleteSlot },
  mixins: [Dateformatter, computedMixins, invoiceMethodsMixin, moneyFormatter, membership, transformerMixin],
  filters: {
    translateTabName(tabName) {
      return tabName === 'medical notes' ? 'Medical Notes' : 'Consents';
    },
  },
  computed: {
    ...mapGetters('config', {
      generalConfig: 'general',
      invoiceConfig: 'invoice',
      queueConfig: 'queueStatus',
      inventoryConfig: 'inventory',
      integratedTestConfig: 'integratedTest',
      integratedTestStatusesMapped: 'integratedTestStatusesMapped',
    }),
    ...mapGetters('teleConsult', {
      initiating: 'initiating',
      teleConsultSession: 'teleConsultSession',
      inValidToStartSession: 'inValidToStartSession',
      invalidReason: 'invalidReason',
    }),
    ...mapGetters('note', ['notesMapped', 'notecategories', 'categoriesMapped', 'categoriesOptions']),
    ...mapState('notetemplate', ['consultNoteTemplatesOnly', 'deletedConsultNoteTemplatesOnly', 'templatesSelectOptions']),
    qStartedConsult() {
      return this.selectedQData.start_consult > 0;
    },
    qEndedConsult() {
      return this.selectedQData.end_consult > 0;
    },
    qStartedTreatment() {
      return this.selectedQData.start_treatment > 0;
    },
    qEndedTreatment() {
      return this.selectedQData.end_treatment > 0;
    },
    qPhotoTaken() {
      return this.selectedQData.photo_taken > 0;
    },
    uncategorizedCategoryId() {
      return this.notecategories.filter(cat => cat.name === 'Uncategorized')[0]._id;
    },
    cardHeight() {
      return {
        height: `${this.defaultCardHeight}px`,
      };
    },
    invoiceItemsMapped() {
      if (this.invoice.items.length === 0) {
        return [];
      }
      return this.invoice.items.map(item =>
      // console.log('item is', item);
        ({
          _id: item._id,
          uuid: item.uuid,
          name: item.name,
          quantity: item.qty,
          description: item.description,
          dosage: item.dosage,
          dosageInstr: item.dosageInstr,
          unit: item.unit,
          inventory: item.inventory,
          testStatusId: item.testStatusId,
          selectedBatch: (item.selectedBatch && item.selectedBatch.number) || '',
          integratedTestId: item.integratedTestId,
          dispensedAt: item.dispensedAt,
        }));
    },
    icd10Mapped() {
      if (this.invoice.icd10.length === 0) {
        return [];
      }
      return this.invoice.icd10.map(i => ({ uuid: i.uuid, name: i.text }));
    },
    invoiceLettersMapped() {
      if (this.invoice.letters && this.invoice.letters.length === 0) {
        return [];
      }
      return (this.invoice.letters || []).map(letter => ({
        uuid: letter.uuid, name: letter.name, text: letter.text, type: letter.type,
      }));
    },
    fields() {
      return [
        { key: 'name', label: this.$t('general.name') },
        { key: 'description', label: this.$t('general.description') },
        { key: 'dosageInstr', label: this.$t('general.dosageInstruction') },
        { key: 'quantity', label: this.$t('general.quantity') },
        { key: 'action', label: this.$t('general.action') },
      ];
    },
    letterFields() {
      return [
        { key: 'name', label: this.$t('general.name') },
        { key: 'type', label: this.$t('general.type') },
      ];
    },
    icd10Fields() {
      const columns = {
        name: {
          label: this.$t('general.name'),
        },
      };
      return columns;
    },
    membership() {
      return this.$store.state.config.membership || {};
    },
    patientConfig() {
      return this.$store.state.config.patient || {};
    },
    lock() {
      if (!this.invoice.queue) return false;
      return (this.invoice.queue.start_consult || 0) <= 0 && (this.invoice.queue.start_treatment || 0) <= 0
            && this.patientConfig.lockConsultNotesUpdate;
    },
    allowEditDiagnosis() {
      return this.invoice && this.mainForm && this.mainForm.draft;
    },
    patientConfig() {
      return this.$store.state.config.patient;
    },
    hasPassedNoteLockTimes() {
      if (!this.patientConfig.lockConsultNoteAt) return false;
      const [hour, minute] = this.patientConfig.lockConsultNoteTime.split(':');
      const cutoff = moment(this.mainForm.consultationDate || 0, 'X')
        .set({ hour, minute, second: '59' }).unix();
      return moment().unix() > cutoff;
    },
    dispensableInventories() {
      const childFns = localStorage.getItem('acl__childrenFnIds');
      if (!childFns) {
        return ['NA'];
      }
      const aclInventories = JSON.parse(childFns);
      let invTypes = constant.INVENTORY_TYPES 
      invTypes = {...invTypes,...constant.INVENTORY_TYPES_MAKUANG}  
      const dispensable = Object.keys(invTypes)
        .filter(invtType => Object.keys(aclInventories).indexOf(invtType) >= 0);
      // dirty hack!! if only one inventory, api is returning all,
      // put the dirty hack NA & NIA
      // pls dont create inventory called NA and NIA
      return [...dispensable, ...['NA', 'NIA']];
    },
    instructionsBuilderFeatureFlag() {
      return this.$store.state.config.featureFlags.instructionsBuilder;
    },
    featureFlags() {
      return this.$store.state.config.featureFlags || {};
    },
    timesPerDay() {
      return this.invoice.instructions.timesPerDay;
    },
    noOfDays() {
      return this.invoice.instructions.noOfDays;
    },
    restrictedMedicines() {
      const childFns = localStorage.getItem('acl__childrenFnIds');
      if (!childFns) {
        return [];
      }
      const aclInventories = JSON.parse(childFns);
      const dispensableMedicines = Object.keys(constant.DRUG_CLASSIFICATION_OPTION_KH)
        .filter(invtType => Object.keys(aclInventories).indexOf(invtType) >= 0);

      const medicines = _.difference(
        Object.keys(constant.DRUG_CLASSIFICATION_OPTION_KH),
        dispensableMedicines,
      );

      return medicines;
    },
    checkPublishValid() {
      return (this.lockConsultNote
        || this.lockInvoice
        // || this.user.id !== this.invoice.mainFormId.editedUser
        || this.user.id !== this.invoice.editedUser)
        && this.$store.state.config.patient.enableConsultNoteSectioning;
    },
    isTeleConsultQueue() {
      return !!this.selectedQData.teleConsultSession;
    },
    getClinicName() {
      if (this.invoice.originalClinic) {
        return (this.clinicsById[this.invoice.originalClinic] || {}).name;
      }
      return this.clinicsById[this.invoice.clinic].name;
    },
  },
  mounted() {
    this.defaultCardHeight = localStorage.getItem('defaultCardHeight') || '300';
    this.selectedQData = this.invoice.queue;
    this.loadInvoiceDiagnosis();
    this.mainFormClone = _.cloneDeep(this.mainForm);
    this.invoiceClone = _.cloneDeep(this.invoice);
    this.lockConsultNote = this.isConsultNoteLocked();
    this.lockInvoice = this.isInvoiceSectioningLocked();
    this.consultNotetemplatesSelectOptions = _.cloneDeep(this.templatesSelectOptions);
    if (!this.consultNoteTemplatesOnly[this.mainFormClone.templateId]) {
      const template = this.deletedConsultNoteTemplatesOnly[this.mainFormClone.templateId];
      if (template) {
        this.consultNotetemplatesSelectOptions.push({
          value: template._id,
          text: `${template.name} (Deleted) (Category: ${(this.categoriesMapped[template.categoryId] || {}).name})`,
        });
      }
    }
    // this.lettersClone = _.cloneDeep(this.invoice.letters);
    // this.checkMainFormChanges();
  },

  methods: {
    ...mapActions('note', ['updateNoteById']),
    ...mapActions('notetemplate', ['fetchAllNoteTemplates']),
    ...mapActions('invoice', ['updateInvoiceById', 'loadInvoiceItems']),
    ...mapActions('queue', ['update']),
    ...mapActions('integratedtest', ['getHL7MessageTemplate', 'createIntegratedTest', 'sendHL7Message', 'cancelIntegratedTest']),
    ...mapActions('teleConsult', ['fetchTeleConsultSession', 'updateTeleConsultSession', 'endConsult', 'resetTeleConsultValidation']),
    qtyFormat(input) {
      if (isNaN(input)) {
        return '0';
      }
      return parseInt(input).toString();
    },
    toggleNote() {
      this.expanded = !this.expanded;
    },
    async loadInvoice() {
      await this.loadInvoiceItems(this.invoice._id);
      this.invoiceClone = _.cloneDeep(this.invoice);
      this.isLoadedItems = true;
      this.checkMainFormChanges();
    },
    isBatchedInventory(dispensedItem) {
      return (dispensedItem.inventory === 'Medicine' || dispensedItem.inventory === 'Wyn' || dispensedItem.inventory === 'Consumables' || dispensedItem.inventory === 'Expendables' || dispensedItem.inventory === 'Product');
    },
    async checkInputThenUpdateLineItem(value, itemUUID, fieldName, invoiceConfig, drugClassification) {
      let totalQty = value;
      if (!value) totalQty = 1;
      let itemid = itemUUID;
      let splitItemRemainingQty = 0;
      let context = {};
      do {
        // eslint-disable-next-line no-await-in-loop
        await this.updateKeyItem(totalQty, itemid, fieldName, invoiceConfig, drugClassification, context);
        const index = this.invoice.items.findIndex(item => item.uuid === itemid);
        if (index === -1) {
          const message = `${this.$t('general.invoiceErrorIndex')} : ${itemid}`;
          this.flash(message, 'error', { timeout: 5000 });
          throw new Error(message);
        }
        const dispensedItem = this.invoice.items[index];

        if (this.isBatchedInventory(dispensedItem)) {
          splitItemRemainingQty = context.splitItemRemainingQty || 0;

          if (splitItemRemainingQty !== 0) {
            // update qty to whats dispensed
            const dispensed = context.dispensed || totalQty; // for split context is set, if not assume all is dispensed
            this.invoice.items[index].qty = dispensed;

            const availBatches = dispensedItem.batches
              .filter(b => b.quantity > 0 && b.number !== dispensedItem.selectedBatch.number)
              .sort((a, b) => a.deliveryDate.localeCompare(b.deliveryDate));
            if (availBatches.length > 0) {
              const nextBatch = availBatches[0];
              const reuseBatchItemIndex = this.invoice.items
                .filter(i => i.id === dispensedItem.id)
                .findIndex(i => i.selectedBatch.number === nextBatch.number);
              if (reuseBatchItemIndex !== -1) { // there is a batch to be reused
                const nextItem = this.invoice.items[reuseBatchItemIndex];
                totalQty = parseInt(nextItem.qty, 10) + splitItemRemainingQty;
                itemid = nextItem.uuid;
                context = {};
                this.invoice.items[reuseBatchItemIndex].qty = totalQty;
              } else { // new line item is needed with diff uuid and qty set to remaining qty to dispense
                this.flash(this.$t('general.invoiceSplitItemPrompt'), 'info', { timeout: 7500 });
                // prepare next values for next iteration
                totalQty = splitItemRemainingQty;
                itemid = generateID();
                context = {};

                // we add new items with the same inventory as previous and try the remaining qty
                const newItem = _.cloneDeep(dispensedItem);
                newItem.qty = totalQty;
                newItem.uuid = itemid;
                newItem.batches = availBatches;
                this.invoice.items.push(newItem);
              }
            } else { // This statement below might not be needed bec selectedBatch handles this but putting here to be explicit
              this.flash(this.$t('general.invoiceErrorInsuff'), 'error', { timeout: 7500 });
              splitItemRemainingQty = 0;
            }
          }
        }
      } while (splitItemRemainingQty !== 0);

      this.$refs.invoicetable.refresh();
    },
    applyPricingScheme(item) {
      // if No selected Pricing Scheme then skip
      if (!this.selectedPricingScheme
                || Object.keys(this.selectedPricingScheme).length === 0) return item;

      const foundScheme = this.selectedPricingScheme.schemeInventory
        .find(ps => ps.type === item.inventory);

      if (foundScheme) {
        // Apply settings if Item is eligible
        const itemIsInactive = foundScheme.items
          .find(schemeItem => schemeItem.inventoryId === item._id
                    && schemeItem.inactive);
        if (!itemIsInactive) {
          item.discount = parseFloat(foundScheme.discount);
          item.discountType = foundScheme.discountType
            ? foundScheme.discountType : item.discountType;
        }
      }
      return item;
    },
    decreaseChartingNoteWidth() {
      this.defaultCardHeight *= 0.8;
      localStorage.setItem('defaultCardHeight', this.defaultCardHeight);
    },
    increaseChartingNoteWidth() {
      this.defaultCardHeight *= 1.2;
      localStorage.setItem('defaultCardHeight', this.defaultCardHeight);
    },
    updateFormTemplate(e) {
      // console.log('in updateFormTemplate e is ', e);
      if (!window.confirm('Changing the template will result in loss of unsaved data, proceed?')) {
        return;
      }
      const newTemplate = this.consultNoteTemplatesOnly[e];
      this.$store.commit('note/UPDATE_MAIN_FORM_TEMPLATE', { noteId: this.mainForm._id, template: newTemplate });
    },
    removeItem(itemUuid) {
      this.$store.commit('invoice/REMOVE_LINE_ITEM', { invoiceId: this.invoice._id, uuid: itemUuid });
      this.$nextTick(() => {
        let clonedInvoice = Object.assign({}, this.invoice);
        delete clonedInvoice.classifiedItemRevision;
        clonedInvoice.dispenseItemRevision = uuid();
        clonedInvoice.dispensedBy = this.user.id;
        clonedInvoice = this.calculateInvoice(clonedInvoice, undefined, this.invoiceConfig);
        this.$store.commit('invoice/UPDATE_INVOICE', clonedInvoice);
      });
    },
    removeICD10(uuid) {
      this.$store.commit('invoice/REMOVE_ICD10', { invoiceId: this.invoice._id, uuid });
    },
    printLetter(letter) {
      // console.log('in printLetter() - letter is ', letter);
      letter.clinic = this.invoice.clinic;
      this.$store.commit('patient/print', letter);
      const routeData = this.$router.resolve({ name: 'patientLetter' });
      window.open(routeData.href);
    },
    removeLetter(uuid) {
      this.$store.commit('invoice/REMOVE_LETTER', { invoiceId: this.invoice._id, uuid });
    },
    extractNote(invoice) {
      // console.log('EXTRACT_NOTE 3 invoice is ', invoice);
      this.$store.commit('note/EXTRACT_NOTE', {
        noteId: invoice.mainFormId._id, invoiceId: invoice._id, openSize: 'mediumSized', type: 'consult',
      });
    },
    updateMainFormData: _.debounce(function fn(e, qns) {
      // console.log('in update main form data, e is ', e);
      this.$store.commit('note/UPDATE_MAIN_FORM_DATA', { noteId: this.mainForm._id, qns, val: e });
    }, 300),
    async saveNote(publishing) {
      this.mainFormClone = _.cloneDeep(this.mainForm);
      this.invoiceClone = _.cloneDeep(this.invoice);
      this.diagnosisClone = _.cloneDeep(this.diagnosis);
      if (publishing) {
        if (
          this.patientConfig.mandatoryICD10Recording
          && this.$v.diagnosis.$invalid
        ) {
          this.flash('Record ICD-10 is required.', 'error', { timeout: 3000 });
          return;
        }
        const confirm = window.confirm(
          '\nPublishing of consult notes is irreversible.\n\nAre you sure you want to proceed?',
        );
        if (!confirm) {
          this.flash('Publishing of note cancelled.', 'warning', { timeout: 3000 });
          return;
        }
        if (
          this.$store.state.config.patient.enableConsultNoteSectioning
          && this.$store.state.config.patient.allowEnhancedControl
        ) {
          try {
            const resp = await noteService.checkPublishProvider({
              invoiceId: this.invoice._id,
            });
            if (!resp.ok) {
              this.flash(resp.body.message, 'warning', { timeout: 5000 });
              return;
            }
          } catch (error) {
            this.flash(error.message, 'error', { timeout: 3000 });
            return;
          }
        }
      }
      await this.$store.dispatch('invoice/PATCH_INVOICE', { id: this.invoiceClone._id, key: 'letters' });
      this.noteSaveDraft(publishing);
      this.invoiceSaveDraft();
    },
    noteSaveDraft(publishing) {
      this.mainFormClone = _.cloneDeep(this.mainForm);
      // console.log('saving...this mainForm is ', this.mainForm);
      this.$store
        .dispatch('note/submitNote', { noteId: this.mainForm._id, publishing })
        .then((resp) => {
          if (resp.ok) {
            if (publishing) {
              this.flash('Note published successfully', 'success', {
                timeout: 3000,
              });
            } else {
              this.flash('Note draft saved successfully', 'success', {
                timeout: 3000,
              });
            }
          } else if (publishing) {
            this.flash(
              resp.body.message || 'Failed to publish note, please try again',
              'error',
              { timeout: 5000 },
            );
          } else {
            this.flash(
              resp.body.message || 'Failed to save note draft, please try again',
              'error',
              { timeout: 5000 },
            );
          }
        });
    },
    invoiceSaveDraft() {
      this.invoiceClone = _.cloneDeep(this.invoice);
      this.diagnosisClone = _.cloneDeep(this.diagnosis);
      if (!this.invoice.finalized) {
        if (this.invoice.invoiceNo === 'draft') {
          this.$store.commit('invoice/UPDATE_INVOICE_GST_X_RATE', {
            invoice: this.invoice,
            includeGST: this.invoiceConfig.includeGST,
            exchangeRate: this.invoiceConfig.exchangeRate,
          });
        }
        this.$store.dispatch('invoice/SAVE_INVOICE', { id: this.invoice._id, voidInvoice: false })
          .then((invResp) => {
            if (invResp.success) {
              this.flash('Invoice details saved successfully', 'success', { timeout: 3000 });
            } else {
              this.flash('Failed to save invoice details', 'error', { timeout: 5000 });
            }
          })
          .catch((e) => {
            this.flash(e, 'error', { timeout: 5000 });
          });
      }
    },
    async simpleSearchInventories(query) {
      if (query === '' || query.includes('(')) return;
      const q = String(query).trim();
      const params = {
        qs: q,
        fields: 'givenId,name,description,brandName,category,inventory,strength',
        inventoryTypes: (this.dispensableInventories || []).join(','),
        ninventory: this.inventoryConfig.salesOptionsConfig.expendablesAreSellable ? '' : 'Expendables',
        ndrugclassification: this.restrictedMedicines.join(','),
        clinicCode: this.selectedClinic._id,
        status: false,
        limit: this.maxSearchResults,
      };
      if (params.ndrugclassification === '') {
        delete params.ndrugclassification;
      }
      const res = await inventoryService.fetchSimplify(params);
      const data = await res.json();
      this.searchInventoryResults = data;
    },
    async searchInventories(query) {
      // prevent inventory search when typeahead result is selected or query is empty
      if (query === '' || query.includes('(')) return;
      const q = String(query).trim();
      const params = {
        qs: q,
        fields: 'inventory,category,pointToRedeem,drugClassification,givenId,name,description,price,priceMin,cost,tax,discountType,precaution1,precaution2,dosage,label,subInventory,unit,defaultDispenseQty,purchasingUnit,qtyOfInventoryUnitPerPurchasingUnit,validitymonths,creditAmount,imageKey,thumbnailKey,subInventoryRef,providerCommissionAmt,providerCommissionType,therapistCommissionAmt,therapistCommissionType,consultantCommissionAmt,consultantCommissionType,brandName',
        inventoryTypes: (this.dispensableInventories || []).join(','),
        ninventory: this.inventoryConfig.salesOptionsConfig.expendablesAreSellable ? '' : 'Expendables',
        ndrugclassification: this.restrictedMedicines.join(','),
        clinicCode: this.selectedClinic._id,
        status: false,
        limit: 10,
      };
      if (params.ndrugclassification === '') {
        delete params.ndrugclassification;
      }
      const res = await inventoryService.fetchWoMovement(params, this.patient._id);
      const data = await res.json();
      this.inventories = data;
    },
    async selectedItem(dispensedItem) {
      const patientID = this.patient._id;
      const response = await inventoryService.fetchInventoryForDispense(patientID, dispensedItem._id, this.selectedClinic._id);
      if (response.data) {
        const item = response.data;
        if (item.instruction) {
          item.dosageInstr = `${item.dosage} ${item.instruction}`;
        } else {
          item.dosageInstr = item.dosage;
        }
        delete item.movements;
        this.dispenseItem(item, this);
      } else {
        this.flash(this.$t('general.invoiceErrorNotDispense'), 'error', { timeout: 7500 });
      }
    },
    async searchICD10(query) {
      const q = String(query).trim();
      const fields = 'class_code,preferred_label_english,preferred_label_chinese';
      const res = await api.get(`/icd10?qs=${q}&fields=${fields}`);
      const data = await res.json();
      this.icd10 = data;
    },
    loadInvoiceDiagnosis() {
      this.diagnosis = [
        [
          ...(this.invoice.diagnosis[0] || []).map(d => ({ ...d, valid: true })),
          { uuid: '', text: '', valid: false },
        ],
      ];
      if (this.patientConfig.multipleICD10Recording) {
        this.diagnosis = [
          ...this.diagnosis,
          [
            ...(this.invoice.diagnosis[1] || []).map(d => ({ ...d, valid: true })),
            { uuid: '', text: '', valid: false },
          ],
          [
            ...(this.invoice.diagnosis[2] || []).map(d => ({ ...d, valid: true })),
            { uuid: '', text: '', valid: false },
          ],
        ];
      }
      this.diagnosisClone = _.cloneDeep(this.diagnosis);
    },
    icd10Representation(item) {
      if (item.class_code.indexOf('-') > -1) {
        return '';
      }
      return `${item.class_code} - ${item.preferred_label_english} ${item.preferred_label_chinese ? `- ${item.preferred_label_chinese}` : ''}`;
    },
    icd10SearchQuery(queryString, cb) {
      const q = String(queryString).trim();
      if (q.length === 0) {
        cb([]);
      } else {
        const fields = 'class_code,preferred_label_english,preferred_label_chinese,is_heading';
        api.get(`/icd10?qs=${q}&fields=${fields}`)
          .then(res => res.json())
          .then((data) => {
            cb(data = data.map(link => ({ value: this.icd10Representation(link), link })));
          });
      }
    },
    changeDiagnoseSearch(query, i, j) {
      this.diagnosis[i][j].uuid = '';
      this.diagnosis[i][j].valid = false;
    },
    clearDiagnoseSearch(i, j) {
      if (this.diagnosis[i].some(d => !d.valid)) {
        this.removeDiagnosis(i, j);
      } else {
        this.diagnosis[i][j].valid = false;
        this.diagnosis[i][j].uuid = '';
      }
    },
    selectedICD10Diagnosis(event, i, j) {
      if (event.link && event.link.is_heading) {
        this.removeDiagnosis(i, j);
        if (!this.diagnosis[i].some(d => !d.valid)) {
          this.diagnosis[i].push({ uuid: '', text: '', valid: false });
        }
        return;
      }
      this.diagnosis[i][j].valid = true;
      if (!this.diagnosis[i].some(d => !d.valid)) {
        this.diagnosis[i].push({ uuid: '', text: '', valid: false });
      }
      this.$store.commit('invoice/UPDATE_ICD10', { invoiceId: this.invoice._id, updatedDiagnosis: this.diagnosis });
    },
    removeDiagnosis(i, j) {
      const items = this.diagnosis[i];
      const filteredItems = items.slice(0, j).concat(items.slice(j + 1, items.length));
      this.$set(this.diagnosis, i, filteredItems);
      this.$store.commit('invoice/UPDATE_ICD10', { invoiceId: this.invoice._id, updatedDiagnosis: this.diagnosis });
    },
    selectedICD10(event) {
      const item = Object.assign({}, event);
      const icd10_text = `${item.class_code} - ${item.preferred_label_english}`;
      this.$store.commit('invoice/ADD_ICD10', { invoiceId: this.invoice._id, icd10_text });
    },
    async searchLetters(query) {
      const q = String(query).trim();
      const fields = 'givenId,name,text,type,referral';
      const res = await api.get(`/letter?qs=${q}&fields=${fields}&clinicCode=${this.selectedClinic._id}`);
      const data = await res.json();
      this.letters = data;
    },
    addWeekdays(date_m, days) {
      let clone = moment(date_m);
      while (days > 0) {
        clone = clone.add(1, 'days');
        // decrease "days" only if it's a weekday.
        if (clone.isoWeekday() !== 6 && clone.isoWeekday() !== 7) {
          days -= 1;
        }
      }
      return clone;
    },
    addMoment(date_m, num, type) {
      const clone = moment(date_m);
      return clone.add(num, type);
    },
    selectedLetter(event) {
      const letter = Object.assign({}, event);
      // console.log('selectedLetter - letter is ', letter);
      const info = { // TODO: to add in date and provider helper info too
        patient: this.patient,
        clinic: this.$store.state.clinic.selectedClinic,
        config: this.$store.state.config.general,
      };
      info.provider = this.providersMappedById[this.invoice.queue.provider || ''] || {};
      const today_m = moment();
      const date = {
        today_date: today_m.format('dddd, Do MMMM YYYY'),
        one_b_day_later: this.addWeekdays(today_m, 1).format('dddd, Do MMMM YYYY'),
        three_b_days_later: this.addWeekdays(today_m, 3).format('dddd, Do MMMM YYYY'),
        five_b_days_later: this.addWeekdays(today_m, 5).format('dddd, Do MMMM YYYY'),
        one_day_later: this.addMoment(today_m, 1, 'day').format('dddd, Do MMMM YYYY'),
        three_days_later: this.addMoment(today_m, 3, 'day').format('dddd, Do MMMM YYYY'),
        five_days_later: this.addMoment(today_m, 5, 'day').format('dddd, Do MMMM YYYY'),
        two_weeks_later: this.addMoment(today_m, 2, 'week').format('dddd, Do MMMM YYYY'),
        one_month_later: this.addMoment(today_m, 1, 'month').format('dddd, Do MMMM YYYY'),
        two_months_later: this.addMoment(today_m, 2, 'month').format('dddd, Do MMMM YYYY'),
        three_months_later: this.addMoment(today_m, 3, 'month').format('dddd, Do MMMM YYYY'),
        six_months_later: this.addMoment(today_m, 6, 'month').format('dddd, Do MMMM YYYY'),
        from_date: '{from_date}',
        to_date: '{to_date}',
        days: '{days}',
      };
      info.date = date;
      if (letter.type === 'Referral' && letter.referral) {
        info.referral = letter.referral;
      }
      let populated_text = '';
      let path = '';
      let gettingVariable = false;
      // console.log('info is ', info);
      for (let i = 0; i < letter.text.length; i++) {
        const c = letter.text[i];
        if (!gettingVariable) {
          if (c !== '{') {
            populated_text += c;
          } else {
            gettingVariable = true;
          }
        } else if (c !== '}') {
          path += c;
        } else {
          // console.log('path is ', path);
          const value = _.get(info, path) || '';
          // console.log('value is ', value);
          populated_text += value;
          path = '';
          gettingVariable = false;
        }
      }
      letter.text = populated_text;
      this.$store.commit('invoice/ADD_LETTER', { invoiceId: this.invoice._id, letter });
      this.toggleLetterBuilder(true, this.invoice, letter);
    },
    ...mapMutations('invoice', ['extractInvoiceNotes']),
    updateQueue(field) {
      const id = this.invoice.queue._id;
      const payload = {};
      const isConsultFields = new Set(['start_consult', 'end_consult', 'start_treatment', 'end_treatment', 'photo_taken', 'invoice', 'payment']);
      payload[field] = moment().valueOf();
      const queueStatus = this.checkQueueConfigParameter(constant.RULE_ENGINE_CONFIG[field]);
      if (queueStatus) payload.queue_status = queueStatus;
      const params = { id, payload };
      this.update(params).then((qRes) => {
        if (qRes.success) {
          this.selectedQData = qRes.data;
          if (isConsultFields.has(field)) {
            this.$store.commit('invoice/PATCH_INVOICE', { id: this.invoice._id, key: 'queue', value: this.selectedQData });
            this.notify(id, 'update', params);
          }
        }
      });
    },
    printConsultNote(pathOptions) {
      const vm = this;
      if (this.mainForm.draft) return;

      const consultNotePrintInfo = {
        patient: vm.info.patient,
        dispensedItems: vm.invoiceItemsMapped,
        invoice: vm.invoice,
        mainForm: vm.mainForm,
      };

      vm.$store.dispatch('note/print', consultNotePrintInfo);

      const routeData = this.$router.resolve(pathOptions);

      window.open(routeData.href);
    },
    endTeleConsult() {
      const teleConsultPayload = {
        _id: this.selectedQData.teleConsultSession._id,
        endConsultTime: moment().unix(),
      };
      this.endConsult({ payload: teleConsultPayload, orgcode: localStorage.getItem('orgCode') })
        .then((updatedTeleConsult) => {
          // bus.$emit('END_TELECONSULT', updatedTeleConsult);
          this.selectedQData.teleConsultSession = updatedTeleConsult;
        });
    },
    notify(id, action, data) {
      const user = localStorage.getItem('user');
      this.$socket.emit('SEND_NOTIFICATION', {
        room: localStorage.getItem('orgCode'),
        payload: {
          userId: user.id,
          user: user.name,
          type: 'notify',
          message: `Queue data: ${action}`,
          goto: '/queue',
          time: moment().valueOf(),
          module: {
            id,
            name: 'queue',
            action,
            data,
          },
        },
      });
    },
    checkQueueConfigParameter(parameter) {
      console.log('checkQueueConfigParameter', parameter);
      const queueCon = this.queueConfig.rules;
      let retConfig = '';
      queueCon.forEach((config) => {
        if (config.parameter == parameter && config.status) {
          retConfig = config.ruleStatus;
        }
      });
      return retConfig;
    },
    preUpdateLineItem(value, uuid, fieldName, invoiceConfig) {
      const totalQty = value * (this.invoice.instructions.timesPerDay || 2) * (this.invoice.instructions.noOfDays || 3);
      this.updateLineItem(totalQty, uuid, fieldName, invoiceConfig);
    },
    async confirmIntegrationTest(lineItem) {
      console.log('lineItem ', lineItem);

      try {
        const dateNow = moment();
        const clinicCode = (this.generalConfig.clinicCode || 'TPH').slice(0, 2).toUpperCase();
        const shortenedUuid = `${clinicCode}${this.invoice.invoiceNo}${dateNow.format('YY')}${lineItem.uuid.slice(lineItem.uuid.length - 3)}${dateNow.format('SSS')}`;

        // 1. create a order in DH database
        this.flash('Confirming test order with EMR database...', 'info', { timeout: 7500 });
        const currentStatusId = this.integratedTestConfig.statuses[lineItem.inventory].Confirmed[0]._id;
        await this.createIntegratedTest({
          invoiceId: this.invoice._id,
          lineItemUuid: lineItem.uuid,
          diagnosis: lineItem.dosage,
          currentStatusId,
          shortenedUuid,
        });
        this.flash('Order confirmed with EMR database successfully', 'success', { timeout: 7500 });

        // 2. if save in EMR DB is successful, then send to local labs machine
        if (lineItem.inventory === 'Radiology') {
          this.flash('Preparing data to send order to test machine...', 'info', { timeout: 7500 });
          this.flash('Sending order info to test machine...', 'info', { timeout: 7500 });
        }
        const sendHL7OrderMessage = await this.sendHL7Message({
          patientId: this.$route.params.id,
          invoiceId: this.invoice._id,
          masterInventoryId: lineItem._id,
          lineItemUuid: lineItem.uuid,
          diagnosis: lineItem.dosage,
          shortenedUuid,
        });
        if (lineItem.inventory === 'Radiology') {
          this.flash('Order sent to test machine successfully', 'success', { timeout: 7500 });
        }
      } catch (e) {
        console.log('caught e is ', e);
        this.flash(e, 'error', { timeout: 7500 });
      }
    },
    async cancelIntegrationTest(lineItem) {
      this.flash('Preparing data to cancel order to test machine...', 'info', { timeout: 5000 });
      this.flash('Sending cancellation info to test machine...', 'info', { timeout: 5000 });
      // console.log('lineItem ', lineItem);
      try {
        // comment me out
        // const sendHL7OrderMessage = await this.sendHL7Message({ url: testUrl, message: HL7MessageTemplate });
        const currentStatusId = this.integratedTestConfig.statuses[lineItem.inventory].Cancelled[0]._id;
        await this.cancelIntegratedTest({
          invoiceId: this.invoice._id,
          integratedTestId: lineItem.integratedTestId,
          currentStatusId,
        });
        this.flash('Order cancellation sent to test machine successfully', 'success', { timeout: 5000 });
        // 3. if sendHL7OrderMessage is successful, then create a order in DH database
        // this.createIntegratedTest({ invoiceId: this.invoice._id, lineItemUuid: lineItem.uuid });
      } catch (e) {
        console.log('caught e is ', e);
        this.flash(e, 'error', { timeout: 5000 });
      }
    },
    // uncomment it when start/end consult/treatment require notification
    // showQueueNotification(field) {
    //    if (field === 'start_consult') {
    //      this.flash('Consultation started successfully', 'success', { timeout: 3000 });
    //    } else if (field === 'end_consult') {
    //      this.flash('Consultation ended successfully', 'success', { timeout: 3000 });
    //    } else if (field === 'start_treatment') {
    //      this.flash('Treatment started successuflly', 'success', { timeout: 3000 });
    //    } else if (field === 'start_treatment') {
    //      this.flash('Treatment ended successuflly', 'success', { timeout: 3000 });
    //    } else if (field === 'photo_taken') {
    //      this.flash('Photo taken successuflly', 'success', { timeout: 3000 });
    //    }
    // }
    saveAddendum() {
      const addendums = [...this.mainForm.addendums];
      addendums.push({
        date: moment().unix(),
        user: this.user.id,
        username: this.user.name,
        text: this.addendumNote || '',
      });
      const payLoad = {
        id: this.mainForm._id,
        data: { addendums },
      };
      this.$store.dispatch('note/patchNote', payLoad)
        .then(() => {
          this.flash('Addendum addeded successfully', 'success', { timeout: 3000 });
          this.addendumNote = '';
        });
    },
    checkMainFormChanges({ mainFormId, chartingAddendumChange } = {}) {
      // TODO: check after publish
      const hasChangeTemplate = this.checkTemplateFormData();
      const hasChangeIcd10 = this.checkIcd10Changes();
      const hasLettersChange = this.checkLetters();
      const dispenseChange = this.checkDispenseItems();
      const consultAddendumChange = !!this.addendumNote;
      //   console.log(this.mainForm._id, ' Template Change >> ', hasChangeTemplate);
      //   console.log(this.mainForm._id, ' ICD10 Change >> ', hasChangeIcd10);
      //   console.log(this.mainForm._id, ' Letter Change >> ', hasLettersChange);
      //   console.log(this.mainForm._id, ' Dispense Change >> ', dispenseChange);
      //   console.log(this.mainForm._id, ' Consult Addendum Change >> ', consultAddendumChange);
      if (chartingAddendumChange !== undefined) {
        this.$set(this.chartingAddendumMap, this.mainForm._id, chartingAddendumChange);
      }
      if (hasChangeTemplate
            || hasChangeIcd10
            || hasLettersChange
            || dispenseChange
            || consultAddendumChange
            || (chartingAddendumChange || this.chartingAddendumMap[this.mainForm._id])) {
        this.$store.commit('note/SET_MAIN_FORMS_CHANGE_LOGS', { mainFormId: this.mainForm._id, flag: true });
      } else {
        this.$store.commit('note/SET_MAIN_FORMS_CHANGE_LOGS', { mainFormId: this.mainForm._id, flag: false });
      }
    },
    checkTemplateFormData() {
      let flag = false;
      // eslint-disable-next-line no-restricted-syntax
      for (const qns of this.mainForm.formDataOrder) {
        if (this.mainForm.formData[qns].answer !== this.mainFormClone.formData[qns].answer) {
          flag = true; break;
        }
      }
      return flag;
    },
    checkIcd10Changes() {
      const primaryDiagnosis = this.diagnosis[0];
      const secondaryDiagnosis = this.diagnosis[1];
      const additionalDiagnosis = this.diagnosis[2];
      return this.checkDiagnosis(this.diagnosisClone[0], primaryDiagnosis)
              || this.checkDiagnosis(this.diagnosisClone[1], secondaryDiagnosis)
              || this.checkDiagnosis(this.diagnosisClone[2], additionalDiagnosis);
    },
    checkDiagnosis(source = [], compare) {
      // something is removed or added
      // console.log(source, compare)
      if (source.length !== compare.length) return true;
      const notFound = compare.filter(c => !source.some(s => c.text === s.text));
      // console.log('Not Found ', notFound);
      return !!notFound.length;
    },
    checkLetters() {
      if (this.invoiceClone.letters && this.invoiceClone.letters.length !== this.invoice.letters.length) return true;
      let flag = false;
      // eslint-disable-next-line no-restricted-syntax
      for (const letter of this.invoice.letters) {
        const has = this.invoiceClone.letters.find(l => l.uuid === letter.uuid);
        if (!has) {
          flag = true;
          break;
        } else if (has && has.text !== letter.text) {
          flag = true;
          break;
        } else {
          flag = false;
        }
      }
      return flag;
    },
    checkDispenseItems() {
      // something is added or removed
      if (this.invoiceClone.items.length !== this.invoice.items.length) return true;

      let flag = false;
      // eslint-disable-next-line no-restricted-syntax
      for (const dispenseItem of this.invoice.items) {
        const item = this.invoiceClone.items.find(i => i.uuid === dispenseItem.uuid);
        if (!item) {
          flag = true; break;
        } else if (this.checkItemChange(item, dispenseItem)) {
          flag = true; break;
        } else {
          flag = false;
        }
      }
      return flag;
    },
    checkItemChange(item, dispenseItem) {
      return item.description !== dispenseItem.description
            || item.dosage !== dispenseItem.dosage
            || +item.qty !== +dispenseItem.qty;
    },
    checkMainFormChartingAddendum() {
      if (this.mainForm && this.mainForm.draft) return false;
    },
    async toggleConsultNoteLock() {
      try {
        const resp = await noteService.toggleLock(this.mainForm._id, {
          isLocked: !this.lockConsultNote,
          invoiceId: this.invoice._id,
        });
        if (resp.ok) {
          this.$store.commit(
            'note/UPDATE_NOTE_LOCK_STATUS',
            { noteId: this.mainForm._id, isLocked: !this.lockConsultNote },
          );
          if (this.lockConsultNote) {
            await this.updateNoteById({ id: this.mainForm._id, returnFullDoc: true });
          }
          this.lockConsultNote = !this.lockConsultNote;
        }
      } catch (error) {
        this.flash(error.body && error.body.message, 'error', { timeout: 5000 });
      }
    },
    async toggleInvoiceLock() {
      try {
        const resp = await invoiceService.toggleLock(
          this.invoice._id, { isLocked: !this.lockInvoice },
        );
        if (resp.ok) {
          this.$store.commit(
            'invoice/UPDATE_INVOICE_LOCK_STATUS',
            { invoiceId: this.invoice._id, isLocked: !this.lockInvoice },
          );
          if (this.lockInvoice) {
            await this.updateInvoiceById({ id: this.invoice._id });
            this.loadInvoiceDiagnosis();
          }
          this.lockInvoice = !this.lockInvoice;
        }
      } catch (error) {
        this.flash(error.body && error.body.message, 'error', { timeout: 5000 });
      }
    },
    setDataAutomationField(index) {
      if (index === 0) { return _.kebabCase('Primary Diagnosis'); }
      return index === 1 ? _.kebabCase('Secondary Diagnosis') : _.kebabCase('Additional Diagnosis');
    },
    isConsultNoteLocked() {
      return this.$store.state.config.patient.enableConsultNoteSectioning
              && this.mainForm
              && (this.mainForm.editedUser !== this.user.id
              || this.mainForm.isLocked);
    },
    isInvoiceSectioningLocked() {
      //   const invoiceQueue = this.invoice.queue || {};
      //   const { provider, secondaryProviders = [] } = invoiceQueue;
      //   const providers = [provider, ...secondaryProviders].filter(p => p);
      return (this.invoice.editedUser !== this.user.id
              || this.invoice.isLocked)
      //   || this.$store.state.config.patient.allowEnhancedControl
      //   && !providers.includes(this.user.id))
              && this.$store.state.config.patient.enableConsultNoteSectioning;
    },
    async onBeforeUnload() {
      if (!this.$store.state.config.patient.enableConsultNoteSectioning) return;
      if (!this.lockConsultNote) await this.toggleConsultNoteLock();
      if (!this.lockInvoice) await this.toggleInvoiceLock();
    },
    toggleExpandCollapseStatus(flag) {
      this.expanded = flag;
    },
    startTeleConsult() {
      this.startingTeleConsulting = true;
      this.fetchTeleConsultSession(
        {
          sessionId: this.selectedQData.teleConsultSession._id,
          orgcode: localStorage.getItem('orgCode'),
          ENDED_BY_HOST: this.$t('teleConsult.endedByHost'),
          CANCELLED_BY_HOST: this.$t('teleConsult.cancelledByHost'),
          NOT_YET_STARTED: this.$t('teleConsult.appointmentNotStartedYet'),
          UNKNOWN_SERVER_ERROR: this.$t('teleConsult.unknownError'),
        },
      ).then(({ session, validToStart }) => {
        this.startingTeleConsulting = false;
        if (validToStart) {
          bus.$emit('TELECONSULT_START', {
            room: session.virtualRoomId,
            provider: session.host,
            patient: session.patient,
            orgcode: localStorage.getItem('orgCode'),
            enableFullScreen: true,
          });
        }
      });
    },
    clearTagParams() {
      this.tagParams = {
        action: '',
        tab: '',
        ot: '',
      };
    },
    toggleTagSelectedCaseNotes(isTag) {
      this.clearTagParams();
      if (isTag && !this.otOptions.length) {
        alert(this.$t('general.patientNoOTBookings'));
        return;
      }
      if (isTag) {
        this.tagParams.action = 'Tag';
        this.tagParams.tab = 'medical notes';
        this.tagParams.ot = this.otOptions[0].value;
      } else {
        this.tagParams.action = 'Untag';
      }
      this.$bvModal.show(`${this.mainForm._id}-tagging-modal`);
    },
    hideTagModal() {
      this.$bvModal.hide(`${this.mainForm._id}-tagging-modal`);
    },
    handleTagModalOk() {
      const params = {
        ids: [this.mainForm._id],
        updates: {
          taggedTab: this.tagParams.tab || null,
          otId: this.tagParams.ot || null,
        },
      };
      const verb = this.tagParams.action.toLowerCase();
      this.$store.dispatch('note/patchNotes', params)
        .then((res) => {
          if (res.ok) {
            this.hideTagModal();
            this.flash(`Consult note ${verb}ged successfully`, 'success', { timeout: 3000 });
          } else {
            this.flash(`Failed to ${verb} consult note, please try again`, 'error', { timeout: 4000 });
          }
        });
    },
  },
  created() {
    if (this.$store.state.config.patient.enableConsultNoteSectioning) {
      window.addEventListener('beforeunload', this.onBeforeUnload);
      bus.$on('consult_onleave', () => {
        if (this && this.onBeforeUnload) this.onBeforeUnload();
      });
    }
    if (!this.patientConfig.visitDateViewDefaultMode || this.patientConfig.visitDateViewDefaultMode === 'OPEN_ALL_NOTES') {
      this.expanded = true;
    } else if (this.patientConfig.visitDateViewDefaultMode === 'OPEN_ONLY_FIRST_NOTE') {
      this.expanded = this.isFirstConsultNote;
    }
  },
  beforeDestroy() {
    if (this.$store.state.config.patient.enableConsultNoteSectioning) {
      window.removeEventListener('beforeunload', this.onBeforeUnload);
    }
    this.resetTeleConsultValidation();
  },
  validations: {
    diagnosis: {
      validateDiagnosis,
    },
  },
};

</script>
<style lang="scss" scoped>
 /* up and down arrow on input introduces a bug on change qty so removing it */
 /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

.absolute {
    position: absolute;
}
.space-around {
  display: flex;
  justify-content: space-around;
}
.space-between {
  display: flex;
  justify-content: space-between;
}

.invoice-note-container {
    max-width: 100%;
    >* {
      padding: 16px 16px 16px 16px;
    }
    .is-extracted {
        padding-left: 8px;
        padding-right: 8px;
    }
    .note-label {
        margin: 0.4px;
        background-color: rgb(240, 240, 240);
        border-bottom: 1.5px solid rgb(60, 60, 60);
        &.draft {
            background-color: rgb(255, 218, 116);
        }
        &.published {
            background-color: rgb(169,229,196);
        }
        &.fixed {
            background-color: rgb(211, 208, 208);
        }
    }
    .note-details {
      * {
        margin-right: 5px;
      }
      span {
        display: inline-block;
        vertical-align: middle;
        line-height: 1em;
      }
    }
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0,0,0,.5);
        box-shadow: 0 0 1px rgba(255,255,255,.5);
    }
    >.note-body {
        height: 50%;
    }
}
.grey-bottom-border {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.grey-bottom-border2 {
    border-top: 2px solid rgba(183,183,183, 0.5);
}
.wrapper {
    position: relative;
}
.vertical-align {
    display: flex;
    align-items: center;
    justify-content: center;
}
.bold {
    font-weight: bolder;
}
.input-file {
    opacity: 0; /* invisible but it's there! */
    top: 0%;
    right: 0%;
    max-width: 100%;
    height: 100%;
    position: absolute;
}
.save-draft-button {
  background-color: rgb(255, 218, 116);
  color: black;
}
.publish-button {
  pointer-events: auto;
  background-color: rgb(169,229,196);
  color: black;
}

.diagnosis-table {
  tr {
    th {
      text-align: left;
      vertical-align: middle;
      width: 20%;
    }

    td.delete-column {
      width: 5%;
    }

    .diagnose-search {
      width: 100%;
    }
  }
}
</style>
