import _ from 'lodash';

import otintraopService from '@/services/otintraop.service';

import {
  SET_METADATA,
  SET_INDUCTIONS,
  SET_INPUTS,
  SET_EVENTS,
  SET_END_OF_SURGERY,
  SET_INDUCTION_FINALIZED,
  SET_FINALIZED,
  SET_AIRWAYS,
  SET_TEMPECGS,
  SET_VITALS,
} from './type.mutation';

const state = {
  metadata: {},
  inductions: {},
  inputs: {
    records: [],
    medicines: {},
    fluids: {},
    fluidLoss: {
      'Blood Loss': {
        total: 0,
        data: [0],
      },
      'Urine Output': {
        total: 0,
        data: [0],
      },
    },
    totalCols: 0,
  },
  events: [],
  endOfSurgery: {
    eetlma: '',
    entries: {},
  },
  isInductionFinalized: false,
  inductionFinalizedBy: {},
  inductionFinalizedAt: null,
  isFinalized: false,
  isFinalizedBy: {},
  isFinalizedAt: null,
  vitals: [],
  airways: [],
  tempECGs: [],
};

/* eslint-disable no-param-reassign */
const mutations = {
  [SET_METADATA](_state, payload) {
    _state.metadata = _.cloneDeep(payload);
  },
  [SET_INDUCTIONS](_state, payload) {
    _state.inductions = _.cloneDeep(payload);
  },
  [SET_INPUTS](_state, payload) {
    let inputs = !_.isEmpty(payload)
      ? _.cloneDeep(payload)
      : {
        records: [],
        medicines: {},
        fluids: {},
        fluidLoss: {
          'Blood Loss': {
            total: 0,
            data: [0],
          },
          'Urine Output': {
            total: 0,
            data: [0],
          },
        },
        totalCols: 0,
      };
    if (!_.isEmpty(payload)) {
      let totalCols = 0;
      const medicines = {};
      const records = inputs.map(i => ({
        recordedAt: i.recordedAt,
        recordedBy: i.recordedBy,
      }));
      const idToIndex = inputs.reduce((acc, data, index) => ({
        ...acc,
        [data._id]: index,
      }), {});
      inputs.forEach(({ _id: inputID, medicines: med }) => {
        Object.keys(med).forEach((id) => {
          if (!(id in medicines)) {
            medicines[id] = {
              data: (new Array(records.length)).fill(0),
              total: 0,
            };
          }
          const val = parseInt(med[id], 10);
          medicines[id].data[idToIndex[inputID]] = val;
          medicines[id].total += val;
        });
      });
      const fluids = {};
      inputs.forEach(({ _id: inputID, fluids: med }) => {
        Object.keys(med).forEach((id) => {
          if (!(id in fluids)) {
            fluids[id] = {
              data: (new Array(records.length)).fill(0),
              total: 0,
            };
          }
          const val = parseInt(med[id], 10);
          fluids[id].data[idToIndex[inputID]] = val;
          fluids[id].total += val;
        });
      });
      const fluidLoss = {};
      inputs.forEach(({ _id: inputID, fluidLoss: med }) => {
        Object.keys(med).forEach((id) => {
          if (!(id in fluidLoss)) {
            fluidLoss[id] = {
              data: (new Array(records.length)).fill(0),
              total: 0,
            };
          }
          const val = parseInt(med[id], 10);
          fluidLoss[id].data[idToIndex[inputID]] = val;
          fluidLoss[id].total += val;
        });
      });
      totalCols = records.length;
      inputs = {
        records, totalCols, medicines, fluids, fluidLoss,
      };
    }
    _state.inputs = _.cloneDeep(inputs);
  },
  [SET_EVENTS](_state, payload) {
    _state.events = _.cloneDeep(payload);
  },
  [SET_END_OF_SURGERY](_state, payload) {
    _state.endOfSurgery = payload
      ? _.cloneDeep(payload)
      : {
        eetlma: '',
        entries: {},
      };
  },
  [SET_INDUCTION_FINALIZED](
    _state,
    { isInductionFinalized, inductionFinalizedBy, inductionFinalizedAt },
  ) {
    _state.isInductionFinalized = isInductionFinalized;
    _state.inductionFinalizedBy = inductionFinalizedBy;
    _state.inductionFinalizedAt = inductionFinalizedAt;
  },
  [SET_FINALIZED](
    _state,
    { isFinalized, isFinalizedBy, isFinalizedAt },
  ) {
    _state.isFinalized = isFinalized;
    _state.isFinalizedBy = isFinalizedBy;
    _state.isFinalizedAt = isFinalizedAt;
  },
  [SET_AIRWAYS](_state, payload) {
    _state.airways = _.cloneDeep(payload);
  },
  [SET_TEMPECGS](_state, payload) {
    _state.tempECGs = _.cloneDeep(payload);
  },
  [SET_VITALS](_state, payload) {
    _state.vitals = _.cloneDeep(payload);
  },
};

const actions = {
  loadData({ commit }, param = {}) {
    return otintraopService.get(param)
      .then((response) => {
        const {
          metadata, inductions, inputs, events, endOfSurgery,
          isInductionFinalized, inductionFinalizedBy, inductionFinalizedAt,
          isFinalized, isFinalizedBy, isFinalizedAt,
          airways, tempECGs, vitals,
        } = response.data;
        commit(SET_METADATA, metadata);
        commit(SET_INDUCTIONS, inductions);
        commit(SET_INPUTS, inputs);
        commit(SET_EVENTS, events);
        commit(SET_END_OF_SURGERY, endOfSurgery);
        commit(SET_INDUCTION_FINALIZED,
          { isInductionFinalized, inductionFinalizedBy, inductionFinalizedAt });
        commit(SET_FINALIZED, { isFinalized, isFinalizedBy, isFinalizedAt });
        commit(SET_AIRWAYS, airways);
        commit(SET_TEMPECGS, tempECGs);
        commit(SET_VITALS, vitals);
      });
  },
  updateMetadata({ commit }, {
    patient, admission, metadata, otbooking,
  }) {
    return otintraopService.updateMetadata({ patient, admission, otbooking }, metadata)
      .then((response) => {
        if (response.success) {
          console.log('the output >> ', response);
          commit(SET_METADATA, response.data.metadata);
        }
      });
  },
  saveInductions({ commit }, {
    patient, admission, inductions, otbooking,
  }) {
    return otintraopService.recordInduction({ patient, admission, otbooking }, inductions)
      .then((response) => {
        if (response.success) {
          console.log('the output >> ', response);
          commit(SET_INDUCTIONS, (response.data && response.data.inductions) || {});
        }
      });
  },
  recordInput({ commit }, {
    patient, admission, input, otbooking,
  }) {
    return otintraopService.recordInput({ patient, admission, otbooking }, input)
      .then((response) => {
        if (response.success) {
          console.log('the output >> ', response);
          commit(SET_INPUTS, (response.data && response.data.inputs));
        }
      });
  },
  recordData({ commit }, {
    patient, admission, type, payload, otbooking,
  }) {
    return otintraopService.recordData({
      patient, admission, type, otbooking,
    }, payload)
      .then((response) => {
        if (response.success) {
          const { airways, tempECGs, vitals } = response.data;
          commit(SET_AIRWAYS, airways);
          commit(SET_TEMPECGS, tempECGs);
          commit(SET_VITALS, vitals);
        }
        return response;
      });
  },
  saveEvent({ commit }, {
    patient, admission, event, otbooking,
  }) {
    return otintraopService.recordEvent({ patient, admission, otbooking }, event)
      .then((response) => {
        if (response.success) {
          console.log('the output >> ', response);
          commit(SET_EVENTS, (response.data && response.data.events) || []);
        }
      });
  },
  saveEndOfSurgery({ commit }, {
    patient, admission, endOfSurgery, otbooking,
  }) {
    return otintraopService.recordEndOfSurgery({ patient, admission, otbooking }, endOfSurgery)
      .then((response) => {
        if (response.success) {
          console.log('the output >> ', response);
          commit(SET_END_OF_SURGERY, (response.data && response.data.endOfSurgery) || {
            eetlma: '',
            entries: {},
          });
        }
      });
  },
  finalizeInduction({ commit }, { patient, admission, otbooking }) {
    return otintraopService.finalizeInduction({ patient, admission, otbooking })
      .then((response) => {
        if (response.success) {
          console.log('the output >> ', response);
          const {
            isInductionFinalized, inductionFinalizedBy, inductionFinalizedAt,
          } = response.data;
          commit(SET_INDUCTION_FINALIZED, {
            isInductionFinalized, inductionFinalizedBy, inductionFinalizedAt,
          });
        } else {
          commit(SET_INDUCTION_FINALIZED, {
            isInductionFinalized: false,
            inductionFinalizedBy: {},
            inductionFinalizedAt: null,
          });
        }
      });
  },
  finalize({ commit }, { patient, admission, otbooking }) {
    return otintraopService.finalize({ patient, admission, otbooking })
      .then((response) => {
        if (response.success) {
          console.log('the output >> ', response);
          const {
            isFinalized, isFinalizedBy, isFinalizedAt,
          } = response.data;
          commit(SET_FINALIZED, {
            isFinalized, isFinalizedBy, isFinalizedAt,
          });
        } else {
          commit(SET_FINALIZED, {
            isFinalized: false,
            isFinalizedBy: {},
            isFinalizedAt: null,
          });
        }
      });
  },
  resetAll({ commit }) {
    commit(SET_METADATA, {});
    commit(SET_INDUCTIONS, []);
    commit(SET_INPUTS, []);
    commit(SET_EVENTS, []);
    commit(SET_AIRWAYS, []);
    commit(SET_TEMPECGS, []);
    commit(SET_VITALS, []);
    commit(SET_END_OF_SURGERY, {
      eetlma: '',
      entries: {},
    });
    // commit(SET_INDUCTION_FINALIZED, { false, {}, null });
  },
};

const getters = {
  metadata: _state => _state.metadata,
  isInductionFinalized: _state => _state.isInductionFinalized,
  inductionFinalizedBy: _state => _state.inductionFinalizedBy,
  inductionFinalizedAt: _state => _state.inductionFinalizedAt,
  isFinalized: _state => _state.isFinalized,
  isFinalizedBy: _state => _state.isFinalizedBy,
  isFinalizedAt: _state => _state.isFinalizedAt,
  inductions: _state => _state.inductions,
  inputs: _state => _state.inputs,
  events: _state => _state.events,
  endOfSurgery: _state => _state.endOfSurgery,
  airways: _state => _state.airways,
  tempECGs: _state => _state.tempECGs,
  vitals: _state => _state.vitals,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
