import _ from 'lodash';

import api from '@/services/api';
import constant from '@/services/constant';

export default {
  list(params) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);

    Object.keys(params).forEach((key) => {
      q.push(`${key}=${params[key]}`);
    });
    const url = `/otbooking/?${q.join('&')}`;

    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`List Error: ${response}, url: ${url}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },

  providerOT(params) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);
    Object.keys(params).forEach((key) => {
      q.push(`${key}=${params[key]}`);
    });

    const url = `/otbooking/for-provider?${q.join('&')}`;
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`List Error: ${response}, url: ${url}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },

  otDatesByPatient(patient) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);
    const params = {
      status: 'APPROVED',
      patient,
      fields: 'bookingDate',
    };
    Object.keys(params).forEach((key) => {
      q.push(`${key}=${params[key]}`);
    });
    const url = `/otbooking/lean?${q.join('&')}`;

    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`List Error: ${response}, url: ${url}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },

  getInvoiceList(otBookingIdList) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);
    const params = {
      otBooking: otBookingIdList,
    };
    Object.keys(params).forEach((key) => {
      q.push(`${key}=${params[key]}`);
    });
    const url = `/otinvoice/list/?${q.join('&')}`;

    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`List Error: ${response}, url: ${url}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },
  otIdByAdmissionList(admissionList) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);
    const params = {
      status: 'APPROVED',
      fields: 'admission',
      admission: admissionList,
    };
    Object.keys(params).forEach((key) => {
      q.push(`${key}=${params[key]}`);
    });
    const url = `/otbooking/lean?${q.join('&')}`;

    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`List Error: ${response}, url: ${url}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },

  getById(id) {
    const url = `/otbooking/${id}`;

    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`Error: ${response.data.message}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },


  create(payload) {
    const url = '/otbooking/';

    return new Promise((resolve, reject) => {
      api.post(url, payload).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            // TODO: Chan : Make this cooler
            // get at least one from array of errors
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },

  update(id, payload) {
    const url = `/otbooking/${id}`;

    return new Promise((resolve, reject) => {
      api.put(url, payload).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            // TODO: Chan : Make this cooler
            // get at least one from array of errors
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },

  patch(id, payload) {
    const url = `/otbooking/${id}`;

    return new Promise((resolve, reject) => {
      api.patch(url, payload).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            // TODO: Chan : Make this cooler
            // get at least one from array of errors
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },

  patchProcedure(id, procedure, payload) {
    const url = `/otbooking/${id}/procedure/${procedure}`;

    return new Promise((resolve, reject) => {
      api.patch(url, payload).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            // TODO: Chan : Make this cooler
            // get at least one from array of errors
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },

  destroy(id) {
    const url = `/otbooking/${id}`;

    return new Promise((resolve, reject) => {
      api.delete(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            // TODO: Chan : Make this cooler
            // get at least one from array of errors
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },

  fetchOTInvoice(id) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);

    const url = `/otinvoice/${id}/?${q.join('&')}`;
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`List Error: ${response}, url: ${url}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },
  updateOneSectionAndOTInvoice(id, { invoice, sectionName }) {
    const url = `/otinvoice/${id}/${sectionName}`;
    return new Promise((resolve, reject) => {
      api.put(url, invoice).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },
  updateOTInvoice(id, payload) {
    const url = `/otinvoice/${id}`;

    return new Promise((resolve, reject) => {
      api.put(url, payload).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },
};
