/* eslint-disable */
import userService from '../services/user.service';
import router from '@/router';
import i18n from '@/i18n.js'

let user = {};
const state = user
  ? { status: { loggedIn: true }, user }
  : { status: {}, user: null };


const getters = {
  user: _state => _state.user,
  status: _state => _state.status,
};

const actions = {
  // eslint-disable-next-line
  login({ dispatch, commit, rootGetters }, { orgCode, username, password }) {
    commit('loginRequest', { username });
    userService.login(orgCode, username, password)
      .then((u) => {
        if(u.user.twoFactorAuth == true) {
          const accessToken = localStorage.getItem('access_token');
          localStorage.setItem('access_token_temp', accessToken);
          localStorage.setItem('xsrf_temp', accessToken);
          // localStorage.setItem('access_token', '');
          // localStorage.setItem('xsrf', '');
          // // window.location = '/twoFactor';  
          router.push({ name: 'twofactor', params: { userDetails: u }})
          return false
        } else{
        return u;
      }
      })
      .then(
        (u) => {   
          if(!u) return false;       
          user = JSON.parse(localStorage.getItem('user'))
          dispatch('clinic/fetchClinics', u.user.clinics.join(','), { root: true })
            .then((response) => {
             // debugger
              if (response.success) {
                commit('loginSuccess', u.user);
                dispatch(
                  'config/loadConfig',
                  { clinicCode: rootGetters['clinic/selectedClinic']._id },
                  { root: true },
                );
                // TODO: comment out when full ACL version is implemented
                dispatch('acl/setUserFunctions', {
                  functions: u.functions,
                  nav: u.nav,
                }, { root: true });
                // window.location = '/queue'
                if(u.nav && u.nav.main[0]) {
                    const index = u.nav.main[0];
                    const indexUrl = index.url ? index.url : u.nav.subMain[index.functionId][0].url;
                    dispatch('task/listTasksByUser', { user: u.user.id, isCompleted: false }, { root: true })
                    .then(() => {
                      let redirectTo = '/';
                      // Temp solution until we have user preferred homepage preferences
                      if (u.orgCode && (u.orgCode === 'MDX' || u.orgCode.startsWith('MDX_'))) {
                        redirectTo = '/lab-tests';
                      }
                      router.push(redirectTo);
                    });
                } else {
                  dispatch('task/listTasksByUser', { user: u.user.id, isCompleted: false }, { root: true })
                    .then(() => {
                      router.push('/queue');
                    });
                }
              } else {
                commit('loginFailure', response.data);
                dispatch('alert/error', i18n.t(response.data), { root: true });
              }
            });
        },
        (error) => {
          commit('loginFailure', error);
          dispatch('alert/error', i18n.t(error), { root: true });
        },
      );
  },
  switchAccount({ dispatch, commit }, { orgCode, username }) {
    commit('loginRequest', { username });
    userService.switchAccount(orgCode, username)
      .then((u) => {
        if(u.user.twoFactorAuth == true) {
          const accessToken = localStorage.getItem('access_token');
          localStorage.setItem('access_token_temp', accessToken);
          localStorage.setItem('xsrf_temp', accessToken);
          router.push({ name: 'twofactor', params: { userDetails: u }})
          return false
        } else{
        return u;
      }
      })
      .then(
        (u) => {   
          if(!u) return false;       
          user = JSON.parse(localStorage.getItem('user'))
          dispatch('clinic/fetchClinics', u.user.clinics.join(','), { root: true })
            .then((response) => {
              if (response.success) {
                 commit('loginSuccess', u.user);
                dispatch('acl/setUserFunctions', {
                  functions: u.functions,
                  nav: u.nav,
                }, { root: true });
                if(u.nav && u.nav.main[0]) {
                    const index = u.nav.main[0];
                    const indexUrl = index.url ? index.url : u.nav.subMain[index.functionId][0].url;
                    dispatch('task/listTasksByUser', { user: u.user.id, isCompleted: false }, { root: true })
                    .then(() => {
                      window.location = '/';
                    });
                } else {
                  dispatch('task/listTasksByUser', { user: u.user.id, isCompleted: false }, { root: true })
                    .then(() => {
                      router.push('/queue');
                    });
                }
              } else {
                commit('loginFailure', response.data);
                dispatch('alert/error', i18n.t(response.data), { root: true });
              }
            });
        },
        (error) => {
          commit('loginFailure', error);
          dispatch('alert/error', i18n.t(error), { root: true });
        },
      );
  },
  logout({ commit }) {
    userService.logout();
    commit('logout');
  },
  validateOTP({ dispatch, commit }, { otp }) {
    userService.validateOTP(otp)
      .then(
        (u) => {
              if (u.otp === otp) {
                commit('otpSuccess');
               
              } else {
                commit('otpFailure');
                dispatch('alert/error', 'The OTP you entered could not be authenticated. Please try again.', { root: true });
              }
        },
        (error) => {
          commit('otpFailure');
          dispatch('alert/error', 'The OTP you entered could not be authenticated. Please try again.', { root: true });
        },
      );
  },
  loginSuccess({ dispatch, commit }, { user }) {
    commit('loginSuccess', user);
  },
  loginFailure({ dispatch, commit }, { response }) {
    commit('loginFailure', response.data);
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  loginRequest(_state, u) {
    _state.status = { loggingIn: true };
    _state.user = u;
  },
  loginSuccess(_state, u) {
    _state.status = { loggedIn: true };
    _state.user = u;
  },
  otpSuccess(_state) {
    _state.status = { otpSuccessed: true };
  },
  otpFailure(_state) {
    _state.status = { otpSuccessed: false };
  },
  loginFailure(_state) {
    _state.status = { loggedIn: false };
    _state.user = null;
  },
  logout(_state) {
    _state.status = {};
    _state.user = null;
  },
  getAllFailure(_state, error) {
    _state.user = { error };
  }
};

// eslint-disable-next-line
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};