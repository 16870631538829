<template>
  <div class="container-fluid">
    <div v-for="(patientItem, index) in commonPatientInvoiceArray"
         :key="index">
      <div class="title text-center mt-4">
        医療費請求書兼領収書
      </div>
      <div class="invoice-info">
        <div class="info-left">
          <div class="item-wrap">
            <div class="item-label">
              患者氏名
            </div>
            <div class="item-value text-center">
              {{patientItem.patientName}}
            </div>
          </div>
          <div class="item-wrap">
            <div class="item-label">
              診療月
            </div>
            <div class="item-value-wrap">
              <div class="item-value-year text-center">
                {{yearNumber}}
              </div>
              <div class="item-label-year">
                年
              </div>
              <div class="item-value-month text-center">
                {{monthNumber}}
              </div>
              <div class="item-label-month">
                月
              </div>
            </div>
          </div>
          <div class="item-wrap">
            <div class="item-label">
              入院·外来区分
            </div>
            <div class="item-value-shrink text-center">
              外来
            </div>
          </div>
        </div>
        <div class="info-right">
          <div class="item-wrap">
            <div class="item-label">
              ID番号
            </div>
            <div class="item-value-no-border text-center">
            {{patientItem.patientGivenId}}
            </div>
          </div>
          <div class="item-wrap">
            <div class="item-label">
              診療科名
            </div>
            <div class="item-value-no-border text-center">
            {{classificationInvoicesInfo.serviceInfo.text}}
            </div>
          </div>
          <div class="item-wrap">
            <div class="item-label">
              通院日数
            </div>
            <div class="item-value-wrap">
              <div class="item-value-day text-center">
                {{patientItem.items.length}}
              </div>
              <div class="item-label-day">
                日
              </div>
              <div class="item-mark-day">
                （回)
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="invoice-total-price">
        <div class="tip">
          請求金額の内訳
        </div>
        <div class="total-price-wrap">
          <span>請求·領収金額</span>
          <span class="total-price">
            {{ region.currency }} {{ patientItem.totalPrice | to2Decimal | thousandSeparator }}
          </span>
          <span>人民元</span>
        </div>
      </div>
      <div class="item-table-list">
        <div class="table-header row">
          <div class="col-2 text-center bdr-2">
            日付
          </div>
          <div class="col text-center bdr-2">
            诊察费
          </div>
          <div class="col text-center bdr-2">
            検查費
          </div>
          <div class="col text-center bdr-2">
            処置费
          </div>
          <div class="col text-center bdr-2">
            薬費
          </div>
          <div class="col-3 text-center bdr-2">
            その他（休日）
          </div>
          <div class="col text-center">
            合計
          </div>
        </div>
        <div class="table-item row"
            v-for="(item, index) in patientItem.items"
            :key="index">
          <div class="col-2 text-center bdr-2 table-item-value">
            {{item.invoiceFormatDate}}
          </div>
          <div class="col text-center bdr-2 table-item-value">
            {{ region.currency }} {{ item.examinationFee | to2Decimal | thousandSeparator }}
          </div>
          <div class="col text-center bdr-2 table-item-value">
            {{ region.currency }} {{ item.inspectionFee | to2Decimal | thousandSeparator }}
          </div>
          <div class="col text-center bdr-2 table-item-value">
            {{ region.currency }} {{ item.disposalFee | to2Decimal | thousandSeparator }}
          </div>
          <div class="col text-center bdr-2 table-item-value">
            {{ region.currency }} {{ item.medicineFee | to2Decimal | thousandSeparator }}
          </div>
          <div class="col-3 text-center bdr-2 table-item-value">
            {{ region.currency }} {{ item.otherFee | to2Decimal | thousandSeparator }}
          </div>
          <div class="col text-center table-item-value">
            {{ region.currency }} {{ item.corporateInvoice | to2Decimal | thousandSeparator }}
          </div>
        </div>
      </div>
      <div class="invoice-footer">
        <div class="clinic-info-wrap">
          <div class="clinic-logo-wrap">
            <img class="clinic-logo" src="@/assets/clinicLogo.jpg">
          </div>
          <div class="clinic-info">
            <div class="clinic-name">
              上海平和門診部
            </div>
            <div class="clinic-url">
              http://www.peaceclinic.cn
            </div>
          </div>
        </div>
        <div class="clinic-contact-wrap">
          <div class="clinic-address">
            上海市古北路1078号2F   Tel：021-5187-7830
          </div>
          <div class="clinic-mark">
            作成日 {{todayDate}}
          </div>
        </div>
      </div>
      <div style="page-break-after: always;"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moneyMixins from '@/components/mixins/money';
import dateFormatMixins from '@/components/mixins/dateformatter';
import moment from 'moment';

export default {
  name: 'ClassificationInvoices',
  mixins: [dateFormatMixins, moneyMixins],
  data() {
    return {
      classificationInvoicesInfo: {},
      commonPatientInvoiceArray: [],
      classificationFeeTypes: {
        examinationFeeTypes: ['Consultation', 'Professional Fees Consult'],
        inspectionFeeTypes: ['Investigation', 'Laboratory', 'Radiology'],
        disposalFeeTypes: ['Procedure', 'Injection', 'Vaccination', 'Nursing Procedure', 'Professional Fees Procedures'],
        medicineFeeTypes: ['Medicine', 'Consumables', 'Expendables', 'Compounded', 'Product','Wyn'],
        otherFeeTypes: ['Teleconsult', 'Bundle', 'Referral', 'Misc', 'Credit', 'Virtual Currency', 'Facilities', 'Package', 'Health Screening'],
      },
      todayDate: moment().format('YYYY-MM-DD'),
    };
  },
  computed: {
    ...mapGetters('config', ['general']),
    yearNumber() {
      const { monthDate } = this.classificationInvoicesInfo;
      if (!monthDate) return '';
      return moment(monthDate).year();
    },
    monthNumber() {
      const { monthDate } = this.classificationInvoicesInfo;
      if (!monthDate) return '';
      return moment(monthDate).month() + 1;
    },
    totalPrice() {
      const vm = this;
      let totalFee = 0;
      if (vm.classificationInvoicesInfo.items) {
        totalFee = vm.classificationInvoicesInfo.items.reduce((total, item) => item.corporateInvoice + total, 0);
      }

      return totalFee;
    },
  },
  methods: {
    // merge the same patient and corporate in one invoice
    mergeCommonPatientAndCorporateInvoice() {
      const vm = this;
      const invoiceItems = vm.classificationInvoicesInfo.items;

      const commonPatientMapInvoiceObject = {};

      invoiceItems.forEach((item) => {
        // make same patinet and same corporate key
        const patientIdAndCorporateNameKey = `${item.patientId}${item.corporateName}`;
        if (patientIdAndCorporateNameKey in commonPatientMapInvoiceObject) {
          commonPatientMapInvoiceObject[patientIdAndCorporateNameKey].items.push(item);
        } else {
          commonPatientMapInvoiceObject[patientIdAndCorporateNameKey] = {
            patientOrderId: isNaN(Number(item.patientGivenId)) ? 0 : Number(item.patientGivenId),
            patientName: item.patientName,
            patientGivenId: item.patientGivenId,
            items: [item],
          };
        }
      });

      vm.commonPatientInvoiceArray = Object.values(commonPatientMapInvoiceObject);
    },

    // order all invoice by patient patientOrderId
    orderInvoiceByPatientOrderId() {
      const vm = this;
      const commonPatientInvoiceArray = [...vm.commonPatientInvoiceArray];

      commonPatientInvoiceArray.sort((before, after) => before.patientOrderId - after.patientOrderId);

      vm.commonPatientInvoiceArray = commonPatientInvoiceArray;
    },

    caculatePatientTotalPrice(items) {
      const vm = this;
      const commonPatientInvoiceArray = [...vm.commonPatientInvoiceArray];
      for (let i = 0; i < commonPatientInvoiceArray.length; i++) {
        commonPatientInvoiceArray[i].totalPrice = commonPatientInvoiceArray[i].items.reduce((total, item) => item.corporateInvoice + total, 0);
      }

      vm.commonPatientInvoiceArray = commonPatientInvoiceArray;
    },


    mergeCommonDatelist() {
      const vm = this;
      const { classificationInvoicesInfo } = vm;
      // singleLine or no service type, it need not merge same date
      if (classificationInvoicesInfo.type === 'singleLine' || classificationInvoicesInfo.serviceInfo.value === 'blankServiceId') return;

      const commonPatientInvoiceArray = [...vm.commonPatientInvoiceArray];
      for (let i = 0; i < commonPatientInvoiceArray.length; i++) {
        commonPatientInvoiceArray[i].items = vm.mergeCommonDateItems(commonPatientInvoiceArray[i].items);
      }

      vm.commonPatientInvoiceArray = commonPatientInvoiceArray;
    },


    mergeCommonDateItems(items) {
      const invoiceItems = [...items];
      const commonDateMapInvoiceArray = {};

      invoiceItems.forEach((item) => {
        if (item.invoiceFormatDate in commonDateMapInvoiceArray) {
          commonDateMapInvoiceArray[item.invoiceFormatDate].push(item);
        } else {
          commonDateMapInvoiceArray[item.invoiceFormatDate] = [item];
        }
      });

      const mergeDateInvoiceItems = [];

      for (const commonDate in commonDateMapInvoiceArray) {
        const itemObject = {
          corporateInvoice: 0,
          invoiceItems: [],
          invoiceFormatDate: commonDate,
        };
        commonDateMapInvoiceArray[commonDate].forEach((item) => {
          itemObject.corporateInvoice += item.corporateInvoice;
          itemObject.invoiceItems = itemObject.invoiceItems.concat(item.invoiceItems);
        });

        mergeDateInvoiceItems.push(itemObject);
      }


      return mergeDateInvoiceItems;
    },

    classificationInventoryFee() {
      const vm = this;
      const { classificationFeeTypes } = vm;
      const commonPatientInvoiceArray = [...vm.commonPatientInvoiceArray];

      for (let i = 0; i < commonPatientInvoiceArray.length; i++) {
        commonPatientInvoiceArray[i].items = commonPatientInvoiceArray[i].items.map((item) => {
          const invoiceItems = [...item.invoiceItems];
          item.examinationFee = 0;
          item.inspectionFee = 0;
          item.disposalFee = 0;
          item.medicineFee = 0;
          item.otherFee = 0;

          invoiceItems.forEach((invoice) => {
            if (classificationFeeTypes.examinationFeeTypes.indexOf(invoice.inventory.trim()) > -1) {
              item.examinationFee += invoice.afterTaxPrice;
            }
            if (classificationFeeTypes.inspectionFeeTypes.indexOf(invoice.inventory.trim()) > -1) {
              item.inspectionFee += invoice.afterTaxPrice;
            }
            if (classificationFeeTypes.disposalFeeTypes.indexOf(invoice.inventory.trim()) > -1) {
              item.disposalFee += invoice.afterTaxPrice;
            }
            if (classificationFeeTypes.medicineFeeTypes.indexOf(invoice.inventory.trim()) > -1) {
              item.medicineFee += invoice.afterTaxPrice;
            }
            if (classificationFeeTypes.otherFeeTypes.indexOf(invoice.inventory.trim()) > -1) {
              item.otherFee += invoice.afterTaxPrice;
            }
          });

          return item;
        });
      }

      vm.commonPatientInvoiceArray = commonPatientInvoiceArray;
    },
  },
  created() {
    const vm = this;
    const vuex = JSON.parse(localStorage.getItem('vuex'));
    const { print } = vuex.invoice;
    this.classificationInvoicesInfo = Object.assign({}, print);

    vm.mergeCommonPatientAndCorporateInvoice();
    vm.orderInvoiceByPatientOrderId();
    vm.caculatePatientTotalPrice();
    vm.mergeCommonDatelist();
    vm.classificationInventoryFee();
  },
  mounted() {
    if (!this.preview) {
      setTimeout(() => {
        window.print();
      }, 2000);
    }
  },
};
</script>
<style lang="scss" scoped>
.title {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 4px;
}

.invoice-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 20px auto;
  .info-left {
    width: 46%;
  }
  .info-right {
    width: 46%;
  }
  .item-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;

    .item-label {
      width: 120px;
      font-weight: bold;
    }

    .item-value {
      flex-grow: 1;
      height: 28px;
      border: 2px solid #333;
      box-sizing: border-box;
    }

    .item-value-no-border {
      flex-grow: 1;
      height: 28px;
      border: 2px solid white;
      box-sizing: border-box;
    }

    .item-value-wrap {
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .item-value-year,
      .item-value-month {
        flex-grow: 1;
        height: 28px;
        border: 2px solid #333;
        box-sizing: border-box;
        margin-right: 10px;
      }

      .item-value-month {
        margin-left: 10px;
      }

      .item-label-year,
      .item-label-month {
        font-weight: bold;
      }

      .item-value-day {
        flex-grow: 1;
        height: 28px;
        border: 2px solid #333;
        box-sizing: border-box;
        margin-right: 6px;
      }
      .item-label-day {
        font-weight: bold;
      }
      .item-mark-day {
        font-weight: bold;
        margin-left: 16px;
      }
    }

    .item-value-shrink {
      font-weight: bold;
      width: 100px;
      border: 2px solid #333;
    }
  }
}

.invoice-total-price {
  width: 100%;
  margin: 0px auto 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .tip {
    font-weight: bold;
  }

  .total-price-wrap {
    font-weight: bold;
    .total-price {
      margin: 0 16px;
    }
  }
}

.item-table-list {
  width: 100%;
  margin: 0px auto 10px;
  box-sizing: border-box;

  .table-header {
    border: 2px solid #333;
  }

  .table-item {
    border: 2px solid #333;
    border-top: 0;
  }

  .bdr-2 {
    border-right: 2px solid #333;
  }
}

.invoice-footer {
  width: 100%;
  margin: 0px auto 10px;
  box-sizing: border-box;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;

  .clinic-info-wrap {
    display: flex;
    justify-content: space-between;

    .clinic-logo {
      width: 60px;
    }

    .clinic-info {
      margin-left: 20px;

      .clinic-name {
        font-size: 26px;
        font-weight: bold;
      }

      .clinic-url {
        font-weight: bold;
        margin-top: 4px;
      }
    }
  }

  .clinic-contact-wrap {
    font-weight: bold;
    .clinic-mark {
      margin-top: 4px;
    }
  }
}


@media print{
  @page {
    size: A5 landscape;
    margin: 0;
  }
  .container-fluid {
    padding-top: 30px !important;
    font-size: 14px !important;
  }

  .table-item-value {
    font-size: 14px;
  }
}

</style>
