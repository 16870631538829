
import api from '@/services/api';

export default {
  create(payload, params) {
    const q = [];

    Object.keys(params).forEach((key) => {
      q.push(`${params[key]}`);
    });
    const url = `/inventorycredit/?${q.join('/')}`;

    return new Promise((resolve, reject) => {
      api.post(url, payload).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`Error: ${response.data.message}`),
              { data: null, success: false },
            );
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },
  getById(id) {
    // console.log('id------------', id);
    const url = `/inventorycredit/${id.patientId}`;
    // console.log('URL -------->', url);
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`Error: ${response.data.message}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },
  transferCredit(params) {
    const q = [];
    // console.log('parmas =========', params);
    // console.log('Payload ----', params.payload);

    const url = `/inventorycredit/transfer/${params.patientId}`;
    // console.log('URL ---', url);
    return new Promise((resolve, reject) => {
      api.post(url, params.payload).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`Error: ${response.data.message}`),
              { data: null, success: false },
            );
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },
  purcaseCredit(params) {
    // console.log('Parmas ---- -Credit ---- ', params.id);
    const url = `/inventorycredit/addnewcredit/${params.id}`;
    // console.log('URL ---', url);
    // console.log('Parmas ------------>>>>', params);

    const payload = { user: params.user };
    // console.log('payload ------------>>>>', payload);

    return new Promise((resolve, reject) => {
      api.post(url, payload).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`Error: ${response.data.message}`),
              { data: null, success: false },
            );
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },
  voidDebit(debitId, invoiceId) {
    const url = `/debit/${debitId}/${invoiceId}/void`;
    return new Promise((resolve, reject) => {
      api.put(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`Error: ${response.data.message}`),
              { data: null, success: false },
            );
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },
  showCredit(params) {
    // console.log('id------------', params);
    const url = `/getcredit/${params.patientId}`;
    // console.log('URL -------->', url);
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`Error: ${response.data.message}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },
  redeemCredit(params) {
    const url = `/inventorycredit/redeemCredit/${params.patientId}`;
    // console.log('URL ---', url);
    return new Promise((resolve, reject) => {
      api.post(url, params.payload).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`Error: ${response.data.message}`),
              { data: null, success: false },
            );
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },
  redeemCreditProvider(params) {
    const url = `/inventorycredit/redeemCreditProvider/${params.providerId}`;
    // console.log('URL ---', url);
    return new Promise((resolve, reject) => {
      api.post(url, params.payload).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`Error: ${response.data.message}`),
              { data: null, success: false },
            );
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },
};
