import inventoryService from '@/services/inventory.service.js';

import inductionOptionsDefaults from './defaults/inductionOptions.json'
import medicineOptionsDefaults from './defaults/medicineOptions.json'


import {
  SET_ALL_INDUCTION_OPTIONS,
} from './type.mutation';

const state = {
  inductionOptions: inductionOptionsDefaults,
  medicineOptions: medicineOptionsDefaults,
};

/* eslint-disable no-param-reassign */
const mutations = {
  [SET_ALL_INDUCTION_OPTIONS](_state, payload) {
    _state.inductionOptions = [...payload];
  },
};

const actions = {
  loadInductionOptions({ commit }, param = {}) {
    return inventoryService.fetchMedicines()
      .then((response) => {
        console.log('MEDS --->', response);
        commit(SET_ALL_INDUCTION_OPTIONS, response.data);
      });
  },
  resetAll({ commit }) {
    commit(SET_ALL_INDUCTION_OPTIONS, inductionOptionsDefaults);
  },
};

const getters = {
  inductionOptions: _state => _state.inductionOptions,
  medicineOptions: _state => _state.medicineOptions,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
