var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "b-invoice-container" }, [
    _c("div", { staticClass: "b-inv-content" }, [
      _c("div", { staticClass: "row align-items-baseline top-header" }, [
        _c("div", { staticClass: "col flex-grow-0 logo-container" }, [
          _c("img", {
            staticStyle: { height: "45px", width: "auto" },
            attrs: { src: _vm.logoURL, alt: _vm.clinicName }
          })
        ]),
        _c("div", { staticClass: "col" }, [
          _c("h4", { staticClass: "text-right" }, [
            _vm._v(_vm._s(_vm.docTypeString))
          ])
        ])
      ]),
      _c("div", { staticClass: "row inner mt-2" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "clinicContact float-left" }, [
            _c("h5", { staticClass: "clinicName fz-6" }, [
              _vm._v(_vm._s(_vm.clinicName))
            ]),
            _c("p", { staticClass: "clinicName fz-6 mb-2" }, [
              _vm._v(_vm._s(_vm.clinicCorpName))
            ]),
            _c("div", { staticClass: "clinicAddress d-flex fz-6 mb-2" }, [
              _c("div", { staticClass: "hint flex-shrink-0" }, [
                _vm._v("ที่อยู่:")
              ]),
              _c(
                "div",
                { staticClass: "clinicAddressLine ml-2 fz-6" },
                [
                  _vm.general.useLocationAddress
                    ? [
                        _c("div", { staticClass: "mb-1" }, [
                          _vm._v(_vm._s(_vm.clinicLocationAddress))
                        ])
                      ]
                    : [
                        _c("div", { staticClass: "thai mb-1" }, [
                          _vm._v(_vm._s(_vm.general.clinicLocalAddress))
                        ]),
                        _c("div", { staticClass: "en" }, [
                          _vm._v(_vm._s(_vm.general.address))
                        ])
                      ]
                ],
                2
              )
            ]),
            _c("div", { staticClass: "clinicTel fz-6" }, [
              _vm.general.telephone
                ? _c("span", { staticClass: "tel" }, [
                    _vm._v("Tel: " + _vm._s(_vm.general.telephone))
                  ])
                : _vm._e(),
              _vm.general.fax
                ? _c("span", { staticClass: "tel" }, [
                    _vm._v("Fax: " + _vm._s(_vm.general.fax))
                  ])
                : _vm._e(),
              _vm.general.gstRegNo
                ? _c("span", { staticClass: "tel" }, [
                    _vm._v("Tax. ID: " + _vm._s(_vm.general.gstRegNo))
                  ])
                : _vm._e()
            ])
          ]),
          _c("div", { staticClass: "docType float-right t-bold" }, [
            _vm._v(_vm._s(_vm.docCopyString))
          ])
        ])
      ]),
      _c("div", { staticClass: "row inner mt-2 t-bold" }, [
        _c("div", { staticClass: "col-6" }),
        _c("div", { staticClass: "col-6" }, [
          _c("div", { staticClass: "docNumber" }, [
            _vm._v(_vm._s(_vm.docNoByType)),
            _c("span", { staticClass: "ml-1" }, [
              _vm._v(_vm._s(_vm.print.invoiceNo))
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "row inner mt-2 t-bold" }, [
        _c("div", { staticClass: "col-6" }, [
          _c("div", { staticClass: "patientName" }, [
            _vm._v("ชื่อ/Name: "),
            _c("span", {}, [_vm._v(_vm._s(_vm.patientNameField) + " ")])
          ])
        ]),
        _c("div", { staticClass: "col-6" }, [
          _c("div", { staticClass: "hn" }, [
            _vm._v("เลขประจำตัวผู้ป่วย       CN : "),
            _c("span", { staticClass: "ml-2" }, [
              _vm._v(
                _vm._s(_vm.selectedPatient.givenIdPrefix) +
                  _vm._s(_vm.selectedPatient.givenId)
              )
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "row inner mt-2 t-bold" }, [
        _c("div", { staticClass: "col-6" }, [
          _c("div", { staticClass: "payer" }, [
            _vm._v("บริษัท / Corporate company :"),
            _c("span", {}, [_vm._v(_vm._s(_vm.corpName))])
          ])
        ]),
        _c("div", { staticClass: "col-6" }, [
          _c("div", { staticClass: "docDateTime" }, [
            _c("div", { staticClass: "date float-left" }, [
              _vm._v("วันที่/Date:"),
              _c("span", {}, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("formatVDDate")(_vm.print.createdAt, {
                        lang: _vm.$i18n.locale,
                        format: "DD/MM/YYYY"
                      })
                    )
                )
              ])
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "row mt-2" }, [
        _c("div", { staticClass: "col" }, [
          _c("table", { staticClass: "table invoice-table" }, [
            _vm._m(0),
            _c(
              "tbody",
              [
                _vm._l(_vm.tableItems, function(item, idx) {
                  return [
                    item.isTitle
                      ? _c(
                          "tr",
                          {
                            key: "invoice_item_" + idx,
                            staticClass: "titleRow"
                          },
                          [
                            _c(
                              "td",
                              {
                                staticClass: "categoryTitle fz-6",
                                attrs: { colspan: "3" }
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(item.description) +
                                    "\n                "
                                )
                              ]
                            ),
                            _c("td"),
                            _c("td"),
                            _c("td")
                          ]
                        )
                      : _c(
                          "tr",
                          {
                            key: "invoice_item_" + idx,
                            staticClass: "itemRow"
                          },
                          [
                            _c(
                              "td",
                              {
                                staticClass: "categorySubTitle",
                                attrs: { colspan: "3" }
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(item.description) +
                                    "\n                "
                                )
                              ]
                            ),
                            _c("td", { staticClass: "currency" }, [
                              _vm._v(_vm._s(item.amount))
                            ]),
                            _c("td", { staticClass: "currency" }, [
                              _vm._v(_vm._s(item.discount))
                            ]),
                            _c("td", { staticClass: "currency" }, [
                              _vm._v(_vm._s(item.netAmount))
                            ])
                          ]
                        )
                  ]
                }),
                _c("tr", { staticClass: "bg-grey subtotal" }, [
                  _c(
                    "td",
                    {
                      staticClass: "categoryTotal text-center fw-500",
                      attrs: { colspan: "3" }
                    },
                    [
                      _vm._v(
                        "\n                รวมทั้งสิ้น / Subtotal\n              "
                      )
                    ]
                  ),
                  _c("td", { staticClass: "currency" }, [
                    _vm._v(_vm._s(_vm._f("to2Decimal")(_vm.totalAmountNumber)))
                  ]),
                  _c("td", { staticClass: "currency" }, [
                    _vm._v(
                      _vm._s(_vm._f("to2Decimal")(_vm.totalDiscountNumber))
                    )
                  ]),
                  _c("td", { staticClass: "currency" }, [
                    _vm._v(
                      _vm._s(_vm._f("to2Decimal")(_vm.totalNetAmountNumber))
                    )
                  ])
                ]),
                _c("tr", { staticClass: "bg-grey" }, [
                  _c(
                    "td",
                    {
                      staticClass: "fw-500 br-none text-center",
                      attrs: { colspan: "3" }
                    },
                    [
                      _vm._v(
                        "\n                (ตัวอักษร) / (in letter)\n              "
                      )
                    ]
                  ),
                  _c(
                    "td",
                    {
                      staticClass: "text-right fw-500",
                      attrs: { colspan: "3" }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.totalNetAmountLetter) +
                          "\n              "
                      )
                    ]
                  )
                ])
              ],
              2
            )
          ])
        ])
      ]),
      _c(
        "div",
        { staticClass: "typeOfPayment inner" },
        [
          _c("p", [_vm._v("\n        ชำระโดย / Mode of payment :\n      ")]),
          _vm._l(_vm.payments, function(payment, idx) {
            return _c("div", { key: idx, staticClass: "row" }, [
              _c("div", { staticClass: "col-4 pl-3" }, [
                _vm._v("\n          " + _vm._s(payment.label) + "\n        ")
              ]),
              _c("div", { staticClass: "col-3" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm._f("to2Decimal")(payment.amount)) +
                    "\n        "
                )
              ]),
              _vm._m(1, true)
            ])
          })
        ],
        2
      )
    ]),
    _c("div", { staticClass: "b-inv-footer" }, [
      _c("div", { staticClass: "row inner sig-container mb-2" }, [
        _vm._m(2),
        _c("div", { staticClass: "col" }, [
          _c("div", { staticClass: "accountantSig" }, [
            _c("div", { staticClass: "sig fz-6" }, [
              _vm._v("(ผู้ออกเอกสาร/Cashier)")
            ]),
            _c("div", { staticClass: "cashierName" }, [
              _vm._v(_vm._s(_vm.user.name))
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "footer text-center inner py-2" }, [
        _vm.docType === "receipt"
          ? _c("p", [
              _vm._v(
                "\n        ใบเสร็จนี้จะสมบูรณ์ต่อเมื่อสามารถเรียกเก็บเงินตามเช็ค หรือ บัตรเครดิตการ์ดได้เป็นที่เรียบร้อยแล้ว"
              ),
              _c("br"),
              _vm._v(
                " This receipt will be valid when the cheque or credit card is duly cleared\n      "
              )
            ])
          : _vm._e(),
        _c("p", [
          _vm._v(
            "\n        เพื่อความปลอดภัยของผู้ป่วยคลินิกขอสวงนสิทธิในการไม่รับเปลี่ยนหรือคืนยาและเวชภัณฑ์\n      "
          )
        ]),
        _c("p", [
          _vm._v(
            "\n        For patient safety,the hospital reserves the right not to accept any exchange or return of medications and medical supplies.\n      "
          )
        ])
      ]),
      _vm._m(3)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", { staticClass: "bg-grey text-center" }, [
        _c("th", { attrs: { colspan: "3" } }, [
          _c("p", { staticClass: "intableTitle thai" }, [_vm._v("รายการ")]),
          _c("p", { staticClass: "intableTitle en" }, [_vm._v("Description")])
        ]),
        _c("th", [
          _c("p", { staticClass: "intableTitle thai" }, [_vm._v("จำนวนเงิน")]),
          _c("p", { staticClass: "intableTitle en" }, [_vm._v("Amount")])
        ]),
        _c("th", [
          _c("p", { staticClass: "intableTitle thai" }, [_vm._v("ส่วนลด")]),
          _c("p", { staticClass: "intableTitle en" }, [_vm._v("Discount")])
        ]),
        _c("th", [
          _c("p", { staticClass: "intableTitle thai" }, [
            _vm._v("จำนวนเงินสุทธิ")
          ]),
          _c("p", { staticClass: "intableTitle en" }, [_vm._v("Net Amount")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("span", [_vm._v("บาท / Baht")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("div", { staticClass: "patientSig" }, [
        _c("div", { staticClass: "sig fz-6" }, [_vm._v("ผู้รับบริการ/Patient")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "inner mb-2" }, [
      _c("p", { staticClass: "text-right mr-4" }, [_vm._v("FM-OPD-009-00")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }