import api from './api';

export default {
  translateErrorMsg(error) {
    if (error.code === 11000) {
      error = 'Duplicate Error: Please use different information and try again.';
    }
    return error;
  },
  fetchAll(params) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);

    Object.keys(params).forEach((key) => {
      q.push(`${key}=${params[key]}`);
    });

    const url = `/adjustment/?${q.join('&')}`;
    return api.get(url);
  },
  createNew(data) {
    return api.post('/adjustment', data);
  },
  updateAdjustment(id, data) {
    return api.put(`/adjustment/update/${id}`, data);
  },
  updateBulkAdjustment(data) {
    return api.put('/adjustment/updateBulk', data);
  },
  delete(id) {
    return api.delete(`/adjustment/${id}`);
  },
  deleteBulk(ids) {
    return api.post('/adjustment/deleteBulk', ids);
  },
  getAdjustment(id) {
    return api.get(`/adjustment/${id}`);
  },
  count(query) {
    const params = Object.keys(query)
      .map(k => `${k}=${query[k]}`)
      .join('&');
    return api.get(`/adjustment/count?${params}`);
  },
};
