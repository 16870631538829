var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group row no-gutters range-link" }, [
    _c(
      "div",
      { class: (_vm.item.lableClass && _vm.item.labelClass[0]) || "col-sm-6" },
      [
        _c("div", { staticClass: "form-group row no-gutters" }, [
          _c(
            "span",
            {
              staticClass: "col-sm-4 range-label",
              attrs: { for: _vm.qns + "_date_range_from_input" }
            },
            [_vm._v(_vm._s(_vm.item.label[0] || "From"))]
          ),
          _c(
            "div",
            { staticClass: "col-sm-8" },
            [
              _c("date-picker", {
                attrs: {
                  value: _vm.item.answer[0],
                  "value-type": "timestamp",
                  format:
                    (_vm.item.dateFormat && _vm.item.dateFormat[0]) ||
                    "DD/MM/YYYY",
                  id: _vm.qns + "_date_range_from_input",
                  type: "date",
                  disabled: _vm.readonly,
                  placeholder: _vm.item.placeholder && _vm.item.placeholder[0]
                },
                on: {
                  input: function($event) {
                    return _vm.updateAnswer($event, "from")
                  }
                }
              })
            ],
            1
          )
        ])
      ]
    ),
    _c(
      "div",
      { class: (_vm.item.lableClass && _vm.item.labelClass[1]) || "col-sm-6" },
      [
        _c("div", { staticClass: "form-group row no-gutters" }, [
          _c(
            "span",
            {
              staticClass: "col-sm-4 range-label",
              attrs: { for: _vm.qns + "_date_range_to_input" }
            },
            [_vm._v(_vm._s(_vm.item.label[1] || "To"))]
          ),
          _c(
            "div",
            { staticClass: "col-sm-8" },
            [
              _c("date-picker", {
                attrs: {
                  value: _vm.item.answer[1],
                  disabled: _vm.readonly,
                  "value-type": "timestamp",
                  format:
                    (_vm.item.dateFormat && _vm.item.dateFormat[1]) ||
                    "DD/MM/YYYY",
                  id: _vm.qns + "_date_range_to_input",
                  type: "date",
                  placeholder: _vm.item.placeholder && _vm.item.placeholder[1]
                },
                on: {
                  input: function($event) {
                    return _vm.updateAnswer($event, "to")
                  }
                }
              })
            ],
            1
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }