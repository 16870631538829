import {
  userHasAccessToFn,
  userHasAllAccessToFns,
  userHasSomeAccessToFns,
} from './helpers/acl';

// --
// only checks route path vs user access
// not recommended!
// --
const ACLRouteGuardViaURLPATH = (to, from, next) => {
  // eslint-disable-next-line no-console
  console.log('inside ACLRouteGuard');
  const aclAccessURLS = localStorage.getItem('acl__urls');
  let hasFunctionAccess = true;
  if (aclAccessURLS) {
    const accessURLList = JSON.parse(aclAccessURLS);
    hasFunctionAccess = accessURLList.includes(to.path);
  }

  if (!hasFunctionAccess) {
    next({ name: 'forbidden' });
  } else {
    next();
  }
};

// --
// will allow route access if user has all reqFnIDS
// --
const ACLAllReqFunctions = (reqFnIDS = []) => (to, from, next) => {
  if (userHasAllAccessToFns(reqFnIDS)) {
    next();
  } else {
    next({ name: 'forbidden' });
  }
};

// --
// will allow route access if user has at least one reqFnIDS
// --
const ACLSomeReqFunctions = (reqFnIDS = []) => (to, from, next) => {
  if (userHasSomeAccessToFns(reqFnIDS)) {
    next();
  } else {
    next({ name: 'forbidden' });
  }
};

// --
// will allow route access if user has reqFn
// --
const ACLReqFunction = reqFn => (to, from, next) => {
  if (userHasAccessToFn(reqFn)) {
    next();
  } else {
    next({ name: 'forbidden' });
  }
};

export {
  ACLRouteGuardViaURLPATH,
  ACLAllReqFunctions,
  ACLSomeReqFunctions,
  ACLReqFunction,
};
