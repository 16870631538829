var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-3 tph-print-invoice", staticStyle: { margin: "20px" } },
    [
      _c("div", { staticClass: "row mb-3 big-fat-divider pb-3" }, [
        _c("div", { staticClass: "col-4" }, [
          _c("img", {
            style: {
              marginLeft: _vm.logoMarginLeft + "px",
              width: _vm.logoWidth + "px"
            },
            attrs: { src: _vm.general.logoURL }
          })
        ]),
        _vm._m(0)
      ]),
      _vm._m(1),
      _c("div", { staticClass: "row mb-3" }, [
        _c("div", { staticClass: "col-8 text-left" }, [
          _c("div", { staticClass: "patient-label" }, [
            _vm._v(
              "\n               ឈ្មោះអ្នកជំងឺ / Patient Name:\n                "
            ),
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v(_vm._s(_vm.selectedPatient.name))
            ])
          ]),
          _c("div", { staticClass: "patient-label" }, [
            _vm._v(
              "\n              លេខសំគាល់អ្នកជំងឺ / Patient ID:\n               "
            ),
            _vm.selectedPatient.givenIdPrefix
              ? _c("span", { staticClass: "font-weight-bold" }, [
                  _vm._v(_vm._s(_vm.selectedPatient.givenIdPrefix))
                ])
              : _vm._e(),
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v(_vm._s(_vm.selectedPatient.givenId))
            ])
          ]),
          _c("div", { staticClass: "patient-label" }, [
            _vm._v(
              "\n                ទូរស័ព្ទលេខ /​ Telephone No.:\n                "
            ),
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v(_vm._s(_vm.selectedPatient.mobile))
            ])
          ])
        ]),
        _c("div", { staticClass: "col-4 text-right" }, [
          _c("div", { staticClass: "patient-label" }, [
            _vm._v("\n               លេខរៀងវិក្កយបត្រ/Invoice No: "),
            _c("span", { staticClass: "text-vd-danger" }, [
              _vm._v(" " + _vm._s(_vm.print.invoiceNo) + " ")
            ])
          ]),
          _c("div", { staticClass: "patient-label" }, [
            _vm._v("\n                កាលបរិច្ឆេទ/Date: "),
            _c("span", { staticClass: "text-vd-danger" }, [
              _vm._v(_vm._s(_vm._f("ddMonthYYYYFromX")(_vm.print.date)))
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "row mb-5" }, [
        _vm.isCategorized
          ? _c("div", { staticClass: "col" }, [
              _c(
                "table",
                { staticClass: "vd-print-table" },
                [
                  _c(
                    "tr",
                    {
                      staticClass:
                        "table-border-top-thick table-border-bottom-thick"
                    },
                    [
                      _vm._m(2),
                      _vm._m(3),
                      _vm._m(4),
                      _vm._m(5),
                      _vm.hasAtLeastOneItemWithDiscount
                        ? _c(
                            "th",
                            {
                              staticClass: "text-right",
                              attrs: { width: "100px" }
                            },
                            [
                              _c("span", [_vm._v("បញ្ចុះតំលៃ")]),
                              _c("br"),
                              _c("span", [_vm._v("Discount")])
                            ]
                          )
                        : _vm._e(),
                      _vm._m(6)
                    ]
                  ),
                  _vm._l(_vm.categorized, function(category, name) {
                    return _c(
                      "tbody",
                      { key: category.key },
                      [
                        _c("tr", [
                          _c(
                            "td",
                            {
                              staticClass: "font-weight-bold",
                              attrs: { colspan: "7" }
                            },
                            [_vm._v(_vm._s(name))]
                          )
                        ]),
                        _vm._l(category, function(item) {
                          return _c("tr", { key: item.uuid }, [
                            _c(
                              "td",
                              [
                                _vm._v(
                                  "\n                          " +
                                    _vm._s(item.name) +
                                    "\n                          "
                                ),
                                [
                                  "Medicine",
                                  "Consumables",
                                  "Expendables",
                                  "Product"
                                ].includes(item.inventory)
                                  ? [
                                      _c("br"),
                                      _c("span", [
                                        _vm._v(
                                          "\n                              - " +
                                            _vm._s(
                                              (item.selectedBatch &&
                                                item.selectedBatch.number) ||
                                                ""
                                            ) +
                                            "\n                            "
                                        )
                                      ])
                                    ]
                                  : _vm._e()
                              ],
                              2
                            ),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(item.description))
                            ]),
                            _c("td", { staticClass: "text-right" }, [
                              _vm._v(_vm._s(item.qty))
                            ]),
                            _c("td", { staticClass: "text-right" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm._f("thousandSeparator")(
                                      _vm._f("to2Decimal")(
                                        _vm.calculateUnitPrice(item),
                                        _vm.region.decimal
                                      ),
                                      _vm.region.thousandSeparator
                                    )
                                  )
                              )
                            ]),
                            _vm.hasAtLeastOneItemWithDiscount
                              ? _c("td", { staticClass: "text-right" }, [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("thousandSeparator")(
                                          _vm._f("to2Decimal")(
                                            item.discountAmount,
                                            _vm.region.decimal
                                          ),
                                          _vm.region.thousandSeparator
                                        )
                                      )
                                    )
                                  ])
                                ])
                              : _vm._e(),
                            _c("td", { staticClass: "text-right" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("thousandSeparator")(
                                    _vm._f("to2Decimal")(
                                      item.afterTaxPrice,
                                      _vm.region.decimal
                                    ),
                                    _vm.region.thousandSeparator
                                  )
                                )
                              )
                            ])
                          ])
                        })
                      ],
                      2
                    )
                  }),
                  _c("tr", [
                    _c("td", { attrs: { colspan: "2" } }, [_vm._v(" ")]),
                    _c(
                      "td",
                      {
                        staticClass: "text-right",
                        attrs: { colspan: _vm.footerColumnSpan }
                      },
                      [_vm._v("Subtotal")]
                    ),
                    _c("td", { staticClass: "text-right" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.region.currency) +
                          _vm._s(
                            _vm._f("thousandSeparator")(
                              _vm._f("to2Decimal")(
                                +_vm.print.subtotal + +_vm.print.totalTax,
                                _vm.region.decimal
                              ),
                              _vm.region.thousandSeparator
                            )
                          ) +
                          "\n                        "
                      )
                    ])
                  ]),
                  parseFloat(_vm.print.promotionDiscount) > 0 > 0
                    ? _c("tr", [
                        _c("td", { attrs: { colspan: "2" } }, [_vm._v(" ")]),
                        _c(
                          "td",
                          {
                            staticClass: "text-right",
                            attrs: { colspan: _vm.footerColumnSpan }
                          },
                          [_vm._v("Promo Discount")]
                        ),
                        _c("td", { staticClass: "text-right" }, [
                          _vm._v(
                            "\n                            -" +
                              _vm._s(_vm.region.currency) +
                              _vm._s(
                                _vm._f("thousandSeparator")(
                                  _vm._f("to2Decimal")(
                                    _vm.print.promotionDiscount,
                                    _vm.region.decimal
                                  ),
                                  _vm.region.thousandSeparator
                                )
                              ) +
                              "\n                        "
                          )
                        ])
                      ])
                    : _vm._e(),
                  parseFloat(_vm.print.totalDiscount) > 0
                    ? _c("tr", [
                        _c("td", { attrs: { colspan: "2" } }, [_vm._v(" ")]),
                        _c(
                          "td",
                          {
                            staticClass: "text-right",
                            attrs: { colspan: _vm.footerColumnSpan }
                          },
                          [_vm._v("Total Discount")]
                        ),
                        _c("td", { staticClass: "text-right" }, [
                          _vm._v(
                            "\n                            -" +
                              _vm._s(_vm.region.currency) +
                              _vm._s(
                                _vm._f("thousandSeparator")(
                                  _vm._f("to2Decimal")(
                                    _vm.print.totalDiscount,
                                    _vm.region.decimal
                                  ),
                                  _vm.region.thousandSeparator
                                )
                              ) +
                              "\n                        "
                          )
                        ])
                      ])
                    : _vm._e(),
                  _c("tr", [
                    _c("td", { attrs: { colspan: "2" } }, [_vm._v(" ")]),
                    _c(
                      "td",
                      {
                        staticClass:
                          "text-right col-border-top col-border-bottom",
                        attrs: { colspan: _vm.footerColumnSpan }
                      },
                      [_vm._v("Invoice Total")]
                    ),
                    _c(
                      "td",
                      {
                        staticClass:
                          "col-border-top col-border-bottom text-right"
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.region.currency) +
                            _vm._s(
                              _vm._f("thousandSeparator")(
                                _vm._f("to2Decimal")(
                                  _vm.print.totalBeforeRounding,
                                  _vm.region.decimal
                                ),
                                _vm.region.thousandSeparator
                              )
                            ) +
                            "\n                        "
                        )
                      ]
                    )
                  ]),
                  _vm.processFees
                    ? _c("tr", [
                        _c("td", { attrs: { colspan: "2" } }, [_vm._v(" ")]),
                        _c(
                          "td",
                          {
                            staticClass: "text-right",
                            attrs: { colspan: _vm.footerColumnSpan }
                          },
                          [_vm._v("Processing Fees")]
                        ),
                        _c("td", { staticClass: "text-right" }, [
                          _vm.processFees
                            ? _c("div", [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(_vm.region.currency) +
                                    _vm._s(
                                      _vm._f("thousandSeparator")(
                                        _vm._f("to2Decimal")(
                                          _vm.processFees,
                                          _vm.region.decimal
                                        ),
                                        _vm.region.thousandSeparator
                                      )
                                    ) +
                                    "\n                            "
                                )
                              ])
                            : _vm._e()
                        ])
                      ])
                    : _vm._e(),
                  _vm.roundOff !== "NONE" &&
                  parseFloat(_vm.print.roundingAdjustment) > 0
                    ? _c("tr", [
                        _c("td", { attrs: { colspan: "2" } }, [_vm._v(" ")]),
                        _c(
                          "td",
                          {
                            staticClass: "text-right",
                            attrs: { colspan: _vm.footerColumnSpan }
                          },
                          [_vm._v("Rounding Adjustment")]
                        ),
                        _c("td", { staticClass: "text-right" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.region.currency) +
                              _vm._s(
                                _vm._f("thousandSeparator")(
                                  _vm._f("to2Decimal")(
                                    _vm.print.roundingAdjustment,
                                    _vm.region.decimal
                                  ),
                                  _vm.region.thousandSeparator
                                )
                              ) +
                              "\n                        "
                          )
                        ])
                      ])
                    : _vm._e(),
                  _c("tr", [
                    _c("td", { attrs: { colspan: "2" } }, [_vm._v(" ")]),
                    _c(
                      "td",
                      {
                        staticClass:
                          "text-right font-weight-bold col-border-top-dotted",
                        attrs: { colspan: _vm.footerColumnSpan }
                      },
                      [_vm._v("Total Amount Payable")]
                    ),
                    _c(
                      "td",
                      { staticClass: "col-border-top-dotted text-right" },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.region.currency) +
                            _vm._s(
                              _vm._f("thousandSeparator")(
                                _vm._f("to2Decimal")(
                                  _vm.print.total + _vm.processFees,
                                  _vm.region.decimal
                                ),
                                _vm.region.thousandSeparator
                              )
                            ) +
                            "\n                        "
                        )
                      ]
                    )
                  ]),
                  _c("tr", [
                    _c("td", { attrs: { colspan: "2" } }, [_vm._v(" ")]),
                    _c(
                      "td",
                      {
                        staticClass: "text-right font-weight-bold",
                        attrs: { colspan: _vm.footerColumnSpan }
                      },
                      [
                        _vm._v(
                          "\n                            សរុបរួម (ជារៀល) អត្រា "
                        ),
                        _c("span", { staticClass: "text-vd-danger" }, [
                          _vm._v(_vm._s(_vm.print.exchangeRate))
                        ]),
                        _vm._v(" រៀល/ដុល្លារ\n                        ")
                      ]
                    ),
                    _c("td", { staticClass: "text-right" }, [
                      _vm._v(
                        "\n                          " +
                          _vm._s(
                            _vm._f("thousandSeparator")(
                              _vm._f("to2Decimal")(
                                _vm.payableInR,
                                _vm.region.decimal
                              ),
                              _vm.region.thousandSeparator
                            )
                          ) +
                          "R\n                        "
                      )
                    ])
                  ]),
                  _vm._l(_vm.patientPayments, function(payment) {
                    return _c("tr", { key: payment.paymentId }, [
                      _c("td", { attrs: { colspan: _vm.footerColumnSpan } }, [
                        _vm._v(" ")
                      ]),
                      _c(
                        "td",
                        { staticClass: "text-right", attrs: { colspan: "2" } },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(payment.label) +
                              " payment received -- Payment Ref: "
                          ),
                          _c("span", { staticClass: "text-vd-danger" }, [
                            _vm._v(_vm._s(payment.paymentRef))
                          ])
                        ]
                      ),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.region.currency) +
                            _vm._s(
                              _vm._f("thousandSeparator")(
                                _vm._f("to2Decimal")(
                                  payment.amount,
                                  _vm.region.decimal
                                ),
                                _vm.region.thousandSeparator
                              )
                            ) +
                            " "
                        )
                      ])
                    ])
                  }),
                  _c("tr", [
                    _c("td", { attrs: { colspan: "2" } }, [_vm._v(" ")]),
                    _c(
                      "td",
                      {
                        staticClass: "text-right font-weight-bold",
                        attrs: { colspan: _vm.footerColumnSpan }
                      },
                      [
                        _vm._v(
                          "\n                            Outstanding Balance\n                        "
                        )
                      ]
                    ),
                    _c("td", { staticClass: "text-right" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.region.currency) +
                          _vm._s(
                            _vm._f("thousandSeparator")(
                              _vm._f("to2Decimal")(
                                _vm.print.ostBalance,
                                _vm.region.decimal
                              ),
                              _vm.region.thousandSeparator
                            )
                          ) +
                          "\n                        "
                      )
                    ])
                  ])
                ],
                2
              )
            ])
          : _c("div", { staticClass: "col" }, [
              _c(
                "table",
                { staticClass: "vd-print-table" },
                [
                  _c(
                    "tr",
                    {
                      staticClass:
                        "table-border-top-thick table-border-bottom-thick"
                    },
                    [
                      _vm._m(7),
                      _vm._m(8),
                      _vm._m(9),
                      _vm._m(10),
                      _vm.hasAtLeastOneItemWithDiscount
                        ? _c(
                            "th",
                            {
                              staticClass: "text-right",
                              attrs: { width: "100px" }
                            },
                            [
                              _c("span", [_vm._v("បញ្ចុះតំលៃ")]),
                              _c("br"),
                              _c("span", [_vm._v("Discount")])
                            ]
                          )
                        : _vm._e(),
                      _vm._m(11)
                    ]
                  ),
                  _c(
                    "tbody",
                    _vm._l(_vm.print.items, function(item) {
                      return _c("tr", { key: item.uuid }, [
                        _c(
                          "td",
                          [
                            _vm._v(
                              "\n                          " +
                                _vm._s(item.name) +
                                "\n                          "
                            ),
                            [
                              "Medicine",
                              "Consumables",
                              "Expendables",
                              "Product"
                            ].includes(item.inventory)
                              ? [
                                  _c("br"),
                                  _c("span", [
                                    _vm._v(
                                      "\n                              - " +
                                        _vm._s(
                                          (item.selectedBatch &&
                                            item.selectedBatch.number) ||
                                            ""
                                        ) +
                                        "\n                            "
                                    )
                                  ])
                                ]
                              : _vm._e()
                          ],
                          2
                        ),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(item.description))
                        ]),
                        _c("td", { staticClass: "text-right" }, [
                          _vm._v(_vm._s(item.qty))
                        ]),
                        _c("td", { staticClass: "text-right" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm._f("thousandSeparator")(
                                  _vm._f("to2Decimal")(
                                    _vm.calculateUnitPrice(item),
                                    _vm.region.decimal
                                  ),
                                  _vm.region.thousandSeparator
                                )
                              )
                          )
                        ]),
                        _vm.hasAtLeastOneItemWithDiscount
                          ? _c("td", { staticClass: "text-right" }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("thousandSeparator")(
                                      _vm._f("to2Decimal")(
                                        item.discountAmount,
                                        _vm.region.decimal
                                      ),
                                      _vm.region.thousandSeparator
                                    )
                                  )
                                )
                              ])
                            ])
                          : _vm._e(),
                        _c("td", { staticClass: "text-right" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("thousandSeparator")(
                                _vm._f("to2Decimal")(
                                  item.afterTaxPrice,
                                  _vm.region.decimal
                                ),
                                _vm.region.thousandSeparator
                              )
                            )
                          )
                        ])
                      ])
                    }),
                    0
                  ),
                  _c("tr", [
                    _c("td", { attrs: { colspan: "2" } }, [_vm._v(" ")]),
                    _c(
                      "td",
                      {
                        staticClass: "text-right",
                        attrs: { colspan: _vm.footerColumnSpan }
                      },
                      [_vm._v("Subtotal")]
                    ),
                    _c("td", { staticClass: "text-right" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.region.currency) +
                          _vm._s(
                            _vm._f("thousandSeparator")(
                              _vm._f("to2Decimal")(
                                +_vm.print.subtotal + +_vm.print.totalTax,
                                _vm.region.decimal
                              ),
                              _vm.region.thousandSeparator
                            )
                          ) +
                          "\n                        "
                      )
                    ])
                  ]),
                  parseFloat(_vm.print.promotionDiscount) > 0 > 0
                    ? _c("tr", [
                        _c("td", { attrs: { colspan: "2" } }, [_vm._v(" ")]),
                        _c(
                          "td",
                          {
                            staticClass: "text-right",
                            attrs: { colspan: _vm.footerColumnSpan }
                          },
                          [_vm._v("Promo Discount")]
                        ),
                        _c("td", { staticClass: "text-right" }, [
                          _vm._v(
                            "\n                            -" +
                              _vm._s(_vm.region.currency) +
                              _vm._s(
                                _vm._f("thousandSeparator")(
                                  _vm._f("to2Decimal")(
                                    _vm.print.promotionDiscount,
                                    _vm.region.decimal
                                  ),
                                  _vm.region.thousandSeparator
                                )
                              ) +
                              "\n                        "
                          )
                        ])
                      ])
                    : _vm._e(),
                  parseFloat(_vm.print.totalDiscount) > 0
                    ? _c("tr", [
                        _c("td", { attrs: { colspan: "2" } }, [_vm._v(" ")]),
                        _c(
                          "td",
                          {
                            staticClass: "text-right",
                            attrs: { colspan: _vm.footerColumnSpan }
                          },
                          [_vm._v("Total Discount")]
                        ),
                        _c("td", { staticClass: "text-right" }, [
                          _vm._v(
                            "\n                            -" +
                              _vm._s(_vm.region.currency) +
                              _vm._s(
                                _vm._f("thousandSeparator")(
                                  _vm._f("to2Decimal")(
                                    _vm.print.totalDiscount,
                                    _vm.region.decimal
                                  ),
                                  _vm.region.thousandSeparator
                                )
                              ) +
                              "\n                        "
                          )
                        ])
                      ])
                    : _vm._e(),
                  _c("tr", [
                    _c("td", { attrs: { colspan: "2" } }, [_vm._v(" ")]),
                    _c(
                      "td",
                      {
                        staticClass:
                          "text-right col-border-top col-border-bottom",
                        attrs: { colspan: _vm.footerColumnSpan }
                      },
                      [_vm._v("Invoice Total")]
                    ),
                    _c(
                      "td",
                      {
                        staticClass:
                          "col-border-top col-border-bottom text-right"
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.region.currency) +
                            _vm._s(
                              _vm._f("thousandSeparator")(
                                _vm._f("to2Decimal")(
                                  _vm.print.totalBeforeRounding,
                                  _vm.region.decimal
                                ),
                                _vm.region.thousandSeparator
                              )
                            ) +
                            "\n                        "
                        )
                      ]
                    )
                  ]),
                  _vm.processFees
                    ? _c("tr", [
                        _c("td", { attrs: { colspan: "2" } }, [_vm._v(" ")]),
                        _c(
                          "td",
                          {
                            staticClass: "text-right",
                            attrs: { colspan: _vm.footerColumnSpan }
                          },
                          [_vm._v("Processing Fees")]
                        ),
                        _c("td", { staticClass: "text-right" }, [
                          _vm.processFees
                            ? _c("div", [
                                _vm._v(
                                  "\n                              " +
                                    _vm._s(_vm.region.currency) +
                                    _vm._s(
                                      _vm._f("thousandSeparator")(
                                        _vm._f("to2Decimal")(
                                          _vm.processFees,
                                          _vm.region.decimal
                                        ),
                                        _vm.region.thousandSeparator
                                      )
                                    ) +
                                    "\n                            "
                                )
                              ])
                            : _vm._e()
                        ])
                      ])
                    : _vm._e(),
                  _vm.roundOff !== "NONE" &&
                  parseFloat(_vm.print.roundingAdjustment) > 0
                    ? _c("tr", [
                        _c("td", { attrs: { colspan: "2" } }, [_vm._v(" ")]),
                        _c(
                          "td",
                          {
                            staticClass: "text-right",
                            attrs: { colspan: _vm.footerColumnSpan }
                          },
                          [_vm._v("Rounding Adjustment")]
                        ),
                        _c("td", { staticClass: "text-right" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.region.currency) +
                              _vm._s(
                                _vm._f("thousandSeparator")(
                                  _vm._f("to2Decimal")(
                                    _vm.print.roundingAdjustment,
                                    _vm.region.decimal
                                  ),
                                  _vm.region.thousandSeparator
                                )
                              ) +
                              "\n                        "
                          )
                        ])
                      ])
                    : _vm._e(),
                  _c("tr", [
                    _c("td", { attrs: { colspan: "2" } }, [_vm._v(" ")]),
                    _c(
                      "td",
                      {
                        staticClass:
                          "text-right font-weight-bold col-border-top-dotted",
                        attrs: { colspan: _vm.footerColumnSpan }
                      },
                      [_vm._v("Total Amount Payable")]
                    ),
                    _c(
                      "td",
                      { staticClass: "col-border-top-dotted text-right" },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.region.currency) +
                            _vm._s(
                              _vm._f("thousandSeparator")(
                                _vm._f("to2Decimal")(
                                  _vm.print.total + _vm.processFees,
                                  _vm.region.decimal
                                ),
                                _vm.region.thousandSeparator
                              )
                            ) +
                            "\n                        "
                        )
                      ]
                    )
                  ]),
                  _c("tr", [
                    _c("td", { attrs: { colspan: "2" } }, [_vm._v(" ")]),
                    _c(
                      "td",
                      {
                        staticClass: "text-right font-weight-bold",
                        attrs: { colspan: _vm.footerColumnSpan }
                      },
                      [
                        _vm._v(
                          "\n                            សរុបរួម (ជារៀល) អត្រា "
                        ),
                        _c("span", { staticClass: "text-vd-danger" }, [
                          _vm._v(_vm._s(_vm.print.exchangeRate))
                        ]),
                        _vm._v(" រៀល/ដុល្លារ\n                        ")
                      ]
                    ),
                    _c("td", { staticClass: "text-right" }, [
                      _vm._v(
                        "\n                          " +
                          _vm._s(
                            _vm._f("thousandSeparator")(
                              _vm._f("to2Decimal")(
                                _vm.payableInR,
                                _vm.region.decimal
                              ),
                              _vm.region.thousandSeparator
                            )
                          ) +
                          "R\n                        "
                      )
                    ])
                  ]),
                  _vm._l(_vm.patientPayments, function(payment) {
                    return _c("tr", { key: payment.paymentId }, [
                      _c("td", { attrs: { colspan: _vm.footerColumnSpan } }, [
                        _vm._v(" ")
                      ]),
                      _c(
                        "td",
                        { staticClass: "text-right", attrs: { colspan: "2" } },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(payment.label) +
                              " payment received -- Payment Ref: "
                          ),
                          _c("span", { staticClass: "text-vd-danger" }, [
                            _vm._v(_vm._s(payment.paymentRef))
                          ])
                        ]
                      ),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.region.currency) +
                            _vm._s(
                              _vm._f("thousandSeparator")(
                                _vm._f("to2Decimal")(
                                  payment.amount,
                                  _vm.region.decimal
                                ),
                                _vm.region.thousandSeparator
                              )
                            ) +
                            " "
                        )
                      ])
                    ])
                  }),
                  _c("tr", [
                    _c("td", { attrs: { colspan: "2" } }, [_vm._v(" ")]),
                    _c(
                      "td",
                      {
                        staticClass: "text-right font-weight-bold",
                        attrs: { colspan: _vm.footerColumnSpan }
                      },
                      [
                        _vm._v(
                          "\n                            Outstanding Balance\n                        "
                        )
                      ]
                    ),
                    _c("td", { staticClass: "text-right" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.region.currency) +
                          _vm._s(
                            _vm._f("thousandSeparator")(
                              _vm._f("to2Decimal")(
                                _vm.print.ostBalance,
                                _vm.region.decimal
                              ),
                              _vm.region.thousandSeparator
                            )
                          ) +
                          "\n                        "
                      )
                    ])
                  ])
                ],
                2
              )
            ])
      ]),
      _c("div", { staticClass: "row mb-5" }, [
        _c(
          "div",
          { staticClass: "col" },
          [
            _vm.withDiagnosis || _vm.printConfig.diagnosis
              ? [
                  _c("div", { staticClass: "row weight-600" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _vm._v(_vm._s(_vm.$t("general.diagnosis")) + ":")
                    ])
                  ]),
                  _vm._l(_vm.print.diagnosis, function(item, index) {
                    return _c("div", { key: index }, [
                      _vm.multipleICD10Enabled
                        ? _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-3" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    index === 0
                                      ? "Primary Diagnosis"
                                      : index === 1
                                      ? "Secondary Diagnosis"
                                      : "Additional Diagnosis"
                                  ) +
                                  "\n              "
                              )
                            ]),
                            _c(
                              "div",
                              { staticClass: "col-9" },
                              _vm._l(item, function(icd) {
                                return _c("p", { key: icd.uuid }, [
                                  _c("i", [_vm._v(_vm._s(icd && icd.text))])
                                ])
                              }),
                              0
                            )
                          ])
                        : _c(
                            "div",
                            _vm._l(item, function(icd) {
                              return _c("p", { key: icd.uuid }, [
                                _c("i", [_vm._v(_vm._s(icd && icd.text))])
                              ])
                            }),
                            0
                          ),
                      _vm.multipleICD10Enabled ? _c("hr") : _vm._e()
                    ])
                  })
                ]
              : _vm._e()
          ],
          2
        )
      ]),
      _c("div", { staticClass: "row mb-5" }, [
        _c(
          "div",
          { staticClass: "col" },
          [
            _vm.corpPayables.length
              ? [
                  _c("div", { staticClass: "row weight-600" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _vm._v(_vm._s(_vm.$t("general.amountDueFrom")) + ":")
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "row weight-600 bd-top bd-bottom" },
                    [
                      _c("div", { staticClass: "col-6" }, [
                        _vm._v(_vm._s(_vm.$t("general.others")))
                      ]),
                      _c("div", { staticClass: "col-6" }, [
                        _vm._v(_vm._s(_vm.$t("general.amount")))
                      ])
                    ]
                  ),
                  _vm._l(_vm.corpPayables, function(payable) {
                    return _c(
                      "div",
                      { key: payable.paymentId, staticClass: "row" },
                      [
                        _c("div", { staticClass: "col-6" }, [
                          _vm._v(_vm._s(payable.label))
                        ]),
                        _c("div", { staticClass: "col-6" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.region.currency) +
                              _vm._s(
                                _vm._f("thousandSeparator")(
                                  _vm._f("to2Decimal")(
                                    payable.amount,
                                    _vm.region.decimal
                                  ),
                                  _vm.region.thousandSeparator
                                )
                              ) +
                              "\n          "
                          )
                        ])
                      ]
                    )
                  })
                ]
              : _vm._e()
          ],
          2
        )
      ]),
      _vm._m(12)
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-8 text-right clinic-address" }, [
      _c("p", [
        _vm._v(
          "#16, 17 & 18, Street 21, Sangkat Tonle Bassac, Khan Chamkarmon, Phnom Penh, Cambodia"
        )
      ]),
      _c("p", [_vm._v("ទូរស័ព្ទលេខ/Telephone No. +855 23 266 999")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row mb-3" }, [
      _c("div", { staticClass: "col text-center" }, [
        _c("h5", [_vm._v("វិក្កយបត្រ")]),
        _c("h5", [_vm._v("INVOICE")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", { staticClass: "text-center" }, [
      _c("span", [_vm._v("ឈ្មោះមុខទំនិញឬសេវា")]),
      _c("br"),
      _c("span", [_vm._v("Item Name")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", { staticClass: "text-center" }, [
      _c("span", [_vm._v("ការបរិយាយ")]),
      _c("br"),
      _c("span", [_vm._v("Description")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", { staticClass: "text-right", attrs: { width: "100px" } }, [
      _c("span", [_vm._v("បរិមាណ")]),
      _c("br"),
      _c("span", [_vm._v("Quantity")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", { staticClass: "text-right", attrs: { width: "200px" } }, [
      _c("span", [_vm._v("ថ្លៃឯកតា")]),
      _c("br"),
      _c("span", [_vm._v("Unit Price")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", { staticClass: "text-right", attrs: { width: "250px" } }, [
      _c("span", [_vm._v("ថ្លៃទំនិញ")]),
      _c("br"),
      _c("span", [_vm._v("Amount")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", { staticClass: "text-center" }, [
      _c("span", [_vm._v("ឈ្មោះមុខទំនិញឬសេវា")]),
      _c("br"),
      _c("span", [_vm._v("Item Name")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", { staticClass: "text-center" }, [
      _c("span", [_vm._v("ការបរិយាយ")]),
      _c("br"),
      _c("span", [_vm._v("Description")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", { staticClass: "text-right", attrs: { width: "100px" } }, [
      _c("span", [_vm._v("បរិមាណ")]),
      _c("br"),
      _c("span", [_vm._v("Quantity")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", { staticClass: "text-right", attrs: { width: "200px" } }, [
      _c("span", [_vm._v("ថ្លៃឯកតា")]),
      _c("br"),
      _c("span", [_vm._v("Unit Price")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", { staticClass: "text-right", attrs: { width: "250px" } }, [
      _c("span", [_vm._v("ថ្លៃទំនិញ")]),
      _c("br"),
      _c("span", [_vm._v("Amount")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row  signature-row" }, [
      _c("div", { staticClass: "col-3" }, [
        _c("div", { staticClass: "signature-pad" }, [_vm._v(" ")]),
        _c("div", { staticClass: "text-center" }, [_vm._v("អ្នកជំងឺ")]),
        _c("div", { staticClass: "text-center" }, [_vm._v("(Patient's)")])
      ]),
      _c("div", { staticClass: "col-3" }),
      _c("div", { staticClass: "col-3" }),
      _c("div", { staticClass: "col-3" }, [
        _c("div", { staticClass: "signature-pad" }, [_vm._v(" ")]),
        _c("div", { staticClass: "text-center" }, [_vm._v("អ្នកគិតលុយ")]),
        _c("div", { staticClass: "text-center" }, [_vm._v("(Cashier's)")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }