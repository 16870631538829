var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "invoice-note-container",
      class: _vm.isExtracted && "grey-bottom-border2",
      attrs: { id: "invoice-note-container" }
    },
    [
      !_vm.isExtracted && _vm.invoice
        ? _c(
            "div",
            {
              staticClass: "note-label bold space-between grey-bottom-border",
              class: _vm.publishedCSSClass,
              on: { click: _vm.toggleNote }
            },
            [
              _vm.invoice
                ? _c("span", { staticClass: "note-label-name" }, [
                    _vm.invoice.queue.teleConsultSession
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("ddMonthYYYY")(
                                _vm.invoice.queue.queue_time
                              )
                            )
                          )
                        ])
                      : _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatVDDate")(_vm.invoice.createdAt, {
                                format: "DD MMM YYYY"
                              })
                            )
                          )
                        ]),
                    _vm.invoice.invoiceNo && _vm.invoice.invoiceNo !== "draft"
                      ? _c("span", [
                          _vm._v("#" + _vm._s(_vm.invoice.invoiceNo))
                        ])
                      : _vm._e(),
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("general.visitNotes")) +
                        "\n                "
                    ),
                    _vm.configConsultNotePublishMandatory
                      ? _c("span", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.mainForm && _vm.mainForm.draft
                                  ? "(" + _vm.$t("general.draft") + ")"
                                  : "(" + _vm.$t("general.published") + ")"
                              ) +
                              "\n                "
                          )
                        ])
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm.selectedQData
                ? _c(
                    "span",
                    [
                      _vm.isTeleConsultQueue && _vm.featureFlags.teleconsult
                        ? _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "acl",
                                  rawName: "v-acl",
                                  value: "patient_consultation_start",
                                  expression: "'patient_consultation_start'"
                                }
                              ],
                              staticClass: "ml-1",
                              attrs: {
                                variant: "outline-info",
                                size: "sm",
                                "data-automation":
                                  "consult-note-start-tele-consult",
                                disabled:
                                  (_vm.selectedQData.teleConsultSession &&
                                    _vm.selectedQData.teleConsultSession
                                      .endConsultTime) ||
                                  _vm.invoice.clinic !== _vm.selectedClinic._id
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.startTeleConsult($event)
                                }
                              }
                            },
                            [_c("v-icon", { attrs: { name: "phone" } })],
                            1
                          )
                        : _vm._e(),
                      _vm.isTeleConsultQueue && _vm.featureFlags.teleconsult
                        ? _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "acl",
                                  rawName: "v-acl",
                                  value: "patient_consultation_start",
                                  expression: "'patient_consultation_start'"
                                }
                              ],
                              staticClass: "ml-1",
                              attrs: {
                                variant: "outline-info",
                                size: "sm",
                                "data-automation":
                                  "consult-note-end-tele-consult",
                                disabled:
                                  _vm.invoice.clinic !==
                                    _vm.selectedClinic._id ||
                                  (_vm.selectedQData.teleConsultSession &&
                                    (_vm.selectedQData.teleConsultSession
                                      .endConsultTime ||
                                      !_vm.selectedQData.teleConsultSession
                                        .startConsultTime))
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.endTeleConsult($event)
                                }
                              }
                            },
                            [
                              _c("phone-down", { attrs: { size: "18" } }),
                              _vm._v(
                                "  \n                    " +
                                  _vm._s(_vm.$t("general.endTeleConsult")) +
                                  "\n                "
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "acl",
                              rawName: "v-acl",
                              value: "patient_consultation_start",
                              expression: "'patient_consultation_start'"
                            }
                          ],
                          staticClass: "ml-1",
                          attrs: {
                            variant: "outline-info",
                            size: "sm",
                            "data-automation": "consult-note-start-consult",
                            disabled:
                              _vm.qStartedConsult ||
                              _vm.invoice.clinic !== _vm.selectedClinic._id
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.updateQueue("start_consult")
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$t("general.startConsult")) +
                              "\n                "
                          )
                        ]
                      ),
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "acl",
                              rawName: "v-acl",
                              value: "patient_consultation_end",
                              expression: "'patient_consultation_end'"
                            }
                          ],
                          staticClass: "ml-1",
                          attrs: {
                            variant: "outline-info",
                            size: "sm",
                            "data-automation": "consult-note-end-consult",
                            disabled:
                              _vm.invoice.clinic !== _vm.selectedClinic._id ||
                              !_vm.qStartedConsult || _vm.qEndedConsult
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.updateQueue("end_consult")
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$t("general.endConsult")) +
                              "\n                "
                          )
                        ]
                      ),
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "acl",
                              rawName: "v-acl",
                              value: "patient_consultation_treatment_start",
                              expression:
                                "'patient_consultation_treatment_start'"
                            }
                          ],
                          staticClass: "ml-1",
                          attrs: {
                            variant: "outline-info",
                            size: "sm",
                            "data-automation": "consult-note-start-treatment",
                            disabled:
                              _vm.qStartedTreatment ||
                              _vm.invoice.clinic !== _vm.selectedClinic._id
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.updateQueue("start_treatment")
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$t("queuestatus.startTreatment")) +
                              "\n                "
                          )
                        ]
                      ),
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "acl",
                              rawName: "v-acl",
                              value: "patient_consultation_treatment_end",
                              expression: "'patient_consultation_treatment_end'"
                            }
                          ],
                          staticClass: "ml-1",
                          attrs: {
                            variant: "outline-info",
                            size: "sm",
                            "data-automation": "consult-note-end-treatment",
                            disabled:
                              _vm.invoice.clinic !== _vm.selectedClinic._id ||
                              !_vm.qStartedTreatment || _vm.qEndedTreatment
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.updateQueue("end_treatment")
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$t("queuestatus.endTreatment")) +
                              "\n                "
                          )
                        ]
                      ),
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "acl",
                              rawName: "v-acl",
                              value: "patient_consultation_photo_take",
                              expression: "'patient_consultation_photo_take'"
                            }
                          ],
                          staticClass: "ml-1",
                          attrs: {
                            variant: "outline-dark",
                            size: "sm",
                            "data-automation": "consult-note-photo-taken",
                            disabled:
                              _vm.qPhotoTaken ||
                              _vm.invoice.clinic !== _vm.selectedClinic._id
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.updateQueue("photo_taken")
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$t("queuestatus.photoTaken")) +
                              "\n                "
                          )
                        ]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "ml-1",
                          attrs: {
                            variant: "outline-dark",
                            size: "sm",
                            "data-automation": "consult-note-print-consultNote",
                            disabled: _vm.mainForm.draft
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.printConsultNote({
                                name: "patientConsultNote"
                              })
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$t("general.printConsultNote")) +
                              "\n                "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _c("span", [_vm._v("No queue for this visit")]),
              _c("span", { staticClass: "note-label-status" }, [
                _vm.mainForm && !_vm.mainForm.draft
                  ? _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("note.publishedOn")) +
                          " " +
                          _vm._s(
                            _vm._f("formatDateTimeEng")(
                              _vm.mainForm.publishedAt
                            )
                          )
                      )
                    ])
                  : _c(
                      "span",
                      {
                        staticClass: "cursor-pointer",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.extractNote(_vm.invoice)
                          }
                        }
                      },
                      [
                        _c("v-icon", {
                          attrs: {
                            name: "external-link-alt",
                            flip: "vertical",
                            scale: "1.3"
                          }
                        })
                      ],
                      1
                    )
              ])
            ]
          )
        : _vm._e(),
      !_vm.isExtracted
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.expanded,
                  expression: "expanded"
                }
              ],
              staticClass: "note-details grey-bottom-border"
            },
            [
              _c(
                "div",
                { staticClass: "note-details-creator" },
                [
                  _c("v-icon", { attrs: { name: "user" } }),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("general.createdBy")) +
                        ": " +
                        _vm._s(_vm.mainForm ? _vm.mainForm.creator : "unknown")
                    )
                  ])
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "note-details-last-updated" },
                [
                  _c("v-icon", { attrs: { name: "clock" } }),
                  _vm.mainForm
                    ? _c("span", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$t("general.lastUpdatedAt")) +
                            ": " +
                            _vm._s(
                              _vm._f("formatVDDate")(_vm.mainForm.updatedAt, {
                                format: "YYYY/MM/DD HH:mm A"
                              })
                            ) +
                            "\n                    "
                        ),
                        _vm.mainForm.lastEditedBy
                          ? _c("span", [
                              _vm._v(" by " + _vm._s(_vm.mainForm.lastEditedBy))
                            ])
                          : _vm._e()
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm.invoice
                ? _c(
                    "div",
                    { staticClass: "note-details-location" },
                    [
                      _c("v-icon", { attrs: { name: "map-marker-alt" } }),
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("general.visitLocation")) +
                            ": " +
                            _vm._s(_vm.getClinicName)
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.expanded,
              expression: "expanded"
            }
          ],
          staticClass: "note-body",
          class: _vm.isExtracted && "is-extracted"
        },
        [
          _c("div", { staticClass: "space-between" }, [
            _vm.invoice
              ? _c(
                  "span",
                  {
                    attrs: { "data-automation": "consult-note" },
                    on: {
                      click: function($event) {
                        _vm.shouldHideConsultNote = !_vm.shouldHideConsultNote
                      }
                    }
                  },
                  [
                    _c("span", [
                      _c("span", { staticClass: "mr-2" }, [
                        _c("span", { staticClass: "bold mr-2" }, [
                          _vm._v(_vm._s(_vm.$t("general.consultNoteTemplate")))
                        ]),
                        _vm.shouldHideConsultNote
                          ? _c(
                              "span",
                              [_c("v-icon", { attrs: { name: "caret-up" } })],
                              1
                            )
                          : _c(
                              "span",
                              [_c("v-icon", { attrs: { name: "caret-down" } })],
                              1
                            )
                      ]),
                      _vm.featureFlags.inpatient
                        ? _c(
                            "span",
                            { staticClass: "mr-2" },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    size: "sm mx-1",
                                    variant: "outline-success",
                                    "data-automation": "consult-note-tag",
                                    disabled:
                                      _vm.invoice.clinic !==
                                      _vm.selectedClinic._id
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return (function() {
                                        return _vm.toggleTagSelectedCaseNotes(
                                          true
                                        )
                                      })($event)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("general.tag")))]
                              ),
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    size: "sm mx-1",
                                    variant: "outline-danger",
                                    disabled:
                                      _vm.invoice.clinic !==
                                      _vm.selectedClinic._id,
                                    "data-automation": "consult-note-untag"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return (function() {
                                        return _vm.toggleTagSelectedCaseNotes(
                                          false
                                        )
                                      })($event)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("general.untag")))]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]),
                    _c("div", [
                      _vm.mainForm.taggedTab || _vm.mainForm.otId
                        ? _c("small", { staticClass: "text-muted" }, [
                            _vm._v(
                              "\n                    (Tagged\n                    "
                            ),
                            _vm.mainForm.otId
                              ? _c("span", [
                                  _vm._v(
                                    " to " +
                                      _vm._s(
                                        _vm.otMappedIdToName[_vm.mainForm.otId]
                                      )
                                  )
                                ])
                              : _vm._e(),
                            _vm.mainForm.taggedTab
                              ? _c("span", [
                                  _vm._v(
                                    " under " +
                                      _vm._s(
                                        _vm._f("translateTabName")(
                                          _vm.mainForm.taggedTab
                                        )
                                      )
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(")\n                  ")
                          ])
                        : _vm._e()
                    ])
                  ]
                )
              : _vm._e(),
            _vm.invoice && _vm.isEnableConsultNote
              ? _c("span", [
                  !_vm.lockConsultNote &&
                  _vm.mainForm &&
                  _vm.mainForm.draft &&
                  _vm.invoice.clinic === _vm.selectedClinic._id
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-outline-info btn-sm mr-2",
                          on: {
                            click: function($event) {
                              return _vm.noteSaveDraft(false)
                            }
                          }
                        },
                        [_vm._v("\n                  Save\n                ")]
                      )
                    : _vm._e(),
                  _c("span", { on: { click: _vm.toggleConsultNoteLock } }, [
                    _vm.lockConsultNote
                      ? _c(
                          "span",
                          [_c("v-icon", { attrs: { name: "lock" } })],
                          1
                        )
                      : _c(
                          "span",
                          [_c("v-icon", { attrs: { name: "lock-open" } })],
                          1
                        )
                  ])
                ])
              : _vm._e()
          ]),
          _c(
            "b-collapse",
            {
              staticClass: "mt-2",
              attrs: { id: "collapse-4" },
              model: {
                value: _vm.shouldHideConsultNote,
                callback: function($$v) {
                  _vm.shouldHideConsultNote = $$v
                },
                expression: "shouldHideConsultNote"
              }
            },
            [
              _vm.invoice && _vm.mainForm && _vm.mainForm.draft
                ? _c(
                    "div",
                    [
                      _c("b-form-select", {
                        staticClass: "mt-3",
                        attrs: {
                          "data-automation": "consult-note-soap-select",
                          value: _vm.mainForm.templateId,
                          options: _vm.consultNotetemplatesSelectOptions,
                          size: "sm",
                          disabled:
                            _vm.lock ||
                            _vm.hasPassedNoteLockTimes ||
                            _vm.lockConsultNote ||
                            _vm.invoice.clinic !== _vm.selectedClinic._id
                        },
                        on: {
                          change: function($event) {
                            return _vm.updateFormTemplate($event)
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c("br"),
              _vm.invoice && _vm.mainForm && _vm.mainForm.formDataOrder
                ? _c(
                    "b-form",
                    [
                      _vm._l(_vm.mainForm.formDataOrder, function(qns, i) {
                        return _c(
                          "b-form-group",
                          {
                            key: i,
                            attrs: { label: _vm.mainForm.formData[qns].label }
                          },
                          [
                            _c("b-form-textarea", {
                              attrs: {
                                "data-automation": _vm.kebabCase(
                                  "consult-notes-" +
                                    _vm.mainForm.formData[qns].label
                                ),
                                disabled:
                                  !_vm.mainForm.draft ||
                                  _vm.lock ||
                                  _vm.hasPassedNoteLockTimes ||
                                  _vm.lockConsultNote ||
                                  _vm.invoice.clinic !== _vm.selectedClinic._id,
                                value: _vm.mainForm.formData[qns].answer,
                                rows: "2",
                                "max-rows": "100",
                                required: "",
                                "no-resize": ""
                              },
                              on: {
                                input: function($event) {
                                  return _vm.updateMainFormData($event, qns)
                                }
                              }
                            })
                          ],
                          1
                        )
                      }),
                      _vm.mainForm && !_vm.mainForm.draft
                        ? [
                            _vm.mainForm.addendums.length
                              ? _c("div", { staticClass: "mb-2" }, [
                                  _c("label", [
                                    _vm._v(_vm._s(_vm.$t("note.addendumAdded")))
                                  ]),
                                  _c(
                                    "ul",
                                    { staticClass: "list-group" },
                                    _vm._l(_vm.mainForm.addendums, function(
                                      addendum,
                                      index
                                    ) {
                                      return _c(
                                        "li",
                                        {
                                          key: index,
                                          staticClass: "list-group-item border"
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticStyle: {
                                                "white-space": "pre-line"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(addendum.text) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-items-center"
                                            },
                                            [
                                              _c("v-icon", {
                                                staticClass: "text-muted mr-2",
                                                attrs: { name: "user" }
                                              }),
                                              _c(
                                                "div",
                                                { staticClass: "mr-3" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        addendum.username
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _c("v-icon", {
                                                staticClass: "text-muted mr-2",
                                                attrs: { name: "calendar-alt" }
                                              }),
                                              _c("div", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f(
                                                        "formatDateTime_DDdMMMYYYYFromX"
                                                      )(addendum.date)
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                ])
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "form-group d-flex flex-column" },
                              [
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.addendumNote,
                                      expression: "addendumNote"
                                    }
                                  ],
                                  staticClass: "form-control mb-2",
                                  attrs: {
                                    rows: "2",
                                    placeholder: _vm.$t("note.enterAddendum"),
                                    disabled:
                                      _vm.lockConsultNote ||
                                      _vm.invoice.clinic !==
                                        _vm.selectedClinic._id
                                  },
                                  domProps: { value: _vm.addendumNote },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.addendumNote = $event.target.value
                                    }
                                  }
                                }),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-primary align-self-end",
                                    attrs: {
                                      type: "button",
                                      "data-automation":
                                        "consult-note-save-addendum",
                                      disabled:
                                        !_vm.addendumNote ||
                                        (_vm.addendumNote &&
                                          !_vm.addendumNote.trim()) ||
                                        _vm.lockConsultNote ||
                                        _vm.invoice.clinic !==
                                          _vm.selectedClinic._id
                                    },
                                    on: { click: _vm.saveAddendum }
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(_vm.$t("note.saveAddendum")) +
                                        "\n                            "
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                : _vm._e()
            ],
            1
          ),
          _c("hr", { staticStyle: { margin: "30px 0" } }),
          _c("div", [
            _vm.invoice
              ? _c("div", { staticClass: "title bold space-between" }, [
                  _c("span", [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.$t("general.dispense")) +
                        "\n                  "
                    )
                  ]),
                  _vm.invoice && _vm.isEnableConsultNote
                    ? _c("span", [
                        !_vm.lockInvoice &&
                        _vm.mainForm &&
                        _vm.mainForm.draft &&
                        _vm.invoice &&
                        !_vm.invoice.finalized &&
                        _vm.invoice.clinic === _vm.selectedClinic._id
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-outline-info btn-sm mr-2 mb-1",
                                on: { click: _vm.invoiceSaveDraft }
                              },
                              [
                                _vm._v(
                                  "\n                      Save\n                    "
                                )
                              ]
                            )
                          : _vm._e(),
                        _c("span", { on: { click: _vm.toggleInvoiceLock } }, [
                          _vm.lockInvoice
                            ? _c(
                                "span",
                                [_c("v-icon", { attrs: { name: "lock" } })],
                                1
                              )
                            : _c(
                                "span",
                                [
                                  _c("v-icon", { attrs: { name: "lock-open" } })
                                ],
                                1
                              )
                        ])
                      ])
                    : _vm._e()
                ])
              : _vm._e(),
            _vm.invoice &&
            !_vm.invoice.finalized &&
            _vm.mainForm &&
            _vm.mainForm.draft &&
            _vm.invoice.clinic === _vm.selectedClinic._id
              ? _c("div", { staticClass: "my-3 flex-fill" }, [
                  !_vm.lock && !_vm.lockInvoice
                    ? _c(
                        "div",
                        [
                          !_vm.lock
                            ? _c("vue-bootstrap-typeahead", {
                                staticClass: "wrapper",
                                attrs: {
                                  data: _vm.searchInventoryResults,
                                  disabled: _vm.lock,
                                  inputClass: "form-control",
                                  maxMatches: _vm.maxSearchResults,
                                  serializer: function(s) {
                                    return (
                                      s.name +
                                      " " +
                                      s.category +
                                      " (" +
                                      s.givenId +
                                      "), Type: " +
                                      s.inventory +
                                      " " +
                                      s.brandName
                                    )
                                  },
                                  placeholder: _vm.$t(
                                    "general.searchInventoryItems"
                                  ),
                                  "data-automation": "search-inventory-items",
                                  clearOnHit: true,
                                  textVariant: "light",
                                  backgroundVariant: "primary"
                                },
                                on: {
                                  hit: function($event) {
                                    return _vm.selectedItem($event)
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "suggestion",
                                      fn: function(ref) {
                                        var data = ref.data
                                        var htmlText = ref.htmlText
                                        return [
                                          _c(
                                            "b-container",
                                            { staticClass: "bv-example-row" },
                                            [
                                              _c(
                                                "b-row",
                                                [
                                                  _c("b-col", [
                                                    _vm._v(
                                                      _vm._s(data.inventory)
                                                    )
                                                  ]),
                                                  _c("b-col", [
                                                    _vm._v(
                                                      _vm._s(data.category)
                                                    )
                                                  ]),
                                                  _c("b-col", [
                                                    _vm._v(_vm._s(data.givenId))
                                                  ]),
                                                  _c("b-col", [
                                                    _vm._v(_vm._s(data.name))
                                                  ]),
                                                  _c("b-col", [
                                                    _vm._v(
                                                      _vm._s(data.brandName)
                                                    )
                                                  ]),
                                                  _c("b-col", [
                                                    _vm._v(
                                                      _vm._s(data.strength)
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  3675041220
                                ),
                                model: {
                                  value: _vm.itemSearch,
                                  callback: function($$v) {
                                    _vm.itemSearch = $$v
                                  },
                                  expression: "itemSearch"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : _c("div", [
                        _c("input", {
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t("general.searchInventoryItems"),
                            disabled: ""
                          }
                        })
                      ])
                ])
              : _vm._e(),
            _vm.invoice
              ? _c(
                  "div",
                  [
                    _vm.invoiceItemsMapped.length > 0
                      ? _c("b-table", {
                          ref: "invoicetable",
                          attrs: {
                            striped: "",
                            hover: "",
                            fields: _vm.fields,
                            items: _vm.invoiceItemsMapped
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "name",
                                fn: function(row) {
                                  return [
                                    _c("div", [_vm._v(_vm._s(row.value))]),
                                    [
                                      "Medicine",
                                      "Consumables",
                                      "Expendables",
                                      "Product",
                                      "Wyn"
                                    ].includes(row.item.inventory)
                                      ? [
                                          _c(
                                            "b-badge",
                                            { staticClass: "batchnumber" },
                                            [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(
                                                    row.item.selectedBatch
                                                  ) +
                                                  "\n                              "
                                              )
                                            ]
                                          )
                                        ]
                                      : _vm._e(),
                                    row.item.testStatusId
                                      ? _c("small", [
                                          _vm._v(
                                            "\n                                (Status: " +
                                              _vm._s(
                                                _vm
                                                  .integratedTestStatusesMapped[
                                                  row.item.testStatusId
                                                ].name
                                              ) +
                                              ") " +
                                              _vm._s(
                                                _vm
                                                  .integratedTestStatusesMapped[
                                                  row.item.testStatusId
                                                ].code
                                              ) +
                                              "\n                            "
                                          )
                                        ])
                                      : _vm._e()
                                  ]
                                }
                              },
                              {
                                key: "description",
                                fn: function(row) {
                                  return [
                                    !_vm.invoice.finalized &&
                                    _vm.mainForm &&
                                    _vm.mainForm.draft
                                      ? _c(
                                          "span",
                                          [
                                            _c("b-input", {
                                              staticClass:
                                                "mb-2 mr-sm-2 mb-sm-0",
                                              attrs: {
                                                size: "sm",
                                                value: row.value
                                              },
                                              on: {
                                                input: function($event) {
                                                  return _vm.updateLineItem(
                                                    $event,
                                                    row.item.uuid,
                                                    "description",
                                                    _vm.invoiceConfig
                                                  )
                                                }
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _c("span", [_vm._v(_vm._s(row.value))])
                                  ]
                                }
                              },
                              {
                                key: "quantity",
                                fn: function(row) {
                                  return [
                                    _c("div", [
                                      !_vm.invoice.finalized &&
                                      _vm.mainForm &&
                                      _vm.mainForm.draft
                                        ? _c(
                                            "span",
                                            { staticClass: "space-between" },
                                            [
                                              _c(
                                                "b-input-group",
                                                {
                                                  staticClass: "mr-sm-2",
                                                  attrs: {
                                                    size: "sm",
                                                    append: row.item.unit
                                                  }
                                                },
                                                [
                                                  _vm.instructionsBuilderFeatureFlag &&
                                                  (row.item.inventory ===
                                                    "Medicine" ||
                                                    row.item.inventory ===
                                                      "Wyn")
                                                    ? _c("b-input", {
                                                        staticClass:
                                                          "mb-2 mb-sm-0",
                                                        attrs: {
                                                          size: "sm",
                                                          disabled:
                                                            _vm.invoice
                                                              .clinic !==
                                                            _vm.selectedClinic
                                                              ._id,
                                                          value: _vm._f(
                                                            "to2Decimal"
                                                          )(
                                                            row.value /
                                                              (_vm.invoice
                                                                .instructions
                                                                .timesPerDay *
                                                                _vm.invoice
                                                                  .instructions
                                                                  .noOfDays),
                                                            _vm.region.decimal
                                                          )
                                                        },
                                                        on: {
                                                          input: function(
                                                            $event
                                                          ) {
                                                            return _vm.preUpdateLineItem(
                                                              $event,
                                                              row.item.uuid,
                                                              "qty",
                                                              _vm.invoiceConfig
                                                            )
                                                          }
                                                        }
                                                      })
                                                    : row.item.inventory ===
                                                      "Package"
                                                    ? _c("b-input", {
                                                        staticClass:
                                                          "mb-2 mb-sm-0",
                                                        attrs: {
                                                          size: "sm",
                                                          disabled:
                                                            _vm.invoice
                                                              .clinic !==
                                                            _vm.selectedClinic
                                                              ._id,
                                                          value: row.value,
                                                          formatter:
                                                            _vm.qtyFormat
                                                        },
                                                        on: {
                                                          input: function(
                                                            $event
                                                          ) {
                                                            return _vm.updateLineItem(
                                                              $event,
                                                              row.item.uuid,
                                                              "qty",
                                                              _vm.invoiceConfig
                                                            )
                                                          }
                                                        }
                                                      })
                                                    : _c("b-form-input", {
                                                        staticClass:
                                                          "mb-2 mb-sm-0 qty-input",
                                                        attrs: {
                                                          size: "sm",
                                                          type: "number",
                                                          disabled:
                                                            _vm.invoice
                                                              .clinic !==
                                                            _vm.selectedClinic
                                                              ._id,
                                                          value: row.value,
                                                          debounce: "500"
                                                        },
                                                        on: {
                                                          input: function(
                                                            $event
                                                          ) {
                                                            return _vm.checkInputThenUpdateLineItem(
                                                              $event,
                                                              row.item.uuid,
                                                              "qty",
                                                              _vm.invoiceConfig
                                                            )
                                                          }
                                                        }
                                                      })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _c("span", [
                                            _vm.instructionsBuilderFeatureFlag &&
                                            (row.item.inventory ===
                                              "Medicine" ||
                                              row.item.inventory === "Wyn")
                                              ? _c("span", [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        _vm._f("to2Decimal")(
                                                          row.value /
                                                            (_vm.invoice
                                                              .instructions
                                                              .timesPerDay *
                                                              _vm.invoice
                                                                .instructions
                                                                .noOfDays),
                                                          _vm.region.decimal
                                                        )
                                                      ) +
                                                      "\n                                    "
                                                  )
                                                ])
                                              : _c("span", [
                                                  _vm._v(_vm._s(row.value))
                                                ])
                                          ])
                                    ]),
                                    (row.item.inventory === "Medicine" ||
                                      row.item.inventory === "Wyn") &&
                                    _vm.instructionsBuilderFeatureFlag &&
                                    _vm.invoice.instructions.timesPerDay !==
                                      undefined &&
                                    _vm.invoice.instructions.noOfDays !==
                                      undefined
                                      ? _c(
                                          "small",
                                          { staticClass: "text-primary" },
                                          [
                                            _vm._v(
                                              "\n                                (" +
                                                _vm._s(
                                                  _vm.$t("general.total2")
                                                ) +
                                                ": " +
                                                _vm._s(
                                                  _vm._f("to2Decimal")(
                                                    row.item.quantity,
                                                    _vm.region.decimal
                                                  )
                                                ) +
                                                " " +
                                                _vm._s(
                                                  row.item.unit
                                                    ? " " + row.item.unit
                                                    : ""
                                                ) +
                                                ")\n                            "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                }
                              },
                              {
                                key: "dosage",
                                fn: function(row) {
                                  return [
                                    !_vm.invoice.finalized &&
                                    _vm.mainForm &&
                                    _vm.mainForm.draft &&
                                    _vm.invoice.clinic ===
                                      _vm.selectedClinic._id
                                      ? _c(
                                          "span",
                                          [
                                            _c("b-input", {
                                              staticClass:
                                                "mb-2 mr-sm-2 mb-sm-0",
                                              staticStyle: { width: "280px" },
                                              attrs: {
                                                size: "sm",
                                                value: row.value
                                              },
                                              on: {
                                                input: function($event) {
                                                  return _vm.updateLineItem(
                                                    $event,
                                                    row.item.uuid,
                                                    "dosage",
                                                    _vm.invoiceConfig
                                                  )
                                                }
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _c("span", [_vm._v(_vm._s(row.value))])
                                  ]
                                }
                              },
                              {
                                key: "dosageInstr",
                                fn: function(row) {
                                  return [
                                    !_vm.invoice.finalized &&
                                    _vm.mainForm &&
                                    _vm.mainForm.draft &&
                                    _vm.invoice.clinic ===
                                      _vm.selectedClinic._id
                                      ? _c(
                                          "span",
                                          [
                                            _c("b-input", {
                                              staticClass:
                                                "mb-2 mr-sm-2 mb-sm-0",
                                              staticStyle: { width: "280px" },
                                              attrs: {
                                                size: "sm",
                                                value: row.value
                                              },
                                              on: {
                                                input: function($event) {
                                                  return _vm.updateLineItem(
                                                    $event,
                                                    row.item.uuid,
                                                    "dosageInstr",
                                                    _vm.invoiceConfig
                                                  )
                                                }
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _c("span", [_vm._v(_vm._s(row.value))])
                                  ]
                                }
                              },
                              {
                                key: "action",
                                fn: function(row) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "space-between" },
                                      [
                                        !_vm.lockInvoice &&
                                        _vm.mainForm &&
                                        _vm.mainForm.draft &&
                                        _vm.invoice.clinic ===
                                          _vm.selectedClinic._id
                                          ? _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  "align-items": "center",
                                                  "margin-right": "5px"
                                                },
                                                attrs: {
                                                  "data-automation":
                                                    "dispense-builder-edit"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.toggleDispenseBuilder(
                                                      true,
                                                      _vm.invoice,
                                                      row.item.uuid
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("v-icon", {
                                                  attrs: { name: "edit" }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        row.item.testStatusId &&
                                        ((row.item.inventory === "Radiology" &&
                                          _vm.integratedTestStatusesMapped[
                                            row.item.testStatusId
                                          ].code !== "00") ||
                                          (row.item.inventory ===
                                            "Laboratory" &&
                                            _vm.integratedTestStatusesMapped[
                                              row.item.testStatusId
                                            ].code !== "10")) &&
                                        _vm.invoice.clinic ===
                                          _vm.selectedClinic._id
                                          ? _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  "align-items": "center"
                                                },
                                                attrs: {
                                                  "data-automation":
                                                    "dispense-builder-times"
                                                }
                                              },
                                              [
                                                _c("v-icon", {
                                                  attrs: {
                                                    name: "times",
                                                    color: "grey"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : !_vm.lockInvoice &&
                                            _vm.mainForm &&
                                            _vm.mainForm.draft &&
                                            _vm.invoice.clinic ===
                                              _vm.selectedClinic._id
                                          ? _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  "align-items": "center"
                                                },
                                                attrs: {
                                                  "data-automation":
                                                    "dispense-builder-remove-btn"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.removeItem(
                                                      row.item.uuid
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("v-icon", {
                                                  attrs: { name: "times" }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.integratedTestConfig &&
                                        _vm.integratedTestConfig.statuses &&
                                        _vm.integratedTestConfig.statuses[
                                          row.item.inventory
                                        ] &&
                                        row.item.dispensedAt &&
                                        _vm.invoice.clinic ===
                                          _vm.selectedClinic._id
                                          ? _c(
                                              "div",
                                              [
                                                !row.item.testStatusId
                                                  ? _c(
                                                      "b-button",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "acl",
                                                            rawName:
                                                              "v-acl:disabled.isDynamic.disabled",
                                                            value:
                                                              _vm
                                                                .integratedTestConfig
                                                                .statuses[
                                                                row.item
                                                                  .inventory
                                                              ]["Confirmed"][0]
                                                                ._id,
                                                            expression:
                                                              "integratedTestConfig.statuses[row.item.inventory]['Confirmed'][0]._id",
                                                            arg: "disabled",
                                                            modifiers: {
                                                              isDynamic: true,
                                                              disabled: true
                                                            }
                                                          }
                                                        ],
                                                        staticClass: "mt-1",
                                                        attrs: {
                                                          size: "sm",
                                                          variant:
                                                            "outline-info",
                                                          disabled:
                                                            _vm.lockInvoice
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.confirmIntegrationTest(
                                                              row.item
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                        " +
                                                            _vm._s(
                                                              _vm
                                                                .integratedTestConfig
                                                                .statuses[
                                                                row.item
                                                                  .inventory
                                                              ]["Confirmed"][0]
                                                                .labelName ||
                                                                "Confirm Test"
                                                            ) +
                                                            "\n                                    "
                                                        )
                                                      ]
                                                    )
                                                  : _vm
                                                      .integratedTestStatusesMapped[
                                                      row.item.testStatusId
                                                    ].statusGroup ===
                                                    "Confirmed"
                                                  ? _c(
                                                      "b-button",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "acl",
                                                            rawName:
                                                              "v-acl:disabled.isDynamic.disabled",
                                                            value:
                                                              _vm
                                                                .integratedTestConfig
                                                                .statuses[
                                                                row.item
                                                                  .inventory
                                                              ]["Cancelled"][0]
                                                                ._id,
                                                            expression:
                                                              "integratedTestConfig.statuses[row.item.inventory]['Cancelled'][0]._id",
                                                            arg: "disabled",
                                                            modifiers: {
                                                              isDynamic: true,
                                                              disabled: true
                                                            }
                                                          }
                                                        ],
                                                        staticClass: "mt-1",
                                                        attrs: {
                                                          size: "sm",
                                                          variant:
                                                            "outline-danger",
                                                          disabled:
                                                            _vm.lockInvoice
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.cancelIntegrationTest(
                                                              row.item
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                        " +
                                                            _vm._s(
                                                              _vm
                                                                .integratedTestConfig
                                                                .statuses[
                                                                row.item
                                                                  .inventory
                                                              ]["Cancelled"][0]
                                                                .labelName ||
                                                                "Cancel Test"
                                                            ) +
                                                            "\n                                    "
                                                        )
                                                      ]
                                                    )
                                                  : _c("span")
                                              ],
                                              1
                                            )
                                          : _c("div")
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3018353687
                          )
                        })
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e(),
            _c("hr", { staticStyle: { margin: "30px 0" } }),
            _c(
              "div",
              [
                _c("div", { staticClass: "title space-between" }, [
                  _c("span", { staticClass: "bold" }, [
                    _vm._v(_vm._s(_vm.$t("general.charting")))
                  ]),
                  _c("span")
                ]),
                _c("charting-container", {
                  attrs: {
                    info: _vm.info,
                    patient: _vm.patient,
                    parentComponent: "consult-note-container",
                    mainForm: _vm.mainForm,
                    invoice: _vm.invoice || {},
                    invoices: _vm.invoices,
                    notesMapped: _vm.notesMapped,
                    categoriesMapped: _vm.categoriesMapped,
                    selectedCategoryFilter: _vm.selectedCategoryFilter,
                    fetchAllNoteTemplates: _vm.fetchAllNoteTemplates,
                    invoiceFetchReady: _vm.invoiceFetchReady,
                    presetChartingCardWidth: _vm.presetChartingCardWidth,
                    presetChartingCardSize: _vm.presetChartingCardSize,
                    lock: _vm.lock,
                    inPatientFeatureFlag: _vm.inPatientFeatureFlag,
                    otOptions: _vm.otOptions,
                    otMappedIdToName: _vm.otMappedIdToName
                  },
                  on: {
                    chartingAddendumChange: _vm.checkMainFormChanges,
                    notesmoved: function(destinationCategory) {
                      return _vm.selectCategoryFilter(null, destinationCategory)
                    }
                  }
                })
              ],
              1
            ),
            !_vm.patientConfig.hideRecordICD10Section
              ? _c("div", [
                  _c("hr", { staticStyle: { margin: "30px 0" } }),
                  _vm.invoice
                    ? _c("div", { staticClass: "title bold" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$t("general.recordICD10")) +
                            "\n                    "
                        ),
                        _vm.patientConfig.mandatoryICD10Recording
                          ? _c("span", { staticClass: "text-primary" }, [
                              _vm._v("*")
                            ])
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "my-3 p-4 flex-fill" },
                    _vm._l(_vm.diagnosis, function(diagnose, i) {
                      return _c(
                        "table",
                        { key: i, staticClass: "table diagnosis-table" },
                        [
                          _c(
                            "tbody",
                            [
                              _c("tr", { key: i }, [
                                _c(
                                  "th",
                                  { attrs: { rowspan: diagnose.length } },
                                  [
                                    _vm._v(
                                      "\n                          " +
                                        _vm._s(
                                          i === 0
                                            ? "Primary Diagnosis"
                                            : i === 1
                                            ? "Secondary Diagnosis"
                                            : "Additional Diagnosis"
                                        ) +
                                        "\n                        "
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  [
                                    _c("el-autocomplete", {
                                      staticClass: "diagnose-search",
                                      attrs: {
                                        "data-automation": _vm.setDataAutomationField(
                                          i
                                        ),
                                        debounce: 500,
                                        clearable: true,
                                        "fetch-suggestions":
                                          _vm.icd10SearchQuery,
                                        placeholder: _vm.$t(
                                          "general.recordICD10"
                                        ),
                                        disabled:
                                          (diagnose.length > 0 &&
                                            diagnose[0].valid) ||
                                          !_vm.allowEditDiagnosis ||
                                          _vm.lock ||
                                          _vm.hasPassedNoteLockTimes ||
                                          _vm.lockInvoice ||
                                          _vm.invoice.clinic !==
                                            _vm.selectedClinic._id
                                      },
                                      on: {
                                        select: function($event) {
                                          return _vm.selectedICD10Diagnosis(
                                            $event,
                                            i,
                                            0
                                          )
                                        },
                                        input: function($event) {
                                          return _vm.changeDiagnoseSearch(
                                            $event,
                                            i,
                                            0
                                          )
                                        },
                                        clear: function() {
                                          return _vm.clearDiagnoseSearch(i, 0)
                                        }
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(props) {
                                              return [
                                                _c("icd10-slot", {
                                                  attrs: { item: props.item }
                                                })
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: diagnose[0].text,
                                        callback: function($$v) {
                                          _vm.$set(diagnose[0], "text", $$v)
                                        },
                                        expression: "diagnose[0].text"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("td", { staticClass: "delete-column" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-secondary",
                                      attrs: {
                                        disabled:
                                          !_vm.allowEditDiagnosis ||
                                          _vm.lock ||
                                          !diagnose[0].valid ||
                                          _vm.hasPassedNoteLockTimes ||
                                          _vm.lockInvoice ||
                                          _vm.invoice.clinic !==
                                            _vm.selectedClinic._id
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.removeDiagnosis(i, 0)
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", { attrs: { name: "times" } })
                                    ],
                                    1
                                  )
                                ])
                              ]),
                              _vm._l(diagnose.slice(1), function(entry, j) {
                                return _c("tr", { key: i + "-" + (j + 1) }, [
                                  _c(
                                    "td",
                                    [
                                      _c("el-autocomplete", {
                                        staticClass: "diagnose-search",
                                        attrs: {
                                          debounce: 500,
                                          clearable: true,
                                          "fetch-suggestions":
                                            _vm.icd10SearchQuery,
                                          placeholder: _vm.$t(
                                            "general.recordICD10"
                                          ),
                                          disabled:
                                            (diagnose.length > 0 &&
                                              diagnose[j + 1].valid) ||
                                            !_vm.allowEditDiagnosis ||
                                            _vm.lock ||
                                            _vm.hasPassedNoteLockTimes ||
                                            _vm.lockInvoice ||
                                            _vm.invoice.clinic !==
                                              _vm.selectedClinic._id
                                        },
                                        on: {
                                          select: function($event) {
                                            return _vm.selectedICD10Diagnosis(
                                              $event,
                                              i,
                                              j + 1
                                            )
                                          },
                                          input: function($event) {
                                            return _vm.changeDiagnoseSearch(
                                              $event,
                                              i,
                                              j + 1
                                            )
                                          },
                                          clear: function() {
                                            return _vm.clearDiagnoseSearch(
                                              i,
                                              j + 1
                                            )
                                          }
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(props) {
                                                return [
                                                  _c("icd10-slot", {
                                                    attrs: { item: props.item }
                                                  })
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: diagnose[j + 1].text,
                                          callback: function($$v) {
                                            _vm.$set(
                                              diagnose[j + 1],
                                              "text",
                                              $$v
                                            )
                                          },
                                          expression: "diagnose[j+1].text"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("td", { staticClass: "delete-column" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-secondary",
                                        attrs: {
                                          disabled:
                                            !_vm.allowEditDiagnosis ||
                                            _vm.lock ||
                                            !diagnose[j + 1].valid ||
                                            _vm.hasPassedNoteLockTimes ||
                                            _vm.lockInvoice ||
                                            _vm.invoice.clinic !==
                                              _vm.selectedClinic._id
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.removeDiagnosis(i, j + 1)
                                          }
                                        }
                                      },
                                      [
                                        _c("v-icon", {
                                          attrs: { name: "times" }
                                        })
                                      ],
                                      1
                                    )
                                  ])
                                ])
                              })
                            ],
                            2
                          )
                        ]
                      )
                    }),
                    0
                  )
                ])
              : _vm._e(),
            _vm.invoice
              ? _c("div", [
                  _c("div", { staticClass: "title bold" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$t("general.letterMC")) +
                        "\n                    "
                    )
                  ]),
                  !_vm.lock &&
                  !_vm.hasPassedNoteLockTimes &&
                  !_vm.lockInvoice &&
                  _vm.mainForm.draft &&
                  _vm.invoice.clinic === _vm.selectedClinic._id
                    ? _c(
                        "div",
                        [
                          _c("vue-bootstrap-typeahead", {
                            staticClass: "wrapper",
                            attrs: {
                              data: _vm.letters,
                              inputClass: "form-control",
                              serializer: function(s) {
                                return s.name + " (" + s.givenId + ")"
                              },
                              "data-automation": "issue-lettermc-select",
                              placeholder: _vm.$t("general.issueLetterMC"),
                              clearOnHit: true,
                              textVariant: "light",
                              backgroundVariant: "primary"
                            },
                            on: {
                              hit: function($event) {
                                return _vm.selectedLetter($event)
                              }
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "suggestion",
                                  fn: function(ref) {
                                    var data = ref.data
                                    var htmlText = ref.htmlText
                                    return [
                                      _c("div", { staticClass: "d-flex" }, [
                                        _c("span", {
                                          staticClass: "ml-2",
                                          domProps: {
                                            innerHTML: _vm._s(htmlText)
                                          }
                                        })
                                      ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              4083190654
                            ),
                            model: {
                              value: _vm.letterSearch,
                              callback: function($$v) {
                                _vm.letterSearch = $$v
                              },
                              expression: "letterSearch"
                            }
                          })
                        ],
                        1
                      )
                    : _c("div", [
                        _c("input", {
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            "data-automation": "issue-lettermc-text",
                            disabled: "",
                            placeholder: _vm.$t("general.issueLetterMC")
                          }
                        })
                      ]),
                  _c(
                    "div",
                    [
                      _vm.invoiceLettersMapped.length > 0
                        ? _c("b-table", {
                            attrs: {
                              striped: "",
                              hover: "",
                              fields: _vm.letterFields,
                              items: _vm.invoiceLettersMapped
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "type",
                                  fn: function(row) {
                                    return [
                                      _vm.mainForm &&
                                      _vm.mainForm.draft &&
                                      !_vm.hasPassedNoteLockTimes &&
                                      !_vm.lockInvoice
                                        ? _c(
                                            "span",
                                            { staticClass: "space-between" },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(row.value))
                                              ]),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "align-items": "center",
                                                    "margin-right": "5px"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "mr-2",
                                                      attrs: {
                                                        "data-automation":
                                                          "print-letter-" +
                                                          row.id
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.printLetter(
                                                            row.item
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("v-icon", {
                                                        attrs: { name: "print" }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm.invoice.clinic ===
                                                  _vm.selectedClinic._id
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass: "mr-2",
                                                          attrs: {
                                                            "data-automation":
                                                              "toggle-letter-builder" +
                                                              row.id
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.toggleLetterBuilder(
                                                                true,
                                                                _vm.invoice,
                                                                row.item
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("v-icon", {
                                                            attrs: {
                                                              name: "edit"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.invoice.clinic ===
                                                  _vm.selectedClinic._id
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass: "mr-1",
                                                          attrs: {
                                                            "data-automation":
                                                              "remove-letter-" +
                                                              row.id
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.removeLetter(
                                                                row.item.uuid
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("v-icon", {
                                                            attrs: {
                                                              name: "times"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ]
                                              )
                                            ]
                                          )
                                        : _c(
                                            "span",
                                            { staticClass: "space-between" },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(row.value))
                                              ]),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    "data-automation":
                                                      "print-letter-" + row.id
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.printLetter(
                                                        row.item
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("v-icon", {
                                                    attrs: { name: "print" }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              598569186
                            )
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ])
              : _vm._e()
          ])
        ],
        1
      ),
      _vm.mainForm && _vm.mainForm.draft && !_vm.isExtracted
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.expanded,
                  expression: "expanded"
                }
              ],
              staticClass: "note-footer space-between"
            },
            [
              _c("span"),
              _vm.configConsultNotePublishMandatory
                ? _c(
                    "span",
                    [
                      !_vm.isEnableConsultNote
                        ? _c(
                            "b-button",
                            {
                              staticClass: "mx-1 save-draft-button",
                              attrs: {
                                disabled:
                                  _vm.invoice.clinic !== _vm.selectedClinic._id,
                                "data-automation": "consult-note-save-draft"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.saveNote(false)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(_vm.$t("general.saveDraft")) +
                                  "\n                "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.checkPublishValid &&
                      _vm.invoice.clinic === _vm.selectedClinic._id
                        ? _c(
                            "b-button",
                            {
                              staticClass: "mx-1 publish-button",
                              attrs: {
                                disabled: "",
                                "data-automation": "consult-note-publish",
                                title:
                                  "Please unlock both notes and invoice to enable this button"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.saveNote(true)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(_vm.$t("general.publish")) +
                                  "\n                "
                              )
                            ]
                          )
                        : _c(
                            "b-button",
                            {
                              staticClass: "mx-1 publish-button",
                              attrs: {
                                "data-automation":
                                  "consult-note-publish-disable",
                                disabled:
                                  _vm.invoice.clinic !== _vm.selectedClinic._id
                              },
                              on: {
                                click: function($event) {
                                  return _vm.saveNote(true)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(_vm.$t("general.publish")) +
                                  "\n                "
                              )
                            ]
                          )
                    ],
                    1
                  )
                : _c(
                    "span",
                    [
                      !_vm.$store.state.config.patient
                        .enableConsultNoteSectioning
                        ? _c(
                            "b-button",
                            {
                              staticClass: "mx-1",
                              attrs: {
                                disabled:
                                  _vm.invoice.clinic !== _vm.selectedClinic._id,
                                "data-automation": "consult-save-note"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.saveNote(false)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("general.save")) +
                                  "\n              "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
            ]
          )
        : _vm._e(),
      _c(
        "b-modal",
        {
          ref: "teleconsult-session-loading",
          attrs: {
            title: "TeleConsult",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            visible: _vm.startingTeleConsulting,
            "hide-footer": ""
          }
        },
        [
          _c("p", [
            _vm._v(
              "\n            Please wait. Connecting to the server ....\n          "
            )
          ])
        ]
      ),
      _c(
        "b-modal",
        {
          ref: "teleconsult-session-error",
          attrs: {
            title: "TeleConsult",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            visible: _vm.inValidToStartSession,
            "hide-footer": ""
          }
        },
        [
          _c("p", [
            _vm._v(
              "\n            " + _vm._s(_vm.invalidReason) + "\n          "
            )
          ])
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: _vm.mainForm._id + "-tagging-modal",
            title: _vm.tagParams.action + " Consult Note"
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: { click: _vm.hideTagModal }
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("general.cancel")) +
                          "\n            "
                      )
                    ]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                      on: { click: _vm.handleTagModalOk }
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("general.ok")) +
                          "\n            "
                      )
                    ]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm.tagParams.action === "Tag"
            ? _c(
                "template",
                { slot: "default" },
                [
                  _c(
                    "b-form-group",
                    [
                      _c("b-form-select", {
                        attrs: { options: _vm.tagOptions.tabs },
                        model: {
                          value: _vm.tagParams.tab,
                          callback: function($$v) {
                            _vm.$set(_vm.tagParams, "tab", $$v)
                          },
                          expression: "tagParams.tab"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    [
                      _c("b-form-select", {
                        attrs: { options: _vm.otOptions },
                        model: {
                          value: _vm.tagParams.ot,
                          callback: function($$v) {
                            _vm.$set(_vm.tagParams, "ot", $$v)
                          },
                          expression: "tagParams.ot"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }