var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _vm.parentComponent === "templates-list-page"
      ? _c("div", { staticClass: "col-sm-2" }, [
          _c("div", { staticClass: "categories-container" }, [
            _c("div", {
              staticClass: "categories-overlay",
              class: _vm.categoriesLoading ? "loading" : "pass-clicks"
            }),
            _c(
              "ul",
              { staticClass: "list-group" },
              [
                _c(
                  "li",
                  {
                    staticClass:
                      "list-group-item d-flex justify-content-between align-items-center",
                    class: { active: _vm.selectedTemplateId === "All" },
                    on: {
                      click: function($event) {
                        return _vm.selectTemplateCat("All")
                      }
                    }
                  },
                  [
                    _c(
                      "span",
                      [_c("v-icon", { attrs: { name: "globe-asia" } })],
                      1
                    ),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "flex-grow": "1",
                          padding: "2px 0 0 5px"
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("general.all")))]
                    ),
                    _c("span")
                  ]
                ),
                _c(
                  "draggable",
                  {
                    attrs: { handle: ".drag-handle", sort: true },
                    on: { change: _vm.handleDrag },
                    model: {
                      value: _vm.categoriesIdsOnlyForDraggable,
                      callback: function($$v) {
                        _vm.categoriesIdsOnlyForDraggable = $$v
                      },
                      expression: "categoriesIdsOnlyForDraggable"
                    }
                  },
                  _vm._l(_vm.categoriesIdsOnlyForDraggable, function(c, index) {
                    return _c(
                      "li",
                      {
                        key: index,
                        staticClass:
                          "list-group-item d-flex justify-content-between align-items-center category-row",
                        class: { active: c === _vm.selectedTemplateId },
                        staticStyle: {
                          "border-radius": "0",
                          position: "relative"
                        },
                        on: {
                          click: function($event) {
                            return _vm.selectTemplateCat(c)
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "color-picker",
                            staticStyle: {
                              position: "absolute",
                              left: "8px",
                              top: "14px"
                            }
                          },
                          [
                            _c("swatches", {
                              attrs: {
                                value: (_vm.categoriesMapped[c] || {})
                                  .colorLabel,
                                colors: "text-advanced",
                                swatchSize: "22",
                                "popover-to": "right",
                                "trigger-style": {
                                  width: "8px",
                                  height: "24px"
                                }
                              },
                              on: {
                                input: function($event) {
                                  return _vm.prePatchCategory(
                                    c,
                                    "colorLabel",
                                    $event
                                  )
                                }
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "drag-handle cursor-grab",
                            staticStyle: { "padding-left": "2px" }
                          },
                          [
                            _c("v-icon", {
                              attrs: { color: "grey", name: "grip-vertical" }
                            })
                          ],
                          1
                        ),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "flex-grow": "1",
                              padding: "2px 0 0 7px"
                            }
                          },
                          [
                            _vm.editingCategoryName_id === c
                              ? _c(
                                  "span",
                                  [
                                    _c("b-input", {
                                      attrs: {
                                        type: "text",
                                        size: "sm",
                                        value:
                                          (_vm.categoriesMapped[c] || {})
                                            .name || c,
                                        autofocus: ""
                                      },
                                      on: { blur: _vm.preUpdateCategoryName }
                                    })
                                  ],
                                  1
                                )
                              : _c(
                                  "span",
                                  {
                                    on: {
                                      click: function($event) {
                                        c === _vm.selectedTemplateId &&
                                        c !== _vm.uncategorizedCategoryId
                                          ? (_vm.editingCategoryName_id = c)
                                          : ""
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        (_vm.categoriesMapped[c] || {}).name ||
                                          c
                                      )
                                    )
                                  ]
                                )
                          ]
                        ),
                        c !== _vm.uncategorizedCategoryId
                          ? _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "acl",
                                    rawName: "v-acl",
                                    value: "chartingbuilder_delete",
                                    expression: "'chartingbuilder_delete'"
                                  }
                                ],
                                staticClass: "hide cursor-pointer",
                                staticStyle: {
                                  position: "relative",
                                  left: "10px"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.preDeleteCategory(c)
                                  }
                                }
                              },
                              [_vm._v("x")]
                            )
                          : _c(
                              "span",
                              { staticStyle: { color: "rgba(0,0,0,0)" } },
                              [_vm._v("x")]
                            )
                      ]
                    )
                  }),
                  0
                ),
                _c(
                  "li",
                  {
                    staticClass:
                      "list-group-item d-flex justify-content-between align-items-center",
                    class: {
                      active: _vm.trashCategoryId === _vm.selectedTemplateId
                    },
                    on: {
                      click: function($event) {
                        return _vm.selectTemplateCat(_vm.trashCategoryId)
                      }
                    }
                  },
                  [
                    _c("span", [_c("v-icon", { attrs: { name: "trash" } })], 1),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "flex-grow": "1",
                          padding: "2px 0 0 5px"
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("general.trash")))]
                    ),
                    _c("span")
                  ]
                )
              ],
              1
            )
          ])
        ])
      : _vm._e(),
    _vm.parentComponent !== "templates-list-page"
      ? _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "space-between" }, [
            _c(
              "span",
              { staticClass: "cursor-pointer" },
              [
                _c("span", [
                  _c(
                    "button",
                    {
                      class: [
                        _vm.selectedTemplateId === "All"
                          ? "active"
                          : "inactive",
                        "category-button"
                      ],
                      on: {
                        click: function($event) {
                          return _vm.selectTemplateCat("All")
                        }
                      }
                    },
                    [
                      _c("span", [
                        _c(
                          "span",
                          { staticClass: "icon-globe" },
                          [
                            _c("v-icon", {
                              attrs: { name: "globe-asia", color: "black" }
                            })
                          ],
                          1
                        ),
                        _c("span", { staticClass: "icon-text" }, [
                          _vm._v(_vm._s(_vm.$t("general.all")))
                        ])
                      ])
                    ]
                  )
                ]),
                _vm._l(Object.keys(_vm.notetemplatesByCategories), function(
                  c,
                  index
                ) {
                  return _c("span", { key: index }, [
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "acl",
                            rawName: "v-acl:disabled.isDynamic",
                            value: c,
                            expression: "c",
                            arg: "disabled",
                            modifiers: { isDynamic: true }
                          }
                        ],
                        staticClass: "category-button",
                        class: [
                          _vm.selectedTemplateId === c ? "active" : "inactive",
                          "category-button"
                        ],
                        on: {
                          click: function($event) {
                            return _vm.selectTemplateCat(c)
                          }
                        }
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "icon-circle" },
                          [
                            _c("v-icon", {
                              attrs: {
                                name: "circle",
                                color: (_vm.categoriesMapped[c] || {})
                                  .colorLabel
                              }
                            })
                          ],
                          1
                        ),
                        _c("span", { staticClass: "icon-text" }, [
                          _vm._v(
                            _vm._s(
                              _vm.categoriesMapped[c].name === "Uncategorized"
                                ? _vm.$t("general.uncategorized")
                                : _vm.categoriesMapped[c].name
                            )
                          )
                        ])
                      ]
                    )
                  ])
                })
              ],
              2
            )
          ])
        ])
      : _vm._e(),
    _c(
      "div",
      { class: { "col-sm-10": _vm.parentComponent === "templates-list-page" } },
      [
        _c(
          "div",
          { staticClass: "row charting-cards-container" },
          _vm._l(_vm.notetemplatesFiltered, function(templateId) {
            return _c(
              "div",
              {
                directives: [
                  {
                    name: "acl",
                    rawName: "v-acl:disabled.isDynamic",
                    value:
                      _vm.parentComponent === "templates-list-page"
                        ? ""
                        : _vm.notetemplatesMappedById[templateId].categoryId,
                    expression:
                      "parentComponent === 'templates-list-page' ? '' : notetemplatesMappedById[templateId].categoryId",
                    arg: "disabled",
                    modifiers: { isDynamic: true }
                  }
                ],
                key: templateId,
                staticClass: "charting-card"
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "charting-card-label",
                    style: {
                      padding: "0 25px",
                      "background-color": (
                        _vm.categoriesMapped[
                          _vm.notetemplatesMappedById[templateId].categoryId
                        ] || {}
                      ).colorLabel,
                      color: "#4D4D4D"
                    }
                  },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.notetemplatesMappedById[templateId].name)
                      )
                    ])
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "charting-card-label-after space-between absolute"
                  },
                  [
                    _vm.parentComponent === "templates-list-page"
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "charting-card-icons-left cursor-pointer",
                            on: {
                              click: function($event) {
                                return _vm.preDeleteNotetemplate(templateId)
                              }
                            }
                          },
                          [
                            _c("v-icon", {
                              staticClass: "trash",
                              attrs: { name: "trash" }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("span"),
                    _c(
                      "span",
                      { staticClass: "charting-card-icons cursor-pointer" },
                      [
                        _vm.externallyValid
                          ? _c(
                              "span",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.topRightIconFunction(templateId)
                                  }
                                }
                              },
                              [
                                _c("v-icon", {
                                  staticClass: "edit",
                                  attrs: { name: _vm.topRightIcon }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "charting-card-body d-flex justify-content-center align-items-center"
                  },
                  [
                    _c("img", {
                      attrs: {
                        src:
                          _vm.notetemplatesMappedById[templateId]
                            .signedThumbnailKey,
                        alt: "",
                        width: "165"
                      },
                      on: {
                        error: function($event) {
                          return _vm.fetchSignedUrl(templateId)
                        }
                      }
                    })
                  ]
                ),
                _c("div", { staticClass: "charting-card-footer" }, [
                  _vm.notetemplatesMappedById[templateId].isDefaultIPDNotes
                    ? _c("span", [_vm._v("Default")])
                    : _vm._e(),
                  _c("span"),
                  _vm.showBottomRightIcon
                    ? _c(
                        "span",
                        { staticClass: "charting-card-icons cursor-pointer" },
                        [
                          _vm.externallyValid
                            ? _c(
                                "span",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.bottomRightIconFunction(
                                        templateId
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm.notetemplatesMappedById[templateId]
                                    .isDefaultIPDNotes
                                    ? _c("v-icon", {
                                        staticClass: "tag",
                                        attrs: { name: "minus-square" }
                                      })
                                    : _c("v-icon", {
                                        staticClass: "tag",
                                        attrs: { name: _vm.bottomRightIcon }
                                      })
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    : _vm._e()
                ])
              ]
            )
          }),
          0
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }