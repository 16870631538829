var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container mb-5", class: { previewPrint: _vm.preview } },
    [
      _c("div", { staticClass: "row mb-3" }, [
        _c("div", { staticClass: "col-9" }, [
          _vm.patientConfig.printConsultNote.clinicLogo
            ? _c("img", {
                style: {
                  marginLeft: _vm.logoMarginLeft + "px",
                  width: _vm.logoWidth + "px"
                },
                attrs: { src: this.companyClinicLogo }
              })
            : _vm._e(),
          _vm.patientConfig.printConsultNote.clinicName
            ? _c("h5", { staticClass: "clinic-name" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.generalConfig.clinicName) +
                    "\n      "
                )
              ])
            : _vm._e(),
          _vm.patientConfig.printConsultNote.clinicAddress
            ? _c("p", { staticClass: "clinic-address" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.generalConfig.address) + "\n      "
                )
              ])
            : _vm._e()
        ]),
        _vm.type === "classified"
          ? _c("div", { staticClass: "col3" }, [
              _c("div", { staticClass: "classified-medicine-tip" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("prescriptionConfig.classifiedMedicineTip")) +
                    "\n      "
                )
              ])
            ])
          : _vm._e()
      ]),
      _c("h3", { staticClass: "mb-5 mt-3 text-center text-uppercase" }, [
        _vm._v("\n    " + _vm._s(_vm.$t("general.consultNoteTitle")) + "\n  ")
      ]),
      _c("div", { staticClass: "row mb-4" }, [
        _c("div", { staticClass: "col-6" }, [
          _vm.patientConfig.printConsultNote.patientName
            ? _c("div", { staticClass: "row mb-2" }, [
                _c("div", { staticClass: "col-4 form-label" }, [
                  _vm._v(_vm._s(_vm.$t("general.patientName")) + ":")
                ]),
                _c("div", { staticClass: "col-8 form-value" }, [
                  _vm._v(_vm._s(_vm.selectedConsultNote.patient.name))
                ])
              ])
            : _vm._e(),
          _vm.patientConfig.printConsultNote.patientGender
            ? _c("div", { staticClass: "row mb-2" }, [
                _c("div", { staticClass: "col-4 form-label" }, [
                  _vm._v(_vm._s(_vm.$t("general.patientGender")) + ":")
                ]),
                _c("div", { staticClass: "col-8 form-value" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("translateGender")(
                        _vm.selectedConsultNote.patient.gender
                      )
                    )
                  )
                ])
              ])
            : _vm._e(),
          _vm.patientConfig.printConsultNote.patientNRIC
            ? _c("div", { staticClass: "row mb-2" }, [
                _c("div", { staticClass: "col-4 form-label" }, [
                  _vm._v(_vm._s(_vm.$t("general.patientNRIC")) + ":")
                ]),
                _c("div", { staticClass: "col-8 form-value" }, [
                  _vm._v(_vm._s(_vm.selectedConsultNote.patient.nric))
                ])
              ])
            : _vm._e(),
          _vm.patientConfig.printConsultNote.patientAddress
            ? _c("div", { staticClass: "row mb-2" }, [
                _c("div", { staticClass: "col-4 form-label" }, [
                  _vm._v(_vm._s(_vm.$t("general.patientAddress")) + ":")
                ]),
                _c("div", { staticClass: "col-8 form-value" }, [
                  _vm._v(_vm._s(_vm.selectedConsultNote.patient.address))
                ])
              ])
            : _vm._e(),
          _vm.patientConfig.printConsultNote.patientDOB
            ? _c("div", { staticClass: "row mb-2" }, [
                _c("div", { staticClass: "col-4 form-label" }, [
                  _vm._v(_vm._s(_vm.$t("general.dateOfBirth")) + ":")
                ]),
                _c("div", { staticClass: "col-8 form-value" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("formatDayTime")(
                        _vm.selectedConsultNote.patient.dateOfBirth
                      )
                    )
                  )
                ])
              ])
            : _vm._e(),
          _vm.patientConfig.printConsultNote.patientNationality
            ? _c("div", { staticClass: "row mb-2" }, [
                _c("div", { staticClass: "col-4 form-label" }, [
                  _vm._v(_vm._s(_vm.$t("general.nationality")) + ":")
                ]),
                _c("div", { staticClass: "col-8 form-value" }, [
                  _vm._v(_vm._s(_vm.selectedConsultNote.patient.nationality))
                ])
              ])
            : _vm._e()
        ]),
        _c("div", { staticClass: "col-6" }, [
          _vm.patientConfig.printConsultNote.visitDate
            ? _c("div", { staticClass: "row mb-2" }, [
                _c("div", { staticClass: "col-4 form-label" }, [
                  _vm._v(_vm._s(_vm.$t("general.visitDate")) + ":")
                ]),
                _c("div", { staticClass: "col-8 form-value" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("formatDayTime")(
                        _vm.selectedConsultNote.invoice.createdAt
                      )
                    )
                  )
                ])
              ])
            : _vm._e(),
          _vm.patientConfig.printConsultNote.medicalServices
            ? _c("div", { staticClass: "row mb-2" }, [
                _c("div", { staticClass: "col-4 form-label" }, [
                  _vm._v(_vm._s(_vm.$t("general.medicalServices")) + ":")
                ]),
                _c("div", { staticClass: "col-8 form-value" }, [
                  _vm._v(
                    _vm._s(
                      _vm.selectedConsultNote.invoice.queue &&
                        _vm.selectedConsultNote.invoice.queue.service &&
                        _vm.selectedConsultNote.invoice.queue.service.name
                    )
                  )
                ])
              ])
            : _vm._e(),
          _vm.patientConfig.printConsultNote.doctorName
            ? _c("div", { staticClass: "row mb-2" }, [
                _c("div", { staticClass: "col-4 form-label" }, [
                  _vm._v(_vm._s(_vm.$t("general.doctorName")) + ":")
                ]),
                _c("div", { staticClass: "col-8 form-value" }, [
                  _vm._v(
                    _vm._s(
                      _vm.selectedConsultNote.patient.doctor &&
                        _vm.selectedConsultNote.patient.doctor.name
                    )
                  )
                ])
              ])
            : _vm._e(),
          _vm.patientConfig.printConsultNote.therapistName
            ? _c("div", { staticClass: "row mb-2" }, [
                _c("div", { staticClass: "col-4 form-label" }, [
                  _vm._v(_vm._s(_vm.$t("general.therapistName")) + ":")
                ]),
                _c("div", { staticClass: "col-8 form-value" }, [
                  _vm._v(
                    _vm._s(
                      _vm.selectedConsultNote.invoice.therapist &&
                        _vm.selectedConsultNote.invoice.therapist.name
                    )
                  )
                ])
              ])
            : _vm._e()
        ])
      ]),
      _c(
        "div",
        { staticClass: "consult-tempalte-wrap mb-4" },
        _vm._l(_vm.selectedConsultNote.mainForm.formData, function(item, key) {
          return _c(
            "div",
            { key: key, staticClass: "consult-template-item mb-2" },
            [
              _c("div", { staticClass: "consult-question" }, [
                _vm._v("\n        " + _vm._s(item.label) + "\n      ")
              ]),
              _c("div", { staticClass: "consult-answer" }, [
                _vm._v("\n        " + _vm._s(item.answer) + "\n      ")
              ])
            ]
          )
        }),
        0
      ),
      _c("div", { staticClass: "item-title" }, [
        _vm._v(_vm._s(_vm.$t("general.recordICD10")))
      ]),
      _c("div", { staticClass: "mb-4 icd-wrap" }, [
        _vm.selectedConsultNote.invoice.diagnosis &&
        _vm.selectedConsultNote.invoice.diagnosis[0] &&
        _vm.selectedConsultNote.invoice.diagnosis[0].length > 0
          ? _c("div", { staticClass: "row icd-sub-item" }, [
              _c("div", { staticClass: "col-3 form-label" }, [
                _vm._v(_vm._s(_vm.$t("general.primaryDiagnosis")))
              ]),
              _c(
                "div",
                { staticClass: "col-9" },
                _vm._l(_vm.selectedConsultNote.invoice.diagnosis[0], function(
                  item
                ) {
                  return _c(
                    "div",
                    { key: item.uuid, staticClass: "diagnosisItem" },
                    [_vm._v("\n          " + _vm._s(item.text) + "\n        ")]
                  )
                }),
                0
              )
            ])
          : _vm._e(),
        _vm.selectedConsultNote.invoice.diagnosis &&
        _vm.selectedConsultNote.invoice.diagnosis[1] &&
        _vm.selectedConsultNote.invoice.diagnosis[1].length > 0
          ? _c("div", { staticClass: "row icd-sub-item" }, [
              _c("div", { staticClass: "col-3 form-label" }, [
                _vm._v(_vm._s(_vm.$t("general.secondaryDiagnosis")))
              ]),
              _c(
                "div",
                { staticClass: "col-9" },
                _vm._l(_vm.selectedConsultNote.invoice.diagnosis[1], function(
                  item
                ) {
                  return _c(
                    "div",
                    { key: item.uuid, staticClass: "diagnosisItem" },
                    [_vm._v("\n          " + _vm._s(item.text) + "\n        ")]
                  )
                }),
                0
              )
            ])
          : _vm._e(),
        _vm.selectedConsultNote.invoice.diagnosis &&
        _vm.selectedConsultNote.invoice.diagnosis[2] &&
        _vm.selectedConsultNote.invoice.diagnosis[2].length > 0
          ? _c("div", { staticClass: "row icd-sub-item" }, [
              _c("div", { staticClass: "col-3 form-label" }, [
                _vm._v(_vm._s(_vm.$t("general.additionalDiagnosis")))
              ]),
              _c(
                "div",
                { staticClass: "col-9" },
                _vm._l(_vm.selectedConsultNote.invoice.diagnosis[2], function(
                  item
                ) {
                  return _c(
                    "div",
                    { key: item.uuid, staticClass: "diagnosisItem" },
                    [_vm._v("\n          " + _vm._s(item.text) + "\n        ")]
                  )
                }),
                0
              )
            ])
          : _vm._e()
      ]),
      _c("div", { staticClass: "item-title" }, [
        _vm._v(_vm._s(_vm.$t("general.dispensedItem")))
      ]),
      _c(
        "div",
        { staticClass: "mb-4" },
        [
          _c("div", { staticClass: "row med-header" }, [
            _c("div", { staticClass: "col text-uppercase" }, [
              _vm._v(_vm._s(_vm.$t("general.itemName")))
            ]),
            _c("div", { staticClass: "col text-uppercase" }, [
              _vm._v(_vm._s(_vm.$t("general.description")))
            ]),
            _c("div", { staticClass: "col text-uppercase" }, [
              _vm._v(_vm._s(_vm.$t("general.quantity")))
            ])
          ]),
          _vm._l(_vm.inventoryByCategory, function(categroy, key) {
            return _c(
              "div",
              { key: key, staticClass: "category-inventroy-wrap" },
              [
                _c("div", { staticClass: "category-name" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t(_vm.categorizedNameMapTranslateId[key])) +
                      "\n      "
                  )
                ]),
                _vm._l(_vm.inventoryByCategory[key], function(item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "row med-body" },
                    [
                      _c("div", { staticClass: "col table-value" }, [
                        _vm._v(_vm._s(item.name))
                      ]),
                      _c("div", { staticClass: "col table-value" }, [
                        _vm._v(_vm._s(item.description))
                      ]),
                      _c("div", { staticClass: "col table-value" }, [
                        _vm._v(_vm._s(item.quantity))
                      ])
                    ]
                  )
                })
              ],
              2
            )
          })
        ],
        2
      ),
      _c("div", { staticClass: "mb-5 row med-footer" }, [
        _c("div", { staticClass: "col-5 offset-7 form-label" }, [
          _c("div", { staticClass: "footer-item" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("general.doctorSignature")) +
                ":\n      "
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }