var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.featureFlags.bgrimm
        ? _c("drug-label-component", {
            attrs: {
              printLocal: _vm.printLocal,
              disableAutoPrint: false,
              medItem: _vm.print,
              generalConfig: _vm.general,
              patient: _vm.selectedPatient
            }
          })
        : _vm.featureFlags.drclinique
        ? [
            _c(
              "div",
              { staticClass: "container-fluid" },
              [
                _c("medical-label-dr-clinique", {
                  attrs: {
                    clinicName: _vm.general.clinicName,
                    clinicAddress: _vm.general.address,
                    medName: _vm.print.name,
                    medQty: _vm.print.qty,
                    medStrength: _vm.print.strength,
                    medUnit: _vm.print.unit,
                    medDosage: _vm.print.dosage,
                    medExpiry: _vm.print.selectedBatch.expiry || "",
                    medPrecautionOne: _vm.print.precaution1,
                    medPrecautionTwo: _vm.print.precaution2,
                    patientName: _vm.selectedPatient.name,
                    patientNric: _vm.selectedPatient.nric
                  }
                })
              ],
              1
            )
          ]
        : [
            _c(
              "div",
              { staticClass: "container-fluid" },
              [
                !_vm.print.adHocCompounding
                  ? _c("medical-label-component", {
                      attrs: {
                        clinicName: _vm.general.clinicName,
                        description: _vm.print.description,
                        clinicAddress: _vm.general.address,
                        medName: _vm.print.name,
                        medQty: _vm.print.qty,
                        medStrength: _vm.print.strength,
                        medUnit: _vm.print.unit,
                        medDosage: _vm.print.dosage,
                        medExpiry: _vm.print.selectedBatch.expiry || "",
                        selectedBatch: _vm.print.selectedBatch,
                        medPrecautionOne: _vm.print.precaution1,
                        medPrecautionTwo: _vm.print.precaution2,
                        patientName: _vm.selectedPatient.name,
                        patientNric: _vm.selectedPatient.nric,
                        patientDetails: _vm.selectedPatient
                      }
                    })
                  : _c("medical-label-component", {
                      attrs: {
                        clinicName: _vm.general.clinicName,
                        clinicAddress: _vm.general.address,
                        medName: _vm.print.adHocCompoundName,
                        medQty: _vm.print.qty,
                        medStrength: _vm.print.strength,
                        medUnit: _vm.print.adHocCompoundUnit,
                        medDosage: _vm.print.adHocCompoundDosage,
                        medExpiry: _vm.print.selectedBatch.expiry || "",
                        selectedBatch: _vm.print.selectedBatch,
                        medPrecautionOne: _vm.print.adHocCompoundPrecaution1,
                        medPrecautionTwo: _vm.print.adHocCompoundPrecaution2,
                        patientName: _vm.selectedPatient.name,
                        patientNric: _vm.selectedPatient.nric,
                        patientDetails: _vm.selectedPatient
                      }
                    })
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }