import Vue from 'vue';
import userService from '../services/user.service';

const state = {
  all: [],
  selectedUser: {},
  error: {},
  // socketRoomUsers: [],
};

const getters = {
  all: _state => _state.all,
  userObjects: _state => (Array.isArray(_state.all)
    && _state.all.reduce(
      (final, user) => ({ ...final, [user.id]: user }),
      {},
    )) || {},
  selectedUser: _state => _state.selectedUser,
  usersOptionMap: _state => (_state.all && _state.all.map(user => ({
    value: user.id,
    text: user.name,
  }))) || {},
};

const actions = {
  getAll({ commit }, clinicCode) {
    return userService.getAll({ clinicCode })
      .then(
        users => commit('setAllUsers', users),
        error => commit('setFailure', error),
      );
  },
  resetAll({ commit }) {
    commit('setAllUsers', []);
  },
  createNewUser({ commit }, user) {
    return userService.createNew(user)
      .then((response) => {
        if (response.ok) {
          commit('setUser', response.data);
        }
        return response;
      });
  },
  patchUser({ commit }, {id, ...params}) {
    return userService.patchUser(id, params)
      .then((response) => {
        if (response.ok) {
          commit('setUser', response.data);
        }
        return response;
      });
  },
  updateUser({ commit }, user) {
    return userService.updateUser(user)
      .then((response) => {
        if (response.ok) {
          commit('setUser', response.data);
        }
        return response;
      });
  },
  bulkDelete({ commit }, ids) {
    return userService.bulkDestroy(ids)
      .then(
        response => response,
        error => commit('setFailure', error),
      );
  },
  socket_userJoined({ commit }, d) {
    /* eslint-disable no-console */
    console.log('VDSOCKET JOINED', d);
    Vue.prototype.$flashStorage.flash(`${d.username} joined this page`, 'success', { timeout: 5000 })
  },
  setSelectedUser({commit},user) {
    commit('setUser',user)
  }
};

/* eslint-disable no-param-reassign */
const mutations = {
  setAllUsers(_state, users) {
    _state.all = users;
  },
  setFailure(_state, error) {
    _state.error = { error };
  },
  setUser(_state, payload) {
    _state.selectedUser = payload;
  },
  // resetSocketRoomUsers(_state) {
  //   _state.socketRoomUsers = [];
  // },
  // setSocketRoomUsers(_state, usernames) {
  //   _state.socketRoomUsers = usernames.splice(0);
  // },
  // addSocketRoomUser(_state, username) {
  //   _state.socketRoomUsers.push(username);
  // },
  // removeSocketRoomUser(_state, username) {
  //   _state.socketRoomUsers = _state.socketRoomUsers.filter(n => n !== username);
  // },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
