<template>
    <individual-corp-invoice
      :settlement="print"
      :invoice="invoice"
      :corporate="corporate"
      :patient="patient"
      :payments="payments"
      :invoiceConfigCorp="invoiceConfig.corporatePrinting"
      :printConfig="invoiceConfig.printing"
      :generalConfig="generalConfig"></individual-corp-invoice>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import IndividualCorpInvoice from '../../components/Print/IndividualCorpInvoice.vue';
import invoiceService from '../../services/invoice.service';
import corpService from '../../services/corporate.service';
import patientService from '../../services/patient.service';
import paymentService from '../../services/payment.service';

const { mapGetters } = createNamespacedHelpers('invoice');

export default {
  name: 'CorpIndividualPrint',
  components: {
    IndividualCorpInvoice,
  },
  data() {
    return {
      invoice: {},
      corporate: {},
      patient: {},
      payments: [],
    };
  },
  computed: {
    ...mapGetters(['print']),
    generalConfig() {
      return this.$store.state.config.general;
    },
    invoiceConfig() {
      return this.$store.state.config.invoice;
    },
  },
  created() {
    console.log('This print @@ ', this.print);
    invoiceService.getById(this.print.invoiceId)
      .then((response) => {
        this.invoice = response.data;
      });
    corpService.getById(this.print.corporate)
      .then((response) => {
        this.corporate = response.data;
      });
    patientService.getPatient(this.print.patientId).then((resp) => {
      this.patient = resp.data;
      console.log(resp.data, 'resp.data');
    });
    paymentService.list({ patient: this.print.patientId }).then((resp) => {
      this.payments = resp.data;
    });
  },
};
</script>
