var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container mb-5" }, [
    _c("div", { staticClass: "row mb-3" }, [
      _c("div", { staticClass: "col-10" }, [
        _c("img", {
          staticStyle: { width: "300px", "margin-left": "0" },
          attrs: { src: _vm.generalConfig.logoURL }
        })
      ]),
      _c("div", { staticClass: "col-2" }, [
        _c("p", { staticClass: "mb-1 mt-3 address-font" }, [
          _vm._v(
            "\n            Credit Note No: #" +
              _vm._s(_vm.creditNote.refNo) +
              "\n        "
          )
        ]),
        _c("p", { staticClass: "mb-1 address-font" }, [
          _vm._v(
            "\n            Date: " +
              _vm._s(_vm._f("ddMonthYYYYFromX")(_vm.creditNote.date)) +
              "\n        "
          )
        ])
      ])
    ]),
    _c("div", { staticClass: "row mb-3" }, [
      _c("div", { staticClass: "col-6" }, [
        _c("h5", [_vm._v(_vm._s(_vm.generalConfig.clinicName))]),
        _c("p", { staticClass: "mb-1 address-font" }, [
          _vm._v("\n        " + _vm._s(_vm.creditNoteAddress) + "\n      ")
        ]),
        _vm.generalConfig.telephone
          ? _c("p", { staticClass: "mb-3 address-font" }, [
              _vm._v("\n        Tel: " + _vm._s(_vm.generalConfig.telephone))
            ])
          : _vm._e()
      ])
    ]),
    _c("div", { staticClass: "row mb-3" }, [
      _c("div", { staticClass: "col-8" }),
      _c("div", { staticClass: "col-4" }, [
        _c("h5", [_vm._v("Reference Details:")]),
        _c("p", { staticClass: "mb-1 address-font" }, [
          _vm._v(
            "\n          Invoice No: " +
              _vm._s(_vm.creditNote.invoice.invoiceNo) +
              "\n        "
          )
        ]),
        _c("p", { staticClass: "mb -1 address-font" }, [
          _vm._v(
            "\n          Invoice Date: " +
              _vm._s(_vm._f("ddMonthYYYYFromX")(_vm.creditNote.invoice.date)) +
              "\n        "
          )
        ])
      ])
    ]),
    _c("div", { staticClass: "row mb-5" }, [
      _c("div", { staticClass: "col" }, [
        _c("p", { staticClass: "mb-1 address-font" }, [
          _vm._v(
            "\n          Patient Name: " +
              _vm._s(_vm.patient.name) +
              "\n        "
          )
        ]),
        _c("p", { staticClass: "mb-1 address-font" }, [
          _vm._v(
            "\n          Patient NRIC/Passport: " +
              _vm._s(_vm.patient.nric) +
              "\n        "
          )
        ]),
        _c("p", { staticClass: "mb-1 address-font" }, [
          _vm._v(
            "\n          Patient Address: " +
              _vm._s(_vm.patient.address) +
              "\n        "
          )
        ])
      ])
    ]),
    _c("div", { staticClass: "row mb-5" }, [
      _c("div", { staticClass: "col" }, [
        _c("h5", [_vm._v("Credit Note Details:")]),
        _c("p", { staticClass: "mb-1 address-font" }, [
          _vm._v(
            "\n          Reason for Credit Note: " +
              _vm._s(_vm.creditNote.remarks) +
              "\n        "
          )
        ]),
        _c("p", { staticClass: "mb-1 address-font" }, [
          _vm._v(
            "\n          Credit Mode: " +
              _vm._s(_vm.creditNote.mode) +
              "\n          "
          ),
          _vm.isRefund
            ? _c("span", [
                _vm._v(
                  "\n          (By " + _vm._s(_vm.creditNote.paymentType) + ")"
                )
              ])
            : _vm._e()
        ])
      ])
    ]),
    _c("div", { staticClass: "row mb-3" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("table", { staticClass: "table" }, [
          _c("thead", [
            _c("tr", [
              _c("td", { staticClass: "font-weight-bold" }, [
                _vm._v("Item Name")
              ]),
              _c("td", { staticClass: "font-weight-bold" }, [_vm._v("Qty")]),
              _c("td", { staticClass: "font-weight-bold" }, [_vm._v("Amount")]),
              _vm.creditNote.invoice.includeGST
                ? _c("td", { staticClass: "font-weight-bold" }, [_vm._v("Tax")])
                : _vm._e(),
              _c("td", { staticClass: "font-weight-bold" }, [_vm._v("Total")])
            ])
          ]),
          _c(
            "tbody",
            _vm._l(_vm.creditNote.creditReturns, function(crReturn) {
              return _c("tr", { key: crReturn._id }, [
                _c("td", [_vm._v(_vm._s(crReturn.returning.name))]),
                _c("td", [
                  _vm._v(_vm._s(crReturn.qty) + _vm._s(crReturn.returning.unit))
                ]),
                _c("td", [
                  _vm._v(
                    _vm._s(_vm.region.currency) +
                      _vm._s(
                        _vm._f("thousandSeparator")(
                          _vm._f("to2Decimal")(
                            crReturn.amount,
                            _vm.region.decimal
                          ),
                          _vm.region.thousandSeparator
                        )
                      )
                  )
                ]),
                _vm.creditNote.invoice.includeGST
                  ? _c("td", [
                      _vm._v(
                        _vm._s(_vm.region.currency) +
                          _vm._s(
                            _vm._f("thousandSeparator")(
                              _vm._f("to2Decimal")(
                                crReturn.tax,
                                _vm.region.decimal
                              ),
                              _vm.region.thousandSeparator
                            )
                          )
                      )
                    ])
                  : _vm._e(),
                _c("td", [
                  _vm._v(
                    _vm._s(_vm.region.currency) +
                      _vm._s(
                        _vm._f("thousandSeparator")(
                          _vm._f("to2Decimal")(
                            crReturn.total,
                            _vm.region.decimal
                          ),
                          _vm.region.thousandSeparator
                        )
                      )
                  )
                ])
              ])
            }),
            0
          ),
          _c("tfoot", [
            _c("tr", [
              _c("td", { attrs: { colspan: _vm.colspan } }, [_vm._v(" ")]),
              _c("td", { staticClass: "font-weight-bold" }, [
                _vm._v(
                  "\n                        Subtotal\n                    "
                )
              ]),
              _c("td", [
                _vm._v(
                  _vm._s(_vm.region.currency) +
                    _vm._s(
                      _vm._f("thousandSeparator")(
                        _vm._f("to2Decimal")(_vm.subTotal, _vm.region.decimal),
                        _vm.region.thousandSeparator
                      )
                    )
                )
              ])
            ]),
            _vm.creditNote.invoice.includeGST
              ? _c("tr", [
                  _c("td", { attrs: { colspan: _vm.colspan } }, [_vm._v(" ")]),
                  _c("td", { staticClass: "font-weight-bold" }, [
                    _vm._v(
                      "\n                        Tax\n                    "
                    )
                  ]),
                  _c("td", [
                    _vm._v(
                      _vm._s(_vm.region.currency) +
                        _vm._s(
                          _vm._f("thousandSeparator")(
                            _vm._f("to2Decimal")(
                              _vm.totalTax,
                              _vm.region.decimal
                            ),
                            _vm.region.thousandSeparator
                          )
                        )
                    )
                  ])
                ])
              : _vm._e(),
            _c("tr", [
              _c("td", { attrs: { colspan: _vm.colspan } }, [_vm._v(" ")]),
              _c("td", { staticClass: "font-weight-bold" }, [
                _vm._v(
                  "\n                        Invoice Total\n                    "
                )
              ]),
              _c("td", [
                _vm._v(
                  _vm._s(_vm.region.currency) +
                    _vm._s(
                      _vm._f("thousandSeparator")(
                        _vm._f("to2Decimal")(_vm.total, _vm.region.decimal),
                        _vm.region.thousandSeparator
                      )
                    )
                )
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }