var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "queue-print-section" } }, [
    _vm._m(0),
    _c("div", [_c("p", [_vm._v("挂号编号:" + _vm._s(_vm.visitId))])]),
    _c("hr"),
    _c("div", { staticClass: "detail__main" }, [
      _c("p", [_vm._v("挂号证:" + _vm._s(_vm.patientId))]),
      _c("p", [_vm._v("病患姓名:" + _vm._s(_vm.patientName))])
    ]),
    _c("br"),
    _c("div", { staticClass: "detail__patient" }, [
      _c("p", [_vm._v("身份别:" + _vm._s(_vm.patientType))]),
      _c("p", [_vm._v("复诊:" + _vm._s(_vm.isFollowUp ? "是" : "否"))])
    ]),
    _c("div", { staticClass: "detail__queue" }, [
      _c("p", [_vm._v("诊间:" + _vm._s(_vm.roomName))]),
      _c("p", [_vm._v("候诊号:" + _vm._s(_vm.queueNumber))]),
      _c("p", [_vm._v("挂号员:" + _vm._s(_vm.registerName))])
    ]),
    _c("hr"),
    _c("div", { staticClass: "detail__date" }, [
      _c("p", [
        _vm._v(
          "挂号日期:" + _vm._s(_vm._f("dateOnlyFormat")(_vm.registeredDateTime))
        )
      ]),
      _c("p", [
        _vm._v(
          "挂号时间:" + _vm._s(_vm._f("timeOnlyFormat")(_vm.registeredDateTime))
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("h4", [_vm._v("总部")]), _c("p", [_vm._v("挂号证")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }