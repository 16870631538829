
export default {
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    selectedClinic() {
      return this.$store.state.clinic.selectedClinic;
    },
    providers() {
      return this.$store.state.provider.all;
    },
    providersMappedById() {
      return this.$store.state.provider.providersMappedById;
    },
    providersMappedByUserId() {
      return this.$store.state.provider.providersMappedByUserId;
    },
    roles() {
      return this.$store.state.acl.roles;
    },
    patients() {
      return this.$store.state.patient.all;
    },
    rooms() {
      return this.$store.state.room.all;
    },
    services() {
      return this.$store.state.medservice.all;
    },
    clinics() {
      return this.$store.state.clinic.all;
    },
    corporates() {
      return this.$store.state.corporate.all;
    },
    clinicsById() {
      const output = {};
      this.$store.state.clinic.all.forEach((c) => {
        output[c._id] = c;
      });
      return output;
    },
    allClinics() {
      return [{ _id: 'all', name: 'All' }, ...this.clinics];
    },
    users() {
      return this.$store.state.users.all || [];
    },
    queue() {
      return this.$store.state.queue.all.items;
    },
    notifications() {
      return this.$store.state.notifications
        .map((d, i) => ({
          idx: i,
          ...d,
        }))
        .filter(
          d => d.read === false
            && d.type === 'notify'
            && d.userId !== this.user.id, // not sent by me
        );
      // .slice()
      // .sort((a, b) => {
      //   a = a['time'];
      //   b = b['time'];
      //   const order = -1;
      //   /* eslint-disable no-nested-ternary */
      //   return (a === b ? 0 : a > b ? 1 : -1) * order;
      // })
      // .slice(0, 5)
    },
    region() {
      return this.$store.state.config.general.region || {};
    },
    taxConfig() {
      return {
        taxName: this.$store.state.config.invoice.taxName,
        taxPercentageAmount: this.$store.state.config.invoice.taxPercentageAmount || 0,
      };
    },
    // vue-good-table standard options with i18n
    vgtPaginationOption() {
      return {
        enabled: true,
        perPage: 10,
        perPageDropdown: [10, 30, 50, 100],
        dropdownAllowAll: false,
        rowsPerPageLabel: this.$t('general.rowsPerPage'),
        prevLabel: this.$t('general.prevPage'),
        nextLabel: this.$t('general.nextPage'),
      };
    },
    vgtSearchOption() {
      return {
        enabled: true,
        skipDiacritics: true,
        placeholder: this.$t('general.searchTable'),
      };
    },
    vgtSearchInventoryOption() {
      return {
        enabled: true,
        skipDiacritics: true,
        placeholder: this.$t('general.searchInventory'),
      };
    },
    bootswatchTheme() {
      if (window.location.hostname === process.env.VUE_APP_KONSULDOK_HOSTNAME) {
        return 'konsuldok';
      }
      return '';
    },
    negInvStock() {
      return this.$store.state.config.inventory.enableNegInvStock;
    },
  },
};
