var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("individual-corp-invoice", {
    attrs: {
      settlement: _vm.print,
      invoice: _vm.invoice,
      corporate: _vm.corporate,
      patient: _vm.patient,
      payments: _vm.payments,
      invoiceConfigCorp: _vm.invoiceConfig.corporatePrinting,
      printConfig: _vm.invoiceConfig.printing,
      generalConfig: _vm.generalConfig
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }