var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("purchase-order-print", {
        attrs: {
          generalConfig: _vm.general,
          invoiceConfig: _vm.invoice,
          inventoryConfig: _vm.inventory,
          print: _vm.print
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }