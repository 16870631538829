var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasAccessToAtLeastOneDropdown
    ? _c(
        "b-nav-item-dropdown",
        {
          class: _vm.className,
          attrs: { id: _vm.idName, text: _vm.text, left: "" }
        },
        [
          _vm._l(_vm.accessibleDropdown, function(item, index) {
            return [
              typeof item == "string"
                ? _c("b-dropdown-divider", { key: index })
                : _c(
                    "b-dropdown-item",
                    {
                      key: index,
                      attrs: {
                        to: item.to,
                        "data-automation": item["data-automation"]
                      }
                    },
                    [_vm._v("\n      " + _vm._s(item.label) + "\n    ")]
                  )
            ]
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }