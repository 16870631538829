import ipdTaskService from '@/services/ipdtask.service';

import {
  SET_PATIENT_IPDTASKS,
  SET_PATIENT_IPDTASK,
} from './type.mutation';

const state = {
  patientIPDTasks: {},
};

/* eslint-disable no-param-reassign */
const mutations = {
  [SET_PATIENT_IPDTASKS](_state, taskList) {
    _state.patientIPDTasks = taskList
      .reduce((acc, task) => ({
        ...acc,
        [task._id]: task,
      }), {});
  },
  [SET_PATIENT_IPDTASK](_state, todo) {
    _state.patientIPDTasks = {
      ..._state.patientIPDTasks,
      [todo._id]: todo,
    };
  },
  // SOCKET_PATIENT_IPDTASKNEW(_state, todo) {
  //   _state.patientIPDTasks = {
  //     ..._state.patientIPDTasks,
  //     [todo._id]: todo,
  //   };
  // },
  // SOCKET_PATIENT_IPDTASKEDIT(_state, todo) {
  //   _state.patientIPDTasks = {
  //     ..._state.patientIPDTasks,
  //     [todo._id]: todo,
  //   };
  // },
};

const actions = {
  socket_patientIpdtaskedit({ dispatch, commit }, payload) {
    commit(SET_PATIENT_IPDTASK, payload);
    dispatch('resortIPDTasks');
  },
  socket_patientIpdtasknew({ dispatch, commit }, payload) {
    commit(SET_PATIENT_IPDTASK, payload);
    dispatch('resortIPDTasks');
  },
  resortIPDTasks({ commit, state: _state }) {
    const ipdtasks = Object.values(_state.patientIPDTasks)
      .sort((a, b) => (`${a.date} ${a.time}`).localeCompare(`${b.date} ${b.time}`));
    commit(SET_PATIENT_IPDTASKS, ipdtasks);
  },
  listAdmissionIPDTasks({ commit }, {
    patient, admission, clinicCode, ismedex,
  }) {
    if(admission === null){
      return commit(SET_PATIENT_IPDTASKS, []);
    }
      return ipdTaskService.list({
        patient, admission, clinicCode, ismedex,
      }).then(
        (response) => {
          console.log('Tasks >>>> ', response.data);
          const tasks = response.data;
          commit(SET_PATIENT_IPDTASKS, tasks);
          return tasks;
        },
      ).catch((error) => {
        console.log('Error in fetching tasks ', error);
        commit(SET_PATIENT_IPDTASKS, []);
        throw error;
      });
  },
};

const getters = {
  patientIPDTasks: _state => _state.patientIPDTasks,
  pendingIPDTasks: _state => Object.keys(_state.patientIPDTasks)
    .map(key => _state.patientIPDTasks[key])
    .filter(task => task.status === 'PENDING')
    .reduce((acc, task) => ({
      ...acc,
      [task._id]: task,
    }), {}),
  completedIPDTasks: _state => Object.keys(_state.patientIPDTasks)
    .map(key => _state.patientIPDTasks[key])
    .filter(task => task.status === 'COMPLETED')
    .reduce((acc, task) => ({
      ...acc,
      [task._id]: task,
    }), {}),
  canceledIPDTasks: _state => Object.keys(_state.patientIPDTasks)
    .map(key => _state.patientIPDTasks[key])
    .filter(task => task.status === 'CANCELED')
    .reduce((acc, task) => ({
      ...acc,
      [task._id]: task,
    }), {}),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
