export const SET_PREPARATION = 'SET_PREPARATION';
export const SET_VITAL = 'SET_VITAL';
export const ADD_MEDICINES = 'ADD_MEDICINES';
export const REMOVE_MEDICINE = 'REMOVE_MEDICINE';
export const UPDATE_MEDICINE = 'UPDATE_MEDICINE';
export const CONFIRM_MEDICATION = 'CONFIRM_MEDICATION';
export const ADMINISTERED_MEDICATION = 'ADMINISTERED_MEDICATION';
export const SET_MEDICATION = 'SET_MEDICATION';
export const ADD_EVENTS = 'ADD_EVENTS';
export const SET_EVENTS = 'SET_EVENTS';
export const FINALISE_DISCHARGE = 'FINALISE_DISCHARGE';
export const SET_DISCHARGE = 'SET_DISCHARGE';
export const ADD_VITAL_TIME = 'ADD_VITAL_TIME';
export const SET_VITAL_TIME = 'SET_VITAL_TIME';
export const ADD_VITAL_LOG = 'ADD_VITAL_LOG';
export const SET_VITAL_LOG = 'SET_VITAL_LOG';
export const ADD_NEW_VITAL = 'ADD_NEW_VITAL';
export const SET_VITAL_VALUE = 'SET_VITAL_VALUE';
export const FETCH_ANAESTHESIA_NOTE = 'FETCH_ANAESTHESIA_NOTE';
export const SET_CHARTED_VITALS = 'SET_CHARTED_VITALS';
