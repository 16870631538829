var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row", style: { height: _vm.medicalLabelHeight + "px" } },
    [
      _c("div", { staticClass: "col-6" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h5", [_vm._v(_vm._s(_vm.clinicName))]),
            _c("p", { staticClass: "mb-1 border-bottom label-address-font" }, [
              _vm._v(_vm._s(_vm.clinicAddress))
            ]),
            _vm.clinicNumber
              ? _c("p", { staticClass: "clinic-tel" }, [
                  _vm._v("Tel : " + _vm._s(_vm.clinicNumber))
                ])
              : _vm._e(),
            _c("h5", { staticClass: "mb-1" }, [
              _vm._v(_vm._s(_vm.medName) + "\n          "),
              _vm.medQty || _vm.medUnit
                ? _c("span", [
                    _vm._v(
                      " - " + _vm._s(_vm.medQty) + " " + _vm._s(_vm.medUnit)
                    )
                  ])
                : _vm._e(),
              _vm.description && _vm.featureFlags.tcmMode
                ? _c("span", [_vm._v("(" + _vm._s(_vm.description) + ")")])
                : _vm._e()
            ])
          ])
        ]),
        _c("div", { staticClass: "row mb-1" }, [
          _c("h6", {
            staticClass: "col-7",
            domProps: { innerHTML: _vm._s(_vm.medDosage) }
          }),
          !_vm.featureFlags.tphInvoice && _vm.medExpiry
            ? _c("h6", { staticClass: "col-5 text-right" }, [
                _vm._v(
                  "EXPIRY  :  " +
                    _vm._s(_vm._f("dateOnlyFormat")(_vm.medExpiry))
                )
              ])
            : _vm._e()
        ]),
        _c(
          "div",
          { staticClass: "row mb-1" },
          [
            _c("p", { staticClass: "precaution-font2 col-12" }, [
              _vm._v(_vm._s(_vm.medPrecautionOne))
            ]),
            _c("p", { staticClass: "precaution-font2 col-12" }, [
              _vm._v(_vm._s(_vm.medPrecautionTwo))
            ]),
            _vm.featureFlags.tphInvoice && _vm.withSelectedBatch
              ? [
                  _c(
                    "p",
                    { staticClass: "text-right precaution-font2 col-12" },
                    [
                      _c("strong", [_vm._v("Batch Number:")]),
                      _vm._v(
                        " " + _vm._s(_vm.selectedBatch.number) + "\n        "
                      )
                    ]
                  ),
                  _c(
                    "p",
                    { staticClass: "text-right precaution-font2 col-12" },
                    [
                      _c("strong", [_vm._v("Expiry Date:")]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.getExpiry(_vm.selectedBatch.expiry) || ""
                          ) +
                          "\n        "
                      )
                    ]
                  )
                ]
              : _vm._e(),
            _c("p", { staticClass: "precaution-font col-12" }, [
              _vm._v(_vm._s(_vm.medStrength))
            ])
          ],
          2
        ),
        _c("div", { staticClass: "bottom-div row weight-600" }, [
          _c("div", { staticClass: "col-6" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.patientName) +
                " / " +
                _vm._s(_vm.patientNric) +
                "\n      "
            )
          ]),
          _c("div", { staticClass: "col-6 text-right" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm._f("ddMonthYYYY")(new Date())) +
                "\n      "
            )
          ]),
          _c("div", { staticClass: "col-6" }, [
            _vm._v(
              "\n        DOB: " +
                _vm._s(_vm._f("ddMonthYYYY")(_vm.patientDetails.dateOfBirth)) +
                "\n      "
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }