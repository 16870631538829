import medServiceService from '@/services/medservice.service';

import {
  SET_ALL_MEDSERVICES,
  SET_MEDSERVICE,
} from './type.mutation';

const state = {
  all: [],
  selectedMedService: null,
};

/* eslint-disable no-param-reassign */
const mutations = {
  [SET_ALL_MEDSERVICES](_state, payload) {
    _state.all = payload;
  },
  [SET_MEDSERVICE](_state, payload) {
    _state.selectedMedService = payload ? { ...payload } : null;
  },
};

const actions = {
  listMedServices({ commit }, param = {}) {
    return medServiceService.list(param)
      .then((response) => {
        commit(SET_ALL_MEDSERVICES, response.data);
      });
  },
  resetAll({ commit }) {
    commit(SET_ALL_MEDSERVICES, []);
  },
  setSelectedMedService({ commit }, payload) {
    commit(SET_MEDSERVICE, payload);
  },
  unsetSelectedMedService({ commit }) {
    commit(SET_MEDSERVICE, null);
  },
  createNewMedService({ commit }, payload) {
    commit('setLoading', true, { root: true });
    return medServiceService.create(payload)
      .then((response) => {
        if (response.success) {
          commit(SET_MEDSERVICE, response.data);
        }
        commit('setLoading', false, { root: true });
        return response;
      });
  },
  createNewMedServiceWithImage({ commit }, { data, image }) {
    commit('setLoading', true, { root: true });
    return medServiceService.createWithImage(data, image)
      .then((response) => {
        if (response.success) {
          commit(SET_MEDSERVICE, response.data);
        }
        commit('setLoading', false, { root: true });
        return response;
      });
  },
  updateMedService({ commit }, payload) {
    commit('setLoading', true, { root: true });
    return medServiceService.update(payload._id, payload)
      .then((response) => {
        if (response.success) {
          commit(SET_MEDSERVICE, response.data);
        }
        commit('setLoading', false, { root: true });
        return response;
      });
  },
  updateMedServiceWithImage({ commit }, { data, image }) {
    commit('setLoading', true, { root: true });
    return medServiceService.updateWithImage(data._id, data, image)
      .then((response) => {
        if (response.success) {
          commit(SET_MEDSERVICE, response.data);
        }
        commit('setLoading', false, { root: true });
        return response;
      });
  },
  destroyMedService({ commit }, payload) {
    commit('setLoading', true, { root: true });
    return medServiceService.destroy(payload._id)
      .then((response) => {
        if (response.success) {
          commit(SET_MEDSERVICE, null);
        }
        commit('setLoading', false, { root: true });
        return response;
      });
  },
};

const getters = {
  all: _state => _state.all,
  serviceOptionMap: _state => _state.all.map(medservice => ({
    value: medservice._id,
    text: medservice.name,
  })),
  selectedMedService: _state => _state.selectedMedService,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
