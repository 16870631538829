var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "queue-print-section" } }, [
    _c("p", [_vm._v("Welcome, you are")]),
    _c("p", [_vm._v("currently in the queue.")]),
    _c("h3", [_vm._v("Ticket Number:")]),
    _c("h1", { attrs: { id: "queue-number" } }, [
      _vm._v(
        " " +
          _vm._s(
            _vm.QUEUE_CALLNUMBER_PREFIX[_vm.queueObj.call_number_status] +
              " " +
              _vm.queueObj.call_number
          ) +
          " "
      )
    ]),
    _c("p", [_vm._v("Your number will be called shortly,")]),
    _c("p", [_vm._v("thank you for waiting")])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }