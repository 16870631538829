<template>
  <a class="label-button p-hide" @click="setAllCheckFlag" tabIndex="-1" role="button"
    v-if="!printing" v-show="!viewing">
    ({{ checked ? $t('forms.unsetAll'): (item.label || $t('forms.setAllAsNil'))}})
  </a>
</template>
<script>
/*
Spec:
"q1":{
  "label":"Set All as Nil",
  "type":"masscheck",
  "colSize": "col-sm-1",
  "affectedObj":["q2","q3"]
}
 */
import _ from 'lodash';

export default {
  name: 'MassCheck',
  data() {
    return {
      checked: false,
    };
  },
  props: {
    item: {
      type: Object,
      default: function fn() {},
    },
    formData: {
      type: Object,
      default: function fn() {},
    },
    setMassCheckFlag: {
      type: Function,
      default: function fn(/* answer, qns */) {},
    },
    viewing: {
      type: Boolean,
      default: false,
    },
    printing: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    setAllCheckFlag() {
      const newFlag = !this.checked;
      const { affectedObj } = this.item;
      this.checked = !this.checked;
      this.$emit('setMassCheckFlag', affectedObj, newFlag);
    },
  },
  mounted() {
    const { affectedObj } = this.item;
    this.checked = affectedObj.every((qnsIdx) => {
      const qnsItem = this.formData[qnsIdx];
      if (!qnsItem) return false;
      const { answer, options } = qnsItem;
      if (!answer || !options || !Array.isArray(answer) || !Array.isArray(options)) return false;
      const allAnswers = options.map(o => o.value);
      return _.isEqual(answer.sort(), allAnswers.sort());
    });
  },
};
</script>
<style lang="scss" scoped>
a.label-button {
  text-decoration: underline;
  color: #007bff;
  cursor: pointer;
  &:visited {
    color: grey;
  }
}
</style>
