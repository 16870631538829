var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal" } }, [
    _c("div", { staticClass: "modal-mask", class: { fading: _vm.fadingBg } }, [
      _c("div", { staticClass: "modal-wrapper" }, [
        _c("div", { staticClass: "modal-container" }, [
          _c(
            "div",
            { staticClass: "modal-body" },
            [
              _c("v-icon", {
                attrs: { name: "spinner", pulse: "", scale: "2.0" }
              }),
              _vm.htmlText.length > 0
                ? _c("p", { domProps: { innerHTML: _vm._s(_vm.htmlText) } })
                : _vm._e()
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }