/* eslint-disable no-restricted-globals,max-len,no-unused-vars */
/* eslint-disable no-param-reassign,no-restricted-syntax,prefer-destructuring */
import _ from 'lodash';
import constant from '@/services/constant';
import { v4 } from 'uuid';
import inventoryService from '@/services/inventory.service';

// import constant from '@/services/constant';
import MoneyMixin from './money';

export default {
  mixins: [MoneyMixin],
  data() {
    return {
      usagesTranslated: this.$t('dosageBuilder.usage'),
      dosesTranslated: this.$t('dosageBuilder.doses'),
      unitsTranslated: this.$t('dosageBuilder.units'),
      freqsTranslated: this.$t('dosageBuilder.freqs'),
      durationsTranslated: this.$t('dosageBuilder.durations'),
      UsagesKey: {
        'zh-cn': Object.values(this.$t('dosageBuilder.usage', 'zh-cn')),
        'zh-cn-tr': Object.values(this.$t('dosageBuilder.usage', 'zh-cn-tr')),
      },
      DosesKey: {
        'zh-cn': Object.values(this.$t('dosageBuilder.doses', 'zh-cn')),
        'zh-cn-tr': Object.values(this.$t('dosageBuilder.doses', 'zh-cn-tr')),
      },
      UnitsKey: {
        'zh-cn': Object.values(this.$t('dosageBuilder.units', 'zh-cn')),
        'zh-cn-tr': Object.values(this.$t('dosageBuilder.units', 'zh-cn-tr')),
      },
      FreqsKey: {
        'zh-cn': Object.values(this.$t('dosageBuilder.freqs', 'zh-cn')),
        'zh-cn-tr': Object.values(this.$t('dosageBuilder.freqs', 'zh-cn-tr')),
      },
      DurationsKey: {
        'zh-cn': Object.values(this.$t('dosageBuilder.durations', 'zh-cn')),
        'zh-cn-tr': Object.values(this.$t('dosageBuilder.durations', 'zh-cn-tr')),
      },
      Usages: {
        en: this.$t('dosageBuilder.usage'),
        'zh-cn': this.$t('dosageBuilder.usage', 'zh-cn'),
        'zh-cn-tr': this.$t('dosageBuilder.usage', 'zh-cn-tr'),
      },
      Doses: {
        en: this.$t('dosageBuilder.doses'),
        'zh-cn': this.$t('dosageBuilder.doses', 'zh-cn'),
        'zh-cn-tr': this.$t('dosageBuilder.doses', 'zh-cn-tr'),
      },
      Units: {
        en: this.$t('dosageBuilder.units'),
        'zh-cn': this.$t('dosageBuilder.units', 'zh-cn'),
        'zh-cn-tr': this.$t('dosageBuilder.units', 'zh-cn-tr'),
      },
      Freqs: {
        en: this.$t('dosageBuilder.freqs'),
        'zh-cn': this.$t('dosageBuilder.freqs', 'zh-cn'),
        'zh-cn-tr': this.$t('dosageBuilder.freqs', 'zh-cn-tr'),
      },
      Durations: {
        en: this.$t('dosageBuilder.durations'),
        'zh-cn': this.$t('dosageBuilder.durations', 'zh-cn'),
        'zh-cn-tr': this.$t('dosageBuilder.durations', 'zh-cn-tr'),
      },
    };
  },
  computed: {
    isDrugApprovalEnabled() {
      return this.$store.state.config.featureFlags.durgClassificationApproval;
    },
    requestApprovalConfig() {
      return this.invoiceConfig.requestApproval;
    },
    hasClassifiedMedicineDispense() {
      const classifiedMedicines = this.invoice.items
        .filter(i => i.drugClassification === constant.DRUG_CLASSIFICATION.CLASSIFIED);
      return !!classifiedMedicines.length;
    },
    isConsultation() {
      if (!this.invoice.queue) return false;
      if (!this.invoice.queue.service) return false;
      return this.invoice.queue.service.name === 'Doctor Consultation Only';
    },
    isTreatment() {
      if (!this.invoice.queue) return false;
      if (!this.invoice.queue.service) return false;
      return this.invoice.queue.service.name === 'Treatment';
    },
    isConsultationAndTreatment() {
      if (!this.invoice.queue) return false;
      if (!this.invoice.queue.service) return false;
      return this.invoice.queue.service.name === 'Doctor > Treatment'
        || this.invoice.queue.service.name === 'Treatment > Doctor';
    },
    isBuyProduct() {
      return !this.isConsultation && !this.isTreatment && !this.isConsultationAndTreatment;
    },
    isDispensedByDoctor() {
      const dispenseUserId = this.invoice.dispensedBy || '';
      const provider = this.providersMappedByUserId[dispenseUserId];
      if (!provider) return false;
      return provider.role === 'provider';
    },
    isDispensedByTherapist() {
      const dispenseUserId = this.invoice.dispensedBy || '';
      const provider = this.providersMappedByUserId[dispenseUserId];
      if (!provider) return false;
      return provider.role === 'therapist';
    },
    isProvider() {
      const provider = this.providersMappedByUserId[this.user.id];
      if (!provider) return false;
      return provider.role === 'provider';
    },
    isTherapist() {
      const provider = this.providersMappedByUserId[this.user.id];
      if (!provider) return false;
      return provider.role === 'therapist';
    },
    isChangeMadeByProviderTherapist() {
      return (this.isConsultation && this.isProvider)
        || (this.isTreatment && this.isProvider)
        || (this.isConsultationAndTreatment
          && (this.isProvider || this.isTherapist))
        || (this.isBuyProduct && this.isProvider);
    },
    // this invoice revision will be used to compare with any recent changes
    // made to the invoice in order to determine request for approval
    originalInvoiceRevision() {
      return this.invoice.latestHistoryByProvider;
    },
    hasDispenseVersionChange() {
      return this.originalInvoiceRevision.dispenseItemRevision
        !== this.invoice.dispenseItemRevision
        || this.hasNormalItemRemovedByUserAndRequiredApproval
        || this.hasNormalItemDispenseByUserAndRequiredApproval;
    },
    hasClassifiedVersionChange() {
      return this.originalInvoiceRevision.classifiedItemRevision
        !== this.invoice.classifiedItemRevision
        || this.hasClassifiedItemRemovedByUserAndRequiredApproval
        || this.hasClassifiedItemDispenseByUserAndRequiredApproval;
    },
    hasApprovalChanges() {
      return this.hasDispenseVersionChange || this.hasClassifiedVersionChange;
    },
    hasNormalItemDispenseByUserAndRequiredApproval() {
      const normalItems = this.invoice.items
        .filter(i => i.drugClassification !== constant.DRUG_CLASSIFICATION.CLASSIFIED
            && this.requestApprovalConfig.normal.dispense
            && i.dispenseUserRole === 'user');
      return !!normalItems.length && !this.isChangeMadeByProviderTherapist;
    },
    hasClassifiedItemDispenseByUserAndRequiredApproval() {
      const normalItems = this.invoice.items
        .filter(i => i.drugClassification === constant.DRUG_CLASSIFICATION.CLASSIFIED
            && this.requestApprovalConfig.classified.dispense
            && i.dispenseUserRole === 'user');
      return !!normalItems.length && !this.isChangeMadeByProviderTherapist;
    },
    hasNormalItemRemovedByUserAndRequiredApproval() {
      const removedItems = this.originalInvoiceRevision.currentDispenseItems
        .filter((i) => {
          const stillExists = this.invoice.items.some(itm => itm.uuid === i.uuid);
          console.log('has removed ', !stillExists, i.name, !stillExists && i.drugClassification !== constant.DRUG_CLASSIFICATION.CLASSIFIED);
          return !stillExists && i.drugClassification !== constant.DRUG_CLASSIFICATION.CLASSIFIED;
        });
      console.log('nromal remove items ', removedItems.length);
      return this.requestApprovalConfig.normal.remove && !!removedItems.length && !this.isChangeMadeByProviderTherapist;
    },
    hasClassifiedItemRemovedByUserAndRequiredApproval() {
      const removedItems = this.originalInvoiceRevision.currentDispenseItems
        .filter((i) => {
          const stillExists = this.invoice.items.some(itm => itm.uuid === i.uuid);
          console.log('has removed classified', !stillExists, i.name, !stillExists && i.drugClassification === constant.DRUG_CLASSIFICATION.CLASSIFIED);
          return !stillExists && i.drugClassification === constant.DRUG_CLASSIFICATION.CLASSIFIED;
        });
      console.log('classified remove items ', removedItems.length);
      return this.requestApprovalConfig.classified.remove && !!removedItems.length && !this.isChangeMadeByProviderTherapist;
    },
    lockInvoiceForApproval() {
      if (this.isChangeMadeByProviderTherapist) return false;
      if (this.isDrugApprovalEnabled
        && this.drugApprovalSubmitted) {
        if (this.drugApproved) return false;
        if (this.drugRejected) return false;
        return true;
      }
      return false;
      // return this.isDrugApprovalEnabled
      //   && this.drugApprovalSubmitted && (!this.drugApproved || !this.drugRejected); // TODO: to check for rejected
    },
    INVENTORY_TYPES() {
      let invTypes = constant.IMPORT_INVENTORY_TYPES;
      if (this.featureFlags.isMaKuang) {
        invTypes = { ...invTypes, ...constant.IMPORT_INVENTORY_TYPES_MAKUANG };
      }
      return Object.values(invTypes)
        .filter((inv) => {
          switch (inv) {
            // case 'Teleconsult':
            //   return this.featureFlags.teleconsult;
            // OTHERS can put featureFlag enabled filters here
            default:
              return true;
          }
        });
    },
    restrictedMedicines() {
      const childFns = localStorage.getItem('acl__childrenFnIds');
      if (!childFns) {
        return [];
      }
      const aclInventories = JSON.parse(childFns);
      // console.log("aclInventories ", aclInventories);
      const dispensableMedicines = Object.keys(constant.DRUG_CLASSIFICATION_OPTION_KH)
        .filter(invtType => Object.keys(aclInventories).indexOf(invtType) >= 0);
      // console.log("dispensableMedicines ", dispensableMedicines);
      const medicines = _.difference(
        Object.keys(constant.DRUG_CLASSIFICATION_OPTION_KH),
        dispensableMedicines,
      );
      // console.log("medicines ", medicines);
      return medicines.length ? medicines : false;
    },
  },
  methods: {
    // start of tracking updating fields
    // TODO: to cleanup by passing classfied/normal as third parameter
    changeNormalItemQtyValidForApproval(newQty, uuid) {
      const originalItem = this.originalInvoiceRevision.currentDispenseItems
        .find(i => i.uuid === uuid);
      if (!originalItem) return false;
      console.log('Compare Qty - Normal', +newQty, +originalItem.price);
      return (this.requestApprovalConfig.normal.increaseQty
        && +newQty > (+originalItem.qty || 0))
        || (this.requestApprovalConfig.normal.reduceQty
        && +newQty < (+originalItem.qty || 0));
    },
    changeNormalItemPriceValidForApproval(decimalValue, uuid) {
      if (isNaN(decimalValue)) return false;
      const originalItem = this.originalInvoiceRevision.currentDispenseItems
        .find(i => i.uuid === uuid);
      if (!originalItem) return false;
      console.log('Compare Price - Normal', +decimalValue, +originalItem.price);
      return (this.requestApprovalConfig.normal.price
        && +decimalValue !== (+originalItem.price || 0));
    },
    changeNormalItemDiscountValidForApproval(newVal, uuid) {
      const originalItem = this.originalInvoiceRevision.currentDispenseItems
        .find(i => i.uuid === uuid);
      if (!originalItem) return false;
      console.log('Compare Discount - Normal ', +newVal, +originalItem.discount);
      return (this.requestApprovalConfig.normal.discount
        && +newVal !== (+originalItem.discount || 0));
    },
    changeClassfiedItemQtyValidForApproval(newQty, uuid) {
      const originalItem = this.originalInvoiceRevision.currentDispenseItems
        .find(i => i.uuid === uuid);
      if (!originalItem) return false;
      console.log('Compare Qty - Classified', +newQty, +originalItem.price);
      return (this.requestApprovalConfig.classified.increaseQty
        && +newQty > (+originalItem.qty || 0))
        || (this.requestApprovalConfig.classified.reduceQty
        && +newQty < (+originalItem.qty || 0));
    },
    changeClassfiedItemPriceValidForApproval(decimalValue, uuid) {
      if (isNaN(decimalValue)) return false;
      const originalItem = this.originalInvoiceRevision.currentDispenseItems
        .find(i => i.uuid === uuid);
      if (!originalItem) return false;
      console.log('Compare Price - Classified', +decimalValue, +originalItem.price);
      return (this.requestApprovalConfig.classified.price
        && +decimalValue !== (+originalItem.price || 0));
    },
    changeClassfiedItemDiscountValidForApproval(newVal, uuid) {
      const originalItem = this.originalInvoiceRevision.currentDispenseItems
        .find(i => i.uuid === uuid);
      if (!originalItem) return false;
      console.log('Compare Discount - Classified ', +newVal, +originalItem.discount);
      return (this.requestApprovalConfig.classified.discount
        && +newVal !== (+originalItem.discount || 0));
    },
    // end of tracking updating fields
    compareInvoiceNormalChanges(currentItem, newVal, key, uuid) {
      const hasQtyChanged = key === 'qty' && uuid === currentItem.uuid
        ? this.changeNormalItemQtyValidForApproval(newVal, uuid)
        : this.changeNormalItemQtyValidForApproval(currentItem.qty, currentItem.uuid);
      const hasPriceChanged = key === 'price' && uuid === currentItem.uuid
        ? this.changeNormalItemPriceValidForApproval(this.currencyToDecimal(newVal), uuid)
        : this.changeNormalItemPriceValidForApproval(currentItem.price, currentItem.uuid);
      const hasDiscountChanged = key === 'discount' && uuid === currentItem.uuid
        ? this.changeNormalItemDiscountValidForApproval(newVal, uuid)
        : this.changeNormalItemDiscountValidForApproval(currentItem.discount, currentItem.uuid);
      console.log(hasQtyChanged, hasPriceChanged, hasDiscountChanged);
      return hasQtyChanged || hasPriceChanged || hasDiscountChanged;
    },
    compareInvoiceClassifiedChanges(currentItem, newVal, key, uuid) {
      const hasQtyChanged = key === 'qty' && uuid === currentItem.uuid
        ? this.changeClassfiedItemQtyValidForApproval(newVal, uuid)
        : this.changeClassfiedItemQtyValidForApproval(currentItem.qty, currentItem.uuid);
      const hasPriceChanged = key === 'price' && uuid === currentItem.uuid
        ? this.changeClassfiedItemPriceValidForApproval(this.currencyToDecimal(newVal), uuid)
        : this.changeClassfiedItemPriceValidForApproval(currentItem.price, currentItem.uuid);
      const hasDiscountChanged = key === 'discount' && uuid === currentItem.uuid
        ? this.changeClassfiedItemDiscountValidForApproval(newVal, uuid)
        : this.changeClassfiedItemDiscountValidForApproval(currentItem.discount, currentItem.uuid);
      return hasQtyChanged || hasPriceChanged || hasDiscountChanged;
    },
    compareInvoiceChanges(clonedInvoice, newVal, uuid, key, isUpdatingLineItem = false) {
      // only check if the feature flag is on
      // otherwise, it will downgrade the performance.
      if (!this.isDrugApprovalEnabled) return;
      let normalChange = false;
      let classifiedChange = false;
      // eslint-disable-next-line no-restricted-syntax
      for (const item of clonedInvoice.items) {
        // check whether item has been removed and require for approval
        if (item.drugClassification === constant.DRUG_CLASSIFICATION.CLASSIFIED) {
          // const hasRemoveItemRequiredApproval = this.requestApprovalConfig.classified.remove
          //   && !this.originalInvoiceRevision.currentDispenseItems.some(i => i.uuid === item.uuid);
          classifiedChange = classifiedChange
            // || hasRemoveItemRequiredApproval
            || this.compareInvoiceClassifiedChanges(item, newVal, key, uuid);
        } else {
          // const hasRemoveItemRequiredApproval = this.requestApprovalConfig.normal.remove
          //   && !this.originalInvoiceRevision.currentDispenseItems.some(i => i.uuid === item.uuid);
          normalChange = normalChange
            // || hasRemoveItemRequiredApproval
            || this.compareInvoiceNormalChanges(item, newVal, key, uuid);
        }
      }
      // there are changes made to normal item meets the requirements of the request for approval
      // base on system perference
      // so, assign new dispense item revision no
      if (normalChange) {
        clonedInvoice.dispenseItemRevision = v4();
        clonedInvoice.dispensedBy = this.user.id;
      } else {
        // no changes made, re-assign original version no
        clonedInvoice.dispenseItemRevision = this.originalInvoiceRevision.dispenseItemRevision;
        clonedInvoice.dispensedBy = this.originalInvoiceRevision.dispensedBy;
      }
      // there are changes made to classified item meets the requirements of the request for approval
      // base on system perference
      // so, assign new classified item revision no
      if (classifiedChange) {
        clonedInvoice.classifiedItemRevision = v4();
        clonedInvoice.dispensedBy = this.user.id;
      } else {
        // no changes made, re-assign original version no
        clonedInvoice.classifiedItemRevision = this.originalInvoiceRevision.classifiedItemRevision;
        clonedInvoice.dispensedBy = this.originalInvoiceRevision.dispensedBy;
      }
    },
    updateInstructionForLineItem(uuid, val) {
      const clonedInvoice = _.cloneDeep(this.invoice);
      clonedInvoice.items = clonedInvoice.items.map((item) => {
        const clone = Object.assign({}, item);
        if (clone.uuid === uuid) {
          clone.instruction = val.instruction;
          clone.instructionLocal = val.instructionLocal;
          clone.instructionInfo = val.instructionInfo;
        }
        return clone;
      });
      this.$store.commit('invoice/UPDATE_INVOICE', clonedInvoice);
    },
    updateDosageForLineItem(uuid, val) {
      const clonedInvoice = _.cloneDeep(this.invoice);
      clonedInvoice.items = clonedInvoice.items.map((item) => {
        let clone = Object.assign({}, item);
        if (clone.uuid === uuid) {
          clone.dosageInstr = `${val.dosage} ${item.instruction}`;
          clone = {
            ...clone,
            ...val,
          };
        }
        return clone;
      });
      this.$store.commit('invoice/UPDATE_INVOICE', clonedInvoice);
    },
    updateKeyItem(val, uuid, key, config, itemClassification = constant.DRUG_CLASSIFICATION.NORMAL, context = {}) {
      // console.log('Item Classification ', itemClassification)
      // console.log('in updateLineItem val, uuid, key are ', val, uuid, key);
      // these 'if' statements become uglier LOL
      // const isUpdatingClassified = itemClassification === constant.DRUG_CLASSIFICATION.CLASSIFIED
      let clonedInvoice = _.cloneDeep(this.invoice);
      let dispensed = val;
      if (key === 'qty') {
        context.invalid = false;
        clonedInvoice = this.selectBatch(clonedInvoice, { val: dispensed, uuid, key }, context);
        dispensed = context.dispensed || val;

        if (!clonedInvoice) return;
      }
      if (key === 'dosageInstr') {
        for (let i=0 ; i<clonedInvoice.items.length; i++) {
        // clonedInvoice.items.forEach((item) => {
          if (clonedInvoice.items[i].uuid === uuid) {
            clonedInvoice.items[i].dosageInstr = val;
          }
        // });
        }
      }
      if (key === 'dosage') {
        clonedInvoice.items.forEach((item) => {
          if (item.uuid === uuid) {
            item['dosageFlag'] = true
            item.dosageInstr = `${val} ${item.instruction}`;
          }
        });
      }
      if (key === 'instruction') {
        clonedInvoice.items.forEach((item) => {
          if (item.uuid === uuid) {
            item.dosageInstr = `${item.dosage} ${val}`;
          }
        });
      }
      if (key === 'selectedBatch') {
        clonedInvoice = this.adjustDispenseQty(clonedInvoice, { val, uuid, key });
        clonedInvoice.items.forEach((item) => {
          if (item.uuid === uuid) {
            // eslint-disable-next-line no-param-reassign
            item.selectedBatch = val;
            item.selectedBatch.batchSlctFlag = true;
          }
        });
      }
      // changes made by non-doctor/non-therapist
      // need to check the changes should require to request for approval
      if (!this.isChangeMadeByProviderTherapist) {
        this.compareInvoiceChanges(clonedInvoice, dispensed, uuid, key, true);
      }

      // end of request for approval checking
      clonedInvoice = this.calculateInvoice(clonedInvoice, { val: dispensed, uuid, key }, config);
      clonedInvoice.redeemQty = clonedInvoice.qty;
      if (key === 'selectedBatch' || key === 'qty') {
        clonedInvoice = this.useCostPriceFromSelectedBatch(clonedInvoice, { val: dispensed, uuid, key });
      }

      this.$store.commit('invoice/UPDATE_INVOICE', clonedInvoice);
      this.$store.commit('invoice/PATCH_STATS', { id: clonedInvoice._id, ostBalance: clonedInvoice.ostBalance });
    },
    updateLineItem: _.debounce(function fn(val, uuid, key, config, itemClassification = constant.DRUG_CLASSIFICATION.NORMAL) {
      this.updateKeyItem(val, uuid, key, config, itemClassification);
    }, 700),

    leanUpdateLineItem(uuid, key, val) {
      const clonedInvoice = _.cloneDeep(this.invoice);
      clonedInvoice.items = clonedInvoice.items.map((item) => {
        if (item.uuid === uuid) {
          item[key] = val;
          if (key === 'unit') {
            if (item.inventory === 'Compounded') {
              item.subInventory.forEach((sInv) => {
                sInv.purchasingUnit = val;
              });
            }
          }
        }
        return item;
      });
      this.$store.commit('invoice/UPDATE_INVOICE', clonedInvoice);
    },
    isInvoiceWithGST(invoice, { includeGST }) {
      if ('includeGST' in invoice) {
        return invoice.includeGST;
      }

      const invoiceIsDraft = invoice.invoiceNo === 'draft';
      return (invoiceIsDraft && includeGST) || (!invoiceIsDraft && invoice.includeGST);
    },
    updateInstructionsBuilder(fieldName, newValue, config) {
      // check each medicine's stock availability

      const includeGST = this.isInvoiceWithGST(this.invoice, config);
      let finalSubTotal = 0;
      let finalTotalDiscount = 0;
      let finalTotalTax = 0;
      let finalInvoiceTotal = 0;
      let haveAvailableStocksForAllMedicine = true;
      const oldDosageMultiplier = this.invoice.instructions.noOfDays * this.invoice.instructions.timesPerDay;
      let newDosageMultiplier = newValue;

      if (fieldName === 'timesPerDay') {
        newDosageMultiplier *= this.invoice.instructions.noOfDays || 1;
      } else if (fieldName === 'noOfDays') {
        newDosageMultiplier *= this.invoice.instructions.timesPerDay || 1;
      }

      const clonedInvoice = _.cloneDeep(this.invoice);

      clonedInvoice.items.forEach((i) => {
        const item = _.cloneDeep(i);
        if (item.inventory === 'Medicine' || item.inventory === 'Wyn') {
          const newTotalQty = parseFloat(item.qty) / oldDosageMultiplier * newDosageMultiplier;
          let selectedBatch = null;
          if (Array.isArray(item.batches) && item.batches.length) {
            const idx = item.batches.findIndex(b => parseFloat(b.quantity) >= parseFloat(newTotalQty));
            selectedBatch = item.batches[idx];
          }
          if (!selectedBatch) {
            // eslint-disable-next-line
            alert(this.$t('general.noAvailableBatch') + item.name);
            haveAvailableStocksForAllMedicine = false;
          }
        }
      });

      if (haveAvailableStocksForAllMedicine) {
        // if all have then move on to recalculate invoice's total and other info
        clonedInvoice.items = clonedInvoice.items.map((i) => {
          const item = _.cloneDeep(i);
          if (item.inventory === 'Medicine' || item.inventory === 'Wyn') {
            item.qty = parseFloat(item.qty) / oldDosageMultiplier * newDosageMultiplier;
            if (Array.isArray(item.batches) && item.batches.length) {
              const idx = item.batches.findIndex(b => parseFloat(b.quantity) >= parseFloat(item.qty));
              item.selectedBatch = item.batches[idx];
            }
          }
          const itemCalculated = this.calculateLineItem(item, { includeGST });
          finalSubTotal += this.computeSubTotal(itemCalculated.beforeDiscountPrice, itemCalculated.redeemAmount);
          finalTotalDiscount += this.computeTotalDiscount(itemCalculated.discountAmount);
          finalTotalTax += this.computeTotalTax(itemCalculated.taxAmount);
          finalInvoiceTotal += (itemCalculated.afterTaxPrice - itemCalculated.redeemAmountAfterTax);
          return item;
        });
        clonedInvoice.subtotal = finalSubTotal;
        clonedInvoice.totalDiscount = finalTotalDiscount;
        clonedInvoice.totalTax = includeGST ? finalTotalTax : 0;
        clonedInvoice.totalBeforeRounding = finalInvoiceTotal;
        clonedInvoice.promotionDiscount = (clonedInvoice.promotions && clonedInvoice.promotions.length > 0)
          ? this.getPromotionDiscount(
            clonedInvoice.totalBeforeRounding,
            clonedInvoice.promotions[0],
            clonedInvoice.items,
            { includeGST },
          ) : 0;
        clonedInvoice.totalBeforeRounding = (clonedInvoice.totalBeforeRounding - clonedInvoice.promotionDiscount < 0)
          ? 0
          : clonedInvoice.totalBeforeRounding - clonedInvoice.promotionDiscount;
        const [total, roundingAdjustment] = this.handleRounding(
          clonedInvoice.totalBeforeRounding,
          { roundingEnabled: clonedInvoice.roundingEnabled },
          { roundOff: config.roundOff },
        );
        clonedInvoice.roundingAdjustment = roundingAdjustment;
        clonedInvoice.total = total;
        if (!clonedInvoice.finalized) {
          clonedInvoice.ostBalance = clonedInvoice.total;
        }

        if (config.exchangeRate && !clonedInvoice.finalized) {
          // eslint-disable-next-line
          clonedInvoice.exchangeRate = config.exchangeRate;
        }
        clonedInvoice.instructions[fieldName] = newValue;
        this.$store.commit('invoice/UPDATE_INVOICE', clonedInvoice);
      }
    },
    requestedQtyCanBeDispensed(qty, batches) {
      let remaining = qty;
      if (batches.length === 0) {
        return false;
      }

      for (const batch of batches) {
        const request = remaining;
        const toDispense = request > batch.quantity ? batch.quantity : request;
        remaining -= toDispense;
      }

      return remaining === 0;
    },
    dispenseItem(event, self, isIpdOrOt = false) {
      const reorderEvent = event;
      let hasClassifiedMed = false;
      const provider = this.providersMappedByUserId[this.user.id];
      let dispensingItem = null;
      if (event.inventory === 'Bundle') {
        const array = event.subInventoryRef;
        for (let index = 0; index < array.length; index += 1) {
          const element = array[index].inventoryId;
          const item = Object.assign({}, element);
          let pointSetting = 0;
          if (!isIpdOrOt) {
            pointSetting = ((self.membership.redeemPointRatio[item.inventory] || {}).inventories || []).find((i => i.inventoryId === item._id)) || {};
          }
          item.pointToRedeem = pointSetting.pointToRedeem || 0;
          item.redeemQty = item.redeemQty || item.qty;
          item.price = self.to2Decimal(item.price);
          item.priceMin = self.to2Decimal(item.priceMin || 0);
          item.qty = array[index].qty ? array[index].qty : 1;
          item.discount = item.discount ? item.discount : 0;
          if (self.$store.state.config.invoice.includeGST) { // includeGST means tax is enabled
            item.tax = self.$store.state.config.invoice.taxInventories.includes(item.inventory)
              ? self.to2Decimal(self.$store.state.config.invoice.taxPercentageAmount || 0) : 0;
          } else {
            // item.tax = self.to2Decimal(item.tax || 0);
            item.tax = 0;
          }
          item.redeemedPoint = 0;
          item.redeemable = !!pointSetting.redeemable;
          item.providers = [];
          item.therapists = [];
          item.dispensedUser = this.user.id;
          item.dispenseUserRole = provider ? provider.role : 'user';

          let isValid = true;
          if (item.inventory === 'Medicine' || item.inventory === 'Wyn'|| item.inventory === 'Consumables' || item.inventory === 'Product' || (item.inventory === 'Expendables' && item.batches)) {
            // Select the earliest batch that fulfil the dispensed qty
            item.selectedBatch = null;
            if (Array.isArray(item.batches) && item.batches.length) {
              const i = item.batches
                .sort((a, b) => a.deliveryDate.localeCompare(b.deliveryDate))
                .findIndex(b => parseFloat(b.quantity) >= parseFloat(item.qty));
              item.selectedBatch = item.batches[i];
            }
            if (!item.selectedBatch) {
              // eslint-disable-next-line
              alert(this.$t('general.noAvailableBatch') + item.name);
              isValid = false;
            } else if (item.selectedBatch && item.selectedBatch.costprice) {
              item.cost = parseFloat(item.selectedBatch.costprice);
            }
          }

          if (isValid) {
            if (item.drugClassification === constant.DRUG_CLASSIFICATION.CLASSIFIED) {
              hasClassifiedMed = true;
            }
            const autoRedeemPoint = item.pointToRedeem * item.redeemQty;
            if (!self.isManualRedeem && autoRedeemPoint <= self.totalRedeemablePoints && item.redeemable) {
              // item.pointToRedeem = autoRedeemPoint;
              item.redeemedPoint = autoRedeemPoint;
            }
            if (!isIpdOrOt) {
              self.$store.commit('invoice/ADD_LINE_ITEM', { invoiceId: self.invoice._id, item });
            }
            dispensingItem = item;
          }
        }
      } else {
        let item = Object.assign({}, event);
        let pointSetting = 0;
        if (!isIpdOrOt) {
          pointSetting = (((self.membership.redeemPointRatio && self.membership.redeemPointRatio[item.inventory]) || {}).inventories || []).find((i => i.inventoryId === item._id)) || {};
        }
        // console.log('PointSetting ', pointSetting, pointSetting.pointToRedeem)
        item.min = 0; // default min allowed 0
        item = self.applyPricingScheme(item); // Apply Pricing Scheme
        item.price = self.to2Decimal(item.price);
        item.qty = item.qty ? item.qty : 1;
        if (this.$store.state.config.featureFlags.instructionsBuilder && (item.inventory === 'Medicine' || item.inventory === 'Wyn')) {
          if (item.defaultDispenseQty) {
            item.qty = item.defaultDispenseQty * (this.timesPerDay || 2) * (this.noOfDays || 3);
          } else {
            item.qty = (this.timesPerDay || 2) * (this.noOfDays || 3) * (item.qty || 1);
          }
        } else if (item.defaultDispenseQty) {
          item.qty = item.defaultDispenseQty;
        }
        if (reorderEvent.summary) {
          item.qty = reorderEvent.qty;
        }
        item.redeemedPoint = 0;
        item.redeemQty = item.redeemQty || item.qty;
        item.discount = item.discount ? item.discount : 0;
        item.pointToRedeem = +pointSetting.pointToRedeem || 0;
        if (self.$store.state.config.invoice.includeGST) {
          item.tax = self.$store.state.config.invoice.taxInventories.includes(item.inventory)
            ? self.to2Decimal(self.$store.state.config.invoice.taxPercentageAmount || 0) : 0;
        } else {
          // item.tax = self.to2Decimal(item.tax || 0);
          item.tax = 0;
        }
        item.redeemable = !!pointSetting.redeemable;
        item.providers = [];
        item.therapists = [];
        item.dispensedUser = this.user.id;
        item.dispenseUserRole = provider ? provider.role : 'user';
        if (item.inventory === 'Medicine' || item.inventory === 'Wyn' || item.inventory === 'Consumables' || item.inventory === 'Product' || (item.inventory === 'Expendables' && item.batches)) {
          // Select the earliest batch that fulfil the dispensed qty
          item.selectedBatch = null;
          if (Array.isArray(item.batches) && item.batches.length) {
            // FIFO by delivery date
            const index = item.batches
              .sort((a, b) => a.deliveryDate.localeCompare(b.deliveryDate))
              .findIndex(b => parseFloat(b.quantity) >= parseFloat(item.qty));
            item.selectedBatch = item.batches[index];
          }
          if (!item.selectedBatch) {
            // eslint-disable-next-line
            alert(this.$t('general.noAvailableBatch') + item.name);
            return null;
          }
          // Set Cost price as cost
          if (item.selectedBatch && item.selectedBatch.costprice) {
            item.cost = parseFloat(item.selectedBatch.costprice);
          }
        }
        if (item.inventory === 'Compounded') {
          const compoundParentUuid = v4();
          item.uuid = compoundParentUuid;
          for (const sub of item.subInventory) {
            sub.compoundParentUuid = compoundParentUuid;
            sub.uuid = v4();
            const subRef = (item.subInventoryRef || []).find(s => s.inventoryId._id === sub._id);
            if (Array.isArray(subRef.inventoryId.batches) && subRef.inventoryId.batches.length) {
              const index = subRef.inventoryId.batches.findIndex(b => parseFloat(b.quantity) >= parseFloat(sub.qty));
              sub.selectedBatch = subRef.inventoryId.batches[index];
            }
            if (!sub.selectedBatch) {
              // eslint-disable-next-line
              alert(this.$t('general.noAvailableBatch') + item.name);
              return null;
            }
          }
        }
        if (item.inventory === 'Order Set') {
          const subItems = item.subInventoryRef;
          const invalidItems = [];
          for (let index = 0; index < subItems.length; index += 1) {
            const itemDetails = subItems[index].inventoryId;
            subItems[index].uuid = v4();
            if (itemDetails.inventory === 'Medicine' || itemDetails.inventory === 'Wyn' ||itemDetails.inventory === 'Consumables' || itemDetails.inventory === 'Product' || (itemDetails.inventory === 'Expendables' && item.batches)) {
              // Select the earliest batch that fulfil the dispensed qty
              subItems[index].selectedBatch = null;
              if (Array.isArray(itemDetails.batches) && itemDetails.batches.length) {
                // FIFO by delivery date
                const i = itemDetails.batches
                  .sort((a, b) => a.deliveryDate.localeCompare(b.deliveryDate))
                  .findIndex(b => parseFloat(b.quantity) >= parseFloat(subItems[index].qty));
                subItems[index].selectedBatch = itemDetails.batches[i];
                if (i>0) {
                alert(this.$t('general.nextAvailableBatch') + itemDetails.name)
                }
              }
              if (!subItems[index].selectedBatch) {
                invalidItems.push(itemDetails.name);
              } else if (subItems[index].selectedBatch && subItems[index].selectedBatch.costprice) {
                subItems[index].cost = parseFloat(subItems[index].selectedBatch.costprice);
              }
            }
          }
          if (invalidItems.length > 0) {
            // eslint-disable-next-line
            alert(this.$t('general.noAvailableBatch') + invalidItems.join(', '));
            return null;
          }
        }
        if (item.drugClassification === constant.DRUG_CLASSIFICATION.CLASSIFIED) {
          hasClassifiedMed = true;
        }
        const autoRedeemPoint = item.pointToRedeem * item.redeemQty;
        if (!self.isManualRedeem && autoRedeemPoint <= self.totalRedeemablePoints && item.redeemable) {
          // item.pointToRedeem = autoRedeemPoint;
          item.redeemedPoint = autoRedeemPoint;
        }

        if (!isIpdOrOt) {
          self.$store.commit('invoice/ADD_LINE_ITEM', { invoiceId: self.invoice._id, item });
        }
        dispensingItem = item;
      }
      self.$nextTick(() => {
        let clonedInvoice = Object.assign({}, self.invoice);
        if (!_.isEmpty(clonedInvoice)) {
          clonedInvoice = self.calculateInvoice(clonedInvoice, undefined, self.invoiceConfig);
          clonedInvoice.dispensedBy = self.user.id;
        }
        // if (!self.isChangeMadeByProviderTherapist
        //   && !hasClassifiedMed
        //   && self.requestApprovalConfig.normal.dispense) {
        //   clonedInvoice.dispenseItemRevision = v4();
        //   clonedInvoice.normalVersion += 1;
        // }
        // if (!self.isChangeMadeByProviderTherapist
        //   && hasClassifiedMed
        //   && self.requestApprovalConfig.classified.dispense) {
        //   clonedInvoice.classifiedItemRevision = v4();
        //   clonedInvoice.classifiedVersion += 1;
        // }
        if (!isIpdOrOt) {
          self.$store.commit('invoice/UPDATE_INVOICE', clonedInvoice);
          self.$store.commit('invoice/PATCH_STATS', { id: clonedInvoice._id, ostBalance: clonedInvoice.ostBalance || 0 });
        }
      });
      return dispensingItem;
    },
    adjustDispenseQty(invoice, updateParams) {
      const index = -1;
      const subInventoryMatched = false;
      invoice.items.forEach((item, idx) => {
        if (item.inventory === 'Compounded') {
          if (updateParams.uuid === item.uuid) {
            const prevQty = parseFloat(item[updateParams.key]);
            const newQty = parseFloat(updateParams.val.quantity);
            item.subInventory.forEach((sInv) => {
              sInv.qty = parseFloat(sInv.qty) / prevQty * newQty;
              sInv.qtyOfInventoryUnitPerPurchasingUnit = sInv.qty / newQty;
            });
            item.price = item.price / prevQty * newQty;
          } else if (item.subInventory && item.subInventory.length) {
            item.subInventory.forEach((sInv) => {
              if (sInv.uuid === updateParams.uuid) {
                sInv.qty = parseFloat(sInv.qty);
                sInv.price = parseFloat(sInv.price);
                updateParams.val.quantity = parseFloat(updateParams.val.quantity);
                if (sInv.qty > updateParams.val.quantity) {
                  const oldCompoundItemPrice = sInv.qty * sInv.price;
                  const newCompoundItemPrice = updateParams.val.quantity * sInv.price;
                  const priceDiff = newCompoundItemPrice - oldCompoundItemPrice;
                  item.price = parseFloat(item.price) + priceDiff;
                  item.price = (item.price).toFixed(2);
                  sInv.qty = updateParams.val.quantity;
                  sInv.qtyOfInventoryUnitPerPurchasingUnit = sInv.qty / item.qty;
                }
              }
            });
          }
        } else if (item.uuid === updateParams.uuid) {
          if (item.qty > updateParams.val.quantity) {
            item.qty = updateParams.val.quantity;
          }
        }
      });
      return invoice;
    },
    useCostPriceFromSelectedBatch(invoice, { uuid }) {
      const index = invoice.items.findIndex(item => item.uuid === uuid);
      if (index > -1) {
        const item = invoice.items[index];
        // NOTE: if this item is not required to do stock tracing skip batch consuming part. (in case "stock === -1")
        if (_.get(item, 'stock') !== -1 && ['Medicine', 'Wyn', 'Product', 'Consumables', 'Expendables'].includes(item.inventory)
          && item.selectedBatch.number !== this.invoice.items[index].selectedBatch.number
        ) {
          if (item.selectedBatch && item.selectedBatch.costprice) {
            // eslint-disable-next-line no-param-reassign
            invoice.items[index].cost = parseFloat(item.selectedBatch.costprice);
          }
        }
      }
      return invoice;
    },
    selectBatch(invoice, updateParams, context = {}) {
      // const index = invoice.items.findIndex(i => i.uuid === updateParams.uuid);
      let index = -1;
      let subInventoryMatched = false;
      let insufficientIngredient = false;
      invoice.items.forEach((item, idx) => {
        if (item.inventory === 'Compounded') {
          if (item.uuid === updateParams.uuid && item.subInventory && item.subInventory.length) {
            item.subInventory.forEach((sInv) => {
              if (sInv.batches && sInv.batches.length) {
                const newCompoundParentQty = parseFloat(updateParams.val);
                const oldCompoundParentQty = parseFloat(item.qty);
                const newIngredientQty = parseFloat(sInv.qty) / oldCompoundParentQty * newCompoundParentQty;

                const batchIndex = sInv.batches.findIndex(b => b.quantity >= newIngredientQty);
                if (batchIndex === -1) {
                  alert(`After calculating new quantity for item ${sInv.name}, unable to find a batch with sufficient quantity. Reduce quantity or remove ingredient.`);
                  insufficientIngredient = true;
                }
              }
            });
          }
          if (item.subInventory && item.subInventory.length) {
            item.subInventory.forEach((sInv) => {
              if (sInv.uuid === updateParams.uuid) {
                if (sInv.batches && sInv.batches.length) {
                  const batchIndex = sInv.batches.findIndex(b => b.quantity >= updateParams.val);
                  if (batchIndex > -1) {
                    sInv.selectedBatch = sInv.batches[batchIndex];
                  } else {
                    // eslint-disable-next-line
                    alert(`Unable to find a batch for item ${sInv.name} with sufficient quantity. Reduce quantity or remove ingredient.`);
                    insufficientIngredient = true;
                  }
                }
                subInventoryMatched = true;
              }
            });
          }
        }
        if (item.inventory === 'Order Set') {
          if (item.subInventoryRef && item.subInventoryRef.length) {
            let warningText = '';
            if (item.uuid === updateParams.uuid) {
              item.subInventoryRef.forEach((sInv) => {
                if (sInv.inventoryId.batches && sInv.inventoryId.batches.length) {
                  const batchIndex = sInv.inventoryId.batches.findIndex(b => b.quantity >= (updateParams.val * sInv.qty));
                  if (batchIndex > -1) {
                    sInv.inventoryId.selectedBatch = sInv.inventoryId.batches[batchIndex];
                  } else {
                    // eslint-disable-next-line
                      if(warningText === ""){
                      warningText = sInv.inventoryId.name;
                    } else {
                      warningText = `${warningText},${sInv.inventoryId.name}`;
                    }
                    insufficientIngredient = true;
                  }
                }
                subInventoryMatched = true;
              });
              if (warningText) {
                alert(`Unable to find a batch for item ${warningText} with sufficient quantity. Reduce quantity or remove item.`);
              }
            }
          }
        }
        if (item.uuid === updateParams.uuid) index = idx;
      });

      if (insufficientIngredient) return false;
      // if the edited line item is a subInventory of a Compounded parent line item, then skip the batch selection
      if (subInventoryMatched) return invoice;

      // NOTE: if this item is not required to do stock tracing skip batch consuming part. (in case "stock === -1")
      if (_.get(invoice, ['items', index, 'stock']) === -1) return invoice;

      if (index > -1 && ['Medicine', 'Wyn', 'Consumables', 'Product'].includes(invoice.items[index].inventory)) {
        const item = invoice.items[index];
        // Select the default batch that can fulfil the dispense quantity
        if (item.batches.length) {
          const batchPool = item.batches
            .filter(b => b.quantity > 0)
            .sort((a, b) => a.deliveryDate.localeCompare(b.deliveryDate));
          const requestedQty = updateParams.val;
          if (this.requestedQtyCanBeDispensed(requestedQty, batchPool)) {
            const requested = requestedQty;
            if (!invoice.items[index].selectedBatch.batchSlctFlag || invoice.items[index].selectedBatch.batchSlctFlag === null || invoice.items[index].selectedBatch.batchSlctFlag === undefined) {
              invoice.items[index].selectedBatch = batchPool[0];
            }
            const requestedQtyGreaterThanCurBatchStock = requested > item.selectedBatch.quantity;

            // if requested qty is above the max available qty
            const dispensed = requestedQtyGreaterThanCurBatchStock ? item.selectedBatch.quantity : requested;
            invoice.items[index].qty = dispensed;
            const remainingQtyToDispensed = requested - dispensed;
            context.dispensed = dispensed;
            context.splitItemRemainingQty = remainingQtyToDispensed;
          } else {
            this.flash(`${this.$t('general.invoiceErrorInsuff')} ${item.name}.`, 'error', { timeout: 5000 });
            context.invalid = true;
            return null;
          }
        }
      }

      return invoice;
    },
    calculateInvoice(invoice, updateParams, config) {
      let uuid;
      let val;
      let key;
      if (updateParams) {
        uuid = updateParams.uuid;
        val = updateParams.val;
        key = updateParams.key;
      }
      let finalSubTotal = 0;
      let finalTotalDiscount = 0;
      let finalTotalTax = 0;
      let finalInvoiceTotal = 0;
      let finalRedeemPoint = 0;
      const finalRedeemAmount = 0;

      // This decides if to use config or invoice saved includeGST settings
      const includeGST = this.isInvoiceWithGST(invoice, config);
      if (invoice.items && invoice.items.length > 0) {
        invoice.items = invoice.items.map((item) => {
          const clone = Object.assign({}, item);
          if (uuid && uuid === clone.uuid) {
            if (key === 'qty') {
              if (clone.redeemedPoint === 0) {
                clone.redeemQty = val;
              }
              if (clone.inventory === 'Compounded') {
                if (val === '' || val === '0' || val === 0) {
                  alert('Quantity should be at least 1');
                  val = 1;
                }
                const prevQty = parseFloat(clone[key]);
                const newQty = parseFloat(val);
                clone.subInventory.forEach((sInv) => {
                  sInv.qty = parseFloat(sInv.qty) / prevQty * newQty;
                  sInv.qtyOfInventoryUnitPerPurchasingUnit = sInv.qty / newQty;
                });
                // clone.price = parseFloat(clone.price) / prevQty * newQty;
              }
            }
            clone[key] = val;
            if (key === 'qty' && clone.redeemedPoint === 0) {
              clone.redeemQty = val;
            }
            if (key === 'price') {
              const decimalValue = this.currencyToDecimalString(val);
              clone.price = decimalValue;
              // if (isNaN(decimalValue)) {
              //   clone.price = 0;
              // } else {
              // }
            }
            if (key === 'discount') {
              const decimalValue = this.currencyToDecimalString(val);
              clone.discount = decimalValue;
              // if (isNaN(decimalValue)) {
              //   clone.discount = 0;
              // } else {
              // }
            }
            const autoRedeemPoint = (clone.qty * clone.pointToRedeem);
            if (!this.isManualRedeem && autoRedeemPoint <= (this.totalRedeemablePoints + clone.redeemedPoint)
              && clone.redeemable) {
              clone.redeemedPoint = autoRedeemPoint;
              clone.redeemQty = clone.qty;
            }
          } else if (uuid && clone.subInventory && clone.subInventory.length) {
            clone.subInventory.forEach((sInv) => {
              if (uuid === sInv.uuid) { // Compounded's ingredient
                if (key === 'qty') {
                  // first, change the price of the parent compounded line item
                  if (val === '' || val === '0' || val === 0) {
                    alert('Quantity should be at least 1');
                    val = 1;
                  }
                  const oldCompoundItemPrice = parseFloat(sInv.qty) * parseFloat(sInv.price);
                  const newCompoundItemPrice = parseFloat(val) * parseFloat(sInv.price);
                  const priceDiff = (newCompoundItemPrice - oldCompoundItemPrice) / parseFloat(clone.qty);
                  clone.price = parseFloat(clone.price) + priceDiff;
                  clone.price = (clone.price).toFixed(2);
                  sInv.qty = val;
                  sInv.qtyOfInventoryUnitPerPurchasingUnit = (sInv.qty / clone.qty).toFixed(2);
                } else if (key === 'price') {
                  const newPrice = parseFloat(val || 0);
                  const priceDiff = ((newPrice - parseFloat(sInv.price)) * parseFloat(sInv.qty)) / parseFloat(clone.qty);
                  clone.price = parseFloat(clone.price) + priceDiff;
                  sInv.price = (newPrice).toFixed(2);
                } else if (key === 'selectedBatch') {
                  sInv[key] = val;
                }
              }
            });
          }
          const itemCalculated = this.calculateLineItem(clone, { includeGST });
          finalSubTotal += this.computeSubTotal(itemCalculated.beforeDiscountPrice, itemCalculated.redeemAmount);
          finalTotalDiscount += this.computeTotalDiscount(itemCalculated.discountAmount);
          finalTotalTax += this.computeTotalTax(itemCalculated.taxAmount);
          finalRedeemPoint += itemCalculated.redeemPoint;
          finalInvoiceTotal += (itemCalculated.afterTaxPrice - itemCalculated.redeemAmountAfterTax);
          return itemCalculated;
        });
      }


      invoice.subtotal = finalSubTotal;
      invoice.totalDiscount = finalTotalDiscount;
      // invoice.totalTax = finalTotalTax;
      invoice.totalTax = includeGST ? finalTotalTax : 0;
      invoice.totalBeforeRounding = finalInvoiceTotal;

      // TODO - chan - currently ui is just accepting one promotion per invoice
      invoice.promotionDiscount = (invoice.promotions && invoice.promotions.length > 0)
        ? this.getPromotionDiscount(
          invoice.totalBeforeRounding,
          invoice.promotions[0],
          invoice.items,
          { includeGST },
        ) : 0;

      if (invoice.promotions && invoice.promotions.length > 0) {
        const promoDetails = this.getPromotionDiscountDetails(
          invoice.totalBeforeRounding,
          invoice.promotions[0],
          invoice.items,
          { includeGST },
        );
        const { discountDetails } = promoDetails;
        if (discountDetails) {
          invoice.items.forEach((item) => {
            discountDetails[item.uuid] = discountDetails[item.uuid] || {};
            item.promoAmountAfterTax = discountDetails[item.uuid].promoAmountAfterTax || 0;
            item.promoAmountBeforeTax = discountDetails[item.uuid].promoAmountBeforeTax || 0;
          });
        }
      } else {
        invoice.items.forEach((item) => {
          item.promoAmountAfterTax = 0;
          item.promoAmountBeforeTax = 0;
        });
      }

      const totalVirtualCurrencyRedeemed = invoice.totalVirtualCurrencyRedeemed || 0;
      invoice.totalBeforeRounding = (invoice.totalBeforeRounding - invoice.promotionDiscount < 0)
        ? 0
        : invoice.totalBeforeRounding - invoice.promotionDiscount;
      invoice.totalBeforeRounding -= totalVirtualCurrencyRedeemed;
      const [total, roundingAdjustment] = this.handleRounding(
        invoice.totalBeforeRounding,
        { roundingEnabled: invoice.roundingEnabled },
        { roundOff: config.roundOff },
      );
      invoice.roundingAdjustment = roundingAdjustment;
      invoice.total = total;
      if (!invoice.finalized) {
        invoice.ostBalance = invoice.total;
      }

      if (config.exchangeRate && invoice.invoiceNo === 'draft') {
        // eslint-disable-next-line
        invoice.exchangeRate = config.exchangeRate;
      }
      return invoice;
    },
    calculateIpdInvoice(invoice, updateParams, config) {
      let uuid;
      let val;
      let key;
      if (updateParams) {
        uuid = updateParams.uuid;
        val = updateParams.val;
        key = updateParams.key;
      }
      let finalSubTotal = 0;
      let finalTotalDiscount = 0;
      let finalTotalTax = 0;
      let finalInvoiceTotal = 0;
      let finalRedeemPoint = 0;
      const finalRedeemAmount = 0;

      // This decides if to use config or invoice saved includeGST settings
      const includeGST = this.isInvoiceWithGST(invoice, config);

      invoice.sections.forEach((section) => {
        if (section.items && section.items.length > 0) {
          section.items = section.items.map((item) => {
            const clone = Object.assign({}, item);
            if (uuid && uuid === clone.uuid) {
              if (key === 'qty') {
                if (clone.redeemedPoint === 0) {
                  clone.redeemQty = val;
                }
                if (clone.inventory === 'Compounded') {
                  if (val === '' || val === '0' || val === 0) {
                    alert('Quantity should be at least 1');
                    val = 1;
                  }
                  const prevQty = parseFloat(clone[key]);
                  const newQty = parseFloat(val);
                  clone.subInventory.forEach((sInv) => {
                    sInv.qty = parseFloat(sInv.qty) / prevQty * newQty;
                    sInv.qtyOfInventoryUnitPerPurchasingUnit = sInv.qty / newQty;
                  });
                  // clone.price = parseFloat(clone.price) / prevQty * newQty;
                }
              }
              clone[key] = val;
              if (key === 'qty' && clone.redeemedPoint === 0) {
                clone.redeemQty = val;
              }
              if (key === 'price') {
                const decimalValue = this.currencyToDecimalString(val);
                clone.price = decimalValue;
                // if (isNaN(decimalValue)) {
                //   clone.price = 0;
                // } else {
                // }
              }
              if (key === 'discount') {
                const decimalValue = this.currencyToDecimalString(val);
                clone.discount = decimalValue;
                // if (isNaN(decimalValue)) {
                //   clone.discount = 0;
                // } else {
                // }
              }
              const autoRedeemPoint = (clone.qty * clone.pointToRedeem);
              if (!this.isManualRedeem && autoRedeemPoint <= (this.totalRedeemablePoints + clone.redeemedPoint)
                && clone.redeemable) {
                clone.redeemedPoint = autoRedeemPoint;
                clone.redeemQty = clone.qty;
              }
            } else if (uuid && clone.subInventory && clone.subInventory.length) {
              clone.subInventory.forEach((sInv) => {
                if (uuid === sInv.uuid) { // Compounded's ingredient
                  if (key === 'qty') {
                    // first, change the price of the parent compounded line item
                    if (val === '' || val === '0' || val === 0) {
                      alert('Quantity should be at least 1');
                      val = 1;
                    }
                    const oldCompoundItemPrice = parseFloat(sInv.qty) * parseFloat(sInv.price);
                    const newCompoundItemPrice = parseFloat(val) * parseFloat(sInv.price);
                    const priceDiff = (newCompoundItemPrice - oldCompoundItemPrice) / parseFloat(clone.qty);
                    clone.price = parseFloat(clone.price) + priceDiff;
                    clone.price = (clone.price).toFixed(2);
                    sInv.qty = val;
                    sInv.qtyOfInventoryUnitPerPurchasingUnit = (sInv.qty / clone.qty).toFixed(2);
                  } else if (key === 'price') {
                    const newPrice = parseFloat(val || 0);
                    const priceDiff = ((newPrice - parseFloat(sInv.price)) * parseFloat(sInv.qty)) / parseFloat(clone.qty);
                    clone.price = parseFloat(clone.price) + priceDiff;
                    sInv.price = (newPrice).toFixed(2);
                  } else if (key === 'selectedBatch') {
                    sInv[key] = val;
                  }
                }
              });
            }
            const itemCalculated = this.calculateLineItem(clone, { includeGST });

            finalSubTotal += this.computeSubTotal(itemCalculated.beforeDiscountPrice, itemCalculated.redeemAmount);
            finalTotalDiscount += this.computeTotalDiscount(itemCalculated.discountAmount);
            finalTotalTax += this.computeTotalTax(itemCalculated.taxAmount);
            finalRedeemPoint += itemCalculated.redeemPoint;
            finalInvoiceTotal += (itemCalculated.afterTaxPrice - itemCalculated.redeemAmountAfterTax);
            return itemCalculated;
          });
        }
      });

      invoice.subtotal = finalSubTotal;
      invoice.totalDiscount = finalTotalDiscount;
      // invoice.totalTax = finalTotalTax;
      invoice.totalTax = includeGST ? finalTotalTax : 0;
      invoice.totalBeforeRounding = finalInvoiceTotal;

      // TODO - chan - currently ui is just accepting one promotion per invoice
      invoice.promotionDiscount = (invoice.promotions && invoice.promotions.length > 0)
        ? this.getPromotionDiscount(
          invoice.totalBeforeRounding,
          invoice.promotions[0],
          invoice.items,
          { includeGST },
        ) : 0;
      const totalVirtualCurrencyRedeemed = invoice.totalVirtualCurrencyRedeemed || 0;
      invoice.totalBeforeRounding = (invoice.totalBeforeRounding - invoice.promotionDiscount < 0)
        ? 0
        : invoice.totalBeforeRounding - invoice.promotionDiscount;
      invoice.totalBeforeRounding -= totalVirtualCurrencyRedeemed;
      const [total, roundingAdjustment] = this.handleRounding(
        invoice.totalBeforeRounding,
        { roundingEnabled: invoice.roundingEnabled },
        { roundOff: config.roundOff },
      );
      invoice.roundingAdjustment = roundingAdjustment;
      invoice.total = total;
      if (!invoice.finalized) {
        invoice.ostBalance = invoice.total;
        console.log('here---------', invoice);
      }

      if (config.exchangeRate && invoice.invoiceNo === 'draft') {
        // eslint-disable-next-line
        invoice.exchangeRate = config.exchangeRate;
      }
      return invoice;
    },
    handleRounding(totalBeforeRounding, indvConfig, { roundOff }) {
      let [total, roundingAdjustment] = [0, 0];
      let roundingMethod = '';
      if (typeof indvConfig.roundingEnabled === 'undefined') {
        // Use what is on Config when indv setting is not defined
        roundingMethod = roundOff;
      } else {
        roundingMethod = indvConfig.roundingEnabled ? roundOff : 'NONE';
      }
      switch (roundingMethod) {
        case 'ROUND1P00': // integer
          roundingAdjustment = (this.getRounding1P00(totalBeforeRounding) - totalBeforeRounding);
          total = totalBeforeRounding + roundingAdjustment;
          break;
        case 'ROUND0P05': // to 0.05
          roundingAdjustment = (this.getRounding0P05(totalBeforeRounding) - totalBeforeRounding);
          total = totalBeforeRounding + roundingAdjustment;
          break;
        case 'ROUND1D00':
          roundingAdjustment = (this.getRounding1D00(totalBeforeRounding) - totalBeforeRounding);
          total = totalBeforeRounding + roundingAdjustment;
          break;
        default: // none or blank or undefined
          roundingAdjustment = 0;
          total = totalBeforeRounding;
      }

      return [total, roundingAdjustment];
    },
    calculateLineItem(item, { includeGST }) {
      const config = this.$store.state.config.invoice;
      const beforeDiscountPrice = parseFloat(item.qty) * parseFloat(item.price) || 0;
      let discountAmount = 0;
      if (item.discount) {
        switch (item.discountType) {
          case '%':
            discountAmount = beforeDiscountPrice * (parseFloat(item.discount) / 100);
            break;
          case '$':
            discountAmount = parseFloat(item.discount);
            break;
          default:
            break;
        }
      }
      /* eslint-disable no-param-reassign */
      item.price = this.to2Decimal(+item.price);
      item.discountAmount = discountAmount;
      item.beforeDiscountPrice = beforeDiscountPrice;
      item.afterDiscountPrice = +(beforeDiscountPrice - discountAmount).toFixed(2);
      item.redeemAmount = 0;
      if (item.redeemedPoint > 0) { // item has been redeemed
        item.redeemAmount = +item.price * item.redeemQty;
      }
      // TAX
      if (includeGST) {
        const taxPriceData = this.computeTax(item.afterDiscountPrice, item.tax);
        const redeemTaxData = this.computeTax(item.redeemAmount, item.tax);
        item.taxAmount = taxPriceData.taxableAmount - redeemTaxData.taxableAmount;
        // round to 2 decimal
        item.afterTaxPrice = this.computeInvoiceTotal(taxPriceData.afterTaxPrice);
        item.redeemAmountAfterTax = this.computeInvoiceTotal(redeemTaxData.afterTaxPrice);
      } else {
        item.tax = 0;
        item.taxAmount = 0;
        item.afterTaxPrice = item.afterDiscountPrice;
        item.redeemAmountAfterTax = item.redeemAmount;
      }
      if (config.roundOffLineItemOrTotal === 'ROUNDLINEITEM') {
        if (config.roundOff === 'ROUND1D00') {
          item.afterTaxPrice = this.getRounding1D00(item.afterTaxPrice);
          item.beforeDiscountPrice = this.getRounding1D00(item.beforeDiscountPrice);
        }
      }
      return item;
    },
    calcValueAfterDiscount(item) {
      const beforeDiscountPrice = parseInt(1, 10) * parseFloat(item.price) || 0;
      let discountAmount = 0;
      if (item.discount) {
        switch (item.discountType) {
          case '%':
            discountAmount = beforeDiscountPrice * (parseFloat(item.discount) / 100);
            break;
          case '$':
            discountAmount = parseFloat(item.discount);
            break;
          default:
            break;
        }
      }
      return beforeDiscountPrice - discountAmount;
    },
    computeTax(price, tax) {
      const taxableAmount = price * parseFloat(tax) / 100 || 0;
      const afterTaxPrice = price + taxableAmount || 0;
      return {
        taxableAmount,
        afterTaxPrice,
      };
    },
    computeInvoiceTotal(afterTaxPrice) {
      return Math.round(afterTaxPrice * 1e2) / 1e2;
    },
    computeSubTotal(beforeDiscountPrice, redeemTotal = 0) {
      const subtotal = Math.round(beforeDiscountPrice * 1e2) / 1e2 || 0;
      return subtotal - redeemTotal;
    },
    computeTotalDiscount(discountAmount) {
      return Math.round(discountAmount * 1e2) / 1e2 || 0;
    },
    computeTotalTax(taxAmount) {
      return Math.round(taxAmount * 1e2) / 1e2;
    },
    getRounding0P05(totalBeforeRounding) { // round to nearest $0.05 algorithm
      const totalAfterRounding = Math.round(totalBeforeRounding * 20) / 20;
      return parseFloat((totalAfterRounding).toFixed(2));
    },
    getRounding1P00(totalBeforeRounding) { // round to nearest integer
      const totalAfterRounding = Math.round(totalBeforeRounding);
      return parseFloat((totalAfterRounding).toFixed(2));
    },
    getRounding1D00(totalBeforeRounding) { // round down to $1
      const totalAfterRounding = Math.floor(totalBeforeRounding);
      return parseFloat((totalAfterRounding).toFixed(2));
    },
    onBlur2dp(val, item) {
      const clone = Object.assign({}, item);
      clone.price = this.to2Decimal(+clone.price);
      // TODO: please take note that call to calculateLineItem below is not accurate
      // since we need the config for the Tax (to include or not)
      this.calculateLineItem(clone);
    },
    getPromotionDiscount(total, promotion, items, { includeGST }) {
      let discount = null;
      switch (promotion.promoType) {
        case 'BUYXGETY':
          discount = this.getBuyXGetYDiscount(total, promotion, items, { includeGST });
          return discount.totalDiscount;
        case 'DISCOUNT':
          discount = this.getDiscountPromoDiscount(total, promotion, items, { includeGST });
          return discount.totalDiscount;
        case 'FREEGIFT':
          discount = this.getFreeGiftDiscount(total, promotion, items, { includeGST });
          return discount.totalDiscount;
        case 'COMPLEX':
          discount = this.getComplexDiscount(total, promotion, items, { includeGST });
          return discount.totalDiscount;
        default:
          return 0;
      }
    },

    getPromotionDiscountDetails(total, promotion, items, { includeGST }) {
      let discount = null;
      switch (promotion.promoType) {
        case 'BUYXGETY':
          discount = this.getBuyXGetYDiscount(total, promotion, items, { includeGST });
          return discount;
        case 'DISCOUNT':
          discount = this.getDiscountPromoDiscount(total, promotion, items, { includeGST });
          return discount;
        case 'FREEGIFT':
          discount = this.getFreeGiftDiscount(total, promotion, items, { includeGST });
          return discount;
        case 'COMPLEX':
          discount = this.getComplexDiscount(total, promotion, items, { includeGST });
          return discount;
        default:
          return discount;
      }
    },

    getComplexDiscount(
      total,
      {
        complexPromo: {
          buyItems, getItems,
          discountType, discount,
        },
      },
      items,
      { includeGST },
    ) {
      // alert('IM HERE!!!')
      const shoppingCart = _.cloneDeep(items).reduce((cart, item) => ({
        ...cart,
        [item._id]: {
          ...item,
          qty: (cart[item._id] ? parseInt(cart[item._id].qty, 10) : 0) + parseInt(item.qty, 10),
        },
      }), {});
      const shoppingCartAfterBuyCriteria = _.cloneDeep(shoppingCart);
      const shoppingCartLessFreeItems = _.cloneDeep(shoppingCart);
      const buycriteriaCart = buyItems.reduce((cart, item) => ({
        ...cart,
        [item.item ? item.item._id : item._id]: item.item
          ? {
            _id: item.item._id,
            name: item.item.name,
            price: item.item.price,
            quantity: item.quantity,
          }
          : item,
      }), {});
      const freeItemList = getItems.map(get => ({
        _id: get.item ? get.item._id : get._id,
        name: get.item ? get.item.name : get.name,
        price: get.item ? get.item.price : get.price,
        quantity: get.quantity,
      }));
      let totalDiscount = 0;
      let valid = false;

      const itemids = Object.keys(shoppingCart);

      // console.log('Shopping CART: ', { ...shoppingCart });
      // console.log('Buy Criteria CART: ', { ...buycriteriaCart });
      // console.log('GETITEMS', freeItemList)
      // console.log('DISCOUNT_TYPE', discountType)
      // console.log('DISCOUNT', discount)

      const buyCriteriaPassed = Object.values(buycriteriaCart).every(buy => (
        itemids.includes(buy._id)
        && shoppingCart[buy._id].qty >= buy.quantity
      ));

      let discountFromFreeItems = 0;
      const discountDetails = {};
      if (buyCriteriaPassed && freeItemList.length > 0) {
        valid = true;
        // alert('PASSED BUY CRITERIA')
        // Get Free Items with qty
        const buyitemids = Object.keys(buycriteriaCart);
        buyitemids.forEach((id) => {
          shoppingCartAfterBuyCriteria[id].qty -= buycriteriaCart[id].quantity;
        });
        const itemsForFree = [];
        freeItemList.forEach((free) => {
          if (shoppingCartAfterBuyCriteria[free._id]
            && shoppingCartAfterBuyCriteria[free._id].qty > 0
          ) {
            const qtyForFree = free.quantity >= shoppingCartAfterBuyCriteria[free._id].qty
              ? shoppingCartAfterBuyCriteria[free._id].qty
              : free.quantity;
            itemsForFree.push({ ...free, quantity: qtyForFree });
          }
        });
        console.log('FOR FREE', itemsForFree);
        // Get Discount from remaining items
        itemsForFree.forEach((free) => {
          shoppingCartLessFreeItems[free._id].qty -= free.quantity;
          const { afterTaxPrice: totalPrice, afterDiscountPrice } = this.calculateLineItem(
            { ...shoppingCartLessFreeItems[free._id], qty: free.quantity },
            { includeGST },
          );
          discountFromFreeItems += totalPrice;
          discountDetails[shoppingCartLessFreeItems[free._id].uuid] = discountDetails[shoppingCartLessFreeItems[free._id].uuid] || {};
          discountDetails[shoppingCartLessFreeItems[free._id].uuid].promoAmountAfterTax = discountFromFreeItems;
          discountDetails[shoppingCartLessFreeItems[free._id].uuid].promoAmountBeforeTax = afterDiscountPrice;
        });
      }

      console.log('DISCOUNT FROM FREE: ', discountFromFreeItems);
      // DISCOUNT !!!
      let discountFromAdtnlDiscount = 0;
      if (buyCriteriaPassed && discount && discountType) {
        // alert('FOR DISCOUNT');
        console.log('shoppingCartLessFreeItems: ', shoppingCartLessFreeItems);
        if (discountType === 'PERCENTAGE') {
          const totalChargeableAmount = Object.values(shoppingCartLessFreeItems)
            .reduce((totalAmt, item) => totalAmt + (this.calculateLineItem(item, { includeGST })).afterTaxPrice, 0);
          console.log('TOTAL CHARGEABLE AMOUNT : ', totalChargeableAmount);
          discountFromAdtnlDiscount = totalChargeableAmount * discount / 100;
          Object.values(shoppingCartLessFreeItems).forEach((item) => {
            console.log(item.uuid, (this.calculateLineItem(item, { includeGST })).afterTaxPrice * discount / 100);
            discountDetails[item.uuid] = discountDetails[item.uuid] || {};
            discountDetails[item.uuid].promoAmountAfterTax = (discountDetails[item.uuid].promoAmountAfterTax || 0) + (this.calculateLineItem(item, { includeGST })).afterTaxPrice * discount / 100;
            discountDetails[item.uuid].promoAmountBeforeTax = (discountDetails[item.uuid].promoAmountBeforeTax || 0) + (this.calculateLineItem(item, { includeGST })).afterDiscountPrice * discount / 100;
          });
        } else if (discountType === 'FIXED') {
          discountFromAdtnlDiscount = discount;
        } else {
          discountFromAdtnlDiscount = 0;
        }
        valid = true;
      }
      totalDiscount = discountFromFreeItems + discountFromAdtnlDiscount;
      console.log('DISCOUNT FROM ADTNL: ', discountFromAdtnlDiscount);
      console.log('AFTER COMPUTE: ', shoppingCartAfterBuyCriteria);
      console.log('DISCOUNT DETAILS: ', discountDetails);
      return valid ? { totalDiscount, discountDetails } : { totalDiscount: 0, discountDetails: {} };
    },
    getBuyXGetYDiscount(
      total,
      {
        buyXgetYPromo: {
          buyItemType, buyItem, buyQuantity,
          getItemType, getItem, getQuantity,
        },
      },
      items,
      { includeGST },
    ) {
      const clonedItems = _.cloneDeep(items);
      let discount = { totalDiscount: 0, discountDetails: {} };
      let valid = 0;
      if (buyItemType === 'ANY' && getItemType !== 'ANY') {
        // if buyitem is ANY, check freeItemList first
        // so you will not accidentally get the items from freeItemList
        const {
          valid: isGetValid, getPromoItems, remainingItems: itemsAfterGet,
        } = this.getBuyXGetYGetItems(clonedItems, getItemType, getQuantity, getItem);
        if (isGetValid) {
          const {
            valid: isBuyValid,
          } = this.getBuyXGetYBuyItems(itemsAfterGet, buyItemType, buyQuantity, buyItem);
          valid = isBuyValid;
          discount = valid ? this.totalFreeItemsFromPromo(getPromoItems, { includeGST }) : { totalDiscount: 0, discountDetails: {} };
        }
      } else {
        // all other promos, check buy items first then get items
        const {
          valid: isBuyValid, remainingItems: itemsAfterBuy,
        } = this.getBuyXGetYBuyItems(clonedItems, buyItemType, buyQuantity, buyItem);
        if (isBuyValid) {
          const {
            valid: isGetValid, getPromoItems,
          } = this.getBuyXGetYGetItems(itemsAfterBuy, getItemType, getQuantity, getItem);
          valid = isGetValid;
          discount = valid ? this.totalFreeItemsFromPromo(getPromoItems, { includeGST }) : { totalDiscount: 0, discountDetails: {} };
        }
      }
      return valid ? discount : { totalDiscount: 0, discountDetails: {} };
    },
    getBuyXGetYBuyItems(items, itemType, promoQty, specifiedItem) {
      let INVTYPE = '';
      const expnsvFrst = [...items];
      expnsvFrst.sort((a, b) => this.calcValueAfterDiscount(b) - this.calcValueAfterDiscount(a));
      let output;
      switch (itemType) {
        case 'ANY':
          output = this.pullBuyXGetYBuyQualItems(() => true, expnsvFrst, promoQty);
          break;
        case 'ANY_MEDICINE':
        case 'ANY_CONSUMABLES':
        case 'ANY_EXPENDABLES':
        case 'ANY_PROCEDURE':
        case 'ANY_PACKAGE':
        case 'ANY_BUNDLE':
        case 'ANY_INVESTIGATION':
        case 'ANY_REFERRAL':
        case 'ANY_INJECTION':
        case 'ANY_VACCINATION':
        case 'ANY_MISC':
        case 'ANY_CREDIT':
          INVTYPE = this.parseBuyXGetYInvType(itemType);
          output = this.pullBuyXGetYBuyQualItems(
            item => item.inventory === INVTYPE,
            expnsvFrst,
            promoQty,
          );
          break;
        case 'SPECIFIED':
          output = this.pullBuyXGetYBuyQualItems(
            item => item.id === specifiedItem.id,
            expnsvFrst,
            promoQty,
          );
          break;
        default:
          output = {
            valid: false,
            pulledItems: [],
            remainingItems: [...items],
          };
      }
      const { valid, pulledItems: buyPromoItems, remainingItems } = output;
      return { valid, buyPromoItems, remainingItems };
    },
    getBuyXGetYGetItems(items, itemType, promoQty, specifiedItem) {
      let INVTYPE = '';
      const cheapFrst = [...items];
      cheapFrst.sort((a, b) => this.calcValueAfterDiscount(a) - this.calcValueAfterDiscount(b));
      let output;
      switch (itemType) {
        case 'ANY':
          output = this.pullBuyXGetYBuyQualItems(() => true, cheapFrst, promoQty);
          break;
        case 'ANY_MEDICINE':
        case 'ANY_CONSUMABLES':
        case 'ANY_EXPENDABLES':
        case 'ANY_PROCEDURE':
        case 'ANY_PACKAGE':
        case 'ANY_BUNDLE':
        case 'ANY_INVESTIGATION':
        case 'ANY_REFERRAL':
        case 'ANY_INJECTION':
        case 'ANY_VACCINATION':
        case 'ANY_MISC':
        case 'ANY_CREDIT':
          INVTYPE = this.parseBuyXGetYInvType(itemType);
          output = this.pullBuyXGetYBuyQualItems(
            item => item.inventory === INVTYPE,
            cheapFrst,
            promoQty,
          );
          break;
        case 'SPECIFIED':
          output = this.pullBuyXGetYBuyQualItems(
            item => item.id === specifiedItem.id,
            cheapFrst,
            promoQty,
          );
          break;
        default:
          output = {
            valid: false,
            pulledItems: [],
            remainingItems: [...items],
          };
      }
      const { valid, pulledItems: getPromoItems, remainingItems } = output;
      return { valid, getPromoItems, remainingItems };
    },
    pullBuyXGetYBuyQualItems(isQualifiedFn, itemList, neededQty) {
      let remainingNeededQty = neededQty;
      const qualifiedItems = [];
      let index = 0;
      while (remainingNeededQty > 0 && index < itemList.length) {
        const currentItem = itemList[index];
        if (isQualifiedFn(currentItem)) {
          const origQty = parseInt(currentItem.qty, 10);
          const qtyTaken = origQty >= remainingNeededQty
            ? remainingNeededQty
            : origQty;
          qualifiedItems.push({
            ...currentItem,
            qty: qtyTaken,
          });
          currentItem.qty = origQty - qtyTaken;
          remainingNeededQty -= qtyTaken;
        }
        index += 1;
      }
      return {
        valid: remainingNeededQty === 0,
        pulledItems: [...qualifiedItems],
        remainingItems: [...itemList],
      };
    },
    totalFreeItemsFromPromo(itemsForFree, { includeGST }) {
      const discountDetails = {};
      let totalDiscount = 0;
      itemsForFree.forEach((item) => {
        const { afterTaxPrice: totalPrice, afterDiscountPrice } = this.calculateLineItem(item, { includeGST });
        discountDetails[item.uuid] = discountDetails[item.uuid] || {};
        discountDetails[item.uuid].promoAmountAfterTax = totalPrice;
        discountDetails[item.uuid].promoAmountBeforeTax = afterDiscountPrice;
        totalDiscount += totalPrice;
      });
      return { totalDiscount, discountDetails };
    },
    parseBuyXGetYInvType(invType) {
      switch (invType) {
        case 'ANY_MEDICINE': return 'Medicine';
        case 'ANY_WYN': return 'Wyn';
        case 'ANY_CONSUMABLES': return 'Consumables';
        case 'ANY_EXPENDABLES': return 'Expendables';
        case 'ANY_PROCEDURE': return 'Procedure';
        case 'ANY_PACKAGE': return 'Package';
        case 'ANY_BUNDLE': return 'Bundle';
        case 'ANY_INVESTIGATION': return 'Investigation';
        case 'ANY_REFERRAL': return 'Referral';
        case 'ANY_INJECTION': return 'Injection';
        case 'ANY_VACCINATION': return 'Vaccination';
        case 'ANY_MISC': return 'Misc';
        case 'ANY_CREDIT': return 'Credit';
        default: return null;
      }
    },
    getFreeGiftDiscount(
      total,
      {
        freeGiftPromo: {
          freeItem, quantity,
        },
      },
      items,
      { includeGST },
    ) {
      const clonedItems = _.cloneDeep(items);
      const { valid, pulledItems: getPromoItems } = this.pullBuyXGetYBuyQualItems(
        item => item.id === freeItem.id,
        clonedItems,
        quantity,
      );
      return valid ? this.totalFreeItemsFromPromo(getPromoItems, { includeGST }) : { totalDiscount: 0, discountDetails: {} };
    },
    getDiscountPromoDiscount(
      total,
      { discountPromo: { discountType, discount } },
      items,
      { includeGST }
    ) {
      let totalDiscount;
      if (discountType === 'FIXED') {
        // Total discount amount cannot be greater than invoice's total amount
        totalDiscount = Math.min(discount, total);
      } else if (discountType === 'PERCENTAGE') {
        totalDiscount = total * (discount / 100);
      } else {
        totalDiscount = 0;
      }

      // Prep for distributing discount to items proportionally
      let totalAfterTaxPrice = 0;
      let totalAfterDiscountPrice = 0;
      // Mapping of {'<item uuid>': {afterTaxPrice: ..., afterDiscountPrice: ...}
      const itemPriceMap = {};
      items.forEach(item => {
        const {
          afterTaxPrice,
          afterDiscountPrice,
        } = this.calculateLineItem(item, { includeGST });
        totalAfterTaxPrice += afterTaxPrice;
        totalAfterDiscountPrice += afterDiscountPrice;
        itemPriceMap[item.uuid] = { afterTaxPrice, afterDiscountPrice };
      });

      let totalDiscountBeforeTax = totalDiscount;
      if (discountType === 'PERCENTAGE') {
        totalDiscountBeforeTax = totalAfterDiscountPrice * (discount / 100);
      }

      // Distribute discount to items proportionally
      const discountDetails = {};
      items.forEach(item => {
        const { afterTaxPrice, afterDiscountPrice } = itemPriceMap[item.uuid];
        discountDetails[item.uuid] = discountDetails[item.uuid] || {};
        // Promo amount cannot be greater than its original item amount
        discountDetails[item.uuid].promoAmountAfterTax = Math.min(
          (afterTaxPrice / totalAfterTaxPrice) * totalDiscount,
          afterTaxPrice
        );
        discountDetails[item.uuid].promoAmountBeforeTax = Math.min(
          (afterDiscountPrice / totalAfterDiscountPrice) * totalDiscountBeforeTax,
          afterDiscountPrice
        );
      });
      return { totalDiscount, discountDetails };
    },
    async simpleSearchInventories(query, inventoryTypeLimits) {
      if (query === '' || query.includes('(')) return {};
      const q = String(query).trim();
      const di = (this.dispensableInventories(inventoryTypeLimits) || []);
      const aclDi = _.difference(
        Object.values(this.INVENTORY_TYPES),
        di,
      );
      const params = {
        qs: q,
        fields: 'givenId,name,description,brandName,category,inventory,strength',
        inventoryTypes: di.join(','),
        // inventoryConfig is from Config store. component must import the config store
        ninventory: this.inventoryConfig.salesOptionsConfig.expendablesAreSellable ? '' : 'Expendables',
        clinicCode: this.selectedClinic._id,
        status: false,
        limit: this.maxSearchResults,
      };
      if (!aclDi.includes('Medicine') && this.restrictedMedicines.length) {
        params.ndrugclassification = this.restrictedMedicines.join(',') || [];
      } else {
        // params.ndrugclassification = [];
      }
      // console.log('simpleSearchInventories - params ', params);
      const res = await inventoryService.fetchSimplify(params);
      const data = await res.json();
      this.searchInventoryResults = data;
      return data;
    },
    dispensableInventories(limits) {
      const childFns = localStorage.getItem('acl__childrenFnIds');
      if (!childFns) {
        return ['NA'];
      }
      const aclInventories = JSON.parse(childFns);
      // console.log('aclInventories ', aclInventories);
      let invTypes = constant.INVENTORY_TYPES 
       invTypes = {...invTypes,...constant.INVENTORY_TYPES_MAKUANG} 
      const dispensable = Object.keys(invTypes)
        .filter((invtType) => {
          const aclCheck = Object.keys(aclInventories).indexOf(invtType) >= 0;
          if (limits && limits.length) {
            return aclCheck && limits.indexOf(invtType) >= 0;
          }
          return aclCheck;
        });
      // dirty hack!! if only one inventory, api is returning all,
      // put the dirty hack NA & NIA
      // pls dont create inventory called NA and NIA
      return [...dispensable, ...['NA', 'NIA']];
    },
    applyPricingScheme(item) {
      // if No selected Pricing Scheme then skip
      if (!this.selectedPricingScheme
        || Object.keys(this.selectedPricingScheme).length === 0) return item;

      return this.applyPricingSchemToItems(this.selectedPricingScheme, item);
    },
    applyPricingSchemToItems(pricingScheme, invoiceItem) {
      const item = { ...invoiceItem };
      if (!pricingScheme) {
        item.discount = 0;
        item.discountType = '%';
        item.appliedDiscountFromPricingScheme = false;
        return item;
      }
      const foundScheme = pricingScheme.schemeInventory
        .find(ps => ps.type === item.inventory);
      console.log('Found Scheme ', foundScheme);
      if (foundScheme) {
        const itemIsInactive = foundScheme.items
          .find(schemeItem => schemeItem.inventoryId === item._id
            && schemeItem.inactive);
        console.log('found itemIsInactive', itemIsInactive);
        if (!itemIsInactive) {
          const discount = (foundScheme.customDiscount || [])[item._id];
          // eslint-disable-next-line no-param-reassign
          // item.discount = hasDiscount ? parseFloat(foundScheme.customDiscount[item._id])
          //   : parseFloat(foundScheme.discount);
          item.discount = discount === undefined ? parseFloat(foundScheme.discount) : parseFloat(discount, 10);
          // eslint-disable-next-line no-param-reassign
          item.discountType = foundScheme.discountType
            ? foundScheme.discountType : item.discountType;
          item.appliedDiscountFromPricingScheme = true;
        } else {
          item.discount = 0;
          item.discountType = '%';
          item.appliedDiscountFromPricingScheme = false;
        }
      } else {
        item.discount = 0;
        item.discountType = '%';
        item.appliedDiscountFromPricingScheme = false;
      }
      console.log('final item', item);
      return item;
    },
  },
};
