<template>
  <!-- eslint-disable max-len -->
  <div class="typing-note-full-print" id="typing-note-full-print">
    <div class="mb-3" v-if="this.print.printFullNote">
      <div id="notesHeader">
        <b-row
          ><div style="width: 20%">
            <img
              v-if="generalConfig && generalConfig.logoURL"
              :style="`margin-left: ${logoMarginLeft}px; height: 150px; width: auto;`"
              :src="generalConfig.logoURL">
            </div>
        </b-row>
        <b-row class="clinicName">{{ generalConfig.clinicName }}</b-row>
        <b-row>{{ generalConfig.address }}</b-row>
      </div>
      <hr />
       <!--  enable name when required -->
      <!-- <h4>{{ this.print.name }}</h4> -->
      <b-row>
        <div class="col">
          <img
            v-if="isTypingNoteReadOnly"
            :src="this.print.signedPrintKey"
            alt="Typing Note Image"
          />
          <typing-note-editor
            v-else
            :editor="this.print"
            :formDataOrder="this.print.formDataOrder"
            :formData="this.print.formData"
            :viewing="true"
            source="print"
          />
        </div>
      </b-row>
    </div>
    <div v-if="featureFlags.printTPH && !this.print.printFullNote">
      <div style="width: 100%" class="mb-3">
        <div style="display: inline-block; width: 30%; vertical-align: middle">
          <img
            v-if="generalConfig && generalConfig.logoURL"
            :src="generalConfig.logoURL"
            alt=""
          />
        </div>
        <div style="display: inline-block; width: 30%"></div>
        <div style="display: inline-block; width: 30%; vertical-align: middle">
          <img
            v-if="integratedTestConfig && integratedTestConfig.radiologyLogoUrl"
            :src="integratedTestConfig.radiologyLogoUrl"
            alt=""
          />
        </div>
      </div>
      <hr />
      <div class="mb-3">
        <template v-for="(orders, idx) in patientInfoSectionQnsOrder">
          <div class="row no-gutters" :key="idx">
            <div class="col-6" v-for="(qns, i) in orders" :key="i">
              <div v-if="print.formData[qns].type === 'input'">
                <label
                  v-if="print.formData[qns].label"
                  style="font-weight: 800"
                  class="w-50"
                  >{{ print.formData[qns].label }}:&nbsp;</label
                >
                <label v-else style="font-weight: 800" class="w-50"
                  >&nbsp;</label
                >
                <span class="w-50">{{ print.formData[qns].answer }}</span>
              </div>
            </div>
          </div>
        </template>
      </div>
      <hr />
      <div
        class="row mb-3"
        v-for="(qns, i) in reportDataSectionQnsOrder"
        :key="i + 'report'"
      >
        <b-card class="col-12">
          <div
            v-for="(res, index) in print.formData[qns].value"
            :key="index"
            class="mt-3"
          >
            <span v-html="formatResults(res)"></span>
          </div>
        </b-card>
      </div>
      <div class="row">
        <template v-for="(orders, idx) in signatureSectionQnsOrder">
          <div class="row no-gutters" :key="idx">
            <div class="col-6" v-for="(qns, i) in orders" :key="i">
              <div>
                <span>{{
                  extractRadiologistNameFromSignature(print.formData[qns].label)
                }}</span>
                <br />
                <span v-if="generalConfig.address">{{
                  generalConfig.address
                }}</span>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div class="mb-3">
        <div class="row px-3">
          <div
            v-for="(field, index) in readOnlyFields"
            :key="`footerfield-${index}`"
            class="col-12"
          >
            {{ field.label }}
          </div>
        </div>
        <hr class="mx-3" />
        <div
          class="row px-3"
          v-for="(qns, i) in signatureSectionQnsOrder"
          :key="i"
        >
          <span class="col-12">
            <span v-if="generalConfig.address">{{ printAddress }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import TypingNoteEditor from '@/components/TypingNoteEditor.vue';

export default {
  name: 'TypingNotePrint',
  props: {
    generalConfig: {
      type: Object,
      default: () => ({}),
    },
    integratedTestConfig: {
      type: Object,
      default: () => ({}),
    },
    print: {
      type: Object,
      default: () => ({}),
    },
    featureFlags: {
      type: Object,
      default: () => ({}),
    },
  },
  components: { TypingNoteEditor },
  data() {
    return {
      patientInfoSectionQnsOrder: [],
      reportDataSectionQnsOrder: [],
      signatureSectionQnsOrder: [],
    };
  },
  computed: {
    isTypingNoteReadOnly() {
      return Object.values(this.print.formData).every(e => e.qns === '');
    },
    logoMarginLeft() {
      if (localStorage.getItem('printSetup')) {
        return JSON.parse(localStorage.getItem('printSetup')).logoMarginLeft;
      }
      return 0;
    },
    logoWidth() {
      if (localStorage.getItem('printSetup')) {
        return JSON.parse(localStorage.getItem('printSetup')).logoWidth;
      }
      return 100;
    },
    printAddress() {
      if (this.generalConfig.useLocationAddress) {
        return this.selectedClinic.address || '';
      }
      return this.generalConfig.address || '';
    },
    readOnlyFields() {
      return Object.values(this.print.formData).filter(
        qns => qns.type === 'readonly',
      );
    },
  },
  methods: {
    formatResults(res) {
      if (!res || res === '') return res;
      const output = res.split('\\.br\\').join('<br>');
      return output;
    },
    extractRadiologistNameFromSignature(res) {
      if (!res || res === '') return res;
      const output = res.replace('Electronically signed by: ', '');
      return output;
    },
  },
  created() {
    console.log('this.print.formDataOrder ', this.print.formDataOrder);
    console.log('this.print, ', this.print);
    console.log('this.featureFlags', this.featureFlags);
    if (
      this.print.formDataOrder
      && !this.print.printFullNote
      && this.featureFlags.printTPH
    ) {
      for (let i = 0; i < this.print.formDataOrder.length; i += 1) {
        const qns = this.print.formDataOrder[i];
        const qnsObj = this.print.formData[qns];
        console.log(qnsObj);
        if (qnsObj.type === 'input') {
          this.patientInfoSectionQnsOrder.push(qns);
        } else if (qnsObj.type === 'formattedResult') {
          this.reportDataSectionQnsOrder.push(qns);
        } else if (
          qnsObj.type === 'readonly'
          && qnsObj.label
          && qnsObj.label.includes('Electronically')
        ) {
          this.signatureSectionQnsOrder.push(qns);
        }
      }
      this.patientInfoSectionQnsOrder = _.chunk(
        this.patientInfoSectionQnsOrder,
        2,
      );
      this.signatureSectionQnsOrder = _.chunk(this.signatureSectionQnsOrder, 2);
    }
  },
  async mounted() {
    setTimeout(() => {
      window.print();
    }, 2000);
  },
};
</script>
<style>
.w-50 {
  width: 50% !important;
}
</style>
<style src="../../assets/print-typing-note.css"></style>
<style lang="scss">
@media print {
  #typing-note-full-print {
    .mx-input:disabled, .mx-input.disabled,
    .el-input.is-disabled .el-input__inner,
    #fullForm input, #fullForm select, #fullForm input:disabled, #fullForm input[readonly], #fullForm input.el-input__inner
    {
      color: black;
    }
    #fullForm input {
      padding-right: 0;
    }
    .mx-icon-calendar {
      display: none;
    }
    .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
      background-color: black;
      color: black;
    }
    .custom-control-input:disabled ~ .custom-control-label::before {
      color: white;
      background-color: transparent;
      border-color: black;
    }
    .custom-control-input:disabled ~ .custom-control-label {
      color: black;
    }
    .custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
      background-color: black;
    }
  }

}
</style>
