// import config from '../config';
import api from './api';

function getAll(params = {}) {
  const t = `t=${new Date().getTime()}`;
  const q = [];
  q.push(t);
  Object.keys(params).forEach((key) => {
    q.push(`${key}=${params[key]}`);
  });
  const url = `/role/?${q.join('&')}`;
  return api.get(url)
    // .then(handleResponse)
    .then(res => res.data);
}

function createNew(data) {
  return api.post('/role', data);
}

function update(data) {
  return api.put(`/role/${data._id}`, data);
}

function bulkDestroy(data) {
  return api.post('/role/bulk', data);
}

function getFuctionByGroup(params = {}) {
  const t = `t=${new Date().getTime()}`;
  const q = [];
  q.push(t);
  Object.keys(params).forEach((key) => {
    q.push(`${key}=${params[key]}`);
  });
  const url = `/function/?${q.join('&')}`;
  return api.get(url)
    .then(res => res.data);
}

function getFuctionByFunctionId(functionId) {
  const url = `/function/${functionId}`;
  return api.get(url)
    .then(res => res.data);
}

export default {
  createNew,
  getAll,
  getFuctionByGroup,
  update,
  bulkDestroy,
  getFuctionByFunctionId
};
