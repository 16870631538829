var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("patient-letter", {
    attrs: {
      name: _vm.selectedLetter.name,
      text: _vm.selectedLetter.text,
      clinicId: _vm.selectedLetter.clinic,
      clinic: _vm.generalConfig,
      patient: _vm.patient,
      printLetterHeaderConfig: _vm.printLetterHeaderConfig,
      printLetterFooterConfig: _vm.printLetterFooterConfig,
      tabIndex: _vm.configIndexPos,
      preview: false
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }