export const SET_ALL_INPUTIVFLUID = 'SET_ALL_INPUTIVFLUID';
export const SET_ALL_INPUTIVMED = 'SET_ALL_INPUTIVMED';
export const SET_ALL_INPUTBLODDPRODUCT = 'SET_ALL_INPUTBLODDPRODUCT';
export const SET_ALL_OUTPUTURINE = 'SET_ALL_OUTPUTURINE';
export const SET_ALL_OUTPUTBOWEL = 'SET_ALL_OUTPUTBOWEL';
export const SET_ALL_OUTPUTVOMITOUS = 'SET_ALL_OUTPUTVOMITOUS';
export const SET_ALL_OUTPUTDRAINAGE = 'SET_ALL_OUTPUTDRAINAGE';
export const ADD_IOROW = 'ADD_IOROW';
export const SET_FILTERS = 'SET_FILTERS';
export const SET_META = 'SET_META';
export const SET_SUMMARY = 'SET_SUMMARY';
export const SET_MEDS = 'SET_MEDS';
