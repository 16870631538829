<template>
  <!-- eslint-disable max-len -->
  <div>
    <div class="row no-gutters form-group mb-2">
      <label class="col-sm-3" for="lmp_picker">EDD (from LMP)</label>
      <div class="col-sm-5">
        <el-date-picker
          id="lmp_picker"
          class="date-filter"
          v-model="form.lmpEDDDate"
          :disabled="viewing || printing"
          size="small"
          @change="onInputChange($event, 'lmp')"
          format="dd/MM/yyyy"
          type="date" />
      </div>
      <div class="col-sm-4">
        <b-form-radio
          v-model="form.radioChecked"
          :disabled="viewing || printing"
          :name="uuid" value="useLMP" @input="onRadioChange">Use To Calculate Gestational Age</b-form-radio>
      </div>
    </div>
    <div class="row no-gutters form-group">
      <label class="col-sm-3" for="us_picker">EDD (U/S)</label>
      <div class="col-sm-5">
        <el-date-picker
          id="us_picker"
          size="small"
          :disabled="viewing || printing"
          class="date-filter"
          v-model="form.usEDDDate"
          @change="onInputChange($event, 'us')"
          format="dd/MM/yyyy"
          type="date" />
      </div>
      <div class="col-sm-4">
        <b-form-radio
          v-model="form.radioChecked"
          :disabled="viewing || printing"
          :name="uuid" value="useUS" @input="onRadioChange">Use To Calculate Gestational Age</b-form-radio>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';

export default {
  name: 'GDCalcWidget',
  props: {
    qns: {
      type: String,
    },
    item: {
      type: Object,
      default: function fn() {},
    },
    updateTypingFormData: {
      type: Function,
      default: function fn() {},
    },
    viewing: {
      type: Boolean,
      default: false,
    },
    printing: {
      type: Boolean,
      default: false,
    },
    tick: {
      type: Number,
    },
  },
  watch: {
    tick() {
      this.refresh();
    },
  },
  mounted() {
    this.refresh();
  },
  data() {
    return {
      form: {
        usEDDDate: '',
        lmpEDDDate: '',
        radioChecked: '',
      },
      uuid: '',
    };
  },
  methods: {
    refresh() {
      const { answer } = this.item;
      const [usEDDDate, lmpEDDDate, radioChecked] = answer;
      this.form = {
        usEDDDate: usEDDDate || '',
        lmpEDDDate: lmpEDDDate || '',
        radioChecked: radioChecked || '',
      };
      this.updateAnswer();
    },
    updateAnswer() {
      const { usEDDDate, lmpEDDDate, radioChecked } = this.form;
      const result = this.calculate();
      const updated = [usEDDDate, lmpEDDDate, radioChecked, result];
      this.updateTypingFormData(updated, this.qns);
    },
    onInputChange() {
      this.updateAnswer();
    },
    onRadioChange() {
      this.updateAnswer();
    },
    calculate() {
      const { usEDDDate, lmpEDDDate, radioChecked } = this.form;
      if (!usEDDDate && !lmpEDDDate) return '';
      const today = moment(new Date());
      let edd;
      if (radioChecked === 'useUS') {
        edd = moment(usEDDDate);
      }
      if (radioChecked === 'useLMP') {
        edd = moment(lmpEDDDate);
      }
      if (!edd || !edd.isValid()) return '';
      const days = today.diff(edd, 'days') + 280;
      const weekAndDays = [Math.floor(days / 7), days % 7];
      return [days, weekAndDays];
    },
    /*
    emitChange() {
      const { affectedObj } = this.item;
      const result = this.calculate();
      if (!result) return;
      this.$emit('change', affectedObj, result);
    },
     */
  },
};
</script>
