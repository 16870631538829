import _ from 'lodash';

import api from './api';

export default {
  showMetadata({ patient, admission }) {
    const url = `/nursing-io/meta/${patient}/${admission}`;
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`List Error: ${response}, url: ${url}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },

  upsertMetadata({ patient, admission }, payload) {
    const url = `/nursing-io/meta/${patient}/${admission}`;

    return new Promise((resolve, reject) => {
      api.post(url, payload).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({
              data, message: '', success: true, error: null,
            });
          } else {
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },

  showRecords({ section, patient, admission }) {
    const url = `/nursing-io/${section}/${patient}/${admission}`;
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`List Error: ${response}, url: ${url}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },

  getTodayIVFluidSummary({ patient, admission }) {
    const url = `/nursing-io/ivfluid-summary/${patient}/${admission}`;
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`List Error: ${response}, url: ${url}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },

  getGrandIVFluidSummary({
    patient, admission, fromDate, toDate,
  }) {
    const url = `/nursing-io/grand-ivfluid-summary/${patient}/${admission}/${fromDate}/${toDate}`;
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`List Error: ${response}, url: ${url}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },

  getTodayIVMedSummary({ patient, admission }) {
    const url = `/nursing-io/ivmed-summary/${patient}/${admission}`;
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`List Error: ${response}, url: ${url}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },

  getGrandIVMedSummary({
    patient, admission, fromDate, toDate,
  }) {
    const url = `/nursing-io/grand-ivmed-summary/${patient}/${admission}/${fromDate}/${toDate}`;
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`List Error: ${response}, url: ${url}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },


  getTodayIBPSummary({ patient, admission }) {
    const url = `/nursing-io/ibp-summary/${patient}/${admission}`;
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`List Error: ${response}, url: ${url}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },

  getGrandTodayIBPSummary({
    patient, admission, fromDate, toDate,
  }) {
    const url = `/nursing-io/grand-ibp-summary/${patient}/${admission}/${fromDate}/${toDate}`;
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`List Error: ${response}, url: ${url}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },

  getTodayOUrineSummary({ patient, admission }) {
    const url = `/nursing-io/ourine-summary/${patient}/${admission}`;
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`List Error: ${response}, url: ${url}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },

  getGrandOUrineSummary({
    patient, admission, fromDate, toDate,
  }) {
    const url = `/nursing-io/grand-ourine-summary/${patient}/${admission}/${fromDate}/${toDate}`;
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`List Error: ${response}, url: ${url}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },

  getTodayOBowelSummary({ patient, admission }) {
    const url = `/nursing-io/obowel-summary/${patient}/${admission}`;
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`List Error: ${response}, url: ${url}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },

  getGrandOBowelSummary({
    patient, admission, fromDate, toDate,
  }) {
    const url = `/nursing-io/grand-obowel-summary/${patient}/${admission}/${fromDate}/${toDate}`;
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`List Error: ${response}, url: ${url}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },

  getTodayOVomitousSummary({ patient, admission }) {
    const url = `/nursing-io/ovomitous-summary/${patient}/${admission}`;
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`List Error: ${response}, url: ${url}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },

  getGrandOVomitousSummary({
    patient, admission, fromDate, toDate,
  }) {
    const url = `/nursing-io/grand-ovomitous-summary/${patient}/${admission}/${fromDate}/${toDate}`;
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`List Error: ${response}, url: ${url}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },

  getTodayODrainageSummary({ patient, admission }) {
    const url = `/nursing-io/odrainage-summary/${patient}/${admission}`;
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`List Error: ${response}, url: ${url}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },

  getGrandODrainageSummary({
    patient, admission, fromDate, toDate,
  }) {
    const url = `/nursing-io/grand-odrainage-summary/${patient}/${admission}/${fromDate}/${toDate}`;
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`List Error: ${response}, url: ${url}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },

  record({ section, patient, admission }, payload) {
    const url = `/nursing-io/${section}/${patient}/${admission}`;

    return new Promise((resolve, reject) => {
      api.post(url, payload).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            // TODO: Chan : Make this cooler
            // get at least one from array of errors
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },
};
