import CorporateService from '@/services/corporate.service';

const SET_ALL_CORPORATES = 'SET_ALL_CORPORATES';
const SET_CORPORATE = 'SET_CORPORATE';
const SET_ALL_PRICING_SCHEMES = 'SET_ALL_PRICING_SCHEMES';

const state = {
  all: [],
  selectedCorporate: {},
  formValid: false,
  filter: {},
  allPricingScheme: [],
};

/* eslint-disable no-param-reassign */
const mutations = {
  [SET_ALL_CORPORATES](_state, payload) {
    _state.all = payload;
  },
  [SET_CORPORATE](_state, payload) {
    _state.selectedCorporate = payload;
  },
  [SET_ALL_PRICING_SCHEMES](_state, payload) {
    _state.allPricingScheme = payload;
  },
};

const actions = {
  fetchAllCorporates({ commit }, param = {}) {
    return CorporateService.fetchAll(param)
      .then((response) => {
        commit(SET_ALL_CORPORATES, response.data);
      });
  },
  resetAll({ commit }) {
    commit(SET_ALL_CORPORATES, []);
  },
  setSelectedCorporate({ commit }, payload) {
    commit(SET_CORPORATE, payload);
  },
  setCorporate({ commit }, payload) {
    commit(UPDATE_Corporate, payload);
  },
  // eslint-disable-next-line no-shadow
  createNewCorporate({ commit }, newCorporate) {
    return CorporateService.createNew(newCorporate)
      .then((response) => {
        if (response.ok) {
          commit(SET_CORPORATE, response.data);
        }
        return response;
      });
  },
  // eslint-disable-next-line no-shadow
  updateCorporate({ commit }, updateCorporate) {
    return CorporateService.updateCorporate(updateCorporate._id, updateCorporate)
      .then((response) => {
        if (response.ok) {
          commit(SET_CORPORATE, response.data);
        }
        return response;
      });
  },
  bulkUpdateCorporate({ commit }, data) {
    return CorporateService.updateBulkCorporate(data)
      .then(response => response);
  },
  bulkDeleteCorporate({ commit }, data) {
    return CorporateService.deleteBulk(data)
      .then(response => response);
  },
  deleteCorporate({ commit }, id) {
    return CorporateService.delete(id)
      .then((response) => {
        if (response.ok) {
          commit(SET_CORPORATE, {});
        }
        return response;
      });
  },
  getCorporate({ commit }, id) {
    return CorporateService.getCorporate(id)
      .then((response) => {
        if (response.ok) {
          commit(SET_CORPORATE, response.data);
        }
        return response;
      });
  },
  getAllPricingScheme({ commit }, params = {}) {
    return CorporateService.getAllPricingScheme(params)
      .then((response) => {
        if (response.ok) {
          commit(SET_ALL_PRICING_SCHEMES, response.data);
        }
        return response.data;
      });
  },
};

const getters = {
  all: _state => _state.all,
  selectedCorporate: _state => _state.selectedCorporate,
  formValid: _state => _state.formValid,
  allPricingScheme: _state => _state.allPricingScheme,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
