<template>
  <!-- eslint-disable max-len -->
  <!-- NOTE:
    you may need the `bubble` menu editor :
    src/components/NoteEditor.vue
  -->
  <div id="xy-note-editor" :class="{ readonly: (viewing || printing) }">
    <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
      <div class="ProseMirror-menubar" v-if="!(viewing || printing)">
        <span :class="['ProseMirror-item', {'is-active': isActive.bold() }]"
          @click="commands.bold" >
          <div class="ProseMirror-icon">
            <v-icon name="bold" />
          </div>
        </span>
        <span :class="['ProseMirror-item', {'is-active': isActive.italic() }]"
          @click="commands.italic" >
          <div class="ProseMirror-icon">
            <v-icon name="italic" />
          </div>
        </span>
        <span :class="['ProseMirror-item', {'is-active': isActive.underline() }]"
          @click="commands.underline" >
          <div class="ProseMirror-icon">
            <v-icon name="underline" />
          </div>
        </span>
        <span :class="['ProseMirror-item', {'is-active': isActive.strike() }]"
          @click="commands.strike" >
          <div class="ProseMirror-icon">
            <v-icon name="strikethrough" />
          </div>
        </span>
        <span class="ProseMirror-menuseparator"></span>
        <span :class="['ProseMirror-item', {'is-active': isActive.heading({ level: 1}) }]"
          @click="commands.heading({ level: 1})" >
          <div class="ProseMirror-icon">
            <span class="xy-h1 h-icon">H1</span>
          </div>
        </span>
        <span :class="['ProseMirror-item', {'is-active': isActive.heading({ level: 2}) }]"
          @click="commands.heading({ level: 2})" >
          <div class="ProseMirror-icon">
            <span class="xy-h2 h-icon">H2</span>
          </div>
        </span>
        <span :class="['ProseMirror-item', {'is-active': isActive.heading({ level: 3}) }]"
          @click="commands.heading({ level: 3})" >
          <div class="ProseMirror-icon">
            <span class="xy-h3 h-icon">H3</span>
          </div>
        </span>
        <span class="ProseMirror-menuseparator"></span>
        <span :class="['ProseMirror-item', {'is-active': isActive.bullet_list()}]"
          @click="commands.bullet_list" >
          <div class="ProseMirror-icon">
            <v-icon name="list-ul" />
          </div>
        </span>
        <span :class="['ProseMirror-item', {'is-active': isActive.ordered_list()}]"
          @click="commands.ordered_list" >
          <div class="ProseMirror-icon">
            <v-icon name="list-ol" />
          </div>
        </span>
        <span class="ProseMirror-menuseparator"></span>
        <span class="ProseMirror-item"
          @click="commands.undo" >
          <div class="ProseMirror-icon">
            <v-icon name="undo" />
          </div>
        </span>
        <span class="ProseMirror-item"
          @click="commands.redo" >
          <div class="ProseMirror-icon">
            <v-icon name="redo" />
          </div>
        </span>
      </div>
    </editor-menu-bar>
    <editor-content :editor="editor" />
  </div>
</template>
<script>
import {
  Editor,
  EditorMenuBar,
  EditorContent,
} from 'tiptap';

import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
} from 'tiptap-extensions';


export default {
  components: {
    EditorContent,
    EditorMenuBar,
  },
  props: {
    qns: {
      type: String,
    },
    html: {
      type: String,
      default: '',
    },
    updateTypingFormData: {
      type: Function,
      default: function fn(/* answer, qns */) {},
    },
    editorBlur: {
      type: Function,
      default: function fn(/*  */) {},
    },
    viewing: {
      type: Boolean,
      default: false,
    },
    printing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editor: null,
      localJSON: '',
    };
  },
  watch: {
    viewing() {
      this.updateEditor();
    },
  },
  methods: {
    updateEditor() {
      if (this.editor) {
        this.editor.destroy();
      }
      const editable = !this.print && !this.viewing;
      try {
        this.localJSON = JSON.parse(this.html);
      } catch {
        this.localJSON = '';
      }
      this.editor = new Editor({
        editable,
        content: '',
        onUpdate: ({ getJSON }) => {
          this.localJSON = getJSON();
          const jsonString = JSON.stringify(this.localJSON);
          this.updateTypingFormData(jsonString, this.qns);
        },
        onFocus: () => {
        },
        onBlur: () => {
        },
        extensions: [
          new Blockquote(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new BulletList(),
          new OrderedList(),
          new ListItem(),
          new TodoItem(),
          new TodoList(),
          new Bold(),
          new Code(),
          new Italic(),
          new Link(),
          new Strike(),
          new Underline(),
          new History(),
        ],
      });
      this.editor.setContent(this.localJSON);
    },

  },
  mounted() {
    this.updateEditor();
  },
  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>
<style lang="scss" src="./editor.scss"></style>
