import healthScreeningService from '@/services/health-screening.service';
import healthScreeningTestResultsService from '@/services/health-screening-test-result.service';
import doctorRecommendationsService from '@/services/doctor-recommendation.service';
import healthScreeningTestSpecsService from '@/services/health-screening-test-specs.service';
import patchData from '@/components/HealthScreening/Recommendation/data/patch.json';

import {
  SET_HEALTHSCREENING,
  SET_ALL_HEALTHSCREENING,
  SET_TEST_RESULTS,
  SET_RAW_TEST_RESULTS,
  SET_DOCTOR_RECOMMENDATIONS,
  SET_PREV_TEST_IDS,
  SET_PREV_TEST_RESULTS,
  SET_HEALTH_SCREENING_TEST_SPECS,
  SET_HEALTH_SCREENING_TEST_SPECS_LIST,
  SET_SOCKET_ONLINE,
  SET_SOCKET_OFFLINE,
} from './type.mutation';

const state = {
  all: [],
  selectedHealthScreening: { inventory: { hsGenerateReport: true } },
  testResults: {},
  rawTestResults: {},
  doctorRecommendations: [],
  previousTestIDs: [],
  previousTestResults: {},
  healthScreeningTestSpecs: {},
  healthScreeningTestSpecsList: [],
  online: false,
};

function resultArrayToObject(results) {
  if (!results || results.length === 0) return {};
  const resultObject = results.reduce((ret, result) => ({
    ...ret,
    [result.ppSubtestCode]: result.result,
  }), {});
  return resultObject;
}

/* eslint-disable no-param-reassign */
const mutations = {
  [SET_HEALTHSCREENING](_state, payload) {
    _state.selectedHealthScreening = payload;
  },
  [SET_ALL_HEALTHSCREENING](_state, payload) {
    _state.all = payload;
  },
  [SET_TEST_RESULTS](_state, payload) {
    _state.testResults = payload;
  },
  [SET_RAW_TEST_RESULTS](_state, payload) {
    _state.rawTestResults = payload;
  },
  [SET_DOCTOR_RECOMMENDATIONS](_state, payload) {
    _state.doctorRecommendations = payload;
  },
  [SET_PREV_TEST_IDS](_state, payload) {
    _state.previousTestIDs = payload;
  },
  [SET_PREV_TEST_RESULTS](_state, payload) {
    _state.previousTestResults = payload;
  },
  [SET_HEALTH_SCREENING_TEST_SPECS](_state, payload) {
    _state.healthScreeningTestSpecs = payload;
  },
  [SET_HEALTH_SCREENING_TEST_SPECS_LIST](_state, payload) {
    _state.healthScreeningTestSpecsList = payload;
  },
  [SET_SOCKET_ONLINE](_state) {
    _state.online = true;
  },
  [SET_SOCKET_OFFLINE](_state) {
    _state.online = false;
  },
};
const actions = {
  socket_hsUpdateSelected({ dispatch, commit, state: _state }, payload) {
    if (_state.online) {
      const selectedHS = _state.selectedHealthScreening;
      const { rawId, patientId, shouldUpdateAll } = payload;
      if (shouldUpdateAll) {
        dispatch('fetchAllHealthScreeningsByPatient', { patientId });
      }
      if (rawId !== selectedHS._id) return;
      const updatedHS = {
        ...selectedHS,
        ...payload,
      };
      commit(SET_HEALTHSCREENING, updatedHS);
    }
  },
  reset({ commit }) {
    commit(SET_ALL_HEALTHSCREENING, []);
  },
  setSocket({ commit }, flag) {
    if (flag) {
      commit(SET_SOCKET_ONLINE);
    } else {
      commit(SET_SOCKET_OFFLINE);
    }
  },
  resetSelected({ commit }) {
    commit(SET_HEALTHSCREENING, {});
  },
  fetchAllHealthScreenings({ commit }, param = {}) {
    return healthScreeningService.fetchAll(param)
      .then((response) => {
        // sort HS list by createdAt.
        const hsList = [...response.data];
        hsList.sort((a, b) => {
          const aCreatedAt = new Date(a.createdAt);
          const bCreatedAt = new Date(b.createdAt);
          if (aCreatedAt < bCreatedAt) return 1;
          if (aCreatedAt > bCreatedAt) return -1;
          return 0;
        });
        commit(SET_ALL_HEALTHSCREENING, [...hsList]);
        return response.data;
      });
  },
  fetchAllHealthScreeningsByPatient({ commit }, param = {}) {
    const { patientId, ...rest } = param;
    return healthScreeningService.fetchAllByPatient(patientId, rest)
      .then((response) => {
        // sort HS list by createdAt.
        const hsList = [...response.data];
        hsList.sort((a, b) => {
          const aCreatedAt = new Date(a.createdAt);
          const bCreatedAt = new Date(b.createdAt);
          if (aCreatedAt < bCreatedAt) return 1;
          if (aCreatedAt > bCreatedAt) return -1;
          return 0;
        });
        commit(SET_ALL_HEALTHSCREENING, [...hsList]);
        return response.data;
      });
  },
  getPreviousTestResults({ commit, state: currentState }, fromHSCreatedAt) {
    const { all } = currentState;

    const previousHSList = all.filter(hs => new Date(hs.createdAt) <= new Date(fromHSCreatedAt)).slice(0, 3);
    previousHSList.sort((a, b) => {
      const aCreatedAt = new Date(a.createdAt);
      const bCreatedAt = new Date(b.createdAt);
      if (aCreatedAt < bCreatedAt) return 1;
      if (aCreatedAt > bCreatedAt) return -1;
      return 0;
    });
    const previousTests = {};
    const range = [0, 1, 2];
    range.forEach((idx) => {
      const hs = previousHSList[idx];
      if (!hs) return;
      const ret = {
        raw: hs,
        results: resultArrayToObject(hs.testResults.results),
      };
      previousTests[`t${idx}`] = ret;
    });
    commit(SET_PREV_TEST_RESULTS, previousTests);
  },
  getDoctorRecommendations({ commit }, param = {}) {
    return doctorRecommendationsService.fetchAll(param)
      .then((response) => {
        commit(SET_DOCTOR_RECOMMENDATIONS, response.data);
        return response.data;
      });
  },
  getHealthScreeningTestSpecs({ commit }, param = {}) {
    return healthScreeningTestSpecsService.fetchAll(param)
      .then((response) => {
        const list = response.data;
        const overrideItems = {};
        const appendItems = [];
        patchData.forEach((p) => {
          const resultInList = list.find(item => item.ppSubcode === p.ppSubcode);
          if (resultInList) {
            overrideItems[p.ppSubcode] = {
              ...resultInList,
              ...p,
            };
          } else {
            appendItems.push(p);
          }
        });
        const patched = list.map((item) => {
          const s = overrideItems[item.ppSubcode];
          if (s) return s;
          return item;
        });
        const overall = patched.concat(appendItems);
        commit(SET_HEALTH_SCREENING_TEST_SPECS_LIST, overall);
        const obj = overall.reduce((ret, current) => ({
          ...ret,
          [current.ppSubcode]: current,
        }), {});
        commit(SET_HEALTH_SCREENING_TEST_SPECS, obj);
        return response.data;
      });
  },
  setAllHealthScreenings({ commit }, payload) {
    commit(SET_ALL_HEALTHSCREENING, payload);
  },
  setSelectedHealthScreening({ commit }, payload) {
    commit(SET_HEALTHSCREENING, payload);
  },

  getTestResults({ commit }, payload) {
    commit('setLoading', true, { root: true });
    return healthScreeningTestResultsService.get(payload)
      .then((response) => {
        if (response.ok) {
          commit(SET_RAW_TEST_RESULTS, response.data);
          const { results } = response.data;
          const resultObject = resultArrayToObject(results);
          commit(SET_TEST_RESULTS, resultObject);
        }
        commit('setLoading', false, { root: true });
        return response.data;
      });
  },
  setTestResults({ commit }, payload) {
    const resultObject = resultArrayToObject(payload);
    commit(SET_TEST_RESULTS, resultObject);
  },
  updateHealthScreening({ commit }, payload) {
    const { id, data } = payload;
    const body = Object.assign({}, data);
    if (body.inventory && typeof body.inventory !== 'string') {
      body.inventory = data.inventory._id;
    }
    if (body.invoice && typeof body.invoice !== 'string') {
      body.invoice = data.invoice._id;
    }
    if (body.testResults && typeof body.testResults !== 'string') {
      body.testResults = data.testResults._id;
    }
    if (body.healthScreeningResultFiles && typeof body.healthScreeningResultFiles[0] !== 'string') {
      body.healthScreeningResultFiles = body.healthScreeningResultFiles.map(x => x._id);
    }
    commit('setLoading', true, { root: true });
    return healthScreeningService.updateHealthScreening(id, body)
      .then((response) => {
        if (response.ok) {
          commit(SET_HEALTHSCREENING, response.body);
        }
        commit('setLoading', false, { root: true });
        return response.body;
      });
  },
  patchHealthScreening({ commit }, payload) {
    const { id, data } = payload;
    const body = Object.assign({}, data);
    commit('setLoading', true, { root: true });
    return healthScreeningService.patchHealthScreening(id, body)
      .then((response) => {
        if (response.ok) {
          commit(SET_HEALTHSCREENING, response.body);
        }
        commit('setLoading', false, { root: true });
        return response.body;
      });
  },
  patchPersonalDetails({ commit }, payload) {
    const { id, data } = payload;
    const body = Object.assign({}, data);
    commit('setLoading', true, { root: true });
    return healthScreeningService.patchPersonalDetails(id, body)
      .then((response) => {
        if (response.ok) {
          commit(SET_HEALTHSCREENING, response.body);
        }
        commit('setLoading', false, { root: true });
        return response.body;
      });
  },
};

const getters = {
  all: _state => _state.all,
  healthScreeningObjects: _state => _state.all
    .reduce((ret, healthscreening) => ({
      ...ret,
      [healthscreening._id]: healthscreening,
    }), {}),
  selectedHealthScreening: _state => _state.selectedHealthScreening,
  selectedTestResults: _state => _state.testResults,
  selectedRawTestResults: _state => _state.rawTestResults,
  doctorRecommendations: _state => _state.doctorRecommendations,
  previousTestIDs: _state => _state.previousTestIDs,
  previousTestResults: _state => _state.previousTestResults,
  healthScreeningTestSpecs: _state => _state.healthScreeningTestSpecs,
  healthScreeningTestSpecsList: _state => _state.healthScreeningTestSpecsList,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
