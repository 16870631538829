import Vue from 'vue';
import notificationService from '../services/notification.service';

const state = {
  all: [],
  error: {},
  testNotifs: {},
  // fetchedSettingTemplates: false,
  notificationTypes: [],
  myNotificationSettings: [],
  totalUnread: 0,
};

const getters = {
  all: _state => _state.all.filter(n => !n.isDeleted),
  unread: (_state, _getters) => _getters.all.filter(n => !n.isRead),
};

const actions = {
  async getActive({ dispatch }, userId) {
    return dispatch('getAll', {
      receipientId: userId,
      isDeleted: false,
      sort: '-createdAt',
    });
  },
  async getAll({ commit, dispatch }, params) {
    console.log('getAll notifs params ', params);
    commit('resetAllNotifs');
    const getAllPromise = notificationService
      .getAll(params)
      .then((response) => {
        console.log('getAll notif repsonse is ', response);
        if (response.ok) {
          commit('setAllNotifs', response.data);
        }
      });

    await Promise.all([getAllPromise, dispatch('getTotalUnread', params)]);

    return getAllPromise;
  },
  async patch({ commit, dispatch, state }, params) {
    const response = await notificationService.patch(params);
    if (response.ok) {
      commit('patchNotif', params);

      if (params.isDeleted && response.data.receipientId) {
        // Get all active notifications after deletion
        await dispatch('getActive', response.data.receipientId);
      } else if (params.isRead !== undefined && params.isRead !== null) {
        // For read/unread toggle, just update total unread
        commit(params.isRead ? 'decreaseTotalUnread' : 'increaseTotalUnread');
      }
    }

    return response;
  },
  updateAll({ dispatch }, params) {
    return notificationService.updateAll(params).then((response) => {
      if (response.ok) {
        return dispatch('getActive', params._id);
      }
    });
  },
  getAllNotificationTypes({ commit }) {
    console.log('in getAllNotificationTypes action');
    // commit('hasFetchedSettingTemplates', false);
    return notificationService.getAllNotificationTypes()
      .then((response) => {
        console.log('getAll notif types response is ', response);
        if (response.ok) {
          commit('setAllNotifTypes', response.data);
          // commit('hasFetchedSettingTemplates', true);
          return response;
        }
      });
  },
  getTotalUnread({ commit }, params) {
    // Get total unread for the specific params filter
    return notificationService
      .getAll({ ...params, isRead: false })
      .then((response) => {
        if (response.ok) {
          commit('setTotalUnread', parseInt(response.headers.get('Total-Count'), 10) || 0);
        }
      });
  },
  socket_newNotification({ commit }, d) {
    /* eslint-disable no-console */
    console.log('VDSOCKET NEW NOTIFICATION', d);
    commit('addNotif', d);
    Vue.prototype.$flashStorage.flash(`Received new notification from ${d.actorName}`, 'success', { timeout: 5000 });
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  resetAllNotifs(_state) {
    console.log('1 in resetAllNotifs ', _state.all);
    _state.all = new Array();
    console.log('2 in resetAllNotifs ', _state.all);
  },
  setAllNotifs(_state, notifs) {
    _state.all = notifs;
  },
  setTotalUnread(_state, totalUnread) {
    _state.totalUnread = totalUnread;
  },
  increaseTotalUnread(_state) {
    _state.totalUnread++;
  },
  decreaseTotalUnread(_state) {
    _state.totalUnread--;
  },
  addNotif(_state, notif) {
    _state.all.push(notif);
  },
  setAllNotifTypes(_state, notificationTypes) {
    _state.notificationTypes = notificationTypes;
    // _state.myNotificationSettings = notifSettings;
  },
  // hasFetchedSettingTemplates(_state, toggle) {
  //   console.log('toggling fetchedSettingTemplates to ', toggle);
  //   _state.fetchedSettingTemplates = toggle;
  // },
  patchNotif(_state, { _id, ...param }) {
    console.log('in mtn patch - _id, param ', _id, param);
    _state.all = _state.all.map((n) => {
      if (n._id === _id) return Object.assign({}, n, param);
      return n;
    });
    console.log('_state.all ', _state.all);
    // _state.all = notifs;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
