var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c("flash-message", {
        staticClass: "template-flash",
        attrs: { transitionName: "fade" }
      }),
      _c(
        "div",
        { staticClass: "card gridify tiny", attrs: { id: "editing-canvas" } },
        [
          _vm.notesMapped[_vm.currentPageId] &&
          _vm.notesMapped[_vm.currentPageId].sessions
            ? _c("canvas-editor-history", {
                attrs: {
                  sessions: _vm.notesMapped[_vm.currentPageId].sessions,
                  closeHistory: _vm.closeHistory,
                  openSessions: _vm.openSessions,
                  toolbarPosition: _vm.toolbarPosition,
                  redrawSession: _vm.redrawSession
                }
              })
            : _vm._e(),
          _vm.showUploadManager
            ? _c("upload-manager", {
                staticStyle: { position: "absolute", "z-index": "3399" },
                attrs: {
                  closeUploadManager: _vm.closeUploadManager,
                  files: _vm.filesToBeUploaded,
                  uploadFiles: _vm.uploadFiles,
                  updateUploadLineItem: _vm.updateUploadLineItem,
                  categoriesOptions: _vm.filteredCategoriesOptions,
                  uploading: _vm.uploading
                }
              })
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "top-menu-bar",
              staticStyle: {
                position: "absolute",
                margin: "12px 0 0 4px",
                "z-index": "299"
              }
            },
            [
              _c(
                "b-form",
                { staticClass: "breadcrumbs", attrs: { inline: "" } },
                [
                  _vm.isEditingCategory
                    ? _c("b-form-select", {
                        attrs: {
                          size: "sm",
                          value: _vm.selectedCategory,
                          options: _vm.filteredCategoriesOptions,
                          state: _vm.editorCategoryState,
                          id: "editor-category",
                          autofocus: ""
                        },
                        on: {
                          change: function($event) {
                            _vm.updateEditorMetadata({
                              key: "categoryId",
                              value: $event
                            })
                            _vm.isEditingCategory = false
                          }
                        },
                        nativeOn: {
                          blur: function($event) {
                            _vm.updateEditorMetadata({
                              key: "categoryId",
                              value: $event.target.value
                            })
                            _vm.isEditingCategory = false
                          }
                        }
                      })
                    : _c(
                        "span",
                        {
                          on: {
                            click: function($event) {
                              _vm.isEditingCategory = true
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: { position: "relative", top: "-1px" }
                            },
                            [
                              _c("v-icon", {
                                attrs: {
                                  name: "circle",
                                  color: (
                                    _vm.categoriesMapped[
                                      _vm.selectedCategory
                                    ] || {}
                                  ).colorLabel
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "span",
                            { staticStyle: { "margin-left": "2px" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.categoriesMapped[_vm.selectedCategory]
                                    .name
                                )
                              )
                            ]
                          )
                        ]
                      ),
                  _c("span", [_vm._v("  /  ")]),
                  _vm.isEditingName
                    ? _c("b-input", {
                        attrs: {
                          id: "editor-name",
                          size: "sm",
                          value: _vm.editor.name,
                          state: _vm.editorNameState,
                          "invalid-feedback": "",
                          autofocus: "",
                          required: ""
                        },
                        on: {
                          input: function($event) {
                            return _vm.updateEditorMetadata({
                              key: "name",
                              value: $event
                            })
                          },
                          blur: function($event) {
                            _vm.emptyNameCheck()
                            _vm.isEditingName = false
                          }
                        }
                      })
                    : _c(
                        "span",
                        {
                          on: {
                            click: function($event) {
                              _vm.isEditingName = true
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.editor.name))]
                      )
                ],
                1
              ),
              _vm.openSessions
                ? _c("div", { staticClass: "revision-history-warning" }, [
                    _vm._v(
                      "All edits made in Revision History mode will not be saved"
                    )
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "toolbar__box--top-right",
                  staticStyle: { "z-index": "299" }
                },
                [
                  _vm.minusIconFunction && !_vm.editor.fromIPD
                    ? _c(
                        "span",
                        {
                          staticClass: "cursor-pointer",
                          staticStyle: { "margin-left": "10px" },
                          on: {
                            click: function($event) {
                              return _vm.minusIconFunction($event, "minimized")
                            }
                          }
                        },
                        [
                          _c("v-icon", {
                            attrs: {
                              name: "regular/window-minimize",
                              scale: "1"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.windowIconFunction && !_vm.editor.fromIPD
                    ? _c(
                        "span",
                        {
                          staticClass: "left-icon cursor-pointer",
                          on: {
                            click: function($event) {
                              _vm.windowIconFunction($event, "mediumSized")
                              _vm.clearAllListeners()
                            }
                          }
                        },
                        [
                          _c("v-icon", {
                            attrs: {
                              name: "external-link-alt",
                              scale: "2",
                              flip: "vertical"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.editor.fromIPD
                    ? _c(
                        "span",
                        {
                          staticClass: "left-icon cursor-pointer",
                          staticStyle: { margin: "3px 5px 0px 10px" },
                          on: {
                            click: function($event) {
                              return _vm.columnsIconFunction(
                                $event,
                                _vm.editor.IPDContainerName
                              )
                            }
                          }
                        },
                        [
                          _c("v-icon", {
                            attrs: { name: "columns", scale: "1.5" }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.timesIconFunction
                    ? _c(
                        "span",
                        {
                          staticClass: "cursor-pointer",
                          staticStyle: { "margin-top": "3px" },
                          on: {
                            click: function($event) {
                              return _vm.beforeTimesIconFunction($event)
                            }
                          }
                        },
                        [
                          _c("v-icon", {
                            attrs: { name: "times", scale: "1.5" }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            ],
            1
          ),
          _vm.type === "notetemplate"
            ? _c("upload-field", {
                staticStyle: { "z-index": "99" },
                attrs: { filesDropped: _vm.filesDropped }
              })
            : _vm._e(),
          _vm.showTemplateSelector
            ? _c(
                "vue-draggable-resizable",
                {
                  staticClass: "note-item charting-container",
                  style: { "z-index": 2999 },
                  attrs: {
                    resizable: false,
                    draggable: false,
                    x: _vm.x,
                    y: _vm.y
                  },
                  on: {
                    activated: function($event) {
                      return _vm.draggingClick()
                    }
                  }
                },
                [
                  _c("div", { staticClass: "label space-between" }, [
                    _c("span"),
                    _c("span", { staticStyle: { "margin-left": "6px" } }, [
                      _vm._v(_vm._s(_vm.$t("general.addPagesNote")))
                    ]),
                    _c(
                      "span",
                      {
                        staticStyle: { "margin-right": "6px" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            _vm.showTemplateSelector = false
                          }
                        }
                      },
                      [_c("v-icon", { attrs: { name: "times" } })],
                      1
                    )
                  ]),
                  _c("templates-container", {
                    staticStyle: {
                      height: "inherit",
                      width: "inherit",
                      "overflow-y": "scroll",
                      "overflow-x": "hidden",
                      padding: "10px",
                      margin: "2px -5px"
                    },
                    attrs: {
                      topRightIconFunction: _vm.addPagesFromTemplate,
                      topRightIcon: "brush",
                      parentComponent: "editing-page"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.showChartingContainer
            ? _c(
                "vue-draggable-resizable",
                {
                  staticClass: "note-item charting-container",
                  style: { "z-index": _vm.zIndexDragging },
                  attrs: { parent: true, resizable: false, x: _vm.x, y: _vm.y },
                  on: {
                    dragstart: _vm.onDragStart,
                    dragstop: _vm.onDragStop,
                    activated: function($event) {
                      return _vm.draggingClick()
                    }
                  }
                },
                [
                  _c("div", { staticClass: "label space-between" }, [
                    _c("span"),
                    _c("span", { staticStyle: { "margin-left": "6px" } }, [
                      _vm._v(_vm._s(_vm.$t("general.editOtherNotes")))
                    ]),
                    _c(
                      "span",
                      {
                        staticStyle: { "margin-right": "6px" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.toggleChartingContainer($event)
                          }
                        }
                      },
                      [_c("v-icon", { attrs: { name: "times" } })],
                      1
                    )
                  ]),
                  _c("charting-container", {
                    staticStyle: {
                      height: "inherit",
                      width: "inherit",
                      "overflow-y": "scroll",
                      "overflow-x": "hidden",
                      margin: "2px -5px"
                    },
                    attrs: {
                      parentComponent: "canvas-editor",
                      mainForm: _vm.notesMapped[_vm.editor.mainFormId],
                      currentNoteId: _vm.editor.noteId,
                      refreshCanvasEditor: _vm.refreshCanvasEditor,
                      invoice: _vm.invoicesMappedById[_vm.editor.invoiceId],
                      notesMapped: _vm.notesMapped,
                      categoriesMapped: _vm.categoriesMapped
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          !_vm.openSessions
            ? _c(
                "div",
                {
                  class: "middle-" + _vm.toolbarPosition + " middle-toolbar",
                  staticStyle: { "z-index": "1999" }
                },
                [
                  _c("div", { staticClass: "helpers mb-3" }, [
                    _c(
                      "div",
                      { on: { click: _vm.switchToolbarPosition } },
                      [
                        _c("v-icon", {
                          attrs: {
                            name: "angle-" + _vm.toolbarOppositePosition,
                            scale: "2"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        on: {
                          click: function($event) {
                            return _vm.deleteNodeText()
                          }
                        }
                      },
                      [
                        _c("v-icon", {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true }
                            }
                          ],
                          attrs: { name: "trash", scale: "2", title: "Delete" }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        on: {
                          click: function($event) {
                            return _vm.switchMode("pointer")
                          }
                        }
                      },
                      [
                        _c("v-icon", {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true }
                            }
                          ],
                          attrs: {
                            name: "mouse-pointer",
                            scale: "2",
                            color: _vm.mode === "pointer" ? "green" : "",
                            title: "Select"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        attrs: { id: _vm.toolbarPosition + "-pencil" },
                        on: {
                          click: function($event) {
                            return _vm.switchMode("pencil")
                          }
                        }
                      },
                      [
                        _c("v-icon", {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true }
                            }
                          ],
                          attrs: {
                            name: "pen",
                            scale: "2",
                            color: _vm.mode === "pencil" ? "green" : "",
                            title: "Brush"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        on: {
                          click: function($event) {
                            return _vm.switchMode("eraser")
                          }
                        }
                      },
                      [
                        _c("v-icon", {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true }
                            }
                          ],
                          attrs: {
                            name: "eraser",
                            scale: "2",
                            color: _vm.mode === "eraser" ? "green" : "",
                            title: "Eraser"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        attrs: { id: _vm.toolbarPosition + "-text" },
                        on: {
                          click: function($event) {
                            return _vm.switchMode("text")
                          }
                        }
                      },
                      [
                        _c("v-icon", {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true }
                            }
                          ],
                          attrs: {
                            name: "i-cursor",
                            scale: "2",
                            color: _vm.mode === "text" ? "green" : "",
                            title: "Text Box"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        attrs: { id: _vm.toolbarPosition + "-code" },
                        on: {
                          click: function($event) {
                            return _vm.switchMode("code")
                          }
                        }
                      },
                      [
                        _c("v-icon", {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true }
                            }
                          ],
                          attrs: {
                            name: "code",
                            scale: "2",
                            color: _vm.mode === "code" ? "green" : "",
                            title: "Auto Fill"
                          }
                        })
                      ],
                      1
                    ),
                    _vm.type === "note"
                      ? _c(
                          "div",
                          {
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.toggleChartingContainer($event)
                              }
                            }
                          },
                          [
                            _c("v-icon", {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true }
                                }
                              ],
                              attrs: {
                                name: "th",
                                scale: "2",
                                color: _vm.showChartingContainer ? "green" : "",
                                title: "Edit"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.type === "note"
                      ? _c(
                          "div",
                          {
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.toggleTemplateSelector()
                              }
                            }
                          },
                          [
                            _c("v-icon", {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true }
                                }
                              ],
                              attrs: {
                                name: "plus",
                                scale: "2",
                                color: _vm.showTemplateSelector ? "green" : "",
                                title: "Add"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.historyStep[_vm.currentPageId] > -1
                      ? _c(
                          "div",
                          {
                            on: {
                              click: function($event) {
                                return _vm.undo()
                              }
                            }
                          },
                          [
                            _c("v-icon", {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true }
                                }
                              ],
                              attrs: { name: "undo", scale: "2", title: "Undo" }
                            })
                          ],
                          1
                        )
                      : _c(
                          "div",
                          { staticStyle: { color: "grey" } },
                          [
                            _c("v-icon", {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true }
                                }
                              ],
                              attrs: { name: "undo", scale: "2", title: "Undo" }
                            })
                          ],
                          1
                        ),
                    _vm.historyStep[_vm.currentPageId] <
                    (_vm.histories[_vm.currentPageId] || []).length - 1
                      ? _c(
                          "div",
                          {
                            on: {
                              click: function($event) {
                                return _vm.redo()
                              }
                            }
                          },
                          [
                            _c("v-icon", {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true }
                                }
                              ],
                              attrs: { name: "redo", scale: "2", title: "Redo" }
                            })
                          ],
                          1
                        )
                      : _c(
                          "div",
                          { staticStyle: { color: "grey" } },
                          [
                            _c("v-icon", {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true }
                                }
                              ],
                              attrs: { name: "redo", scale: "2", title: "Redo" }
                            })
                          ],
                          1
                        ),
                    _vm.editor.draft === undefined || _vm.editor.draft
                      ? _c(
                          "div",
                          {
                            on: {
                              click: function($event) {
                                return _vm.save()
                              }
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "save-icon-wrapper" },
                              [
                                _c("v-icon", {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover",
                                      modifiers: { hover: true }
                                    }
                                  ],
                                  attrs: {
                                    name: "save",
                                    scale: "2",
                                    title: "Save"
                                  }
                                }),
                                _vm.hasUnsavedChanges
                                  ? _c("span", {
                                      staticClass: "unsaved-edits-badge"
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm.editor.publishable &&
                    (_vm.editor.draft === undefined || _vm.editor.draft)
                      ? _c(
                          "div",
                          {
                            on: {
                              click: function($event) {
                                return _vm.save(true)
                              }
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "save-icon-wrapper" },
                              [
                                _c("v-icon", {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover",
                                      modifiers: { hover: true }
                                    }
                                  ],
                                  attrs: {
                                    name: "stamp",
                                    scale: "2",
                                    title: "Stamp"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm.mode === "pencil" || _vm.mode === "eraser"
                      ? _c(
                          "div",
                          { staticClass: "input-container" },
                          [
                            _c(
                              "span",
                              {
                                attrs: {
                                  id: _vm.toolbarPosition + "-font-size"
                                }
                              },
                              [
                                _c("b-input", {
                                  staticClass: "input-field",
                                  attrs: {
                                    type: "number",
                                    title: "Brush size",
                                    size: "sm",
                                    value: _vm.strokeWidth[_vm.mode],
                                    disabled: ""
                                  }
                                })
                              ],
                              1
                            ),
                            _c("b-tooltip", {
                              attrs: {
                                target: _vm.toolbarPosition + "-font-size",
                                variant: "light",
                                triggers: "click blur",
                                placement: "" + _vm.toolbarOppositePosition,
                                "custom-class": "range-tool-tip"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.mode === "pencil"
                      ? _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "color-picker",
                              attrs: { title: "Brush Color" }
                            },
                            [
                              _c("swatches", {
                                attrs: {
                                  colors: "text-advanced",
                                  swatchSize: "22",
                                  "popover-to":
                                    "" + _vm.toolbarOppositePosition,
                                  "trigger-style": {
                                    width: "26px",
                                    height: "26px"
                                  }
                                },
                                model: {
                                  value: _vm.strokeColor,
                                  callback: function($$v) {
                                    _vm.strokeColor = $$v
                                  },
                                  expression: "strokeColor"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm.mode === "text" ||
                    _vm.mode === "code" ||
                    _vm.activeText !== null
                      ? _c(
                          "div",
                          { staticClass: "input-container" },
                          [
                            _c(
                              "span",
                              {
                                attrs: {
                                  id: _vm.toolbarPosition + "-text-size"
                                }
                              },
                              [
                                _c("b-input", {
                                  staticClass: "input-field",
                                  attrs: {
                                    type: "number",
                                    title: "Brush size",
                                    size: "sm",
                                    value: _vm.textSize,
                                    disabled: ""
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "b-tooltip",
                              {
                                attrs: {
                                  target: _vm.toolbarPosition + "-text-size",
                                  variant: "light",
                                  triggers: "click blur",
                                  placement: "" + _vm.toolbarOppositePosition,
                                  "custom-class": "range-tool-tip"
                                }
                              },
                              [
                                _c("b-form-input", {
                                  staticClass: "input-range",
                                  attrs: {
                                    value: _vm.textSize,
                                    type: "range",
                                    min: "2",
                                    max: "50",
                                    step: "2",
                                    orient: "vertical"
                                  },
                                  on: { input: _vm.updateTextSize }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.mode === "text" ||
                    _vm.mode === "code" ||
                    _vm.activeText !== null
                      ? _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "color-picker",
                              attrs: { title: "Brush Color" }
                            },
                            [
                              _c("swatches", {
                                attrs: {
                                  value: _vm.textColor,
                                  colors: "text-advanced",
                                  "popover-to":
                                    "" + _vm.toolbarOppositePosition,
                                  "trigger-style": {
                                    width: "26px",
                                    height: "26px"
                                  }
                                },
                                on: { input: _vm.updateTextColor }
                              }),
                              _c("span", { staticClass: "tooltiptext" }, [
                                _vm._v("Brush Colorrtr")
                              ])
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _c("div", [
                      _c(
                        "span",
                        {
                          on: {
                            click: function($event) {
                              _vm.openPermissionsModal = !_vm.openPermissionsModal
                            }
                          }
                        },
                        [
                          _c("v-icon", {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true }
                              }
                            ],
                            staticClass: "cursor-pointer",
                            attrs: { name: "lock", scale: "1", title: "lock" }
                          })
                        ],
                        1
                      ),
                      _vm._v(" /\n                    "),
                      _c(
                        "span",
                        {
                          on: {
                            click: function($event) {
                              _vm.openSessions = !_vm.openSessions
                            }
                          }
                        },
                        [
                          _c("v-icon", {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true }
                              }
                            ],
                            staticClass: "cursor-pointer",
                            attrs: {
                              name: "info-circle",
                              scale: "1",
                              title: "Info"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "mb-3",
                      class: _vm.toolbar_position !== "left" ? "hide" : null
                    },
                    [
                      _vm.editor.pagesIdOrder.length > 1
                        ? _c("div", { staticClass: "page-navigators" }, [
                            _vm.currentPageIndex !== 0
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "left-arrow",
                                    on: {
                                      click: function($event) {
                                        return _vm.goToPage2(
                                          _vm.currentPageIndex - 1
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("v-icon", {
                                      attrs: {
                                        name: "angle-left",
                                        scale: "1.7"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _c(
                                  "span",
                                  {
                                    staticClass: "left-arrow",
                                    staticStyle: { color: "grey" }
                                  },
                                  [
                                    _c("v-icon", {
                                      attrs: {
                                        name: "angle-left",
                                        scale: "1.7"
                                      }
                                    })
                                  ],
                                  1
                                ),
                            _c("span", { staticClass: "page-number" }, [
                              _vm._v(
                                _vm._s(_vm.currentPageIndex + 1) +
                                  " / " +
                                  _vm._s(_vm.editor.pagesIdOrder.length)
                              )
                            ]),
                            _vm.currentPageIndex !==
                            _vm.editor.pagesIdOrder.length - 1
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "right-arrow",
                                    on: {
                                      click: function($event) {
                                        return _vm.goToPage2(
                                          _vm.currentPageIndex + 1
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("v-icon", {
                                      attrs: {
                                        name: "angle-right",
                                        scale: "1.7"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _c(
                                  "span",
                                  {
                                    staticClass: "right-arrow",
                                    staticStyle: { color: "grey" }
                                  },
                                  [
                                    _c("v-icon", {
                                      attrs: {
                                        name: "angle-right",
                                        scale: "1.7"
                                      }
                                    })
                                  ],
                                  1
                                )
                          ])
                        : _vm._e()
                    ]
                  )
                ]
              )
            : _vm._e(),
          _c("div", { staticClass: "middle space-between" }, [
            _c(
              "div",
              {
                staticClass: "middle-middle",
                class: { "scroll-mode": _vm.featureFlags.scrollNoteEditor },
                style: { "z-index": _vm.zIndexCanvas }
              },
              [
                _vm.editor.currentPageId !== null &&
                _vm.editor.currentPageId !== undefined
                  ? _c("canvas-editor", {
                      ref: "canvasEditor",
                      attrs: {
                        type: _vm.type,
                        overlayClick: _vm.overlayClick,
                        mode: _vm.mode,
                        orientation: _vm.orientation,
                        originalOrientation: _vm.originalOrientation,
                        switchMode: _vm.switchMode,
                        toggleTypingMode: _vm.toggleTypingMode,
                        strokeColor: _vm.strokeColor,
                        strokeWidth: _vm.strokeWidth,
                        textSize: _vm.textSize,
                        textColor: _vm.textColor,
                        setActiveText: _vm.setActiveText,
                        openSessions: _vm.openSessions
                      }
                    })
                  : _vm._e(),
                _c("span", { staticClass: "dock-container" }, [
                  _vm.editor.pagesIdOrder.length > 0
                    ? _c(
                        "ul",
                        { staticClass: "dock" },
                        [
                          _vm._l(_vm.editor.pagesIdOrder, function(
                            pageId,
                            index
                          ) {
                            return _c(
                              "li",
                              {
                                key:
                                  (_vm.notesMapped[pageId] || {}).updatedAt +
                                  pageId,
                                on: {
                                  click: function($event) {
                                    return _vm.goToPage2(index)
                                  }
                                }
                              },
                              [
                                _vm.editor.pages[pageId].tempBase64 ||
                                _vm.editor.pages[pageId].signedThumbnailKey
                                  ? _c("img", {
                                      attrs: {
                                        src:
                                          _vm.editor.pages[pageId].tempBase64 ||
                                          _vm.editor.pages[pageId]
                                            .signedThumbnailKey
                                      }
                                    })
                                  : _vm._e()
                              ]
                            )
                          }),
                          _vm.type === "notetemplate"
                            ? _c(
                                "li",
                                {
                                  staticClass: "add-img-to-template-container"
                                },
                                [
                                  _c(
                                    "form",
                                    {
                                      staticClass:
                                        "add-img-to-template btn btn-secondary",
                                      attrs: { enctype: "multipart/form-data" }
                                    },
                                    [
                                      _c("input", {
                                        staticClass: "input-file",
                                        attrs: {
                                          type: "file",
                                          accept:
                                            "image/jpeg,image/png,application/pdf"
                                        },
                                        domProps: { value: _vm.inputFileValue },
                                        on: {
                                          change: function($event) {
                                            return _vm.addPagesToTemplate(
                                              $event.target.files
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(
                                        "\n                            Upload\n                            "
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        " More\n                          "
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        2
                      )
                    : _vm._e()
                ])
              ],
              1
            )
          ]),
          _vm.openPermissionsModal
            ? _c(
                "div",
                {
                  staticStyle: {
                    position: "absolute",
                    display: "flex",
                    "justify-content": "space-around",
                    "align-items": "center",
                    "background-color": "rgba(167,167,167,0.8)",
                    height: "100vh",
                    width: "100vw",
                    "z-index": "3000"
                  }
                },
                [
                  _c("div", { staticClass: "permissions-modal" }, [
                    _c("div", { staticClass: "modal-header" }, [
                      _vm.type == "notetemplate"
                        ? _c("div", [
                            _c("div", [
                              _vm._v(
                                "Permissions for Notes Created from this Template"
                              )
                            ]),
                            _c("div", [
                              _vm._v(
                                "(existing notes created from this template will not be affected by new changes)"
                              )
                            ])
                          ])
                        : _c("div", [
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm.$t("general.permissionsForNote"))
                              )
                            ])
                          ]),
                      _c(
                        "div",
                        {
                          staticClass: "cursor-pointer",
                          on: {
                            click: function($event) {
                              _vm.resetPermissions()
                              _vm.openPermissionsModal = false
                            }
                          }
                        },
                        [_vm._v("X")]
                      )
                    ]),
                    _c("div", { staticClass: "modal-body" }, [
                      _c(
                        "div",
                        [
                          _c(
                            "b-table",
                            {
                              attrs: {
                                striped: "",
                                hover: "",
                                fields: _vm.fields,
                                items: _vm.items
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "name",
                                    fn: function(data) {
                                      return [
                                        _c("b", { staticClass: "text-info" }, [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.userAndRolesDictionary[
                                                  data.item.value
                                                ]
                                              )
                                            )
                                          ]),
                                          _vm.type == "note" &&
                                          data.item.value ==
                                            (_vm.coverPage.creatorId._id ||
                                              _vm.coverPage.creatorId.id)
                                            ? _c("span", [_vm._v(" (Creator)")])
                                            : _vm._e()
                                        ])
                                      ]
                                    }
                                  },
                                  {
                                    key: "update",
                                    fn: function(data) {
                                      return [
                                        _c("b-form-checkbox", {
                                          attrs: {
                                            disabled:
                                              data.item.value === "creator" ||
                                              (_vm.type == "note" &&
                                                data.item.value ==
                                                  _vm.coverPage.creatorId._id)
                                          },
                                          on: {
                                            input: function($event) {
                                              return _vm.updatePermissionRow(
                                                data
                                              )
                                            }
                                          },
                                          model: {
                                            value: data.item.update,
                                            callback: function($$v) {
                                              _vm.$set(data.item, "update", $$v)
                                            },
                                            expression: "data.item.update"
                                          }
                                        })
                                      ]
                                    }
                                  },
                                  {
                                    key: "action",
                                    fn: function(data) {
                                      return [
                                        !(
                                          _vm.type === "notetemplate" &&
                                          data.item.value === "creator"
                                        ) &&
                                        !(
                                          _vm.type === "note" &&
                                          data.item.value ===
                                            _vm.coverPage.creatorId._id
                                        )
                                          ? _c(
                                              "b",
                                              {
                                                staticClass: "text-info",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.removePermissionRow(
                                                      data
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "X\n                                "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1667673419
                              )
                            },
                            [
                              _c("template", { slot: "table-caption" }, [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selected,
                                        expression: "selected"
                                      }
                                    ],
                                    staticClass: "mb-3",
                                    on: {
                                      change: [
                                        function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.selected = $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        },
                                        _vm.addNewPermissionRow
                                      ]
                                    }
                                  },
                                  [
                                    _c(
                                      "option",
                                      { domProps: { value: null } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("general.addPermission")
                                          )
                                        )
                                      ]
                                    ),
                                    _c(
                                      "optgroup",
                                      {
                                        attrs: {
                                          label: _vm.$t("general.generalRole")
                                        }
                                      },
                                      [
                                        _c(
                                          "option",
                                          {
                                            attrs: {
                                              value: "anyone",
                                              disabled:
                                                _vm.allSelectedPermissions[
                                                  "anyone"
                                                ]
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("general.anyone"))
                                            )
                                          ]
                                        ),
                                        _vm.type === "notetemplate"
                                          ? _c(
                                              "option",
                                              {
                                                attrs: {
                                                  value: "creator_own_roles",
                                                  disabled:
                                                    _vm.allSelectedPermissions[
                                                      "creator_own_roles"
                                                    ]
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "Users from Creator's Role(s)"
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    ),
                                    _c(
                                      "optgroup",
                                      {
                                        attrs: {
                                          label: _vm.$t("general.byRoles")
                                        }
                                      },
                                      _vm._l(_vm.allRoles, function(role, i) {
                                        return _c(
                                          "option",
                                          {
                                            key: role._id + i,
                                            attrs: {
                                              disabled:
                                                _vm.allSelectedPermissions[
                                                  role._id
                                                ]
                                            },
                                            domProps: { value: role._id }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.userAndRolesDictionary[
                                                  role._id
                                                ]
                                              ) +
                                                "\n                                        "
                                            )
                                          ]
                                        )
                                      }),
                                      0
                                    ),
                                    _c(
                                      "optgroup",
                                      {
                                        attrs: {
                                          label: _vm.$t(
                                            "general.byIndividualUsers"
                                          )
                                        }
                                      },
                                      _vm._l(_vm.allUsers, function(user, i) {
                                        return _c(
                                          "option",
                                          {
                                            key: user.id + i,
                                            attrs: {
                                              disabled:
                                                _vm.allSelectedPermissions[
                                                  user.id
                                                ]
                                            },
                                            domProps: { value: user.id }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.userAndRolesDictionary[
                                                  user.id
                                                ]
                                              ) +
                                                "\n                                        "
                                            )
                                          ]
                                        )
                                      }),
                                      0
                                    )
                                  ]
                                )
                              ])
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "modal-footer" },
                      [
                        _c(
                          "b-button",
                          {
                            on: {
                              click: function($event) {
                                _vm.resetPermissions()
                                _vm.openPermissionsModal = false
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("general.cancel")))]
                        ),
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "success" },
                            on: {
                              click: function($event) {
                                _vm.openPermissionsModal = false
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("pharmacy.confirm")))]
                        )
                      ],
                      1
                    )
                  ])
                ]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }