<template>
  <!-- eslint-disable max-len -->
  <div>
    <div class="row no-gutters form-group mb-2">
      <label class="col-sm-3">{{ item.label }}</label>
      <div class="col-sm-9">
        <input type="text" v-if="!showNil" class="form-control" placeholder="NIL" v-model="firstIpt" @change="updateAnswer" :disabled="viewing || printing">
        <label class="col-sm-3" v-else>NIL</label>
      </div>
    </div>
    <div class="row no-gutters form-group mb-2" v-if="!showNil">
      <label class="col-sm-3"></label>
      <div class="col-sm-9">
        <input type="text" class="form-control" v-model="secondIpt" @change="updateAnswer" :disabled="viewing || printing">
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'NoAnswerNil',
  props: {
    qns: {
      type: String,
    },
    item: {
      type: Object,
      default: function fn() {},
    },
    updateTypingFormData: {
      type: Function,
      default: function fn() {},
    },
    viewing: {
      type: Boolean,
      default: false,
    },
    printing: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    const { answer } = this.item;
    if (!Array.isArray(answer)) return;
    const [firstIpt, secondIpt] = answer;
    if (!firstIpt && !secondIpt) {
      this.showNil = true;
      return;
    }
    const initValue = 'init103187';
    this.firstIpt = (!firstIpt || firstIpt === initValue) ? '' : firstIpt;
    this.secondIpt = (!secondIpt || secondIpt === initValue) ? '' : secondIpt;
    this.updateAnswer();
  },
  data() {
    return {
      firstIpt: '',
      secondIpt: '',
      showNil: false,
    };
  },
  methods: {
    updateAnswer() {
      const updated = [this.firstIpt, this.secondIpt];
      this.updateTypingFormData(updated, this.qns);
    },
  },
};
</script>
