/* eslint-disable no-param-reassign */
import creditService from '@/services/credit.service';
import { SET_CREDITS, CREATE_CREDITS, SET_SELECTED_CREDITNOTE } from './type.mutation';

const state = {
  all: [],
  credit: {},
  selectedCreditNote: {},
};

const mutations = {
  [SET_CREDITS](_state, payload) {
    _state.all = payload;
  },
  [CREATE_CREDITS](_state, payload) {
    _state.credit = payload;
  },
  [SET_SELECTED_CREDITNOTE](_state, payload) {
    _state.selectedCreditNote = payload;
  },
};

const actions = {
  createCredit({ commit }, { credit, invoice }) {
    return creditService.create(credit, { invoice })
      .then((data) => {
        // TODO: fetch credit here
        commit(CREATE_CREDITS, data.credit);
        return data;
      });
  },
  createCreditProvider({ commit }, { credit, invoice }) {
    return creditService.createProvider(credit, { invoice })
      .then((data) => {
        // TODO: fetch credit here
        commit(CREATE_CREDITS, data.credit);
        return data;
      });
  },
  voidCredit({ commit }, { credit, invoice, reason }) {
    return creditService.voidCredit({ credit, invoice, reason })
      .then((response) => {
        commit(CREATE_CREDITS, response.credit);
        return response;
      });
  },
  refundCredit({ commit }, { credit, feedback, signature }) {
    return creditService.refundCredit({ credit, feedback, signature })
      .then((response) => {
        commit(CREATE_CREDITS, response.credit);
        return response;
      });
  },
  sendEmailFeedbackReport({ commit }, { credit, pdfBlob }) {
    return creditService.sendEmailFeedbackReport(credit, pdfBlob)
      .then(response => response);
  },
};

const getters = {
  all: _state => _state.all,
  selectedCreditNote: _state => _state.selectedCreditNote,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
