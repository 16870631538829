<template>
  <div>
    <purchase-order-print
      :generalConfig="general"
      :invoiceConfig="invoice"
      :inventoryConfig="inventory"
      :print="print"
    />
  </div>
</template>

<script>
import PurchaseOrderPrint from '@/components/Print/PurchaseOrderPrint.vue';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'PurchaseOrder',
  components: {
    PurchaseOrderPrint,
  },
  computed: {
    ...mapState('purchaseorder', ['print']),
    ...mapGetters('config', ['general', 'invoice', 'inventory']),
  },
};
</script>
