<template>
  <div :class="{ 'vdp-datepicker': lib === 'vuedate'}">
    <div v-if="lib === 'el'">
      <el-date-picker-custom
        ref="customDatePicker"
        :class="['date-filter el-custom-input', parentClass]"
        v-model="dateSelectedOld"
        :type="elDateType"
        :disabled="disabled"
        :placeholder="placeholder"
        :picker-options="pickerOptions"
        :format="format"
      />
      <label class="el-custom-label" @click="handleElClick">
        {{ dateSelectedOld | formatVDDate({ format: (format && format.toUpperCase()) || 'DD/MM/YYYY HH:mm:ss' }) }}
      </label>
    </div>
    <datepicker
      v-else-if="lib === 'vuedate'"
      :format="formatDate"
      v-model="dateSelectedOld"
      :disabled="disabled"
      :language="getLanguage()"
      :disabled-dates="{ customPredictor: disabledDatesFn }"
      :placeholder="placeholder"
      :input-class="['form-control text-center date-picker-input', inputClass, parentClass]"
      :name="name"
      :inline="inline"
      :calendar-class="calendarClass"
      @selected="handleSelected"
    />
    <span v-else> Lib Not Supported </span>
  </div>
</template>

<script>
import moment from 'moment';
import Dateformatter from '@/components/mixins/dateformatter';
import Datepicker from 'vuejs-datepicker';
import { DatePicker as ElDatePickerCustom } from 'element-ui';
import { extractDateFormat } from 'element-ui/src/utils/date-util';

export default {
  name: 'VdDatePicker',
  mixins: [Dateformatter],
  components: {
    Datepicker,
    ElDatePickerCustom,

  },
  props: {
    name: { type: String, default: 'date' },
    value: { type: [Date, Number, String, Array] },
    valueFormat: { type: String, default: null },
    placeholder: { type: String, default: '' },
    lib: { type: String, default: 'el' },
    format: { type: String, default: 'yyyy/MM/dd' },
    valueFormat: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    disabledDatesFn: { type: Function, default: date => false },
    handleSelected: { type: Function, default: () => {} },
    inline: { type: Boolean, default: false },
    calendarClass: { type: String, default: '' },
    inputClass: { type: String, default: '' },
    elDateType: { type: String, default: 'date' },

  },
  data() {
    return {
      date: null,
      parentClass: '',
    };
  },
  computed: {
    dateSelectedOld: {
      get() {
        if (!this.value) {
          return null;
        }
        const value = this.valueFormat
          ? moment(this.value, this.valueFormat).toDate()
          : new Date(this.value);
        // const year = value.getFullYear() + 543;
        // value.setFullYear(year);
        return value;
      },
      set(val) {
        if (val) {
          const value = this.valueFormat
            ? moment(val, this.valueFormat).format(this.valueFormat)
            : new Date(val);
          // const year = value.getFullYear() - 543;
          // value.setFullYear(year);
          // alert(value);
          this.$emit('input', value);
          // this.value = value;
        } else {
          // this.value = null;
          // alert(null);
          this.$emit('input', null);
        }
      },
    },

    pickerOptions() {
      return {
        disabledDate: date => this.disabledDatesFn(date),
      };
    },
    defaultValue() {
      return (moment.locale() == 'th')
        ? new Date().setFullYear(new Date().getFullYear() + 543)
        : new Date();
    },
    dateValue() {
      return this.toVDDate(this.value); // This checks language selected
    },
    dateSelected: {
      get() {
        // alert('TRANSFORMED VAL (raw): ' + this.value);
        const uiVal = this.toVDDate(this.value); // This checks language selected
        // alert('TRANSFORMED VAL: ' + uiVal);
        return uiVal;
      },
      set(val) {
        // alert('DATA VAL (raw): ' + val);
        const newVal = this.fromVDDateToDate(val);
        // alert('DATA VAL: ' + newVal);
        this.$emit('input', newVal);
      },
    },
    language() {
      return moment.locale();
    },
  },
  methods: {
    handleElClick() {
      this.$refs.customDatePicker.showPicker();
    },
    formatDate(value) {
      return this.formatVDDate(value, { format: this.format });
    },
    overrideElDatePickerFunctions() {
      ElDatePickerCustom.created();
      this.$nextTick((_) => {
        const selectedLocale = this.$i18n.locale;
        const getBuddhistYear = currentYear => currentYear + 543;
        const self = this;

        ElDatePickerCustom.panel.computed.yearLabel = function overrideYearLabel() {
          const yearTranslation = this.t('el.datepicker.year');
          if (this.currentView === 'year') {
            let startYear = Math.floor(this.year / 10) * 10;
            if (selectedLocale === 'th') {
              startYear = getBuddhistYear(startYear);
            }
            if (yearTranslation) {
              return `${startYear} ${yearTranslation} - ${startYear + 9} ${yearTranslation}`;
            }
            return `${startYear} - ${startYear + 9}`;
          }
          let { year } = this;
          if (selectedLocale === 'th') {
            year = getBuddhistYear(year);
          }
          return `${year} ${yearTranslation}`;
        };
        ElDatePickerCustom.panel.computed.visibleDate = function overrideVisibleDate() {
          if (this.userInputDate) {
            return this.userInputDate;
          }

          const dateFormat = this.format
            ? extractDateFormat(this.format)
            : 'YYYY-MM-DD';

          return self.formatVDDate(this.value || this.defaultValue, { format: dateFormat.toUpperCase() });
        };
        /** ************************
         * YEAR TABLE
         ************************* */
        ElDatePickerCustom.panel.components.YearTable.computed.startYear = function overrideStartYear() {
          let startYear = Math.floor(this.date.getFullYear() / 10) * 10;
          if (selectedLocale === 'th') {
            startYear = getBuddhistYear(startYear);
          }

          return startYear;
        };
        ElDatePickerCustom.panel.components.YearTable.methods.handleYearTableClick = function overrideHandleYearTableClick(event) {
          const { target } = event;
          if (target && target.tagName === 'A') {
            const year = Number(target.textContent || target.innerText);
            const newYear = (selectedLocale === 'th') ? year - 543 : year;
            this.$emit('pick', Number(newYear));
          }
        };
      });
    },

    overrideDatePickerFunctions() {
      const selectedLocale = this.$i18n.locale;
      const getBuddhistYear = currentYear => currentYear + 543;
      Datepicker.components.PickerDay.computed.currYearName = function overrideCurrYearName() {
        const { yearSuffix } = this.translation;
        let year = this.utils.getFullYear(this.pageDate);
        if (selectedLocale === 'th') {
          year = getBuddhistYear(year);
        }

        return ''.concat(year).concat(yearSuffix);
      };
      Datepicker.components.PickerMonth.computed.pageYearName = function overridePageYearName() {
        const { yearSuffix } = this.translation;
        let year = this.utils.getFullYear(this.pageDate);
        if (selectedLocale === 'th') {
          year = getBuddhistYear(year);
        }

        return ''.concat(year).concat(yearSuffix);
      };
      Datepicker.components.PickerYear.computed.years = function overrideYears() {
        const d = this.pageDate;
        const years = [];
        const dObj = this.useUtc
          ? new Date(Date.UTC(Math.floor(d.getUTCFullYear() / 10) * 10, d.getUTCMonth(), d.getUTCDate()))
          : new Date(Math.floor(d.getFullYear() / 10) * 10, d.getMonth(), d.getDate(), d.getHours(), d.getMinutes());
        for (let i = 0; i < 10; i++) {
          let year = this.utils.getFullYear(dObj);
          if (selectedLocale === 'th') {
            year = getBuddhistYear(year);
          }

          years.push({
            year,
            timestamp: dObj.getTime(),
            isSelected: this.isSelectedYear(dObj),
            isDisabled: this.isDisabledYear(dObj),
          });
          this.utils.setFullYear(dObj, this.utils.getFullYear(dObj) + 1);
        }
        return years;
      };

      Datepicker.components.PickerYear.computed.getPageDecade = function overrideGetPageDecade() {
        let year = (Math.floor(this.utils.getFullYear(this.pageDate) / 10) * 10);
        if (selectedLocale === 'th') {
          year = getBuddhistYear(year);
        }

        const decadeStart = year;
        const decadeEnd = decadeStart + 9;
        const { yearSuffix } = this.translation;
        return `${decadeStart} - ${decadeEnd}${yearSuffix}`;
      };
    },
  },
  created() {
    this.overrideDatePickerFunctions();
    this.overrideElDatePickerFunctions();
    this.$nextTick((_) => {
      this.parentClass = this.$el.className;
    });
  },
};
</script>

<style>
.el-input--prefix.el-custom-input .el-input__inner {
    color: rgba(255, 0, 0, 0);
}
</style>
<style lang="scss" scoped>
.el-custom-label {
  position: absolute;
  left: 32px;
  -webkit-appearance: none;
  background-image: none;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  font-size: 0.9rem;
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
}
</style>
