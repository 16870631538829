import InventoryService from '@/services/inventory.service';

const SET_ALL_EXPENDABLES = 'SET_ALL_EXPENDABLES';
const SET_INVENTORY = 'SET_INVENTORY';
const SET_INVENTORY_BATCH = 'SET_INVENTORY_BATCH';

const state = {
  selectedInventory: {},
  formValid: false,
  filter: {},
  inventoryBatch: [],
  expendableList: [],
};

/* eslint-disable no-param-reassign */
const mutations = {
  [SET_INVENTORY](_state, payload) {
    _state.selectedInventory = payload;
  },
  [SET_ALL_EXPENDABLES](_state, payload) {
    _state.expendableList = payload;
  },
  [SET_INVENTORY_BATCH](_state, payload) {
    _state.inventoryBatch = payload;
  },
};

const actions = {
  fetchExpendables({ commit }, param = {}) {
    return InventoryService.fetchExpendables(param)
      .then((response) => {
        commit(SET_ALL_EXPENDABLES, response.data);
      });
  },
  setSelectedInventory({ commit }, payload) {
    return commit(SET_INVENTORY, payload);
  },
  setInventory({ commit }, payload) {
    commit(UPDATE_INVENTORY, payload);
  },
  // eslint-disable-next-line no-shadow
  createNewInventory({ commit }, newinventory) {
    return InventoryService.createNew(newinventory)
      .then((response) => {
        if (response.ok) {
          commit(SET_INVENTORY, response.data);
        }
        return response;
      });
  },
  createNewInventoryWithImage({ commit }, { inventory, image }) {
    return InventoryService.createNewWithImage(inventory, image)
      .then((response) => {
        if (response.ok) {
          commit(SET_INVENTORY, response.data);
        }
        return response;
      });
  },
  // eslint-disable-next-line no-shadow
  updateInventory({ commit }, updateinventory) {
    return InventoryService.updateInventory(updateinventory._id, updateinventory)
      .then((response) => {
        if (response.ok) {
          commit(SET_INVENTORY, response.data);
        }
        return response;
      });
  },
  updateInventoryWithImage({ commit }, { inventory, image }) {
    return InventoryService.updateInventoryWithImage(inventory._id, inventory, image)
      .then((response) => {
        if (response.ok) {
          commit(SET_INVENTORY, response.data);
        }
        return response;
      });
  },
  bulkUpdateInventory({ commit }, data) {
    return InventoryService.updateBulkInventory(data)
      .then(response => response);
  },
  bulkDeleteInventory({ commit }, data) {
    return InventoryService.deleteBulk(data)
      .then(response => response);
  },
  deleteInventory({ commit }, id) {
    return InventoryService.delete(id)
      .then((response) => {
        if (response.ok) {
          commit(SET_INVENTORY, {});
        }
        return response;
      });
  },
  getInventory({ commit }, id) {
    return InventoryService.getInventory(id)
      .then((response) => {
        if (response.ok) {
          commit(SET_INVENTORY, response.data);
        }
        return response;
      });
  },
  selectBatch({ commit }, inventoryId) {
    return InventoryService.selectBatch(inventoryId)
      .then((response) => {
        if (response.ok) {
          commit(SET_INVENTORY_BATCH, response.data);
        }
      });
  },
  createNewInventoryWithImport({ commit }, { items }) {
    return InventoryService.importInventory(items)
      .then((response) => {
        return response;
      });
  },
  resetBatches({ commit }) {
    commit(SET_INVENTORY_BATCH, []);
  },
};

const getters = {
  selectedInventory: _state => _state.selectedInventory,
  formValid: _state => _state.formValid,
  inventoryBatch: _state => _state.inventoryBatch,
  expendableObjects: _state => (Array.isArray(_state.all)
    && _state.all
      .reduce(
        (final, item) => ({ ...final, [item.id]: item }),
        {},
      )) || {},
  expendableList: _state => _state.expendableList,
  trademarkList: (_state, _getters) => (
    [
      ...new Set(_getters.expendableList
        .filter(item => item.trademark)
        .map(item => item.trademark)),
    ]
  ),
  trademarksOptionMap: (_state, _getters) => _getters.trademarkList.map(trademark => ({
    value: trademark,
    text: trademark,
  })),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
