var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.printing
    ? _c(
        "a",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.viewing,
              expression: "!viewing"
            }
          ],
          staticClass: "label-button p-hide",
          attrs: { tabIndex: "-1", role: "button" },
          on: { click: _vm.setAllCheckFlag }
        },
        [
          _vm._v(
            "\n  (" +
              _vm._s(
                _vm.checked
                  ? _vm.$t("forms.unsetAll")
                  : _vm.item.label || _vm.$t("forms.setAllAsNil")
              ) +
              ")\n"
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }