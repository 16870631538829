var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mb-5",
      class: { previewPrint: _vm.preview },
      staticStyle: { margin: "20px" }
    },
    [
      _c("div", { staticClass: "row mb-3" }, [
        _c("div", { staticClass: "col-12" }, [
          _vm.printConfig.clinicLogo
            ? _c("img", {
                style: {
                  marginLeft: _vm.logoMarginLeft + "px",
                  width: _vm.logoWidth + "px"
                },
                attrs: { src: this.companyClinicLogo }
              })
            : _vm._e(),
          _c("h5", [_vm._v(_vm._s(this.companyClinicName))]),
          _vm.printConfig.clinicLocalName
            ? _c("h6", { staticClass: "mt-2" }, [
                _vm._v(_vm._s(this.companyClinicLocalName))
              ])
            : _vm._e(),
          _vm.printConfig.clinicAdd
            ? _c(
                "p",
                {
                  staticClass: "mb-1 address-font",
                  staticStyle: { "white-space": "pre-line" }
                },
                [_vm._v("\n        " + _vm._s(_vm.invoiceAddress) + "\n      ")]
              )
            : _vm._e(),
          _vm.printConfig.clinicLocalAddress
            ? _c(
                "p",
                {
                  staticClass: "mb-3 address-font",
                  staticStyle: {
                    "white-space": "pre-line",
                    "line-height": ".5rem"
                  }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.invoiceLocalAddress) + "\n      "
                  )
                ]
              )
            : _vm._e(),
          _vm.general.telephone
            ? _c("p", { staticClass: "mb-3 address-font" }, [
                _vm._v("Tel: " + _vm._s(_vm.general.telephone))
              ])
            : _vm._e(),
          _c("h3", { staticClass: "mb-3 text-center text-uppercase" }, [
            _c("u", [
              _vm.withTaxes
                ? _c("span", [_vm._v(_vm._s(_vm.$t("general.tax")))])
                : _vm._e(),
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("general.invoice")) +
                  "\n        "
              )
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "row mb-5" }, [
        _c(
          "div",
          { staticClass: "col-5" },
          [
            _c("div", { staticClass: "weight-600 mb-2" }, [
              _vm._v(
                _vm._s(_vm.selectedPatient.name) +
                  " - " +
                  _vm._s(_vm.selectedPatient.nric)
              )
            ]),
            _vm.printConfig.patientLocalName
              ? [
                  _c("div", { staticClass: "weight-600 mb-2" }, [
                    _vm._v(_vm._s(_vm.selectedPatient.localName))
                  ])
                ]
              : _vm._e(),
            _vm.print.pricingScheme
              ? _c("div", { staticClass: "weight-600 mb-2" }, [
                  _vm._v(
                    _vm._s(_vm.print.pricingScheme.corpName) +
                      " - " +
                      _vm._s(_vm.print.pricingScheme.schemeName)
                  )
                ])
              : _vm._e(),
            _vm.printConfig.patientAdd
              ? _vm._l(_vm.splitAddress, function(address) {
                  return _c("div", { key: address.id }, [
                    _vm._v("\n          " + _vm._s(address) + "\n        ")
                  ])
                })
              : _vm._e()
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "col-4 text-right" },
          _vm._l(_vm.invoiceCategory, function(category) {
            return _c("div", { key: category.id }, [
              _vm._v("\n        " + _vm._s(category.label) + "\n      ")
            ])
          }),
          0
        ),
        _c("div", { staticClass: "col-3" }, [
          _c("div", [_vm._v(":  " + _vm._s(_vm.print.invoiceNo) + " ")]),
          _c("div", [
            _vm._v(
              ":  " +
                _vm._s(
                  _vm._f("formatVDDate")(_vm.print.date, {
                    sourceFormat: "X",
                    lang: _vm.$i18n.locale,
                    format: "DD MMM YYYY"
                  })
                )
            )
          ]),
          _vm.printConfig.invoiceDateBE
            ? _c("div", [
                _vm._v(
                  ":  " +
                    _vm._s(
                      _vm._f("formatVDDate")(_vm.print.date, {
                        sourceFormat: "X",
                        lang: "th",
                        format: "DD MMM YYYY"
                      })
                    )
                )
              ])
            : _vm._e(),
          _vm.general.useLocationAddress
            ? _c("div", [_vm._v(":  " + _vm._s(this.selectedClinic.regNo))])
            : _vm._e(),
          !!_vm.general.companyRegNo && !_vm.general.useLocationAddress
            ? _c("div", [
                _vm._v(":  " + _vm._s(_vm.general.companyRegNo) + " ")
              ])
            : _vm._e(),
          _vm.general.useLocationAddress
            ? _c("div", [_vm._v(":  " + _vm._s(this.selectedClinic.taxNo))])
            : _vm._e(),
          !!_vm.printConfig.regNumber && !_vm.general.useLocationAddress
            ? _c("div", [_vm._v(":  " + _vm._s(_vm.general.gstRegNo))])
            : _vm._e(),
          _vm.printConfig.docName
            ? _c("div", [_vm._v(":  " + _vm._s(_vm.provider.name))])
            : _vm._e(),
          _vm.printConfig.docQual
            ? _c("div", [_vm._v(":  " + _vm._s(_vm.provider.qualifications))])
            : _vm._e(),
          _vm.printConfig.therapistName
            ? _c("div", [
                _vm._v(
                  ":  " +
                    _vm._s(_vm.print.therapist && _vm.print.therapist.name)
                )
              ])
            : _vm._e()
        ])
      ]),
      _c("div", { staticClass: "row weight-600 bd-top bd-bottom" }, [
        _c(
          "div",
          {
            class: {
              "col-2": _vm.printConfig.lineItemsDesc,
              "col-4": !_vm.printConfig.lineItemsDesc
            }
          },
          [_vm._v("\n      " + _vm._s(_vm.$t("general.itemName")) + "\n    ")]
        ),
        _vm.printConfig.lineItemsDesc
          ? _c("div", { staticClass: "col-2" }, [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("general.description")) + "\n    "
              )
            ])
          : _vm._e(),
        _c("div", { staticClass: "col-2 text-center" }, [
          _vm._v("\n      " + _vm._s(_vm.$t("general.qty")) + "\n    ")
        ]),
        _c("div", { staticClass: "col-2 text-right" }, [
          _vm._v(_vm._s(_vm.$t("general.unitPrice")))
        ]),
        _vm.hasAtLeastOneItemWithDiscount
          ? _c("div", { staticClass: "col-1 text-right" }, [
              _vm._v(_vm._s(_vm.$t("general.discount")))
            ])
          : _c("div", { staticClass: "col-1 text-right" }),
        _vm.withTaxes
          ? _c("div", { staticClass: "col-1 text-right" }, [
              _vm._v("\n      " + _vm._s(_vm.$t("general.tax")) + "\n    ")
            ])
          : _c("div", { staticClass: "col-1 text-right" }),
        _c("div", { staticClass: "col-2 text-right" }, [
          _vm._v(_vm._s(_vm.$t("general.total")))
        ])
      ]),
      _vm.isCategorized
        ? _c(
            "div",
            _vm._l(_vm.categorized, function(category, idx) {
              return _c("div", { key: category.key }, [
                _c("div", { staticClass: "mt-3 row" }, [
                  !_vm.instructionsBuilderFeatureFlag
                    ? _c("div", { staticClass: "col-12 weight-600 mb-2" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t(_vm.categorizedNameMapTranslateId[idx])
                            ) +
                            "\n        "
                        )
                      ])
                    : _vm._e(),
                  _vm.instructionsBuilderFeatureFlag
                    ? _c(
                        "div",
                        {
                          staticClass: "col-2 weight-600 mb-2",
                          staticStyle: { "padding-right": "0px" }
                        },
                        [_vm._v(_vm._s(idx))]
                      )
                    : _vm._e(),
                  _vm.instructionsBuilderFeatureFlag
                    ? _c("div", {
                        staticClass: "weight-600 mb-2",
                        class: {
                          "col-3": _vm.printConfig.lineItemsDesc,
                          "col-2": !_vm.printConfig.lineItemsDesc
                        },
                        staticStyle: { "padding-right": "0px" }
                      })
                    : _vm._e(),
                  _vm.instructionsBuilderFeatureFlag
                    ? _c("div", {
                        class: {
                          "col-1 text-left": _vm.printConfig.lineItemsDesc,
                          "col-2 text-center": !_vm.printConfig.lineItemsDesc
                        },
                        staticStyle: { "padding-right": "0px" }
                      })
                    : _vm._e(),
                  _vm.instructionsBuilderFeatureFlag &&
                  (idx === "Medicine" || idx === "Wyn")
                    ? _c("div", { staticClass: "col-2 text-right" }, [
                        _vm._v(
                          _vm._s(_vm.region.currency) +
                            _vm._s(_vm._f("to2Decimal")(_vm.totalMedicinePrice))
                        )
                      ])
                    : _vm._e(),
                  _vm.instructionsBuilderFeatureFlag
                    ? _c("div", { staticClass: "col-1 text-right" })
                    : _vm._e(),
                  _vm.instructionsBuilderFeatureFlag
                    ? _c("div", { staticClass: "col-1 text-right" })
                    : _vm._e(),
                  _vm.instructionsBuilderFeatureFlag &&
                  (idx === "Medicine" || idx === "Wyn")
                    ? _c("div", { staticClass: "col-2 text-right" }, [
                        _vm._v(
                          _vm._s(_vm.region.currency) +
                            _vm._s(
                              _vm._f("to2Decimal")(
                                _vm.totalMedicineAfterDiscountPrice
                              )
                            )
                        )
                      ])
                    : _vm._e()
                ]),
                !_vm.instructionsBuilderFeatureFlag ||
                (_vm.instructionsBuilderFeatureFlag &&
                  (idx !== "Medicine" || idx !== "Wyn"))
                  ? _c(
                      "span",
                      _vm._l(category, function(item) {
                        return _c(
                          "div",
                          { key: item.key, staticClass: "row" },
                          [
                            _c(
                              "div",
                              {
                                class: {
                                  "col-2": _vm.printConfig.lineItemsDesc,
                                  "col-4": !_vm.printConfig.lineItemsDesc
                                }
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(item.name) +
                                    "\n          "
                                ),
                                [
                                  "Medicine",
                                  "Wyn",
                                  "Consumables",
                                  "Expendables",
                                  "Product"
                                ].includes(item.inventory)
                                  ? [
                                      _c("br"),
                                      _c("span", [
                                        _vm._v(
                                          "\n              - " +
                                            _vm._s(
                                              (item.selectedBatch &&
                                                item.selectedBatch.number) ||
                                                ""
                                            ) +
                                            "\n            "
                                        )
                                      ])
                                    ]
                                  : _vm._e(),
                                _vm._l(item.subInventory, function(i, k) {
                                  return _c("div", { key: k }, [
                                    i.givenId
                                      ? _c("span", [
                                          _vm._v("- "),
                                          _c("small", [
                                            _vm._v(
                                              _vm._s(i.givenId) +
                                                " / " +
                                                _vm._s(i.name)
                                            )
                                          ])
                                        ])
                                      : _vm._e()
                                  ])
                                })
                              ],
                              2
                            ),
                            _vm.printConfig.lineItemsDesc
                              ? _c("div", { staticClass: "col-2" }, [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(item.description) +
                                      "\n        "
                                  )
                                ])
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass: "col-1 text-right",
                                staticStyle: { "padding-right": "0px" }
                              },
                              [_vm._v(_vm._s(item.qty))]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "col-1 text-left",
                                staticStyle: { "padding-left": "5px" }
                              },
                              [_vm._v(_vm._s(item.unit))]
                            ),
                            _c("div", { staticClass: "col-2 text-right" }, [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm._f("thousandSeparator")(
                                      _vm._f("to2Decimal")(
                                        _vm._f("toVirtualCurrency")(
                                          item.price,
                                          _vm.virtualCurrencyConfig
                                            .exchangeRate,
                                          _vm.convertToVirtualCurrency
                                        ),
                                        _vm.region.decimal
                                      ),
                                      _vm.region.thousandSeparator
                                    )
                                  ) +
                                  "\n        "
                              )
                            ]),
                            _vm.hasAtLeastOneItemWithDiscount
                              ? _c("div", { staticClass: "col-1 text-right" }, [
                                  item.discountType === "%"
                                    ? _c("span", [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(
                                              _vm._f("thousandSeparator")(
                                                _vm._f("to2Decimal")(
                                                  item.discount,
                                                  _vm.region.decimal
                                                ),
                                                _vm.region.thousandSeparator
                                              )
                                            ) +
                                            "%\n          "
                                        )
                                      ])
                                    : item.discountType === "$"
                                    ? _c("span", [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(
                                              _vm.convertToVirtualCurrency
                                                ? ""
                                                : _vm.region.currency
                                            ) +
                                            "\n            " +
                                            _vm._s(
                                              _vm._f("thousandSeparator")(
                                                _vm._f("to2Decimal")(
                                                  _vm._f("toVirtualCurrency")(
                                                    item.discount,
                                                    _vm.virtualCurrencyConfig
                                                      .exchangeRate,
                                                    _vm.convertToVirtualCurrency
                                                  ),
                                                  _vm.region.decimal
                                                ),
                                                _vm.region.thousandSeparator
                                              )
                                            ) +
                                            "\n          "
                                        )
                                      ])
                                    : _vm._e()
                                ])
                              : _c("div", { staticClass: "col-1 text-right" }),
                            _vm.withTaxes
                              ? _c("div", { staticClass: "col-1 text-right" }, [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(item.tax) +
                                      "%\n        "
                                  )
                                ])
                              : _c("div", { staticClass: "col-1 text-right" }),
                            _c("div", { staticClass: "col-2 text-right" }, [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm._f("thousandSeparator")(
                                      _vm._f("to2Decimal")(
                                        _vm._f("toVirtualCurrency")(
                                          item.afterTaxPrice,
                                          _vm.virtualCurrencyConfig
                                            .exchangeRate,
                                          _vm.convertToVirtualCurrency
                                        ),
                                        _vm.region.decimal
                                      ),
                                      _vm.region.thousandSeparator
                                    )
                                  ) +
                                  "\n        "
                              )
                            ])
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e()
              ])
            }),
            0
          )
        : _c(
            "div",
            _vm._l(_vm.print.items, function(item) {
              return _c("div", { key: item.key, staticClass: "row" }, [
                _c(
                  "div",
                  {
                    class: {
                      "col-2": _vm.printConfig.lineItemsDesc,
                      "col-4": !_vm.printConfig.lineItemsDesc
                    }
                  },
                  [
                    _vm._v("\n        " + _vm._s(item.name) + "\n        "),
                    [
                      "Medicine",
                      "Wyn",
                      "Consumables",
                      "Expendables",
                      "Product"
                    ].includes(item.inventory)
                      ? [
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              "\n            - " +
                                _vm._s(
                                  (item.selectedBatch &&
                                    item.selectedBatch.number) ||
                                    ""
                                ) +
                                "\n          "
                            )
                          ])
                        ]
                      : _vm._e(),
                    _vm._l(item.subInventory, function(i, k) {
                      return _c("div", { key: k }, [
                        i.givenId
                          ? _c("span", [
                              _vm._v("- "),
                              _c("small", [
                                _vm._v(
                                  _vm._s(i.givenId) + " / " + _vm._s(i.name)
                                )
                              ])
                            ])
                          : _vm._e()
                      ])
                    })
                  ],
                  2
                ),
                _vm.printConfig.lineItemsDesc
                  ? _c("div", { staticClass: "col-2" }, [
                      _vm._v(
                        "\n        " + _vm._s(item.description) + "\n      "
                      )
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "col-1 text-right",
                    staticStyle: { "padding-right": "0px" }
                  },
                  [_vm._v(_vm._s(item.qty))]
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-1 text-left",
                    staticStyle: { "padding-left": "5px" }
                  },
                  [_vm._v(_vm._s(item.unit))]
                ),
                _c("div", { staticClass: "col-2 text-right" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm._f("thousandSeparator")(
                          _vm._f("to2Decimal")(
                            _vm._f("toVirtualCurrency")(
                              item.price,
                              _vm.virtualCurrencyConfig.exchangeRate,
                              _vm.convertToVirtualCurrency
                            )
                          )
                        )
                      ) +
                      "\n      "
                  )
                ]),
                _vm.hasAtLeastOneItemWithDiscount
                  ? _c("div", { staticClass: "col-1 text-right" }, [
                      item.discountType === "%"
                        ? _c("span", [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm._f("thousandSeparator")(
                                    _vm._f("to2Decimal")(
                                      item.discount,
                                      _vm.region.decimal
                                    ),
                                    _vm.region.thousandSeparator
                                  )
                                ) +
                                "%\n        "
                            )
                          ])
                        : item.discountType === "$"
                        ? _c("span", [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.convertToVirtualCurrency
                                    ? ""
                                    : _vm.region.currency
                                ) +
                                "\n          " +
                                _vm._s(
                                  _vm._f("thousandSeparator")(
                                    _vm._f("to2Decimal")(
                                      _vm._f("toVirtualCurrency")(
                                        item.discount,
                                        _vm.virtualCurrencyConfig.exchangeRate,
                                        _vm.convertToVirtualCurrency
                                      ),
                                      _vm.region.decimal
                                    ),
                                    _vm.region.thousandSeparator
                                  )
                                ) +
                                "\n        "
                            )
                          ])
                        : _vm._e()
                    ])
                  : _c("div", { staticClass: "col-1 text-right" }),
                _vm.withTaxes
                  ? _c("div", { staticClass: "col-1 text-right" }, [
                      _vm._v("\n        " + _vm._s(item.tax) + "%\n      ")
                    ])
                  : _c("div", { staticClass: "col-1 text-right" }),
                _c("div", { staticClass: "col-2 text-right" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm._f("thousandSeparator")(
                          _vm._f("to2Decimal")(
                            _vm._f("toVirtualCurrency")(
                              item.afterTaxPrice,
                              _vm.virtualCurrencyConfig.exchangeRate,
                              _vm.convertToVirtualCurrency
                            ),
                            _vm.region.decimal
                          ),
                          _vm.region.thousandSeparator
                        )
                      ) +
                      "\n      "
                  )
                ])
              ])
            }),
            0
          ),
      _vm.redeem && _vm.redeem.point > 0
        ? _c("div", [
            _c("div", { staticClass: "row weight-600" }, [
              _c("div", { staticClass: "col-10 text-left" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("membership.redeemOfPoint")) +
                    " " +
                    _vm._s(_vm.region.currency) +
                    "\n      "
                )
              ]),
              _c("div", { staticClass: "col-2 text-right" }, [
                _vm._v(
                  "\n        -" +
                    _vm._s(
                      _vm._f("thousandSeparator")(
                        _vm._f("to2Decimal")(
                          _vm.redeem.amount,
                          _vm.region.decimal
                        ),
                        _vm.region.thousandSeparator
                      )
                    ) +
                    "\n      "
                )
              ])
            ])
          ])
        : _vm._e(),
      _c("div", { staticClass: "row mt-3 bd-top" }, [
        _c("div", { staticClass: "col-10 text-right" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.withTaxes && _vm.printConfig.showGSTLabel
                  ? _vm.$t("general.totalBeforeGST")
                  : _vm.$t("general.subtotal")
              ) +
              " " +
              _vm._s(
                _vm._f("shouldHideCurrencyIfConvertingToVC")(
                  _vm.region.currency,
                  _vm.convertToVirtualCurrency
                )
              ) +
              "\n    "
          )
        ]),
        _c("div", { staticClass: "col-2 text-right" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm._f("thousandSeparator")(
                  _vm._f("to2Decimal")(
                    _vm._f("toVirtualCurrency")(
                      _vm.print.subtotal,
                      _vm.virtualCurrencyConfig.exchangeRate,
                      _vm.convertToVirtualCurrency
                    ),
                    _vm.region.decimal
                  ),
                  _vm.region.thousandSeparator
                )
              ) +
              "\n    "
          )
        ])
      ]),
      parseFloat(_vm.print.promotionDiscount) > 0
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-10 text-right" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("general.promotionDiscount")) +
                  " " +
                  _vm._s(
                    _vm._f("shouldHideCurrencyIfConvertingToVC")(
                      _vm.region.currency,
                      _vm.convertToVirtualCurrency
                    )
                  ) +
                  "\n    "
              )
            ]),
            _c("div", { staticClass: "col-2 text-right" }, [
              _vm._v(
                "\n      -" +
                  _vm._s(
                    _vm._f("thousandSeparator")(
                      _vm._f("to2Decimal")(
                        _vm._f("toVirtualCurrency")(
                          _vm.print.promotionDiscount,
                          _vm.virtualCurrencyConfig.exchangeRate,
                          _vm.convertToVirtualCurrency
                        ),
                        _vm.region.decimal
                      ),
                      _vm.region.thousandSeparator
                    )
                  ) +
                  "\n    "
              )
            ])
          ])
        : _vm._e(),
      parseFloat(_vm.print.totalDiscount) > 0
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-10 text-right" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("general.totalDiscount")) +
                  " " +
                  _vm._s(
                    _vm._f("shouldHideCurrencyIfConvertingToVC")(
                      _vm.region.currency,
                      _vm.convertToVirtualCurrency
                    )
                  ) +
                  "\n    "
              )
            ]),
            _c("div", { staticClass: "col-2 text-right" }, [
              _vm._v(
                "\n      -" +
                  _vm._s(
                    _vm._f("thousandSeparator")(
                      _vm._f("to2Decimal")(
                        _vm._f("toVirtualCurrency")(
                          _vm.print.totalDiscount,
                          _vm.virtualCurrencyConfig.exchangeRate,
                          _vm.convertToVirtualCurrency
                        ),
                        _vm.region.decimal
                      ),
                      _vm.region.thousandSeparator
                    )
                  ) +
                  "\n    "
              )
            ])
          ])
        : _vm._e(),
      _vm.withTaxes
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-10 text-right" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.withTaxes && _vm.printConfig.showGSTLabel
                      ? _vm.$t("general.gst")
                      : _vm.$t("general.tax")
                  ) +
                  " " +
                  _vm._s(
                    _vm._f("shouldHideCurrencyIfConvertingToVC")(
                      _vm.region.currency,
                      _vm.convertToVirtualCurrency
                    )
                  ) +
                  "\n    "
              )
            ]),
            _c("div", { staticClass: "col-2 text-right" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm._f("thousandSeparator")(
                      _vm._f("to2Decimal")(
                        _vm._f("toVirtualCurrency")(
                          _vm.print.totalTax,
                          _vm.virtualCurrencyConfig.exchangeRate,
                          _vm.convertToVirtualCurrency
                        ),
                        _vm.region.decimal
                      ),
                      _vm.region.thousandSeparator
                    )
                  ) +
                  "\n    "
              )
            ])
          ])
        : _vm._e(),
      _vm.print.totalVirtualCurrencyRedeemed
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-4" }),
            _c("div", { staticClass: "col-3" }),
            _c("div", { staticClass: "col-3 bd-top text-right" }, [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("membership.redeemed")) + "\n      "
              ),
              _c("span", [
                _vm._v(" (" + _vm._s(_vm.virtualCurrencyConfig.name)),
                !_vm.convertToVirtualCurrency
                  ? _c("span", [_vm._v(" in " + _vm._s(_vm.region.currency))])
                  : _vm._e(),
                _vm._v(")")
              ])
            ]),
            _c("div", { staticClass: "col-2 bd-top text-right" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm._f("thousandSeparator")(
                      _vm._f("to2Decimal")(
                        _vm._f("toVirtualCurrency")(
                          _vm.print.totalVirtualCurrencyRedeemed,
                          _vm.virtualCurrencyConfig.exchangeRate,
                          _vm.convertToVirtualCurrency
                        ),
                        _vm.region.decimal
                      ),
                      _vm.region.thousandSeparator
                    )
                  ) +
                  "\n    "
              )
            ])
          ])
        : _vm._e(),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-4" }),
        _c("div", { staticClass: "col-3" }),
        _c("div", { staticClass: "col-3 bd-top text-right" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.withTaxes && _vm.printConfig.showGSTLabel
                  ? _vm.$t("general.totalAfterGST")
                  : _vm.$t("general.invoiceTotal")
              ) +
              " " +
              _vm._s(
                _vm._f("shouldHideCurrencyIfConvertingToVC")(
                  _vm.region.currency,
                  _vm.convertToVirtualCurrency
                )
              ) +
              "\n    "
          )
        ]),
        _c("div", { staticClass: "col-2 bd-top text-right" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm._f("thousandSeparator")(
                  _vm._f("to2Decimal")(
                    _vm._f("toVirtualCurrency")(
                      _vm.print.totalBeforeRounding,
                      _vm.virtualCurrencyConfig.exchangeRate,
                      _vm.convertToVirtualCurrency
                    ),
                    _vm.region.decimal
                  ),
                  _vm.region.thousandSeparator
                )
              ) +
              "\n    "
          )
        ])
      ]),
      _vm.processFees
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-4" }),
            _c("div", { staticClass: "col-3" }),
            _c("div", { staticClass: "col-3 bd-top text-right" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("paymentMode.processValue")) +
                  " " +
                  _vm._s(
                    _vm._f("shouldHideCurrencyIfConvertingToVC")(
                      _vm.region.currency,
                      _vm.convertToVirtualCurrency
                    )
                  ) +
                  "\n    "
              )
            ]),
            _c("div", { staticClass: "col-2 bd-top text-right" }, [
              _vm.processFees
                ? _c("div", [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm._f("thousandSeparator")(
                            _vm._f("to2Decimal")(
                              _vm._f("toVirtualCurrency")(
                                _vm.processFees,
                                _vm.virtualCurrencyConfig.exchangeRate,
                                _vm.convertToVirtualCurrency
                              ),
                              _vm.region.decimal
                            ),
                            _vm.region.thousandSeparator
                          )
                        ) +
                        "\n      "
                    )
                  ])
                : _vm._e()
            ])
          ])
        : _vm._e(),
      _vm.roundOff !== "NONE" && parseFloat(_vm.print.roundingAdjustment) > 0
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-3" }),
            _c("div", { staticClass: "col-7 text-right" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("general.roundingAdjustment")) +
                  " " +
                  _vm._s(
                    _vm._f("shouldHideCurrencyIfConvertingToVC")(
                      _vm.region.currency,
                      _vm.convertToVirtualCurrency
                    )
                  ) +
                  "\n    "
              )
            ]),
            _c("div", { staticClass: "col-2 text-right" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm._f("thousandSeparator")(
                      _vm._f("to2Decimal")(
                        _vm._f("toVirtualCurrency")(
                          _vm.print.roundingAdjustment,
                          _vm.virtualCurrencyConfig.exchangeRate,
                          _vm.convertToVirtualCurrency
                        ),
                        _vm.region.decimal
                      ),
                      _vm.region.thousandSeparator
                    )
                  ) +
                  "\n    "
              )
            ])
          ])
        : _vm._e(),
      _c("div", { staticClass: "row weight-600" }, [
        _c("div", { staticClass: "col-4" }),
        _c(
          "div",
          { staticClass: "col-3" },
          [
            _vm.region.code === "KH"
              ? [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("setting.exchangeRate")) +
                      ": $1 = R " +
                      _vm._s(_vm.print.exchangeRate) +
                      "\n      "
                  )
                ]
              : _vm._e()
          ],
          2
        ),
        _c("div", { staticClass: "col-3 bd-top bd-bottom text-right" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("general.totalAmountPayable")) +
              " " +
              _vm._s(
                _vm._f("shouldHideCurrencyIfConvertingToVC")(
                  _vm.region.currency,
                  _vm.convertToVirtualCurrency
                )
              ) +
              "\n    "
          )
        ]),
        _c("div", { staticClass: "col-2 bd-top bd-bottom text-right" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm._f("thousandSeparator")(
                  _vm._f("to2Decimal")(
                    _vm._f("toVirtualCurrency")(
                      _vm.print.total + _vm.processFees,
                      _vm.virtualCurrencyConfig.exchangeRate,
                      _vm.convertToVirtualCurrency
                    ),
                    _vm.region.decimal
                  ),
                  _vm.region.thousandSeparator
                )
              ) +
              "\n    "
          )
        ])
      ]),
      _vm._l(_vm.patientPayments, function(payment) {
        return _c("div", { key: payment.paymentId, staticClass: "row mt-4" }, [
          _c("div", { staticClass: "col-10 text-right" }, [
            _vm._v(
              "\n      " +
                _vm._s(payment.label) +
                " " +
                _vm._s(_vm.$t("general.paymentReceived")) +
                " -- " +
                _vm._s(_vm.$t("general.paymentRef")) +
                ": " +
                _vm._s(payment.paymentRef) +
                " " +
                _vm._s(_vm.region.currency) +
                "\n    "
            )
          ]),
          _c("div", { staticClass: "col-2 text-right" }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm._f("thousandSeparator")(
                    _vm._f("to2Decimal")(payment.amount, _vm.region.decimal),
                    _vm.region.thousandSeparator
                  )
                ) +
                "\n    "
            )
          ])
        ])
      }),
      _vm._l(_vm.creditPayments, function(payment) {
        return _c("div", { key: payment.paymentId, staticClass: "row mt-4" }, [
          _c("div", { staticClass: "col-10 text-right" }, [
            _vm._v(
              "\n      " +
                _vm._s(payment.mode) +
                " -- " +
                _vm._s(payment.paymentType) +
                " : " +
                _vm._s(_vm.region.currency) +
                "\n    "
            )
          ]),
          _c("div", { staticClass: "col-2 text-right" }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm._f("thousandSeparator")(
                    _vm._f("to2Decimal")(payment.amount, _vm.region.decimal),
                    _vm.region.thousandSeparator
                  )
                ) +
                "\n    "
            )
          ])
        ])
      }),
      _c("div", { staticClass: "row mt-4 weight-600" }, [
        _c("div", { staticClass: "col-10 text-right" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("general.outstandingBalance")) +
              " " +
              _vm._s(
                _vm._f("shouldHideCurrencyIfConvertingToVC")(
                  _vm.region.currency,
                  _vm.convertToVirtualCurrency
                )
              ) +
              "\n    "
          )
        ]),
        _c("div", { staticClass: "col-2 text-right" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm._f("thousandSeparator")(
                  _vm._f("to2Decimal")(
                    _vm._f("toVirtualCurrency")(
                      _vm.print.ostBalance,
                      _vm.virtualCurrencyConfig.exchangeRate,
                      _vm.convertToVirtualCurrency
                    ),
                    _vm.region.decimal
                  ),
                  _vm.region.thousandSeparator
                )
              ) +
              "\n    "
          )
        ])
      ]),
      _vm.virtualCurrencyConfig.enable
        ? _c("div", { staticClass: "row mt-3 weight-600" }, [
            _c("div", { staticClass: "col-10 text-right" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("general.current")) +
                  " " +
                  _vm._s(_vm.virtualCurrencyConfig.name) +
                  " " +
                  _vm._s(_vm.$t("general.balance")) +
                  "\n    "
              )
            ]),
            _c("div", { staticClass: "col-2 text-right" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.print.virtualCurrencyBalanceRightAfterInvoiceRedemption
                  ) +
                  " " +
                  _vm._s(_vm.virtualCurrencyConfig.currencyCode) +
                  "\n    "
              )
            ])
          ])
        : _vm._e(),
      _vm.promoCode
        ? _c("div", { staticClass: "row mt-3 weight-600" }, [
            _c("div", { staticClass: "col-10 text-right" }, [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("campaign.promotionCode")) + "\n    "
              )
            ]),
            _c("div", { staticClass: "col-2 text-right" }, [
              _vm._v("\n      " + _vm._s(_vm.promoCode) + "\n    ")
            ])
          ])
        : _vm._e(),
      _c("br"),
      _c("br"),
      _vm.withDiagnosis || _vm.printConfig.diagnosis
        ? [
            _c("div", { staticClass: "row weight-600" }, [
              _c("div", { staticClass: "col-12" }, [
                _vm._v(_vm._s(_vm.$t("general.diagnosis")) + ":")
              ])
            ]),
            _vm._l(_vm.print.diagnosis, function(item, index) {
              return _c("div", { key: index }, [
                _vm.multipleICD10Enabled
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-3" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              index === 0
                                ? "Primary Diagnosis"
                                : index === 1
                                ? "Secondary Diagnosis"
                                : "Additional Diagnosis"
                            ) +
                            "\n        "
                        )
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-9" },
                        _vm._l(item, function(icd) {
                          return _c("p", { key: icd.uuid }, [
                            _c("i", [_vm._v(_vm._s(icd && icd.text))])
                          ])
                        }),
                        0
                      )
                    ])
                  : _c(
                      "div",
                      _vm._l(item, function(icd) {
                        return _c("p", { key: icd.uuid }, [
                          _c("i", [_vm._v(_vm._s(icd && icd.text))])
                        ])
                      }),
                      0
                    ),
                _vm.multipleICD10Enabled ? _c("hr") : _vm._e()
              ])
            })
          ]
        : _vm._e(),
      _c("br"),
      _vm.printConfig.notes
        ? [
            _c("div", { staticClass: "weight-600" }, [
              _vm._v(" " + _vm._s(_vm.$t("general.notes")) + ": ")
            ]),
            _c("p", { staticStyle: { "white-space": "pre-line" } }, [
              _vm._v(" " + _vm._s(_vm.print.notes) + " ")
            ])
          ]
        : _vm._e(),
      _c("br"),
      _vm.corpPayables.length
        ? [
            _c("div", { staticClass: "row weight-600" }, [
              _c("div", { staticClass: "col-12" }, [
                _vm._v(_vm._s(_vm.$t("general.amountDueFrom")) + ":")
              ])
            ]),
            _c("div", { staticClass: "row weight-600 bd-top bd-bottom" }, [
              _c("div", { staticClass: "col-6" }, [
                _vm._v(_vm._s(_vm.$t("general.others")))
              ]),
              _c("div", { staticClass: "col-6" }, [
                _vm._v(_vm._s(_vm.$t("general.amount")))
              ])
            ]),
            _vm._l(_vm.corpPayables, function(payable) {
              return _c("div", { key: payable.paymentId, staticClass: "row" }, [
                _c("div", { staticClass: "col-6" }, [
                  _vm._v(_vm._s(payable.label))
                ]),
                _c("div", { staticClass: "col-6" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.region.currency) +
                      _vm._s(
                        _vm._f("thousandSeparator")(
                          _vm._f("to2Decimal")(
                            payable.amount,
                            _vm.region.decimal
                          ),
                          _vm.region.thousandSeparator
                        )
                      ) +
                      "\n      "
                  )
                ])
              ])
            })
          ]
        : _vm._e(),
      _c("br"),
      _vm.corpSettled.length
        ? [
            _c("div", { staticClass: "row weight-600" }, [
              _c("div", { staticClass: "col-12" }, [
                _vm._v(_vm._s(_vm.$t("general.amountSettledFrom")) + ":")
              ])
            ]),
            _c("div", { staticClass: "row weight-600 bd-top bd-bottom" }, [
              _c("div", { staticClass: "col-6" }, [
                _vm._v(_vm._s(_vm.$t("general.others")))
              ]),
              _c("div", { staticClass: "col-6" }, [
                _vm._v(_vm._s(_vm.$t("general.amount")))
              ])
            ]),
            _vm._l(_vm.corpSettled, function(payable) {
              return _c("div", { key: payable.paymentId, staticClass: "row" }, [
                _c("div", { staticClass: "col-6" }, [
                  _vm._v(_vm._s(payable.label))
                ]),
                _c("div", { staticClass: "col-6" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.region.currency) +
                      _vm._s(
                        _vm._f("thousandSeparator")(
                          _vm._f("to2Decimal")(
                            payable.amount,
                            _vm.region.decimal
                          ),
                          _vm.region.thousandSeparator
                        )
                      ) +
                      "\n      "
                  )
                ])
              ])
            })
          ]
        : _vm._e(),
      _vm.isCustomNote
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-7" }),
            _vm._m(0)
          ])
        : _vm._e(),
      _c("br"),
      _c("div", { staticClass: "row mb-3" }, [
        _c(
          "div",
          { staticClass: "col-7 weight-600" },
          [
            _vm.printConfig.clinicHospital
              ? [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("setting.footerClinicHospital")) +
                      " :\n      "
                  )
                ]
              : _vm._e()
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "col-5 weight-600" },
          [
            _vm.printConfig.patientSignature
              ? [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("setting.footerPatientSignature")) +
                      " :\n      "
                  )
                ]
              : _vm._e()
          ],
          2
        )
      ]),
      _vm.printConfig.footer
        ? _c("p", { staticStyle: { "white-space": "pre-line" } }, [
            _vm._v("\n    " + _vm._s(_vm.printConfig.footerContent) + "\n  ")
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "col-5 text-left",
        staticStyle: { "white-space": "pre-line", color: "red" }
      },
      [
        _vm._v("\n        注意事項："),
        _c("br"),
        _vm._v("定型化契約"),
        _c("br"),
        _vm._v(
          "1. 此療程為優惠方案如因故無法使用，除非有身體傷害之狀況，否則不得要求退費，已使用之課程需以原售價扣除。因特殊因素，得允許將已付金頟轉換其他療程。依現行法令規定意退費時，將扣除已接受服務之費用、已提領並拆封之附屬商品金額、終止契約手續費（剩餘金額之百分之10）後退子消費者本人（依現行法令公告規定辦理）已使用部分療程/保養，須以單堂原價計算之。"
        ),
        _c("br"),
        _vm._v("2.手術訂金於術前5天，個人因素而無法手術時得轉换其他療程產品。"),
        _c("br"),
        _vm._v(
          "於手術當天因個人因素取消手術時，訂金不退還也不可轉換其他療程及產品。\n      "
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }