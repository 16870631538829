export const SET_ALL_PATIENTS = 'SET_ALL_PATIENT';
export const SET_PATIENT = 'SET_PATIENT';
export const UPDATE_PATIENT = 'UPDATE_PATIENT';
export const SET_QUESTIONNAIRE = 'SET_QUESTIONNAIRE';
export const SET_ADULTFORM = 'SET_ADULTFORM';
export const SET_CHILDFORM = 'SET_CHILDFORM';
export const SET_VITALS = 'SET_VITALS';
export const UPDATE_VITALS = 'UPDATE_VITALS';
export const SET_TAGS = 'SET_TAGS';
export const UPDATE_TAGS = 'UPDATE_TAGS';
export const UPDATE_FORM_VALIDITY = 'UPDATE_FORM_VALIDITY';
export const SET_PRINT = 'print';
export const SET_PROFILE_PIC = 'SET_PROFILE_PIC';
export const SET_SELECTED_OT = 'SET_SELECTED_OT';
export const SET_SLIDEBAR_OPEN = 'SET_SLIDEBAR_OPEN';
export const SET_COMPILED_REPORT_PRINT = 'compiledReportPrint';
export const SET_INTEGRATION_REPORT_PRINT = 'integrationReportPrint';
