<template>
  <div class="authorisation-form">
        <div class="row form-group">
          <label class="col-3">Select User</label>
          <div class="col">
            <select v-model="authorisationForm.email" class="form-control">
              <option v-for="user in providersOrTherapistArray"
                :key="user.id"
                :value="user.email">{{user.name}} - {{ user.email }}</option>
            </select>
          </div>
        </div>
        <div class="row form-group">
          <label class="col-3">Password</label>
          <div class="col">
            <input type="password" v-model="authorisationForm.password"
              class="form-control" autocomplete="false">
          </div>
        </div>
      <b-button class="float-right my-3" variant="outline-primary" @click="onConfirmVerify">
        Verify
      </b-button>
  </div>
</template>

<script>

import User from '@/services/user.service';

import providerService from '@/services/provider.service';

export default {
  name: 'DigitalSignatureForm',
  data() {
    return {
      providersOrTherapistArray: [],
      authorisationForm: {
        email: '',
        password: '',
      },
      providersMappedUserId: {},
      therapistMappedUserId: {},
    };
  },
  props: ['providerOrTherapistProp'],
  methods: {
    onConfirmVerify() {
      /* eslint-disable no-restricted-properties,max-len */
      User.digitalSignatureVerification(this.authorisationForm.email, this.authorisationForm.password)
        .then((response) => {
          // this.digitalSignedUser = response.user;
          if (response.user) {
            this.$emit('digitalSignedHandler', response.user);
          } else {
            this.flash('Email or Password doesnt match', 'error', { timeout: 3000 });
          }
        })
        .catch((errObject) => {
          // console.log('errObject ', errObject);
          // const errorMessage = this.translateAuthoriseError(errObject.code);
          alert(errObject.code);
        });
    },
    isProvider(userId) {
      const provider = this.providersMappedUserId[userId];
      // console.log('provider ', provider, userId);
      if (!provider) return false;
      return provider.role === 'provider';
    },
    isTherapist(userId) {
      const therapist = this.therapistMappedUserId[userId];
      if (!therapist) return false;
      // console.log('this.providersMappedUserId[userId] ', this.therapistMappedUserId);
      // console.log('therapist ', therapist.role, userId);
      return therapist.role === 'therapist';
    },
    async fetchAllProviders() {
      // console.log('in fetchAllProviders');
      const tmp = await providerService.fetchAll({ clinicCode: this.selectedClinic._id });
      // console.log(' tmp ', tmp);
      if (tmp.ok) {
        (tmp.data || []).forEach((provider) => {
          // console.log('provider ', provider);
          if (provider.role === 'provider') {
            this.providersMappedUserId[provider.user] = provider;
          } else {
            this.therapistMappedUserId[provider.user] = provider;
          }
        });
      }
    },
  },
  async mounted() {
    await this.fetchAllProviders();
    this.providersOrTherapistArray = [];
    const providersUsers = this.users;
    // console.log('this.providersMappedUserId[userId] ', this.providersMappedUserId);
    if (this.providerOrTherapistProp === 'provider') {
      this.providersOrTherapistArray = providersUsers.filter(u => this.isProvider(u.id));
    } else if (this.providerOrTherapistProp === 'therapist') {
      this.providersOrTherapistArray = providersUsers.filter(u => this.isTherapist(u.id));
    } else {
      this.providersOrTherapistArray = this.users;
    }
    // console.log('this.providersOrTherapistArray ', this.providersOrTherapistArray);
  },
};
</script>
