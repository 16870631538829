<template>
  <div class="print-container mt-3">
    <!-- header -->
    <div class="row mb-3">
      <div class="col">
        <h6 class="bill-to">Bill To / Address</h6>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-9">
        <div class="clinic-name font-weight-bold">{{ corpData.name }}</div>
        <div class="clinic-address text-secondary">{{ corpData.address }}</div>
      </div>
      <div class="col-3">
        <div class="tax-invoice-no">
          <span class="font-weight-bold">Tax Invoice No: </span>
          <span>{{ getInvNoRange() }}</span>
        </div>
        <div class="visit date">
          <span class="font-weight-bold">Invoice Date: </span>
          <span>{{ new Date | ddMonthYYYY }}</span>
        </div>
      </div>
    </div>
    <!-- table data -->
    <table class="table table-sm">
      <thead class="thead-dark">
        <tr>
          <th class="border">Visit Date</th>
          <th class="border">Invoice No</th>
          <th class="border">Patient</th>
          <th class="border">Item</th>
          <th class="border">Subtotal</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="cp in corpPayments" :key="cp._id">
          <td class="border">{{ cp.invoiceDate | ddMonthYYYYFromX }}</td>
          <td class="border">{{ cp.invoice }}</td>
          <td class="border">{{ cp.patientName.toUpperCase() }}</td>
          <td class="border">
            <p v-for="item in cp.invoiceItems" :key="item.givenId">
              {{ item.name }}({{ item.givenId }})
            </p>
          </td>
          <td class="border">
            {{ generalConfig.region.currency }}{{ cp.corporateInvoice | to2Decimal | thousandSeparator }}
          </td>
        </tr>
        <tr>
          <td colspan="3" class="no-border"></td>
          <td class="border bg-light">Total</td>
          <td class="border bg-light">
            {{ generalConfig.region.currency }}{{ computeTotal() | to2Decimal | thousandSeparator }}
          </td>
        </tr>
        <tr>
          <td colspan="3" class="no-border">&nbsp;</td>
          <td class="border bg-light">Outstanding</td>
          <td class="border text-danger bg-light">
            {{ generalConfig.region.currency }}{{ computeTotalOutstanding() | to2Decimal | thousandSeparator }}
          </td>
        </tr>
      </tbody>
    </table>
    <!-- footer -->
    <footer class="footer mt-2 py-3">
      <div>
        <div class="row">
          <div class="col">
            <h6>Remarks:</h6>
            <p class="text-secondary">
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-10"></div>
          <div class="col">
            <div>
              <img :src="generalConfig.logoURL" class="img-fluid float-right"/>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-8"></div>
          <div class="col">
            <div class="text-right">
              {{ generalConfig.clinicName }}
            </div>
            <div class="text-right"> {{ generalConfig.address }} </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import dateFormatter from '@/components/mixins/dateformatter';
import moneyMixins from '@/components/mixins/money';

export default {
  name: 'BulkCorpInsurance',
  props: {
    corpPayments: {
      type: Array,
      default: [],
    },
    corpData: {
      type: Object,
      default: () => ({}),
    },
    generalConfig: {
      type: Object,
      default: () => ({}),
    },
  },
  mixins: [dateFormatter, moneyMixins],
  methods: {
    computeTotal() {
      return this.corpPayments.reduce((a, b) => a + b.corporateInvoice, 0);
    },
      computeTotalOutstanding() {
      return this.corpPayments.reduce((a, b) => a + Number(b.corporateOutstanding), 0);
    },
    getInvNoRange() {
      if (this.corpPayments.length === 1) return `${this.corpPayments[0].invoice}`;
      let highestInvNo = 0;
      let lowestInvNo = Number.POSITIVE_INFINITY;
      let tmp;
      for (let i = 0; i < this.corpPayments.length; i += 1) {
        tmp = parseInt(this.corpPayments[i].invoice);
        if (tmp < lowestInvNo) lowestInvNo = tmp;
        if (tmp > highestInvNo) highestInvNo = tmp;
      }
      return `${lowestInvNo} - ${highestInvNo}`;
    },
  },
  mounted() {
    setTimeout(() => {
      window.print();
    }, 2000);
  },
};
</script>

<style scoped>
  body{
    -webkit-print-color-adjust:exact;
  }
  .no-border {
  border: none !important;
  }
  .border {
  border: 1px solid #666;
  }

  td.bg-light {
  color: #495057 !important;
  background-color: #eee !important;
  }

  td.bg-light.text-danger {
  color: #c0031d !important;
  background-color: #eee !important;
  }

  @media screen {
  .print-container {
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
  }
  }

  @media print {
  .table .thead-dark th {
    -webkit-print-color-adjust: exact !important;
    color: #fff !important;
    background-color: #333 !important;
  }
  .table .thead-light th {
    -webkit-print-color-adjust: exact !important;
    color: #495057 !important;
    background-color: #eee !important;
  }
  td.bg-light {
    -webkit-print-color-adjust: exact !important;
    color: #495057 !important;
    background-color: #eee !important;
  }
  td.bg-light.text-danger {
    -webkit-print-color-adjust: exact !important;
    color: #c0031d !important;
    background-color: #eee !important;
  }
  }
</style>
