<template>
    <feedback
      :patient="patient"
      :creditNote="selectedCreditNote"/>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import Feedback from '../../components/Print/Feedback.vue';

const { mapGetters } = createNamespacedHelpers('credit');

export default {
  name: 'CreditNotePrintIndex',
  components: {
    Feedback,
  },
  computed: {
    ...mapGetters(['selectedCreditNote']),
    patient() {
      return this.$store.state.patient.selectedPatient;
    },
  },
};
</script>
