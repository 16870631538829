var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.featureFlags.isMaKuang
    ? _c("CreditNotePrintMakuang", {
        attrs: {
          patient: _vm.patient,
          creditNote: _vm.selectedCreditNote,
          invoiceConfig: _vm.invoiceConfig
        }
      })
    : _c("credit-note-print", {
        attrs: { patient: _vm.patient, creditNote: _vm.selectedCreditNote }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }