<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-linecap="round"
    stroke-linejoin="round"
    focusable="false"
    role="presentation"
  >
    <g fill-rule="evenodd">
      <path
        d="
        M13.416 4.417a2.002 2.002 0 0 0-2.832 0l-6.168 6.167a2.002 2.002 0 0 0 0 2.833l6.168
        6.167a2.002 2.002 0 0 0 2.832 0l6.168-6.167a2.002 2.002 0 0 0 0-2.833l-6.168-6.167z"
        fill="currentColor"
      ></path>
      <path
        d="M12 14a1 1 0 0 1-1-1V8a1 1 0 0 1 2 0v5a1 1 0 0 1-1 1m0 3a1 1 0 0 1 0-2 1 1 0 0 1 0 2"
        fill="#FFFFFF"
      ></path>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'SimpleAddSvg',
  props: {
    size: {
      default: 24,
    },
  },
};
</script>
