<template>
    <span>
        <flash-message transitionName="fade" class="template-flash"></flash-message>
        <div id="editing-canvas" class="card gridify tiny">
            <canvas-editor-history
                v-if="notesMapped[currentPageId] && notesMapped[currentPageId].sessions"
                :sessions="notesMapped[currentPageId].sessions"
                :closeHistory="closeHistory"
                :openSessions="openSessions"
                :toolbarPosition="toolbarPosition"
                :redrawSession="redrawSession"
            />
            <upload-manager
                style="position: absolute; z-index: 3399;"
                v-if="showUploadManager"
                :closeUploadManager="closeUploadManager"
                :files="filesToBeUploaded"
                :uploadFiles="uploadFiles"
                :updateUploadLineItem="updateUploadLineItem"
                :categoriesOptions="filteredCategoriesOptions"
                :uploading="uploading"
            />
            <div class="top-menu-bar" style="position: absolute; margin: 12px 0 0 4px; z-index: 299;">
            <!-- <b-form class="breadcrumbs" inline style="position: absolute; margin: 12px 0 0 4px; z-index: 299;"> -->
            <b-form class="breadcrumbs" inline >
                <b-form-select
                    v-if="isEditingCategory"
                    size="sm"
                    :value="selectedCategory"
                    @change="updateEditorMetadata({key: 'categoryId', value: $event}); isEditingCategory=false;"
                    @blur.native="updateEditorMetadata({key: 'categoryId', value: $event.target.value}); isEditingCategory=false;"
                    :options="filteredCategoriesOptions"
                    :state="editorCategoryState"
                    id="editor-category"
                    autofocus
                />
                <span v-else @click="isEditingCategory = true">
                    <span style="position: relative; top: -1px;"><v-icon name="circle" :color="(categoriesMapped[selectedCategory] || {}).colorLabel"/></span>
                    <span style="margin-left: 2px;">{{categoriesMapped[selectedCategory].name}}</span>
                </span>
                <span>&nbsp;&nbsp;/&nbsp;&nbsp;</span>
                <b-input
                    v-if="isEditingName"
                    id="editor-name"
                    size="sm"
                    :value="editor.name"
                    @input="updateEditorMetadata({key: 'name', value: $event})"
                    @blur="emptyNameCheck(); isEditingName = false;"
                    :state="editorNameState"
                    invalid-feedback
                    autofocus
                    required
                ></b-input>
                <span v-else @click="isEditingName = true">{{editor.name}}</span>
            </b-form>
            <div class="revision-history-warning" v-if="openSessions">All edits made in Revision History mode will not be saved</div>
            <div class="toolbar__box--top-right" style="z-index: 299;">
                <span v-if="minusIconFunction && !editor.fromIPD" @click="minusIconFunction($event, 'minimized')" class="cursor-pointer" style="margin-left: 10px;">
                  <v-icon name="regular/window-minimize" scale="1" />
                </span>
                <span v-if="windowIconFunction && !editor.fromIPD" @click="windowIconFunction($event, 'mediumSized');clearAllListeners();" class="left-icon cursor-pointer">
                  <v-icon name="external-link-alt" scale="2" flip="vertical" />
                </span>
                <span v-if="editor.fromIPD" @click="columnsIconFunction($event, editor.IPDContainerName)" class="left-icon cursor-pointer" style="margin: 3px 5px 0px 10px;">
                  <v-icon name="columns" scale="1.5" />
                </span>
                <span v-if="timesIconFunction" @click="beforeTimesIconFunction($event)" class="cursor-pointer" style="margin-top: 3px;">
                  <v-icon name="times" scale="1.5" />
                </span>
            </div>
            </div>

            <upload-field v-if="type === 'notetemplate'" :filesDropped="filesDropped" style="z-index: 99;"/>

            <vue-draggable-resizable
                v-if="showTemplateSelector"
                class="note-item charting-container"
                :resizable="false"
                :draggable="false"
                :x="x"
                :y="y"
                :style="{'z-index': 2999}"
                @activated="draggingClick()"
            >
                <div class="label space-between">
                    <span></span>
                    <span style="margin-left: 6px;">{{ $t('general.addPagesNote') }}</span>
                    <span style="margin-right: 6px;" @click.stop="showTemplateSelector = false"><v-icon name="times" /></span>
                </div>
                <templates-container
                    :topRightIconFunction="addPagesFromTemplate"
                    :topRightIcon="'brush'"
                    :parentComponent="'editing-page'"
                    style="height: inherit; width: inherit; overflow-y: scroll; overflow-x: hidden; padding: 10px; margin: 2px -5px;"
                />
            </vue-draggable-resizable>

            <vue-draggable-resizable
                v-if="showChartingContainer"
                :parent="true"
                class="note-item charting-container"
                @dragstart="onDragStart"
                @dragstop="onDragStop"
                :resizable="false"
                :x="x"
                :y="y"
                :style="{'z-index': zIndexDragging}"
                @activated="draggingClick()"
            >
                <div class="label space-between">
                    <span></span>
                    <span style="margin-left: 6px;">{{ $t('general.editOtherNotes') }}</span>
                    <span style="margin-right: 6px;" @click.stop="toggleChartingContainer($event)"><v-icon name="times" /></span>
                </div>
                <charting-container
                    style="height: inherit; width: inherit; overflow-y: scroll; overflow-x: hidden; margin: 2px -5px;"
                    :parentComponent="'canvas-editor'"
                    :mainForm="notesMapped[editor.mainFormId]"
                    :currentNoteId="editor.noteId"
                    :refreshCanvasEditor="refreshCanvasEditor"
                    :invoice="invoicesMappedById[editor.invoiceId]"
                    :notesMapped="notesMapped"
                    :categoriesMapped="categoriesMapped"
                />
            </vue-draggable-resizable>
            <div :class="`middle-${toolbarPosition} middle-toolbar`" style="z-index: 1999;" v-if="!openSessions">
                <div class="helpers mb-3">
                    <div @click="switchToolbarPosition"><v-icon :name="`angle-${toolbarOppositePosition}`" scale="2" /></div>
                    <div @click="deleteNodeText()"><v-icon :name="`trash`" scale="2" v-b-tooltip.hover title="Delete"/></div>
                    <div @click="switchMode('pointer')"><v-icon name="mouse-pointer" scale="2" :color="mode === 'pointer' ? 'green' : ''" v-b-tooltip.hover title="Select" /></div>
                    <div :id="`${toolbarPosition}-pencil`" @click="switchMode('pencil')"><v-icon name="pen" scale="2" :color="mode === 'pencil' ? 'green' : ''" v-b-tooltip.hover title="Brush" /></div>
                    <div @click="switchMode('eraser')"><v-icon name="eraser" scale="2" :color="mode === 'eraser' ? 'green' : ''" v-b-tooltip.hover title="Eraser" /></div>
                    <div :id="`${toolbarPosition}-text`" @click="switchMode('text')"><v-icon name="i-cursor" scale="2" :color="mode === 'text' ? 'green' : ''" v-b-tooltip.hover title="Text Box" /></div>
                    <div :id="`${toolbarPosition}-code`" @click="switchMode('code')"><v-icon name="code" scale="2" :color="mode === 'code' ? 'green' : ''" v-b-tooltip.hover title="Auto Fill" /></div>
                    <div v-if="type === 'note'" @click.stop="toggleChartingContainer($event)"><v-icon name="th" scale="2" :color="showChartingContainer ? 'green' : ''" v-b-tooltip.hover title="Edit" /></div>
                    <div v-if="type === 'note'" @click.stop="toggleTemplateSelector()"><v-icon name="plus" scale="2" :color="showTemplateSelector ? 'green' : ''" v-b-tooltip.hover title="Add" /></div>
                    <div @click="undo()" v-if="historyStep[currentPageId] > -1"><v-icon name="undo" scale="2" v-b-tooltip.hover title="Undo" /></div>
                    <div v-else style="color: grey;"><v-icon name="undo" scale="2" v-b-tooltip.hover title="Undo" /></div>
                    <div @click="redo()" v-if="historyStep[currentPageId] < (histories[currentPageId] || []).length - 1"><v-icon name="redo" scale="2" v-b-tooltip.hover title="Redo" /></div>
                    <div v-else style="color: grey;"><v-icon name="redo" scale="2" v-b-tooltip.hover title="Redo" /></div>
                    <div @click="save()" v-if="(editor.draft === undefined || editor.draft)">
                        <span class="save-icon-wrapper">
                            <v-icon name="save" scale="2" v-b-tooltip.hover title="Save"/>
                            <span class="unsaved-edits-badge" v-if="hasUnsavedChanges"></span>
                        </span>
                    </div>
                    <div v-if="editor.publishable && (editor.draft === undefined || editor.draft)" @click="save(true)">
                        <span class="save-icon-wrapper">
                            <v-icon name="stamp" scale="2" v-b-tooltip.hover title="Stamp"/>
                        </span>
                    </div>
                    <div v-if="mode === 'pencil' || mode === 'eraser'" class="input-container">
                        <span :id="`${toolbarPosition}-font-size`"><b-input type="number" title="Brush size" size="sm" :value="strokeWidth[mode]" class="input-field" disabled/></span>
                        <b-tooltip :target="`${toolbarPosition}-font-size`" variant="light" triggers="click blur" :placement="`${toolbarOppositePosition}`"
                          custom-class="range-tool-tip">
                        </b-tooltip>
                    </div>
                    <div v-if="mode === 'pencil'">
                        <div title="Brush Color" class="color-picker">
                            <swatches
                                v-model="strokeColor"
                                colors="text-advanced"
                                swatchSize="22"
                                :popover-to="`${toolbarOppositePosition}`"
                                :trigger-style="{ width: '26px', height: '26px' }"
                            />
                        </div>
                    </div>
                    <div v-if="mode === 'text' || mode === 'code' || activeText !== null" class="input-container">
                        <span :id="`${toolbarPosition}-text-size`"><b-input type="number" title="Brush size" size="sm" :value="textSize" class="input-field" disabled/></span>
                        <b-tooltip :target="`${toolbarPosition}-text-size`" variant="light" triggers="click blur" :placement="`${toolbarOppositePosition}`"
                          custom-class="range-tool-tip">
                            <b-form-input class="input-range" :value="textSize" @input="updateTextSize" type="range" min="2" max="50" step="2" orient="vertical"></b-form-input>
                        </b-tooltip>
                    </div>
                    <div v-if="mode === 'text' || mode === 'code' || activeText !== null">
                        <div title="Brush Color" class="color-picker">
                            <swatches
                                :value="textColor"
                                @input="updateTextColor"
                                colors="text-advanced"
                                :popover-to="`${toolbarOppositePosition}`"
                                :trigger-style="{ width: '26px', height: '26px' }"
                            />
                            <span class="tooltiptext">Brush Colorrtr</span>
                        </div>
                    </div>
                    <div>
                        <span @click="openPermissionsModal = !openPermissionsModal">
                            <v-icon class="cursor-pointer" name="lock" scale="1" v-b-tooltip.hover title="lock" />
                        </span>&nbsp;/
                        <span @click="openSessions = !openSessions">
                            <v-icon class="cursor-pointer" name="info-circle" scale="1" v-b-tooltip.hover title="Info"/>
                        </span>
                    </div>
                </div>
                <div :class="toolbar_position !== 'left' ? 'hide' : null" class="mb-3">
                    <div class="page-navigators" v-if="editor.pagesIdOrder.length > 1">
                        <span class="left-arrow" @click="goToPage2(currentPageIndex - 1)" v-if="currentPageIndex !== 0">
                            <v-icon name="angle-left" scale="1.7" />
                        </span>
                        <span class="left-arrow" v-else style="color: grey;">
                            <v-icon name="angle-left" scale="1.7" />
                        </span>
                        <span class="page-number">{{currentPageIndex + 1}} / {{editor.pagesIdOrder.length}}</span>
                        <span class="right-arrow" @click="goToPage2(currentPageIndex + 1)" v-if="currentPageIndex !== editor.pagesIdOrder.length - 1">
                            <v-icon name="angle-right" scale="1.7" />
                        </span>
                        <span class="right-arrow" v-else style="color: grey;">
                            <v-icon name="angle-right" scale="1.7" />
                        </span>
                    </div>
                </div>
            </div>
            <div class="middle space-between">
                <div class="middle-middle" :style="{'z-index': zIndexCanvas}" :class="{'scroll-mode': featureFlags.scrollNoteEditor}">

                    <canvas-editor
                        v-if="editor.currentPageId !== null && editor.currentPageId !== undefined"
                        :type="type"
                        :overlayClick="overlayClick"
                        ref="canvasEditor"
                        :mode="mode"
                        :orientation="orientation"
                        :originalOrientation="originalOrientation"
                        :switchMode="switchMode"
                        :toggleTypingMode="toggleTypingMode"
                        :strokeColor="strokeColor"
                        :strokeWidth="strokeWidth"
                        :textSize="textSize"
                        :textColor="textColor"
                        :setActiveText="setActiveText"
                        :openSessions="openSessions"
                    />

                    <span class="dock-container">
                        <ul class="dock" v-if="editor.pagesIdOrder.length > 0">
                            <li v-for="(pageId, index) in editor.pagesIdOrder" :key="(notesMapped[pageId] || {}).updatedAt + pageId" @click="goToPage2(index)">
                              <img
                                  v-if="editor.pages[pageId].tempBase64 || editor.pages[pageId].signedThumbnailKey"
                                  :src="editor.pages[pageId].tempBase64 || editor.pages[pageId].signedThumbnailKey"
                              />
                            </li>
                            <li v-if="type === 'notetemplate'" class="add-img-to-template-container">
                              <form enctype="multipart/form-data" class="add-img-to-template btn btn-secondary">
                              <input
                                type="file"
                                @change="addPagesToTemplate($event.target.files)"
                                :value="inputFileValue"
                                accept="image/jpeg,image/png,application/pdf" class="input-file">
                                Upload
                                <br/> More
                              </form>
                            </li>
                        </ul>
                    </span>
                </div>
            </div>
            <div v-if="openPermissionsModal"
            style="position: absolute; display: flex; justify-content: space-around; align-items: center; background-color: rgba(167,167,167,0.8); height: 100vh; width: 100vw; z-index: 3000;">
                <div class="permissions-modal">
                    <div class="modal-header">
                        <div v-if="type == 'notetemplate'">
                            <div>Permissions for Notes Created from this Template</div>
                            <div>(existing notes created from this template will not be affected by new changes)</div>
                        </div>
                        <div v-else>
                            <div>{{ $t('general.permissionsForNote') }}</div>
                        </div>
                        <div class="cursor-pointer" @click="resetPermissions(); openPermissionsModal = false">X</div>
                    </div>
                    <div class="modal-body">
                        <div>
                            <b-table striped hover :fields="fields" :items="items">
                                <template slot="name" slot-scope="data">
                                    <b class="text-info">
                                        <span>{{userAndRolesDictionary[data.item.value]}}</span>
                                        <span v-if="type == 'note' && data.item.value == (coverPage.creatorId._id || coverPage.creatorId.id)"> (Creator)</span>
                                    </b>
                                </template>
                                <template slot="update" slot-scope="data">
                                    <b-form-checkbox
                                        v-model="data.item.update"
                                        @input="updatePermissionRow(data)"
                                        :disabled="data.item.value === 'creator' || (type == 'note' && data.item.value == coverPage.creatorId._id)"
                                    />
                                </template>
                                <template slot="action" slot-scope="data">
                                    <b class="text-info"
                                        v-if="!(type === 'notetemplate' && data.item.value === 'creator') && !(type === 'note' && data.item.value === coverPage.creatorId._id)"
                                        @click="removePermissionRow(data)"
                                    >X
                                    </b>
                                </template>
                                <template slot="table-caption">
                                    <select v-model="selected" class="mb-3" @change="addNewPermissionRow">
                                        <option :value="null">{{ $t('general.addPermission') }}</option>
                                        <optgroup :label="$t('general.generalRole')">
                                            <option value="anyone" :disabled="allSelectedPermissions['anyone']">{{ $t('general.anyone') }}</option>
                                            <option v-if="type === 'notetemplate'" value="creator_own_roles" :disabled="allSelectedPermissions['creator_own_roles']">Users from Creator's Role(s)</option>
                                        </optgroup>
                                        <optgroup :label="$t('general.byRoles')">
                                            <option
                                                v-for="(role, i) in allRoles"
                                                :key="role._id+i"
                                                :value="role._id"
                                                :disabled="allSelectedPermissions[role._id]"
                                            >{{userAndRolesDictionary[role._id]}}
                                            </option>
                                        </optgroup>
                                        <optgroup :label="$t('general.byIndividualUsers')">
                                            <option
                                                v-for="(user, i) in allUsers"
                                                :key="user.id+i"
                                                :value="user.id"
                                                :disabled="allSelectedPermissions[user.id]"
                                            >{{userAndRolesDictionary[user.id]}}
                                            </option>
                                        </optgroup>
                                    </select>
                                </template>
                            </b-table>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <b-button @click="resetPermissions(); openPermissionsModal = false">{{ $t('general.cancel') }}</b-button>
                        <b-button variant="success" @click="openPermissionsModal = false">{{ $t('pharmacy.confirm') }}</b-button>
                    </div>
                </div>
            </div>
        </div>
      </span>
</template>

<script>
import ChartingContainer from '@/components/ChartingContainer';
import TemplatesContainer from '@/components/TemplatesContainer.vue';
import CanvasEditor from '@/components/CanvasEditor.vue';
import CanvasEditorHistory from '@/components/CanvasEditorHistory.vue';
import UploadField from '@/components/utils/UploadField.vue';
import UploadManager from '@/components/utils/UploadManager2.vue';

import Swatches from 'vue-swatches';
import moment from 'moment';
import uuid from 'uuid';
import _ from 'lodash';

import {
  mapState, mapGetters, mapMutations,
} from 'vuex';

import { bus } from '@/main.js';

export default {
  name: 'CanvasEditorContainer',
  props: [
    'type',
    // 'filesDropped',
    'extractedNote',
    'fetchAllNoteTemplates',
    'notesMapped', // make the following few default to {}
    'invoicesMappedById',
    'minusIconFunction',
    'windowIconFunction',
    'columnsIconFunction',
    'timesIconFunction',
    'patient',
    // 'goToPage' // this one needs to be context-specific (note or notetemplate)
  ],
  components: {
    CanvasEditor,
    CanvasEditorHistory,
    ChartingContainer,
    TemplatesContainer,
    UploadField,
    UploadManager,
    Swatches,
  },
  data() {
    return {
      showTemplateSelector: false,
      showChartingContainer: false,
      x: 50,
      y: 50,
      zIndexDragging: 1088,
      zIndexCanvas: 1078,
      toolbar_position: 'left',
      mode: 'pencil',
      orientation: 'Portrait',
      originalOrientation: 'Portrait',

      showUploadManager: false,
      filesToBeUploaded: [],
      filesToBeAdded: [],
      uploading: false,
      strokeColor: 'black',
      strokeWidth: {
        pencil: '2',
        eraser: '2',
      },
      textSize: 24,
      textColor: 'black',
      activeText: null,
      toolbarPosition: 'left',
      toolbarOppositePosition: 'right',
      keysDown: {},
      isTyping: false,
      openSessions: false,
      openPermissionsModal: false,
      editorNameState: null,
      editorCategoryState: null,
      isEditingCategory: false,
      isEditingName: false,
      // withUpload: false,
      info: {},
      inputFileValue: null,
      fields: [
        { key: 'name', label: this.$t('general.rolesAndIndividual') },
        { key: 'update', label: this.$t('general.edit') },
        { key: 'action', label: this.$t('general.action') },
      ],
      items: [],
      selected: null,
      allSelectedPermissions: {},
      userAndRolesDictionary: {
        creator: 'Creator',
        anyone: 'Anyone',
        creator_own_roles: "Users from Creator' Role(s)",
      },
    };
  },
  // watch: {
  //     selectedClinic(clinic) {
  //     this.loadRoles(clinic._id);
  //     this.loadCorporates(clinic._id);
  //     this.userForm.roles = [];
  //     },
  // },
  computed: {
    currentPageId() {
      return this.editor.currentPageId;
    },
    currentPageIndex() {
      return this.editor.pagesIdOrder.indexOf(this.editor.currentPageId);
    },
    selectedCategory() {
      return this.editor.categoryId || this.uncategorizedCategoryId;
    },
    ...mapState({
      editor(state) {
        return state[this.type].editor;
      },
      originalEditorName(state) {
        return state[this.type].originalEditorName;
      },
      histories(state) {
        return state[this.type].histories;
      },
      historyStep(state) {
        return state[this.type].historyStep;
      },
    }),
    coverPage() {
      return this.editor.pages[this.editor.pagesIdOrder[0]];
    },
    hasUnsavedChanges() {
      return !_.isEmpty(this.editor.pagesWithChanges) || this.editor.hasPermissionChanges;
    },
    filteredCategoriesOptions() {
      let output = this.categoriesOptions;
      const userAccessChildrenFNIDs = localStorage.getItem('acl__childrenFnIds');
      if (userAccessChildrenFNIDs && this.categoriesOptions) {
        const userAccess = JSON.parse(userAccessChildrenFNIDs);
        output = this.categoriesOptions.filter(c => userAccess[c.value]);
      }
      return output;
    },
    editorScrollStyle() {
      if (this.featureFlags.scrollNoteEditor) {
        return 'overflow: auto; height: 100vh;';
      }
      return '';
    },
    ...mapGetters('acl', { allRoles: 'roles' }),
    ...mapGetters('users', { allUsers: 'all' }),
    ...mapState('auth', ['user']),
    ...mapState('note', ['notecategories', 'uncategorizedCategoryId', 'categoriesMapped', 'viewer', 'categoriesOptions']),
    ...mapGetters('config', ['featureFlags']),
  },
  watch: {
    strokeWidth: {
      handler(val) {
        localStorage.setItem('pencilStrokeWidth', val.pencil);
        localStorage.setItem('eraserStrokeWidth', val.eraser);
      },
      deep: true,
    },
    strokeColor(val) {
      localStorage.setItem('strokeColor', val);
    },
  },
  methods: {
    emptyNameCheck() {
      if (this.editor.name === '') {
        alert('Title field is required');
        this.updateEditorMetadata({ key: 'name', value: this.originalEditorName });
        return false;
      }
      return true;
    },
    closeHistory() {
      this.openSessions = false;
    },
    redrawSession(session) {
      // Cannot go to session when canvas editor is not ready
      if (!this.$refs.canvasEditor) {
        return;
      }
      this.$refs.canvasEditor.goToSession(session);
    },
    switchToolbarPosition() {
      if (this.toolbarPosition === 'left') {
        this.toolbarPosition = 'right';
        this.toolbarOppositePosition = 'left';
      } else {
        this.toolbarPosition = 'left';
        this.toolbarOppositePosition = 'right';
      }
    },
    setActiveText(textNode) {
      // console.log('setActiveText - textNode is ',  textNode);
      this.activeText = textNode;
      if (this.activeText) {
        if (this.activeText.fill()) {
          this.textColor = this.activeText.fill();
        }
        if (this.activeText.fontSize()) {
          this.textSize = this.activeText.fontSize();
        }
      }
    },
    updateTextSize(e) {
      // Cannot update text size when canvas editor is not ready
      if (!this.$refs.canvasEditor) {
        return;
      }
      // console.log('update text size, e is ', e);
      this.textSize = parseInt(e);
      localStorage.setItem('textSize', this.textSize);
      this.$refs.canvasEditor.updateTextSize(this.textSize);
    },
    updateTextColor(e) {
      // Cannot update text color when canvas editor is not ready
      if (!this.$refs.canvasEditor) {
        return;
      }
      // console.log('update text color, e is ', e);
      this.textColor = e;
      localStorage.setItem('textColor', this.textColor);
      // console.log('update color this.activeText is ', this.activeText);
      this.$refs.canvasEditor.updateTextColor(this.textColor);
    },
    shortUuid() {
      let firstPart = (Math.random() * 46656) | 0;
      let secondPart = (Math.random() * 46656) | 0;
      firstPart = (`000${firstPart.toString(36)}`).slice(-3);
      secondPart = (`000${secondPart.toString(36)}`).slice(-3);
      return firstPart + secondPart;
    },
    deleteNodeText(){
      // Cannot delete text when canvas editor is not ready
      if (!this.$refs.canvasEditor) {
        return;
      }
      this.$refs.canvasEditor.deleteTextNode();
    },
    switchMode(newMode) {
      // Cannot switch mode when canvas editor is not ready
      if (!this.$refs.canvasEditor) {
        return;
      }
      if (newMode === 'pointer') {
        this.$refs.canvasEditor.setDraggable(true);
      } else {
        this.$refs.canvasEditor.setDraggable(false);
        if (newMode === 'pencil' || newMode === 'eraser') {
          this.$refs.canvasEditor.toggleTransformers(false);
          this.setActiveText(null);
        }
      }
      this.mode = newMode;
    },
    toggleTypingMode(flag) {
      this.isTyping = flag;
    },
    filesDropped(file, i) {
      console.log('CEC - filesDropped! file is ', file);
      const name = file.name.replace(/\.[^/.]+$/, '');
      this.filesToBeUploaded.push({
        file, name, categoryId: this.uncategorizedCategoryId, uuid: uuid(), status: 'Ready',
      });
      this.showUploadManager = true;
    },
    closeUploadManager() {
      this.filesToBeUploaded = [];
      this.showUploadManager = false;
    },
    updateUploadLineItem(e, uuid, key) {
      this.filesToBeUploaded = this.filesToBeUploaded.map((file) => {
        if (uuid === file.uuid) {
          const clone = Object.assign({}, file);
          clone[key] = e;
          return clone;
        }
        return file;
      });
    },
    uploadFiles() {
      console.log('CEC - uploadFiles');
      const self = this;
      let numJobs = 0;
      let doneJobs = 0;
      this.uploading = true;
      // this.filesToBeUploaded.forEach((elem, i) => {
      let promises = [];
      // for (let i=0; i<self.filesToBeUploaded.length; i++) {
      // let elem = self.filesToBeUploaded[i];
      console.log('this.filesToBeUploaded is ', this.filesToBeUploaded);
      promises = this.filesToBeUploaded.map((elem, i) => new Promise((resolve, reject) => {
        if (elem.status === 'Uploaded') {
          return resolve();
        }
        numJobs++;
        const { file } = elem;
        // key, originalImageKey, categoryId, patientId, visitId, name, type: drawing, [pageNumber, otherPagesId]
        const imgapiurl = process.env.VUE_APP_imgApiUrl || process.env.VUE_APP_apiUrl
        const apiurl = imgapiurl || `${window.location.origin}/api`;
        const url = `${apiurl}/${this.type}/uploadTempImages?uuid=${elem.uuid}&type=${this.type}`;
        // let url = `${process.env.VUE_APP_apiUrl}/note/uploadNotes?mainFormId=${this.mainForm._id}&visitId=${this.invoice._id}&patientId=${this.patient._id}&name=${elem.name}&categoryId=${elem.categoryId}&type=drawing`;
        const xhr = new XMLHttpRequest();
        const formData = new FormData();
        xhr.open('POST', url, true);
        xhr.setRequestHeader('X-CSRF', localStorage.getItem('xsrf'));
        xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('access_token')}`);
        xhr.setRequestHeader('Accept', 'application/json');
        // xhr.setRequestHeader("Content-Type", "multipart/form-data");
        xhr.setRequestHeader('Cache-Control', 'no-cache');
        const orgCode = localStorage.getItem('orgCode');
        xhr.setRequestHeader('x-org', orgCode);
        const xclinic = localStorage.getItem('xclinic');
        xhr.setRequestHeader('x-clinic', xclinic);

        // Add following event listener
        xhr.upload.addEventListener('progress', (e) => {
          console.log('got progress! e.loaded, e.total are ', e.loaded, e.total);
          // updateProgress(i, (e.loaded * 100.0 / e.total) || 100)
        });

        xhr.addEventListener('readystatechange', (e) => {
          console.log('readystatechange e is ', e);
          console.log('readystatechange xhr is ', xhr);
          if (xhr.readyState == 4 && xhr.status == 200) {
            // Done. Inform the user
            // add note commit and update mainForm commit
            const res = JSON.parse(xhr.response);
            doneJobs++;
            return resolve(res);
            // for (let i=0; i<res.length; i++) {
            //     self.$store.commit('notetemplate/addPage', {key: res[i].key, tempBase64: res[i].signedKey});
            // }
            // doneJobs++;
            // // self.$set(self.filesToBeUploaded[i], 'status', 'Uploaded');
            // if (numJobs === doneJobs) {
            //     self.uploading = false;
            //     self.closeUploadManager();
            // }
          }
          if (xhr.readyState == 4 && xhr.status != 200) {
            // Error. Inform the user
            console.log('not 200! xhr is ', xhr);
            return reject(xhr.response);
            // doneJobs++;
            // if (numJobs === doneJobs) {
            //     self.uploading = false;
            // }
            // self.flash(xhr.response, 'error', { timeout: 3000 });
            // self.$set(self.filesToBeUploaded[i], 'status', 'Error');
          }
        });
        formData.append('myFile', file);
        console.log('file, formData is ', file, formData);
        xhr.send(formData);
      }));
      Promise.all(promises)
        .then((allResults) => {
          console.log('all results are ', allResults);
          for (let i = 0; i < allResults.length; i++) {
            const tempUploadedFile = allResults[i];
            for (let j = 0; j < tempUploadedFile.length; j++) {
              self.$store.commit(`${this.type}/addPage`, {
                shortUuid: this.shortUuid(), type: 'drawing', key: tempUploadedFile[j].key, tempBase64: tempUploadedFile[j].signedOriginalImageKey,
              });
            }
          }
          console.log('numJobs and doneJobs are ', numJobs, doneJobs);
          if (numJobs === doneJobs) {
            self.uploading = false;
            self.closeUploadManager();
          }
        });
    },

    async toggleTemplateSelector() {
      const templates = await this.fetchAllNoteTemplates({ excludeFields: ['formDataBuilder', 'formDataBuilderOrder'] });
      this.showTemplateSelector = !this.showTemplateSelector;
    },

    addPagesToTemplate(files) {
      files = [...files];
      for (let i = 0; i < files.length; ++i) {
        const file = files[i];
        const name = file.name.replace(/\.[^/.]+$/, '');
        this.filesToBeUploaded.push({
          file,
          name,
          categoryId: this.selectedCategory,
          uuid: uuid(),
          status: 'Ready',
        });
      }
      this.uploadFiles();
    },
    addPagesFromTemplate(templateId) {
      const params = { templateId, noteId: this.editor.pagesIdOrder[0] };
      params.creatorId = this.user.id;
      params.info = this.info;
      params.patientId = this.patient._id;
      this.$store.dispatch(`${this.type}/addPagesFromTemplate`, params)
        .then((res) => {
          if (res.ok) {
            // payload.totalPages = selectedCharting.otherPagesId.length + 1; // other pages plus the cover page itself
            // payload.currentPage = 1; // always start with 1
            // payload.allPages
            const updatedNote = res.body[0];
            const newNoteFromTemplate = res.body[1];
            // for (let i=0; i<newNoteFromTemplate.length; i++) {
            //     let id = newNoteFromTemplate[i]._id;
            //     this.histories[id] = [];
            //     this.historyStep[id] = -1;
            // }
            // this.$store.commit('note/ADD_NEW_PAGE_EXTRACTED_NOTE', updatedNote);
            this.showTemplateSelector = false;
            this.flash('New page(s) added to this note successfully', 'success', { timeout: 3000 });
          } else {
            this.flash('Failed to add new page(s), please try again', 'error', { timeout: 5000 });
          }
        });
    },
    overlayClick() {
      this.zIndexCanvas = 1088;
      this.zIndexDragging = 1078;
    },
    draggingClick() {
      this.zIndexCanvas = 1078;
      this.zIndexDragging = 1088;
    },
    toggleChartingContainer(e) {
      if (this.showChartingContainer) { // closing charting container
        // console.log('IF - closing charting container');
        this.showChartingContainer = !this.showChartingContainer;
        this.zIndexCanvas = 1088;
        this.zIndexDragging = 1078;
      } else { // opening charting container
        // console.log('ELSE - opening charting container');
        this.showChartingContainer = !this.showChartingContainer;
        this.zIndexCanvas = 1078;
        this.zIndexDragging = 1088;
      }
      // e.preventDefault();
    },
    onDragStart(a, b, c, d) {
      // console.log('onDragStart - ', a,b,c,d);
    },
    onDragStop(left, top, e) {
      const newX = left;
      const newY = top;
      this.x = newX;
      this.y = newY;
    },
    undo() {
      // Cannot undo when canvas editor is not ready
      if (!this.$refs.canvasEditor) {
        return;
      }
      if (this.isTyping) {
        const node = document.getElementById('editing-canvas');
        node.click();
      }
      this.$refs.canvasEditor.undo();
    },
    redo() {
      // Cannot redo when canvas editor is not ready
      if (!this.$refs.canvasEditor) {
        return;
      }
      if (this.isTyping) {
        const node = document.getElementById('editing-canvas');
        node.click();
      }
      this.$refs.canvasEditor.redo();
    },
    async save(publishing) {
      if (publishing) {
        const confirm = window.confirm('Publishing is irreversible, proceed?');
        if (!confirm) return;
        this.updateEditorMetadata({ key: 'draft', value: false });
      }
      // let base64 = this.$refs.canvasEditor.getDataURL();
      // console.log('base64 is ', base64);
      if (this.isTyping) {
        const node = document.getElementById('editing-canvas');
        node.click();
      }
      if (!this.emptyNameCheck()) return;
      let permissions;
      if (this.editor.hasPermissionChanges && this.items.length > 0) {
        permissions = {};
        this.items.forEach((item) => {
          permissions[item.value] = {};
          if (item.update) {
            permissions[item.value].update = 1;
          } else {
            permissions[item.value].update = 0;
          }
        });
      }
      try {
        const resp = await this.$store.dispatch(`${this.type}/saveEditorUpdates`, { permissions });
        if (resp && resp.ok) {
          this.updateEditorMetadata({ key: 'updatedAt', value: resp.data[0].updatedAt });
          // do the params check for /list/new or /list/:id
          // mutate the editor.pages now
          this.flash(`Charting ${publishing ? 'published' : 'updates saved'} successfully`, 'success', { timeout: 3000 });
          // change to this new category
          if (this.type === 'notetemplate') {
            this.selectTemplateCat(this.editor.categoryId);
          } else {
            const coverPageId = this.editor.pages[this.editor.pagesIdOrder[0]]._id;
            this.deleteOneCachedPermission({ chartingId: coverPageId });
          }
          // if the viewer is opened, dont emit. fetch only when viewer is closed.
          // because user still might be viewing the charting.
          if (!this.viewer.open) {
            bus.$emit('canvas_saved');
          }
        } else {
          this.flash('Failed to save charting updates, please try again', 'error', { timeout: 5000 });
        }
      } catch (error) {
        this.flash(`Error: ${error}`, 'error', { timeout: 5000 });
      }
    },
    refreshCanvasEditor() {
      // Cannot refresh when canvas editor is not ready
      if (!this.$refs.canvasEditor) {
        return;
      }
      // console.log('CEC - refreshCanvasEditor...');
      this.$refs.canvasEditor.refreshCanvasEditor();
    },
    goToPage2(newPageIndex) {
      // console.log('goToPage2...');
      const newPageId = this.editor.pagesIdOrder[newPageIndex];
      if (newPageId === this.editor.currentPageId) {
        return;
      }
      this.goToPage(newPageIndex);
      // console.log('goToPage2...');
      // Cannot go next page when canvas editor is not ready
      if (!this.$refs.canvasEditor) {
        return;
      }
      this.$refs.canvasEditor.nextPage(newPageId); // let's try letting canvas-container watch changes on the image key
    },
    reOrientate() {
      // Cannot reorient when canvas editor is not ready
      if (!this.$refs.canvasEditor) {
        return;
      }
      // console.log('in NWS reOrientate...');
      this.$refs.canvasEditor.reOrientate();
      // console.log('in parent - ');
      if (this.orientation === 'Landscape') {
        this.orientation = 'Portrait';
        if (this.originalOrientation === null) {
          this.originalOrientation = 'Landscape';
        }
      } else {
        this.orientation = 'Landscape';
        if (this.originalOrientation === null) {
          this.originalOrientation = 'Portrait';
        }
      }
    },
    shortcutKeys(e) {
      if (this.isTyping) return;
      // console.log('in shortcutKeys e is ', e);
      // console.log('e key is ', e.key);
      const { key } = e;
      this.keysDown[key] = e.type === 'keydown';
      // 20190917 - cmd + key not stable yet, probably need to use debounce
      // if (this.keysDown['Meta']) {
      //     if (this.keysDown['Shift'] && this.keysDown['z']) {
      //         _.debounce(this.redo(), 700);
      //     } else if (this.keysDown['z']) {
      //         _.debounce(this.undo(), 700);
      //     }
      //     // else if (this.keysDown['y']) this.redo();
      // }
      if (this.keysDown.Backspace) {
        this.deleteNodeText();
      }
      if (this.keysDown[key]) {
        if (key == 'a') {
          this.switchMode('pointer');
        } else if (key == 'p') {
          this.switchMode('pencil');
        } else if (key == 'e') {
          this.switchMode('eraser');
        } else if (key == 't') {
          this.switchMode('text');
        } else if (key == 'ArrowLeft') {
          if (this.currentPageIndex === 0) return;
          this.goToPage2(this.currentPageIndex - 1); // currentPage, allPages
        } else if (key == 'ArrowRight') {
          if (this.currentPageIndex === this.editor.pagesIdOrder.length - 1) return;
          this.goToPage2(this.currentPageIndex + 1);
        }
      }
    },
    addMoment(date_m, num, type) {
      const clone = moment(date_m);
      return clone.add(num, type);
    },
    addWeekdays(date_m, days) {
      let clone = moment(date_m);
      while (days > 0) {
        clone = clone.add(1, 'days');
        // decrease "days" only if it's a weekday.
        if (clone.isoWeekday() !== 6 && clone.isoWeekday() !== 7) {
          days -= 1;
        }
      }
      return clone;
    },
    addNewPermissionRow(e) {
      const { value } = e.target;
      const name = this.userAndRolesDictionary[value];
      this.items.push({ name, value, update: true });
      this.allSelectedPermissions[value] = true;
      this.selected = null;
      if (!this.editor.hasPermissionChanges) {
        this.updateEditorMetadata({ key: 'hasPermissionChanges', value: true });
      }
    },
    removePermissionRow(data) {
      const { index, item: { value } } = data;
      this.items = this.items.filter((item, idx) => idx !== index);
      this.allSelectedPermissions[value] = false;
      if (!this.editor.hasPermissionChanges) {
        this.updateEditorMetadata({ key: 'hasPermissionChanges', value: true });
      }
    },
    updatePermissionRow(data) {
      const { index, item, item: { value } } = data;
      this.updateEditorMetadata({ key: 'hasPermissionChanges', value: true });
    },
    clearAllListeners(){
      console.log('CEC destroyed');
      window.removeEventListener('keydown', this.shortcutKeys, true);
      window.removeEventListener('keyup', this.shortcutKeys, true);
      window.removeEventListener('unload', this.beforeTimesIconFunction, true);
    },
    resetPermissions() {
      this.items = this.originalItems;
      this.updateEditorMetadata({ key: 'hasPermissionChanges', value: false });
    },
    beforeTimesIconFunction() {
      if (!_.isEmpty(this.editor.pagesWithChanges)) {
        let confirmMessage = 'There are unsaved edits';
        if (this.editor.hasPermissionChanges) {
          confirmMessage += ' and permissions changes';
        }
        confirmMessage += ', exiting will result in loss of these edits.\n\nProceed?';
        const confirm = window.confirm(confirmMessage);
        if (!confirm) return;
      } else if (this.editor.hasPermissionChanges) {
        const confirm = window.confirm('There are unsaved permissions changes, exiting will result in loss of these edits.\n\nProceed?');
        if (!confirm) return;
      }
      if (this.editor.fromIPD) {
        bus.$emit('close_ipd_editor', this.editor.IPDContainerName);
        this.timesIconFunction();
        return;
      }
      this.timesIconFunction();
    },
    ...mapMutations({
      updateEditorMetadata(commit, payload) {
        return commit(`${this.type}/updateEditorMetadata`, payload);
      },
      goToPage(commit, payload) {
        return commit(`${this.type}/goToPage`, payload);
      },
    }),
    ...mapMutations('note', ['selectTemplateCat', 'deleteOneCachedPermission']),
  },
  created() {
    const lastPencilStrokeWidth = localStorage.getItem('pencilStrokeWidth');
    const lastEraserStrokeWidth = localStorage.getItem('eraserStrokeWidth');
    const lastStrokeColor = localStorage.getItem('strokeColor');
    const lastTextSize = localStorage.getItem('textSize');
    const lastTextColor = localStorage.getItem('textColor');
    if (lastPencilStrokeWidth !== null && lastPencilStrokeWidth !== 'undefined') {
      this.strokeWidth.pencil = lastPencilStrokeWidth;
    }
    if (lastEraserStrokeWidth !== null && lastEraserStrokeWidth !== 'undefined') {
      this.strokeWidth.eraser = lastEraserStrokeWidth;
    }
    if (lastStrokeColor !== null && lastStrokeColor !== 'undefined') {
      this.strokeColor = lastStrokeColor;
    }
    if (lastTextSize !== null && lastTextSize !== 'undefined') {
      this.textSize = lastTextSize;
    }
    if (lastTextColor !== null && lastTextColor !== 'undefined') {
      this.textColor = lastTextColor;
    }
  },
  mounted() {
    window.addEventListener('keydown', this.shortcutKeys, true);
    window.addEventListener('keyup', this.shortcutKeys, true);
    window.addEventListener('unload', this.beforeTimesIconFunction, true);

    this.info.patient = this.patient;
    this.info.clinic = this.$store.state.clinic.selectedClinic;
    const today_m = moment();
    this.info.date = {
      today_date: today_m.format('dddd, MMMM Do YYYY'),
      one_b_day_later: this.addWeekdays(today_m, 1).format('dddd, MMMM Do YYYY'),
      three_b_days_later: this.addWeekdays(today_m, 3).format('dddd, MMMM Do YYYY'),
      five_b_days_later: this.addWeekdays(today_m, 5).format('dddd, MMMM Do YYYY'),
      one_day_later: this.addMoment(today_m, 1, 'day').format('dddd, MMMM Do YYYY'),
      three_days_later: this.addMoment(today_m, 3, 'day').format('dddd, MMMM Do YYYY'),
      five_days_later: this.addMoment(today_m, 5, 'day').format('dddd, MMMM Do YYYY'),
      two_weeks_later: this.addMoment(today_m, 2, 'week').format('dddd, MMMM Do YYYY'),
      one_month_later: this.addMoment(today_m, 1, 'month').format('dddd, MMMM Do YYYY'),
      two_months_later: this.addMoment(today_m, 2, 'month').format('dddd, MMMM Do YYYY'),
      three_months_later: this.addMoment(today_m, 3, 'month').format('dddd, MMMM Do YYYY'),
      six_months_later: this.addMoment(today_m, 6, 'month').format('dddd, MMMM Do YYYY'),
    };
    this.allRoles.forEach((role) => {
      this.userAndRolesDictionary[role._id] = role.roleName;
    });
    this.allUsers.forEach((user) => {
      this.userAndRolesDictionary[user.id] = user.name;
    });

    let creatorId = '';
    if (this.type === 'note') creatorId = this.coverPage.creatorId._id || this.coverPage.creatorId.id;

    if (this.type === 'notetemplate') {
      this.items.push({ name: 'Creator', value: 'creator', update: true });
      this.allSelectedPermissions.creator = true;
    } else { // type === 'note'
      this.items.push({ name: this.userAndRolesDictionary[creatorId], value: creatorId, update: true });
      this.allSelectedPermissions[creatorId] = true;
    }

    if (!this.editor.permissions || _.isEmpty(this.editor.permissions)) {
      this.items.push({ name: 'Anyone', value: 'anyone', update: true });
      this.allSelectedPermissions.anyone = true;
    } else {
      for (const name in this.editor.permissions) {
        if (name === 'creator_own_roles') {
          this.items.push({ name: "Users from Creator's Role(s)", value: 'creator_own_roles', update: !!this.editor.permissions[name].update });
          this.allSelectedPermissions.creator_own_roles = true;
        } else if (name === 'anyone') {
          this.items.push({ name: 'Anyone', value: 'anyone', update: !!this.editor.permissions[name].update });
          this.allSelectedPermissions.anyone = true;
        } else if (name !== 'creator' && name !== creatorId) { // for roles and users id
          this.items.push({ name: this.userAndRolesDictionary[name], value: name, update: !!this.editor.permissions[name].update });
          this.allSelectedPermissions[name] = true;
        }
      }
    }
    this.originalItems = this.items;
  },
  //destroyed() {
  //  console.log('CEC destroyed');
  //  window.removeEventListener('keydown', this.shortcutKeys, true);
  //  window.removeEventListener('keyup', this.shortcutKeys, true);
  //  window.removeEventListener('unload', this.beforeTimesIconFunction, true);
  //  this.beforeTimesIconFunction();
  //},
};
</script>

<style lang="scss" scoped>
.space-between {
  display: flex;
  justify-content: space-between;
}
#editing-canvas {
  height: 100%;
  width: 100%;
  pointer-events: all;
  overflow-y: hidden;
  .name {
      position: absolute;
      top: 12px;
      left: 12px;
  }
  .middle {
    margin: 0 auto;
    padding: 50px 0;
    .middle-left, .middle-right {
      height: 50%;
      margin: auto 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .middle-right2 {
        height: 50%;
        margin: auto 0;
    }
    .middle-middle {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: -15px;
      position: relative;
      &.scroll-mode {
        overflow: auto;
        height: 100vh;
      }
    }
  }
}
.middle-toolbar {
  position: absolute;
  top: 17%;
  &.middle-right {
    left: 97%;
  }
  .helpers {
    padding: 10px 10px;
    display: inline-block;
    div {
      margin: 10px 0;
    }
    .input-container {
      span {
        display: flex;
        .input-field {
          text-align: center;
          flex-grow: 1;
          width: 0;
        }
      }
      z-index: 1388;
    }
  }
  .page-navigators {
    padding: 4px;
    .page-number {
      margin: 0 5px;
    }
  }
  >div {
    text-align: center;
    background-color: rgb(221, 221, 221);
  }
}
.card {
  float: left;
  background: #fff;
  box-shadow: 0 0px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12);
}
.gridify.tiny {
  background-image:
    linear-gradient(transparent 11px, rgba(220,220,200,.8) 12px, transparent 12px),
    linear-gradient(90deg, transparent 11px, rgba(220,220,200,.8) 12px, transparent 12px);
    background-size: 100% 12px, 12px 100%;
}
.toolbar__box--top-right {
  background-color: rgb(221, 221, 221);
//   position: absolute;
//   z-index: 10;
//   right: 12px;
//   top: 12px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  >span {
    margin-right: 10px;
  }
}

.toolbar__box--middle-left {
  position: absolute;
  z-index: 10;
  left: 12px;
  top: 76px;
}
.toolbar__box--middle-right {
  position: absolute;
  z-index: 10;
  right: 12px;
  top: 72px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
}
.hide {
  visibility: hidden;
}
.dock-container {
  background: rgb(221, 221, 221);
  margin-top: 20px;

  .dock {
    list-style: none;
    padding: 0px;
    margin-top: -20px;
    text-align: center;

    img {
      height: 100px;
      margin: 0;
    }

    li {
      transition: all 0.1s ease-out;
      height: 100px;
      margin: 0;
      display: inline-block;
      padding: 0px;
      position: relative;
      border: 0.5px solid rgb(221, 221, 221);
      top: 0;
      div {
        position: absolute;
        width: 100%;
        .label {
          height: 7%;
          position: absolute;
          width: 100%;
          .right-span {
            .circle {
              position: absolute;
              height: 8px;
              width: 8px;
              border-radius: 16px;
              border: 1px solid black;
            }
            .fa-icon {
              border-radius: 60px;
              box-shadow: 0px 0px 2px #888;
              padding: 0.5em 0.6em;

            }
          }
        }
      }
      &:hover {
        transform: scale(2.1);
        margin: 20px;
        margin-top: -15px;
        top: -30px;
        position:relative;
        z-index: 19;
      }
    }
  }
}
.note-item {
  margin: 1em auto;
  border: 1px solid black;

  position: absolute;
  pointer-events: auto;

  &.minimized {
      visibility: hidden;
  }


  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.dragging-background {
  pointer-events: none;
  position: absolute;
  height: 100vh;
  width: 100vw;
}
.charting-container {
  box-shadow: 0 0 10px gray;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  height: 45vh !important;
  width: 35vw !important;
  .label {
    height: 6%;
    margin: 0.5px;
    background: rgb(221, 221, 221);
  }
}
.pencil-settings {
    list-style: none;
    padding: 0px;
    li {
        height: 40px;
        width: 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .line-width-1 {
            margin: 1px 4px;
            height: 1px;
        }
        .line-width-2 {
            margin: 1px 4px;
            height: 2px;
        }
        .line-width-4 {
            margin: 1px 4px;
            height: 4px;
        }
        .line-width-6 {
            margin: 1px 4px;
            height: 6px;
        }
        .line-width-10 {
            margin: 1px 4px;
            height: 10px;
        }
        .line-width-16 {
            margin: 1px 4px;
            height: 16px;
        }
    }
    .selected {
        border: 1px solid black;
    }
    li.width:hover {
        border: 1px solid black;
    }
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.input-range {
    writing-mode: bt-lr;
    -webkit-appearance: slider-vertical;
    height: 200px;
    width: 16px;
}
.color-picker {
    z-index: 1388;
}
.top-menu-bar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    .breadcrumbs {
        display: flex;
        flex-wrap: wrap;
        padding: 0 1rem;
        list-style: none;
        background-color: #e9ecef;
        border-radius: .25rem;
    }

    .revision-history-warning {
        padding: 0 1rem;
        list-style: none;
        background-color: #e9ecef;
        border-radius: .25rem;
    }
}

.add-img-to-template-container {
  border-radius: 0.25rem;

  .add-img-to-template {
    // background: lightslategray;
    /* margin-top: 10px; */
    height: 100px;
    // width: 100px;
    /* z-index: 999; */
    padding: 20px;
    // color: white;
    cursor: pointer;
  }
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  top: 0%;
  right: 0%;
  max-width: 100%;
  height: 100%;
  position: absolute;
}

.permissions-modal {
    display: flex;
    flex-direction: column;
    min-height: 50vh;
    max-height: 75vh;

    width: 50vw;
    background-color: white;
    border-radius: 5px;
    .modal-header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 1rem;
        border-bottom: 1px solid #dee2e6;
        border-top-left-radius: calc(.3rem - 1px);
        border-top-right-radius: calc(.3rem - 1px);
    }
    .modal-body {
        position: relative;
        flex: 1 1 auto;
        padding: 1rem;
        overflow-y: auto;
    }
    .modal-footer {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
        padding: .75rem;
        border-top: 1px solid #dee2e6;
        border-bottom-right-radius: calc(.3rem - 1px);
        border-bottom-left-radius: calc(.3rem - 1px);
    }
}
</style>
