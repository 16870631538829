/* eslint-disable */
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import elUIenLocale from 'element-ui/lib/locale/lang/en'
import elUIzhLocale from 'element-ui/lib/locale/lang/zh-CN'
import elUIidLocale from 'element-ui/lib/locale/lang/id';
import elUIthLocale from 'element-ui/lib/locale/lang/th';

Vue.use(VueI18n)

function getElementUIMessages(locale) {
  switch(locale) {
    case 'en': // English
      return elUIenLocale;
    case 'zh-cn': // Simplified Chinese
      return elUIzhLocale;
    case 'id': // Indonesian
      return elUIidLocale;
    case 'th': // Thailand
      return elUIthLocale;
    default:
      return {}
  }
}

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = {
        ...locales(key),
        ...getElementUIMessages(locale)
      };
    }
  })
  return messages
}

const dateTimeFormats = {
  'en': {
    short: {
      year: 'numeric', month: 'short', day: 'numeric'
    },
    long: {
      year: 'numeric', month: 'short', day: 'numeric',
      weekday: 'short', hour: 'numeric', minute: 'numeric'
    }
  },
  'zh-cn': {
    short: {
      year: 'numeric', month: 'short', day: 'numeric'
    },
    long: {
      year: 'numeric', month: 'short', day: 'numeric',
      weekday: 'short', hour: 'numeric', minute: 'numeric', 
    }
  }
}

export default new VueI18n({
  locale: localStorage.getItem('locale') || process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
  dateTimeFormats
})
