import nursingioService from '@/services/nursingio.service.js';
import inventoryService from '@/services/inventory.service.js';


import dummyinputIVFluid from './defaults/inputIVFluid2.json'
import dummyinputIVMed from './defaults/inputIVMed2.json'
import dummyinputBloodProduct from './defaults/inputBloodProduct2.json'
import dummyoutputUrine from './defaults/outputUrine.json'
import dummyoutputBowel from './defaults/outputBowel.json'
import dummyoutputVomitous from './defaults/outputVomitous.json'
import dummyoutputDrainage from './defaults/outputDrainage2.json'
import dummyFormMetadata from './defaults/formMetadata2.json'
import dummyMedicineData from './defaults/dummyMedicine.json'
import filterDefaults from './defaults/filters.json'
import summaryDefaults from './defaults/summary.json'

import {
  SET_ALL_INPUTIVFLUID,
  SET_ALL_INPUTIVMED,
  SET_ALL_INPUTBLODDPRODUCT,
  SET_ALL_OUTPUTURINE,
  SET_ALL_OUTPUTBOWEL,
  SET_ALL_OUTPUTVOMITOUS,
  SET_ALL_OUTPUTDRAINAGE,
  ADD_IOROW,
  SET_FILTERS,
  SET_META,
  SET_SUMMARY,
  SET_MEDS,
} from './type.mutation';
import { BEFORE_SET_ALL_NOTES } from '../note/type.mutation';

const state = {
  // providers: dummyProviders,
  medicines: dummyMedicineData,
  formMetadata: dummyFormMetadata,
  inputIVFluid: dummyinputIVFluid,
  inputIVMed: dummyinputIVMed,
  inputBloodProduct: dummyinputBloodProduct,
  outputUrine: dummyoutputUrine,
  outputBowel: dummyoutputBowel,
  outputVomitous: dummyoutputVomitous,
  outputDrainage: dummyoutputDrainage,
  filters: filterDefaults,
  summary: summaryDefaults,
};

/* eslint-disable no-param-reassign */
const mutations = {
  [SET_ALL_INPUTIVFLUID](_state, payload) {
    _state.inputIVFluid = [...payload];
  },
  [SET_ALL_INPUTIVMED](_state, payload) {
    _state.inputIVMed = [...payload];
  },
  [SET_ALL_INPUTBLODDPRODUCT](_state, payload) {
    _state.inputBloodProduct = [...payload];
  },
  [SET_ALL_OUTPUTURINE](_state, payload) {
    _state.outputUrine = [...payload];
  },
  [SET_ALL_OUTPUTBOWEL](_state, payload) {
    _state.outputBowel = [...payload];
  },
  [SET_ALL_OUTPUTVOMITOUS](_state, payload) {
    _state.outputVomitous = [...payload];
  },
  [SET_ALL_OUTPUTDRAINAGE](_state, payload) {
    _state.outputDrainage = [...payload];
  },
  [ADD_IOROW](_state, payload) {
    // TODO: chan - below is a fix for already pushed outputDrainage schema
    let { mode } = payload;
    if (payload.mode === 'outputDrainages') {
      mode = 'outputDrainage';
    } else if (payload.mode === 'inputIVMeds') {
      mode = 'inputIVMed';
    }
    _state[mode] = [
      payload.data,
      ...(_state[mode] || []),
    ];
  },
  [SET_FILTERS](_state, payload) {
    _state.filters = { ...payload };
  },
  [SET_META](_state, payload) {
    _state.formMetadata = { ...payload };
  },
  [SET_SUMMARY](_state, payload) {
    _state.summary = { ...payload };
  },
  [SET_MEDS](_state, payload) {
    _state.medicines = [...payload];
  },
};

const actions = {
  loadMedicines({ commit }) {
    return inventoryService.fetchMedicines()
      .then((response) => {
        console.log('MEDS --->', response);
        commit(SET_MEDS, response.data);
      });
  },
  loadFormMetadata({ commit }, payload) {
    return nursingioService.showMetadata({
      clinic: payload.clinic,
      patient: payload.patient,
      admission: payload.admission,
    }, payload.data)
      .then((response) => {
        console.log('YOYO --->', response);
        const { _v, ...metadata } = response.data;
        commit(SET_META, metadata);
      });
  },
  loadIORows({ commit }, payload) {
    // TODO: chan - should get inputIVFluid on backend
    return nursingioService.showRecords({
      section: payload.section,
      patient: payload.patient,
      admission: payload.admission,
    }).then((response) => {
      console.log('YOYO --->', response);
      console.log('YOYO : payload --->', payload);
      // const { _v, ...metadata } = response.data;
      if (response.success) {
        const data = response.data.map(d => ({
          recordedAt: d.recordedAt,
          recordedBy: d.recordedBy,
          ...d[payload.section],
        }));
        switch (payload.section) {
          case 'inputIVFluid':
            commit(SET_ALL_INPUTIVFLUID, data);
            break;
          case 'inputIVMeds':
            commit(SET_ALL_INPUTIVMED, data);
            break;
          case 'inputBloodProduct':
            commit(SET_ALL_INPUTBLODDPRODUCT, data);
            break;
          case 'outputUrine':
            commit(SET_ALL_OUTPUTURINE, data);
            break;
          case 'outputBowel':
            commit(SET_ALL_OUTPUTBOWEL, data);
            break;
          case 'outputVomitous':
            commit(SET_ALL_OUTPUTVOMITOUS, data);
            break;
          case 'outputDrainages':
            commit(SET_ALL_OUTPUTDRAINAGE, data);
            break;
          default:
            console.log('SHOULD NOT HAPPEN!');
        }
      }
      // commit(ADD_IOROW, response.data);
    });
  },
  computeTodaySummary({ commit }, payload) {
    let summaryPromise;
    let summaryData = summaryDefaults;
    switch (payload.section) {
      case 'inputIVFluid':
        summaryPromise = nursingioService.getTodayIVFluidSummary({
          patient: payload.patient,
          admission: payload.admission,
        });
        break;
      case 'inputIVMed':
        summaryPromise = nursingioService.getTodayIVMedSummary({
          patient: payload.patient,
          admission: payload.admission,
        });
        break;
      case 'inputBloodProduct':
        summaryPromise = nursingioService.getTodayIBPSummary({
          patient: payload.patient,
          admission: payload.admission,
        });
        break;
      case 'outputUrine':
        summaryPromise = nursingioService.getTodayOUrineSummary({
          patient: payload.patient,
          admission: payload.admission,
        });
        break;
      case 'outputBowel':
        summaryPromise = nursingioService.getTodayOBowelSummary({
          patient: payload.patient,
          admission: payload.admission,
        });
        break;
      case 'outputVomitous':
        summaryPromise = nursingioService.getTodayOVomitousSummary({
          patient: payload.patient,
          admission: payload.admission,
        });
        break;
      case 'outputDrainage':
        summaryPromise = nursingioService.getTodayODrainageSummary({
          patient: payload.patient,
          admission: payload.admission,
        });
        break;
      default:
        console.log('SHOULD NOT HAPPEN!');
    }
    return summaryPromise.then((response) => {
      console.log('WAAA --->', response);
      commit(SET_SUMMARY, response.data);
    });
  },
  addIORow({ commit }, payload) {
    const data = {
      [payload.mode]: payload.data,
    };
    return nursingioService.record({
      section: payload.mode,
      patient: payload.patient,
      admission: payload.admission,
    }, data).then((response) => {
      if (response.success) {
        payload.data.recordedAt = response.data.createdAt;
        payload.data.recordedBy = response.data.entryBy;
        if (payload.mode === 'inputIVMeds') {
          payload.data = {
            ...payload.data,
            ...response.data.inputIVMeds,
          };
        }
        commit(ADD_IOROW, payload);
      }
    });
  },
  setFilters({ commit }, payload) {
    commit(SET_FILTERS, payload);
  },
  setFormMetadata({ commit }, payload) {
    return nursingioService.upsertMetadata({
      clinic: payload.clinic,
      patient: payload.patient,
      admission: payload.admission,
    }, payload.data)
      .then((response) => {
        console.log('YOYO --->', response);
        commit(SET_META, payload.data);
      });
  },
  resetAll({ commit }) {
    commit(SET_ALL_INPUTIVFLUID, dummyinputIVFluid);
    commit(SET_ALL_INPUTIVMED, dummyinputIVMed);
    commit(SET_ALL_INPUTBLODDPRODUCT, dummyinputBloodProduct);
    commit(SET_ALL_OUTPUTURINE, dummyoutputUrine);
    commit(SET_ALL_OUTPUTBOWEL, dummyoutputBowel);
    commit(SET_ALL_OUTPUTVOMITOUS, dummyoutputVomitous);
    commit(SET_ALL_OUTPUTDRAINAGE, dummyoutputDrainage);
    commit(SET_FILTERS, filterDefaults);
    commit(SET_META, dummyFormMetadata);
  },
};

const getters = {
  summary: _state => _state.summary,
  allIORows: _state => _state[_state.filters.mode],
  currentIORows: _state => _state[_state.filters.mode].slice(
    (_state.filters.page - 1) * _state.filters.max_docs, // start
    ((_state.filters.page - 1) * _state.filters.max_docs) + _state.filters.max_docs, // records
  ).reverse(),
  lastPage: _state => Math.ceil((_state[_state.filters.mode].length || 0) / _state.filters.max_docs),
  filters: _state => _state.filters,
  formMetadata: _state => _state.formMetadata,
  medicines: _state => _state.medicines,
  medicineOptions: _state => _state.medicines.map(med => ({
    value: med.name,
    data: med,
  })),
  medicineNames: _state => _state.medicines.reduce((total, cur) => ({
    ...total,
    [cur._id]: cur.name,
  }), {}),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
