import recommendationService from '@/services/health-screening-recommendation.service';
import {
  SET_HEALTHOVERVIEW,
  SET_RECOMMENDATION,
  SET_RECOMMENDATION_DATA,
  SET_ERROR_FIELDS,
  SET_REQUIRED_FIELDS,
} from './type.mutation';

const state = {
  healthOverview: {},
  recommendation: {},
  recommendationData: {},
  errorFields: [],
  requiredFields: [],
};

/* eslint-disable no-param-reassign */
const mutations = {
  [SET_HEALTHOVERVIEW](_state, payload) {
    _state.healthOverview = payload;
  },
  [SET_RECOMMENDATION](_state, payload) {
    _state.recommendation = payload;
  },
  [SET_RECOMMENDATION_DATA](_state, payload) {
    _state.recommendationData = payload;
  },
  [SET_ERROR_FIELDS](_state, payload) {
    _state.errorFields = payload;
  },
  [SET_REQUIRED_FIELDS](_state, payload) {
    _state.requiredFields = payload;
  },
};

function updateRecommendationData(commit, data) {
  if (!data || data.length === 0) {
    commit(SET_RECOMMENDATION_DATA, {});
    return {};
  }
  const resultObjects = data.reduce((ret, result) => ({
    ...ret,
    [result.subcode]: result.recommendation,
  }), {});
  commit(SET_RECOMMENDATION_DATA, resultObjects);
  return {};
}

const actions = {
  setRecommendation({ commit }, payload) {
    commit(SET_RECOMMENDATION, payload);
    if (payload.data) {
      updateRecommendationData(commit, payload.data);
    }
  },
  getRecommendation({ commit }, id) {
    commit('setLoading', true, { root: true });
    return recommendationService.getRecommendation(id)
      .then((response) => {
        if (response.ok) {
          commit(SET_RECOMMENDATION, response.data);
          updateRecommendationData(commit, response.data.data);
        }
        commit('setLoading', false, { root: true });
        return response;
      });
  },
  updateRecommendation({ commit }, payload) {
    const { id, data } = payload;
    commit('setLoading', true, { root: true });
    return recommendationService.updateRecommendation(id, data)
      .then((response) => {
        if (response.ok) {
          commit(SET_RECOMMENDATION, response.data);
          updateRecommendationData(commit, response.data.data);
        }
        commit('setLoading', false, { root: true });
        return response;
      });
  },
  patchRecommendation({ commit }, payload) {
    const { id, data } = payload;
    commit('setLoading', true, { root: true });
    return recommendationService.patchRecommendation(id, data)
      .then((response) => {
        if (response.ok) {
          commit(SET_RECOMMENDATION, response.data);
          updateRecommendationData(commit, response.data.data);
        }
        commit('setLoading', false, { root: true });
        return response;
      });
  },
  // eslint-disable-next-line no-shadow
  createNewRecommendation({ commit }, newRecommendation) {
    commit('setLoading', true, { root: true });
    return recommendationService.createNew(newRecommendation)
      .then((response) => {
        if (response.ok) {
          commit(SET_RECOMMENDATION, response.data);
          updateRecommendationData(commit, response.data.data);
        }
        commit('setLoading', false, { root: true });
        return response;
      });
  },
  getOrCreateRecommendation({ commit }, data) {
    commit('setLoading', true, { root: true });
    return recommendationService.getOrCreateRecommendation(data)
      .then((response) => {
        if (response.ok) {
          commit(SET_RECOMMENDATION, response.data);
          updateRecommendationData(commit, response.data.data);
        }
        commit('setLoading', false, { root: true });
        return response;
      });
  },
  setErrorFields({ commit }, payload) {
    commit(SET_ERROR_FIELDS, payload);
  },
  setRequiredFields({ commit }, payload) {
    commit(SET_REQUIRED_FIELDS, payload);
  },
};

const getters = {
  healthOverview: _state => _state.healthOverview,
  selectedRecommendation: _state => _state.recommendation,
  selectedRecommendationData: _state => _state.recommendationData,
  errorFields: _state => _state.errorFields,
  requiredFields: _state => _state.requiredFields,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
