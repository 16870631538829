<template>
  <div class="A4">
    <div class="row my-4">
      <h2 class="col-sm-12">Purchase Order</h2>
    </div>
    <div class="row my-4">
      <div class="col-sm-4">
        <strong v-if="printConfig.supplierName || printConfig.supplierAddress">Supplier</strong>
        <div v-if="printConfig.supplierName">{{print.supplierObj.name}}</div>
        <div v-if="printConfig.supplierAddress && print.supplierObj && print.supplierObj.address">
          {{print.supplierObj.address}}
        </div>
      </div>
      <div class="col-sm-4">
        <strong v-if="printConfig.clinicName || printConfig.clinicAddress">Ship To</strong>
        <div v-if="printConfig.clinicName">{{generalConfig.clinicName}}</div>
        <div v-if="printConfig.clinicAddress">{{shipToAddress}}</div>
      </div>
      <div class="col-sm-4 row">
        <div class="col-sm-6" style="text-align: right;">
          <strong v-if="printConfig.purchaseOrderNumber">PO No:</strong><br>
          <strong v-if="printConfig.purchaseOrderCreatedDate">Date:</strong>
        </div>
        <div class="col-sm-6" style="text-align: left;">
          <div v-if="printConfig.purchaseOrderNumber">{{print.docNumber}}</div>
          <div v-if="printConfig.purchaseOrderCreatedDate">{{print.createdAt | dateOnlyFormat_DDMMYYYY}}</div>
        </div>
      </div>
    </div>
    <div class="row my-5">
      <vue-good-table
      class="col-sm-12"
      :columns="columns"
      :rows="rows"
      :sort-options="{ enabled: false }">
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'taxRate'">
            {{print.taxPercentageAmount || invoiceConfig.taxPercentageAmount}}%
          </span>
          <span v-else-if="props.column.field == 'name'">
            <span>{{props.row.name}}</span>
            <i v-if="props.row.isBonus" class="text-right"><br>- {{`BONUS ${props.row.bonusPurchasingUnit} ${props.row.purchasingUnit || ''}`}}</i>
          </span>
          <span v-else-if="props.column.field == 'amount'">
            <span>{{props.row.amount | thousandSeparator(region.thousandSeparator)}}</span>
          </span>
        </template>
      </vue-good-table>
    </div>
    <div class="row my-4">
      <div class="col-sm-6">
        <span v-if="printConfig.notes && print.notes">
          <strong>Notes</strong><br>
          <div>{{print.notes}}</div>
        </span>
      </div>
      <div class="col-sm-3">
      </div>
      <div class="col-sm-3">
        <span v-if="printConfig.subtotalTaxAndTotalAmount" class="row">
          <div class="col-sm-6 text-left">
            <strong>{{$t('general.subtotal')}}</strong><br>
            <strong>{{$t('general.tax')}} ({{print.taxPercentageAmount || invoiceConfig.taxPercentageAmount}}%)</strong><br>
            <strong>{{$t('general.total')}}</strong><br>
          </div>
          <div class="col-sm-6 text-right">
            <div>{{subtotal | thousandSeparator(region.thousandSeparator)}}</div>
            <div>{{tax | thousandSeparator(region.thousandSeparator)}}</div>
            <div>{{region.currency}} {{total | thousandSeparator(region.thousandSeparator)}}</div>
          </div>
        </span>
      </div>
    </div>
    <div class="row mt-5">
      <span class="col-sm-7"></span>
      <span class="col-sm-5 text-right d-flex justify-content-between">
        <div>Approved By:</div>
        <div>________________________________</div>
      </span>
    </div>
  </div>
</template>
<script>
import moment from 'moment';

export default {
  name: 'PurchaseOrderPrint',
  props: {
    generalConfig: {
      type: Object,
      default: () => ({}),
    },
    invoiceConfig: {
      type: Object,
      default: () => ({}),
    },
    inventoryConfig: {
      type: Object,
      default: () => ({}),
    },
    print: {
      type: Object,
      default: () => ({}),
    },
  },
  filters: {
    dateOnlyFormat_DDMMYYYY(dt) {
      return dt ? moment(dt).format('DD/MM/YYYY') : '';
    },
    thousandSeparator(value, separator = ',') {
      return value
        ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator)
        : '';
    },
  },
  computed: {
    shipToAddress() {
      if (this.generalConfig.useLocationAddress) {
        const location = this.clinics.find(location => location._id === this.print.clinics[0] );
        return location ? location.address : '';
      }
      return this.generalConfig.address;
    },
    printConfig() {
      return this.inventoryConfig.purchaseOrderingConfig.print;
    },
    subtotal() {
      if (this.print.taxIncluded) {
        return (parseFloat(this.total) / (1 + this.taxPercentageAmount)).toFixed(2);
      }
      return this.print.items.reduce((accum, curr) => accum + parseFloat(curr.amount), 0).toFixed(2);
    },
    tax() {
      if (this.print.taxIncluded) {
        return (parseFloat(this.total) - parseFloat(this.subtotal)).toFixed(2);
      }
      return (this.taxPercentageAmount * parseFloat(this.subtotal)).toFixed(2);
    },
    total() {
      if (this.print.taxIncluded) {
        return this.print.items.reduce((accum, curr) => accum + parseFloat(curr.amount), 0).toFixed(2);
      }
      return (parseFloat(this.subtotal) + parseFloat(this.tax)).toFixed(2);
    },
    taxPercentageAmount() {
      return (this.print.taxPercentageAmount || this.invoiceConfig.taxPercentageAmount) / 100;
    },
    columns() {
      const cols = [
        {
          label: this.$t('general.inventoryName'),
          field: 'name',
        },
        {
          label: this.$t('general.qty'),
          field: 'qty',
          type: 'number',
        },
      ];
      if (this.printConfig.itemRate) {
        cols.push({
          label: this.$t('general.rate'),
          field: 'rate',
          type: 'number',
        });
      }
      if (this.printConfig.taxRate) {
        cols.push({
          label: this.$t('general.taxRate'),
          field: 'taxRate',
          type: 'number',
        });
      }
      if (this.printConfig.lineItemAmount) {
        cols.push({
          label: this.$t('general.amount'),
          field: 'amount',
          type: 'number',
        });
      }
      return cols;
    },
    rows() {
      const items = [];
      if (this.print.items && this.print.items.length) {
        this.print.items.forEach((i) => {
          if (i.qtyOrdered > 0) {
            const { ...item } = i;
            item.qty = item.qtyOrdered;
            items.push(item);
          }
          if (i.qtyBonus > 0) {
            const { ...bonus } = i;
            bonus.isBonus = true;
            bonus.qty = bonus.qtyBonus;
            bonus.rate = '0';
            bonus.amount = '0';
            items.push(bonus);
          }
        });
      }
      return items;
    },
  },
  mounted() {
    setTimeout(() => {
      window.print();
    }, 2000);
  },
};
</script>

<style scoped src="../../assets/paper.css"></style>
<style scoped>
@media screen {
  div.divFooter {
    display: none;
  }
}
@media print {
  div.divFooter {
    position: fixed;
    bottom: 20px;
  }
}
@page {
  size: A4
}
img {
  display: block;
  width: 100%;
  height: auto;
}
</style>
