var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container mb-5", class: { previewPrint: _vm.preview } },
    [
      _vm.classifiedItems.length > 0
        ? _c("prescription-form-item-print", {
            attrs: {
              patientConfig: _vm.patientConfig,
              generalConfig: _vm.generalConfig,
              selectedPrescription: _vm.selectedPrescription,
              items: _vm.classifiedItems,
              type: "classified"
            }
          })
        : _vm._e(),
      _vm.normalItems.length > 0
        ? _c("prescription-form-item-print", {
            attrs: {
              patientConfig: _vm.patientConfig,
              generalConfig: _vm.generalConfig,
              selectedPrescription: _vm.selectedPrescription,
              items: _vm.normalItems,
              type: "normal"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }