<template>
  <div class="container-fluid">
    <consult-note-print
      :selectedPatient="selectedPatient"
      :generalConfig="general"
      :selectedConsultNote="selectedConsultNote"
      :patientConfig="patient"
    />
  </div>
</template>

<script>
import ConsultNotePrint from '@/components/Print/ConsultNotePrint.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'PatientConsultNote',
  components: {
    ConsultNotePrint,
  },
  data() {
    return {
      selectedConsultNote: {},
    };
  },
  computed: {
    ...mapGetters('patient', ['selectedPatient']),
    ...mapGetters('config', ['general', 'patient']),
  },
  created() {
    const vuex = JSON.parse(localStorage.getItem('vuex'))
    const print = vuex.note.print
    this.selectedConsultNote = Object.assign({}, print);
  },
};
</script>
