import moment from 'moment';

import campaignService from '@/services/campaign.service';

import {
  SET_ALL_CAMPAIGNS,
  SET_CAMPAIGN_FROMJSON,
  SET_CAMPAIGN,
  UPDATE_CAMPAIGN_FROMJSON,
  REMOVE_CAMPAIGN,
} from './type.mutation';

const mapper = {
  JSONtoCampaign: (json) => {
    let campaign = {};
    if (json) {
      const {
        _id: id, clinic, name, start, end,
        status, eligibility, promotions, isRunningForever,
      } = json;
      campaign = {
        id,
        clinic,
        name,
        start: start ? moment(start).format('YYYY/MM/DD') : '',
        end: end ? moment(end).format('YYYY/MM/DD') : '',
        status,
        eligibility,
        isRunningForever,
        promotions: promotions && promotions.map(promotion => ({
          id: promotion._id,
          promoType: promotion.promoType,
          name: promotion.name,
          promoCode: promotion.promoCode,
          quantityLimit: promotion.quantityLimit,
          patientQuantityType: promotion.patientQuantityType,
          patientQuantityLimit: promotion.patientQuantityLimit,
          quantityAvailable: promotion.quantityAvailable,
          invoices: promotion.invoices,
          buyXgetYPromo: promotion.buyXgetYPromo && promotion.promoType === 'BUYXGETY' ? {
            buyQuantity: promotion.buyXgetYPromo.buyQuantity,
            buyItemType: promotion.buyXgetYPromo.buyItemType,
            buyItem: promotion.buyXgetYPromo.buyItem ? {
              id: promotion.buyXgetYPromo.buyItem._id,
              name: promotion.buyXgetYPromo.buyItem.name,
              price: promotion.buyXgetYPromo.buyItem.price,
            } : null,
            getQuantity: promotion.buyXgetYPromo.getQuantity,
            getItemType: promotion.buyXgetYPromo.getItemType,
            getItem: promotion.buyXgetYPromo.getItem ? {
              id: promotion.buyXgetYPromo.getItem._id,
              name: promotion.buyXgetYPromo.getItem.name,
              price: promotion.buyXgetYPromo.getItem.price,
            } : null,
          } : null,
          discountPromo: promotion.discountPromo && promotion.promoType === 'DISCOUNT' ? {
            discountType: promotion.discountPromo.discountType,
            discount: promotion.discountPromo.discount,
          } : null,
          freeGiftPromo: promotion.freeGiftPromo && promotion.promoType === 'FREEGIFT' ? {
            freeItem: promotion.freeGiftPromo.freeItem ? {
              id: promotion.freeGiftPromo.freeItem._id,
              name: promotion.freeGiftPromo.freeItem.name,
              price: promotion.freeGiftPromo.freeItem.price,
            } : null,
            quantity: promotion.freeGiftPromo.quantity,
          } : null,
          complexPromo: promotion.complexPromo && promotion.promoType === 'COMPLEX' ? {
            buyItems: promotion.complexPromo.buyItems.map(item => ({
              _id: item.item._id,
              name: item.item.name,
              price: item.item.price,
              quantity: item.quantity,
            })),
            getItems: promotion.complexPromo.getItems.map(item => ({
              _id: item.item._id,
              name: item.item.name,
              price: item.item.price,
              quantity: item.quantity,
            })),
            discountType: promotion.complexPromo.discountType,
            discount: promotion.complexPromo.discount,
          } : null,
        })),
      };
    }
    return campaign;
  },
  CampaignToJSON: (obj) => {
    let jsonData = {};
    if (obj) {
      const {
        id: _id, clinic, name, start, end,
        eligibility, promotions, isRunningForever,
      } = obj;
      jsonData = {
        _id,
        clinic,
        name,
        start,
        end,
        eligibility,
        isRunningForever,
        promotions: promotions.map(promotion => ({
          _id: promotion.id,
          promoType: promotion.promoType,
          name: promotion.name,
          promoCode: promotion.promoCode,
          quantityLimit: promotion.quantityLimit,
          patientQuantityType: promotion.patientQuantityType,
          patientQuantityLimit: promotion.patientQuantityLimit,
          quantityAvailable: promotion.quantityAvailable,
          invoices: promotion.invoices,
          buyXgetYPromo: promotion.buyXgetYPromo && promotion.promoType === 'BUYXGETY' ? {
            buyQuantity: promotion.buyXgetYPromo.buyQuantity,
            buyItemType: promotion.buyXgetYPromo.buyItemType,
            buyItem: (promotion.buyXgetYPromo.buyItem
              && promotion.buyXgetYPromo.buyItem.id) || null,
            getQuantity: promotion.buyXgetYPromo.getQuantity,
            getItemType: promotion.buyXgetYPromo.getItemType,
            getItem: (promotion.buyXgetYPromo.getItem
              && promotion.buyXgetYPromo.getItem.id) || null,
          } : null,
          discountPromo: promotion.discountPromo && promotion.promoType === 'DISCOUNT' ? {
            discountType: promotion.discountPromo.discountType,
            discount: promotion.discountPromo.discount,
          } : null,
          freeGiftPromo: promotion.freeGiftPromo && promotion.promoType === 'FREEGIFT' ? {
            freeItem: (promotion.freeGiftPromo.freeItem
              && promotion.freeGiftPromo.freeItem.id) || null,
            quantity: promotion.freeGiftPromo.quantity,
          } : null,
          complexPromo: promotion.complexPromo && promotion.promoType === 'COMPLEX' ? {
            buyItems: promotion.complexPromo.buyItems.map(item => ({
              quantity: item.quantity,
              item: item._id,
            })),
            getItems: promotion.complexPromo.getItems.map(item => ({
              quantity: item.quantity,
              item: item._id,
            })),
            discountType: promotion.complexPromo.discountType,
            discount: promotion.complexPromo.discount,
          } : null,
        })),
      };
      if (obj.id) {
        jsonData._id = obj.id;
      }
    }
    return jsonData;
  },
};

const state = {
  all: [],
  selectedCampaign: null,
};

/* eslint-disable no-param-reassign */
const mutations = {
  [SET_ALL_CAMPAIGNS](_state, payload) {
    _state.all = payload.map(item => mapper.JSONtoCampaign(item));
  },
  [SET_CAMPAIGN_FROMJSON](_state, payload) {
    _state.selectedCampaign = payload ? { ...mapper.JSONtoCampaign(payload) } : null;
  },
  [SET_CAMPAIGN](_state, payload) {
    _state.selectedCampaign = payload ? { ...payload } : null;
  },
  [UPDATE_CAMPAIGN_FROMJSON](_state, payload) {
    const index = _state.all.findIndex(campaign => campaign.id === payload._id);
    if (index >= 0) {
      const newAll = _state.all.slice(0);
      newAll[index] = mapper.JSONtoCampaign(payload);
      _state.all = newAll;
    }
  },
  [REMOVE_CAMPAIGN](_state, payload) {
    _state.all = _state.all.filter(campaign => campaign.id !== payload.id);
  },
};

const actions = {
  listCampaigns({ commit }, param = {}) {
    return campaignService.list(param)
      .then((response) => {
        commit(SET_ALL_CAMPAIGNS, response.data);
      });
  },
  resetAll({ commit }) {
    commit(SET_ALL_CAMPAIGNS, []);
  },
  setSelectedCampaign({ commit }, payload) {
    commit(SET_CAMPAIGN, payload);
  },
  unsetSelectedCampaign({ commit }) {
    commit(SET_CAMPAIGN, null);
  },
  createNewCampaign({ commit }, payload) {
    commit('setLoading', true, { root: true });
    return campaignService.create(mapper.CampaignToJSON(payload))
      .then((response) => {
        if (response.success) {
          commit(SET_CAMPAIGN_FROMJSON, response.data);
        }
        commit('setLoading', false, { root: true });
        return response;
      });
  },
  updateCampaign({ commit }, payload) {
    commit('setLoading', true, { root: true });
    const serializedData = mapper.CampaignToJSON(payload);
    return campaignService.update(serializedData._id, serializedData)
      .then((response) => {
        if (response.success) {
          commit(SET_CAMPAIGN_FROMJSON, response.data);
        }
        commit('setLoading', false, { root: true });
        return response;
      });
  },
  deactivateCampaign({ commit }, payload) {
    commit('setLoading', true, { root: true });
    return campaignService.updateStatus(payload.id, false)
      .then((response) => {
        if (response.success) {
          commit(UPDATE_CAMPAIGN_FROMJSON, response.data);
        }
        commit('setLoading', false, { root: true });
        return response;
      });
  },
  destroyCampaign({ commit }, payload) {
    commit('setLoading', true, { root: true });
    return campaignService.destroy(payload.id)
      .then((response) => {
        if (response.success) {
          commit(REMOVE_CAMPAIGN, payload);
        }
        commit('setLoading', false, { root: true });
        return response;
      });
  },
};

const getters = {
  all: _state => _state.all,
  promotions: (_state) => {
    let promotions = [];
    _state.all
      .filter(campaign => campaign.status === 'ACTIVE')
      .forEach((campaign) => {
        if (campaign.promotions.length > 0) {
          campaign.promotions.filter((p) => {
            p.eligibility = campaign.eligibility;
          });
          promotions = promotions.concat(campaign.promotions);
        }
      });
    return promotions;
  },
  availablePromotions: (_state, _getters) => _getters.promotions
    .filter(promotion => promotion.quantityAvailable && promotion.quantityAvailable > 0),
  filtered: _state => ({
    start, end, status, search,
  }) => (_state.all
    .filter(item => (start ? moment(item.start).valueOf() <= start : true))
    .filter(item => (end ? moment(item.end).valueOf() >= end : true))
    .filter(item => (status ? item.status === status : true))
    .filter((item) => {
      const searchRegExp = new RegExp(search, 'i');
      return search
        ? item.name.match(searchRegExp)
          || item.promotions.some(promotion => promotion.promoCode.match(searchRegExp))
        : true;
    })
  ),
  selectedCampaign: _state => _state.selectedCampaign,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
