var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "main-card" } },
    [
      _c("div", { attrs: { id: "square-box" } }, [
        _vm._m(0),
        _c("div", { attrs: { id: "header-2" } }, [
          _c("div", { staticClass: "patient dateOfPrint" }, [
            _vm._v("\n        Date of Print: "),
            _c("u", [_vm._v(_vm._s(_vm.printPayload.dateOfPrint))])
          ]),
          _c("div", { staticClass: "patient visitBranch" }, [
            _vm._v("\n        Visit Branch : "),
            _c("u", [_vm._v(_vm._s(_vm.printPayload.visitBranch))])
          ])
        ]),
        _c(
          "div",
          { attrs: { id: "content-inside-square-box" } },
          [
            _c("div", { staticClass: "patient" }, [
              _c("strong", [_vm._v(" (Name) ")]),
              _c("u", [_vm._v(_vm._s(_vm.printPayload.name))]),
              _c("strong", [_vm._v(" (NRIC/Passport) ")]),
              _c("u", [_vm._v(_vm._s(_vm.printPayload.nric))]),
              _c("strong", [_vm._v(" (D.O.B.) ")]),
              _c("u", [_vm._v(_vm._s(_vm.printPayload.dateOfBirth))]),
              _vm._v(
                "\n        has been prescribed the following herbal medication on\n        " +
                  _vm._s(_vm.printPayload.dateOfPrescription) +
                  ":\n      "
              )
            ]),
            _c("div", { staticClass: "blank-empty-space-2" }),
            _c("vue-good-table", {
              attrs: {
                columns: _vm.columns,
                rows: _vm.printPayload.items,
                id: "prescription-table"
              }
            }),
            _c("div", { staticClass: "blank-empty-space-2" }),
            _c("div", { attrs: { id: "weight" } }, [
              _vm._v(
                "\n        Total Weight: " +
                  _vm._s(_vm.printPayload.weightPerPacket) +
                  " g per packet\n      "
              )
            ]),
            _c("div", { staticClass: "blank-empty-space-2" }),
            _c("div", [
              _vm._v(
                "\n        * This prescription should be consumed under the advice of a licensed\n        TCM physician. Ma Kuang does not encourage the purchase of this herbal\n        prescription from external sources other than our clinics.\n      "
              )
            ]),
            _c("div", [_vm._v("Remarks:")]),
            _c("div", { attrs: { id: "blank-square-box" } }),
            _c("div", [
              _vm._v(
                "\n        Prescribed By: (Name of TCM physician)\n        "
              ),
              _c("u", [_vm._v(_vm._s(_vm.printPayload.nameOfPrescriber))])
            ])
          ],
          1
        )
      ]),
      _c(
        "b-button",
        {
          staticClass: "btn print-button",
          attrs: { variant: "primary" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.printForm($event)
            }
          }
        },
        [_vm._v("Print Prescription")]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "header" } }, [
      _c("div", { attrs: { id: "header-img" } }, [
        _c("img", {
          attrs: {
            src: require("../assets/makuang_logo.png"),
            id: "makuang-logo",
            alt: "makuang-logo"
          }
        })
      ]),
      _c("div", { attrs: { id: "header-text" } }, [_vm._v("PRESCRIPTION")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }