var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex flex-column", attrs: { id: "medicine-label" } },
    [
      _c("div", { staticClass: "text-center font-weight-bold" }, [
        _vm._v("Instalasi Farmasi Dr's Clinique")
      ]),
      _c("div", { staticClass: "text-right" }, [
        _vm._v(_vm._s(_vm._f("ddMonthYYYYHHmm")(new Date())))
      ]),
      _c("div", [_vm._v(_vm._s(_vm.patientName))]),
      _c("div", [_vm._v(_vm._s(_vm.medName))]),
      _c("div", [_vm._v(_vm._s(_vm.medDosage))]),
      _c("div", [_vm._v(_vm._s(_vm.medStrength))])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }