var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container mb-5 pdfContainer" },
    [
      _c("div", { staticClass: "row mb-3" }, [
        _c(
          "div",
          { staticClass: "col-9" },
          [
            !_vm.disablePrint
              ? [
                  _c("img", {
                    staticStyle: { width: "300px", "margin-left": "0" },
                    attrs: { src: _vm.generalConfig.logoURL }
                  })
                ]
              : _vm.disablePrint && _vm.creditNote.logoBase64
              ? [
                  _c("img", {
                    staticStyle: { width: "300px", "margin-left": "0" },
                    attrs: { src: _vm.creditNote.logoBase64 }
                  })
                ]
              : _vm._e()
          ],
          2
        ),
        _c("div", { staticClass: "col-3" }, [
          _c("p", { staticClass: "mb-1 mt-3 address-font" }, [
            _vm._v("\n            Customer Feedback\n        ")
          ]),
          _c("p", { staticClass: "mb-1 address-font" }, [
            _vm._v(
              "\n            Date: " +
                _vm._s(
                  _vm._f("formatVDDate")(
                    _vm.creditNote.feedback &&
                      _vm.creditNote.feedback.submitted,
                    { format: "DD MMM YYYY" }
                  )
                ) +
                "\n        "
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "row mb-3" }, [
        _c("div", { staticClass: "col-6" }, [
          _c("h5", [_vm._v(_vm._s(_vm.generalConfig.clinicName))]),
          _c("p", { staticClass: "mb-1 address-font" }, [
            _vm._v("\n        " + _vm._s(_vm.creditNoteAddress) + "\n      ")
          ]),
          _vm.generalConfig.telephone
            ? _c("p", { staticClass: "mb-3 address-font" }, [
                _vm._v("\n        Tel: " + _vm._s(_vm.generalConfig.telephone))
              ])
            : _vm._e()
        ])
      ]),
      _c("div", { staticClass: "row mb-3" }, [
        _c("div", { staticClass: "col-7" }),
        _c("div", { staticClass: "col-5" }, [
          _c("h5", [_vm._v("Reference Details:")]),
          _c("p", { staticClass: "mb-1 address-font" }, [
            _vm._v(
              "\n          Invoice No: " +
                _vm._s(
                  _vm.creditNote.invoice && _vm.creditNote.invoice.invoiceNo
                ) +
                "\n        "
            )
          ]),
          _c("p", { staticClass: "mb -1 address-font" }, [
            _vm._v(
              "\n          Invoice Date: " +
                _vm._s(
                  _vm._f("ddMonthYYYYFromX")(
                    _vm.creditNote.invoice && _vm.creditNote.invoice.date
                  )
                ) +
                "\n        "
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "row mb-3 dynamicContent" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("json-schema-form", {
              attrs: {
                schema: _vm.feedbackForm.schema,
                schemaData: this.creditNote.feedback
              }
            })
          ],
          1
        )
      ]),
      !_vm.disablePrint
        ? [
            _vm.creditNote.customerSignatureUrl
              ? _c(
                  "section",
                  { staticClass: "feedback-signature-url-section" },
                  [
                    _c(
                      "div",
                      { staticClass: "feedback-signature-url-section-img" },
                      [
                        _c("img", {
                          staticClass: "feedback-signature-image",
                          attrs: {
                            src: _vm.creditNote.customerSignatureUrl,
                            width: "460px",
                            height: "260px"
                          }
                        }),
                        _c("br"),
                        _c("div", { staticClass: "feedback-signature-label" }, [
                          _vm._v(
                            _vm._s(_vm.$t("credit.customerAcknowledgement"))
                          )
                        ])
                      ]
                    )
                  ]
                )
              : _vm._e()
          ]
        : _vm.disablePrint && _vm.creditNote.signatureBase64
        ? [
            _vm.creditNote.signatureBase64
              ? _c(
                  "section",
                  { staticClass: "feedback-signature-url-section" },
                  [
                    _c(
                      "div",
                      { staticClass: "feedback-signature-url-section-img" },
                      [
                        _c("img", {
                          staticClass: "feedback-signature-image",
                          attrs: {
                            src: _vm.creditNote.signatureBase64,
                            width: "460px",
                            height: "260px"
                          }
                        }),
                        _c("br"),
                        _c("div", { staticClass: "feedback-signature-label" }, [
                          _vm._v(
                            _vm._s(_vm.$t("credit.customerAcknowledgement"))
                          )
                        ])
                      ]
                    )
                  ]
                )
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }