var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("bulk-corp-insurance", {
    attrs: {
      corpPayments: _vm.corpPaymentsData,
      corpData: _vm.corpData,
      generalConfig: _vm.generalConfig
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }