import Vue from 'vue';
import userService from '../services/user.service';
import integratedtestService from '../services/integratedtest.service';

const state = {
  all: [],
  selectedUser: {},
  error: {},
  testNotifs: {},
  labsToReview: {},
  labsPending: {},
  ewan: {},
};

const getters = {
  all: _state => _state.all,
  userObjects: _state => (Array.isArray(_state.all)
    && _state.all.reduce(
      (final, user) => ({ ...final, [user.id]: user }),
      {},
    )) || {},
  selectedUser: _state => _state.selectedUser,
  usersOptionMap: _state => (_state.all && _state.all.map(user => ({
    value: user.id,
    text: user.name,
  }))) || {},
  testNotifs: _state => _state.testNotifs,
  // testNotifsLength: _state => {
  //   console.log('in testnotifslength getter..');
  //   return Object.keys(_state.testNotifs).length;
  // },
  labsToReview: _state => _state.labsToReview,
  labsPending: _state => _state.labsPending,
};

const actions = {
  socket_labsNew({ commit, rootGetters }, data) {
    const statusMap = rootGetters['config/integratedTestStatusesMapped'];
    if (data.currentStatusId in statusMap) {
      const { statusGroup } = statusMap[data.currentStatusId];
      const status = statusGroup === 'Report Generated'
        ? statusMap[data.currentStatusId].name
        : statusGroup;
      const payload = {
        _id: data._id,
        name: data.patientId.name,
        requested: data.createdAt,
        item: data.name,
        status,
        picURL: data.patientId.profilePicURL,
      };
      if (['Confirmed', 'In Progress'].includes(payload.status)) {
        commit('LABS_NEW_PENDING', payload);
      } else if (payload.status === 'Report Generated') {
        commit('LABS_NEW_REVIEW', payload);
      }
    }
  },
  socket_labsUpdate({ commit, rootGetters }, data) {
    const statusMap = rootGetters['config/integratedTestStatusesMapped'];
    if (data.currentStatusId in statusMap) {
      const { statusGroup } = statusMap[data.currentStatusId];
      const status = statusGroup === 'Report Generated'
        ? statusMap[data.currentStatusId].name
        : statusGroup;
      const payload = {
        _id: data._id,
        name: data.patientId.name,
        requested: data.createdAt,
        item: data.name,
        status,
        picURL: data.patientId.profilePicURL,
      };
      if (['Confirmed', 'In Progress'].includes(payload.status)) {
        commit('LABS_NEW_PENDING', payload);
      } else if (payload.status === 'Report Generated') {
        commit('LABS_DEL_PENDING', payload);
        commit('LABS_NEW_REVIEW', payload);
      }
    }
  },
  getHL7MessageTemplate({ commit }, params) {
    console.log('in getHL7MessageTemplate...params is ', params);
    return integratedtestService.getHL7MessageTemplate(params)
      .then((response) => {
        console.log('response is ', response);
        if (response.ok) {
          return response.data;
        }
      })
  },
  async createIntegratedTest({ commit, state, rootState }, params) {
    console.log('in integratedtest vuex create, params is ', params);
    let response = await integratedtestService.createIntegratedTest(params);
    console.log('2 response ', response);
    if (response.ok) {
      let invoice = rootState.invoice.invoicesMappedById[params.invoiceId];
      let clonedItems = _.cloneDeep(invoice.items);
      for (let i=0; i<clonedItems.length; i++) {
        if (clonedItems[i].uuid === params.lineItemUuid) {
          clonedItems[i].testStatusId = params.currentStatusId;
          break;
        }
      }
      console.log('clonedItems ', clonedItems);
      // params.lineItemUuid
      commit('invoice/PATCH_INVOICE', { id: params.invoiceId, key: 'items', value: clonedItems }, { root: true });
    }
    return response;
  },
  
  createIntegratedTestOfAdimission({ commit }, params) {
    return integratedtestService.createIntegratedTestOfAdimission(params).then((response) => {
      if (response.ok) {
        return response;
      }
    })
  },

  getAll({ commit }, params) {
    console.log('params ', params);
    return integratedtestService.getAll(params)
      .then((response) => {
        console.log('getAll int tests repsonse is ', response);
        if (response.ok) {
          return response.body;
        }
      })
  },
  loadUserLabs({ commit }, { params, user, itestStatusMapping }) {
    console.log('params ', params);
    console.log('user ', user);
    return integratedtestService.getAll(params)
      .then((response) => {
        console.log('getAll int tests repsonse is ', response);
        if (response.ok) {
          const data = response.body
            .reduce((acc, row) => acc.concat(row.children), [])
            .filter(row => (row.queueProvider && row.queueProvider.user === user)
              && row.currentStatusId in itestStatusMapping)
            .map(row => ({
              _id: row._id,
              name: row.patient.name,
              requested: row.createdAt,
              item: row.name,
              status: itestStatusMapping[row.currentStatusId].statusGroup === 'Report Generated'
                ? itestStatusMapping[row.currentStatusId].name
                : itestStatusMapping[row.currentStatusId].statusGroup,
              picURL: row.patient.profilePicURL,
            }));
          const labsToReview = data.filter(({ status }) => status === 'Report Generated')
            .reduce((acc, lab) => ({
              ...acc,
              [lab._id]: lab,
            }), {});
          commit('LABS_TO_REVIEW', labsToReview);
          const labsPending = data.filter(({ status }) => ['Confirmed', 'In Progress'].includes(status))
            .reduce((acc, lab) => ({
              ...acc,
              [lab._id]: lab,
            }), {});
          commit('LABS_PENDING', labsPending);
        }
      });
  },
  sendHL7Message({ commit }, params) {
    return integratedtestService.sendHL7Message(params)
  },
  sendProlabHL7Message({ commit }, params) {
    return integratedtestService.sendProlabHL7Message(params)
  },
  cancelIntegratedTest({ commit, rootState }, { invoiceId, integratedTestId, currentStatusId }) {
    return integratedtestService.cancelIntegratedTest(integratedTestId, currentStatusId)
      .then((response) => {
        console.log('cancelIntegratedTest response.data is ', response.data);
        console.log('rootState ', rootState);
        if (response.ok) {
          let invoice = rootState.invoice.invoicesMappedById[invoiceId];
          let clonedItems = _.cloneDeep(invoice.items);
          for (let i=0; i<clonedItems.length; i++) {
            if (clonedItems[i].integratedTestId === integratedTestId) {
              clonedItems[i].testStatusId = currentStatusId;
              break;
            }
          }
          console.log('hoho - clonedItems ', clonedItems);
          // params.lineItemUuid
          commit('invoice/PATCH_INVOICE', { id: invoiceId, key: 'items', value: clonedItems }, { root: true });
          return response.data;
        }
      })
  },
  cancelAdmissionIntegratedTest({ commit, rootState }, params) {
    return integratedtestService.cancelAdmissionIntegratedTest(params)
  },
  updateTestStatus({ commit, rootState }, params) {
    return integratedtestService.updateIntegratedTestStatus(params)
      .then((response) => {
        console.log('response data is ', response.data);
        return response;
      })
  },
  fetchIntegratedTestResults({ commit }, id) {
    return integratedtestService.fetchIntegratedTestResults(id);
  },

  fetchIntegratedSimpleList({ commit }, params) {
    return integratedtestService.fetchIntegratedSimpleList(params);
  }
  // resetAll({ commit }) {
  //   commit('setAllUsers', []);
  // },
  // createNewUser({ commit }, user) {
  //   return userService.createNew(user)
  //     .then((response) => {
  //       if (response.ok) {
  //         commit('setUser', response.data);
  //       }
  //       return response;
  //     });
  // },
  // updateUser({ commit }, user) {
  //   return userService.updateUser(user)
  //     .then((response) => {
  //       if (response.ok) {
  //         commit('setUser', response.data);
  //       }
  //       return response;
  //     });
  // },
  // bulkDelete({ commit }, ids) {
  //   return userService.bulkDestroy(ids)
  //     .then(
  //       response => response,
  //       error => commit('setFailure', error),
  //     );
  // },
  // setSelectedUser({ commit }, user) {
  //   commit('setUser', user);
  // },
};

/* eslint-disable no-param-reassign */
const mutations = {
  // setAllUsers(_state, users) {
  //   _state.all = users;
  // },
  // setFailure(_state, error) {
  //   _state.error = { error };
  // },
  // setUser(_state, payload) {
  //   _state.selectedUser = payload;
  // },
  updateTestNotifs(_state, data) {
    console.log('in updateTestNotifs - data is ', data);
    console.log('_state.testNotifs ', _state.testNotifs);
    if (_state.testNotifs === 0) _state.testNotifs = {};
    if (_state.testNotifs[data._id]) {
      Vue.set(_state.testNotifs, data._id, 0);
    } else {
      Vue.set(_state.testNotifs, data._id, 1);
    }
    console.log(' _state.testNotifs ',  _state.testNotifs);
  },
  LABS_TO_REVIEW(_state, data) {
    _state.labsToReview = { ...data };
  },
  LABS_PENDING(_state, data) {
    _state.labsPending = { ...data };
  },
  LABS_NEW_PENDING(_state, test) {
    _state.labsPending = {
      ..._state.labsPending,
      [test._id]: test,
    };
  },
  LABS_DEL_PENDING(_state, test) {
    const { [test._id]: omit, ...res } = _state.labsPending;
    _state.labsPending = { ...res };
  },
  LABS_NEW_REVIEW(_state, test) {
    _state.labsToReview = {
      ..._state.labsToReview,
      [test._id]: test,
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
