<template>
  <div class="fluid-container">
    <h3 class="mb-5 mt-3 text-center text-uppercase">
      {{ $t('occupationalService.printNoteTitle') }}
    </h3>
    <div class="h3 mb-4">{{$t('occupationalService.consultInfo')}}</div>
    <div class="d-flex flex-wrap mb-4">
      <div class="w-50 mb-2">
        <div class="row m-0 p-0">
            <div class="col-4 form-label pl-0">{{ $t("occupationalService.startConsultTime") }}:</div>
            <div class="col-8 form-value">{{queue.start_consult > 0 ? formatDate(queue.start_consult) : ''}}</div>
        </div>
      </div>
      <div class="w-50 mb-2">
        <div class="row m-0 p-0">
            <div class="col-4 form-label pl-0">{{ $t("occupationalService.endConsultTime") }}:</div>
            <div class="col-8 form-value">{{formData.endConsultTime ? formData.endConsultTime.answer : ''}}</div>
        </div>
      </div>
    </div>
    <div class="h3 mb-4">{{$t('occupationalService.personalInfo')}}</div>
    <div class="d-flex flex-wrap mb-4">
      <template v-for="spec in readonlyFormData">
        <div class="w-50 mb-2" v-if="selectedPatient[spec.key]" :key="spec.key">
          <div class="row m-0 p-0">
              <div class="col-4 form-label pl-0">{{ $t(`occupationalService.${spec.label}`) }}:</div>
              <div class="col-8 form-value">{{selectedPatient[spec.key]}}</div>
          </div>
        </div>
      </template>
    </div>
    <div class="h3 mb-4">{{$t('occupationalService.noteContent')}}</div>
    <div class="consult-tempalte-wrap d-flex flex-wrap mb-4">
       <div class="w-50 consult-template-item mb-2" v-if="formData.medicalHistory">
        <div class="consult-question mr-5">
          {{$t(`occupationalService.${formData.medicalHistory.label}`)}}
        </div>
        <div class="consult-answer" v-html="getValueOrEmptySign(formData.medicalHistory.answer)">
        </div>
      </div>
      <div class="w-50 consult-template-item mb-2" v-if="formData.foodAllergyHistory">
        <div class="consult-question">
          {{$t(`occupationalService.${formData.foodAllergyHistory.label}`)}}
        </div>
        <div class="consult-answer" v-html="getValueOrEmptySign(formData.foodAllergyHistory.answer)">
        </div>
      </div>
      <div class="w-50 consult-template-item mb-2" v-if="formData.drugAllergyHistory">
        <div class="consult-question mr-5">
          {{$t(`occupationalService.${formData.drugAllergyHistory.label}`)}}
        </div>
        <div class="consult-answer" v-html="getValueOrEmptySign(formData.drugAllergyHistory.answer)">
        </div>
      </div>
      <div class="w-50 consult-template-item mb-2" v-if="formData.reason">
        <div class="consult-question">
          {{$t(`occupationalService.${formData.reason.label}`)}}
        </div>
        <div class="consult-answer" v-html="getOptionText('reason',formData.reason.answer)">
        </div>
      </div>
      <div class="w-50 consult-template-item mb-2" v-if="formData.type">
        <div class="consult-question mr-5">
          {{$t(`occupationalService.${formData.type.label}`)}}
        </div>
        <div class="consult-answer" v-html="getOptionText('type',formData.type.answer)">
        </div>
      </div>
      <div class="w-50 consult-template-item mb-2" v-if="formData.advice">
        <div class="consult-question">
          {{$t(`occupationalService.${formData.advice.label}`)}}
        </div>
        <div class="consult-answer" v-html="getValueOrEmptySign(formData.advice.answer)">
        </div>
      </div>
    </div>
    <div class="h3 mb-4">{{$t('occupationalService.education')}}</div>
    <div class="consult-tempalte-wrap d-flex flex-wrap mb-4">
      <div class="w-100 consult-template-item mb-2" v-if="formData.educationFood">
        <div class="consult-question">
          {{$t(`occupationalService.${formData.educationFood.label}`)}}
        </div>
        <div class="consult-answer">
          <template v-for="option in getOption('educationFood')">
            <span class="mr-2" :key="option.value">
              <v-icon v-if="formData.educationFood.answer.includes(option.value)" name="square"/>
              <v-icon v-else name="regular/square"/>
              &nbsp;{{option.text}}
              <template v-if="formData.educationFood.answer.includes('other') && option.value == 'other'">
                <span v-html="`: ${formatPreLine(formData.educationFoodNote.answer)}`"></span>
              </template>
            </span>
          </template>
        </div>
      </div>
      <div class="w-100 consult-template-item mb-2" v-if="formData.educationDisease">
        <div class="consult-question">
          {{$t(`occupationalService.${formData.educationDisease.label}`)}}
        </div>
        <div class="consult-answer">
          <template v-for="option in getOption('educationDisease')">
            <span class="mr-2" :key="option.value">
              <v-icon v-if="formData.educationDisease.answer.includes(option.value)" name="square"/>
              <v-icon v-else name="regular/square"/>
              &nbsp;{{option.text}}
              <template v-if="formData.educationDisease.answer.includes('other') && option.value == 'other'" >
                <span v-html="`: ${formatPreLine(formData.educationDiseaseNote.answer)}`"></span>
              </template>
            </span>
          </template>
        </div>
      </div>
      <div class="w-100 consult-template-item mb-2" v-if="formData.educationHabit">
        <div class="consult-question">
          {{$t(`occupationalService.${formData.educationHabit.label}`)}}
        </div>
        <div class="consult-answer">
          <template v-for="option in getOption('educationHabit')">
            <span class="mr-2" :key="option.value">
              <v-icon v-if="formData.educationHabit.answer.includes(option.value)" name="square"/>
              <v-icon v-else name="regular/square"/>
              &nbsp;{{option.text}}
              <template v-if="formData.educationHabit.answer.includes('other') && option.value == 'other'">
                <span v-html="`: ${formatPreLine(formData.educationHabitNote.answer)}`"></span>
              </template>
            </span>
          </template>
        </div>
      </div>
      <div class="w-100 consult-template-item mb-2" v-if="formData.educationSpecial">
        <div class="consult-question">
          {{$t(`occupationalService.${formData.educationSpecial.label}`)}}
        </div>
        <div class="consult-answer">
          <template v-for="option in getOption('educationSpecial')">
            <span class="mr-2" :key="option.value">
              <v-icon v-if="formData.educationSpecial.answer.includes(option.value)" name="square"/>
              <v-icon v-else name="regular/square"/>
              &nbsp;{{option.text}}
              <template v-if="formData.educationSpecial.answer.includes('other') && option.value == 'other'">
                <span v-html="`: ${formatPreLine(formData.educationSpecialNote.answer)}`"></span>
              </template>
            </span>
          </template>
        </div>
      </div>
    </div>

    <div class="h3 mb-4">{{$t('occupationalService.trackStatus')}}</div>
    <div class="mb-5">
      <template v-for="option in getOption('track')">
        <span class="mr-2" :key="option.value">
          <v-icon v-if="formData.track.answer.includes(option.value)" name="square"/>
          <v-icon v-else name="regular/square"/>
          &nbsp;{{option.text}}
          <template v-if="formData.track.answer == 'track' && option.value == 'track'">
            : {{formData.trackDate.answer}}
          </template>
        </span>
      </template>
    </div>


    <div class="mb-5 row med-footer">
      <div class="col-4 form-label">
        <div class="footer-item">
          {{$t('occupationalService.nurseSignature')}}:
        </div>
      </div>
      <div class="col-4 form-label">
        <div class="footer-item">
          {{$t('occupationalService.doctorSignature')}}:
        </div>
      </div>
      <div class="col-4 form-label">
        <div class="footer-item">
          {{$t('occupationalService.patientSignature')}}:
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import occupationalPatientSchemaService from '@/services/occupational.patient.schema.js';

export default {
  name: 'OccupationalConsultNotePrint',
  props: {
    selectedPatient: {
      type: Object,
      default: () => ({}),
    },
    selectedConsultNote: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      templateId: '',
    };
  },
  filters: {
    formatDayTime(value) {
      if (!value) return;
      return moment(value).format('YYYY/MM/DD');
    },
    translateGender(val) {
      const map = { O: 'Other', M: 'Male', F: 'Female' };
      return map[val] || '';
    },
  },
  mounted() {
    setTimeout(() => {
      window.print();
    }, 2000);
  },
  created() {
    this.templateId = this.selectedConsultNote.mainForm.templateId;
  },
  methods: {
    getValueOrEmptySign(value) {
      let result;
      if (value) {
        result = this.formatPreLine(value);
      } else {
        result = 'N/A';
      }

      return result;
    },
    formatPreLine(value) {
      return value.replace(/\n/g, '<br/>');
    },
    getOptionText(field, value) {
      let text = 'N/A';
      const optionList = occupationalPatientSchemaService.getFormDataOptions(field);

      optionList.forEach((option) => {
        if (option.value && option.value == value) {
          text = option.text;
        }
      });

      if (value == 'other' && this.formData[`${this.formData[field].label}Note`]) {
        text = `${text} : ${this.formData[`${this.formData[field].label}Note`].answer}`;
      }

      return text;
    },
    getOption(field) {
      return occupationalPatientSchemaService.getFormDataOptions(field);
    },
    formatDate(value, format="YYYY/MM/DD HH:mm:ss") {
      return moment(value).format(format);
    }
  },
  computed: {
    readonlyFormData() {
      return occupationalPatientSchemaService.getReadonlyFormData(this.templateId);
    },
    formData() {
      return this.selectedConsultNote.mainForm.formData;
    },
    queue() {
      return this.selectedConsultNote.invoice.queue;
    }
  },
};
</script>

<style scoped src="../../assets/print.css"></style>
<style lang="scss" scoped>
  @media print{
    .container {
      margin-left: 3rem !important;
      width: 100% !important;
    }
  }
  .classified-medicine-tip {
    font-size: 20px;
    font-weight: bold;
    margin-top: 40px;
  }

  .clinic-name {
    margin-top: 10px;
  }
  .clinic-address {
    font-weight: bold;
    margin-top: 10px;
  }
  .form-label {
    font-size: 14px;
    font-weight: bold;
  }

  .form-value {
    font-size: 14px;
  }
  .item-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 4px;
  }
  .consult-tempalte-wrap {
    .consult-question {
      font-weight: bold;
      font-size: 18px;
      border-bottom: 1px solid #333;
    }
    .consult-answer {
      font-size: 16px;
      padding: 6px 0;
    }
  }
  .diagnosisItem {
    font-size: 16px;
  }
  .icd-wrap {
    padding: 10px 0;
    border-top: 2px solid #333;

    .icd-sub-item {
      padding: 6px 0;
      border-bottom: 1px solid #333;
      margin-left: 0;
      margin-right: 0;

      .form-label {
        padding-left: 0;
      }
    }
  }
  .med-header {
    font-weight: bold;
    padding: 10px 0;
    border-width: 2px 0 2px 0;
    border-style: solid;
    border-color: #333;
    margin-left: 0;
    margin-right: 0;
  }

  .category-inventroy-wrap {
    .category-name {
      font-size: 16px;
      font-weight: bold;
      padding: 4px 0 4px 15px;
    }


  }

  .med-body {
    padding: 10px 0;
    border-bottom: 1px solid #333;
    margin-left: 0;
    margin-right: 0;

    .table-value {
      font-size: 14px;
    }
  }

  .med-footer {
    display: flex;
    align-items: flex-end;

    .footer-item {
      padding: 16px 0;
    }
  }
</style>
