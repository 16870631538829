import Moment from '@/helpers/moment';
import { en, th, zh } from 'vuejs-datepicker/dist/locale';

const CE_BE_DIFF = 543;

const formatVDDateHelper = (value, { format = 'YYYY/MM/DD', lang, sourceFormat = '' } = {}) => {
  let output = '';
  if (value) {
    const inst = sourceFormat
      ? Moment(value, sourceFormat)
      : Moment(value);
    if (lang) {
      inst.locale(lang);
    }
    if (['th', 'TH'].includes(inst.locale())) {
      output = inst.toBuddhistYear(format);
    } else {
      output = inst.format(format);
    }
  }
  return output;
};

export default {
  filters: {
    // 2. on using it with filters
    formatVDDate(value, { format = 'YYYY/MM/DD', lang, sourceFormat = '' } = {}) {
      return formatVDDateHelper(value, { format, lang, sourceFormat } || {});
    },
    ddMonthYYYYFromX1000(value) {
      return value ? formatVDDateHelper(value, { format: 'DD MMM YYYY', sourceFormat: 'X' }) : '';
    },
    ddMonthYYYYHHmmFromX(value) {
      return value ? formatVDDateHelper(value, { format: 'DD MMM YYYY HH:mm', sourceFormat: 'X' }) : '';
    },
    ddMonthYYYYFromX(value) {
      return value ? formatVDDateHelper(value, { format: 'DD MMM YYYY', sourceFormat: 'X' }) : '';
    },
    ddMonthYYYYFromx(value) {
      return value ? formatVDDateHelper(value, { format: 'DD MMM YYYY', sourceFormat: 'x' }) : '';
    },
    ddMonthYYYY(value) {
      return value ? formatVDDateHelper(value, { format: 'DD MMM YYYY' }) : '';
    },
    dateOnlyFormat(dt) {
      return dt ? formatVDDateHelper(String(dt), { format: 'YYYY/MM/DD' }) : '';
    },
    dateOnlyFormat_DDMMYYYY(dt, sep = '/') {
      return dt ? formatVDDateHelper(String(dt), { format: `DD${sep}MM${sep}YYYY` }) : '';
    },
    timeFormat(time) {
      return formatVDDateHelper(time, { format: 'HH:mm:ss A' });
    },
    timeOnlyFormat(dt) {
      return dt ? formatVDDateHelper(String(dt), { format: 'HH:mm' }) : '';
    },
    timeOnlyFormatFromx(value) {
      return value ? formatVDDateHelper(value, { format: 'HH:mm', sourceFormat: 'x' }) : '';
    },
    timeFormatFromX(value) {
      return value ? formatVDDateHelper(value, { format: 'HH:mm:ss', sourceFormat: 'X' }) : '';
    },
    formatDateTime(value) {
      return formatVDDateHelper(value, { format: 'YYYY/MM/DD HH:mm A' });
    },
    formatDateTime_DDMMYYYY(value) {
      return formatVDDateHelper(value, { format: 'DD/MM/YYYY HH:mm A' });
    },
    formatDateTime_DDdMMMYYYYFromX(value) {
      return formatVDDateHelper(value, { format: 'DD MMM YYYY hh:mm:ss A', sourceFormat: 'X' });
    },
    formatDateTimeEng(value) {
      return formatVDDateHelper(value, { format: 'DD MMM YYYY, h:mm A' });
    },
    formatDate(value) {
      return formatVDDateHelper(value, { format: 'YYYY/MM/DD' });
    },
    formatDateTimeSS(value) {
      return formatVDDateHelper(value, { format: 'YYYY/MM/DD HH:mm:ss A' });
    },
    timeFormatFromNow(value) {
      return Moment(value).fromNow();
    },
    dateTimeFormatFromX(value) {
      return value ? formatVDDateHelper(value, { format: 'DD MMM YYYY - HH:mm', sourceFormat: 'X' }) : '';
    },
    dateTimeFormatAmPmFromX(value) {
      return value ? formatVDDateHelper(value, { format: 'DD MMM YYYY - HH:mma', sourceFormat: 'X' }) : '';
    },
    HHmmddMonthYYYY(value) {
      return value ? formatVDDateHelper(value, { format: 'HH:mm, DD MMM YYYY' }) : '';
    },
    ddMonthYYYYHHmm(value) {
      return value ? formatVDDateHelper(value, { format: 'DD MMM YYYY, HH:mm' }) : '';
    },
    ddMonthYYYYHHmmDash(value) {
      return value ? formatVDDateHelper(value, { format: 'DD MMM YYYY - HH:mm' }) : '';
    },
    YYYYMMDDFromx(value) {
      return value ? formatVDDateHelper(value, { format: 'YYYY/MM/DD', sourceFormat: 'x' }) : '';
    },
    formatDDMMMMYYYY(value) {
      return value ? formatVDDateHelper(value, { format: 'DD MMMM YYYY' }) : '';
    },
  },
  data() {
    return {
      en,
      th,
      zh,
    };
  },
  methods: {
    getLanguage() {
      switch (Moment.locale()) {
        case 'th':
          return this.th;
        case 'zh-cn':
          return this.zh;
        // English for the rest
        case 'en':
        default:
          return this.en;
      }
    },
    formatVDDate(value, { format = 'YYYY/MM/DD', lang = '', sourceFormat = '' } = {}) {
      return formatVDDateHelper(value, { format, lang, sourceFormat } || {});
    },
    toVDDate(val) {
      let output = null;
      if (val) {
        const value = val instanceof Date
          ? val
          : new Date(val);
        if (Moment.locale() === 'th') {
          const year = value.getFullYear() + CE_BE_DIFF;
          value.setFullYear(year);
          output = value;
        } else {
          output = value;
        }
      }
      return output;
    },
    fromVDDateToDate(value, { lang = '', sourceFormat = '' } = {}) {
      let output = null;
      if (value) {
        const inst = sourceFormat
          ? Moment(value)
          : Moment(value, sourceFormat);
        if (lang) {
          inst.locale(lang);
        }
        if (['th', 'TH'].includes(inst.locale())) {
          output = inst.toDate();
          const year = output.getFullYear() - CE_BE_DIFF;
          output.setFullYear(year);
        } else {
          output = inst.toDate();
        }
      }
      return output;
    },
    formatStringDate(value) {
      return Moment(value, 'YYYY-MM-DD').format('DD MMM YYYY');
    },
    formatDateTime(value) {
      return formatVDDateHelper(value, { format: 'YYYY/MM/DD HH:mm' });
    },
    formatDateTimeSS(value) {
      return formatVDDateHelper(value, { format: 'DD MMM YYYY, HH:mm:ss' });
    },
    formatDate(value) {
      return formatVDDateHelper(value, { format: 'YYYY/MM/DD' });
    },
    dMonthYYYYFromX(value) { // unix secs
      return value ? formatVDDateHelper(value, { format: 'DD MMM YYYY', sourceFormat: 'X' }) : '';
    },
    formatDateTimeFromx(value) { // unix milisecs
      return value ? formatVDDateHelper(value, { format: 'YYYY/MM/DD HH:mm', sourceFormat: 'x' }) : '';
    },
    formatDateTimeFromX(value) { // unix secs
      return value ? formatVDDateHelper(value, { format: 'DD/MM/YYYY HH:mm:ss', sourceFormat: 'X' }) : '';
    },
    formatDateTimeFromxDMYHM(value) {
      return value ? formatVDDateHelper(value, { format: 'DD/MM/YYYY HH:mm', sourceFormat: 'x' }) : '';
    },
    parseDateValue(answer, dateFormat) {
      return answer && dateFormat ? formatVDDateHelper(answer, { format: dateFormat }) : '';
    },
    parseDateValueFromX(answer, dateFormat, X = 'X') {
      return answer && dateFormat ? formatVDDateHelper(answer, { format: dateFormat, sourceFormat: X }) : '';
    },
    inventoryExpiryDateFormat(value) {
      return value ? formatVDDateHelper(value, { format: this.$store.state.config.inventory.expiryFormatConfig }) : null;
    },
    formatDateTime_DDMMYYYY(value) {
      return formatVDDateHelper(value, { format: 'DD/MM/YYYY HH:mm A' });
    },
  },
};
