<template>
  <vue-good-table :columns="spec.columns" :rows="spec.rows.dummyRows">
    <template slot="table-row" slot-scope="props">
      <template v-if="props.column.field == 'item1'">
        <div style="max-width: 250px">
          {{
            spec.rows.group1[props.row.originalIndex]
              ? $t(
                  `occupationalService.compiledReport.${parseKey(
                    spec.rows.group1[props.row.originalIndex].key
                  )}`
                )
              : ""
          }}
          
        </div>
      </template>
      <template v-else-if="props.column.field == 'value1'">
        <div style="width: 100px" :class="spec.rows.group1[props.row.originalIndex] && compiledData[`${spec.rows.group1[props.row.originalIndex].key}-level`] ? compiledData[`${spec.rows.group1[props.row.originalIndex].key}-level`] : ''">
          {{
            spec.rows.group1[props.row.originalIndex]
              ? compiledData[spec.rows.group1[props.row.originalIndex].key]
              : ""
          }}
        </div>
      </template>
      <template v-if="props.column.field == 'item2'">
        <div style="max-width: 250px">
          {{
            spec.rows.group2[props.row.originalIndex]
              ? $t(
                  `occupationalService.compiledReport.${parseKey(
                    spec.rows.group2[props.row.originalIndex].key
                  )}`
                )
              : ""
          }}
        </div>
      </template>
      <template v-else-if="props.column.field == 'value2'">
        <div style="width: 100px" :class="spec.rows.group2[props.row.originalIndex] && compiledData[`${spec.rows.group2[props.row.originalIndex].key}-level`] ? compiledData[`${spec.rows.group2[props.row.originalIndex].key}-level`] : ''">
          {{
            spec.rows.group2[props.row.originalIndex]
              ? compiledData[spec.rows.group2[props.row.originalIndex].key]
              : ""
          }}
        </div>
      </template>
      <template v-if="props.column.field == 'item3'">
        <div style="max-width: 250px">
          {{
            spec.rows.group3[props.row.originalIndex]
              ? $t(
                  `occupationalService.compiledReport.${parseKey(
                    spec.rows.group3[props.row.originalIndex].key
                  )}`
                )
              : ""
          }}
        </div>
      </template>
      <template v-else-if="props.column.field == 'value3'">
        <div style="width: 100px" :class="spec.rows.group3[props.row.originalIndex] && compiledData[`${spec.rows.group3[props.row.originalIndex].key}-level`] ? compiledData[`${spec.rows.group3[props.row.originalIndex].key}-level`] : ''">
          {{
            spec.rows.group3[props.row.originalIndex]
              ? compiledData[spec.rows.group3[props.row.originalIndex].key]
              : ""
          }}
        </div>
      </template>
    </template>
  </vue-good-table>
</template>
<script>
export default {
  name: 'CompiledReportTableComponents',
  props: {
    compiledData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      spec: {
        columns: [
          {
            label: 'Row 1 Item',
            field: 'item1',
            thClass: 'd-none',
            tdClass: 'text-center bg-item',
            sortable: false,
          },
          {
            label: 'Row 1 Value',
            field: 'value1',
            thClass: 'd-none',
            tdClass: 'text-center',
            sortable: false,
          },
          {
            label: 'Row 2 Item',
            field: 'item2',
            thClass: 'd-none',
            tdClass: 'text-center bg-item',
            sortable: false,
          },
          {
            label: 'Row 2 Value',
            field: 'value2',
            thClass: 'd-none',
            tdClass: 'text-center',
            sortable: false,
          },
          {
            label: 'Row 3 Item',
            field: 'item3',
            thClass: 'd-none',
            tdClass: 'text-center bg-item',
            sortable: false,
          },
          {
            label: 'Row 3 Value',
            field: 'value3',
            thClass: 'd-none',
            tdClass: 'text-center',
            width: '100px',
            sortable: false,
          },
        ],
        rows: {
          dummyRows: [],
          group1: [
            { key: 'healthCare-year' },
            { key: 'healthCare-month' },
            { key: 'healthCare-employeeNo' },
            { key: 'employeeName' },
            { key: 'departmentCode' },
            { key: 'departmentName' },
            { key: 'jobPosition' },
            { key: 'gender' },
            { key: 'birthOfDate' },
            { key: 'age' },
            { key: 'ageRange' },
            { key: 'H01' },
            { key: 'H02' },
            { key: 'H04' },
            { key: 'H05' },
            { key: 'H06' },
            { key: 'H10' },
            { key: 'H11' },
            { key: 'H14' },
            { key: 'H15' },
            { key: 'H16' },
            { key: 'H17' },
            { key: 'H36' },
            { key: 'H37' },
            { key: 'framinghamRiskScore' },
            { key: 'cardiovascularDiseaseRiskLevel' },
            { key: 'adultBloodPressureClassification' },
            { key: 'bmiClassification' },
            { key: 'metabolicSyndromeClassification' },
            { key: 'chronicDiseaseGroupClassification' },
          ],
          group2: [
            { key: 'workload-year' },
            { key: 'workload-month' },
            { key: 'I01' },
            { key: 'I02' },
            { key: 'I03' },
            { key: 'I04' },
            { key: 'I05' },
            { key: 'I06' },
            { key: 'iScore' },
            { key: 'iRisk' },
            { key: 'J01' },
            { key: 'J02' },
            { key: 'J03' },
            { key: 'J04' },
            { key: 'J05' },
            { key: 'J06' },
            { key: 'J07' },
            { key: 'jScore' },
            { key: 'jRisk' },
            { key: 'H38' },
            { key: 'jobTypeAssessmentLevel' },
            { key: 'workloadLevel' },
            { key: 'cardiovascularDiseaseRiskLevel' },
            { key: 'abnormalWorkloadPromotesDiseaseRiskLevel' },
          ],
          group3: [
            { key: 'personal-year' },
            { key: 'personal-month' },
            { key: 'personal-employeeNo' },
            { key: 'seniority' },
            { key: 'allergyHistory' },
            { key: 'betelNut' },
            { key: 'alcohol' },
            { key: 'travelHistory' },
            { key: 'nmq-year' },
            { key: 'nmq-month' },
            { key: 'q1' },
            { key: 'q2' },
            { key: 'q3' },
            { key: 'q4' },
            { key: 'other' },
            { key: 'description' },
            { key: 'neck' },
            { key: 'leftShoulder' },
            { key: 'rightShoulder' },
            { key: 'upperBack' },
            { key: 'lowerBack' },
            { key: 'leftArm' },
            { key: 'rightArm' },
            { key: 'leftWrist' },
            { key: 'rightWrist' },
            { key: 'leftTight' },
            { key: 'rightTight' },
            { key: 'leftKnee' },
            { key: 'rightKnee' },
            { key: 'leftAnkle' },
            { key: 'rightAnkle' },
          ],
        },
      },
    };
  },
  created() {
    const maxLength = Math.max(this.spec.rows.group1.length, this.spec.rows.group2.length, this.spec.rows.group3.length);

    for (let i = 0; i < maxLength; i++) {
      this.spec.rows.dummyRows.push({});
    }
  },
  methods: {
    parseKey(key) {
      let resultKey = '';

      if (key) {
        resultKey = key.includes('-') ? key.substring(key.lastIndexOf('-') + 1) : key;
      }

      return resultKey;
    },
  },
};
</script>
<style scoped>

.level-1 {
  font-weight: 700;
  color: #55a603;
}

.level-2 {
  font-weight: 700;
  color: #FFD600;
}

.level-3 {
  font-weight: 700;
  color: #c45902;
}

.level-4 {
  font-weight: 700;
  color: #e30000;
}

</style>
