import api from './api';

export default {
  getRecommendation(id) {
    return api.get(`/health-screening-recommendation/${id}`);
  },
  createNew(data) {
    return api.post('/health-screening-recommendation', data);
  },
  fetchAll(params) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);

    // FIXME: URL encoding
    Object.keys(params).forEach((key) => {
      q.push(`${key}=${encodeURIComponent(params[key])}`);
    });

    const url = `/health-screening-recommendation/?${q.join('&')}`;
    return api.get(url);
  },
  updateRecommendation(id, data) {
    return api.put(`/health-screening-recommendation/${id}`, data);
  },
  patchRecommendation(id, data) {
    return api.patch(`/health-screening-recommendation/${id}`, data);
  },
  getOrCreateRecommendation(data) {
    return api.put('/health-screening-recommendation/get-or-create-by-hs', data);
  },
};
