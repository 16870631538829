/* eslint-disable linebreak-style */
/* eslint-disable space-before-function-paren */
/* eslint-disable no-var */
import 'vue-good-table/dist/vue-good-table.css';
import 'vue-awesome/icons';

import Vue from 'vue';
import {
  Input,
  InputNumber,
  TimeSelect,
  DatePicker,
  TimePicker,
  Select,
  Option,
  Autocomplete,
  Slider,
  Button,
  Tag
} from 'element-ui';
import elUIelementLocale from 'element-ui/lib/locale';

import BootstrapVue from 'bootstrap-vue';
import Icon from 'vue-awesome/components/Icon.vue';
import draggable from 'vuedraggable';

import VueResource from 'vue-resource';
import VueGoodTable from 'vue-good-table';
import VueFlashMessage from 'vue-flash-message';
import Vuelidate from 'vuelidate';
import Multiselect from 'vue-multiselect';
import VueDraggableResizable from 'vue-draggable-resizable';
import VueTextareaAutosize from 'vue-textarea-autosize';
import VueSocketio from 'vue-socket.io-extended';
import io from 'socket.io-client';
import VueResize from 'vue-resize';
import VueScrollTo from 'vue-scrollto';
import VueSanitize from 'vue-sanitize';

import App from './App.vue';
import router from './router';
import store from './vuex/index';
import userService from './services/user.service';
import vueIsOffline from './plugins/vue-is-offline';
// import VueDragResize from 'vue-drag-resize'

/* eslint-disable import/extensions */
import VueBootstrapTypeaheadExt from './components/VueTypeaheadExt/VueBootstrapTypeahead';
import VdDatePicker from './components/VdDatePicker/VdDatePicker.vue';
import 'vue-flash-message/dist/vue-flash-message.min.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import 'element-ui/lib/theme-chalk/index.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
// import 'vue-awesome-form/dist/main.css';
import './registerServiceWorker';
import './assets/styles.scss';
import 'vue-draggable-resizable/dist/VueDraggableResizable.css';
import 'vue-swatches/dist/vue-swatches.min.css';
import VueCroppie from 'vue-croppie';
import Loading from './components/Loading';
import ACL from './plugins/vue-acl';
import vueTwoDigits from './plugins/vue-two-digits';
import Data from './components/mixins/data';
import Computed from './components/mixins/computed';
import i18n from './i18n';
import * as Sentry from '@sentry/vue';
import { live as liveVersions, staging as stagingVersions, name as appName } from '../package.json';
const clientVersion = process.env.NODE_ENV === 'production' ? liveVersions.client_version : stagingVersions.client_version;

import ECharts from 'vue-echarts/components/ECharts.vue';
import Semver from 'semver';

if (window.location.hostname === process.env.VUE_APP_KONSULDOK_HOSTNAME) {
    import('./assets/bootstrap-konsuldok.min.css');
} else {
    import('./assets/bootstrap.min.css');
}

Vue.config.productionTip = false;

Vue.use(Vuelidate);
Vue.use(VueResource);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(TimeSelect);
Vue.use(DatePicker);
Vue.use(TimePicker);
Vue.use(Select);
Vue.use(Slider);
Vue.use(Option);
Vue.use(Button);
Vue.use(Tag);
Vue.use(Autocomplete);
Vue.use(BootstrapVue);
Vue.use(VueResize);
Vue.use(VueFlashMessage);
Vue.use(VueTextareaAutosize);
Vue.use(VueScrollTo);
Vue.use(VueSanitize);
Vue.component('v-icon', Icon);
Vue.component('loading', Loading);
Vue.component('draggable', draggable);
Vue.component('vue-bootstrap-typeahead', VueBootstrapTypeaheadExt);
Vue.component('multiselect', Multiselect);
Vue.component('vue-draggable-resizable', VueDraggableResizable);
Vue.component('vd-date-picker', VdDatePicker);
// Vue.component('vue-drag-resize', VueDragResize);
Vue.use(VueGoodTable);
Vue.use(vueIsOffline);
Vue.use(ACL);
Vue.use(vueTwoDigits);
Vue.use(VueCroppie);
Vue.mixin(Data);
Vue.mixin(Computed);

const apiurl = process.env.VUE_APP_apiUrl
  ? process.env.VUE_APP_apiUrl
  : `${window.location.origin}/api`;

Vue.use(
  VueSocketio,
  io(
    `${apiurl}/${localStorage.getItem('orgCode')}`,
    {
      autoConnect: false,
      query: {
        clinic: localStorage.getItem('orgCode'),
        user: localStorage.getItem('user'),
        token: localStorage.getItem('xsrf'),
      },
      transports: ['websocket'],
    },
  ),
  { store },
);
Vue.component('v-chart', ECharts);

const appVersionOK = (currentApp, latestApp) => {
  if (!Semver.valid(currentApp) || !Semver.valid(latestApp)) {
    alert('WRONG VERSION - Please notify Vaultdragon about this incident');
    return null;
  }
  return Semver.gte(currentApp, latestApp)
};

/* eslint-disable no-param-reassign */
Vue.http.interceptors.push((request, next) => {
  store.commit('setLoading', true);

  if (request.headers.has('ignore-loading')) {
    store.commit('setLoading', false);
  }

  // request.credentials = true;
  request.headers.set('X-CSRF', localStorage.getItem('xsrf'));

  // Disable appending token for grabscan
  if (!(process.env.VUE_APP_GRABSCAN_API && request.url.startsWith(process.env.VUE_APP_GRABSCAN_API))) {
    request.headers.set('Authorization', `Bearer ${localStorage.getItem('access_token')}`);
  }

  request.headers.set('Accept', 'application/json');
  request.headers.set('Cache-Control', 'no-cache');

  if (!request.headers.has('x-org')) {
    // LOGGING IN
    if (request.body && request.body.orgCode) {
      const { orgCode } = request.body;
      request.headers.set('x-org', orgCode);
    }

    // AUTHENTICATED
    if (localStorage.getItem('orgCode')) {
      const orgCode = localStorage.getItem('orgCode');
      request.headers.set('x-org', orgCode);
    }
  }

  // // LOGIN
  // if (request.body && request.body.cliniccode) {
  //   const { cliniccode } = request.body;
  //   request.headers.set('x-clinic', cliniccode);
  // }

  if (localStorage.getItem('xclinic')) {
    const orgCode = localStorage.getItem('xclinic');
    request.headers.set('x-clinic', orgCode);
  }

  const mobileLinks = [
    'medservice/mobile',
    'appointment/mobile',
    'appointment-weekly-schedule/mobile',
    'config/mobile',
    'provider/mobile',
    'clinic/mobile',
  ];

  next((response) => {
    const isMobileLink = mobileLinks.find(link => response.url.indexOf(link) > -1);
    // [NOTE FROM VD-1867] Add in the check to see if the url ends with "/login" as this
    // will intercept the http request and make the response body incorrect.
    if (response.status === 401 && !isMobileLink && !response.url.endsWith('/login')) {
      store.commit('setLoading', false);
      userService.logout();
      store.dispatch('auth/logout');
      return router.push('/login?unauthorized=api-call');
    }

    const version_from_server = response.headers.get('x-clientversion');
    if (version_from_server) { // do nothing if response does not pass a header
      const nextAppVersion = String(version_from_server);
      const curAppVersion = String(clientVersion);
      store.commit('setlatestAppVersion', nextAppVersion);
      const isAppVersionOk = appVersionOK(curAppVersion, nextAppVersion);
      store.commit('setVerCheckerEnabled', isAppVersionOk !== null);
      store.commit('setNeedAppUpdate', !isAppVersionOk);
    } else {
      store.commit('setVerCheckerEnabled', false);
    }

    // TODO: Error 500 to dispatch an alert here instead(?)
    store.commit('setLoading', false);
    return response;
  });
});

Vue.filter('capitalize', (value) => {
  if (!value) return '';
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

if (
  process.env.VUE_APP_SENTRY_DSN &&
  new Set(['1', 'true']).has(
    String(process.env.VUE_APP_SENTRY_ENABLED).toLowerCase(),
  )
) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_ENV,
    release: `${appName}@${clientVersion}`,
  });

  if (process.env.VUE_APP_DEPLOYMENT) {
    Sentry.setTag('deployment', process.env.VUE_APP_DEPLOYMENT);
  }

  try {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.email) {
      Sentry.setUser({ email: user.email });
    }
  } catch (e) {}

  const orgCode = localStorage.getItem('orgCode');
  if (orgCode) {
    Sentry.setTag('org_code', orgCode);
  }
}

// Use i18n selected locale on Element-UI controls
elUIelementLocale.i18n((key, value) => i18n.t(key, value));
export const bus = new Vue();
export const vdSentry = Sentry;
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
