<template>
  <div>
    <div class="view-overlay" style="background-color: rgba(200,200,200,0.96); z-index: 2000;" v-if="viewer && viewerOpenState">
      <charting-viewer />
    </div>
    <div class="big-overlay" v-if="editor">
      <flash-message transitionName="fade" class="template-flash"></flash-message>
      <div class="bottom-panel" v-if="openSize !=='maximized'">

        <!-- when 'mediumSized' window aka gmail tab -->

        <div class="popup-window new-mail" v-if="openSize === 'mediumSized'">
          <div class="label space-between draft" :class="setPublishedCSSClass" @click="resizeExtractedNote($event, 'minimized')">
            <span>
              <span v-if="invoiceId">{{ (invoicesMappedById[invoiceId] || {}).createdAt | ddMonthYYYY }} Visit Notes</span>
              <span v-if="editor.currentPageId"> > {{editor.name}}</span>
            </span>
            <span class="top-right-icons space-between">
              <span @click="resizeExtractedNote($event, 'minimized')"><v-icon name="regular/window-minimize" scale="1.3" /></span>
              <span @click.stop="preMaximizeWindow($event)"
                v-if="isDraft && !hasPassedNoteLockTimes && checkPermissions(coverPage, 'update')"><v-icon name="regular/window-maximize" scale="1.3" /></span>
              <span @click.stop="closeEditor()"><v-icon name="times" scale="1.3" /></span>
            </span>
          </div>
            <!-- if user extracts the entire CONSULT NOTE -->
            <consult-note-container
              class="invoice-note-container"
              v-if="editor.type === 'consult'"
              :id="invoiceId"
              :invoice="invoicesMappedById[invoiceId]"
              :mainForm="notesMapped[noteId]"
              :isExtracted="true"
              :toggleDispenseBuilder="toggleDispenseBuilder"
              :otOptions="otOptions"
              :otMappedIdToName="otMappedIdToName"
            />
            <!-- if user extracts individual CHARTING NOTE (either drawing or typing) -->
            <div v-if="editor.currentPageId && editor.type === 'drawing'" class="image">
              <img :src="imageURL">
            </div>
            <span v-if="editor.type === 'typing'">
              <div :id="'print-'+editor.currentPageId" style="background-color: white;"
                class="invoice-note-container">
                <div v-if="isTypingNoteReadOnly">
                  <img
                    :src="currentNote.signedPrintKey"
                    alt="Typing Note Image"
                  />
                </div>
                <typing-note-editor
                  v-else
                  :editor="editor"
                  :formDataOrder="notesMapped[editor.currentPageId].formDataOrder"
                  :formData="editor.formData"
                  :viewing="editor.viewing"
                  :updateTypingFormData="updateTypingFormData"
                  :mainCheckboxChanged="mainCheckboxChanged"
                  :editorId="editorId"
                  :editorFocus="editorFocus"
                  :editorBlur="editorBlur"
                  :mainRadioChanged="mainRadioChanged"
                  />
              </div>
            </span>
            <span v-if="editor.type === 'letter'">
              <div class="invoice-note-container" ref="minimized-letter-container">
                <div class="form-group row">
                  <div class="form-group col">
                    <label for="title">{{ $t('setting.letterDate.fromDate') }}</label>
                    <datepicker
                      :format="'yyyy/MM/dd'"
                      input-class="form-control bg-white"
                      data-automation="notes-from-date"
                      name="fromDate"
                      v-model="fromDate"
                      @input="updateSelectedLetterDate($event, editor.invoiceId, editor.currentPageId, true)"
                    ></datepicker>
                  </div>
                  <div class="form-group col">
                    <label for="title">{{ $t('setting.letterDate.toDate') }} </label>
                    <datepicker
                      :format="'yyyy/MM/dd'"
                      data-automation="notes-to-date"
                      input-class="form-control bg-white"
                      name="toDate"
                      v-model="toDate"
                      @input="updateSelectedLetterDate($event, editor.invoiceId, editor.currentPageId, false)"
                    ></datepicker>
                  </div>
                </div>
                <textarea-autosize
                    class="form-control"
                    :value="populatedLetter"
                     data-automation="notes-text-area"
                    ref="templatetextarea"
                    :min-height="30"
                    :max-height="calculateLetterContainerHeight('minimized-letter-container')"
                    @input="updateSelectedLetter($event, editor.invoiceId, editor.currentPageId)"
                  ></textarea-autosize>
              </div>
            </span>
          <div class="footer space-between">
            <span></span>
            <span v-if="!editor.currentPageId">
              <span v-if="patientConfig.consultNotesPublishMandatory">
                <b-button
                class="mx-1 save-draft-button"   data-automation="notes-save-draft" @click="saveNote(noteId, false, invoiceId)">Save Draft</b-button>
                <b-button
                 data-automation="notes-save-publish"
                class="publish-button" @click="saveNote(noteId, true, invoiceId)">Publish</b-button>
              </span>
              <span v-else>
                <b-button
                 data-automation="notes-save"
                class="mx-1" @click="saveNote(noteId, false, invoiceId)">Save</b-button>
              </span>
            </span>
            <span v-else-if="editor.currentPageId && editor.type === 'typing' && !editor.viewing">
              <b-button variant="outline-success"
              class="mx-1" data-automation="notes-save-charting" @click="saveNote(editor.currentPageId, false)">Save Charting</b-button>
            </span>
            <span v-else-if="editor.currentPageId && editor.type === 'letter'">
              <b-button variant="outline-success"
              class="mx-1" data-automation="notes-save-letters" @click="patchInvoice(editor.invoiceId, 'letters')">Save Letter</b-button>
            </span>
          </div>
        </div>

        <!-- when minimized -->

        <div class="minimized-window" v-if="openSize === 'minimized'">
          <span class="label space-between" @click="resizeExtractedNote($event, 'mediumSized')">
            <span>Invoice Notes</span>
            <span class="top-right-icons space-between">
              <span @click="resizeExtractedNote($event, 'mediumSized')" class="left-icon"><v-icon name="minus" /></span>
              <span @click.stop="preMaximizeWindow($event)"
                v-if="isDraft && !hasPassedNoteLockTimes"><v-icon name="regular/window-maximize" /></span>
              <span @click.stop="closeEditor()"><v-icon name="times" /></span>
            </span>
          </span>
        </div>
      </div>

      <!-- when maximized AND drawing note -->

      <canvas-editor-container
        v-if="openSize === 'maximized' && editor.type == 'drawing' && editor.open"
        type="note"
        :key="editor.updatedAt"
        :editor="editor"
        :patient="patient"
        :fetchAllNoteTemplates="fetchAllNoteTemplates"
        :notetemplatesMappedById="notetemplatesMappedById"
        :notesMapped="notesMapped"
        :invoicesMappedById="invoicesMappedById"
        :minusIconFunction="preMinimizeWindow"
        :windowIconFunction="preMinimizeWindow"
        :columnsIconFunction="preMinimizeToIPD"
        :timesIconFunction="closeEditor"
        :goToPage="goToPage"
      />

      <!-- when maximized AND is typing note OR entire consult note -->

      <b-modal
        id="bv-modal-note"
        class="bv-modal-note"
        header-class="label"
        size="xl"
      >
        <template slot="modal-header" class="space-between">
            <span v-if="invoiceId && openSize === 'maximized'">
              {{ (invoicesMappedById[invoiceId] || {}).createdAt | ddMonthYYYY }} Visit Notes
              <!-- <span v-if="editor.currentPageId"> > {{editor.name}}</span> -->
              <b-form class="breadcrumbs">
                <b-form-select
                    v-if="isEditingCategory"
                    size="sm"
                    :value="selectedCategory"
                    @change="updateEditorMetadata({key: 'categoryId', value: $event}); isEditingCategory=false;"
                    @blur.native="updateEditorMetadata({key: 'categoryId', value: $event.target.value}); isEditingCategory=false;"
                    :options="filteredCategoriesOptions"
                    :state="editorCategoryState"
                    id="editor-category"
                    autofocus
                    plain
                />
                <span v-else-if="!editor.viewing" @click="isEditingCategory = true">
                    <span style="position: relative; top: -1px;"><v-icon name="circle" :color="categoriesMapped[selectedCategory].colorLabel"/></span>
                    <span style="margin-left: 2px;">{{categoriesMapped[selectedCategory].name}}</span>
                </span>
                <span v-else>
                    <span style="position: relative; top: -1px;"><v-icon name="circle" :color="categoriesMapped[selectedCategory].colorLabel"/></span>
                    <span style="margin-left: 2px;">{{categoriesMapped[selectedCategory].name}}</span>
                </span>
                <span>&nbsp;&nbsp;/&nbsp;&nbsp;</span>
                <b-input
                    v-if="isEditingName"
                    id="editor-name"
                    size="sm"
                    :value="editor.name"
                    @input="updateEditorMetadata({key: 'name', value: $event})"
                    @blur="emptyNameCheck(); isEditingName = false;"
                    :state="editorNameState"
                    invalid-feedback
                    autofocus
                    required
                ></b-input>
                <span v-else-if="!editor.viewing" @click="isEditingName = true">{{editor.name}}</span>
                <span v-else>{{editor.name}}</span>
            </b-form>
            </span>
            <span v-else>
            </span>
            <span class="top-right-icons">
              <span @click="preMinimizeWindow($event, 'minimized')"><v-icon name="regular/window-minimize" scale="1" /></span>
              <span @click="preMinimizeWindow($event, 'mediumSized')" class="left-icon"><v-icon name="external-link-alt" scale="1.5" flip="vertical" /></span>
              <span @click="preCloseEditor($event)"><v-icon name="times" scale="1.3" /></span>
            </span>
        </template>
          <consult-note-container
            v-if="editor.type === 'consult'"
            :id="invoiceId"
            :invoice="invoicesMappedById[invoiceId]"
            :mainForm="notesMapped[noteId]"
            :isExtracted="true"
            :toggleDispenseBuilder="toggleDispenseBuilder"
            :otOptions="otOptions"
            :otMappedIdToName="otMappedIdToName"
          />
          <span v-else>
            <div v-if="editor.type === 'typing'"
              class="invoice-note-container"
              :id="'print-'+editor.currentPageId"
              style="background-color: white;">
              <div v-if="isTypingNoteReadOnly">
                <img
                  :src="currentNote.signedPrintKey"
                  alt="Typing Note Image"
                />
              </div>
              <typing-note-editor
                v-else
                :editor="editor"
                :formDataOrder="notesMapped[editor.currentPageId].formDataOrder"
                :formData="editor.formData"
                :viewing="editor.viewing"
                :updateTypingFormData="updateTypingFormData"
                :mainCheckboxChanged="mainCheckboxChanged"
                :editorId="editorId"
                :editorFocus="editorFocus"
                :editorBlur="editorBlur"
                :mainRadioChanged="mainRadioChanged"
              />
            </div>
            <div v-if="editor.type === 'letter'">
              <div class="invoice-note-container" ref="maximized-letter-container">
                <div class="form-group row">
                  <div class="form-group col">
                    <label for="title">{{ $t('setting.letterDate.fromDate') }}</label>
                    <datepicker
                      :format="'yyyy/MM/dd'"
                      input-class="form-control bg-white"
                      name="fromDate"
                      v-model="fromDate"
                      @input="updateSelectedLetterDate($event, editor.invoiceId, editor.currentPageId, true)"
                    ></datepicker>
                  </div>
                  <div class="form-group col">
                    <label for="title">{{ $t('setting.letterDate.toDate') }} </label>
                    <datepicker
                      :format="'yyyy/MM/dd'"
                      input-class="form-control bg-white"
                      name="toDate"
                      v-model="toDate"
                      @input="updateSelectedLetterDate($event, editor.invoiceId, editor.currentPageId, false)"
                    ></datepicker>
                  </div>
                </div>
                <textarea-autosize
                  class="form-control"
                  :value="populatedLetter"
                  ref="templatetextarea"
                  :min-height="30"
                  :max-height="calculateLetterContainerHeight('maximized-letter-container')"
                  @input="updateSelectedLetter($event, editor.invoiceId, editor.currentPageId)"
                ></textarea-autosize>
              </div>
            </div>
          </span>
        <template slot="modal-footer">
          <div class="footer space-between">
            <span></span>
            <span v-if="!editor.currentPageId">
              <span v-if="patientConfig.consultNotesPublishMandatory">
                <b-button
                class="mx-1 save-draft-button" @click="saveNote(noteId, false, invoiceId)">Save Draft</b-button>
                <b-button
                class="mx-1 publish-button" @click="saveNote(noteId, true, invoiceId)">Publish</b-button>
              </span>
              <span v-else>
                <b-button
                class="mx-1" @click="saveNote(noteId, false, invoiceId)">Save</b-button>
              </span>
            </span>
            <span v-else-if="editor.currentPageId && editor.type === 'typing' && !editor.viewing">
                <b-button variant="outline-success"
                class="mx-1" @click="saveNote(editor.currentPageId, false)">Save Charting</b-button>
            </span>
            <span v-else-if="editor.currentPageId && editor.type === 'letter'">
              <b-button variant="outline-success"
              class="mx-1" @click="patchInvoice(editor.invoiceId, 'letters')">Save Letter</b-button>
            </span>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>
<script>
import Dateformatter from '@/components/mixins/dateformatter';
import ConsultNoteContainer from '@/components/ConsultNoteContainer.vue';
import CanvasEditorContainer from '@/components/CanvasEditorContainer.vue';
import TypingNoteEditor from '@/components/TypingNoteEditor.vue';
import ChartingViewer from '@/components/ChartingViewer.vue';
import otBookingService from '@/services/otbooking.service';
import Datepicker from 'vuejs-datepicker';

import {
  mapState, mapGetters, mapActions, mapMutations,
} from 'vuex';
import domtoimage from 'dom-to-image';
import moment from 'moment';
import uuid from 'uuid';
import { bus } from '@/main.js';

export default {
  name: 'NotesOverlay',
  data() {
    return {
      editorId: null,
      isEditingCategory: false,
      editorCategoryState: null,
      isEditingName: false,
      editorNameState: null,
      fromDate: null,
      toDate: null,
      numberOfDays: 0,
      prevFromDate:"",
      prevToDate:"",
      prevDays:0,
    };
  },
  components: {
    ConsultNoteContainer,
    CanvasEditorContainer,
    ChartingViewer,
    TypingNoteEditor,
    Datepicker,
  },
  mixins: [Dateformatter],
  computed: {
    patient() {
    return this.$store.state.patient.selectedPatient;
      // return this.$route.params.id;
    },
    noteId() {
      return this.editor.currentPageId || this.editor.noteId;
    },
    coverPage() {
      if (this.editor.type !== 'drawing') return undefined;
      return this.editor.pages[this.editor.pagesIdOrder[0]];
    },
    invoiceId() {
      return this.editor.invoiceId;
    },
    openSize() {
      return this.editor.openSize;
    },
    viewerOpenState() {
      return this.viewer.open;
    },
    imageURL() {
      return this.notesMapped[this.editor.currentPageId].signedPrintKey
        || this.notesMapped[this.editor.currentPageId].signedThumbnailKey
        || '';
    },
    populatedLetter() {
      const filtered = this.invoicesMappedById[this.editor.invoiceId].letters.filter(letter => letter.uuid === this.editor.currentPageId);
      return filtered[0].text;
    },
    isTypingNoteReadOnly() {
      return (this.editor.type === 'typing') && Object.values(this.editor.formData).every(e => e.qns === '');
    },
    currentNote() {
      return this.isTypingNoteReadOnly
        ? this.notesMapped[this.editor.noteId] || {}
        : null;
    },
    setPublishedCSSClass() {
      if (this.editor.type !== 'consult') return '';

      if (!this.notesMapped[this.editor.noteId].draft) return 'published';
      if (!this.patientConfig.consultNotesPublishMandatory) return 'fixed';
      return 'draft';
    },
    selectedCategory() {
      return this.editor.categoryId;
    },
    hasPassedNoteLockTimes() {
      if (!this.patientConfig.lockConsultNoteAt) return false;
      const note = this.notesMapped[this.noteId];
      let consultationDate = 0;
      if (!note) return;
      if (note.mainFormId) {
        const mainForm = this.notesMapped[note.mainFormId];
        consultationDate = mainForm.consultationDate || 0;
      } else {
        consultationDate = note.consultationDate || 0;
      }
      const [hour, minute] = this.patientConfig.lockConsultNoteTime.split(':');
      const cutoff = moment(consultationDate, 'X')
        .set({ hour, minute, second: '59' }).unix();
      return moment().unix() > cutoff;
    },
    isDraft() {
      const note = this.notesMapped[this.noteId];
      if (!note) return true;
      if (note.mainFormId) {
        const mainForm = this.notesMapped[note.mainFormId];
        return mainForm.draft;
      }
      return note.draft;
    },
    filteredCategoriesOptions() {
      let output = this.categoriesOptions;
      const userAccessChildrenFNIDs = localStorage.getItem('acl__childrenFnIds');
      if (userAccessChildrenFNIDs && this.categoriesOptions) {
        const userAccess = JSON.parse(userAccessChildrenFNIDs);
        output = this.categoriesOptions.filter(c => userAccess[c.value]);
      }
      return output;
    },
    ...mapState('notetemplate', ['notetemplatesMappedById']),
    ...mapState('invoice', ['invoices', 'invoicesMappedById']),
    ...mapState('otbooking', ['otOptions', 'otMappedIdToName']),
    ...mapState('note', [
      'editor',
      'viewer',
      'notecategories',
      'categoriesMapped',
      'originalEditorName',
      'cachedPermissions',
      'categoriesOptions',
    ]),
    ...mapGetters('note', ['notes', 'notesMapped']),
    ...mapGetters('config', { invoiceConfig: 'invoice', generalConfig: 'general' }),
    ...mapState('config', { patientConfig: 'patient' }),
  },
  methods: {
    editorFocus(pos) {
      this.editorId = pos;
    },
    editorBlur() {
      this.editorId = null;
    },
    calculateLetterContainerHeight(refVal) {
      // console.log('calculateLetterContainerHeight...');
      if (refVal === 'maximized-letter-container') {
        const node = document.getElementById('bv-modal-note');
        const height = window.innerHeight;
        // console.log('height is ', height);
        return height * 0.8;
        // TODO: FIXME - maximum window's letter height doesnt expand on first render
      }
      const node = this.$refs[refVal];
      if (node !== undefined && node !== null) {
        // console.log('node.clientHeight is ', node.clientHeight);
        // console.log('node.height is ', node.height);
        return node.clientHeight;
      }

      return 350;
    },
    updateSelectedLetter: _.debounce(function fn(e, invoiceId, letterUuid) {
      this.editLetter({
        invoiceId, letterUuid, key: 'text', value: e,
      });
    }, 300),
    updateSelectedLetterDate: _.debounce(function fn(e, invoiceId, letterUuid, type) {
      const slectedDate = moment(e).format('Do MMMM YYYY');
      let dict;
      const t = this.$refs.templatetextarea.$el;
      let copy = t.value;
      let oriContent = copy;
      let uFromDate = '\u200C';   //Unicode for zero width space
      let uToDate = '\u200B';    // Unicode for zero width non-joiner
      let uDays = '\u200D';      // Unicode for zero width joiner
    
    /* Adding Unicode zero width spaces before and after "{from_date}", "{to_date}", "{days}" to differentiate 
       them while replacing with new selected values */

      const regExpFrom = new RegExp(uFromDate+this.prevFromDate+uFromDate,'g');
      const regExpTo = new RegExp(uToDate+this.prevToDate+uToDate,'g');
      const regDays = new RegExp(uDays+this.prevDays+uDays,'g');
      
    /* Replacing the values of from_date, to_date, days with new values by identifying the position of these fields 
       with the unicode appended before and after of these fields in the above step.*/

      if (type) {
        oriContent = this.prevFromDate !=="" ? oriContent.replace(regExpFrom,"{from_date}") : oriContent
        oriContent = this.prevDays !=="" ? oriContent.replace(regDays, "{days}") : oriContent
        dict = {
        '{from_date}':  `${uFromDate}`+slectedDate+ `${uFromDate}`,
        };
        this.prevFromDate = slectedDate;
      } else {
        oriContent = this.prevToDate !=="" ? oriContent.replace(regExpTo, "{to_date}")  : oriContent
        oriContent = this.prevDays !=="" ? oriContent.replace(regDays,"{days}") : oriContent
        dict = {
          '{to_date}': `${uToDate}`+slectedDate+`${uToDate}`,
        };
        this.prevToDate = slectedDate;
      }

      if (this.fromDate && this.toDate) {
        const date1 = moment(this.fromDate);
        const date2 = moment(this.toDate);
        dict['{days}'] = date2.diff(date1, 'days');
        this.prevDays=dict['{days}'];
        dict['{days}'] = `${uDays}`+dict['{days}']+`${uDays}`
      }
      const dictKeys = Object.keys(dict);
      for (let i = 0; i < dictKeys.length; i += 1) {
        const regex = new RegExp(dictKeys[i], 'g');
        oriContent = oriContent.replace(regex, dict[dictKeys[i]]);
      }
      copy = oriContent;// (t.value.slice(0, t.selectionStart)) + slectedDate + (t.value.slice(t.selectionEnd));
      this.updateSelectedLetter(copy, invoiceId, letterUuid);
    }, 50),
    toggleDispenseBuilder(toggle, invoice, lineItemUuid) {
      // console.log('NotesOverlay toggleDispenseBuilder - toggle, invoice, lineItemUuid is ', toggle, invoice, lineItemUuid);
      if (toggle) {
        const lineItem = invoice.items.filter(item => item.uuid === lineItemUuid)[0];
        // console.log('lineitem is ', lineItem);
        this.dispenseBuilderInvoiceId = { lineItem, invoice };
      } else {
        this.dispenseBuilderInvoiceId = {};
      }
    },
    updateMainFormData(e, qns) {
      // console.log('in update main form data, e, qns is ', e, qns);
      if (typeof e === 'string') {
        e = e.replace(/\<p\>\<\/p\>/g, '<p><br></p>');
      }
      this.$store.commit('note/UPDATE_MAIN_FORM_DATA', { noteId: this.editor.currentPageId, qns, val: e });
    },
    updateTypingFormData(e, qns) {
      console.log('in update typing form data, e, qns is ', e, qns);
      this.$store.commit('note/UPDATE_TYPING_FORM_DATA', { qns, val: e });
    },
    async saveNote(noteId, publishing, invoiceId) {
      console.log('saving...noteId, publishing, invoiceId is ', noteId, publishing, invoiceId);
      let resp;
      try {
        // console.log('this.editor.currentPageId ', this.editor.currentPageId);
        if (this.editor.currentPageId) {
          const editorBubble = document.getElementById('editor-menu-bubble');
          if (editorBubble !== null && editorBubble !== undefined) {
            editorBubble.classList.remove('is-active');
          }
          const dom = document.getElementById(`print-${this.editor.currentPageId}`);
          const signatureCanvas = document.getElementsByClassName('signature-canvas');
          const arrayCanvasLength = signatureCanvas.length;
          let checkForCanvas = false;

          if (signatureCanvas && signatureCanvas.length) {
            for (let index = 0; index < arrayCanvasLength; index++) {
              checkForCanvas = dom.contains(signatureCanvas[0]);
              if (checkForCanvas) {
                signatureCanvas[0].remove();
              }
            }
          }
          const base64 = await domtoimage.toJpeg(dom, { quality: 0.95 });
          resp = await this.$store.dispatch('note/submitNote', { noteId, publishing, base64 });
        } else {
          resp = await this.$store.dispatch('note/submitNote', { noteId, publishing });
        }
        if (publishing) {
          if (resp.ok) {
            this.flash('Note published successfully', 'success', { timeout: 3000 });
          } else {
            this.flash('Failed to publish note, please try again', 'error', { timeout: 5000 });
          }
        } else if (resp.ok) {
          this.flash('Note saved successfully', 'success', { timeout: 3000 });
        } else {
          this.flash('Failed to save note, please try again', 'error', { timeout: 5000 });
        }
        if (invoiceId && !this.invoicesMappedById[invoiceId].finalized) {
          this.saveInvoice(invoiceId);
        }
      } catch (error) {
        console.error('e is ', error);
        this.flash(error, 'error', { timeout: 5000 });
      }
    },

    async saveInvoice(invoiceId) {
      if (this.invoicesMappedById[invoiceId].invoiceNo === 'draft') {
        this.$store.commit('invoice/UPDATE_INVOICE_GST_X_RATE', {
          invoice: this.invoicesMappedById[invoiceId],
          includeGST: this.invoiceConfig.includeGST,
          exchangeRate: this.invoiceConfig.exchangeRate,
        });
      }
      const invResp = await this.$store.dispatch('invoice/SAVE_INVOICE', { id: invoiceId, voidInvoice: false });
      if (invResp.success) {
        this.flash('Invoice details saved successfully', 'success', { timeout: 3000 });
      } else {
        this.flash('Failed to save invoice details', 'error', { timeout: 5000 });
      }
    },
    async patchInvoice(invoiceId, key) {
      const invResp = await this.$store.dispatch('invoice/PATCH_INVOICE', { id: invoiceId, key });
      if (invResp.ok) {
        this.flash('Invoice details saved successfully', 'success', { timeout: 3000 });
      } else {
        this.flash('Failed to save invoice details', 'error', { timeout: 5000 });
      }
    },
    mainCheckboxChanged(checked, qns, allEffects) {
      let effects = [];
      if (!checked) {
        effects = allEffects.unchecked;
      } else {
        effects = allEffects.checked;
      }
      console.log('effect');
      this.$store.commit('note/UPDATE_TYPING_FORM_MAIN_CHECKBOX', { qns, checked, effects });
    },
    mainRadioChanged(e, qns, mainRadioboxControl) {
      this.$store.commit('note/UPDATE_TYPING_FORM_MAIN_RADIO', { e, qns, mainRadioboxControl });
    },
    preMinimizeWindow(e, newSize) {
      // console.log('in preMinimizeWindow');
      this.resizeExtractedNote(e, newSize);
      this.$bvModal.hide('bv-modal-note');
    },
    preMinimizeToIPD(e, name) {
      console.log('inside preMinimizeToIPD...e, name is ', e, name);
      this.$store.commit('note/MINIMIZE_EXTRACTED_IPD_NOTE', { openSize: 'hidden', containerName: name });
      bus.$emit('ipd_drawing_minimized', name);
      this.$store.commit('note/CLOSE_EXTRACTED_NOTE');
    },
    async preMaximizeWindow() {
      // console.log('in preMaximizeWindow...e is  ',e);
      // if (this.editor.currentPageId) {
      const payload = _.cloneDeep(this.editor);
      payload.openSize = 'maximized';
      if (this.editor.type === 'drawing') {
        // console.log('this.editor is ', this.editor);
        payload.open = true;
        const res = await this.$store.dispatch('note/getLatestImage', {
          id: this.editor.currentPageId,
          keys: ['signedThumbnailKey', 'signedOriginalImageKey', 'strokeStore'],
        });
        payload.pages[this.editor.currentPageId] = this.notesMapped[this.editor.currentPageId];
        // console.log('fetchone first page res for preMaximizeWindow is ', res);
        if (this.editor.pagesIdOrder && this.editor.pagesIdOrder.length > 1) {
          for (let i = 1; i < this.editor.pagesIdOrder; i++) {
            const res = await this.$store.dispatch('note/getLatestImage', {
              id: this.editor.pagesIdOrder[i],
              keys: ['signedThumbnailKey', 'strokeStore'],
            });
            payload.pages[this.editor.pagesIdOrder[i]] = this.notesMapped[this.editor.pagesIdOrder[i]];
            // console.log('fetchone subsequent pages res for preMaximizeWindow is ', res);
          }
        }
        // this.$store.commit('state/setLoading', true);
        this.$store.commit('note/EXTRACT_NOTE', payload);
      } else {
        this.$store.commit('note/EXTRACT_NOTE', payload);
        this.$bvModal.show('bv-modal-note');
      }
      // }
      // else {
      //   this.$bvModal.show('bv-modal-note');
      // }
    },
    onResize(x, y, width, height) {
      this.width = width;
      this.height = height;
    },
    resizeExtractedNote(e, newSize) {
      // console.log('in resizeExtractedNote... e and newSize ', e, newSize);
      this.$store.commit('note/RESIZE_EXTRACTED_NOTE', { openSize: newSize });
      e.stopPropagation();
    },
    preCloseEditor() {
      this.$bvModal.hide('bv-modal-note');
      this.closeEditor();
    },
    emptyNameCheck() {
      if (this.editor.name === '') {
        alert('Title field is required');
        this.updateEditorMetadata({ key: 'name', value: this.originalEditorName });
        return false;
      }
      return true;
    },
    checkPermissions(charting, action) {
      if (!charting) return;
      const chartingId = charting._id;
      const { permissions } = charting;
      if (!permissions) return true;
      if (this.cachedPermissions[chartingId] && this.cachedPermissions[chartingId][action] !== undefined) {
        return this.cachedPermissions[chartingId][action];
      }
      if (!this.cachedPermissions[chartingId]) this.cachedPermissions[chartingId] = {};
      if (permissions.anyone && permissions.anyone[action]) {
        this.updateCachedPermissions({ chartingId, action, toggle: true });
        return true;
      }
      if (permissions[this.user.id] && permissions[this.user.id][action]) {
        this.updateCachedPermissions({ chartingId, action, toggle: true });
        return true;
      }
      for (let i = 0; i < this.user.roles.length; i++) {
        const role = this.user.roles[i];
        if (permissions[role._id] && permissions[role._id][action]) {
          this.updateCachedPermissions({ chartingId, action, toggle: true });
          return true;
        }
      }
      this.updateCachedPermissions({ chartingId, action, toggle: false });
      return false;
    },
    formatResults(res) {
      if (!res || res === '') return res;
      console.log('res is ', res);
      const output = res.split('\\.br\\').join('<br>');
      console.log('output ', output);
      return output;
    },
    ...mapMutations('invoice', ['editLetter']),
    ...mapMutations('note', ['updateNote', 'goToPage', 'closeEditor', 'updateEditorMetadata', 'updateCachedPermissions']),
    ...mapActions('note', ['submitNote']),
    ...mapActions('notetemplate', ['fetchAllNoteTemplates']),
  },
};
</script>
<style lang="scss" scoped>
//colors
$c-silver: #eeeeee;
$c-lightGrey: #BBB;
$c-grey: #999;
$c-darkGrey: #484848;
$c-dimGrey: #282828;
$c-gold: #DAA520;
$c-paleGold: #FFE300;
$c-green: #3f3;
$c-darkGreen: #008000;
$c-orange: #FF4500;
$c-aqua: #00FFFF;
$c-blue: #3F90FE;
$c-pink: #FFC0CB;
$c-white: #ffffff;

.modal-dialog,
.modal-content {
    /* 80% of window height */
    height: 80% !important;
}

.modal-body {
    /* 100% = dialog height, 120px = header + footer */
    max-height: calc(100% - 120px) !important;
    overflow-y: scroll !important;
}
.image {
  text-align: center;
  width: 100%;
  height: 100%;
  border: 1px solid red;
  img {
    display: inline-block;
    vertical-align: middle;
    max-height: 100%;
    max-width: 100%;
  }
  &:before {
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }
}
.space-between {
  display: flex;
  justify-content: space-between;
}
.bottom-panel {
  position: fixed;
  bottom: 0;
  right: 0;
  text-align: right;
  z-index: 1062;
  padding: 0 2.5rem;
  pointer-events:none;
}
.popup-window {
  background: $c-white;
  color: $c-dimGrey;
  position: relative;
  z-index: 30;
  text-align: left;
  box-shadow: 0 0 3px $c-darkGrey;
  pointer-events: auto;
  height: inherit;
  .label {
    min-height: 7%;
    max-height: 7%;
    padding: .6rem .6rem;
    color: $c-lightGrey;
    line-height: 1rem;
    &.draft {
      background-color: rgb(255, 218, 116);
      color: black;
    }
    &.published {
      background-color: rgb(169,229,196);
      color: black;
    }
    &.fixed {
      background-color: rgb(211, 208, 208);
      color: black;
    }
  }
  .invoice-note-container {
    overflow-x: hidden;
    min-height: 82%;
    max-height: 82%;
  }
  .footer {
    min-height: 11%;
    max-height: 11%;
    padding: .6rem .6rem;
    line-height: 1rem;
    border-top: 2px solid rgba(183,183,183, 0.7);
  }

}
.top-right-icons > * {
  margin-left: 5px;
  cursor: pointer;
}
.new-mail {
  width: 35rem;
  height: 30rem;

  display: inline-block;
  margin-left: .4rem;
  vertical-align: bottom;
  position: relative;
}

.minimized-window {
  background: $c-dimGrey;
  color: $c-lightGrey;
  position: relative;
  z-index: 30;
  text-align: left;
  box-shadow: 0 0 3px $c-darkGrey;
  pointer-events: auto;

  width: 15rem;
  height: 2rem;
}
.left-icon > .fa-icon {
  padding-bottom: 6px;
  width: 18px;
}

.big-overlay {
  // border: 1px black solid;
  display: block;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 2068;
  background: transparent;
  pointer-events: none;
}
.view-overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  background: transparent;
  pointer-events: all;
  z-index: 1068;
}
.editor {
  min-height: 20px;
  border: 1px solid rgb(206,212,218);
  border-radius: 4px;
}
.save-draft-button {
  background-color: rgb(255, 218, 116);
  color: black;
}
.publish-button {
  background-color: rgb(169,229,196);
  color: black;
}
.invoice-note-container {
  .row {
    background-color: white;
  }
}
</style>
