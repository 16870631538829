<template>
    <div
        class="previous-sessions-container"
        :class="`${openSessions ? 'visible' : 'invisible'} ${toolbarPosition}`"
    >
        <div class="space-between">
            <span>{{ $t('general.revisionHistory') }}</span>
            <span @click="preCloseHistory()">X</span>
        </div>
        <span v-if="openSessions">
            <div
              v-for="(session, index) in sessions.slice().reverse()"
              :key="session[3]"
              class="cndh-content-item"
            >
                <span
                  @click="redrawSession(session)">
                    {{ $t('general.session') }} #{{sessions.length - index}} {{ $t('general.byUser')}} {{session[2]}} {{ $t('general.at')}} {{formatTime(session[3])}}
                </span>
            </div>
        </span>
    </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'CanvasEditorHistory',
  props: [
    'closeHistory',
    'sessions',
    'openSessions',
    'toolbarPosition',
    'redrawSession',
  ],
  data() {
    return {
      dateSection: null,
    };
  },
  methods: {
    formatTime(time) {
      return moment(time).format('DD MMMM YYYY');
    },
    renderDateSection(session) {
      const day = moment(session[3] * 1000).format('DD MMMM YYYY');
      const today = day === moment().format('DD MMMM YYYY') ? 'Today, ' : '';
      if (day !== this.dateSection) {
        this.dateSection = day;
        return `${today + day}`;
      }
      return '';
    },
    preCloseHistory() {
      this.redrawSession();
      this.closeHistory();
    },
  },
};
</script>

<style lang="scss" scoped>

.space-between {
  display: flex;
  justify-content: space-between;
}

.previous-sessions-container {
    position: absolute;
    height: 100%;
    background: #3d464d;
    bottom: 0;
    top: 0;
    padding: 0;
    color: #ffffff;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    z-index: 1488;
    &.left {
        left: 0;
    }
    &.right {
        right: 0;
    }
    > h3 {
        padding: 15px;
        background: #283138;
    }

    &.visible {
        width: 170px;
    }

    &.invisible {
        width: 0;
        padding: 0;
    }
    .cndh-content {
        overflow: hidden;
    }

    .cndh-content-item {
        > h4 {
            padding: 0 15px;
            background: #20272d;
            font-size: 12px;
        }

        .cndh-content-item-info {
            cursor: pointer;
            padding: 0 15px;
            transition: all .2s ease-in-out;
            -webkit-transition: all .2s ease-in-out;
            background-color: #3d464d;

            &.second {
                background-color: #384349;
            }

            &:hover {
                opacity: 0.75;
            }

            &.active {
                -webkit-box-shadow: inset 5px 0px 0px 0px #18bc9a;
                -moz-box-shadow: inset 5px 0px 0px 0px #18bc9a;
                box-shadow: inset 5px 0px 0px 0px #18bc9a;
                > h5 {
                    > span {
                        color: #1bbc9b;
                    }
                }
            }

            > h5 {
                font-size: 12px;

                > span {
                    float: right;
                }
            }
            > h6 {
                font-size: 12px;
            }
        }
    }

    .cndh-btn {
        background: #c8cccf;
        color: #ffffff;
        position: absolute;
        left: -56px;
        bottom: 40px;
        padding: 5px 10px;
        border-radius: 6px 0 0 6px;
        display: -ms-flexbox;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        -webkit-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;

        :hover {
            opacity: 0.75;
        }
    }
}
</style>
