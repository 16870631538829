import Vue from 'vue';
import invoiceService from '@/services/invoice.service';
import paymentService from '@/services/payment.service';

import {
  SET_PAYMENT,
  UPDATE_NEW_PAYMENT,
  APPLY_INVOICE_AMOUNT,
  SET_INVOICES_TO_PAY,
  SET_PAYMENTS,
  SET_OFFSET,
  SET_IPD_PAYMENT,
  SET_PAYPAL_OFFSET,
  SET_STRIPE_OFFSET,
} from './type.mutation';

const state = {
  all: [],
  selectedPayment: {},
  appliedInvoices: [],
  invoicesToPay: [], // TODO: should be in invoice store?,
  offset: {},
  ipdPayment: {},
  paypalOffset: {},
  stripeOffset: {},
};

/* eslint-disable no-param-reassign */
const mutations = {
  [SET_PAYMENT](_state, payload) {
    _state.selectedPayment = payload;
  },
  [SET_IPD_PAYMENT](_state, payload) {
    _state.ipdPayment = payload;
  },
  [UPDATE_NEW_PAYMENT](_state, payload) {
    _state.all.push(payload);
  },
  [APPLY_INVOICE_AMOUNT](_state, payload) {
    Vue.set(_state.invoicesToPay, payload.index, payload.invoice);
  },
  [SET_INVOICES_TO_PAY](_state, payload) {
    _state.invoicesToPay = payload;
  },
  [SET_PAYMENTS](_state, payload) {
    _state.all = payload;
  },
  [SET_OFFSET](_state, payload) {
    _state.offset = payload;
  },
  [SET_PAYPAL_OFFSET](_state, payload) {
    _state.paypalOffset = payload;
  },
  [SET_STRIPE_OFFSET](_state, payload) {
    _state.stripeOffset = payload;
  },
};
const actions = {
  setPayment({ commit }, payload) {
    return paymentService.create(payload)
      .then((response) => {
        if (response.success) {
          commit(SET_PAYMENT, response.data);
          commit(UPDATE_NEW_PAYMENT, response.data);
        }
        return response;
      }).catch((error) =>  error)
  },
  setPaymentProvider({ commit }, payload) {
    return paymentService.createPackage(payload)
      .then((response) => {
        if (response.success) {
          commit(SET_PAYMENT, response.data);
          commit(UPDATE_NEW_PAYMENT, response.data);
        }
        return response;
      });
  },
  setIPDPayment({ commit }, payload) {
    return paymentService.createIPDInvoicePayment(payload)
      .then((response) => {
        if (response.success) {
          commit(SET_PAYMENT, response.data);
          commit(UPDATE_NEW_PAYMENT, response.data);
        }
        return response;
      });
  },
  voidPayment({ commit }, payload) {
    return paymentService.void(payload._id, payload)
      .then((response) => {
        if (response.success) {
          commit(SET_PAYMENT, response.data);
        }
        return response;
      });
  },
  voidIpdPayment({ commit }, payload) {
    return paymentService.voidIpd(payload._id, payload)
      .then((response) => {
        if (response.success) {
          commit(SET_IPD_PAYMENT, response.data);
        }
        return response;
      });
  },
  selectPayment({ commit }, payload) {
    commit(SET_PAYMENT, payload);
  },
  applyInvoiceAmount({ commit }, payload) {
    commit(APPLY_INVOICE_AMOUNT, payload);
  },
  fetchInvoicesToPay({ commit }, { patient, clinicCode }) {
    return invoiceService.list({
      patient,
      open: 0,
      includeUnsettledCorpPayments: true,
      clinicCode,
      voided: false,
    })
      .then((response) => {
        if (response.success) {
          commit(SET_INVOICES_TO_PAY, response.data);
        }
        return response;
      });
  },
  fetchInvoicesProviderToPay({ commit }, { provider, clinicCode }) {
    return invoiceService.list({
      provider,
      open: 0,
      includeUnsettledCorpPayments: true,
      clinicCode,
      voided: false,
    })
      .then((response) => {
        if (response.success) {
          commit(SET_INVOICES_TO_PAY, response.data);
        }
        return response;
      });
  },
  updateInvoicesToPay({ commit }, payload) {
    commit(SET_INVOICES_TO_PAY, [payload]);
    return { success: true };
  },
  fetchPayments({ commit }, { patient, clinicCode }) {
    paymentService.list({ patient, clinicCode })
      .then((response) => {
        
        if (response.success) {
          commit(SET_PAYMENTS, response.data);
        }
      });
  },
  fetchProviderPayments({ commit }, { provider, clinicCode }) {
    paymentService.providerList({ provider, clinicCode })
      .then((response) => {
        
        if (response.success) {
          commit(SET_PAYMENTS, response.data);
        }
      });
  },
  fetchPatientOffset({ commit }, { patient }) {
    return paymentService.getOffset(patient)
      .then((response) => {
        commit(SET_OFFSET, response.data);
      });
  },
  fetchProviderOffset({ commit }, { provider }) {
    return paymentService.getOffsetProvider(provider)
      .then((response) => {
        commit(SET_OFFSET, response.data);
      });
  },
  fetchProviderPayPalOffset({ commit }, { provider }) {
    return paymentService.getPayPalProviderOffset(provider)
      .then((response) => {
        commit(SET_PAYPAL_OFFSET, response.data);
      });
  },
  fetchPatientPayPalOffset({ commit }, { patient }) {
    return paymentService.getPayPalOffset(patient)
      .then((response) => {
        commit(SET_PAYPAL_OFFSET, response.data);
      });
  },
  fetchPatientStripeOffset({ commit }, { patient }) {
    return paymentService.getStripeOffset(patient)
      .then((response) => {
        commit(SET_STRIPE_OFFSET, response.data);
      });
  },
};

const getters = {
  all: _state => _state.all,
  selectedPayment: _state => _state.selectedPayment,
  appliedInvoices: _state => _state.appliedInvoices,
  invoicesToPay: _state => _state.invoicesToPay,
  offset: _state => _state.offset,
  paypalOffset: _state => _state.paypalOffset,
  stripeOffset: _state => _state.stripeOffset,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
