var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    _vm._l(_vm.commonPatientInvoiceArray, function(patientItem, index) {
      return _c("div", { key: index }, [
        _c("div", { staticClass: "title text-center mt-4" }, [
          _vm._v("\n      医療費請求書兼領収書\n    ")
        ]),
        _c("div", { staticClass: "invoice-info" }, [
          _c("div", { staticClass: "info-left" }, [
            _c("div", { staticClass: "item-wrap" }, [
              _c("div", { staticClass: "item-label" }, [
                _vm._v("\n            患者氏名\n          ")
              ]),
              _c("div", { staticClass: "item-value text-center" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(patientItem.patientName) +
                    "\n          "
                )
              ])
            ]),
            _c("div", { staticClass: "item-wrap" }, [
              _c("div", { staticClass: "item-label" }, [
                _vm._v("\n            診療月\n          ")
              ]),
              _c("div", { staticClass: "item-value-wrap" }, [
                _c("div", { staticClass: "item-value-year text-center" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.yearNumber) +
                      "\n            "
                  )
                ]),
                _c("div", { staticClass: "item-label-year" }, [
                  _vm._v("\n              年\n            ")
                ]),
                _c("div", { staticClass: "item-value-month text-center" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.monthNumber) +
                      "\n            "
                  )
                ]),
                _c("div", { staticClass: "item-label-month" }, [
                  _vm._v("\n              月\n            ")
                ])
              ])
            ]),
            _vm._m(0, true)
          ]),
          _c("div", { staticClass: "info-right" }, [
            _c("div", { staticClass: "item-wrap" }, [
              _c("div", { staticClass: "item-label" }, [
                _vm._v("\n            ID番号\n          ")
              ]),
              _c("div", { staticClass: "item-value-no-border text-center" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(patientItem.patientGivenId) +
                    "\n          "
                )
              ])
            ]),
            _c("div", { staticClass: "item-wrap" }, [
              _c("div", { staticClass: "item-label" }, [
                _vm._v("\n            診療科名\n          ")
              ]),
              _c("div", { staticClass: "item-value-no-border text-center" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.classificationInvoicesInfo.serviceInfo.text) +
                    "\n          "
                )
              ])
            ]),
            _c("div", { staticClass: "item-wrap" }, [
              _c("div", { staticClass: "item-label" }, [
                _vm._v("\n            通院日数\n          ")
              ]),
              _c("div", { staticClass: "item-value-wrap" }, [
                _c("div", { staticClass: "item-value-day text-center" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(patientItem.items.length) +
                      "\n            "
                  )
                ]),
                _c("div", { staticClass: "item-label-day" }, [
                  _vm._v("\n              日\n            ")
                ]),
                _c("div", { staticClass: "item-mark-day" }, [
                  _vm._v("\n              （回)\n            ")
                ])
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "invoice-total-price" }, [
          _c("div", { staticClass: "tip" }, [
            _vm._v("\n        請求金額の内訳\n      ")
          ]),
          _c("div", { staticClass: "total-price-wrap" }, [
            _c("span", [_vm._v("請求·領収金額")]),
            _c("span", { staticClass: "total-price" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.region.currency) +
                  " " +
                  _vm._s(
                    _vm._f("thousandSeparator")(
                      _vm._f("to2Decimal")(patientItem.totalPrice)
                    )
                  ) +
                  "\n        "
              )
            ]),
            _c("span", [_vm._v("人民元")])
          ])
        ]),
        _c(
          "div",
          { staticClass: "item-table-list" },
          [
            _vm._m(1, true),
            _vm._l(patientItem.items, function(item, index) {
              return _c("div", { key: index, staticClass: "table-item row" }, [
                _c(
                  "div",
                  { staticClass: "col-2 text-center bdr-2 table-item-value" },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(item.invoiceFormatDate) +
                        "\n        "
                    )
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "col text-center bdr-2 table-item-value" },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.region.currency) +
                        " " +
                        _vm._s(
                          _vm._f("thousandSeparator")(
                            _vm._f("to2Decimal")(item.examinationFee)
                          )
                        ) +
                        "\n        "
                    )
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "col text-center bdr-2 table-item-value" },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.region.currency) +
                        " " +
                        _vm._s(
                          _vm._f("thousandSeparator")(
                            _vm._f("to2Decimal")(item.inspectionFee)
                          )
                        ) +
                        "\n        "
                    )
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "col text-center bdr-2 table-item-value" },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.region.currency) +
                        " " +
                        _vm._s(
                          _vm._f("thousandSeparator")(
                            _vm._f("to2Decimal")(item.disposalFee)
                          )
                        ) +
                        "\n        "
                    )
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "col text-center bdr-2 table-item-value" },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.region.currency) +
                        " " +
                        _vm._s(
                          _vm._f("thousandSeparator")(
                            _vm._f("to2Decimal")(item.medicineFee)
                          )
                        ) +
                        "\n        "
                    )
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "col-3 text-center bdr-2 table-item-value" },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.region.currency) +
                        " " +
                        _vm._s(
                          _vm._f("thousandSeparator")(
                            _vm._f("to2Decimal")(item.otherFee)
                          )
                        ) +
                        "\n        "
                    )
                  ]
                ),
                _c("div", { staticClass: "col text-center table-item-value" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.region.currency) +
                      " " +
                      _vm._s(
                        _vm._f("thousandSeparator")(
                          _vm._f("to2Decimal")(item.corporateInvoice)
                        )
                      ) +
                      "\n        "
                  )
                ])
              ])
            })
          ],
          2
        ),
        _c("div", { staticClass: "invoice-footer" }, [
          _vm._m(2, true),
          _c("div", { staticClass: "clinic-contact-wrap" }, [
            _c("div", { staticClass: "clinic-address" }, [
              _vm._v(
                "\n          上海市古北路1078号2F   Tel：021-5187-7830\n        "
              )
            ]),
            _c("div", { staticClass: "clinic-mark" }, [
              _vm._v(
                "\n          作成日 " + _vm._s(_vm.todayDate) + "\n        "
              )
            ])
          ])
        ]),
        _c("div", { staticStyle: { "page-break-after": "always" } })
      ])
    }),
    0
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item-wrap" }, [
      _c("div", { staticClass: "item-label" }, [
        _vm._v("\n            入院·外来区分\n          ")
      ]),
      _c("div", { staticClass: "item-value-shrink text-center" }, [
        _vm._v("\n            外来\n          ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "table-header row" }, [
      _c("div", { staticClass: "col-2 text-center bdr-2" }, [
        _vm._v("\n          日付\n        ")
      ]),
      _c("div", { staticClass: "col text-center bdr-2" }, [
        _vm._v("\n          诊察费\n        ")
      ]),
      _c("div", { staticClass: "col text-center bdr-2" }, [
        _vm._v("\n          検查費\n        ")
      ]),
      _c("div", { staticClass: "col text-center bdr-2" }, [
        _vm._v("\n          処置费\n        ")
      ]),
      _c("div", { staticClass: "col text-center bdr-2" }, [
        _vm._v("\n          薬費\n        ")
      ]),
      _c("div", { staticClass: "col-3 text-center bdr-2" }, [
        _vm._v("\n          その他（休日）\n        ")
      ]),
      _c("div", { staticClass: "col text-center" }, [
        _vm._v("\n          合計\n        ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "clinic-info-wrap" }, [
      _c("div", { staticClass: "clinic-logo-wrap" }, [
        _c("img", {
          staticClass: "clinic-logo",
          attrs: { src: require("@/assets/clinicLogo.jpg") }
        })
      ]),
      _c("div", { staticClass: "clinic-info" }, [
        _c("div", { staticClass: "clinic-name" }, [
          _vm._v("\n            上海平和門診部\n          ")
        ]),
        _c("div", { staticClass: "clinic-url" }, [
          _vm._v("\n            http://www.peaceclinic.cn\n          ")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }