var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "drug-label-content-container",
      attrs: { id: "drug-label" }
    },
    [
      _c("div", { staticClass: "address-header" }, [
        _vm.printLocal
          ? _c("div", { staticClass: "drug-label-address" }, [
              _c("p", [
                _vm._v(
                  "\n         " +
                    _vm._s(_vm.selectedClinic.localAddress) +
                    "\n         "
                ),
                _c("span", { staticClass: "drug-label-phone" }, [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-size": "0.35rem",
                        "vertical-align": "middle"
                      }
                    },
                    [_vm._v("📞")]
                  ),
                  _vm._v(
                    "\n           " +
                      _vm._s(_vm.selectedClinic.phoneNumber) +
                      "\n         "
                  )
                ])
              ]),
              _c("p", { staticClass: "dispense-time" }, [
                _vm._v(
                  "\n         " +
                    _vm._s(
                      _vm._f("formatVDDate")(new Date(), {
                        lang: "en",
                        format: "DD/MM/YYYY HH:mm:ss"
                      })
                    ) +
                    "\n         "
                ),
                _c("span", { staticClass: "hn" }, [
                  _vm._v(
                    "\n           CN: " +
                      _vm._s(_vm.patient.givenIdPrefix) +
                      _vm._s(_vm.patient.givenId) +
                      "\n         "
                  )
                ])
              ])
            ])
          : _c("div", { staticClass: "drug-label-address" }, [
              _c("p", [
                _vm._v(
                  "\n         " +
                    _vm._s(_vm.selectedClinic.address) +
                    "\n         "
                ),
                _c("span", { staticClass: "drug-label-phone" }),
                _c(
                  "span",
                  {
                    staticStyle: {
                      "font-size": "0.35rem",
                      "vertical-align": "middle"
                    }
                  },
                  [_vm._v("📞")]
                ),
                _vm._v(
                  "\n           " +
                    _vm._s(_vm.selectedClinic.phoneNumber) +
                    "\n         "
                )
              ]),
              _c("p", { staticClass: "dispense-time" }, [
                _vm._v(
                  "\n         " +
                    _vm._s(
                      _vm._f("formatVDDate")(new Date(), {
                        lang: "en",
                        format: "DD/MM/YYYY HH:mm:ss"
                      })
                    ) +
                    "\n         "
                ),
                _c("span", { staticClass: "hn" }, [
                  _vm._v(
                    "\n           CN: " +
                      _vm._s(_vm.patient.givenIdPrefix) +
                      _vm._s(_vm.patient.givenId) +
                      "\n         "
                  )
                ])
              ])
            ])
      ]),
      _c("div", { staticClass: "text-right patient-details" }, [
        _c("p", [
          !!_vm.medItem.location
            ? _c("span", { staticClass: "drug-location" }, [
                _vm._v(_vm._s(_vm.medItem.location))
              ])
            : _vm._e(),
          _vm._v(
            "\n         Batch no: " +
              _vm._s(_vm.selectedBatch.number) +
              "\n         Expired: " +
              _vm._s(
                _vm._f("formatVDDate")(_vm.selectedBatch.expiry, {
                  lang: "en",
                  format: _vm.$store.state.config.inventory.expiryFormatConfig
                })
              ) +
              "\n       "
          )
        ])
      ]),
      _c("div", { staticClass: "columns med-name-line" }),
      _c("div", { staticClass: "drug-name-col" }, [
        _c("div", [
          _c("div", { staticClass: "drug-name" }, [
            _c("p", [
              _vm._v("\n         " + _vm._s(_vm.medItem.name) + "\n         "),
              _vm.medItem.strength
                ? _c("span", { staticClass: "ml-1" }, [
                    _vm._v(" " + _vm._s(_vm.medItem.strength))
                  ])
                : _vm._e(),
              _vm.medItem.qty || _vm.medItem.unit
                ? _c("span", { staticClass: "ml-1 drug-qty" }, [
                    _vm._v(
                      "( " +
                        _vm._s(_vm.medItem.qty) +
                        " " +
                        _vm._s(_vm.medItem.unit) +
                        " )"
                    )
                  ])
                : _vm._e()
            ]),
            !!_vm.medItem.chineseName
              ? _c("p", [
                  _vm._v(
                    "\n         " +
                      _vm._s(_vm.medItem.chineseName) +
                      "\n         "
                  ),
                  _vm.medItem.strength
                    ? _c("span", { staticClass: "ml-1" }, [
                        _vm._v(" " + _vm._s(_vm.medItem.strength))
                      ])
                    : _vm._e(),
                  _vm.medItem.qty || _vm.medItem.unit
                    ? _c("span", { staticClass: "ml-1 drug-qty" }, [
                        _vm._v(
                          "( " +
                            _vm._s(_vm.medItem.qty) +
                            " " +
                            _vm._s(_vm.medItem.unit) +
                            " )"
                        )
                      ])
                    : _vm._e()
                ])
              : _vm._e()
          ])
        ])
      ]),
      _c("div", { staticClass: "columns is-gapless main-body-container" }, [
        _c("div", { staticClass: "column body-left" }, [
          _c("div", { staticClass: "body-left-grey" }, [
            _vm.printLocal
              ? _c("p", { staticClass: "patient-info name" }, [
                  _vm._v(
                    _vm._s(_vm._f("' '")(_vm.patient.localTitle)) +
                      " " +
                      _vm._s(_vm.patient.localName || " ")
                  )
                ])
              : _c("p", { staticClass: "patient-info name" }, [
                  _vm._v(
                    _vm._s(_vm.patient.title) + " " + _vm._s(_vm.patient.name)
                  )
                ]),
            _c("p", { staticClass: "dosage-msg" }, [
              _vm._v(_vm._s(_vm.medDosageMessage))
            ]),
            _c("p", { staticClass: "dosage-instruct" }, [
              _vm._v(_vm._s(_vm.medInstruction))
            ])
          ]),
          _c("div", { staticClass: "body-left-white" }, [
            _c("div", [
              _c("p", {}, [_vm._v(_vm._s(_vm.medPrecautionOne))]),
              _c("p", {}, [_vm._v(_vm._s(_vm.medPrecautionTwo))])
            ]),
            _c("p", { staticClass: "mb-4" }, [
              _vm._v(_vm._s(_vm.medIndication))
            ])
          ]),
          _vm.doctorName
            ? _c("div", { staticClass: "doctor" }, [
                _vm._v("\n         " + _vm._s(_vm.doctorName) + "\n       ")
              ])
            : _vm._e()
        ]),
        _c("div", { staticClass: "column body-right is-narrow" }, [
          _c("div", { staticClass: "dosage-info" }, [
            _vm.medItem.instructionInfo && _vm.medItem.instructionInfo.morning
              ? _c("div", { staticClass: "dosage-info-inner" }, [
                  _c("p", { staticClass: "mb-1 med-unit" }, [
                    _vm._v(_vm._s(_vm.medDosage))
                  ]),
                  _c("p", {}, [_vm._v(_vm._s(_vm.medDosageUnit))])
                ])
              : _c("div", [_vm._v(" ")])
          ]),
          _c("div", { staticClass: "dosage-info" }, [
            _vm.medItem.instructionInfo && _vm.medItem.instructionInfo.afternoon
              ? _c("div", { staticClass: "dosage-info-inner" }, [
                  _c("p", { staticClass: "mb-1 med-unit" }, [
                    _vm._v(_vm._s(_vm.medDosage))
                  ]),
                  _c("p", {}, [_vm._v(_vm._s(_vm.medDosageUnit))])
                ])
              : _c("div", [_vm._v(" ")])
          ]),
          _c("div", { staticClass: "dosage-info" }, [
            _vm.medItem.instructionInfo && _vm.medItem.instructionInfo.evening
              ? _c("div", { staticClass: "dosage-info-inner" }, [
                  _c("p", { staticClass: "mb-1 med-unit" }, [
                    _vm._v(_vm._s(_vm.medDosage))
                  ]),
                  _c("p", {}, [_vm._v(_vm._s(_vm.medDosageUnit))])
                ])
              : _c("div", [_vm._v(" ")])
          ]),
          _c("div", { staticClass: "dosage-info" }, [
            _vm.medItem.instructionInfo && _vm.medItem.instructionInfo.bedtime
              ? _c("div", { staticClass: "dosage-info-inner" }, [
                  _c("p", { staticClass: "mb-1 med-unit" }, [
                    _vm._v(_vm._s(_vm.medDosage))
                  ]),
                  _c("p", {}, [_vm._v(_vm._s(_vm.medDosageUnit))])
                ])
              : _c("div", [_vm._v(" ")])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }