var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "fluid-container" }, [
    _c("h3", { staticClass: "mb-5 mt-3 text-center text-uppercase" }, [
      _vm._v(
        "\n    " + _vm._s(_vm.$t("occupationalService.printNoteTitle")) + "\n  "
      )
    ]),
    _c("div", { staticClass: "h3 mb-4" }, [
      _vm._v(_vm._s(_vm.$t("occupationalService.consultInfo")))
    ]),
    _c("div", { staticClass: "d-flex flex-wrap mb-4" }, [
      _c("div", { staticClass: "w-50 mb-2" }, [
        _c("div", { staticClass: "row m-0 p-0" }, [
          _c("div", { staticClass: "col-4 form-label pl-0" }, [
            _vm._v(_vm._s(_vm.$t("occupationalService.startConsultTime")) + ":")
          ]),
          _c("div", { staticClass: "col-8 form-value" }, [
            _vm._v(
              _vm._s(
                _vm.queue.start_consult > 0
                  ? _vm.formatDate(_vm.queue.start_consult)
                  : ""
              )
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "w-50 mb-2" }, [
        _c("div", { staticClass: "row m-0 p-0" }, [
          _c("div", { staticClass: "col-4 form-label pl-0" }, [
            _vm._v(_vm._s(_vm.$t("occupationalService.endConsultTime")) + ":")
          ]),
          _c("div", { staticClass: "col-8 form-value" }, [
            _vm._v(
              _vm._s(
                _vm.formData.endConsultTime
                  ? _vm.formData.endConsultTime.answer
                  : ""
              )
            )
          ])
        ])
      ])
    ]),
    _c("div", { staticClass: "h3 mb-4" }, [
      _vm._v(_vm._s(_vm.$t("occupationalService.personalInfo")))
    ]),
    _c(
      "div",
      { staticClass: "d-flex flex-wrap mb-4" },
      [
        _vm._l(_vm.readonlyFormData, function(spec) {
          return [
            _vm.selectedPatient[spec.key]
              ? _c("div", { key: spec.key, staticClass: "w-50 mb-2" }, [
                  _c("div", { staticClass: "row m-0 p-0" }, [
                    _c("div", { staticClass: "col-4 form-label pl-0" }, [
                      _vm._v(
                        _vm._s(_vm.$t("occupationalService." + spec.label)) +
                          ":"
                      )
                    ]),
                    _c("div", { staticClass: "col-8 form-value" }, [
                      _vm._v(_vm._s(_vm.selectedPatient[spec.key]))
                    ])
                  ])
                ])
              : _vm._e()
          ]
        })
      ],
      2
    ),
    _c("div", { staticClass: "h3 mb-4" }, [
      _vm._v(_vm._s(_vm.$t("occupationalService.noteContent")))
    ]),
    _c("div", { staticClass: "consult-tempalte-wrap d-flex flex-wrap mb-4" }, [
      _vm.formData.medicalHistory
        ? _c("div", { staticClass: "w-50 consult-template-item mb-2" }, [
            _c("div", { staticClass: "consult-question mr-5" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t(
                      "occupationalService." + _vm.formData.medicalHistory.label
                    )
                  ) +
                  "\n      "
              )
            ]),
            _c("div", {
              staticClass: "consult-answer",
              domProps: {
                innerHTML: _vm._s(
                  _vm.getValueOrEmptySign(_vm.formData.medicalHistory.answer)
                )
              }
            })
          ])
        : _vm._e(),
      _vm.formData.foodAllergyHistory
        ? _c("div", { staticClass: "w-50 consult-template-item mb-2" }, [
            _c("div", { staticClass: "consult-question" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t(
                      "occupationalService." +
                        _vm.formData.foodAllergyHistory.label
                    )
                  ) +
                  "\n      "
              )
            ]),
            _c("div", {
              staticClass: "consult-answer",
              domProps: {
                innerHTML: _vm._s(
                  _vm.getValueOrEmptySign(
                    _vm.formData.foodAllergyHistory.answer
                  )
                )
              }
            })
          ])
        : _vm._e(),
      _vm.formData.drugAllergyHistory
        ? _c("div", { staticClass: "w-50 consult-template-item mb-2" }, [
            _c("div", { staticClass: "consult-question mr-5" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t(
                      "occupationalService." +
                        _vm.formData.drugAllergyHistory.label
                    )
                  ) +
                  "\n      "
              )
            ]),
            _c("div", {
              staticClass: "consult-answer",
              domProps: {
                innerHTML: _vm._s(
                  _vm.getValueOrEmptySign(
                    _vm.formData.drugAllergyHistory.answer
                  )
                )
              }
            })
          ])
        : _vm._e(),
      _vm.formData.reason
        ? _c("div", { staticClass: "w-50 consult-template-item mb-2" }, [
            _c("div", { staticClass: "consult-question" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t("occupationalService." + _vm.formData.reason.label)
                  ) +
                  "\n      "
              )
            ]),
            _c("div", {
              staticClass: "consult-answer",
              domProps: {
                innerHTML: _vm._s(
                  _vm.getOptionText("reason", _vm.formData.reason.answer)
                )
              }
            })
          ])
        : _vm._e(),
      _vm.formData.type
        ? _c("div", { staticClass: "w-50 consult-template-item mb-2" }, [
            _c("div", { staticClass: "consult-question mr-5" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t("occupationalService." + _vm.formData.type.label)
                  ) +
                  "\n      "
              )
            ]),
            _c("div", {
              staticClass: "consult-answer",
              domProps: {
                innerHTML: _vm._s(
                  _vm.getOptionText("type", _vm.formData.type.answer)
                )
              }
            })
          ])
        : _vm._e(),
      _vm.formData.advice
        ? _c("div", { staticClass: "w-50 consult-template-item mb-2" }, [
            _c("div", { staticClass: "consult-question" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t("occupationalService." + _vm.formData.advice.label)
                  ) +
                  "\n      "
              )
            ]),
            _c("div", {
              staticClass: "consult-answer",
              domProps: {
                innerHTML: _vm._s(
                  _vm.getValueOrEmptySign(_vm.formData.advice.answer)
                )
              }
            })
          ])
        : _vm._e()
    ]),
    _c("div", { staticClass: "h3 mb-4" }, [
      _vm._v(_vm._s(_vm.$t("occupationalService.education")))
    ]),
    _c("div", { staticClass: "consult-tempalte-wrap d-flex flex-wrap mb-4" }, [
      _vm.formData.educationFood
        ? _c("div", { staticClass: "w-100 consult-template-item mb-2" }, [
            _c("div", { staticClass: "consult-question" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t(
                      "occupationalService." + _vm.formData.educationFood.label
                    )
                  ) +
                  "\n      "
              )
            ]),
            _c(
              "div",
              { staticClass: "consult-answer" },
              [
                _vm._l(_vm.getOption("educationFood"), function(option) {
                  return [
                    _c(
                      "span",
                      { key: option.value, staticClass: "mr-2" },
                      [
                        _vm.formData.educationFood.answer.includes(option.value)
                          ? _c("v-icon", { attrs: { name: "square" } })
                          : _c("v-icon", { attrs: { name: "regular/square" } }),
                        _vm._v(
                          "\n             " +
                            _vm._s(option.text) +
                            "\n            "
                        ),
                        _vm.formData.educationFood.answer.includes("other") &&
                        option.value == "other"
                          ? [
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    ": " +
                                      _vm.formatPreLine(
                                        _vm.formData.educationFoodNote.answer
                                      )
                                  )
                                }
                              })
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  ]
                })
              ],
              2
            )
          ])
        : _vm._e(),
      _vm.formData.educationDisease
        ? _c("div", { staticClass: "w-100 consult-template-item mb-2" }, [
            _c("div", { staticClass: "consult-question" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t(
                      "occupationalService." +
                        _vm.formData.educationDisease.label
                    )
                  ) +
                  "\n      "
              )
            ]),
            _c(
              "div",
              { staticClass: "consult-answer" },
              [
                _vm._l(_vm.getOption("educationDisease"), function(option) {
                  return [
                    _c(
                      "span",
                      { key: option.value, staticClass: "mr-2" },
                      [
                        _vm.formData.educationDisease.answer.includes(
                          option.value
                        )
                          ? _c("v-icon", { attrs: { name: "square" } })
                          : _c("v-icon", { attrs: { name: "regular/square" } }),
                        _vm._v(
                          "\n             " +
                            _vm._s(option.text) +
                            "\n            "
                        ),
                        _vm.formData.educationDisease.answer.includes(
                          "other"
                        ) && option.value == "other"
                          ? [
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    ": " +
                                      _vm.formatPreLine(
                                        _vm.formData.educationDiseaseNote.answer
                                      )
                                  )
                                }
                              })
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  ]
                })
              ],
              2
            )
          ])
        : _vm._e(),
      _vm.formData.educationHabit
        ? _c("div", { staticClass: "w-100 consult-template-item mb-2" }, [
            _c("div", { staticClass: "consult-question" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t(
                      "occupationalService." + _vm.formData.educationHabit.label
                    )
                  ) +
                  "\n      "
              )
            ]),
            _c(
              "div",
              { staticClass: "consult-answer" },
              [
                _vm._l(_vm.getOption("educationHabit"), function(option) {
                  return [
                    _c(
                      "span",
                      { key: option.value, staticClass: "mr-2" },
                      [
                        _vm.formData.educationHabit.answer.includes(
                          option.value
                        )
                          ? _c("v-icon", { attrs: { name: "square" } })
                          : _c("v-icon", { attrs: { name: "regular/square" } }),
                        _vm._v(
                          "\n             " +
                            _vm._s(option.text) +
                            "\n            "
                        ),
                        _vm.formData.educationHabit.answer.includes("other") &&
                        option.value == "other"
                          ? [
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    ": " +
                                      _vm.formatPreLine(
                                        _vm.formData.educationHabitNote.answer
                                      )
                                  )
                                }
                              })
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  ]
                })
              ],
              2
            )
          ])
        : _vm._e(),
      _vm.formData.educationSpecial
        ? _c("div", { staticClass: "w-100 consult-template-item mb-2" }, [
            _c("div", { staticClass: "consult-question" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t(
                      "occupationalService." +
                        _vm.formData.educationSpecial.label
                    )
                  ) +
                  "\n      "
              )
            ]),
            _c(
              "div",
              { staticClass: "consult-answer" },
              [
                _vm._l(_vm.getOption("educationSpecial"), function(option) {
                  return [
                    _c(
                      "span",
                      { key: option.value, staticClass: "mr-2" },
                      [
                        _vm.formData.educationSpecial.answer.includes(
                          option.value
                        )
                          ? _c("v-icon", { attrs: { name: "square" } })
                          : _c("v-icon", { attrs: { name: "regular/square" } }),
                        _vm._v(
                          "\n             " +
                            _vm._s(option.text) +
                            "\n            "
                        ),
                        _vm.formData.educationSpecial.answer.includes(
                          "other"
                        ) && option.value == "other"
                          ? [
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    ": " +
                                      _vm.formatPreLine(
                                        _vm.formData.educationSpecialNote.answer
                                      )
                                  )
                                }
                              })
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  ]
                })
              ],
              2
            )
          ])
        : _vm._e()
    ]),
    _c("div", { staticClass: "h3 mb-4" }, [
      _vm._v(_vm._s(_vm.$t("occupationalService.trackStatus")))
    ]),
    _c(
      "div",
      { staticClass: "mb-5" },
      [
        _vm._l(_vm.getOption("track"), function(option) {
          return [
            _c(
              "span",
              { key: option.value, staticClass: "mr-2" },
              [
                _vm.formData.track.answer.includes(option.value)
                  ? _c("v-icon", { attrs: { name: "square" } })
                  : _c("v-icon", { attrs: { name: "regular/square" } }),
                _vm._v("\n         " + _vm._s(option.text) + "\n        "),
                _vm.formData.track.answer == "track" && option.value == "track"
                  ? [
                      _vm._v(
                        "\n          : " +
                          _vm._s(_vm.formData.trackDate.answer) +
                          "\n        "
                      )
                    ]
                  : _vm._e()
              ],
              2
            )
          ]
        })
      ],
      2
    ),
    _c("div", { staticClass: "mb-5 row med-footer" }, [
      _c("div", { staticClass: "col-4 form-label" }, [
        _c("div", { staticClass: "footer-item" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("occupationalService.nurseSignature")) +
              ":\n      "
          )
        ])
      ]),
      _c("div", { staticClass: "col-4 form-label" }, [
        _c("div", { staticClass: "footer-item" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("occupationalService.doctorSignature")) +
              ":\n      "
          )
        ])
      ]),
      _c("div", { staticClass: "col-4 form-label" }, [
        _c("div", { staticClass: "footer-item" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("occupationalService.patientSignature")) +
              ":\n      "
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }