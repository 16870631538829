<template>
  <div class="container mb-5" :class="{'previewPrint': preview}">
    <prescription-form-item-print
      v-if="classifiedItems.length > 0"
      :patientConfig="patientConfig"
      :generalConfig="generalConfig"
      :selectedPrescription="selectedPrescription"
      :items="classifiedItems"
      type="classified"
    />
    <prescription-form-item-print
      v-if="normalItems.length > 0"
      :patientConfig="patientConfig"
      :generalConfig="generalConfig"
      :selectedPrescription="selectedPrescription"
      :items="normalItems"
      type="normal"
    />
  </div>
</template>
<script>
import moment from 'moment';
import PrescriptionFormItemPrint from './PrescriptionFormItemPrint.vue'

export default {
  name: 'PrescriptionFormPrint',
  components: {
    PrescriptionFormItemPrint,
  },
  props: {
    generalConfig: {
      type: Object,
      default: () => ({}),
    },
    patientConfig: {
      type: Object,
      default: () => ({}),
    },
    selectedPrescription: {
      type: Object,
      default: () => ({}),
    },
    preview: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      classifiedItems: [],
      normalItems: []
    };
  },
  mounted() {
    this.distinguishMedicineCategory()
    if (!this.preview) {
      setTimeout(() => {
        window.print();
      }, 2000);
    }
  },
  methods: {
    distinguishMedicineCategory(){
      const vm = this;
      const allItems = [...vm.selectedPrescription.items];
      const medicineItems = allItems.filter(item=>{
        return item.inventory === 'Medicine';
      })
      if(medicineItems && medicineItems.length > 0) {
        medicineItems.forEach(item => {
          if(item.drugClassification === 'CLASSIFIED') {
            vm.classifiedItems.push(item)
          } else {
            vm.normalItems.push(item)
          }
        })
      }
    }
  }
};
</script>
<style scoped src="../../assets/print.css"></style>
<style lang="scss" scoped>
  .previewPrint {
    background-color: white;
    font-size: 0.1em !important;
    padding: 20px;
    padding-left: 35px;
    min-height: 60em;
    margin-bottom: 0px !important;
  }
  
</style>

<style lang="scss">
  @media print{
    @page {
      size: A5;
      margin: 0;
    }
    .container {
      padding-top: 30px !important;
      padding-right: 240px !important;
      font-size: 14px !important;
    }
  } 
</style>
