export const DISPENSE_ITEM = 'DISPENSE_ITEM';
export const SET_DISPENSE_ITEM = 'SET_DISPENSE_ITEM';
export const UPDATE_ITEM = 'UPDATE_ITEM';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const SET_DISCHARGE_FORM = 'SET_DISCHARGE_FORM';
export const SET_ORDER_DISPENSE = 'SET_ORDER_DISPENSE';
export const SET_DISCHARGE_PHARMACY = 'SET_DISCHARGE_PHARMACY';
export const ADD_DISCHARGE_PHARMACY = 'ADD_DISCHARGE_PHARMACY';
export const CLEAR_DISPENSE_MEDICINES = 'CLEAR_DISPENSE_MEDICINES';
export const UPDATE_PHARMACY_ITEM = 'UPDATE_PHARMACY_ITEM';
export const SET_PRINT = 'SET_PRINT';
