<template>
  <div class="printable-herbal-medicine-order">
    <div class="printable-herbal-medicine-order-info">
      <div>
        <div>
          <div><h5>顾客联-凭单取药</h5></div>
          <div>Fax to 6223-9365 黄耀南牛车水（有代煎服务）</div>
          <div>Tel：6220-8334</div>
          <div>传真日期：{{ today | formatDate }}</div>
          <div>门诊助理：{{ creatorName | capitalize }}</div>
          <!-- TODO -->
          <div>Herbal Fee：</div>
          <div>代客煎药费：</div>
          <div>药材总包数：</div>
          <div><b>请盖马光店章</b></div>
          <br />
        </div>
        <hr />
        <div>
          <div><h5>顾客资料</h5></div>
          <div>Name：{{ patientName }}</div>
          <div>姓名：{{ patientLocalName }}</div>
          <div>NRIC：{{ patientNRIC }}</div>
          <div>手机：{{ patientPhoneNumber }}</div>
          <div>电话：{{ patientTelephone }}</div>
        </div>
        <hr />
        <div>
          <div><h5>其他</h5></div>
          <div><checked-flag :checked="decoctByFactory" /><b>代客煎药（只限顾客到黄耀南牛车水自取，不提供送货服务）：</b></div>
          <div>1 包药材包=2 包药液包，共 {{ totalLiquidPacks }} 药液包：</div>
          <div>贵重药材处理方式：</div>
          <div class="horizontal-wrapper">
            <div>提早切片：<checked-flag :checked="valuable === 'SLICE_IN_ADVANCE'" /></div>
            <div>现切片：<checked-flag :checked="valuable === 'SLICE_ON_SITE'" /></div>
            <div>提早磨粉：<checked-flag :checked="valuable === 'GRIND_IN_ADVANCE'" /></div>
            <div>现磨：<checked-flag :checked="valuable === 'GRIND_ON_SITE'" /></div>
          </div>
        </div>
        <hr />
        <div>
          <div><h5>取药方式</h5></div>
          <div><checked-flag :checked="takingBy === 'BY_PATIENT'" /><b>A.顾客自行凭此单取药</b></div>
          <div><checked-flag :checked="takingBy === 'BY_FACTORY'" /><b>B.送货 Delivery Order</b></div>
          <div>Delivery Date：{{ deliveryDate | formatDate }}</div>
          <div><b>Delivery Time</b></div>
          <div class="horizontal-wrapper">
            <div>9am-12noon：<checked-flag :checked="deliveryTime === 'NINE_TO_TWELVE'" /></div>
            <div>12noon-5pm：<checked-flag :checked="deliveryTime === 'TWELVE_TO_FIVE'" /></div>
            <div>5pm-7pm：<checked-flag :checked="deliveryTime === 'FIVE_TO_SEVEN'" /></div>
          </div>
          <div><b>Delivery Address</b></div>
          <div>
            <checked-flag :checked="!deliveryToPatientAddress" />
            送马光分院：{{ !deliveryToPatientAddress ? deliveryAddress : '' }}
          </div>
          <div>
            <checked-flag :checked="deliveryToPatientAddress" />
            顾客指定地址：{{ deliveryToPatientAddress ? deliveryAddress : '' }}
          </div>
          <div>
            Postal Code：{{ deliveryPostalCode }}
          </div>
          <div>
            <b>顾客签字确认：</b>
            <br />
            <img :src="patientSig" style="height: 50px;" />
          </div>
        </div>
        <div>
          <div><h5>医师填写</h5></div>
          <div><b>自行煮药者煎法</b></div>
          <div>头煎（第一次煎）： {{ decoctFirstWaterCount }} 碗水，煎成 {{ decoctFirstBowlCount }} 碗</div>
          <div>二煎（第二次煎）： {{ decoctSecondWaterCount }} 碗水，煎成 {{ decoctSecondBowlCount }} 碗</div>
          <div><b>服法（每项选其一）</b></div>
          <div>
            <checked-flag :checked="instructionOne === 'BEFORE_MEAL'" />饭前
            /
            <checked-flag :checked="instructionOne === 'AFTER_MEAL'" />饭后
            /
            <checked-flag :checked="instructionOne === 'BEFORE_SLEEP'" />睡前
          </div>
          <div>
            <checked-flag :checked="instructionTwo === 'BID'" />早晚 2 次
            /
            <checked-flag :checked="instructionTwo === 'QD_MORNING'" />一天一次（早上）
            /
            <checked-flag :checked="instructionTwo === 'QD_NIGHT'" />一天一次（晚上）
          </div>
        </div>
      </div>
      <div>
        <img :src="WYNNote" class="wyn-note" />
      </div>
    </div>
    <div class="footer" />
    <img :src="WYNMap" class="wyn-map" />
    <img :src="WYNNotice" class="wyn-notice" />
  </div>
</template>
<script>
import { herbalMedicineOrderInformationComputedGetter } from '@/components/TCMConsultation/utils';
import Dateformatter from '@/components/mixins/dateformatter';

const CheckedFlag = ({ props: { checked } }) => (
    <div style="display: inline-block; position: relative; margin: 0px 5px 0 0px;">
      <span>&#x2610;</span>
      { checked && <span style="position: absolute; left: 1px;">&#x2713;</span> }
    </div>
);

export default {
  name: 'PrintableHerbalMedicineOrder',
  mixins: [Dateformatter],
  components: {
    CheckedFlag,
  },
  computed: {
    ...herbalMedicineOrderInformationComputedGetter(),
  },
  mounted() {
    window.print();
  },
};
</script>
<style scoped src="../../../assets/print.css"></style>
<style scoped lang="scss">
.printable-herbal-medicine-order {
  width: 210mm;
  height: 147mm;
  position: relative;

  &-info {
    display: grid;
    grid-template-columns: 5fr 5fr;
  }
}

.wyn {
  &-map {
    width: 100%;
  }

  &-notice {
    width: 100%;
  }

  &-note {
    width: 100%;
  }
}

@page {
  size: 210mm 147mm;
}

@media print {
  body {
    width: 148mm;
  }

  .printable-herbal-medicine-order {
    font-size: 3mm!important;
    margin-left: 0mm;
    margin-right: 0mm;
  }

  .horizontal-wrapper {
    display: flex;
    width: 100%;

    & > * {
      flex: 1;
    }
  }

  .footer {
    page-break-after: always;
  }
}
</style>
