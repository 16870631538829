var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mb-5",
      class: { previewPrint: _vm.preview },
      staticStyle: { margin: "0 20px" },
      attrs: { id: "individual-corp-invoice" }
    },
    [
      _c("div", { staticClass: "row mb-3" }, [
        _c("div", { staticClass: "col-12" }, [
          _vm.invoiceConfigCorp.clinicLogo
            ? _c("img", {
                staticClass: "clinic-logo",
                attrs: { src: this.companyClinicLogo }
              })
            : _vm._e(),
          _c("h5", [_vm._v(_vm._s(this.companyClinicName))]),
          _vm.invoiceConfigCorp.clinicLocalName
            ? _c("h6", { staticClass: "mt-2" }, [
                _vm._v(_vm._s(this.companyClinicLocalName))
              ])
            : _vm._e(),
          _vm.invoiceConfigCorp.clinicAdd
            ? _c(
                "p",
                {
                  staticClass: "mb-1 address-font",
                  staticStyle: { "white-space": "pre-line" }
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.corpInvoiceAddress) +
                      "\n          "
                  )
                ]
              )
            : _vm._e(),
          _vm.invoiceConfigCorp.clinicLocalAddress
            ? _c(
                "p",
                {
                  staticClass: "mb-3 address-font",
                  staticStyle: {
                    "white-space": "pre-line",
                    "line-height": ".5rem"
                  }
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.corpInvoiceLocalAddress) +
                      "\n          "
                  )
                ]
              )
            : _vm._e(),
          _vm.generalConfig.telephone
            ? _c("p", { staticClass: "mb-3 address-font" }, [
                _vm._v("Tel: " + _vm._s(_vm.generalConfig.telephone))
              ])
            : _vm._e(),
          _c("h3", { staticClass: "mb-3 text-center text-uppercase" }, [
            _c("u", [
              _vm._v(
                "\n              " +
                  _vm._s(_vm.$t("general.corporateInvoice")) +
                  "\n            "
              )
            ])
          ])
        ])
      ]),
      _vm._m(0),
      _c("div", { staticClass: "row mb-5" }, [
        _c("div", { staticClass: "col-8" }, [
          _vm.invoiceConfigCorp.companyName
            ? _c("div", { staticClass: "clinic-name font-weight-bold" }, [
                _c("span", { staticClass: "font-weight-bold" }, [
                  _vm._v("Company Name:")
                ]),
                _vm._v("  \n                "),
                _c("span", [_vm._v(_vm._s(_vm.corporate.name))])
              ])
            : _vm._e(),
          _vm.invoiceConfigCorp.companyAddress
            ? _c("div", { staticClass: "clinic-address font-weight-bold" }, [
                _c("span", { staticClass: "font-weight-bold" }, [
                  _vm._v("Company Address:")
                ]),
                _vm._v("  \n                "),
                _c("span", [_vm._v(_vm._s(_vm.corporate.address))])
              ])
            : _vm._e(),
          _c("div", [
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v("Patient Name:")
            ]),
            _vm._v("  \n                "),
            _c("span", [
              _vm._v(
                _vm._s(_vm.patient.name) + " - " + _vm._s(_vm.patient.givenId)
              )
            ])
          ]),
          _vm.printConfig.patientLocalName
            ? _c("div", [
                _c("span", { staticClass: "font-weight-bold" }, [
                  _vm._v("Patient Local Name:")
                ]),
                _vm._v("  \n                "),
                _c("span", [_vm._v(_vm._s(_vm.patient.localName))])
              ])
            : _vm._e(),
          _vm.invoiceConfigCorp.patientAdd
            ? _c("div", [
                _c("span", { staticClass: "font-weight-bold" }, [
                  _vm._v(" Address:")
                ]),
                _vm._v("  \n                "),
                _c("span", [_vm._v(_vm._s(_vm.patient.address))])
              ])
            : _vm._e()
        ]),
        _c("div", { staticClass: "col-4" }, [
          _c("div", { staticClass: "tax-invoice-no" }, [
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v("Tax Invoice No:")
            ]),
            _vm._v("  \n                  "),
            _c("span", [_vm._v(_vm._s(_vm.invoice.invoiceNo))])
          ]),
          _c("div", { staticClass: "visit date" }, [
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v("Visit Date:")
            ]),
            _vm._v("  \n                  "),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm._f("formatVDDate")(_vm.invoice.date, {
                    sourceFormat: "X",
                    lang: _vm.$i18n.locale,
                    format: "DD MMM YYYY"
                  })
                )
              )
            ])
          ]),
          _vm.invoiceConfigCorp.invoiceDateBE
            ? _c("div", { staticClass: "visit date" }, [
                _c("span", { staticClass: "font-weight-bold" }, [
                  _vm._v(_vm._s(_vm.$t("setting.visitDateBE")) + ":")
                ]),
                _vm._v("  \n                  "),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm._f("formatVDDate")(_vm.invoice.date, {
                        sourceFormat: "X",
                        lang: "th",
                        format: "DD MMM YYYY"
                      })
                    )
                  )
                ])
              ])
            : _vm._e(),
          _c("div", { staticClass: "acra" }, [
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v("ACRA No:")
            ]),
            _vm._v("  \n                  "),
            _c("span", [_vm._v(_vm._s(_vm.companyACRANo))])
          ]),
          _c("div", { staticClass: "tax-reg-no" }, [
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v("Tax Reg No:")
            ]),
            _vm._v("  \n                  "),
            _c("span", [_vm._v(_vm._s(_vm.companyTaxRegNo))])
          ]),
          _vm.invoiceConfigCorp.docName
            ? _c("div", { staticClass: "attending-doctor" }, [
                _c("span", { staticClass: "font-weight-bold" }, [
                  _vm._v("Attending Doctor:")
                ]),
                _vm._v("  \n                  "),
                _vm.providersMappedById[_vm.invoice.provider]
                  ? _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.providersMappedById[_vm.invoice.provider].name
                        )
                      )
                    ])
                  : _vm._e()
              ])
            : _vm._e(),
          _vm.invoiceConfigCorp.docQual
            ? _c("div", { staticClass: "qualifications" }, [
                _c("span", { staticClass: "font-weight-bold" }, [
                  _vm._v("Qualifications:")
                ]),
                _vm._v("  \n                  "),
                _vm.providersMappedById[_vm.invoice.provider]
                  ? _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.providersMappedById[_vm.invoice.provider]
                            .qualifications
                        )
                      )
                    ])
                  : _vm._e()
              ])
            : _vm._e(),
          _vm.invoiceConfigCorp.therapistName
            ? _c("div", { staticClass: "attending-therapist" }, [
                _c("span", { staticClass: "font-weight-bold" }, [
                  _vm._v("Attending Therapist:")
                ]),
                _vm._v("  \n                  "),
                _vm.providersMappedById[_vm.invoice.therapist]
                  ? _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.providersMappedById[_vm.invoice.therapist].name
                        )
                      )
                    ])
                  : _vm._e()
              ])
            : _vm._e()
        ])
      ]),
      _c("table", { staticClass: "table table-sm invoice-table" }, [
        _c("thead", { staticClass: "thead-dark" }, [
          _c("tr", [
            _c("th", { staticClass: "border" }, [_vm._v("Item")]),
            _c("th", { staticClass: "border" }, [_vm._v("Description")]),
            _c("th", { staticClass: "border" }, [_vm._v("Qty")]),
            _c("th", { staticClass: "border" }, [_vm._v("Unit Cost")]),
            _c("th", { staticClass: "border" }, [_vm._v("Discount")]),
            _c("th", { staticClass: "border" }, [
              _vm._v(
                "Subtotal (excluding " +
                  _vm._s(_vm.invoice.taxName || "GST") +
                  ")"
              )
            ])
          ])
        ]),
        _c(
          "tbody",
          [
            _vm._l(_vm.invoice.items, function(item) {
              return _c("tr", { key: item._id }, [
                _c("td", { staticClass: "border" }, [
                  _vm._v(_vm._s(item.name))
                ]),
                _c("td", { staticClass: "border" }, [
                  _vm._v(_vm._s(item.description))
                ]),
                _c("td", { staticClass: "border" }, [_vm._v(_vm._s(item.qty))]),
                _c("td", { staticClass: "border" }, [
                  _vm._v(
                    _vm._s(_vm.generalConfig.region.currency) +
                      _vm._s(item.price)
                  )
                ]),
                item.discountType === "%"
                  ? _c("td", { staticClass: "border" }, [
                      _vm._v(
                        "\n                              " +
                          _vm._s(item.discount) +
                          "%\n                          "
                      )
                    ])
                  : _c("td", { staticClass: "border" }, [
                      _vm._v(_vm._s(item.discountAmount))
                    ]),
                _c("td", { staticClass: "border" }, [
                  _vm._v(
                    _vm._s(_vm.generalConfig.region.currency) +
                      _vm._s(item.afterDiscountPrice.toFixed(2))
                  )
                ])
              ])
            }),
            _vm.invoice.totalTax > 0
              ? _c("tr", [
                  _c("td", {
                    staticClass: "no-border",
                    attrs: { colspan: "4" }
                  }),
                  _c("td", { staticClass: "border" }, [
                    _vm._v(_vm._s(_vm.invoice.taxName || "GST"))
                  ]),
                  _c("td", { staticClass: "border" }, [
                    _vm._v(
                      _vm._s(_vm.generalConfig.region.currency) +
                        _vm._s(_vm.invoice.totalTax)
                    )
                  ])
                ])
              : _vm._e(),
            _c("tr", [
              _c("td", { staticClass: "no-border", attrs: { colspan: "4" } }),
              _c("td", { staticClass: "border bg-light" }, [_vm._v("Total")]),
              _c("td", { staticClass: "border bg-light" }, [
                _vm._v(
                  _vm._s(_vm.generalConfig.region.currency) +
                    _vm._s(_vm.invoice.total)
                )
              ])
            ]),
            _c("tr", [
              _c("td", { staticClass: "no-border", attrs: { colspan: "4" } }),
              _c("td", { staticClass: "border bg-light" }, [
                _vm._v("PatientPayment")
              ]),
              _c("td", { staticClass: "border bg-light" }, [
                _vm._v(
                  _vm._s(_vm.generalConfig.region.currency) +
                    _vm._s(_vm.patientPayment)
                )
              ])
            ])
          ],
          2
        )
      ]),
      _c("table", { staticClass: "table table-sm" }, [
        _vm._m(1),
        _c(
          "tbody",
          _vm._l(_vm.settlement.corpSettlement, function(settle, index) {
            return _c("tr", { key: index }, [
              _c(
                "td",
                {
                  staticClass: "border",
                  attrs: { colspan: "2", width: "1000" }
                },
                [
                  _vm._v(
                    "\n                              " +
                      _vm._s(settle.collectionMode) +
                      "\n                              (" +
                      _vm._s(_vm._f("ddMonthYYYYFromX")(settle.timestamp)) +
                      ")\n                          "
                  )
                ]
              ),
              _c("td", { staticClass: "border" }, [
                _vm._v(
                  "\n                              " +
                    _vm._s(_vm.generalConfig.region.currency) +
                    _vm._s(settle.recordedAmount) +
                    "\n                          "
                )
              ]),
              _c("td", { staticClass: "border" }, [
                _vm._v(
                  "\n                              " +
                    _vm._s(_vm.generalConfig.region.currency) +
                    _vm._s(settle.batchAmount) +
                    "\n                          "
                )
              ])
            ])
          }),
          0
        ),
        _c("tfoot", [
          _c("tr", [
            _c("td", { staticClass: "no-border" }, [_vm._v(" ")]),
            _c("td", { staticClass: "no-border" }, [_vm._v(" ")]),
            _c(
              "td",
              { staticClass: "border bg-light", attrs: { width: "100px" } },
              [_vm._v("Total")]
            ),
            _c("td", { staticClass: "border bg-light" }, [
              _vm._v(
                "\n                              " +
                  _vm._s(_vm.generalConfig.region.currency) +
                  _vm._s(_vm.settlement.corpSettlementTotal) +
                  "\n                          "
              )
            ])
          ]),
          _c("tr", [
            _c("td", { staticClass: "no-border" }, [_vm._v(" ")]),
            _c("td", { staticClass: "no-border" }, [_vm._v(" ")]),
            _c("td", { staticClass: "border bg-light" }, [
              _vm._v("Outstanding")
            ]),
            _c("td", { staticClass: "border text-danger bg-light" }, [
              _vm._v(
                "\n                              " +
                  _vm._s(_vm.generalConfig.region.currency) +
                  _vm._s(_vm.settlement.corporateOutstanding) +
                  "\n                          "
              )
            ])
          ])
        ])
      ]),
      _c("footer", { staticClass: "footer" }, [
        _c("div", [
          _vm._m(2),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _vm.invoiceConfigCorp.notes
                  ? [
                      _c("div", { staticClass: "weight-600" }, [
                        _vm._v(" " + _vm._s(_vm.$t("general.notes")) + ": ")
                      ]),
                      _c("p", { staticStyle: { "white-space": "pre-line" } }, [
                        _vm._v(" " + _vm._s(_vm.invoice.notes) + " ")
                      ])
                    ]
                  : _vm._e(),
                _vm.invoiceConfigCorp.footer
                  ? _c("p", { staticStyle: { "white-space": "pre-line" } }, [
                      _vm._v(
                        "\n                  " +
                          _vm._s(_vm.invoiceConfigCorp.footerContent) +
                          "\n                "
                      )
                    ])
                  : _vm._e()
              ],
              2
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row mb-3" }, [
      _c("div", { staticClass: "col" }, [
        _c("h6", { staticClass: "bill-to" }, [_vm._v("Bill To / Address:")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticClass: "thead-light" }, [
      _c("tr", [
        _c("th", { staticClass: "border", attrs: { colspan: "2" } }, [
          _vm._v("Payment Mode:")
        ]),
        _c("th", { staticClass: "border" }, [_vm._v("Batch Amount")]),
        _c("th", { staticClass: "border" }, [_vm._v("Allocated Amount")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c("h6", [_vm._v("Remarks:")]),
        _c("p", { staticClass: "text-secondary" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }