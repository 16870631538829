var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.formGroups, function(group) {
      return _c(
        "div",
        {
          key: group,
          staticClass: "form-group row",
          class: _vm.formSchema[group].containerClass,
          attrs: { "data-tik": _vm.tik }
        },
        [
          _vm.isFreetext(_vm.formSchema[group].type)
            ? _c(
                "label",
                {
                  staticClass: "col-sm-12",
                  class: _vm.formSchema[group].labelClass
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.formSchema[group].i18nId
                          ? _vm.$t(_vm.formSchema[group].i18nId)
                          : _vm.formSchema[group].label
                      ) +
                      "\n    "
                  )
                ]
              )
            : _vm._e(),
          !_vm.isFreetext(_vm.formSchema[group].type)
            ? _c(
                "label",
                {
                  staticClass: "col-sm-12",
                  class: [
                    { multiline: _vm.formSchema[group].multiline },
                    _vm.formSchema[group].labelClass
                  ]
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.formSchema[group].i18nId
                          ? _vm.$t(_vm.formSchema[group].i18nId)
                          : _vm.formSchema[group].label
                      ) +
                      "\n      "
                  ),
                  _vm.formSchema[group].required
                    ? _c(
                        "span",
                        { staticClass: "badge badge-pill badge-danger" },
                        [_vm._v(_vm._s(_vm.$t("general.required")))]
                      )
                    : _vm._e()
                ]
              )
            : _vm._e(),
          _vm.isInput(_vm.formSchema[group].type)
            ? _c("div", { staticClass: "col-sm-12" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData[group],
                      expression: "formData[group]"
                    }
                  ],
                  staticClass: "form-control",
                  class: _vm.formSchema[group].inputClass,
                  attrs: {
                    type: "text",
                    disabled: _vm.formSchema[group].disabled || false
                  },
                  domProps: { value: _vm.formData[group] },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.formData, group, $event.target.value)
                    }
                  }
                })
              ])
            : _vm._e(),
          _vm.isTextArea(_vm.formSchema[group].type)
            ? _c("div", { staticClass: "col-sm-12" }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData[group],
                      expression: "formData[group]"
                    }
                  ],
                  staticClass: "form-control",
                  class: _vm.formSchema[group].inputClass,
                  attrs: { disabled: _vm.formSchema[group].disabled || false },
                  domProps: { value: _vm.formData[group] },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.formData, group, $event.target.value)
                    }
                  }
                })
              ])
            : _vm._e(),
          _vm.isRichText(_vm.formSchema[group].type)
            ? _c(
                "div",
                { staticClass: "col-sm-12" },
                [
                  _c("rich-text-editor", {
                    attrs: {
                      qns: group,
                      html: _vm.formData[group],
                      updateTypingFormData: _vm.updateRichTextFormData
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.isSelect(_vm.formSchema[group].type)
            ? _c("div", { staticClass: "col-sm-12" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formData[group],
                        expression: "formData[group]"
                      }
                    ],
                    staticClass: "form-control",
                    class: _vm.formSchema[group].inputClass,
                    attrs: {
                      disabled: _vm.formSchema[group].disabled || false
                    },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.formData,
                          group,
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  _vm._l(_vm.formSchema[group].options, function(val, index) {
                    return _c(
                      "option",
                      { key: index, domProps: { value: val } },
                      [_vm._v(_vm._s(val))]
                    )
                  }),
                  0
                )
              ])
            : _vm._e(),
          _vm.isDateTime(_vm.formSchema[group].type)
            ? _c(
                "div",
                { staticClass: "col-sm-12" },
                [
                  _c("date-picker", {
                    attrs: {
                      inputClass: [
                        "form-control",
                        _vm.formSchema[group].inputClass
                      ],
                      disabled: _vm.formSchema[group].disabled || false
                    },
                    model: {
                      value: _vm.formData[group],
                      callback: function($$v) {
                        _vm.$set(_vm.formData, group, $$v)
                      },
                      expression: "formData[group]"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.isGroup(_vm.formSchema[group].type)
            ? _c(
                "div",
                { staticClass: "col-sm-12" },
                _vm._l(_vm.formSchema[group].groups, function(input, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "group_input col-sm-4",
                      class: _vm.formSchema[group].groupClass
                    },
                    [
                      _vm.isRadio(input.type)
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "custom-control custom-radio custom-control-inline",
                              class: input.groupClass
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.formData[group],
                                    expression: "formData[group]"
                                  }
                                ],
                                staticClass: "custom-control-input",
                                class: input.inputClass,
                                attrs: {
                                  type: "radio",
                                  id: _vm.loopUniqueId(
                                    _vm.formSchema[group].label,
                                    index
                                  ),
                                  name: _vm.formSchema[group].label,
                                  disabled: (input && input.disabled) || false
                                },
                                domProps: {
                                  value: input.value || "",
                                  checked: _vm._q(
                                    _vm.formData[group],
                                    input.value || ""
                                  )
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$set(
                                      _vm.formData,
                                      group,
                                      input.value || ""
                                    )
                                  }
                                }
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "custom-control-label",
                                  class: input.labelClass,
                                  attrs: {
                                    for: _vm.loopUniqueId(
                                      _vm.formSchema[group].label,
                                      index
                                    )
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        input.i18nId
                                          ? _vm.$t(input.i18nId)
                                          : input.label
                                      )
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.isCheckbox(input.type)
                        ? _c(
                            "div",
                            {
                              staticClass: "custom-control custom-checkbox",
                              class: input.groupClass
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.formData[group],
                                    expression: "formData[group]"
                                  }
                                ],
                                staticClass: "custom-control-input",
                                class: input.inputClass,
                                attrs: {
                                  type: "checkbox",
                                  id: _vm.loopUniqueId(input.label, index),
                                  disabled: (input && input.disabled) || false
                                },
                                domProps: {
                                  value: input.value || "",
                                  checked: Array.isArray(_vm.formData[group])
                                    ? _vm._i(
                                        _vm.formData[group],
                                        input.value || ""
                                      ) > -1
                                    : _vm.formData[group]
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.formData[group],
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = input.value || "",
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.formData,
                                            group,
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.formData,
                                            group,
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(_vm.formData, group, $$c)
                                    }
                                  }
                                }
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "custom-control-label",
                                  class: input.labelClass,
                                  attrs: {
                                    for: _vm.loopUniqueId(input.label, index)
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        input.i18nId
                                          ? _vm.$t(input.i18nId)
                                          : input.label
                                      )
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.isInput(input.type)
                        ? _c(
                            "div",
                            {
                              staticClass: "form-group",
                              class: input.groupClass
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "form-label",
                                  class: input.labelClass,
                                  attrs: {
                                    for: _vm.loopUniqueId(input.label, index)
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        input.i18nId
                                          ? _vm.$t(input.i18nId)
                                          : input.label
                                      )
                                  )
                                ]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.formData[input.model],
                                    expression: "formData[input.model]"
                                  }
                                ],
                                staticClass: "form-control",
                                class: input.inputClass,
                                attrs: {
                                  type: "text",
                                  placeholder: input.placeholder,
                                  disabled: (input && input.disabled) || false
                                },
                                domProps: { value: _vm.formData[input.model] },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.formData,
                                      input.model,
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          )
                        : _vm._e(),
                      _vm.isDateTime(input.type)
                        ? _c(
                            "div",
                            {
                              staticClass: "form-group",
                              class: input.groupClass
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "form-label",
                                  class: input.labelClass,
                                  attrs: {
                                    for: _vm.loopUniqueId(input.label, index)
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        input.i18nId
                                          ? _vm.$t(input.i18nId)
                                          : input.label
                                      )
                                  )
                                ]
                              ),
                              _c("date-picker", {
                                attrs: {
                                  inputClass: [
                                    "form-control",
                                    input.inputClass
                                  ],
                                  disabled: (input && input.disabled) || false
                                },
                                model: {
                                  value: _vm.formData[input.model],
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, input.model, $$v)
                                  },
                                  expression: "formData[input.model]"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isFreetext(input.type)
                        ? _c(
                            "div",
                            {
                              staticClass: "form-group",
                              class: input.groupClass
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "form-label",
                                  class: input.labelClass
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        input.i18nId
                                          ? _vm.$t(input.i18nId)
                                          : input.label
                                      ) +
                                      "\n              "
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _vm.isYesNoSpecify(_vm.formSchema[group].type)
            ? _c("div", { staticClass: "col-sm-12" }, [
                _c("div", { staticClass: "form-row align-items-center" }, [
                  _c("div", { staticClass: "col-1" }, [
                    _c("div", { staticClass: "custom-control custom-radio " }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formData[group],
                            expression: "formData[group]"
                          }
                        ],
                        staticClass: "custom-control-input",
                        class: _vm.formSchema[group].inputClass,
                        attrs: {
                          type: "radio",
                          id: _vm.formSchema[group].label + "Yes",
                          name: _vm.formSchema[group].label,
                          value: "yes"
                        },
                        domProps: {
                          checked: _vm._q(_vm.formData[group], "yes")
                        },
                        on: {
                          change: function($event) {
                            return _vm.$set(_vm.formData, group, "yes")
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "custom-control-label",
                          class: _vm.formSchema[group].labelClass,
                          attrs: { for: _vm.formSchema[group].label + "Yes" }
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("tcmhq.yes")) +
                              "\n            "
                          )
                        ]
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "col-1" }, [
                    _c("div", { staticClass: "custom-control custom-radio " }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formData[group],
                            expression: "formData[group]"
                          }
                        ],
                        staticClass: "custom-control-input",
                        class: _vm.formSchema[group].inputClass,
                        attrs: {
                          type: "radio",
                          id: _vm.formSchema[group].label + "No",
                          name: _vm.formSchema[group].label,
                          value: "no"
                        },
                        domProps: {
                          checked: _vm._q(_vm.formData[group], "no")
                        },
                        on: {
                          change: function($event) {
                            return _vm.$set(_vm.formData, group, "no")
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "custom-control-label",
                          class: _vm.formSchema[group].labelClass,
                          attrs: { for: _vm.formSchema[group].label + "No" }
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("tcmhq.no")) +
                              "\n            "
                          )
                        ]
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "col" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "form-label",
                          class: _vm.formSchema[group].labelClass,
                          attrs: { for: "" }
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("tcmhq.please_specify")) +
                              "\n            "
                          )
                        ]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formData[group + "_input"],
                            expression: "formData[`${group}_input`]"
                          }
                        ],
                        staticClass: "form-control",
                        class: _vm.formSchema[group].inputClass,
                        attrs: { type: "text" },
                        domProps: { value: _vm.formData[group + "_input"] },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.formData,
                              group + "_input",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  ])
                ])
              ])
            : _vm._e()
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }