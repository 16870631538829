var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-form-textarea", {
    ref: "textarea",
    attrs: {
      value: _vm.value,
      rows: _vm.rows || 2,
      "max-rows": "100",
      required: "",
      "no-resize": "",
      disabled: _vm.disabled,
      id: _vm.qns + "textarea"
    },
    on: {
      input: function($event) {
        return _vm.updateTypingFormData($event, _vm.qns)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }