import remunerationService from '@/services/remuneration.service';
import Vue from 'vue';
import moment from 'moment'
import {
  SET_REMUNERATIONS,
  RECORD_PAYMENT,
} from './type.mutation';

const state = {
  remunerations: [],
};

/* eslint-disable no-param-reassign */
const mutations = {
  [SET_REMUNERATIONS](_state, payload) {
    _state.remunerations = payload;
  },
  [RECORD_PAYMENT](_state, payload) {
    _state.remuerations = _state.remunerations
      .map((rem) => {
        if (rem.invoiceNo === payload.invoiceNo
            && rem.remuneratedDoctor._id === payload.remuneratedProvider._id) {
          Vue.set(rem, 'remunerationPayment', payload);
        }
        return rem;
      });
  },
};
const actions = {
  fetchRemunerations({ commit }, params = {}) {
    return remunerationService.list(params)
      .then((response) => {
        commit(SET_REMUNERATIONS, response.data);
        response.data.map((x)=> {
          x.datestr = moment(x.date, 'X').format('DD MMM YYYY');
          x.payRecDet = x.remunerationPayment ? "Recorded by " + x.remunerationPayment.paymentOrganiser.name + "on " + moment(x.remunerationPayment.paymentDate, 'X').format('DD MMM YYYY, h:mm a') : ''
          x.invStatus = x.ostBalance > 0 ?  "Outstanding" : "Paid"
          x.corpInsurance = x.payments.reduce((a, b) => { a = [...a, ...b.corpPayments]; return a;}, []).map(p => p.label).filter((label, index, labels) => labels.indexOf(label) === index).join(', ')
          var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          });
          x.totProfFee = formatter.format(x.totalProfessionalFee) || ''
          x.remuAmt = x.remunerationPayment ? formatter.format(x.remunerationPayment.amount) : ''
        })
      });
  },
  recordPayment({ commit }, payload) {
    return remunerationService.create(payload)
      .then((response) => {
        commit(RECORD_PAYMENT, response.data);
        return response.data;
      });
  },
  updatePayment({ commit }, { id, payload }) {
    return remunerationService.update(id, payload)
      .then((response) => {
        commit(RECORD_PAYMENT, payload);
        return response.data;
      });
  },
};

const getters = {
  remunerations: _state => _state.remunerations,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
