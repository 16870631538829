<template>
  <div class="form-group row no-gutters range-link">
    <div :class="item.lableClass && item.labelClass[0] || 'col-sm-6'">
      <div class="form-group row no-gutters">
        <span class="col-sm-4 range-label"  :for="`${qns}_date_range_from_input`">{{ item.label[0] || 'From'}}</span>
        <div class="col-sm-8">
          <date-picker
            :value="item.answer[0]"
            value-type="timestamp"
            :format="(item.dateFormat && item.dateFormat[0]) || 'DD/MM/YYYY'"
            :id="`${qns}_date_range_from_input`"
            type="date"
            @input="updateAnswer($event, 'from')"
            :disabled="readonly"
            :placeholder="item.placeholder && item.placeholder[0]"
          ></date-picker>
        </div>
      </div>
    </div>
    <div :class="item.lableClass && item.labelClass[1] || 'col-sm-6'">
      <div class="form-group row no-gutters">
        <span class="col-sm-4 range-label" :for="`${qns}_date_range_to_input`">{{ item.label[1] || 'To'}}</span>
        <div class="col-sm-8">
          <date-picker
            :value="item.answer[1]"
            :disabled="readonly"
            value-type="timestamp"
            :format="(item.dateFormat && item.dateFormat[1]) || 'DD/MM/YYYY'"
            :id="`${qns}_date_range_to_input`"
            type="date"
            @input="updateAnswer($event, 'to')"
            :placeholder="item.placeholder && item.placeholder[1]"
          ></date-picker>
        </div>
      </div>
    </div>
    <!--
    <b-form-group
      :label="item.label[0] || 'From'"
      :disabled="readonly"
      :id="`${qns}_date_range_from`"
    >
    </b-form-group>
    <b-form-group
      :label="item.label[1] || 'To'"
      :disabled="readonly"
      :id="`${qns}_date_range_to`"
      :label-cols="item.labelCols && item.labelCols[1]"
      :label-class="item.lableClass && item.labelClass[1]"
    >
      <b-form-input
        :id="`${qns}_date_range_to_input`"
        :value="item.answer[1]"
        type="date"
        @input="updateAnswer($event)"
        :readonly="readonly"
        :placeholder="item.placeholder && item.placeholder[0]"
      ></b-form-input>
    </b-form-group>
    -->
  </div>
</template>
<script>
/*
Spec:
"q1":{
  "type": "range-link",
  "label": ["From", "To"],
  "answer": [new Date(), new Date()],
  "colSize": "col-sm-1",
  "labelcols": [],
  "linkTo":["q2","q3"] || "q4",
  "inclusive": true,
  "rangeType": "day",
  "answerSuffix": " days"
}
*/
import moment from 'moment';
import DateFormatter from '@/components/mixins/dateformatter';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  name: 'DateRangeLinkInput',
  mixins: [DateFormatter],
  components: {
    DatePicker,
  },
  computed: {
    readonly() {
      return this.viewing || this.printing || this.item.disabled || this.item.display === 'hide';
    },
  },
  props: {
    item: {
      type: Object,
      default: function fn() {},
    },
    formData: {
      type: Object,
      default: function fn() {},
    },
    viewing: {
      type: Boolean,
      default: false,
    },
    printing: {
      type: Boolean,
      default: false,
    },
    qns: {
      type: String,
    },
    rowIdx: {
      type: [String, Number],
    },
    updateTick: {
      type: Function,
      default: function fn() {},
    },
  },
  methods: {
    updateAnswer(value, type) {
      const {
        linkTo, answer, answerPrefix, answerSuffix,
        inclusive,
      } = this.item;
      let linkedQnsIds = [];
      if (typeof linkTo === 'string') {
        linkedQnsIds = [linkTo];
      } else if (Array.isArray(linkTo)) {
        linkedQnsIds = linkTo;
      }
      const [fromAnswer, toAnswer] = answer;
      let newAnswer = [];
      if (type === 'from') {
        newAnswer = [value, toAnswer];
      } else {
        newAnswer = [fromAnswer, value];
      }
      this.updateTick(newAnswer, this.qns);

      const fromDate = moment(newAnswer[0]);
      const toDate = moment(newAnswer[1]);
      if (!fromDate.isValid() || !toDate.isValid()) return;

      // valid range Types: "minutes", "hours", "days", "weeks"
      let ret = toDate.diff(fromDate, this.rangeType || 'days');
      if (inclusive) ret += 1;
      ret = `${answerPrefix || ''}${ret}${answerSuffix || ''}`;

      // linkedQnsIds.push(this.qns);
      this.$emit('update', linkedQnsIds, ret);
    },
  },
};
</script>
<style scoped lang="scss">
.range-link {
  .mx-datepicker {
    width: 120px;
  }
  .range-label {
    padding-top: 5px;
    text-align: center;
  }
}
</style>
