import api from './api';

export default {
  fetchSimplify(params) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);

    Object.keys(params).forEach((key) => {
      q.push(`${key}=${params[key]}`);
    });

    const url = `/billing-group/allSimplify?${q.join('&')}`;
    return api.get(url);
  },
};
