var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.viewer && _vm.viewerOpenState
      ? _c(
          "div",
          {
            staticClass: "view-overlay",
            staticStyle: {
              "background-color": "rgba(200,200,200,0.96)",
              "z-index": "2000"
            }
          },
          [_c("charting-viewer")],
          1
        )
      : _vm._e(),
    _vm.editor
      ? _c(
          "div",
          { staticClass: "big-overlay" },
          [
            _c("flash-message", {
              staticClass: "template-flash",
              attrs: { transitionName: "fade" }
            }),
            _vm.openSize !== "maximized"
              ? _c("div", { staticClass: "bottom-panel" }, [
                  _vm.openSize === "mediumSized"
                    ? _c(
                        "div",
                        { staticClass: "popup-window new-mail" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "label space-between draft",
                              class: _vm.setPublishedCSSClass,
                              on: {
                                click: function($event) {
                                  return _vm.resizeExtractedNote(
                                    $event,
                                    "minimized"
                                  )
                                }
                              }
                            },
                            [
                              _c("span", [
                                _vm.invoiceId
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("ddMonthYYYY")(
                                            (
                                              _vm.invoicesMappedById[
                                                _vm.invoiceId
                                              ] || {}
                                            ).createdAt
                                          )
                                        ) + " Visit Notes"
                                      )
                                    ])
                                  : _vm._e(),
                                _vm.editor.currentPageId
                                  ? _c("span", [
                                      _vm._v(" > " + _vm._s(_vm.editor.name))
                                    ])
                                  : _vm._e()
                              ]),
                              _c(
                                "span",
                                {
                                  staticClass: "top-right-icons space-between"
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.resizeExtractedNote(
                                            $event,
                                            "minimized"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", {
                                        attrs: {
                                          name: "regular/window-minimize",
                                          scale: "1.3"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm.isDraft &&
                                  !_vm.hasPassedNoteLockTimes &&
                                  _vm.checkPermissions(_vm.coverPage, "update")
                                    ? _c(
                                        "span",
                                        {
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.preMaximizeWindow(
                                                $event
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("v-icon", {
                                            attrs: {
                                              name: "regular/window-maximize",
                                              scale: "1.3"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "span",
                                    {
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.closeEditor()
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", {
                                        attrs: { name: "times", scale: "1.3" }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm.editor.type === "consult"
                            ? _c("consult-note-container", {
                                staticClass: "invoice-note-container",
                                attrs: {
                                  id: _vm.invoiceId,
                                  invoice:
                                    _vm.invoicesMappedById[_vm.invoiceId],
                                  mainForm: _vm.notesMapped[_vm.noteId],
                                  isExtracted: true,
                                  toggleDispenseBuilder:
                                    _vm.toggleDispenseBuilder,
                                  otOptions: _vm.otOptions,
                                  otMappedIdToName: _vm.otMappedIdToName
                                }
                              })
                            : _vm._e(),
                          _vm.editor.currentPageId &&
                          _vm.editor.type === "drawing"
                            ? _c("div", { staticClass: "image" }, [
                                _c("img", { attrs: { src: _vm.imageURL } })
                              ])
                            : _vm._e(),
                          _vm.editor.type === "typing"
                            ? _c("span", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "invoice-note-container",
                                    staticStyle: {
                                      "background-color": "white"
                                    },
                                    attrs: {
                                      id: "print-" + _vm.editor.currentPageId
                                    }
                                  },
                                  [
                                    _vm.isTypingNoteReadOnly
                                      ? _c("div", [
                                          _c("img", {
                                            attrs: {
                                              src:
                                                _vm.currentNote.signedPrintKey,
                                              alt: "Typing Note Image"
                                            }
                                          })
                                        ])
                                      : _c("typing-note-editor", {
                                          attrs: {
                                            editor: _vm.editor,
                                            formDataOrder:
                                              _vm.notesMapped[
                                                _vm.editor.currentPageId
                                              ].formDataOrder,
                                            formData: _vm.editor.formData,
                                            viewing: _vm.editor.viewing,
                                            updateTypingFormData:
                                              _vm.updateTypingFormData,
                                            mainCheckboxChanged:
                                              _vm.mainCheckboxChanged,
                                            editorId: _vm.editorId,
                                            editorFocus: _vm.editorFocus,
                                            editorBlur: _vm.editorBlur,
                                            mainRadioChanged:
                                              _vm.mainRadioChanged
                                          }
                                        })
                                  ],
                                  1
                                )
                              ])
                            : _vm._e(),
                          _vm.editor.type === "letter"
                            ? _c("span", [
                                _c(
                                  "div",
                                  {
                                    ref: "minimized-letter-container",
                                    staticClass: "invoice-note-container"
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "form-group row" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "form-group col" },
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "title" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "setting.letterDate.fromDate"
                                                    )
                                                  )
                                                )
                                              ]
                                            ),
                                            _c("datepicker", {
                                              attrs: {
                                                format: "yyyy/MM/dd",
                                                "input-class":
                                                  "form-control bg-white",
                                                "data-automation":
                                                  "notes-from-date",
                                                name: "fromDate"
                                              },
                                              on: {
                                                input: function($event) {
                                                  return _vm.updateSelectedLetterDate(
                                                    $event,
                                                    _vm.editor.invoiceId,
                                                    _vm.editor.currentPageId,
                                                    true
                                                  )
                                                }
                                              },
                                              model: {
                                                value: _vm.fromDate,
                                                callback: function($$v) {
                                                  _vm.fromDate = $$v
                                                },
                                                expression: "fromDate"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "form-group col" },
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "title" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "setting.letterDate.toDate"
                                                    )
                                                  ) + " "
                                                )
                                              ]
                                            ),
                                            _c("datepicker", {
                                              attrs: {
                                                format: "yyyy/MM/dd",
                                                "data-automation":
                                                  "notes-to-date",
                                                "input-class":
                                                  "form-control bg-white",
                                                name: "toDate"
                                              },
                                              on: {
                                                input: function($event) {
                                                  return _vm.updateSelectedLetterDate(
                                                    $event,
                                                    _vm.editor.invoiceId,
                                                    _vm.editor.currentPageId,
                                                    false
                                                  )
                                                }
                                              },
                                              model: {
                                                value: _vm.toDate,
                                                callback: function($$v) {
                                                  _vm.toDate = $$v
                                                },
                                                expression: "toDate"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    ),
                                    _c("textarea-autosize", {
                                      ref: "templatetextarea",
                                      staticClass: "form-control",
                                      attrs: {
                                        value: _vm.populatedLetter,
                                        "data-automation": "notes-text-area",
                                        "min-height": 30,
                                        "max-height": _vm.calculateLetterContainerHeight(
                                          "minimized-letter-container"
                                        )
                                      },
                                      on: {
                                        input: function($event) {
                                          return _vm.updateSelectedLetter(
                                            $event,
                                            _vm.editor.invoiceId,
                                            _vm.editor.currentPageId
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            : _vm._e(),
                          _c("div", { staticClass: "footer space-between" }, [
                            _c("span"),
                            !_vm.editor.currentPageId
                              ? _c("span", [
                                  _vm.patientConfig.consultNotesPublishMandatory
                                    ? _c(
                                        "span",
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "mx-1 save-draft-button",
                                              attrs: {
                                                "data-automation":
                                                  "notes-save-draft"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.saveNote(
                                                    _vm.noteId,
                                                    false,
                                                    _vm.invoiceId
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("Save Draft")]
                                          ),
                                          _c(
                                            "b-button",
                                            {
                                              staticClass: "publish-button",
                                              attrs: {
                                                "data-automation":
                                                  "notes-save-publish"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.saveNote(
                                                    _vm.noteId,
                                                    true,
                                                    _vm.invoiceId
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("Publish")]
                                          )
                                        ],
                                        1
                                      )
                                    : _c(
                                        "span",
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass: "mx-1",
                                              attrs: {
                                                "data-automation": "notes-save"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.saveNote(
                                                    _vm.noteId,
                                                    false,
                                                    _vm.invoiceId
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("Save")]
                                          )
                                        ],
                                        1
                                      )
                                ])
                              : _vm.editor.currentPageId &&
                                _vm.editor.type === "typing" &&
                                !_vm.editor.viewing
                              ? _c(
                                  "span",
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "mx-1",
                                        attrs: {
                                          variant: "outline-success",
                                          "data-automation":
                                            "notes-save-charting"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.saveNote(
                                              _vm.editor.currentPageId,
                                              false
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("Save Charting")]
                                    )
                                  ],
                                  1
                                )
                              : _vm.editor.currentPageId &&
                                _vm.editor.type === "letter"
                              ? _c(
                                  "span",
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "mx-1",
                                        attrs: {
                                          variant: "outline-success",
                                          "data-automation":
                                            "notes-save-letters"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.patchInvoice(
                                              _vm.editor.invoiceId,
                                              "letters"
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("Save Letter")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.openSize === "minimized"
                    ? _c("div", { staticClass: "minimized-window" }, [
                        _c(
                          "span",
                          {
                            staticClass: "label space-between",
                            on: {
                              click: function($event) {
                                return _vm.resizeExtractedNote(
                                  $event,
                                  "mediumSized"
                                )
                              }
                            }
                          },
                          [
                            _c("span", [_vm._v("Invoice Notes")]),
                            _c(
                              "span",
                              { staticClass: "top-right-icons space-between" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "left-icon",
                                    on: {
                                      click: function($event) {
                                        return _vm.resizeExtractedNote(
                                          $event,
                                          "mediumSized"
                                        )
                                      }
                                    }
                                  },
                                  [_c("v-icon", { attrs: { name: "minus" } })],
                                  1
                                ),
                                _vm.isDraft && !_vm.hasPassedNoteLockTimes
                                  ? _c(
                                      "span",
                                      {
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            return _vm.preMaximizeWindow($event)
                                          }
                                        }
                                      },
                                      [
                                        _c("v-icon", {
                                          attrs: {
                                            name: "regular/window-maximize"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "span",
                                  {
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.closeEditor()
                                      }
                                    }
                                  },
                                  [_c("v-icon", { attrs: { name: "times" } })],
                                  1
                                )
                              ]
                            )
                          ]
                        )
                      ])
                    : _vm._e()
                ])
              : _vm._e(),
            _vm.openSize === "maximized" &&
            _vm.editor.type == "drawing" &&
            _vm.editor.open
              ? _c("canvas-editor-container", {
                  key: _vm.editor.updatedAt,
                  attrs: {
                    type: "note",
                    editor: _vm.editor,
                    patient: _vm.patient,
                    fetchAllNoteTemplates: _vm.fetchAllNoteTemplates,
                    notetemplatesMappedById: _vm.notetemplatesMappedById,
                    notesMapped: _vm.notesMapped,
                    invoicesMappedById: _vm.invoicesMappedById,
                    minusIconFunction: _vm.preMinimizeWindow,
                    windowIconFunction: _vm.preMinimizeWindow,
                    columnsIconFunction: _vm.preMinimizeToIPD,
                    timesIconFunction: _vm.closeEditor,
                    goToPage: _vm.goToPage
                  }
                })
              : _vm._e(),
            _c(
              "b-modal",
              {
                staticClass: "bv-modal-note",
                attrs: {
                  id: "bv-modal-note",
                  "header-class": "label",
                  size: "xl"
                }
              },
              [
                _c(
                  "template",
                  { staticClass: "space-between", slot: "modal-header" },
                  [
                    _vm.invoiceId && _vm.openSize === "maximized"
                      ? _c(
                          "span",
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm._f("ddMonthYYYY")(
                                    (
                                      _vm.invoicesMappedById[_vm.invoiceId] ||
                                      {}
                                    ).createdAt
                                  )
                                ) +
                                " Visit Notes\n            "
                            ),
                            _c(
                              "b-form",
                              { staticClass: "breadcrumbs" },
                              [
                                _vm.isEditingCategory
                                  ? _c("b-form-select", {
                                      attrs: {
                                        size: "sm",
                                        value: _vm.selectedCategory,
                                        options: _vm.filteredCategoriesOptions,
                                        state: _vm.editorCategoryState,
                                        id: "editor-category",
                                        autofocus: "",
                                        plain: ""
                                      },
                                      on: {
                                        change: function($event) {
                                          _vm.updateEditorMetadata({
                                            key: "categoryId",
                                            value: $event
                                          })
                                          _vm.isEditingCategory = false
                                        }
                                      },
                                      nativeOn: {
                                        blur: function($event) {
                                          _vm.updateEditorMetadata({
                                            key: "categoryId",
                                            value: $event.target.value
                                          })
                                          _vm.isEditingCategory = false
                                        }
                                      }
                                    })
                                  : !_vm.editor.viewing
                                  ? _c(
                                      "span",
                                      {
                                        on: {
                                          click: function($event) {
                                            _vm.isEditingCategory = true
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              position: "relative",
                                              top: "-1px"
                                            }
                                          },
                                          [
                                            _c("v-icon", {
                                              attrs: {
                                                name: "circle",
                                                color:
                                                  _vm.categoriesMapped[
                                                    _vm.selectedCategory
                                                  ].colorLabel
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-left": "2px"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.categoriesMapped[
                                                  _vm.selectedCategory
                                                ].name
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  : _c("span", [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            position: "relative",
                                            top: "-1px"
                                          }
                                        },
                                        [
                                          _c("v-icon", {
                                            attrs: {
                                              name: "circle",
                                              color:
                                                _vm.categoriesMapped[
                                                  _vm.selectedCategory
                                                ].colorLabel
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { "margin-left": "2px" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.categoriesMapped[
                                                _vm.selectedCategory
                                              ].name
                                            )
                                          )
                                        ]
                                      )
                                    ]),
                                _c("span", [_vm._v("  /  ")]),
                                _vm.isEditingName
                                  ? _c("b-input", {
                                      attrs: {
                                        id: "editor-name",
                                        size: "sm",
                                        value: _vm.editor.name,
                                        state: _vm.editorNameState,
                                        "invalid-feedback": "",
                                        autofocus: "",
                                        required: ""
                                      },
                                      on: {
                                        input: function($event) {
                                          return _vm.updateEditorMetadata({
                                            key: "name",
                                            value: $event
                                          })
                                        },
                                        blur: function($event) {
                                          _vm.emptyNameCheck()
                                          _vm.isEditingName = false
                                        }
                                      }
                                    })
                                  : !_vm.editor.viewing
                                  ? _c(
                                      "span",
                                      {
                                        on: {
                                          click: function($event) {
                                            _vm.isEditingName = true
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.editor.name))]
                                    )
                                  : _c("span", [
                                      _vm._v(_vm._s(_vm.editor.name))
                                    ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _c("span"),
                    _c("span", { staticClass: "top-right-icons" }, [
                      _c(
                        "span",
                        {
                          on: {
                            click: function($event) {
                              return _vm.preMinimizeWindow($event, "minimized")
                            }
                          }
                        },
                        [
                          _c("v-icon", {
                            attrs: {
                              name: "regular/window-minimize",
                              scale: "1"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "left-icon",
                          on: {
                            click: function($event) {
                              return _vm.preMinimizeWindow(
                                $event,
                                "mediumSized"
                              )
                            }
                          }
                        },
                        [
                          _c("v-icon", {
                            attrs: {
                              name: "external-link-alt",
                              scale: "1.5",
                              flip: "vertical"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "span",
                        {
                          on: {
                            click: function($event) {
                              return _vm.preCloseEditor($event)
                            }
                          }
                        },
                        [
                          _c("v-icon", {
                            attrs: { name: "times", scale: "1.3" }
                          })
                        ],
                        1
                      )
                    ])
                  ]
                ),
                _vm.editor.type === "consult"
                  ? _c("consult-note-container", {
                      attrs: {
                        id: _vm.invoiceId,
                        invoice: _vm.invoicesMappedById[_vm.invoiceId],
                        mainForm: _vm.notesMapped[_vm.noteId],
                        isExtracted: true,
                        toggleDispenseBuilder: _vm.toggleDispenseBuilder,
                        otOptions: _vm.otOptions,
                        otMappedIdToName: _vm.otMappedIdToName
                      }
                    })
                  : _c("span", [
                      _vm.editor.type === "typing"
                        ? _c(
                            "div",
                            {
                              staticClass: "invoice-note-container",
                              staticStyle: { "background-color": "white" },
                              attrs: { id: "print-" + _vm.editor.currentPageId }
                            },
                            [
                              _vm.isTypingNoteReadOnly
                                ? _c("div", [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.currentNote.signedPrintKey,
                                        alt: "Typing Note Image"
                                      }
                                    })
                                  ])
                                : _c("typing-note-editor", {
                                    attrs: {
                                      editor: _vm.editor,
                                      formDataOrder:
                                        _vm.notesMapped[
                                          _vm.editor.currentPageId
                                        ].formDataOrder,
                                      formData: _vm.editor.formData,
                                      viewing: _vm.editor.viewing,
                                      updateTypingFormData:
                                        _vm.updateTypingFormData,
                                      mainCheckboxChanged:
                                        _vm.mainCheckboxChanged,
                                      editorId: _vm.editorId,
                                      editorFocus: _vm.editorFocus,
                                      editorBlur: _vm.editorBlur,
                                      mainRadioChanged: _vm.mainRadioChanged
                                    }
                                  })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.editor.type === "letter"
                        ? _c("div", [
                            _c(
                              "div",
                              {
                                ref: "maximized-letter-container",
                                staticClass: "invoice-note-container"
                              },
                              [
                                _c("div", { staticClass: "form-group row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group col" },
                                    [
                                      _c("label", { attrs: { for: "title" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "setting.letterDate.fromDate"
                                            )
                                          )
                                        )
                                      ]),
                                      _c("datepicker", {
                                        attrs: {
                                          format: "yyyy/MM/dd",
                                          "input-class":
                                            "form-control bg-white",
                                          name: "fromDate"
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.updateSelectedLetterDate(
                                              $event,
                                              _vm.editor.invoiceId,
                                              _vm.editor.currentPageId,
                                              true
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.fromDate,
                                          callback: function($$v) {
                                            _vm.fromDate = $$v
                                          },
                                          expression: "fromDate"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "form-group col" },
                                    [
                                      _c("label", { attrs: { for: "title" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("setting.letterDate.toDate")
                                          ) + " "
                                        )
                                      ]),
                                      _c("datepicker", {
                                        attrs: {
                                          format: "yyyy/MM/dd",
                                          "input-class":
                                            "form-control bg-white",
                                          name: "toDate"
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.updateSelectedLetterDate(
                                              $event,
                                              _vm.editor.invoiceId,
                                              _vm.editor.currentPageId,
                                              false
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.toDate,
                                          callback: function($$v) {
                                            _vm.toDate = $$v
                                          },
                                          expression: "toDate"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]),
                                _c("textarea-autosize", {
                                  ref: "templatetextarea",
                                  staticClass: "form-control",
                                  attrs: {
                                    value: _vm.populatedLetter,
                                    "min-height": 30,
                                    "max-height": _vm.calculateLetterContainerHeight(
                                      "maximized-letter-container"
                                    )
                                  },
                                  on: {
                                    input: function($event) {
                                      return _vm.updateSelectedLetter(
                                        $event,
                                        _vm.editor.invoiceId,
                                        _vm.editor.currentPageId
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        : _vm._e()
                    ]),
                _c("template", { slot: "modal-footer" }, [
                  _c("div", { staticClass: "footer space-between" }, [
                    _c("span"),
                    !_vm.editor.currentPageId
                      ? _c("span", [
                          _vm.patientConfig.consultNotesPublishMandatory
                            ? _c(
                                "span",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mx-1 save-draft-button",
                                      on: {
                                        click: function($event) {
                                          return _vm.saveNote(
                                            _vm.noteId,
                                            false,
                                            _vm.invoiceId
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("Save Draft")]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mx-1 publish-button",
                                      on: {
                                        click: function($event) {
                                          return _vm.saveNote(
                                            _vm.noteId,
                                            true,
                                            _vm.invoiceId
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("Publish")]
                                  )
                                ],
                                1
                              )
                            : _c(
                                "span",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mx-1",
                                      on: {
                                        click: function($event) {
                                          return _vm.saveNote(
                                            _vm.noteId,
                                            false,
                                            _vm.invoiceId
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("Save")]
                                  )
                                ],
                                1
                              )
                        ])
                      : _vm.editor.currentPageId &&
                        _vm.editor.type === "typing" &&
                        !_vm.editor.viewing
                      ? _c(
                          "span",
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "mx-1",
                                attrs: { variant: "outline-success" },
                                on: {
                                  click: function($event) {
                                    return _vm.saveNote(
                                      _vm.editor.currentPageId,
                                      false
                                    )
                                  }
                                }
                              },
                              [_vm._v("Save Charting")]
                            )
                          ],
                          1
                        )
                      : _vm.editor.currentPageId && _vm.editor.type === "letter"
                      ? _c(
                          "span",
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "mx-1",
                                attrs: { variant: "outline-success" },
                                on: {
                                  click: function($event) {
                                    return _vm.patchInvoice(
                                      _vm.editor.invoiceId,
                                      "letters"
                                    )
                                  }
                                }
                              },
                              [_vm._v("Save Letter")]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ])
              ],
              2
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }