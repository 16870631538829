var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "autoitem",
        { disabled: _vm.item.link && _vm.item.link.is_heading }
      ]
    },
    [_vm._v("\n  " + _vm._s(_vm.item.value || "") + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }