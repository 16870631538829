var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "signature-pad-form" } },
    [
      _c("div", { staticClass: "signature-date mb-2" }, [
        _vm.signatureDate
          ? _c("span", [_vm._v(_vm._s(_vm.$t("general.date")))])
          : _vm._e(),
        _vm._v(": " + _vm._s(_vm.signatureDate) + "\n    ")
      ]),
      !_vm.isAccepted
        ? _c("vd-signature-pad", {
            attrs: { width: "500px", height: "300px", viewing: _vm.viewing },
            on: { accept: _vm.acceptSignature }
          })
        : _c("div", [
            _vm.signature
              ? _c("img", {
                  staticClass: "signature-image",
                  attrs: {
                    src: _vm.signature,
                    alt: "",
                    width: "500px",
                    height: "300px"
                  }
                })
              : _vm._e(),
            _c("br"),
            _c(
              "button",
              {
                staticClass: "btn btn-success",
                attrs: { disabled: _vm.viewing },
                on: {
                  click: function($event) {
                    _vm.isAccepted = false
                  }
                }
              },
              [_vm._v("Re-sign")]
            )
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }