import InventoryTransfer from '../../services/inventory-transfer.service';

const SET_INVENTORY_TRANSFER = 'SET_INVENTORY_TRANSFER';
const SET_TRANSFER_FINALIZED = 'SET_TRANSFER_FINALIZED';

const state = {
  selectedInventoryTransfer: {},
  isInventoryTransferFinalized: true
};

/* eslint-disable no-param-reassign */
const mutations = {
  [SET_INVENTORY_TRANSFER](_state, payload) {
    _state.selectedInventoryTransfer = payload;
  },
  [SET_TRANSFER_FINALIZED](_state, payload) {

    _state.isInventoryTransferFinalized = payload;
  },
};

const actions = {
  getInventoryTransfer({ commit }, data) {
    return commit(SET_INVENTORY_TRANSFER, data.payload);
  },
  TransferFinalized({ commit }, data) {
    return commit(SET_TRANSFER_FINALIZED, data.payload.isInventoryTransferFinalized);
  },
};

const getters = {

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
