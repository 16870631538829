<template>
  <!-- eslint-disable max-len -->
  <!--
  This is the V2 version of drug label component. @ 20/Mar/2021
  For V1, please refer to @/components/Print/DrugLabelComponent.vue.

  This template will print just contents of the drug label.
  The clinic has printed the logo, grey color area, all images of time and the warning message
  on the sticker label.
  -->
 <div class="drug-label-content-container" id="drug-label">
  <div class="address-header">
      <div class="drug-label-address" v-if="printLocal">
        <p>
          {{ selectedClinic.localAddress }}
          <span class="drug-label-phone">
            <span style="font-size: 0.35rem;vertical-align: middle;">📞</span>
            {{ selectedClinic.phoneNumber }}
          </span>
        </p>
        <p class="dispense-time">
          {{  new Date() | formatVDDate({ lang: "en", format: 'DD/MM/YYYY HH:mm:ss'}) }}
          <span class="hn">
            CN: {{ patient.givenIdPrefix }}{{ patient.givenId }}
          </span>
        </p>
      </div>
      <div class="drug-label-address" v-else>
        <p>
          {{ selectedClinic.address }}
          <span class="drug-label-phone"></span>
          <span style="font-size: 0.35rem;vertical-align: middle;">📞</span>
            {{ selectedClinic.phoneNumber }}
          </span>
        </p>
        <p class="dispense-time">
          {{  new Date() | formatVDDate({ lang: "en", format: 'DD/MM/YYYY HH:mm:ss'}) }}
          <span class="hn">
            CN: {{ patient.givenIdPrefix }}{{ patient.givenId }}
          </span>
        </p>
      </div>
    </div>
    <div class="text-right patient-details">
        <p>
          <span v-if="!!medItem.location" class="drug-location">{{ medItem.location }}</span>
          Batch no: {{ selectedBatch.number }}
          Expired: {{ selectedBatch.expiry | formatVDDate({ lang: "en", format: $store.state.config.inventory.expiryFormatConfig}) }}
        </p>
    </div>

    <!-- Med name, strength, localtion, expiry -->
    <div class="columns med-name-line"></div>
    <div class="drug-name-col">
      <div>
        <div class="drug-name">
          <p>
          {{ medItem.name }}
          <span v-if="medItem.strength" class="ml-1"> {{ medItem.strength }}</span>
          <span class="ml-1 drug-qty" v-if="medItem.qty || medItem.unit">( {{medItem.qty}}&nbsp;{{medItem.unit}} )</span>
          </p>
          <p v-if="!!medItem.chineseName">
          {{medItem.chineseName}}
          <span v-if="medItem.strength" class="ml-1"> {{ medItem.strength }}</span>
          <span class="ml-1 drug-qty" v-if="medItem.qty || medItem.unit">( {{medItem.qty}}&nbsp;{{medItem.unit}} )</span>
          </p>
        </div>
      </div>
    </div>
    <!-- Grey color , main body -->
    <div class="columns is-gapless main-body-container">
      <div class="column body-left">
        <div class="body-left-grey">
          <p class="patient-info name" v-if="printLocal">{{ patient.localTitle | '&nbsp;' }} {{ patient.localName || '&nbsp;' }}</p>
          <p class="patient-info name" v-else>{{ patient.title }} {{ patient.name }}</p>
          <p class="dosage-msg">{{ medDosageMessage }}</p>
          <p class="dosage-instruct">{{ medInstruction }}</p>

        </div>
        <div class="body-left-white">
          <div>
            <p class="">{{ medPrecautionOne }}</p>
            <p class="">{{ medPrecautionTwo }}</p>
          </div>
          <p class="mb-4">{{ medIndication }}</p>
        </div>
        <div class="doctor" v-if="doctorName">
          {{ doctorName }}
        </div>
      </div>
      <div class="column body-right is-narrow">
        <div class="dosage-info">
          <div v-if="medItem.instructionInfo && medItem.instructionInfo.morning" class="dosage-info-inner">
            <p class="mb-1 med-unit">{{ medDosage }}</p>
            <p class="">{{ medDosageUnit }}</p>
          </div>
          <div v-else>&nbsp;</div>
        </div>
        <div class="dosage-info">
          <div v-if="medItem.instructionInfo && medItem.instructionInfo.afternoon" class="dosage-info-inner">
            <p class="mb-1 med-unit">{{ medDosage }}</p>
            <p class="">{{ medDosageUnit }}</p>
          </div>
          <div v-else>&nbsp;</div>
        </div>
        <div class="dosage-info">
          <div v-if="medItem.instructionInfo && medItem.instructionInfo.evening" class="dosage-info-inner">
            <p class="mb-1 med-unit">{{ medDosage }}</p>
            <p class="">{{ medDosageUnit }}</p>
          </div>
          <div v-else>&nbsp;</div>
        </div>
        <div class="dosage-info">
          <div v-if="medItem.instructionInfo && medItem.instructionInfo.bedtime" class="dosage-info-inner">
            <p class="mb-1 med-unit">{{ medDosage }}</p>
            <p class="">{{ medDosageUnit }}</p>
          </div>
          <div v-else>&nbsp;</div>
        </div>
      </div>
    </div>
 </div>
</template>
<script>
import _ from 'lodash';
import moment from 'moment';
import dateformatter from '@/components/mixins/dateformatter';
import { mapGetters } from 'vuex';

export default {
  name: 'MedicalLabelComponent',
  mixins: [dateformatter],
  props: [
    'disableAutoPrint',
    'lang',
    'medItem',
    'patient',
    'printLocal',
    'generalConfig',
  ],
  methods: {
    getDob(dateOfBirth) {
      return moment(dateOfBirth, 'YYYYMMDD').format('DD MMM YYYY') || '';
    },
  },
  data() {
    return {
      medIndication: 'indication',
      medInstruction: 'instruction',
      medPrecautionOne: 'precaution1',
      medPrecautionTwo: 'precaution2',
      medDosage: 'dosage',
      medDosageMessage: '',
      medDosageUnit: '',
      doctorName: '',
      selectedBatch: {},
    };
  },
  mounted() {
    // remove all the styles
    const styles = document.querySelectorAll('style');

    styles.forEach(ele => ele.remove());

    const {
      medItem, printLocal, allProviders,
    } = this;
    const localLanguage = 'th';
    this.$i18n.locale = printLocal ? localLanguage : 'en';

    const fieldWithLocale = {
      medIndication: 'indication',
      medInstruction: 'instruction',
      medPrecautionOne: 'precaution1',
      medPrecautionTwo: 'precaution2',
      medDosageMessage: 'dosage',
    };

    Object.keys(fieldWithLocale).forEach((field) => {
      const medKey = fieldWithLocale[field];
      if (printLocal) {
        this[field] = medItem[`${medKey}Local`];
      } else {
        this[field] = medItem[medKey];
      }
    });

    import('../../assets/print/bulma.min.css'); // eslint-disable-line
    import('../../assets/print//paper.min.css'); // eslint-disable-line
    import('../../assets/print/drug-label.scss'); // eslint-disable-line

    this.medDosage = medItem.dosageDose;
    this.medDosageUnit = medItem.dosageUnit;
    this.selectedBatch = medItem.selectedBatch;

    // provider as doctor
    const { providerId } = medItem;
    if (providerId) {
      const provider = allProviders.find(i => i._id === providerId);
      if (provider) {
        this.doctorName = provider.name;
        // this.doctorSurname = provider.surname
      }
    }

    if (!this.disableAutoPrint) {
      setTimeout(() => {
        window.print();
      }, 2000);
    }
  },
  computed: {
    ...mapGetters('print', ['setting']),
    ...mapGetters('provider', { allProviders: 'all' }),
    ...mapGetters('config', ['featureFlags']),
    ...mapGetters('clinic', ['selectedClinic']),
    withSelectedBatch() {
      return !_.isEmpty(this.selectedBatch);
    },
    medName() {
      if (this.printLocal) {
        if (this.medItem.medicine && this.medItem.medicine.chineseName) {
          return this.medItem.medicine.chineseName;
        }
        if (this.medItem.chineseName) {
          return this.medItem.chineseName;
        }
      }

      return this.medItem.name;
    },
  },
};
</script>
