var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.formGroups, function(group) {
      return _c(
        "div",
        {
          key: group,
          staticClass: "form-group row",
          class: _vm.formSchema[group].containerClass,
          attrs: { "data-tik": _vm.tik }
        },
        [
          _c("section", { staticClass: "pdf-item" }, [
            _vm.isFreetext(_vm.formSchema[group].type)
              ? _c(
                  "label",
                  {
                    staticClass: "col-sm-12",
                    class: _vm.formSchema[group].labelClass
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.formSchema[group].i18nId
                            ? _vm.$t(_vm.formSchema[group].i18nId)
                            : _vm.formSchema[group].label
                        ) +
                        "\n        "
                    )
                  ]
                )
              : _vm._e(),
            !_vm.isFreetext(_vm.formSchema[group].type)
              ? _c(
                  "label",
                  {
                    staticClass: "col-sm-12",
                    class: [
                      { multiline: _vm.formSchema[group].multiline },
                      _vm.formSchema[group].labelClass
                    ]
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.formSchema[group].i18nId
                            ? _vm.$t(_vm.formSchema[group].i18nId)
                            : _vm.formSchema[group].label
                        ) +
                        "\n          "
                    ),
                    _vm.formSchema[group].required
                      ? _c(
                          "span",
                          { staticClass: "badge badge-pill badge-danger" },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("general.required")) +
                                "\n          "
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              : _vm._e(),
            _vm.isInput(_vm.formSchema[group].type)
              ? _c("div", { staticClass: "answer" }, [
                  _c("span", {
                    class: _vm.formSchema[group].inputClass,
                    domProps: {
                      textContent: _vm._s(_vm.formData[group] || "-")
                    }
                  })
                ])
              : _vm._e(),
            _vm.isTextArea(_vm.formSchema[group].type)
              ? _c("div", { staticClass: "answer" }, [
                  _c("span", {
                    staticClass: "textarea",
                    class: _vm.formSchema[group].inputClass,
                    domProps: { innerHTML: _vm._s(_vm.formData[group] || "-") }
                  })
                ])
              : _vm._e(),
            _vm.isDateTime(_vm.formSchema[group].type)
              ? _c("div", { staticClass: "answer" }, [
                  _c("span", {
                    attrs: {
                      inputClass: [
                        "form-control",
                        _vm.formSchema[group].inputClass
                      ]
                    },
                    domProps: {
                      textContent: _vm._s(_vm.formatDate(_vm.formData[group]))
                    }
                  })
                ])
              : _vm._e(),
            _vm.isGroup(_vm.formSchema[group].type)
              ? _c(
                  "div",
                  { staticClass: "col-sm-12" },
                  _vm._l(_vm.formSchema[group].groups, function(input, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "group_input col-sm-4",
                        class: _vm.formSchema[group].groupClass
                      },
                      [
                        _vm.isRadio(input.type)
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "answer custom-control custom-radio custom-control-inline",
                                class: input.groupClass
                              },
                              [
                                _vm.formData[group].includes(input.value)
                                  ? [
                                      _c("v-icon", {
                                        attrs: { name: "check-circle" }
                                      }),
                                      _vm._v(
                                        "\n                   \n                "
                                      )
                                    ]
                                  : [
                                      _c("v-icon", {
                                        attrs: { name: "circle" }
                                      }),
                                      _vm._v(
                                        "\n                   \n                "
                                      )
                                    ],
                                _c("label", { class: input.labelClass }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        input.i18nId
                                          ? _vm.$t(input.i18nId)
                                          : input.label
                                      ) +
                                      "\n                "
                                  )
                                ])
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm.isCheckbox(input.type)
                          ? _c(
                              "div",
                              { class: input.groupClass },
                              [
                                _vm.formData[group].includes(input.value)
                                  ? [
                                      _c("v-icon", {
                                        attrs: { name: "check-square" }
                                      }),
                                      _vm._v(
                                        "\n                     \n                  "
                                      )
                                    ]
                                  : [
                                      _c("v-icon", {
                                        attrs: { name: "square-full" }
                                      }),
                                      _vm._v(
                                        "\n                     \n                  "
                                      )
                                    ],
                                _c("label", { class: input.labelClass }, [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        input.i18nId
                                          ? _vm.$t(input.i18nId)
                                          : input.label
                                      ) +
                                      "\n                  "
                                  )
                                ])
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm.isInput(input.type)
                          ? _c(
                              "div",
                              {
                                staticClass: "form-group",
                                class: input.groupClass
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-label",
                                    class: input.labelClass
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          input.i18nId
                                            ? _vm.$t(input.i18nId)
                                            : input.label
                                        )
                                    )
                                  ]
                                ),
                                _c("div", { staticClass: "answer" }, [
                                  _c("span", {
                                    class: input.inputClass,
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.formData[input.model] || "-"
                                      )
                                    }
                                  })
                                ])
                              ]
                            )
                          : _vm._e(),
                        _vm.isDateTime(input.type)
                          ? _c(
                              "div",
                              {
                                staticClass: "form-group",
                                class: input.groupClass
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-label",
                                    class: input.labelClass
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          input.i18nId
                                            ? _vm.$t(input.i18nId)
                                            : input.label
                                        )
                                    )
                                  ]
                                ),
                                _c("div", { staticClass: "answer" }, [
                                  _c("span", {
                                    attrs: {
                                      inputClass: [
                                        "form-control",
                                        input.inputClass
                                      ]
                                    },
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.formatDate(
                                          _vm.formData[input.model]
                                        )
                                      )
                                    }
                                  })
                                ])
                              ]
                            )
                          : _vm._e(),
                        _vm.isFreetext(input.type)
                          ? _c(
                              "div",
                              {
                                staticClass: "form-group",
                                class: input.groupClass
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-label",
                                    class: input.labelClass
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          input.i18nId
                                            ? _vm.$t(input.i18nId)
                                            : input.label
                                        ) +
                                        "\n                "
                                    )
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    )
                  }),
                  0
                )
              : _vm._e()
          ])
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }