<template>
  <div class="conuid print-body"
    :class="{'previewPrint': preview}">
    <div class="row no-gutters">
      <div class="col-7 label-container">
        <div class="row no-gutters">
          <div class="col-12 heading-font">
            <h5 v-if="printLabelConfig.printLabel.clinicName">{{ clinic.clinicName }}</h5>
            <p v-if="printLabelConfig.printLabel.clinicAddress"
              class="mb-2 address-font">
              {{ labelAddress }}
            </p>
            <div class="border-bottom mb-2 line"></div>
          </div>
        </div>
        <div class="text-font">
        <div class="row no-gutters" v-if="printLabelConfig.printLabel.patientName">
          <div class="col-2 s-label">NAME</div>
          <div class="col-10">:&nbsp;<strong>{{ patient.name }}</strong></div>
        </div>
        <div class="row no-gutters">
          <template v-if="printLabelConfig.printLabel.age">
            <div class="col-2 s-label">AGE</div>
            <div class="col-3 s-text">:&nbsp;{{ age }}</div>
          </template>
          <template v-if="printLabelConfig.printLabel.nricfin">
            <div class="col-3 l-label">NRIC / PASSPORT</div>
            <div class="col-4">:&nbsp;{{ patient.nric }}</div>
          </template>
        </div>
        <div class="row no-gutters">
          <template v-if="printLabelConfig.printLabel.patientDOB">
            <div class="col-2 s-label">DOB</div>
            <div class="col-3 s-text">:&nbsp;{{ patient.dateOfBirth | formatVDDate({ format: 'DD/MM/YYYY', lang: $i18n.locale }) }}</div>
          </template>
          <template v-if="printLabelConfig.printLabel.nationality">
            <div class="col-3 l-label">NATIONALITY</div>
            <div class="col-4">:&nbsp;{{ patient.nationality }}</div>
          </template>
        </div>
        <div class="row no-gutters">
          <template v-if="printLabelConfig.printLabel.patientID">
            <div class="col-2 s-label">PATIENTID</div>
            <div class="col-3 s-text">
              :&nbsp;{{ patient.givenIdPrefix ?
                patient.givenIdPrefix + patient.givenId
                : patient.givenId }}
            </div>
          </template>
          <template v-if="printLabelConfig.printLabel.patientSex">
            <div class="col-1 l-label">SEX</div>
            <div class="col-1">:&nbsp;{{ patient.gender }}</div>
          </template>
        </div>
        <div class="row no-gutters">
          <template v-if="printLabelConfig.printLabel.patientTest">
            <div class="col-2 s-label">TEST</div>
            <div class="col-3 s-text">:&nbsp;{{ patient.test }}</div>
          </template>
          <template v-if="printLabelConfig.printLabel.mobile">
            <div class="col-3 l-label">MOBILE NUMBER</div>
            <div class="col-4">:&nbsp;{{ patient.mobile }}</div>
          </template>
        </div>
        <div class="row no-gutters">
          <template v-if="printLabelConfig.printLabel.address">
            <div class="col-2 s-label">ADDRESS</div>
            <div class="col-10">:&nbsp;{{ patient.address }}</div>
          </template>
        </div>
        <div class="row no-gutters">
          <div class="col-2 s-label">DATE</div>
          <div class="col-10">:&nbsp;{{new Date() | formatVDDate({ format: 'DD/MM/YYYY HH:mm A', lang: $i18n.locale }) }}</div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dateformatter from '@/components/mixins/dateformatter';
import moment from 'moment';
import { createNamespacedHelpers } from 'vuex';
import patientMixin from '@/components/mixins/patient';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

export default {
  name: 'PatientLabel',
  mixins: [dateformatter, patientMixin],
  props: {
    clinic: {
      type: Object,
      default() {
        return { };
      },
    },
    patient: {
      type: Object,
      default() {
        return { };
      },
    },
    preview: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapConfigGetters({ printLabelConfig: 'patient' }),
    ...mapConfigGetters(['general']),
    labelAddress() {
      if (this.general.useLocationAddress) {
        return this.selectedClinic.address || '';
      }
      return this.general.address || '';
    },
    age() {
      if (this.preview) return '<Patient Age>';
      return this.formatAge(moment(this.patient.dateOfBirth));
    },
  },
  mounted() {
    if (!this.preview) {
      setTimeout(() => {
        window.print();
      }, 2000);
    }
  },
};
</script>

<style scoped src="../../assets/print.css"></style>
<style scoped lang="scss">
  .previewPrint {
    background-color: white;
    font-size: 0.1em !important;
    padding: 20px;
    padding-left: 35px;
    min-height: 50em;
  }
  @media print {
    .print-body {
      font-family:  Helvetica;
      font-weight: 500;
      max-height: 38em;
      max-width: 90em;
      .label-container {
        margin-left: -7pt;
        background: red;
        margin-right: 10px;
        .address-font {
          font-size: 14px;
        }
        .line {
          margin-right: 10pt;
        }
      }
    }
    .text-font{
      font-size: 14px;
      line-height: 1.25;
      max-width: 90em;
      .s-label {
        max-width: 60pt;
      }
      .s-text {
        max-width: 80pt;
      }
      .l-label {
        max-width: 95pt;
      }
    }
    .heading-font{
      font-size: 14px;
    }
  }
</style>
