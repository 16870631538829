import api from './api';

export default {
  getQuestionnaire(id) {
    return api.get(`/questionnaire/${id}`);
  },
  createNew(data) {
    return api.post('/questionnaire', data);
  },
  updateQuestionnaire(id, data) {
    return api.put(`/questionnaire/${id}`, data);
  },
  async getLatestPrefillData(patientId) {
    return api.get(`/health-screening/patient/${patientId}/qs-result`);
  },
};
