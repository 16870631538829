import api from "./api";

export default {
  fetchAll(params) {
    const user = JSON.parse(localStorage.getItem("user"));
    const url = `/receive-hr-notification/list/${user.id}`;
    return api.post(url, params);
  },
  updateReceiveHrNotification(data) {
    return api.put(`/receive-hr-notification`, data);
  },
  getReceiveHrNotificationById(id) {
    const user = JSON.parse(localStorage.getItem("user"));
    return api.get(`/receive-hr-notification/${user.id}/${id}`);
  },
  getReceiveHrNotificationUnCompletedCount() {
    const user = JSON.parse(localStorage.getItem("user"));
    return api.get(`/receive-hr-notification/uncompleted/${user.id}`);
  }
};
