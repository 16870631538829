
import api from '@/services/api';

export default {
  list(params, forReport) {
    const q = [
      'sort=priority',
      // 'sort=-createdAt',
      // 'page=1',
      // 'limit=5',
      // 'from=1555372800000',
      // 'to=1558001925000',
      `t=${new Date().getTime()}`,
    ];

    if (params) {
      Object.keys(params).forEach((key) => {
        q.push(`${key}=${params[key]}`);
      });
    }
    let url = `/queue/?${q.join('&')}`;
    if (forReport) {
      url = `/queue/queue-list?${q.join('&')}`;
    }
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`SYSERR: ${JSON.stringify(response)}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },
  // chan
  outpatients(params) {
    const q = [
      'sort=priority',
      `t=${new Date().getTime()}`,
    ];

    if (params) {
      Object.keys(params).forEach((key) => {
        q.push(`${key}=${params[key]}`);
      });
    }
    const url = `/queue/outpatient?${q.join('&')}`;
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`SYSERR: ${JSON.stringify(response)}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },

  getById(id) {
    const url = `/queue/${id}`;

    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`Error: ${response.data.message}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },

  create(payload) {
    const url = '/queue/';

    return new Promise((resolve, reject) => {
      api.post(url, payload).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`Error: ${response.data.message}`),
              { data: null, success: false },
            );
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },

  bulkCreate(time, payload) {
    const url = '/queue/bulk';
    return new Promise((resolve, reject) => {
      api.post(`${url}?queue_time=${time}`, payload)
        .then(
          (response) => {
            if (response.ok) {
              const data = response.body;
              resolve({ data, message: '', success: true });
            } else {
              reject(
                new Error(`Error: ${response.data.message}`),
                { data: null, success: false },
              );
            }
          },
          (response) => {
            reject(
              new Error(`Request Error: ${response}, url: ${url}`),
              { data: null, success: false },
            );
          },
        );
    });
  },

  update(id, payload) {
    const url = `/queue/update/${id}`;

    return new Promise((resolve, reject) => {
      api.put(url, payload).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`Error: ${response.data.message}`),
              { data: null, success: false },
            );
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },

  call(id, payload) {
    const url = `/queue/call-queue/${id}`;

    return new Promise((resolve, reject) => {
      api.put(url, payload).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`Error: ${response.data.message}`),
              { data: null, success: false },
            );
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },

  updateBulk(payload) {
    const url = '/queue/updateBulk';

    return new Promise((resolve, reject) => {
      api.put(url, payload).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`Error: ${response.data.message}`),
              { data: null, success: false },
            );
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },

  remove(id) {
    const url = `/queue/${id}`;

    return new Promise((resolve, reject) => {
      api.delete(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`Error: ${response.data.message}`),
              { data: null, success: false },
            );
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },

  removeBulk(params) {
    const url = '/queue/removeBulk';

    return new Promise((resolve, reject) => {
      api.post(url, params).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`Error: ${response.data.message}`),
              { data: null, success: false },
            );
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },

  removeSecondaryProvider(id, providerId) {
    const url = `/queue/${id}/secondaryProvider/${providerId}`;

    return new Promise((resolve, reject) => {
      api.delete(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`Error: ${response.data.message}`),
              { data: null, success: false },
            );
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },
  checkPatient(payload) {
    const url = `/appointment/appt-qr-attended/${payload.id}/`;
    return new Promise((resolve, reject) => {
      api.put(url, payload).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({
              data, message: '', success: true, error: null,
            });
          } else {
            reject(new Error(JSON.stringify(response.body.message)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },
};
