import _ from 'lodash';

import api from './api';
// import xyCache from './xy.cache';

const MODULE_PATH = 'tcm-consultation';
const VISITRECORD_PATH = 'tcm-visit-record';
const REFERENCE_PATH = 'tcm-reference';
const ORDER_PATH = 'tcm-order';
const REFERER_PATH = 'tcm-referer';
const HERBAL_ORDER_PATH = 'tcm-herbal-order';

export default {
  list(params) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);
    if (params) {
      Object.keys(params).forEach((key) => {
        q.push(`${key}=${params[key]}`);
      });
    }

    const url = `/${MODULE_PATH}/?${q.join('&')}`;
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`List Error: ${response}, url: ${url}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },

  create(payload) {
    const url = `/${MODULE_PATH}/create`;

    return new Promise((resolve, reject) => {
      api.post(url, payload).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({
              data, message: '', success: true, error: null,
            });
          } else {
            // TODO: Chan : Make this cooler
            // get at least one from array of errors
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },

  patch(id, payload) {
    const url = `/${MODULE_PATH}/patch/${id}`;

    return new Promise((resolve, reject) => {
      api.patch(url, payload).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({
              data, message: '', success: true, error: null,
            });
          } else {
            // TODO: Chan : Make this cooler
            // get at least one from array of errors
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },

  copy(payload) {
    const url = `/${MODULE_PATH}/copy`;

    return new Promise((resolve, reject) => {
      api.post(url, payload).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({
              data, message: '', success: true, error: null,
            });
          } else {
            // TODO: Chan : Make this cooler
            // get at least one from array of errors
            const { status, body } = response;
            if (status === 404 || status === 422) {
              if (typeof body.message === 'string') {
                const errors = [body.message || ''];
                reject(new Error(JSON.stringify(errors)));
              }
            }
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },

  patchVisitRecord(id, payload) {
    const url = `/${VISITRECORD_PATH}/patch/${id}`;

    return new Promise((resolve, reject) => {
      api.patch(url, payload).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({
              data, message: '', success: true, error: null,
            });
          } else {
            // TODO: Chan : Make this cooler
            // get at least one from array of errors
            const { status, body } = response;
            if (status === 404 || status === 422) {
              if (typeof body.message === 'string') {
                const errors = [body.message || ''];
                reject(new Error(JSON.stringify(errors)));
              }
            }
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },

  listVisitRecordTemplates(params) {
    const q = ['isTemplate=true'];
    q.push();
    if (params) {
      Object.keys(params).forEach((key) => {
        q.push(`${key}=${params[key]}`);
      });
    }
    const url = `/${VISITRECORD_PATH}/?${q.join('&')}`;

    return new Promise((resolve, reject) => {
      /*
      const cached = xyCache.get(url);
      if (cached) {
        console.log('from cache tcm.visitrecord.listtemplates');
        resolve({
          data: cached, message: '', success: true, error: null,
        });
        return;
      }
      */
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            // xyCache.set(url, data, 60); // cache for an hour
            resolve({
              data, message: '', success: true, error: null,
            });
          } else {
            // TODO: Chan : Make this cooler
            // get at least one from array of errors
            const { status, body } = response;
            if (status === 404 || status === 422) {
              if (typeof body.message === 'string') {
                const errors = [body.message || ''];
                reject(new Error(JSON.stringify(errors)));
              }
            }
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },

  setAsTemplate(id, payload) {
    const url = `/${VISITRECORD_PATH}/create-template/${id}`;

    return new Promise((resolve, reject) => {
      api.post(url, payload).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({
              data, message: '', success: true, error: null,
            });
          } else {
            // TODO: Chan : Make this cooler
            // get at least one from array of errors
            const { status, body } = response;
            if (status === 404 || status === 422) {
              if (typeof body.message === 'string') {
                const errors = [body.message || ''];
                reject(new Error(JSON.stringify(errors)));
              }
            }
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },

  deleteTemplate(id) {
    const url = `/${VISITRECORD_PATH}/delete-template/${id}`;

    return new Promise((resolve, reject) => {
      api.post(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({
              data, message: '', success: true, error: null,
            });
          } else {
            // TODO: Chan : Make this cooler
            // get at least one from array of errors
            const { status, body } = response;
            if (status === 404 || status === 422) {
              if (typeof body.message === 'string') {
                const errors = [body.message || ''];
                reject(new Error(JSON.stringify(errors)));
              }
            }
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },

  getTcmReferenceTypes() {
    const url = `/${REFERENCE_PATH}/types`;
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({
              data, message: '', success: true, error: null,
            });
          } else {
            const { status, body } = response;
            if (status === 404 || status === 422) {
              if (typeof body.message === 'string') {
                const errors = [body.message || ''];
                reject(new Error(JSON.stringify(errors)));
                return;
              }
            }
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },
  deleteTcmReferences(types) {
    const url = `/${REFERENCE_PATH}/index`;
    return new Promise((resolve, reject) => {
      api.delete(url, { types }).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({
              data, message: '', success: true, error: null,
            });
          } else {
            const { status, body } = response;
            if (status === 404 || status === 422) {
              if (typeof body.message === 'string') {
                const errors = [body.message || ''];
                reject(new Error(JSON.stringify(errors)));
                return;
              }
            }
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },
  downloadReferenceType(params) {
    const q = [];
    if (params) {
      Object.keys(params).forEach((key) => {
        q.push(`${key}=${params[key]}`);
      });
    }

    const url = `/${REFERENCE_PATH}/download/?${q.join('&')}`;

    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({
              data, message: '', success: true, error: null,
            });
          } else {
            const { status, body } = response;
            if (status === 404 || status === 422) {
              if (typeof body.message === 'string') {
                const errors = [body.message || ''];
                reject(new Error(JSON.stringify(errors)));
                return;
              }
            }
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },
  uploadReferenceFile(payload, onProgress = function fn() {}) {
    const url = `/${REFERENCE_PATH}/upload`;

    return new Promise((resolve, reject) => {
      api.upload(url, payload, onProgress).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({
              data, message: '', success: true, error: null,
            });
          } else {
            // TODO: Chan : Make this cooler
            // get at least one from array of errors
            const { status, body } = response;
            if (status === 404 || status === 422) {
              if (typeof body.message === 'string') {
                const errors = [body.message || ''];
                reject(new Error(JSON.stringify(errors)));
              }
            }
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },
  getReferenceIndex() {
    // TODO: cache
    const url = `/${REFERENCE_PATH}/index`;

    return new Promise((resolve, reject) => {
      // TODO: adapter
      //
      //
      /*

      const cached = xyCache.get(url);
      if (cached) {
        console.log('from cache tcm.reference.index');
        resolve({
          data: cached, message: '', success: true, error: null,
        });
        return;
      }
      */
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            // xyCache.set(url, data);
            resolve({
              data, message: '', success: true, error: null,
            });
          } else {
            // TODO: Chan : Make this cooler
            // get at least one from array of errors
            const { status, body } = response;
            if (status === 404 || status === 422) {
              if (typeof body.message === 'string') {
                const errors = [body.message || ''];
                reject(new Error(JSON.stringify(errors)));
                return;
              }
            }
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },

  listReference(params) {
    const q = [];
    if (params) {
      Object.keys(params).forEach((key) => {
        q.push(`${key}=${params[key]}`);
      });
    }

    const url = `/${REFERENCE_PATH}/list/?${q.join('&')}`;

    return new Promise((resolve, reject) => {
      // TODO: adapter

      /*
      const cached = xyCache.get(url);
      if (cached) {
        console.log('from cache tcm.reference.list');
        resolve({
          data: cached, message: '', success: true, error: null,
        });
        return;
      }
      */
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            // xyCache.set(url, data);
            resolve({
              data, message: '', success: true, error: null,
            });
          } else {
            // TODO: Chan : Make this cooler
            // get at least one from array of errors
            const { status, body } = response;
            if (status === 404 || status === 422) {
              if (typeof body.message === 'string') {
                const errors = [body.message || ''];
                reject(new Error(JSON.stringify(errors)));
                return;
              }
            }
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },

  addNewPS(payload) {
    const url = `/${ORDER_PATH}/new`;

    return new Promise((resolve, reject) => {
      api.post(url, payload).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({
              data, message: '', success: true, error: null,
            });
          } else {
            // TODO: Chan : Make this cooler
            // get at least one from array of errors
            const { status, body } = response;
            if (status === 404 || status === 422) {
              if (typeof body.message === 'string') {
                const errors = [body.message || ''];
                reject(new Error(JSON.stringify(errors)));
                return;
              }
            }
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },

  updateInvoice(id, payload, closeInvoice = false) {
    const url = `/${ORDER_PATH}/${id}?close=${closeInvoice}`;
    return new Promise((resolve, reject) => {
      api.put(url, payload).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`Error: ${response.data.message}`),
              { data: null, success: false },
            );
          }
        },
        (response) => {
          reject(
            new Error(`${(response.data || {}).message}`),
            { data: null, success: false },
          );
        },
      );
    });
  },

  getInvoiceByShowId(id) {
    const url = `/${ORDER_PATH}/invshow/${id}`;

    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`Error: ${response.data.message}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },

  listInvoiceTemplates(params) {
    const q = ['isTemplate=true'];
    if (params) {
      Object.keys(params).forEach((key) => {
        q.push(`${key}=${params[key]}`);
      });
    }
    const url = `/${ORDER_PATH}/list?${q.join('&')}`;

    return new Promise((resolve, reject) => {
      /*
      const cached = xyCache.get(url);
      if (cached) {
        console.log('from cache tcm.visitrecord.listtemplates');
        resolve({
          data: cached, message: '', success: true, error: null,
        });
        return;
      }
      */
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            // xyCache.set(url, data, 60); // cache for an hour
            resolve({
              data, message: '', success: true, error: null,
            });
          } else {
            // TODO: Chan : Make this cooler
            // get at least one from array of errors
            const { status, body } = response;
            if (status === 404 || status === 422) {
              if (typeof body.message === 'string') {
                const errors = [body.message || ''];
                reject(new Error(JSON.stringify(errors)));
              }
            }
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },

  listInvoice(params) {
    const q = ['isTemplate=false'];
    if (params) {
      Object.keys(params).forEach((key) => {
        q.push(`${key}=${params[key]}`);
      });
    }
    const url = `/${ORDER_PATH}/list?${q.join('&')}`;

    return new Promise((resolve, reject) => {
      /*
      const cached = xyCache.get(url);
      if (cached) {
        console.log('from cache tcm.visitrecord.listtemplates');
        resolve({
          data: cached, message: '', success: true, error: null,
        });
        return;
      }
      */
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            // xyCache.set(url, data, 60); // cache for an hour
            resolve({
              data, message: '', success: true, error: null,
            });
          } else {
            // TODO: Chan : Make this cooler
            // get at least one from array of errors
            const { status, body } = response;
            if (status === 404 || status === 422) {
              if (typeof body.message === 'string') {
                const errors = [body.message || ''];
                reject(new Error(JSON.stringify(errors)));
              }
            }
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },


  setAsInvoiceTemplate(id, payload) {
    const url = `/${ORDER_PATH}/create-template/${id}`;

    return new Promise((resolve, reject) => {
      api.post(url, payload).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({
              data, message: '', success: true, error: null,
            });
          } else {
            // TODO: Chan : Make this cooler
            // get at least one from array of errors
            const { status, body } = response;
            if (status === 404 || status === 422) {
              if (typeof body.message === 'string') {
                const errors = [body.message || ''];
                reject(new Error(JSON.stringify(errors)));
              }
            }
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },

  deleteInvoiceTemplate(id) {
    const url = `/${ORDER_PATH}/delete-template/${id}`;

    return new Promise((resolve, reject) => {
      api.post(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({
              data, message: '', success: true, error: null,
            });
          } else {
            // TODO: Chan : Make this cooler
            // get at least one from array of errors
            const { status, body } = response;
            if (status === 404 || status === 422) {
              if (typeof body.message === 'string') {
                const errors = [body.message || ''];
                reject(new Error(JSON.stringify(errors)));
              }
            }
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },

  patchInvoice(id, payload) {
    const url = `/${ORDER_PATH}/patch/${id}`;

    return new Promise((resolve, reject) => {
      api.patch(url, payload).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({
              data, message: '', success: true, error: null,
            });
          } else {
            // TODO: Chan : Make this cooler
            // get at least one from array of errors
            const { status, body } = response;
            if (status === 404 || status === 422) {
              if (typeof body.message === 'string') {
                const errors = [body.message || ''];
                reject(new Error(JSON.stringify(errors)));
              }
            }
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },
  listReferer(params) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);
    if (params) {
      Object.keys(params).forEach((key) => {
        q.push(`${key}=${params[key]}`);
      });
    }

    const url = `/${REFERER_PATH}/list/?${q.join('&')}`;
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`List Error: ${response}, url: ${url}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },
  updateReferer(payload) {
    const url = `/${REFERER_PATH}/update`;

    return new Promise((resolve, reject) => {
      api.put(url, payload).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({
              data, message: '', success: true, error: null,
            });
          } else {
            // TODO: Chan : Make this cooler
            // get at least one from array of errors
            const { status, body } = response;
            if (status === 404 || status === 422) {
              if (typeof body.message === 'string') {
                const errors = [body.message || ''];
                reject(new Error(JSON.stringify(errors)));
              }
            }
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },

  removeReferer(payload) {
    const url = `/${REFERER_PATH}/remove`;

    return new Promise((resolve, reject) => {
      api.put(url, payload).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({
              data, message: '', success: true, error: null,
            });
          } else {
            // TODO: Chan : Make this cooler
            // get at least one from array of errors
            const { status, body } = response;
            if (status === 404 || status === 422) {
              if (typeof body.message === 'string') {
                const errors = [body.message || ''];
                reject(new Error(JSON.stringify(errors)));
              }
            }
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },

  createReferer(payload) {
    const url = `/${REFERER_PATH}/create`;

    return new Promise((resolve, reject) => {
      api.post(url, payload).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({
              data, message: '', success: true, error: null,
            });
          } else {
            // TODO: Chan : Make this cooler
            // get at least one from array of errors
            const { status, body } = response;
            if (status === 404 || status === 422) {
              if (typeof body.message === 'string') {
                const errors = [body.message || ''];
                reject(new Error(JSON.stringify(errors)));
              }
            }
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },
  listHerbalOrder(params) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);
    if (params) {
      Object.keys(params).forEach((key) => {
        q.push(`${key}=${params[key]}`);
      });
    }

    const url = `/${HERBAL_ORDER_PATH}/?${q.join('&')}`;
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`List Error: ${response}, url: ${url}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },

  createHerbalOrder(payload) {
    const url = `/${HERBAL_ORDER_PATH}/create`;

    return new Promise((resolve, reject) => {
      api.post(url, payload).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({
              data, message: '', success: true, error: null,
            });
          } else {
            // TODO: Chan : Make this cooler
            // get at least one from array of errors
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },

  patchHerbalOrder(id, payload) {
    const url = `/${HERBAL_ORDER_PATH}/patch/${id}`;

    return new Promise((resolve, reject) => {
      api.patch(url, payload).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({
              data, message: '', success: true, error: null,
            });
          } else {
            // TODO: Chan : Make this cooler
            // get at least one from array of errors
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },
  sendEmail(payload) {
    const url = `/${HERBAL_ORDER_PATH}/sendToWYN`;
    return new Promise((resolve, reject) => {
      api.post(url, payload).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({
              data, message: '', success: true, error: null,
            });
          } else {
            // TODO: Chan : Make this cooler
            // get at least one from array of errors
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },
};
