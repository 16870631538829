var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mb-3" }, [
    _c(
      "div",
      _vm._l(_vm.medLineItems, function(item) {
        return _c("div", { key: item.key, staticClass: "row my-4" }, [
          _c("div", { staticClass: "col-3" }, [
            _vm._v(_vm._s(item.medicine.name))
          ]),
          _c("div", { staticClass: "col-3" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.regionData.currency) +
                _vm._s(
                  _vm._f("thousandSeparator")(
                    _vm._f("to2Decimal")(item.unitPrice)
                  )
                ) +
                "\n      "
            )
          ]),
          _c("div", { staticClass: "col-3" }, [
            _vm._v(
              _vm._s(item.quantity) + " " + _vm._s(item.medicine.unit || "")
            )
          ]),
          _c("div", { staticClass: "col-3" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.regionData.currency) +
                "\n        " +
                _vm._s(
                  _vm._f("thousandSeparator")(
                    _vm._f("to2Decimal")(
                      _vm.singleItemTotal(item.unitPrice, item.quantity)
                    )
                  )
                ) +
                "\n      "
            )
          ])
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }