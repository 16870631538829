import Vue from 'vue';
import objectToFormData from 'object-to-formdata';

export default {
  get(path, options = {}) {
    console.log('process.env.VUE_APP_apiUrl + path', process.env.VUE_APP_apiUrl + path);
    const apiurl = process.env.VUE_APP_apiUrl
      ? process.env.VUE_APP_apiUrl
      : `${window.location.origin}/api`;
    return Vue.http.get(apiurl + path, options)
      .then(
        response => response,
        response => response,
      )
      .catch(
        err => err,
      );
  },
  post(path, payload, options = {}) {
    const apiurl = process.env.VUE_APP_apiUrl
      ? process.env.VUE_APP_apiUrl
      : `${window.location.origin}/api`;
    console.log('process.env.VUE_APP_apiUrl + path', process.env.VUE_APP_apiUrl + path);
    return Vue.http.post(apiurl + path, payload, options).then(
      response => response,
      reject => reject,
    );

    // const requestOptions = {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json' },
    //   headers: { 'x-clinic': cliniccode },
    //   body: JSON.stringify({email: username, password}),
    // };

    // return fetch(`${process.env.VUE_APP_apiUrl}/auth/login`, requestOptions);
  },
  patch(path, payload, options = {}) {
    const apiurl = process.env.VUE_APP_apiUrl
      ? process.env.VUE_APP_apiUrl
      : `${window.location.origin}/api`;
    return Vue.http.patch(apiurl + path, payload, options).then(
      response => response,
      response => response,
    );
  },
  put(path, payload, options = {}) {
    const apiurl = process.env.VUE_APP_apiUrl
      ? process.env.VUE_APP_apiUrl
      : `${window.location.origin}/api`;
    return Vue.http.put(apiurl + path, payload, options);
  },
  delete(path, payload = undefined) {
    const apiurl = process.env.VUE_APP_apiUrl
      ? process.env.VUE_APP_apiUrl
      : `${window.location.origin}/api`;
    return Vue.http.delete(apiurl + path, payload ? { body: payload } : undefined).then(
      response => response,
      response => response,
    );
  },
  upload(path, payload, onProgress) {
    const apiurl = process.env.VUE_APP_apiUrl
      ? process.env.VUE_APP_apiUrl
      : `${window.location.origin}/api`;
    return Vue.http.post(apiurl + path, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      progress: (e) => {
        this.progress = (e.loaded / e.total) * 100;
        if (typeof onProgress === 'function') {
          onProgress(e);
        }
      },
    }).then(
      response => response,
      response => response,
    );
  },
  uploadImageApi(path, payload) {
    const apiurl = process.env.VUE_APP_imgApiUrl || process.env.VUE_APP_apiUrl || `${window.location.origin}/api`;
    return Vue.http.post(apiurl + path, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(
      response => response,
      reject => reject,
    );
  },
  multiPartPost(path, payload, fileObj = {}) {
    const formData = objectToFormData(payload, { indices: true });
    Object.entries(fileObj).forEach(([key, val]) => {
      formData.append(key, val);
    });
    const apiurl = process.env.VUE_APP_apiUrl
      ? process.env.VUE_APP_apiUrl
      : `${window.location.origin}/api`;
    return Vue.http.post(apiurl + path, formData)
      .then(
        response => response,
        reject => reject,
      );
  },
  multiPartPut(path, payload, fileObj = {}) {
    const formData = objectToFormData(payload, { indices: true });
    Object.entries(fileObj).forEach(([key, val]) => {
      formData.append(key, val);
    });
    const apiurl = process.env.VUE_APP_apiUrl
      ? process.env.VUE_APP_apiUrl
      : `${window.location.origin}/api`;
    return Vue.http.put(apiurl + path, formData)
      .then(
        response => response,
        reject => reject,
      );
  },
  multiPartPatch(path, fileObj = {}) {
    const formData = objectToFormData({}, { indices: true });
    Object.entries(fileObj).forEach(([key, val]) => {
      formData.append(key, val);
    });
    const apiurl = process.env.VUE_APP_apiUrl
      ? process.env.VUE_APP_apiUrl
      : `${window.location.origin}/api`;
    return Vue.http.patch(apiurl + path, formData)
      .then(
        response => response,
        reject => reject,
      );
  },
  lotusPost(url, payload) {
    return Vue.http.post(url, payload).then(
      response => response,
      reject => reject,
    );
  },
  saveBlob(blob, fileName) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  },
};
