<template>
    <div id="drop-area" class="drop-area">
    </div>
</template>

<script>
export default {
    name: 'UploadField',
    props: ['filesDropped', 'invoiceId'],
    components: {},
    mixins: [],
    data() {
        return {
            dropArea: null
        }
    },
    watch: {

    },
    computed: {
        dropAreaId() {
            return `drop-area-${this.invoiceId}`;
        }
    },
    methods: {
    },
    created() {
        // console.log('upload field created! this.invoiceId is ',this.invoiceId);
        // console.log('upload field created! elem is ', document.getElementById(`drop-area-${this.invoiceId}`));
        this.dropArea = document.getElementById(`drop-area`);
    },
    mounted() {
        let uploadProgress = []
        let progressBar = document.getElementById('progress-bar')
        const self = this;
        // console.log('progressBar, filesDropped is ', progressBar, this.filesDropped);

        function initializeProgress(numFiles) {
            progressBar.value = 0
            uploadProgress = []

            for(let i = numFiles; i > 0; i--) {
                uploadProgress.push(0)
            }
        }

        function updateProgress(fileNumber, percent) {
            uploadProgress[fileNumber] = percent
            let total = uploadProgress.reduce((tot, curr) => tot + curr, 0) / uploadProgress.length
            progressBar.value = total
        }
        // const self = this;
        self.dropArea = document.getElementById('drop-area');
        if (self.dropArea !== undefined && self.dropArea !== null) {
            // console.log('1');
            function preventDefaults (e) {
                e.preventDefault()
                e.stopPropagation()
            }
            function highlight(e) {
                self.dropArea.classList.add('highlight');
            }
            function unhighlight(e) {
                self.dropArea.classList.remove('highlight');
            }
            function handleFiles(files) {
                if (files) {
                    files = [...files]
                    files.forEach(self.filesDropped);
                }
            }
            function handleDrop(e) {
                // console.log('in handleDrop, invoiceId is ', self.invoiceId);
                let dt = e.dataTransfer
                let files = dt.files
                // console.log('dt and files are ', dt, files);
                if (files) {
                    handleFiles(files)
                }
            }
            console.log('self.dropArea is ', self.dropArea);
            ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
                // console.log('1 eventName, self.dropArea is ', eventName, self.dropArea);
                self.dropArea.addEventListener(eventName, preventDefaults, false)
            })
            console.log('2');
            ['dragenter', 'dragover'].forEach(eventName => {
                // console.log('2 eventName, self.dropArea is ', eventName, self.dropArea);
                self.dropArea.addEventListener(eventName, highlight, false)
            })
            console.log('3 - self.dropArea ', self.dropArea);
            ['dragleave', 'drop'].forEach(eventName => {
                // console.log('3 eventName, self.dropArea is ', eventName, self.dropArea);
                self.dropArea.addEventListener(eventName, unhighlight, false)
            })
            console.log('4');
            

            self.dropArea.addEventListener('drop', handleDrop, false)
        }
    }
}
</script>

<style lang="scss" scoped>
.drop-area {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
.drop-area.highlight {
    border: 10px solid grey;
}
p {
  margin-top: 0;
}
.my-form {
  margin-bottom: 10px;
}
#gallery {
  margin-top: 10px;
}
#gallery img {
  width: 150px;
  margin-bottom: 10px;
  margin-right: 10px;
  vertical-align: middle;
}
.button {
  display: inline-block;
  padding: 10px;
  background: #ccc;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #ccc;
}
.button:hover {
  background: #ddd;
}
#fileElem {
  display: none;
}
</style>
