<template>
<div id="queue-print-section">
  <p>Welcome, you are</p>
  <p>currently in the queue.</p>
  <h3>Ticket Number:</h3>
  <h1 id="queue-number"> {{ QUEUE_CALLNUMBER_PREFIX[queueObj.call_number_status] + ' ' + queueObj.call_number }} </h1>
  <p>Your number will be called shortly,</p>
  <p>thank you for waiting</p>
</div>
</template>

<script>
import constant from '@/services/constant';
import { createNamespacedHelpers } from 'vuex';
import print from 'print-js';

const { mapGetters: mapQueueGetters } = createNamespacedHelpers('queue');

export default {
  name: 'PrintQueueCard',
  data() {
    return {
      printLabelConfig: {},
    };
  },
  computed: {
    ...mapQueueGetters({
      queueObj: 'queueForPrint',
    }),
    QUEUE_CALLNUMBER_PREFIX() {
      return constant.QUEUE_CALLNUMBER_PREFIX;
    },
  },
  mounted() {
    print({
      printable: 'queue-print-section',
      type: 'html',
      ignoreElements: ['app'],
      targetStyles: ['*'],
      onPrintDialogClose: function() {
        window.close();
      }
    });
  }
}
</script>


<style lang="scss">
#queue-print-section {
  size: 80mm 80mm;
  margin: 0mm;

  width: 80mm;
  height: auto;
  box-sizing: border-box;

  background-color: white;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  padding-top: 0.4em;
  padding-bottom: 0.4em;

  p {
    width: 100%;
    text-align: center;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    font-weight: 300;
  }

  h3 {
    text-align: center;
    margin-top: 1.5rem;
    margin-bottom: 0px;
  }

  h1 {
    text-align: center;
    margin-top: 0;
    margin-bottom: 1.5rem;
    font-size: 4.5rem !important;
  }
}
</style>