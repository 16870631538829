<template>
    <div class="mt-3 tph-print-invoice" style="margin: 20px">
        <div class="row mb-3 big-fat-divider pb-3">
            <div class="col-4">
                <img
                  :style="{ marginLeft:`${logoMarginLeft}px`, width:`${logoWidth}px` }"
                  :src="general.logoURL">
            </div>
            <div class="col-8 text-right clinic-address">
                <p>#16, 17 & 18, Street 21, Sangkat Tonle Bassac, Khan Chamkarmon, Phnom Penh, Cambodia</p>
                <p>ទូរស័ព្ទលេខ/Telephone No. +855 23 266 999</p>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col text-center">
                <h5>វិក្កយបត្រ</h5>
                <h5>INVOICE</h5>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-8 text-left">
                <div class="patient-label">
                   ឈ្មោះអ្នកជំងឺ / Patient Name:
                    <span class="font-weight-bold">{{ selectedPatient.name }}</span>
                </div>
                <div class="patient-label">
                  លេខសំគាល់អ្នកជំងឺ / Patient ID:
                   <span class="font-weight-bold" v-if="selectedPatient.givenIdPrefix">{{ selectedPatient.givenIdPrefix }}</span>
                   <span class="font-weight-bold">{{ selectedPatient.givenId }}</span>
                </div>
                <!-- <div class="patient-label">
                  េǜõǓះសហ£ǖស / Enterprise Name:
                </div>
                <div class="patient-label">
                    អសយǞëǓន / Address:
                </div> -->
                <div class="patient-label">
                    ទូរស័ព្ទលេខ /​ Telephone No.:
                    <span class="font-weight-bold">{{ selectedPatient.mobile }}</span>
                </div>
                <!-- <div class="patient-label">
                     េលខអតសǽʲǓណកម¡ǳរេពើពន/ Tax Identification Number (TIN):
                    <span class="tin"></span>
                    (£បសិនǛǬន / If any)
                </div> -->
            </div>
            <div class="col-4 text-right">
                <div class="patient-label">
                   លេខរៀងវិក្កយបត្រ/Invoice No: <span class="text-vd-danger"> {{ print.invoiceNo }} </span>
                </div>
                <div class="patient-label">
                    កាលបរិច្ឆេទ/Date: <span class="text-vd-danger">{{ print.date | ddMonthYYYYFromX }}</span>
                </div>
            </div>
        </div>
        <div class="row mb-5">
            <div class="col" v-if="isCategorized">
                <table class="vd-print-table">
                        <tr class="table-border-top-thick table-border-bottom-thick">
                            <th class="text-center">
                                <span>ឈ្មោះមុខទំនិញឬសេវា</span><br/>
                                <span>Item Name</span>
                            </th>
                            <th class="text-center">
                                <span>ការបរិយាយ</span><br/>
                                <span>Description</span>
                            </th>
                            <th class="text-right" width="100px">
                                <span>បរិមាណ</span><br/>
                                <span>Quantity</span>
                            </th>
                            <th class="text-right" width="200px">
                                <span>ថ្លៃឯកតា</span><br/>
                                <span>Unit Price</span>
                            </th>
                            <th class="text-right" width="100px" v-if="hasAtLeastOneItemWithDiscount">
                                <span>បញ្ចុះតំលៃ</span><br/>
                                <span>Discount</span>
                            </th>
                            <!-- <th class="text-center" width='100px'>
                                <span>អតប</span><br/>
                                <span>VAT</span>
                            </th> -->
                            <th class="text-right" width="250px">
                                <span>ថ្លៃទំនិញ</span><br/>
                                <span>Amount</span>
                            </th>
                        </tr>
                    <tbody
                      v-for="(category, name) in categorized" :key="category.key">
                        <!-- <tr v-for="(category, idx) in categorized" :key="category.key">
                          <td colspan="7 text-weight-bold">{{ idx }}</td>
                        </tr> -->
                        <tr>
                          <td colspan="7" class="font-weight-bold">{{ name }}</td>
                        </tr>
                        <tr v-for="item in category"
                          :key="item.uuid">
                            <td>
                              {{ item.name }}
                              <template v-if="['Medicine', 'Consumables', 'Expendables', 'Product'].includes(item.inventory)">
                                <br/>
                                <span>
                                  - {{ (item.selectedBatch && item.selectedBatch.number) || '' }}
                                </span>
                              </template>
                            </td>
                            <td class="text-center">{{ item.description }}</td>
                            <td class="text-right">{{ item.qty }}</td>
                            <td class="text-right">
                                {{ calculateUnitPrice(item) | to2Decimal(region.decimal) | thousandSeparator(region.thousandSeparator) }}</td>
                            <td class="text-right" v-if="hasAtLeastOneItemWithDiscount">
                                <!-- <span v-if="item.discountType === '%'">
                                    {{ item.discount }}%
                                </span>
                                <span v-else>{{ item.discount | to2Decimal(region.decimal) | thousandSeparator(region.thousandSeparator) }}</span> -->
                                <span >{{ item.discountAmount | to2Decimal(region.decimal) | thousandSeparator(region.thousandSeparator) }}</span>
                            </td>
                            <!-- <td class="text-right">
                                <span v-if="withTaxes">{{ item.tax }}</span>
                                <span v-else>&nbsp;</span>
                            </td> -->
                            <td class="text-right">{{ item.afterTaxPrice | to2Decimal(region.decimal) | thousandSeparator(region.thousandSeparator) }}</td>
                        </tr>
                    </tbody>
                        <tr>
                            <td colspan="2">&nbsp;</td>
                            <td :colspan="footerColumnSpan" class="text-right">Subtotal</td>
                            <td class="text-right">
                                <!-- {{ region.currency }}{{ print.subtotal | to2Decimal(region.decimal) | thousandSeparator(region.thousandSeparator) }} -->
                                {{ region.currency }}{{ (+print.subtotal + +print.totalTax) | to2Decimal(region.decimal) | thousandSeparator(region.thousandSeparator) }}
                            </td>
                        </tr>
                        <tr v-if="parseFloat(print.promotionDiscount) > 0 > 0">
                            <td colspan="2">&nbsp;</td>
                            <td class="text-right" :colspan="footerColumnSpan">Promo Discount</td>
                            <td class="text-right">
                                -{{ region.currency }}{{ print.promotionDiscount | to2Decimal(region.decimal) | thousandSeparator(region.thousandSeparator) }}
                            </td>
                        </tr>
                        <tr v-if="parseFloat(print.totalDiscount) > 0">
                            <td colspan="2">&nbsp;</td>
                            <td class="text-right" :colspan="footerColumnSpan">Total Discount</td>
                            <td class="text-right">
                                -{{ region.currency }}{{ print.totalDiscount | to2Decimal(region.decimal) | thousandSeparator(region.thousandSeparator) }}
                            </td>
                        </tr>
                        <!-- <tr v-if="withTaxes">
                            <td colspan="2">&nbsp;</td>
                            <td class="text-right" colspan="2">Tax</td>
                            <td class="text-right">
                                {{ print.totalTax | to2Decimal(region.decimal) | thousandSeparator(region.thousandSeparator) }}
                            </td>
                        </tr> -->
                        <tr>
                            <td colspan="2">&nbsp;</td>
                            <td class="text-right col-border-top col-border-bottom" :colspan="footerColumnSpan">Invoice Total</td>
                            <td class="col-border-top col-border-bottom text-right">
                                {{ region.currency }}{{ print.totalBeforeRounding | to2Decimal(region.decimal) | thousandSeparator(region.thousandSeparator) }}
                            </td>
                        </tr>
                        <tr v-if="processFees">
                            <td colspan="2">&nbsp;</td>
                            <td class="text-right" :colspan="footerColumnSpan">Processing Fees</td>
                            <td class="text-right">
                                <div v-if="processFees">
                                    {{ region.currency }}{{ processFees | to2Decimal(region.decimal) | thousandSeparator(region.thousandSeparator) }}
                                </div>
                            </td>
                        </tr>
                        <tr v-if="roundOff !== 'NONE' && parseFloat(print.roundingAdjustment) > 0">
                            <td colspan="2">&nbsp;</td>
                            <td class="text-right" :colspan="footerColumnSpan">Rounding Adjustment</td>
                            <td class="text-right">
                                {{ region.currency }}{{print.roundingAdjustment | to2Decimal(region.decimal) | thousandSeparator(region.thousandSeparator) }}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">&nbsp;</td>
                            <td class="text-right font-weight-bold col-border-top-dotted" :colspan="footerColumnSpan">Total Amount Payable</td>
                            <td class="col-border-top-dotted text-right">
                                {{ region.currency }}{{ (print.total + processFees) | to2Decimal(region.decimal) | thousandSeparator(region.thousandSeparator) }}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">&nbsp;</td>
                            <td class="text-right font-weight-bold" :colspan="footerColumnSpan">
                                សរុបរួម (ជារៀល) អត្រា <span class="text-vd-danger">{{ print.exchangeRate }}</span> រៀល/ដុល្លារ
                            </td>
                            <td class="text-right">
                              {{ payableInR | to2Decimal(region.decimal) | thousandSeparator(region.thousandSeparator) }}R
                            </td>
                        </tr>
                        <tr v-for="payment in patientPayments" :key="payment.paymentId">
                            <td :colspan="footerColumnSpan">&nbsp;</td>
                            <td class="text-right" colspan="2">
                                {{ payment.label }} payment received -- Payment Ref: <span class="text-vd-danger">{{ payment.paymentRef }}</span>
                            </td>
                            <td class="text-right"> {{ region.currency }}{{ payment.amount | to2Decimal(region.decimal) | thousandSeparator(region.thousandSeparator) }} </td>
                        </tr>
                        <tr>
                            <td colspan="2">&nbsp;</td>
                            <td class="text-right font-weight-bold" :colspan="footerColumnSpan">
                                Outstanding Balance
                            </td>
                            <td class="text-right">
                                {{ region.currency }}{{ print.ostBalance | to2Decimal(region.decimal) | thousandSeparator(region.thousandSeparator) }}
                            </td>
                        </tr>
                </table>
            </div>
            <div class="col" v-else>
                <table class="vd-print-table">
                        <tr class="table-border-top-thick table-border-bottom-thick">
                            <th class="text-center">
                                <span>ឈ្មោះមុខទំនិញឬសេវា</span><br/>
                                <span>Item Name</span>
                            </th>
                            <th class="text-center">
                                <span>ការបរិយាយ</span><br/>
                                <span>Description</span>
                            </th>
                            <th class="text-right" width="100px">
                                <span>បរិមាណ</span><br/>
                                <span>Quantity</span>
                            </th>
                            <th class="text-right" width="200px">
                                <span>ថ្លៃឯកតា</span><br/>
                                <span>Unit Price</span>
                            </th>
                            <th class="text-right" width="100px" v-if="hasAtLeastOneItemWithDiscount">
                                <span>បញ្ចុះតំលៃ</span><br/>
                                <span>Discount</span>
                            </th>
                            <!-- <th class="text-center" width='100px'>
                                <span>អតប</span><br/>
                                <span>VAT</span>
                            </th> -->
                            <th class="text-right" width="250px">
                                <span>ថ្លៃទំនិញ</span><br/>
                                <span>Amount</span>
                            </th>
                        </tr>
                    <tbody>
                        <tr v-for="item in print.items"
                          :key="item.uuid">
                            <td>
                              {{ item.name }}
                              <template v-if="['Medicine', 'Consumables', 'Expendables', 'Product'].includes(item.inventory)">
                                <br/>
                                <span>
                                  - {{ (item.selectedBatch && item.selectedBatch.number) || '' }}
                                </span>
                              </template>
                            </td>
                            <td class="text-center">{{ item.description }}</td>
                            <td class="text-right">{{ item.qty }}</td>
                            <td class="text-right">
                                {{ calculateUnitPrice(item) | to2Decimal(region.decimal) | thousandSeparator(region.thousandSeparator) }}</td>
                            <td class="text-right" v-if="hasAtLeastOneItemWithDiscount">
                                <!-- <span v-if="item.discountType === '%'">
                                    {{ item.discount }}%
                                </span>
                                <span v-else>{{ item.discount | to2Decimal(region.decimal) | thousandSeparator(region.thousandSeparator) }}</span> -->
                                <span >{{ item.discountAmount | to2Decimal(region.decimal) | thousandSeparator(region.thousandSeparator) }}</span>
                            </td>
                            <!-- <td class="text-right">
                                <span v-if="withTaxes">{{ item.tax }}</span>
                                <span v-else>&nbsp;</span>
                            </td> -->
                            <td class="text-right">{{ item.afterTaxPrice | to2Decimal(region.decimal) | thousandSeparator(region.thousandSeparator) }}</td>
                        </tr>
                    </tbody>
                        <tr>
                            <td colspan="2">&nbsp;</td>
                            <td :colspan="footerColumnSpan" class="text-right">Subtotal</td>
                            <td class="text-right">
                                <!-- {{ region.currency }}{{ print.subtotal | to2Decimal(region.decimal) | thousandSeparator(region.thousandSeparator) }} -->
                                {{ region.currency }}{{ (+print.subtotal + +print.totalTax) | to2Decimal(region.decimal) | thousandSeparator(region.thousandSeparator) }}
                            </td>
                        </tr>
                        <tr v-if="parseFloat(print.promotionDiscount) > 0 > 0">
                            <td colspan="2">&nbsp;</td>
                            <td class="text-right" :colspan="footerColumnSpan">Promo Discount</td>
                            <td class="text-right">
                                -{{ region.currency }}{{ print.promotionDiscount | to2Decimal(region.decimal) | thousandSeparator(region.thousandSeparator) }}
                            </td>
                        </tr>
                        <tr v-if="parseFloat(print.totalDiscount) > 0">
                            <td colspan="2">&nbsp;</td>
                            <td class="text-right" :colspan="footerColumnSpan">Total Discount</td>
                            <td class="text-right">
                                -{{ region.currency }}{{ print.totalDiscount | to2Decimal(region.decimal) | thousandSeparator(region.thousandSeparator) }}
                            </td>
                        </tr>
                        <!-- <tr v-if="withTaxes">
                            <td colspan="2">&nbsp;</td>
                            <td class="text-right" colspan="2">Tax</td>
                            <td class="text-right">
                                {{ print.totalTax | to2Decimal(region.decimal) | thousandSeparator(region.thousandSeparator) }}
                            </td>
                        </tr> -->
                        <tr>
                            <td colspan="2">&nbsp;</td>
                            <td class="text-right col-border-top col-border-bottom" :colspan="footerColumnSpan">Invoice Total</td>
                            <td class="col-border-top col-border-bottom text-right">
                                {{ region.currency }}{{ print.totalBeforeRounding | to2Decimal(region.decimal) | thousandSeparator(region.thousandSeparator) }}
                            </td>
                        </tr>
                        <tr v-if="processFees">
                            <td colspan="2">&nbsp;</td>
                            <td class="text-right" :colspan="footerColumnSpan">Processing Fees</td>
                            <td class="text-right">
                                <div v-if="processFees">
                                  {{ region.currency }}{{ processFees | to2Decimal(region.decimal) | thousandSeparator(region.thousandSeparator) }}
                                </div>
                            </td>
                        </tr>
                        <tr v-if="roundOff !== 'NONE' && parseFloat(print.roundingAdjustment) > 0">
                            <td colspan="2">&nbsp;</td>
                            <td class="text-right" :colspan="footerColumnSpan">Rounding Adjustment</td>
                            <td class="text-right">
                                {{ region.currency }}{{print.roundingAdjustment | to2Decimal(region.decimal) | thousandSeparator(region.thousandSeparator) }}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">&nbsp;</td>
                            <td class="text-right font-weight-bold col-border-top-dotted" :colspan="footerColumnSpan">Total Amount Payable</td>
                            <td class="col-border-top-dotted text-right">
                                {{ region.currency }}{{ (print.total + processFees) | to2Decimal(region.decimal) | thousandSeparator(region.thousandSeparator) }}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">&nbsp;</td>
                            <td class="text-right font-weight-bold" :colspan="footerColumnSpan">
                                សរុបរួម (ជារៀល) អត្រា <span class="text-vd-danger">{{ print.exchangeRate }}</span> រៀល/ដុល្លារ
                            </td>
                            <td class="text-right">
                              {{ payableInR | to2Decimal(region.decimal) | thousandSeparator(region.thousandSeparator) }}R
                            </td>
                        </tr>
                        <tr v-for="payment in patientPayments" :key="payment.paymentId">
                            <td :colspan="footerColumnSpan">&nbsp;</td>
                            <td class="text-right" colspan="2">
                                {{ payment.label }} payment received -- Payment Ref: <span class="text-vd-danger">{{ payment.paymentRef }}</span>
                            </td>
                            <td class="text-right"> {{ region.currency }}{{ payment.amount | to2Decimal(region.decimal) | thousandSeparator(region.thousandSeparator) }} </td>
                        </tr>
                        <tr>
                            <td colspan="2">&nbsp;</td>
                            <td class="text-right font-weight-bold" :colspan="footerColumnSpan">
                                Outstanding Balance
                            </td>
                            <td class="text-right">
                                {{ region.currency }}{{ print.ostBalance | to2Decimal(region.decimal) | thousandSeparator(region.thousandSeparator) }}
                            </td>
                        </tr>
                </table>
            </div>
        </div>
        <div class="row mb-5">
          <div class="col">
            <template v-if="withDiagnosis || printConfig.diagnosis">
              <div class="row weight-600">
                <div class="col-12">{{ $t('general.diagnosis') }}:</div>
              </div>
              <div v-for="(item, index) in print.diagnosis" :key="index">
                <div v-if="multipleICD10Enabled" class="row">
                  <div class="col-3">
                    {{ index === 0 ? 'Primary Diagnosis' :
                       index === 1 ? 'Secondary Diagnosis' :
                       'Additional Diagnosis' }}
                  </div>
                  <div class="col-9">
                    <p v-for="icd in item" :key="icd.uuid">
                      <i>{{ icd && icd.text }}</i>
                    </p>
                  </div>
                </div>
                <div v-else>
                  <p v-for="icd in item" :key="icd.uuid">
                    <i>{{ icd && icd.text }}</i>
                  </p>
                </div>
                <hr v-if="multipleICD10Enabled">
              </div>
            </template>
          </div>
        </div>
        <div class="row mb-5">
          <div class="col">
            <template v-if="corpPayables.length">
            <div class="row weight-600">
              <div class="col-12">{{ $t('general.amountDueFrom') }}:</div>
            </div>

            <div class="row weight-600 bd-top bd-bottom">
              <div class="col-6">{{ $t('general.others') }}</div>
              <div class="col-6">{{ $t('general.amount') }}</div>
            </div>

            <div class="row" v-for="payable in corpPayables" :key="payable.paymentId">
              <div class="col-6">{{ payable.label }}</div>
              <div class="col-6">
                {{ region.currency }}{{ payable.amount | to2Decimal(region.decimal) | thousandSeparator(region.thousandSeparator) }}
              </div>
            </div>
    </template>
          </div>
        </div>
        <div class="row  signature-row">
            <div class="col-3">
                <div class="signature-pad">&nbsp;</div>
                <div class="text-center">អ្នកជំងឺ</div>
                <div class="text-center">(Patient's)</div>
            </div>
            <div class="col-3"></div>
            <div class="col-3"></div>
            <div class="col-3">
                <div class="signature-pad">&nbsp;</div>
                <div class="text-center">អ្នកគិតលុយ</div>
                <div class="text-center">(Cashier's)</div>
            </div>
        </div>
    </div>
</template>

<script>
import dateFormatter from '@/components/mixins/dateformatter';
import moneyFormatter from '@/components/mixins/money';
import invoiceMethodsMixin from '@/components/mixins/invoice';

export default {
  name: 'TaxInvoiceTph',
  mixins: [dateFormatter, moneyFormatter, invoiceMethodsMixin],
  props: {
    selectedPatient: {
      type: Object,
      default: () => ({ corpInsurance: [] }),
    },
    print: {
      type: Object,
      default: () => ({}),
    },
    general: {
      type: Object,
      default: () => ({}),
    },
    printConfig: [Array, Object],
    preview: {
      type: Boolean,
      default: false,
    },
    includeGST: [Boolean, Object],
    roundOff: [String, Object],
    isCategorized: {
      type: Boolean,
    },
    redeem: {
      type: Object,
      default: () => ({}),
    },
    processFees: { type: Number, default: 0 },
    multipleICD10Enabled: { type: Boolean, default: false },
  },
  data() {
    return {
      sortedInventoryByType: { // for categorized invoice printing
        'Professional Fees Consult': [],
        'Professional Fees Procedures': [],
        Facilities: [],
        Consultation: [],
        Laboratory: [],
        Radiology: [],
        Investigation: [],
        Medicine: [],
        Compounded: [],
        Product: [],
        Vaccination: [],
        Injection: [],
        Procedure: [],
        'Nursing Procedure': [],
        Consumables: [],
        Package: [],
        Referral: [],
        Credit: [],
        Misc: [],
      },
    };
  },
  computed: {
    withTaxes() {
      return this.isInvoiceWithGST(this.print, { includeGST: this.includeGST });
    },
    withDiagnosis() {
      if (this.$route && this.$route.query) {
        return this.$route.query.diagnosis === 'true';
      }
      return false;
    },
    logoMarginLeft() {
      if (localStorage.getItem('printSetup')) {
        return JSON.parse(localStorage.getItem('printSetup')).logoMarginLeft;
      }
      return 0;
    },
    logoWidth() {
      if (localStorage.getItem('printSetup')) {
        return JSON.parse(localStorage.getItem('printSetup')).logoWidth;
      }
      return 300;
    },
    patientPayments() {
      const payments = [];
      if (Array.isArray(this.print.payments) && this.print.payments.length) {
        // Loop through the payment records
        this.print.payments.filter(p => (!p.voided)).forEach((p) => {
          if (Array.isArray(p.payments) && p.payments.length) {
            this.processFees = (+this.processFees) + (+p.totalProcessFees);
            // Loop through the patient payments records
            p.payments.forEach((pp) => {
              const patientPayment = {
                paymentId: pp._id,
                paymentRef: p.paymentRef,
                label: pp.label,
                amount: pp.amount,
              };
              payments.push({ ...patientPayment });
            });
          }
        });
      }
      return payments;
    },
    hasAtLeastOneItemWithDiscount() {
      return this.print.items.some(item => item.discount && parseFloat(item.discount) > 0);
    },
    footerColumnSpan() {
      if (parseFloat(this.print.totalDiscount) > 0) return 3;
      return 2;
    },
    categorized() {
      // convert data to object with inventory type as key
      // example: { Medicine: [{...}, {...}], Procedure: [{...}, {...}] }
      const print = this.print.items.reduce((result, a) => {
        const r = result;
        r[a.inventory] = r[a.inventory] || [];
        r[a.inventory].push(a);
        return r;
      }, Object.create(null));

      // add dispense items into the default sorted inventory by type
      const sortedInvType = Object.assign({}, this.sortedInventoryByType);
      const dispensedInvTypes = Object.keys(print);
      for (let i = 0; i < dispensedInvTypes.length; i += 1) {
        sortedInvType[dispensedInvTypes[i]] = print[dispensedInvTypes[i]];
      }

      // remove empty array of inventory type
      const sortedInvTypeKeys = Object.keys(sortedInvType);
      for (let j = 0; j < sortedInvTypeKeys.length; j += 1) {
        if (sortedInvType[sortedInvTypeKeys[j]].length < 1) {
          delete sortedInvType[sortedInvTypeKeys[j]];
        }
      }

      return sortedInvType;
    },
    corpPayables() {
      const payments = [];
      if (Array.isArray(this.print.payments) && this.print.payments.length) {
        // Loop through the payment records
        this.print.payments.filter(p => !p.voided).forEach((p) => {
          if (Array.isArray(p.corpPayments) && p.corpPayments.length) {
            // Loop through the corp payments records
            p.corpPayments.forEach((cp) => {
              const corpPayment = {
                paymentId: cp._id,
                paymentRef: p.paymentRef,
                label: cp.label,
                amount: cp.amount,
              };
              payments.push({ ...corpPayment });
            });
          }
        });
      }
      return payments;
    },
    payableInR() {
      return ((parseFloat(this.print.totalBeforeRounding)
        + parseFloat(this.print.roundingAdjustment))
        * (this.print.exchangeRate || 0));
    },
  },
  methods: {
    calculateUnitPrice(item) {
      const itemUnitPrice = (item.price - item.discountAmount / item.qty) * (1 + item.tax / 100) + (item.discountAmount / item.qty);
      return itemUnitPrice;
    },
  },
  mounted() {
    if (!this.preview) {
      setTimeout(() => {
        window.print();
      }, 2000);
    }
  },
};
</script>

<style scoped src="../../assets/print.css"></style>
<style scoped>
  /* .tph-print-invoice {
    font-family: 'TphCambodia';
  } */
  @media print {
   body {
   display:table;
   table-layout:fixed;
   padding-top:2.5cm;
   padding-bottom:2.5cm;
   height:auto;
    }
}
  .clinic-address p {
    margin-bottom: 0px !important;
  }
  .big-fat-divider {
    border-bottom: 2px solid #666;
  }
  .table-border-top-thick {
    border-top: 2px solid rgb(39, 39, 39)
  }
  .table-border-bottom-thick {
    border-bottom: 2px solid rgb(39, 39, 39)
  }
  .col-border-top {
    border-top: 1px solid rgb(39, 39, 39) ;
  }
  .col-border-bottom {
    border-bottom: 4px solid rgb(39, 39, 39) ;
  }
  .col-border-top-dotted {
    border-top: 4px dotted  rgb(39, 39, 39) ;
  }
  .vd-print-table {
    width: 100%;
    margin-bottom: 1rem;
    color: #222;
    border-collapse: collapse;
  }
  .vd-print-table tr td{
    padding-top: 3px;
    padding-bottom: 3px;
  }
  .vd-print-table tbody:last-of-type tr:last-of-type {
    border-bottom: 2px solid rgb(39, 39, 39)
  }
  .signature-pad {
    width: 100%;
    display: block;
    border-bottom: 2px solid #222;
    padding: 4rem;
  }
  .signature-row {
    /* margin-top: 10rem; */
  }
  .text-vd-danger {
    color: rgb(214, 16, 16);
  }
  .vd-print-inner-table {
    width: 100%;
    color: #222;
    border-collapse: collapse;
  }

  .vd-print-table span.vd-table-virtual-row, .vd-print-table tbody tr {
    display: table-row;
  }
  .vd-print-table thead tr{
    display: table-row;
  }
</style>
