import api from './api';

export default {
  fetchAll(params) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);

    // FIXME: URL encoding
    Object.keys(params).forEach((key) => {
      q.push(`${key}=${encodeURIComponent(params[key])}`);
    });

    const url = `/doctor-recommendation-default/?${q.join('&')}`;
    return api.get(url);
  },
};
