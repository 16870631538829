var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid config" },
    [
      _c("flash-message", {
        staticClass: "template-flash",
        attrs: { transitionName: "fade" }
      }),
      _c("div", { staticClass: "col-12 mainbody" }, [
        _c("div", { staticClass: "col-sm-10" }, [
          _vm._m(0),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-6" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  on: { click: _vm.saveDetails }
                },
                [
                  _c("v-icon", {
                    staticClass: "tablehead__buttons__icons",
                    attrs: { name: "save" }
                  }),
                  _c(
                    "span",
                    {
                      staticClass:
                        "tablehead__buttons__label tablehead__buttons__save"
                    },
                    [
                      _vm._v(
                        "\n              " + _vm._s(_vm.$t("general.submit"))
                      )
                    ]
                  )
                ],
                1
              )
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-6" }, [_c("h3", [_vm._v("WIPE CACHE")])])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }