<template>
    <b-form-textarea
        ref="textarea"
        :value="value"
        :rows="rows || 2"
        max-rows="100"
        required
        no-resize
        :disabled="disabled"
        :id="`${qns}textarea`"
        @input="updateTypingFormData($event, qns)"
    ></b-form-textarea>
</template>

<script>
export default {
  name: 'TypedForm',
  props: {
    qns: {
      default: null,
      type: String,
    },
    value: {
      default: null,
      type: String,
    },
    rows: {
      default: null,
      type: Number,
    },
    updateTypingFormData: {
      default: null,
      type: Function,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
  },
  mounted() {
    const elem = this.$refs.textarea.$el;
    // elem.focus();
    // elem.select();
    window.setTimeout(() => {
      elem.style.height = 'auto';
      elem.style.height = `${elem.scrollHeight}px`;
    }, 0);
  },
};
</script>
