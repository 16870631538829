<template>
  <div class="mb-3">
    <div>
      <div v-for="item in medLineItems" :key="item.key" class="row my-4">
        <div class="col-3">{{ item.medicine.name }}</div>
        <div class="col-3">
          {{ regionData.currency }}{{ item.unitPrice | to2Decimal | thousandSeparator }}
        </div>
        <div class="col-3">{{ item.quantity }} {{ item.medicine.unit || '' }}</div>
        <div class="col-3">
          {{ regionData.currency }}
          {{ singleItemTotal(item.unitPrice, item.quantity) | to2Decimal | thousandSeparator }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moneyFormatter from '@/components/mixins/money';

export default {
  name: 'PackingListComponent',
  mixins: [moneyFormatter],

  props: ['medLineItems', 'regionData'],

  data() {
    return {};
  },
  computed: {
  },
  methods: {
    singleItemTotal(price, qty) { // unitPrice * quantity
      return parseInt(price, 10) * parseInt(qty, 10);
    },
  },
};
</script>

<style scoped src="../../assets/print.css"></style>
