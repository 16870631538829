<template>
  <!-- eslint-disable max-len -->
  <div class="b-invoice-container">
    <div class="b-inv-content">
      <!-- logo and title -->
      <div class="row align-items-baseline top-header">
        <div class="col flex-grow-0 logo-container">
          <img :src="logoURL" :alt="clinicName" style="height:45px;width:auto;">
        </div>
        <div class="col">
          <h4 class="text-right">{{ docTypeString }}</h4>
        </div>
      </div>
      <!-- clinic name and contact info -->
      <div class="row inner mt-2">
        <div class="col-12">
          <div class="clinicContact float-left">
            <h5 class="clinicName fz-6">{{ clinicName }}</h5>
            <p class="clinicName fz-6 mb-2">{{ clinicCorpName }}</p>
            <div class="clinicAddress d-flex fz-6 mb-2">
              <div class="hint flex-shrink-0">ที่อยู่:</div>
              <div class="clinicAddressLine ml-2 fz-6">
                <template v-if="general.useLocationAddress">
                  <div class="mb-1">{{ clinicLocationAddress }}</div>
                </template>
                <template v-else>
                  <div class="thai mb-1">{{ general.clinicLocalAddress }}</div>
                  <div class="en">{{ general.address }}</div>
                </template>
              </div>
            </div>
            <div class="clinicTel fz-6">
              <span class="tel" v-if="general.telephone">Tel: {{ general.telephone }}</span>
              <span class="tel" v-if="general.fax">Fax: {{ general.fax }}</span>
              <span class="tel" v-if="general.gstRegNo">Tax. ID: {{ general.gstRegNo }}</span>
            </div>
          </div>
          <div class="docType float-right t-bold">{{ docCopyString }}</div>
        </div>
      </div>
      <!-- document number -->
      <div class="row inner mt-2 t-bold">
        <div class="col-6"></div>
        <div class="col-6">
          <div class="docNumber">{{ docNoByType }}<span class="ml-1">{{ print.invoiceNo }}</span></div>
        </div>
      </div>
      <!-- patient name and hn -->
      <div class="row inner mt-2 t-bold">
        <div class="col-6">
          <div class="patientName">ชื่อ/Name: <span class="">{{ patientNameField }} </span></div>
        </div>
        <div class="col-6">
          <div class="hn">เลขประจำตัวผู้ป่วย       CN : <span class="ml-2">{{ selectedPatient.givenIdPrefix }}{{ selectedPatient.givenId}}</span></div>
        </div>
      </div>
      <!-- payer -->
      <div class="row inner mt-2 t-bold">
        <div class="col-6">
          <div class="payer">บริษัท / Corporate company :<span class="">{{ corpName }}</span></div>
        </div>
        <div class="col-6">
          <div class="docDateTime">
            <div class="date float-left">วันที่/Date:<span class=""> {{ print.createdAt | formatVDDate({ lang: $i18n.locale, format: 'DD/MM/YYYY'}) }}</span></div>
            <!--
            <div class="time float-right">เวลา/Time: <span class="t-red">{{ parseDateValue(print.createdAt, 'HH:mm:ss') }}</span></div>
            -->
          </div>
        </div>
      </div>
      <!-- table -->
      <div class="row mt-2">
        <div class="col">
          <table class="table invoice-table">
            <thead>
              <tr class="bg-grey text-center">
                <th colspan="3">
                  <p class="intableTitle thai">รายการ</p>
                  <p class="intableTitle en">Description</p>
                </th>
                <th>
                  <p class="intableTitle thai">จำนวนเงิน</p>
                  <p class="intableTitle en">Amount</p>
                </th>
                <th>
                  <p class="intableTitle thai">ส่วนลด</p>
                  <p class="intableTitle en">Discount</p>
                </th>
                <th>
                  <p class="intableTitle thai">จำนวนเงินสุทธิ</p>
                  <p class="intableTitle en">Net Amount</p>
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item, idx) in tableItems">
                <tr class="titleRow" v-if="item.isTitle" :key="`invoice_item_${idx}`">
                  <td colspan="3" class="categoryTitle fz-6">
                    {{ item.description }}
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr class="itemRow" v-else :key="`invoice_item_${idx}`">
                  <td colspan="3" class="categorySubTitle">
                    {{ item.description }}
                  </td>
                  <td class="currency">{{ item.amount }}</td>
                  <td class="currency">{{ item.discount }}</td>
                  <td class="currency">{{ item.netAmount }}</td>
                </tr>
              </template>
              <tr class="bg-grey subtotal">
                <td colspan="3" class="categoryTotal text-center fw-500">
                  รวมทั้งสิ้น / Subtotal
                </td>
                <td class="currency">{{ totalAmountNumber  | to2Decimal }}</td>
                <td class="currency">{{ totalDiscountNumber  | to2Decimal }}</td>
                <td class="currency">{{ totalNetAmountNumber  | to2Decimal }}</td>
              </tr>
              <tr class="bg-grey">
                <td colspan="3" class="fw-500 br-none text-center">
                  (ตัวอักษร) / (in letter)
                </td>
                <td colspan="3" class="text-right fw-500">
                  {{ totalNetAmountLetter }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="typeOfPayment inner">
        <p>
          ชำระโดย / Mode of payment :
        </p>
        <div class="row" v-for="(payment, idx) in payments" :key="idx">
          <div class="col-4 pl-3">
            {{ payment.label }}
          </div>
          <div class="col-3">
            {{ payment.amount | to2Decimal }}
          </div>
          <div class="col">
            <span>บาท / Baht</span>
          </div>
        </div>
      </div>
    </div>
    <div class="b-inv-footer">
      <div class="row inner sig-container mb-2">
        <div class="col">
          <div class="patientSig">
            <div class="sig fz-6">ผู้รับบริการ/Patient</div>
          </div>
        </div>
        <div class="col">
          <div class="accountantSig">
            <div class="sig fz-6">(ผู้ออกเอกสาร/Cashier)</div>
            <div class="cashierName">{{ user.name }}</div>
          </div>
        </div>
      </div>
      <div class="footer text-center inner py-2">
        <p v-if="docType === 'receipt'">
          ใบเสร็จนี้จะสมบูรณ์ต่อเมื่อสามารถเรียกเก็บเงินตามเช็ค หรือ บัตรเครดิตการ์ดได้เป็นที่เรียบร้อยแล้ว<br > This receipt will be valid when the cheque or credit card is duly cleared
        </p>
        <p>
          เพื่อความปลอดภัยของผู้ป่วยคลินิกขอสวงนสิทธิในการไม่รับเปลี่ยนหรือคืนยาและเวชภัณฑ์
        </p>
        <p>
          For patient safety,the hospital reserves the right not to accept any exchange or return of medications and medical supplies.
        </p>
      </div>
      <div class="inner mb-2">
        <p class="text-right mr-4">FM-OPD-009-00</p>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import Number2text from 'number2text';
import THBText from 'thai-baht-text';
import dateFormatter from '@/components/mixins/dateformatter';
import bgrimmLogo from '../../assets/drugLabel/bg-logo.png';

export default {
  name: 'BGrimmTaxInvoiceSubPage',
  mixins: [dateFormatter],
  mounted() {
    import('../../assets/print/bgrimm-invoice.scss');
  },
  props: {
    selectedPatient: {
      type: Object,
      default: () => ({ corpInsurance: [] }),
    },
    print: {
      type: Object,
      default: () => ({}),
    },
    general: {
      type: Object,
      default: () => ({}),
    },
    printEN: {
      type: Boolean,
      default: true,
    },
    payor: String,
    docCopyString: String,
    typeOfPayments: String,
    tableItems: {
      type: Array,
      default: () => ([]),
    },
    group: String,
    corpName: {
      type: String,
      default: '',
    },
    payments: {
      type: Array,
      default: () => ([]),
    },
    pageIndex: {
      type: Number,
      default: 0,
    },
  },
  filters: {
    to2Decimal(value, decimal = '.') {
      if (value !== '') {
        return parseFloat(value).toFixed(2).replace('.', decimal);
      }
      return '-';
    },
  },
  computed: {
    totalAmountNumber() {
      return this.print.subtotal;
    },
    totalDiscountNumber() {
      return this.print.totalDiscount;
    },
    totalNetAmountNumber() {
      return this.print.total;
    },
    clinicCorpName() {
      // TODO: use real name
      return '';
    },
    clinicName() {
      if (this.general.useLocationAddress) {
        const clinic = this.clinics.find(location => location._id === this.print.clinic);

        return clinic ? clinic.name : '';
      }

      const clinicHqName = (this.general && this.general.clinicName)
        ? this.general.clinicName.replace('Primo Care', 'PrimoCare')
        : '';

      return `${this.general.clinicLocalName} ${clinicHqName}`;
    },
    clinicLocationAddress() {
      const clinic = this.clinics.find(location => location._id === this.print.clinic);

      return clinic ? clinic.address : '';
    },
    logoURL() {
      return this.general.logoURL || bgrimmLogo;
    },
    totalNetAmountLetter() {
      const sum = parseFloat(this.totalNetAmountNumber).toFixed(2);
      if (this.printEN) {
        return Number2text(sum, 'English');
      }
      return THBText(sum);
    },
    docType() {
      const { group, pageIndex } = this;
      if (group === 'group3') {
        if (pageIndex === 0 || pageIndex === 1) {
          return 'invoice';
        }
        return 'receipt';
      }
      if (group === 'group1') {
        return 'receipt';
      }
      return 'invoice';
    },
    docNoByType() {
      if (this.docType === 'invoice') {
        return 'เลขที่ใบแจ้งหนี้/Invoice No. : IV';
      }
      return 'เลขที่ใบเสร็จ/Receipt No. : RC';
    },
    docTypeString() {
      if (this.docType === 'invoice') {
        return 'ใบแจ้งหนี้ค่ารักษาพยาบาล / INVOICE';
      }
      return 'ใบเสร็จรับเงินค่ารักษาพยาบาล / RECEIPT';
    },
    patientNameField() {
      if (this.printEN) {
        return `${this.selectedPatient.title || ''} ${this.selectedPatient.name}`;
      }
      return `${this.selectedPatient.localTitle || ''} ${this.selectedPatient.localName || ''}`;
    },
  },
  methods: {
    getDateFromX(d, f = 'DD/MM/YYYY') {
      return moment(d, 'X').format(f) || '';
    },
    getTotal(key) {
      const sum = this.tableItems.reduce((acc, item) => {
        const addNumber = item[key] ? parseFloat(item[key]) : 0;
        return acc + addNumber;
      }, 0);
      return parseFloat(sum).toFixed(2);
    },
  },
};
</script>

<style scoped src="../../assets/print.css"></style>
<style scoped lang="scss">
@media print {
  .b-invoice-container {
    -webkit-print-color-adjust:exact;
    border: 1px solid black;
    width: 140mm;
    height: 202mm;
    margin-top: 4mm;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .table.invoice-table {
      tr.bg-grey {
        background: #989aa4 !important;
        td, th {
          background: #989aa4 !important;
        }
      }
    }
  }
}
.t-bold {
  font-weight: 700;
}
.b-invoice-container {
  font-size: 10px !important;
  border: 1px solid black;
  line-height: 1;
  h4 {
    font-size: 12px;
  }
  .fw-500 {
    font-weight: 700;
  }
  .br-none {
    border-right-style: none !important;
  }
  .fz-6 {
    font-size: 8pt !important;
    line-height: 1.25;
  }
  .lh-1 {
    line-height: 1.1 !important;
  }
  .top-header {
    padding: 1rem 1rem 0 .2rem;
  }
  .inner {
    padding: 0 1rem;
  }
  .clinicTel {
    .tel:not(:first-child) {
      margin-left: 2rem;
    }
  }
  .table.invoice-table {
    tr.bg-grey {
      background: #989aa4;
    }
    border-collapse: separate;
    border-spacing: 0;
    margin-bottom: .5rem;
    td, th {
      border: 1px solid black;
      border-style: none solid solid none;
      padding: .5rem;
    }
    thead tr:first-child th {
      border-top-style: solid;
    }
    tbody td {
      padding: 3px .5rem;
    }
    tr td:last-child,
    tr th:last-child {
      border-right-style: none;
    }
    tr.titleRow td, tr.itemRow td, .categoryTotal {
      border-bottom-style: none;
      &.categorySubTitle {
        padding-left: 2rem;
      }
    }
    tr.subtotal td {
      border-top-style: solid;
    }
    .totalAmountLetter {
      padding-left: 2rem;
    }
    .currency {
      text-align: right;
    }
  }
  .patientSig {
    text-align: center;
    .sig {
      border-top: 1px solid black;
      width: 40%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .accountantSig {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    border-top: 1px solid black;
    width: 50%;
  }
}
</style>
