
import _ from 'lodash';

import api from '@/services/api';
import apiGetById from './api';

export default {
  get({ otbooking }) {
    console.log("otbooking ", otbooking);
    const url = `/ot-intraop/${otbooking}`;

    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`Error: ${response.data.message}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },

  getById(id) {
    const url = `/ot-intraop/otbooking/${id}`;
    //console.log("URL ", url)
    return new Promise((resolve, reject) => {
      apiGetById.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            //console.log("data ",data)
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`Error: ${response.data.message}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },


  updateMetadata({ patient, admission, otbooking }, metadata) {
    const url = `/ot-intraop/meta/${patient}/${admission}/${otbooking}`;

    return new Promise((resolve, reject) => {
      api.post(url, metadata).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            // TODO: Chan : Make this cooler
            // get at least one from array of errors
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },

  recordInduction({ patient, admission, otbooking }, induction) {
    const url = `/ot-intraop/induction/${patient}/${admission}/${otbooking}`;

    return new Promise((resolve, reject) => {
      api.post(url, induction).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            // TODO: Chan : Make this cooler
            // get at least one from array of errors
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },

  finalizeInduction({ patient, admission, otbooking }) {
    const url = `/ot-intraop/induction-finalization/${patient}/${admission}/${otbooking}`;

    return new Promise((resolve, reject) => {
      api.post(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            // TODO: Chan : Make this cooler
            // get at least one from array of errors
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },

  recordInput({ patient, admission, otbooking }, input) {
    const url = `/ot-intraop/input/${patient}/${admission}/${otbooking}`;

    return new Promise((resolve, reject) => {
      api.post(url, input).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            // TODO: Chan : Make this cooler
            // get at least one from array of errors
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },

  recordEvent({ patient, admission, otbooking }, event) {
    const url = `/ot-intraop/event/${patient}/${admission}/${otbooking}`;

    return new Promise((resolve, reject) => {
      api.post(url, event).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            // TODO: Chan : Make this cooler
            // get at least one from array of errors
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },

  recordData({
    patient, admission, type, otbooking,
  }, payload) {
    const url = `/ot-intraop/record-data/${type.toLowerCase()}/${patient}/${admission}/${otbooking}`;

    // TODO: check and re-assign rawPostData to request body

    return new Promise((resolve, reject) => {
      api.post(url, payload).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            // TODO: Chan : Make this cooler
            // get at least one from array of errors
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },

  recordEndOfSurgery({ patient, admission, otbooking }, endOfSurgery) {
    const url = `/ot-intraop/end-of-surgery/${patient}/${admission}/${otbooking}`;

    return new Promise((resolve, reject) => {
      api.post(url, endOfSurgery).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            // TODO: Chan : Make this cooler
            // get at least one from array of errors
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },


  finalize({ patient, admission, otbooking }) {
    const url = `/ot-intraop/finalization/${patient}/${admission}/${otbooking}`;

    return new Promise((resolve, reject) => {
      api.post(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            // TODO: Chan : Make this cooler
            // get at least one from array of errors
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },

};
