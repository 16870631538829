var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { position: "relative" } }, [
    _vm.IPDContainerName
      ? _c("div", { attrs: { id: _vm.IPDContainerName + "canvas" } })
      : _c("div", { attrs: { id: "canvas" } })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }