/* eslint-disable no-param-reassign */
import { cloneDeep, isEmpty, last as dashLast } from 'lodash';
import consultationService from '@/services/tcm-consultation.service';
import constant from '@/services/constant';
import { v4 } from 'uuid';

import {
  SET_TCM_CONSULTATION_LIST,
  SET_TCM_SELECTED_CONSULTATION_HISTORY,
  SET_TCM_SELECTED_CONSULTATION_BY_QUEUE,
  SET_TCM_ACTIVE_HISTORY_PRESCRIPTION,
  SET_TCM_ACTIVE_CONSULTATION,
  SET_TCM_ACTIVE_PRESCRIPTION,
  SET_TCM_ACTIVE_VISITRECORD,
  SET_TCM_CONSULTATION_HISTORY_LIST,
  SET_TCM_OPEN_CONSULTATION_LIST,
  UPDATE_VISITRECORD_FORM,
  SET_ACTIVE_TAB,
  SET_REFER_ROOT_SHORTCUTS,
  UPDATE_INVOICE,
  ADD_LINE_ITEM,
  SET_INVOICES,
  UPDATE_INVOICE_GST,
  UPDATE_INVOICE_GST_X_RATE,
  SAVE_INVOICE,
  REMOVE_LINE_ITEM,
  REMOVE_ALL_LINE_ITEMS,
  BULK_UPDATE_INVOICE,
  SET_PRINT,
  SET_PRINTABLE_DATA,
  SET_PRINT_TREATMENT_RECORD_CONSULTATION,
} from './type.mutation';

const state = {
  tcmPrescriptionPrintPayload: null,
  consultationList: [],
  consultationHistoryList: [],
  selectedConsultationHistory: {},
  activeHistoryPrescription: {},
  selectedConsultationIdByQueue: null,
  selectedConsultation: {},
  activePrescription: {},
  activeVisitRecord: {},

  openConsultationList: [],
  visitRecordForm: {},
  activeTab: 0,
  referRootShortcuts: {}, // { mainComplaint: [item1, item2, item3, ....]}

  invoicesMappedById: {},
  invoicesToPrint: [],
  dataToPrint: null,
  treatmentRecordConsultationToPrint: {},
};

const mutations = {
  setTcmPrintPrescription(_state, payload) {
    _state.tcmPrescriptionPrintPayload = payload;
  },
  [SET_TCM_SELECTED_CONSULTATION_BY_QUEUE](_state, consultationId) {
    _state.selectedConsultationIdByQueue = consultationId;
  },
  [SET_TCM_CONSULTATION_LIST](_state, payload) {
    _state.consultationList = payload;
  },
  [SET_TCM_CONSULTATION_HISTORY_LIST](_state, payload) {
    _state.consultationHistoryList = payload;
  },
  [SET_TCM_OPEN_CONSULTATION_LIST](_state, payload) {
    _state.openConsultationList = payload;
  },
  [SET_TCM_SELECTED_CONSULTATION_HISTORY](_state, payload) {
    _state.selectedConsultationHistory = payload;
  },
  [SET_TCM_ACTIVE_HISTORY_PRESCRIPTION](_state, payload) {
    _state.activeHistoryPrescription = payload;
  },
  [SET_TCM_ACTIVE_CONSULTATION](_state, payload) {
    _state.selectedConsultation = payload;
    const { visitRecord } = payload;
    const {
      __v, _id, clinic, patient, visitDate, updatedAt, createdAt, ...rest
    } = cloneDeep(visitRecord || {});
    _state.visitRecordForm = rest || {};
  },
  [SET_TCM_ACTIVE_PRESCRIPTION](_state, payload) {
    _state.activePrescription = payload;
  },
  [SET_TCM_ACTIVE_VISITRECORD](_state, payload) {
    _state.activeVisitRecord = payload;
  },
  [UPDATE_VISITRECORD_FORM](_state, payload) {
    _state.visitRecordForm = payload;
  },
  [SET_ACTIVE_TAB](_state, payload) {
    _state.activeTab = payload;
  },
  [SET_REFER_ROOT_SHORTCUTS](_state, payload) {
    _state.referRootShortcuts = payload;
  },

  // Order/Invoice/Prescription
  [SET_INVOICES](_state, payload) {
    _state.invoicesMappedById = payload;
  },
  [ADD_LINE_ITEM](_state, { invoiceId, item }) {
    const existingInvoice = _state.invoicesMappedById[invoiceId] ? _state.invoicesMappedById[invoiceId] : { items: false };
    const clonedItems = cloneDeep(existingInvoice.items || []);
    if (!item.uuid) {
      item.uuid = v4();
    }
    clonedItems.push(item);
    _state.invoicesMappedById = {
      ..._state.invoicesMappedById,
      [invoiceId]: { ..._state.invoicesMappedById[invoiceId], items: clonedItems },
    };

    _state.activePrescription = _state.invoicesMappedById[_state.activePrescription._id] || _state.activePrescription;
  },
  [REMOVE_LINE_ITEM](_state, { invoiceId, uuid }) {
    let clonedItems = cloneDeep(_state.invoicesMappedById[invoiceId].items) || [];
    clonedItems = clonedItems.filter(item => item.uuid !== uuid);
    _state.invoicesMappedById = { ..._state.invoicesMappedById, [invoiceId]: { ..._state.invoicesMappedById[invoiceId], items: clonedItems } };
    _state.activePrescription = _state.invoicesMappedById[_state.activePrescription._id] || _state.activePrescription;
  },
  [REMOVE_ALL_LINE_ITEMS](_state, { invoiceId }) {
    _state.invoicesMappedById = { ..._state.invoicesMappedById, [invoiceId]: { ..._state.invoicesMappedById[invoiceId], items: [] } };
    _state.activePrescription = _state.invoicesMappedById[_state.activePrescription._id] || _state.activePrescription;
  },
  [UPDATE_INVOICE](_state, invoice) {
    _state.invoicesMappedById = { ..._state.invoicesMappedById, [invoice._id]: invoice };
    _state.activePrescription = _state.invoicesMappedById[_state.activePrescription._id] || _state.activePrescription;
  },
  [UPDATE_INVOICE_GST](_state, { invoice, includeGST }) {
    _state.invoicesMappedById = {
      ..._state.invoicesMappedById,
      [invoice._id]: { ...invoice, includeGST },
    };
    _state.activePrescription = _state.invoicesMappedById[_state.activePrescription._id] || _state.activePrescription;
  },
  [UPDATE_INVOICE_GST_X_RATE](_state, { invoice, includeGST, exchangeRate }) {
    _state.invoicesMappedById = {
      ..._state.invoicesMappedById,
      [invoice._id]: { ...invoice, includeGST, exchangeRate },
    };
    _state.activePrescription = _state.invoicesMappedById[_state.activePrescription._id] || _state.activePrescription;
  },
  [BULK_UPDATE_INVOICE](_state, { invoiceIdList, payload }) {
    invoiceIdList.forEach((invoiceId) => {
      const inv = _state.invoicesMappedById[invoiceId];
      if (isEmpty(inv)) return;
      _state.invoicesMappedById = {
        ..._state.invoicesMappedById,
        [inv._id]: { ...inv, ...payload },
      };
    });
    _state.activePrescription = _state.invoicesMappedById[_state.activePrescription._id] || _state.activePrescription;
  },
  [SET_PRINT](_state, payload) {
    _state.invoicesToPrint = payload.filter(i => i.items && i.items.length > 0);
  },
  [SET_PRINTABLE_DATA](_state, payload) {
    console.debug(SET_PRINTABLE_DATA, payload);
    _state.dataToPrint = payload;
  },
  [SET_PRINT_TREATMENT_RECORD_CONSULTATION](_state, payload) {
    _state.treatmentRecordConsultationToPrint = payload;
  }
  /*
  [PATCH_STATS](_state, payload) {
    const clonedStats = { ..._state.stats };
    clonedStats[payload.id] = { ...clonedStats[payload.id], ...{ ostBalance: payload.ostBalance } };
    _state.stats = clonedStats;
  },
  */
};

const actions = {
  resetAll({ commit }) {
    commit(SET_INVOICES, []);
    commit(SET_TCM_CONSULTATION_LIST, []);
    commit(SET_TCM_CONSULTATION_HISTORY_LIST, []);
    commit(SET_TCM_OPEN_CONSULTATION_LIST, []);
    commit(SET_TCM_SELECTED_CONSULTATION_HISTORY, {});
    commit(SET_TCM_ACTIVE_HISTORY_PRESCRIPTION, {});
    commit(SET_TCM_ACTIVE_CONSULTATION, {});
    commit(SET_TCM_ACTIVE_PRESCRIPTION, {});
    commit(SET_TCM_ACTIVE_VISITRECORD, {});
    // TODO: we need to keep this for tcm consultation tab. I have no better idea now
    // commit(SET_TCM_SELECTED_CONSULTATION_BY_QUEUE, null);
  },
  fetchConsultationList({ commit, state: _state }, params = {}) {
    return consultationService.list(params)
      .then((resp) => {
        commit(SET_TCM_CONSULTATION_LIST, resp.data);
        const historyList = resp.data.filter(c => c.status === constant.TCM_CONSULTATION_STATUS.FINALIZED);
        commit(SET_TCM_CONSULTATION_HISTORY_LIST, historyList);
        const openList = resp.data.filter(c => c.status !== constant.TCM_CONSULTATION_STATUS.FINALIZED);
        commit(SET_TCM_OPEN_CONSULTATION_LIST, openList);
        // update invoices
        openList.forEach((con) => {
          const { order } = con;
          order.forEach((inv) => {
            commit(UPDATE_INVOICE, inv);
          });
        });
        if (_state.selectConsultation && _state.selectConsultation._id) {
          const payload = resp.data.find(i => i._id === _state.selectedConsultation._id);
          if (payload) {
            commit(SET_TCM_ACTIVE_CONSULTATION, payload);
            const { order } = payload;
            if (!order) return;
            const [prescription] = order;
            if (prescription) {
              commit(SET_TCM_ACTIVE_PRESCRIPTION, prescription);
            }
          }
        }
        return resp.data;
      });
  },
  selectHistoryById({ commit, state: _state }, consultationId) {
    if (!consultationId) {
      commit(SET_TCM_SELECTED_CONSULTATION_HISTORY, {});
      commit(SET_TCM_ACTIVE_HISTORY_PRESCRIPTION, {});
      return;
    }
    const { consultationList } = _state;
    const consultation = consultationList.find(c => c._id === consultationId);
    if (consultation) {
      commit(SET_TCM_SELECTED_CONSULTATION_HISTORY, consultation);
      const { order } = consultation;
      if (order) {
        const [firstPrescrition] = order;
        commit(SET_TCM_ACTIVE_HISTORY_PRESCRIPTION, firstPrescrition || {});
      } else {
        commit(SET_TCM_ACTIVE_HISTORY_PRESCRIPTION, {});
      }
    }
  },
  selectHistoryPrescriptionById({ commit, state: _state }, prescriptionId) {
    const { selectedConsultationHistory } = _state;
    if (selectedConsultationHistory) {
      const { order } = selectedConsultationHistory;
      const prescription = order.find(o => o._id === prescriptionId);
      if (prescription) {
        commit(SET_TCM_ACTIVE_HISTORY_PRESCRIPTION, prescription);
      }
    }
  },
  selectPrescriptionById({ commit, state: _state }, prescriptionId) {
    const { selectedConsultation } = _state;
    if (selectedConsultation) {
      const prescription = _state.invoicesMappedById[prescriptionId];
      if (prescription) {
        commit(SET_TCM_ACTIVE_PRESCRIPTION, prescription);
      }
    }
  },
  copyConsultation({ commit, state: _state }, payload) {
    const { type } = payload;
    if (!['all', 'visitRecord', 'singlePrescription', 'allPrescription'].includes(type)) {
      return false;
    }
    const { selectedConsultation, consultationList } = _state;
    payload.to = selectedConsultation ? selectedConsultation._id : undefined;
    return consultationService.copy(payload)
      .then((resp) => {
        const consultation = resp.data;
        const { order } = consultation;
        if (order) {
          const [firstPrescrition] = order;
          commit(SET_TCM_ACTIVE_PRESCRIPTION, firstPrescrition || {});
        } else {
          commit(SET_TCM_ACTIVE_PRESCRIPTION, {});
        }
        // update list
        const newList = consultationList.map((c) => {
          if (c._id !== consultation._id) return c;
          return consultation;
        });
        // TODO: refactor, update in Mutations
        commit(SET_TCM_CONSULTATION_LIST, newList);
        const historyList = newList.filter(c => c.status === constant.TCM_CONSULTATION_STATUS.FINALIZED);
        commit(SET_TCM_CONSULTATION_HISTORY_LIST, historyList);
        const openList = newList.filter(c => c.status !== constant.TCM_CONSULTATION_STATUS.FINALIZED);
        commit(SET_TCM_OPEN_CONSULTATION_LIST, openList);
        commit(SET_TCM_ACTIVE_CONSULTATION, consultation);
        openList.forEach((con) => {
          con.order.forEach((inv) => {
            commit(UPDATE_INVOICE, inv);
          });
        });
        return consultation;
      });
  },
  selectConsultation({ commit }, payload) {
    commit(SET_TCM_ACTIVE_CONSULTATION, payload);
    const { order } = payload;
    if (!order) return;
    const [prescription] = order;
    if (prescription) {
      commit(SET_TCM_ACTIVE_PRESCRIPTION, prescription);
    }
  },
  selectConsultationById({ commit, state: _state }, consultationId, reset = false) {
    const { consultationList } = _state;
    const consultation = consultationList.find(c => c._id === consultationId);
    if (consultation) {
      commit(SET_TCM_ACTIVE_CONSULTATION, consultation);
    } else if (reset) {
      commit(SET_TCM_ACTIVE_CONSULTATION, {});
    }
  },
  setPendingTcmConsultationId({ commit }, consultationId) {
    commit();
  },
  updateVisitRecordForm({ commit, state: _state }, payload) {
    const { visitRecordForm } = _state;
    const form = cloneDeep(visitRecordForm);
    commit(UPDATE_VISITRECORD_FORM, { ...form, ...payload });
  },
  updateActivePrescriptionForm({ commit, state: _state }, payload) {
    const { activePrescription } = _state;
    const form = cloneDeep(activePrescription);
    commit(SET_TCM_ACTIVE_PRESCRIPTION, { ...form, ...payload });
  },
  setActiveTab({ commit }, tabName) {
    const TABS = [
      'consultationHistory',
      'diagnosisTreatment',
      'prescription',
      'settings',
    ];
    const index = TABS.includes(tabName) ? TABS.indexOf(tabName) : 0;
    commit(SET_ACTIVE_TAB, index);
  },
  resetVisitRecordForm({ commit }) {
    commit(UPDATE_VISITRECORD_FORM, {});
  },
  updateVisitRecord({ commit, state: _state }, { id, payload }) {
    // NOTE: payload should have a field 'consultationId'
    return consultationService.patchVisitRecord(id, payload)
      .then((resp) => {
        // note this returns a consultation Record
        const consultation = resp.data;
        if (!consultation) return {};
        // replace the one in list
        const { consultationList } = _state;
        const newList = consultationList.map((c) => {
          if (c._id !== consultation._id) return c;
          return consultation;
        });
        commit(SET_TCM_CONSULTATION_LIST, newList);
        const historyList = newList.filter(c => c.status === constant.TCM_CONSULTATION_STATUS.FINALIZED);
        commit(SET_TCM_CONSULTATION_HISTORY_LIST, historyList);
        const openList = newList.filter(c => c.status !== constant.TCM_CONSULTATION_STATUS.FINALIZED);
        commit(SET_TCM_OPEN_CONSULTATION_LIST, openList);
        openList.forEach((con) => {
          con.order.forEach((inv) => {
            commit(UPDATE_INVOICE, inv);
          });
        });
        commit(SET_TCM_ACTIVE_CONSULTATION, consultation);
        return consultation;
      });
  },
  updateTreatmentRecord({ commit, state: _state }, { visitRecordId, consultationId, treatmentRecord }) {
    return consultationService.patchVisitRecord(visitRecordId, { consultationId, treatmentRecord })
      .then((resp) => {
        const consultation = resp.data;
        if (!consultation) return {};
        const { consultationList, selectedConsultation, selectedConsultationHistory } = _state;
        const newList = consultationList.map((c) => {
          if (c._id !== consultation._id) return c;
          return consultation;
        });
        commit(SET_TCM_CONSULTATION_LIST, newList);
        const historyList = newList.filter(c => c.status === constant.TCM_CONSULTATION_STATUS.FINALIZED);
        commit(SET_TCM_CONSULTATION_HISTORY_LIST, historyList);
        const openList = newList.filter(c => c.status !== constant.TCM_CONSULTATION_STATUS.FINALIZED);
        commit(SET_TCM_OPEN_CONSULTATION_LIST, openList);
        if (selectedConsultation._id === consultation._id) {
          commit(SET_TCM_ACTIVE_CONSULTATION, consultation);
        }
        if (selectedConsultationHistory._id === consultation._id) {
          commit(SET_TCM_SELECTED_CONSULTATION_HISTORY, consultation);
        }
        return consultation;
      });
  },
  fetchReferenceRootAndShortcuts({ commit }) {
    return consultationService.getReferenceIndex()
      .then((resp) => {
        const items = resp.data;
        const payload = {};
        items.forEach((item) => {
          const { type } = item;
          if (!payload[type]) {
            payload[type] = [item];
          } else {
            payload[type].push(item);
          }
        });
        // TODO sort here or in component
        commit(SET_REFER_ROOT_SHORTCUTS, payload);
        return payload;
      });
  },
  addNewPS({ commit, state: _state }, payload) {
    return consultationService.addNewPS(payload)
      .then((resp) => {
        // note this returns a consultation Record
        const consultation = resp.data;
        if (!consultation) return {};
        // replace the one in list
        const { consultationList } = _state;
        const newList = consultationList.map((c) => {
          if (c._id !== consultation._id) return c;
          return consultation;
        });
        commit(SET_TCM_CONSULTATION_LIST, newList);
        const historyList = newList.filter(c => c.status === constant.TCM_CONSULTATION_STATUS.FINALIZED);
        commit(SET_TCM_CONSULTATION_HISTORY_LIST, historyList);
        const openList = newList.filter(c => c.status !== constant.TCM_CONSULTATION_STATUS.FINALIZED);
        commit(SET_TCM_OPEN_CONSULTATION_LIST, openList);
        openList.forEach((con) => {
          con.order.forEach((inv) => {
            commit(UPDATE_INVOICE, inv);
          });
        });
        commit(SET_TCM_ACTIVE_CONSULTATION, consultation);

        const { order } = consultation;
        const prescription = dashLast(order);
        if (prescription) {
          commit(SET_TCM_ACTIVE_PRESCRIPTION, prescription);
        }
        return consultation;
      });
  },
  async [SAVE_INVOICE]({ commit, state: _state }, { id, voidInvoice, closeInvoice }) {
    let payload = Object.assign({}, _state.invoicesMappedById[id]);
    const clonedMainFormId = { ...payload.mainFormId };
    // misc
    payload.amountApply = 0;
    payload.corpAmountApply = 0;

    if (payload.queue) {
      if (payload.queue.provider && !payload.provider) payload.provider = payload.queue.provider;
      if (payload.queue.therapist && !payload.therapist) {
        payload.therapist = payload.queue.therapist;
      }
    }

    if (payload.provider && typeof payload.provider === 'object') {
      payload.provider = payload.provider._id;
    }
    if (payload.therapist && typeof payload.therapist === 'object') {
      payload.therapist = payload.therapist._id;
    }
    if (payload.payments) {
      if (typeof payload.payments[0] === 'object') {
        payload.payments = payload.payments.map(val => val._id);
      }
    }
    if (payload.clinic && typeof payload.clinic === 'object') {
      payload.clinic = payload.clinic._id;
    }

    // TODO: chan - there should be mapperfromInvoice to JSON and vice versa
    payload.promotions = payload.promotions.map(promotion => promotion.id);

    delete payload.queue;
    delete payload.patient;
    if (voidInvoice) {
      const { items } = payload;
      payload = {
        invoiceNo: id,
        voided: true,
        items,
      };
    }

    if (closeInvoice) {
      payload.finalized = true;
    }
    const resp = await consultationService.updateInvoice(id, payload, closeInvoice);
    if (resp.success) {
      const updatedInvoice = resp.data;
      if (clonedMainFormId) {
        updatedInvoice.mainFormId = clonedMainFormId;
      }
      commit(UPDATE_INVOICE, updatedInvoice);
    }
    return resp;
  },
  async saveAllPrescriptions({ commit, state: _state }, { invoiceConfig, voidInvoice, closeInvoice }) {
    if (isEmpty(_state.selectedConsultation)) return;
    const { order } = _state.selectedConsultation;
    if (!order) return;
    const invoiceIdList = order.map((o) => {
      if (typeof o === 'string') { return o; }
      return o._id;
    });

    invoiceIdList.forEach((idx) => {
      const invoice = _state.invoicesMappedById[idx];
      console.log(invoice, "invoice listed")
      if (!invoice) return;
      if (invoice.invoiceNo === 'draft') {
        const params = {
          invoice,
          includeGST: invoiceConfig.includeGST,
        };
        if (invoiceConfig.exchangeRate) {
          params.exchangeRate = invoiceConfig.exchangeRate;
          commit(UPDATE_INVOICE_GST_X_RATE, params);
        } else {
          commit(UPDATE_INVOICE_GST, params);
        }
      }
    });

    let invoices = invoiceIdList.map(idx => _state.invoicesMappedById[idx]).filter(i => !!i._id);

    invoices = invoices.map(ite => {
      let adminAddedItems = ite.items.map(item => {
        return {
          ...item,
          isAddedByAdmin : true
        }
      })
      return {
        ...ite,
        items : adminAddedItems
      }
    })

    // return;

    const promises = invoices.map((invoice) => {
      let payload = Object.assign({}, invoice);
      const clonedMainFormId = { ...payload.mainFormId };
      // misc
      payload.amountApply = 0;
      payload.corpAmountApply = 0;

      if (payload.queue) {
        if (payload.queue.provider && !payload.provider) payload.provider = payload.queue.provider;
        if (payload.queue.therapist && !payload.therapist) {
          payload.therapist = payload.queue.therapist;
        }
      }

      if (payload.provider && typeof payload.provider === 'object') {
        payload.provider = payload.provider._id;
      }
      if (payload.clinic && typeof payload.clinic === 'object') {
        payload.clinic = payload.clinic._id;
      }
      if (payload.therapist && typeof payload.therapist === 'object') {
        payload.therapist = payload.therapist._id;
      }
      if (payload.payments) {
        if (typeof payload.payments[0] === 'object') {
          payload.payments = payload.payments.map(val => val._id);
        }
      }

      // TODO: chan - there should be mapperfromInvoice to JSON and vice versa
      payload.promotions = payload.promotions.map(promotion => promotion.id);

      delete payload.queue;
      delete payload.patient;
      if (voidInvoice) {
        const { items } = payload;
        payload = {
          invoiceNo: invoice._id,
          voided: true,
          items,
        };
      }

      if (closeInvoice) {
        payload.finalized = true;
      }
      return new Promise((resolve, reject) => {
        consultationService.updateInvoice(invoice._id, payload, closeInvoice).then((resp) => {
          if (resp.success) {
            const updatedInvoice = resp.data;
            if (clonedMainFormId) {
              updatedInvoice.mainFormId = clonedMainFormId;
            }
            commit(UPDATE_INVOICE, updatedInvoice);
          }
          resolve(resp);
        }).catch((e) => {
          reject(e);
        });
      });
    });
    await Promise.all(promises).then(() => {
      console.log('done');
    });
  },
  loadInvoiceItems({ commit }, invoiceId) {
    return consultationService.getInvoiceByShowId(invoiceId)
      .then((response) => {
        let payload = {};
        if (response.success) {
          payload = response.data;
        }
        commit(UPDATE_INVOICE, payload);
        return payload;
      });
  },
  setPrintTreatmentRecordConsultation({ commit }, consultation) {
    commit(SET_PRINT_TREATMENT_RECORD_CONSULTATION, consultation);
  }
};

const getters = {
  printPayload: _state => _state.tcmPrescriptionPrintPayload,
  selectedConsultationIdByQueue: _state => _state.selectedConsultationIdByQueue,
  consultationList: _state => _state.consultationList,
  consultationHistoryList: _state => _state.consultationHistoryList,
  selectedConsultationHistory: _state => _state.selectedConsultationHistory,
  activeHistoryPrescription: _state => _state.activeHistoryPrescription,
  selectedConsultation: _state => _state.selectedConsultation,
  activePrescription: _state => _state.activePrescription,
  openConsultationList: _state => _state.openConsultationList,
  visitRecordForm: _state => _state.visitRecordForm,
  activeTab: _state => _state.activeTab,
  referRootShortcuts: _state => _state.referRootShortcuts,
  invoicesMappedById: _state => _state.invoicesMappedById,
  invoicesToPrint: _state => _state.invoicesToPrint,
  dataToPrint: _state => _state.dataToPrint,
  treatmentRecordConsultationToPrint: _state => _state.treatmentRecordConsultationToPrint,
  activePrescriptions: (_state) => {
    const { order } = _state.selectedConsultation;
    if (!order) {
      return [];
    }
    const ids = order.map((i) => {
      if (typeof i === 'string') return i;
      return i._id;
    });
    const prescriptions = ids.map(id => _state.invoicesMappedById[id]);
    return prescriptions;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
