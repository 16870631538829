import questionnaireService from '@/services/questionnaire.service';

import {
  SET_ANSWER, SET_ANSWERS,
  DELETE_ANSWER,
  SET_QUESTIONNAIRE,
} from './type.mutation';

const state = {
  answers: {},
  questionnaire: {},
};

/* eslint-disable no-param-reassign */
const mutations = {
  [SET_ANSWERS](_state, payload) {
    _state.answers = payload;
  },
  [SET_ANSWER](_state, payload) {
    _state.answers = { ..._state.answers, ...payload };
  },
  [DELETE_ANSWER](_state, payload) {
    delete _state.answers[payload];
  },
  [SET_QUESTIONNAIRE](_state, payload) {
    _state.questionnaire = payload;
  },
};
const actions = {
  reset({ commit }) {
    commit(SET_ANSWERS, {});
  },
  setAnswers({ commit }, answers) {
    commit(SET_ANSWERS, answers);
  },
  updateAnswer({ commit }, answer = {}) {
    commit(SET_ANSWER, answer);
  },
  deleteAnswer({ commit }, qid) {
    commit(DELETE_ANSWER, qid);
  },
  setQuestionnaire({ commit }, payload) {
    commit(SET_QUESTIONNAIRE, payload);
  },
  getQuestionnaire({ commit }, id) {
    commit('setLoading', true, { root: true });
    return questionnaireService.getQuestionnaire(id)
      .then((response) => {
        if (response.ok) {
          commit(SET_QUESTIONNAIRE, response.data);
        }
        commit('setLoading', false, { root: true });
        return response;
      });
  },
  updateQuestionnaire({ commit }, payload) {
    const { id, data } = payload;
    commit('setLoading', true, { root: true });
    return questionnaireService.updateQuestionnaire(id, data)
      .then((response) => {
        if (response.ok) {
          commit(SET_QUESTIONNAIRE, response.data);
        }
        commit('setLoading', false, { root: true });
        return response;
      });
  },
  // eslint-disable-next-line no-shadow
  createNewQuestionnaire({ commit }, newQuestionnaire) {
    commit('setLoading', true, { root: true });
    return questionnaireService.createNew(newQuestionnaire)
      .then((response) => {
        if (response.ok) {
          commit(SET_QUESTIONNAIRE, response.data);
        }
        commit('setLoading', false, { root: true });
        return response;
      });
  },
};

const getters = {
  answers: _state => _state.answers,
  questionnaire: _state => _state.questionnaire,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
