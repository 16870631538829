import NursingActivityService from '@/services/nursingactivity.service';

const state = {
};

/* eslint-disable no-param-reassign */
const mutations = {
};

const actions = {
  fetchList({}, param = {}) {
    // TODO: chan - should get vaps on backend
    return NursingActivityService.list(param)
      .then((response) => {
        return response;
      });
  },
  fetchListByGroup({}, payload) {
    return NursingActivityService.listByGroup(payload)
      .then((response) => {
        return response;
      });
  },
  addItem({}, payload) {
    return NursingActivityService.create(payload)
      .then((response) => {
        return response;
      });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};