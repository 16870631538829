
/** This mixin adds:
 * - `isOffline` data properties
 * - `online`, `offline` events
 * */
export const isOfflineMixin = {
    data () {
      return {
        isOffline: false
      }
    },
    mounted () {
      if (typeof window !== 'undefined') {
        this.isOffline = !navigator.onLine
  
          const offlineHandler = () => {
            this.$emit('offline')
            this.isOffline = true
          }
          const onlineHandler = () => {
            this.$emit('online')
            this.isOffline = false
          }
  
          window.addEventListener('online',  onlineHandler)
          window.addEventListener('offline',  offlineHandler)
          
          this.$once('hook:beforeDestroy', () => {
            window.removeEventListener('online', onlineHandler)
            window.removeEventListener('offline', offlineHandler)
          })
        }
    }
  }

  export const isOfflinePlugin = {
    install (Vue) {
      Vue.mixin(isOfflineMixin)
    }
  }
  
  export default isOfflinePlugin