/* eslint-disable no-param-reassign */
import vitalsignService from '@/services/vitalsign.service'

import {
  ADD_RECORD_TIME,
  ADD_TEMPARATURE_VITAL,
  SET_TEMPARATURE_VITAL,
  ADD_RESPIRATORY_RATE,
  SET_RESPIRATORY_RATE,
  ADD_HEART_RATE,
  SET_HEART_RATE,
  ADD_PULSE_RATE,
  SET_PULSE_RATE,
  ADD_ECG_RATE,
  SET_ECG_RATE,
  ADD_BLOOD_PRESSURE_ABP,
  SET_BLOOD_PRESSURE_ABP,
  ADD_BLOOD_PRESSURE_NIBP,
  SET_BLOOD_PRESSURE_NIBP,
  ADD_CENTRAL_VENUS_PRESSURE,
  SET_CENTRAL_VENUS_PRESSURE,
  ADD_SEDATION,
  SET_SEDATION,
  ADD_NEUROLOGICAL,
  SET_NEUROLOGICAL,
  ADD_PULILS,
  SET_PULILS,
  ADD_COMMA_SCALE_ADULT,
  SET_COMMA_SCALE_ADULT,
  ADD_BLADDER_PRESSURE,
  SET_BLADDER_PRESSURE,
  SET_RECORD_TIME,
  ADD_PAIN_SCORE,
  SET_PAIN_SCORE,
} from './type.mutation';

export const state = {
  recordedDateTime: [],
  temperatures: {},
  respiratoryRates: {},
  heartRates: {},
  pulseRates: {},
  ecgRhythms: {},
  bloodPressuresABP: {},
  bloodPressuresNIBP: {},
  centralVenousPressures: {},
  painScores: {},
  sedations: {},
  neurologicals: {},
  comaScaleAudlts: {},
  pupils: {},
  bladderPressures: {},
};

export const mutations = {
  [SET_RECORD_TIME](_state, dateTime) {
    _state.recordedDateTime = dateTime;
  },
  [ADD_RECORD_TIME](_state, dateTime) {
    _state.recordedDateTime.push(dateTime);
  },
  [ADD_TEMPARATURE_VITAL](_state, payload) {
    _state.temperatures = { ..._state.temperatures, ...payload };
  },
  [SET_TEMPARATURE_VITAL](_state, payload) {
    _state.temperatures = payload;
  },
  [ADD_RESPIRATORY_RATE](_state, payload) {
    _state.respiratoryRates = { ..._state.respiratoryRates, ...payload };
  },
  [SET_RESPIRATORY_RATE](_state, payload) {
    _state.respiratoryRates = payload;
  },
  [ADD_HEART_RATE](_state, payload) {
    _state.heartRates = { ..._state.heartRates, ...payload };
  },
  [SET_HEART_RATE](_state, payload) {
    _state.heartRates = payload;
  },
  [ADD_PULSE_RATE](_state, payload) {
    _state.pulseRates = { ..._state.pulseRates, ...payload };
  },
  [SET_PULSE_RATE](_state, payload) {
    _state.pulseRates = payload;
  },
  [ADD_ECG_RATE](_state, payload) {
    _state.ecgRhythms = { ..._state.ecgRhythms, ...payload };
  },
  [SET_ECG_RATE](_state, payload) {
    _state.ecgRhythms = payload;
  },
  [ADD_BLOOD_PRESSURE_ABP](_state, payload) {
    _state.bloodPressuresABP = { ..._state.bloodPressuresABP, ...payload };
  },
  [SET_BLOOD_PRESSURE_ABP](_state, payload) {
    _state.bloodPressuresABP = payload;
  },
  [ADD_BLOOD_PRESSURE_NIBP](_state, payload) {
    _state.bloodPressuresNIBP = { ..._state.bloodPressuresNIBP, ...payload };
  },
  [SET_BLOOD_PRESSURE_NIBP](_state, payload) {
    _state.bloodPressuresNIBP = payload;
  },
  [ADD_CENTRAL_VENUS_PRESSURE](_state, payload) {
    _state.centralVenousPressures = { ..._state.centralVenousPressures, ...payload };
  },
  [SET_CENTRAL_VENUS_PRESSURE](_state, payload) {
    _state.centralVenousPressures = payload;
  },
  [ADD_SEDATION](_state, payload) {
    _state.sedations = { ..._state.sedations, ...payload };
  },
  [SET_SEDATION](_state, payload) {
    _state.sedations = payload;
  },
  [ADD_NEUROLOGICAL](_state, payload) {
    _state.neurologicals = { ..._state.neurologicals, ...payload };
  },
  [SET_NEUROLOGICAL](_state, payload) {
    _state.neurologicals = payload;
  },
  [ADD_COMMA_SCALE_ADULT](_state, payload) {
    _state.comaScaleAudlts = { ..._state.comaScaleAudlts, ...payload };
  },
  [SET_COMMA_SCALE_ADULT](_state, payload) {
    _state.comaScaleAudlts = payload;
  },
  [ADD_PULILS](_state, payload) {
    _state.pupils = { ..._state.pupils, ...payload };
  },
  [SET_PULILS](_state, payload) {
    _state.pupils = payload;
  },
  [ADD_BLADDER_PRESSURE](_state, payload) {
    _state.bladderPressures = { ..._state.bladderPressures, ...payload };
  },
  [SET_BLADDER_PRESSURE](_state, payload) {
    _state.bladderPressures = payload;
  },
  [ADD_PAIN_SCORE](_state, payload) {
    _state.painScores = { ..._state.painScores, ...payload };
  },
  [SET_PAIN_SCORE](_state, payload) {
    _state.painScores = payload;
  },
};

export const actions = {
  recordVitals({ commit }, payload) {
    return vitalsignService.create(payload)
      .then((response) => {
        commit(ADD_RECORD_TIME, payload.recordTime);
        commit(ADD_TEMPARATURE_VITAL, payload.temperatures);
        commit(ADD_RESPIRATORY_RATE, payload.respiratoryRates);
        commit(ADD_HEART_RATE, payload.heartRates);
        commit(ADD_PULSE_RATE, payload.pulseRates);
        commit(ADD_ECG_RATE, payload.ecgRhythms);
        commit(ADD_BLOOD_PRESSURE_ABP, payload.bloodPressuresABP);
        commit(ADD_BLOOD_PRESSURE_NIBP, payload.bloodPressuresNIBP);
        commit(ADD_CENTRAL_VENUS_PRESSURE, payload.centralVenousPressures);
        commit(ADD_SEDATION, payload.sedations);
        commit(ADD_NEUROLOGICAL, payload.neurologicals);
        commit(ADD_PULILS, payload.pupils);
        commit(ADD_COMMA_SCALE_ADULT, payload.comaScaleAudlts);
        commit(ADD_BLADDER_PRESSURE, payload.bladderPressures);
        commit(ADD_PAIN_SCORE, payload.painScores);
        return response.data;
      });
  },
  fetchVitals({ commit }, params = {}) {
    return vitalsignService.list(params)
      .then((response) => {
        commit(SET_RECORD_TIME, response.data.recordedDateTime);
        commit(SET_TEMPARATURE_VITAL, response.data.temperatures);
        commit(SET_RESPIRATORY_RATE, response.data.respiratoryRates);
        commit(SET_HEART_RATE, response.data.heartRates);
        commit(SET_PULSE_RATE, response.data.pulseRates);
        commit(SET_ECG_RATE, response.data.ecgRhythms);
        commit(SET_BLOOD_PRESSURE_ABP, response.data.bloodPressuresABP);
        commit(SET_BLOOD_PRESSURE_NIBP, response.data.bloodPressuresNIBP);
        commit(SET_CENTRAL_VENUS_PRESSURE, response.data.centralVenousPressures);
        commit(SET_SEDATION, response.data.sedations);
        commit(SET_NEUROLOGICAL, response.data.neurologicals);
        commit(SET_PULILS, response.data.pupils);
        commit(SET_COMMA_SCALE_ADULT, response.data.comaScaleAudlts);
        commit(SET_BLADDER_PRESSURE, response.data.bladderPressures);
        commit(SET_PAIN_SCORE, response.data.painScores);
        return response.data;
      });
  },
  page({ commit }, payload) {
    commit(SET_RECORD_TIME, payload);
  },
};

export const getters = {
  recordedDateTime: _state => _state.recordedDateTime,
  temperatures: _state => _state.temperatures,
  respiratoryRates: _state => _state.respiratoryRates,
  heartRates: _state => _state.heartRates,
  pulseRates: _state => _state.pulseRates,
  ecgRhythms: _state => _state.ecgRhythms,
  bloodPressuresABP: _state => _state.bloodPressuresABP,
  bloodPressuresNIBP: _state => _state.bloodPressuresNIBP,
  centralVenousPressures: _state => _state.centralVenousPressures,
  painScores: _state => _state.painScores,
  sedations: _state => _state.sedations,
  neurologicals: _state => _state.neurologicals,
  comaScaleAudlts: _state => _state.comaScaleAudlts,
  pupils: _state => _state.pupils,
  bladderPressures: _state => _state.bladderPressures,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
