var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "printable-herbal-medicine-order" }, [
    _c("div", { staticClass: "printable-herbal-medicine-order-info" }, [
      _c("div", [
        _c("div", [
          _vm._m(0),
          _c("div", [_vm._v("Fax to 6223-9365 黄耀南牛车水（有代煎服务）")]),
          _c("div", [_vm._v("Tel：6220-8334")]),
          _c("div", [
            _vm._v("传真日期：" + _vm._s(_vm._f("formatDate")(_vm.today)))
          ]),
          _c("div", [
            _vm._v("门诊助理：" + _vm._s(_vm._f("capitalize")(_vm.creatorName)))
          ]),
          _c("div", [_vm._v("Herbal Fee：")]),
          _c("div", [_vm._v("代客煎药费：")]),
          _c("div", [_vm._v("药材总包数：")]),
          _vm._m(1),
          _c("br")
        ]),
        _c("hr"),
        _c("div", [
          _vm._m(2),
          _c("div", [_vm._v("Name：" + _vm._s(_vm.patientName))]),
          _c("div", [_vm._v("姓名：" + _vm._s(_vm.patientLocalName))]),
          _c("div", [_vm._v("NRIC：" + _vm._s(_vm.patientNRIC))]),
          _c("div", [_vm._v("手机：" + _vm._s(_vm.patientPhoneNumber))]),
          _c("div", [_vm._v("电话：" + _vm._s(_vm.patientTelephone))])
        ]),
        _c("hr"),
        _c("div", [
          _vm._m(3),
          _c(
            "div",
            [
              _c("checked-flag", { attrs: { checked: _vm.decoctByFactory } }),
              _c("b", [
                _vm._v(
                  "代客煎药（只限顾客到黄耀南牛车水自取，不提供送货服务）："
                )
              ])
            ],
            1
          ),
          _c("div", [
            _vm._v(
              "1 包药材包=2 包药液包，共 " +
                _vm._s(_vm.totalLiquidPacks) +
                " 药液包："
            )
          ]),
          _c("div", [_vm._v("贵重药材处理方式：")]),
          _c("div", { staticClass: "horizontal-wrapper" }, [
            _c(
              "div",
              [
                _vm._v("提早切片："),
                _c("checked-flag", {
                  attrs: { checked: _vm.valuable === "SLICE_IN_ADVANCE" }
                })
              ],
              1
            ),
            _c(
              "div",
              [
                _vm._v("现切片："),
                _c("checked-flag", {
                  attrs: { checked: _vm.valuable === "SLICE_ON_SITE" }
                })
              ],
              1
            ),
            _c(
              "div",
              [
                _vm._v("提早磨粉："),
                _c("checked-flag", {
                  attrs: { checked: _vm.valuable === "GRIND_IN_ADVANCE" }
                })
              ],
              1
            ),
            _c(
              "div",
              [
                _vm._v("现磨："),
                _c("checked-flag", {
                  attrs: { checked: _vm.valuable === "GRIND_ON_SITE" }
                })
              ],
              1
            )
          ])
        ]),
        _c("hr"),
        _c("div", [
          _vm._m(4),
          _c(
            "div",
            [
              _c("checked-flag", {
                attrs: { checked: _vm.takingBy === "BY_PATIENT" }
              }),
              _c("b", [_vm._v("A.顾客自行凭此单取药")])
            ],
            1
          ),
          _c(
            "div",
            [
              _c("checked-flag", {
                attrs: { checked: _vm.takingBy === "BY_FACTORY" }
              }),
              _c("b", [_vm._v("B.送货 Delivery Order")])
            ],
            1
          ),
          _c("div", [
            _vm._v(
              "Delivery Date：" + _vm._s(_vm._f("formatDate")(_vm.deliveryDate))
            )
          ]),
          _vm._m(5),
          _c("div", { staticClass: "horizontal-wrapper" }, [
            _c(
              "div",
              [
                _vm._v("9am-12noon："),
                _c("checked-flag", {
                  attrs: { checked: _vm.deliveryTime === "NINE_TO_TWELVE" }
                })
              ],
              1
            ),
            _c(
              "div",
              [
                _vm._v("12noon-5pm："),
                _c("checked-flag", {
                  attrs: { checked: _vm.deliveryTime === "TWELVE_TO_FIVE" }
                })
              ],
              1
            ),
            _c(
              "div",
              [
                _vm._v("5pm-7pm："),
                _c("checked-flag", {
                  attrs: { checked: _vm.deliveryTime === "FIVE_TO_SEVEN" }
                })
              ],
              1
            )
          ]),
          _vm._m(6),
          _c(
            "div",
            [
              _c("checked-flag", {
                attrs: { checked: !_vm.deliveryToPatientAddress }
              }),
              _vm._v(
                "\n          送马光分院：" +
                  _vm._s(
                    !_vm.deliveryToPatientAddress ? _vm.deliveryAddress : ""
                  ) +
                  "\n        "
              )
            ],
            1
          ),
          _c(
            "div",
            [
              _c("checked-flag", {
                attrs: { checked: _vm.deliveryToPatientAddress }
              }),
              _vm._v(
                "\n          顾客指定地址：" +
                  _vm._s(
                    _vm.deliveryToPatientAddress ? _vm.deliveryAddress : ""
                  ) +
                  "\n        "
              )
            ],
            1
          ),
          _c("div", [
            _vm._v(
              "\n          Postal Code：" +
                _vm._s(_vm.deliveryPostalCode) +
                "\n        "
            )
          ]),
          _c("div", [
            _c("b", [_vm._v("顾客签字确认：")]),
            _c("br"),
            _c("img", {
              staticStyle: { height: "50px" },
              attrs: { src: _vm.patientSig }
            })
          ])
        ]),
        _c("div", [
          _vm._m(7),
          _vm._m(8),
          _c("div", [
            _vm._v(
              "头煎（第一次煎）： " +
                _vm._s(_vm.decoctFirstWaterCount) +
                " 碗水，煎成 " +
                _vm._s(_vm.decoctFirstBowlCount) +
                " 碗"
            )
          ]),
          _c("div", [
            _vm._v(
              "二煎（第二次煎）： " +
                _vm._s(_vm.decoctSecondWaterCount) +
                " 碗水，煎成 " +
                _vm._s(_vm.decoctSecondBowlCount) +
                " 碗"
            )
          ]),
          _vm._m(9),
          _c(
            "div",
            [
              _c("checked-flag", {
                attrs: { checked: _vm.instructionOne === "BEFORE_MEAL" }
              }),
              _vm._v("饭前\n          /\n          "),
              _c("checked-flag", {
                attrs: { checked: _vm.instructionOne === "AFTER_MEAL" }
              }),
              _vm._v("饭后\n          /\n          "),
              _c("checked-flag", {
                attrs: { checked: _vm.instructionOne === "BEFORE_SLEEP" }
              }),
              _vm._v("睡前\n        ")
            ],
            1
          ),
          _c(
            "div",
            [
              _c("checked-flag", {
                attrs: { checked: _vm.instructionTwo === "BID" }
              }),
              _vm._v("早晚 2 次\n          /\n          "),
              _c("checked-flag", {
                attrs: { checked: _vm.instructionTwo === "QD_MORNING" }
              }),
              _vm._v("一天一次（早上）\n          /\n          "),
              _c("checked-flag", {
                attrs: { checked: _vm.instructionTwo === "QD_NIGHT" }
              }),
              _vm._v("一天一次（晚上）\n        ")
            ],
            1
          )
        ])
      ]),
      _c("div", [
        _c("img", { staticClass: "wyn-note", attrs: { src: _vm.WYNNote } })
      ])
    ]),
    _c("div", { staticClass: "footer" }),
    _c("img", { staticClass: "wyn-map", attrs: { src: _vm.WYNMap } }),
    _c("img", { staticClass: "wyn-notice", attrs: { src: _vm.WYNNotice } })
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("h5", [_vm._v("顾客联-凭单取药")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("b", [_vm._v("请盖马光店章")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("h5", [_vm._v("顾客资料")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("h5", [_vm._v("其他")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("h5", [_vm._v("取药方式")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("b", [_vm._v("Delivery Time")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("b", [_vm._v("Delivery Address")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("h5", [_vm._v("医师填写")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("b", [_vm._v("自行煮药者煎法")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("b", [_vm._v("服法（每项选其一）")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }