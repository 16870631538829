import LetterService from '@/services/letter.service';
import {
  BEFORE_FETCH_ALL_LETTERS,
  FETCH_ALL_LETTERS,
  CREATE_NEW_LETTER,
  EDIT_LETTER,

  SET_LETTER,
  UPDATE_LETTER,
  SET_PRINT,
} from './type.mutation';

const state = {
  letters: [],
  lettersMappedById: {},
  selectedLetterId: '', // the _id of old letter template or 'new' if creating new letter template
  print: {},
};

/* eslint-disable no-param-reassign */
const mutations = {
  [BEFORE_FETCH_ALL_LETTERS](_state) {
    _state.letters = [];
    _state.lettersMappedById = {};
  },
  [FETCH_ALL_LETTERS](_state, payload) {
    _state.letters = payload;
    // console.log('fetch lal letters mtn - payload is ', payload);
    payload.forEach((letter) => {
      _state.lettersMappedById[letter._id] = letter;
    });
    // console.log('_state.lettersMappedById is ', _state.lettersMappedById);
  },
  [CREATE_NEW_LETTER](_state) {
    _state.lettersMappedById.new = { givenId: '', name: '', text: '' };
    // _state.selectedLetterId = 'new';
  },
  [EDIT_LETTER](_state, { id, key, value }) {
    // console.log('mtn - id, key, value are ', id, key, value);
    const letter = _state.lettersMappedById[id];
    // console.log('letter was ', letter);
    // _state.lettersMappedById[id] = { ...letter, [key]: value };
    _state.lettersMappedById = { ..._state.lettersMappedById, [id]: { ...letter, [key]: value } };
    // console.log('letter is now ', _state.lettersMappedById[id]);
  },


  [SET_LETTER](_state, payload) { // TODO: should set _state.selectedInvoice to the invoice's _id
    // _state.selectedInvoice = payload;
  },
  [UPDATE_LETTER](_state, invoice) {
    _state.lettersMappedById = { ..._state.lettersMappedById, [invoice._id]: invoice };
  },
  PATCH_LETTER(_state, { id, key, value }) {
    _state.lettersMappedById = { ..._state.lettersMappedById, [id]: { ..._state.lettersMappedById[id], [key]: value } };
    _state.letters = _state.letters.map((invoice) => {
      if (invoice._id === id) {
        invoice = _state.lettersMappedById[id];
      }
      return invoice;
    });
  },
  [SET_PRINT](_state, payload) {
    _state.print = payload;
  },
};

const actions = {
  fetchAll({ commit }, params = {}) {
    // console.log('letter action - fetchAllLetters params is ', params);
    commit(BEFORE_FETCH_ALL_LETTERS);
    return LetterService.fetchAll(params)
      .then((response) => {
        // console.log('fetch letters - response is ', response);
        const payload = response.body;
        commit(FETCH_ALL_LETTERS, payload);
        return response;
      })
      .catch((err) => {
        console.log('fetch letters err is ', err);
      });
  },
  create({ commit, state, rootState }) {
    const payload = Object.assign({}, state.lettersMappedById.new);
    payload.clinics = [rootState.clinic.selectedClinic._id];
    // console.log('create letter - payload ', payload);
    return LetterService.createNew(payload)
      .then((response) => {
        // console.log('create letter - response is ', response);
        const newLetter = response.body;
        // commit something
        return response;
      })
      .catch((err) => {
        console.log('create letter - err is ', err);
      });
  },
  updateLetter({ commit, state }, id) {
    const payload = Object.assign({}, state.lettersMappedById[id]);
    // console.log('payload is ', payload);
    return LetterService.update(id, payload)
      .then((response) => {
        // console.log('update letter - response is ', response);
        const newLetter = response.body;
        // commit something
        return response;
      })
      .catch((err) => {
        console.log('update letter - err is ', err);
      });
  },
  print({ commit }, payload) {
    commit(SET_PRINT, payload);
  },
  setSelectedInvoice({ commit }, payload) {
    commit(SET_LETTER, payload);
  },
  updateInvoice({ commit }, payload) {
    commit(UPDATE_LETTER, payload);
  },
  bulkDeleteLetter({ commit }, data) {
    return LetterService.deleteBulk(data)
      .then(response => response);
  },
};

const getters = {
  letters: _state => _state.letters,
  lettersMappedById: _state => _state.lettersMappedById,
  print: _state => _state.print,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
