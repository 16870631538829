import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

// Modules
import auth from './module.auth';
import users from './module.users';
import appointments from './appointments';
import patient from './patient';
import provider from './provider';
import inventory from './inventory';
import inventoryTransfer from './inventory-Transfer';
import letter from './letter';
import order from './inventory/order';
import purchaseorder from './inventory/purchaseorder';
import adjustment from './inventory/adjustment';
import queue from './module.queue';
import invoice from './invoice';
import payment from './payment';
import clinic from './clinics';
import { alert } from './alert.module';
import note from './note';
import notetemplate from './notetemplate';
import acl from './acl';
import corporate from './corporate';
import room from './room';
import config from './config';
import print from './module.print';
import medservice from './medservice';
import credit from './credit';
import task from './task';
import debit from './debit';
import packages from './packages';
import credits from './inventorycredit';
import supplier from './supplier';
import membership from './membership';
import pharmacy from './pharmacy';
import stockcard from './stockcard';
import campaign from './campaign';
import message from './message';
import integratedtest from './module.integratedtest';
import marketing from './marketing';
import notification from './module.notification';
import area from './area';
import ipdtask from './ipdtask';
import teleConsult from './teleconsult';
import admission from './admission';
import nursing from './nursing';
import iphPharmacy from './ipd-pharmacy';
import otbooking from './otbooking';
import questionnaire from './questionnaire';
import healthScreening from './health-screening';
import recommendation from './health-screening-recommendation';
import otanaesthesia from './otanaesthesia';
import nursingIO from './nursing-io';
import discharge from './discharge';
import anaesthesianote from './anaesthesianote';
import vitalsign from './vitalsign';
import nursingRestrainer from './nursing-restrainer';
import nursingVap from './nursing-vap';
import doctorRemuneration from './doctor-remuneration';
import otintraop from './otintraop';
import billingGroup from './billing-group';
import nursingVentilation from './nursing-ventilation';
import nursingCirculation from './nursing-circulation';
import nursingActivity from './nursing-activity';
import ipdInvoice from './ipd-invoice';
import gad7 from './gad7';
import phq9 from './phq9';
import wsas from './wsas';
import stimulationChart from './stimulation-chart';
import fetChart from './fet-chart';
import iuiChart from './iui-chart';
import tcmConsultation from './tcm-consultation';
import hrNotification from "./hr-notification";
import receiveHrNotification from "./receive-hr-notification";


Vue.use(Vuex);
const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: state => (
    {
      acl: state.acl,
      auth: state.auth,
      clickOutside: state.clickOutside,
      clinic: state.clinic,
      config: state.config,
      credit: {
        selectedCreditNote: state.credit.selectedCreditNote,
      },
      invoice: {
        print: state.invoice.print,
        printPackageTransferReceipt: state.invoice.printPackageTransferReceipt,
      },
      discharge: {
        print: state.discharge.print,
      },
      loading: state.loading,
      medservice: state.medservice,
      patient: {
        print: state.patient.print,
        selectedPatient: state.patient.selectedPatient,
        compiledReportPrint: state.patient.compiledReportPrint,
        integrationReportPrint: state.patient.integrationReportPrint,
      },
      pharmacy: state.pharmacy,
      queue: state.queue,
      questionnaire: state.questionnaire,
      print: state.print,
      provider: state.provider,
      purchaseorder: {
        print: state.purchaseorder.print,
      },
      users: state.users,
      notification: state.notification,
      note: {
        print: state.note.print,
      },
      anaesthesianote: state.anaesthesianote, // remove later
      // eslint-disable-next-line no-dupe-keys
      // discharge: state.discharge, // remove later
      tcmConsultation: {
        invoicesToPrint: state.tcmConsultation.invoicesToPrint,
        dataToPrint: state.tcmConsultation.dataToPrint,
        treatmentRecordConsultationToPrint: state.tcmConsultation.treatmentRecordConsultationToPrint,
      },
    }
  ),
});

const debug = process.env.NODE_ENV !== 'production';

const state = {
  loading: false,
  notifications: [],
  clickOutside: false,
  latestAppVersion: null,
  needsAppUpdate: false,
  verCheckerEnabled: false,
};

const getters = {
  isLoading: () => state.loading,
  notifications: () => state.notifications,
  clickOutside: _state => _state.clickOutside,
};

const mutations = {
  setLoading(s, flag) {
    const _state = s;
    _state.loading = flag;
  },
  setlatestAppVersion(s, val) {
    const _state = s;
    _state.latestAppVersion = val;
  },
  setVerCheckerEnabled(s, val) {
    const _state = s;
    _state.verCheckerEnabled = val;
  },
  setNeedAppUpdate(s, val) {
    const _state = s;
    _state.needsAppUpdate = val;
  },
  SOCKET_NOTIFICATION(_state, data) {
    const mostRecent = _state.notifications // Keep most Recent only
      /* eslint-disable no-param-reassign */
      .sort((a, b) => {
        a = a.time;
        b = b.time;
        // eslint-disable-next-line no-shadow
        const order = -1;
        /* eslint-disable no-nested-ternary */
        return (a === b ? 0 : a > b ? 1 : -1) * order;
      })
      .slice(0, 10);
    /* eslint-disable no-param-reassign */
    _state.notifications = [...mostRecent, Object.assign({}, data, { read: false })];
  },
  UPDATE_NOTIFICATION(_state, data) {
    /* eslint-disable no-param-reassign */
    _state.notifications[data.index].read = true;
  },
  RESET_NOTIFICATION(_state) {
    /* eslint-disable no-param-reassign */
    _state.notifications = [];
  },
  SET_CLICKOUTSIDE(_state, payload) {
    _state.clickOutside = payload;
  },
};

const actions = {
  read_notification(vx, params) {
    const { commit } = vx;
    commit('UPDATE_NOTIFICATION', params);
  },
  reset_notification({ commit }) {
    commit('RESET_NOTIFICATION');
  },
  setClickOutside({ commit }, payload) {
    commit('SET_CLICKOUTSIDE', payload);
  },
};

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  modules: {
    auth,
    users,
    appointments,
    patient,
    provider,
    queue,
    alert,
    invoice,
    payment,
    note,
    notetemplate,
    clinic,
    inventory,
    inventoryTransfer,
    letter,
    acl,
    order,
    purchaseorder,
    corporate,
    room,
    adjustment,
    config,
    print,
    medservice,
    credit,
    task,
    debit,
    packages,
    credits,
    supplier,
    membership,
    pharmacy,
    stockcard,
    campaign,
    message,
    integratedtest,
    marketing,
    notification,
    area,
    ipdtask,
    teleConsult,
    admission,
    nursing,
    iphPharmacy,
    otbooking,
    questionnaire,
    healthScreening,
    recommendation,
    otanaesthesia,
    nursingIO,
    discharge,
    anaesthesianote,
    vitalsign,
    nursingRestrainer,
    nursingVap,
    doctorRemuneration,
    otintraop,
    billingGroup,
    nursingVentilation,
    nursingCirculation,
    nursingActivity,
    ipdInvoice,
    gad7,
    phq9,
    wsas,
    stimulationChart,
    fetChart,
    iuiChart,
    tcmConsultation,
    hrNotification,
    receiveHrNotification
  },
  strict: debug,
  plugins: [vuexLocal.plugin],
  //   plugins: debug ? [createLogger()] : []
});
