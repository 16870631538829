import api from "./api";

export default {
  fetchAll(params) {
    const url = `/hr-notification/list`;
    return api.post(url, params);
  },
  pinByUser(pin) {
    const data = {
      pin
    };
    const url = `/hr-notification/pin`;
    return api.post(url, data);
  },
  createNew(data) {
    return api.post("/hr-notification", data);
  },
  updateHrNotification(id, data) {
    return api.put(`/hr-notification/update/${id}`, data);
  },
  getHrNotificationById(id) {
    return api.get(`/hr-notification/${id}`);
  },
  sendHrNotification(id) {
    const data = {
      id
    };
    return api.post("/hr-notification/sent", data);
  },
  uploadPdf(pdfFile) {
    return api.multiPartPost("/hr-notification/file", {}, { pdf: pdfFile });
  },
  deleteHrNotification(id) {
    return api.delete(`/hr-notification/${id}`);
  }
};
