export const BEFORE_SET_ALL_NOTETEMPLATES = 'BEFORE_SET_ALL_NOTETEMPLATES';
export const SET_ALL_NOTETEMPLATES = 'SET_ALL_NOTETEMPLATES';
export const SET_ALL_CONSULTNOTETEMPLATES = 'SET_ALL_CONSULTNOTETEMPLATES';
export const SET_PAGINATED_NOTETEMPLATES = 'SET_PAGINATED_NOTETEMPLATES';
export const UPDATE_NOTETEMPLATE = 'UPDATE_NOTETEMPLATE';
export const GO_TO_PAGE = 'goToPage';
export const UPDATE_NOTETEMPLATE_LIST = 'UPDATE_NOTETEMPLATE_LIST';
export const DELETE_NOTETEMPLATE = 'DELETE_NOTETEMPLATE';
export const ADD_NEW_NOTE = 'ADD_NEW_NOTE';
export const RESET_TEMPLATE_IDS = 'RESET_TEMPLATE_IDS';
