<template>
  <div>
    <invoices-component-ma-kuang
      :patient="selectedPatient"
      :generalConfig="generalConfig"
      :invoices="invoicesToPrint"
      :lang="lang"
      :mode="mode"
      v-if="featureFlags.isMakuang"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import InvoicesComponentMaKuang from '@/components/TCMConsultation/Print/Invoices.vue';
import computedMixins from '@/components/mixins/computed';

export default {
  name: 'TcmPrintInvoices',
  components: {
    InvoicesComponentMaKuang,
  },
  mixins: [computedMixins],
  computed: {
    ...mapGetters('patient', ['selectedPatient']),
    ...mapGetters('tcmConsultation', ['invoicesToPrint']),
    ...mapGetters('config', {
      generalConfig: 'general',
      featureFlags: 'featureFlags',
    }),
  },
  data() {
    return {
      lang: 'en',
      mode: 'ca',
    };
  },
  mounted() {
    if (this.$route.query) {
      const { lang, mode } = this.$route.query;
      if (lang) {
        this.lang = lang;
        this.$i18n.locale = lang;
      }
      if (mode) {
        this.mode = mode;
      }
    }
  },
};
</script>
