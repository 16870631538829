var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._g(
      {
        ref: "textarea",
        attrs: {
          rows: _vm.rows || 2,
          "max-rows": "100",
          required: "",
          "no-resize": "",
          disabled: _vm.disabled,
          contenteditable: !_vm.disabled
        },
        domProps: { innerHTML: _vm._s(_vm.value) },
        on: {
          keydown: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            $event.preventDefault()
            return _vm.hahaha($event)
          },
          input: function($event) {
            return _vm.newEntry()
          },
          focus: _vm.onFocus,
          blur: function($event) {
            return _vm.saveNewValue($event)
          }
        }
      },
      _vm.listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }