var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("patient-label", {
    attrs: {
      clinic: _vm.generalConfig,
      patient: _vm.patient,
      printLabelConfig: _vm.printLabelConfig
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }