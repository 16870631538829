import { v4 } from 'uuid';
import _ from 'lodash';
import otBookingService from '@/services/otbooking.service';
import moment from 'moment';

import {
  SET_OTBOOKINGS,
  SET_OTBOOKING,
  SET_FILTERS,
  SET_OTINVOICE,
  UPDATE_OTINVOICE,
  UPDATE_OTINVOICE_SECTION,
  BEFORE_SET_OTOPTIONS,
  SET_OTOPTIONS,
  SET_SELECTED_OTOPTIONS,
  SET_PROVIDER_OT
} from './type.mutation';
import otbookingService from '../../services/otbooking.service';

const state = {
  otbookings: {},
  filters: {},
  otInvoicesMappedByOtBookingId: {},
  otBookingsLean: [],
  otOptions: [],
  selectedOtOption: '',
  otMappedIdToName: {},
  providersOT: [],
};

/* eslint-disable no-param-reassign */
const mutations = {
  [SET_OTBOOKINGS](_state, bookingList) {
    _state.otbookings = bookingList
      .reduce((acc, booking) => ({
        ...acc,
        [booking._id]: booking,
      }), {});
  },
  [SET_OTBOOKING](_state, booking) {
    _state.otbookings = {
      ..._state.otbookings,
      [booking._id]: booking,
    };
  },
  [SET_PROVIDER_OT](_state, payload) {
    _state.providersOT = _.cloneDeep(payload);
  },
  [SET_FILTERS](_state, filters) {
    _state.filters = { ...filters };
  },
  [SET_OTINVOICE](_state, invoice) {
    _state.otInvoicesMappedByOtBookingId = {
      [invoice.otBooking]: invoice,
      ..._state.otInvoicesMappedByOtBookingId,
    };
  },
  [UPDATE_OTINVOICE](_state, invoice) {
    _state.otInvoicesMappedByOtBookingId = { ..._state.otInvoicesMappedByOtBookingId, [invoice.otBooking]: invoice };
  },
  [UPDATE_OTINVOICE_SECTION](_state, { otBookingId, sectionName, sectionData }) {
    _state.otInvoicesMappedByOtBookingId[otBookingId][sectionName] = sectionData;
  },
  [BEFORE_SET_OTOPTIONS](_state) {
    _state.otBookingsLean = [];
    _state.otOptions = [];
    _state.selectedOtOption = '';
    _state.otMappedIdToName = {};
  },
  [SET_OTOPTIONS](_state, bookings) {
    _state.otBookingsLean = bookings;
    _state.otOptions = bookings.map((ot) => {
      const name = `OT - ${moment(ot.bookingDate).format('DD MMMM YYYY')}`;
      _state.otMappedIdToName[ot._id] = name;
      return {
        value: ot._id,
        text: name,
      };
    });
    if (_state.otOptions.length && _state.selectedOtOption === '') {
      _state.selectedOtOption = _state.otOptions[0].value;
    }
  },
  [SET_SELECTED_OTOPTIONS](_state, otId) {
    _state.selectedOtOption = otId;
  },
};

const actions = {
  socket_otbookingUpdated({ dispatch, commit, state: _state }, payload) {
    commit(SET_OTBOOKING, payload);
  },
  // socket_patientIpdtaskedit({ dispatch, commit }, payload) {
  //   commit(SET_OTBOOKING, payload);
  //   // dispatch('resortIPDTasks');
  // },
  // socket_patientIpdtasknew({ dispatch, commit }, payload) {
  //   commit(SET_OTBOOKING, payload);
  //   // dispatch('resortIPDTasks');
  // },
  // resortIPDTasks({ commit, state: _state }) {
  //   const ipdtasks = Object.values(_state.patientIPDTasks)
  //     .sort((a, b) => (`${a.date} ${a.time}`).localeCompare(`${b.date} ${b.time}`));
  //   commit(SET_OTBOOKINGS, ipdtasks);
  // },
  setFilters({ commit }, payload) {
    commit(SET_FILTERS, payload);
  },
  listOTBookings({ commit, state: _state }) {
    return otBookingService.list(_state.filters).then(
      (response) => {
        console.log('Tasks >>>> ', response.data);
        const tasks = response.data;
        commit(SET_OTBOOKINGS, tasks);
        return tasks;
      },
    ).catch((error) => {
      console.log('Error in fetching tasks ', error);
      commit(SET_OTBOOKINGS, []);
      throw error;
    });
  },
  fetchProvidersOT({ commit }, params = {}) {
    return otbookingService.providerOT(params)
      .then((response) => {
        commit(SET_PROVIDER_OT, response.data);
      });
  },
  fetchOTInvoice({ commit }, otId) {
    return otBookingService.fetchOTInvoice(otId)
      .then((response) => {
        console.log('hoho got response from fetchOTInvoice? ', response);
        const otInvoice = response.data;
        commit(SET_OTINVOICE, otInvoice);
        return otInvoice;
      });
  },
  updateOneSectionAndOTInvoice({ commit }, { invoice, sectionName }) {
    return otBookingService.updateOneSectionAndOTInvoice(invoice._id, { invoice, sectionName })
      .then((response) => {
        const payload = response.data;
        commit(UPDATE_OTINVOICE, payload);
      });
  },
  updateOTInvoice({ commit }, invoice) {
    return otBookingService.updateOTInvoice(invoice._id, invoice)
      .then((response) => {
        const payload = response.data;
        commit(UPDATE_OTINVOICE, payload);
      });
  },
  listOTBookingDatesByPatientId({ commit }, id) {
    commit(BEFORE_SET_OTOPTIONS);
    return otBookingService.otDatesByPatient(id).then(
      (response) => {
        const bookings = response.data;
        commit(SET_OTOPTIONS, bookings);
        return bookings;
      },
    ).catch((error) => {
      console.log('Error in fetching bookings ', error);
      commit(SET_OTBOOKINGS, []);
      throw error;
    });
  },
};

const getters = {
  bookings: _state => _state.otbookings,
  bookingList: _state => Object.values(_state.otbookings),
  providersOT: _state => _state.providersOT,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
