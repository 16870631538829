var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      attrs: { id: "app" },
      on: { dragover: _vm.videoPanelAllowDrop, drop: _vm.videoPanelDrop }
    },
    [
      _c(
        "div",
        { staticClass: "nonMobileView" },
        [
          _vm.showMainNav && !_vm.isWithoutLogin
            ? _c(
                "div",
                { attrs: { id: "nav" } },
                [_c("MainNav", { ref: "mainNav" })],
                1
              )
            : _vm._e(),
          !_vm.isWithoutLogin && _vm.patient ? _c("notes-overlay") : _vm._e(),
          _c(
            "main",
            {
              staticClass: "container-fluid",
              class: {
                "no-nav": !_vm.showMainNav,
                "need-update": _vm.needsAppUpdate
              },
              attrs: { id: "main" }
            },
            [
              _vm.isOffline
                ? _c(
                    "div",
                    {
                      staticClass:
                        "text-danger d-flex justify-content-center align-items-center"
                    },
                    [
                      _c("IconError"),
                      _c("div", { staticClass: "m-2 font-weight-bold" }, [
                        _vm._v(_vm._s(_vm.$t("general.offlinePrompt")))
                      ]),
                      _c("IconError")
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "transition",
                { attrs: { name: "fade", mode: "out-in" } },
                [
                  _vm.alert.message
                    ? _c(
                        "b-alert",
                        {
                          attrs: {
                            show: "",
                            variant: _vm.alert.type,
                            dismissible: ""
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.alert.message) +
                              "\n        "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "transition",
                { attrs: { name: "fade", mode: "out-in" } },
                [_c("router-view")],
                1
              )
            ],
            1
          ),
          _vm.isLoading ? _c("loading") : _vm._e(),
          !_vm.isWithoutLogin && _vm.needsAppUpdate
            ? _c("div", { staticClass: "new-version-alert" }, [
                _c(
                  "div",
                  [
                    _c("v-icon", {
                      attrs: { name: "exclamation-circle", scale: "1.6" }
                    }),
                    _c("span", { staticClass: "version-statement" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("general.versionPrompt")) +
                          " (" +
                          _vm._s(_vm.latestAppVersion) +
                          ")\n        "
                      )
                    ]),
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("general.versionInstruction")))
                    ])
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("span", { staticClass: "text-muted" }, [
                      _vm._v(_vm._s(_vm.$t("general.versionNote")))
                    ]),
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "primary" },
                        on: { click: _vm.appUpdate }
                      },
                      [_vm._v(_vm._s(_vm.$t("general.update")))]
                    )
                  ],
                  1
                )
              ])
            : _vm._e()
        ],
        1
      ),
      !_vm.isWithoutLogin
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.teleConsultRoom.hasStarted &&
                    _vm.teleConsultRoom.fullScreen,
                  expression:
                    "teleConsultRoom.hasStarted && teleConsultRoom.fullScreen"
                }
              ],
              staticClass: "teleconference-container",
              class: {
                "video-float": _vm.teleConsultRoom.floatWindow,
                "video-overlay": !_vm.teleConsultRoom.floatWindow
              },
              attrs: {
                id: "video-panel",
                draggable: _vm.teleConsultRoom.floatWindow
              },
              on: { dragstart: _vm.videoPanelDragStart }
            },
            [
              _vm.enableFullScreen
                ? _c("div", { staticClass: "video-window-controls" }, [
                    _c("div", { staticClass: "window-panel" }, [
                      !_vm.teleConsultRoom.floatWindow
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-default btn-sm text-light float-right",
                              on: {
                                click: function($event) {
                                  _vm.teleConsultRoom.floatWindow = true
                                }
                              }
                            },
                            [
                              _c("v-icon", {
                                attrs: { name: "compress", scale: "1.5" }
                              })
                            ],
                            1
                          )
                        : _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-default btn-sm text-light float-right",
                              on: {
                                click: function($event) {
                                  _vm.teleConsultRoom.floatWindow = false
                                }
                              }
                            },
                            [
                              _c("v-icon", {
                                attrs: { name: "expand", scale: "1.5" }
                              })
                            ],
                            1
                          ),
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-default btn-sm text-light float-right",
                          on: {
                            click: function($event) {
                              _vm.teleConsultRoom.floatWindow = false
                              _vm.teleConsultRoom.fullScreen = false
                            }
                          }
                        },
                        [
                          _c("v-icon", {
                            attrs: { name: "window-minimize", scale: "1.5" }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                : _vm._e(),
              _c("div", { staticClass: "row remote-video-container" }, [
                _c("div", { staticClass: "col" }, [
                  _c("div", {
                    class: {
                      "remote-video-full-screen-adjust":
                        _vm.teleConsultRoom.floatWindow
                    },
                    attrs: { id: "remote-video-track" }
                  })
                ])
              ]),
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.teleConsultRoom.floatWindow,
                    expression: "!teleConsultRoom.floatWindow"
                  }
                ],
                staticClass: "local-video-container",
                attrs: { id: "local-video-track" }
              }),
              _c(
                "div",
                {
                  staticClass: "video-control-container",
                  class: {
                    "video-control-container-full-screen-adjust":
                      _vm.teleConsultRoom.floatWindow
                  }
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "vdeo-control hangup",
                      on: { click: _vm.leaveRoomIfJoined }
                    },
                    [_c("v-icon", { attrs: { name: "phone", scale: "1.5" } })],
                    1
                  )
                ]
              )
            ]
          )
        : _vm._e(),
      !_vm.isWithoutLogin
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.teleConsultRoom.hasStarted &&
                    !_vm.teleConsultRoom.fullScreen,
                  expression:
                    "teleConsultRoom.hasStarted && !teleConsultRoom.fullScreen"
                }
              ],
              staticClass: "video-minimize text-right"
            },
            [
              _c(
                "span",
                {
                  staticClass: "mr-3",
                  staticStyle: { color: "rgb(204, 16, 16)" }
                },
                [_c("v-icon", { attrs: { name: "phone", scale: "1.5" } })],
                1
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-default btn-sm text-light",
                  on: {
                    click: function($event) {
                      _vm.teleConsultRoom.fullScreen = true
                      _vm.teleConsultRoom.floatWindow = true
                    }
                  }
                },
                [_c("v-icon", { attrs: { name: "compress", scale: "1.5" } })],
                1
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-default btn-sm text-light",
                  on: {
                    click: function($event) {
                      _vm.teleConsultRoom.fullScreen = true
                      _vm.teleConsultRoom.floatWindow = false
                    }
                  }
                },
                [_c("v-icon", { attrs: { name: "expand", scale: "1.5" } })],
                1
              )
            ]
          )
        : _vm._e(),
      _vm.showMainNav
        ? _c("script", {
            attrs: {
              type: "application/javascript",
              id: "ze-snippet",
              src:
                "https://static.zdassets.com/ekr/snippet.js?key=62e817cf-9971-4673-a62c-32922dc19307"
            }
          })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }