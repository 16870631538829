/* eslint-disable no-param-reassign */
import { SET_DEBITS, CREATE_DEBITS } from './type.mutation';
import debitService from '@/services/debit.service';

const state = {
  all: [],
  credit: {},
};

const mutations = {
  [SET_DEBITS](_state, payload) {
    _state.all = payload;
  },
  [CREATE_DEBITS](_state, payload) {
    _state.credit = payload;
  },
};

const actions = {
  createDebit({ commit }, { debit, invoice }) {
    return debitService.create(debit, { invoice })
      .then((data) => {
        // TODO: fetch credit here
        commit(CREATE_DEBITS, data.debit);
        return data;
      });
  },
  voidDebit({ commit }, { debit, invoice }) {
    return debitService.voidDebit(debit, invoice)
      .then((data) => {
        commit(CREATE_DEBITS, data.debit);
        return data;
      });
  },
};

const getters = {
  all: _state => _state.all,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
