var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar",
    {
      staticClass: "navbar navbar-expand-lg navbar-light bg-light main-nav",
      attrs: { toggleable: "lg" }
    },
    [
      _vm.shouldShowEnvBadge
        ? _c("span", { staticClass: "envBadge" }, [
            _vm._v(_vm._s(_vm.envBadge))
          ])
        : _vm._e(),
      _vm.bootswatchTheme === "konsuldok"
        ? _c("acl-navbar-brand", { attrs: { to: "/" } }, [
            _c("img", {
              staticClass: "d-inline-block align-top",
              attrs: {
                src: require("../assets/logo_konsuldok.png"),
                width: "42",
                alt: "Logo",
                "data-automation": "logo-konsuldok"
              }
            })
          ])
        : _c("acl-navbar-brand", { attrs: { to: "/" } }, [
            _c("img", {
              staticClass: "d-inline-block align-top",
              attrs: {
                src: require("../assets/logo.png"),
                width: "42",
                alt: "Logo",
                "data-automation": "logo"
              }
            })
          ]),
      _c("b-navbar-toggle", { attrs: { target: "nav-collapse" } }),
      _c(
        "b-collapse",
        { attrs: { id: "nav-collapse", "is-nav": "" } },
        [
          _c(
            "b-navbar-nav",
            { staticClass: "navbar-nav mr-auto module-menu" },
            [
              _vm.operatingMode === "inpatient"
                ? [
                    _c(
                      "acl-nav-item",
                      {
                        attrs: {
                          to: "/admission/list",
                          "data-automation": "admission-list"
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("general.admission")))]
                    ),
                    _c(
                      "acl-nav-item",
                      {
                        attrs: {
                          to: "/nursing/list",
                          "data-automation": "nursing-list"
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("general.nursing")))]
                    ),
                    _c(
                      "acl-nav-item",
                      {
                        attrs: {
                          to: "/bed/list",
                          "data-automation": "bed-list"
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("general.bed")))]
                    ),
                    _c(
                      "acl-nav-item",
                      { attrs: { to: "/ot", "data-automation": "ot-list" } },
                      [_vm._v(_vm._s(_vm.$t("general.ot")))]
                    )
                  ]
                : _vm._e(),
              _vm.operatingMode === "outpatient"
                ? [
                    _c(
                      "acl-nav-item",
                      {
                        attrs: {
                          to: "/queue/list",
                          exact: "",
                          "data-automation": "queue-list"
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("general.queue")))]
                    ),
                    _c(
                      "div",
                      [
                        _vm.featureFlags.dragoncareIntegration ||
                        (_vm.appointmentConfig.otherConfig &&
                          _vm.appointmentConfig.otherConfig.scheduler)
                          ? _c("acl-nav-item-dropdown", {
                              attrs: {
                                idName: "appointment-dropdown",
                                text: _vm.$t("general.appointment"),
                                className: {
                                  appointmentActive:
                                    _vm.appointmentChildrenSelected
                                },
                                dropdown: _vm.appointmentDropdown
                              }
                            })
                          : _c(
                              "acl-nav-item",
                              {
                                attrs: {
                                  to: "/appointment/list",
                                  "data-automation": "appointment-list"
                                }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("general.appointment")) +
                                    "\n          "
                                )
                              ]
                            )
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              _c(
                "acl-nav-item",
                {
                  attrs: {
                    to: "/patient/list",
                    "data-automation": "patient-list"
                  }
                },
                [
                  _vm._v(
                    "\n          " + _vm._s(_vm.patientLabel) + "\n        "
                  )
                ]
              ),
              _vm.operatingMode === "outpatient"
                ? _c("acl-nav-item-dropdown", {
                    attrs: {
                      idName: "invoice-dropdown",
                      text: _vm.$t("general.invoice"),
                      className: { invoiceActive: _vm.invoiceChildrenSelected },
                      dropdown: _vm.showInvoiceClassificationDropDown
                        ? [
                            {
                              label: _vm.$tc("general.corpInvoice", 1),
                              to: "/corporate-invoice/list",
                              "data-automation": "corporate-invoice-list"
                            },
                            {
                              label: _vm.$tc(
                                "moduleFunction.doctor_remuneration",
                                2
                              ),
                              to: "/doctor-remuneration",
                              "data-automation": "doctor-remuneration"
                            },
                            {
                              label: _vm.$t("general.japaneseCorpInvoice"),
                              to: "/corporate-invoice/classification-list",
                              "data-automation": "classification-list"
                            },
                            {
                              label: _vm.$t("general.providerInvoice"),
                              to: "/provider-invoice/provider-list",
                              "data-automation": "provider-list"
                            }
                          ]
                        : [
                            {
                              label: _vm.$tc("general.corpInvoice", 1),
                              to: "/corporate-invoice/list",
                              "data-automation": "corporate-invoice-list"
                            },
                            {
                              label: _vm.$tc(
                                "moduleFunction.doctor_remuneration",
                                2
                              ),
                              to: "/doctor-remuneration",
                              "data-automation": "doctor-remuneration"
                            }
                          ],
                      "data-automation": "invoice-dropdown"
                    }
                  })
                : _vm._e(),
              _c("acl-nav-item-dropdown", {
                attrs: {
                  idName: "inventory-dropdown",
                  text: _vm.$t("general.inventory"),
                  className: { inventoryActive: _vm.inventoryChildrenSelected },
                  dropdown: [
                    {
                      label: _vm.$t("general.inventory"),
                      to: "/inventory/list",
                      "data-automation": "inventory-list"
                    },
                    {
                      label: _vm.$t("general.inventoryAdjustment"),
                      to: "/adjustment/list",
                      "data-automation": "adjustment-list"
                    },
                    {
                      label: _vm.$t("general.transferBetweenBranch"),
                      to: "/transfer/list",
                      "data-automation": "transfer-list"
                    },
                    "divider",
                    {
                      label: _vm.$t("general.purchaseOrder"),
                      to: "/purchaseorder/list",
                      "data-automation": "purchaseorder-list"
                    },
                    {
                      label: _vm.$t("general.deliveryOrder"),
                      to: "/order/list",
                      "data-automation": "order-list"
                    },
                    "divider",
                    {
                      label: _vm.$t("general.stockCard"),
                      to: "/stockcard/list",
                      "data-automation": "stockcard-list"
                    }
                  ]
                }
              }),
              _vm.operatingMode === "outpatient"
                ? _c(
                    "acl-nav-item",
                    {
                      attrs: {
                        to: "/pharmacy",
                        exact: "",
                        "data-automation": "pharmacy"
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("pharmacy.title")) +
                          "\n        "
                      ),
                      _c("span", { staticClass: "notifCount" }, [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.pharmacyNotif > 0,
                                expression: "pharmacyNotif > 0"
                              }
                            ],
                            staticClass: "badge badge-pill badge-primary"
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.pharmacyNotif) +
                                "\n          "
                            )
                          ]
                        ),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.ipdPharmacyNotif > 0,
                                expression: "ipdPharmacyNotif > 0"
                              }
                            ],
                            staticClass: "badge badge-pill badge-success"
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.ipdPharmacyNotif) +
                                "\n          "
                            )
                          ]
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _c(
                "acl-nav-item",
                { attrs: { to: "/occupationalService/list" } },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("general.occupationalService")) +
                      "\n      "
                  )
                ]
              ),
              _vm.featureFlags.healthScreeningReport || _vm.featureFlags.labTest
                ? _c("acl-nav-item-dropdown", {
                    attrs: {
                      idName: "integratedTest-dropdown",
                      text: _vm.$t("general.tests"),
                      className: {
                        integratedTestActive:
                          _vm.integratedTestsChildrenSelected
                      },
                      dropdown: _vm.testDropDownOptions
                    }
                  })
                : _c(
                    "acl-nav-item",
                    {
                      attrs: {
                        to: "/integrated-test",
                        "data-automation": "integrated-test",
                        exact: ""
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("general.tests")) +
                          "\n      "
                      )
                    ]
                  ),
              _vm.operatingMode === "inpatient"
                ? _c(
                    "acl-nav-item",
                    {
                      attrs: {
                        to: "/ipd-pharmacy/list",
                        "data-automation": "ipd-pharmacy-list"
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("general.ipdPharmacy")) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e(),
              _vm.operatingMode === "inpatient"
                ? _c(
                    "acl-nav-item",
                    { attrs: { to: "", "data-automation": "office" } },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("general.office")) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e(),
              _vm.operatingMode === "outpatient" || _vm.featureFlags.inpatient
                ? _c(
                    "acl-nav-item",
                    {
                      attrs: {
                        to: "/reports/list",
                        "data-automation": "reports-list"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("general.reports")) + "\n      ")]
                  )
                : _vm._e(),
              _vm.operatingMode === "outpatient"
                ? [
                    _c(
                      "acl-nav-item",
                      {
                        attrs: {
                          to: "/campaign/list",
                          "data-automation": "campaign-list"
                        }
                      },
                      [_vm._v(_vm._s(_vm.$tc("general.campaign")))]
                    ),
                    _vm.featureFlags.dragoncareIntegration
                      ? _c(
                          "b-nav-item",
                          {
                            attrs: {
                              to: "/messages/list",
                              "data-automation": "messages-list"
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("messages.messages")) +
                                "\n        "
                            )
                          ]
                        )
                      : _vm._e(),
                    _c("acl-nav-item-dropdown", {
                      attrs: {
                        idName: "hr-dropdown",
                        text: _vm.$t("general.hr"),
                        className: { hrActive: _vm.hrChildrenSelected },
                        dropdown: [
                          {
                            label: _vm.$t("general.dashboard"),
                            to: "/hr/dashboard",
                            "data-automation": "hr-dashboard"
                          },
                          {
                            label: _vm.$t("general.settings"),
                            to: "/hr/settings",
                            "data-automation": "hr-settings"
                          },
                          {
                            label: _vm.$t("general.internalNotification"),
                            to: "/hr/internal-notification",
                            "data-automation": "hr-internal-notification"
                          }
                        ]
                      }
                    }),
                    _vm.featureFlags.dragoncareIntegration
                      ? _c(
                          "b-nav-item-dropdown",
                          {
                            attrs: {
                              text: _vm.$t("general.marketing"),
                              left: ""
                            }
                          },
                          [
                            _c(
                              "acl-dropdown-item",
                              {
                                attrs: {
                                  to: "/marketing/list",
                                  "data-automation": "marketing-list"
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("general.marketingTag")))]
                            ),
                            _c(
                              "acl-dropdown-item",
                              {
                                attrs: {
                                  to: "/marketing/mass-sending",
                                  "data-automation": "marketing-mass-sending"
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("general.massSendingSMSEmail"))
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                : _vm._e(),
              _c(
                "b-nav-item-dropdown",
                {
                  staticClass: "d-xl-none d-lg-none",
                  attrs: { text: "Settings", left: "" }
                },
                [
                  _c("acl-dropdown-item", { attrs: { to: "/config" } }, [
                    _vm._v(_vm._s(_vm.$t("setting.system")))
                  ]),
                  _vm.featureFlags.inpatient
                    ? _c("acl-dropdown-item", { attrs: { to: "/area/list" } }, [
                        _vm._v(_vm._s(_vm.$t("general.area")))
                      ])
                    : _vm._e(),
                  _c("acl-dropdown-item", { attrs: { to: "/room/list" } }, [
                    _vm._v(_vm._s(_vm.$t("setting.room")))
                  ]),
                  _vm.featureFlags.inpatient
                    ? _c(
                        "acl-dropdown-item",
                        { attrs: { to: "/ipd-room/list" } },
                        [_vm._v(_vm._s(_vm.$t("setting.ipdRoom")))]
                      )
                    : _vm._e(),
                  _c(
                    "acl-dropdown-item",
                    { attrs: { to: "/corporate/list" } },
                    [_vm._v(_vm._s(_vm.$t("general.corpInsurance")))]
                  ),
                  _c(
                    "acl-dropdown-item",
                    { attrs: { to: "/chartingbuilder/list" } },
                    [_vm._v(_vm._s(_vm.$t("setting.chartingTemplate")))]
                  ),
                  _c(
                    "acl-dropdown-item",
                    { attrs: { to: "/letterbuilder/list" } },
                    [_vm._v(_vm._s(_vm.$t("setting.letter")))]
                  ),
                  _c("acl-dropdown-item", { attrs: { to: "/provider/list" } }, [
                    _vm._v(_vm._s(_vm.$t("setting.provider")))
                  ]),
                  _c(
                    "acl-dropdown-item",
                    { attrs: { to: "/medservice/list" } },
                    [_vm._v(_vm._s(_vm.$t("setting.service")))]
                  ),
                  _c("acl-dropdown-item", { attrs: { to: "/supplier/list" } }, [
                    _vm._v(_vm._s(_vm.$t("setting.supplier")))
                  ]),
                  _c("b-dropdown-divider"),
                  _c("acl-dropdown-item", { attrs: { to: "/user/list" } }, [
                    _vm._v(_vm._s(_vm.$t("setting.user")))
                  ]),
                  _c("acl-dropdown-item", { attrs: { to: "/role/list" } }, [
                    _vm._v(_vm._s(_vm.$t("setting.role")))
                  ]),
                  _c("acl-dropdown-item", { attrs: { to: "/smsLogs/list" } }, [
                    _vm._v(_vm._s(_vm.$t("setting.smsLogs")))
                  ]),
                  _c("acl-dropdown-item", { attrs: { to: "/lineLogs/list" } }, [
                    _vm._v(_vm._s(_vm.$t("setting.lineLogs")))
                  ]),
                  _c(
                    "acl-dropdown-item",
                    { attrs: { to: "/emailLogs/list" } },
                    [_vm._v(_vm._s(_vm.$t("setting.emailLogs")))]
                  ),
                  _c("acl-dropdown-item", { attrs: { to: "/auditlog/list" } }, [
                    _vm._v(_vm._s(_vm.$t("setting.audit")))
                  ])
                ],
                1
              ),
              _c(
                "b-nav-item-dropdown",
                {
                  staticClass: "d-xl-none d-lg-none",
                  attrs: { text: "Change Clinic", left: "" }
                },
                _vm._l(_vm.clinics, function(c) {
                  return _c(
                    "b-dropdown-item",
                    {
                      key: c._id,
                      class: {
                        selectedClinic: c._id == _vm.selectedClinic._id
                      },
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          return _vm.toggleClinic(c)
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm._f("capitalize")(c.name)) + "\n        "
                      )
                    ]
                  )
                }),
                1
              ),
              _c(
                "b-nav-item",
                {
                  staticClass: "d-xl-none d-lg-none",
                  attrs: { to: "/profile/password" }
                },
                [_vm._v("Change Password")]
              ),
              _c(
                "b-nav-item",
                {
                  staticClass: "d-xl-none d-lg-none",
                  attrs: { to: "/logout" }
                },
                [_vm._v("Sign Out")]
              )
            ],
            2
          ),
          _c(
            "form",
            {
              staticClass: "form-inline my-2 my-lg-0 d-none d-lg-block",
              attrs: { id: "navformcontrols" }
            },
            [
              _c(
                "b-dropdown",
                { attrs: { id: "newbutton" } },
                [
                  _c(
                    "template",
                    { slot: "button-content" },
                    [
                      _c("v-icon", {
                        staticClass: "settingsbutton__icon",
                        attrs: { name: "plus", scale: "1.1" }
                      })
                    ],
                    1
                  ),
                  _c(
                    "acl-dropdown-item",
                    {
                      attrs: {
                        to: "/patient/new",
                        "data-automation": "create-patient"
                      }
                    },
                    [_vm._v(_vm._s(_vm.createPatientLabel))]
                  ),
                  _c(
                    "acl-dropdown-item",
                    {
                      attrs: {
                        to: "/appointment/new",
                        "data-automation": "create-appointment"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("general.addNewAppointment")))]
                  ),
                  _c(
                    "acl-dropdown-item",
                    {
                      attrs: {
                        to: "/task/upsert/new",
                        "data-automation": "create-task"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("general.addNewTask")))]
                  ),
                  _c(
                    "acl-dropdown-item",
                    {
                      attrs: {
                        to: "/transfer/point",
                        "data-automation": "transfer-point"
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("transferPoint.transferCreditPoint"))
                      )
                    ]
                  ),
                  _c(
                    "acl-dropdown-item",
                    {
                      attrs: {
                        to: "/transfer/package",
                        "data-automation": "transfer-package"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("transferPoint.transferPackage")))]
                  ),
                  _c(
                    "acl-dropdown-item",
                    {
                      attrs: {
                        to: "/lab-tests-create",
                        "data-automation": "create-lab-test-order"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("labTest.createLabTestOrder")))]
                  )
                ],
                2
              ),
              _c(
                "b-dropdown",
                {
                  attrs: {
                    id: "bell-dropdown-right",
                    right: "",
                    variant: "link",
                    "toggle-class": "text-decoration-none",
                    "menu-class": "notification-menu",
                    "no-caret": ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "button-content",
                      fn: function() {
                        return [
                          _c("v-icon", { attrs: { name: "bell" } }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.totalUnreadNotif > 0,
                                  expression: "totalUnreadNotif > 0"
                                }
                              ],
                              staticClass: "badge badge-pill badge-primary",
                              staticStyle: { position: "absolute", top: "4px" }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.totalUnreadNotif) +
                                  "\n          "
                              )
                            ]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _vm.totalUnreadNotif > 0
                    ? _c(
                        "span",
                        {
                          staticStyle: {
                            color: "grey",
                            cursor: "pointer",
                            float: "right",
                            "margin-right": "15px"
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.markAllRead()
                            }
                          }
                        },
                        [_vm._v("Mark All as Read")]
                      )
                    : _vm._e(),
                  _vm._l(_vm.systemNotif, function(notif) {
                    return _c("b-dropdown-item", { key: notif._id }, [
                      _c(
                        "div",
                        {
                          staticStyle: { position: "relative", padding: "5px" },
                          on: {
                            click: function() {
                              return _vm.clickNotif(notif)
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                position: "absolute",
                                left: "100%",
                                top: "0%",
                                color: "red"
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.updateNotif({
                                    _id: notif._id,
                                    isDeleted: true
                                  })
                                }
                              }
                            },
                            [_vm._v("X")]
                          ),
                          _c("div", { staticStyle: { color: "black" } }, [
                            _vm._v(_vm._s(notif.message))
                          ]),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-content-between" },
                            [
                              _c("span", { staticStyle: { color: "grey" } }, [
                                _vm._v(_vm._s(_vm._f("date")(notif.date)))
                              ]),
                              notif.isRead
                                ? _c(
                                    "span",
                                    {
                                      staticStyle: { color: "grey" },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.updateNotif({
                                            _id: notif._id,
                                            isRead: false
                                          })
                                        }
                                      }
                                    },
                                    [_vm._v("(Mark as Unread)")]
                                  )
                                : _vm._e(),
                              !notif.isRead
                                ? _c(
                                    "span",
                                    {
                                      staticStyle: { color: "grey" },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.updateNotif({
                                            _id: notif._id,
                                            isRead: true
                                          })
                                        }
                                      }
                                    },
                                    [_vm._v("(Mark as Read)")]
                                  )
                                : _vm._e()
                            ]
                          )
                        ]
                      )
                    ])
                  })
                ],
                2
              ),
              _c(
                "router-link",
                {
                  staticClass: "btn",
                  staticStyle: { position: "relative", padding: "2px 0 0 5px" },
                  attrs: { id: "taskbutton", to: "/task/main" }
                },
                [
                  _c("v-icon", {
                    staticClass: "taskbutton__icon",
                    attrs: { name: "tasks", scale: "1.1" }
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.todaysTaskAndReceiveHrNotificationUnCompletedTotal >
                            0,
                          expression:
                            "todaysTaskAndReceiveHrNotificationUnCompletedTotal > 0"
                        }
                      ],
                      staticClass:
                        "badge badge-pill badge-primary task-notification",
                      staticStyle: { position: "absolute", top: "0%" }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.todaysTaskAndReceiveHrNotificationUnCompletedTotal
                          ) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "b-dropdown",
                {
                  attrs: {
                    id: "settingsbutton",
                    "data-content": "notifcations"
                  }
                },
                [
                  _c(
                    "template",
                    { slot: "button-content" },
                    [
                      _c("v-icon", {
                        staticClass: "settingsbutton__icon",
                        attrs: { name: "cog", scale: "1.1" }
                      })
                    ],
                    1
                  ),
                  _c(
                    "acl-dropdown-item",
                    { attrs: { to: "/config", "data-automation": "config" } },
                    [_vm._v(_vm._s(_vm.$t("setting.system")))]
                  ),
                  _vm.featureFlags.inpatient
                    ? _c(
                        "acl-dropdown-item",
                        {
                          attrs: {
                            to: "/area/list",
                            "data-automation": "area-list"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("general.area")))]
                      )
                    : _vm._e(),
                  _c(
                    "acl-dropdown-item",
                    {
                      attrs: {
                        to: "/room/list",
                        "data-automation": "room-list"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("setting.room")))]
                  ),
                  _vm.featureFlags.inpatient
                    ? _c(
                        "acl-dropdown-item",
                        {
                          attrs: {
                            to: "/ipd-room/list",
                            "data-automation": "ipd-room-list"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("setting.ipdRoom")))]
                      )
                    : _vm._e(),
                  _c(
                    "acl-dropdown-item",
                    {
                      attrs: {
                        to: "/corporate/list",
                        "data-automation": "corporate-list"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("general.corpInsurance")))]
                  ),
                  _c(
                    "acl-dropdown-item",
                    {
                      attrs: {
                        to: "/chartingbuilder/list",
                        "data-automation": "chartingbuilder-list"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("setting.chartingTemplate")))]
                  ),
                  _c(
                    "acl-dropdown-item",
                    {
                      attrs: {
                        to: "/letterbuilder/list",
                        "data-automation": "letterbuilder-list"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("setting.letter")))]
                  ),
                  _c(
                    "acl-dropdown-item",
                    {
                      attrs: {
                        to: "/provider/list",
                        "data-automation": "provider-list"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("setting.provider")))]
                  ),
                  _c(
                    "acl-dropdown-item",
                    {
                      attrs: {
                        to: "/medservice/list",
                        "data-automation": "medservice-list"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("setting.service")))]
                  ),
                  _c(
                    "acl-dropdown-item",
                    {
                      attrs: {
                        to: "/supplier/list",
                        "data-automation": "supplier-list"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("setting.supplier")))]
                  ),
                  _vm.region.code === "KH"
                    ? _c(
                        "acl-dropdown-item",
                        {
                          attrs: {
                            to: "/exchange-rate",
                            "data-automation": "exchange-rate"
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("setting.exchangeRate")) +
                              "\n        "
                          )
                        ]
                      )
                    : _vm._e(),
                  _c("b-dropdown-divider"),
                  _c(
                    "acl-dropdown-item",
                    {
                      attrs: {
                        to: "/user/list",
                        "data-automation": "user-list"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("setting.user")))]
                  ),
                  _c(
                    "acl-dropdown-item",
                    {
                      attrs: {
                        to: "/role/list",
                        "data-automation": "role-list"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("setting.role")))]
                  ),
                  _c(
                    "acl-dropdown-item",
                    {
                      attrs: {
                        to: "/smsLogs/list",
                        "data-automation": "smslogs-list"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("setting.smsLogs")))]
                  ),
                  _c(
                    "acl-dropdown-item",
                    {
                      attrs: {
                        to: "/lineLogs/list",
                        "data-automation": "linelogs-list"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("setting.lineLogs")))]
                  ),
                  _c(
                    "acl-dropdown-item",
                    {
                      attrs: {
                        to: "/emailLogs/list",
                        "data-automation": "emaillogs-list"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("setting.emailLogs")))]
                  ),
                  _c(
                    "acl-dropdown-item",
                    {
                      attrs: {
                        to: "/auditlog/list",
                        "data-automation": "auditlog-list"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("setting.audit")))]
                  )
                ],
                2
              )
            ],
            1
          ),
          _c("div", { staticClass: "divider-vertical d-none d-lg-block" }),
          _c("img", {
            staticClass: "img-thumbnail rounded-circle d-none d-lg-block",
            staticStyle: { width: "45px", height: "45px" },
            attrs: {
              id: "userphotobutton",
              alt: "150x150",
              src: require("../assets/user_placeholder.svg"),
              "data-holder-rendered": "true"
            }
          }),
          _c(
            "div",
            {
              staticClass: "d-none d-lg-block",
              attrs: { id: "userclinic-section" }
            },
            [
              _c(
                "b-dropdown",
                { attrs: { id: "userclinic__dropdown" } },
                [
                  _c("template", { slot: "button-content" }, [
                    _c("div", { staticClass: "userclinic__user" }, [
                      _c("span", [_vm._v(_vm._s(_vm.user.name))])
                    ]),
                    _c("span", { staticClass: "userclinic__clinic" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.selectedClinic.name) +
                          "\n          "
                      )
                    ])
                  ]),
                  _c(
                    "b-dropdown-text",
                    { attrs: { id: "photo-details__wrapper" } },
                    [
                      _c("div", { attrs: { id: "photo-details" } }, [
                        _c("img", {
                          staticClass: "img-thumbnail rounded-circle",
                          staticStyle: { width: "45px", height: "45px" },
                          attrs: {
                            id: "userphotobutton2",
                            alt: "150x150",
                            src: require("../assets/user_placeholder.svg"),
                            "data-holder-rendered": "true"
                          }
                        }),
                        _c("h6", [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm._f("capitalize")(_vm.user.name)) +
                              "\n            "
                          ),
                          _c("br"),
                          _c("small", [_vm._v(_vm._s(_vm.user.email))])
                        ])
                      ])
                    ]
                  ),
                  _c("b-dropdown-divider"),
                  _c(
                    "el-select",
                    {
                      ref: "elSelect",
                      staticClass: "select-clinic",
                      attrs: {
                        value: _vm.selectedClinic,
                        "value-key": "_id",
                        placeholder: "Select clinic",
                        filterable: "",
                        "data-automation": "select-user-clinic"
                      },
                      on: {
                        change: function($event) {
                          return _vm.toggleClinic($event)
                        }
                      }
                    },
                    _vm._l(_vm.clinics, function(item) {
                      return _c("el-option", {
                        key: item._id,
                        attrs: {
                          "data-automation": _vm.kebabCase(
                            "selected-clinic-" + item.name
                          ),
                          label: item.name,
                          value: item
                        }
                      })
                    }),
                    1
                  ),
                  _c("b-dropdown-divider"),
                  _vm.accountSwitcher && _vm.accountSwitcher.length > 1
                    ? _c(
                        "b-nav-text",
                        { staticClass: "d-flex align-items-center mx-4" },
                        [
                          _c("v-icon", {
                            staticClass: "mr-2",
                            attrs: { name: "user", scale: "1.5" }
                          }),
                          _c("b-form-select", {
                            attrs: { options: _vm.accountSwitcher },
                            on: { change: _vm.switchAccount },
                            model: {
                              value: _vm.selectedOrgCode,
                              callback: function($$v) {
                                _vm.selectedOrgCode = $$v
                              },
                              expression: "selectedOrgCode"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.accountSwitcher && _vm.accountSwitcher.length > 1
                    ? _c("b-dropdown-divider")
                    : _vm._e(),
                  _vm.featureFlags.inpatient
                    ? _c(
                        "b-nav-text",
                        { staticClass: "d-flex align-items-center mx-4" },
                        [
                          _c("v-icon", {
                            staticClass: "mr-2",
                            attrs: { name: "exchange-alt", scale: "1.5" }
                          }),
                          _c("b-form-select", {
                            attrs: { options: _vm.operatingModeOptions },
                            on: { change: _vm.setOperatingMode },
                            model: {
                              value: _vm.selectedOperatingMode,
                              callback: function($$v) {
                                _vm.selectedOperatingMode = $$v
                              },
                              expression: "selectedOperatingMode"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-dropdown-item",
                    { attrs: { href: "#" } },
                    [
                      _c("v-icon", { attrs: { name: "bell" } }),
                      _c(
                        "span",
                        {
                          staticClass: "link__text",
                          on: {
                            click: function($event) {
                              return _vm.$router.push(
                                "/profile/notifications-settings"
                              )
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t("general.editNotificationsSettings")
                              ) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-dropdown-item",
                    { attrs: { href: "#" } },
                    [
                      _c("v-icon", { attrs: { name: "key" } }),
                      _c(
                        "span",
                        {
                          staticClass: "link__text",
                          on: {
                            click: function($event) {
                              return _vm.$router.push("/profile/password")
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("general.changePassword")) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-nav-text",
                    { attrs: { id: "selectlang__wrapper" } },
                    [
                      _c("v-icon", {
                        attrs: {
                          id: "#selectlang__icon",
                          name: "globe",
                          scale: "1.5"
                        }
                      }),
                      _c("b-form-select", {
                        attrs: {
                          id: "selectlang__ctrl",
                          options: _vm.optionLang
                        },
                        on: { change: _vm.setLocale },
                        model: {
                          value: _vm.$i18n.locale,
                          callback: function($$v) {
                            _vm.$set(_vm.$i18n, "locale", $$v)
                          },
                          expression: "$i18n.locale"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-dropdown-item",
                    { attrs: { href: "#" } },
                    [
                      _c("v-icon", { attrs: { name: "door-closed" } }),
                      _c("span", { staticClass: "link__text" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("general.logoutOtherSessions")) +
                            "\n          "
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-dropdown-item",
                    { staticClass: "versionMenu" },
                    [
                      _c("small", [
                        _vm._v(" Version " + _vm._s(_vm.appVersion))
                      ]),
                      _vm.needsAppUpdate
                        ? _c(
                            "b-button",
                            {
                              attrs: { size: "sm", variant: "info" },
                              on: { click: _vm.appUpdate }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("general.update")) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("b-dropdown-divider"),
                  _c(
                    "b-dropdown-item",
                    { attrs: { to: "/logout" } },
                    [
                      _c("v-icon", { attrs: { name: "sign-out-alt" } }),
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("general.signOut")) +
                          "\n        "
                      )
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }