import configService from '@/services/config.service';
import featureFlagService from '@/services/feature-flag.service';
import constant from '@/services/constant';
import _ from 'lodash';

import {
  SET_ALL_CONFIG,
  SET_GENERAL_CONFIG,
  SET_PATIENT_CONFIG,
  SET_APPOINTMENT_CONFIG,
  SET_OCCUPATIONAL_SERVICE_CONFIG,
  SET_INVENTORY_CONFIG,
  SET_INVOICE_CONFIG,
  SET_SURVEY_FORM,
  SET_FEEDBACK_FORM,
  SET_TREATMENT_RECORD_FORM,
  SET_THRIVEADULT_FORM,
  SET_THRIVECHILD_FORM,
  SET_MEMBERSHIP_CONFIG,
  SET_FEATURE_FLAG,
  SET_RULE_CONFIG,
  SET_TRANSFER_CREDIT_POINT_CONFIG,
  SET_LOTUS_MEDICAL_CONFIG,
  SET_INTEGRATED_TEST_CONFIG,
  SET_DC_CONFIG,
  SET_REPORT_CONFIG,
  SET_SMG_CONFIG,
  SET_VIRTUALCURRENCY_CONFIG,
  RESET_CONFIG,
  SET_IPD_PHARMACY,
  SET_SUPPLIER_CONFIG,
  SET_NURSING_CONFIG,
  SET_FINANCIAL_COUNSELING_CONFIG,
  SET_THIRDPARTY_CONFIG,
  SET_APPOINTMENT_URLS,
  SET_IS_HQ,
} from './type.mutation';

const defaultPaymentModes = [
  {
    name: 'Cash',
    mode: 'cash',
    processType: '',
    processValue: '',
    defaultPayment: true,
  },
  {
    name: 'Offset-Credit Note',
    mode: 'offset_credit',
    processType: '',
    processValue: '',
    defaultPayment: true,
  },
  {
    name: 'Offset - Deposit',
    mode: 'offset_deposit',
    processType: '',
    processValue: '',
    defaultPayment: true,
  },
  {
    name: 'Credit Redeem',
    mode: 'credit_redeem',
    processType: '',
    processValue: '',
    defaultPayment: true,
  },
  {
    name: 'PayPal (Online) - Offset',
    mode: 'paypal_offset',
    processType: '',
    processValue: '',
    defaultPayment: true,
  },
  {
    name: 'Bank Transfer',
    mode: 'bank_transfer',
    processType: '',
    processValue: '',
    defaultPayment: true,
  },
  {
    name: 'Stripe',
    mode: 'stripe',
    processType: '',
    processValue: '',
    defaultPayment: true,
  },
];

const state = {
  all: {},
  isHQ: false,
  appointmentURL: '',
  appointmentWEBURL: '',
  general: {
    clinicName: '',
    companyRegNo: '',
    gstRegNo: '',
    clinicCode: '',
    clinicHeader: '',
    clinicLocalName: '',
    clinicLocalAddress: '',
    logoURL: '',
    country: '',
    address: '',
    useLocationAddress: false,
    telephone: '',
    fax: '',
    region: {
      name: 'Singapore',
      code: 'SG',
      currency: '$',
      googleCalendarId: 'en.singapore#holiday@group.v.calendar.google.com',
      phoneCode: '+65',
      thousandSeparator: ',',
    },
    bdayMsg: '',
  },
  patient: {
    mandatoryFields: {
      fullName: true,
      nric: true,
      dob: false,
      nationality: false,
      resAdd: false,
      mobNumber: false,
      emailAdd: false,
      drugAllergies: false,
      foodAllergies: false,
      clinicQuestnr: false,
      patientSig: false,
      province: false,
    },
    registrationOptions: {
      prefixPatientIdWithClinicCode: false,
      showClinicLogo: false,
      uniqueMobileNumber: true,
      zeroPaddPatientID: false,
      serializePatientID: false,
      zeroPaddPatientIDDigits: 1,
      zeroPaddPatientIDDigitsFlag: false,
    },
    vitals: [],
    printLabel: {
      clinicName: true,
      clinicAddress: true,
      patientName: true,
      nricfin: false,
      patientID: false,
      patientDOB: false,
      patientSex: false,
      nationality: false,
      age: false,
      patientAddress: false,
      mobile: false,
    },
    printConsultNote: {
      clinicLogo: true,
      clinicName: true,
      clinicAddress: true,
      patientAddress: true,
      doctorName: true,
      therapistName: true,
      patientName: true,
      patientNRIC: true,
      patientDOB: true,
      patientGender: true,
      patientNationality: true,
      medicalServices: true,
      visitDate: true,
    },
    printLetterHeader: {
      clinicLogo: [true, true, true],
      clinicName: [true, true, true],
      clinicPhone: [true, true, true],
      clinicAddress: [true, true, true],
      todayDate: [true, true, true],
      todayDateBE: [true, true, true],
      printedDateTime: [true, true, true],
      patientName: [true, true, true],
      nricfin: [true, true, true],
      patientID: [true, true, true],
      patientDOB: [true, true, true],
      patientSex: [true, true, true],
      clinicLocalName: [true, true, true],
      clinicLocalAddress: [true, true, true],
      patientLocalName: [true, true, true],
      patientAge: [true, true, true],
    },
    printLetterFooter: {
      clinicName: [true, true, true],
      clinicPhone: [true, true, true],
      clinicAddress: [true, true, true],
      todayDate: [true, true, true],
      todayDateBE: [true, true, true],
      printedDateTime: [true, true, true],
      patientName: [true, true, true],
      nricfin: [true, true, true],
      patientID: [true, true, true],
      patientDOB: [true, true, true],
      patientSex: [true, true, true],
      clinicLocalName: [true, true, true],
      clinicLocalAddress: [true, true, true],
      patientLocalName: [true, true, true],
      patientAge: [true, true, true],
    },
    printPackageHeader: {
      clinicLogo: true,
      customContent: '',
      // clinicName: [true, true, true],
      // clinicPhone: [true, true, true],
      // clinicAddress: [true, true, true],
      // todayDate: [true, true, true],
      // printedDateTime: [true, true, true],
      // patientName: [true, true, true],
      // nricfin: [true, true, true],
      // patientID: [true, true, true],
      // patientDOB: [true, true, true],
      // patientSex: [true, true, true],
    },
    printPrescriptionHeader: {
      clinicLogo: true,
      clinicName: true,
      clinicAddress: true,
      patientName: true,
      patientGender: true,
      patientAge: true,
      patientTelephone: true,
      patientID: true,
      patientVN: true,
      serviceType: true,
      diagnosis: true,
      patientSkinTest: true,
      invoiceDate: true,
      allergies: true,
      weight: true,
    },
    consultNotesLayout: 'VISIT_DATE',
    consultMaxPaginationNotes: 5,
    visitDateViewDefaultMode: 'OPEN_ALL_NOTES',
    lockConsultNotesUpdate: false,
    multipleICD10Recording: true,
    lockConsultNoteAt: false,
    enableAddendum: false,
    enableConsultNoteSectioning: false,
    allowEnhancedControl: false,
    lockConsultNoteTime: '23:59',
    mandatoryICD10Recording: false,
    tags: [],
    notification: {
      enableDraftNotesEmail: false,
      emailRecipients: [],
      emailSendingTime: '',
    },
    smartChart: false,
  },
  appointment: {
    mandatoryFields: {
      category: true,
      medService: true,
      room: true,
      provider: true,
      organiser: true,
      apptmntDetails: false,
    },
    hoverFields: {
      apptmntDetails: true,
      category: true,
      type: true,
      medService: true,
      room: true,
      provider: false,
      therapist: false,
      consultant: false,
      secondaryProvider: false,
      organiser: false,
    },
    category: [],
    aptCategoryNames: [],
    calendar: {
      timeRangeFrom: '',
      timeRangeTo: '',
      syncToICal: {
        apptType: false,
        category: false,
        medService: false,
        room: false,
        provider: false,
        therapist: false,
        consultant: false,
        secondaryProvider: false,
        organiser: false,
        apptDetails: false,
      },
    },
    notification: {
      smsReminder: false,
      hrBeforeSendSms: 1,
      emailReminder: false,
      hrBeforeSendEmail: 1,
      smsReminderOncePerDayPerPatientForManyAppts: false,
      emailReminderOncePerDayPerPatientForManyAppts: false,
    },
    otherConfig: {
      maxAppointmentCalendarView: '',
      defaultCalendarInterval: '',
      defaultAptDuration: 30,
      scheduler: false,
      crossBranchBookingCancel: false,
      allowConcurrentBookingRoomArea: false,
    },
  },
  occupationalService: {
    emailOption: {
      enable: false,
      addresses: [],
      recipients: [],
      sendMonthDate: 1,
    },
    returnVisitScheduleOption: {
      nextWeek: true,
      nextMonth: true,
    },
    content: '',
  },
  inventory: {
    salesOptionsConfig: {
      expendablesAreSellable: false,
    },
    notification: {
      enableLowStockEmail: false,
      emailRecipients: [],
      emailSendingTime: '',
    },
    notifyPOReview: false,
    usersToNotify: [],
    orderingConfig: {
      createDOWithoutPO: true,
      enablePOReview: false,
      enablePOAutoGeneration: false,
    },
    purchaseOrderingConfig: {
      print: {
        supplierName: true,
        supplierAddress: true,
        clinicName: true,
        clinicAddress: true,
        purchaseOrderNumber: true,
        purchaseOrderCreatedDate: true,
        itemRate: true,
        taxRate: true,
        lineItemAmount: true,
        subtotalTaxAndTotalAmount: true,
        notes: true,
      },
    },
    expiryFormatConfig: 'YYYY-MM-DD',
    enableNegInvStock: false,
    heldInvStock: false,
    usePredefinedCategories: false,
    invCategory: [],
  },
  invoice: {
    printing: {
      defaultCategorized: true,
      clinicLogo: false,
      clinicAdd: false,
      patientAdd: false,
      footer: false,
      regNumber: false,
      docName: false,
      docQual: false,
      diagnosis: false,
      lineItemsDesc: false,
      footerContent: '',
    },
    corporatePrinting: {
      defaultCategorized: true,
      clinicLogo: false,
      clinicAdd: false,
      patientAdd: false,
      footer: false,
      regNumber: false,
      docName: false,
      docQual: false,
      diagnosis: false,
      lineItemsDesc: false,
      footerContent: '',
      companyName: false,
      companyAddress: false,
    },
    requestApproval: {
      classified: {},
      normal: {},
    },
    includeGST: true,
    splitPayments: false,
    fixedPriceForPowder: false,
    fixedPriceForPowderPrice: 1.00,
    fixedPriceForPowderCategory: '科中',
    tcmMaxGramPerDay: 18,
    isRatioReadOnly: false,
    chineseHerbalMedicineOrderRecieverEmail: '',
    defaultTotalDays: 1,
    tcmHistoryFields: {},
    tcmVisitRecordFields: {},
    roundOff: 'None',
    roundOffLineItemOrTotal: 'ROUNDTOTAL',
    modes: [
      {
        name: 'Cash',
        mode: 'cash',
        processType: '',
        processValue: '',
        defaultPayment: true,
      },
      {
        name: 'Offset-Credit Note',
        mode: 'offset_credit',
        processType: '',
        processValue: '',
        defaultPayment: true,
      },
      {
        name: 'Offset - Deposit',
        mode: 'offset_deposit',
        processType: '',
        processValue: '',
        defaultPayment: true,
      },
      {
        name: 'Credit Redeem',
        mode: 'credit_redeem',
        processType: '',
        processValue: '',
        defaultPayment: true,
      },
      {
        name: 'PayPal (Online) - Offset',
        mode: 'paypal_offset',
        processType: '',
        processValue: '',
        defaultPayment: true,
      },
      {
        name: 'Bank Transfer',
        mode: 'bank_transfer',
        processType: '',
        processValue: '',
        defaultPayment: true,
      },
      {
        name: 'Stripe',
        mode: 'stripe',
        processType: '',
        processValue: '',
        defaultPayment: true,
      },
    ],
    exchangeRate: 0,
    taxName: 'GST',
    taxPercentageAmount: 7,
    autoDraftExpiration: {
      enable: false,
      expiryTime: '',
    },
    taxInventories: [],
    itemOrder: 'latestItemAtBottom',
    sendInvoiceToPatient: false,
    sendEmailInvoiceToPatient: false,
    sendSMSInvoiceToPatient: false,
    autoSaveInvoiceOption: false,
    categoryBased: false,
    invoiceCategory: [],
    allowOverPaymentOption: false,
    allowRefundOption: false,
    emailTemplates: [],
    smsTemplates: [],
    defaultEmailTemplate: 0,
    defaultSmsTemplate: 0,
  },
  membership: {
    enableModule: false,
    pointDeductionType: 'MANUAL',
    tiers: [],
    templates: {
      newMember: {},
      upgrade: {},
      downgrade: {},
      retention: {},
      membershipExpiry: {},
      additionalSpending: {},
    },
    redeemPointRatio: {},
  },
  surveyForm: {},
  feedbackForm: {},
  treatmentRecordForm: {},
  thriveAdultForm: {},
  thriveChildForm: {},
  featureFlags: {
    bgrimm: false,
    durgClassificationApproval: false,
    smg: false,
    lotusMedical: false,
    healthScreeningReport: false,
    hidePatientRace: false,
    tphInvoice: false,
    inpatient: false,
    tvic: false,
    teleconsult: false,
    peaceclinic: false,
    china: false,
    prolabhl7: false,
  },
  queueStatus: {
    rules: [],
  },
  transferCreditPoint: {
    transferFeeCredit: 0,
    minCreditTransAmount: 0,
    validityTransCredit: 1,
    transferFeePoint: 0,
    minPointTransAmount: 0,
    validityTransPoint: 1,
    emailAlertForExcessivePoint: 0,
    emailAddress: '',
  },
  integratedTest: {
    radiologyUrl: '',
    radiologyLogoUrl: '',
    buildDynamicVuemotionImageUrl: false,
    vuemotionImageBaseUrl: '',
    addPatientIDToVuemotionImageBaseUrl: false,
    radiologyPort: '',
    laboratoryUrl: '',
    laboratoryPort: '',
    statusGroups: ['Confirmed', 'In Progress', 'Report Generated', 'Cancelled'],
    statuses: {
      Radiology: {
        Confirmed: [
          {
            _id: '123',
            code: '01',
            labelName: 'Confirm Test Order',
            name: 'Test Order Confirmed',
            default: true,
            editMode: false,
            statusGroup: 'Confirmed',
          },
        ],
        'In Progress': [
          {
            _id: '124',
            code: '02',
            labelName: 'In Progress',
            name: 'In Progress',
            editMode: false,
            statusGroup: 'In Progress',
          },
        ],
        'Report Generated': [
          {
            _id: '127',
            code: '08',
            labelName: 'Generate Report',
            name: 'Report Generated',
            default: true,
            editMode: false,
            userSelectable: false,
            statusGroup: 'Report Generated',
          },
          {
            _id: '127b',
            code: '09',
            labelName: 'Publish Report',
            name: 'Report Published',
            default: true,
            editMode: false,
            userSelectable: false,
            statusGroup: 'Report Generated',
          },
        ],
        Cancelled: [
          {
            _id: '128',
            code: '00',
            labelName: 'Cancel Test',
            name: 'Cancelled',
            default: true,
            editMode: false,
            statusGroup: 'Cancelled',
          },
        ],
      },
      Laboratory: {
        Confirmed: [
          {
            _id: '133',
            code: '11',
            labelName: 'Confirm Test Order',
            name: 'Test Order Confirmed',
            default: true,
            statusGroup: 'Confirmed',
          },
        ],
        'In Progress': [
          {
            _id: '134',
            code: '12',
            labelName: 'In Progress',
            name: 'In Progress',
            statusGroup: 'In Progress',
          },
        ],
        'Report Generated': [
          {
            _id: '135',
            code: '18',
            labelName: 'Generate Report',
            name: 'Report Generated',
            default: true,
            editMode: false,
            userSelectable: false,
            statusGroup: 'Report Generated',
          },
          {
            _id: '135b',
            code: '19',
            labelName: 'Publish Report',
            name: 'Report Published',
            default: true,
            editMode: false,
            userSelectable: false,
            statusGroup: 'Report Generated',
          },
        ],
        Cancelled: [
          {
            _id: '136',
            code: '10',
            labelName: 'Cancel Test',
            name: 'Cancelled',
            default: true,
            statusGroup: 'Cancelled',
          },
        ],
      },
      'Health Screening': {
        Confirmed: [
          {
            _id: '137',
            code: '21',
            labelName: 'Confirm Test Order',
            name: 'Test Order Confirmed',
            default: true,
            statusGroup: 'Confirmed',
          },
        ],
        'In Progress': [
          {
            _id: '138',
            code: '22',
            labelName: 'In Progress',
            name: 'In Progress',
            statusGroup: 'In Progress',
          },
        ],
        'Report Generated': [
          {
            _id: '139',
            code: '28',
            labelName: 'Generate Report',
            name: 'Report Generated',
            default: true,
            editMode: false,
            userSelectable: false,
            statusGroup: 'Report Generated',
          },
          {
            _id: '139b',
            code: '29',
            labelName: 'Publish Report',
            name: 'Report Published',
            default: true,
            editMode: false,
            userSelectable: false,
            statusGroup: 'Report Generated',
          },
        ],
        Cancelled: [
          {
            _id: '140',
            code: '20',
            labelName: 'Cancel Test',
            name: 'Cancelled',
            default: true,
            statusGroup: 'Cancelled',
          },
        ],
      },
    },
  },
  lotusMedical: {
    url: '',
    clinetSecret: '',
  },
  dragonCare: {
    editableProfileFields: {
      name: false,
      nric: false,
      gender: false,
      dateOfBirth: false,
      email: false,
      mobile: false,
      address: false,
      occupation: false,
      literacy: false,
      bloodGroup: false,
      rhesus: false,
    },
    enableAptBooking: true,
    enablePkgForAptBooking: true,
    rolesForMsgSending: [],
    toNotifyOnNewProspects: [],
    toNotifyOnNewApptBooking: [],
  },
  report: {
    reportPasswordEnabled: false,
    reportPassword: '',
    sendExecuteTask: false,
    sendClarifyExecuteTask: false,
    sendClarifyCancelTask: false,
  },
  SMG: {
    companyCode: '',
    clinicCode: '',
  },
  virtualCurrency: {
    enable: false,
    name: '',
    currencyCode: 'VC',
    exchangeRate: 1,
    autoConvertCurrency: false,
    validityPeriod: 12,
    notifyLowVirtualCurrency: false,
    notifyLowVirtualCurrencyLimit: 1,
    usersToNotify: [],
  },
  ipdPharmacy: {
    sendExecuteTask: false,
    sendClarifyExecuteTask: false,
    sendClarifyCancelTask: false,
  },
  supplier: {
    viewExternalSuppliersForAllRoles: false,
  },
  nursing: {
    prePlanningForDischargeTask: '',
    preDischargeTrigger: '',
    preDischargeCancellation: '',
  },
  financialCounseling: {
    warningAmount: 0,
  },
  thirdParty: {
    paypal: [],
    stripe: [],
    xero: [],
    line: [],
    enableXero: false,
    enableLine: false,
    enableStripeOnline: false,
    enableStripeTerminal: false,
    grabScan: {
      enabled: false,
      enableSlider: false,
      clinicMappings: [],
    },
    registrationPaymentStripe: false,
    registrationPaymentPaypal: false,
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  [RESET_CONFIG](_state) {
    _state.all = {};
    _state.general = {};
    _state.patient = {};
    _state.appointment = {};
    _state.occupationalService = {};
    _state.inventory = {};
    _state.invoice = {};
    _state.surveyForm = {};
    _state.feedbackForm = {};
    _state.treatmentRecordForm = {};
    _state.thriveAdultForm = {};
    _state.thriveChildForm = {};
    _state.membership = {};
    _state.featureFlags = {};
    _state.queueStatus = {};
    _state.transferCreditPoint = {};
    _state.lotusMedical = {};
    _state.integratedTest = {};
    _state.dragonCare = {};
    _state.report = {};
    _state.virtualCurrency = {};
    _state.thirdParty = {
      paypal: [],
      stripe: [],
      xero: [],
      enableXero: false,
      enableStripeOnline: false,
      enableStripeTerminal: false,
      grabScan: {
        enabled: false,
        enableSlider: false,
        clinicMappings: [],
      },
    };
    _state.appointmentURL = '';
    _state.appointmentWEBURL = '';
    _state.isHQ = false;
  },
  [SET_ALL_CONFIG](_state, payload) {
    _state.all = Object.assign({}, _state.all, payload);
  },
  [SET_GENERAL_CONFIG](_state, payload) {
    const { general } = _state;
    _state.general = { ...general, ...payload };
  },
  [SET_PATIENT_CONFIG](_state, payload) {
    const { patient } = _state;
    _state.patient = { ...patient, ...payload };
  },
  [SET_APPOINTMENT_CONFIG](_state, payload) {
    const { appointment } = _state;
    payload.aptCategoryNames.push('Uncategorized');
    _state.appointment = { ...appointment, ...payload };
  },
  [SET_OCCUPATIONAL_SERVICE_CONFIG](_state, payload) {
    const { occupationalService } = _state;
    _state.occupationalService = { ...occupationalService, ...payload };
  },
  [SET_INVENTORY_CONFIG](_state, payload) {
    const { inventory } = _state;
    _state.inventory = { ...inventory, ...payload };
  },
  [SET_INVOICE_CONFIG](_state, payload) {
    const { invoice } = _state;
    _state.invoice = { ...invoice, ...payload };

    // check and add default payment modes
    const modes = payload.modes.map(m => m.name);
    const defaultModesNotInPayload = defaultPaymentModes.filter(mode => !modes.includes(mode.name));
    if (defaultModesNotInPayload.length > 0) {
      const newModes = [...payload.modes, ...defaultModesNotInPayload];
      // sort newModes
      const defaultModeNames = defaultPaymentModes.map(m => m.name);
      const modesNotDefault = newModes.filter(m => !defaultModeNames.includes(m.name));
      const defaultModes = defaultModeNames.map(name => newModes.find(m => m.name === name));
      _state.invoice.modes = [...defaultModes, ...modesNotDefault];
    }

    if (!payload.taxInventories) {
      // default state, select all inventories for Tax
      _state.invoice.taxInventories = Object.keys(constant.INVENTORY_TYPES);
    }

    // NOTE: if value is undefine, the object merge will not remove the attributes
    if (!payload.locationSettings || _.isEmpty(payload.locationSettings)) {
      _state.invoice.locationSettings = {};
    }
  },
  [SET_SURVEY_FORM](_state, payload) {
    const { surveyForm } = _state;
    _state.surveyForm = { ...surveyForm, ...payload };
  },
  [SET_FEEDBACK_FORM](_state, payload) {
    const { feedbackForm } = _state;
    _state.feedbackForm = { ...feedbackForm, ...payload };
  },
  [SET_TREATMENT_RECORD_FORM](_state, payload) {
    const { treatmentRecordForm } = _state;
    _state.treatmentRecordForm = { ...treatmentRecordForm, ...payload };
  },
  [SET_THRIVEADULT_FORM](_state, payload) {
    const { thriveAdultForm } = _state;
    _state.thriveAdultForm = { ...thriveAdultForm, ...payload };
  },
  [SET_THRIVECHILD_FORM](_state, payload) {
    const { thriveChildForm } = _state;
    _state.thriveChildForm = { ...thriveChildForm, ...payload };
  },
  [SET_MEMBERSHIP_CONFIG](_state, payload) {
    const { membership } = _state;
    _state.membership = { ...membership, ...payload };
  },
  [SET_FEATURE_FLAG](_state, payload) {
    _state.featureFlags = _.isEmpty(payload)
      ? _.cloneDeep(_state.featureFlags)
      : _.cloneDeep(payload);
  },
  [SET_RULE_CONFIG](_state, payload) {
    const { queueStatus } = _state;
    _state.queueStatus = { ...queueStatus, ...payload };
  },
  [SET_TRANSFER_CREDIT_POINT_CONFIG](_state, payload) {
    const { transferCreditPoint } = _state;
    _state.transferCreditPoint = { ...transferCreditPoint, ...payload };
  },
  [SET_LOTUS_MEDICAL_CONFIG](_state, payload) {
    const { lotusMedical } = _state;
    _state.lotusMedical = { ...lotusMedical, ...payload };
  },
  [SET_INTEGRATED_TEST_CONFIG](_state, payload) {
    console.log('in set int test config commit');
    const { integratedTest } = _state;
    _state.integratedTest = { ...integratedTest, ...payload };
    console.log('_state.integratedTest is now ', _state.integratedTest);
  },
  [SET_DC_CONFIG](_state, payload) {
    const { dragonCare } = _state;
    _state.dragonCare = { ...dragonCare, ...payload };
  },
  [SET_REPORT_CONFIG](_state, payload) {
    const {
      reportPasswordEnabled,
      reportPassword,
      sendExecuteTask,
      sendClarifyExecuteTask,
      sendClarifyCancelTask,
    } = _.isEmpty(payload)
      ? state.report
      : payload;
    _state.report = {
      reportPasswordEnabled,
      reportPassword,
      sendExecuteTask,
      sendClarifyExecuteTask,
      sendClarifyCancelTask,
    };
  },
  [SET_SMG_CONFIG](_state, payload) {
    const { companyCode, clinicCode } = _.isEmpty(payload)
      ? state.SMG
      : payload;
    _state.SMG = { companyCode, clinicCode };
  },
  [SET_VIRTUALCURRENCY_CONFIG](_state, payload) {
    const {
      enable, name, currencyCode, exchangeRate, autoConvertCurrency,
      validityPeriod, notifyLowVirtualCurrency, notifyLowVirtualCurrencyLimit, usersToNotify,
    } = _.isEmpty(payload)
      ? state.virtualCurrency
      : payload;
    _state.virtualCurrency = {
      // eslint-disable-next-line max-len
      enable, name, currencyCode, exchangeRate, autoConvertCurrency, validityPeriod, notifyLowVirtualCurrency, notifyLowVirtualCurrencyLimit, usersToNotify,
    };
  },
  [SET_IPD_PHARMACY](_state, payload) {
    const { ipdPharmacy } = _state;
    _state.ipdPharmacy = { ...ipdPharmacy, ...payload };
  },
  [SET_SUPPLIER_CONFIG](_state, payload) {
    const { supplier } = _state;
    _state.supplier = { ...supplier, ...payload };
  },
  [SET_NURSING_CONFIG](_state, payload) {
    const { nursing } = _state;
    _state.nursing = { ...nursing, ...payload };
  },
  [SET_FINANCIAL_COUNSELING_CONFIG](_state, payload) {
    const { financialCounseling } = _state;
    _state.financialCounseling = { ...financialCounseling, ...payload };
  },
  [SET_THIRDPARTY_CONFIG](_state, payload) {
    const { thirdParty } = _state;
    _state.thirdParty = { ...thirdParty, ...payload };
  },
  [SET_APPOINTMENT_URLS](_state, { appointmentURL, appointmentWEBURL }) {
    _state.appointmentURL = appointmentURL;
    _state.appointmentWEBURL = appointmentWEBURL;
  },
  [SET_IS_HQ](_state, { isHQ }) {
    _state.isHQ = isHQ;
  },
};

function setConfigForAllFields(commit, data) {
  const {
    general,
    patient,
    invoice,
    appointment,
    occupationalService,
    inventory,
    surveyForm,
    feedbackForm,
    treatmentRecordForm,
    thriveAdultForm,
    thriveChildForm,
    membership,
    featureFlags,
    queueStatus,
    transferCreditPoint,
    lotusMedical,
    integratedTest,
    dragonCare,
    report,
    SMG,
    virtualCurrency,
    ipdPharmacy,
    supplier,
    nursing,
    financialCounseling,
    thirdParty,
    appointmentURL,
    appointmentWEBURL,
    isHQ,
  } = data;
  appointment.aptCategoryNames = appointment.category.map(cat => cat.name);
  commit(SET_ALL_CONFIG, data);
  commit(SET_GENERAL_CONFIG, general);
  commit(SET_PATIENT_CONFIG, patient);
  commit(SET_APPOINTMENT_CONFIG, appointment);
  commit(SET_OCCUPATIONAL_SERVICE_CONFIG, occupationalService);
  commit(SET_INVENTORY_CONFIG, inventory);
  commit(SET_INVOICE_CONFIG, invoice);
  commit(SET_SURVEY_FORM, surveyForm);
  commit(SET_FEEDBACK_FORM, feedbackForm);
  commit(SET_TREATMENT_RECORD_FORM, treatmentRecordForm);
  commit(SET_THRIVEADULT_FORM, thriveAdultForm);
  commit(SET_THRIVECHILD_FORM, thriveChildForm);
  commit(SET_MEMBERSHIP_CONFIG, membership);
  commit(SET_FEATURE_FLAG, featureFlags);
  commit(SET_RULE_CONFIG, queueStatus);
  commit(SET_TRANSFER_CREDIT_POINT_CONFIG, transferCreditPoint);
  commit(SET_LOTUS_MEDICAL_CONFIG, lotusMedical);
  commit(SET_INTEGRATED_TEST_CONFIG, integratedTest);
  commit(SET_DC_CONFIG, dragonCare);
  commit(SET_REPORT_CONFIG, report);
  commit(SET_SMG_CONFIG, SMG);
  commit(SET_VIRTUALCURRENCY_CONFIG, virtualCurrency);
  commit(SET_IPD_PHARMACY, ipdPharmacy);
  commit(SET_SUPPLIER_CONFIG, supplier);
  commit(SET_NURSING_CONFIG, nursing);
  commit(SET_FINANCIAL_COUNSELING_CONFIG, financialCounseling);
  commit(SET_THIRDPARTY_CONFIG, thirdParty || { paypal: [] });
  commit(SET_APPOINTMENT_URLS, {
    appointmentURL,
    appointmentWEBURL,
  });
  commit(SET_IS_HQ, { isHQ });
}

const actions = {
  loadConfig({ commit }, param = {}) {
    console.log('loadConfig...');
    return Promise.all([
      configService.show(param),
      featureFlagService.get(),
    ]).then(([configResponse, featureFlagsResponse]) => {
      const config = configResponse.data || {};
      const featureFlags = featureFlagsResponse.data || {};
      if (!_.isEmpty(featureFlags)) {
        config.featureFlags = _.cloneDeep(_.omit(featureFlags, ['id', '_id', '_v']));
      }
      setConfigForAllFields(commit, config);
      return config;
    });
  },
  updateConfig({ commit }, payload) {
    commit('setLoading', true, { root: true });
    // console.log('Config Save >>>', payload)
    return Promise.all([
      configService.upsert(payload),
      featureFlagService.get(),
    ]).then(([configResponse, featureFlagsResponse]) => {
      const config = configResponse.data || {};
      if (configResponse.success) {
        const featureFlags = featureFlagsResponse.data || {};
        if (!_.isEmpty(featureFlags)) {
          config.featureFlags = _.omit(featureFlags, ['id', '_id', '_v']);
        }
        setConfigForAllFields(commit, config);
        return config;
      }
      commit('setLoading', false, { root: true });
      return config;
    });
  },
  resetConfig({ commit }) {
    commit(RESET_CONFIG);
  },
};

const getters = {
  all: _state => _state.all,
  appointmentURL: _state => _state.appointmentURL,
  appointmentWEBURL: _state => _state.appointmentWEBURL,
  general: _state => _state.general,
  patient: _state => _state.patient,
  appointment: _state => _state.appointment,
  occupationalService: _state => _state.occupationalService,
  inventory: _state => _state.inventory,
  invoice: _state => _state.invoice,
  surveyForm: _state => _state.surveyForm,
  feedbackForm: _state => _state.feedbackForm,
  treatmentRecordForm: _state => _state.treatmentRecordForm,
  thriveAdultForm: _state => _state.thriveAdultForm,
  thriveChildForm: _state => _state.thriveChildForm,
  membership: _state => _state.membership,
  featureFlags: _state => _state.featureFlags,
  queueStatus: _state => _state.queueStatus,
  transferCreditPoint: _state => _state.transferCreditPoint,
  lotusMedical: _state => _state.lotusMedical,
  integratedTest: _state => _state.integratedTest,
  isGrabScanEnabled: _state => _state.thirdParty.grabScan && _state.thirdParty.grabScan.enabled,
  isGrabScanSliderEnabled: _state => _state.thirdParty.grabScan && _state.thirdParty.grabScan.enableSlider,
  grabscanClinicCodeMap: _state => ((_state.thirdParty.grabScan && _state.thirdParty.grabScan.clinicMappings) || [])
    .filter(c => c.grabScan && c.grabScan.clinicCode)
    .reduce((map, c) => ({
      ...map,
      [c.clinic]: c.grabScan.clinicCode,
    }), {}),
  integratedTestStatusesOptions: (_state) => {
    const options = [];
    for (const testType in _state.integratedTest.statuses) {
      for (const statusGroup in _state.integratedTest.statuses[testType]) {
        _state.integratedTest.statuses[testType][statusGroup].forEach((status) => {
          const option = {
            _id: status._id,
            name: `${status.name} (${testType})`,
          };
          options.push(option);
        });
      }
      console.log('options  ', options);
    }
    return options;
  },
  integratedTestStatusesMapped: (_state) => {
    const dict = {};
    for (const testType in _state.integratedTest.statuses) {
      for (const statusGroup in _state.integratedTest.statuses[testType]) {
        _state.integratedTest.statuses[testType][statusGroup].forEach((status) => {
          status.testType = testType;
          status.statusGroup = statusGroup;
          dict[status._id] = status;
        });
      }
    }
    return dict;
  },
  dragonCare: _state => _state.dragonCare,
  report: _state => _state.report,
  SMG: _state => _state.SMG,
  virtualCurrency: _state => _state.virtualCurrency,
  ipdPharmacy: _state => _state.ipdPharmacy,
  supplier: _state => _state.supplier,
  nursing: _state => _state.nursing,
  financialCounseling: _state => _state.financialCounseling,
  thirdParty: _state => _state.thirdParty,
  lineCredentials: (_state) => {
    let creds = null;
    if (_state.thirdParty && _state.thirdParty.enableLine && _state.thirdParty.line) {
      creds = _state.thirdParty.line.reduce((acc, cur) => ({
        ...acc,
        [cur.clinicId]: cur,
      }), {});
    }
    return creds;
  },
  isHQ: _state => _state.isHQ,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
