var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "vdp-datepicker": _vm.lib === "vuedate" } },
    [
      _vm.lib === "el"
        ? _c(
            "div",
            [
              _c("el-date-picker-custom", {
                ref: "customDatePicker",
                class: ["date-filter el-custom-input", _vm.parentClass],
                attrs: {
                  type: _vm.elDateType,
                  disabled: _vm.disabled,
                  placeholder: _vm.placeholder,
                  "picker-options": _vm.pickerOptions,
                  format: _vm.format
                },
                model: {
                  value: _vm.dateSelectedOld,
                  callback: function($$v) {
                    _vm.dateSelectedOld = $$v
                  },
                  expression: "dateSelectedOld"
                }
              }),
              _c(
                "label",
                {
                  staticClass: "el-custom-label",
                  on: { click: _vm.handleElClick }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm._f("formatVDDate")(_vm.dateSelectedOld, {
                          format:
                            (_vm.format && _vm.format.toUpperCase()) ||
                            "DD/MM/YYYY HH:mm:ss"
                        })
                      ) +
                      "\n    "
                  )
                ]
              )
            ],
            1
          )
        : _vm.lib === "vuedate"
        ? _c("datepicker", {
            attrs: {
              format: _vm.formatDate,
              disabled: _vm.disabled,
              language: _vm.getLanguage(),
              "disabled-dates": { customPredictor: _vm.disabledDatesFn },
              placeholder: _vm.placeholder,
              "input-class": [
                "form-control text-center date-picker-input",
                _vm.inputClass,
                _vm.parentClass
              ],
              name: _vm.name,
              inline: _vm.inline,
              "calendar-class": _vm.calendarClass
            },
            on: { selected: _vm.handleSelected },
            model: {
              value: _vm.dateSelectedOld,
              callback: function($$v) {
                _vm.dateSelectedOld = $$v
              },
              expression: "dateSelectedOld"
            }
          })
        : _c("span", [_vm._v(" Lib Not Supported ")])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }