export const SET_ALL_CONFIG = 'SET_ALL_CONFIG';
export const SET_GENERAL_CONFIG = 'SET_GENERAL_CONFIG';
export const SET_PATIENT_CONFIG = 'SET_PATIENT_CONFIG';
export const SET_APPOINTMENT_CONFIG = 'SET_APPOINTMENT_CONFIG';
export const SET_OCCUPATIONAL_SERVICE_CONFIG = 'SET_OCCUPATIONAL_SERVICE_CONFIG';
export const SET_INVENTORY_CONFIG = 'SET_INVENTORY_CONFIG';
export const SET_SMSEMAIL_CONFIG = 'SET_SMSEMAIL_CONFIG';
export const SET_PRINT_CONFIG = 'SET_PRINT_CONFIG';
export const SET_INVOICE_CONFIG = 'SET_INVOICE_CONFIG';
export const SET_SURVEY_FORM = 'SET_SURVEY_FORM';
export const SET_FEEDBACK_FORM = 'SET_FEEDBACK_FORM';
export const SET_TREATMENT_RECORD_FORM = 'SET_TREATMENT_RECORD_FORM';
export const SET_THRIVEADULT_FORM = 'SET_THRIVEADULT_FORM';
export const SET_THRIVECHILD_FORM = 'SET_THRIVECHILD_FORM';
export const SET_MEMBERSHIP_CONFIG = 'SET_MEMBERSHIP_CONFIG';
export const SET_FEATURE_FLAG = 'SET_FEATURE_FLAG';
export const SET_RULE_CONFIG = 'SET_RULE_CONFIG';
export const SET_TRANSFER_CREDIT_POINT_CONFIG = 'SET_TRANSFER_CREDIT_POINT_CONFIG';
export const SET_LOTUS_MEDICAL_CONFIG = 'SET_LOTUS_MEDICAL_CONFIG';
export const SET_INTEGRATED_TEST_CONFIG = 'SET_INTEGRATED_TEST_CONFIG';
export const SET_DC_CONFIG = 'SET_DC_CONFIG';
export const RESET_CONFIG = 'RESET_CONFIG';
export const SET_REPORT_CONFIG = 'SET_REPORT_CONFIG';
export const SET_SMG_CONFIG = 'SET_SMG_CONFIG';
export const SET_VIRTUALCURRENCY_CONFIG = 'SET_VIRTUALCURRENCY_CONFIG';
export const SET_IPD_PHARMACY = 'SET_IPD_PHARMACY';
export const SET_SUPPLIER_CONFIG = 'SET_SUPPLIER_CONFIG';
export const SET_NURSING_CONFIG = 'SET_NURSING_CONFIG';
export const SET_FINANCIAL_COUNSELING_CONFIG = 'SET_FINANCIAL_COUNSELING_CONFIG';
export const SET_THIRDPARTY_CONFIG = 'SET_THIRDPARTY_CONFIG';
export const SET_APPOINTMENT_URLS = 'SET_APPOINTMENT_URLS';
export const SET_IS_HQ = 'SET_IS_HQ';
