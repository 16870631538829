var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "oneclick-button" }, [
    !_vm.finalized
      ? _c(
          "button",
          {
            staticClass: "btn btn-outline-primary",
            on: {
              click: function($event) {
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.onClick($event)
              }
            }
          },
          [_vm._v(_vm._s(_vm.item.label))]
        )
      : _c("div", [_vm._v(_vm._s(_vm.answer))])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }