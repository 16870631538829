var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-lg" }, [
    _c("div", { staticClass: "background" }, [
      _vm.backgroundImageURL
        ? _c("img", {
            staticClass: "background-img",
            attrs: { src: _vm.backgroundImageURL }
          })
        : _vm._e()
    ]),
    _c(
      "div",
      {
        staticClass: "content",
        class: { "has-bg-image": _vm.backgroundImageURL }
      },
      [
        _c("header", { staticClass: "mb-1" }, [
          !_vm.backgroundImageURL
            ? _c("img", {
                staticClass: "logo",
                attrs: {
                  src: _vm.generalConfig.logoURL,
                  alt: _vm.generalConfig.clinicName
                }
              })
            : _vm._e(),
          _c("div", [
            _c("strong", [_vm._v(_vm._s(_vm.$t("general.date")) + ":")]),
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm._f("formatVDDate")(_vm.now, { format: "DD/MM/YYYY" })
                ) +
                "\n      "
            )
          ])
        ]),
        _c("section", [
          _c("h3", { staticClass: "text-center text-uppercase" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.$t("tcm.treatmentRecord")) + "\n      "
            )
          ]),
          _c("div", { staticClass: "patient-info mt-4" }, [
            _c("table", { staticClass: "table table-bordered" }, [
              _c("tr", [
                _c("td", [
                  _c("label", [_vm._v(_vm._s(_vm.$t("general.name")) + ":")]),
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.selectedPatient.name) +
                      "\n            "
                  )
                ]),
                _c("td", [
                  _c("label", [_vm._v(_vm._s(_vm.$t("general.gender")) + ":")]),
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.patientGender) +
                      "\n            "
                  )
                ])
              ]),
              _c("tr", [
                _c("td", [
                  _c("label", [
                    _vm._v(_vm._s(_vm.$t("general.chineseName")) + ":")
                  ]),
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.selectedPatient.localName) +
                      "\n            "
                  )
                ]),
                _c("td", [
                  _c("label", [
                    _vm._v(_vm._s(_vm.$t("general.idDocNumber")) + ":")
                  ]),
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.selectedPatient.nric) +
                      "\n            "
                  )
                ])
              ])
            ])
          ]),
          _c("div", { staticClass: "treatment-record mt-2" }, [
            _c(
              "table",
              { staticClass: "table table-bordered" },
              [
                _vm.treatmentRecord && _vm.treatmentRecord.medicalComplaint
                  ? _c("tr", [
                      _c("td", [
                        _c("strong", [
                          _vm._v(_vm._s(_vm.$t("tcm.medicalComplaint")) + ":")
                        ])
                      ]),
                      _c("td", [
                        _vm.getFieldType("medicalComplaint") === "richtext"
                          ? _c(
                              "div",
                              [
                                _c("rich-text-editor", {
                                  attrs: {
                                    html: _vm.treatmentRecord.medicalComplaint,
                                    viewing: true,
                                    printing: true
                                  }
                                })
                              ],
                              1
                            )
                          : _c("div", [
                              _vm._v(
                                _vm._s(_vm.treatmentRecord.medicalComplaint)
                              )
                            ])
                      ])
                    ])
                  : _vm._e(),
                _c("tr", [
                  _c("td", { staticStyle: { width: "25%" } }, [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.$t("tcm.visitDate")) + ":")
                    ])
                  ]),
                  _c("td", [
                    _vm._v(
                      "\n              " +
                        _vm._s(
                          _vm._f("formatVDDate")(_vm.consultation.visitDate, {
                            format: "DD/MM/YYYY",
                            sourceFormat: "x"
                          })
                        ) +
                        "\n            "
                    )
                  ])
                ]),
                _c("tr", [
                  _c("td", [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.$t("tcm.physicianTherapist")) + ":")
                    ])
                  ]),
                  _c("td", [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.physicianAndTherapist) +
                        "\n            "
                    )
                  ])
                ]),
                _c("tr", [
                  _c("td", [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.$t("general.visitLocation")) + ":")
                    ])
                  ]),
                  _c("td", [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.clinicName) +
                        "\n            "
                    )
                  ])
                ]),
                _vm._l(_vm.filteredTreatmentRecord, function(value, field) {
                  return _c("tr", { key: field }, [
                    _c("td", [
                      _c("strong", { staticClass: "text-capitalize" }, [
                        _vm._v(_vm._s(_vm.getTranslatedField(field)) + ":")
                      ])
                    ]),
                    _c("td", [
                      _vm.getFieldType(field) === "richtext"
                        ? _c(
                            "div",
                            [
                              _c("rich-text-editor", {
                                attrs: {
                                  html: value,
                                  viewing: true,
                                  printing: true
                                }
                              })
                            ],
                            1
                          )
                        : _c("div", [_vm._v(_vm._s(value))])
                    ])
                  ])
                })
              ],
              2
            )
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }