import moment from 'moment';

import pharmacyService from '@/services/pharmacy.service';
import dischargeService from '@/services/discharge.service';

import {
  SET_ALL_MED_DISPENSES,
  UPDATE_MED_DISPENSE,
  SET_ITEM_FOR_PRINT,
  SET_TODAYS_TODO_COUNT,
  SET_TODAYS_TODO_COUNT_IPD,
  SET_INVOICE_FOR_PRINT,
  SET_RAW_PHARMACY_ITEMS,
} from './type.mutation';

const state = {
  all: [],
  selectedItemForPrint: {},
  todaysTodoCount: 0,
  todaysTodoCountIPD: 0,
  rawPharmacyItems: [],
  print: {},
};

/* eslint-disable no-param-reassign */
const mutations = {
  [SET_ALL_MED_DISPENSES](_state, payload) {
    _state.all = payload;
  },
  [SET_RAW_PHARMACY_ITEMS](_state, payload) {
    _state.rawPharmacyItems = payload;
  },
  [UPDATE_MED_DISPENSE](_state, payload) {
    _state.all = _state.all.map(item => (item._id === payload._id ? payload : item));
    _state.rawPharmacyItems = _state.rawPharmacyItems.map(item => (item._id === payload._id ? { ...item, ...payload } : item));
  },
  [SET_ITEM_FOR_PRINT](_state, payload) {
    _state.selectedItemForPrint = payload || {};
  },
  [SET_INVOICE_FOR_PRINT](_state, payload) {
    _state.print = payload || {};
  },
  [SET_TODAYS_TODO_COUNT](_state, payload) {
    _state.todaysTodoCount = payload >= 0 ? payload : 0;
  },
  [SET_TODAYS_TODO_COUNT_IPD](_state, payload) {
    _state.todaysTodoCountIPD = payload >= 0 ? payload : 0;
  },
};

const actions = {
  fetchAllMedDispenses({ commit }, { clinic, fromDate, toDate }) {
    commit('setLoading', true, { root: true });
    return pharmacyService.list({ clinic, fromDate, toDate })
      .then((response) => {
        // TODO: chan - do mapping from a separate entity
        const mappedData = response.data.map(item => ({
          _id: item._id,
          invoice: item.invoice,
          clinic: item.clinic,
          patient: item.patient,
          service: item.medservice,
          doctor: item.doctor,
          therapist: item.therapist,
          visitDate: item.visitDate,
          items: item.items
            .filter(med => med.medicine !== null && med.medicine !== undefined)
            .map(med => ({
              medicine: {
                _id: med.medicine._id,
                name: med.medicine.name,
                unit: med.medicine.unit,
                thumbnailURL: med.medicine.thumbnailURL,
                imageURL: med.medicine.imageURL,
                inventory: med.medicine.inventory,
                strength: med.medicine.strength,
              },
              medItem: med,
              dosage: med.dosage,
              precaution1: med.precaution1,
              precaution2: med.precaution2,
              batch: med.batch,
              quantity: med.quantity,
              unitPrice: med.unitPrice,
              totalDiscountedPrice: med.totalDiscountedPrice,
              status: med.status,
              statusDate: med.statusDate,
              subInventory: med.subInventory || [],
              adHocCompounding: med.adHocCompounding,
              adHocCompoundName: med.adHocCompoundName,
              adHocCompoundUnit: med.adHocCompoundUnit,
              adHocCompoundDosage: med.adHocCompoundDosage,
              adHocCompoundPrecaution1: med.adHocCompoundPrecaution1,
              adHocCompoundPrecaution2: med.adHocCompoundPrecaution2,
            })),
        }));
        const rawMedMappedData = response.data.map(item => ({
          _id: item._id,
          invoice: item.invoice,
          clinic: item.clinic,
          patient: item.patient,
          service: item.medservice,
          doctor: item.doctor,
          therapist: item.therapist,
          visitDate: item.visitDate,
          items: item.items
            .filter(med => med.medicine !== null && med.medicine !== undefined),
        }));
        commit(SET_RAW_PHARMACY_ITEMS, rawMedMappedData);
        commit(SET_ALL_MED_DISPENSES, mappedData);
        commit('setLoading', false, { root: true });
        return mappedData;
      });
  },
  updateMedDispense({ commit, getters, dispatch }, payload) {
    const mappedData = {
      _id: payload._id,
      invoice: payload.invoice._id,
      clinic: payload.clinic,
      patient: payload.patient._id,
      visitDate: payload.visitDate,
      items: payload.items.map(med => ({
        medicine: med.medicine._id,
        batch: med.batch,
        dosage: med.dosage || '',
        precaution1: med.precaution1 || '',
        precaution2: med.precaution2 || '',
        quantity: med.quantity,
        status: med.status,
        statusDate: med.statusDate,
        totalDiscountedPrice: med.totalDiscountedPrice,
        unitPrice: med.unitPrice,
        is_changed : med.is_changed || false,
        subInventory: med.subInventory || [],
        adHocCompounding: med.adHocCompounding,
        adHocCompoundName: med.adHocCompoundName,
        adHocCompoundUnit: med.adHocCompoundUnit,
        adHocCompoundDosage: med.adHocCompoundDosage,
        adHocCompoundPrecaution1: med.adHocCompoundPrecaution1,
        adHocCompoundPrecaution2: med.adHocCompoundPrecaution2,
      })),
    };
    if (payload.service) {
      mappedData.medservice = payload.service._id;
    }
    if (payload.doctor) {
      mappedData.doctor = payload.doctor._id;
    }
    if (payload.therapist) {
      mappedData.therapist = payload.therapist._id;
    }
    // console.log('payload ', payload);
    // console.log('mappedData ', mappedData);
    commit('setLoading', true, { root: true });
    return pharmacyService.update(mappedData._id, mappedData).then((response) => {
      if (response.success) {
        // commit(SET_MEDSERVICE, response.data);
        const item = response.data;
        commit(UPDATE_MED_DISPENSE, {
          _id: item._id,
          invoice: item.invoice,
          clinic: item.clinic,
          patient: item.patient,
          service: item.medservice,
          doctor: item.doctor,
          therapist: item.therapist,
          visitDate: item.visitDate,
          items: item.items.map((med, idx) => ({
            medicine: {
              _id: med.medicine._id,
              name: med.medicine.name,
              unit: med.medicine.unit,
              thumbnailURL: med.medicine.thumbnailURL,
              imageURL: med.medicine.imageURL,
              inventory: med.medicine.inventory,
              strength: med.medicine.strength,
            },
            dosage: payload.items[idx].dosage,
            precaution1: payload.items[idx].precaution1,
            precaution2: payload.items[idx].precaution2,
            batch: med.batch,
            quantity: med.quantity,
            status: med.status,
            statusDate: med.statusDate,
            totalDiscountedPrice: med.totalDiscountedPrice,
            unitPrice: med.unitPrice,
            subInventory: med.subInventory || [],
            adHocCompounding: med.adHocCompounding,
            adHocCompoundName: med.adHocCompoundName,
            adHocCompoundUnit: med.adHocCompoundUnit,
            adHocCompoundDosage: med.adHocCompoundDosage,
            adHocCompoundPrecaution1: med.adHocCompoundPrecaution1,
            adHocCompoundPrecaution2: med.adHocCompoundPrecaution2,
          })),
        });
        // TODO: chan : revisit this when REALTIME notif count with socket.io is implemented
        if ((moment(item.visitDate).isSame(moment(), 'day'))) {
          dispatch('getTodaysToDo', { clinic: item.clinic });
          dispatch('getTodaysToDoIPD', { clinic: item.clinic });
        }
      }
      commit('setLoading', false, { root: true });
      return response;
    });
  },
  setItemForPrint({ commit }, payload) {
    commit(SET_ITEM_FOR_PRINT, payload);
  },
  getTodaysToDo({ commit }, { clinic }) {
    commit('setLoading', true, { root: true });
    return pharmacyService.getToDo({
      clinic,
      fromDate: new Date(),
      toDate: new Date(),
    }).then((response) => {
      commit(SET_TODAYS_TODO_COUNT, response.data);
      return response.data;
    });
  },
  setInvoiceForPrint({ commit }, payload) {
    commit(SET_INVOICE_FOR_PRINT, payload);
  },
  getTodaysToDoIPD({ commit }, { clinic }) {
    commit('setLoading', true, { root: true });
    const fromDate = new Date();
    return dischargeService.getToDo({
      clinic,
      fromDate: new Date(),
      toDate: fromDate.setDate(fromDate.getDate() + 1),
    }).then((response) => {
      commit(SET_TODAYS_TODO_COUNT_IPD, response.data);
      return response.data;
    });
  },
};

const getters = {
  all: _state => _state.all,
  rawPharmacyItems: _state => _state.rawPharmacyItems,
  filtered: _state => ({
    service,
    provider,
    status,
    search,
  }) => (_state.all
    .filter(item => (service ? (item.service && item.service._id === service) : true))
    .filter(item => (provider
      ? (item.doctor && item.doctor._id === provider)
            || (item.therapist && item.therapist._id === provider)
      : true))
    .filter(item => (status
      ? item.items.some(med => med.status === status)
      : true))
    .filter((item) => {
      const searchRegExp = new RegExp(search, 'i');
      return search
        ? item.patient.name.match(searchRegExp)
                || item.patient.nric.match(searchRegExp)
                || (item.service && item.service.name.match(searchRegExp))
                || (item.doctor && item.doctor.name.match(searchRegExp))
                || (item.therapist && item.therapist.name.match(searchRegExp))
                || (item.items.some(med => med.medicine.name.match(searchRegExp)))
        : true;
    })
  ),
  selectedItemForPrint: _state => _state.selectedItemForPrint,
  getMedItemToPrint: _state => (index) => {
    const output = _state.selectedItemForPrint.items && _state
      .selectedItemForPrint.items[index];
    return output;
  },
  print: _state => _state.print,
  todaysTaskCount: _state => _state.todaysTodoCount,
  todaysTaskCountIPD: _state => _state.todaysTodoCountIPD,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
