<template>
  <div class="container mb-5"
    :class="{'previewPrint': preview}">
    <div class="row mb-3">
      <div class="col-12">
        <img v-if="patientConfig.printPackageHeader.clinicLogo"
          :style="{ marginLeft:`${logoMarginLeft}px`, width:`${logoWidth}px` }"
          :src="generalConfig.logoURL">
        <h5>{{ generalConfig.clinicName }}</h5>
        <p
          class="mb-1 address-font"
          style="white-space: pre-line;">
          {{ generalConfig.address }}
        </p>
        <!-- <p class="mb-3 address-font" v-if="generalConfig.telephone">Tel: {{ generalConfig.telephone }}</p> -->
        <h3 class="mb-3 text-center text-uppercase">
          <u>
            {{ $t('general.packageTransferAcknowledgement') }}
          </u>
        </h3>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-7 weight-600">
        <div class="row mb-2">
          <div class="col-4">{{ $t('general.packageName') }}</div>
          <div class="col-8"> - {{ selectedPackage.packageName }}</div>
        </div>
        <div class="row mb-2">
          <div class="col-4">{{ $t('general.itemName') }}</div>
          <div class="col-8"> - {{ selectedPackage.itemName }}</div>
        </div>
        <div class="row mb-2">
          <div class="col-4">{{ $t('general.transferFrom') }}</div>
          <div class="col-8"> - {{ selectedPackage.transferFrom }}</div>
        </div>
        <div class="row mb-2">
          <div class="col-4">{{ $t('general.transferTo') }}</div>
          <div class="col-8"> - {{ selectedPackage.transferTo }}</div>
        </div>
        <div class="row mb-2">
          <div class="col-4">{{ $t('general.date') }}</div>
          <div class="col-8"> - {{ selectedPackage.dateTime | formatDayTime }}</div>
        </div>
      </div>
      <div class="col-5">
      </div>
    </div>

    <!-- table body for customized message -->
    <p class="mb-5" v-if="patientConfig.printPackageHeader.customContent" style="white-space: pre-line;">
      {{ patientConfig.printPackageHeader.customContent }}
    </p>

    <br>
    <div class="row mb-5">
      <div class="col-8"></div>
      <div class="col-4">
        <span>_____________________</span><br>
        <span v-if="preview">[{{ $t('general.transferFrom') }} {{ $t('general.patientName') }}]</span>
        <span v-else>{{ selectedPackage.transferFrom }}</span>
      </div>
    </div>
    <!-- <p v-if="printConfig.footer" style="white-space: pre-line;">
      {{ printConfig.footerContent }}
    </p> -->
  </div>
</template>
<script>
import moment from 'moment';

export default {
  name: 'PackageTransferReceiptPrint',
  // mixins: [dateFormatter, moneyFormatter],
  props: {
    // selectedPatient: {
    //   type: Object,
    //   default: () => ({ corpInsurance: [] }),
    // },
    generalConfig: {
      type: Object,
      default: () => ({}),
    },
    patientConfig: {
      type: Object,
      default: () => ({}),
    },
    selectedPackage: {
      type: Object,
      default: () => ({}),
    },
    // printConfig: [Array, Object],
    preview: {
      type: Boolean,
      default: false,
    },
    // includeGST: [Boolean, Object],
    // roundOff: [String, Object],
    // isCategorized: {
    //   type: Boolean,
    // },
    // redeem: {
    //   type: Object,
    //   default: () => ({}),
    // },
    // processFees: { type: Number, default: 0 },
    // multipleICD10Enabled: { type: Boolean, default: false },
  },
  data() {
    return {
      // sortedInventoryByType: { // for categorized invoice printing
      //   Consultation: [],
      //   Laboratory: [],
      //   Radiology: [],
      //   Investigation: [],
      //   Medicine: [],
      //   Compounded: [],
      //   Product: [],
      //   Vaccination: [],
      //   Injection: [],
      //   Procedure: [],
      //   'Nursing Procedure': [],
      //   Consumables: [],
      //   Package: [],
      //   Referral: [],
      //   Credit: [],
      //   Misc: [],
      // },
    };
  },
  computed: {
    splitAddress() {
      const address = this.selectedPatient.address.split(',');
      if (this.selectedPatient.unit) {
        address.splice(-1, 0, this.selectedPatient.unit);
      }
      return address;
    },
    logoMarginLeft() {
      if (localStorage.getItem('printSetup')) {
        return JSON.parse(localStorage.getItem('printSetup')).logoMarginLeft;
      }
      return 0;
    },
    logoWidth() {
      if (localStorage.getItem('printSetup')) {
        return JSON.parse(localStorage.getItem('printSetup')).logoWidth;
      }
      return 300;
    },
  },
  filters: {
    formatDayTime(value) {
      return moment(value * 1000).format('dddd, MMMM Do YYYY');
    },
  },
  mounted() {
    if (!this.preview) {
      setTimeout(() => {
        window.print();
      }, 2000);
    }
    console.log('printpreview - patientConfig ', this.patientConfig);
    console.log('printpreview - selectedPackage ', this.selectedPackage);
  },
};
</script>

<style scoped src="../../assets/print.css"></style>
<style>
  .clinicLogo{
    width: setting.medicalLabel
  }

  .previewPrint {
    background-color: white;
    font-size: 0.1em !important;
    padding: 20px;
    padding-left: 35px;
    min-height: 60em;
    margin-bottom: 0px !important;
  }
</style>
