var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isDataReady && _vm.selectedItemForPrint && _vm.med
    ? _c(
        "span",
        [
          _vm.featureFlags.bgrimm
            ? _c("drug-label-component", {
                attrs: {
                  printLocal: _vm.printLocal,
                  disableAutoPrint: false,
                  medItem: _vm.print,
                  generalConfig: _vm.general,
                  patient: _vm.print.selectedPatient
                }
              })
            : _vm.featureFlags.drclinique
            ? [
                _c(
                  "div",
                  { staticClass: "container-fluid" },
                  [
                    _c("medical-label-dr-clinique", {
                      attrs: {
                        clinicName: _vm.general.clinicName,
                        clinicAddress: _vm.general.address,
                        medName: _vm.med.medicine.name,
                        medStrength: _vm.med.medicine.strength,
                        medQty: _vm.med.quantity,
                        medUnit: _vm.med.medicine.unit,
                        medDosage: _vm.med.dosage,
                        medExpiry:
                          (_vm.med.batch && _vm.med.batch.expiry) || "",
                        medPrecautionOne: _vm.med.precaution1,
                        medPrecautionTwo: _vm.med.precaution2,
                        patientName: _vm.selectedItemForPrint.patient.name,
                        patientNric: _vm.selectedItemForPrint.patient.nric
                      }
                    })
                  ],
                  1
                )
              ]
            : [
                _c(
                  "div",
                  { staticClass: "container-fluid" },
                  [
                    !_vm.med.adHocCompounding
                      ? _c("medical-label-component", {
                          attrs: {
                            clinicName: _vm.general.clinicName,
                            clinicAddress: _vm.general.address,
                            medName: _vm.med.medicine.name,
                            medStrength: _vm.med.medicine.strength,
                            medQty: _vm.med.quantity,
                            medUnit: _vm.med.medicine.unit,
                            medDosage: _vm.med.dosage,
                            medExpiry:
                              (_vm.med.batch && _vm.med.batch.expiry) || "",
                            selectedBatch: _vm.med.batch,
                            medPrecautionOne: _vm.med.precaution1,
                            medPrecautionTwo: _vm.med.precaution2,
                            patientName: _vm.selectedItemForPrint.patient.name,
                            patientNric: _vm.selectedItemForPrint.patient.nric,
                            patientDetails: _vm.selectedItemForPrint.patient
                          }
                        })
                      : _c("medical-label-component", {
                          attrs: {
                            clinicName: _vm.general.clinicName,
                            clinicAddress: _vm.general.address,
                            medName: _vm.med.adHocCompoundName,
                            medStrength: _vm.med.medicine.strength,
                            medQty: _vm.med.quantity,
                            medUnit: _vm.med.adHocCompoundUnit,
                            medDosage: _vm.med.adHocCompoundDosage,
                            medExpiry: "",
                            selectedBatch: _vm.med.batch,
                            medPrecautionOne: _vm.med.adHocCompoundPrecaution1,
                            medPrecautionTwo: _vm.med.adHocCompoundPrecaution2,
                            patientName: _vm.selectedItemForPrint.patient.name,
                            patientNric: _vm.selectedItemForPrint.patient.nric,
                            patientDetails: _vm.selectedItemForPrint.patient
                          }
                        })
                  ],
                  1
                )
              ]
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }