/* eslint-disable no-restricted-globals */
<template>
  <div id="app" @dragover="videoPanelAllowDrop" @drop="videoPanelDrop">
    <div class="nonMobileView">
      <div id="nav" v-if="showMainNav && !isWithoutLogin">
        <MainNav ref="mainNav"/>
      </div>
      <notes-overlay v-if="!isWithoutLogin && patient"></notes-overlay>
      <main id="main" class="container-fluid"
        :class="{ 'no-nav': !showMainNav, 'need-update': needsAppUpdate }">
        <div class="text-danger d-flex justify-content-center align-items-center" v-if="isOffline">
          <IconError />
          <div class="m-2 font-weight-bold">{{ $t('general.offlinePrompt') }}</div>
          <IconError />
        </div>
        <transition name="fade" mode="out-in">
          <b-alert
            v-if="alert.message"
            show
            :variant="alert.type"
            dismissible>
            {{alert.message}}
          </b-alert>
        </transition>
        <transition name="fade" mode="out-in">
          <router-view/>
        </transition>
      </main>
      <loading v-if="isLoading" />

      <div v-if="!isWithoutLogin && needsAppUpdate" class="new-version-alert">
        <div>
          <v-icon name="exclamation-circle" scale="1.6"></v-icon>
          <span class="version-statement">
            {{ $t('general.versionPrompt') }} ({{ latestAppVersion }})
          </span>
          <span>{{ $t('general.versionInstruction') }}</span>
        </div>
        <div>
          <span class="text-muted">{{ $t('general.versionNote') }}</span>
          <b-button variant="primary" @click="appUpdate">{{ $t('general.update') }}</b-button>
        </div>
      </div>
    </div>
    <div :class="{'video-float': teleConsultRoom.floatWindow,
      'video-overlay': !teleConsultRoom.floatWindow}"
      id="video-panel"
      class="teleconference-container"
      :draggable="teleConsultRoom.floatWindow"
      @dragstart="videoPanelDragStart"
      v-show="teleConsultRoom.hasStarted && teleConsultRoom.fullScreen"
      v-if="!isWithoutLogin">
      <div class="video-window-controls" v-if="enableFullScreen">
        <div class="window-panel">
          <button class="btn btn-default btn-sm text-light float-right"
            v-if="!teleConsultRoom.floatWindow"
            @click="teleConsultRoom.floatWindow = true">
            <v-icon name="compress" scale="1.5"/>
          </button>
          <button class="btn btn-default btn-sm text-light float-right"
            v-else
            @click="teleConsultRoom.floatWindow = false">
            <v-icon name="expand" scale="1.5"/>
          </button>
          <button class="btn btn-default btn-sm text-light float-right"
            @click="teleConsultRoom.floatWindow = false; teleConsultRoom.fullScreen = false">
            <v-icon name="window-minimize" scale="1.5"/>
          </button>
        </div>
      </div>
      <div class="row remote-video-container">
        <div class="col">
          <div id="remote-video-track"
            :class="{'remote-video-full-screen-adjust': teleConsultRoom.floatWindow}">
            <!-- <video>
              <source src="/cnn.mp4"
                      type="video/mp4">
              Sorry, your browser doesn't support embedded videos.
            </video> -->
          </div>
        </div>
      </div>
      <div class="local-video-container" id="local-video-track"
        v-show="!teleConsultRoom.floatWindow">
      </div>
      <div class="video-control-container"
        :class="{'video-control-container-full-screen-adjust': teleConsultRoom.floatWindow}">
        <!-- <span class="vdeo-control video-off">
          <v-icon name="video" scale="1.5"/>
        </span>
        <span class="vdeo-control mute">
          <v-icon name="microphone" scale="1.5"/>
        </span> -->
        <span class="vdeo-control hangup"
          @click="leaveRoomIfJoined">
          <v-icon name="phone" scale="1.5"/>
        </span>
      </div>
    </div>
    <div class="video-minimize text-right"
      v-show="teleConsultRoom.hasStarted && !teleConsultRoom.fullScreen"
         v-if="!isWithoutLogin">
      <!-- <span class="mr-3 text-muted">
        <v-icon name="video" scale="1.5"/>
      </span>
      <span class="mr-3 text-muted">
        <v-icon name="microphone" scale="1.5"/>
      </span> -->
      <span class="mr-3" style="color:  rgb(204, 16, 16);">
        <v-icon name="phone" scale="1.5"/>
      </span>
      <button class="btn btn-default btn-sm text-light"
        @click="teleConsultRoom.fullScreen = true; teleConsultRoom.floatWindow = true">
        <v-icon name="compress" scale="1.5"/>
      </button>
      <button class="btn btn-default btn-sm text-light"
        @click="teleConsultRoom.fullScreen = true; teleConsultRoom.floatWindow = false">
        <v-icon name="expand" scale="1.5"/>
      </button>
    </div>
    <script  v-if="showMainNav" type="application/javascript" id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=62e817cf-9971-4673-a62c-32922dc19307"> </script>
  </div>
</template>

<script>
import _ from 'lodash';

import Video, { createLocalVideoTrack } from 'twilio-video';
import { v4 } from 'uuid';
import moment from 'moment';
import MainNav from '@/components/MainNav.vue';
import socketEvents from '@/components/mixins/socketevents';
import IconError from '@/components/Icons/Error.vue';
import NotesOverlay from '@/components/NotesOverlay.vue';
import teleConsultService from '@/services/teleconsult-room.service';
import { bus } from '@/main.js';
import { live as live_versions, staging as staging_versions } from '../package.json';
const client_version = process.env.NODE_ENV === 'production' ? live_versions.client_version : staging_versions.client_version;

export default {
  name: 'App',
  mixins: [socketEvents],
  data() {
    return {
      room: localStorage.getItem('orgCode'),
      teleConsultRoom: {
        hostProvider: null,
        roomName: '',
        token: '',
        patient: null,
        hasStarted: false,
        fullScreen: false,
        floatWindow: false,
        muted: false,
        cameraOff: false,
        localTrack: false,
        activeRoom: null,
        localMedia: null,
        participants: [],
      },
      enableFullScreen: true,
      appVersion: client_version,
    };
  },
  components: {
    MainNav,
    IconError,
    NotesOverlay,
  },
   beforeMount() {
    this.$store.commit('setLoading', false);
  },
  computed: {
    isWithoutLogin() {
      return this.$route.matched[0] ?
        this.$route.matched[0].props.default.withoutLogin ? this.$route.matched[0].props.default.withoutLogin : false : true;
    },
    latestAppVersion() {
      return this.$store.state.latestAppVersion;
    },
    patient() {
      return this.$route.params.id;
    },
    showMainNav() {
      return this.$route.matched.length === 0
        || this.$route.matched[0].props.default.navigation;
    },
    verCheckerEnabled() {
      return this.$store.state.verCheckerEnabled;
    },
    needsAppUpdate() {
      return this.verCheckerEnabled && this.$store.state.needsAppUpdate && !(this.$route.path.startsWith('/print'));
    },
    alert() {
      return this.$store.state.alert;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    isLoading() {
      return this.$store.state.loading;
    },
    selectedClinic() {
      return this.$store.state.clinic.selectedClinic;
    },
    teleConsultSession() {
      return this.$store.state.teleConsult.teleConsultSession;
    },
  },
  methods: {
    onOffCamera() {},
    muteUnmuteAudio() {},
    trackPublished(publication, participant) {
      // If the TrackPublication is already subscribed to, then attach the Track to the DOM.
      if (publication.track) {
        this.attachTrack(publication.track, participant);
      }

      // Once the TrackPublication is subscribed to, attach the Track to the DOM.
      publication.on('subscribed', (track) => {
        this.attachTrack(track, participant);
      });

      // Once the TrackPublication is unsubscribed from, detach the Track from the DOM.
      publication.on('unsubscribed', (track) => {
        this.detachTrack(track, participant);
        this.roomParticipants = [];
      });
    },
    attachTrack(track) {
      const mediaContainer = document.getElementById('remote-video-track');
      const participantVideo = track.attach();
      participantVideo.style.transform = 'scale(-1, 1)';
      mediaContainer.append(participantVideo);
    },
    detachTrack(track) {
      track.detach().forEach((detachedElement) => {
        detachedElement.remove();
      });
    },
    leaveRoomIfJoined() {
      console.log('Leaving room ....', !!this.teleConsultRoom.activeRoom);
      if (this.teleConsultRoom.activeRoom) {
        this.teleConsultRoom.activeRoom.disconnect();
        this.teleConsultRoom.localMedia.stop();
        const attachedElements = this.teleConsultRoom.localMedia
          .detach();
        attachedElements.forEach(element => element.remove());
        this.teleConsultRoom.fullScreen = false;
        this.teleConsultRoom.floatWindow = false;
        this.teleConsultRoom.localTrack = false;
        this.teleConsultRoom.hasStarted = false;
        bus.$emit('LEAVE_TELECONSULT_ROOM');
      }
    },
    detachTracks(tracks) {
      tracks.forEach((track) => {
        track.detach().forEach((detachedElement) => {
          detachedElement.remove();
        });
      });
    },
    detachParticipantTracks(participant) {
      const tracks = Array.from(participant.tracks.values());
      this.detachTracks(tracks);
    },
    startTeleConsult(params) {
      teleConsultService.getTwilioToken({ identity: params.provider, db: params.orgcode })
        .then((response) => {
          this.teleConsultRoom.hasStarted = true;
          this.teleConsultRoom.fullScreen = true;
          this.teleConsultRoom.token = response.data.token;
          const connectOptions = {
            name: this.teleConsultRoom.roomName,
            audio: true,
            video: true,
          };
          return Video.connect(this.teleConsultRoom.token, connectOptions);
        })
        .then((room) => {
          this.teleConsultRoom.activeRoom = room;
          this.teleConsultRoom.participants = room.participants
            ? Array.from(room.participants.values())
            : [];
          console.log('Connected to the room. No of participants ', this.teleConsultRoom.participants.length);
          // TODO: to update consult start time
          if (!this.teleConsultSession.startConsultTime) {
            const payload = { ...this.teleConsultSession };
            payload.startConsultTime = moment().unix();
            payload.appointment = payload.appointment._id;
            // it's ok not to by async
            this.$store.dispatch('teleConsult/updateTeleConsultSession', { payload, orgcode: params.orgcode });
          }
          this.teleConsultRoom.participants.forEach((participant) => {
            this.participantConnected(participant, room);
          });
          room.on('participantConnected', (participant) => {
            // eslint-disable-next-line no-console
            console.log(`POC1: Participant "${participant.identity}" connected`);
            this.teleConsultRoom.participants.push(participant);

            // Handle the TrackPublications already published by the Participant.
            participant.tracks.forEach((publication) => {
              this.trackPublished(publication, participant);
            });

            // Handle theTrackPublications that will be published by the Participant later.
            participant.on('trackPublished', (publication) => {
              this.trackPublished(publication, participant);
            });
          });
          room.on('disconnected', (detachedRoom) => {
          // Detach the local media elements
            console.log('Participant left the room');
            detachedRoom.localParticipant.tracks.forEach((publication) => {
              detachedRoom.localParticipant.unpublishTrack(publication.track);
              const attachedElements = publication.track.detach();
              console.log(`${publication.track.kind} detached`);
              console.log('Attached media ', attachedElements.length);
              console.log(`unsubscribed from: ${publication.track}`);
              attachedElements.forEach(element => element.remove());
            });
          });
          if (!this.teleConsultRoom.localTrack) {
            createLocalVideoTrack()
              .then((track) => {
                this.teleConsultRoom.localMedia = track;
                const localVideo = track.attach();
                console.log('Local Video ', localVideo);
                const localMediaContainer = document.getElementById('local-video-track');
                // const localMediaContainer = document.getElementById('remote-video-track');
                localMediaContainer.appendChild(localVideo);
                localVideo.style.transform = 'scale(-1, 1)';
                this.teleConsultRoom.localTrack = true;
              });
          }
        });
    },
    participantConnected(participant) {
      // Handle the TrackPublications already published by the Participant.
      participant.tracks.forEach((publication) => {
        this.trackPublished(publication, participant);
      });

      // Handle theTrackPublications that will be published by the Participant later.
      participant.on('trackPublished', (publication) => {
        this.trackPublished(publication, participant);
      });
    },
    videoPanelDragStart(event) {
      console.log('Dragging ', event.target.id);
      event.dataTransfer.setData('text/plain', event.target.id);
    },
    videoPanelAllowDrop(event) {
      event.preventDefault();
    },
    videoPanelDrop(event) {
      console.log('PANEL DROP:', event);
      const data = event.dataTransfer.getData('text/plain');
      console.log('Draggable Item ', data);
      try {
        const element = document.querySelector(`#${data}`);
        if (element) {
          event.preventDefault();
        }
        event.target.appendChild(element);
      } catch (error) {
        console.warn("you can't move the item to the same place");
      }
    },
    appUpdate() {
      window.location.reload(true);
    },
  },
  watch: {
    selectedClinic: {
      handler(newClinic) {
        if (newClinic) {
          window.localStorage.setItem('xclinic', newClinic._id);
        }
      },
      deep: true,
      immediate: true,
    },
    $route(to, from) {
      // clear alert on location change
      /* eslint-disable no-console */
      // console.info(`VDSOCKET**ROUTING from ${from.name} to ${to.name}`);
      if (!this.isWithoutLogin) {
        this.$store.dispatch('alert/clear');

        /* eslint-disable no-console */
        // console.info(`VDSOCKET**SOCKET.CONNECTED? ${this.$socket.connected}`);
        // console.info(`VDSOCKET**THIS.LOGGEDIN? ${this.loggedIn}`);

        this.$nextTick(() => {
          if (!this.$socket.connected && this.loggedIn) {
            // console.info(`VDSOCKET TRY .CONNECT ${!this.$socket.connected && this.loggedIn}`);
            this.$socket.open();
          }
        });
      }
    },
  },
  created() {
    bus.$on('TELECONSULT_START', (params) => {
      this.teleConsultRoom.roomName = params.room;
      this.teleConsultRoom.hostProvider = params.provider;
      this.teleConsultRoom.patient = params.patient;
      this.enableFullScreen = params.enableFullScreen;
      this.startTeleConsult(params);
    });
    bus.$on('END_TELECONSULT', () => {
      this.leaveRoomIfJoined();
    });
    window.addEventListener('beforeunload', () => {
      this.leaveRoomIfJoined();
    });
    if (!this.isWithoutLogin) {
      this.$store.dispatch('users/getAll', this.selectedClinic._id);
      this.$store.commit('setLoading', false);
      this.$store.dispatch('alert/clear');
      this.$nextTick(() => {
        if (!this.$socket.connected && this.loggedIn) {
          console.info(`VDSOCKET TRY .CONNECT ${!this.$socket.connected && this.loggedIn}`);
          this.$socket.open();
        }
        this.$socket.emit('JOIN_ROOM', { room: this.user.id, username: this.user.name });
      });
    }
  },
};
</script>

<style lang="scss">
#main {
  margin-bottom: 90px;
}
.template-flash {
  position: fixed;
  top: 100px;
  right: 10px;
  z-index: 99;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.6s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.my-handle-class {
  position: absolute;
  display: block !important;
  background-color: transparent;
  border: 0;
  height: 14px;
  width: 14px;
  -webkit-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear;
}

.my-handle-class-tl {
  top: -10px;
  left: -10px;
  height: 20px;
  width: 20px;
  z-index: 99;
  cursor: nw-resize;
}

.my-handle-class-tm {
  top: -10px;
  width: 100%;
  cursor: n-resize;
}

.my-handle-class-tr {
  top: -10px;
  right: -10px;
  height: 20px;
  width: 20px;
  z-index: 99;
  cursor: ne-resize;
}

.my-handle-class-ml {
  height: 100%;
  left: -10px;
  cursor: w-resize;
}

.my-handle-class-mr {
  height: 100%;
  right: -10px;
  cursor: e-resize;
}

.my-handle-class-bl {
  bottom: -10px;
  left: -10px;
  height: 20px;
  width: 20px;
  z-index: 99;
  cursor: sw-resize;
}

.my-handle-class-bm {
  bottom: -10px;
  width: 100%;
  cursor: s-resize;
}

.my-handle-class-br {
  bottom: -10px;
  right: -10px;
  height: 20px;
  width: 20px;
  z-index: 99;
  cursor: se-resize;
}

.cursor-pointer {
  cursor: pointer;
}
.cursor-grab {
  cursor: grab;
}
.range-tool-tip {
  z-index: 2068 !important; /** important to have same z-index at bg-overlay at NotesOverlay.vue */
}
.locked-typeahead {
  pointer-events: none;
}
.video-overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  top:0;
  right: 0;
  background-color: #485058!important;
  pointer-events: all;
  z-index: 2000;
}
.video-float {
  height: auto;
  width: 350px;
  position: fixed;
  top: 8%;
  right: 5px;
  background-color: rgb(67, 76, 85)!important;
  pointer-events: all;
  z-index: 2000;
  box-shadow: 0px 0px 2px 2px #666;
}
.teleconference-container .video-window-controls {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: auto;
  z-index: 2001;
}
// .teleconference-container .video-window-controls:hover {
//   background-color: rgb(67, 76, 85)!important;
//   opacity: 0.2;
// }
.video-minimize {
  position: fixed;
  bottom: 0;
  right: 50%;
  background-color: #485058!important;
  height: auto;
  width: 400px;
  padding: 5px 4px;
}
.remote-video-container #remote-video-track {
  width: 65%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  position: relative;
  top: 20px;
}

.remote-video-full-screen-adjust {
  top: 5px !important;
}

.remote-video-container #remote-video-track video {
  width: 100%;
  height: auto;
  object-fit: contain;
  /* Make video to at least 100% wide and tall */
  // min-width: 100%;
  // min-height: 100%;

  // /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  // width: auto;
  // height: auto;

  // /* Center the video */
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%,-50%);
}

.local-video-container {
  height: auto;
  width: 300px;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgb(67, 76, 85)!important;
  pointer-events: all;
  z-index: 2001;
  box-shadow: 0px 0px 2px 2px #666;
}

.local-video-container video {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.video-control-container {
  width: 200px;
  height: auto;
  position: absolute;
  left: 53%;
  bottom: 15%;
  margin-left: -100px;
  z-index: 2002;
}
.video-control-container-full-screen-adjust {
  left: 73% !important;
}
.video-control-container .vdeo-control {
  color: #fff;
  background-color: rgb(173, 171, 171);
  opacity: 0.8;
  padding: 5.5% 7% 5.5% 7%;
}

.video-control-container .vdeo-control:hover {
  cursor: pointer;
}

.video-control-container .vdeo-control.hangup {
  color: rgb(204, 16, 16);
}

.new-version-alert {
  background: #fde4cc;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  align-content: center;
  border-top: 1px solid lightgray;
  z-index: 99999;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;

  & > div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;

    & > .version-statement {
      font-weight: bolder;
      display: inline-block;
      margin-left: 15px;
      /* display: inline-flex;
      align-items: center;
      margin-right: 10px;
      align-content: center; */
    }

    & > span:last-child {
      font-weight: bold;
      display: inline-block;
      margin-left: 20px;
      /* display: inline-flex;
      align-items: center;
      align-content: center; */
    }
  }

  & > :last-child {
    span {
      font-weight: bold;
      font-style: italic;
    }

    button {
      margin-left: 10px;
    }
  }
}

.slide-enter-active {
   -moz-transition-duration: 0.6s;
   -webkit-transition-duration: 0.6s;
   -o-transition-duration: 0.6s;
   transition-duration: 0.6s;
   -moz-transition-timing-function: ease-in;
   -webkit-transition-timing-function: ease-in;
   -o-transition-timing-function: ease-in;
   transition-timing-function: ease-in;
}

.slide-leave-active {
   -moz-transition-duration: 0.6s;
   -webkit-transition-duration: 0.6s;
   -o-transition-duration: 0.6s;
   transition-duration: 0.6s;
   -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
   max-height: 100px;
   overflow: hidden;
}

.slide-enter, .slide-leave-to {
   overflow: hidden;
   max-height: 0;
}
.save-icon-wrapper {
    position: relative;
    .unsaved-edits-badge {
        background: #f00707;
        margin: 0;
        border-radius: 50%;
        position: absolute;
        line-height: 1.1em;
        height: 1.1em;
        width: 1.1em;
        top: -0.6em;
        right: -0.6em;
    }
}
</style>
