<template>
  <div class="container-fluid">
    <div class="row mt-5">
      <div class="col-12">
        <img v-if="general.logoURL"
          :style="{ marginLeft:`${logoMarginLeft}px`, width:`${logoWidth}px` }"
          :src="general.logoURL">
        <h5 class="text-center">{{ general.clinicName }}</h5>
      </div>
    </div>
    <div class="row my-4">
      <div class="col-4">
        <strong>SHIP TO:</strong>
        <p>{{ selectedItemForPrint.patient.name }}</p>
        <div v-for="address in splitAddress" :key="address.id">
          {{ address }}
        </div>
        <p class="mt-3"><small>{{ new Date() | ddMonthYYYYFromX }}</small></p>
      </div>
      <div class="col-4">
        <strong>BILL TO:</strong>
        <p>{{ selectedItemForPrint.patient.name }}</p>
        <div v-for="address in splitAddress" :key="address.id">
          {{ address }}
        </div>
      </div>
    </div>
    <hr>
    <template v-if="selectedItemForPrint.items">
      <div class="row weight-600 bd-top bd-bottom">
        <div class="col-3">PRODUCT</div>
        <div class="col-3">UNIT PRICE</div>
        <div class="col-3">QUANTITY</div>
        <div class="col-3">TOTAL PRICE</div>
      </div>
      <packing-list-component
        :medLineItems="selectedItemForPrint.items"
        :regionData="general.region"/>
    </template>
    <hr>
    <div class="row mt-3">
      <div class="col-9 text-right">Subtotal:</div>
      <div class="col-3 text-left">
        {{ general.region.currency }} {{ computeSubtotal | to2Decimal | thousandSeparator }}
      </div>
    </div>
    <div class="row">
      <div class="col-9 text-right">Total Discount:</div>
      <div class="col-3 text-left">
        -{{ general.region.currency }} {{ computeTotalDiscount | to2Decimal | thousandSeparator }}
      </div>
    </div>
    <div class="row">
      <div class="col-9 text-right">Total:</div>
      <div class="col-3 text-left">
        {{ general.region.currency }} {{ computeTotal | to2Decimal | thousandSeparator }}
      </div>
    </div>
  </div>
</template>

<script>
import PackingListComponent from '@/components/Print/PackingListComponent.vue';
import moneyFormatter from '@/components/mixins/money';
import dateFormatter from '@/components/mixins/dateformatter';
import { mapGetters } from 'vuex';

export default {
  name: 'PackingList',
  mixins: [dateFormatter, moneyFormatter],
  computed: {
    ...mapGetters('pharmacy', ['selectedItemForPrint']),
    ...mapGetters('config', ['general']),
    splitAddress() {
      const address = this.selectedItemForPrint.patient.address.split(',');
      if (this.selectedItemForPrint.patient.unit) {
        address.splice(-1, 0, this.selectedItemForPrint.patient.unit);
      }
      return address;
    },
    computeSubtotal() {
      let subTotal = 0;
      const lineItems = this.selectedItemForPrint.items;
      for (let i = 0; i < lineItems.length; i += 1) {
        subTotal += lineItems[i].unitPrice * lineItems[i].quantity;
      }
      return subTotal;
    },
    computeTotal() {
      const total = this.selectedItemForPrint.items.reduce((a, b) => ({ totalDiscountedPrice: a.totalDiscountedPrice + b.totalDiscountedPrice }));
      return total.totalDiscountedPrice;
    },
    computeTotalDiscount() {
      return this.computeSubtotal - this.computeTotal;
    },
  },
  components: {
    PackingListComponent,
  },
  mounted() {
    setTimeout(() => {
      window.print();
    }, 2000);
  },
};
</script>


<style scoped src="../../assets/print.css"></style>
