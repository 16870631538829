import supplierService from '@/services/supplier.service';

import {
  SET_SUPPLIER,
} from './type.mutation';

const state = {
  selectedSupplier: null,
};

/* eslint-disable no-param-reassign */
const mutations = {
  [SET_SUPPLIER](_state, payload) {
    _state.selectedSupplier = payload ? { ...payload } : null;
  },
};

const actions = {
  setSelectedSupplier({ commit }, payload) {
    commit(SET_SUPPLIER, payload);
  },
  unsetSelectedSupplier({ commit }) {
    commit(SET_SUPPLIER, null);
  },
  createNewSupplier({ commit }, payload) {
    commit('setLoading', true, { root: true });
    return supplierService.create(payload)
      .then((response) => {
        if (response.success) {
          commit(SET_SUPPLIER, response.data);
        }
        commit('setLoading', false, { root: true });
        return response;
      });
  },
  updateSupplier({ commit }, payload) {
    commit('setLoading', true, { root: true });
    return supplierService.update(payload._id, payload)
      .then((response) => {
        if (response.success) {
          commit(SET_SUPPLIER, response.data);
        }
        commit('setLoading', false, { root: true });
        return response;
      });
  },
  destroySupplier({ commit }, payload) {
    commit('setLoading', true, { root: true });
    return supplierService.destroy(payload._id)
      .then((response) => {
        if (response.success) {
          commit(SET_SUPPLIER, null);
        }
        commit('setLoading', false, { root: true });
        return response;
      });
  },
};

const getters = {
  selectedSupplier: _state => _state.selectedSupplier,
  supplierName: _state => (supplierId) => {
    const supplier = _state.all.find(i => i._id === supplierId);
    return supplier ? supplier.name : undefined;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
