// import config from '../config';
import api from './api';


function getAll(params) {
  const q = [];
  Object.keys(params).forEach((key) => {
    q.push(`${key}=${params[key]}`);
  });
  console.log('q is ', q);
  const url = `/integratedtest/?${q.join('&')}`;
  return api.get(url);
}

function getHL7MessageTemplate(params) {
  const { invoiceId, lineItemUuid, type } = params;
  let url = `/integratedtest/template?invoiceId=${invoiceId}&lineItemUuid=${lineItemUuid}&type=${type}`;
  return api.get(url);
}

function createIntegratedTest(data) {
  console.log('in createIntegratedTest data is ', data);
  return new Promise((resolve, reject) => {
    api.post('/integratedtest', data)
      .then(
        (response) => {
          console.log('1 response ', response);
          if (response.ok) {
            resolve(response);
          }
          else {
            reject(
              new Error(`${(response.data || {}).message}`),
              { data: null, success: false },
            );
          }
        },
        (error) => {
          console.log('1.5 error ', error);
          reject(
            new Error(`${(response.data || {}).message}`),
            { data: null, success: false },
          );
        }
      )
  })
}

function createIntegratedTestOfAdimission(data) {
  return new Promise((resolve, reject) => {
    api.post('/integratedtest/createOfAdimission', data)
      .then(
        (response) => {
          if (response.ok) {
            resolve(response);
          }
          else {
            reject(
              new Error(`${(response.data || {}).message}`),
              { data: null, success: false },
            );
          }
        },
        () => {
          reject(
            new Error(`${(response.data || {}).message}`),
            { data: null, success: false },
          );
        }
      )
  })
}

function sendHL7Message(params) {
  console.log('in sendHL7Message, params is ', params);
  return new Promise((resolve, reject) => {
    api.post('/integratedtest/sendHl7Message', params)
      .then(
        (response) => {
          console.log('hl7 1 response ', response);
          if (response.ok) {
            resolve(response);
          }
          else {
            reject(
              new Error(`${(response.data || {}).message}`),
              { data: null, success: false },
            );
          }
        },
        (error) => {
          console.log('hl7 2 error ', error);
          reject(
            new Error(`${(response.data || {}).message}`),
            { data: null, success: false },
          );
        }
      )
  })
}

function sendProlabHL7Message(params) {
  console.log('in sendProlabHL7Message, params is ', params);
  return new Promise((resolve, reject) => {
    api.post('/integratedtest/sendProlabHl7Message', params)
      .then(
        (response) => {
          console.log('hl7 1 response ', response);
          if (response.ok) {
            resolve(response);
          }
          else {
            reject(
              new Error(`${(response.data || {}).message}`),
              { data: null, success: false },
            );
          }
        },
        (error) => {
          console.log('hl7 2 error ', error);
          reject(
            new Error(`${(response.data || {}).message}`),
            { data: null, success: false },
          );
        }
      )
  })
}

function fetchIntegratedTestsByLineItemUUID(lineItemUuid) {
  return api.get(`/integratedtest/fetchByLineItemUuid/${lineItemUuid}`)
}

function cancelIntegratedTest(id, cancelledStatusId) {
  console.log('in cancelIntegratedTest id, cancelledStatusId is ', id, cancelledStatusId);
  return api.delete(`/integratedtest/${id}/${cancelledStatusId}`);
}

function cancelAdmissionIntegratedTest(params) {
  return new Promise((resolve, reject) => {
    api.post('/integratedtest/cancelStatus', params)
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
  })
}

function updateIntegratedTestStatus({id, ...params}) {
  return api.patch(`/integratedtest/status/${id}`, params);
}

function fetchIntegratedTestResults(integratedTestId) {
  return api.get(`/integratedtest/results/${integratedTestId}`);
}

function fetchIntegratedSimpleList(params) {
  const q = [];
  Object.keys(params).forEach((key) => {
    q.push(`${key}=${params[key]}`);
  });
  const url = `/integratedtest/smipleList?${q.join('&')}`;
  return api.get(url);
}
// function logout() {
//   // remove user from local storage to log user out
//   localStorage.removeItem('user');
//   localStorage.removeItem('access_token');
//   localStorage.removeItem('xsrf');
//   localStorage.removeItem('orgCode');
// }

// function handleResponse(response) {
//   return response.text().then((text) => {
//     const data = text && JSON.parse(text);
//     if (!response.ok) {
//       if (response.status === 401) {
//         // auto logout if 401 response returned from api
//         logout();
//         // eslint-disable-next-line
//         // location.reload(true);
//       }
//       const error = (data && data.message) || response.statusText;
//       return Promise.reject(error);
//     }
//     return data;
//   });
// }

// function login(orgcode, username, password) {
//   return api.post('/auth/login', { orgCode: orgcode, email: username, password })
//     .then(handleResponse)
//     .then((res) => {
//       const { orgCode, token, user, functions, nav } = res;
//       localStorage.setItem('orgCode', orgCode);
//       localStorage.setItem('user', JSON.stringify(user));
//       localStorage.setItem('access_token', token);
//       localStorage.setItem('xsrf', token);
//       localStorage.setItem('acl__nav', JSON.stringify(nav));
//       localStorage.setItem('acl__urls', JSON.stringify(functions.filter(func => func.url).map(func => func.url)));
//       let acl__childrenFnIds = {};
//       localStorage.setItem('acl__fnids', JSON.stringify(functions.map(func => {
//         if (func.parentFnId && func.parentFnId !== undefined && func.parentFnId !== '') {
//           acl__childrenFnIds[func.functionId] = 1;
//           return false;
//         } else {
//           return func.functionId;
//         }
//       })));
//       localStorage.setItem('acl__childrenFnIds', JSON.stringify(acl__childrenFnIds));
//       return res;
//     });
// }
// function validateOTP(otp) {
//   // eslint-disable-next-line
//   let userInfo = JSON.parse(localStorage.getItem('user'));
//   const email = userInfo.email;

//   return api.get(`/two-factor/${email}/${otp}`)
//     .then(handleResponse)
//     .then((res) => {
    
//       return res;
//     });
// }

// // function register(user) {
// //   const requestOptions = {
// //     method: 'POST',
// //     headers: { 'Content-Type': 'application/json' },
// //     body: JSON.stringify(user),
// //   };

// //   return fetch(`${config.API_BASE_URL}/users/register`, requestOptions).then(handleResponse);
// // }

// function getAll(params) {
//   // const t = `t=${new Date().getTime()}`;
//   const q = [];
//   Object.keys(params).forEach((key) => {
//     q.push(`${key}=${params[key]}`);
//   });
//   console.log('q is ', q);
//   const url = `/integratedtest/?${q.join('&')}`;
//   return api.get(url)
//     // .then(handleResponse)
//     .then(res => res.data);
// }

// function updateUser(data) {
//   return api.put(`/users/${data.id}`, data);
// }

// function createNew(data) {
//   return api.post('/users', data);
// }

// function changePassword(data) {
//   return api.put('/users/me/password', data);
// }

// function bulkDestroy(data) {
//   return api.post('/users/bulk', data);
// }

// function sendResetPasswordLink(data) {
//   return api.post('/password-resets', data);
// }

// function resetPassword(data) {
//   return api.put('/password-resets', data);
// }

// function getById(id) {
//   const requestOptions = {
//     method: 'GET',
//     headers: authHeader(),
//   };

//   return fetch(`${config.API_BASE_URL}/users/${id}`, requestOptions).then(handleResponse);
// }

// function update(user) {
//   const requestOptions = {
//     method: 'PUT',
//     headers: { ...authHeader(), 'Content-Type': 'application/json' },
//     body: JSON.stringify(user),
//   };

//   return fetch(`${config.API_BASE_URL}/users/${user.id}`, requestOptions).then(handleResponse);
// }

// // prefixed function name with underscore because delete is a reserved word in javascript
// function udelete(id) {
//   const requestOptions = {
//     method: 'DELETE',
//     headers: authHeader(),
//   };

//   return fetch(`${config.API_BASE_URL}/users/${id}`, requestOptions).then(handleResponse);
// }

export default {
  getAll,
  getHL7MessageTemplate,
  createIntegratedTest,
  createIntegratedTestOfAdimission,
  sendHL7Message,
  sendProlabHL7Message,
  cancelIntegratedTest,
  cancelAdmissionIntegratedTest,
  updateIntegratedTestStatus,
  fetchIntegratedTestResults,
  fetchIntegratedTestsByLineItemUUID,
  fetchIntegratedSimpleList
};
