import _ from 'lodash';

import api from '@/services/api';

export default {

  list(params) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);

    Object.keys(params).forEach((key) => {
      q.push(`${key}=${params[key]}`);
    });
    const url = `/ipd-invoice/list/?${q.join('&')}`;

    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`List Error: ${response}, url: ${url}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },

  fetch(admissionId) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);

    const url = `/ipd-invoice/${admissionId}/?${q.join('&')}`;
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`List Error: ${response}, url: ${url}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },

  addItemsToActiveSection(id, params) {
    const url = `/ipd-invoice/additems/${id}`;
    return new Promise((resolve, reject) => {
      api.put(url, params).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },

  romoveItemsFromSection(id, params) {
    const url = `/ipd-invoice/removeitems/${id}`;
    return new Promise((resolve, reject) => {
      api.put(url, params).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },

  getById(id) {
    const url = `/ipd-invoice/${id}`;

    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`Error: ${response.data.message}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },

  updateOneSection(id, { invoice, sectionId }) {
    const url = `/ipd-invoice/${id}/${sectionId}`;
    return new Promise((resolve, reject) => {
      api.put(url, invoice).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },

  update(id, payload) {
    const url = `/ipd-invoice/${id}`;

    return new Promise((resolve, reject) => {
      api.put(url, payload).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },
};
