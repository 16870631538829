var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "modal-header" }, [
      _c("span", [_vm._v(_vm._s(_vm.$t("general.uploadManager")))])
    ]),
    _c(
      "div",
      { staticClass: "default" },
      [
        _vm.filesMapped.length > 0
          ? _c(
              "b-table",
              {
                attrs: {
                  striped: "",
                  hover: "",
                  fields: _vm.fields,
                  items: _vm.filesMapped
                }
              },
              _vm._l(_vm.filesMapped, function(row, i) {
                return _c("div", { key: i }, [
                  _c("span", { staticClass: "fileName" }, [
                    _c(
                      "span",
                      [
                        _c("b-input", {
                          staticClass: "mb-2 mr-sm-2 mb-sm-0",
                          attrs: {
                            "data-automation": "upload-manager-file-name",
                            size: "sm",
                            value: row.value
                          },
                          on: {
                            input: function($event) {
                              return _vm.updateUploadLineItem(
                                $event,
                                row.item.uuid,
                                "name"
                              )
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("span", { staticClass: "category" }, [
                    _c(
                      "span",
                      [
                        _c("b-form-select", {
                          staticClass: "mb-2 mr-sm-2 mb-sm-0",
                          attrs: {
                            "data-automation": "upload-manager-category",
                            size: "sm",
                            value: row.value,
                            options: _vm.categoriesOptions
                          },
                          on: {
                            change: function($event) {
                              return _vm.updateUploadLineItem(
                                $event,
                                row.item.uuid,
                                "categoryId"
                              )
                            }
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              }),
              0
            )
          : _vm._e()
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-footer space-between" },
      [
        _c(
          "b-button",
          {
            attrs: {
              variant: "danger",
              "data-automation": "upload-manager-close"
            },
            on: { click: _vm.closeUploadManager }
          },
          [_vm._v("Close")]
        ),
        _c(
          "b-button",
          {
            attrs: {
              variant: "success",
              "data-automation": "upload-manager-upload",
              disabled: _vm.uploading
            },
            on: { click: _vm.uploadFiles }
          },
          [_vm._v("Upload")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }