import _ from 'lodash';

import api from './api';

export default {
  getReservationByInvoice(patient, params = {}) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);

    Object.keys(params).forEach((key) => {
      q.push(`${key}=${params[key]}`);
    });
    const url = `/membership/reservation/${patient}/invoice`;
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`Error: ${response.data.message}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },
  getReservationByProviderInvoice(provider, params = {}) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);

    Object.keys(params).forEach((key) => {
      q.push(`${key}=${params[key]}`);
    });
    const url = `/membership/reservation/${provider}/providerInvoice`;
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`Error: ${response.data.message}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },
  getMembershipTrx(patient, params = {}) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);

    Object.keys(params).forEach((key) => {
      q.push(`${key}=${params[key]}`);
    });
    const url = `/membership/transaction/${patient}`;
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`Error: ${response.data.message}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },
  getMembershipStats(patient, params = {}) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);

    Object.keys(params).forEach((key) => {
      q.push(`${key}=${params[key]}`);
    });
    const url = `/membership/transaction/stats/${patient}`;
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`Error: ${response.data.message}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },
  getMembershipProviderStats(provider, params = {}) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);

    Object.keys(params).forEach((key) => {
      q.push(`${key}=${params[key]}`);
    });
    const url = `/membership/transaction/statsProvider/${provider}`;
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`Error: ${response.data.message}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },
  getCurrentMembership(params) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);

    Object.keys(params).forEach((key) => {
      q.push(`${key}=${params[key]}`);
    });
    const url = `/membership/activate?${q.join('&')}`;
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`Error: ${response.data.message}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },
  activate(payload) {
    const url = '/membership/activate';

    return new Promise((resolve, reject) => {
      api.post(url, payload).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({
              data, message: '', success: true, error: null,
            });
          } else {
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },
  bulkActivate(payload) {
    const url = '/membership/activate/bulk';

    return new Promise((resolve, reject) => {
      api.post(url, payload).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({
              data, message: '', success: true, error: null,
            });
          } else {
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },
  adjustUp(payload) {
    const url = '/membership/transaction/adjust/up';

    return new Promise((resolve, reject) => {
      api.post(url, payload).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({
              data, message: '', success: true, error: null,
            });
          } else {
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },
  adjustDown(payload) {
    const url = '/membership/transaction/adjust/down';

    return new Promise((resolve, reject) => {
      api.post(url, payload).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({
              data, message: '', success: true, error: null,
            });
          } else {
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },
  deactivate(patient) {
    const url = `/membership/activate/deactivate/${patient}`;

    return new Promise((resolve, reject) => {
      api.post(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({
              data, message: '', success: true, error: null,
            });
          } else {
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },
  upgradeDowngrade(patient, currentTier, nextTier) {
    const url = `/membership/activate/updown/${patient}/${currentTier}/${nextTier}`;

    return new Promise((resolve, reject) => {
      api.post(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({
              data, message: '', success: true, error: null,
            });
          } else {
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },
  transferPoint(payload) {
    const url = '/membership/transaction/transfer';
    return new Promise((resolve, reject) => {
      api.post(url, payload).then(
        (response) => {
          // console.log('Res Point00>>', response)
          if (response.ok) {
            const data = response.body;
            resolve({
              data, message: '', success: true, error: null,
            });
          } else {
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },
};
