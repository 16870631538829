import _ from 'lodash';

import api from './api';

export default {
  async obtainPIIQrCode({ patientId, clinicCode }) {
    // NOTE: if we have authentication in the future we can implement it here.
    return {
      url: `${process.env.VUE_APP_apiUrl}/patient/${patientId}/public-pii-qrcode?clinicCode=${clinicCode}`,
    };
  },
  translateErrorMsg(error) {
    if (error === 'name is required') return 'Patient name is required';
    if (error === 'nric is required') return 'NRIC is required';
    if (error === 'drugAllergies is required') return 'Drug Allergy is required';
    if (typeof error === 'object' && error.errors.givenId
      && error.errors.givenId.kind === 'mongoose-unique-validator') return 'Patient ID must be unique';
    if (typeof error === 'object' && error.errors.nric
      && error.errors.nric.kind === 'mongoose-unique-validator') return 'NRIC/PASSPORT must be unique';
    return error;
  },
  async fetchAllRadiologByPatientId(patientId) {
    /*
     NOTE: example
         [{
           id: `data-key1${idx}`,
           name: 'image description#1',
           createdAt: '2021-01-13T09:16:31.122Z',
           location: '312321321',
           thumbnailUrl: 'https://dragonhub-v2-staging-others.s3.ap-southeast-1.amazonaws.com/democlinic/sample/sample.png',
           reportUrl: 'https://dragonhub-v2-staging-others.s3.ap-southeast-1.amazonaws.com/democlinic/sample/sample.pdf',
           dicomUrl: 'http://www.example.com/',
         }]
     */
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);
    const url = `/patient/${patientId}/scans/?${q.join('&')}`;
    const resp = await api.get(url);
    return _.chain(resp)
      .get('body', [])
      /*
      // NOTE: test mock live dicom link and location, if you need.
      .map(item => ({
        ...item,
        location: '1323',
        dicomUrl: 'https://zeddoc.diagnostiximaging.com/viewer/share?code=189c6d1b26bf7166a35848e49e79eed6852dd5e01e216c38c307a7f1002d659832e1d54c16061e99c7e225db3a450bad66ccf4cc48f23921816d8aa0289f63',
      }))
      */
      .orderBy(['createdAt'], ['desc'])
      .value();
  },
  fetchAll(params) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);
    Object.keys(params).forEach((key) => {
      q.push(`${key}=${params[key]}`);
    });

    const url = `/patient/?${q.join('&')}`;
    return api.get(url);
  },

  search(params) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);

    Object.keys(params).forEach((key) => {
      q.push(`${key}=${params[key]}`);
    });

    const url = `/patient/search?${q.join('&')}`;
    return api.get(url);
  },
  searchPostalCodeSG(params) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);

    Object.keys(params).forEach((key) => {
      q.push(`${key}=${params[key]}`);
    });

    const url = `/patient/postalCodeSg?${q.join('&')}`;
    return api.get(url);
  },
  searchPostalCode(params) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);

    Object.keys(params).forEach((key) => {
      q.push(`${key}=${params[key]}`);
    });

    const url = `/patient/postalcode/search?${q.join('&')}`;
    return api.get(url);
  },
  createNew(data) {
    if (data.doctor === '') {
      delete data.doctor;
    }
    return api.post('/patient', data);
  },
  createWithSignatures(id, signatures) {
    const images = {};
    if (signatures.bgrimmfinRepPatientSig) {
      images.bgrimmfinRepPatientSig = signatures.bgrimmfinRepPatientSig;
    }
    if (signatures.bgrimmfinRepWitness1Sig) {
      images.bgrimmfinRepWitness1Sig = signatures.bgrimmfinRepWitness1Sig;
    }
    if (signatures.bgrimmfinRepWitness2Sig) {
      images.bgrimmfinRepWitness2Sig = signatures.bgrimmfinRepWitness2Sig;
    }
    if (signatures.bgrimmInterpreterSig) {
      images.bgrimmInterpreterSig = signatures.bgrimmInterpreterSig;
    }
    if (signatures.bgrimmPatientRepSig) {
      images.bgrimmPatientRepSig = signatures.bgrimmPatientRepSig;
    }
    if (signatures.bgrimmHQPatientSig) {
      images.bgrimmHQPatientSig = signatures.bgrimmHQPatientSig;
    }
    if (signatures.bgrimmPatientSig) {
      images.bgrimmPatientSig = signatures.bgrimmPatientSig;
    }
    if (signatures.bgrimmAuthorisedPersonSig) {
      images.bgrimmAuthorisedPersonSig = signatures.bgrimmAuthorisedPersonSig;
    }
    if (signatures.thriveChildRegPatientSig) {
      images.thriveChildRegPatientSig = signatures.thriveChildRegPatientSig;
    }
    if (signatures.thriveAdultRegPatientSig) {
      images.thriveAdultRegPatientSig = signatures.thriveAdultRegPatientSig;
    }
    if (signatures.thriveAdultRegClientSig) {
      images.thriveAdultRegClientSig = signatures.thriveAdultRegClientSig;
    }
    if (signatures.thriveChildRegClientSig) {
      images.thriveChildRegClientSig = signatures.thriveChildRegClientSig;
    }
    return api.multiPartPost(
      `/patient/${id}/signatures`,
      {},
      images,
    );
  },
  validatePatinetMobile(data) {
    return api.post('/patient/conditionSearch', data);
  },
  updatePatient(id, data) {
    if (data.doctor === '') {
      delete data.doctor;
    }
    return api.put(`/patient/${id}`, data);
  },
  patchPatient(id, data) {
    return api.patch(`/patient/${id}`, data);
  },
  createVital(data) {
    return api.post('/vital', data);
  },
  getPatientVitals(patientId) {
    return api.get(`/vital/${patientId}`);
  },
  getTags() {
    return api.get('/patient/tags');
  },
  delete(id) {
    return api.delete(`/patient/${id}`);
  },
  bulkDelete(ids) {
    return api.post('/patient/bulk', ids);
  },
  count(query) {
    const params = Object.keys(query)
      .map(k => `${k}=${query[k]}`)
      .join('&');
    return api.get(`/patient/count?${params}`);
  },
  maxGivenId(query) {
    const params = Object.keys(query)
      .map(k => `${k}=${query[k]}`)
      .join('&');
    return api.get(`/patient/max_givenid?${params}`);
  },
  getSignature(key) {
    return api.get(`/patient/signature/${key}`);
  },
  getPatient(id) {
    return api.get(`/patient/${id}`);
  },
  getGivenId(id) {
    return api.get(`/patient/givenId/${id}`);
  },
  getReferral() {
    return new Promise((resolve, reject) => {
      api.get('/patient/referral/all')
        .then((res) => {
          // console.log('get referral res ', res);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updatePatientProfilePic(id, imageFile) {
    return api.multiPartPatch(
      `/patient/pic/${id}`,
      { picture: imageFile },
    );
  },
  downloadPatientXlsxTemplate() {
    return api.get('/patient/infoTemplate?type=xlsx', { responseType: 'arraybuffer' });
  },
  uploadEmployeePatientFile(file) {
    return api.multiPartPost('/patient/importEmployeesAsPatientsTW', {}, { myFile: file });
  },
  getEmployeeAllergyHistory(id) {
    return api.get(`/questionnaire/employee/patientAllergyHistory/${id}`);
  },
  createEmployeePatient(data) {
    return api.post('/patient/employee', data);
  },
  updateEmployeePatient(id, data) {
    return api.put(`/patient/employee/${id}`, data);
  },
  updateEmployeeAllergyHistory(id, data) {
    return api.put(`/patient/${id}/allergyHistory`, data);
  },
};
