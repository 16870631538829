
import Vue from 'vue';
import { v4 } from 'uuid';
import _ from 'lodash';
import InvoiceService from '@/services/invoice.service';
import {
  BEFORE_FETCH_ALL_INVOICES,
  FETCH_ALL_INVOICES,
  SET_INVOICE,
  ADD_LINE_ITEM,
  ADD_LETTER,
  REMOVE_LINE_ITEM,
  UPDATE_ICD10,
  ADD_ICD10,
  REMOVE_ICD10,
  EDIT_LETTER,
  REMOVE_LETTER,
  UPDATE_INVOICE,
  SAVE_INVOICE,
  SAVE_INVOICE_PROVIDER,
  PATCH_INVOICE,
  SET_PRINT,
  SET_PRINT_PACKAGE_TRANSFER_RECEIPT,
  SET_RECENT_CHANGE,
  SET_BASE_VERSION,
  VOID_INVOICE,
  SET_SELECTED_CORP_INV,
  UPDATE_INVOICE_GST,
  SET_STATS,
  PATCH_STATS,
  UNREDEEM_PACKAGE_ITEM,
  UPDATE_INVOICE_GST_X_RATE,
  UPDATE_INVOICE_LOCK_STATUS,
  SET_XERO_TOKEN,
} from './type.mutation';


const state = {
  invoices: [],
  invoicesMappedById: {},
  selectedInvoice: {},
  selectedCorpInvoice: {},
  selectedInvoiceNotes: {},
  print: {},
  printPackageTransferReceipt: {},
  recentChange: {},
  baseVersion: {},
  stats: {},
  xeroToken: {},
};

/* eslint-disable no-param-reassign */
const mutations = {
  [BEFORE_FETCH_ALL_INVOICES](_state) {
    _state.invoices = [];
    _state.invoicesMappedById = {};
    _state.selectedInvoiceNotes = {};
  },
  [FETCH_ALL_INVOICES](_state, payload) {
    _state.invoices = payload;
    payload.forEach((inv) => {
      Vue.set(_state.invoicesMappedById, inv._id, inv);
      // _state.invoicesMappedById[inv._id] = inv; // use Vue.set, vue will not be reactive when adding new property to the object
    });
  },
  [SET_INVOICE](_state, payload) { // TODO: should set _state.selectedInvoice to the invoice's _id
    _state.selectedInvoice = payload;
  },
  [UPDATE_INVOICE_GST](_state, { invoice, includeGST }) {
    _state.invoicesMappedById = {
      ..._state.invoicesMappedById,
      [invoice._id]: { ...invoice, includeGST },
    };
  },
  [UPDATE_INVOICE_GST_X_RATE](_state, { invoice, includeGST, exchangeRate }) {
    _state.invoicesMappedById = {
      ..._state.invoicesMappedById,
      [invoice._id]: { ...invoice, includeGST, exchangeRate },
    };
  },
  [UPDATE_INVOICE](_state, invoice) {
    _state.invoicesMappedById = { ..._state.invoicesMappedById, [invoice._id]: invoice };
  },
  [UPDATE_INVOICE_LOCK_STATUS](_state, { invoiceId, isLocked }) {
    if (_state.invoicesMappedById[invoiceId]) {
      Vue.set(_state.invoicesMappedById[invoiceId], 'isLocked', isLocked);
    }
  },
  PATCH_INVOICE(_state, { id, key, value }) {
    _state.invoicesMappedById = { ..._state.invoicesMappedById, [id]: { ..._state.invoicesMappedById[id], [key]: value } };
    _state.invoices = _state.invoices.map((invoice) => {
      if (invoice._id === id) {
        invoice = _state.invoicesMappedById[id];
      }
      return invoice;
    });
  },
  [ADD_LINE_ITEM](_state, { invoiceId, item }) {
    const clonedItems = _.cloneDeep(_state.invoicesMappedById[invoiceId].items) || [];
    if (!item.uuid) {
      item.uuid = v4();
    }
    if (this.state.config.invoice.itemOrder === 'latestItemAtTop') {
      clonedItems.unshift(item);
    } else {
      clonedItems.push(item);
    }
    _state.invoicesMappedById = {
      ..._state.invoicesMappedById,
      [invoiceId]: { ..._state.invoicesMappedById[invoiceId], items: clonedItems },
    };
  },
  [REMOVE_LINE_ITEM](_state, { invoiceId, uuid }) {
    let clonedItems = _.cloneDeep(_state.invoicesMappedById[invoiceId].items) || [];
    clonedItems = clonedItems.filter(item => item.uuid !== uuid);
    _state.invoicesMappedById = { ..._state.invoicesMappedById, [invoiceId]: { ..._state.invoicesMappedById[invoiceId], items: clonedItems } };
  },
  [UPDATE_ICD10](_state, { invoiceId, updatedDiagnosis }) {
    const diagnosis = updatedDiagnosis
      .map(dgroup => dgroup
        .filter(d => d.valid)
        .map(d => ({
          uuid: d.uuid || v4(),
          text: d.text,
        })));
    _state.invoicesMappedById = {
      ..._state.invoicesMappedById,
      [invoiceId]: {
        ..._state.invoicesMappedById[invoiceId],
        diagnosis,
      },
    };
  },
  [ADD_ICD10](_state, { invoiceId, icd10_text }) {
    const clonedICD10 = _.cloneDeep(_state.invoicesMappedById[invoiceId].icd10) || [];
    const icd10 = {
      uuid: v4(),
      text: icd10_text,
    };
    clonedICD10.push(icd10);
    _state.invoicesMappedById = { ..._state.invoicesMappedById, [invoiceId]: { ..._state.invoicesMappedById[invoiceId], icd10: clonedICD10 } };
  },
  [REMOVE_ICD10](_state, { invoiceId, uuid }) {
    let clonedICD10 = _.cloneDeep(_state.invoicesMappedById[invoiceId].icd10) || [];
    clonedICD10 = clonedICD10.filter(icd10 => icd10.uuid !== uuid);
    _state.invoicesMappedById = { ..._state.invoicesMappedById, [invoiceId]: { ..._state.invoicesMappedById[invoiceId], icd10: clonedICD10 } };
  },
  [ADD_LETTER](_state, { invoiceId, letter }) {
    const clonedLetters = _.cloneDeep(_state.invoicesMappedById[invoiceId].letters) || [];
    letter.uuid = v4();
    clonedLetters.push(letter);
    _state.invoicesMappedById = { ..._state.invoicesMappedById, [invoiceId]: { ..._state.invoicesMappedById[invoiceId], letters: clonedLetters } };
  },
  [REMOVE_LETTER](_state, { invoiceId, uuid }) {
    let clonedLetters = _.cloneDeep(_state.invoicesMappedById[invoiceId].letters) || [];
    clonedLetters = clonedLetters.filter(letter => letter.uuid !== uuid);
    _state.invoicesMappedById = { ..._state.invoicesMappedById, [invoiceId]: { ..._state.invoicesMappedById[invoiceId], letters: clonedLetters } };
  },
  [EDIT_LETTER](_state, {
    invoiceId, letterUuid, key, value,
  }) {
    const clonedLetters = (_state.invoicesMappedById[invoiceId].letters || []).map(letter => Object.assign({}, letter));
    for (let i = 0; i < clonedLetters.length; i++) {
      if (clonedLetters[i].uuid === letterUuid) {
        clonedLetters[i][key] = value;
      }
    }
    _state.invoicesMappedById = { ..._state.invoicesMappedById, [invoiceId]: { ..._state.invoicesMappedById[invoiceId], letters: clonedLetters } };
  },
  [SET_PRINT](_state, payload) {
    _state.print = payload;
  },
  [SET_PRINT_PACKAGE_TRANSFER_RECEIPT](_state, payload) {
    console.log('setting printPackageTransferReceipt to payload ', payload);
    _state.printPackageTransferReceipt = payload;
  },
  [SET_RECENT_CHANGE](_state, payload) {
    _state.recentChange = payload;
  },
  [SET_BASE_VERSION](_state, payload) {
    _state.baseVersion = payload;
  },
  extractInvoiceNotes(_state, invoiceId) {
    // let lastMediumSizedHeight = localStorage.getItem('lastMediumSizedHeight');
    _state.selectedInvoiceNotes = Object.assign({}, {
      invoiceId,
      openSize: 'mediumSized',
    });
  },
  clearInvoiceNotesExtract(_state) {
    _state.selectedInvoiceNotes = {};
  },
  changeInvoiceNotesExtractSize(_state, newSize) {
    _state.selectedInvoiceNotes = { ..._state.selectedInvoiceNotes, openSize: newSize };
  },
  [SET_SELECTED_CORP_INV](_state, payload) {
    _state.selectedCorpInvoice = payload;
  },
  [SET_STATS](_state, payload) {
    _state.stats = payload;
  },
  [PATCH_STATS](_state, payload) {
    const clonedStats = { ..._state.stats };
    clonedStats[payload.id] = { ...clonedStats[payload.id], ...{ ostBalance: payload.ostBalance } };
    _state.stats = clonedStats;
  },
  [UNREDEEM_PACKAGE_ITEM](_state, { invoiceId, uuid }) {
    let clonedItems = _.cloneDeep(_state.invoicesMappedById[invoiceId].items) || [];
    const removedItem = clonedItems.filter(item => item.uuid === uuid);
    clonedItems = clonedItems.filter(item => item.uuid !== uuid);
    _state.invoicesMappedById = { ..._state.invoicesMappedById, [invoiceId]: { ..._state.invoicesMappedById[invoiceId], items: clonedItems } };
    const addLineItem = _.cloneDeep(_state.invoicesMappedById[invoiceId].items) || [];
    removedItem[0].uuid = v4();
    removedItem[0].isInPackage = true;
    removedItem[0].price = removedItem[0].oldPrice;
    delete removedItem[0].packageId;
    delete removedItem[0].refrenceItemId;
    delete removedItem[0].refrenceItemId;
    addLineItem.push(removedItem[0]);
    _state.invoicesMappedById = { ..._state.invoicesMappedById, [invoiceId]: { ..._state.invoicesMappedById[invoiceId], items: addLineItem } };
  },
  [SET_XERO_TOKEN](_state, token = {}) {
    _state.xeroToken = _.cloneDeep(token);
  },
};

const actions = {
  fetchAllInvoices({ commit }, params = {}) {
    commit(BEFORE_FETCH_ALL_INVOICES);
    commit('note/resetCachedPermissions', null, { root: true });
    const { chartingView } = params;
    delete params.chartingView;
    return InvoiceService.list(params)
      .then((response) => {
        const payload = response.data;
        commit(FETCH_ALL_INVOICES, payload);
        // console.log('Preparing Notemap fron invoice ', new Date());
        let chartings = payload
          .filter(p => !!p.mainFormId)
          .map((p) => {
            const clonedMainForm = _.cloneDeep(p.mainFormId);
            clonedMainForm.chartingNoteIds = clonedMainForm.chartingNoteIds.map(c => c._id);
            const clonedChartings = _.cloneDeep(p.mainFormId.chartingNoteIds);
            clonedChartings.push(clonedMainForm);
            return clonedChartings;
          });
        chartings = _.flatten(chartings);
        if (!chartingView) {
          commit('note/SET_ALL_NOTES', chartings, { root: true });
        }
        // console.log('Completed Preparing Notemap fron invoice ', new Date());
        return response;
      })
      .catch((err) => {
        console.log('fetch invoices err is ', err);
      });
  },

  async [PATCH_INVOICE]({ commit, state }, { id, key }) {
    const invoice = Object.assign({}, state.invoicesMappedById[id]);
    const payload = {
      [key]: invoice[key],
    };
    const resp = await InvoiceService.patch(id, payload);
    if (resp.success) {
      const updatedInvoice = resp.data;
      commit(UPDATE_INVOICE, updatedInvoice);
    }
    return resp;
  },
  async [SAVE_INVOICE]({ commit, state, dispatch }, { id, voidInvoice, closeInvoice }) {
    let payload = Object.assign({}, state.invoicesMappedById[id]);
    const clonedMainFormId = { ...payload.mainFormId };
    // misc
    payload.amountApply = 0;
    payload.corpAmountApply = 0;

    if (payload.queue) {
      if (payload.queue.provider && !payload.provider) payload.provider = payload.queue.provider;
      if (payload.queue.therapist && !payload.therapist) {
        payload.therapist = payload.queue.therapist;
      }
    }

    if (payload.provider && typeof payload.provider === 'object') {
      payload.provider = payload.provider._id;
    }
    if (payload.therapist && typeof payload.therapist === 'object') {
      payload.therapist = payload.therapist._id;
    }
    if (payload.payments) {
      if (typeof payload.payments[0] === 'object') {
        payload.payments = payload.payments.map(val => val._id);
      }
    }

    // TODO: chan - there should be mapperfromInvoice to JSON and vice versa
    payload.promotions = payload.promotions.map(promotion => promotion.id);

    let { patient } = payload;
    delete payload.queue;
    delete payload.patient;
    if (voidInvoice) {
      const { items } = payload;
      payload = {
        invoiceNo: id,
        voided: true,
        items,
      };
    }

    if (closeInvoice) {
      payload.finalized = true;
    }

    const resp = await InvoiceService.update(id, payload, closeInvoice);
    if (resp.success) {
      const updatedInvoice = resp.data;
      if (clonedMainFormId) {
        updatedInvoice.mainFormId = clonedMainFormId;
      }
      commit(UPDATE_INVOICE, updatedInvoice);

      if (typeof patient === 'object') patient = patient._id;
      dispatch('membership/fetchPointsTrxStats', patient, { root: true });
    }

    return resp;
  },
  async [SAVE_INVOICE_PROVIDER]({ commit, state, dispatch }, { id, voidInvoice, closeInvoice }) {
    let payload = Object.assign({}, state.invoicesMappedById[id]);
    const clonedMainFormId = { ...payload.mainFormId };
    // misc
    payload.amountApply = 0;
    payload.corpAmountApply = 0;

    if (payload.queue) {
      if (payload.queue.provider && !payload.provider) payload.provider = payload.queue.provider;
      if (payload.queue.therapist && !payload.therapist) {
        payload.therapist = payload.queue.therapist;
      }
    }

    if (payload.provider && typeof payload.provider === 'object') {
      payload.provider = payload.provider._id;
    }
    if (payload.patient && typeof payload.patient === 'object') {
      payload.patient = payload.patient._id;
    }
    if (payload.therapist && typeof payload.therapist === 'object') {
      payload.therapist = payload.therapist._id;
    }
    if (payload.payments) {
      if (typeof payload.payments[0] === 'object') {
        payload.payments = payload.payments.map(val => val._id);
      }
    }

    // TODO: chan - there should be mapperfromInvoice to JSON and vice versa
    payload.promotions = payload.promotions.map(promotion => promotion.id);

    let { provider } = payload;
    delete payload.queue;
    // delete payload.provider;
    if (voidInvoice) {
      const { items } = payload;
      payload = {
        invoiceNo: id,
        voided: true,
        items,
      };
    }

    if (closeInvoice) {
      payload.finalized = true;
    }

    const resp = await InvoiceService.updateProviderInvoice(id, payload, closeInvoice);
    if (resp.success) {
      const updatedInvoice = resp.data;
      if (clonedMainFormId) {
        updatedInvoice.mainFormId = clonedMainFormId;
      }
      commit(UPDATE_INVOICE, updatedInvoice);

      if (typeof provider === 'object') provider = provider;
      dispatch('membership/fetchPointsTrxProviderStats', provider, { root: true });
    }

    return resp;
  },
  print({ commit }, payload) {
    commit(SET_PRINT, payload);
  },
  printPackageTransferReceipt({ commit }, payload) {
    console.log('in SET_PRINT_PACKAGE_TRANSFER_RECEIPT, payload is ', payload);
    commit(SET_PRINT_PACKAGE_TRANSFER_RECEIPT, payload);
  },
  loadInvoiceItems({ commit }, invoiceId) {
    return InvoiceService.getByShowId(invoiceId)
      .then((response) => {
        let payload = {};
        if (response.success) {
          payload = response.data;
        }
        commit(UPDATE_INVOICE, payload);
        return payload;
      });
  },
  loadInvoiceItemsProvider({ commit }, invoiceId) {
    return InvoiceService.getByShowProviderInvoiceId(invoiceId)
      .then((response) => {
        let payload = {};
        if (response.success) {
          payload = response.data;
        }
        commit(UPDATE_INVOICE, payload);
        return payload;
      });
  },
  setSelectedInvoice({ commit }, payload) {
    commit(SET_INVOICE, payload);
  },
  updateInvoice({ commit }, payload) {
    commit(UPDATE_INVOICE, payload);
  },
  fetchRecentChange({ commit }, invoiceId) {
    return InvoiceService.getRecentChange(invoiceId)
      .then((response) => {
        const payload = response.data;
        commit(SET_RECENT_CHANGE, payload);
        return payload;
      });
  },
  createNewInvoice({ commit }, payload) {
    return InvoiceService.create(payload)
      .then(response => response.data);
  },
  deleteInvoice({ commit }, id) {
    return InvoiceService.delete(id).then((response) => {
      if (!response.ok) commit(UPDATE_INVOICE, response.body.data);
      return response;
    });
  },
  voidInvoice({ commit }, payload) {
    return InvoiceService.voidInvoice(payload._id, payload)
      .then(response =>
        // dispatch('fetchAllInvoices');
        response.data);
  },
  fetchBaseVersion({ commit }, params = {}) {
    console.log('param ', params);
    return InvoiceService.getBaseVersion(params.invoiceId, params)
      .then((response) => {
        const payload = response.data;
        commit(SET_BASE_VERSION, payload);
        return payload;
      });
  },
  fetchInvoiceStats({ commit }, params) {
    // console.log('Fetching invoice stats ', params);
    return InvoiceService.invoiceStats(params)
      .then((response) => {
        const payload = response.data;
        commit(SET_STATS, payload);
      });
  },
  updateInvoiceById({ commit }, params) {
    return InvoiceService.getById(params.id)
      .then((response) => {
        const payload = response.data;
        commit(UPDATE_INVOICE, payload);
      });
  },
  updateIPDInvoiceById({ commit }, params) {
    return InvoiceService.getById(params.id)
      .then((response) => {
        const payload = response.data;
        commit(UPDATE_INVOICE, payload);
      });
  },
};

const getters = {
  SELECTED_INVOICE: _state => _state.selectedInvoice,
  invoices: _state => _state.invoices,
  invoicesMappedById: _state => _state.invoicesMappedById,
  print: _state => _state.print,
  printPackageTransferReceipt: _state => _state.printPackageTransferReceipt,
  recentChange: _state => _state.recentChange,
  baseVersion: _state => _state.baseVersion,
  selectedCorpInvoice: _state => _state.selectedCorpInvoice,
  stats: _state => _state.stats,
  xeroToken: _state => _state.xeroToken,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
