
import Vue from 'vue';
import api from '@/services/api';

export default {
  getClinics(params = {}) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);

    Object.keys(params).forEach((key) => {
      q.push(`${key}=${params[key]}`);
    });
    const url = `/grabscan/clinics?${q.join('&')}`;

    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`List Error: ${response}, url: ${url}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },

  login(creds = {}) {
    const apiurl = process.env.VUE_APP_GRABSCAN_API || ''
    return Vue.http.post(`${apiurl}/authenticate`, creds, {})
      .then((response) => {
        const token = (response.body && response.body.token) || '';
        return token;
      }).catch((err )=> {
        console.log(err)
        return '';
      });
  },

  getAvailableCentres(token, appointmentOn, scantestCode) {
    const apiurl = process.env.VUE_APP_GRABSCAN_API || '';
    return Vue.http.post(`${apiurl}/CAppointmentRegistration/SearchAvailableCentres`, {
      appointmentOn, scantestCode,
    }, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    })
      .then(response => response.body)
      .catch((err) => {
        console.log(err);
        return [];
      });
  },

  getScanTestPrice(token, CentreId = '', ScanTestCode = '') {
    const apiurl = process.env.VUE_APP_GRABSCAN_API || '';
    return Vue.http.post(
      `${apiurl}/CAppointmentRegistration/ScanTestPrice`,
      { CentreId, ScanTestCode },
      {
        headers: {
          Authorization: `bearer ${token}`,
        },
      },
    )
      .then(response => response.body)
      .catch((err) => {
        console.log(err);
        return '';
      });
    // return api.post('/grabscan/avail-centres', {
    //   appointmentOn,
    //   scantestCode,
    // });
  },

  bookAppointment(token, appointment = {}) {
    const apiurl = process.env.VUE_APP_GRABSCAN_API || ''
    return Vue.http.post(
      `${apiurl}/CAppointmentRegistration/CreateAppointment`,
      appointment,
      {
        headers: {
          Authorization: `bearer ${token}`,
        },
      },
    )
      .then((response) => {
        // console.log('GRABSCAN SERVICE: (SUC): ', response);
        if (response.body && response.body.AccessionNumber) {
          return response.body.AccessionNumber;
        }
        return null;
      }).catch((err) => {
        console.log('GRABSCAN SERVICE: (ERR): ', err);
        return null;
      });
  },

  updateAppointment(token, appointment = {}) {
    const apiurl = process.env.VUE_APP_GRABSCAN_API || ''
    return Vue.http.post(
      `${apiurl}/CAppointmentRegistration/UpdateAppointment`,
      appointment,
      {
        headers: {
          Authorization: `bearer ${token}`,
        },
      },
    )
      .then((response) => {
        // console.log('GRABSCAN SERVICE: (SUC): ', response);
        if (response.body && response.body.AccessionNumber) {
          return response.body.AccessionNumber;
        }
        return null;
      }).catch((err) => {
        console.log('GRABSCAN SERVICE: (ERR): ', err);
        return null;
      });
  },

  rescheduleAppointment(token, CmsAppCode, AccessionNumber, AppointmentDate, Remarks = '') {
    const apiurl = process.env.VUE_APP_GRABSCAN_API || ''
    const data = {
      CmsAppCode, AccessionNumber, AppointmentDate, Remarks,
    };
    // console.log('for RESCHEDULE : ', data);
    return Vue.http.post(
      `${apiurl}/CAppointmentRegistration/RescheduleAppointment`,
      data,
      {
        headers: {
          Authorization: `bearer ${token}`,
        },
      },
    )
      .then((response) => {
        // console.log('GRABSCAN SERVICE: (SUC): ', response);
        if (response.body && response.body.Message === 'Success') {
          return true;
        }
        return false;
      }).catch((err) => {
        console.log('GRABSCAN SERVICE: (ERR): ', err);
        return false;
      });
  },

  cancelAppointment(token, CmsAppCode, AccessionNumber, Remarks = '') {
    const apiurl = process.env.VUE_APP_GRABSCAN_API || ''
    return Vue.http.post(
      `${apiurl}/CAppointmentRegistration/CancelAppointment`,
      {
        CmsAppCode, AccessionNumber, Remarks,
      },
      {
        headers: {
          Authorization: `bearer ${token}`,
        },
      },
    )
      .then((response) => {
        // console.log('GRABSCAN SERVICE: (SUC): ', response);
        if (response.body && response.body.Message === 'Success') {
          return true;
        }
        return false;
      }).catch((err) => {
        console.log('GRABSCAN SERVICE: (ERR): ', err);
        return false;
      });
  },
};
