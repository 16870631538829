import service from '@/services/anaesthesia.service';
/* eslint-disable no-param-reassign */
import {
  SET_PREPARATION,
  ADD_MEDICINES,
  REMOVE_MEDICINE,
  UPDATE_MEDICINE,
  CONFIRM_MEDICATION,
  SET_MEDICATION,
  ADD_EVENTS,
  FINALISE_DISCHARGE,
  ADD_VITAL_TIME,
  ADD_VITAL_LOG,
  ADD_NEW_VITAL,
  SET_VITAL_VALUE,
  SET_VITAL_TIME,
  SET_VITAL_LOG,
  SET_EVENTS,
  SET_CHARTED_VITALS,
} from './type.mutation';

const defaultPreparation = {
  o2Inhalation: '',
  targetSpo2: '',
  ivFluid: '',
  arrivalTime: '',
  ett: '',
  oralNasalAirway: '',
  others: '',
  anesthesiologist: '',
  states: '',
  prepared: false,
  preparedTime: '',
  preparedUser: null,
};

const defaultMedications = {
  confirmed: false,
  confirmedTime: '',
  confirmedUser: '',
  // administered: false,
  // administrator: '',
  // administeredTime: '',
  medicines: [],
};

const defaultDischarge = {
  checklist: [],
  aldreteActivity: '',
  aldreteRespiration: '',
  aldreteCirculation: '',
  aldreteO2Saturation: '',
  checklistConfirmed: false,
  checklistConfirmedUser: '',
  checklistConfirmedTime: '',
  bromageScale: '',
  dischargeTo: '',
  dischargeToConfirmed: false,
  dischargeToConfirmedUser: '',
  dischargeToConfirmedTime: '',
  wardInstruction: [],
  wardInstructionDetails: '',
  wardInstructionConfirmed: false,
  wardInstructionConfirmedUser: '',
  wardInstructionConfirmedTime: '',
  acknowledged: false,
  acknowledgedUser: '',
  acknowledgeTime: '',
};

const defaultVitalLogs = [
  { vitalType: 'Conscious State', value: '', logs: {} },
  { vitalType: 'SpO2 (%)', value: '', logs: {} },
  { vitalType: 'Pain Score', value: '', logs: {} },
  { vitalType: 'Fluids (ml)', value: '', logs: {} },
];

const state = {
  preparation: defaultPreparation,
  vitals: {},
  vitalTimes: [],
  vitalLogs: defaultVitalLogs,
  medications: defaultMedications,
  discharge: defaultDischarge,
  events: [],
  chartedVitals: [],
};

const mutations = {
  [SET_PREPARATION](_state, payload) {
    _state.preparation = payload || _state.preparation;
  },
  [ADD_MEDICINES](_state, payload) {
    _state.medications.medicines.push(payload);
  },
  [REMOVE_MEDICINE](_state, uuid) {
    _state.medications.medicines = _state.medications.medicines
      .filter(m => m.uuid !== uuid);
  },
  [UPDATE_MEDICINE](_state, payload) {
    _state.medications.medicines = _state.medications.medicines.map((medicine) => {
      if (medicine.uuid === payload.uuid) medicine = payload;
      return medicine;
    });
  },
  [CONFIRM_MEDICATION](_state, payload) {
    _state.medications.medicines = payload.medicines;
  },
  [SET_MEDICATION](_state, payload) {
    _state.medications = payload || _state.medications;
  },
  // [ADMINISTERED_MEDICATION](_state, payload) {
  //   _state.medications.administered = true;
  //   _state.medications.administeredTime = administeredTime;
  //   _state.medications.administrator = administrator;
  // },
  [ADD_EVENTS](_state, payload) {
    _state.events.push(payload);
  },
  [SET_EVENTS](_state, payload) {
    _state.events = payload || [];
  },
  [FINALISE_DISCHARGE](_state, payload) {
    _state.discharge = payload || _state.discharge;
  },
  [ADD_VITAL_TIME](_state, payload) {
    _state.vitalTimes.push(payload);
  },
  [ADD_VITAL_LOG](_state, recordTime) {
    _state.vitalLogs = _state.vitalLogs.map((vital) => {
      vital.logs[recordTime] = vital.value;
      vital.value = '';
      return vital;
    });
  },
  [ADD_NEW_VITAL](_state, payload) {
    _state.vitalLogs.push(payload);
  },
  [SET_VITAL_VALUE](_state, payload) {
    _state.vitalLogs = _state.vitalLogs.map((vital) => {
      if (vital.vitalType === payload.vitalType) vital.value = payload.value;
      return vital;
    });
  },
  [SET_VITAL_TIME](_state, payload) {
    _state.vitalTimes = payload || [];
  },
  [SET_VITAL_LOG](_state, payload) {
    _state.vitalLogs = payload || _state.vitalLogs;
  },
  [SET_CHARTED_VITALS](_state, payload) {
    _state.chartedVitals = payload;
  },
};

const actions = {
  confirmPreparation({ commit }, payload) {
    return service.prepare(payload)
      .then((response) => {
        commit(SET_PREPARATION, payload.preparation);
        return response.data;
      });
  },
  dispenseMedicine({ commit }, payload) {
    commit(ADD_MEDICINES, payload);
  },
  removeMedicine({ commit }, uuid) {
    commit(REMOVE_MEDICINE, uuid);
  },
  updateMedicine({ commit }, payload) {
    commit(UPDATE_MEDICINE, payload);
  },
  confirmMedication({ commit }, payload) {
    return service.confirmMedication(payload)
      .then((response) => {
        commit(CONFIRM_MEDICATION, payload.medications);
        return response.data;
      });
  },
  administerMedication({ commit }, payload) {
    return service.administerMedicine(payload)
      .then((response) => {
        commit(UPDATE_MEDICINE, payload.medicine);
        return response.data;
      });
  },
  addEvent({ commit }, payload) {
    return service.recordEvents(payload)
      .then((response) => {
        commit(ADD_EVENTS, payload.events);
        return response.data;
      });
  },
  finaliseDischarge({ commit }, payload) {
    return service.discharge(payload)
      .then((response) => {
        commit(FINALISE_DISCHARGE, payload.discharge);
        return response.data;
      });
  },
  recordVitalTime({ commit }, payload) {
    commit(ADD_VITAL_TIME, payload);
  },
  recordVitalLog({ commit }, payload) {
    return service.recordVital(payload)
      .then((response) => {
        commit(ADD_VITAL_TIME, payload.vitalTime);
        commit(ADD_VITAL_LOG, payload.vitalTime.time);
        return response.data;
      });
  },
  addNewVital({ commit }, payload) {
    commit(ADD_NEW_VITAL, payload);
  },
  setVitalValue({ commit }, payload) {
    commit(SET_VITAL_VALUE, payload);
  },
  fetchAnaesthesia({ commit }, params = {}) {
    return service.list(params)
      .then((response) => {
        console.log('---> resp.data', response.data);
        commit(SET_PREPARATION, response.data.preparation);
        commit(SET_VITAL_TIME, response.data.vitalTimes);
        commit(SET_VITAL_LOG, response.data.vitalLogs);
        commit(SET_EVENTS, response.data.events);
        commit(FINALISE_DISCHARGE, response.data.discharge);
        commit(SET_MEDICATION, response.data.medications);
        commit(SET_CHARTED_VITALS, response.data.chartedVitals);
        return response.data;
      })
      .catch(() => {
        commit(SET_PREPARATION, defaultPreparation);
        commit(SET_VITAL_TIME, []);
        commit(SET_VITAL_LOG, defaultVitalLogs);
        commit(SET_EVENTS, []);
        commit(FINALISE_DISCHARGE, defaultDischarge);
        commit(SET_MEDICATION, defaultMedications);
        commit(SET_CHARTED_VITALS, []);
      });
  },
  recordChartedVital({ commit }, {
    patient, admission, otbooking, payload,
  }) {
    return service.recordChartedVital({ patient, admission, otbooking }, payload)
      .then((response) => {
        if (response.success) {
          const { chartedVitals } = response.data;
          commit(SET_CHARTED_VITALS, chartedVitals);
        }
        return response;
      });
  },
};

const getters = {
  preparation: _state => _state.preparation,
  medications: _state => _state.medications,
  events: _state => _state.events,
  discharge: _state => _state.discharge,
  vitalTimes: _state => _state.vitalTimes,
  vitalLogs: _state => _state.vitalLogs,
  chartedVitals: _state => _state.chartedVitals,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
