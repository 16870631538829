import clinicService from '@/services/clinic.service';
import grabscanService from '@/services/grabscan.service';

import {
  SET_CLINICS,
  SET_SELECTED_CLINIC,
  SET_GRABSCAN_CLINICS
} from './type.mutation';

const state = {
  all: [],
  grabscanClinics: [],
  selectedClinic: {},
};

/* eslint-disable no-param-reassign */
const mutations = {
  [SET_CLINICS](_state, payload) {
    _state.all = payload;
  },
  [SET_GRABSCAN_CLINICS](_state, payload) {
    _state.grabscanClinics = payload;
  },
  [SET_SELECTED_CLINIC](_state, payload) {
    _state.selectedClinic = payload;
  },
};
const actions = {
  fetchClinics({ commit }, clinicCode) {
    return clinicService.list({ clinicCode })
      .then((response) => {
        if (response.success) {
          commit(SET_CLINICS, response.data);
          let DEFAULT_SELECTED_CLINIC = {};
          response.data.map((c) => {
            if (c.default) DEFAULT_SELECTED_CLINIC = c;
            return DEFAULT_SELECTED_CLINIC;
          });
          commit(SET_SELECTED_CLINIC, Object.keys(DEFAULT_SELECTED_CLINIC).length ? DEFAULT_SELECTED_CLINIC : response.data[0]);
        }
        return response;
      });
  },
  fetchGrabscanClinics({ commit }) {
    return grabscanService.getClinics({})
      .then((response) => {
        if (response.success) {
          commit(SET_GRABSCAN_CLINICS, response.data);
        }
        return response;
      });
  },
  resetAll({ commit }) {
    commit(SET_CLINICS, []);
  },
  selectClinic({ commit }, clinic = {}) {
    commit(SET_SELECTED_CLINIC, clinic);
  },
};

const getters = {
  all: _state => _state.all,
  grabscanClinics: _state => _state.grabscanClinics,
  selectedClinic: _state => _state.selectedClinic,
  clinicIDS: _state => _state.all.reduce((acc, clinic) => ({
    ...acc,
    [clinic._id]: clinic,
  }), {}),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
