<template>
  <div class="container mb-5" :class="{'previewPrint': preview}">
    <div class="row mb-3">
      <div class="col-9">
     
        <img v-if="patientConfig.printConsultNote.clinicLogo"
             :style="{ marginLeft:`${logoMarginLeft}px`, width:`${logoWidth}px`, }"
             :src="this.companyClinicLogo">
        <h5 class="clinic-name" v-if="patientConfig.printConsultNote.clinicName">
          {{ generalConfig.clinicName }}
        </h5>
        <p class="clinic-address" v-if="patientConfig.printConsultNote.clinicAddress">
          {{ generalConfig.address }}
        </p>
      </div>
      <div class="col3" v-if="type==='classified'">
        <div class="classified-medicine-tip">
          {{ $t('prescriptionConfig.classifiedMedicineTip') }}
        </div>
      </div>
    </div>
    <h3 class="mb-5 mt-3 text-center text-uppercase">
      {{ $t('general.consultNoteTitle') }}
    </h3>
    <div class="row mb-4">
      <div class="col-6">
        <div class="row mb-2" v-if="patientConfig.printConsultNote.patientName">
          <div class="col-4 form-label">{{ $t('general.patientName') }}:</div>
          <div class="col-8 form-value">{{selectedConsultNote.patient.name}}</div>
        </div>
        <div class="row mb-2" v-if="patientConfig.printConsultNote.patientGender">
          <div class="col-4 form-label">{{ $t('general.patientGender') }}:</div>
          <div class="col-8 form-value">{{selectedConsultNote.patient.gender | translateGender}}</div>
        </div>
        <div class="row mb-2" v-if="patientConfig.printConsultNote.patientNRIC">
          <div class="col-4 form-label">{{ $t('general.patientNRIC') }}:</div>
          <div class="col-8 form-value">{{selectedConsultNote.patient.nric}}</div>
        </div>
        <div class="row mb-2" v-if="patientConfig.printConsultNote.patientAddress">
          <div class="col-4 form-label">{{ $t('general.patientAddress') }}:</div>
          <div class="col-8 form-value">{{selectedConsultNote.patient.address}}</div>
        </div>
        <div class="row mb-2" v-if="patientConfig.printConsultNote.patientDOB">
          <div class="col-4 form-label">{{ $t('general.dateOfBirth') }}:</div>
          <div class="col-8 form-value">{{selectedConsultNote.patient.dateOfBirth | formatDayTime}}</div>
        </div>
        <div class="row mb-2" v-if="patientConfig.printConsultNote.patientNationality">
          <div class="col-4 form-label">{{ $t('general.nationality') }}:</div>
          <div class="col-8 form-value">{{selectedConsultNote.patient.nationality}}</div>
        </div>
      </div>
      <div class="col-6">
        <div class="row mb-2" v-if="patientConfig.printConsultNote.visitDate">
          <div class="col-4 form-label">{{ $t('general.visitDate') }}:</div>
          <div class="col-8 form-value">{{selectedConsultNote.invoice.createdAt | formatDayTime}}</div>
        </div>
        <div class="row mb-2" v-if="patientConfig.printConsultNote.medicalServices">
          <div class="col-4 form-label">{{ $t('general.medicalServices') }}:</div>
          <div class="col-8 form-value">{{selectedConsultNote.invoice.queue && selectedConsultNote.invoice.queue.service && selectedConsultNote.invoice.queue.service.name}}</div>
        </div>
        <div class="row mb-2" v-if="patientConfig.printConsultNote.doctorName">
          <div class="col-4 form-label">{{ $t('general.doctorName') }}:</div>
          <div class="col-8 form-value">{{selectedConsultNote.patient.doctor && selectedConsultNote.patient.doctor.name}}</div>
        </div>
        <div class="row mb-2" v-if="patientConfig.printConsultNote.therapistName">
          <div class="col-4 form-label">{{ $t('general.therapistName') }}:</div>
          <div class="col-8 form-value">{{selectedConsultNote.invoice.therapist && selectedConsultNote.invoice.therapist.name}}</div>
        </div>
      </div>
    </div>
    <div class="consult-tempalte-wrap mb-4">
      <div class="consult-template-item mb-2"
           v-for="(item, key) in selectedConsultNote.mainForm.formData"
           :key="key">
        <div class="consult-question">
          {{item.label}}
        </div>
        <div class="consult-answer">
          {{item.answer}}
        </div>
      </div>
    </div>
    <div class="item-title">{{$t('general.recordICD10')}}</div>
    <div class="mb-4 icd-wrap">
      <div class="row icd-sub-item"
           v-if="selectedConsultNote.invoice.diagnosis && selectedConsultNote.invoice.diagnosis[0] && (selectedConsultNote.invoice.diagnosis[0].length > 0)">
        <div class="col-3 form-label">{{ $t('general.primaryDiagnosis')}}</div>
        <div class="col-9">
          <div class="diagnosisItem"
               v-for="item in selectedConsultNote.invoice.diagnosis[0]"
               :key="item.uuid">
            {{item.text}}
          </div>
        </div>
      </div>
      <div class="row icd-sub-item"
           v-if="selectedConsultNote.invoice.diagnosis && selectedConsultNote.invoice.diagnosis[1] && (selectedConsultNote.invoice.diagnosis[1].length > 0)">
        <div class="col-3 form-label">{{ $t('general.secondaryDiagnosis')}}</div>
        <div class="col-9">
          <div class="diagnosisItem"
               v-for="item in selectedConsultNote.invoice.diagnosis[1]"
               :key="item.uuid">
            {{item.text}}
          </div>
        </div>
      </div>
      <div class="row icd-sub-item"
           v-if="selectedConsultNote.invoice.diagnosis && selectedConsultNote.invoice.diagnosis[2] && (selectedConsultNote.invoice.diagnosis[2].length > 0)">
        <div class="col-3 form-label">{{ $t('general.additionalDiagnosis')}}</div>
        <div class="col-9">
          <div class="diagnosisItem"
               v-for="item in selectedConsultNote.invoice.diagnosis[2]"
               :key="item.uuid">
            {{item.text}}
          </div>
        </div>
      </div>
    </div>
    <div class="item-title">{{$t('general.dispensedItem')}}</div>
    <div class="mb-4">
      <div class="row med-header">
        <div class="col text-uppercase">{{ $t('general.itemName')}}</div>
        <div class="col text-uppercase">{{$t('general.description')}}</div>
        <div class="col text-uppercase">{{$t('general.quantity')}}</div>
      </div>
      <div class="category-inventroy-wrap"
           v-for="(categroy, key) in inventoryByCategory"
           :key="key">
        <div class="category-name">
          {{ $t(categorizedNameMapTranslateId[key]) }}
        </div>
        <div class="row med-body"
             v-for="(item, index) in inventoryByCategory[key]"
             :key="index">
          <div class="col table-value">{{item.name}}</div>
          <div class="col table-value">{{item.description}}</div>
          <div class="col table-value">{{item.quantity}}</div>
        </div>
      </div>
    </div>
    <div class="mb-5 row med-footer">
      <div class="col-5 offset-7 form-label">
        <div class="footer-item">
          {{$t('general.doctorSignature')}}:
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import moneyFormatter from '@/components/mixins/money';

export default {
  name: 'ConsultNotePrint',
  mixins: [moneyFormatter],
  props: {
    generalConfig: {
      type: Object,
      default: () => ({}),
    },
    patientConfig: {
      type: Object,
      default: () => ({}),
    },
    selectedConsultNote: {
      type: Object,
      default: () => ({}),
    },
    preview: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => ([]),
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      inventoryByCategory: {},
      // Because the key of the sortedInventoryByType object above is directly displayed on the page, and not translate,
      // so add this categorizedNameMapTranslateId variable to map the key of the sortedInventoryByType -> translateId,
      // if you add another key in sortedInventoryByType, please add the translateId in categorizedNameMapTranslateId
      categorizedNameMapTranslateId: {
        'Professional Fees Consult': 'setting.inventoryType.professionalFeesConsult',
        'Professional Fees Procedures': 'setting.inventoryType.professionalFeesProcedures',
        Facilities: 'setting.inventoryType.facilities',
        Teleconsult: 'setting.inventoryType.teleconsult',
        Consultation: 'setting.inventoryType.consultation',
        Expendables: 'setting.inventoryType.expendables',
        Laboratory: 'setting.inventoryType.laboratory',
        Radiology: 'setting.inventoryType.radiology',
        Investigation: 'setting.inventoryType.investigation',
        Medicine: 'setting.inventoryType.medicine',
        Wyn: 'setting.inventoryType.wyn',
        Compounded: 'setting.inventoryType.compounded',
        Product: 'setting.inventoryType.product',
        Vaccination: 'setting.inventoryType.vaccination',
        Injection: 'setting.inventoryType.injection',
        Procedure: 'setting.inventoryType.procedure',
        'Nursing Procedure': 'setting.inventoryType.nursingProcedure',
        Consumables: 'setting.inventoryType.consumables',
        Package: 'setting.inventoryType.package',
        Bundle: 'setting.inventoryType.bundle',
        Referral: 'setting.inventoryType.referral',
        Credit: 'setting.inventoryType.credit',
        'Virtual Currency': 'setting.inventoryType.virtualCurrency',
        Misc: 'setting.inventoryType.misc',
      },

    };
  },
  computed: {
    companyClinicLogo() {
      if (this.generalConfig.useLocationAddress) {
        const location = this.clinics.find(location => location._id === this.selectedConsultNote.invoice.clinic);
        return location ? location.clinicLogo : '';
      }
      return this.generalConfig.logoURL;
    },
    splitAddress() {
      const address = this.selectedPatient.address.split(',');
      if (this.selectedPatient.unit) {
        address.splice(-1, 0, this.selectedPatient.unit);
      }
      return address;
    },
    logoMarginLeft() {
      if (localStorage.getItem('printSetup')) {
        return JSON.parse(localStorage.getItem('printSetup')).logoMarginLeft;
      }
      return 0;
    },
    logoWidth() {
      if (localStorage.getItem('printSetup')) {
        return JSON.parse(localStorage.getItem('printSetup')).logoWidth;
      }
      return 150;
    },
    itemTotalPrice() {
      const price = this.items.reduce((total, item) => total + Number(item.afterTaxPrice), 0);

      return price;
    },
  },
  filters: {
    formatDayTime(value) {
      if (!value) return;
      return moment(value).format('YYYY/MM/DD');
    },
    translateGender(val) {
      const map = { O: 'Other', M: 'Male', F: 'Female' };
      return map[val] || '';
    },
  },
  mounted() {
    if (!this.preview) {
      setTimeout(() => {
        window.print();
      }, 2000);
    }

    let queueDetails = this.$store.state.queue;
    if (queueDetails !== undefined) {
      let listQueue = queueDetails.all.items.filter(
        (item) =>
          item.patient._id == this.selectedConsultNote.patient._id &&
          this.selectedConsultNote.invoice.queue.call_number ==
            item.call_number &&
          this.selectedConsultNote.invoice.queue.queue_time == item.queue_time
      );
      if (listQueue.length > 0) {
        this.selectedConsultNote.patient.doctor = listQueue[0].provider;
      }
    }
  },
  created() {
    const vm = this;
    const { dispensedItems } = vm.selectedConsultNote;
    dispensedItems.forEach((item) => {
      if (item.inventory in vm.inventoryByCategory) {
        vm.inventoryByCategory[item.inventory].push(item);
      } else {
        vm.inventoryByCategory[item.inventory] = [item];
      }
    });
  },
};
</script>

<style scoped src="../../assets/print.css"></style>
<style lang="scss" scoped>
  .classified-medicine-tip {
    font-size: 20px;
    font-weight: bold;
    margin-top: 40px;
  }

  .clinic-name {
    margin-top: 10px;
  }
  .clinic-address {
    font-weight: bold;
    margin-top: 10px;
  }
  .form-label {
    font-size: 14px;
    font-weight: bold;
  }

  .form-value {
    font-size: 14px;
  }
  .item-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 4px;
  }
  .consult-tempalte-wrap {
    .consult-question {
      font-weight: bold;
      font-size: 18px;
      border-bottom: 1px solid #333;
    }
    .consult-answer {
      font-size: 16px;
      padding: 6px 0;
    }
  }
  .diagnosisItem {
    font-size: 16px;
  }
  .icd-wrap {
    padding: 10px 0;
    border-top: 2px solid #333;

    .icd-sub-item {
      padding: 6px 0;
      border-bottom: 1px solid #333;
      margin-left: 0;
      margin-right: 0;

      .form-label {
        padding-left: 0;
      }
    }
  }
  .med-header {
    font-weight: bold;
    padding: 10px 0;
    border-width: 2px 0 2px 0;
    border-style: solid;
    border-color: #333;
    margin-left: 0;
    margin-right: 0;
  }

  .category-inventroy-wrap {
    .category-name {
      font-size: 16px;
      font-weight: bold;
      padding: 4px 0 4px 15px;
    }


  }

  .med-body {
    padding: 10px 0;
    border-bottom: 1px solid #333;
    margin-left: 0;
    margin-right: 0;

    .table-value {
      font-size: 14px;
    }
  }

  .med-footer {
    display: flex;
    align-items: flex-end;

    .footer-item {
      padding: 16px 0;
    }
  }
</style>
