import membershipService from '@/services/membership.service';
import { SET_MEMBERSHIPS, SET_STATS, FETCH_MEMBERSHIP_TRX, SET_INVOICE_RESERVATION } from './type.mutation';

const state = {
  activations: {}, // map by patient id
  transactions: [],
  stats: {
    redeemablePoints: 0,
    lifetimePoints: 0,
    earnedPoints: 0,
    reservedPoints: 0,
  },
  reservationByInvoice: {},
};

/* eslint-disable no-param-reassign */
const mutations = {
  [SET_MEMBERSHIPS](_state, payload = {}) {
    _state.activations = payload;
  },
  [SET_STATS](_state, payload) {
    _state.stats = payload;
  },
  [FETCH_MEMBERSHIP_TRX](_state, payload) {
    _state.transactions = payload;
  },
  [SET_INVOICE_RESERVATION](_state, payload) {
    _state.reservationByInvoice = payload;
  },
};

const actions = {
  activateTier({ commit, dispatch }, payload) {
    return membershipService.activate(payload)
      .then((response) => {
        commit(SET_MEMBERSHIPS, response.data);
        dispatch('fetchPointsTrxStats', payload.patient);
        return response.data.data;
      });
  },
  activateProviderTier({ commit, dispatch }, payload) {
    return membershipService.activate(payload)
      .then((response) => {
        commit(SET_MEMBERSHIPS, response.data);
        dispatch('fetchPointsTrxProviderStats', payload.provider);
        return response.data.data;
      });
  },
  bulkActivateTier(context, payload) {
    return membershipService.bulkActivate(payload)
      .then(response => response.data.data);
  },
  fetchCurrentMembership({ commit }, payload) {
    return membershipService
      .getCurrentMembership({ patient: payload.patient, active: payload.active })
      .then((response) => {
        commit(SET_MEMBERSHIPS, response.data[0]);
        return response.data[0];
      });
  },
  fetchCurrentProviderMembership({ commit }, payload) {
    return membershipService
      .getCurrentMembership({ provider: payload.provider, active: payload.active })
      .then((response) => {
        commit(SET_MEMBERSHIPS, response.data[0]);
        return response.data[0];
      });
  },
  setActivateTier({ commit }, payload) {
    commit(SET_MEMBERSHIPS, payload);
  },
  fetchPointsTrxStats({ commit }, patient) {
    return membershipService
      .getMembershipStats(patient)
      .then((response) => {
        commit(SET_STATS, response.data);
        return response.data;
      });
  },
  fetchPointsTrxProviderStats({ commit }, provider) {
    return membershipService
      .getMembershipProviderStats(provider)
      .then((response) => {
        commit(SET_STATS, response.data);
        return response.data;
      });
  },
  fetchMembershipTransaction({ commit }, patient) {
    return membershipService
      .getMembershipTrx(patient)
      .then((response) => {
        commit(FETCH_MEMBERSHIP_TRX, response.data);
        return response.data;
      });
  },
  adjustUp({ dispatch }, payload) {
    return membershipService
      .adjustUp(payload)
      .then((response) => {
        dispatch('fetchMembershipTransaction', payload.patient);
        dispatch('fetchPointsTrxStats', payload.patient);
        return response.data;
      });
  },
  adjustDown({ dispatch }, payload) {
    return membershipService
      .adjustDown(payload)
      .then((response) => {
        dispatch('fetchMembershipTransaction', payload.patient);
        dispatch('fetchPointsTrxStats', payload.patient);
        return response.data;
      });
  },
  fetchReservationByInvoice({ commit }, patient) {
    return membershipService
      .getReservationByInvoice(patient)
      .then((response) => {
        commit(SET_INVOICE_RESERVATION, response.data);
        return response.data;
      });
  },
  fetchReservationByProviderInvoice({ commit }, provider) {
    return membershipService
      .getReservationByProviderInvoice(provider)
      .then((response) => {
        commit(SET_INVOICE_RESERVATION, response.data);
        return response.data;
      });
  },
  deactivateTier({ commit, dispatch }, patientId) {
    return membershipService.deactivate(patientId)
      .then((response) => {
        commit(SET_MEMBERSHIPS, response.data);
        dispatch('setActivateTier', {});
        return response.data;
      });
  },
  upgradeDowngrade({ commit, dispatch }, { patient, currentTier, nextTier }) {
    return membershipService.upgradeDowngrade(patient, currentTier, nextTier)
      .then((response) => {
        commit(SET_MEMBERSHIPS, response.data);
        dispatch('fetchCurrentMembership', { patient, active: true });
        dispatch('fetchPointsTrxStats', patient);
        dispatch('fetchMembershipTransaction', patient);
        return response.data;
      });
  },
  transferPoint({ dispatch }, payload) {
    return membershipService
      .transferPoint(payload)
      .then((response) => {
        dispatch('fetchMembershipTransaction', payload.fromPatient);
        dispatch('fetchPointsTrxStats', payload.fromPatient);
        return response.data;
      });
  },
};

const getters = {
  activations: _state => _state.activations,
  stats: _state => _state.stats,
  transactions: _state => _state.transactions,
  reservationByInvoice: _state => _state.reservationByInvoice,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
