/* eslint-disable no-param-reassign */
import gad7Service from '@/services/gad7.service';

import {
  ADD_RECORD_TIME,
  SET_RECORD_TIME,
  ADD_GENERALISED_ANXIETY_DISORDER,
  SET_GENERALISED_ANXIETY_DISORDER,
} from './type.mutation';

export const state = {
  recordedDateTime: [],
  generalisedAnxietyDisorders: {},
};

export const mutations = {
  [SET_RECORD_TIME](_state, dateTime) {
    _state.recordedDateTime = dateTime;
  },
  [ADD_RECORD_TIME](_state, dateTime) {
    _state.recordedDateTime.push(dateTime);
  },
  [ADD_GENERALISED_ANXIETY_DISORDER](_state, payload) {
    _state.generalisedAnxietyDisorders = { ..._state.generalisedAnxietyDisorders, ...payload };
  },
  [SET_GENERALISED_ANXIETY_DISORDER](_state, payload) {
    _state.generalisedAnxietyDisorders = payload;
  },
};

export const actions = {
  recordGAD7({ commit }, payload) {
    return gad7Service.create(payload)
      .then((response) => {
        commit(ADD_RECORD_TIME, payload.recordTime);
        commit(ADD_GENERALISED_ANXIETY_DISORDER, payload.generalisedAnxietyDisorders);
        return response.data;
      });
  },
  fetchGAD7({ commit }, params = {}) {
    return gad7Service.list(params)
      .then((response) => {
        commit(SET_RECORD_TIME, response.data.recordedDateTime);
        commit(SET_GENERALISED_ANXIETY_DISORDER, response.data.generalisedAnxietyDisorders);
        return response.data;
      });
  },
  page({ commit }, payload) {
    commit(SET_RECORD_TIME, payload);
  },
};

export const getters = {
  recordedDateTime: _state => _state.recordedDateTime,
  generalisedAnxietyDisordersData: _state => _state.generalisedAnxietyDisorders,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
