import api from './api';

export default {
  create(payload) {
    const url = '/health-screening-test-result';
    return api.post(url, payload);
  },
  get(id) {
    return api.get(`/health-screening-test-result/${id}`);
  },
};
