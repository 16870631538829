<template>
  <!-- eslint-disable max-len -->
  <div class="container-fluid config">
    <flash-message transitionName="fade" class="template-flash"></flash-message>
    <div class="col-12 mainbody">
      <div class="col-sm-10">
        <div class="row">
          <div class="col-6">
            <h3>WIPE CACHE</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <button class="btn btn-primary" @click="saveDetails">
              <v-icon name="save" class="tablehead__buttons__icons" />
              <span class="tablehead__buttons__label tablehead__buttons__save">
                {{ $t("general.submit") }}</span
              >
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Configuration',
  methods: {
    saveDetails() {
      localStorage.clear();
      sessionStorage.clear();
      document.cookie = `COOKIE_NAME=; Max-Age=0; path=/; domain=${location.host}`;
      this.flash('Cache cleared Successfully', 'success', { timeout: 3000 });
      setTimeout(() => {
        window.location = '';
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.mainbody {
  margin-top: 50px;
}
</style>
