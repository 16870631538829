<template>
    <div
        ref="textarea"
        v-html="value"
        :rows="rows || 2"
        max-rows="100"
        required
        no-resize
        v-on="listeners"
        :disabled="disabled"
        :contenteditable="!disabled"
        @keydown.enter.prevent="hahaha"
        @input="newEntry()"
        @focus="onFocus"
        @blur="saveNewValue($event)"
    ></div>
</template>

<script>
export default {
  name: 'TextEditor',
  props: {
    qns: {
      default: null,
      type: String,
    },
    value: {
      default: null,
      type: String,
    },
    rows: {
      default: null,
      type: Number,
    },
    updateTypingFormData: {
      default: null,
      type: Function,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    listeners() {
      return { ...this.$listeners, input: this.onInput };
    },
  },
  methods: {
    onInput(e) {
      this.$emit('input', this.$refs.textarea.innerHTML);
    },
    newEntry() {
      this.dataChanged = true; // maybe to enable the submit btn
    },
    saveNewValue(e) {
      console.log('in onBlur');
      // e.target.innerHTML
      console.log('e ', e);
      console.log('e.target ', e.target);
      console.log('e.target.value ', e.target.value);
      console.log('e.target.innerHTML ', e.target.innerHTML);
      this.updateTypingFormData(e.target.innerHTML, this.qns);
    },
    hahaha(e) {
      if (e.keyCode === 13) {
        // insert 2 br tags (if only one br tag is inserted the cursor won't go to the next line)
        document.execCommand('insertHTML', false, '<br/>');
        console.log("this.$refs['textarea'].innerHTML ", this.$refs.textarea.innerHTML);
        // prevent the default behaviour of return key pressed
        return false;
      }
    },
    onFocus(e) {
      console.log('in onFocus...e is ', e);
    },
  },
  mounted() {
    const elem = this.$refs.textarea.$el;
    console.log('elem got innerHTML? ', elem.innerHTML);
    console.log("this.$refs['textarea'].innerHTML ", this.$refs.textarea.innerHTML);
    this.$refs.textarea.innerHTML = this.value;
    elem.focus();
    elem.select();
    window.setTimeout(() => {
      elem.style.height = 'auto';
      elem.style.height = `${elem.scrollHeight}px`;
    }, 0);
  },
};
</script>
