var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "A4" }, [
    _vm._m(0),
    _c("div", { staticClass: "row my-4" }, [
      _c("div", { staticClass: "col-sm-4" }, [
        _vm.printConfig.supplierName || _vm.printConfig.supplierAddress
          ? _c("strong", [_vm._v("Supplier")])
          : _vm._e(),
        _vm.printConfig.supplierName
          ? _c("div", [_vm._v(_vm._s(_vm.print.supplierObj.name))])
          : _vm._e(),
        _vm.printConfig.supplierAddress &&
        _vm.print.supplierObj &&
        _vm.print.supplierObj.address
          ? _c("div", [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.print.supplierObj.address) +
                  "\n      "
              )
            ])
          : _vm._e()
      ]),
      _c("div", { staticClass: "col-sm-4" }, [
        _vm.printConfig.clinicName || _vm.printConfig.clinicAddress
          ? _c("strong", [_vm._v("Ship To")])
          : _vm._e(),
        _vm.printConfig.clinicName
          ? _c("div", [_vm._v(_vm._s(_vm.generalConfig.clinicName))])
          : _vm._e(),
        _vm.printConfig.clinicAddress
          ? _c("div", [_vm._v(_vm._s(_vm.shipToAddress))])
          : _vm._e()
      ]),
      _c("div", { staticClass: "col-sm-4 row" }, [
        _c(
          "div",
          { staticClass: "col-sm-6", staticStyle: { "text-align": "right" } },
          [
            _vm.printConfig.purchaseOrderNumber
              ? _c("strong", [_vm._v("PO No:")])
              : _vm._e(),
            _c("br"),
            _vm.printConfig.purchaseOrderCreatedDate
              ? _c("strong", [_vm._v("Date:")])
              : _vm._e()
          ]
        ),
        _c(
          "div",
          { staticClass: "col-sm-6", staticStyle: { "text-align": "left" } },
          [
            _vm.printConfig.purchaseOrderNumber
              ? _c("div", [_vm._v(_vm._s(_vm.print.docNumber))])
              : _vm._e(),
            _vm.printConfig.purchaseOrderCreatedDate
              ? _c("div", [
                  _vm._v(
                    _vm._s(
                      _vm._f("dateOnlyFormat_DDMMYYYY")(_vm.print.createdAt)
                    )
                  )
                ])
              : _vm._e()
          ]
        )
      ])
    ]),
    _c(
      "div",
      { staticClass: "row my-5" },
      [
        _c("vue-good-table", {
          staticClass: "col-sm-12",
          attrs: {
            columns: _vm.columns,
            rows: _vm.rows,
            "sort-options": { enabled: false }
          },
          scopedSlots: _vm._u([
            {
              key: "table-row",
              fn: function(props) {
                return [
                  props.column.field == "taxRate"
                    ? _c("span", [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.print.taxPercentageAmount ||
                                _vm.invoiceConfig.taxPercentageAmount
                            ) +
                            "%\n        "
                        )
                      ])
                    : props.column.field == "name"
                    ? _c("span", [
                        _c("span", [_vm._v(_vm._s(props.row.name))]),
                        props.row.isBonus
                          ? _c("i", { staticClass: "text-right" }, [
                              _c("br"),
                              _vm._v(
                                "- " +
                                  _vm._s(
                                    "BONUS " +
                                      props.row.bonusPurchasingUnit +
                                      " " +
                                      (props.row.purchasingUnit || "")
                                  )
                              )
                            ])
                          : _vm._e()
                      ])
                    : props.column.field == "amount"
                    ? _c("span", [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("thousandSeparator")(
                                props.row.amount,
                                _vm.region.thousandSeparator
                              )
                            )
                          )
                        ])
                      ])
                    : _vm._e()
                ]
              }
            }
          ])
        })
      ],
      1
    ),
    _c("div", { staticClass: "row my-4" }, [
      _c("div", { staticClass: "col-sm-6" }, [
        _vm.printConfig.notes && _vm.print.notes
          ? _c("span", [
              _c("strong", [_vm._v("Notes")]),
              _c("br"),
              _c("div", [_vm._v(_vm._s(_vm.print.notes))])
            ])
          : _vm._e()
      ]),
      _c("div", { staticClass: "col-sm-3" }),
      _c("div", { staticClass: "col-sm-3" }, [
        _vm.printConfig.subtotalTaxAndTotalAmount
          ? _c("span", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-6 text-left" }, [
                _c("strong", [_vm._v(_vm._s(_vm.$t("general.subtotal")))]),
                _c("br"),
                _c("strong", [
                  _vm._v(
                    _vm._s(_vm.$t("general.tax")) +
                      " (" +
                      _vm._s(
                        _vm.print.taxPercentageAmount ||
                          _vm.invoiceConfig.taxPercentageAmount
                      ) +
                      "%)"
                  )
                ]),
                _c("br"),
                _c("strong", [_vm._v(_vm._s(_vm.$t("general.total")))]),
                _c("br")
              ]),
              _c("div", { staticClass: "col-sm-6 text-right" }, [
                _c("div", [
                  _vm._v(
                    _vm._s(
                      _vm._f("thousandSeparator")(
                        _vm.subtotal,
                        _vm.region.thousandSeparator
                      )
                    )
                  )
                ]),
                _c("div", [
                  _vm._v(
                    _vm._s(
                      _vm._f("thousandSeparator")(
                        _vm.tax,
                        _vm.region.thousandSeparator
                      )
                    )
                  )
                ]),
                _c("div", [
                  _vm._v(
                    _vm._s(_vm.region.currency) +
                      " " +
                      _vm._s(
                        _vm._f("thousandSeparator")(
                          _vm.total,
                          _vm.region.thousandSeparator
                        )
                      )
                  )
                ])
              ])
            ])
          : _vm._e()
      ])
    ]),
    _vm._m(1)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row my-4" }, [
      _c("h2", { staticClass: "col-sm-12" }, [_vm._v("Purchase Order")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row mt-5" }, [
      _c("span", { staticClass: "col-sm-7" }),
      _c(
        "span",
        { staticClass: "col-sm-5 text-right d-flex justify-content-between" },
        [
          _c("div", [_vm._v("Approved By:")]),
          _c("div", [_vm._v("________________________________")])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }