<template>
  <div>
    <printable-herbal-medicine-order />
  </div>
</template>
<script>
import PrintableHerbalMedicineOrder from '@/components/TCMConsultation/Print/PrintableHerbalMedicineOrder.vue';

export default {
  name: 'TcmPrintableHerbalMedicineOrder',
  components: {
    PrintableHerbalMedicineOrder,
  },
};
</script>
