/* eslint-disable */
import OrderService from '@/services/order.service';

const SET_ORDER = 'SET_ORDER';

const state = {
  selectedOrder: {},
  formValid: false,
  filter: {},
};

/* eslint-disable no-param-reassign */
const mutations = {
  [SET_ORDER](_state, payload) {
    _state.selectedOrder = payload;
  },
};

const actions = {
  setSelectedOrder({ commit }, payload) {
    commit(SET_ORDER, payload);
  },
  // eslint-disable-next-line no-shadow
  createNewOrder({ commit }, newOrder) {
    return OrderService.createNew(newOrder)
      .then((response) => {
        if (response.ok) {
          commit(SET_ORDER, response.data);
        }
        return response;
      });
  },
  createNewOrderWithImage({ commit }, { order, image }) {
    return OrderService.createNewWithImage(order, image)
      .then((response) => {
        if (response.ok) {
          commit(SET_ORDER, response.data);
        }
        return response;
      });
  },
  // eslint-disable-next-line no-shadow
  updateOrder({ commit }, updateOrder) {
    return OrderService.updateOrder(updateOrder._id, updateOrder)
      .then((response) => {
        if (response.ok) {
          commit(SET_ORDER, response.data);
        }
        return response;
      });
  },
  updateOrderWithImage({ commit }, { order, image }) {
    return OrderService.updateOrderWithImage(order._id, order, image)
      .then((response) => {
        if (response.ok) {
          commit(SET_ORDER, response.data);
        }
        return response;
      });
  },
  bulkUpdateOrder({ commit }, data) {
    return OrderService.updateBulkOrder(data)
      .then(response => response);
  },
  bulkDeleteOrder({ commit }, data) {
    return OrderService.deleteBulk(data)
      .then(response => response);
  },
  deleteOrder({ commit }, id) {
    return OrderService.delete(id)
      .then((response) => {
        if (response.ok) {
          commit(SET_ORDER, {});
        }
        return response;
      });
  },
  getOrder({ commit }, id) {
    return OrderService.getOrder(id)
      .then((response) => {
        if (response.ok) {
          commit(SET_ORDER, response.data);
        }
        return response;
      });
  },
};

const getters = {
  selectedOrder: _state => _state.selectedOrder,
  formValid: _state => _state.formValid,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
