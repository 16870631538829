<template>
    <div style="position: relative;">
        <div :id="IPDContainerName + 'canvas'" v-if="IPDContainerName">
        </div>
        <div v-else id="canvas">
        </div>
    </div>
</template>
<script>
import Dateformatter from '@/components/mixins/dateformatter';
import {
  mapState, mapGetters, mapMutations,
} from 'vuex';
import computedMixins from '@/components/mixins/computed';
import invoiceMethodsMixin from '@/components/mixins/invoice';
import _ from 'lodash';
import Konva from 'konva/cmj';
import Tribute from 'tributejs';
import 'tributejs/dist/tribute.css';

export default {
  name: 'CanvasEditor',
  props: [
    'type',
    'originalOrientation',
    'mode',
    'overlayClick',
    'switchMode',
    'toggleTypingMode',
    'strokeColor',
    'strokeWidth',
    'textSize',
    'textColor',
    'setActiveText',
    'openSessions',
    'IPDContainerName',
    'disableEvents',
  ],
  data() {
    return {
      note: {},
      pointerID: null,
      background_image: null,

      currPage: {},

      currentStroke: { points: [] },
      finalHeight: null,
      finalWidth: null,

      orientation: 'Portrait',
      redoArray: [],

      stage: {},
      layer: {},
      image: null,
      loadedImage: null,
      isPortrait: true,
      finalDimensions: {},
      ratio: 1,
      height: 0,
      width: 0,
      x: 0,
      y: 0,
      strokes_layer: null,
      canvas_image: null,
      canvas: null,
      context: null,
      docId: null,
      doc: null,
      rotation: null,
      scale: null,
      rightBoundary: null,
      bottomBoundary: null,
      leftBoundary: null,
      topBoundary: null,
      verticalGuides: [],
      horizontalGuides: [],
      tribute: null,
      activeText: null,
      isHovering: false,
      nodeBeforeDragged: {},
    };
  },
  components: {},
  mixins: [Dateformatter, computedMixins, invoiceMethodsMixin],
  computed: {
    ...mapState({
      editor(state) {
        if (this.IPDContainerName) {
          return state.note[this.IPDContainerName].editor;
        }
        return state[this.type].editor;
      },
      documentsMappedById(state) {
        let mappedDocumentsVariableName = '';
        if (this.type === 'notetemplate') {
          mappedDocumentsVariableName = 'notetemplatesMappedById';
        } else if (this.type === 'note') {
          mappedDocumentsVariableName = 'notesMapped';
        }
        return state[this.type][mappedDocumentsVariableName];
      },
    }),
    ...mapGetters('config', ['featureFlags']),
  },
  methods: {
    async nextPage(pageId) {
      this.doc = _.cloneDeep(this.editor.pages[pageId]);
      if (this.doc._id && !this.doc.signedOriginalImageKey) {
        const res = await this.$store.dispatch(`${this.type}/getLatestImage`, { id: this.doc._id, keys: ['signedOriginalImageKey'] });
        this.doc.signedOriginalImageKey = this.documentsMappedById[pageId].signedOriginalImageKey;
      }
      const textNodes = this.strokes_layer.find('.text');
      if (textNodes) {
        textNodes.map(i => i.destroy());
      }
      this.setDimensions();
      this.switchDisplayOrientation();
      this.loadedImage.src = this.doc.tempBase64 || this.doc.signedOriginalImageKey;
    },
    refreshCanvasEditor() {
      const textNodes = this.strokes_layer.findOne('.text');
      if (textNodes) {
        textNodes.destroy();
      }
      this.doc = _.cloneDeep(this.editor.pages[this.editor.currentPageId]);
      this.setDimensions();
      this.switchDisplayOrientation();
      this.loadedImage.src = this.doc.signedOriginalImageKey;
    },
    orientationChanged() {
      const timeout = 120;
      return new window.Promise(((resolve) => {
        const go = (i, height0) => {
          window.innerHeight != height0 || i >= timeout
            ? resolve()
            : window.requestAnimationFrame(() => go(i + 1, height0));
        };
        go(0, window.innerHeight);
      }));
    },
    switchDisplayOrientation(redraw) {
      this.stage.height(this.finalDimensions.finalHeight);
      this.stage.width(this.finalDimensions.finalWidth);

      this.setFittedBackgroundImageDimensions(this.ratio);

      this.image.height(this.height);
      this.image.width(this.width);
      this.image.x(this.x);
      this.image.y(this.y);

      this.canvas.height = this.height;
      this.canvas.width = this.width;
      this.canvas_image.x(this.x);
      this.canvas_image.y(this.y);

      this.context.strokeStyle = this.strokeColor;
      this.context.lineJoin = 'round';
      this.context.lineCap = 'round';
      this.context.lineWidth = this.strokeWidth[this.mode] || 2;

      this.stage.draw(); // original is this one

      if (redraw && this.doc.strokesIdOrder && this.doc.strokesIdOrder.length > 0) {
        this.redrawAll();
      }
    },
    setDimensions(fakePortrait) {
      let node3;
      if (this.IPDContainerName) {
        node3 = document.getElementById(`${this.IPDContainerName}_size`);
      } else {
        node3 = document.getElementById('editing-canvas');
      }
      if (this.featureFlags.scrollNoteEditor) {
        const maxHeight = Math.max(node3.offsetHeight * 0.9, 1400);
        this.finalDimensions.finalHeight = maxHeight;
        this.finalDimensions.finalWidth = node3.offsetWidth * 0.8;
        return;
      }

      if (fakePortrait) {
        this.finalDimensions.finalHeight = node3.offsetHeight * 0.9;
        this.finalDimensions.finalWidth = this.finalDimensions.finalHeight * 0.9;
      } else {
        this.finalDimensions.finalHeight = node3.offsetHeight * 0.9;
        this.finalDimensions.finalWidth = node3.offsetWidth * 0.8;
      }

      if (this.finalDimensions.finalWidth > this.finalDimensions.finalHeight) {
        this.isPortrait = false; // landscape
      } else {
        this.isPortrait = true; // portrait
      }
    },
    updateTextColor(color) {
      if (this.activeText) {
        const targetId = this.activeText.id();
        const oldTextColor = this.doc.strokeStore[targetId].fill;
        this.activeText.fill(color);
        this.strokes_layer.draw();
        // S 08 HY
        const strokeAdjustment = {
          id: this.shortUuid(),
          targetId,
          type: 'textColorUpdate',
          from: {
            fill: oldTextColor,
          },
          to: {
            fill: color,
          },
        };
        if (!this.openSessions) {
          this.updateDoc({ key: 'strokeStore', value: strokeAdjustment });
          this.doc = _.cloneDeep(this.editor.pages[this.editor.currentPageId]);
          this.addHistory({ pageId: this.doc._id || this.doc.shortUuid, stroke: strokeAdjustment });
        }
      }
    },
    setupStage() {
      this.stage = new Konva.Stage({
        container: this.IPDContainerName ? `${this.IPDContainerName}canvas` : 'canvas',
        height: this.finalDimensions.finalHeight,
        width: this.finalDimensions.finalWidth,
      });
      this.layer = new Konva.Layer();
      this.stage.add(this.layer);
    },
    setDraggable(flag) {
      if (!this.strokes_layer) {
        return;
      }
      const findText = this.strokes_layer.find('.text');
      for (const i in findText) {
        findText[i].draggable(flag);
      }
    },
    setFittedBackgroundImageDimensions(ratio) { // ratio is loaded image original height / original width
      if (this.isPortrait) { // screen's height > width,
        this.height = this.finalDimensions.finalHeight; // so we fit loaded image's height to screen's height first
        this.width = this.height / ratio;
        if (this.width > this.finalDimensions.finalWidth) { // IF fitted bg image width is STILL > than screen's width...
          this.width = this.finalDimensions.finalWidth;
          this.height = this.width * ratio;
        }
      } else { // screen's width > height,
        this.width = this.finalDimensions.finalWidth; // so we fit loaded image's width to screen's width first
        this.height = this.width * ratio;
        if (this.height > this.finalDimensions.finalHeight) {
          this.height = this.finalDimensions.finalHeight;
          this.width = this.height / ratio;
        }
      }
      this.x = (this.finalDimensions.finalWidth - this.width) / 2;
      this.y = (this.finalDimensions.finalHeight - this.height) / 2;

      if (this.canvas && this.canvas_image) {
        this.canvas.height = this.height;
        this.canvas.width = this.width;
        this.canvas_image.x(this.x);
        this.canvas_image.y(this.y);
        this.stage.draw();
      }
      this.rightBoundary = this.x + this.width;
      this.leftBoundary = this.x;
      this.bottomBoundary = this.y + this.height;
      this.topBoundary = this.y;
    },
    setupBackgroundImage() {
      const self = this;
      self.loadedImage = new Image();
      self.image = new Konva.Image({
        image: self.loadedImage,
      });
      self.loadedImage.addEventListener('error', (e) => {
        console.log('error in loading image!', e);
      });
      self.loadedImage.addEventListener('load', () => {
        self.ratio = self.loadedImage.height / self.loadedImage.width;

        self.setFittedBackgroundImageDimensions(self.ratio);

        // get guides
        self.image.height(self.height);
        self.image.width(self.width);
        self.image.x(self.x);
        self.image.y(self.y);

        if (!self.strokes_layer) {
          const whiteBackground = new Konva.Rect({
            x: self.x,
            y: self.y,
            width: self.width,
            height: self.height,
            fill: 'white',
          });
          whiteBackground.shadowForStrokeEnabled(false);
          // add the shape to the layer
          self.layer.add(whiteBackground);
        }
        self.layer.add(self.image);
        self.layer.draw();

        if (self.strokes_layer) {
          self.context.strokeStyle = self.strokeColor; // take from line.strokeStyle
          self.context.lineJoin = 'round';
          self.context.lineCap = 'round';
          self.context.lineWidth = self.strokeWidth[self.mode] || 2;
          if (self.doc.strokesIdOrder && self.doc.strokesIdOrder.length > 0) {
            self.redrawAll(); // with the new height, width, x, y
          }
        } else { // first time
          self.strokes_layer = new Konva.Layer();
          self.stage.add(self.strokes_layer);

          self.stage.on('click tap', (e) => {
            if (self.disableEvents) return;
            if (!e.target.hasName('text')) {
              self.setActiveText(null);
              self.activeText = null;
              const findTran = self.stage.find('.transformer');
              for (const i in findTran) {
                findTran[i].hide();
              }
              self.strokes_layer.draw();
            }
          });

          self.canvas = document.createElement('canvas');
          self.canvas.height = self.height;
          self.canvas.width = self.width;
          self.canvas_image = new Konva.Image({
            image: self.canvas,
            x: self.x,
            y: self.y,
            stroke: 'purple',
          });
          self.canvas_image.shadowForStrokeEnabled(false);

          self.strokes_layer.add(self.canvas_image);
          self.canvas_image.on('mouseout', (e) => {
            self._drawing = false;
          });
          self.strokes_layer.on('dragmove', (e) => {
            if (self.disableEvents) return;
            // clear all previous lines on the screen
            const findGuide = self.strokes_layer.find('.guide-line');
            for (const i in findGuide) {
              findGuide[i].destroy();
            }
            // find possible snapping lines
            const lineGuideStops = self.getLineGuideStops(e.target);
            // find snapping points of current object
            const itemBounds = self.getObjectSnappingEdges(e.target);

            // now find where can we snap current object
            const guides = self.getGuides(lineGuideStops, itemBounds);
            // console.log('guides are ', guides);
            // do nothing of no snapping
            if (!guides.length) {
              return;
            }
            self.drawGuides(guides);

            // now force object position
            guides.forEach((lg) => {
              switch (lg.snap) {
                case 'start': {
                  switch (lg.orientation) {
                    case 'V': {
                      e.target.x(lg.lineGuide + lg.offset);
                      break;
                    }
                    case 'H': {
                      e.target.y(lg.lineGuide + lg.offset);
                      break;
                    }
                  }
                  break;
                }
                case 'center': {
                  switch (lg.orientation) {
                    case 'V': {
                      e.target.x(lg.lineGuide + lg.offset);
                      break;
                    }
                    case 'H': {
                      e.target.y(lg.lineGuide + lg.offset);
                      break;
                    }
                  }
                  break;
                }
                case 'end': {
                  switch (lg.orientation) {
                    case 'V': {
                      e.target.x(lg.lineGuide + lg.offset);
                      break;
                    }
                    case 'H': {
                      e.target.y(lg.lineGuide + lg.offset);
                      break;
                    }
                  }
                  break;
                }
              }
            });
          });

          self.strokes_layer.on('dragstart', (e) => {
            if (self.disableEvents) return;

            if (e.target.hasName('text')) {
              const id = e.target.id();
              const stroke = _.cloneDeep(self.doc.strokeStore[id]);
              self.nodeBeforeDragged = stroke;
            }
          });

          self.strokes_layer.on('dragend', (e) => {
            if (self.disableEvents) return;

            // update
            if (e.target.hasName('text')) {
              const targetId = e.target.id();
              const x_percent = (e.target.x() - self.x) / self.width * 100;
              const y_percent = (e.target.y() - self.y) / self.height * 100;
              const strokeAdjustment = {
                id: self.shortUuid(),
                targetId,
                type: 'movement',
                from: {
                  x: self.nodeBeforeDragged.x,
                  y: self.nodeBeforeDragged.y,
                },
                to: {
                  x: x_percent,
                  y: y_percent,
                },
              };
              if (!this.openSessions) {
                self.updateDoc({ key: 'strokeStore', value: strokeAdjustment });

                self.doc = _.cloneDeep(self.editor.pages[self.editor.currentPageId]);
                self.addHistory({ pageId: self.doc._id || self.doc.shortUuid, stroke: strokeAdjustment });
              }
            }
            const finalX = e.target.x();
            const finalY = e.target.y();
            const nodeWidth = e.target.width();
            const nodeHeight = e.target.height();

            if (finalX < self.x) {
              e.target.x(self.x);
            } else if (finalX + nodeWidth > self.x + self.width) {
              e.target.x(self.x + self.width - nodeWidth);
            }

            if (finalY < self.y) {
              e.target.y(self.y);
            } else if (finalY + nodeHeight > self.y + self.height) {
              e.target.y(self.y + self.height - nodeHeight);
            }
            const findGuides = self.strokes_layer.find('.guide-line');
            for (const i in findGuides) {
              findGuides[i].destroy();
            }
            self.strokes_layer.batchDraw();
          });
          self.strokes_layer.draw();

          self.context = self.canvas.getContext('2d');
          self.context.strokeStyle = self.strokeColor; // take from line.strokeStyle
          self.context.lineJoin = 'round';
          self.context.lineCap = 'round';
          self.context.lineWidth = self.strokeWidth[self.mode] || 2;

          self._drawing = false;

          self.canvas_image.on('mousedown touchstart', ({ pointerId }) => {
            if (self.pointerID) {
              return;
            }
            self.pointerID = pointerId;

            if (self.disableEvents) return;
            self.overlayClick();
            self.lastPointerPosition = self.stage.getPointerPosition();
            if (self.mode === 'text') {
              self.addTextNode(self.lastPointerPosition);
              self.switchMode('pointer');
            } else if (self.mode === 'code') {
              self.addTextNode(self.lastPointerPosition);
            } else if (self.mode === 'textarea') {

            } else if (self.mode === 'table') {
              self.currPage.htmlNodes.push({
                y: self.lastPointerPosition.y,
                x: self.lastPointerPosition.x,
              });
              self.mode = 'pencil';
            } else { // if pencil or eraser
              if (self.mode === 'pencil' || self.mode === 'eraser') {
                self.mode === 'pencil' ? self.context.globalCompositeOperation = 'source-over' : self.context.globalCompositeOperation = 'destination-out';
              }
              self._drawing = true;
            }
          });


          self.canvas_image.on('mousemove touchmove', ({ pointerId }) => {
            if (pointerId !== self.pointerID) {
              return;
            }

            if (self.disableEvents) return;
            if (!self._drawing) {
              return;
            }
            // console.log('mousemoving!');
            if (self.mode === 'pencil' || self.mode === 'eraser') {
              self.context.beginPath();
              const lastPos = {
                x: self.lastPointerPosition.x - self.x,
                y: self.lastPointerPosition.y - self.y,
              };
              self.currentStroke.globalCompositeOperation = self.context.globalCompositeOperation;

              const x_percent = parseFloat((lastPos.x / (self.width) * 100).toFixed(3));
              const y_percent = parseFloat((lastPos.y / (self.height) * 100).toFixed(3));
              const { length } = self.currentStroke.points;

              self.currentStroke.points.push(x_percent, y_percent);

              const pos = self.stage.getPointerPosition();

              const currPos = {
                x: pos.x - self.x,
                y: pos.y - self.y,
              };

              self.context.strokeStyle = self.strokeColor || 'black';
              self.context.lineWidth = self.strokeWidth[self.mode] || 2;
              self.context.moveTo(lastPos.x, lastPos.y); // was not here
              self.context.lineTo(currPos.x, currPos.y);

              self.context.closePath(); // originally before .stroke()
              self.context.stroke();

              self.lastPointerPosition = pos;
              self.strokes_layer.batchDraw();
            }
          });

          self.canvas_image.on('mouseup touchend', () => {
            if (self.disableEvents) return;

            if (self.currentStroke.points.length !== 0) {
              self.currentStroke.strokeColor = self.strokeColor;
              self.currentStroke.strokeWidthRatio = (self.strokeWidth[self.mode] || 2) / self.width * 100;
              self.currentStroke.type = 'line';
              self.currentStroke.id = self.shortUuid();

              // S 01 HY
              if (!this.openSessions) {
                self.updateDoc({ key: 'strokeStore', value: self.currentStroke });
                self.addHistory({ pageId: self.doc._id || self.doc.shortUuid, stroke: self.currentStroke });

                const updatedDoc = _.cloneDeep(self.editor.pages[self.doc._id || self.doc.shortUuid]);
                self.doc.strokeStore = updatedDoc.strokeStore;
                self.doc.strokesIdOrder = updatedDoc.strokesIdOrder;
              }

              self.currentStroke = { points: [] };
            }
            self._drawing = false;
            self.pointerID = null;
          });

          if (self.doc.strokesIdOrder && self.doc.strokesIdOrder.length > 0) {
            self.redrawAll();
          }
        }
      });

      self.loadedImage.src = self.doc.tempBase64 || self.doc.signedOriginalImageKey;
      self.layer.draw();
    },

    shortUuid() {
      // I generate the UID from two parts here
      // to ensure the random number provide enough bits.
      let firstPart = (Math.random() * 46656) | 0;
      let secondPart = (Math.random() * 46656) | 0;
      firstPart = (`000${firstPart.toString(36)}`).slice(-3);
      secondPart = (`000${secondPart.toString(36)}`).slice(-3);
      return firstPart + secondPart;
    },
    // were can we snap our objects?
    getLineGuideStops(skipShape) {
      // we can snap to stage borders and the center of the stage
      this.vertical = [this.x, this.stage.width() / 2, this.x + this.width];
      this.horizontal = [0, this.stage.height() / 2, this.stage.height()];

      // and we snap over edges and center of each object on the canvas
      this.stage.find('.text').forEach((guideItem) => {
        if (guideItem === skipShape) {
          return;
        }
        const box = guideItem.getClientRect();
        // and we can snap to all edges of shapes
        this.vertical.push([box.x, box.x + box.width, box.x + box.width / 2]);
        this.horizontal.push([box.y, box.y + box.height, box.y + box.height / 2]);
      });
      return {
        vertical: this.vertical.flat(),
        horizontal: this.horizontal.flat(),
      };
    },
    // what points of the object will trigger to snapping?
    // it can be just center of the object
    // but we will enable all edges and center
    getObjectSnappingEdges(node) {
      const box = node.getClientRect();
      return {
        vertical: [
          {
            guide: Math.round(box.x),
            offset: Math.round(node.x() - box.x),
            snap: 'start',
          },
          {
            guide: Math.round(box.x + box.width / 2),
            offset: Math.round(node.x() - box.x - box.width / 2),
            snap: 'center',
          },
          {
            guide: Math.round(box.x + box.width),
            offset: Math.round(node.x() - box.x - box.width),
            snap: 'end',
          },
        ],
        horizontal: [
          {
            guide: Math.round(box.y),
            offset: Math.round(node.y() - box.y),
            snap: 'start',
          },
          {
            guide: Math.round(box.y + box.height / 2),
            offset: Math.round(node.y() - box.y - box.height / 2),
            snap: 'center',
          },
          {
            guide: Math.round(box.y + box.height),
            offset: Math.round(node.y() - box.y - box.height),
            snap: 'end',
          },
        ],
      };
    },
    // find all snapping possibilities
    getGuides(lineGuideStops, itemBounds) {
      const resultV = [];
      const resultH = [];
      const GUIDELINE_OFFSET = 5;

      lineGuideStops.vertical.forEach((lineGuide) => {
        itemBounds.vertical.forEach((itemBound) => {
          const diff = Math.abs(lineGuide - itemBound.guide);
          // if the distance between guild line and object snap point is close we can consider this for snapping
          if (diff < GUIDELINE_OFFSET) {
            resultV.push({
              lineGuide,
              diff,
              snap: itemBound.snap,
              offset: itemBound.offset,
            });
          }
        });
      });

      lineGuideStops.horizontal.forEach((lineGuide) => {
        itemBounds.horizontal.forEach((itemBound) => {
          const diff = Math.abs(lineGuide - itemBound.guide);
          if (diff < GUIDELINE_OFFSET) {
            resultH.push({
              lineGuide,
              diff,
              snap: itemBound.snap,
              offset: itemBound.offset,
            });
          }
        });
      });

      const guides = [];

      // find closest snap
      const minV = resultV.sort((a, b) => a.diff - b.diff)[0];
      const minH = resultH.sort((a, b) => a.diff - b.diff)[0];
      if (minV) {
        guides.push({
          lineGuide: minV.lineGuide,
          offset: minV.offset,
          orientation: 'V',
          snap: minV.snap,
        });
      }
      if (minH) {
        guides.push({
          lineGuide: minH.lineGuide,
          offset: minH.offset,
          orientation: 'H',
          snap: minH.snap,
        });
      }
      return guides;
    },
    drawGuides(guides) {
      guides.forEach((lg) => {
        if (lg.orientation === 'H') {
          var line = new Konva.Line({
            points: [-6000, lg.lineGuide, 6000, lg.lineGuide],
            stroke: 'rgb(0, 72, 255)',
            strokeWidth: 3,
            name: 'guide-line',
            dash: [4, 6],
          });
          line.shadowForStrokeEnabled(false);
          this.strokes_layer.add(line);
          this.strokes_layer.batchDraw();
        } else if (lg.orientation === 'V') { // vertical
          var line = new Konva.Line({
            points: [lg.lineGuide, -1000, lg.lineGuide, 6000],
            stroke: 'rgb(0, 72, 255)',
            strokeWidth: 3,
            name: 'guide-line',
            dash: [4, 6],
          });
          line.shadowForStrokeEnabled(false);
          this.strokes_layer.add(line);
          this.strokes_layer.batchDraw();
        }
      });
    },
    undo() {
      this.undoHistory();
      this.doc = _.cloneDeep(this.editor.pages[this.editor.currentPageId]);
      this.redrawAll();
    },
    redo() {
      this.redoHistory();
      this.doc = _.cloneDeep(this.editor.pages[this.editor.currentPageId]);
      this.redrawAll();
    },
    reOrientate() {
      const self = this;
      self.orientationChanged().then(() => {
        self.setDimensions();
        self.switchDisplayOrientation(true);
      });
    },
    goToSession(session) {
      const textNodes = this.strokes_layer.findOne('.text');
      if (textNodes) {
        textNodes.destroy();
      }
      this.redrawAll(session);
    },
    redrawAll(session) {
      this.context.clearRect(0, 0, this.stage.width(), this.stage.height());
      const textNodes = this.strokes_layer.findOne('.text');
      if (textNodes) {
        textNodes.destroy();
      }

      const transformerNodes = this.strokes_layer.findOne('.transformer');
      if (transformerNodes) {
        transformerNodes.destroy();
      }

      let { strokesIdOrder } = this.doc;
      if (session) {
        strokesIdOrder = this.doc.strokesIdOrder.slice(0, session[1]);
      }

      for (const id of strokesIdOrder) {
        const stroke = this.doc.strokeStore[id];
        if (stroke.type === 'line') {
          if (stroke.points.length < 2) { // each stroke's points will have AT LEAST .length === 2 (one x and one y)
            return;
          }
          this.context.globalCompositeOperation = stroke.globalCompositeOperation || 'source-over';
          this.context.strokeStyle = stroke.strokeColor || this.strokeColor;
          this.context.lineJoin = 'round';
          this.context.lineCap = 'round';
          if (stroke.strokeWidthRatio) {
            this.context.lineWidth = (stroke.strokeWidthRatio / 100 * this.width) || 2;
          } else {
            this.context.lineWidth = 2;
          }

          this.context.beginPath();

          this.context.moveTo((stroke.points[0] / 100 * (this.width)), (stroke.points[1] / 100 * (this.height)));
          for (let i = 2; i < stroke.points.length; i += 2) {
            const x = stroke.points[i];
            const y = stroke.points[i + 1];
            this.context.lineTo((x / 100 * (this.width)), (y / 100 * (this.height)));
          }
          this.context.stroke();
        } else if (stroke.type === 'text') {
          const clone = Object.assign({}, stroke);
          clone.x = (clone.x / 100 * this.width) + this.x;
          clone.y = (clone.y / 100 * this.height) + this.y;
          clone.width = clone.width / 100 * this.width;
          clone.fontSize = clone.fontSizeRatio / 100 * this.width;
          delete clone.fontSizeRatio;

          this.addTextNode(clone);
        } else if (stroke.type === 'movement') {
          const textNode = this.strokes_layer.findOne(`#${stroke.targetId}`);
          textNode.x((stroke.to.x / 100 * this.width) + this.x);
          textNode.y((stroke.to.y / 100 * this.height) + this.y);
        } else if (stroke.type === 'deletion') {
          const textNode = this.strokes_layer.findOne(`#${stroke.targetId}`);
          textNode.destroy();
        } else if (stroke.type === 'textSizeUpdate') {
          const textNode = this.strokes_layer.findOne(`#${stroke.targetId}`);
          textNode.fontSize(stroke.to.fontSizeRatio / 100 * this.width);
        } else if (stroke.type === 'textColorUpdate') {
          const textNode = this.strokes_layer.findOne(`#${stroke.targetId}`);
          textNode.fill(stroke.to.fill);
        } else if (stroke.type === 'textWidthUpdate') {
          const textNode = this.strokes_layer.findOne(`#${stroke.targetId}`);
          textNode.width(stroke.to.width / 100 * this.width);
          textNode.x((stroke.to.x / 100 * this.width) + this.x);
          textNode.y((stroke.to.y / 100 * this.height) + this.y);
        } else if (stroke.type === 'textValueUpdate') {
          const textNode = this.strokes_layer.findOne(`#${stroke.targetId}`);
          textNode.text(stroke.to.text);
          textNode.width(stroke.to.width / 100 * this.width);
          textNode.x((stroke.to.x / 100 * this.width) + this.x);
          textNode.y((stroke.to.y / 100 * this.height) + this.y);
        }
      }
      this.strokes_layer.batchDraw();
    },
    toggleTransformers(state) {
      if (!state) {
        const stageTrans = this.stage.find('.transformer');
        for (const i in stageTrans) {
          stageTrans[i].hide();
        }
        this.stage.draw();
      }
    },
    deleteTextNode() {
      const textNode = this.activeText;
      if (textNode === null) return;

      const textNodeId = textNode.id();
      const textTrans = this.stage.find(`#transformer${textNodeId}`);
      for (const i in textTrans) {
        textTrans[i].hide();
      }
      const strokeToBeDeleted = this.doc.strokeStore[textNodeId];
      this.stage.draw();
      textNode.destroy();
      this.stage.draw();
      this.setActiveText(null);
      // S 02 HY
      const strokeAdjustment = {
        id: this.shortUuid(),
        targetId: textNodeId,
        type: 'deletion',
      };
      if (!this.openSessions) {
        this.updateDoc({ key: 'strokeStore', value: strokeAdjustment });
        this.addHistory({ pageId: this.doc._id || this.doc.shortUuid, stroke: strokeAdjustment });
        this.doc = _.cloneDeep(this.editor.pages[this.editor.currentPageId]);
      }
    },
    addTextNode(data) {
      const self = this;
      let textNode;
      let maxWidth;
      // different mode have different default text
      const modeMapText = {
        text: 'text',
        code: '',
      };
      if (data.id) { // existing text
        const dataLen = this.stage.find(`#${data.id}`).find(x => x.length > 0);
        if (dataLen) return;
        if (self.mode === 'pointer') data.draggable = true;
        textNode = new Konva.Text(data);
        textNode.shadowForStrokeEnabled(false);
        maxWidth = self.rightBoundary - textNode.x();
      } else {
        const shortUuid = self.shortUuid();
        const { x } = data;
        const { y } = data;
        const width = self.width - (x - self.x);
        const newTextNodeData = {
          text: modeMapText[self.mode],
          x,
          y,
          fontSize: self.textSize,
          fill: self.textColor,
          draggable: self.mode === 'pointer',
          name: 'text',
          id: shortUuid,
        };
        textNode = new Konva.Text(newTextNodeData);
        textNode.shadowForStrokeEnabled(false);
        maxWidth = self.rightBoundary - x;
        const newTextNodeDataPercentage = Object.assign({}, newTextNodeData);
        newTextNodeDataPercentage.x = (newTextNodeDataPercentage.x - self.x) / self.width * 100;
        newTextNodeDataPercentage.y = (newTextNodeDataPercentage.y - self.y) / self.height * 100;
        newTextNodeDataPercentage.width = textNode.width() / self.width * 100;
        newTextNodeDataPercentage.fontSizeRatio = newTextNodeDataPercentage.fontSize / self.width * 100;
        newTextNodeDataPercentage.type = 'text';
        if (!this.openSessions) {
          // S 16 HY
          self.updateDoc({ key: 'strokeStore', value: newTextNodeDataPercentage });
          self.addHistory({ pageId: self.doc._id || self.doc.shortUuid, stroke: newTextNodeDataPercentage });
        }
        const updatedDoc = _.cloneDeep(self.editor.pages[self.doc._id || self.doc.shortUuid]);
        self.doc.strokeStore = updatedDoc.strokeStore;
        self.doc.strokesIdOrder = updatedDoc.strokesIdOrder;
      }
      self.strokes_layer.add(textNode);

      const tr = new Konva.Transformer({
        id: `transformer${textNode.id()}`,
        name: 'transformer',
        node: textNode,
        borderStrokeWidth: 3,
        enabledAnchors: ['middle-left', 'middle-right'],
        // set minimum width of text
        boundBoxFunc(oldBox, newBox) {
          newBox.width = Math.max(30, newBox.width);
          return newBox;
        },
      });
      tr.hide();
      self.strokes_layer.add(tr);
      self.strokes_layer.draw();


      textNode.on('dragmove', () => {
        console.log('moving through .....', textNode.absolutePosition());

        const textPosition = textNode.absolutePosition();
      });


      textNode.on('transform', () => {
        if (this.disableEvents) return;

        // reset scale, so only with is changing by transformer
        textNode.setAttrs({
          width: textNode.width() * textNode.scaleX(),
          scaleX: 1,
        });
      });
      textNode.on('transformend', function (e) {
        if (this.disableEvents) return;
        const targetId = textNode.id();
        const oldStroke = _.cloneDeep(self.doc.strokeStore[targetId]);
        textNode.setAttrs({
          width: textNode.width() * textNode.scaleX(),
          scaleX: 1,
        });
        const widthPercentage = textNode.width() / self.width * 100;
        const x_percent = (textNode.x() - self.x) / self.width * 100;
        const y_percent = (textNode.y() - self.y) / self.height * 100;
        // S 03 HY (textWidthUpdate)
        const strokeAdjustment = {
          id: self.shortUuid(),
          targetId,
          type: 'textWidthUpdate',
          from: {
            width: oldStroke.width,
            x: oldStroke.x,
            y: oldStroke.y,
          },
          to: {
            width: widthPercentage,
            x: x_percent,
            y: y_percent,
          },
        };
        if (!this.openSessions) {
          self.updateDoc({ key: 'strokeStore', value: strokeAdjustment });
          self.addHistory({ pageId: self.doc._id || self.doc.shortUuid, stroke: strokeAdjustment });
          self.doc = _.cloneDeep(self.editor.pages[self.editor.currentPageId]);
        }
      });
      textNode.on('mouseover', (e) => {
        if (self.disableEvents) return;
        if (self.mode !== 'pointer') return;
        document.body.style.cursor = 'move';
        tr.show();
        self.strokes_layer.draw();
      });
      textNode.on('mouseout', (e) => {
        if (self.disableEvents) return;
        document.body.style.cursor = 'default';
        if (self.activeText && self.activeText.id() === textNode.id()) {
          return;
        }
        tr.hide();
        self.strokes_layer.draw();
      });
      textNode.on('click', () => {
        if (self.disableEvents) return;
        if (self.mode !== 'pointer' && self.mode !== 'text') return;
        if (self.activeText) {
          const findTrans = self.stage.find(`#transformer${self.activeText.id()}`);
          for (const i in findTrans) {
            findTrans[i].hide();
          }
          self.stage.draw();
        }
        self.setActiveText(textNode);
        self.activeText = textNode;
        tr.show();
        self.strokes_layer.draw();
      });

      textNode.on('dblclick dbltap', () => {
        if (self.disableEvents) return;
        // when the text is begin'{' and end '}', it will be a template option, user can not edit it
        if (/^{(.*?)}$/.test(textNode.attrs.text.trim())) return;
        console.log('ttm 3');
        self.toggleTypingMode(true);
        self.setActiveText(textNode);
        self.activeText = textNode;
        tr.hide();
        self.strokes_layer.draw();

        textNode.hide();
        self.strokes_layer.draw();

        // create textarea over canvas with absolute position
        // first we need to find position for textarea
        // how to find it?

        // at first lets find position of text node relative to the stage:
        const textPosition = textNode.absolutePosition();
        // console.log('textPosition pos is ', textPosition);
        // then lets find position of stage container on the page:
        const stageBox = self.stage.container().getBoundingClientRect();
        // console.log('stageBox pos is ', stageBox);
        // so position of textarea will be the sum of positions above:
        const areaPosition = {
          x: stageBox.left + textPosition.x,
          y: stageBox.top + textPosition.y,
        };

        // create textarea and style it
        const textarea = document.createElement('textarea');
        textarea.id = `textarea-${textNode.id()}`;
        document.body.appendChild(textarea);

        // apply many styles to match text on canvas as close as possible
        // remember that text rendering on canvas and on the textarea can be different
        // and sometimes it is hard to make it 100% the same. But we will try...
        textarea.value = textNode.text();
        self.tribute.attach(textarea);
        textarea.style.position = 'absolute';
        textarea.style.top = `${areaPosition.y}px`;
        textarea.style.left = `${areaPosition.x}px`;
        textarea.style['max-width'] = `${maxWidth}px`;
        textarea.style.width = `${textNode.width()}px`;
        textarea.style.height = `${textNode.height() - textNode.padding() * 2 + 5}px`;
        textarea.style.fontSize = `${textNode.fontSize()}px`;
        textarea.style.border = 'none';
        textarea.style.padding = '0px';
        textarea.style.margin = '0px';
        textarea.style.overflow = 'hidden';
        textarea.style.background = 'none';
        textarea.style.outline = 'none';
        textarea.style.resize = 'none';
        textarea.style.lineHeight = textNode.lineHeight();
        textarea.style.fontFamily = textNode.fontFamily();
        textarea.style.transformOrigin = 'left top';
        textarea.style.textAlign = textNode.align();
        textarea.style.color = textNode.fill();
        self.rotation = textNode.rotation();
        let transform = '';
        if (self.rotation) {
          transform += `rotateZ(${self.rotation}deg)`;
        }

        let px = 0;
        // also we need to slightly move textarea on firefox
        // because it jumps a bit
        const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
        if (isFirefox) {
          px += 2 + Math.round(textNode.fontSize() / 20);
        }
        transform += `translateY(-${px}px)`;

        textarea.style.transform = transform;

        // reset height
        textarea.style.height = 'auto';
        // after browsers resized it we can set actual value
        textarea.style.height = `${textarea.scrollHeight + 3}px`;
        textarea.style['z-index'] = 9999;
        textarea.focus();

        const fakeDiv = document.createElement('div');
        fakeDiv.id = `fakeDiv-${textNode.id()}`;
        document.body.appendChild(fakeDiv);

        fakeDiv.style['max-width'] = `${maxWidth}px`;
        fakeDiv.style.position = 'absolute';
        fakeDiv.style.top = '200px';
        fakeDiv.style.left = '200px';
        fakeDiv.style['z-index'] = 9999;
        fakeDiv.innerHTML = textNode.text();
        fakeDiv.style.fontSize = `${textNode.fontSize()}px`;
        fakeDiv.style.border = 'none';
        fakeDiv.style.padding = '0px';
        fakeDiv.style.margin = '0px';
        fakeDiv.style.overflow = 'hidden';
        fakeDiv.style.background = 'none';
        fakeDiv.style.outline = 'none';
        fakeDiv.style.resize = 'none';
        fakeDiv.style.lineHeight = textNode.lineHeight();
        fakeDiv.style.fontFamily = textNode.fontFamily();
        fakeDiv.style.transformOrigin = 'left top';
        fakeDiv.style.textAlign = textNode.align();
        fakeDiv.style.transform = transform;
        fakeDiv.style.visibility = 'hidden';

        function removeTextarea() {
          textarea.parentNode.removeChild(textarea);
          fakeDiv.parentNode.removeChild(fakeDiv);
          window.removeEventListener('click', handleOutsideClick);
          textNode.show();
          self.strokes_layer.draw();
        }

        textarea.addEventListener('tribute-replaced', (e) => {
          if (self.disableEvents) return;
          const newText = e.target.value;
          fakeDiv.innerHTML = newText;
          textarea.style.width = `${fakeDiv.clientWidth + 0.9}px`;
          textNode.width(fakeDiv.clientWidth + 0.9);
          textarea.style.height = 'auto';
          textarea.style.height = `${textarea.scrollHeight + textNode.fontSize()}px`;

          // S 04 HY (textValueUpdate)
          const targetId = textNode.id();
          self.updateTextValue(textNode, newText, true);
          // self.updateDoc({key: 'strokeStore', value: strokeAdjustment});
          // self.updateStrokeStore({id: textNode.id(), key: 'text', value: newText});

          /**
           * when user use the template text function(icon: </>) selected on option, it
           * need trriger handleOutsideClick function, and switch mode to pointer
           */
          if (self.mode === 'code') {
            handleOutsideClick();
            self.switchMode('pointer');
          }
        });

        textarea.addEventListener('input', (e) => {
          if (self.disableEvents) return;
          console.log('textarea input! e is ', e.target.value);
          fakeDiv.innerHTML = e.target.value;
          textarea.style.width = `${fakeDiv.clientWidth + 0.9}px`;
          textNode.width(fakeDiv.clientWidth + 0.9);
          textarea.style.height = 'auto';
          textarea.style.height = `${textarea.scrollHeight + textNode.fontSize()}px`;
        });

        textarea.addEventListener('keyup', (e) => {
          if (self.disableEvents) return;
          // S 05 HY (every keyup in typing mode)
          if (e.keyCode === 27) {
            // S 15 HY (escape key pressed up - think again)
            console.log('ttm 1');
            self.toggleTypingMode(false);
            self.toggleTransformers(false);
            self.setActiveText(null);
            textNode.text(textarea.value);
            self.updateTextValue(textNode, textarea.value);
            removeTextarea();
          }
        });

        function handleOutsideClick(e) {
          if (!e || e.target !== textarea) {
            console.log('ttm 2');
            self.toggleTypingMode(false);
            self.toggleTransformers(false);
            self.setActiveText(null);
            textNode.text(textarea.value);
            if (textNode.width() + textNode.x() > self.rightBoundary) {
              textNode.width(self.rightBoundary - textNode.x());
            }
            self.updateTextValue(textNode, textarea.value);
            removeTextarea();
          }
        }

        // when the mode is code, user use the template text function(icon: </>), the template options need show automatically
        if (self.mode === 'code') {
          setTimeout(() => {
            self.tribute.showMenuForCollection(textarea);
          }, 400);
        } else {
          setTimeout(() => {
            if (self.disableEvents) return;

            window.addEventListener('click', handleOutsideClick);
          });
        }
      });

      /**
       * the template text function(icon: </>, mode: code) inherits from text function(icon: look like 'I', mode: 'text')
       *
       * Before
       * when user select Text function, the code usually need three steps :
       * - first, put a textnode on the canvas by user
       * - sencond, when user dbclick the textnode, the code will create textarea element
       * - third, when user input text beginning with '{', it will show the template options to select. and other beginning char will be normal text
       *
       * After
       * add template text function (icon: </>, mode: code), it implement part of the Text function
       * user use template text function and click canvas, it will show the template options to select one,
       * when user selected, the template text can't edit, but can move ,change fontsize, change color
       *
       * Diff
       * so it need automatically trigger the second and third step before
       *
       * trriger textnode dbclick event
       * */
      if (self.mode === 'code') {
        setImmediate(() => {
          textNode.fire('dblclick');
        });
      }
    },
    updateTextValue(textNodeCurrentState, newText, fromTributeReplaced) {
      // console.log('********************** updateTextValue ******************* newText - ', newText);
      const targetId = textNodeCurrentState.id();
      const lastStroke = this.doc.strokeStore[this.doc.strokesIdOrder[this.doc.strokesIdOrder.length - 1]];

      if (lastStroke.targetId === targetId && lastStroke.to.text === newText) return;
      const widthPercentage = textNodeCurrentState.width() / this.width * 100;
      const x_percent = (textNodeCurrentState.x() - this.x) / this.width * 100;
      const y_percent = (textNodeCurrentState.y() - this.y) / this.height * 100;
      const oldStroke = _.cloneDeep(this.doc.strokeStore[targetId]);
      const strokeAdjustment = {
        id: this.shortUuid(),
        targetId,
        type: 'textValueUpdate',
        from: {
          text: oldStroke.text,
          width: oldStroke.width,
          x: oldStroke.x,
          y: oldStroke.y,
        },
        to: {
          text: newText,
          width: widthPercentage,
          x: x_percent,
          y: y_percent,
        },
      };
      if (oldStroke.etching || fromTributeReplaced) {
        strokeAdjustment.isEtching = true;
      }

      if (!this.openSessions) {
        this.updateDoc({ key: 'strokeStore', value: strokeAdjustment });
        this.addHistory({ pageId: this.doc._id || this.doc.shortUuid, stroke: strokeAdjustment });
        this.doc = _.cloneDeep(this.editor.pages[this.editor.currentPageId]);
      }
    },
    updateTextSize(newFontSize) {
      // console.log('in child update text fontSize ', this.activeText);
      // console.log('in child update text fontSizeRatio ?? ', this.activeText.fontSizeRatio);
      const targetId = this.activeText.id();

      const lastStroke = this.doc.strokeStore[this.doc.strokesIdOrder[this.doc.strokesIdOrder.length - 1]];
      // console.log('lastStroke ', lastStroke);
      let newUuid = this.shortUuid();
      if (lastStroke.targetId === targetId && lastStroke.type === 'textSizeUpdate') {
        newUuid = lastStroke.id;
      }

      const oldFontSizeRatio = this.doc.strokeStore[targetId].fontSizeRatio;
      // console.log('oldFontSizeRatio ', oldFontSizeRatio);
      const newFontSizeRatio = newFontSize / this.width * 100;
      // console.log('newFontSizeRatio '), newFontSizeRatio;
      if (this.activeText) {
        this.activeText.fontSize(newFontSize);
        this.strokes_layer.draw();
        // S 07 HY
        const strokeAdjustment = {
          id: newUuid,
          targetId,
          type: 'textSizeUpdate',
          from: {
            fontSizeRatio: oldFontSizeRatio,
          },
          to: {
            fontSizeRatio: newFontSizeRatio,
          },
        };
        if (!this.openSessions) {
          this.updateDoc({ key: 'strokeStore', value: strokeAdjustment });
          this.doc = _.cloneDeep(this.editor.pages[this.editor.currentPageId]);
          this.addHistory({ pageId: this.doc._id || this.doc.shortUuid, stroke: strokeAdjustment });
        }
        // this.updateStrokeStore({id: this.activeText.id(), key: 'fontSize', value: newFontSize});
        // this.updateStrokeStore({id: this.activeText.id(), key: 'fontSizeRatio', value: newFontSize / this.width * 100});
      }
    },
    ...mapMutations({
      updateDoc(commit, payload) {
        return commit(`${this.type}/updateDoc`, payload);
      },
      updateStrokeStore(commit, payload) {
        return commit(`${this.type}/updateStrokeStore`, payload);
      },
      addHistory(commit, payload) {
        return commit(`${this.type}/addHistory`, payload);
      },
      undoHistory(commit) {
        return commit(`${this.type}/undoHistory`);
      },
      redoHistory(commit) {
        return commit(`${this.type}/redoHistory`);
      },
    }),
    assignTributeCode() {
      this.tribute.append(0, [
        { key: '{patient.age}', value: '{patient.age}' },
        { key: '{patient.localName}', value: '{patient.localName}' },
        { key: '{patient.localAddress}', value: '{patient.localAddress}' },
        { key: '{patient.doctor.localName}', value: '{patient.doctor.localName}' },
        { key: '{patient.doctor.licenseNumber}', value: '{patient.doctor.licenseNumber}' },
      ]);
    },
    detachTributeWhenCanvasDestroyed() {
      if (this.tribute.current.element !== undefined) {
        this.tribute.detach(this.tribute.current.element);
      }
    },
  },
  async mounted() {
    // console.log('CanvasEditor mounted - doc, this.editor is ', this.doc, this.editor);
    this.doc = _.cloneDeep(this.editor.pages[this.editor.currentPageId]); // currentPage is not index-based
    // console.log('CanvasEditor mounted - doc, this.editor is ', this.doc, this.editor);
    let values = [
      { key: '{patient.name}', value: '{patient.name}' },
      { key: '{patient.nric}', value: '{patient.nric}' },
      { key: '{patient.patientId}', value: '{patient.givenId}' },
      { key: '{patient.nationality}', value: '{patient.nationality}' },
      { key: '{patient.occupation}', value: '{patient.occupation}' },
      { key: '{patient.dateOfBirth}', value: '{patient.birthDate}' },
      { key: '{patient.gender}', value: '{patient.gender}' },
      { key: '{patient.visitDate}', value: '{patient.visitDate}' },
      { key: '{patient.visitDateBE}', value: '{patient.visitDateBE}' },
      { key: '{patient.race}', value: '{patient.race}' },
      { key: '{patient.email}', value: '{patient.email}' },
      { key: '{patient.mobile}', value: '{patient.mobile}' },
      { key: '{patient.telephone}', value: '{patient.telephone}' },
      { key: '{patient.address}', value: '{patient.address}' },
      { key: '{patient.nextOfKin}', value: '{patient.nextOfKin}' },
      { key: '{patient.nextOfKinRelationship}', value: '{patient.nextOfKinRelationship}' },
      { key: '{patient.nextOfKinEmail}', value: '{patient.nextOfKinEmail}' },
      { key: '{patient.nextOfKinPhone}', value: '{patient.nextOfKinPhone}' },
      { key: '{patient.drugSymptom}', value: '{patient.drugSymptom}' },
      { key: '{patient.foodSymptom}', value: '{patient.foodSymptom}' },
      { key: '{patient.bloodGroup}', value: '{patient.bloodGroup}' },
      { key: '{patient.countryOfBirth}', value: '{patient.countryOfBirth}' },
      { key: '{patient.companyName}', value: '{patient.companyName}' },
      { key: '{patient.companyTelephone}', value: '{patient.companyTelephone}' },
      { key: '{patient.companyAddress}', value: '{patient.companyAddress}' },
    ];
    if (this.region.code !== 'TH') {
      values = values.filter(i => i.value !== '{patient.visitDateBE}');
    }
    this.tribute = new Tribute({
      values,
      trigger: '{',
      selectTemplate(item) {
        return item.original.value;
      },
    });

    this.assignTributeCode();

    if (!this.disableEvents) {
      window.addEventListener('orientationchange', this.reOrientate, true);
    }
    this.setDimensions();
    this.setupStage();
    this.setupBackgroundImage();
  },
  async destroyed() {
    console.log('CanvasEditor DESTROYED');

    this.detachTributeWhenCanvasDestroyed();
    await this.$store.dispatch('note/fetchAllCategories');
    window.removeEventListener('orientationchange', this.reOrientate, true);
  },
};
</script>
<style lang="scss" scoped>
#invoice-note-container {
    display: flex;
    justify-content: space-around;
}
.space-between {
  display: flex;
  justify-content: space-between;
}

.invoice-note-container {
    width: 100%;
    >* {
      padding: 16px;
    }
    .note-label {
      .note-label-status > * {
        margin-left: 5px;
      }
    }
    .note-details {
      * {
        margin-right: 5px;
      }
      span {
        display: inline-block;
        vertical-align: middle;
        line-height: 1em;
      }
    }
    overflow: auto;
    >.note-body {

        height: 50%;
    }
}
.grey-bottom-border {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.wrapper {
    position: relative;
}
.vertical-align {
    display: flex;
    align-items: center;
    justify-content: center;
}
.button {
  position: absolute;
  top: 250px;
  left: 56px;
}
</style>
