<template>
  <!-- eslint-disable max-len -->
    <div class="row">
        <div
            class="col-sm-2"
            v-if="parentComponent === 'templates-list-page'"
        >
            <div class="categories-container">
                <div
                    class="categories-overlay"
                    :class="categoriesLoading ? 'loading': 'pass-clicks'"
                ></div>
                <ul class="list-group">
                    <li
                        class="list-group-item d-flex justify-content-between align-items-center"
                        :class="{'active': selectedTemplateId === 'All'}"
                        @click="selectTemplateCat('All')"
                    >
                        <span><v-icon name="globe-asia"/></span>
                        <span style="flex-grow: 1; padding: 2px 0 0 5px;">{{ $t('general.all') }}</span>
                        <span></span>
                    </li>
                    <!-- <li
                        class="list-group-item d-flex justify-content-between align-items-center"
                        :class="{'active': selectedTemplateId === 'Starred'}"
                        @click="selectTemplateCat('Starred')"
                    >
                        <span><v-icon name="star"/></span>
                        <span style="flex-grow: 1; padding: 2px 0 0 5px;">{{ $t('general.starred') }}</span>
                        <span></span>
                    </li> -->
                    <draggable
                        v-model="categoriesIdsOnlyForDraggable"
                        handle=".drag-handle"
                        :sort="true"
                        @change="handleDrag"
                    >
                        <li
                            v-for="(c, index) in categoriesIdsOnlyForDraggable"
                            :key="index"
                            class="list-group-item d-flex justify-content-between align-items-center category-row"
                            style="border-radius: 0; position: relative;"
                            :class="{'active': c === selectedTemplateId}"
                            @click="selectTemplateCat(c)"
                        >
                            <div class="color-picker" style="position: absolute; left: 8px; top: 14px;">
                                <swatches
                                    :value="(categoriesMapped[c] || {}).colorLabel"
                                    @input="prePatchCategory(c, 'colorLabel', $event)"
                                    colors="text-advanced"
                                    swatchSize="22"
                                    popover-to="right"
                                    :trigger-style="{ width: '8px', height: '24px' }"
                                />
                            </div>
                            <span class="drag-handle cursor-grab" style="padding-left: 2px;"><v-icon color="grey" name="grip-vertical"/></span>
                            <span style="flex-grow: 1; padding: 2px 0 0 7px;">
                                <span v-if="editingCategoryName_id === c">
                                    <b-input type="text" size="sm" :value="(categoriesMapped[c] || {}).name || c" @blur="preUpdateCategoryName" autofocus />
                                </span>
                                <span
                                    v-else
                                    @click="(c === selectedTemplateId && c !== uncategorizedCategoryId) ? editingCategoryName_id = c : ''"
                                >{{(categoriesMapped[c] || {}).name || c}}</span>
                            </span>
                            <!-- <span class="badge badge-secondary badge-pill">
                                <span>{{(notetemplatesByCategories[c] || []).length}}</span>
                            </span> -->
                            <span
                                style="position:relative; left:10px;"
                                class="hide cursor-pointer"
                                v-acl="'chartingbuilder_delete'"
                                @click="preDeleteCategory(c)"
                                v-if="c !== uncategorizedCategoryId"
                            >x</span>
                            <span v-else style="color: rgba(0,0,0,0)">x</span>
                        </li>
                    </draggable>
                    <li
                        class="list-group-item d-flex justify-content-between align-items-center"
                        :class="{'active': trashCategoryId === selectedTemplateId}"
                        @click="selectTemplateCat(trashCategoryId)"
                    >
                        <span><v-icon name="trash"/></span>
                        <span style="flex-grow: 1; padding: 2px 0 0 5px;">{{ $t('general.trash') }}</span>
                        <span></span>
                    </li>
                </ul>
            </div>
        </div>
        <div v-if="parentComponent !== 'templates-list-page'" class="col-sm-12">
            <div class="space-between">
              <span class="cursor-pointer">
                <span>
                  <button
                    :class="[selectedTemplateId === 'All' ? 'active' : 'inactive', 'category-button']"
                    @click="selectTemplateCat('All')"
                  >
                    <span>
                      <span class="icon-globe"><v-icon name="globe-asia" color="black" /></span>
                      <span class="icon-text">{{ $t('general.all') }}</span>
                    </span>
                  </button>
                  <!-- <button
                  class="category-button"
                    :class="[selectedTemplateId === 'Starred' ? 'active' : 'inactive', 'category-button']"
                    @click="selectTemplateCat('Starred')"
                  >
                    <span class="icon-star"><v-icon name="star" color="gold" /></span>
                    <span class="icon-text">{{ $t('general.starred') }}</span>
                  </button> -->
                </span>
                <span
                  v-for="(c, index) in Object.keys(notetemplatesByCategories)"
                  :key="index"
                >
                  <button
                    v-acl:disabled.isDynamic="c"
                    class="category-button"
                    :class="[selectedTemplateId === c ? 'active' : 'inactive', 'category-button']"
                    @click="selectTemplateCat(c)"
                  >
                    <span class="icon-circle"><v-icon name="circle" :color="(categoriesMapped[c] || {}).colorLabel" /></span>
                    <span class="icon-text">{{ categoriesMapped[c].name === 'Uncategorized' ? $t('general.uncategorized') : categoriesMapped[c].name }}</span>
                  </button>
                </span>
              </span>
            </div>
        </div>
        <div :class="{'col-sm-10': parentComponent === 'templates-list-page'}">
            <div class="row charting-cards-container">
                <div class="charting-card"
                    v-for="templateId in notetemplatesFiltered"
                    :key="templateId"
                    v-acl:disabled.isDynamic="parentComponent === 'templates-list-page' ? '' : notetemplatesMappedById[templateId].categoryId"
                >
                    <div
                        class="charting-card-label"
                        :style="{'padding': '0 25px', 'background-color': (categoriesMapped[notetemplatesMappedById[templateId].categoryId] || {}).colorLabel, color: '#4D4D4D'}"
                    >
                        <span>{{notetemplatesMappedById[templateId].name}}</span>
                    </div>
                    <div class="charting-card-label-after space-between absolute">
                        <span
                            v-if="parentComponent === 'templates-list-page'"
                            class="charting-card-icons-left cursor-pointer"
                            @click="preDeleteNotetemplate(templateId)"
                        >
                            <v-icon name="trash" class="trash" />
                        </span>
                        <!-- <span v-else class="charting-card-icons-left cursor-pointer">
                            <span
                              v-if="topLeftIconFunction && externallyValid"
                              @click="topLeftIconFunction(templateId)"
                            >
                              <v-icon :name="'brush'" class="edit" />
                            </span>
                        </span> -->
                        <span></span>
                        <span class="charting-card-icons cursor-pointer">
                            <span @click="topRightIconFunction(templateId)"
                              v-if="externallyValid">
                              <v-icon :name="topRightIcon" class="edit" />
                            </span>
                        </span>
                    </div>
                    <div class="charting-card-body d-flex justify-content-center align-items-center">
                        <img :src="notetemplatesMappedById[templateId].signedThumbnailKey" alt="" width="165" @error="fetchSignedUrl(templateId)">
                    </div>
                    <div class="charting-card-footer">
                      <span v-if="notetemplatesMappedById[templateId].isDefaultIPDNotes">Default</span>
                      <span></span>
                      <span class="charting-card-icons cursor-pointer" v-if="showBottomRightIcon">
                        <span @click="bottomRightIconFunction(templateId)"
                          v-if="externallyValid">
                          <v-icon name="minus-square" class="tag" v-if="notetemplatesMappedById[templateId].isDefaultIPDNotes" />
                          <v-icon :name="bottomRightIcon" class="tag" v-else />
                        </span>
                      </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable max-len */
import Swatches from 'vue-swatches';

import {
  mapGetters, mapMutations, mapState,
} from 'vuex';

export default {
  name: 'TemplatesContainer',
  props: {
    topRightIcon: String,
    topRightIconFunction: Function,
    parentComponent: String,
    trashCategoryId: String,
    uncategorizedCategoryId: String,
    reorderNoteCategories: Function,
    updateCategoriesOrder: Function,
    categoriesLoading: Boolean,
    categoriesLoadingError: Boolean,
    patchCategory: Function,
    deleteCategory: Function,
    deleteNotetemplate: Function,
    extraValidation: Function,
    bottomRightIconFunction: {
      type: Function,
      default: function fn() {},
    },
    bottomRightIcon: {
      type: String,
      default: 'tag',
    },
    showBottomRightIcon: {
      type: Boolean,
      default: false,
    },
  },
  components: { Swatches },
  mixins: [],
  data() {
    return {
      // selectedTemplateId: 'All',
      editingCategoryName_id: '',
      list: [],
    };
  },
  computed: {
    categoriesIdsOnlyForDraggable: {
      get() {
        return this.activeCategoriesIdsOnly.slice(0, this.activeCategoriesIdsOnly.length - 1); // exclude trash category id
      },
      set(a) {
        this.reorderNoteCategories(a);
      },
    },
    externallyValid() {
      if (typeof this.extraValidation === 'undefined') {
        return true;
      }
      return this.extraValidation;
    },
    notetemplatesFiltered() {
      let output = [...this.notetemplatesCoverPagesOnlyOrdered];
      if (this.parentComponent === 'editing-page') {
        output = output.filter((templateId) => {
          const template = this.notetemplatesMappedById[templateId];
          return template && template.type === 'drawing';
        });
      }
      if (this.selectedTemplateId !== 'All') {
        output = output.filter((templateId) => {
          const template = this.notetemplatesMappedById[templateId];
          return template && template.categoryId === this.selectedTemplateId;
        });
      }
      return output;
    },
    ...mapState('note', ['activeCategoriesIdsOnly']),
    ...mapGetters('note', ['categoriesMapped', 'selectedTemplateId']),
    ...mapState('notetemplate', [
      'notetemplatesCoverPagesOnlyOrdered',
      'notetemplatesByCategories',
      'notetemplatesMappedById',
    ]),
  },
  methods: {
    async fetchSignedUrl(templateId) {
      await this.$store.dispatch('notetemplate/getTemplateLatestImage', { id: templateId, key: 'signedThumbnailKey' });
    },
    preUpdateCategoryName(e) {
      const catId = this.editingCategoryName_id;
      const newName = e.target.value;
      if (newName !== '' && newName !== this.categoriesMapped[catId].name) {
        this.prePatchCategory(catId, 'name', newName);
      }
      this.editingCategoryName_id = '';
    },
    async prePatchCategory(catId, key, value) {
      const response = await this.patchCategory({ id: catId, patchKeyValue: { [key]: value } });
      if (response.ok) {
        this.flash('Charting category updated successfully', 'success', {
          timeout: 3000,
        });
      } else {
        this.flash('Failed to update charting category', 'warning', {
          timeout: 3000,
        });
      }
    },
    async preDeleteCategory(catId) {
      const confirm = window.confirm('Deleting of category is irreversible and will result in the deletion of its templates (if any).\nAre you sure you want to proceed?');
      if (!confirm) {
        this.flash('Deletion of category cancelled.', 'warning', { timeout: 3000 });
        return;
      }
      const response = await this.deleteCategory(catId);
      if (response.ok) {
        this.flash('Charting category deleted successfully', 'success', {
          timeout: 3000,
        });
        if (catId === this.selectedTemplateId) {
          this.selectTemplateCat('All');
        }
      } else {
        this.flash('Failed to delete charting category', 'warning', {
          timeout: 3000,
        });
      }
    },
    async preDeleteNotetemplate(templateId) {
      const confirm = window.confirm('Deletion of template is irreversible.\n\nAre you sure you want to proceed?');
      if (!confirm) {
        this.flash('Deletion of template cancelled.', 'warning', { timeout: 3000 });
        return;
      }
      const response = await this.deleteNotetemplate(templateId);
      if (response.ok) {
        this.flash('Charting template deleted successfully', 'success', {
          timeout: 3000,
        });
      } else {
        this.flash('Failed to delete charting template', 'warning', {
          timeout: 3000,
        });
      }
    },
    handleDrag(e) {
      const { element: id, oldIndex, newIndex } = e.moved;
      let oldSiblingId;
      if (oldIndex > newIndex) { // dragged upwards
        oldSiblingId = this.activeCategoriesIdsOnly[oldIndex + 1];
      } else {
        oldSiblingId = this.activeCategoriesIdsOnly[oldIndex];
      }
      this.updateCategoriesOrder({
        id,
        oldSiblingId,
        newSiblingId: this.activeCategoriesIdsOnly[newIndex + 1],
      });
    },
    ...mapMutations('note', ['selectTemplateCat']),
    // selectTemplateCat(e, catId) {
    //     this.selectedTemplateId = catId;
    // },
  },
};
</script>

<style lang="scss" scoped>
.absolute {
    position: absolute;
}
.space-between {
  display: flex;
  justify-content: space-between;
}
.charting-cards-container {
    height: inherit;
    width: auto;
    padding: 25px 15px 15px 25px;
    // background-color: rgb(207, 232, 252);
    .charting-card {
        height: 260px;
        position:  relative;
        margin: 0 20px 15px 0;
        box-shadow: 0 0 10px gray;
        .charting-card-label {
            height: 10%;
            text-align: center;
            padding: 1px 0 0 0;
        }
        .charting-card-label-after {
            width: 100%;
            z-index: 99;
            .charting-card-icons-left {
                position: relative;
                top: -26px;
                margin: 0px 0 0 4px;
            }
            .charting-card-icons {
                position: relative;
                top: -26px;
                margin: 4px 4px 0 0;
                >* {
                    margin-left: 3px;
                    >* {
                        position: relative;
                    }
                    .search {
                        top: -4px;
                    }
                    .edit {
                        top: -5px;
                    }
                }
            }
        }
        .charting-card-body {
            height: 90%;
            position: relative;
            img {
                max-height: 100%;
            }
        }
        .charting-card-footer {
          position: absolute;
          bottom: 0;
          background: #737373;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 5px;
          opacity: .9;
        }
    }
}
.categories-container {
    position: relative;
    .categories-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 99;
        &.pass-clicks  {
            pointer-events: none;
        }
        &.loading {
            background-color: rgba(0, 0, 0, 0.2);
        }
    }
    .category-row .hide {
        visibility: hidden;
    }
    .category-row:hover .hide{
        visibility: visible;
    }
}

button.category-button {
  border-radius: 4px;
  margin: 4px;
  border: 2px solid grey;
  &:hover {
    border: 2px solid black;
  }
  &.active {
    border: 2px solid red;
  }
  &:focus {
    outline: 0;
  }
}

[class^="icon"] {
  position: relative;
  top: -1px;
}
.icon-text {
  position: relative;
  top: 1px;
  margin-left: 3px;
}

</style>
