<template>
  <!-- eslint-disable max-len -->
    <div class="mb-5" id="individual-corp-invoice" style="margin: 0 20px"
      :class="{'previewPrint': preview}">
        <div class="row mb-3">
          <div class="col-12">
            <img v-if="invoiceConfigCorp.clinicLogo"
              class="clinic-logo"
              :src="this.companyClinicLogo">
            <h5>{{ this.companyClinicName }}</h5>
            <h6 class="mt-2" v-if="invoiceConfigCorp.clinicLocalName">{{ this.companyClinicLocalName }}</h6>
            <p v-if="invoiceConfigCorp.clinicAdd"
              class="mb-1 address-font"
              style="white-space: pre-line;">
              {{corpInvoiceAddress}}
            </p>
            <p v-if="invoiceConfigCorp.clinicLocalAddress"
              class="mb-3 address-font"
              style="white-space: pre-line; line-height: .5rem;">
              {{ corpInvoiceLocalAddress }}
            </p>
            <p class="mb-3 address-font" v-if="generalConfig.telephone">Tel: {{ generalConfig.telephone }}</p>
            <h3 class="mb-3 text-center text-uppercase">
              <u>
                {{ $t('general.corporateInvoice') }}
              </u>
            </h3>
          </div>
        </div>
        <div class="row mb-3">
            <div class="col">
                <h6 class="bill-to">Bill To / Address:</h6>
            </div>
        </div>
        <div class="row mb-5">
            <div class="col-8">
                <div class="clinic-name font-weight-bold" v-if="invoiceConfigCorp.companyName">
                  <span class="font-weight-bold">Company Name:</span>&nbsp;&nbsp;
                  <span>{{ corporate.name }}</span>
                </div>
                <div class="clinic-address font-weight-bold" v-if="invoiceConfigCorp.companyAddress">
                  <span class="font-weight-bold">Company Address:</span>&nbsp;&nbsp;
                  <span>{{ corporate.address }}</span>
                </div>
                <div>
                  <span class="font-weight-bold">Patient Name:</span>&nbsp;&nbsp;
                  <span>{{patient.name}} - {{patient.givenId}}</span>
                </div>
                <div v-if="printConfig.patientLocalName">
                  <span class="font-weight-bold">Patient Local Name:</span>&nbsp;&nbsp;
                  <span>{{ patient.localName }}</span>
                </div>
                <div v-if="invoiceConfigCorp.patientAdd">
                  <span class="font-weight-bold"> Address:</span>&nbsp;&nbsp;
                  <span>{{patient.address}}</span>
                </div>
            </div>
            <div class="col-4">
                <div class="tax-invoice-no">
                    <span class="font-weight-bold">Tax Invoice No:</span>&nbsp;&nbsp;
                    <span>{{ invoice.invoiceNo }}</span>
                </div>
                <div class="visit date">
                    <span class="font-weight-bold">Visit Date:</span>&nbsp;&nbsp;
                    <span>{{ invoice.date | formatVDDate({ sourceFormat: 'X', lang: $i18n.locale, format: 'DD MMM YYYY'}) }}</span>
                </div>
                <div  v-if="invoiceConfigCorp.invoiceDateBE" class="visit date">
                    <span class="font-weight-bold">{{ $t('setting.visitDateBE')}}:</span>&nbsp;&nbsp;
                    <span>{{ invoice.date | formatVDDate({ sourceFormat: 'X', lang: 'th', format: 'DD MMM YYYY'}) }}</span>
                </div>
                <div class="acra">
                    <span class="font-weight-bold">ACRA No:</span>&nbsp;&nbsp;
                    <span>{{companyACRANo}}</span>
                </div>
                 <div class="tax-reg-no">
                    <span class="font-weight-bold">Tax Reg No:</span>&nbsp;&nbsp;
                    <span>{{companyTaxRegNo}}</span>
                </div>

                 <div v-if="invoiceConfigCorp.docName" class="attending-doctor">
                    <span class="font-weight-bold">Attending Doctor:</span>&nbsp;&nbsp;
                    <span v-if="providersMappedById[invoice.provider]">{{ providersMappedById[invoice.provider].name }}</span>
                </div>
                <div v-if="invoiceConfigCorp.docQual" class="qualifications">
                    <span class="font-weight-bold">Qualifications:</span>&nbsp;&nbsp;
                    <span v-if="providersMappedById[invoice.provider]">{{ providersMappedById[invoice.provider].qualifications }}</span>
                </div>
                 <div v-if="invoiceConfigCorp.therapistName" class="attending-therapist">
                    <span class="font-weight-bold">Attending Therapist:</span>&nbsp;&nbsp;
                    <span v-if="providersMappedById[invoice.therapist]">{{ providersMappedById[invoice.therapist].name }}</span>
                </div>
            </div>
        </div>
                <table class="table table-sm invoice-table">
                    <thead class="thead-dark">
                        <tr>
                            <th class="border">Item</th>
                            <th class="border">Description</th>
                            <th class="border">Qty</th>
                            <th class="border">Unit Cost</th>
                            <th class="border">Discount</th>
                            <th class="border">Subtotal (excluding {{invoice.taxName || 'GST'}})</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in invoice.items" :key="item._id">
                            <td class="border">{{ item.name }}</td>
                            <td class="border">{{ item.description }}</td>
                            <td class="border">{{ item.qty }}</td>
                            <td class="border">{{ generalConfig.region.currency }}{{ item.price }}</td>
                            <td class="border" v-if="item.discountType === '%'">
                                {{ item.discount }}%
                            </td>
                            <td class="border" v-else>{{ item.discountAmount }}</td>
                            <td class="border">{{ generalConfig.region.currency }}{{ item.afterDiscountPrice.toFixed(2) }}</td>
                        </tr>
                        <tr v-if="invoice.totalTax > 0">
                            <td colspan="4" class="no-border"></td>
                            <td class="border">{{invoice.taxName || 'GST'}}</td>
                            <td class="border">{{ generalConfig.region.currency }}{{ invoice.totalTax }}</td>
                        </tr>
                        <tr>
                            <td colspan="4" class="no-border"></td>
                            <td class="border bg-light">Total</td>
                            <td class="border bg-light">{{ generalConfig.region.currency }}{{ invoice.total }}</td>
                        </tr>
                        <tr>
                            <td colspan="4" class="no-border"></td>
                            <td class="border bg-light">PatientPayment</td>
                            <td class="border bg-light">{{ generalConfig.region.currency }}{{ patientPayment }}</td>
                        </tr>
                    </tbody>
                </table>
                <table class="table table-sm">
                    <thead class="thead-light">
                        <tr>
                            <th class="border" colspan="2">Payment Mode:</th>
                            <th class="border">Batch Amount</th>
                            <th class="border">Allocated Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(settle, index) in settlement.corpSettlement"
                          :key="index">
                            <td class="border" colspan="2"
                              width="1000">
                                {{ settle.collectionMode }}
                                ({{ settle.timestamp | ddMonthYYYYFromX }})
                            </td>
                            <td class="border">
                                {{ generalConfig.region.currency }}{{ settle.recordedAmount }}
                            </td>
                            <td class="border">
                                {{ generalConfig.region.currency }}{{ settle.batchAmount }}
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td class="no-border">&nbsp;</td>
                            <td class="no-border">&nbsp;</td>
                            <td class="border bg-light" width="100px">Total</td>
                            <td class="border bg-light">
                                {{ generalConfig.region.currency }}{{ settlement.corpSettlementTotal }}
                            </td>
                        </tr>
                        <tr>
                            <td class="no-border">&nbsp;</td>
                            <td class="no-border">&nbsp;</td>
                            <td class="border bg-light">Outstanding</td>
                            <td class="border text-danger bg-light">
                                {{ generalConfig.region.currency }}{{ settlement.corporateOutstanding }}
                            </td>
                        </tr>
                    </tfoot>
                </table>
        <footer class="footer">
          <div>
            <div class="row">
                <div class="col">
                    <h6>Remarks:</h6>
                    <p class="text-secondary">

                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-10">
                  <template v-if="invoiceConfigCorp.notes">
                    <div class="weight-600"> {{ $t('general.notes') }}: </div>
                    <p style="white-space: pre-line;"> {{ invoice.notes }} </p>
                  </template>
                  <p v-if="invoiceConfigCorp.footer" style="white-space: pre-line;">
                    {{ invoiceConfigCorp.footerContent }}
                  </p>
                </div>
                <!--
                <div class="col">
                    <div>
                        <img :src="generalConfig.logoURL"
                      class="img-fluid float-right"/>
                    </div>
                </div>
                -->
            </div>
            <!--
            <div class="row">
                <div class="col-8"></div>
                <div class="col">
                    <div class="text-right">
                        {{ generalConfig.clinicName }}
                    </div>
                    <div class="text-right"> {{ corpInvoiceAddress }} </div>
                </div>
            </div>
            -->
          </div>
      </footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dateFormatter from '../mixins/dateformatter';

export default {
  name: 'IndividualCorpInvoice',
  props: {
    settlement: {
      type: Object,
      default: () => ({}),
    },
    invoice: {
      type: Object,
      default: () => ({}),
    },
    corporate: {
      type: Object,
      default: () => ({}),
    },
    generalConfig: {
      type: Object,
      default: () => ({}),
    },
    invoiceConfigCorp: {
      type: Object,
      default: () => ({}),
    },
    patient: {
      type: Object,
      default: () => ({}),
    },
    payments: {
      type: Array,
      default: [],
    },
    printConfig: {
      type: Object,
      default: () => ({}),
    },
    preview: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [dateFormatter],
  computed: {
    ...mapGetters('config', ['featureFlags']),
    taxName() {
      return this.$store.state.config.invoice.taxName;
    },
    corpInvoiceAddress() {
      if (this.generalConfig.useLocationAddress) {
        const location = this.clinics.find(loc => loc._id === this.invoice.clinic);
        return location ? location.address : '';
      }
      return this.generalConfig.address;
    },
    corpInvoiceLocalAddress() {
      if (this.generalConfig.useLocationAddress) {
        const location = this.clinics.find(clinicLocation => clinicLocation._id === this.invoice.clinic);
        return location ? location.localAddress : '';
      }
      return this.generalConfig.clinicLocalAddress || '';
    },
    companyClinicLogo() {
      if (this.generalConfig.useLocationAddress) {
        const location = this.clinics.find(loc => loc._id === this.invoice.clinic);
        return location ? location.clinicLogo : '';
      }
      return this.generalConfig.logoURL;
    },
    companyClinicName() {
      if (this.generalConfig.useLocationAddress) {
        const location = this.clinics.find(clinicLocation => clinicLocation._id === this.invoice.clinic);
        return location ? location.name : '';
      }
      return this.generalConfig.clinicName || '';
    },
    companyClinicLocalName() {
      if (this.generalConfig.useLocationAddress) {
        const location = this.clinics.find(clinicLocation => clinicLocation._id === this.invoice.clinic);
        return location ? location.localName : '';
      }

      return this.generalConfig.clinicLocalName || '';
    },
    patientPayment() {
      const invoicePymt = this.payments.filter(x => x.invoice._id === this.invoice.id && x.clinic === this.invoice.clinic);
      if (invoicePymt) {
        let totPatientPymt = 0;
        for (let i = 0; i < invoicePymt.length; i++) {
          const patientPymt = invoicePymt[i].payments.reduce((a, b) => a + parseFloat(b.amount), 0);
          totPatientPymt += parseFloat(patientPymt);
        }
        return totPatientPymt;
      }
      return 0;
    },
    companyACRANo() {
      if (this.generalConfig.useLocationAddress) {
        const location = this.clinics.find(loc => loc._id === this.invoice.clinic);
        return location ? location.regNo : '';
      }
      return this.generalConfig.companyRegNo;
    },
    companyTaxRegNo() {
      if (this.generalConfig.useLocationAddress) {
        const location = this.clinics.find(loc => loc._id === this.invoice.clinic);
        return location ? location.taxNo : '';
      }
      return this.generalConfig.gstRegNo;
    },
    providersMappedById() {
      return this.$store.state.provider.providersMappedById;
    },

    // provider() {
    //   const invoiceProvider = this.invoice.provider && typeof this.invoice.provider === 'object'
    //     ? this.invoice.provider._id : this.invoice.provider;
    //   const queueProvider = this.invoice.queue && this.invoice.queue.provider;
    //   const providerId = invoiceProvider || queueProvider;
    //   if (providerId) {
    //     const printProvider = this.providers.find(p => p._id === providerId);
    //     if (printProvider) return printProvider;
    //     return {};
    //   }
    //   return {};
    // }
  },
  // methods: {
  //   provider() {
  //           console.log("provider before", invoice.provider)

  //     const doctor = this.providers.find(invoice.provider)
  //     console.log("provider ", doctor)
  //     return doctor;
  //   },
  // },
  mounted() {
    if (!this.preview) {
      setTimeout(() => {
        window.print();
      }, 3000);
      /*
       * LeiLi: anyone who want to use these following lines
       * will need to import the `print-js` package
       * Otherwise it will have nothing different with window.print().
       * Also, if you import the `print-js` package, it will
       * take effect only when mounted called. If the user closes the printing popup
       * and clicks 'print' from right click context menu of browser, or from browser-file-print
       * it will call window.print instead.
       */
      /*
      setTimeout(() => {
        print({
          printable: 'individual-corp-invoice',
          type: 'html',
          ignoreElements: ['app'],
          targetStyles: ['*'],
          onPrintDialogClose() {
            window.close();
          },
        });
      }, 3000);
      */
    }
  },
};
</script>
<style scoped>
  body{
    -webkit-print-color-adjust:exact;
  }
  .no-border {
    border: none !important;
  }
  .border {
    border: 1px solid #666;
  }

  td.bg-light {
    color: #495057 !important;
    background-color: #eee !important;
  }

  td.bg-light.text-danger {
    color: #c0031d !important;
    background-color: #eee !important;
  }

  .weight-600{
    font-weight: 600;
  }
  .previewPrint {
    background-color: white;
    font-size: 0.1em !important;
    padding: 20px;
    padding-left: 35px;
    min-height: 60em;
    margin-bottom: 0px !important;
  }
  /* @media screen {
    .print-container {
      max-width: 1140px;
      margin-left: auto;
      margin-right: auto;
      padding: 20px;
    }
    } */

  @media print {
    .table .thead-dark th {
      -webkit-print-color-adjust: exact !important;
      color: #fff !important;
      background-color: #333 !important;
    }
    .table .thead-light th {
        -webkit-print-color-adjust: exact !important;
      color: #495057 !important;
      background-color: #eee !important;
    }

    td.bg-light {
      -webkit-print-color-adjust: exact !important;
      color: #495057 !important;
      background-color: #eee !important;
    }

    td.bg-light.text-danger {
      -webkit-print-color-adjust: exact !important;
      color: #c0031d !important;
      background-color: #eee !important;
    }
    /* .print-container {
      max-width: 1140px;
      margin-left: auto;
      margin-right: auto;
      padding: 20px;
      page-break-after: auto;
    } */
  }
</style>
<style lang="scss" scoped>
#individual-corp-invoice {
  &.previewPrint {
    .clinic-logo {
      height: 30px;
      width: auto;
    }
  }
  .clinic-logo {
    max-height: 150px;
    width: 150px;
    margin-bottom: 10px;
  }
}
</style>
