<template>
  <div :style="{ 'height' :`${medicalLabelHeight}px` }" class="row">
    <div class="col-6">
      <div class="row">
        <div class="col-12">
          <h5>{{clinicName}}</h5>
          <p class="mb-1 border-bottom label-address-font">{{clinicAddress}}</p>
            <p class="clinic-tel" v-if="clinicNumber">Tel : {{ clinicNumber }}</p>
          <h5 class="mb-1">{{medName}}
            <span v-if="medQty || medUnit">&nbsp;-&nbsp;{{medQty}}&nbsp;{{medUnit}}</span>
            <span v-if="description && featureFlags.tcmMode">({{ description }})</span>
          </h5>
        </div>
      </div>
      <div class="row mb-1">
        <h6 class="col-7" v-html="medDosage"></h6>
        <h6 v-if="!featureFlags.tphInvoice && medExpiry" class="col-5 text-right">EXPIRY &nbsp;:&nbsp; {{medExpiry | dateOnlyFormat}}</h6>
      </div>
      <div class="row mb-1">
        <p class="precaution-font2 col-12">{{medPrecautionOne}}</p>
        <p class="precaution-font2 col-12">{{medPrecautionTwo}}</p>
        <template v-if="featureFlags.tphInvoice && withSelectedBatch">
          <p class="text-right precaution-font2 col-12">
            <strong>Batch Number:</strong> {{selectedBatch.number}}
          </p>
          <p class="text-right precaution-font2 col-12">
            <strong>Expiry Date:</strong> {{getExpiry(selectedBatch.expiry) || ''}}
          </p>
        </template>
        <p class="precaution-font col-12">{{medStrength}}</p>
      </div>
      <div class="bottom-div row weight-600">
        <div class="col-6">
          {{patientName}} / {{patientNric}}
        </div>
        <div class="col-6 text-right">
          {{new Date() | ddMonthYYYY}}
        </div>
        <div class="col-6">
          DOB: {{patientDetails.dateOfBirth | ddMonthYYYY}}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import moment from 'moment';
import dateformatter from '@/components/mixins/dateformatter';
import { mapGetters } from 'vuex';

export default {
  name: 'MedicalLabelComponent',
  mixins: [dateformatter],
  props: ['clinicName', 'clinicAddress', 'medName', 'medStrength', 'medQty', 'medDosage', 'medUnit',
    'medExpiry', 'medPrecautionOne', 'medPrecautionTwo', 'patientName', 'patientNric',
    'selectedBatch', 'disableAutoPrint', 'clinicNumber', 'patientDetails', 'description',
  ],
  methods: {
    getExpiry(expiryDate) {
      return this.inventoryExpiryDateFormat(expiryDate);
    },
  },
  computed: {
    ...mapGetters('print', ['setting']),
    ...mapGetters('config', ['featureFlags']),
    medicalLabelHeight() {
      if (localStorage.getItem('printSetup')) {
        return JSON.parse(localStorage.getItem('printSetup')).medicalLabelHeight;
      }
      return 200;
    },
    withSelectedBatch() {
      return !_.isEmpty(this.selectedBatch);
    },
  },
  mounted() {
    if (!this.disableAutoPrint) {
      setTimeout(() => {
        window.print();
      }, 2000);
    }
  },
};
</script>

<style scoped src="../../assets/print.css"></style>
