export const SET_HEALTHSCREENING = 'SET_HEALTHSCREENING';
export const GET_HEALTHSCREENING = 'GET_HEALTHSCREENING';
export const SET_TEST_RESULTS = 'SET_TEST_RESULTS';
export const SET_RAW_TEST_RESULTS = 'SET_RAW_TEST_RESULTS';
export const SET_ALL_HEALTHSCREENING = 'SET_ALL_HEALTHSCREENING';
export const SET_DOCTOR_RECOMMENDATIONS = 'SET_DOCTOR_RECOMMENDATIONS';
export const SET_PREV_TEST_IDS = 'SET_PREV_TEST_IDS';
export const SET_PREV_TEST_RESULTS = 'SET_PREV_TEST_RESULTS';
export const SET_HEALTH_SCREENING_TEST_SPECS = 'SET_HEALTH_SCREENING_TEST_SPECS';
export const SET_HEALTH_SCREENING_TEST_SPECS_LIST = 'SET_HEALTH_SCREENING_TEST_SPECS_LIST';
export const SET_SOCKET_ONLINE = 'SET_SOCKET_ONLINE';
export const SET_SOCKET_OFFLINE = 'SET_SOCKET_OFFLINE';
