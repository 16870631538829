var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid", class: { previewPrint: _vm.preview } },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-5" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _vm.printLabelConfig.printLabel.clinicName
                ? _c("h5", [_vm._v(_vm._s(_vm.clinic.clinicName))])
                : _vm._e(),
              _vm.printLabelConfig.printLabel.clinicAddress
                ? _c("p", { staticClass: "mb-2 address-font" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.clinic.address || "") +
                        "\n          "
                    )
                  ])
                : _vm._e(),
              _c("div", { staticClass: "border-bottom mb-2" })
            ])
          ]),
          _vm.printLabelConfig.printLabel.patientName
            ? _c("div", { staticClass: "row col-12" }, [
                _c("div", [_vm._v("NAME")]),
                _c("div", [
                  _vm._v(" : "),
                  _c("strong", [_vm._v(_vm._s(_vm.patient.name))])
                ])
              ])
            : _vm._e(),
          _vm.printLabelConfig.printLabel.patientID
            ? _c("div", { staticClass: "row col-12" }, [
                _c("div", [_vm._v("CN")]),
                _c("div", [
                  _vm._v(
                    " : " +
                      _vm._s(
                        _vm.patient.givenIdPrefix
                          ? _vm.patient.givenIdPrefix + _vm.patient.givenId
                          : _vm.patient.givenId
                      )
                  )
                ])
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "row col-12" },
            [
              _vm.printLabelConfig.printLabel.patientDOB
                ? [
                    _c("div", [_vm._v("DOB")]),
                    _c("div", [
                      _vm._v(" : \n            "),
                      _vm.patient.dateOfBirth == null ||
                      _vm.patient.dateOfBirth == ""
                        ? _c("span")
                        : _c("span", [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm._f("formatVDDate")(
                                    _vm.patient.dateOfBirth,
                                    {
                                      format: "DD MMM YYYY",
                                      lang: _vm.$i18n.locale
                                    }
                                  )
                                ) +
                                " / " +
                                _vm._s(_vm.age || " ") +
                                "\n            "
                            )
                          ])
                    ])
                  ]
                : _vm._e()
            ],
            2
          ),
          _c("div", { staticClass: "row col-12" }, [
            _c("div", [_vm._v("DATE")]),
            _c("div", [
              _vm._v(
                " : " +
                  _vm._s(
                    _vm._f("formatVDDate")(new Date(), {
                      format: "DD MMM YYYY hh:mm:ss A",
                      sourceFormat: "X",
                      lang: _vm.$i18n.locale
                    })
                  )
              )
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }