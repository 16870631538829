<template>
  <div class="item-container" :style="{ color: (type==='classified'? 'green': '') }">
    <div class="row mb-3">
      <div class="col-9">
        <img v-if="patientConfig.printPrescriptionHeader.clinicLogo"
             :style="{ marginLeft:`${logoMarginLeft}px`, width:`${logoWidth}px` }"
             :src="generalConfig.logoURL">
        <h5 class="clinic-name" v-if="patientConfig.printPrescriptionHeader.clinicName">
          {{ generalConfig.clinicName }}
        </h5>
        <p class="clinic-address" v-if="patientConfig.printPrescriptionHeader.clinicAddress">
          {{ generalConfig.address }}
        </p>
      </div>
      <div class="col3" v-if="type==='classified'">
        <div class="classified-medicine-tip">
          {{ $t('prescriptionConfig.classifiedMedicineTip') }}
        </div>
      </div>
    </div>
    <h3 class="mb-5 mt-3 text-center text-uppercase">
      {{ $t('general.prescriptionFormTitle') }}
    </h3>
    <div class="row mb-4">
      <div class="col-6">
        <div class="row mb-2" v-if="patientConfig.printPrescriptionHeader.patientName">
          <div class="col-3 form-label">{{ $t('prescriptionConfig.patientName') }}:</div>
          <div class="col-9">{{selectedPrescription.patient.name}}</div>
        </div>
        <div class="row mb-2" v-if="patientConfig.printPrescriptionHeader.patientID">
          <div class="col-3 form-label">{{ $t('prescriptionConfig.patientId') }}:</div>
          <div class="col-9">{{selectedPrescription.patient.givenIdPrefix}}{{selectedPrescription.patient.givenId}}</div>
        </div>
        <div class="row mb-2" v-if="patientConfig.printPrescriptionHeader.patientAge">
          <div class="col-3 form-label">{{ $t('prescriptionConfig.patientAge') }}:</div>
          <div class="col-9">{{selectedPrescription.patient.age}}</div>
        </div>
        <div class="row mb-2" v-if="patientConfig.printPrescriptionHeader.patientVN">
          <div class="col-3 form-label">{{ $t('prescriptionConfig.patientVN') }}:</div>
          <div class="col-9">{{selectedPrescription.queue && selectedPrescription.queue.call_number}}</div>
        </div>
        <div class="row mb-2" v-if="patientConfig.printPrescriptionHeader.diagnosis">
          <div class="col-3 form-label">{{ $t('prescriptionConfig.diagnosis') }}:</div>
          <div class="col-9"></div>
        </div>
        <div class="row mb-2" v-if="patientConfig.printPrescriptionHeader.patientSkinTest">
          <div class="col-3 form-label">{{ $t('prescriptionConfig.patientSkinTest') }}:</div>
          <div class="col-9"></div>
        </div>
        <div class="row mb-2" v-if="patientConfig.printPrescriptionHeader.allergies">
          <div class="col-3 form-label">{{ $t('prescriptionConfig.allergies') }}:</div>
          <div class="col-9"></div>
        </div>
      </div>
      <div class="col-6">
        <div class="row mb-2" v-if="patientConfig.printPrescriptionHeader.patientGender">
          <div class="col-3 form-label">{{ $t('prescriptionConfig.patientGender') }}:</div>
          <div class="col-9">{{selectedPrescription.patient.gender | translateGender}}</div>
        </div>
        <div class="row mb-2" v-if="patientConfig.printPrescriptionHeader.patientWeight">
          <div class="col-3 form-label">{{ $t('prescriptionConfig.patientWeight') }}:</div>
          <div class="col-9"></div>
        </div>
        <div class="row mb-2" v-if="patientConfig.printPrescriptionHeader.patientTelephone">
          <div class="col-3 form-label">{{ $t('prescriptionConfig.patientTelephone') }}:</div>
          <div class="col-9">{{selectedPrescription.patient.mobile}}</div>
        </div>
        <div class="row mb-2" v-if="patientConfig.printPrescriptionHeader.serviceType">
          <div class="col-3 form-label">{{ $t('prescriptionConfig.serviceType') }}:</div>
          <div class="col-9">{{selectedPrescription.queue && selectedPrescription.queue.service && selectedPrescription.queue.service.name}}</div>
        </div>
        <div class="row mb-2" v-if="patientConfig.printPrescriptionHeader.invoiceDate">
          <div class="col-3 form-label">{{ $t('prescriptionConfig.invoiceDate') }}:</div>
          <div class="col-9">{{selectedPrescription.createdAt | formatDayTime}}</div>
        </div>
      </div>
    </div>
    <div class="medicine-content-title">Rp.</div>
    <div class="mb-4">
      <div class="row med-header" :style="{'borderColor': (type==='classified'? 'green': '')}">
        <div class="col text-uppercase">{{ $t('prescriptionConfig.medName')}}</div>
        <div class="col text-uppercase">{{$t('prescriptionConfig.usage')}}</div>
        <div class="col text-uppercase">{{$t('prescriptionConfig.dosage')}}</div>
        <div class="col text-uppercase">{{$t('prescriptionConfig.frequency')}}</div>
        <div class="col text-uppercase">{{$t('prescriptionConfig.duration')}}</div>
        <div class="col text-uppercase">{{$t('prescriptionConfig.qty')}}</div>
        <div class="col text-uppercase">{{$t('prescriptionConfig.precautions')}}</div>
      </div>
      <div class="row med-body" 
           :style="{'borderColor': (type==='classified'? 'green': '')}"
           v-for="item in items"
           :key="item._id">
        <div class="col">{{item.name}}</div>
        <div class="col">{{item.dosageUsage}}</div>
        <div class="col">{{`${item.dosageDose} ${item.dosageUnit}`}}</div>
        <div class="col">{{item.dosageFreq}}</div>
        <div class="col">{{item.duration}}</div>
        <div class="col">{{item.qty}} {{item.unit}}</div>
        <div class="col">
          <div>{{item.precaution1}}</div>
          <div>{{item.precaution2}}</div>
        </div>
      </div>
    </div>
    <div class="mb-5 row med-footer">
      <div class="col-6 form-label">
        <div class="footer-item">
          {{$t('prescriptionConfig.audit')}}:
        </div>
        <div class="footer-item">
          {{$t('prescriptionConfig.prepare')}}:
        </div>
        <div class="footer-item">
          {{$t('prescriptionConfig.checkAndDispense')}}:
        </div>
      </div>
      <div class="col-6 form-label">
        <div class="footer-item">
            {{$t('prescriptionConfig.price')}}: 
          <template v-if="!!itemTotalPrice">
            {{ region.currency }}{{ itemTotalPrice | to2Decimal(region.decimal) | thousandSeparator(region.thousandSeparator)}}
          </template>
        </div>
        <div class="footer-item">
          {{$t('prescriptionConfig.physician')}}:
        </div>
      </div>
    </div>
    <div v-if="type==='classified'" style="page-break-after: always;"></div>
  </div>
</template>
<script>
import moment from 'moment';
import moneyFormatter from '@/components/mixins/money';

export default {
  name: 'PrescriptionFormItemPrint',
  mixins: [moneyFormatter],
  props: {
    generalConfig: {
      type: Object,
      default: () => ({}),
    },
    patientConfig: {
      type: Object,
      default: () => ({}),
    },
    selectedPrescription: {
      type: Object,
      default: () => ({}),
    },
    preview: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: []
    },
    type: {
      type: String
    }
  },
  data() {
    return {
    };
  },
  computed: {
    splitAddress() {
      const address = this.selectedPatient.address.split(',');
      if (this.selectedPatient.unit) {
        address.splice(-1, 0, this.selectedPatient.unit);
      }
      return address;
    },
    logoMarginLeft() {
      if (localStorage.getItem('printSetup')) {
        return JSON.parse(localStorage.getItem('printSetup')).logoMarginLeft;
      }
      return 0;
    },
    logoWidth() {
      if (localStorage.getItem('printSetup')) {
        return JSON.parse(localStorage.getItem('printSetup')).logoWidth;
      }
      return 300;
    },
    itemTotalPrice(){
      if(this.items.length === 0) return 0;
      let price = this.items.reduce((total,item) => {
        return total + Number(item.afterTaxPrice)
      }, 0)

      return price
    }
  },
  filters: {
    formatDayTime(value) {
      if(!value) return
      return moment(value).format('YYYY/MM/DD');
    },
    translateGender(val) {
      const map = { O: 'Other', M: 'Male', F: 'Female' };
      return map[val] || '';
    }
  }
};
</script>

<style scoped src="../../assets/print.css"></style>
<style lang="scss" scoped>
  .classified-medicine-tip {
    font-size: 20px;
    font-weight: bold;
    margin-top: 40px;
  }

  .clinic-name {
    margin-top: 10px;
  }
  .clinic-address {
    font-weight: bold;
    margin-top: 10px;
  }
  .form-label {
    font-weight: bold;
  }
  .medicine-content-title {
    font-size: 20px;
    font-weight: bold;
  }
  .med-header {
    font-weight: bold;
    padding: 10px 0;
    border-width: 2px 0 2px 0;
    border-style: solid;
    border-color: #333;
    margin-left: 0;
    margin-right: 0;
  }
  
  .med-body {
    padding: 10px 0;
    border-bottom: 1px solid #333;
    margin-left: 0;
    margin-right: 0;
  }
  .dividing-wrap {
    font-weight: bold;
    padding-top: 10px;
    border-top: 2px solid #333;
    margin-top: 30px;
  }

  .med-footer {
    display: flex;
    align-items: flex-end;

    .footer-item {
      padding: 16px 0;
    }
  }
</style>
<style lang="scss">
  @media print{
    
  } 
</style>
