<template>
  <bulk-corp-insurance
    :corpPayments="corpPaymentsData"
    :corpData="corpData"
    :generalConfig="generalConfig">
  </bulk-corp-insurance>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import BulkCorpInsurance from '../../components/Print/BulkCorpInsurance.vue';
const { mapGetters } = createNamespacedHelpers('invoice');

export default {
  name: 'CorpInsurBulkPrint',
  components: {
    BulkCorpInsurance,
  },
  computed: {
    ...mapGetters(['print']),
    generalConfig() {
      return this.$store.state.config.general;
    },
    corpPaymentsData() {
      return this.print.tableData;
    },
    corpData() {
      return this.print.corpData;
    },
  },
};
</script>