<template>
  <!-- eslint-disable max-len -->
  <div class="oneclick-button">
    <button class="btn btn-outline-primary" v-if="!finalized" @click.prevent.stop="onClick">{{ item.label }}</button>
    <div v-else>{{ answer }}</div>
  </div>
</template>
<script>
/*
Spec:
A button to click to get a value and replace itself with the value.

How and where to get the value depends on its `source` field.
if `source` is string, like qnsIndex, the value will be fetched
from this qns object' answer field.
if `source` is object, like `{ func: 'functionName', args: ['arg1', 'arg2'] }`
you need to write down the functionName here, DO NOT write any function to db.

e.g. :

*/
import moment from 'moment';

export default {
  name: 'OneClickButton',
  props: {
    item: {
      type: Object,
      default: function fn() {},
    },
    qns: {
      type: String,
    },
    updateTypingFormData: {
      type: Function,
      default: function fn(/* answer, qns */) {},
    },
    viewing: {
      type: Boolean,
      default: false,
    },
    printing: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: function fn() {},
    },
    coords: {
      type: Array,
      default: function fn() { return []; },
    },
  },
  computed: {
    answer() {
      if (!this.item || !this.item.answer) return '';
      const { value, finalized } = this.item.answer;
      if (!finalized || !value) return '';
      return value;
    },
    finalized() {
      if (!this.item || !this.item.answer) return false;
      const { finalized } = this.item.answer;
      if (!finalized) return false;
      return true;
    },
  },
  data() {
    return {
      funcs: { },
    };
  },
  mounted() {
    this.funcs = {
      getGestionalAge(formData, coords, qnsIdx, type) {
        function calculateGA({ usEDDDate, lmpEDDDate, radioChecked }, fromDay) {
          if (!fromDay) return '';
          if (!usEDDDate && !lmpEDDDate) return '';
          const today = moment(fromDay);
          let edd;
          if (radioChecked === 'useUS') {
            edd = moment(usEDDDate);
          }
          if (radioChecked === 'useLMP') {
            edd = moment(lmpEDDDate);
          }
          if (!edd || !edd.isValid()) return '';
          const days = today.diff(edd, 'days') + 280;
          const weekAndDays = [Math.floor(days / 7), days % 7];
          return [days, weekAndDays];
        }

        let fromDay;
        if (Array.isArray(coords) && coords.length === 2) {
          const [rowIdx, idx] = coords;
          const item = formData[qnsIdx];
          if (!item
            || !item.answer
            || !Array.isArray(item.answer) || !item.answer[rowIdx]) return null;
          fromDay = item.answer[rowIdx][idx - 1];
          if (!fromDay) return null;
        } else {
          fromDay = new Date();
        }

        const gdCalcItem = Object.values(formData).find(item => item.type === 'misc-gdcalc');
        if (!gdCalcItem) return null;
        const { answer } = gdCalcItem;
        if (!answer) return null;
        const [usEDDDate, lmpEDDDate, radioChecked] = answer;
        const result = calculateGA({ usEDDDate, lmpEDDDate, radioChecked }, fromDay);
        if (!result || !Array.isArray(result) || !result.length === 2) {
          return null;
        }
        try {
          const [days, weekAndDays] = result;
          const [weeks, reminder] = weekAndDays;
          if (type === 'days') {
            return `${days} Days`;
          }
          if (type === 'weekAndDays') {
            const weekLabel = weeks <= 1 ? 'Week' : 'Weeks';
            const dayLabel = reminder <= 1 ? 'Day' : 'Days';
            return `${weeks} ${weekLabel} ${reminder} ${dayLabel}`;
          }
        } catch (e) {
          return null;
        }
        return null;
      },
    };
  },
  methods: {
    getAnswerByQnsIdx(qnsIdx) {
      const qnsItem = this.formData[qnsIdx];
      if (!qnsItem) return null;
      const { answer } = qnsItem;
      if (!answer) return null;
      return `${answer}`;
    },
    onClick() {
      const { source } = this.item;
      let value = null;

      if (typeof source === 'string') {
        // get answer from this source. source mustbe qnsIndex
        value = this.getAnswerByQnsIdx(source);
      } else {
        const { func, args } = source;
        if (typeof this.funcs[func] === 'function') {
          value = this.funcs[func](this.formData, this.coords, this.qns, ...args);
        }
      }
      if (value !== null && value !== undefined) {
        const answer = {
          finalized: true,
          value,
        };
        this.updateTypingFormData(answer, this.qns);
      }
    },
  },
};
</script>
<style>
.oneclick-button {
  padding: 5px 0;
  text-align: center;
}
</style>
