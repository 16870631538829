<template>
    <div>
      <div class="form-group row" :class="formSchema[group].containerClass" v-for="group in formGroups" :key="group" :data-tik="tik">
        <!-- checkbox and radio, always grouped -->
        <label class="col-sm-12" :class="formSchema[group].labelClass" v-if="isFreetext(formSchema[group].type)">
            {{ formSchema[group].i18nId ? $t(formSchema[group].i18nId) : formSchema[group].label }}
        </label>
        <label class="col-sm-12"
          :class="[{ multiline: formSchema[group].multiline }, formSchema[group].labelClass]"
          v-if="!isFreetext(formSchema[group].type)">
          {{ formSchema[group].i18nId ? $t(formSchema[group].i18nId) : formSchema[group].label }}
          <span v-if="formSchema[group].required"
            class="badge badge-pill badge-danger">{{ $t('general.required') }}</span>
        </label>
        <div class="col-sm-12" v-if="isInput(formSchema[group].type)">
          <input
            type="text"
            class="form-control"
            v-model="formData[group]"
            :class="formSchema[group].inputClass"
            :disabled="formSchema[group].disabled || false"
          />
        </div>
        <div class="col-sm-12" v-if="isTextArea(formSchema[group].type)">
          <textarea
            class="form-control"
            v-model="formData[group]"
            :class="formSchema[group].inputClass"
            :disabled="formSchema[group].disabled || false"
          />
        </div>
        <div class="col-sm-12" v-if="isRichText(formSchema[group].type)">
          <rich-text-editor
            :qns="group"
            :html="formData[group]"
            :updateTypingFormData="updateRichTextFormData"
            />
        </div>
        <div class="col-sm-12" v-if="isSelect(formSchema[group].type)">
          <select
            class="form-control"
            v-model="formData[group]"
            :class="formSchema[group].inputClass"
            :disabled="formSchema[group].disabled || false"
          >
            <option
              v-for="(val, index) in formSchema[group].options"
              :key="index"
              :value="val">{{ val }}</option>
          </select>
        </div>
        <div class="col-sm-12" v-if="isDateTime(formSchema[group].type)">
          <date-picker
            v-model="formData[group]"
            :inputClass="['form-control', formSchema[group].inputClass]"
            :disabled="formSchema[group].disabled || false"
          >
          </date-picker>
        </div>
        <!-- Group Inputs -->
        <div class="col-sm-12" v-if="isGroup(formSchema[group].type)">
            <div
              class="group_input col-sm-4"
              v-for="(input, index) in formSchema[group].groups"
              :key="index"
              :class="formSchema[group].groupClass"
            >
                <div class="custom-control custom-radio custom-control-inline"
                  :class="input.groupClass"
                  v-if="isRadio(input.type)">
                  <input type="radio"
                    :id="loopUniqueId(formSchema[group].label, index)"
                    :name="formSchema[group].label"
                    class="custom-control-input"
                    :value="input.value || ''"
                    :class="input.inputClass"
                    :disabled="input && input.disabled || false"
                    v-model="formData[group]"/>
                  <label class="custom-control-label"
                    :class="input.labelClass"
                    :for="loopUniqueId(formSchema[group].label, index)">
                    {{ input.i18nId ? $t(input.i18nId) : input.label }}</label>
                </div>
                <div class="custom-control custom-checkbox" :class="input.groupClass" v-if="isCheckbox(input.type)">
                    <input type="checkbox" class="custom-control-input"
                      :id="loopUniqueId(input.label, index)"
                      :value="input.value || ''"
                      :class="input.inputClass"
                      :disabled="input && input.disabled || false"
                      v-model="formData[group]">
                    <label class="custom-control-label"
                      :class="input.labelClass"
                      :for="loopUniqueId(input.label, index)">
                      {{ input.i18nId ? $t(input.i18nId) : input.label }}</label>
                </div>
                <!-- if input is grouped, each input must have model -->
                <div class="form-group" :class="input.groupClass" v-if="isInput(input.type)">
                    <label class="form-label"
                      :class="input.labelClass"
                      :for="loopUniqueId(input.label, index)">
                      {{ input.i18nId ? $t(input.i18nId) : input.label }}</label>
                    <input type="text" class="form-control"
                      :placeholder="input.placeholder"
                      :class="input.inputClass"
                      :disabled="input && input.disabled || false"
                      v-model="formData[input.model]"/>
                </div>
                <!-- if date is grouped, date must have model -->
                <div class="form-group" :class="input.groupClass" v-if="isDateTime(input.type)">
                    <label class="form-label"
                      :class="input.labelClass"
                      :for="loopUniqueId(input.label, index)">
                      {{ input.i18nId ? $t(input.i18nId) : input.label }}</label>
                    <date-picker
                      :inputClass="['form-control', input.inputClass]"
                      :disabled="input && input.disabled || false"
                      v-model="formData[input.model]"
                    ></date-picker>
                </div>
                <div class="form-group" :class="input.groupClass" v-if="isFreetext(input.type)">
                  <label class="form-label" :class="input.labelClass">
                      {{ input.i18nId ? $t(input.i18nId) : input.label }}
                  </label>
                </div>
            </div>
        </div>
        <div class="col-sm-12" v-if="isYesNoSpecify(formSchema[group].type)">
          <div class="form-row align-items-center">
            <div class="col-1">
              <div class="custom-control custom-radio ">
                <input type="radio"
                  :id="`${formSchema[group].label}Yes`"
                  :name="formSchema[group].label"
                  class="custom-control-input"
                  :class="formSchema[group].inputClass"
                  value="yes"
                  v-model="formData[group]"/>
                <label class="custom-control-label"
                  :class="formSchema[group].labelClass"
                  :for="`${formSchema[group].label}Yes`">
                  {{ $t('tcmhq.yes') }}
                </label>
              </div>
            </div>
            <div class="col-1">
              <div class="custom-control custom-radio ">
                <input type="radio"
                  :id="`${formSchema[group].label}No`"
                  :name="formSchema[group].label"
                  value="no"
                  class="custom-control-input"
                  :class="formSchema[group].inputClass"
                  v-model="formData[group]"/>
                <label class="custom-control-label"
                  :class="formSchema[group].labelClass"
                  :for="`${formSchema[group].label}No`">
                  {{ $t('tcmhq.no') }}
                </label>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="" class="form-label" :class="formSchema[group].labelClass">
                  {{ $t('tcmhq.please_specify')}}
                </label>
                <input type="text" class="form-control"
                  :class="formSchema[group].inputClass"
                  v-model="formData[`${group}_input`]"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import _ from 'lodash';
import DatePicker from 'vuejs-datepicker';
import moment from 'moment';
import RichTextEditor from '@/components/NoteEditorWidget/RichTextEditor.vue';

export default {
  name: 'JsonSchemaForm',
  props: {
    schema: {
      type: Object,
      required: true,
    },
    schemaData: {
      type: Object,
      required: true,
    },
    sourceData: {
      type: Object,
      default: function fn() {
        return {};
      },
    },
  },
  components: {
    DatePicker,
    RichTextEditor,
  },
  data() {
    return {
      formData: {},
      tik: 0,
    };
  },
  watch: {
    sourceData: {
      deep: true,
      immediate: true,
      handler(v) {
        this.updateFormData();
      },
    },
  },
  computed: {
    formGroups() {
      return this.schema ? Object.keys(this.schema.properties) : [];
    },
    formSchema() {
      return this.schema ? this.schema.properties : {};
    },
    formGroupCheckbox() {
      return this.formGroups.filter((group) => {
        const isGroup = this.isGroup(this.formSchema[group].type);
        const isWithGroup = this.formSchema[group].groups
          && this.formSchema[group].groups.length > 0;
        return isGroup && isWithGroup && this.isCheckbox(this.formSchema[group].groups[0].type);
      });
    },
  },
  methods: {
    isInput(val) {
      return !this.isFreetext(val) && val === 'text';
    },
    isDateTime(val) {
      return !this.isFreetext(val) && val === 'date';
    },
    isSelect(val) {
      return !this.isFreetext(val) && val === 'select';
    },
    isRadio(val) {
      return !this.isFreetext(val) && val === 'radio';
    },
    isCheckbox(val) {
      return !this.isFreetext(val) && val === 'checkbox';
    },
    isTextArea(val) {
      return !this.isFreetext(val) && val === 'textarea';
    },
    isRichText(val) {
      return !this.isFreetext(val) && val === 'richtext';
    },
    isGroup(val) {
      return !this.isFreetext(val) && val === 'group';
    },
    isFreetext(val) {
      return val === 'freetext';
    },
    isYesNoSpecify(val) {
      return val === 'yesNoSpecify';
    },
    loopUniqueId(val, id) {
      return `${val}_${id}`;
    },
    dateInput(e, format = 'DD MMM YYYY', form, key) {
      if (format === 'X') {
        // eslint-disable-next-line no-param-reassign
        form[key] = moment(e).unix();
      } else {
        // eslint-disable-next-line no-param-reassign
        form[key] = moment(e).format(format);
      }
    },
    updateRichTextFormData(data, group) {
      this.formData[group] = data;
    },
    updateFormData() {
      const output = this.schemaData || {};
      this.formGroupCheckbox.forEach((checkbox) => {
        output[checkbox] = (checkbox in output && Array.isArray(output[checkbox])
          ? output[checkbox]
          : []);
      });
      // auto populate from sourceData
      if (!_.isEmpty(this.sourceData)) {
        this.formGroups.forEach((key) => {
          const item = this.formSchema[key];
          if (!item) return;
          if (this.isGroup(item.type)) {
            const toPopulate = item.groups.filter(i => i.type === 'input' && i.populateKey);
            toPopulate.forEach((p) => {
              output[p.model] = _.get(this.sourceData, p.populateKey);
            });
          }
          if (item.populateKey) {
            output[key] = _.get(this.sourceData, item.populateKey);
          }
        });
      }
      this.formData = output;
      this.tik += 1;
    },
  },
};
</script>
<style scoped lang="scss">
  .group_input {
    display: inline-block;
  };
  input {
    border: 1px solid #ced4da !important;
  }
  .vdp-datepicker input[type='text'] {
    border: 1px solid #ced4da !important;
  }
  label.multiline {
    white-space: pre-line;
    /*
     Due to HTML template and CSS pre-line, we need to remove the first line.
     NOTE: https://stackoverflow.com/questions/34857373/how-to-hide-first-line-for-white-spacepre-line-in-firefox-browser
     */
    &:first-line {
      line-height: 0px;
    }
  }
</style>
