<template>
  <patient-letter
    :name="selectedLetter.name"
    :text="selectedLetter.text"
    :clinicId="selectedLetter.clinic"
    :clinic="generalConfig"
    :patient="patient"
    :printLetterHeaderConfig="printLetterHeaderConfig"
    :printLetterFooterConfig="printLetterFooterConfig"
    :tabIndex="configIndexPos"
    :preview="false"
  />
</template>

<script>
import PatientLetter from '@/components/Print/Letter.vue';
import computedMixins from '@/components/mixins/computed';
import { createNamespacedHelpers } from 'vuex';

const { mapGetters: mapPatientGetters,
        mapState: mapPatientState,
        mapActions: mapPatientActions 
} = createNamespacedHelpers('patient');
const {
  mapGetters: mapConfigGetters,
} = createNamespacedHelpers('config');

export default {
  name: 'PrintPatientLabel',
  components: {
    PatientLetter,
  },
  mixins: [computedMixins],
  watch: {
  },
  data() {
    return {
    };
  },
  computed: {
    configIndexPos() {
      return ['General', 'Medical Certificate', 'Referral'].indexOf(this.selectedLetter.type || 'General');
    },
    printLetterHeaderConfig() {
      return (this.patientConfig && this.patientConfig.printLetterHeader) || {};
    },
    printLetterFooterConfig() {
      return (this.patientConfig && this.patientConfig.printLetterFooter) || {};
    },
    ...mapPatientState({ selectedLetter: 'print' }),
    ...mapConfigGetters({
      generalConfig: 'general',
      patientConfig: 'patient'
    }),
    ...mapPatientGetters({
      patient: 'selectedPatient'
    }),
  },
};
</script>
