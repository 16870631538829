import stockCardService from '@/services/stockcard.service';

import {
  SET_ALL_FROMJSON,
  SET_NEXT_ID,
  RESET_STATE,
} from './type.mutation';

const mapper = {
  JSONtoStockcard: (json) => {
    let stockcard = {};
    if (json) {
      const {
        _id: id, givenId, dateDispensed, clinic,
        requestor, items, stockist, patient = '',
        status, sigURL,
      } = json;
      stockcard = {
        id,
        givenId,
        clinic,
        movementType: json.movementType || '',
        dateDispensed,
        requestor: requestor._id,
        requestor_name: requestor.name,
        items: items.map(expndbl => ({
          id: (expndbl.expendable && expndbl.expendable._id) || null,
          name: (expndbl.expendable && expndbl.expendable.name) || '',
          unit: (expndbl.expendable && expndbl.expendable.unit) || '',
          trademark: (expndbl.expendable && expndbl.expendable.trademark) || '',
          quantity: expndbl.quantity,
          selectedBatch: expndbl.selectedBatch,
          batch: ''
        })),
        status,
        stockist: stockist._id,
        patient: patient._id || '',
        sigURL,
        stockist_name: stockist.name,
        patient_name: patient.name || '',
      };
    }
    return stockcard;
  },
  StockcardToJSON: (obj) => {
    let jsonData = {};
    if (obj) {
      const {
        givenId, clinic, dateDispensed,
        requestor, items, stockist, patient,
        status, sigURL,
      } = obj;
      jsonData = {
        givenId,
        clinic,
        dateDispensed,
        movementType: obj.movementType || '',
        requestor,
        items: items.map(expndbl => ({
          expendable: expndbl.id,
          quantity: expndbl.quantity,
          selectedBatch: expndbl.selectedBatch
        })),
        stockist,
        patient,
        status,
        sigURL
      };
      if (obj.id) {
        jsonData._id = obj.id;
      }
    }
    return jsonData;
  },
};

const state = {
  all: [],
  changedStockCard: null,
  nextID: null,
};

/* eslint-disable no-param-reassign */
const mutations = {
  [SET_ALL_FROMJSON](_state, payload) {
    _state.all = payload.map(item => mapper.JSONtoStockcard(item));
  },
  [SET_NEXT_ID](_state, payload) {
    _state.nextID = payload;
  },
  [RESET_STATE](_state) {
    _state.all = [];
    _state.changedStockCard = null;
    _state.nextID = null;
  },
};

const actions = {
  reset({ commit }) {
    commit(RESET_STATE);
  },
  loadStockCards({ commit }, param = {}) {
    commit('setLoading', true, { root: true });
    return stockCardService.list(param)
      .then((response) => {
        commit(SET_ALL_FROMJSON, response.data);
        commit('setLoading', false, { root: true });
        return true;
      }).catch(() => false);
  },
  createStockCard({ commit }, payload) {
    commit('setLoading', true, { root: true });
    return stockCardService.create(mapper.StockcardToJSON(payload))
      .then((response) => {
        commit('setLoading', false, { root: true });
        return response.success;
      });
  },
  acknowledgeStockCard({ commit }, { stockcard, image }) {
    commit('setLoading', true, { root: true });
    const serializedData = mapper.StockcardToJSON(stockcard);
    return stockCardService.updateWithImage(serializedData._id, serializedData, image)
      .then((response) => {
        commit('setLoading', false, { root: true });
        return response.success;
      }).catch(() => false);
  },
  loadNextPossibleID({ commit }) {
    return stockCardService.getNextID()
      .then((response) => {
        commit(SET_NEXT_ID, response.data);
        return true;
      }).catch(() => false);
  },
};

const getters = {
  all: _state => _state.all,
  nextID: _state => _state.nextID,
  IDList: _state => _state.all.map(stockcard => stockcard.id),
  filtered: _state => ({
    requestor, trademark, status, search,
  }) => (_state.all
    .filter(item => (requestor ? (item.requestor === requestor) : true))
    .filter(item => (trademark ? (item.items.some(i => i.trademark === trademark)) : true))
    .filter(item => (status ? (item.status === status) : true))
    .filter((item) => {
      const searchRegExp = new RegExp(search, 'i');
      return search
        ? item.givenId.match(searchRegExp)
          || item.requestor_name.match(searchRegExp)
        : true;
    })
  ),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
