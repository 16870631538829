import {
  SET_SELECTED_RECEIVE_HR_NOTIFICATION,
  SET_UNCOMPLETED_COUNT
} from "./type.mutation";
import receiveHrNotificationService from "@/services/receive-hr-notification.service";
const state = {
  selectedReceiveHrNotification: {},
  count: 0
};

/* eslint-disable no-param-reassign */
const mutations = {
  [SET_SELECTED_RECEIVE_HR_NOTIFICATION](_state, payload) {
    _state.selectedReceiveHrNotification = payload;
  },
  [SET_UNCOMPLETED_COUNT](_state, payload) {
    _state.count = payload;
  }
};

const actions = {
  getReceiveHrNotificationById({ commit }, id) {
    commit("setLoading", true, { root: true });
    return receiveHrNotificationService
      .getReceiveHrNotificationById(id)
      .then(response => {
        if (response.ok) {
          commit(SET_SELECTED_RECEIVE_HR_NOTIFICATION, response.data);
        }
        commit("setLoading", false, { root: true });
        return response;
      });
  },
  update({ commit, dispatch }, payload) {
    commit("setLoading", true, { root: true });
    return receiveHrNotificationService
      .updateReceiveHrNotification(payload)
      .then(response => {
        commit("setLoading", false, { root: true });
        dispatch("getUnCompletedCount");
        return response;
      });
  },
  getUnCompletedCount({ commit }) {
    commit("setLoading", true, { root: true });
    receiveHrNotificationService
      .getReceiveHrNotificationUnCompletedCount()
      .then(response => {
        if (response.ok) {
          commit(SET_UNCOMPLETED_COUNT, response.data.count);
        } else {
          throw new Error(`Fatal: ${response}`);
        }
        commit("setLoading", false, { root: true });
      });
  }
};

const getters = {
  selectedReceiveHrNotification: _state => _state.selectedReceiveHrNotification,
  receiveHrNotificationUnCompletedCount: _state => _state.count
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
