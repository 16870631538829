import api from './api';


export default {
  get() {
    const path = '/notecategory';
    return api.get(path);
  },
  fetchAll() {
    return api.get('/notecategory');
  },
  createNew(data) {
    return api.post('/notecategory', data);
  },
  updateNotecategory(id, data) {
    return api.put(`/notecategory/${id}`, data);
  },
};
