import constant from '@/services/constant';

export default {
  computed: {
    currentActivation() {
      return this.$store.state.membership.activations;
    },
    membership() {
      return this.$store.state.config.membership || {};
    },
    enableMembership() {
      return this.membership.enableModule;
    },
    isManualRedeem() {
      return this.membership.pointDeductionType === constant.MEMBERSHIP.POINTS_DEDUCTION.MANUAL;
    },
    membershipStats() {
      return this.$store.state.membership.stats;
    },
    redeemableItems() {
      return this.invoice.items
        .filter(i => i.redeemable)
        .map((i) => {
          let isRedeemed = false;
          if (!!i.redeemedPoint) {
            isRedeemed = true;
          }
          return {
            name: i.name,
            point: i.pointToRedeem * +i.redeemQty,
            originalQty: i.qty,
            pointToRedeem: i.pointToRedeem,
            qty: i.redeemQty,
            price: i.price,
            uuid: i.uuid,
            isRedeemed,
          };
        });
    },
    enoughToRedeemAll() {
      const totalPoints = this.redeemableItems
        .filter(i => !i.isRedeemed).reduce((a, b) => a + b.point, 0);
      if (totalPoints === 0) return false;
      return this.totalRedeemablePoints >= totalPoints;
    },
    totalRedeemedPoints() {
      return this.invoice.items.reduce((a, b) => a + b.redeemedPoint, 0);
    },
    totalReservedPoints() {
      const reserve = this.$store.state.membership.reservationByInvoice[this.invoice._id] || 0;
      return (this.membershipStats.reservedPoints || 0) - reserve;
    },
    totalRedeemablePoints() {
      return this.membershipStats.redeemablePoints - (this.totalRedeemedPoints + this.totalReservedPoints);
    },
    hasMembershipActivated() {
      return !!(Object.keys(this.currentActivation).length);
    },
    redeem() {
      return this.invoice.items.filter(i => i.redeemedPoint > 0)
        .reduce((a, b) => {
          a.point = a.point + b.redeemedPoint;
          a.amount = a.amount + b.redeemAmountAfterTax;
          return a;
        }, { point: 0, amount: 0 });
    },
  },
};
