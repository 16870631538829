import providerService from '@/services/provider.service';

import {
  SET_ALL_PROVIDERS,
  SET_PROVIDER,
  SET_PROVIDER_BY_USERID,
  SET_PROFILE_PIC,
} from './type.mutation';

const state = {
  all: [],
  providersMappedById: {},
  selectedProvider: null,
  providersMappedByUserId: {},
};

/* eslint-disable no-param-reassign */
const mutations = {
  [SET_ALL_PROVIDERS](_state, payload) {
    _state.all = payload;
    (payload || []).forEach((provider) => {
      _state.providersMappedById[provider._id] = provider;
    });
  },
  [SET_PROVIDER_BY_USERID](_state, payload) {
    _state.all = payload;
    (payload || []).forEach((provider) => {
      _state.providersMappedByUserId[provider.user] = provider;
    });
  },
  [SET_PROVIDER](_state, payload) {
    _state.selectedProvider = payload ? { ...payload } : null;
  },
  [SET_PROFILE_PIC](_state, payload) {
    _state.selectedProvider = {
      ...(_state.selectedProvider),
      profilePicKey: payload.profilePicKey || '',
      profilePicURL: payload.profilePicURL || '',
    };
  },
};

const actions = {
  fetchAllProviders({ commit }, param = {}) {
    return providerService.fetchAll(param)
      .then((response) => {
        commit(SET_ALL_PROVIDERS, response.data);
        commit(SET_PROVIDER_BY_USERID, response.data);
        return response.data;
      });
  },
  resetAll({ commit }) {
    commit(SET_ALL_PROVIDERS, []);
  },
  setSelectedProvider({ commit }, payload) {
    commit(SET_PROVIDER, payload);
  },
  unsetSelectedProvider({ commit }) {
    commit(SET_PROVIDER, null);
  },
  createNewProvider({ commit }, payload) {
    commit('setLoading', true, { root: true });
    return providerService.create(payload)
      .then((response) => {
        if (response.success) {
          commit(SET_PROVIDER, response.data);
        }
        commit('setLoading', false, { root: true });
        return response;
      });
  },
  updateProfilePic({ commit }, { providerId, file }) {
    return providerService.updateProviderProfilePic(providerId, file)
      .then((response) => {
        if (response.ok) {
          commit(SET_PROFILE_PIC, response.data);
        }
        return response;
      });
  },
  updateProvider({ commit }, payload) {
    commit('setLoading', true, { root: true });
    return providerService.update(payload._id, payload)
      .then((response) => {
        if (response.success) {
          commit(SET_PROVIDER, response.data);
        }
        commit('setLoading', false, { root: true });
        return response;
      });
  },
  deleteProvider({ commit }, payload) {
    commit('setLoading', true, { root: true });
    return providerService.delete(payload._id)
      .then((response) => {
        if (response.success) {
          commit(SET_PROVIDER, null);
        }
        commit('setLoading', false, { root: true });
        return response;
      });
  },
};

const getters = {
  all: _state => _state.all,
  providersOptionMap: _state => _state.all
    .map(provider => ({
      value: provider._id,
      text: provider.name,
    })),
  doctorsOptionMap: _state => _state.all
    .filter(doctor => doctor.role === 'provider')
    .map(doctor => ({
      value: doctor._id,
      text: doctor.name,
    })),
  therapistOptionMap: _state => _state.all
    .filter(doctor => doctor.role === 'therapist')
    .map(doctor => ({
      value: doctor._id,
      text: doctor.name,
    })),
  consultantOptionMap: _state => _state.all
    .filter(doctor => doctor.role === 'consultant')
    .map(doctor => ({
      value: doctor._id,
      text: doctor.name,
    })),
  selectedProvider: _state => _state.selectedProvider,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
