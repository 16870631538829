export const SET_METADATA = 'SET_METADATA';
export const SET_INDUCTIONS = 'SET_INDUCTIONS';
export const SET_INPUTS = 'SET_INPUTS';
export const SET_EVENTS = 'SET_EVENTS';
export const SET_END_OF_SURGERY = 'SET_END_OF_SURGERY';
export const SET_INDUCTION_FINALIZED = 'SET_INDUCTION_FINALIZED';
export const SET_FINALIZED = 'SET_FINALIZED';
export const SET_AIRWAYS = 'SET_AIRWAYS';
export const SET_TEMPECGS = 'SET_TEMPECGS';
export const SET_VITALS = 'SET_VITALS';
