
import _ from 'lodash';
import moment from 'moment';

import api from '@/services/api';

export default {
  list(params) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);
    Object.entries(params).forEach(([key, val]) => {
      let data = val;
      if (val && (key === 'fromDate' || key === 'toDate')) {
        const momentDate = moment(val);
        data = momentDate.isValid() ? momentDate.format('YYYY-MM-DD') : false;
      }
      if (data) {
        q.push(`${key}=${data}`);
      }
    });
    const url = `/pharmacy/?${q.join('&')}`;

    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`List Error: ${response}, url: ${url}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },


  create(payload) {
    const url = '/pharmacy/';
    return new Promise((resolve, reject) => {
      api.post(url, payload).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            // TODO: Chan : Make this cooler
            // get at least one from array of errors
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },


  update(id, payload) {
    const url = `/pharmacy/${id}`;
    return new Promise((resolve, reject) => {
      api.put(url, payload).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            // TODO: Chan : Make this cooler
            // get at least one from array of errors
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },

  getToDo(params) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);
    Object.entries(params).forEach(([key, val]) => {
      let data = val;
      if (val && (key === 'fromDate' || key === 'toDate')) {
        const momentDate = moment(val);
        data = momentDate.isValid() ? momentDate.format('YYYY-MM-DD') : false;
      }
      if (data) {
        q.push(`${key}=${data}`);
      }
    });
    const url = `/pharmacy/todo?${q.join('&')}`;

    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body ? response.body.count : 0;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`List Error: ${response}, url: ${url}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },
};
