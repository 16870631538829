import {
  FETCH_ALL_AREAS, SET_AREA, RESET_ALL_AREAS, RESET_AREA,
  SET_ASSIGNED_ROOMS,
} from './type.mutation';

import areaService from '@/services/area.service';

const state = {
  allAreas: [],
  selectedArea: {},
  assignedRooms: [],
};

const mutations = {
  [FETCH_ALL_AREAS](_state, payload) {
    // eslint-disable-next-line no-param-reassign
    _state.allAreas = payload;
  },
  [RESET_ALL_AREAS](_state) {
    // eslint-disable-next-line no-param-reassign
    _state.allAreas = [];
  },
  [SET_AREA](_state, payload) {
    // eslint-disable-next-line no-param-reassign
    _state.selectedArea = payload;
  },
  [RESET_AREA](_state) {
    // eslint-disable-next-line no-param-reassign
    _state.selectedArea = {};
  },
  [SET_ASSIGNED_ROOMS](_state, payload) {
    // eslint-disable-next-line no-param-reassign
    _state.assignedRooms = payload;
  },
};

const actions = {
  fetchAreas({ commit }, params) {
    return areaService.list(params)
      .then((response) => {
        commit(FETCH_ALL_AREAS, response.data);
        return response.data;
      });
  },
  createNewArea({ commit }, payload) {
    return areaService.create(payload)
      .then(response => response.data);
  },
  updateArea({ commit }, { id, payload }) {
    return areaService.update(id, payload)
      .then(respose => respose.data);
  },
  setSelectedArea({ commit }, payload) {
    commit(SET_AREA, payload);
  },
};

const getters = {
  allAreas: _state => _state.allAreas,
  selectedArea: _state => _state.selectedArea,
  assignedRooms: _state => _state.assignedRooms,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
