import api from './api';


export default {
  get(params) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);

    Object.keys(params).forEach((key) => {
      q.push(`${key}=${params[key]}`);
    });

    const url = `/notetemplate/paginated/?${q.join('&')}`;
    console.log('url is ', url);
    return api.get(url);
  },
  fetchAll({ excludeFields = [] }) {
    let url = '/notetemplate';
    if (excludeFields && excludeFields.length > 0) {
      // eslint-disable-next-line prefer-template
      url += '?fields=' + excludeFields.map(f => `-${f}`).join(',');
    }
    return api.get(url);
  },
  fetchConsultNoteTemplates({ excludeFields = [] }) {
    let url = '/notetemplate/consultnotetemplates';
    if (excludeFields && excludeFields.length > 0) {
      // eslint-disable-next-line prefer-template
      url += '?fields=' + excludeFields.map(f => `-${f}`).join(',');
    }
    return api.get(url);
  },
  fetchOne(params) {
    let url = `/notetemplate/${params.id}?keys=${params.keys}`;
    if (params.returnFullDoc) {
      url += `&returnFullDoc=${params.returnFullDoc}`;
    }
    return api.get(url);
  },
  search(params) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);

    Object.keys(params).forEach((key) => {
      q.push(`${key}=${params[key]}`);
    });

    const url = `/notetemplate/search?${q.join('&')}`;
    return api.get(url);
  },
  // fetchOne(id) {
  //   return api.get(`/note/${id}`);
  // },
  createNew(data) {
    return api.post('/notetemplate', data);
  },
  updateNote(id, data) {
    return api.put(`/notetemplate/${id}`, data);
  },
  deleteTemplate(id) {
    return api.put(`/notetemplate/delete/${id}`);
  },
  delete(id) {
    return api.delete(`/notetemplate/${id}`);
  },
  uploadTemplate() {
    return api.put('/notetemplate/uploadTemplate');
  },
  tagDefault(id, flag) {
    const url = `/notetemplate/tag/${id}/${flag}`;
    return new Promise((resolve, reject) => {
      api.put(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`Error: ${response.data.message}`),
              { data: null, success: false },
            );
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },
};
