var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: { readonly: _vm.viewing || _vm.printing },
      attrs: { id: "xy-note-editor" }
    },
    [
      _c("editor-menu-bar", {
        attrs: { editor: _vm.editor },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var commands = ref.commands
              var isActive = ref.isActive
              return [
                !(_vm.viewing || _vm.printing)
                  ? _c("div", { staticClass: "ProseMirror-menubar" }, [
                      _c(
                        "span",
                        {
                          class: [
                            "ProseMirror-item",
                            { "is-active": isActive.bold() }
                          ],
                          on: { click: commands.bold }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "ProseMirror-icon" },
                            [_c("v-icon", { attrs: { name: "bold" } })],
                            1
                          )
                        ]
                      ),
                      _c(
                        "span",
                        {
                          class: [
                            "ProseMirror-item",
                            { "is-active": isActive.italic() }
                          ],
                          on: { click: commands.italic }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "ProseMirror-icon" },
                            [_c("v-icon", { attrs: { name: "italic" } })],
                            1
                          )
                        ]
                      ),
                      _c(
                        "span",
                        {
                          class: [
                            "ProseMirror-item",
                            { "is-active": isActive.underline() }
                          ],
                          on: { click: commands.underline }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "ProseMirror-icon" },
                            [_c("v-icon", { attrs: { name: "underline" } })],
                            1
                          )
                        ]
                      ),
                      _c(
                        "span",
                        {
                          class: [
                            "ProseMirror-item",
                            { "is-active": isActive.strike() }
                          ],
                          on: { click: commands.strike }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "ProseMirror-icon" },
                            [
                              _c("v-icon", { attrs: { name: "strikethrough" } })
                            ],
                            1
                          )
                        ]
                      ),
                      _c("span", { staticClass: "ProseMirror-menuseparator" }),
                      _c(
                        "span",
                        {
                          class: [
                            "ProseMirror-item",
                            { "is-active": isActive.heading({ level: 1 }) }
                          ],
                          on: {
                            click: function($event) {
                              return commands.heading({ level: 1 })
                            }
                          }
                        },
                        [
                          _c("div", { staticClass: "ProseMirror-icon" }, [
                            _c("span", { staticClass: "xy-h1 h-icon" }, [
                              _vm._v("H1")
                            ])
                          ])
                        ]
                      ),
                      _c(
                        "span",
                        {
                          class: [
                            "ProseMirror-item",
                            { "is-active": isActive.heading({ level: 2 }) }
                          ],
                          on: {
                            click: function($event) {
                              return commands.heading({ level: 2 })
                            }
                          }
                        },
                        [
                          _c("div", { staticClass: "ProseMirror-icon" }, [
                            _c("span", { staticClass: "xy-h2 h-icon" }, [
                              _vm._v("H2")
                            ])
                          ])
                        ]
                      ),
                      _c(
                        "span",
                        {
                          class: [
                            "ProseMirror-item",
                            { "is-active": isActive.heading({ level: 3 }) }
                          ],
                          on: {
                            click: function($event) {
                              return commands.heading({ level: 3 })
                            }
                          }
                        },
                        [
                          _c("div", { staticClass: "ProseMirror-icon" }, [
                            _c("span", { staticClass: "xy-h3 h-icon" }, [
                              _vm._v("H3")
                            ])
                          ])
                        ]
                      ),
                      _c("span", { staticClass: "ProseMirror-menuseparator" }),
                      _c(
                        "span",
                        {
                          class: [
                            "ProseMirror-item",
                            { "is-active": isActive.bullet_list() }
                          ],
                          on: { click: commands.bullet_list }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "ProseMirror-icon" },
                            [_c("v-icon", { attrs: { name: "list-ul" } })],
                            1
                          )
                        ]
                      ),
                      _c(
                        "span",
                        {
                          class: [
                            "ProseMirror-item",
                            { "is-active": isActive.ordered_list() }
                          ],
                          on: { click: commands.ordered_list }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "ProseMirror-icon" },
                            [_c("v-icon", { attrs: { name: "list-ol" } })],
                            1
                          )
                        ]
                      ),
                      _c("span", { staticClass: "ProseMirror-menuseparator" }),
                      _c(
                        "span",
                        {
                          staticClass: "ProseMirror-item",
                          on: { click: commands.undo }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "ProseMirror-icon" },
                            [_c("v-icon", { attrs: { name: "undo" } })],
                            1
                          )
                        ]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "ProseMirror-item",
                          on: { click: commands.redo }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "ProseMirror-icon" },
                            [_c("v-icon", { attrs: { name: "redo" } })],
                            1
                          )
                        ]
                      )
                    ])
                  : _vm._e()
              ]
            }
          }
        ])
      }),
      _c("editor-content", { attrs: { editor: _vm.editor } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }