var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vue-good-table", {
    attrs: { columns: _vm.spec.columns, rows: _vm.spec[_vm.type].rows },
    scopedSlots: _vm._u([
      {
        key: "table-column",
        fn: function(props) {
          return [
            props.column.i18nId
              ? _c("span", [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t(props.column.i18nId)) + "\n    "
                  )
                ])
              : _c("span", [
                  _vm._v("\n      " + _vm._s(props.column.label) + "\n    ")
                ])
          ]
        }
      },
      {
        key: "table-row",
        fn: function(props) {
          return [
            props.column.field == "item"
              ? [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "occupationalService.integrationReport." +
                            props.row.key
                        )
                      )
                    )
                  ])
                ]
              : props.column.field == "value"
              ? [
                  _vm.has(_vm.data, props.row.key)
                    ? _c("span", [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.data[props.row.key]) +
                            "\n      "
                        )
                      ])
                    : _vm._e()
                ]
              : _vm._e()
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }