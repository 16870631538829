var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _c("vue-signature-pad", {
          ref: _vm.id,
          staticClass: "signature-canvas",
          attrs: {
            width: _vm.width,
            height: _vm.height,
            saveType: _vm.saveType,
            options: {
              onBegin: function() {
                _vm.$refs[_vm.id].resizeCanvas()
              }
            }
          }
        }),
        _c("div", { staticClass: "signature-context" }, [_vm._t("default")], 2)
      ],
      1
    ),
    !_vm.hideSign
      ? _c("div", { staticClass: "col-12 signature-btns" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-info",
              attrs: { disabled: _vm.viewing },
              on: {
                click: function($event) {
                  return _vm.undo($event)
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("general.clear")))]
          ),
          _vm._v(" \n        "),
          _c(
            "button",
            {
              staticClass: "btn btn-success",
              attrs: { disabled: _vm.viewing },
              on: {
                click: function($event) {
                  return _vm.accept($event)
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("setting.accept")))]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }