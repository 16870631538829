import Moment from 'moment';

export default {
  filters: {
    htmlBreakFormatter(value = '') {
      return value.replace(/(?:\r\n|\r|\n)/g, '<br/>');
    },
  },
  methods: {
    upperCase(value) {
      return value ? value.toUpperCase() : '';
    },
    translateGender(value) {
      if (value === 'F') return 'Female';
      if (value === 'M') return 'Male';
      if (value === 'O') return 'Other';
      return '';
    },
    ddMonthYYYY(value) {
      return value ? Moment(value).format('DD MMM YYYY') : '';
    },
    to2Decimal(value) {
      return parseFloat(Math.round(value * 100) / 100).toFixed(2);
    },
    kebabCase(automationTagName) {
      return _.isString(automationTagName) ? _.kebabCase(automationTagName) : '';
    },
  },
};
