var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "authorisation-form" },
    [
      _c("div", { staticClass: "row form-group" }, [
        _c("label", { staticClass: "col-3" }, [_vm._v("Select User")]),
        _c("div", { staticClass: "col" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.authorisationForm.email,
                  expression: "authorisationForm.email"
                }
              ],
              staticClass: "form-control",
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.authorisationForm,
                    "email",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                }
              }
            },
            _vm._l(_vm.providersOrTherapistArray, function(user) {
              return _c(
                "option",
                { key: user.id, domProps: { value: user.email } },
                [_vm._v(_vm._s(user.name) + " - " + _vm._s(user.email))]
              )
            }),
            0
          )
        ])
      ]),
      _c("div", { staticClass: "row form-group" }, [
        _c("label", { staticClass: "col-3" }, [_vm._v("Password")]),
        _c("div", { staticClass: "col" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.authorisationForm.password,
                expression: "authorisationForm.password"
              }
            ],
            staticClass: "form-control",
            attrs: { type: "password", autocomplete: "false" },
            domProps: { value: _vm.authorisationForm.password },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.authorisationForm, "password", $event.target.value)
              }
            }
          })
        ])
      ]),
      _c(
        "b-button",
        {
          staticClass: "float-right my-3",
          attrs: { variant: "outline-primary" },
          on: { click: _vm.onConfirmVerify }
        },
        [_vm._v("\n      Verify\n    ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }