import { cloneDeep } from 'lodash';

import stimulationChartService from '@/services/stimulation-chart.service';
import {
  SET_ACTIVE_STIMULATION_CHART,
  SET_STIMULATION_LIST,
  UPDATE_STIMULATION_LIST,
} from './type.mutation';

const state = {
  activeStimulationChart: {},
  stimulationList: [],
};

const mutations = {
  [SET_ACTIVE_STIMULATION_CHART](_state, payload) {
    // eslint-disable-next-line no-param-reassign
    _state.activeStimulationChart = payload;
  },
  [SET_STIMULATION_LIST](_state, payload) {
    // eslint-disable-next-line no-param-reassign
    _state.stimulationList = payload;
  },
  [UPDATE_STIMULATION_LIST](_state, payload) {
    // eslint-disable-next-line no-param-reassign
    _state.stimulationList = _state.stimulationList.map((s) => {
      if (s._id !== payload._id) {
        return s;
      }
      return payload;
    });
  },
};

const actions = {
  fetchStimulationChartList({ commit }, params = {}) {
    return stimulationChartService.list(params)
      .then((resp) => {
        commit(SET_STIMULATION_LIST, resp.data);
        commit(SET_ACTIVE_STIMULATION_CHART, resp.data[0] || {});
        return resp.data;
      });
  },
  setActiveStimulationChart({ commit }, payload) {
    commit(SET_ACTIVE_STIMULATION_CHART, payload);
  },
  createStimulationChart({ commit }, data) {
    return stimulationChartService.create(data)
      .then((resp) => {
        const chart = resp.data;
        commit(SET_ACTIVE_STIMULATION_CHART, chart);
        // commit(ADD_TO_STIMULATION_LIST, chart);
        return chart;
      });
  },
  updateNotes({ commit }, data) {
    return stimulationChartService.updateNotes(data)
      .then((resp) => {
        const chart = resp.data;
        commit(SET_ACTIVE_STIMULATION_CHART, chart);
        commit(UPDATE_STIMULATION_LIST, chart);
        return chart;
      });
  },
  updateTreatmentAndProceduresNote({ commit }, data) {
    return stimulationChartService.updateTreatmentAndProceduresNote(data)
      .then((resp) => {
        const chart = resp.data;
        commit(SET_ACTIVE_STIMULATION_CHART, chart);
        commit(UPDATE_STIMULATION_LIST, chart);
        return chart;
      });
  },
  updateBetaHCGDate({ commit }, data) {
    return stimulationChartService.updateBetaHCGDate(data)
      .then((resp) => {
        const chart = resp.data;
        commit(SET_ACTIVE_STIMULATION_CHART, chart);
        commit(UPDATE_STIMULATION_LIST, chart);
        return chart;
      });
  },
  createVisitItem({ commit }, data) {
    return stimulationChartService.createVisitItem(data)
      .then((resp) => {
        const chart = resp.data;
        commit(SET_ACTIVE_STIMULATION_CHART, chart);
        commit(UPDATE_STIMULATION_LIST, chart);
        return chart;
      });
  },
  updateVisitItem({ commit }, data) {
    return stimulationChartService.updateVisitItem(data)
      .then((resp) => {
        const chart = resp.data;
        commit(SET_ACTIVE_STIMULATION_CHART, chart);
        commit(UPDATE_STIMULATION_LIST, chart);
        return chart;
      });
  },
  patchStimulationChart({ commit }, payload) {
    const data = cloneDeep(payload);
    const { id } = data;
    delete data.id;
    delete data.createdAt;
    return stimulationChartService.patch(id, data)
      .then((resp) => {
        const chart = resp.data;
        commit(SET_ACTIVE_STIMULATION_CHART, chart);
        commit(UPDATE_STIMULATION_LIST, chart);
        return chart;
      });
  },
};

const getters = {
  activeStimulationChart: _state => _state.activeStimulationChart,
  stimulationList: _state => _state.stimulationList,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
