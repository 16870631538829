import Vue from 'vue';
import i18n from '@/i18n';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import Moment from 'moment';

import tcmConsultationServices from '@/services/tcm-consultation.service';

/* eslint-disable no-param-reassign */
export const isChildOf = (child, parent) => {
  if (child && parent) {
    let { parentNode } = child;
    while (parentNode) {
      if (parent === parentNode) return true;
      parentNode = parentNode.parentNode;
    }
  }
  return false;
};

export function getPopupElementSize(element) {
  const originalDisplay = element.style.display;
  const originalVisibility = element.style.visibility;
  element.style.display = 'block';
  element.style.visibility = 'hidden';
  const styles = window.getComputedStyle(element);
  const width = element.offsetWidth + parseInt(styles.marginLeft, 10) + parseInt(styles.marginRight, 10);
  const height = element.offsetHeight + parseInt(styles.marginTop, 10) + parseInt(styles.marginBottom, 10);
  element.style.display = originalDisplay;
  element.style.visibility = originalVisibility;
  return { width, height };
}

/**
 * get the popup position
 * @param {HTMLElement} el relative element
 * @param {Number} targetWidth target element's width
 * @param {Number} targetHeight target element's height
 * @param {Boolean} fixed
 */
export function getRelativePosition(el, targetWidth, targetHeight, fixed) {
  let left = 0;
  let top = 0;
  let offsetX = 0;
  let offsetY = 0;
  const relativeRect = el.getBoundingClientRect();
  const dw = document.documentElement.clientWidth;
  const dh = document.documentElement.clientHeight;
  if (fixed) {
    offsetX = window.pageXOffset + relativeRect.left;
    offsetY = window.pageYOffset + relativeRect.top;
  }
  if (dw - relativeRect.left < targetWidth && relativeRect.right < targetWidth) {
    left = offsetX - relativeRect.left + 1;
  } else if (relativeRect.left + relativeRect.width / 2 <= dw / 2) {
    left = offsetX;
  } else {
    left = offsetX + relativeRect.width - targetWidth;
  }
  if (relativeRect.top <= targetHeight && dh - relativeRect.bottom <= targetHeight) {
    top = offsetY + dh - relativeRect.top - targetHeight;
  } else if (relativeRect.top + relativeRect.height / 2 <= dh / 2) {
    top = offsetY + relativeRect.height;
  } else {
    top = offsetY - targetHeight;
  }
  return { left: `${left}px`, top: `${top}px` };
}

export function getScrollParent(node, until = document.body) {
  if (!node || node === until) {
    return null;
  }

  const style = (value, prop) => getComputedStyle(value, null).getPropertyValue(prop);

  const regex = /(auto|scroll)/;

  const scroll = regex.test(
    style(node, 'overflow') + style(node, 'overflow-y') + style(node, 'overflow-x'),
  );

  return scroll ? node : getScrollParent(node.parentNode, until);
}

export const getInterDisciplinaryUIState = async ({
  selectedPatientId,
  selectedClinicId,
  selectedProviderId,
  selectedConcultationId,
  parseDateValueFromX,
}) => {
  const getReferToUIState = ({ type, existingRefererSettingList }) => {
    let to = '';
    let isReadOnly = false;
    const existingReferTo = _.chain(existingRefererSettingList)
      .find(item => item.from._id === selectedProviderId && item.type === type) // NOTE: find the record set up by current providerId
      .value();
    if (existingReferTo) {
      to = existingReferTo.to.id;
    }
    if (existingReferTo && existingReferTo.status === 'CLOSED') {
      isReadOnly = true;
    }
    return {
      to,
      isReadOnly,
    };
  };

  const getReferSourceUIState = ({ type, refererList, allRefererList }) => {
    let source = '';
    let isReadOnly = true; // NOTE: not allow user to choose recommendation source

    const typeReferList = _.chain(refererList).filter(item => item.type === type).map(item => ({
      text: `${item.from.name}-${parseDateValueFromX(item.visitDate, 'YYYY-MM-DD', 'x')}`,
      value: item,
    })).value();
    const suggestedTypeReferSource = _.chain(allRefererList)
      .find(item => item.to._id === selectedProviderId && item.status !== 'CLOSED' && item.type === type)
      .value();
    const foundConsumedRecommandedType = _.chain(typeReferList)
      .filter(item => item.value.consumedByConsultation === selectedConcultationId && item.value.type === type)
      .get('0.value')
      .value();

    // NOTE: disabled if already received recommendation from other provider, we don't allow user to change it.
    if (!typeReferList.length) {
      isReadOnly = true;
    } else if (foundConsumedRecommandedType) {
      source = foundConsumedRecommandedType;
      isReadOnly = true;
    } else if (suggestedTypeReferSource) {
      source = suggestedTypeReferSource;
      isReadOnly = true;
    }

    return {
      source,
      isReadOnly,
      typeReferList,
    };
  };

  if (!selectedProviderId) throw new Error('No providerId selected');
  const params = {
    patient: selectedPatientId,
    clinic: selectedClinicId,
  };
  const resp = await tcmConsultationServices.listReferer(params);
  const { success, data: allRefererList } = resp;
  if (!success) throw new Error('API call fail');
  const existingRefererSettingList = allRefererList.filter(item => item.consultation === selectedConcultationId);
  const refererList = allRefererList.filter(item => (item.to._id === selectedProviderId));

  // NOTE: get referTo UI state
  const { to: acupunctureTo, isReadOnly: disableAcTo } = getReferToUIState({ type: 'Acupuncture', existingRefererSettingList });
  const { to: tuinaTo, isReadOnly: disableTuTo } = getReferToUIState({ type: 'Tuina', existingRefererSettingList });
  const { to: eltTo, isReadOnly: disableEltTo } = getReferToUIState({ type: 'Elt', existingRefererSettingList });

  // NOTE: get referSource UI state
  const { source: acupunctureSource, isReadOnly: disableAcSource, typeReferList: acupunctureReferList } = getReferSourceUIState({ type: 'Acupuncture', refererList, allRefererList });
  const { source: tuinaSource, isReadOnly: disableTuSource, typeReferList: tuinaReferList } = getReferSourceUIState({ type: 'Tuina', refererList, allRefererList });
  const { source: eltSource, isReadOnly: disableEltSource, typeReferList: eltReferList } = getReferSourceUIState({ type: 'Elt', refererList, allRefererList });

  // NOTE: next UI state
  return {
    refererList,
    //
    acupunctureTo,
    tuinaTo,
    eltTo,
    //
    acupunctureSource,
    tuinaSource,
    eltSource,
    //
    disableAcTo,
    disableTuTo,
    disableEltTo,
    //
    disableAcSource,
    disableTuSource,
    disableEltSource,
    //
    acupunctureReferList,
    tuinaReferList,
    eltReferList,
  };
};

export const herbalMedicineOrderInformationComputedGetter = ({ namespace = '' } = {}) => ({
  ...mapGetters('tcmConsultation', {
    [`${namespace}dataToPrint`]: 'dataToPrint',
  }),
  /* eslint-disable */
  [`${namespace}WYNNote`]() {
    return require('@/assets/tcm/wyn/note.jpg');
  },
  [`${namespace}WYNNotice`]() {
    return require('@/assets/tcm/wyn/notice.jpg');
  },
  [`${namespace}WYNMap`]() {
    return require('@/assets/tcm/wyn/map.jpg');
  },
  // /* eslint-enable */
  [`${namespace}patientName`]() {
    return _.chain(this).get(`${namespace}dataToPrint.selectedPatient.name`).value();
  },
  [`${namespace}patientLocalName`]() {
    return _.chain(this).get(`${namespace}dataToPrint.selectedPatient.localName`).value();
  },
  [`${namespace}patientNRIC`]() {
    return _.chain(this).get(`${namespace}dataToPrint.selectedPatient.nric`).value();
  },
  [`${namespace}patientPhoneNumber`]() {
    return _.chain(this).get(`${namespace}dataToPrint.selectedPatient.mobile`).value();
  },
  [`${namespace}patientTelephone`]() {
    return _.chain(this).get(`${namespace}dataToPrint.selectedPatient.telephone`).value();
  },
  [`${namespace}today`]() {
    return Date.now();
  },
  // NOTE: 门诊助理：
  [`${namespace}creatorName`]() {
    return _.chain(this).get(`${namespace}dataToPrint.loggedInProvider.name`).value();
  },
  // NOTE: 其他
  [`${namespace}decoctByFactory`]() {
    return _.chain(this).get(`${namespace}dataToPrint.decoctByFactory`).value();
  },
  [`${namespace}totalLiquidPacks`]() {
    return _.chain(this).get(`${namespace}dataToPrint.totalLiquidPacks`).value();
  },
  [`${namespace}valuable`]() {
    return _.chain(this).get(`${namespace}dataToPrint.valuable`).value();
  },
  // NOTE: 取药方式
  [`${namespace}takingBy`]() {
    return _.chain(this).get(`${namespace}dataToPrint.takingBy`).value();
  },
  [`${namespace}deliveryDate`]() {
    return _.chain(this).get(`${namespace}dataToPrint.deliveryDate`).value();
  },
  [`${namespace}deliveryTime`]() {
    return _.chain(this).get(`${namespace}dataToPrint.deliveryTime`).value();
  },
  [`${namespace}deliveryToPatientAddress`]() {
    return _.chain(this).get(`${namespace}dataToPrint.deliveryToPatientAddress`).value();
  },
  [`${namespace}deliveryAddress`]() {
    return _.chain(this).get(`${namespace}dataToPrint.deliveryAddress`).value();
  },
  [`${namespace}deliveryPostalCode`]() {
    return _.chain(this).get(`${namespace}dataToPrint.deliveryPostalCode`).value();
  },
  // NOTE: 顾客签字确认
  [`${namespace}patientSig`]() {
    return _.chain(this).get(`${namespace}dataToPrint.patientSig`).value();
  },
  // NOTE: 自行煮药者煎法
  [`${namespace}decoctFirstWaterCount`]() {
    return _.chain(this).get(`${namespace}dataToPrint.decoctFirstWaterCount`).value();
  },
  [`${namespace}decoctFirstBowlCount`]() {
    return _.chain(this).get(`${namespace}dataToPrint.decoctFirstBowlCount`).value();
  },
  [`${namespace}decoctSecondWaterCount`]() {
    return _.chain(this).get(`${namespace}dataToPrint.decoctSecondWaterCount`).value();
  },
  [`${namespace}decoctSecondBowlCount`]() {
    return _.chain(this).get(`${namespace}dataToPrint.decoctSecondBowlCount`).value();
  },
  // NOTE: 服法（每项选其一）
  [`${namespace}instructionOne`]() {
    return _.chain(this).get(`${namespace}dataToPrint.instructionOne`).value();
  },
  [`${namespace}instructionTwo`]() {
    return _.chain(this).get(`${namespace}dataToPrint.instructionTwo`).value();
  },
});

// TBD: I'm not sure if this us a good idea to hard code here
export const POWDER_CATEGORIES = ['科中', '科复'];
export const HERBAL_CATEGORIES = ['草药'];
export const PATENT_CATEGORIES = ['保养药'];

export const VALUABLE_MED_TREATMENT = {
  SLICE_IN_ADVANCE: '提早切片',
  SLICE_ON_SITE: '现切片',
  GRIND_IN_ADVANCE: '提早磨粉',
  GRIND_ON_SITE: '现磨粉',
};

export const DELIVERY_TIME = {
  NINE_TO_TWELVE: '9am-12noon',
  TWELVE_TO_FIVE: '12noon-5pm',
  FIVE_TO_SEVEN: '5pm-7pm',
};

export const MK_INSTRUCTION_ONE = {
  BEFORE_MEAL: '饭前',
  AFTER_MEAL: '饭后',
  BEFORE_SLEEP: '睡前',
};

export const MK_INSTRUCTION_TWO = {
  BID: '早晚2次',
  QD_MORNING: '一天一次（早上）',
  QD_NIGHT: '一天一次（晚上）',
};

const translateMessage = (key) => {
  const dictionary = {
    ...VALUABLE_MED_TREATMENT,
    ...DELIVERY_TIME,
    ...MK_INSTRUCTION_ONE,
    ...MK_INSTRUCTION_TWO,
  };
  return _.get(dictionary, key, key || '');
}

export const getWYNEamilMessage = ({ data, sendableItems, invoiceId }) => {
  console.debug('getWYNEamilMessage/data:', data);
  const translatedData = _.chain(data)
    .toPairs()
    .map(([key, value]) => {
      // NOTE: implement translate strategy here
      if (key === 'deliveryDate') {
        return [key, value ? Moment(value).format('YYYY/MM/DD') : '']
      }
      if (key === 'decoctByFactory') {
        return [key, value ? '是' : '否']
      }
      return [key, translateMessage(value)]
    })
    .fromPairs()
    .value();
  // TODO: i18n support in the future maybe?
  const herbalMedicineMessage = sendableItems.map(({ name, qty, purchasingUnit }) => {
    return `藥材: ${name}, 單位: ${qty}${purchasingUnit}`
  }).join('\n');
  const output = `
    [Clinic]
    invoiceId: ${invoiceId}

    [藥材]
    ${herbalMedicineMessage}

    [顾客资料]
    门诊助理：${translatedData.creatorName || ''}
    Name：${translatedData.patientName || ''}
    姓名：${translatedData.patientLocalName || ''}
    NRIC：${translatedData.patientNRIC || ''}
    手机：${translatedData.patientPhoneNumber || ''}
    电话：${translatedData.patientTelephone || ''}

    [其他]
    代客煎药（只限顾客到黄耀南牛车水自取，不提供送货服务）：${translatedData.decoctByFactory || ''}
    ${translatedData.decoctByFactory ? `1 包药材包=2 包药液包，共 ${translatedData.totalLiquidPacks || ''} 药液包` : ''}
    贵重药材处理方式：${translatedData.valuable || ''}

    [取药方式]
    A.顾客自行凭此单取药：${translatedData.takingBy === 'BY_PATIENT' ? '是' : ''}
    B.送货 Delivery Order：${translatedData.takingBy === 'BY_FACTORY' ? '是' : ''}
    Delivery Date：${translatedData.deliveryDate || ''}
    Delivery Time：${translatedData.deliveryTime || ''}
    Delivery Address
    ${!translatedData.deliveryToPatientAddress ? `送马光分院：${translatedData.deliveryAddress || ''}` : ''}
    ${translatedData.deliveryToPatientAddress ? `顾客指定地址：${translatedData.deliveryAddress || ''}` : ''}
    ${translatedData.deliveryToPatientAddress ? `Postal Code：${translatedData.deliveryPostalCode || ''}` : ''}
    顾客签字确认：<img src="${translatedData.patientSig}" />

    [医师填写]
    自行煮药者煎法
    头煎（第一次煎）： ${translatedData.decoctFirstWaterCount || ''} 碗水，煎成 ${translatedData.decoctFirstBowlCount || ''}
    二煎（第二次煎）： ${translatedData.decoctSecondWaterCount || ''} 碗水，煎成 ${translatedData.decoctSecondBowlCount || ''}

    服法
    ${translatedData.instructionOne || ''}
    ${translatedData.instructionTwo || ''}
  `;
  console.debug('getWYNEamilMessage/output:', output);
  return output.replace(/\n/g, '<br />');
}

export const showModal = async ({
  component = null,
  confirmEventKey = 'confirmed',
  modalProps = {},
  componentProps = {},
  onEventMap = {},
}) => {
  let cmp;
  let output = null;
  const dom = document.createElement('div');
  const data = await new Promise((res) => {
    const id = `dynamic-modal-${Date.now()}`;
    document.body.appendChild(dom);
    cmp = new Vue({
      el: dom,
      i18n,
      render(h) {
        return h('b-modal', {
          attrs: {
            id,
          },
          props: {
            ...modalProps,
            visible: true,
          },
          on: {
            hide() {
              if (!output) {
                output = {
                  data: null,
                  ok: false,
                };
              }
            },
            hidden: () => {
              res(output);
            },
          },
        }, [
          h(component, {
            props: {
              ...componentProps,
            },
            on: {
              ...onEventMap,
              [confirmEventKey]: (value) => {
                output = {
                  data: value,
                  ok: true,
                };
                this.$bvModal.hide(id);
              },
            },
          }),
        ]);
      },
    });
    cmp.$el.remove(); // NOTE: remove comment element
  });
  cmp.$destroy();
  dom.remove();
  return data;
};
