export const ADD_RECORD_TIME = 'ADD_RECORD_TIME';
export const SET_RECORD_TIME = 'SET_RECORD_TIME';
export const ADD_TEMPARATURE_VITAL = 'ADD_TEMPARATURE_VITAL';
export const SET_TEMPARATURE_VITAL = 'SET_TEMPARATURE_VITAL';
export const ADD_RESPIRATORY_RATE = 'ADD_RESPIRATORY_RATE';
export const SET_RESPIRATORY_RATE = 'SET_RESPIRATORY_RATE';
export const ADD_HEART_RATE = 'ADD_HEART_RATE';
export const SET_HEART_RATE = 'SET_HEART_RATE';
export const ADD_PULSE_RATE = 'ADD_PULSE_RATE';
export const SET_PULSE_RATE = 'SET_PULSE_RATE';
export const ADD_ECG_RATE = 'ADD_ECG_RATE';
export const SET_ECG_RATE = 'SET_ECG_RATE';
export const ADD_BLOOD_PRESSURE_ABP = 'ADD_BLOOD_PRESSURE_ABP';
export const SET_BLOOD_PRESSURE_ABP = 'SET_BLOOD_PRESSURE_ABP';
export const ADD_BLOOD_PRESSURE_NIBP = 'ADD_BLOOD_PRESSURE_NIBP';
export const SET_BLOOD_PRESSURE_NIBP = 'SET_BLOOD_PRESSURE_NIBP';
export const ADD_CENTRAL_VENUS_PRESSURE = 'ADD_CENTRAL_VENUS_PRESSURE';
export const SET_CENTRAL_VENUS_PRESSURE = 'SET_CENTRAL_VENUS_PRESSURE';
export const ADD_PAIN_SCORE = 'ADD_PAIN_SCORE';
export const SET_PAIN_SCORE = 'SET_PAIN_SCORE';
export const ADD_SEDATION = 'ADD_SEDATION';
export const SET_SEDATION = 'SET_SEDATION';
export const ADD_NEUROLOGICAL = 'ADD_NEUROLOGICAL';
export const SET_NEUROLOGICAL = 'SET_NEUROLOGICAL';
export const ADD_PULILS = 'ADD_PULILS';
export const SET_PULILS = 'SET_PULILS';
export const ADD_COMMA_SCALE_ADULT = 'ADD_COMMA_SCALE_ADULT';
export const SET_COMMA_SCALE_ADULT = 'SET_COMMA_SCALE_ADULT';
export const ADD_BLADDER_PRESSURE = 'ADD_BLADDER_PRESSURE';
export const SET_BLADDER_PRESSURE = 'SET_BLADDER_PRESSURE';
