import NursingCirculationService from '@/services/nursing-circulation.service';

const state = {
};

/* eslint-disable no-param-reassign */
const mutations = {
};

const actions = {
  getCircultions({}, param = {}) {
    // TODO: chan - should get vaps on backend
    return NursingCirculationService.list(param)
      .then((response) => {
        return response;
      });
  },
  addCirculation({}, payload) {
    return NursingCirculationService.create(payload)
      .then((response) => {
        return response;
      });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};