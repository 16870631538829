/* eslint-disable no-param-reassign */
import wsasService from '@/services/wsas.service';
import {
  ADD_RECORD_TIME,
  SET_RECORD_TIME,
  ADD_WORK_SOCIAL_ADJUSTMENT_SCALE,
  SET_WORK_SOCIAL_ADJUSTMENT_SCALE,
} from './type.mutation';

export const state = {
  recordedDateTime: [],
  workSocialAdjustmentScales: {},
};

export const mutations = {
  [SET_RECORD_TIME](_state, dateTime) {
    _state.recordedDateTime = dateTime;
  },
  [ADD_RECORD_TIME](_state, dateTime) {
    _state.recordedDateTime.push(dateTime);
  },
  [ADD_WORK_SOCIAL_ADJUSTMENT_SCALE](_state, payload) {
    _state.workSocialAdjustmentScales = { ..._state.workSocialAdjustmentScales, ...payload };
  },
  [SET_WORK_SOCIAL_ADJUSTMENT_SCALE](_state, payload) {
    _state.workSocialAdjustmentScales = payload;
  },
};

export const actions = {
  recordWSAS({ commit }, payload) {
    return wsasService.create(payload)
      .then((response) => {
        commit(ADD_RECORD_TIME, payload.recordTime);
        commit(ADD_WORK_SOCIAL_ADJUSTMENT_SCALE, payload.workSocialAdjustmentScales);
        return response.data;
      });
  },
  fetchWSAS({ commit }, params = {}) {
    return wsasService.list(params)
      .then((response) => {
        commit(SET_RECORD_TIME, response.data.recordedDateTime);
        commit(SET_WORK_SOCIAL_ADJUSTMENT_SCALE, response.data.workSocialAdjustmentScales);
        return response.data;
      });
  },
  page({ commit }, payload) {
    commit(SET_RECORD_TIME, payload);
  },
};

export const getters = {
  recordedDateTime: _state => _state.recordedDateTime,
  workSocialAdjustmentScalesData: _state => _state.workSocialAdjustmentScales,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
