var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mb-5",
      class: { previewPrint: _vm.preview },
      staticStyle: { margin: "20px" }
    },
    [
      _c("div", { staticClass: "row mb-3" }, [
        _c("div", { staticClass: "col-6" }, [
          true
            ? _c("img", {
                style: {
                  marginLeft: _vm.logoMarginLeft + "px",
                  width: _vm.logoWidth + "px"
                },
                attrs: { src: this.companyClinicLogo }
              })
            : _vm._e()
        ]),
        _c(
          "div",
          { staticClass: "col-6", staticStyle: { "margin-top": "114px" } },
          [
            _c("h3", { staticClass: "mb-3 text-center text-uppercase" }, [
              _c("u", [
                _vm.withTaxes
                  ? _c("span", [_vm._v(_vm._s(_vm.$t("general.tax")))])
                  : _vm._e(),
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("general.invoice")) +
                    "\n        "
                )
              ])
            ])
          ]
        )
      ]),
      _c("div", { staticClass: "row mb-5" }, [
        _c("div", { staticClass: "col-5" }, [
          _c("div", { staticClass: "col-12 row" }, [
            _c("div", { staticClass: "col-6" }, [
              _vm._v(_vm._s(_vm.$t("general.patientName")))
            ]),
            _c("div", { staticClass: "col-6" }, [
              _vm._v(": " + _vm._s(_vm.selectedPatient.name || "-"))
            ])
          ]),
          _c("div", { staticClass: "col-12 row" }, [
            _c("div", { staticClass: "col-6" }, [
              _vm._v(_vm._s(_vm.$t("general.patientLocalName")))
            ]),
            _c("div", { staticClass: "col-6" }, [
              _vm._v(": " + _vm._s(_vm.selectedPatient.localName || "-"))
            ])
          ]),
          _c("div", { staticClass: "col-12 row" }, [
            _c("div", { staticClass: "col-6" }, [
              _vm._v(_vm._s(_vm.$t("makuang.PatientIC")))
            ]),
            _c("div", { staticClass: "col-6" }, [
              _vm._v(": " + _vm._s(_vm.selectedPatient.nric || "-"))
            ])
          ]),
          _c("div", { staticClass: "col-12 row" }, [
            _c("div", { staticClass: "col-6" }, [
              _vm._v(_vm._s(_vm.$t("makuang.Address")))
            ]),
            _c("div", { staticClass: "col-6" }, [
              _vm._v("\n          : " + _vm._s(_vm.splitAddress) + "\n        ")
            ])
          ]),
          _c("div", { staticClass: "col-12 row" }, [
            _c("div", { staticClass: "col-6" }, [
              _vm._v(_vm._s(_vm.$t("makuang.PostalCode")))
            ]),
            _c("div", { staticClass: "col-6" }, [
              _vm._v(": " + _vm._s(_vm.selectedPatient.postal || "-"))
            ])
          ])
        ]),
        _c(
          "div",
          { staticClass: "col-4 text-right" },
          _vm._l(_vm.invoiceCategory, function(category) {
            return _c("div", { key: category.id }, [
              _vm._v("\n        " + _vm._s(category.label) + "\n      ")
            ])
          }),
          0
        ),
        _c("div", { staticClass: "col-3" }, [
          _c("div", [_vm._v(":  " + _vm._s(_vm.print.invoiceNo))]),
          _c("div", [
            _vm._v(
              "\n        : \n        " +
                _vm._s(
                  _vm._f("formatVDDate")(_vm.print.date, {
                    sourceFormat: "X",
                    lang: _vm.$i18n.locale,
                    format: "DD MMM YYYY"
                  })
                ) +
                "\n      "
            )
          ]),
          _vm.printConfig.invoiceDateBE
            ? _c("div", [
                _vm._v(
                  "\n        : \n        " +
                    _vm._s(
                      _vm._f("formatVDDate")(_vm.print.date, {
                        sourceFormat: "X",
                        lang: "th",
                        format: "DD MMM YYYY"
                      })
                    ) +
                    "\n      "
                )
              ])
            : _vm._e(),
          _vm.general.useLocationAddress
            ? _c("div", [
                _vm._v(
                  "\n        :  " +
                    _vm._s(this.selectedClinic.regNo) +
                    "\n      "
                )
              ])
            : _vm._e(),
          !!_vm.general.companyRegNo && !_vm.general.useLocationAddress
            ? _c("div", [
                _vm._v(
                  "\n        :  " +
                    _vm._s(_vm.general.companyRegNo) +
                    "\n      "
                )
              ])
            : _vm._e(),
          _vm.general.useLocationAddress
            ? _c("div", [
                _vm._v(
                  "\n        :  " +
                    _vm._s(this.selectedClinic.taxNo) +
                    "\n      "
                )
              ])
            : _vm._e(),
          !!_vm.printConfig.regNumber && !_vm.general.useLocationAddress
            ? _c("div", [
                _vm._v(
                  "\n        :  " + _vm._s(_vm.general.gstRegNo) + "\n      "
                )
              ])
            : _vm._e(),
          _vm.printConfig.docName
            ? _c("div", [_vm._v(":  " + _vm._s(_vm.provider.name))])
            : _vm._e(),
          _vm.printConfig.docQual
            ? _c("div", [
                _vm._v(
                  "\n        :  " +
                    _vm._s(_vm.provider.qualifications) +
                    "\n      "
                )
              ])
            : _vm._e(),
          _vm.printConfig.therapistName
            ? _c("div", [
                _vm._v(
                  "\n        :  " +
                    _vm._s(_vm.print.therapist && _vm.print.therapist.name) +
                    "\n      "
                )
              ])
            : _vm._e()
        ])
      ]),
      _c("div", { staticClass: "row mb-5" }, [
        _c("div", { staticClass: "col-5" }, [
          _c("div", { staticClass: "col-12 row" }, [
            _c("div", { staticClass: "col-6" }, [
              _vm._v(_vm._s(_vm.$t("makuang.VisitBranch")))
            ]),
            _c("div", { staticClass: "col-6" }, [
              _vm._v(": " + _vm._s(_vm.invoiceBranchName || "-"))
            ])
          ]),
          _c("div", { staticClass: "col-12 row" }, [
            _c("div", { staticClass: "col-6" }, [
              _vm._v(_vm._s(_vm.$t("makuang.BranchAddress")))
            ]),
            _c("div", { staticClass: "col-6" }, [
              _vm._v(": " + _vm._s(_vm.invoiceAddress || "-"))
            ])
          ]),
          _c("div", { staticClass: "col-12 row" }, [
            _c("div", { staticClass: "col-6" }, [
              _vm._v(_vm._s(_vm.$t("makuang.TelFax")))
            ]),
            _c("div", { staticClass: "col-6" }, [
              _vm._v(": " + _vm._s(_vm.general.telephone || "-"))
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "row weight-600 bd-top bd-bottom" }, [
        _c("div", { staticClass: "col-4" }, [_vm._v("Service")]),
        _c("div", { staticClass: "col-4" }, [
          _vm._v("\n      " + _vm._s(_vm.$t("general.description")) + "\n    ")
        ]),
        _c("div", { staticClass: "col-2 text-center" }, [
          _vm._v("\n      " + _vm._s(_vm.$t("general.qty")) + "\n    ")
        ]),
        _c("div", { staticClass: "col-2 text-right" }, [
          _vm._v(_vm._s(_vm.$t("makuang.Amount")))
        ])
      ]),
      _vm.isCategorized
        ? _c(
            "div",
            _vm._l(_vm.categorized, function(category, idx) {
              return _c("div", { key: category.key }, [
                _c("div", { staticClass: "mt-3 row" }, [
                  _c(
                    "div",
                    {
                      staticClass: "col-4",
                      staticStyle: { "padding-right": "0px" }
                    },
                    [_vm._v("\n          " + _vm._s(idx) + "\n        ")]
                  ),
                  _c("div", {
                    staticClass: "col-4 weight-600 mb-2",
                    staticStyle: { "padding-right": "0px" }
                  }),
                  _c("div", {
                    staticClass: "col-2 weight-600 mb-2",
                    staticStyle: { "padding-right": "0px" }
                  }),
                  _c(
                    "div",
                    { staticClass: "col-2 weight-600 mb-2 text-right" },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.getTotalPricePerInvoiceCategory(category)
                          ) +
                          "\n        "
                      )
                    ]
                  )
                ])
              ])
            }),
            0
          )
        : _c(
            "div",
            _vm._l(_vm.unCategorized, function(category, idx) {
              return _c(
                "div",
                { key: category.key },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "mt-2" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col-12 weight-600",
                          staticStyle: { "padding-right": "0px" }
                        },
                        [
                          _vm._v(
                            "\n            " + _vm._s(idx) + "\n          "
                          )
                        ]
                      )
                    ])
                  ]),
                  _vm._l(category, function(item, id) {
                    return _c(
                      "div",
                      { key: item.key, staticClass: "mt-3 row" },
                      [
                        _c("div", {
                          staticClass: "col-4",
                          staticStyle: { "padding-right": "0px" }
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "col-4",
                            staticStyle: { "padding-right": "0px" }
                          },
                          [_vm._v("\n          " + _vm._s(id) + "\n        ")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "col-2 text-center",
                            staticStyle: { "padding-right": "0px" }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.getTotalQuantity(item)) +
                                "\n        "
                            )
                          ]
                        ),
                        _c("div", { staticClass: "col-2 text-right" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.getTotalPricePerInvoiceCategory(item)
                              ) +
                              "\n        "
                          )
                        ])
                      ]
                    )
                  })
                ],
                2
              )
            }),
            0
          ),
      _vm.redeem && _vm.redeem.point > 0
        ? _c("div", [
            _c("div", { staticClass: "row weight-600" }, [
              _c("div", { staticClass: "col-10 text-left" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("membership.redeemOfPoint")) +
                    " " +
                    _vm._s(_vm.region.currency) +
                    "\n      "
                )
              ]),
              _c("div", { staticClass: "col-2 text-right" }, [
                _vm._v(
                  "\n        -" +
                    _vm._s(
                      _vm._f("thousandSeparator")(
                        _vm._f("to2Decimal")(
                          _vm.redeem.amount,
                          _vm.region.decimal
                        ),
                        _vm.region.thousandSeparator
                      )
                    ) +
                    "\n      "
                )
              ])
            ])
          ])
        : _vm._e(),
      _c("div", { staticClass: "row mt-3 bd-top" }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-10 text-right" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("general.totalDiscount")) +
              "\n      " +
              _vm._s(
                _vm._f("shouldHideCurrencyIfConvertingToVC")(
                  _vm.region.currency,
                  _vm.convertToVirtualCurrency
                )
              ) +
              "\n    "
          )
        ]),
        _c("div", { staticClass: "col-2 text-right" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm._f("thousandSeparator")(
                  _vm._f("to2Decimal")(
                    _vm._f("toVirtualCurrency")(
                      _vm.print.totalDiscount,
                      _vm.virtualCurrencyConfig.exchangeRate,
                      _vm.convertToVirtualCurrency
                    ),
                    _vm.region.decimal
                  ),
                  _vm.region.thousandSeparator
                )
              ) +
              "\n    "
          )
        ])
      ]),
      _vm.withTaxes
        ? _c("div", { staticClass: "row marginRight" }, [
            _c("div", { staticClass: "col-10 text-right" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("general.beforeTax")) +
                  "\n      " +
                  _vm._s(
                    _vm._f("shouldHideCurrencyIfConvertingToVC")(
                      _vm.region.currency,
                      _vm.convertToVirtualCurrency
                    )
                  ) +
                  "\n    "
              )
            ]),
            _c("div", { staticClass: "col-2 text-right" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm._f("thousandSeparator")(
                      _vm._f("to2Decimal")(
                        _vm._f("toVirtualCurrency")(
                          Math.abs(
                            _vm.print.total - _vm.print.totalTax
                          ).toFixed(2),
                          _vm.virtualCurrencyConfig.exchangeRate,
                          _vm.convertToVirtualCurrency
                        ),
                        _vm.region.decimal
                      ),
                      _vm.region.thousandSeparator
                    )
                  ) +
                  "\n    "
              )
            ])
          ])
        : _vm._e(),
      _vm.withTaxes
        ? _c("div", { staticClass: "row marginRight" }, [
            _c("div", { staticClass: "col-10 text-right" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    (_vm.print.taxName || _vm.invoiceConfig.taxName) +
                      " " +
                      _vm.invoiceConfig.taxPercentageAmount +
                      " %"
                  ) +
                  "\n      " +
                  _vm._s(
                    _vm._f("shouldHideCurrencyIfConvertingToVC")(
                      _vm.region.currency,
                      _vm.convertToVirtualCurrency
                    )
                  ) +
                  "\n    "
              )
            ]),
            _c("div", { staticClass: "col-2 text-right" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm._f("thousandSeparator")(
                      _vm._f("to2Decimal")(
                        _vm._f("toVirtualCurrency")(
                          _vm.print.totalTax,
                          _vm.virtualCurrencyConfig.exchangeRate,
                          _vm.convertToVirtualCurrency
                        ),
                        _vm.region.decimal
                      ),
                      _vm.region.thousandSeparator
                    )
                  ) +
                  "\n    "
              )
            ])
          ])
        : _vm._e(),
      _c("div", { staticClass: "row marginRight" }, [
        _c("div", { staticClass: "col-3" }),
        _c("div", { staticClass: "col-7 text-right" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("general.rounding")) +
              "\n      " +
              _vm._s(
                _vm._f("shouldHideCurrencyIfConvertingToVC")(
                  _vm.region.currency,
                  _vm.convertToVirtualCurrency
                )
              ) +
              "\n    "
          )
        ]),
        _c("div", { staticClass: "col-2 text-right" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm._f("thousandSeparator")(
                  _vm._f("to2Decimal")(
                    _vm._f("toVirtualCurrency")(
                      _vm.print.roundingAdjustment,
                      _vm.virtualCurrencyConfig.exchangeRate,
                      _vm.convertToVirtualCurrency
                    ),
                    _vm.region.decimal
                  ),
                  _vm.region.thousandSeparator
                )
              ) +
              "\n    "
          )
        ])
      ]),
      _vm.processFees
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-4" }),
            _c("div", { staticClass: "col-3" }),
            _c("div", { staticClass: "col-3 bd-top text-right" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("paymentMode.processValue")) +
                  "\n      " +
                  _vm._s(
                    _vm._f("shouldHideCurrencyIfConvertingToVC")(
                      _vm.region.currency,
                      _vm.convertToVirtualCurrency
                    )
                  ) +
                  "\n    "
              )
            ]),
            _c("div", { staticClass: "col-2 bd-top text-right" }, [
              _vm.processFees
                ? _c("div", [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm._f("thousandSeparator")(
                            _vm._f("to2Decimal")(
                              _vm._f("toVirtualCurrency")(
                                _vm.processFees,
                                _vm.virtualCurrencyConfig.exchangeRate,
                                _vm.convertToVirtualCurrency
                              ),
                              _vm.region.decimal
                            ),
                            _vm.region.thousandSeparator
                          )
                        ) +
                        "\n      "
                    )
                  ])
                : _vm._e()
            ])
          ])
        : _vm._e(),
      _c("div", { staticClass: "row weight-600" }, [
        _c("div", { staticClass: "col-4" }),
        _c("div", { staticClass: "col-3" }),
        _c("div", { staticClass: "col-3 bd-top bd-bottom text-right" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("makuang.Total")) +
              "\n      " +
              _vm._s(
                _vm._f("shouldHideCurrencyIfConvertingToVC")(
                  _vm.region.currency,
                  _vm.convertToVirtualCurrency
                )
              ) +
              "\n    "
          )
        ]),
        _c("div", { staticClass: "col-2 bd-top bd-bottom text-right" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm._f("thousandSeparator")(
                  _vm._f("to2Decimal")(
                    _vm._f("toVirtualCurrency")(
                      _vm.print.total + _vm.processFees,
                      _vm.virtualCurrencyConfig.exchangeRate,
                      _vm.convertToVirtualCurrency
                    ),
                    _vm.region.decimal
                  ),
                  _vm.region.thousandSeparator
                )
              ) +
              "\n    "
          )
        ])
      ]),
      _c("div", { staticClass: "row marginRight mt-4" }, [
        _c("div", { staticClass: "col-10 text-right" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("makuang.TotalPaid")) +
              "\n      " +
              _vm._s(
                _vm._f("shouldHideCurrencyIfConvertingToVC")(
                  _vm.region.currency,
                  _vm.convertToVirtualCurrency
                )
              ) +
              "\n    "
          )
        ]),
        _c("div", { staticClass: "col-2 text-right" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm._f("thousandSeparator")(
                  _vm._f("to2Decimal")(
                    _vm._f("toVirtualCurrency")(
                      _vm.totalPaidAmount,
                      _vm.virtualCurrencyConfig.exchangeRate,
                      _vm.convertToVirtualCurrency
                    ),
                    _vm.region.decimal
                  ),
                  _vm.region.thousandSeparator
                )
              ) +
              "\n    "
          )
        ])
      ]),
      _c("div", { staticClass: "row marginRight" }, [
        _c("div", { staticClass: "col-10 text-right" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("makuang.Change")) +
              "\n\n      " +
              _vm._s(
                _vm._f("shouldHideCurrencyIfConvertingToVC")(
                  _vm.region.currency,
                  _vm.convertToVirtualCurrency
                )
              ) +
              "\n    "
          )
        ]),
        _c("div", { staticClass: "col-2 text-right" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm._f("thousandSeparator")(
                  _vm._f("to2Decimal")(
                    _vm._f("toVirtualCurrency")(
                      _vm.totalChange,
                      _vm.virtualCurrencyConfig.exchangeRate,
                      _vm.convertToVirtualCurrency
                    ),
                    _vm.region.decimal
                  ),
                  _vm.region.thousandSeparator
                )
              ) +
              "\n    "
          )
        ])
      ]),
      _c("div", { staticClass: "row mt-4" }, [
        _c(
          "div",
          {
            staticClass: "col-2 text-right",
            staticStyle: { "font-weight": "bolder" }
          },
          [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("makuang.PaymentDetails")) + " :\n    "
            )
          ]
        )
      ]),
      _vm._l(_vm.patientPayments, function(payment) {
        return _c("div", { key: payment.paymentId, staticClass: "row mt-4" }, [
          _c("div", { staticClass: "col-2 text-right" }, [
            _vm._v(
              "\n      " +
                _vm._s(payment.label) +
                "\n      " +
                _vm._s(_vm.region.currency) +
                "\n    "
            )
          ]),
          _c("div", { staticClass: "col-2" }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm._f("thousandSeparator")(
                    _vm._f("to2Decimal")(payment.amount, _vm.region.decimal),
                    _vm.region.thousandSeparator
                  )
                ) +
                "\n    "
            )
          ])
        ])
      }),
      _vm._l(_vm.creditPayments, function(payment) {
        return _c("div", { key: payment.paymentId, staticClass: "row mt-4" }, [
          _c("div", { staticClass: "col-10 text-right" }, [
            _vm._v(
              "\n      " +
                _vm._s(payment.mode) +
                " -- " +
                _vm._s(payment.paymentType) +
                " : " +
                _vm._s(_vm.region.currency) +
                "\n    "
            )
          ]),
          _c("div", { staticClass: "col-2 text-right" }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm._f("thousandSeparator")(
                    _vm._f("to2Decimal")(payment.amount, _vm.region.decimal),
                    _vm.region.thousandSeparator
                  )
                ) +
                "\n    "
            )
          ])
        ])
      }),
      _vm.virtualCurrencyConfig.enable
        ? _c("div", { staticClass: "row mt-3 weight-600" }, [
            _c("div", { staticClass: "col-10 text-right" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("general.current")) +
                  " " +
                  _vm._s(_vm.virtualCurrencyConfig.name) +
                  "\n      " +
                  _vm._s(_vm.$t("general.balance")) +
                  "\n    "
              )
            ]),
            _c("div", { staticClass: "col-2 text-right" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.print.virtualCurrencyBalanceRightAfterInvoiceRedemption
                  ) +
                  "\n      " +
                  _vm._s(_vm.virtualCurrencyConfig.currencyCode) +
                  "\n    "
              )
            ])
          ])
        : _vm._e(),
      _c("br"),
      _c("br"),
      _vm.withDiagnosis || _vm.printConfig.diagnosis
        ? [
            _c("div", { staticClass: "row weight-600" }, [
              _c("div", { staticClass: "col-12" }, [
                _vm._v(_vm._s(_vm.$t("general.diagnosis")) + ":")
              ])
            ]),
            _vm._l(_vm.print.diagnosis, function(item, index) {
              return _c("div", { key: index }, [
                _vm.multipleICD10Enabled
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-3" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              index === 0
                                ? "Primary Diagnosis"
                                : index === 1
                                ? "Secondary Diagnosis"
                                : "Additional Diagnosis"
                            ) +
                            "\n        "
                        )
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-9" },
                        _vm._l(item, function(icd) {
                          return _c("p", { key: icd.uuid }, [
                            _c("i", [_vm._v(_vm._s(icd && icd.text))])
                          ])
                        }),
                        0
                      )
                    ])
                  : _c(
                      "div",
                      _vm._l(item, function(icd) {
                        return _c("p", { key: icd.uuid }, [
                          _c("i", [_vm._v(_vm._s(icd && icd.text))])
                        ])
                      }),
                      0
                    ),
                _vm.multipleICD10Enabled ? _c("hr") : _vm._e()
              ])
            })
          ]
        : _vm._e(),
      _c("br"),
      _vm.printConfig.notes
        ? [
            _c("div", { staticClass: "weight-600" }, [
              _vm._v(_vm._s(_vm.$t("general.notes")) + ":")
            ]),
            _c("p", { staticStyle: { "white-space": "pre-line" } }, [
              _vm._v(_vm._s(_vm.print.notes))
            ])
          ]
        : _vm._e(),
      _c("br"),
      _vm.corpPayables.length
        ? [
            _c("div", { staticClass: "row weight-600" }, [
              _c("div", { staticClass: "col-12" }, [
                _vm._v(_vm._s(_vm.$t("general.amountDueFrom")) + ":")
              ])
            ]),
            _c("div", { staticClass: "row weight-600 bd-top bd-bottom" }, [
              _c("div", { staticClass: "col-6" }, [
                _vm._v(_vm._s(_vm.$t("general.others")))
              ]),
              _c("div", { staticClass: "col-6" }, [
                _vm._v(_vm._s(_vm.$t("general.amount")))
              ])
            ]),
            _vm._l(_vm.corpPayables, function(payable) {
              return _c("div", { key: payable.paymentId, staticClass: "row" }, [
                _c("div", { staticClass: "col-6" }, [
                  _vm._v(_vm._s(payable.label))
                ]),
                _c("div", { staticClass: "col-6" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.region.currency) +
                      _vm._s(
                        _vm._f("thousandSeparator")(
                          _vm._f("to2Decimal")(
                            payable.amount,
                            _vm.region.decimal
                          ),
                          _vm.region.thousandSeparator
                        )
                      ) +
                      "\n      "
                  )
                ])
              ])
            })
          ]
        : _vm._e(),
      _c("br"),
      _vm.corpSettled.length
        ? [
            _c("div", { staticClass: "row weight-600" }, [
              _c("div", { staticClass: "col-12" }, [
                _vm._v(_vm._s(_vm.$t("general.amountSettledFrom")) + ":")
              ])
            ]),
            _c("div", { staticClass: "row weight-600 bd-top bd-bottom" }, [
              _c("div", { staticClass: "col-6" }, [
                _vm._v(_vm._s(_vm.$t("general.others")))
              ]),
              _c("div", { staticClass: "col-6" }, [
                _vm._v(_vm._s(_vm.$t("general.amount")))
              ])
            ]),
            _vm._l(_vm.corpSettled, function(payable) {
              return _c("div", { key: payable.paymentId, staticClass: "row" }, [
                _c("div", { staticClass: "col-6" }, [
                  _vm._v(_vm._s(payable.label))
                ]),
                _c("div", { staticClass: "col-6" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.region.currency) +
                      _vm._s(
                        _vm._f("thousandSeparator")(
                          _vm._f("to2Decimal")(
                            payable.amount,
                            _vm.region.decimal
                          ),
                          _vm.region.thousandSeparator
                        )
                      ) +
                      "\n      "
                  )
                ])
              ])
            })
          ]
        : _vm._e(),
      _vm.isCustomNote
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-7" }),
            _vm._m(0)
          ])
        : _vm._e(),
      _c("br"),
      _c("div", { staticClass: "row mb-3" }, [
        _c(
          "div",
          { staticClass: "col-7 weight-600" },
          [
            _vm.printConfig.clinicHospital
              ? [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("setting.footerClinicHospital")) +
                      " :\n      "
                  )
                ]
              : _vm._e()
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "col-5 weight-600" },
          [
            _vm.printConfig.patientSignature
              ? [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("setting.footerPatientSignature")) +
                      " :\n      "
                  )
                ]
              : _vm._e()
          ],
          2
        )
      ]),
      _vm.printConfig.footer
        ? _c("p", { staticStyle: { "white-space": "pre-line" } }, [
            _vm._v("\n    " + _vm._s(_vm.printConfig.footerContent) + "\n  ")
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "col-5 text-left",
        staticStyle: { "white-space": "pre-line", color: "red" }
      },
      [
        _vm._v("\n      注意事項："),
        _c("br"),
        _vm._v("定型化契約"),
        _c("br"),
        _vm._v(
          "1.\n      此療程為優惠方案如因故無法使用，除非有身體傷害之狀況，否則不得要求退費，已使用之課程需以原售價扣除。因特殊因素，得允許將已付金頟轉換其他療程。依現行法令規定意退費時，將扣除已接受服務之費用、已提領並拆封之附屬商品金額、終止契約手續費（剩餘金額之百分之10）後退子消費者本人（依現行法令公告規定辦理）已使用部分療程/保養，須以單堂原價計算之。"
        ),
        _c("br"),
        _vm._v("2.手術訂金於術前5天，個人因素而無法手術時得轉换其他療程產品。"),
        _c("br"),
        _vm._v(
          "於手術當天因個人因素取消手術時，訂金不退還也不可轉換其他療程及產品。\n    "
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }