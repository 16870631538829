import NursingVapService from '@/services/nursingvap.service';

const state = {
};

/* eslint-disable no-param-reassign */
const mutations = {
};

const actions = {
  getVaps({}, param = {}) {
    // TODO: chan - should get vaps on backend
    return NursingVapService.list(param)
      .then((response) => {
        return response;
      });
  },
  addVap({}, payload) {
    return NursingVapService.create(payload)
      .then((response) => {
        return response;
      });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
