import api from './api';

export default {
  translateErrorMsg(error) {
    return error;
  },
  fetchAll(params) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);

    Object.keys(params).forEach((key) => {
      q.push(`${key}=${params[key]}`);
    });

    // const url = `/letter/?${q.join('&')}`;
    const url = '/letter';
    return api.get(url);
  },
  count(query) {
    const params = Object.keys(query)
      .map(k => `${k}=${query[k]}`)
      .join('&');
    return api.get(`/letter/count?${params}`);
  },
  createNew(data) {
    return api.post('/letter', data);
  },
  update(id, data) {
    return api.put(`/letter/update/${id}`, data);
  },
  updateBulkInventory(data) {
    return api.put('/letter/updateBulk', data);
  },
  delete(id) {
    return api.delete(`/letter/${id}`);
  },
  deleteBulk(ids) {
    return api.post('/letter/deleteBulk', ids);
  },
  getInventory(id) {
    return api.get(`/letter/${id}`);
  },
};
