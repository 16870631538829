var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.printSummary
      ? _c(
          "div",
          [
            _c("summary-page", {
              attrs: {
                isCategorized: true,
                selectedPatient: _vm.selectedPatient,
                print: _vm.print,
                general: _vm.general,
                printEN: _vm.printEN,
                tableItems: _vm.itemsByBillingGroup,
                payments: _vm.payments,
                group: _vm.group,
                corpName: _vm.corpName
              }
            })
          ],
          1
        )
      : _vm.group === "group1" || _vm.group === "group2"
      ? _c("div", { staticClass: "row no-gutters" }, [
          _c(
            "div",
            { staticClass: "col" },
            [
              _c("sub-page", {
                key: "bgrimm-sub-page-0",
                attrs: {
                  isCategorized: true,
                  selectedPatient: _vm.selectedPatient,
                  print: _vm.print,
                  general: _vm.general,
                  tableItems: _vm.itemsByBillingGroup,
                  typeOfPayments: _vm.typeOfPayments,
                  payments: _vm.payments,
                  printEN: _vm.printEN,
                  group: _vm.group,
                  corpName: _vm.corpName,
                  docCopyString: _vm.pageType[0]
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col" },
            [
              _c("sub-page", {
                key: "bgrimm-sub-page-1",
                attrs: {
                  isCategorized: true,
                  selectedPatient: _vm.selectedPatient,
                  print: _vm.print,
                  general: _vm.general,
                  tableItems: _vm.itemsByBillingGroup,
                  typeOfPayments: _vm.typeOfPayments,
                  payments: _vm.payments,
                  printEN: _vm.printEN,
                  group: _vm.group,
                  corpName: _vm.corpName,
                  docCopyString: _vm.pageType[1]
                }
              })
            ],
            1
          )
        ])
      : _vm.group === "group3"
      ? _c("div", [
          _c("div", { staticClass: "row no-gutters" }, [
            _c(
              "div",
              { staticClass: "col" },
              [
                _c("sub-page", {
                  key: "bgrimm-sub-page-0",
                  attrs: {
                    isCategorized: true,
                    selectedPatient: _vm.selectedPatient,
                    print: _vm.print,
                    general: _vm.general,
                    tableItems: _vm.itemsByBillingGroup,
                    typeOfPayments: _vm.typeOfPayments,
                    payments: _vm.payments,
                    printEN: _vm.printEN,
                    group: _vm.group,
                    corpName: _vm.corpName,
                    docCopyString: _vm.pageType[0],
                    pageIndex: 0
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col" },
              [
                _c("sub-page", {
                  key: "bgrimm-sub-page-1",
                  attrs: {
                    isCategorized: true,
                    selectedPatient: _vm.selectedPatient,
                    print: _vm.print,
                    general: _vm.general,
                    tableItems: _vm.itemsByBillingGroup,
                    typeOfPayments: _vm.typeOfPayments,
                    payments: _vm.payments,
                    printEN: _vm.printEN,
                    group: _vm.group,
                    corpName: _vm.corpName,
                    docCopyString: _vm.pageType[1],
                    pageIndex: 1
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticStyle: { "page-break-after": "always" } }),
          _c("div", { staticClass: "row no-gutters mt-1" }, [
            _c(
              "div",
              { staticClass: "col" },
              [
                _c("sub-page", {
                  key: "bgrimm-sub-page-2",
                  attrs: {
                    isCategorized: true,
                    selectedPatient: _vm.selectedPatient,
                    print: _vm.print,
                    general: _vm.general,
                    tableItems: _vm.itemsByBillingGroup,
                    typeOfPayments: _vm.typeOfPayments,
                    payments: _vm.payments,
                    printEN: _vm.printEN,
                    group: _vm.group,
                    corpName: _vm.corpName,
                    docCopyString: _vm.pageType[2],
                    pageIndex: 2
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col" },
              [
                _c("sub-page", {
                  key: "bgrimm-sub-page-3",
                  attrs: {
                    isCategorized: true,
                    selectedPatient: _vm.selectedPatient,
                    print: _vm.print,
                    general: _vm.general,
                    tableItems: _vm.itemsByBillingGroup,
                    typeOfPayments: _vm.typeOfPayments,
                    payments: _vm.payments,
                    printEN: _vm.printEN,
                    group: _vm.group,
                    corpName: _vm.corpName,
                    docCopyString: _vm.pageType[3],
                    pageIndex: 3
                  }
                })
              ],
              1
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }