var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vue-good-table", {
    attrs: { columns: _vm.spec.columns, rows: _vm.spec.rows.dummyRows },
    scopedSlots: _vm._u([
      {
        key: "table-row",
        fn: function(props) {
          return [
            props.column.field == "item1"
              ? [
                  _c("div", { staticStyle: { "max-width": "250px" } }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.spec.rows.group1[props.row.originalIndex]
                            ? _vm.$t(
                                "occupationalService.compiledReport." +
                                  _vm.parseKey(
                                    _vm.spec.rows.group1[
                                      props.row.originalIndex
                                    ].key
                                  )
                              )
                            : ""
                        ) +
                        "\n        \n      "
                    )
                  ])
                ]
              : props.column.field == "value1"
              ? [
                  _c(
                    "div",
                    {
                      class:
                        _vm.spec.rows.group1[props.row.originalIndex] &&
                        _vm.compiledData[
                          _vm.spec.rows.group1[props.row.originalIndex].key +
                            "-level"
                        ]
                          ? _vm.compiledData[
                              _vm.spec.rows.group1[props.row.originalIndex]
                                .key + "-level"
                            ]
                          : "",
                      staticStyle: { width: "100px" }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.spec.rows.group1[props.row.originalIndex]
                              ? _vm.compiledData[
                                  _vm.spec.rows.group1[props.row.originalIndex]
                                    .key
                                ]
                              : ""
                          ) +
                          "\n      "
                      )
                    ]
                  )
                ]
              : _vm._e(),
            props.column.field == "item2"
              ? [
                  _c("div", { staticStyle: { "max-width": "250px" } }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.spec.rows.group2[props.row.originalIndex]
                            ? _vm.$t(
                                "occupationalService.compiledReport." +
                                  _vm.parseKey(
                                    _vm.spec.rows.group2[
                                      props.row.originalIndex
                                    ].key
                                  )
                              )
                            : ""
                        ) +
                        "\n      "
                    )
                  ])
                ]
              : props.column.field == "value2"
              ? [
                  _c(
                    "div",
                    {
                      class:
                        _vm.spec.rows.group2[props.row.originalIndex] &&
                        _vm.compiledData[
                          _vm.spec.rows.group2[props.row.originalIndex].key +
                            "-level"
                        ]
                          ? _vm.compiledData[
                              _vm.spec.rows.group2[props.row.originalIndex]
                                .key + "-level"
                            ]
                          : "",
                      staticStyle: { width: "100px" }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.spec.rows.group2[props.row.originalIndex]
                              ? _vm.compiledData[
                                  _vm.spec.rows.group2[props.row.originalIndex]
                                    .key
                                ]
                              : ""
                          ) +
                          "\n      "
                      )
                    ]
                  )
                ]
              : _vm._e(),
            props.column.field == "item3"
              ? [
                  _c("div", { staticStyle: { "max-width": "250px" } }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.spec.rows.group3[props.row.originalIndex]
                            ? _vm.$t(
                                "occupationalService.compiledReport." +
                                  _vm.parseKey(
                                    _vm.spec.rows.group3[
                                      props.row.originalIndex
                                    ].key
                                  )
                              )
                            : ""
                        ) +
                        "\n      "
                    )
                  ])
                ]
              : props.column.field == "value3"
              ? [
                  _c(
                    "div",
                    {
                      class:
                        _vm.spec.rows.group3[props.row.originalIndex] &&
                        _vm.compiledData[
                          _vm.spec.rows.group3[props.row.originalIndex].key +
                            "-level"
                        ]
                          ? _vm.compiledData[
                              _vm.spec.rows.group3[props.row.originalIndex]
                                .key + "-level"
                            ]
                          : "",
                      staticStyle: { width: "100px" }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.spec.rows.group3[props.row.originalIndex]
                              ? _vm.compiledData[
                                  _vm.spec.rows.group3[props.row.originalIndex]
                                    .key
                                ]
                              : ""
                          ) +
                          "\n      "
                      )
                    ]
                  )
                ]
              : _vm._e()
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }