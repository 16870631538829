/* eslint-disable no-param-reassign */
/* eslint-disable no-param-reassign */
import _ from 'lodash';

import admissionService from '@/services/admission.service';
import admissionInvoiceService from '@/services/admission.invoice.service';
import {
  SET_ACTIVE_ADMISSION,
  SET_FANILISED_ITEMS,
  ADD_ADMISSION_ITEMS,
  SET_ADMISSION_ITEMS,
  REMOVE_ADMISSION_ITEM,
  SET_ADMISSION_INVOICE,
  UPDATE_ADMISSION_ITEM,
  ADD_FANILISED_ITEMS,
  SET_IPD_TAB,
  SET_ADMINISTRATION_LOG,
  REMOVE_ADMINISTRATION_LOG,
  SET_PROVIDER_ADMISSIONS,
} from './type.mutation';

const state = {
  activeAdmission: {},
  admissionItems: [],
  finalisedItems: [],
  admissionInvoice: {},
  selectedAdmissionId: '',
  providersAdmissions: [],
  ipdTab: 'Routine',
};

const mutations = {
  [SET_ACTIVE_ADMISSION](_state, payload) {
    // eslint-disable-next-line no-param-reassign
    _state.activeAdmission = payload;
  },
  [SET_FANILISED_ITEMS](_state, payload) {
    // eslint-disable-next-line no-param-reassign
    _state.finalisedItems = payload;
  },
  [ADD_ADMISSION_ITEMS](_state, payload) {
    // eslint-disable-next-line no-param-reassign
    _state.admissionItems.push(payload);
  },
  [SET_ADMISSION_ITEMS](_state, payload = []) {
    // eslint-disable-next-line no-param-reassign
    _state.admissionItems = payload;
  },
  [REMOVE_ADMISSION_ITEM](_state, index) {
    _state.admissionItems.splice(index, 1);
  },
  [SET_ADMISSION_INVOICE](_state, payload) {
    _state.admissionInvoice = payload;
  },
  [UPDATE_ADMISSION_ITEM](_state, { index, item }) {
    _state.admissionItems.splice(index, 1, item);
  },
  [ADD_FANILISED_ITEMS](_state, payload) {
    // eslint-disable-next-line no-param-reassign
    _state.finalisedItems = [..._state.finalisedItems, ...payload];
  },
  [SET_IPD_TAB](_state, payload) {
    _state.ipdTab = payload;
  },
  [SET_ADMINISTRATION_LOG](_state, { inventoryId, payload }) {
    _state.finalisedItems = _state.finalisedItems.map((item) => {
      if (item._id === inventoryId) item.administrationLogs = { ...item.administrationLogs, ...payload };
      return item;
    });
  },
  [REMOVE_ADMINISTRATION_LOG](_state, { inventoryId, timing }) {
    _state.finalisedItems = _state.finalisedItems.map((item) => {
      console.log('item >>> ', item._id, inventoryId, timing)
      if (item._id === inventoryId) {
        delete item.administrationLogs[timing];
      }
      return item;
    });
  },
  [SET_PROVIDER_ADMISSIONS](_state, payload = []) {
    // eslint-disable-next-line no-param-reassign
    _state.providersAdmissions = _.cloneDeep(payload);
  },
};

const actions = {
  socket_controlFlowUpdate({ dispatch }, payload) {
    console.log('SOCKET IO >>> ', payload);
  },
  fetchActiveAdmission({ commit }, params = {}) {
    return admissionService.list(params)
      .then((response) => {
        commit(SET_ACTIVE_ADMISSION, response.data[0]);
        return response.data[0];
      });
  },
  saveAdmissionItems({ commit }, payload) {
    return admissionInvoiceService.create(payload)
      .then((response) => {
        commit(SET_ADMISSION_ITEMS, response.data.items);
        commit(SET_ADMISSION_INVOICE, response.data);
        return response.data.items;
      });
  },
  fetchAdmissionItems({ commit }, params = {}) {
    return admissionInvoiceService.list(params)
      .then((response) => {
        if (response.data.length > 0) {
          commit(SET_ADMISSION_ITEMS, response.data[0].items.filter(i => !i.isFinalise));
          // commit(SET_FANILISED_ITEMS, response.data[0].items.filter(i => i.isFinalise));
          commit(SET_ADMISSION_INVOICE, response.data[0]);
          return response.data[0].items;
        }
        return null;
      });
  },
  fetchFinalisedItems({ commit }, params = {}) {
    return admissionInvoiceService.getFinaliseItems(params)
      .then((response) => {
        // commit(SET_ADMISSION_ITEMS, response.data[0].items.filter(i => !i.isFinalise));
        commit(SET_FANILISED_ITEMS, response.data);
        return response.data.finalisedItems;
      });
  },
  updateAdmissionItems({ commit }, payload) {
    return admissionInvoiceService.update(payload._id, payload)
      .then((response) => {
        commit(SET_ADMISSION_ITEMS, response.data.items);
        commit(SET_ADMISSION_INVOICE, response.data);
        return response.data;
      });
  },
  finaliseAdmissionItems({ commit }, id) {
    return admissionInvoiceService.finalise(id)
      .then((response) => {
        commit(SET_ADMISSION_ITEMS, []);
        // commit(ADD_FANILISED_ITEMS, response.data.items);
        // commit(SET_FANILISED_ITEMS, response.data.items);
        // commit(SET_ADMISSION_INVOICE, response.data);
        return response.data;
      });
  },
  getAdmissionById({ commit }, id) {
    return admissionService.getById(id)
      .then((response) => {
        // commit(SET_ADMISSION_ITEMS, response.data.items.filter(i => !i.isFinalise));
        commit(SET_ACTIVE_ADMISSION, response.data);
        return response.data;
      });
  },
  updateIpdStatus({ commit }, { params, payload }) {
    return admissionInvoiceService.updateIpdStatus(params, payload)
      .then(response => response.data);
  },
  recordMedicationLog({ commit }, { invoiceId, inventoryId, payload }) {
    return admissionInvoiceService.recordLogs({ invoiceId, inventoryId }, payload)
      .then((response) => {
        commit(SET_ADMINISTRATION_LOG, { inventoryId, payload: response.data });
      });
  },
  deleteLogsRecord({ commit }, { invoiceId, inventoryId, timing }) {
    return admissionInvoiceService.removeLogs({ invoiceId, inventoryId }, { timing })
      .then(() => {
        commit(REMOVE_ADMINISTRATION_LOG, { inventoryId, timing });
      });
  },
  fetchProvidersAdmissions({ commit }, params = {}) {
    return admissionService.providerAdmissions(params)
      .then((response) => {
        commit(SET_PROVIDER_ADMISSIONS, response.data);
      });
  },
};

const getters = {
  activeAdmission: _state => _state.activeAdmission,
  admissionItems: _state => _state.admissionItems,
  finalisedItems: _state => _state.finalisedItems,
  admissionInvoice: _state => _state.admissionInvoice,
  providersAdmissions: _state => _state.providersAdmissions,
  ipdTab: _state => _state.ipdTab,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
