import notetemplateService from '@/services/notetemplate.service';
import uuid from 'uuid';
import moment from 'moment';

import {
  BEFORE_SET_ALL_NOTETEMPLATES,
  SET_ALL_NOTETEMPLATES,
  SET_ALL_CONSULTNOTETEMPLATES,
  SET_PAGINATED_NOTETEMPLATES,
  UPDATE_NOTETEMPLATE,
  GO_TO_PAGE,
  UPDATE_NOTETEMPLATE_LIST,
  DELETE_NOTETEMPLATE,
  ADD_NEW_NOTE,
  RESET_TEMPLATE_IDS,
} from './type.mutation';

const state = {
  notetemplatesCoverPagesOnlyOrdered: [],
  notetemplatesMappedById: {},
  deleteNotetemplatesCoverPagesOnlyOrdered: [],
  consultNoteTemplatesOnly: {},
  deletedConsultNoteTemplatesOnly: {},
  templatesSelectOptions: [],
  notetemplatesByCategories: {},
  editor: {
    open: false,
    openSize: null,
    name: null,
    categoryId: null,
    currentPageId: null, // will start from 0
    pages: {},
    pagesIdOrder: [],
    pagesWithChanges: {}, // { id1: true, id2: true, etc }
    permissions: null,
    hasPermissionChanges: false,
  },
  originalEditorName: null,
  histories: {},
  historyStep: {},
  isConsultNoteTemplateReady: false,
};

const shortUuid = function () {
  // I generate the UID from two parts here
  // to ensure the random number provide enough bits.
  let firstPart = (Math.random() * 46656) | 0;
  let secondPart = (Math.random() * 46656) | 0;
  firstPart = (`000${firstPart.toString(36)}`).slice(-3);
  secondPart = (`000${secondPart.toString(36)}`).slice(-3);
  return firstPart + secondPart;
};

/* eslint-disable no-param-reassign */
const mutations = {
  resetEditor(_state) {
    _state.editor = {
      open: false,
      openSize: null,
      name: null,
      categoryId: null,
      currentPageId: null, // will start from 0
      pages: {},
      pagesIdOrder: [],
      pagesWithChanges: {}, // { id1: true, id2: true, etc }
      permissions: null,
      hasPermissionChanges: false,
    };
  },
  [SET_ALL_CONSULTNOTETEMPLATES](_state, payload) {
    payload.forEach((t) => {
      if (t.isDeleted) {
        _state.deletedConsultNoteTemplatesOnly[t._id] = t;
      } else {
        _state.consultNoteTemplatesOnly[t._id] = t;
      }
    });
    const templatesSelectOptions = [];
    const keys = Object.keys(_state.consultNoteTemplatesOnly);
    keys.forEach((templateId) => {
      const template = _state.consultNoteTemplatesOnly[templateId];
      templatesSelectOptions.push({ value: templateId, text: `${template.name} (Category: ${this.state.note.categoriesMapped[template.categoryId].name})` });
    });
    _state.templatesSelectOptions = templatesSelectOptions;
    _state.isConsultNoteTemplateReady = true;
  },
  [BEFORE_SET_ALL_NOTETEMPLATES](_state) {
    _state.notetemplatesCoverPagesOnlyOrdered = [];
    _state.notetemplatesMappedById = {};
    _state.templatesSelectOptions = [];
    _state.notetemplatesByCategories = {};
    _state.deleteNotetemplatesCoverPagesOnlyOrdered = [];
    _state.consultNoteTemplatesOnly = {};
    _state.deletedConsultNoteTemplatesOnly = {};
    _state.isConsultNoteTemplateReady = false;
  },
  [RESET_TEMPLATE_IDS](_state) {
    _state.notetemplatesCoverPagesOnlyOrdered = [];
    _state.notetemplatesMappedById = {};
    _state.notetemplatesByCategories = {};
  },
  [SET_ALL_NOTETEMPLATES](_state, payload) {
    const notetemplatesByCategories = {};
    // console.log('SET_ALL_NOTETEMPLATES payload is ', payload);
    payload.forEach((t) => {
      if (!t.coverPageId && !_state.notetemplatesCoverPagesOnlyOrdered.includes(t._id)) {
        _state.notetemplatesCoverPagesOnlyOrdered.push(t._id);
      }
      if (!notetemplatesByCategories[t.categoryId]) {
        notetemplatesByCategories[t.categoryId] = [];
      }
      notetemplatesByCategories[t.categoryId].push(t._id);
      _state.notetemplatesMappedById[t._id] = t;
    });
    _state.notetemplatesByCategories = notetemplatesByCategories;
  },
  [SET_PAGINATED_NOTETEMPLATES](_state, payload) {
    console.log('mtn SET_PAGINATED_NOTETEMPLATES - payload ', payload);
    const { notetemplatesByCategories, templatesSelectOptions } = _state;
    // payload.forEach((t) => {
    for (let i = 0; i < payload.length; i += 1) {
      const t = payload[i];
      if (!_state.notetemplatesMappedById[t._id]) {
        if (notetemplatesByCategories[t.categoryId] === undefined) {
          notetemplatesByCategories[t.categoryId] = [];
        }
        notetemplatesByCategories[t.categoryId].push(t._id);
        if (notetemplatesByCategories.All === undefined) {
          notetemplatesByCategories.All = [];
        }
        notetemplatesByCategories.All.push(t._id);
        if (t.starred) {
          if (notetemplatesByCategories.Starred === undefined) {
            notetemplatesByCategories.Starred = [];
          }
          notetemplatesByCategories.Starred.push(t._id);
        }

        if (t.isDeleted) {
          if (!t.coverPageId) {
            _state.deleteNotetemplatesCoverPagesOnlyOrdered.push(t._id);
          }
        } else {
          if (t.consultNoteTemplate) {
            if (t.isDeleted) {
              _state.deletedConsultNoteTemplatesOnly[t._id] = t;
            } else {
              _state.consultNoteTemplatesOnly[t._id] = t;
            }
            templatesSelectOptions.push({
              value: t._id,
              text: `${t.name} (Category: ${this.state.note.categoriesMapped[t.categoryId].name})`,
            });
          }
          if (!t.coverPageId) {
            _state.notetemplatesCoverPagesOnlyOrdered.push(t._id);
          }
        }
        _state.notetemplatesMappedById[t._id] = t;
      }
    }
    console.log('after mtn _state.deleteNotetemplatesCoverPagesOnlyOrdered ', _state.deleteNotetemplatesCoverPagesOnlyOrdered);
    _state.templatesSelectOptions = templatesSelectOptions;
    _state.notetemplatesByCategories = notetemplatesByCategories;
  },
  [DELETE_NOTETEMPLATE](_state, templateId) {
    _state.notetemplatesCoverPagesOnlyOrdered = _state.notetemplatesCoverPagesOnlyOrdered
      .filter(id => id !== templateId);
  },
  [UPDATE_NOTETEMPLATE_LIST](_state, noteTemplates) {
    // noteTemplates shld be [noteTemplate, noteTemplate2, etc...]
    // console.log('in UPDATE_NOTETEMPLATE_LIST...noteTemplates are ', noteTemplates);
    for (let i = 0; i < noteTemplates.length; i += 1) {
      const template = noteTemplates[i];
      // if (!_state.notetemplatesMappedById[template._id]) { // new addition
      //   _state.notetemplatesOrdered.push(template._id)
      // }
      _state.notetemplatesMappedById[template._id] = template;
      if (!_state.notetemplatesByCategories[template.categoryId]) {
        _state.notetemplatesByCategories[template.categoryId] = [];
      }
      _state.notetemplatesByCategories[template.categoryId].push(template._id);
      if (!template.coverPageId
        && _state.notetemplatesCoverPagesOnlyOrdered.indexOf(template._id) === -1) {
        _state.notetemplatesCoverPagesOnlyOrdered.push(template._id);
      }
    }
    _state.editor.pagesWithChanges = {};
    // console.log('_state.notetemplatesMappedById is now ', _state.notetemplatesMappedById);
  },
  [UPDATE_NOTETEMPLATE](_state, payload) {

  },
  updateNoteKeys(_state, { id, updatedKeyValues }) {
    // console.log('in updateNoteKeys mutation - id, updatedKeyValues are ', id, updatedKeyValues);

    if (updatedKeyValues.categoryId !== undefined) {
      const newCatId = updatedKeyValues.categoryId;
      const clone = Object.assign({}, _state.notetemplatesByCategories);
      clone[_state.notetemplatesMappedById[id].categoryId] = clone[_state.notetemplatesMappedById[id].categoryId].filter(n => n !== id);
      if (clone[_state.notetemplatesMappedById[id].categoryId].length === 0) {
        delete clone[_state.notetemplatesMappedById[id].categoryId];
      }
      if (clone[newCatId] === undefined) {
        clone[newCatId] = [];
      }
      clone[newCatId].push(id);
      _state.notetemplatesByCategories = clone;
    }
    _state.notetemplatesMappedById[id] = Object.assign({}, _state.notetemplatesMappedById[id], updatedKeyValues);

    // console.log('end of updateNoteKeys - _state.notetemplatesMappedById is ', _state.notetemplatesMappedById);
  },
  openEditor(_state, payload) {
    // console.log('in notetemplate/openEditor payload is ', payload);
    if (!payload) {
      _state.editor = {
        open: false,
        openSize: null,
        name: null,
        categoryId: null,
        currentPageId: null, // will start from 0
        pages: {},
        pagesIdOrder: [],
        pagesWithChanges: {}, // { id1: true, id2: true, etc }
        permissions: null,
        hasPermissionChanges: false,
      };
    } else {
      _state.editor.open = true;
      if (payload.categoryId) {
        _state.editor.categoryId = payload.categoryId;
      }
      if (payload.pages) {
        const currentPageId = payload.pagesIdOrder[0];
        _state.editor.pages = payload.pages;
        _state.editor.pagesIdOrder = payload.pagesIdOrder;
        _state.editor.currentPageId = currentPageId;
        _state.editor.name = payload.pages[currentPageId].name;
        _state.editor.pagesWithChanges = {};
        for (const pageId in _state.editor.pages) {
          // console.log('pageId is ', pageId);
          _state.histories[pageId] = [];
          _state.historyStep[pageId] = -1;
        }
      }
      // console.log('AFT openEditor - _state.editor is ', _state.editor);
    }
    if (payload.permissions) {
      _state.editor.permissions = payload.permissions;
    }
  },
  closeEditor(_state, payload) {
    // console.log('in notetemplate/closeEditor');
    // if (!_.isEmpty(_state.editor.pagesWithChanges)) {
    //   let confirm = window.confirm('There are unsaved edits, exiting will result in the loss of these edits. \nProceed?');
    //   if (!confirm) return;
    // }
    _state.editor = {
      open: false,
      openSize: null,
      name: null,
      categoryId: null,
      currentPageId: null, // will start from 0
      pages: {},
      pagesIdOrder: [],
      pagesWithChanges: {}, // { id1: true, id2: true, etc }
      permissions: null,
      hasPermissionChanges: false,
    };
  },
  // 'updatePages'(_state, updatedPages) {
  //   let clone = _state.editor.pages.slice(0);
  //   for (l)
  // },
  addPage(_state, {
    shortUuid, type, key, tempBase64,
  }) {
    // console.log('in addPage shortUuid, type, key, tempBase64, _state.editor is ', shortUuid, type, key, tempBase64, _state.editor);

    // _state.editor.pages.push({key, tempBase64, strokeStore: {}, strokesIdOrder: []}); // no id, indicating this is not in the DB yet (temp)
    if (!_state.editor.pages) _state.editor.pages = {};
    if (!_state.editor.pagesIdOrder) _state.editor.pagesIdOrder = [];
    _state.editor.pages[shortUuid] = {
      shortUuid, type, key, tempBase64, strokeStore: {}, strokesIdOrder: [],
    };
    _state.editor.pagesIdOrder.push(shortUuid);

    _state.histories[shortUuid] = [];
    _state.historyStep[shortUuid] = -1;

    if (!_state.editor.currentPageId) {
      _state.editor.currentPageId = shortUuid;
    }
    if (!_state.editor.name) {
      _state.editor.name = 'Untitled Template';
    }
    if (!_state.editor.pagesWithChanges) {
      _state.editor.pagesWithChanges = {};
    }
    // console.log('final _state is ', _state);
    // console.log('final _state.editor is ', _state.editor);
  },
  addHistory(_state, { pageId, stroke }) {
    // console.log('BEF addHistory for page id and history is ', pageId, stroke);
    if (_state.historyStep[pageId] + 1 < _state.histories[pageId].length) {
      _state.histories[pageId] = _state.histories[pageId].slice(0, _state.historyStep[pageId] + 1);
    }
    _state.histories[pageId].push(stroke);
    _state.historyStep[pageId] += 1;
    // console.log('AFT addHistory _state.histories, _state.historyStep ', _state.histories, _state.historyStep);
  },
  undoHistory(_state) {
    const pageId = _state.editor.currentPageId;
    // console.log('page bef undo - ', _.cloneDeep(_state.editor.pages[pageId]));
    const strokeToUndo = _state.histories[pageId][_state.historyStep[pageId]];
    const page = _.cloneDeep(_state.editor.pages[pageId]);
    delete page.strokeStore[strokeToUndo.id];
    page.strokesIdOrder = page.strokesIdOrder.filter(sId => sId !== strokeToUndo.id);
    // delete _state.editor.pages[pageId].strokeStore[strokeToUndo.targetId];
    _state.editor.pages[pageId] = page;
    _state.editor.pagesWithChanges[pageId] = true;
    _state.historyStep[pageId] -= 1;
    // console.log('page aft undo - ', _.cloneDeep(_state.editor.pages[pageId]));
    // console.log('aft undo, strokeToUndo.targetId, strokeStore, historyStep are ', strokeToUndo.targetId, _state.editor.pages[_state.editor.currentPageId].strokeStore, _state.historyStep);
  },
  redoHistory(_state) {
    const pageId = _state.editor.currentPageId;
    const strokeToRedo = _state.histories[pageId][_state.historyStep[pageId] + 1];
    const page = _.cloneDeep(_state.editor.pages[pageId]);
    page.strokeStore[strokeToRedo.id] = strokeToRedo;
    page.strokesIdOrder.push(strokeToRedo.id);
    _state.editor.pages[pageId] = page;
    _state.editor.pagesWithChanges[pageId] = true;
    _state.historyStep[pageId] += 1;
  },
  updateDoc(_state, { key, value }) {
    const clonePages = {};
    const clonePagesWithChanges = Object.assign({}, _state.editor.pagesWithChanges);
    _state.editor.pagesIdOrder.forEach((pageId, i) => {
      const page = _state.editor.pages[pageId];
      if (pageId === _state.editor.currentPageId) {
        const pageClone = Object.assign({}, page);
        if (key === 'strokeStore') {
          if (!pageClone.strokeStore) {
            pageClone.strokeStore = {};
          }
          // let id = shortUuid();
          pageClone.strokeStore[value.id] = value;
          if (!pageClone.strokesIdOrder) {
            pageClone.strokesIdOrder = [];
          }
          pageClone.strokesIdOrder.push(value.id);
        }
        clonePagesWithChanges[pageClone._id] = true;
        clonePages[pageId] = pageClone;
      } else {
        clonePages[pageId] = page;
      }
    });
    _state.editor.pages = clonePages;
    _state.editor.pagesWithChanges = clonePagesWithChanges;
  },
  updateStrokeStore(_state, { id, key, value }) {
    // let cloneStrokeStore = Object.assign({}, _state.editor.pages[_state.editor.currentPageId].strokeStore);
    // cloneStrokeStore[id] = value;
    _state.editor.pages[_state.editor.currentPageId].strokeStore[id][key] = value;
    // console.log('aft _state.editor.pages[_state.editor.currentPageId] is ', _state.editor.pages[_state.editor.currentPageId]);
  },
  updateEditorMetadata(_state, { key, value }) {
    // console.log('in updateEditorMetadata...key value are ', key, value);
    if (key === 'name' && value === '') {
      _state.originalEditorName = _state.editor.name;
    }
    if (key === 'categoryId') {
      const oldCatId = _state.editor.categoryId;
      _state.notetemplatesByCategories[oldCatId] = _state.notetemplatesByCategories[oldCatId].filter(tId => tId !== _state.editor.pagesIdOrder[0]);
      if (!_state.notetemplatesByCategories[value]) {
        _state.notetemplatesByCategories[value] = [];
      }
      _state.notetemplatesByCategories[value].push(_state.editor.pagesIdOrder[0]);
    }
    _state.editor[key] = value;
    // console.log('post metadata update, editor is ', _state.editor);
  },
  [GO_TO_PAGE](_state, newPageIndex) {
    // console.log('in go to page newPageIndex is ', newPageIndex);
    _state.editor.currentPageId = _state.editor.pagesIdOrder[newPageIndex];
  },
  [ADD_NEW_NOTE](_state, payload) {
    _state.notetemplatesMappedById[payload._id] = payload;
  },
};

const actions = {
  fetchConsultNoteTemplates({ commit }, { excludeFields = [] } = {}) {
    // console.log('yay fetchConsultNoteTemplates');
    return notetemplateService.fetchConsultNoteTemplates({ excludeFields })
      .then((response) => {
        // console.log('fetchConsultNoteTemplates response is ', response);
        if (response.ok) {
          const consultnotetemplates = response.body;
          commit(SET_ALL_CONSULTNOTETEMPLATES, consultnotetemplates);
          return consultnotetemplates;
        }
      });
  },
  fetchAllNoteTemplates({ commit }, { excludeFields = [] } = {}) {
    // console.log('yay fetchAll notetemplates');
    // commit(BEFORE_SET_ALL_NOTETEMPLATES);
    // commit('notecategory/BEFORE_SET_ALL_NOTECATEGORIES', null, { root: true });
    return notetemplateService.fetchAll({ excludeFields })
      .then((response) => {
        // console.log('fetchAll notetemplates response is ', response);
        if (response.ok) {
          const notetemplates = response.body;
          commit(SET_ALL_NOTETEMPLATES, notetemplates);
          // commit('notecategory/SET_ALL_NOTECATEGORIES', categories, { root: true });
          return notetemplates;
        }
      });
  },
  fetchPaginated({ commit }, params) {
    console.log('yay fetchPaginated notetemplates params ', params);
    // commit(BEFORE_SET_ALL_NOTETEMPLATES);
    // commit('notecategory/BEFORE_SET_ALL_NOTECATEGORIES', null, { root: true });
    return notetemplateService.get(params)
      .then((response) => {
        console.log('fetchAll notetemplates response is ', response);
        if (response.ok) {
          const notetemplates = response.body;
          console.log('notetemplates fetched ', notetemplates);
          commit(SET_PAGINATED_NOTETEMPLATES, notetemplates);
          return response;
        }
      });
  },
  getTemplateLatestImage({ commit }, { id, key }) {
    // console.log('yay fetching one notetemplate latest img - id, key ', id, key);
    return notetemplateService.fetchOne({ id, key })
      .then((response) => {
        // console.log('fetchOne notetemplate response is ', response);
        const notetemplate = response.body;
        commit(UPDATE_NOTETEMPLATE, { id, key, value: notetemplate[key] });
        return notetemplate;
      });
  },
  saveEditorUpdates({ commit, state }, { permissions }) {
    // console.log('in notetemplate/create, state.editor is ', state.editor);
    // let ss = state.editor.pages[0].strokeStore;
    // console.log('is ss arr ', Array.isArray(ss));
    // console.log('object keys of ss ', Object.keys(ss));
    // return;
    const updateParams = Object.assign({}, state.editor);
    if (permissions) updateParams.permissions = permissions;
    return notetemplateService.createNew(updateParams)
      .then((response) => {
        // console.log('notetemplate create response is ', response);
        const updatedTemplates = response.body;
        // commit(ADD_NEW_NOTE, note);
        commit(UPDATE_NOTETEMPLATE_LIST, updatedTemplates);
        // commit note._id to invoice
        return response;
      })
      .catch(err =>
        // console.log('notetemplate create err is ', err);
        err);
  },
  getLatestImage({ commit, state }, params) {
    // console.log('getLatestImage for notetemplate params ', params);
    // commit(BEFORE_SET_ALL_NOTES);
    return new Promise((resolve, reject) => notetemplateService.fetchOne(params)
      .then((response) => {
        // console.log('getLatestImage response and keys is ', response, params);
        if (params.returnFullDoc) {
          if (!state.notetemplatesMappedById[params.id]) {
            commit(ADD_NEW_NOTE, response.body);
          }
        } else {
          commit('updateNoteKeys', { id: params.id, updatedKeyValues: response.body });
        }
        // let updatedKeyValues = response.body;
        // commit('updateNoteKeys', {id: params.id, updatedKeyValues});
        // commit('updateNote', {id, key, value: note[keys]});
        // commit(ADD_NEW_NOTE, [note]);
        return resolve(response);
      }));
    // return noteService.fetchOne(params)
    //   .then((response) => {
    //     console.log('getLatestImage response and keys is ', response, params.keys);
    //     if (params.returnFullDoc) {
    //       commit(ADD_NEW_NOTE, [response.body]);
    //     } else {
    //       commit('updateNoteKeys', {id: params.id, updatedKeyValues: response.body});
    //     }
    //     // let updatedKeyValues = response.body;
    //     // commit('updateNoteKeys', {id: params.id, updatedKeyValues});
    //     // commit('updateNote', {id, key, value: note[keys]});
    //     // commit(ADD_NEW_NOTE, [note]);
    //     return response;
    //   });
  },
  uploadTemplate({ commit }, params) {
    return notetemplateService.uploadTemplate(params);
  },
  deleteNotetemplate({ commit }, templateId) {
    return new Promise((resolve, reject) => notetemplateService.deleteTemplate(templateId)
      .then((response) => {
        // console.log('respones.... ', response);
        if (response.ok) {
          commit(DELETE_NOTETEMPLATE, templateId);
        }
        return resolve(response);
      }));
  },
  setAllNoteTemplates({ commit }, payload) {
    commit(RESET_TEMPLATE_IDS);
    commit(SET_ALL_NOTETEMPLATES, payload);
  },
};

const getters = {
  isConsultNoteTemplateReady: _state => _state.isConsultNoteTemplateReady,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
