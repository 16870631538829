<template>
  <!-- eslint-disable max-len -->
  <div class="b-summary-container">
    <!-- clinic address and logo -->
    <div class="row">
      <div class="col">
        <h5 class="clinic-local-name">
          {{ general.clinicLocalName }}
        </h5>
        <h5 class="clinic-name">
          {{ general.clinicName }}
        </h5>
        <p class="mb-0 clinic-campony">
          {{ clinicCorpName }}
        </p>
        <div class="clinic-addr">
          <div class="hint">ที่อยู่</div>
          <div class="addr-line">
            <div class="thai">{{ general.clinicLocalAddress }}</div>
            <div class="en">{{ general.address }}</div>
          </div>
        </div>
      </div>
      <div class="col flex-grow-0 logo-container">
        <img :src="general.logoURL" :alt="general.clinicName">
      </div>
    </div>
    <h4 class="doc-title text-center mt-2">เอกสารสรุปค่าใช้จ่าย / SUMMARY INVOICE</h4>
    <h6 class="patient-cn">
      เลขประจำตัวผู้ป่วย
      <span class="ml-4">
        CN: <span class="ml-2">{{ selectedPatient.givenIdPrefix }}{{ selectedPatient.givenId}}</span>
      </span>
    </h6>
    <div class="row t-bold">
      <div class="col-8">
        <div class="patient-name">
          ชื่อ/Name: <span class="ml-2">{{ patientNameField }}</span>
        </div>
      </div>
      <div class="col-4">
        <div class="doc-number">
          เลขที่เอกสาร/Document No. :
          <span >{{ print.invoiceNo }}</span>
        </div>
      </div>
    </div>
    <div class="row t-bold">
      <div class="col-8">
        <div class="corp-company">
          บริษัท / Corporate company :<span class="ml-2"> {{ corpName }} </span>
        </div>
      </div>
      <div class="col-4">
        <div class="invoice-date">
          วันที่/ Date : <span> {{ print.createdAt | formatVDDate({ lang: $i18n.locale, format: 'DD/MM/YYYY'}) }}</span>
        </div>
      </div>
    </div>
    <!-- table -->
    <div class="row">
      <div class="col">
        <table class="table invoice-table">
          <thead>
            <tr class="text-center">
              <th colspan="3">
                <p class="intableTitle thai">รายการ</p>
                <p class="intableTitle en">Description</p>
              </th>
              <th>
                <p class="intableTitle thai">จำนวนเงิน</p>
                <p class="intableTitle en">Amount</p>
              </th>
              <th>
                <p class="intableTitle thai">ส่วนลด</p>
                <p class="intableTitle en">Discount</p>
              </th>
              <th>
                <p class="intableTitle thai">จำนวนเงินสุทธิ</p>
                <p class="intableTitle en">Net Amount</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, idx) in tableItems">
              <tr class="title-row" v-if="item.isTitle" :key="`invoice_item_${idx}`">
                <td colspan="3" class="category-title">
                  {{ item.description }}
                </td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr class="item-row" v-else :key="`invoice_item_${idx}`">
                <td colspan="3" class="category-subtitle">
                  {{ item.description }}
                </td>
                <td class="currency">{{ item.amount }}</td>
                <td class="currency">{{ item.discount }}</td>
                <td class="currency">{{ item.netAmount }}</td>
              </tr>
            </template>
            <tr class="subtotal-tr top-row">
              <td></td>
              <td colspan="3" class="t-bold">
                รวมทั้งสิ้น / Subtotal
              </td>
              <td></td>
              <td class="currency">{{ totalAmountNumber | to2Decimal }}</td>
            </tr>
            <tr class="subtotal-tr">
              <td></td>
              <td colspan="3" class="t-bold">
                ส่วนลดทั้งหมด / Total Discount
              </td>
              <td></td>
              <td class="currency">{{ totalDiscountNumber  | to2Decimal }}</td>
            </tr>
            <tr class="subtotal-tr">
              <td></td>
              <td colspan="3" class="t-bold">
                ยอดรวมสุทธิ / Net Amount
              </td>
              <td></td>
              <td class="currency">{{ totalNetAmountNumber  | to2Decimal }}</td>
            </tr>
            <template v-for="(payment, idx) in payments">
              <tr :key="idx" :class="['subtotal-tr payments', {'no-border': idx !== 0, 'first-payment-row': idx === 0}]">
                <td></td>
                <td colspan="2" class="t-bold">
                  <span v-if="idx === 0">
                    ชำระโดย / Mode of payment :
                  </span>
                  <span v-else>&nbsp;</span>
                </td>
                <td colspan="2">
                  {{ payment.label }}
                </td>
                <td class="currency">
                  {{ payment.amount | to2Decimal }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import dateFormatter from '@/components/mixins/dateformatter';

export default {
  name: 'BGrimmTaxInvoiceSubPage',
  mixins: [dateFormatter],
  filters: {
    to2Decimal(value, decimal = '.') {
      if (value !== '') {
        return parseFloat(value).toFixed(2).replace('.', decimal);
      }
      return '-';
    },
  },
  props: {
    selectedPatient: {
      type: Object,
      default: () => ({ corpInsurance: [] }),
    },
    print: {
      type: Object,
      default: () => ({}),
    },
    general: {
      type: Object,
      default: () => ({}),
    },
    printEN: {
      type: Boolean,
      default: true,
    },
    tableItems: {
      type: Array,
      default: () => ([]),
    },
    group: String,
    corpName: {
      type: String,
      default: '',
    },
    payments: {
      type: Array,
      default: () => ([]),
    },
  },
  computed: {
    clinicCorpName() {
      return '';
    },
    totalAmountNumber() {
      return this.print.subtotal;
    },
    totalDiscountNumber() {
      return this.print.totalDiscount;
    },
    totalNetAmountNumber() {
      return this.print.total;
    },
    patientNameField() {
      if (this.printEN) {
        return `${this.selectedPatient.title} ${this.selectedPatient.name}`;
      }
      return `${this.selectedPatient.localTitle} ${this.selectedPatient.localName}`;
    },
  },
  methods: {
    getDateFromX(d, f = 'DD/MM/YYYY') {
      return moment(d, 'X').format(f) || '';
    },
    getTotal(key) {
      const sum = this.tableItems.reduce((acc, item) => {
        const addNumber = item[key] ? parseFloat(item[key]) : 0;
        return acc + addNumber;
      }, 0);
      return parseFloat(sum).toFixed(2);
    },
  },
};
</script>

<style lang="scss">
.t-bold {
  font-weight: 700;
}
$borderStyle: 2px solid black;
.b-summary-container {
  padding: 10px;
  .clinic-addr {
    display: flex;
    .addr-line {
      margin-left: 20px;
    }
  }
  .logo-container {
    img {
      height: 85px;
      width: auto;
    }
  }
  .invoice-table {
    margin-top: 1rem;
    p {
      margin: 0;
    }
    .currency {
      text-align: right;
    }
    thead {
      th {
        padding: 2px;
        border-top: $borderStyle;
        border-bottom: $borderStyle;
        &:not(:first-child) {
          text-align: right;
        }
      }
    }
    tbody {
      td {
        border-top-style: none;
        padding: .25rem;
      }
      tr.item-row {
        td.category-subtitle {
          padding-left: 40px;
        }
      }
      tr:first-child {
        td {
          padding-top: .5rem;
        }
      }
      tr:not(.subtotal-tr):last-child {
        td {
          padding-bottom: .5rem;
        }
      }
      tr.subtotal-tr {
        td {
          border-top: $borderStyle;
          &:first-child {
            width: 40%;
            border-top-style: none;
          }
        }
        &.top-row {
          td {
            border-top: $borderStyle;
          }
        }
        &.no-border {
          td {
            border-top-style: none;
          }
        }
        &.payments:not(:first-child) {
          td {
            padding-top: 2px;
            padding-bottom: 2px;
          }
        }
        &.payments.first-payment-row {
          td {
            padding-top: 1rem;
          }
        }
      }
    }
  }
}
</style>
