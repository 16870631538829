/* eslint-disable no-param-reassign */
import { SET_INV_CREDITS } from './type.mutation';
import creditService from '@/services/inventory-credit.service';

const state = {
  all: [],
  movement: [],
};

const mutations = {
  [SET_INV_CREDITS](_state, payload) {
    _state.all = payload;
    if (payload.creditMovement) {
      _state.movement = payload.creditMovement;
    } else {
      _state.movement = [];
    }
  },
};

const actions = {
  createCredit({ commit }, { credit, params }) {
    return creditService.create(credit, { params })
      .then((data) => {
        // TODO: fetch credit here
        commit(SET_INV_CREDITS, data);
        return data;
      });
  },
  transferCredit({ commit }, { patientId, payload }) {
    return creditService.transferCredit({ patientId, payload })
      .then((data) => {
        // TODO: fetch credit here\
        // console.log('Data', data);
        // commit(SET_INV_CREDITS, data);
        return data; // data;
      });
  },
  fetchCredit({ commit }, { patientId }) {
    return creditService.getById({ patientId })
      .then((data) => {
        commit(SET_INV_CREDITS, data.data);
        return data.data;
      });
  },
  purchaseCrdit({ commit }, { id, user }) {
    // console.log('Purchase Credit ===========', id);
    // console.log('Purchase Credit ===========', user);

    return creditService.purcaseCredit({ id, user })
      .then(data =>
        // commit(SET_INV_CREDITS, data.data);
        data.data,
      );
  },
  redeemCredit({ commit }, { patientId, payload }) {
    payload = { 'payment': payload }
    return creditService.redeemCredit({ patientId, payload })
      .then((data) => {
        // TODO: fetch credit here\
        // console.log('Data', data);
        // commit(SET_INV_CREDITS, data);
        return data; // data;
      });
  },
  redeemCreditProvider({ commit }, { providerId, payload }) {
    payload = { 'payment': payload }
    return creditService.redeemCreditProvider({ providerId, payload })
      .then((data) => {
        // TODO: fetch credit here\
        // console.log('Data', data);
        // commit(SET_INV_CREDITS, data);
        return data; // data;
      });
  },
  showCredit({ commit }, { id }) {
    // console.log('Purchase Credit ===========', id);
    return creditService.showCredit({ id })
      .then(data => data.data);
  },
};

const getters = {
  all: _state => _state.all,
  movement: _state => state.movement,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
