var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "previous-sessions-container",
      class:
        (_vm.openSessions ? "visible" : "invisible") + " " + _vm.toolbarPosition
    },
    [
      _c("div", { staticClass: "space-between" }, [
        _c("span", [_vm._v(_vm._s(_vm.$t("general.revisionHistory")))]),
        _c(
          "span",
          {
            on: {
              click: function($event) {
                return _vm.preCloseHistory()
              }
            }
          },
          [_vm._v("X")]
        )
      ]),
      _vm.openSessions
        ? _c(
            "span",
            _vm._l(_vm.sessions.slice().reverse(), function(session, index) {
              return _c(
                "div",
                { key: session[3], staticClass: "cndh-content-item" },
                [
                  _c(
                    "span",
                    {
                      on: {
                        click: function($event) {
                          return _vm.redrawSession(session)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("general.session")) +
                          " #" +
                          _vm._s(_vm.sessions.length - index) +
                          " " +
                          _vm._s(_vm.$t("general.byUser")) +
                          " " +
                          _vm._s(session[2]) +
                          " " +
                          _vm._s(_vm.$t("general.at")) +
                          " " +
                          _vm._s(_vm.formatTime(session[3])) +
                          "\n            "
                      )
                    ]
                  )
                ]
              )
            }),
            0
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }