var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form-group",
    {
      class: _vm.getClass(_vm.formData, _vm.rowIdx, _vm.qns),
      attrs: {
        label: _vm.item.label,
        disabled:
          _vm.viewing || _vm.item.disabled || _vm.item.display === "hide",
        id: _vm.qns + "group_link"
      }
    },
    [
      _c("b-form-input", {
        attrs: {
          id: _vm.qns + "input",
          value:
            _vm.item.dateFormat && _vm.item.dateFormat.length > 0
              ? _vm.parseDateValue(_vm.item.answer, _vm.item.dateFormat)
              : _vm.item.answer,
          type: _vm.item.inputType,
          plaintext: _vm.item.plaintext,
          readonly: _vm.item.readonly,
          placeholder: _vm.item.placeholder
        },
        on: {
          input: function($event) {
            return _vm.updateAnswer($event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }