<template>
    <div>
      <!-- // TODO - chan - REFACTOR! DUPLICATE!!! -->
        <div class="row mt-3 mb-3" v-if="parentComponent !== 'canvas-editor'">
          <div class="col">
            <div class="action-btn-container">
              <button class="btn btn-outline-primary"
                v-if="selectedNotes.length && isOnSameClinicWithInvoice"
                v-b-tooltip.hover title="Delete"
                @click="showCasenoteActionModal"
                v-acl="'consultation_delete_note'">
                  <v-icon name="trash" scale="1"/>
                  {{ $t('general.delete') }}
              </button>

              <button class="btn btn-outline-primary"
                v-if="inPatientFeatureFlag && selectedNotes.length && isOnSameClinicWithInvoice"
                v-b-tooltip.hover title="Tag Selected Notes"
                @click="() => toggleTagSelectedCaseNotes(true)">
                  <v-icon name="tag" scale="1"/>
                  {{ $t('general.tag') }}
              </button>
              <button class="btn btn-outline-primary"
                v-if="inPatientFeatureFlag && selectedNotes.length &&  isOnSameClinicWithInvoice"
                v-b-tooltip.hover title="Untag Selected Notes"
                @click="() => toggleTagSelectedCaseNotes(false)">
                <v-icon name="tag" scale="1" color="grey"/>
                  {{ $t('general.untag') }}
              </button>
              <button class="btn btn-outline-primary"
                v-if="selectedNotes.length"
                v-b-tooltip.hover title="Download Selected Notes"
                v-acl="'consultation_download_pdf'"
                @click="downloadSelectedCaseNotes">
                  <v-icon name="download" scale="1"/>
                  {{ $t('general.download') }}
              </button>

              <button class="btn btn-outline-primary"
                v-if="selectedNotes.length && isOnSameClinicWithInvoice"
                v-acl="'consultation_bulk_move_notes'"
                @click="() => showCaseNoteActionModal('casenotes-move-modal')">
                  <v-icon name="arrows-alt" scale="1"/>
                  {{ $t('general.move') }}
              </button>

              <button class="btn btn-outline-primary"
                v-if="selectedNotes.length === 1 && notesMapped[selectedNotes[0]].otherPagesId.length > 0 && isOnSameClinicWithInvoice"
                v-b-tooltip.hover title="Split Selected Note"
                v-acl="'consultation_split_note'"
                @click="() => showCaseNoteActionModal('casenote-split-modal')">
                  <v-icon name="copy" scale="1"/>
                  {{ $t('general.split') }}
              </button>

              <button class="btn btn-outline-primary"
                v-b-tooltip.hover
                :disabled="
                  (parentComponent === 'charting-view' && noVisitsYet) ||
                  (parentComponent === 'consult-note-container' && !mainForm.draft)
                "
                data-automation="charting-upload-files"
                v-if="!consultNoteLock() && isOnSameClinicWithInvoice"
                v-show="parentComponent !== 'tcm-charting-view'"
                v-acl="'consultation_upload_files'"
                :title="$t('general.uploadFiles')">
                  <form enctype="multipart/form-data"
                    style="position: relative;">
                    <input type="file"
                      multiple
                      ref="uploadFilesButton"
                      :disabled="
                        (parentComponent === 'charting-view' && noVisitsYet) ||
                        (parentComponent === 'consult-note-container' && !mainForm.draft)"
                      @change="filesChange($event.target.files)"
                      :value="inputFileValue"
                        accept="image/jpeg,image/png,application/pdf" class="input-file">
                    <v-icon name="upload" style="margin-right: 5px;"/>
                    <span>{{ $t('general.uploadFiles') }}</span>
                  </form>
              </button>

              <button class="btn btn-outline-primary"
                data-automation="add-charting"
                @click="openTemplateSelector"
                v-if="!consultNoteLock() && isOnSameClinicWithInvoice"
                v-acl="'consultation_add_charting'"
                v-show="parentComponent !== 'tcm-charting-view'"
                :disabled="
                  (parentComponent === 'charting-view' && noVisitsYet) ||
                  (parentComponent === 'consult-note-container' && !mainForm.draft) ||
                  lock
                "
                style="margin-left: 10px;">
                <span class="vertical-align">
                    <v-icon name="plus" style="margin-right: 5px;"/>
                    <span>{{ $t('general.addCharting') }}</span>
                </span>
              </button>
            </div>
          </div>
        </div>

        <div class="row chartings-container">
          <div
            class="col-sm-12"
            style="width: auto;"
            v-for="(day, index) in (parentComponent === 'charting-view' ? draggableNotes : filteredChartingNotes)"
            :key="index"
          >
            <div class="text-center mb-2">
              <!-- CHAN: EDIT -->
              <strong v-if="presetChartingSortType == 'name'">------ {{(day[0].name || 'Unknown')[0].toUpperCase()}}  ------</strong>
              <strong v-else-if="presetChartingSortType == 'order'">------ Drag notes to change their order  ------</strong>
              <strong v-else>------ {{sortType}}{{ formatVDDate(day[0].dateDividerUnix, { format: 'MMM DD, YYYY', lang: $i18n.locale }) }} ------</strong>
            </div>
            <draggable :id="`chart-${index}`" class="row mb-5" v-model="draggableNotes[index]" v-bind="dragOptions"
              :disabled="presetChartingSortType !== 'order'"
              @change="(evt, originalEvent) => onDragMoved(evt, originalEvent, index)">
                <div
                  v-for="charting in day"
                  v-acl:disabled.isDynamic="charting.categoryId"
                  class="charting-card m-4 mt-5"
                  :class="{'selected': hasSelected(charting._id)}"
                  :style="chartingBodyStyle"
                  :id="'chartingcard-'+charting._id"
                  :key="charting._id"
                >
                  <div
                    class="charting-card-label space-between"
                    :style="{'background-color': getColorLabel(charting)}"
                    >
                    <span class="left-icons">
                      <span @click="toggleStar(charting._id)"
                        v-if="isOnSameClinicWithInvoice">
                        <v-icon
                          name="star"
                          class="star"
                          :color="charting.starred ? 'gold' : 'white'"
                          scale="1.6"/>
                      </span>
                    </span>
                    <span class="right-icons novideodrop">
                      <span
                        v-if="parentComponent !== 'canvas-editor' && showAddendum(charting) && isOnSameClinicWithInvoice"
                        v-acl="'consultation_view_note'"
                        @click="onClickAddendum(charting)"
                      >
                        <span class="addendum-count rounded-circle">
                          {{ (charting.addendums || []).length > 0 ? charting.addendums.length : '' }}
                        </span>
                        <v-icon name="paperclip" class="paperclip" scale="1.5" />
                      </span>
                      <span
                        v-if="parentComponent !== 'canvas-editor'"
                        v-acl="'consultation_view_note'"
                        @click="viewCharting(charting)"
                      >
                        <v-icon name="search" class="search" scale="1.5" />
                      </span>
                      <span
                        v-if="(!notesLock(charting) && isOnSameClinicWithInvoice && isEditable(charting))"
                        v-acl="'consultation_edit_note'"
                        :class="{'disabled-icon': !checkPermissions(charting, 'update')}"
                        @click="editCharting(charting, 'maximized')"
                      >
                        <v-icon
                          name="edit"
                          class="edit"
                          scale="1.6"
                          v-b-tooltip.hover.top="getChartingPermissionsTooltip(charting)"
                        />
                      </span>
                      <b-dropdown class="more-icons" v-if="parentComponent !== 'canvas-editor'">
                        <template v-slot:button-content>
                          <v-icon name="ellipsis-h" class="more-actions" scale="1.3"/>
                        </template>
                        <b-dropdown-item class="print-dropdown"
                          v-if="aclHasAccess('consultation_print_note') && featureFlags.printTPH"
                          @click="prePrintNote(charting)">
                          {{ $t('general.printTPH') }}
                          <v-icon name="print" scale="0.9"/>
                        </b-dropdown-item>
                        <b-dropdown-item class="print-dropdown"
                          v-if="aclHasAccess('consultation_print_note')"
                          @click="prePrintNote(charting, true)">
                          {{ $t('general.print') }}
                          <v-icon name="print" scale="0.9"/>
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="aclHasAccess('consultation_download_pdf')"
                          @click="downloadNoteAsPDF(charting)">
                          {{ $t('general.noteDownloadPDF') }}
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="
                            aclHasAccess('consultation_split_note') &&
                            notesMapped[charting._id].otherPagesId &&
                            notesMapped[charting._id].otherPagesId.length > 0 &&
                            isOnSameClinicWithInvoice
                          "
                          @click="selectedNotes.push(charting._id); showSplitCaseNoteModal()">
                          {{ $t('general.noteSplit') }}
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="aclHasAccess('consultation_duplicate_note') && isOnSameClinicWithInvoice">
                          {{ $t('general.noteDuplicate') }}
                        </b-dropdown-item>
                      </b-dropdown>
                    </span>
                  </div>
                  <div class="charting-card-body"
                    :style="chartingCardBodyStyle"
                    @click="selectNotesForAction(charting._id, index)"
                  >
                      <img
                      :src="charting.signedThumbnailKey"
                      alt=""
                      :id="'charting-'+charting._id"
                      :style="charting.otherPagesId &&  charting.otherPagesId.length > 0 && imageStyle">
                    <div class="selectionnum-container">
                      <p class="selectionnum">
                        {{ selectedNotes.findIndex(id => id == charting._id) + 1 }}
                      </p>
                    </div>
                    <span class="info-icon">
                      <v-icon name="info-circle" scale="1.5"
                        v-b-tooltip.hover.bottom="() => getChartingMetadataTooltip(charting)">
                      </v-icon>
                    </span>
                  </div>
                  <div class="addendum-container"
                      v-if="isInLargeView && enabledAddendum && charting.addendums && charting.addendums.length > 0">
                      <h5>Addendum Added</h5>
                      <div class="addendum"
                        v-for="addm in (charting.addendums || [])"
                        :key="addm._id">
                        <div class="mb-1">
                          <p v-for="(addmp, index) in breakHtmlParagraph(addm.text)"
                            :key="index">
                            <span
                              :class="{'blank': addmp === '&nbsp;'}">
                              {{ addmp }}
                            </span>
                          </p>
                        </div>
                        <v-icon name="user" scale="0.8" class="addm-icon"/>
                        <span class="mt-1">
                          {{ addm.username }}
                        </span>&nbsp;&nbsp;
                        <v-icon name="calendar-alt" scale="0.8" class="addm-icon"/>
                        <span>
                          {{ addm.date | formatDateTime_DDdMMMYYYYFromX }}
                        </span>
                      </div>
                    </div>
                  <div class="addendum-input-field"
                    v-if="showAddendumInput && charting._id === selectedNoteForAddendum">
                    <div class="form-group">
                      <label>Add Addendum</label>
                      <textarea class="form-control" v-model="addendumDataMap[charting._id]"/>
                    </div>
                    <div class="form-group">
                      <button class="btn btn-primary"
                        :disabled="!addendumDataMap[charting._id]
                          || (addendumDataMap[charting._id] && !addendumDataMap[charting._id].trim())"
                        @click="handleAddAddendum(charting)">Save</button>&nbsp;
                      <button class="btn btn-primary"
                        @click="closeAddendum(charting)">Cancel</button>
                    </div>
                  </div>
                  <div class="charting-footer mt-1">
                    <div class="name">
                      <span class="ellipses">{{charting.name}}</span>
                    </div>
                    <div>{{charting.createdAt | formatVDDate({format: 'DD MMM YYYY, h:mm:ssa'}) }}</div>
                    <small v-if="charting.taggedTab || charting.otId" class="text-muted">
                      (Tagged
                      <span v-if="charting.otId"> to {{otMappedIdToName[charting.otId]}}</span>
                      <span v-if="charting.taggedTab"> under {{charting.taggedTab | translateTabName}}</span>)
                    </small>
                  </div>
                </div> <!-- charting-card -->
            </draggable> <!-- row -->
          </div> <!-- charting-container -->
        </div>
        <b-modal ref="casenotes-action-modal" size="md" :title="actionTitle" hide-footer>
            <p>
                Please confirm the following action:
            </p>
            <p class="font-weight-bold">Deletion of:</p>
            <ul>
                <li v-for="id in selectedNotes" :key="id">
                    {{ notesMapped[id].name }}
                    ({{ notesMapped[id].otherPagesId.length + 1 }} pages)
                </li>
            </ul>
            <b-button class="float-right my-3" variant="outline-primary"
              @click="onConfirmDelete">
                {{ $t('pharmacy.confirm') }}
            </b-button>
        </b-modal>

        <b-modal ref="casenote-split-modal" size="md" :title="'Split'" hide-footer>
          <b-form-group
            class="mt-2"
            label="How do you want to arrange the resulting pages of the split note?">
            <b-form-radio-group
              class="mt-4"
              id="radio-group-2"
              v-model="splittingOrder"
              name="split-radio-sub-component">
              <b-form-radio value="ascending">Ascending order (page 1, 2, 3, ..., x)</b-form-radio>
              <b-form-radio value="descending">Descending order (page x, ..., 3, 2, 1)</b-form-radio>
            </b-form-radio-group>
            <b-form-checkbox
              v-model="shouldRememberSplittingOrder"
              name="split-last-remembered-checkbox"
              class="mt-5">
              <div>Remember this splitting arrangement on this device</div>
              <div v-if="lastRememberedSplittingOrder">(current remembered order: <strong>{{lastRememberedSplittingOrder}}</strong>)</div>
            </b-form-checkbox>
          </b-form-group>
          <b-button-group class="float-right">
            <b-button class="mx-1" variant="outline-primary"
              @click="splitSelectedCaseNote">
                {{ $t('pharmacy.confirm') }}
            </b-button>
            <b-button class="mx-1" variant="outline-secondary"
              @click="() => hideCaseNoteActionModal('casenote-split-modal')">
                {{ $t('general.cancel') }}
            </b-button>
          </b-button-group>
        </b-modal>

        <b-modal ref="casenotes-move-modal" size="md" :title="'Bulk Move'" hide-footer>
          <b-form-group
            class="mt-3"
            label="Which category do you want to bulk move the selected note(s) to?">
            <div>(current category:
              <span v-if="originateFromSameCategory.length == 1">{{categoriesMapped[originateFromSameCategory[0]].name}}</span>
              <span v-else><i>multiple values</i></span>)
            </div>
            <b-list-group class="mt-3">
              <b-list-group-item
                v-for="(catId, index) in filteredCategoryIds" :key="index"
                @click="selectedMoveDestination = catId"
                :active="selectedMoveDestination == catId"
                v-acl:disabled.isDynamic="catId"
                :disabled="originateFromSameCategory.length == 1 && originateFromSameCategory[0] == catId">
                {{categoriesMapped[catId].name}}
              </b-list-group-item>
            </b-list-group>
          </b-form-group>
          <b-button-group class="float-right mt-3">
            <b-button class="mx-2" variant="outline-secondary"
              @click="() => hideCaseNoteActionModal('casenotes-move-modal')">
                {{ $t('general.cancel') }}
            </b-button>
            <b-button class="mx-2" variant="outline-primary"
              @click="moveSelectedCaseNotes" :disabled="!selectedMoveDestination">
                {{ $t('pharmacy.confirm') }}
            </b-button>
          </b-button-group>
        </b-modal>

        <upload-manager
            :showUploadManager="showUploadManager"
            :closeUploadManager="closeUploadManager"
            :files="filesToBeUploaded"
            :uploadFiles="submitFiles"
            :updateUploadLineItem="updateUploadLineItem"
            :categoriesOptions="categoriesOptions"
            :uploading="uploading"
            :extraValidation="selectedInvoice !== null">
            <template slot="extra">
              <div class="form-group row">
                <label for="staticEmail" class="col-sm-3 col-form-label">
                  {{ $t('general.visitDate') }}
                </label>

                <div class="col-sm-9">
                  <b-form-select id="staticEmail"
                    v-model="selectedInvoice"
                    class="mb-3">
                    <template v-slot:first>
                      <option :value="null" data-automation="visit-date-select-default">-- Select Visit Date --</option>
                      <option v-for="(invoice, indexOpt) in invoiceOptions"
                          :key="indexOpt"
                          :value="invoice.value"
                          :data-automation="'invoice-options-'+indexOpt"
                      >
                        {{  formatVDDate(new Date(invoice.text), { format: 'DD MMM YYYY', lang: $i18n.locale }) }} -- {{ CLINIC_MAP[invoice.value.clinic].name }}
                      </option>
                    </template>
                  </b-form-select>
                </div>
              </div>
            </template>
        </upload-manager>

        <b-modal
          :id="`tagging-modal-${mainForm ? mainForm._id : ''}`"
          ref="tag-notes-modal"
          :title="tagModalTitle"
        >
          <template slot="default" v-if="tagParams.action === 'Tag'">
            <b-form-group>
              <b-form-select
                v-model="tagParams.tab"
                :options="tagOptions.tabs"
              ></b-form-select>
            </b-form-group>
            <b-form-group>
              <b-form-select
                v-model="tagParams.ot"
                :options="otOptions"
              ></b-form-select>
            </b-form-group>

          </template>
          <template v-slot:modal-footer>
            <button type="button"
              class="btn btn-secondary"
              @click="hideTagModal">
              {{ $t('general.cancel') }}
            </button>
            <button type="button"
              class="btn btn-primary"
              @click="handleTagModalOk">
              {{ $t('general.ok') }}
            </button>
          </template>
        </b-modal>

        <!-- TODO - chan - REFACTOR -->
        <b-modal
          v-model="showTemplateSelector"
          class="bv-modal-example"
          header-class="label"
          :hide-footer="hideFooter"
          :title="$t('general.selectChartingTemplate')"
          size="xl">
          <template slot="default">
              <div class="form-group row">
                <label for="staticEmail" class="col-sm-3 col-form-label">
                  {{ $t('general.visitDate') }}
                </label>
                <div class="col-sm-9">
                  <b-form-select id="staticEmail"
                    v-model="selectedInvoice"
                    class="mb-3">
                    <template v-slot:first>
                      <option data-automation="visit-date-default-select" :value="null">-- Select Visit Date --</option>
                      <option v-for="(invoice, indexOpt) in invoiceOptions"
                          :key="indexOpt"
                          :value="invoice.value"
                          :data-automation="'invoice-options-'+indexOpt"
                      >
                        {{ $d( new Date(invoice.text), 'short', $i18n.locale) }} -- {{ CLINIC_MAP[invoice.value.clinic].name }}
                      </option>
                    </template>
                  </b-form-select>
                </div>
              </div>

            <templates-container
              :topRightIconFunction="submitNoteTemplate"
              :topRightIcon="'brush'"
              :parentComponent="'consult-note-container'"
              :extraValidation="selectedInvoice !== null"
            />
          </template>
        </b-modal>

        <b-modal
          ref="no-visit-prompt-modal"
          :hide-footer="hideFooter"
          :title="$t('general.nopatientvisittitle')">
          <template slot="default">
            {{ $t('general.nopatientvisits') }}
            {{ invoiceFetchReady }}
          </template>
        </b-modal>
    </div>
</template>
<script>
import print from 'print-js';
import UploadField from '@/components/utils/UploadField.vue';
import DateFormatter from '@/components/mixins/dateformatter';
import Transformer from '@/components/mixins/transformer';
import moment from 'moment';
import UploadManager from '@/components/utils/UploadManager.vue';
import {
  mapGetters, mapState, mapActions, mapMutations,
} from 'vuex';
import { userHasAccessToFn } from '@/helpers/acl';
import uuid from 'uuid';
import TemplatesContainer from '@/components/TemplatesContainer.vue';
import { bus } from '@/main';
import _ from 'lodash';
import constant from '@/services/constant';
import noteService from '@/services/note.service';

export default {
  name: 'ChartingContainer',
  props: {
    patient: {
      default: null,
      type: Object,
    },
    invoices: {
      type: Array,
    },
    info: {
      type: Object,
    },
    pagination: {
      type: Object,
    },
    mainForm: {
      default: null,
      type: Object,
    },
    currentNoteId: {
      default: null,
      type: String,
    },
    invoice: {
      default: null,
      type: Object,
    },
    notesMapped: {
      default: null,
      type: Object,
    },
    categoriesMapped: {
      default: null,
      type: Object,
    },
    parentComponent: {
      default: false,
      type: String,
    },
    refreshCanvasEditor: {
      default: null,
      type: Function,
    },
    selectedCategoryFilter: {
      default: null,
      type: String,
    },
    fetchAllNoteTemplates: {
      type: Function,
      default() {
        console.log('FETCHING NOTE TEMPLATES');
      },
    },
    presetChartingCardWidth: {
      type: Number,
    },
    invoiceFetchReady: {
      type: Boolean,
      default: false,
    },
    lock: {
      type: Boolean,
      default: false,
    },
    inPatientFeatureFlag: {
      type: Boolean,
      default: false,
    },
    presetChartingCardSize: {
      type: String,
      default: 'small',
    },
    otOptions: {
      type: Array,
    },
    otMappedIdToName: {
      type: Object,
    },
  },
  components: { UploadField, UploadManager, TemplatesContainer },
  mixins: [DateFormatter, Transformer],
  data() {
    return {
      dragOptions: {
        animation: 200,
        group: 'customorder',
        ghostClass: 'ghost',
      },
      draggableNotes: [],
      selectedNotes: [],
      actionTitle: 'Delete',
      isDeleteAction: true,
      showUploadManager: false,
      filesToBeUploaded: [],
      inputFileValue: null,
      uploading: false,
      selectedInvoice: null,
      selectedMainForm: null,
      hideFooter: true,
      showTemplateSelector: false,
      templateSelectorInfo: {
        invoiceId: '',
        consultNoteId: '',
      },
      splittingOrder: 'ascending',
      shouldRememberSplittingOrder: false,
      lastRememberedSplittingOrder: null,
      selectedMoveDestination: null,
      selectedNoteForAddendum: '',
      showAddendumInput: false,
      addendumDataMap: {},
      userAndRolesDictionary: {
        creator: { name: 'Creator', type: 'general' },
        anyone: { name: 'Anyone', type: 'general' },
        creator_own_roles: { name: "Users from Creator' Role(s)", type: 'general' },
      },
      tagParams: {
        action: '',
        tab: '',
        ot: '',
      },
      tagOptions: {
        tabs: [
          { text: 'Medical Notes', value: 'medical notes' },
          { text: 'Consents', value: 'consents' },
        ],
      },
    };
  },
  watch: {
    selectedCategoryFilter() {
      this.selectedNotes = [];
    },
    filteredChartingNotes(notes) {
      this.draggableNotes = _.cloneDeep(notes || []);
    },
    addendumDataMap: {
      handler() {
        this.checkAddendumChanges();
      },
      deep: true,
    },
  },
  filters: {
    formatDayDivider(value) {
      return moment(value).format('dddd, MMMM Do YYYY');
    },
    translateTabName(tabName) {
      return tabName === 'medical notes' ? 'Medical Notes' : 'Consents';
    },
  },
  computed: {
    ...mapGetters('acl', { allRoles: 'roles' }),
    ...mapGetters('users', { allUsers: 'all' }),
    ...mapGetters('config', ['featureFlags']),
    ...mapGetters('note', ['notecategories', 'presetChartingSortOptions']),
    ...mapGetters('clinic', { CLINIC_MAP: 'clinicIDS' }),
    ...mapState('note', [
      'trashCategoryId',
      'viewer',
      'presetChartingSortType',
      'presetChartingSortOrder',
      'cachedPermissions',
      'categoriesOptions',
    ]),
    ...mapState('notetemplate', [
      'notetemplatesMappedById',
      'consultNoteTemplatesOnly',
      'templatesSelectOptions',
    ]),
    isOnSameClinicWithInvoice() {
      return (this.invoice && this.invoice.clinic)
        ? this.invoice.clinic === this.selectedClinic._id
        : true;
    },
    defaultCardWidth() {
      return this.presetChartingCardWidth ? this.presetChartingCardWidth : window.innerHeight / 3;
    },
    chartingBodyStyle() {
      return {
        width: `${this.defaultCardWidth * 0.75}px`,
      };
    },
    chartingCardBodyStyle() {
      return {
        width: `${this.defaultCardWidth * 0.75}px`,
        height: `${this.defaultCardWidth}px`,
      };
    },
    imageStyle() {
      if (this.presetChartingCardWidth > 600) {
        return {
          'box-shadow':
                `4px 0 4px rgba(0, 0, 0, .15),
                14px -14px 0 0px white,
                16.5px -13.5px 4px 0px rgba(0, 0, 0, .15)`,
        };
      }
      return {
        'box-shadow':
                `1.5px 0 1.5px rgba(0, 0, 0, .15),
                6px -6px 0 0px white,
                7px -6px 1px 0px rgba(0, 0, 0, .15)`,
      };
    },
    invoiceOptions() {
      if (this.patientConfig.lockConsultNoteAt) {
        return (this.invoices || []).filter(i => !this.hasPassedNoteLockTimes(i.date))
          .map(invoice => ({
            value: invoice,
            text: invoice.createdAt,
          }));
      }
      return this.invoices && this.invoices.map(invoice => ({
        value: invoice,
        text: invoice.createdAt,
      })) || [];
    },
    noVisitsYet() {
      return this.invoiceFetchReady && this.invoices.length <= 0;
    },
    uncategorizedCategoryId() {
      return this.notecategories.filter(cat => cat.name === 'Uncategorized')[0]._id;
    },
    filteredChartingNotes() {
      let toBeMapped = [];
      let divider = '';
      const dividerIndexes = {};
      const output = [];

      if (this.parentComponent === 'charting-view' || this.parentComponent === 'tcm-charting-view') {
        toBeMapped = Object.values(this.notesMapped || {});
      } else if (this.mainForm) {
        toBeMapped = this.mainForm.chartingNoteIds.map(id => this.notesMapped[id]);
      }
      if (this.presetChartingSortType == 'name') {
        toBeMapped = toBeMapped.sort((n1, n2) => {
          if ((n1.name || 'Unknown')[0].toLowerCase() > (n2.name || 'Unknown')[0].toLowerCase()) {
            return parseInt(this.presetChartingSortOrder);
          }
          return parseInt(this.presetChartingSortOrder) * -1;
        });
      } else if (this.presetChartingSortType === 'order') {
        const empty = toBeMapped.filter(n => !('order' in n));
        const sorted = toBeMapped.filter(n => ('order' in n)).sort((n1, n2) => parseInt(n2.order, 10) - parseInt(n1.order, 10));
        toBeMapped = [...sorted, ...empty];
      } else {
        toBeMapped = toBeMapped.sort((n1, n2) => {
          if (n1[this.presetChartingSortType] > n2[this.presetChartingSortType]) {
            return parseInt(this.presetChartingSortOrder);
          }
          return parseInt(this.presetChartingSortOrder) * -1;
        });
      }


      if (this.parentComponent === 'canvas-editor') {
        if (this.mainForm && this.mainForm.chartingNoteIds) {
          toBeMapped.forEach((n) => {
            if (n._id !== this.currentNoteId) {
              if (this.presetChartingSortType === 'name') {
                const firstLetter = (n.name || 'Unknown')[0].toLowerCase();
                if (dividerIndexes[firstLetter] === undefined) {
                  output.push([n]);
                  dividerIndexes[firstLetter] = output.length - 1;
                } else {
                  const i = dividerIndexes[firstLetter];
                  output[i].push(n);
                }
              } else {
                const d = this.presetChartingSortType == 'createdAt' ? n.createdAt : n.updatedAt;
                n.dateDividerUnix = moment(d).startOf('day').valueOf();
                if (!divider || divider !== n.dateDividerUnix) {
                  divider = n.dateDividerUnix;
                  output.push([n]);
                } else {
                  output[output.length - 1].push(n);
                }
              }
            }
          });
        }
        return output;
      }
      const { trashCategoryId } = this.$store.state.note;
      if (this.parentComponent === 'charting-view' || this.parentComponent === 'tcm-charting-view') {
        toBeMapped
          .filter(n => n.type !== 'mainForm' && !n.coverPageId)
          .forEach((n) => {
            if (this.presetChartingSortType === 'name') {
              const firstLetter = (n.name || 'Unknown')[0].toLowerCase();
              if (dividerIndexes[firstLetter] === undefined) {
                output.push([n]);
                dividerIndexes[firstLetter] = output.length - 1;
              } else {
                const i = dividerIndexes[firstLetter];
                output[i].push(n);
              }
            } else if (this.presetChartingSortType === 'order') {
              if (output.length === 0) {
                output.push([]);
              }
              output[0].push(n);
            } else {
              const d = this.presetChartingSortType == 'createdAt' ? n.createdAt : n.updatedAt;
              n.dateDividerUnix = moment(d).startOf('day').valueOf();
              if (dividerIndexes[n.dateDividerUnix] == undefined) {
                divider = n.dateDividerUnix;
                output.push([n]);
                dividerIndexes[n.dateDividerUnix] = output.length - 1;
              } else { // have this dividerUnix before
                const i = dividerIndexes[n.dateDividerUnix];
                output[i].push(n);
              }
            }
          });
      } else {
        toBeMapped
          .forEach((n) => {
            let validCategory = true;
            if (this.selectedCategoryFilter === 'All'
                        && n.categoryId !== trashCategoryId) validCategory = true;
            else if (this.selectedCategoryFilter === 'Starred'
                        && n.categoryId !== trashCategoryId) validCategory = n.starred;
            else if (this.selectedCategoryFilter === 'Trash') validCategory = n.categoryId === trashCategoryId;
            else validCategory = this.selectedCategoryFilter === n.categoryId && n.categoryId !== trashCategoryId;
            if (n.type !== 'mainForm' && !n.coverPageId
                        && this.mainForm._id === n.mainFormId && validCategory) {
              if (this.presetChartingSortType === 'name') {
                const firstLetter = n.name[0].toLowerCase();
                if (dividerIndexes[firstLetter] === undefined) {
                  output.push([n]);
                  dividerIndexes[firstLetter] = output.length - 1;
                } else {
                  const i = dividerIndexes[firstLetter];
                  output[i].push(n);
                }
              } else {
                const d = this.presetChartingSortType == 'createdAt' ? n.createdAt : n.updatedAt;
                n.dateDividerUnix = moment(d).startOf('day').valueOf();
                if (dividerIndexes[n.dateDividerUnix] == undefined) {
                  divider = n.dateDividerUnix;
                  output.push([n]);
                  dividerIndexes[n.dateDividerUnix] = output.length - 1;
                } else { // have this dividerUnix before
                  const i = dividerIndexes[n.dateDividerUnix];
                  output[i].push(n);
                }
              }
            }
          });
      }
      return output;
    },
    filteredCategoryIds() {
      return Object.keys(this.categoriesMapped)
        .filter(cId => cId != this.trashCategoryId && !this.categoriesMapped[cId].isDeleted);
    },
    originateFromSameCategory() {
      if (!this.selectedNotes) return false;
      return this.selectedNotes
        .map(noteId => this.notesMapped[noteId].categoryId)
        .filter((catId, index, self) => self.indexOf(catId) === index);
    },
    sortType() {
      if (!this.presetChartingSortType) return;
      switch (this.presetChartingSortType) {
        case 'createdAt': {
          return `${this.$t('general.createdOn')} `;
        }
        case 'updatedAt': {
          return 'Edited on ';
        }
      }
    },
    patientConfig() {
      return this.$store.state.config.patient;
    },
    isInLargeView() {
      return this.presetChartingCardSize === 'large';
    },
    enabledAddendum() {
      return this.$store.state.config.patient.enableAddendum;
    },
    tagModalTitle() {
      if (this.selectedNotes.length > 1) {
        return `${this.tagParams.action} Notes`;
      }
      return `${this.tagParams.action} Note`;
    },
  },
  methods: {
    ...mapActions('note', ['patchNoteOrders']),
    async onDragMoved(evt, originalEvent, index) {
      const page = this.pagination.page || 0;
      const limit = this.pagination.limit || 5;
      const TOTAL_PAGES = this.pagination.count;
      if (evt.moved) {
        const indices = [evt.moved.oldIndex, evt.moved.newIndex];
        const dragged = this.draggableNotes[index].slice(Math.min(...indices), Math.max(...indices) + 1);
        const output = [];
        for (let i = 0; i < dragged.length; i += 1) {
          const { _id } = dragged[i];
          const position = this.draggableNotes[index].findIndex(nt => nt._id === dragged[i]._id);
          const order = TOTAL_PAGES - ((page * limit) + position);
          output.push({ _id, order });
        }
        await this.patchNoteOrders(output);
      }
    },
    handleTagModalOk() {
      const params = {
        ids: this.selectedNotes,
        updates: {
          taggedTab: this.tagParams.tab || null,
          otId: this.tagParams.ot || null,
        },
      };
      const verb = this.tagParams.action.toLowerCase();
      this.$store.dispatch('note/patchNotes', params)
        .then((res) => {
          console.log('patchNotes res ', res);
          if (res.ok) {
            this.selectedNotes = [];
            this.hideTagModal();
            this.flash(`Note(s) ${verb}ged successfully`, 'success', { timeout: 3000 });
          } else {
            this.flash(`Failed to ${verb} notes, please try again`, 'error', { timeout: 4000 });
          }
        });
    },
    hideTagModal() {
      this.$refs['tag-notes-modal'].hide();
    },
    aclHasAccess(fnID) {
      if (!fnID) return true; // if not pass, consider accessible to all roles
      return userHasAccessToFn(fnID);
    },
    getChartingMetadataTooltip(charting) {
      let message = '';
      message = `Number of pages: ${charting.otherPagesId.length + 1}`;
      const editUser = this.lastEdited(charting);
      if (editUser) {
        message = `${`Last edited by: ${editUser}` + '\n'}${message}`;
      }
      message = `${`Created by: ${((charting.creatorId && charting.creatorId.name) || 'Unknown')}` + '\n'}${message}`;
      return message;
    },
    getChartingPermissionsTooltip(charting) {
      let message = 'Editable by: ';
      if (charting.permissions === undefined || _.isEmpty(charting.permissions)) {
        message += 'anyone';
        return message;
      }
      message = 'Editable by: ';
      if (charting.permissions.anyone) {
        message += 'anyone';
        return message;
      }
      for (let i = 0; i < Object.keys(charting.permissions).length; i++) {
        const id = Object.keys(charting.permissions)[i];
        if (!charting.permissions[id].update) continue;
        const data = this.userAndRolesDictionary[id];
        if (data) {
          const { name } = data;
          if (data.type == 'general') {
            message += `${name}, `;
          } else if (data.type == 'role') {
            message += `users of '${name}' role, `;
          } else if (data.type == 'user') {
            message += `${name}, `;
          }
        }
      }
      message = message.slice(0, message.length - 2);
      return message;
    },
    prePrintNote(note, printFullNote, downloadFullNote) {
      console.log('note ', note, printFullNote, downloadFullNote);
      const prePrintNote = note;
      prePrintNote.printFullNote = printFullNote;
      prePrintNote.downloadFullNote = downloadFullNote;
      if (note.type === 'drawing') this.printDrawingNotes(prePrintNote);
      if (note.type === 'typing') this.printTypingNotes(prePrintNote);
    },
    async printTypingNotes(note) {
      this.$store.commit('note/SET_PRINT', { note });
      console.log('zzzz: ', note);
      const routeData = this.$router.resolve({ name: 'TypingNote' });
      window.open(routeData.href);
    },
    async printDrawingNotes(note) {
      const key = 'signedPrintKey';
      const lowreskey = 'signedThumbnailKey';
      const coverRes = await this.$store.dispatch('note/getLatestImage', { id: note._id, keys: [key, lowreskey] });
      if (coverRes) {
        const coverPage = coverRes.body[key] ? coverRes.body[key] : coverRes.body[lowreskey];
        const images = [coverPage];
        for (const nextpage of note.otherPagesId) {
          const res = await this.$store.dispatch('note/getLatestImage', { id: nextpage, keys: [key, lowreskey] });
          if (res.body) {
            images.push(res.body[key] ? res.body[key] : res.body[lowreskey]);
          }
        }
        print({
          printable: images,
          type: 'image',
        });
      } else {
        alert('Could not download notes');
      }
    },
    async openTemplateSelector() {
      // TODO - chan - REFACTOR : Duplicate, toggle on is different
      // WY comment - we need formDataBuilder, formDataBuilderOrder to create main form with typing notes correctly
      if (Object.keys(this.notetemplatesMappedById).length === 0) {
        await this.fetchAllNoteTemplates({}); // get latest and valid signed urls of thumbnail images from s3
      }
      this.showTemplateSelector = true;
      if (this.parentComponent === 'charting-view') {
        this.selectedInvoice = this.invoiceOptions[0].value || null;
      } else if (this.parentComponent === 'consult-note-container') {
        this.selectedInvoice = this.invoiceOptions.filter(item => item.value._id === this.invoice._id)[0].value;
      }
      this.templateSelectorInfo = {
        invoiceId: '',
        consultNoteId: '',
      };
    },
    async submitNoteTemplate(templateId) {
      // TODO - chan - REFACTOR! DUPLICATE!!!
      this.selectedMainForm = null;
      if (this.selectedInvoice && !this.selectedInvoice.mainFormId) {
        this.selectedMainForm = await this.createMainForm(this.selectedInvoice._id);
      } else {
        this.selectedMainForm = this.notesMapped[this.selectedInvoice.mainFormId._id];
      }
      if (this.selectedMainForm) {
        this.templateSelectorInfo = {
          invoiceId: this.selectedInvoice._id,
          consultNoteId: this.selectedMainForm._id,
        };
        const templateType = this.notetemplatesMappedById[templateId].type;
        const params = { templateId, templateType, ...this.templateSelectorInfo };

        let birthDate;
        let visitDate;
        let visitDateBE;
        if (this.patient.dateOfBirth) {
          birthDate = moment(this.patient.dateOfBirth).format('DD MMM YYYY');
        }
        if (this.templateSelectorInfo.invoiceId) {
          const currentInvoice = this.invoices.filter(
            data => data._id == this.templateSelectorInfo.invoiceId,
          );
          if (currentInvoice.length > 0) {
            visitDate = moment(currentInvoice[0].createdAt).format('DD MMM YYYY');
            // visitDate is always in EN for Bgrimm only
            if (this.featureFlags.bgrimm) {
              visitDate = this.formatVDDate(currentInvoice[0].createdAt, { format: 'DD MMM YYYY', lang: 'en' });
            }
            visitDateBE = this.formatVDDate(currentInvoice[0].createdAt, { format: 'DD MMM YYYY', lang: 'th' });
          }
        }
        const queueDetails = this.$store.state.queue;
        if (queueDetails !== undefined) {
          const listQueue = queueDetails.all.items.filter(
            item => item.patient && item.patient._id == this.info.patient._id
              && this.selectedInvoice.queue.call_number == item.call_number
              && this.selectedInvoice.queue.queue_time == item.queue_time,
          );
          if (listQueue.length > 0) {
            this.info.patient.doctor = listQueue[0].provider;
          }
        }
        params.info = {
          ...this.info,
          patient: {
            ...this.info.patient,
            birthDate,
            visitDate,
            visitDateBE,
          },
        };
        params.creatorId = this.user.id;
        params.patientId = this.patient._id;
        this.$store.dispatch('note/createChartingNote', params)
          .then((note) => {
            this.showTemplateSelector = false;
            this.templateSelectorInfo = {
              invoiceId: '',
              consultNoteId: '',
            };
            this.openEditor(params.consultNoteId, note);
          });
      } else {
        alert('Can not upload your files');
      }
    },
    async openEditor(consultNoteId, note) {
      const payload = {
        name: note.name,
        mainFormId: consultNoteId,
        categoryId: note.categoryId,
        noteId: note._id,
        currentPageId: note._id,
        invoiceId: note.visitId,
        open: true,
        openSize: 'maximized',
        type: note.type,
        pages: { [note._id]: this.notesMapped[note._id] },
        pagesIdOrder: [note._id],
        requireSignature: note.requireSignature,
        signature: note.signature,
        signatureDate: note.signatureDate,
      };
      if (note.permissions) {
        payload.permissions = note.permissions;
      }
      if (note.type == 'typing') {
        payload.formData = note.formData;
        payload.formDataOrder = note.formDataOrder;
        this.$store.commit('note/EXTRACT_NOTE', payload);
        setTimeout(() => {
          this.$root.$emit('bv::show::modal', 'bv-modal-note');
        }, 100);
      } else {
        if (note.otherPagesId && note.otherPagesId.length > 0) {
          for (let i = 0; i < note.otherPagesId.length; i++) {
            const nextPageId = note.otherPagesId[i];
            if (this.notesMapped[nextPageId].signedThumbnailKey === undefined) {
              await this.$store.dispatch('note/getLatestImage', { id: nextPageId, keys: ['signedThumbnailKey'] });
            }
            payload.pages[nextPageId] = this.notesMapped[nextPageId];
            payload.pagesIdOrder.push(nextPageId);
          }
        }
        this.$store.commit('note/openEditor', payload);
      }
    },
    downloadNoteAsPDF(note) {
      let url;
      const apiurl = process.env.VUE_APP_apiUrl
        ? process.env.VUE_APP_apiUrl
        : `${window.location.origin}/api`;
      if (note.type === 'drawing') {
        url = `${apiurl}/note/pdf/${note._id}`;
      } else {
        url = `${apiurl}/note/printNote/?noteId=${note._id}`;
      }
      this.$http({
        method: 'get',
        responseType: 'arraybuffer',
        url,
      })
        .then((response) => {
          const href = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', `${note.key}.pdf`); // or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => alert('could not download notes'));
    },
    filesChange(files) {
      // TODO - chan - REFACTOR! DUPLICATE!!!
      files = [...files];
      files.forEach(this.filesSelected);
    },
    filesDropped(file, i) {
      console.log('filesDropped! file is ', file);
      const name = file.name.replace(/\.[^/.]+$/, '');
      this.filesToBeUploaded.push({
        file, name, categoryId: this.uncategorizedCategoryId, uuid: uuid(), status: 'Ready', state: constant.UPLOAD_STATE.PENDING,
      });
      this.showUploadManager = true;
    },
    filesSelected(file, i) {
      // TODO - chan - REFACTOR! DUPLICATE!!!
      if (this.parentComponent === 'charting-view') {
        this.selectedInvoice = this.invoiceOptions[0].value || null;
      } else if (this.parentComponent === 'consult-note-container') {
        this.selectedInvoice = this.invoiceOptions.filter(item => item.value._id === this.invoice._id)[0].value;
      }
      const name = file.name.replace(/\.[^/.]+$/, '');
      this.filesToBeUploaded.push({
        file, name, categoryId: this.uncategorizedCategoryId, uuid: uuid(), status: 'Ready', state: constant.UPLOAD_STATE.PENDING,
      });
      this.showUploadManager = true;
    },
    closeUploadManager() {
      // TODO - chan - REFACTOR! DUPLICATE!!!
      this.filesToBeUploaded = [];
      this.showUploadManager = false;
      this.inputFileValue = null;
    },
    createMainForm(invoiceId) {
      // TODO - chan - REFACTOR! DUPLICATE!!!
      const mainFormPayload = {
        name: 'Notes',
        type: 'mainForm',
        draft: true,
        creator: this.$store.state.auth.user.name || '',
        creatorId: this.$store.state.auth.user.id || '',
        patientId: this.patient._id,
        visitId: invoiceId,
        chartingNoteIds: [],
        templateId: this.templatesSelectOptions[0].value,
        formData: this.consultNoteTemplatesOnly[this.templatesSelectOptions[0].value]
          .formDataBuilder,
        formDataOrder: this.consultNoteTemplatesOnly[this.templatesSelectOptions[0].value]
          .formDataBuilderOrder,
        consultationDate: this.selectedInvoice.date,
      };
      console.log('Main Form creating ', mainFormPayload);
      return new Promise((resolve, reject) => {
        this.$store.dispatch('note/create', mainFormPayload).then((response) => {
          if (response.ok) {
            resolve(response.body);
          } else {
            reject();
          }
        });
      });
    },
    async submitFiles() {
      // TODO - chan - REFACTOR! DUPLICATE!!!
      this.selectedMainForm = null;
      if (!this.selectedInvoice.mainFormId) {
        this.selectedMainForm = await this.createMainForm(this.selectedInvoice._id);
      } else {
        this.selectedMainForm = this.notesMapped[this.selectedInvoice.mainFormId._id];
      }
      if (this.selectedMainForm) {
        await this.uploadFiles();
      } else {
        alert('Can not upload your files');
      }
    },
    async uploadFiles() {
      let numJobs = 0;
      let doneJobs = 0;
      this.uploading = true;

      // eslint-disable-next-line no-restricted-syntax
      for (const fileToBeUploaded of this.filesToBeUploaded) {
        numJobs += 1;
        // eslint-disable-next-line no-await-in-loop
        const uploaded = await this.uploadFile(fileToBeUploaded);

        if (uploaded) {
          doneJobs += 1;
        }
      }

      if (numJobs === doneJobs) {
        // add sleep for 1.5 seconds to let user see the whole result before closing the upload manager
        await new Promise(resolve => setTimeout(() => resolve(), 1500));

        this.closeUploadManager();
      }

      this.uploading = false;
    },
    /* eslint no-param-reassign: ["error", { "props": false }] */
    async uploadFile(fileToBeUploaded) {
      const self = this;
      let uploaded = false;

      if (fileToBeUploaded.status === 'Uploaded') {
        return uploaded;
      }

      fileToBeUploaded.status = 'Uploading...';
      fileToBeUploaded.state = constant.UPLOAD_STATE.PROCESSING;

      const { file } = fileToBeUploaded;
      const queryString = new URLSearchParams({
        mainFormId: this.selectedMainForm._id,
        visitId: this.selectedInvoice._id,
        patientId: this.patient._id,
        name: fileToBeUploaded.name,
        categoryId: fileToBeUploaded.categoryId,
        type: 'drawing',
        creatorId: this.user.id,
      }).toString();
      const formData = new FormData();
      formData.append('myFile', file);
      const response = await noteService.uploadNote(queryString, formData);
      const { status: resStatus, body: resBody, statusText: resStatusText } = response;

      if (resStatus === 200) {
        try {
          const updatedConsultNote = resBody[0];
          let newChartingNotes = resBody[1]; // an array of a single or multiple new notes
          const mainFormCloned = _.cloneDeep(self.mainForm);

          if (self.parentComponent !== 'charting-view' && self.parentComponent !== 'tcm-charting-view') {
            self.$store.commit('note/updateNote', { id: updatedConsultNote._id, key: 'chartingNoteIds', value: updatedConsultNote.chartingNoteIds });

            if (!Array.isArray(newChartingNotes)) {
              mainFormCloned.chartingNoteIds.push(newChartingNotes);
              newChartingNotes = [newChartingNotes];
            } else {
              mainFormCloned.chartingNoteIds.push(newChartingNotes[0]);
            }

            self.$store.commit('invoice/PATCH_INVOICE', { id: self.invoice._id, key: 'mainFormId', value: mainFormCloned });
          }

          self.$store.commit('note/updateNote', { id: updatedConsultNote._id, key: 'chartingNoteIds', value: updatedConsultNote.chartingNoteIds });

          if (!Array.isArray(newChartingNotes)) {
            mainFormCloned.chartingNoteIds.push(newChartingNotes);
            newChartingNotes = [newChartingNotes];
          }

          self.$store.commit('note/ADD_NEW_NOTE', newChartingNotes);

          fileToBeUploaded.status = 'Uploaded';
          fileToBeUploaded.state = constant.UPLOAD_STATE.COMPLETED;
          uploaded = true;
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error('Error uploading: ', err);
        }

        return uploaded;
      }

      // Error. Inform the user
      fileToBeUploaded.status = 'Error';
      fileToBeUploaded.state = constant.UPLOAD_STATE.FAILED;
      self.flash(resStatusText || 'Something went wrong!!', 'error', { timeout: 3000 });

      return uploaded;
    },
    updateUploadLineItem(e, uuid, key) {
      // TODO - chan - REFACTOR! DUPLICATE!!!
      this.filesToBeUploaded = this.filesToBeUploaded.map((file) => {
        if (uuid === file.uuid) {
          const clone = Object.assign({}, file);
          clone[key] = e;
          return clone;
        }
        return file;
      });
    },
    lastEdited(note) {
      if (note.type === 'typing' && note.lastEditedBy) return note.lastEditedBy;
      const id = note._id;
      const { sessions } = this.notesMapped[id] || {};
      if (sessions && sessions.length) {
        return sessions[sessions.length - 1][2];
      }
      return false;
    },
    async viewCharting(selectedCharting) {
      if (selectedCharting.type === 'typing') {
        const payload = {
          noteId: selectedCharting._id,
          invoiceId: (this.invoice || {})._id,
          openSize: 'maximized',
          currentPageId: selectedCharting._id,
          type: selectedCharting.type,
          name: selectedCharting.name,
          categoryId: selectedCharting.categoryId,
          viewing: true,
          requireSignature: selectedCharting.requireSignature,
          signature: selectedCharting.signature,
        };
        payload.formData = _.cloneDeep(selectedCharting.formData);
        this.$store.commit('note/EXTRACT_NOTE', payload);
        this.$root.$emit('bv::show::modal', 'bv-modal-note');
        this.$emit('openMaximizedWindow');
        return;
      }
      if (this.parentComponent !== 'charting-view') {
        this.editCharting(selectedCharting);
      } else {
        const mainForm = this.notesMapped[selectedCharting.mainFormId];
        const payload = {
          open: true,
          noteId: selectedCharting._id,
          currentPageId: selectedCharting._id,
          name: selectedCharting.name,
          createdAt: selectedCharting.createdAt,
          createdBy: (selectedCharting.creatorId || {}).name || undefined,
          draft: (mainForm || {}).draft,
        };
        const res = await this.$store.dispatch('note/getLatestImage', {
          id: selectedCharting._id,
          keys: ['signedThumbnailKey', selectedCharting.strokesIdOrder.length ? 'signedPrintKey' : 'signedOriginalImageKey'],
        });
        payload.pages = { [selectedCharting._id]: this.notesMapped[selectedCharting._id] };
        payload.pagesIdOrder = [selectedCharting._id];
        if (selectedCharting.otherPagesId && selectedCharting.otherPagesId.length > 0) {
          await this.$store.dispatch('note/fetchOtherPages', {
            id: selectedCharting._id,
            keys: ['signedThumbnailKey', 'signedPrintKey'],
            returnFullDoc: true,
            showOtherPages: true,
          });
          for (let i = 0; i < selectedCharting.otherPagesId.length; i++) {
            const nextPageId = selectedCharting.otherPagesId[i];
            const nextPage = this.notesMapped[nextPageId];
            payload.pages[nextPageId] = nextPage;
            payload.pagesIdOrder.push(nextPageId);
          }
        }
        this.$store.commit('note/openViewer', payload);
      }
    },
    async editCharting(selectedCharting, maximised, editingPageId) {
      if (maximised && !this.checkPermissions(selectedCharting, 'update')) return;

      const note = selectedCharting;
      const payload = {
        noteId: selectedCharting._id,
        invoiceId: (this.invoice || {})._id,
        openSize: maximised || 'mediumSized',
        currentPageId: selectedCharting._id,
        type: selectedCharting.type,
        name: selectedCharting.name,
        categoryId: selectedCharting.categoryId,
        requireSignature: selectedCharting.requireSignature,
        signature: selectedCharting.signature,
      };
      if (this.mainForm) payload.mainFormId = this.mainForm._id;

      if (!maximised && selectedCharting.type === 'drawing') {
        const keys = ['signedThumbnailKey', 'signedPrintKey', 'strokeStore'];
        const res = await this.$store.dispatch('note/getLatestImage', { id: selectedCharting._id, keys });

        payload.pages = { [selectedCharting._id]: selectedCharting };
        payload.pagesIdOrder = [selectedCharting._id];
        if (selectedCharting.otherPagesId && selectedCharting.otherPagesId.length > 0) {
          for (let i = 0; i < selectedCharting.otherPagesId.length; i++) {
            const nextPageId = selectedCharting.otherPagesId[i];
            console.log('getLatsetImage3');
            await this.$store.dispatch('note/getLatestImage', { id: nextPageId, keys, returnFullDoc: true });
            payload.pages[nextPageId] = this.notesMapped[nextPageId];
            payload.pagesIdOrder.push(nextPageId);
          }
        }
        this.$store.commit('note/openEditor', payload);
      }

      if (maximised) {
        if (selectedCharting.type === 'drawing') {
          const res = await this.$store.dispatch('note/getLatestImage', {
            id: editingPageId || selectedCharting._id,
            keys: ['signedThumbnailKey', 'signedPrintKey', 'signedOriginalImageKey', 'strokeStore'],
          });
          const payload = {
            open: true,
            type: 'drawing',
            mainFormId: (this.mainForm || {})._id || null,
            noteId: selectedCharting._id,
            openSize: maximised,
            categoryId: selectedCharting.categoryId,
            pages: { [selectedCharting._id]: this.notesMapped[selectedCharting._id] },
            pagesIdOrder: [selectedCharting._id],
            currentPageId: selectedCharting._id,
            name: selectedCharting.name,
            invoiceId: (this.invoice || {})._id || null,
          };
          if (selectedCharting.permissions) {
            payload.permissions = selectedCharting.permissions;
          }
          if (selectedCharting.otherPagesId && selectedCharting.otherPagesId.length > 0) {
            for (let i = 0; i < selectedCharting.otherPagesId.length; i++) {
              const nextPageId = selectedCharting.otherPagesId[i];
              if (nextPageId !== editingPageId) {
                await this.$store.dispatch('note/getLatestImage', { id: nextPageId, keys: ['signedThumbnailKey', 'signedPrintKey'], returnFullDoc: true });
              }
              payload.pages[nextPageId] = this.notesMapped[nextPageId];
              payload.pagesIdOrder.push(nextPageId);
            }
          }
          this.$store.commit('note/openEditor', payload);
          if (this.parentComponent === 'canvas-editor') {
            this.refreshCanvasEditor();
          }
          if (editingPageId) {
            const editingPageIndex = payload.pagesIdOrder.findIndex(p => p === editingPageId);

            this.goToPage(editingPageIndex);
          }
        } else if (selectedCharting.type === 'typing') {
          payload.type = 'typing';
          payload.formData = _.cloneDeep(selectedCharting.formData);
          this.$store.commit('note/EXTRACT_NOTE', payload);
          this.$root.$emit('bv::show::modal', 'bv-modal-note');
        }
      } else { // not maximized size, but mediumSized (gmail tab window)
        this.$store.commit('note/EXTRACT_NOTE', payload); // yo
      }
      if (maximised === 'maximized' && selectedCharting.type === 'typing') {
        this.$emit('openMaximizedWindow');
      }
    },
    toggleStar(id) {
      const payLoad = {
        id,
        data: {
          starred: !this.notesMapped[id].starred,
        },
      };
      this.$store.dispatch('note/patchNote', payLoad);
    },
    momentFormat(date) {
      return moment(date).format('DD MMM YYYY, h:mm:ssa').valueOf();
    },
    selectNotesForAction(noteId) {
      if (this.selectedCategoryFilter === 'Trash') return;
      const index = this.selectedNotes.findIndex(id => id === noteId);
      if (index >= 0) {
        this.selectedNotes.splice(index, 1);
      } else {
        this.selectedNotes.push(noteId);
      }
    },
    hasSelected(noteId) {
      return this.selectedNotes.find(n => n === noteId);
    },
    showCasenoteActionModal() {
      this.$refs['casenotes-action-modal'].show();
    },
    clearTagParams() {
      this.tagParams = {
        action: '',
        tab: '',
        ot: '',
      };
    },
    toggleTagSelectedCaseNotes(isTag) {
      this.clearTagParams();
      if (isTag && !this.otOptions.length) {
        alert(this.$t('general.patientNoOTBookings'));
        return;
      }
      if (isTag) {
        this.tagParams.action = 'Tag';
        this.tagParams.tab = 'medical notes';
        this.tagParams.ot = this.otOptions[0].value;
      } else {
        this.tagParams.action = 'Untag';
      }
      this.$bvModal.show(`tagging-modal-${this.mainForm ? this.mainForm._id : ''}`);
    },
    downloadSelectedCaseNotes() {
      const apiurl = process.env.VUE_APP_apiUrl
        ? process.env.VUE_APP_apiUrl
        : `${window.location.origin}/api`;
      this.$http({
        method: 'post',
        url: `${apiurl}/note/pdf/multiple`,
        body: {
          ids: [...this.selectedNotes],
        },
        responseType: 'arraybuffer',
      })
        .then((response) => {
          this.flash(this.$t('general.filedownloadsuccess'), 'success', { timeout: 3000 });
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${moment().valueOf()}.pdf`); // or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => alert('could not download notes'));
    },
    showCaseNoteActionModal(modalName) {
      this.selectedMoveDestination = null;
      this.$refs[modalName].show();
    },
    hideCaseNoteActionModal(modalName) {
      this.$refs[modalName].hide();
    },
    splitSelectedCaseNote() {
      if (this.shouldRememberSplittingOrder) {
        localStorage.setItem('lastRememberedSplittingOrder', this.splittingOrder);
      }
      this.$store.dispatch('note/splitNote', { id: this.selectedNotes[0], data: { splittingOrder: this.splittingOrder } })
        .then((res) => {
          if (res.ok) {
            this.hideCaseNoteActionModal('casenote-split-modal');
            this.selectedNotes = [];
            if (this.shouldRememberSplittingOrder) {
              this.lastRememberedSplittingOrder = this.splittingOrder;
            }
            this.flash('Note split successfully', 'success', { timeout: 3000 });
          } else {
            this.flash('Failed to split note, please try again', 'error', { timeout: 4000 });
          }
        });
    },
    moveSelectedCaseNotes() {
      this.$store.dispatch('note/patchNotes', { ids: this.selectedNotes, updates: { categoryId: this.selectedMoveDestination } })
        .then((res) => {
          if (res.ok) {
            this.hideCaseNoteActionModal('casenotes-move-modal');
            this.selectedNotes = [];
            if (this.selectedCategoryFilter !== 'All') {
              this.$emit('notesmoved', this.selectedMoveDestination);
            }
            this.selectedMoveDestination = null;
            this.flash('Category of note(s) updated successfully', 'success', { timeout: 3000 });
          } else {
            this.flash('Failed to update category, please try again', 'error', { timeout: 4000 });
          }
        });
    },
    onConfirmDelete() {
      const cloneIds = [...this.selectedNotes];
      this.selectedNotes = [];
      this.$store.dispatch('note/bulkDeleteNotes', { noteIds: cloneIds, clinic: this.selectedClinic._id })
        .then(() => {
          this.$refs['casenotes-action-modal'].hide();
          this.flash('Note(s) deleted successfuly', 'success', { timeout: 3000 });
          this.$emit('notedeleted', cloneIds);
        })
        .catch(() => {
          this.$refs['casenotes-action-modal'].hide();
        });
    },
    getColorLabel(charting) {
      const { categoryId } = charting;
      return (this.categoriesMapped[categoryId] || {}).colorLabel;
    },
    notesLock(charting) {
      if (this.mainForm) {
        // in consultation view, decided based on marinForm object to lock
        return this.hasPassedNoteLockTimes(this.mainForm.consultationDate) || !this.mainForm.draft;
      }
      // in charting view, find associate mainform and decided to lock
      const mainForm = this.notesMapped[charting.mainFormId];
      if (mainForm) {
        return this.hasPassedNoteLockTimes(mainForm.consultationDate) || !mainForm.draft;
      }
      const createdAtUnix = moment(charting.createdAt).unix();
      return this.hasPassedNoteLockTimes(createdAtUnix);
    },
    isEditable(charting) {
      return (charting.type === 'drawing')
        // Typing and not all formdata has no question
        || ((charting.type === 'typing') && !Object.values(charting.formData || {}).every(e => e.qns === ''));
    },
    hasPassedNoteLockTimes(consultNoteTime = 0, cutOffTime = '23:59') {
      if (!this.patientConfig.lockConsultNoteAt) return false;
      const [hour, minute] = this.patientConfig.lockConsultNoteTime.split(':');
      const cutoff = moment(consultNoteTime, 'X')
        .set({ hour, minute, second: '59' }).unix();
      return moment().unix() > cutoff;
    },
    consultNoteLock() {
      if (!this.patientConfig.lockConsultNoteAt) return false;
      if (this.parentComponent === 'consult-note-container') {
        return this.hasPassedNoteLockTimes(this.mainForm.consultationDate);
      }
      return false;
    },
    showAddendum(charting) {
      if (!this.enabledAddendum) return false;
      if (this.parentComponent === 'consult-note-container') {
        return !this.mainForm.draft && this.isInLargeView;
      } if (this.parentComponent === 'charting-view') {
        console.log('charting ', charting);
        const mainForm = this.notesMapped[charting.mainFormId];
        return mainForm && !mainForm.draft && this.isInLargeView;
      }
      return false;
    },
    closeAddendum(charting) {
      this.showAddendumInput = false;
      this.addendumDataMap[charting._id] = '';
    },
    onClickAddendum(charting) {
      this.selectedNoteForAddendum = charting._id;
      this.showAddendumInput = true;
    },
    handleAddAddendum(charting) {
      const addendums = charting.addendums || [];
      addendums.push({
        date: moment().unix(),
        user: this.user.id,
        username: this.user.name,
        text: this.addendumDataMap[charting._id] || '',
      });
      const payLoad = {
        id: charting._id,
        data: {
          addendums,
        },
      };
      this.$store.dispatch('note/patchNote', payLoad)
        .then(() => {
          this.flash('Addendum addeded successfully', 'success', { timeout: 3000 });
          this.closeAddendum(charting);
        });
    },
    breakHtmlParagraph(text = '') {
      return text.split(/(?:\r\n|\r|\n)/g)
        .map(s => (!s ? '&nbsp;' : s));
    },
    checkPermissions(charting, action) {
      const chartingId = charting._id;
      const { permissions } = charting;
      if (!permissions) return true;
      if (this.cachedPermissions[chartingId] && this.cachedPermissions[chartingId][action] !== undefined) {
        return this.cachedPermissions[chartingId][action];
      }
      if (!this.cachedPermissions[chartingId]) this.cachedPermissions[chartingId] = {};
      if (permissions.anyone && permissions.anyone[action]) {
        this.updateCachedPermissions({ chartingId, action, toggle: true });
        return true;
      }
      if (permissions[this.user.id] && permissions[this.user.id][action]) {
        this.updateCachedPermissions({ chartingId, action, toggle: true });
        return true;
      }
      for (let i = 0; i < this.user.roles.length; i++) {
        const role = this.user.roles[i];
        if (permissions[role._id] && permissions[role._id][action]) {
          this.updateCachedPermissions({ chartingId, action, toggle: true });
          return true;
        }
      }
      this.updateCachedPermissions({ chartingId, action, toggle: false });
      return false;
    },
    checkAddendumChanges() {
      let flag = false;
      for (const chartingId of Object.keys(this.addendumDataMap)) {
        console.log('Addendum >>> ', this.addendumDataMap[chartingId], chartingId);
        if (this.addendumDataMap[chartingId]) {
          flag = true;
          break;
        }
      }
      const { mainFormId } = this.notesMapped[this.selectedNoteForAddendum];
      console.log(flag, this.parentComponent, this.parentComponent === 'charting-view');
      if (flag && this.parentComponent === 'consult-note-container') {
        this.$emit('chartingAddendumChange', { mainFormId, chartingAddendumChange: flag });
      } else {
        this.$emit('chartingAddendumChange', { mainFormId, chartingAddendumChange: false });
      }
      if (flag && this.parentComponent === 'charting-view') {
        this.$store.commit('note/SET_MAIN_FORMS_CHANGE_LOGS', { mainFormId, flag: true });
      } else if (this.parentComponent === 'charting-view') {
        this.$store.commit('note/SET_MAIN_FORMS_CHANGE_LOGS', { mainFormId, flag: false });
      }
    },
    ...mapMutations('note', ['updateCachedPermissions']),
  },
  created() {
    bus.$on('tcm.uploadImage', () => {
      if (this.$refs && this.$refs.uploadFilesButton) {
        this.$refs.uploadFilesButton.click();
      }
    });
    bus.$on('tcm.addChartings', () => {
      this.openTemplateSelector();
    });
  },
  mounted() {
    if (this.invoiceFetchReady && this.noVisitsYet && localStorage.chartingView === 'CHART_VIEW') {
      this.$refs['no-visit-prompt-modal'].show();
    }
    const lastRememberedSplittingOrder = localStorage.getItem('lastRememberedSplittingOrder');
    if (lastRememberedSplittingOrder) {
      this.splittingOrder = lastRememberedSplittingOrder;
      this.lastRememberedSplittingOrder = lastRememberedSplittingOrder;
      this.shouldRememberSplittingOrder = true;
    }
    this.allRoles.forEach((role) => {
      this.userAndRolesDictionary[role._id] = { name: role.roleName, type: 'role' };
    });
    this.allUsers.forEach((user) => {
      this.userAndRolesDictionary[user.id] = { name: user.name, type: 'user' };
    });
    if (this.otOptions && this.otOptions.length) {
      this.tagParams.ot = this.otOptions[0].value;
    }
  },
  beforeDestroy() {
    bus.$off('pageEdit');
    bus.$off('tcm.addChartings');
    bus.$off('tcm.uploadImage');
  },
};
</script>

<style lang="scss" scoped>
.space-around {
  display: flex;
  justify-content: space-around;
}
.space-between {
  display: flex;
  justify-content: space-between;
}
.action-btn-container {
  float: right;
  button {
    margin: 0 5px;
  }
}
.chartings-container {
  position: relative;
  height: inherit;
  width: auto;
  padding: 25px 15px;

  .draggable-charts {
    display: inline-flex;
  }

    .ghost {
      opacity: 0.5;
      background: #c8ebfb;
    }

    .charting-card {
      width: inherit;

      position: relative;
      .charting-card-label {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        height: 46px;
        padding: 8px;
        z-index: 20;

        .left-icons {
          z-index: 30;
          span {
            cursor: pointer;
          }
          .star:hover {
            color: gold;
          }
        }
        .right-icons {
          position: relative;
          z-index: 30;
          span {
            cursor: pointer;
            margin: 0 3px;
          }
          .more-icons {
            & > button {
              padding: 2px;
            }

            /deep/ .dropdown-toggle {
              padding: 2px;
              background: transparent;
              border: 0;
              color: rgb(77, 77, 77);
              &::after {
                display:none;
              }
            }

            /deep/ .dropdown-menu {
              top: 3px !important;
              background-color: #F0F0F0;

              a {
                color: rgb(77, 77, 77);
              }

              .print-dropdown {
                a {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;

                  .fa-icon {
                    margin-left: 5px;
                  }
                }
              }
            }
          }
          .addendum-count {
            color: #fff;
            font-weight: bold;
            position: absolute;
            bottom: 15px;
            font-size: 16px;
            padding: 0.1em 0.6em;
            background-color: #da4c4c;
          }
        }
      }

      .charting-card-body {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        background-color: rgb(236,236,236);
        position: relative;
        // height: 85%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 25;
        padding-bottom: 4px;
        img {
          max-width: 92.5%;
          max-height: 93.5%;
        }
        .info-icon {
          color: rgb(77,77,77);
          position: absolute;
          top: 3%;
          left: 3%;
          padding-left: 4px;
        }
      }
      .addendum-input-field {
        padding: 8px;
        z-index: 20;
      }
      .addendum-container {
          margin-top: 15px;
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 92.5%;
          font-size: 16px;
          .addendum {
            border: 1px solid #ddd;
            background-color: #fff;
            padding: 5px 10px;
            margin-bottom: 3px;

            .addm-icon {
              color: rgba(158, 158, 158, 0.867);
            }
            p {
              font-size: 1.3em;
              margin-bottom: 0px;
            }
            span {
              font-size: 0.8em;
              line-height: 2;
            }
            span.blank {
              opacity: 0;
            }
          }
      }
      .charting-footer {
        display: flex;
        flex-direction: column;
        text-align: center;
        .name {
          display: flex;
          font-size: 20px;
          font-weight: 800;
          .ellipses {
            flex-grow: 1;
            width: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .selectionnum {
      display: none;
    }
    .charting-card.selected {
        .charting-card-label {
          border-top: 4px solid black;
          border-left: 4px solid black;
          border-right: 4px solid black;
          padding: 4px 4px 0 4px;
        }
        .charting-card-body {
          border-left: 4px solid black;
          border-right: 4px solid black;
          border-bottom: 4px solid black;
          padding-bottom: 0;

          .selectionnum-container {
            position: absolute;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .selectionnum {
              display: block;
              margin: 0;
              color: rgb(107, 227, 141);
              font-size: 10em;
              font-weight: bold;
            }
          }
          .info-icon {
            padding-left: 0px;
          }
        }
    }
  .upload-field {
    z-index: 10;
  }
}

.input-file {
    opacity: 0; /* invisible but it's there! */
    top: 0%;
    right: 0%;
    max-width: 100%;
    height: 100%;
    position: absolute;
}

[class^="icon"] {
  position: relative;
  top: -1px;
}
.icon-text {
  position: relative;
  top: 1px;
  margin-left: 3px;
}
.disabled-icon {
  color: darkgrey;
}
</style>
