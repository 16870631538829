<template>
  <div class="invoices-container">
    <div class="invoice-item" v-for="(invoice, idx) in formattedInvoices" :key="idx">
      <header class="inv-header columns is-align-items-center">
        <div class="logo column is-6">
          <img :src="generalConfig.logoURL" :alt="generalConfig.clinicName">
        </div>
        <div class="inv-title column is-6 is-uppercase">
          <h5>{{ title }}</h5>
        </div>
      </header>
      <div class="inv-neck">
        <div class="columns is-gapless">
          <div class="column is-6">
            <div class="inv-item">
              <span class="name is-uppercase">
              {{ $t('tcm.patientName') }}:
              </span>
              <span class="value">
                {{ patient.name }}
              </span>
            </div>
          </div>
          <div class="column is-6"></div>
        </div>
        <div class="columns is-gapless">
          <div class="column is-6">
            <div class="inv-item">
              <span class="name is-uppercase">
              {{ $t('tcm.IC') }}:
              </span>
              <span class="value">
                {{ patient.nric }}
              </span>
            </div>
          </div>
          <div class="column is-6">
            <div class="inv-item" v-if="mode !== 'patient'">
              <span class="name">
              {{ $t('tcm.age') }}:
              </span>
              <span class="value">
                {{ patient.age }}
              </span>
            </div>
          </div>
        </div>
        <div class="columns is-gapless">
          <div class="column is-6">
            <div class="inv-item">
              <span class="name is-uppercase">
              {{ $t('tcm.regNo') }}:
              </span>
              <span class="value">
                {{ invoice.invoiceNo | pad }}
              </span>
            </div>
          </div>
          <div class="column is-6">
            <div class="inv-item">
              <span class="name is-uppercase">
              {{ $t('tcm.clinicName') }}:
              </span>
              <span class="value">
                {{ invoice.clinic.name }}
              </span>
            </div>
          </div>
        </div>
        <div class="columns is-gapless">
          <div class="column is-6">
            <div class="inv-item">
              <span class="name is-uppercase">
              {{ $t('tcm.doctor') }}:
              </span>
              <span class="value">
                {{ invoice.provider ? invoice.provider.name || '' : ''}}
              </span>
            </div>
          </div>
          <div class="column is-6">
            <div class="inv-item">
              <span class="name is-uppercase">
              {{ $t('tcm.CA') }}:
              </span>
              <span class="value">
                {{ user.name }}
              </span>
            </div>
          </div>
        </div>
        <div class="columns is-gapless">
          <div class="column is-6">
            <div class="inv-item">
              <span class="name is-uppercase">
              {{ $t('tcm.visitDate') }}:
              </span>
              <span class="value">
                {{ invoice.visitDate | dateFormat_DDMMYYYY }}
              </span>
            </div>
          </div>
          <div class="column is-6">
            <div class="inv-item">
              <span class="name is-uppercase">
              {{ $t('tcm.printDate') }}:
              </span>
              <span class="value">
                {{ printDate }}
              </span>
            </div>
          </div>
        </div>
        <div class="columns is-gapless" v-if="mode === 'patient' ">
          <div class="column is-6">
            <div class="inv-item">
              <span class="name is-uppercase">
                diagnosis:
              </span>
              <span class="value">
                {{ invoice.diseaseName }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="inv-body">
        <table class="table is-bordered is-fullwidth">
          <thead>
            <tr>
              <th>
                <div class="name-en is-uppercase">{{ $t('tcm.code') }}</div>
                <div class="name-cn" v-if="mode === 'patient'">代码</div>
              </th>
              <th>
                <div class="name-en is-uppercase">{{ $t('tcm.medicineName') }}</div>
                <div class="name-cn" v-if="mode === 'patient'">药名</div>
              </th>
              <th>
                <div class="name-en is-uppercase">{{ $t('tcm.medicineNameEn') }}</div>
                <div class="name-cn" v-if="mode === 'patient'">药名(英)</div>
              </th>
              <th v-if="isDosageDisplayable">
                <div class="name-en is-uppercase">{{ $t('tcm.dailyDosage') }}</div>
                <div class="name-cn" v-if="mode === 'patient'">日量</div>
              </th>
              <th>
                <div class="name-en is-uppercase">{{ $t('tcm.remarks') }}</div>
                <div class="name-cn" v-if="mode === 'patient'">备注</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in invoice.printableItems" :key="index">
              <td>{{ item.givenId }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.chineseName }}</td>
              <td v-if="isDosageDisplayable">{{ getPrintDailyDosage(item, invoice) }} {{ $t('tcm.gram') }}</td>
              <td>{{ item.remarks }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="inv-sig columns is-gapless" v-if="mode === 'patient'">
        <div class="column is-4"></div>
        <div class="column is-8">
          <div class="sig is-uppercase">SIGNATURE TCM PHAYSICIAN {{ invoice.provider.name }}</div>
        </div>
      </div>
      <div class="inv-footer" v-if="isDosageDisplayable">
        <div v-if="mode === 'patient'" class="is-uppercase">instruction</div>
        <div v-if="langCNTR || mode === 'patient'">
          服法：每日 {{ invoice.packagePerDay }} 包；共 {{ invoice.totalDays }} 天；每日量 {{invoice.powderDosage}} 克;
        </div>
        <div v-if="mode === 'patient' ">
          Dosage: {{ invoice.packagePerDay }} pack(s) per day, {{ invoice.totalDays }} day(s), total {{ invoice.totalPackages }} pack(s), {{invoice.powderDosage}}g per day;
        </div>
        <div v-if="langEn && mode !== 'patient' ">
          Dosage: {{ invoice.packagePerDay }} Package(s)/Day For {{ invoice.totalDays }} Day(s); Daily dosage: {{invoice.powderDosage}} g;
        </div>
      </div>
      <div style="break-after: always;page-break-after: always;" v-if="idx !== (invoices.length - 1)"></div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters } from 'vuex';
import Transformer from '@/components/mixins/transformer';
import { POWDER_CATEGORIES, HERBAL_CATEGORIES } from '@/components/TCMConsultation/utils';

export default {
  name: 'InvoicesComponent',
  props: {
    disableAutoPrint: {
      type: Boolean,
      default: false,
    },
    printLocal: {
      type: Boolean,
      default: false,
    },
    lang: {
      type: String,
      default: 'en',
    },
    patient: {
      type: Object,
      default: function fn() {
        return {
          name: '',
        };
      },
    },
    generalConfig: {
      type: Object,
      default: function fn() {
        return {
          logoURL: '',
          clinicName: '',
        };
      },
    },
    invoices: {
      type: Array,
      default: function fn() {
        return [];
      },
    },
    mode: {
      type: String,
      default: 'patient', // "ca", "patient"
    },
  },
  mixins: [Transformer],
  computed: {
    ...mapGetters('config', {
      invoiceConfig: 'invoice',
    }),
    isChineseHerbalMedicineOrderRecieverEmailSetUp() {
      return _.chain(this)
        .get('invoiceConfig.chineseHerbalMedicineOrderRecieverEmail')
        .invoke('trim')
        .isEmpty()
        .thru(value => !value)
        .value();
    },
    langEn() {
      return this.$i18n.locale === 'en';
    },
    langCNTR() {
      return this.$i18n.locale === 'zh-cn-tr';
    },
    title() {
      const { mode } = this;
      if (mode === 'ca') return '配药单';
      if (mode === 'patient') return 'PRESCRIPTION';
      return '';
    },
    isDosageDisplayable() {
      const { mode } = this;
      return mode !== 'patient-without-dosage';
    },
    formattedInvoices() {
      const { invoices } = this;
      return invoices.map(invoice => ({
        ...invoice,
        printableItems: this.getPrintItems(invoice),
      }));
    },
  },
  filters: {
    dateFormat_DDMMYYYY(dt) {
      return dt ? moment(String(dt), 'x').format('DD/MM/YYYY') : '';
    },
    pad(str) {
      return str.padStart(10, '0');
    },
  },
  data() {
    return {
      printDate: moment().format('DD/MM/YYYY'),
    };
  },
  created() {
    if (this.lang) {
      this.$i18n.locale = this.lang;
    }
  },
  methods: {
    getPrintItems(invoice) {
      const { isChineseHerbalMedicineOrderRecieverEmailSetUp } = this;
      console.debug('getPrintItems/isChineseHerbalMedicineOrderRecieverEmailSetUp:', isChineseHerbalMedicineOrderRecieverEmailSetUp);
      if (isChineseHerbalMedicineOrderRecieverEmailSetUp) {
        return invoice.items.filter(i => new Set(POWDER_CATEGORIES).has(i.category));
      }
      return invoice.items.filter(i => new Set([...POWDER_CATEGORIES, ...HERBAL_CATEGORIES]).has(i.category));
    },
    getPrintDailyDosage(item, invoice) {
      const rawValue = item.qty * invoice.dilutionRatio / invoice.totalDays;
      return this.to2Decimal(rawValue);
    },
  },
  mounted() {
    // remove all the styles
    const styles = document.querySelectorAll('style');
    styles.forEach(ele => ele.remove());

    import('../../../assets/print/bulma.min.css'); // eslint-disable-line
    import('../../../assets/print//paper.min.css'); // eslint-disable-line
    import('../../../assets/print/tcm-invoice-ca.scss'); // eslint-disable-line

    if (!this.disableAutoPrint) {
      setTimeout(() => {
        window.print();
      }, 2000);
    }
  },
};
</script>
