var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _c("consult-note-print", {
        attrs: {
          selectedPatient: _vm.selectedPatient,
          generalConfig: _vm.general,
          selectedConsultNote: _vm.selectedConsultNote,
          patientConfig: _vm.patient
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }