/* eslint-disable max-len */
import moment from 'moment';
import dateFormatter from '@/components/mixins/dateformatter';
import moneyFormatter from '@/components/mixins/money';
import invoiceMethodsMixin from '@/components/mixins/invoice';

export default {
  mixins: [dateFormatter, moneyFormatter, invoiceMethodsMixin],
  computed: {
    withTaxes() {
      return this.isInvoiceWithGST(this.print, { includeGST: this.includeGST });
    },
    printEN() {
      return this.$i18n.locale === 'en';
    },
    printTH() {
      return this.$i18n.locale === 'th';
    },
    patientPayments() {
      const payments = [];
      if (Array.isArray(this.print.payments) && this.print.payments.length) {
        // Loop through the payment records
        this.print.payments.filter(p => (!p.voided)).forEach((p) => {
          if (Array.isArray(p.payments) && p.payments.length) {
            // Loop through the patient payments records
            p.payments.forEach((pp) => {
              let l = pp.label;
              if (pp.defaultPayment) {
                l = this.$t(`paymentMode.${pp.mode}`);
                if (pp.mode === 'offset') {
                  l = pp.label.replace('Offset', this.$t('paymentMode.offset'));
                }
                // eslint-disable-next-line
                pp.labelTrans = l;
              } else {
                // eslint-disable-next-line
                pp.labelTrans = l;
              }
              const patientPayment = {
                paymentId: pp._id,
                paymentRef: p.paymentRef,
                label: pp.labelTrans,
                amount: pp.amount,
              };
              payments.push({ ...patientPayment });
            });
          }
        });
      }
      return payments;
    },
    itemsByBillingGroup() {
      // sort items by thaiBillingGroup
      const rawItems = [];
      const uncatedItems = [];
      this.print.items.forEach((item) => {
        const { thaiBillingGroup } = item;
        if (!thaiBillingGroup) {
          uncatedItems.push(item);
          return;
        }
        const [code] = thaiBillingGroup.split(','); // 1.1.1(3)
        const bgObject = this.print.billingGroupObject[code]; // { code: '', parnetEng, ...}
        if (!bgObject) {
          uncatedItems.push(item);
          return;
        }
        rawItems.push(item);
      });

      const items = rawItems.sort((a, b) => {
        const [aCode] = a.thaiBillingGroup.split(',');
        const [bCode] = b.thaiBillingGroup.split(',');
        if (aCode > bCode) return 1;
        if (aCode < bCode) return -1;
        return 0;
      });

      // convert data to object with inventory type as key
      // example: {
      // '1.1.11, 1.1.11  ค่าบริการฝังเข็ม, 1.1.1(1)  ค่ายา': [{...}, {...}],
      // '1.1.1(3), 1.1.1(3)  ค่ายาฉีด, 1.1.1(3) Injection': [{...}, {...}]
      // 'code,name,description': [{....}, {...}]
      // }
      const print = items.reduce((result, item) => {
        const r = result;
        const { thaiBillingGroup } = item;
        if (!thaiBillingGroup) return r;
        r[thaiBillingGroup] = r[thaiBillingGroup] || [];
        r[thaiBillingGroup].push(item);
        return r;
      }, {});

      if (uncatedItems.length > 0) {
        print['Not Categorised'] = uncatedItems;
      }

      const results = [];

      // An array to save generated parent items. If an item's label not in this array, we should generate this item's parent.
      const parentsGenerated = [];

      Object.keys(print).forEach((thaiBillingGroupString) => {
        const bgItems = print[thaiBillingGroupString];
        const amount = parseFloat(bgItems.reduce((acc, i) => acc + parseFloat(i.beforeDiscountPrice), 0)).toFixed(2);
        const discount = parseFloat(bgItems.reduce((acc, i) => acc + parseFloat(i.discountAmount), 0)).toFixed(2);
        const netAmount = parseFloat(bgItems.reduce((acc, i) => acc + parseFloat(i.afterTaxPrice), 0)).toFixed(2);

        let tableLineItem;
        if (thaiBillingGroupString === 'Not Categorised') {
          const notCategorisedLabel = 'Not Categorised';
          const notCategorisedLabelLocal = this.$t('general.notCategorised');
          tableLineItem = {
            description: (this.printEN ? notCategorisedLabel : notCategorisedLabelLocal),
            amount,
            discount,
            netAmount,
            code: thaiBillingGroupString,
          };
          const parentGroupCode = 'Not Categorised';
          if (!parentsGenerated.includes(parentGroupCode)) {
            results.push({
              code: parentGroupCode,
              isTitle: true,
              description: (this.printEN ? notCategorisedLabel : notCategorisedLabelLocal),
            });
            parentsGenerated.push(parentGroupCode);
          }
        } else {
          const [code, name, description] = thaiBillingGroupString.split(','); // 1.1.1(3)
          const bgObject = this.print.billingGroupObject[code]; // { code: '', parnetEng, ...}
          const { parentEN, parentTH, parentGroupCode } = bgObject;
          tableLineItem = {
            description: (this.printEN ? description : name),
            amount,
            discount,
            netAmount,
            code,
          };
          if (!parentsGenerated.includes(parentGroupCode)) {
            results.push({
              code: parentGroupCode,
              isTitle: true,
              description: (this.printEN ? parentEN : parentTH),
            });
            parentsGenerated.push(parentGroupCode);
          }
        }
        results.push(tableLineItem);
      });
      /*
        results:
        [1.1.1: {…}, 1.1.1(3): {…}]
        example:
        [
          1.1.1: {
            code: "1.1.1"
            description: "1.1.1 Drugs and Parenteral Nutrition"
          }
          1.1.1(2): {
            amount: 90
            code: "1.1.1(3)"
            description: "1.1.1(3) Injection"
            discount: 1.8
            netAmount: 91.73
          }
          1.1.1(3): {
            amount: 90
            code: "1.1.1(3)"
            description: "1.1.1(3) Injection"
            discount: 1.8
            netAmount: 91.73
          }
        ]
      */
      return results;
    },
    isSelfPayment() {
      const { pricingScheme } = this.print;
      if (!pricingScheme || Object.keys(pricingScheme).length === 0) return true;
      const { schemeId } = pricingScheme;
      if (!schemeId) return true;
      return false;
    },
    payor() {
      const defaultPayor = 'Self payment';
      if (this.isSelfPayment) return defaultPayor;
      const { pricingScheme } = this.print;
      const { corpName } = pricingScheme;
      return corpName;
    },
    docTypeString() {
      if (this.isSelfPayment) {
        return 'ใบเสร็จรับเงินค่ารักษาพยาบาล/Receipt';
      }
      return 'ใบแจ้งหนี้ค่ารักษาพยาบาล/Invoice';
    },
    typeOfPayments() {
      if (!this.isSelfPayment) return '';
      // TODO: i18n
      const payments = this.patientPayments.map(i => `${i.label}: ${i.amount} Baht`);
      return payments.join(', ');
    },
  },
  methods: {
    getDateFromX(d, f = 'DD/MM/YYYY') {
      return moment(d, 'X').format(f) || '';
    },
    getDocCopyString(index) {
      const pageTypeMap = {
        origin: 'ต้นฉบับ/Original',
        copy: 'สำเนา/Copy',
      };
      if (this.$route && this.$route.query) {
        const { pageType } = this.$route.query;
        if (pageType) {
          const pageTypes = pageType.split(',');
          const [firtPageType, secondPageType] = pageTypes;
          if (index === 0) {
            return pageTypeMap[firtPageType];
          }
          if (index === 1) {
            return pageTypeMap[secondPageType];
          }
        }
      }
      return 'CopyStringNotFound';
    },
  },
};
