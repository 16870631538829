/* eslint-disable no-param-reassign */
import admissionInvoiceService from '@/services/admission.invoice.service';
import {
  SET_IPD_PHARMACY_LIST,
  PATCH_IPD_PHARMACY_ITEM,
  SET_AUTHORISED_DISPENSE,
} from './type.mutation';

const state = {
  ipdPharmacies: [],
  authorisedDispense: true,
};

const mutations = {
  [SET_IPD_PHARMACY_LIST](_state, payload) {
    // eslint-disable-next-line no-param-reassign
    _state.ipdPharmacies = payload;
  },
  [PATCH_IPD_PHARMACY_ITEM](_state, payload) {
    // eslint-disable-next-line no-param-reassign
    _state.ipdPharmacies = _state.ipdPharmacies.map((pharmacy) => {
      if (payload.invoiceId === pharmacy._id) {
        // eslint-disable-next-line no-param-reassign
        pharmacy = { ...pharmacy, ...payload.updatedPayload };
      }
      return pharmacy;
    });
  },
  [SET_AUTHORISED_DISPENSE](_state, payload) {
    _state.authorisedDispense = payload;
  },
};

const actions = {
  fetchIpdPharmacyList({ commit }, params = {}) {
    return admissionInvoiceService.listPharmacies(params)
      .then((response) => {
        commit(SET_IPD_PHARMACY_LIST, response.data);
        return response.data;
      });
  },
  dispense({ commit }, { params, payload }) {
    return admissionInvoiceService.dispenseItem(params, payload)
      .then((response) => {
        commit(PATCH_IPD_PHARMACY_ITEM,
          { invoiceId: params.invoiceId, updatedPayload: response.data });
      });
  },
  record({ commit }, { params, payload }) {
    return admissionInvoiceService.recordItem(params, payload)
      .then((response) => {
        commit(PATCH_IPD_PHARMACY_ITEM,
          { invoiceId: params.invoiceId, updatedPayload: response.data });
      });
  },
  authoriseDispense({ commit }, payload) {
    return admissionInvoiceService.authoriseDispense(payload)
      .then((data) => {
        commit(SET_AUTHORISED_DISPENSE, true);
        return data;
      })
      .catch((error) => {
        commit(SET_AUTHORISED_DISPENSE, false);
        throw error;
      });
  },
};

const getters = {
  ipdPharmacies: _state => _state.ipdPharmacies,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
