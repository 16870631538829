var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _c("div", { staticClass: "row mt-5" }, [
        _c("div", { staticClass: "col-12" }, [
          _vm.general.logoURL
            ? _c("img", {
                style: {
                  marginLeft: _vm.logoMarginLeft + "px",
                  width: _vm.logoWidth + "px"
                },
                attrs: { src: _vm.general.logoURL }
              })
            : _vm._e(),
          _c("h5", { staticClass: "text-center" }, [
            _vm._v(_vm._s(_vm.general.clinicName))
          ])
        ])
      ]),
      _c("div", { staticClass: "row my-4" }, [
        _c(
          "div",
          { staticClass: "col-4" },
          [
            _c("strong", [_vm._v("SHIP TO:")]),
            _c("p", [_vm._v(_vm._s(_vm.selectedItemForPrint.patient.name))]),
            _vm._l(_vm.splitAddress, function(address) {
              return _c("div", { key: address.id }, [
                _vm._v("\n        " + _vm._s(address) + "\n      ")
              ])
            }),
            _c("p", { staticClass: "mt-3" }, [
              _c("small", [
                _vm._v(_vm._s(_vm._f("ddMonthYYYYFromX")(new Date())))
              ])
            ])
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "col-4" },
          [
            _c("strong", [_vm._v("BILL TO:")]),
            _c("p", [_vm._v(_vm._s(_vm.selectedItemForPrint.patient.name))]),
            _vm._l(_vm.splitAddress, function(address) {
              return _c("div", { key: address.id }, [
                _vm._v("\n        " + _vm._s(address) + "\n      ")
              ])
            })
          ],
          2
        )
      ]),
      _c("hr"),
      _vm.selectedItemForPrint.items
        ? [
            _vm._m(0),
            _c("packing-list-component", {
              attrs: {
                medLineItems: _vm.selectedItemForPrint.items,
                regionData: _vm.general.region
              }
            })
          ]
        : _vm._e(),
      _c("hr"),
      _c("div", { staticClass: "row mt-3" }, [
        _c("div", { staticClass: "col-9 text-right" }, [_vm._v("Subtotal:")]),
        _c("div", { staticClass: "col-3 text-left" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.general.region.currency) +
              " " +
              _vm._s(
                _vm._f("thousandSeparator")(
                  _vm._f("to2Decimal")(_vm.computeSubtotal)
                )
              ) +
              "\n    "
          )
        ])
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-9 text-right" }, [
          _vm._v("Total Discount:")
        ]),
        _c("div", { staticClass: "col-3 text-left" }, [
          _vm._v(
            "\n      -" +
              _vm._s(_vm.general.region.currency) +
              " " +
              _vm._s(
                _vm._f("thousandSeparator")(
                  _vm._f("to2Decimal")(_vm.computeTotalDiscount)
                )
              ) +
              "\n    "
          )
        ])
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-9 text-right" }, [_vm._v("Total:")]),
        _c("div", { staticClass: "col-3 text-left" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.general.region.currency) +
              " " +
              _vm._s(
                _vm._f("thousandSeparator")(
                  _vm._f("to2Decimal")(_vm.computeTotal)
                )
              ) +
              "\n    "
          )
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row weight-600 bd-top bd-bottom" }, [
      _c("div", { staticClass: "col-3" }, [_vm._v("PRODUCT")]),
      _c("div", { staticClass: "col-3" }, [_vm._v("UNIT PRICE")]),
      _c("div", { staticClass: "col-3" }, [_vm._v("QUANTITY")]),
      _c("div", { staticClass: "col-3" }, [_vm._v("TOTAL PRICE")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }