export default Object.freeze({
  patient: {
    create_new_patient: 'patient_create',
    preview: 'patient_preview',
    bulk_delete: 'patient_bulk_delete',
    bulk_queue: 'patient_bulk_add_queue',
    create_queue: 'patient_add_queue',
    edit: 'patient_edit',
    delete: 'patient_delete',
    detail: 'patient_detail',
    print_label: 'patient_print_label',
    patient_view_notes_categories: 'patient_view_notes_categories'
  },
  vital: {
    main: 'vital_main',
    create: 'vital_new',
  },
  invoice: {
    main: 'invoice_main',
    save: 'invoice_save',
    dispense: 'invoice_dispense_item',
    payment: 'invoice_payment',
    void: 'invoice_void',
    dispense_edit: 'invoice_dispense_item_edit',
    dispense_delete: 'invoice_dispense_item_delete',
    dispense_print: 'invoice_dispense_item_print',
  },
  payment: {
    main: 'payment_main',
    create: 'payment_new',
  },
  patient_appointment: {
    main: 'patient_appointment',
  },
  highlight: {
    main: 'highlight_main',
  },
  notes: {
    main: 'notes_main',
  },
  queue: {
    queue_patient: 'add_to_queue',
    edit: 'queue_edit',
    bulk_edit: 'queue_bulk_edit',
    bulk_delete: 'queue_bulk_delete',
    delete: 'queue_delete',
    dashboard: 'queue_dashboard',
  },
  appointment: {
    create: 'appointment_create',
    patient_create: 'patient_create_appointment',
    patient_queue: 'patient_create_queue_appointment',
    send_sms: 'appointment_send_sms',
    send_email: 'appointment_send_email',
  },
  inventory: {
    inventory_create: 'inventory_new',
    inventory_edit: 'inventory_edit',
    inventory_bulk_eidt: 'inventory_edit',
    inventory_bulk_delete: 'inventory_delete',
    order_create: 'order_new',
    order_edit: 'order_edit',
    order_delete: 'order_delete',
    order_bulk_send: 'order_bulk_send',
    order_bulk_edit: 'order_bulk_edit',
    order_bulk_delete: 'order_bulk_delete',
    dispensable_inventory_by_role: 'dispensable_inventory_by_role',
    inventory_import: 'inventory_import',
  },
  setup: {
    user_create: 'user_new',
    role_create: 'role_new',
    provider_create: 'provider_new',
    provider_edit: 'provider_edit',
    provider_delete: 'provider_delete',
    provider_bulk_delete: 'provider_bulk_delete',
    provider_export_csv: 'provider_export_csv',
    provider_export_excel: 'provider_export_excel',
    corporate_create: 'corporate_create',
  },
});
