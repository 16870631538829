var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { previewPrint: _vm.preview }, staticStyle: { margin: "20px" } },
    [
      _c("div", { staticClass: "row mb-3" }, [
        _c("div", { staticClass: "col-9" }, [
          _c("strong", [_vm._v(_vm._s(_vm.general.clinicName))]),
          _vm.printConfig.clinicAdd
            ? _c("p", { staticStyle: { "white-space": "pre-line" } }, [
                _vm._v("\n        " + _vm._s(_vm.invoiceAddress) + "\n      ")
              ])
            : _vm._e(),
          _vm.general.telephone
            ? _c("p", { staticClass: "mb-3 address-font" }, [
                _vm._v("Tel: " + _vm._s(_vm.general.telephone))
              ])
            : _vm._e()
        ]),
        _c(
          "div",
          { staticClass: "col-3" },
          [
            _c("strong", [_vm._v("To: " + _vm._s(_vm.selectedPatient.name))]),
            _vm.printConfig.patientAdd
              ? _vm._l(_vm.splitAddress, function(address) {
                  return _c("div", { key: address.id }, [
                    _vm._v("\n          " + _vm._s(address) + "\n        ")
                  ])
                })
              : _vm._e()
          ],
          2
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "div",
            { staticClass: "mb-3 text-center text-uppercase font-weight-bold" },
            [
              _vm._v(
                "\n        " + _vm._s(_vm.$t("general.invoice")) + "\n      "
              )
            ]
          )
        ])
      ]),
      _c("div", { staticClass: "row mb-3" }, [
        _c("div", { staticClass: "col-2" }, [
          _c("div", [_vm._v(_vm._s(_vm.$t("general.invoiceNumber")))]),
          _c("div", [_vm._v(_vm._s(_vm.$t("general.invoiceDate")))])
        ]),
        _c("div", { staticClass: "col-4" }, [
          _c("div", [_vm._v(":  " + _vm._s(_vm.print.invoiceNo))]),
          _c("div", [
            _vm._v(":  " + _vm._s(_vm._f("ddMonthYYYYFromX")(_vm.print.date)))
          ])
        ]),
        _c("div", { staticClass: "col-1" }),
        _c("div", { staticClass: "col-2" }, [
          _c("div", [_vm._v(_vm._s(_vm.$t("general.doctor")))]),
          _c("div", [_vm._v(_vm._s(_vm.$t("general.beautician")))])
        ]),
        _c("div", { staticClass: "col-3" }, [
          _c("div", [
            _vm._v(
              ":  " + _vm._s(_vm.print.provider && _vm.print.provider.name)
            )
          ]),
          _c("div", [
            _vm._v(
              ":  " + _vm._s(_vm.print.therapist && _vm.print.therapist.name)
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "row weight-600 bd-top bd-bottom" }, [
        _c(
          "div",
          {
            class: {
              "col-2 text-left": _vm.printConfig.lineItemsDesc,
              "col-4 text-left": !_vm.printConfig.lineItemsDesc
            }
          },
          [_vm._v("\n      " + _vm._s(_vm.$t("general.itemName")) + "\n    ")]
        ),
        _vm.printConfig.lineItemsDesc
          ? _c("div", { staticClass: "col-2" }, [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("general.description")) + "\n    "
              )
            ])
          : _vm._e(),
        _c("div", { staticClass: "col-1 text-right" }, [
          _vm._v("\n      " + _vm._s(_vm.$t("general.qty")) + "\n    ")
        ]),
        _c("div", { staticClass: "col-2 text-right" }, [
          _vm._v(_vm._s(_vm.$t("general.unitPrice")))
        ]),
        _c("div", { staticClass: "col-2 text-right" }, [
          _vm._v(_vm._s(_vm.$t("general.discount")))
        ]),
        _vm.withTaxes
          ? _c("div", { staticClass: "col-1 text-right" }, [
              _vm._v("\n      " + _vm._s(_vm.$t("general.tax")) + "\n    ")
            ])
          : _c("div", { staticClass: "col-1 text-right" }),
        _c("div", { staticClass: "col-2 text-right" }, [
          _vm._v(_vm._s(_vm.$t("general.total")))
        ])
      ]),
      false
        ? _c(
            "div",
            _vm._l(_vm.categorized, function(category, idx) {
              return _c(
                "div",
                { key: category.key },
                [
                  _c("div", { staticClass: "mt-3 row" }, [
                    _c("div", { staticClass: "col-12 weight-600 mb-2" }, [
                      _vm._v("\n          " + _vm._s(idx) + "\n        ")
                    ])
                  ]),
                  _vm._l(category, function(item) {
                    return _c("div", { key: item.key, staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _vm._v(
                            "\n          " + _vm._s(item.name) + "\n          "
                          ),
                          [
                            "Medicine",
                            "Consumables",
                            "Expendables",
                            "Product"
                          ].includes(item.inventory)
                            ? [
                                _c("br"),
                                _c("span", [
                                  _vm._v(
                                    "\n              - " +
                                      _vm._s(
                                        (item.selectedBatch &&
                                          item.selectedBatch.number) ||
                                          ""
                                      ) +
                                      "\n            "
                                  )
                                ])
                              ]
                            : _vm._e()
                        ],
                        2
                      ),
                      _vm.printConfig.lineItemsDesc
                        ? _c("div", { staticClass: "col-2" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(item.description) +
                                "\n        "
                            )
                          ])
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          class: {
                            "col-1 text-right": _vm.printConfig.lineItemsDesc,
                            "col-3 text-center": !_vm.printConfig.lineItemsDesc
                          },
                          staticStyle: { "padding-right": "0px" }
                        },
                        [_vm._v(_vm._s(item.qty))]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col-1 text-left",
                          staticStyle: { "padding-left": "5px" }
                        },
                        [_vm._v(_vm._s(item.unit))]
                      ),
                      _c("div", { staticClass: "col-2 text-right" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm._f("thousandSeparator")(
                                _vm._f("to2Decimal")(
                                  _vm._f("toVirtualCurrency")(
                                    item.price,
                                    _vm.virtualCurrencyConfig.exchangeRate,
                                    _vm.convertToVirtualCurrency
                                  )
                                )
                              )
                            ) +
                            "\n        "
                        )
                      ]),
                      _c("div", { staticClass: "col-1 text-right" }, [
                        item.discountType === "%"
                          ? _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm._f("thousandSeparator")(
                                      _vm._f("to2Decimal")(item.discountAmount)
                                    )
                                  ) +
                                  "%\n          "
                              )
                            ])
                          : item.discountType === "$"
                          ? _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.convertToVirtualCurrency
                                      ? ""
                                      : _vm.region.currency
                                  ) +
                                  "\n            " +
                                  _vm._s(
                                    _vm._f("thousandSeparator")(
                                      _vm._f("to2Decimal")(
                                        _vm._f("toVirtualCurrency")(
                                          item.discountAmount,
                                          _vm.virtualCurrencyConfig
                                            .exchangeRate,
                                          _vm.convertToVirtualCurrency
                                        )
                                      )
                                    )
                                  ) +
                                  "\n          "
                              )
                            ])
                          : _vm._e()
                      ]),
                      _vm.withTaxes
                        ? _c("div", { staticClass: "col-1 text-right" }, [
                            _vm._v(
                              "\n          " + _vm._s(item.tax) + "%\n        "
                            )
                          ])
                        : _c("div", { staticClass: "col-1 text-right" }),
                      _c("div", { staticClass: "col-2 text-right" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm._f("thousandSeparator")(
                                _vm._f("to2Decimal")(
                                  _vm._f("toVirtualCurrency")(
                                    item.afterDiscountPrice,
                                    _vm.virtualCurrencyConfig.exchangeRate,
                                    _vm.convertToVirtualCurrency
                                  )
                                )
                              )
                            ) +
                            "\n        "
                        )
                      ])
                    ])
                  })
                ],
                2
              )
            }),
            0
          )
        : _c(
            "div",
            _vm._l(_vm.print.items, function(item) {
              return _c("div", { key: item.key, staticClass: "row" }, [
                _c(
                  "div",
                  {
                    class: {
                      "col-2 text-left": _vm.printConfig.lineItemsDesc,
                      "col-4 text-left": !_vm.printConfig.lineItemsDesc
                    }
                  },
                  [
                    _vm._v("\n        " + _vm._s(item.name) + "\n        "),
                    [
                      "Medicine",
                      "Consumables",
                      "Expendables",
                      "Product"
                    ].includes(item.inventory)
                      ? [
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              "\n            - " +
                                _vm._s(
                                  (item.selectedBatch &&
                                    item.selectedBatch.number) ||
                                    ""
                                ) +
                                "\n          "
                            )
                          ])
                        ]
                      : _vm._e()
                  ],
                  2
                ),
                _vm.printConfig.lineItemsDesc
                  ? _c("div", { staticClass: "col-2" }, [
                      _vm._v(
                        "\n        " + _vm._s(item.description) + "\n      "
                      )
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "col-1 text-right" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(item.qty) +
                      " " +
                      _vm._s(item.unit) +
                      "\n      "
                  )
                ]),
                _c("div", { staticClass: "col-2 text-right" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm._f("thousandSeparator")(
                          _vm._f("to2Decimal")(
                            _vm._f("toVirtualCurrency")(
                              item.price,
                              _vm.virtualCurrencyConfig.exchangeRate,
                              _vm.convertToVirtualCurrency
                            ),
                            _vm.region.decimal
                          ),
                          _vm.region.thousandSeparator
                        )
                      ) +
                      "\n      "
                  )
                ]),
                _c("div", { staticClass: "col-2 text-right" }, [
                  item.discountType === "%"
                    ? _c("span", [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm._f("thousandSeparator")(
                                _vm._f("to2Decimal")(
                                  item.discount,
                                  _vm.region.decimal
                                ),
                                _vm.region.thousandSeparator
                              )
                            ) +
                            "%\n          "
                        )
                      ])
                    : item.discountType === "$"
                    ? _c("span", [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.convertToVirtualCurrency
                                ? ""
                                : _vm.region.currency
                            ) +
                            "\n            " +
                            _vm._s(
                              _vm._f("thousandSeparator")(
                                _vm._f("to2Decimal")(
                                  _vm._f("toVirtualCurrency")(
                                    item.discount,
                                    _vm.virtualCurrencyConfig.exchangeRate,
                                    _vm.convertToVirtualCurrency
                                  ),
                                  _vm.region.decimal
                                ),
                                _vm.region.thousandSeparator
                              )
                            ) +
                            "\n          "
                        )
                      ])
                    : _vm._e()
                ]),
                _vm.withTaxes
                  ? _c("div", { staticClass: "col-1 text-right" }, [
                      _vm._v("\n        " + _vm._s(item.tax) + "%\n      ")
                    ])
                  : _c("div", { staticClass: "col-1 text-right" }),
                _c("div", { staticClass: "col-2 text-right" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm._f("thousandSeparator")(
                          _vm._f("to2Decimal")(
                            _vm._f("toVirtualCurrency")(
                              item.afterTaxPrice,
                              _vm.virtualCurrencyConfig.exchangeRate,
                              _vm.convertToVirtualCurrency
                            ),
                            _vm.region.decimal
                          ),
                          _vm.region.thousandSeparator
                        )
                      ) +
                      "\n      "
                  )
                ])
              ])
            }),
            0
          ),
      _vm.redeem && _vm.redeem.point > 0
        ? _c("div", [
            _c("div", { staticClass: "row weight-600" }, [
              _c("div", { staticClass: "col-10 text-left" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("membership.redeemOfPoint")) +
                    " " +
                    _vm._s(_vm.region.currency) +
                    "\n      "
                )
              ]),
              _c("div", { staticClass: "col-2 text-right" }, [
                _vm._v(
                  "\n        -" +
                    _vm._s(
                      _vm._f("thousandSeparator")(
                        _vm._f("to2Decimal")(
                          _vm.redeem.amount,
                          _vm.region.decimal
                        ),
                        _vm.region.thousandSeparator
                      )
                    ) +
                    "\n      "
                )
              ])
            ])
          ])
        : _vm._e(),
      _c("div", { staticClass: "row mt-3 bd-top" }, [
        _c("div", { staticClass: "col-10 text-right" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("general.subtotal")) +
              " " +
              _vm._s(
                _vm._f("shouldHideCurrencyIfConvertingToVC")(
                  _vm.region.currency,
                  _vm.convertToVirtualCurrency
                )
              ) +
              "\n    "
          )
        ]),
        _c("div", { staticClass: "col-2 text-right" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm._f("thousandSeparator")(
                  _vm._f("to2Decimal")(
                    _vm._f("toVirtualCurrency")(
                      _vm.print.subtotal,
                      _vm.virtualCurrencyConfig.exchangeRate,
                      _vm.convertToVirtualCurrency
                    ),
                    _vm.region.decimal
                  ),
                  _vm.region.thousandSeparator
                )
              ) +
              "\n    "
          )
        ])
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-6" }, [
          _c("div", { staticClass: "weight-600" }, [
            _vm._v(" " + _vm._s(_vm.$t("general.notes")) + ": ")
          ]),
          _c("p", { staticStyle: { "white-space": "pre-line" } }, [
            _vm._v(" " + _vm._s(_vm.print.notes) + " ")
          ]),
          _c("br"),
          _c("p", [
            _vm._v(
              " " +
                _vm._s(_vm.$t("general.printedBy")) +
                ": " +
                _vm._s(_vm.user.name) +
                ", " +
                _vm._s(_vm._f("HHmmddMonthYYYY")(new Date()))
            )
          ]),
          _vm.printConfig.footer
            ? _c("p", { staticStyle: { "white-space": "pre-line" } }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.printConfig.footerContent) +
                    "\n      "
                )
              ])
            : _vm._e()
        ]),
        _c(
          "div",
          { staticClass: "col-6" },
          [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-8 text-right" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("general.promotionDiscount")) +
                    " " +
                    _vm._s(
                      _vm._f("shouldHideCurrencyIfConvertingToVC")(
                        _vm.region.currency,
                        _vm.convertToVirtualCurrency
                      )
                    ) +
                    "\n        "
                )
              ]),
              _c("div", { staticClass: "col-4 text-right" }, [
                _vm._v(
                  "\n          -" +
                    _vm._s(
                      _vm._f("thousandSeparator")(
                        _vm._f("to2Decimal")(
                          _vm._f("toVirtualCurrency")(
                            _vm.print.promotionDiscount,
                            _vm.virtualCurrencyConfig.exchangeRate,
                            _vm.convertToVirtualCurrency
                          ),
                          _vm.region.decimal
                        ),
                        _vm.region.thousandSeparator
                      )
                    ) +
                    "\n        "
                )
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-8 text-right" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("general.totalDiscount")) +
                    " " +
                    _vm._s(
                      _vm._f("shouldHideCurrencyIfConvertingToVC")(
                        _vm.region.currency,
                        _vm.convertToVirtualCurrency
                      )
                    ) +
                    "\n        "
                )
              ]),
              _c("div", { staticClass: "col-4 text-right" }, [
                _vm._v(
                  "\n          -" +
                    _vm._s(
                      _vm._f("thousandSeparator")(
                        _vm._f("to2Decimal")(
                          _vm._f("toVirtualCurrency")(
                            _vm.print.totalDiscount,
                            _vm.virtualCurrencyConfig.exchangeRate,
                            _vm.convertToVirtualCurrency
                          ),
                          _vm.region.decimal
                        ),
                        _vm.region.thousandSeparator
                      )
                    ) +
                    "\n        "
                )
              ])
            ]),
            _vm.withTaxes
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-8 text-right" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("general.tax")) +
                        " " +
                        _vm._s(
                          _vm._f("shouldHideCurrencyIfConvertingToVC")(
                            _vm.region.currency,
                            _vm.convertToVirtualCurrency
                          )
                        ) +
                        "\n        "
                    )
                  ]),
                  _c("div", { staticClass: "col-4 text-right" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm._f("thousandSeparator")(
                            _vm._f("to2Decimal")(
                              _vm._f("toVirtualCurrency")(
                                _vm.print.totalTax,
                                _vm.virtualCurrencyConfig.exchangeRate,
                                _vm.convertToVirtualCurrency
                              ),
                              _vm.region.decimal
                            ),
                            _vm.region.thousandSeparator
                          )
                        ) +
                        "\n        "
                    )
                  ])
                ])
              : _vm._e(),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-2" }),
              _c("div", { staticClass: "col-6 bd-top text-right" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("general.invoiceTotal")) +
                    " " +
                    _vm._s(
                      _vm._f("shouldHideCurrencyIfConvertingToVC")(
                        _vm.region.currency,
                        _vm.convertToVirtualCurrency
                      )
                    ) +
                    "\n        "
                )
              ]),
              _c("div", { staticClass: "col-4 bd-top text-right" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm._f("thousandSeparator")(
                        _vm._f("to2Decimal")(
                          _vm._f("toVirtualCurrency")(
                            _vm.print.totalBeforeRounding,
                            _vm.virtualCurrencyConfig.exchangeRate,
                            _vm.convertToVirtualCurrency
                          ),
                          _vm.region.decimal
                        ),
                        _vm.region.thousandSeparator
                      )
                    ) +
                    "\n        "
                )
              ])
            ]),
            _vm.processFees
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-2" }),
                  _c("div", { staticClass: "col-6 bd-top text-right" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("paymentMode.processValue")) +
                        " " +
                        _vm._s(
                          _vm._f("shouldHideCurrencyIfConvertingToVC")(
                            _vm.region.currency,
                            _vm.convertToVirtualCurrency
                          )
                        ) +
                        "\n        "
                    )
                  ]),
                  _c("div", { staticClass: "col-4 bd-top text-right" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm._f("thousandSeparator")(
                            _vm._f("to2Decimal")(
                              _vm._f("toVirtualCurrency")(
                                _vm.processFees,
                                _vm.virtualCurrencyConfig.exchangeRate,
                                _vm.convertToVirtualCurrency
                              ),
                              _vm.region.decimal
                            ),
                            _vm.region.thousandSeparator
                          )
                        ) +
                        "\n        "
                    )
                  ])
                ])
              : _vm._e(),
            _vm.roundOff !== "NONE"
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-8 text-right" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("general.roundingAdjustment")) +
                        " " +
                        _vm._s(
                          _vm._f("shouldHideCurrencyIfConvertingToVC")(
                            _vm.region.currency,
                            _vm.convertToVirtualCurrency
                          )
                        ) +
                        "\n        "
                    )
                  ]),
                  _c("div", { staticClass: "col-4 text-right" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm._f("thousandSeparator")(
                            _vm._f("to2Decimal")(
                              _vm._f("toVirtualCurrency")(
                                _vm.print.roundingAdjustment,
                                _vm.virtualCurrencyConfig.exchangeRate,
                                _vm.convertToVirtualCurrency
                              ),
                              _vm.region.decimal
                            ),
                            _vm.region.thousandSeparator
                          )
                        ) +
                        "\n        "
                    )
                  ])
                ])
              : _vm._e(),
            _c("div", { staticClass: "row weight-600" }, [
              _c("div", { staticClass: "col-2" }),
              _c("div", { staticClass: "col-6 bd-top bd-bottom text-right" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("general.totalAmountPayable")) +
                    " " +
                    _vm._s(
                      _vm._f("shouldHideCurrencyIfConvertingToVC")(
                        _vm.region.currency,
                        _vm.convertToVirtualCurrency
                      )
                    ) +
                    "\n        "
                )
              ]),
              _c("div", { staticClass: "col-4 bd-top bd-bottom text-right" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm._f("thousandSeparator")(
                        _vm._f("to2Decimal")(
                          _vm._f("toVirtualCurrency")(
                            _vm.print.total + _vm.processFees,
                            _vm.virtualCurrencyConfig.exchangeRate,
                            _vm.convertToVirtualCurrency
                          ),
                          _vm.region.decimal
                        ),
                        _vm.region.thousandSeparator
                      )
                    ) +
                    "\n        "
                )
              ])
            ]),
            _c("br"),
            _vm._l(_vm.patientPayments, function(payment) {
              return _c("div", { key: payment.paymentId, staticClass: "row" }, [
                _c("small", { staticClass: "col-8 text-right" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(payment.label) +
                      " " +
                      _vm._s(_vm.$t("general.paymentReceived")) +
                      " --\n          " +
                      _vm._s(_vm.$t("general.paymentRef")) +
                      ": " +
                      _vm._s(payment.paymentRef) +
                      " " +
                      _vm._s(_vm.region.currency) +
                      "\n        "
                  )
                ]),
                _c("div", { staticClass: "col-4 text-right" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm._f("thousandSeparator")(
                          _vm._f("to2Decimal")(
                            payment.amount,
                            _vm.region.decimal
                          ),
                          _vm.region.thousandSeparator
                        )
                      ) +
                      "\n        "
                  )
                ])
              ])
            }),
            _c("div", { staticClass: "row weight-600" }, [
              _c("div", { staticClass: "col-8 text-right" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("general.outstandingBalance")) +
                    " " +
                    _vm._s(
                      _vm._f("shouldHideCurrencyIfConvertingToVC")(
                        _vm.region.currency,
                        _vm.convertToVirtualCurrency
                      )
                    ) +
                    "\n        "
                )
              ]),
              _c("div", { staticClass: "col-4 text-right" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm._f("thousandSeparator")(
                        _vm._f("to2Decimal")(
                          _vm._f("toVirtualCurrency")(
                            _vm.print.ostBalance,
                            _vm.virtualCurrencyConfig.exchangeRate,
                            _vm.convertToVirtualCurrency
                          ),
                          _vm.region.decimal
                        ),
                        _vm.region.thousandSeparator
                      )
                    ) +
                    "\n        "
                )
              ])
            ]),
            _vm.virtualCurrencyConfig.enable
              ? _c("div", { staticClass: "row weight-600" }, [
                  _c("div", { staticClass: "col-8 text-right" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("general.current")) +
                        " " +
                        _vm._s(_vm.virtualCurrencyConfig.name) +
                        " " +
                        _vm._s(_vm.$t("general.balance")) +
                        "\n        "
                    )
                  ]),
                  _c("div", { staticClass: "col-4 text-right" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.print
                            .virtualCurrencyBalanceRightAfterInvoiceRedemption
                        ) +
                        _vm._s(_vm.virtualCurrencyConfig.currencyCode) +
                        "\n        "
                    )
                  ])
                ])
              : _vm._e(),
            _vm.promoCode
              ? _c("div", { staticClass: "row mt-3 weight-600" }, [
                  _c("div", { staticClass: "col-8 text-right" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("campaign.promotionCode")) +
                        "\n        "
                    )
                  ]),
                  _c("div", { staticClass: "col-4 text-right" }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.promoCode) + "\n        "
                    )
                  ])
                ])
              : _vm._e()
          ],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }