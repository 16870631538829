<template>
  <div class="container-fluid py-4"
    :class="{'previewPrint': preview}">
    <div class="row mb-3">
      <div class="col-12">
        <img
          v-if="printLetterHeaderConfig.clinicLogo[tabIndex]"
          :style="{marginLeft:`${logoMarginLeft}px`, width:`${logoWidth}px`}"
          :src="this.companyClinicLogo">
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <h5 v-if="printLetterHeaderConfig.clinicName[tabIndex]">{{clinic.clinicName}}</h5>
        <p v-if="printLetterHeaderConfig.clinicAddress[tabIndex]" class="mb-1 address-font">{{letterAddress}}</p>
        <h5 v-if="printLetterHeaderConfig.clinicLocalName[tabIndex]">{{clinic.clinicLocalName}}</h5>
        <p v-if="printLetterHeaderConfig.clinicLocalAddress[tabIndex]" class="mb-1 address-font">{{clinic.clinicLocalAddress}}</p>
        <p v-if="printLetterHeaderConfig.clinicPhone[tabIndex]" class="mb-3 address-font">Tel: {{clinic.telephone}}</p>
        <p v-if="printLetterHeaderConfig.todayDate[tabIndex]" class="mb-3 address-font">Date: {{new Date() | formatDateTime}}</p>
        <p v-if="printLetterHeaderConfig.todayDateBE[tabIndex]" class="mb-3 address-font">Date: {{new Date() | formatVDDate({ lang: 'th', format: 'YYYY/MM/DD HH:mm A'})}}</p>
      </div>
      <div class="col-2">
      </div>
      <div class="col-4">
        <div v-if="printLetterHeaderConfig.patientName[tabIndex]" class="row">
          <div class="col-4 address-font">Name:</div>
          <div class="col-8 address-font">{{patient.name}}</div>
        </div>
        <div v-if="printLetterHeaderConfig.patientLocalName[tabIndex]" class="row">
          <div class="col-4 address-font">Patient Local Name:</div>
          <div class="col-8 address-font">{{patient.localName}}</div>
        </div>
        <div v-if="printLetterHeaderConfig.nricfin[tabIndex]" class="row">
          <div class="col-4 address-font">NRIC:</div>
          <div class="col-8 address-font">{{patient.nric}}</div>
        </div>
        <div v-if="printLetterHeaderConfig.patientAge[tabIndex]" class="row">
          <div class="col-4 address-font">Age:</div>
          <div class="col-8 address-font">{{patient.age}}</div>
        </div>
        <div v-if="printLetterHeaderConfig.patientID[tabIndex]" class="row">
          <div class="col-4 address-font">Clinic ID:</div>
          <div class="col-8 address-font">
            {{ patient.givenIdPrefix ?
            patient.givenIdPrefix + patient.givenId
            : patient.givenId }}
          </div>
        </div>
        <div v-if="printLetterHeaderConfig.patientDOB[tabIndex]" class="row">
          <div class="col-4 address-font">Date of Birth:</div>
          <div class="col-8 address-font">{{ patient.dateOfBirth | formatVDDate({ format: 'YYYY/MM/DD', lang: $i18n.locale }) }}</div>
        </div>
        <div v-if="printLetterHeaderConfig.patientSex[tabIndex]" class="row">
          <div class="col-4 address-font">Sex:</div>
          <div class="col-8 address-font">{{patient.gender}}</div>
        </div>
      </div>
    </div>
    <div class="mb-3" style="border-top-style:solid"></div>
    <h3 class="mb-3 text-center"><u>{{name}}</u></h3>
    <div
      class="row p-4 mb-3 d-flex justify-content-center content-box"
      v-if="preview"
      @click="goToLetters"
    >
      <div class="p-4">
        <p class="address-font">(Click to configure content)</p>
      </div>
    </div>
    <div class="mb-3" v-else>
      <div v-html="textWithBr"></div>
    </div>
    <div class="mb-3" style="border-top-style:solid"></div>
    <div class="row">
      <div class="col-6">
        <h5 v-if="printLetterFooterConfig.clinicName[tabIndex]" class="footer-font">{{clinic.clinicName}}</h5>
        <p v-if="printLetterFooterConfig.clinicAddress[tabIndex]" class="footer-font">{{letterAddress}}</p>
        <h5 v-if="printLetterFooterConfig.clinicLocalName[tabIndex]" class="footer-font">{{clinic.clinicLocalName}}</h5>
        <p v-if="printLetterFooterConfig.clinicLocalAddress[tabIndex]" class="footer-font">{{clinic.clinicLocalAddress}}</p>
        <p v-if="printLetterFooterConfig.clinicPhone[tabIndex]" class="footer-font">Tel: {{clinic.telephone}}</p>
        <p v-if="printLetterFooterConfig.todayDate[tabIndex]" class="footer-font">Date: {{new Date() | formatDateTime}}</p>
        <p v-if="printLetterFooterConfig.todayDateBE[tabIndex]" class="footer-font">Date: {{new Date() | formatVDDate({ lang: 'th', format: 'YYYY/MM/DD HH:mm A'})}}</p>
      </div>
      <div class="col-2">
      </div>
      <div class="col-4">
        <div v-if="printLetterFooterConfig.printedDateTime[tabIndex]" class="row">
          <div class="col-4 footer-font">Printed at:</div>
          <div class="col-8 footer-font">{{new Date() | formatVDDate({ lang: $i18n.locale, format: 'YYYY/MM/DD HH:mm A'})}}</div>
        </div>
        <div v-if="printLetterFooterConfig.patientName[tabIndex]" class="row">
          <div class="col-4 footer-font">Name:</div>
          <div class="col-8 footer-font">{{patient.name}}</div>
        </div>
        <div v-if="printLetterFooterConfig.patientLocalName[tabIndex]" class="row">
          <div class="col-4 footer-font">Patient Local Name:</div>
          <div class="col-8 footer-font">{{patient.localName}}</div>
        </div>
        <div v-if="printLetterFooterConfig.nricfin[tabIndex]" class="row">
          <div class="col-4 footer-font">NRIC:</div>
          <div class="col-8 footer-font">{{patient.nric}}</div>
        </div>
        <div v-if="printLetterFooterConfig.patientAge[tabIndex]" class="row">
          <div class="col-4 footer-font">Age:</div>
          <div class="col-8 footer-font">{{patient.age}}</div>
        </div>
        <div v-if="printLetterFooterConfig.patientID[tabIndex]" class="row">
          <div class="col-4 footer-font">Clinic ID:</div>
          <div class="col-8 footer-font">{{patient.givenId}}</div>
        </div>
        <div v-if="printLetterFooterConfig.patientDOB[tabIndex]" class="row">
          <div class="col-4 footer-font">Date of Birth:</div>
          <div class="col-8 footer-font">{{patient.dateOfBirth | formatDate}}</div>
        </div>
        <div v-if="printLetterFooterConfig.patientSex[tabIndex]" class="row">
          <div class="col-4 footer-font">Sex:</div>
          <div class="col-8 footer-font">{{patient.gender}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dateformatter from '@/components/mixins/dateformatter';

export default {
  name: 'PatientLabel',
  mixins: [dateformatter],
  props: {
    name: {
      type: String,
      default: () => (''),
    },
    text: {
      type: String,
      default: () => (''),
    },
    clinicId: {
      type: String,
      default: () => (''),
    },
    clinic: {
      type: Object,
      default() {
        return { };
      },
    },
    patient: {
      type: Object,
      default() {
        return { };
      },
    },
    printLetterHeaderConfig: {
      type: Object,
      default() {
        return { };
      },
    },
    printLetterFooterConfig: {
      type: Object,
      default() {
        return { };
      },
    },
    tabIndex: {
      type: Number,
      default: 0,
    },
    preview: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    companyClinicLogo() {
      if (this.clinic.useLocationAddress) {
        const location = this.clinics.find(location => location._id === this.clinicId);
        return location ? location.clinicLogo : this.clinic.logoURL || '';
      }
      return this.clinic.logoURL;
    },
    letterAddress() {
      if (this.clinic.useLocationAddress) {
        const location = this.clinics.find(location => location._id === this.clinicId);
        return location ? location.address : '';
      }
      return this.clinic.address;
    },
    textWithBr() {
      return this.text.replace(/\n/g, '<br>');
    },
    logoMarginLeft() {
      if (localStorage.getItem('printSetup')) {
        return JSON.parse(localStorage.getItem('printSetup')).logoMarginLeft;
      }
      return 0;
    },
    logoWidth() {
      if (localStorage.getItem('printSetup')) {
        return JSON.parse(localStorage.getItem('printSetup')).logoWidth;
      }
      return 150;
    },
  },
  methods: {
    goToLetters() {
      this.$router.push('/letterbuilder/list');
    },
  },
  mounted() {
    if (!this.preview) {
      setTimeout(() => {
        window.print();
      }, 2000);
    }
  },
};
</script>

<style scoped src="../../assets/print.css"></style>
<style scoped>
  .previewPrint {
    background-color: white;
    font-size: 0.1em !important;
    padding: 20px;
    padding-left: 35px;
    min-height: 50em;
  }
  .content-box {
    border: 1px dashed black;
  }
  .letter-textarea {
    background-color: white !important;
    border: none;
  }
</style>
