var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "typing-note-full-print",
      attrs: { id: "typing-note-full-print" }
    },
    [
      this.print.printFullNote
        ? _c(
            "div",
            { staticClass: "mb-3" },
            [
              _c(
                "div",
                { attrs: { id: "notesHeader" } },
                [
                  _c("b-row", [
                    _c("div", { staticStyle: { width: "20%" } }, [
                      _vm.generalConfig && _vm.generalConfig.logoURL
                        ? _c("img", {
                            style:
                              "margin-left: " +
                              _vm.logoMarginLeft +
                              "px; height: 150px; width: auto;",
                            attrs: { src: _vm.generalConfig.logoURL }
                          })
                        : _vm._e()
                    ])
                  ]),
                  _c("b-row", { staticClass: "clinicName" }, [
                    _vm._v(_vm._s(_vm.generalConfig.clinicName))
                  ]),
                  _c("b-row", [_vm._v(_vm._s(_vm.generalConfig.address))])
                ],
                1
              ),
              _c("hr"),
              _c("b-row", [
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _vm.isTypingNoteReadOnly
                      ? _c("img", {
                          attrs: {
                            src: this.print.signedPrintKey,
                            alt: "Typing Note Image"
                          }
                        })
                      : _c("typing-note-editor", {
                          attrs: {
                            editor: this.print,
                            formDataOrder: this.print.formDataOrder,
                            formData: this.print.formData,
                            viewing: true,
                            source: "print"
                          }
                        })
                  ],
                  1
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.featureFlags.printTPH && !this.print.printFullNote
        ? _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "mb-3", staticStyle: { width: "100%" } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "inline-block",
                        width: "30%",
                        "vertical-align": "middle"
                      }
                    },
                    [
                      _vm.generalConfig && _vm.generalConfig.logoURL
                        ? _c("img", {
                            attrs: { src: _vm.generalConfig.logoURL, alt: "" }
                          })
                        : _vm._e()
                    ]
                  ),
                  _c("div", {
                    staticStyle: { display: "inline-block", width: "30%" }
                  }),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "inline-block",
                        width: "30%",
                        "vertical-align": "middle"
                      }
                    },
                    [
                      _vm.integratedTestConfig &&
                      _vm.integratedTestConfig.radiologyLogoUrl
                        ? _c("img", {
                            attrs: {
                              src: _vm.integratedTestConfig.radiologyLogoUrl,
                              alt: ""
                            }
                          })
                        : _vm._e()
                    ]
                  )
                ]
              ),
              _c("hr"),
              _c(
                "div",
                { staticClass: "mb-3" },
                [
                  _vm._l(_vm.patientInfoSectionQnsOrder, function(orders, idx) {
                    return [
                      _c(
                        "div",
                        { key: idx, staticClass: "row no-gutters" },
                        _vm._l(orders, function(qns, i) {
                          return _c("div", { key: i, staticClass: "col-6" }, [
                            _vm.print.formData[qns].type === "input"
                              ? _c("div", [
                                  _vm.print.formData[qns].label
                                    ? _c(
                                        "label",
                                        {
                                          staticClass: "w-50",
                                          staticStyle: { "font-weight": "800" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.print.formData[qns].label
                                            ) + ": "
                                          )
                                        ]
                                      )
                                    : _c(
                                        "label",
                                        {
                                          staticClass: "w-50",
                                          staticStyle: { "font-weight": "800" }
                                        },
                                        [_vm._v(" ")]
                                      ),
                                  _c("span", { staticClass: "w-50" }, [
                                    _vm._v(
                                      _vm._s(_vm.print.formData[qns].answer)
                                    )
                                  ])
                                ])
                              : _vm._e()
                          ])
                        }),
                        0
                      )
                    ]
                  })
                ],
                2
              ),
              _c("hr"),
              _vm._l(_vm.reportDataSectionQnsOrder, function(qns, i) {
                return _c(
                  "div",
                  { key: i + "report", staticClass: "row mb-3" },
                  [
                    _c(
                      "b-card",
                      { staticClass: "col-12" },
                      _vm._l(_vm.print.formData[qns].value, function(
                        res,
                        index
                      ) {
                        return _c("div", { key: index, staticClass: "mt-3" }, [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(_vm.formatResults(res))
                            }
                          })
                        ])
                      }),
                      0
                    )
                  ],
                  1
                )
              }),
              _c(
                "div",
                { staticClass: "row" },
                [
                  _vm._l(_vm.signatureSectionQnsOrder, function(orders, idx) {
                    return [
                      _c(
                        "div",
                        { key: idx, staticClass: "row no-gutters" },
                        _vm._l(orders, function(qns, i) {
                          return _c("div", { key: i, staticClass: "col-6" }, [
                            _c("div", [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.extractRadiologistNameFromSignature(
                                      _vm.print.formData[qns].label
                                    )
                                  )
                                )
                              ]),
                              _c("br"),
                              _vm.generalConfig.address
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.generalConfig.address))
                                  ])
                                : _vm._e()
                            ])
                          ])
                        }),
                        0
                      )
                    ]
                  })
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "mb-3" },
                [
                  _c(
                    "div",
                    { staticClass: "row px-3" },
                    _vm._l(_vm.readOnlyFields, function(field, index) {
                      return _c(
                        "div",
                        { key: "footerfield-" + index, staticClass: "col-12" },
                        [
                          _vm._v(
                            "\n          " + _vm._s(field.label) + "\n        "
                          )
                        ]
                      )
                    }),
                    0
                  ),
                  _c("hr", { staticClass: "mx-3" }),
                  _vm._l(_vm.signatureSectionQnsOrder, function(qns, i) {
                    return _c("div", { key: i, staticClass: "row px-3" }, [
                      _c("span", { staticClass: "col-12" }, [
                        _vm.generalConfig.address
                          ? _c("span", [_vm._v(_vm._s(_vm.printAddress))])
                          : _vm._e()
                      ])
                    ])
                  })
                ],
                2
              )
            ],
            2
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }