import _ from 'lodash';

import api from './api';

export default {
  show() {
    const url = '/config/';
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`List Error: ${response}, url: ${url}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },

  categoryIsUsed(category) {
    const url = `/config/appointment/category/${category}/used`;
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`List Error: ${response}, url: ${url}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },

  providerClassIsUsed(klass) {
    const url = `/config/provider/category/${klass}/used`;
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`List Error: ${response}, url: ${url}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },

  upsert(payload) {
    const url = '/config/';

    return new Promise((resolve, reject) => {
      api.post(url, payload).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({
              data, message: '', success: true, error: null,
            });
          } else {
            const errors = _.mapObject(response.body.error.errors, val => val.message || '');
            reject(new Error(JSON.stringify(errors)));
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },

  // TODO: calculate what provider are using this category.
  async providerCategoryIsUsed(/* category */) {
    return {
      data: [],
      message: '',
      success: true,
    };
  },

  fetchNegInvStock() {
    const url = '/config/inventory/negInvStock';
    return new Promise((resolve, reject) => {
      api.get(url)
        .then((response) => {
          if (response.ok) {
            const data = response.body.availableQuantity;
            const negInvData = _.find(data, (x) => {
              if (x.qty < 0) { return (x); }
            });
            resolve({
              negInvData, message: '', success: true, error: null,
            });
          }
          reject(
            new Error(`List Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        });
    });
  },
};
