var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container mb-5", class: { previewPrint: _vm.preview } },
    [
      _c("div", { staticClass: "row mb-3" }, [
        _c("div", { staticClass: "col-12" }, [
          _vm.patientConfig.printPackageHeader.clinicLogo
            ? _c("img", {
                style: {
                  marginLeft: _vm.logoMarginLeft + "px",
                  width: _vm.logoWidth + "px"
                },
                attrs: { src: _vm.generalConfig.logoURL }
              })
            : _vm._e(),
          _c("h5", [_vm._v(_vm._s(_vm.generalConfig.clinicName))]),
          _c(
            "p",
            {
              staticClass: "mb-1 address-font",
              staticStyle: { "white-space": "pre-line" }
            },
            [
              _vm._v(
                "\n        " + _vm._s(_vm.generalConfig.address) + "\n      "
              )
            ]
          ),
          _c("h3", { staticClass: "mb-3 text-center text-uppercase" }, [
            _c("u", [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("general.packageTransferAcknowledgement")) +
                  "\n        "
              )
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "row mb-5" }, [
        _c("div", { staticClass: "col-7 weight-600" }, [
          _c("div", { staticClass: "row mb-2" }, [
            _c("div", { staticClass: "col-4" }, [
              _vm._v(_vm._s(_vm.$t("general.packageName")))
            ]),
            _c("div", { staticClass: "col-8" }, [
              _vm._v(" - " + _vm._s(_vm.selectedPackage.packageName))
            ])
          ]),
          _c("div", { staticClass: "row mb-2" }, [
            _c("div", { staticClass: "col-4" }, [
              _vm._v(_vm._s(_vm.$t("general.itemName")))
            ]),
            _c("div", { staticClass: "col-8" }, [
              _vm._v(" - " + _vm._s(_vm.selectedPackage.itemName))
            ])
          ]),
          _c("div", { staticClass: "row mb-2" }, [
            _c("div", { staticClass: "col-4" }, [
              _vm._v(_vm._s(_vm.$t("general.transferFrom")))
            ]),
            _c("div", { staticClass: "col-8" }, [
              _vm._v(" - " + _vm._s(_vm.selectedPackage.transferFrom))
            ])
          ]),
          _c("div", { staticClass: "row mb-2" }, [
            _c("div", { staticClass: "col-4" }, [
              _vm._v(_vm._s(_vm.$t("general.transferTo")))
            ]),
            _c("div", { staticClass: "col-8" }, [
              _vm._v(" - " + _vm._s(_vm.selectedPackage.transferTo))
            ])
          ]),
          _c("div", { staticClass: "row mb-2" }, [
            _c("div", { staticClass: "col-4" }, [
              _vm._v(_vm._s(_vm.$t("general.date")))
            ]),
            _c("div", { staticClass: "col-8" }, [
              _vm._v(
                " - " +
                  _vm._s(_vm._f("formatDayTime")(_vm.selectedPackage.dateTime))
              )
            ])
          ])
        ]),
        _c("div", { staticClass: "col-5" })
      ]),
      _vm.patientConfig.printPackageHeader.customContent
        ? _c(
            "p",
            { staticClass: "mb-5", staticStyle: { "white-space": "pre-line" } },
            [
              _vm._v(
                "\n    " +
                  _vm._s(_vm.patientConfig.printPackageHeader.customContent) +
                  "\n  "
              )
            ]
          )
        : _vm._e(),
      _c("br"),
      _c("div", { staticClass: "row mb-5" }, [
        _c("div", { staticClass: "col-8" }),
        _c("div", { staticClass: "col-4" }, [
          _c("span", [_vm._v("_____________________")]),
          _c("br"),
          _vm.preview
            ? _c("span", [
                _vm._v(
                  "[" +
                    _vm._s(_vm.$t("general.transferFrom")) +
                    " " +
                    _vm._s(_vm.$t("general.patientName")) +
                    "]"
                )
              ])
            : _c("span", [_vm._v(_vm._s(_vm.selectedPackage.transferFrom))])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }