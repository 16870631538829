/* eslint-disable no-param-reassign */
import store from '@/vuex';
import { userHasAccessToFn, userHasAccessToChildFn } from '../helpers/acl';

const createCommentNode = (vnode, el) => {
  // replace HTMLElement with comment node
  const comment = document.createComment('ACL denied');
  Object.defineProperty(comment, 'setAttribute', {
    value: () => undefined,
  });
  vnode.elm = comment;
  vnode.text = ' ';
  vnode.isComment = true;
  vnode.context = undefined;
  vnode.tag = undefined;
  vnode.data.directives = undefined;

  if (vnode.componentInstance) {
    vnode.componentInstance.$el = comment;
  }

  if (el.parentNode) {
    el.parentNode.replaceChild(comment, el);
  }
};
const disableHideElement = (vnode, el, behaviour) => {
  if (behaviour.disabled) {
    el.disabled = true;
  } else {
    createCommentNode(vnode, el);
  }
};
export default {
  bind(el, binding, vnode) {
    if (binding.modifiers && binding.modifiers.isDynamic) {
      const childFnId = binding.value;
      const behaviour = binding.modifiers;
      if (typeof childFnId === 'string') {
        if (!userHasAccessToChildFn(childFnId)) { 
          disableHideElement(vnode, el, behaviour);
        }
      } else {
        if (childFnId.name === 'should_disable_status') {
          if (childFnId.check || !userHasAccessToChildFn(childFnId.id)) {
            disableHideElement(vnode, el, behaviour);
          }
        }
      }
    } else {
      const functionId = binding.value;
      const behaviour = binding.modifiers;
      if (!userHasAccessToFn(functionId)) { 
        disableHideElement(vnode, el, behaviour);
      }
    }
  },
};
