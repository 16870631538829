export const SET_OTBOOKINGS = 'SET_OTBOOKINGS';
export const SET_OTBOOKING = 'SET_OTBOOKING';
export const SET_FILTERS = 'SET_FILTERS';
export const SET_OTINVOICE = 'SET_OTINVOICE';
export const UPDATE_OTINVOICE = 'UPDATE_OTINVOICE';
export const UPDATE_OTINVOICE_SECTION = 'UPDATE_OTINVOICE_SECTION';
export const BEFORE_SET_OTOPTIONS = 'BEFORE_SET_OTOPTIONS';
export const SET_OTOPTIONS = 'SET_OTOPTIONS';
export const SET_SELECTED_OTOPTIONS = 'SET_SELECTED_OTOPTIONS';
export const SET_PROVIDER_OT = 'SET_PROVIDER_OT';
