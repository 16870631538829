<template>
    <span v-if="isDataReady && selectedItemForPrint && med">
      <drug-label-component
        v-if="featureFlags.bgrimm"
        :printLocal='printLocal'
        :disableAutoPrint="false"
        :medItem='print'
        :generalConfig='general'
        :patient='print.selectedPatient'
      />
      <template v-else-if="featureFlags.drclinique">
        <div class="container-fluid" >
          <medical-label-dr-clinique
            :clinicName='general.clinicName'
            :clinicAddress='general.address'
            :medName='med.medicine.name'
            :medStrength='med.medicine.strength'
            :medQty='med.quantity'
            :medUnit='med.medicine.unit'
            :medDosage='med.dosage'
            :medExpiry='(med.batch && med.batch.expiry) || ""'
            :medPrecautionOne='med.precaution1'
            :medPrecautionTwo='med.precaution2'
            :patientName='selectedItemForPrint.patient.name'
            :patientNric='selectedItemForPrint.patient.nric'
            />
        </div>
      </template>
      <template v-else>
        <div class="container-fluid" >
          <medical-label-component
        v-if="!med.adHocCompounding"
        :clinicName='general.clinicName'
        :clinicAddress='general.address'
        :medName='med.medicine.name'
        :medStrength='med.medicine.strength'
        :medQty='med.quantity'
        :medUnit='med.medicine.unit'
        :medDosage='med.dosage'
        :medExpiry='(med.batch && med.batch.expiry) || ""'
        :selectedBatch="med.batch"
        :medPrecautionOne='med.precaution1'
        :medPrecautionTwo='med.precaution2'
        :patientName='selectedItemForPrint.patient.name'
        :patientNric='selectedItemForPrint.patient.nric'
        :patientDetails='selectedItemForPrint.patient'
        />
        <medical-label-component
        v-else
        :clinicName='general.clinicName'
        :clinicAddress='general.address'
        :medName='med.adHocCompoundName'
        :medStrength='med.medicine.strength'
        :medQty='med.quantity'
        :medUnit='med.adHocCompoundUnit'
        :medDosage='med.adHocCompoundDosage'
        :medExpiry='""'
        :selectedBatch="med.batch"
        :medPrecautionOne='med.adHocCompoundPrecaution1'
        :medPrecautionTwo='med.adHocCompoundPrecaution2'
        :patientName='selectedItemForPrint.patient.name'
        :patientNric='selectedItemForPrint.patient.nric'
        :patientDetails='selectedItemForPrint.patient'
        />
        </div>
      </template>
    </span>
</template>

<script>
import _ from 'lodash';

import { mapGetters } from 'vuex';
import MedicalLabelComponent from '@/components/Print/MedicalLabelComponent.vue';
import MedicalLabelDrClinique from '@/components/Print/MedicalLabelDrClinique.vue';
import DrugLabelComponent from '@/components/Print/BGrimmDrugLabelV2.vue';

export default {
  name: 'PharmacyMedLabelSingle',
  computed: {
    ...mapGetters('pharmacy', ['selectedItemForPrint', 'getMedItemToPrint', 'print']),
    ...mapGetters('config', ['general', 'featureFlags']),
    isDataReady() {
      return !_.isEmpty(this.med);
    },
  },
  components: {
    MedicalLabelComponent,
    MedicalLabelDrClinique,
    DrugLabelComponent,
  },
  data() {
    return {
      printLocal: false,
      medItem: { },
      med: {},
      rawDoctorName: '',
      rawDoctorSurname: '',
    };
  },
  async created() {
    if (this.$route.query && this.$route.query['print-local']) {
      this.printLocal = true;
    } else {
      this.printLocal = false;
    }
    this.med = await this.getMedItemToPrint(parseInt(this.$route.params.id));
  },
};
</script>
