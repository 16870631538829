var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.featureFlags.isMakuang
        ? _c("invoices-component-ma-kuang", {
            attrs: {
              patient: _vm.selectedPatient,
              generalConfig: _vm.generalConfig,
              invoices: _vm.invoicesToPrint,
              lang: _vm.lang,
              mode: _vm.mode
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }