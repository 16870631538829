<template>
  <!-- this is used in ChartingContainer and IntegratedTest -->
  <b-modal
    v-model="showUploadManager"
    class="bv-modal-example"
    header-class="label"
    size="xl"
    @hide="closeUploadManager"
  >
    <template slot="modal-header">
      <span>{{ $t("general.uploadManager") }}</span>
    </template>

    <slot name="extra"> </slot>

    <template slot="default">
      <b-table
        striped
        hover
        :fields="fields"
        :items="filesMapped"
        v-if="filesMapped.length > 0"
      >
        <template slot="fileName" slot-scope="row">
          <span>
            <b-input
              class="mb-2 mr-sm-2 mb-sm-0"
              size="sm"
              :value="row.value"
              @input="updateUploadLineItem($event, row.item.uuid, 'name')"
            />
          </span>
        </template>
        <template slot="category" slot-scope="row">
          <span>
            <b-form-select
              class="mb-2 mr-sm-2 mb-sm-0"
              size="sm"
              :value="row.value"
              @change="
                updateUploadLineItem($event, row.item.uuid, 'categoryId')
              "
              :options="filteredCategoriesOptions"
            ></b-form-select>
          </span>
        </template>
      </b-table>
    </template>

    <template slot="modal-footer" class="space-between">
      <b-button variant="danger" @click="closeUploadManager">{{
        $t("general.close")
      }}</b-button>
      <b-button
        variant="success"
        @click="uploadFiles"
        :disabled="!externallyValid || uploading"
        >{{ $t("general.upload") }}</b-button
      >
    </template>
  </b-modal>
</template>

<script>
import constant from '@/services/constant';

export default {
  name: 'UploadManager',
  props: ['showUploadManager', 'closeUploadManager', 'files', 'uploadFiles', 'updateUploadLineItem', 'categoriesOptions', 'uploading', 'extraValidation'],
  components: {},
  mixins: [],
  data() {
    return {
      fields: [
        { key: 'fileName', label: this.$t('general.fileName') },
        { key: 'category', label: this.$t('general.category') },
        { key: 'type (Page)', label: this.$t('general.typeOfPage') },
        { key: 'size', label: this.$t('general.size') },
        { key: 'status', label: this.$t('general.status') },
      ],
    };
  },
  watch: {

  },
  computed: {
    filesMapped() {
      const output = (this.files || []).map((elem) => {
        const { file } = elem;
        return {
          fileName: elem.name,
          'type (Page)': file.type,
          category: elem.categoryId,
          size: file.size,
          status: elem.status,
          uuid: elem.uuid,
          _cellVariants: { status: this.checkStatusForCellVariants(elem) },
        };
      });
      return output;
    },
    externallyValid() {
      if (typeof this.extraValidation === 'undefined') {
        return true;
      }
      return this.extraValidation;
    },
    filteredCategoriesOptions() {
      let output = this.categoriesOptions;
      const userAccessChildrenFNIDs = localStorage.getItem('acl__childrenFnIds');
      if (userAccessChildrenFNIDs && this.categoriesOptions) {
        const userAccess = JSON.parse(userAccessChildrenFNIDs);
        output = this.categoriesOptions.filter(c => userAccess[c.value]);
      }
      return output;
    },
  },
  methods: {
    checkStatusForCellVariants(elem) {
      let tdClass = '';

      switch (elem.state) {
        case constant.UPLOAD_STATE.PROCESSING:
          tdClass = 'warning';
          break;
        case constant.UPLOAD_STATE.COMPLETED:
          tdClass = 'success';
          break;
        case constant.UPLOAD_STATE.FAILED:
        case constant.UPLOAD_STATE.CANCELLED:
          tdClass = 'danger';
          break;
        case constant.UPLOAD_STATE.PENDING:
        default:
          tdClass = '';
          break;
      }

      return tdClass;
    },
  },
  created() {
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
.space-between {
  display: flex;
  justify-content: space-between;
}
</style>
