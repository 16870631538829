<template>
  <div>
    <template v-if="selectedItemForPrint">
      <template v-if="featureFlags.bgrimm">
        <div v-for="item in medicalItems" :key="item.id">
          <drug-label-component
            :disableAutoPrint="true"
            :printLocal='printLocal'
            :medItem='item'
            :generalConfig='general'
            :patient='print.selectedPatient'
          />
          <div style="page-break-after:always;break-after:always;"></div>
        </div>
      </template>
      <template v-else>
        <div v-for="item in selectedItemForPrint.items" :key="item.id">
          <div class="container-fluid" v-if="featureFlags.drclinique">
            <medical-label-dr-clinique
            :disableAutoPrint="true"
            :clinicName='general.clinicName'
            :clinicAddress='general.address'
            :medName='item.medicine.name'
            :medQty='item.quantity'
            :medStrength='item.medicine.strength'
            :medUnit='item.medicine.unit'
            :medDosage='item.dosage'
            :medExpiry='(item.batch || {}).expiry'
            :medPrecautionOne='item.precaution1'
            :medPrecautionTwo='item.precaution2'
            :patientName='selectedItemForPrint.patient.name'
            :patientNric='selectedItemForPrint.patient.nric'
            />
          </div>
          <template v-else>
            <div class="container-fluid">
              <medical-label-component
                :disableAutoPrint="true"
                v-if="!item.adHocCompounding"
                :clinicName='general.clinicName'
                :clinicAddress='general.address'
                :clinicNumber='general.telephone'
                :medName='item.medicine.name'
                :medQty='item.quantity'
                :medStrength='item.medicine.strength'
                :medUnit='item.medicine.unit'
                :medDosage='item.dosage'
                :medExpiry='(item.batch || {}).expiry'
                :selectedBatch="item.batch"
                :medPrecautionOne='item.precaution1'
                :medPrecautionTwo='item.precaution2'
                :patientName='selectedItemForPrint.patient.name'
                :patientNric='selectedItemForPrint.patient.givenId'
                :patientDetails='selectedItemForPrint.patient'
                />
              <medical-label-component
                v-else
                :disableAutoPrint="true"
                :clinicName='general.clinicName'
                :clinicAddress='general.address'
                :clinicNumber='general.telephone'
                :medName='item.adHocCompoundName'
                :medQty='item.quantity'
                :medStrength='item.medicine.strength'
                :medUnit='item.adHocCompoundUnit'
                :medDosage='item.adHocCompoundDosage'
                :medExpiry='""'
                :selectedBatch="item.batch"
                :medPrecautionOne='item.adHocCompoundPrecaution1'
                :medPrecautionTwo='item.adHocCompoundPrecaution2'
                :patientName='selectedItemForPrint.patient.name'
                :patientNric='selectedItemForPrint.patient.givenId'
                :patientDetails='selectedItemForPrint.patient'
                />
            </div>
          </template>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MedicalLabelComponent from '@/components/Print/MedicalLabelComponent.vue';
import MedicalLabelDrClinique from '@/components/Print/MedicalLabelDrClinique.vue';
import DrugLabelComponent from '@/components/Print/BGrimmDrugLabelV2.vue';

export default {
  name: 'PharmacyMedLabelAll',
  computed: {
    ...mapGetters('pharmacy', ['selectedItemForPrint', 'print']),
    ...mapGetters('config', ['general', 'featureFlags']),
        medicalItems() {
      const allowedInvType = ['Medicine', 'Wyn'];
      const meta = {
        providerId: this.print.providerId,
      };

      const medicineById = this.selectedItemForPrint.items.reduce(
        (mById, item) => {
          if (
            item.medItem &&
            item.medItem.medicine &&
            item.medItem.medicine._id
          ) {
            mById[item.medItem.medicine._id] = item.medItem.medicine;
          }
          return mById;
        },
        {},
      );

      return this.print.items.reduce((fItems, pItem) => {
        if (allowedInvType.indexOf(pItem.inventory)!== -1) {
          fItems.push({
            ...pItem,
            medicine: medicineById[pItem._id] || {},
            ...meta,
          });
        } else if (pItem.inventory === 'Order Set' && pItem.subInventoryRef) {
          pItem.subInventoryRef.forEach((subInvRef) => {
            if (
              subInvRef.inventoryId &&
              allowedInvType.indexOf(subInvRef.inventoryId.inventory)!== -1
            ) {
              fItems.push({
                ...subInvRef.inventoryId,
                medicine: medicineById[subInvRef.inventoryId._id] || {},
                qty: subInvRef.qty,
                selectedBatch: subInvRef.selectedBatch,
                ...meta,
              });
            }
          });
        }

        return fItems;
      }, []);
    },
  },
  components: {
    MedicalLabelComponent,
    MedicalLabelDrClinique,
    DrugLabelComponent,
  },
  data() {
    return {
      med: {},
      printLocal: false,
    };
  },
  async created() {
    // console.log('FOR PRINT', this.selectedItemForPrint)
    if (this.$route.query && this.$route.query['print-local']) {
      this.printLocal = true;
    } else {
      this.printLocal = false;
    }
    setTimeout(() => {
      window.print();
    }, 2000);
  },
};
</script>


<style scoped src="../../assets/print.css"></style>
