export const BEFORE_SET_ALL_NOTES = 'BEFORE_SET_ALL_NOTES';
export const SET_ALL_NOTES = 'SET_ALL_NOTES';
export const BEFORE_SET_ALL_NOTE_CATEGORIES = 'BEFORE_SET_ALL_NOTE_CATEGORIES';
export const SET_ALL_NOTE_CATEGORIES = 'SET_ALL_NOTE_CATEGORIES';
export const BEFORE_SET_ALL_NOTE_CATEGORIES_BY_PATIENTID = 'BEFORE_SET_ALL_NOTE_CATEGORIES_BY_PATIENTID';
export const SET_ALL_NOTE_CATEGORIES_BY_PATIENTID = 'SET_ALL_NOTE_CATEGORIES_BY_PATIENTID';
export const REORDER_NOTE_CATEGORIES = 'reorderNoteCategories';
export const ADD_NEW_CATEGORY = 'ADD_NEW_CATEGORY';
export const GO_TO_PAGE = 'goToPage';
export const UPDATE_CHARTING_SORT_OPTIONS = 'updateChartingSortOptions';

export const ADD_NEW_NOTE = 'ADD_NEW_NOTE';
export const NOTE_SAVED = 'NOTE_SAVED';
export const UPDATE_NOTE_LIST = 'UPDATE_NOTE_LIST';
export const PATCH_NOTES = 'PATCH_NOTES';

export const BEFORE_UPDATE_CATEGORIES = 'BEFORE_UPDATE_CATEGORIES';
export const UPDATE_CATEGORIES_SUCCESS = 'UPDATE_CATEGORIES_SUCCESS';
export const UPDATE_CATEGORIES_ERROR = 'UPDATE_CATEGORIES_ERROR';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const DELETE_NOTES = 'DELETE_NOTES';
export const SET_ALL_MAINFORMS = 'SET_ALL_MAINFORMS';
export const SET_MAIN_FORMS_CHANGE_LOGS = 'SET_MAIN_FORMS_CHANGE_LOGS';
export const RESET_MAIN_FORMS_CHANGE_LOGS = 'RESET_MAIN_FORMS_CHANGE_LOGS';
export const UPDATE_NOTE_LOCK_STATUS = 'UPDATE_NOTE_LOCK_STATUS';

export const SET_PAGINATED_NOTES = 'SET_PAGINATED_NOTES';
export const RESET_IPD_CONTAINERS_SETTINGS = 'RESET_IPD_CONTAINERS_SETTINGS';
export const ADD_IPD_CONTAINER_PAGE = 'ADD_IPD_CONTAINER_PAGE';
export const SET_IPD_CONTAINER_VALUE = 'SET_IPD_CONTAINER_VALUE';
export const SET_IPD_CONTAINER_NOTES = 'SET_IPD_CONTAINER_NOTES';
export const SET_IPD_CONTAINER_NOTETEMPLATES = 'SET_IPD_CONTAINER_NOTETEMPLATES';

export const SET_PRINT = 'SET_PRINT';
