<template>
  <div>
    <div v-for="item in medicalItems" :key="item.id">
      <template
        v-if="featureFlags.bgrimm"
      >
        <drug-label-component
          :disableAutoPrint="true"
          doctorName='good'
          doctorSurname='welldone'
          :printLocal='printLocal'
          :medItem='item'
          :generalConfig='general'
          :patient='selectedPatient'
        />
        <div style="page-break-after: always;break-after:always"></div>
      </template>
      <template v-else-if="featureFlags.drclinique">
        <div class="container-fluid">
          <medical-label-dr-clinique
            :disableAutoPrint="true"
            :clinicName='general.clinicName'
            :clinicAddress='general.address'
            :medName='item.name'
            :medQty='item.qty'
            :medStrength='item.strength'
            :medUnit='item.unit'
            :medDosage='item.dosage'
            :medExpiry='item.selectedBatch.expiry || ""'
            :medPrecautionOne='item.precaution1'
            :medPrecautionTwo='item.precaution2'
            :patientName='selectedPatient.name'
            :patientNric='selectedPatient.nric'
            :patientDetails='selectedPatient'
          />
        </div>
      </template>
      <template v-else>
        <div class="container-fluid">
          <medical-label-component
            :disableAutoPrint="true"
            v-if="!item.adHocCompounding"
            :clinicName='general.clinicName'
            :clinicAddress='general.address'
            :medName='item.name'
            :medQty='item.qty'
            :medStrength='item.strength'
            :medUnit='item.unit'
            :medDosage='item.dosage'
            :medExpiry='item.selectedBatch.expiry || ""'
            :selectedBatch="item.selectedBatch"
            :medPrecautionOne='item.precaution1'
            :medPrecautionTwo='item.precaution2'
            :patientName='selectedPatient.name'
            :patientNric='selectedPatient.nric'
            :patientDetails='selectedPatient'
          />
          <medical-label-component
            v-else
            :disableAutoPrint="true"
            :clinicName='general.clinicName'
            :clinicAddress='general.address'
            :medName='item.adHocCompoundName'
            :medQty='item.qty'
            :medStrength='item.strength'
            :medUnit='item.adHocCompoundUnit'
            :medDosage='item.adHocCompoundDosage'
            :medExpiry='item.selectedBatch.expiry || ""'
            :selectedBatch="item.selectedBatch"
            :medPrecautionOne='item.adHocCompoundPrecaution1'
            :medPrecautionTwo='item.adHocCompoundPrecaution2'
            :patientName='selectedPatient.name'
            :patientNric='selectedPatient.nric'
            :patientDetails='selectedPatient'
          />
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import MedicalLabelComponent from '@/components/Print/MedicalLabelComponent.vue';
import MedicalLabelDrClinique from '@/components/Print/MedicalLabelDrClinique.vue';
import DrugLabelComponent from '@/components/Print/BGrimmDrugLabelV2.vue';

export default {
  name: 'PrintAllMedicalLabels',
  computed: {
    ...mapGetters('patient', ['selectedPatient']),
    ...mapGetters('invoice', ['print']),
    ...mapGetters('config', ['general', 'featureFlags']),
    medicalItems() {
      const allowedInvType = ['Medicine', 'Wyn'];
      const meta = {
        therapistId: this.print.therapistId,
        providerId: this.print.providerId,
      };

      // Flatten all `Medicine` items with `Order Set` awareness
      return this.print.items.reduce((fItems, pItem) => {
        if (allowedInvType.indexOf(pItem.inventory)!== -1) {
          fItems.push({ ...pItem, ...meta });
        } else if (pItem.inventory === 'Order Set' && pItem.subInventoryRef) {
          pItem.subInventoryRef.forEach((subInvRef) => {
            if (
              subInvRef.inventoryId &&
              allowedInvType.indexOf(subInvRef.inventoryId.inventory)!== -1
            ) {
              fItems.push({
                ...subInvRef.inventoryId,
                qty: subInvRef.qty,
                selectedBatch: subInvRef.selectedBatch,
                ...meta,
              });
            }
          });
        }
        
        return fItems;
      }, []);
    },
  },
  data() {
    return {
      printLocal: false,
    };
  },
  components: {
    MedicalLabelComponent,
    MedicalLabelDrClinique,
    DrugLabelComponent,
  },
  async created() {
    if (this.$route.query && this.$route.query['print-local']) {
      this.printLocal = true;
    } else {
      this.printLocal = false;
    }
    setTimeout(() => {
      window.print();
    }, 2000);
  },
};
</script>

<style scoped src="../../assets/print.css"></style>
