import {
  SET_INITIATING,
  SET_INVALID_REASON,
  SET_INVALID_TO_START,
  SET_TELECONSULT_SESSION,
} from './type.mutation';
import teleConsultService from '@/services/teleconsult-room.service';
import moment from 'moment';

export const state = {
  initiating: true,
  teleConsultSession: {},
  inValidToStartSession: false,
  invalidReason: '',
};

export const mutations = {
  [SET_INITIATING](_state, payload) {
    _state.initiating = payload;
  },
  [SET_INVALID_TO_START](_state, payload) {
    _state.inValidToStartSession = payload;
  },
  [SET_INVALID_REASON](_state, payload) {
    _state.invalidReason = payload;
  },
  [SET_TELECONSULT_SESSION](_state, payload) {
    _state.teleConsultSession = payload;
  },
};

export const actions = {
  fetchTeleConsultSession({ commit }, params) {
    commit(SET_INITIATING, true);
    commit(SET_INVALID_TO_START, false);
    commit(SET_INVALID_REASON, '');
    let invalidToStart = false;
    return teleConsultService.getTeleConsultSession(params.sessionId, params.orgcode)
      .then((response) => {
        commit(SET_INITIATING, false);
        commit(SET_TELECONSULT_SESSION, response.data);
        const now = +moment().valueOf();
        if (response.data.status === 'ENDED') {
          invalidToStart = true;
          commit(SET_INVALID_TO_START, invalidToStart);
          commit(SET_INVALID_REASON, params.ENDED_BY_HOST);
        } else if (response.data.status === 'CANCELLED') {
          invalidToStart = true;
          commit(SET_INVALID_TO_START, invalidToStart);
          commit(SET_INVALID_REASON, params.CANCELLED_BY_HOST);
        } else if (now < response.data.appointment.startDateTime) {
          invalidToStart = true;
          commit(SET_INVALID_TO_START, invalidToStart);
          commit(SET_INVALID_REASON, params.NOT_YET_STARTED);
        }
        // TODO: refactor. This is not good code. I wrote it hehe.
        return { session: response.data, validToStart: !invalidToStart };
      })
      .catch((error) => {
        commit(SET_INVALID_TO_START, true);
        commit(SET_INVALID_REASON, params.UNKNOWN_SERVER_ERROR);
        throw error;
      });
  },
  updateTeleConsultSession({ commit }, params) {
    return teleConsultService
      .updateTeleConsultSession(params.payload._id, params.payload, params.orgcode)
      .then((response) => {
        commit(SET_TELECONSULT_SESSION, response.data);
        return response.data;
      });
  },
  endConsult({ commit }, params) {
    return teleConsultService.endConsult(params.payload._id, params.payload, params.orgcode)
      .then((response) => {
        commit(SET_TELECONSULT_SESSION, response.data);
        return response.data;
      });
  },
  resetTeleConsultValidation({ commit }) {
    commit(SET_INITIATING, true);
    commit(SET_INVALID_TO_START, false);
    commit(SET_INVALID_REASON, '');
  },
};

export const getters = {
  initiating: _state => _state.initiating,
  teleConsultSession: _state => _state.teleConsultSession,
  inValidToStartSession: _state => _state.inValidToStartSession,
  invalidReason: _state => _state.invalidReason,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
