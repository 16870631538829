var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "viewer-container" }, [
    _c("div", { staticClass: "flex-container" }, [
      _c("div", { staticClass: "metadata-container" }, [
        _c("div", { staticClass: "row" }, [
          _c("span", { staticClass: "label col-sm-5" }, [_vm._v("Title:")]),
          _c("span", { staticClass: "data col-sm-7" }, [
            _vm._v(_vm._s(_vm.viewer.name))
          ])
        ]),
        _c("div", { staticClass: "row" }, [
          _c("span", { staticClass: "label col-sm-5" }, [
            _vm._v("Created by / Created date:")
          ]),
          _c("span", { staticClass: "data col-sm-7" }, [
            _vm._v(
              _vm._s(_vm.viewer.createdBy || "Unknown") +
                " / " +
                _vm._s(_vm.formattedCreatedOn)
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "top-right-icons-container" }, [
        _vm.viewer.draft &&
        !_vm.hasPassedNoteLockTimes(
          _vm.notesMapped[_vm.viewer.currentPageId]
        ) &&
        _vm.checkPermissions(
          _vm.notesMapped[_vm.viewer.currentPageId],
          "update"
        )
          ? _c(
              "button",
              {
                directives: [
                  {
                    name: "acl",
                    rawName: "v-acl",
                    value: "consultation_edit_note",
                    expression: "'consultation_edit_note'"
                  }
                ],
                staticClass: "btn btn-default",
                on: {
                  click: function($event) {
                    return _vm.editIndividualPage(
                      _vm.notesMapped[_vm.viewer.currentPageId]
                    )
                  }
                }
              },
              [
                _c("v-icon", {
                  staticClass: "edit",
                  attrs: { name: "edit", scale: "1.6" }
                })
              ],
              1
            )
          : _vm._e(),
        _c("div", { staticClass: "page-number-container" }, [
          _c("span", [
            _vm._v(
              "Page " +
                _vm._s(_vm.currentPageIndex + 1) +
                " / " +
                _vm._s(_vm.viewer.pagesIdOrder.length)
            )
          ])
        ]),
        _c(
          "div",
          {
            staticClass: "close-button2",
            on: { click: _vm.closeChartingViewer }
          },
          [_c("v-icon", { attrs: { name: "times-circle", scale: "2" } })],
          1
        )
      ])
    ]),
    _c("div", { staticClass: "middle-container" }, [
      _c("div", { staticClass: "arrow-container" }, [
        _vm.currentPageIndex !== 0 && _vm.viewer.pagesIdOrder.length !== 1
          ? _c(
              "span",
              {
                on: {
                  click: function($event) {
                    return _vm.goToPageViewer(_vm.currentPageIndex - 1)
                  }
                }
              },
              [_c("v-icon", { attrs: { name: "angle-left", scale: "6" } })],
              1
            )
          : _c(
              "span",
              [
                _c("v-icon", {
                  attrs: { name: "angle-left", scale: "6", color: "grey" }
                })
              ],
              1
            )
      ]),
      _c(
        "div",
        { staticClass: "image-container", attrs: { id: "image-container" } },
        [
          _c("img", {
            attrs: {
              src: _vm.imageURL,
              height: _vm.finalImageHeight,
              width: _vm.finalImageWidth
            },
            on: { load: _vm.imageLoaded }
          })
        ]
      ),
      _c("div", { staticClass: "arrow-container" }, [
        _vm.currentPageIndex !== _vm.viewer.pagesIdOrder.length - 1 &&
        _vm.viewer.pagesIdOrder.length !== 1
          ? _c(
              "span",
              {
                on: {
                  click: function($event) {
                    return _vm.goToPageViewer(_vm.currentPageIndex + 1)
                  }
                }
              },
              [_c("v-icon", { attrs: { name: "angle-right", scale: "6" } })],
              1
            )
          : _c(
              "span",
              [
                _c("v-icon", {
                  attrs: { name: "angle-right", scale: "6", color: "grey" }
                })
              ],
              1
            )
      ])
    ]),
    _c("div", { staticClass: "flex-container" }, [
      _c("div", { staticStyle: { flex: "1" } }),
      _c("div", { staticStyle: { flex: "1" } }),
      _c(
        "div",
        { staticClass: "helper-container", staticStyle: { flex: "1.4" } },
        [
          _c("div", { staticClass: "row" }, [
            _c("span", { staticClass: "col-sm-6" }, [
              _c(
                "span",
                { staticClass: "icon" },
                [
                  _c("v-icon", { attrs: { scale: "1.4", name: "info-circle" } })
                ],
                1
              ),
              _c("span", { staticClass: "icon-text" }, [
                _vm._v("View Revision History")
              ])
            ]),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "acl",
                    rawName: "v-acl",
                    value: "consultation_download_pdf",
                    expression: "'consultation_download_pdf'"
                  }
                ],
                staticClass: "print-note-btn col-sm-6",
                on: {
                  click: function($event) {
                    return _vm.downloadNoteAsPDF(
                      _vm.notesMapped[_vm.viewer.currentPageId]
                    )
                  }
                }
              },
              [
                _c(
                  "span",
                  { staticClass: "icon" },
                  [_c("v-icon", { attrs: { scale: "1.4", name: "download" } })],
                  1
                ),
                _c("span", { staticClass: "icon-text" }, [
                  _vm._v("Download this Page")
                ])
              ]
            )
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "span",
              {
                staticClass: "print-note-btn col-sm-6",
                on: {
                  click: function($event) {
                    return _vm.printNote(
                      _vm.notesMapped[_vm.viewer.currentPageId]
                    )
                  }
                }
              },
              [
                _c(
                  "span",
                  { staticClass: "icon" },
                  [_c("v-icon", { attrs: { scale: "1.4", name: "print" } })],
                  1
                ),
                _c("span", { staticClass: "icon-text" }, [
                  _vm._v("Print this Page")
                ])
              ]
            ),
            _c("span", { staticClass: "col-sm-6" }, [
              _c(
                "span",
                { staticClass: "icon" },
                [_c("v-icon", { attrs: { scale: "1.4", name: "trash" } })],
                1
              ),
              _c("span", { staticClass: "icon-text" }, [
                _vm._v("Delete this Page")
              ])
            ])
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }