import announcementService from "@/services/announcement.service";
import queueService from '../services/queue.service';

const state = {
  all: {},
  params: {},
  queueForPrint: {},
  notificationUpdates: {
    since: 0, // timestamp
  },
  outpatients: {},
};

const getters = {
  all: _state => _state.all,
  calledQueue: _state => _state.all.items
    .filter(d => !!d.is_calling)
    .sort((a, b) => a.calling_timestamp - b.calling_timestamp),
  queueForPrint: _state => _state.queueForPrint,
  notificationUpdates: _state => _state.notificationUpdates,
  outpatients: _state => _state.outpatients,
};

const actions = {
  resetAllQueue({ commit }) {
    commit('resetAllQueueData', {});
  },
  setAllParams({ commit }, params) {
    commit('setAllParams', params);
  },
  getAll(vx) {
    const { commit } = vx;
    const _state = vx.state;
    commit('getAllRequest');
    return queueService.list(_state.all.params)
      .then(
        (res) => {
          commit('getAllSuccess', res.data);
          return res.data;
        },
        (err) => {
          commit('getAllFailure', err);
          throw err;
        },
      );
  },
  // chan
  loadOutpatients({ commit }, params) {
    queueService.outpatients(params)
      .then(
        (res) => {
          console.warn('OUTPATIENTS');
          console.warn(res);
          const patientsList = (res.success) ? res.data : [];
          commit('LOAD_OUTPATIENTS', patientsList);
        },
        (err) => {
          console.error('OUTPATIENTS');
          console.error(err);
          commit('LOAD_OUTPATIENTS', []);
        },
      );
  },
  create(vx, payload) {
    const { dispatch } = vx;
    return queueService.create(payload)
      .then(
        (response) => { dispatch('getAll'); return response; },
        response => response,
      );
  },
  bulkCreate(vx, payload) {
    const { dispatch } = vx;
    return queueService.bulkCreate(payload.time, payload.data)
      .then(
        (response) => { dispatch('getAll'); return response; },
        () => { },
      );
  },
  update(vx, params) {
    const { dispatch } = vx;
    const { payload, id } = params;
    /* eslint-disable no-console */
    // console.log('--update--', id, payload);
    return queueService.update(id, payload)
      .then(
        (response) => { dispatch('getAll'); return response; },
        response => response,
      );
  },
  call(vx, params) {
    const { dispatch } = vx;
    const { payload, id } = params;
    /* eslint-disable no-console */
    // console.log('--call--', id, payload);
    return queueService.call(id, payload)
      .then(
        (response) => { dispatch('getAll'); return response; },
        response => response,
      );
  },
  updateBulk(vx, params) {
    const { dispatch } = vx;
    const payload = params;
    /* eslint-disable no-console */
    // console.log('--update-bulk--', payload);
    queueService.updateBulk(payload)
      .then(
        () => { dispatch('getAll'); },
        () => { },
      );
  },
  remove(vx, params) {
    const { dispatch } = vx;
    const { id } = params;
    /* eslint-disable no-console */
    // console.log('--delete--', id);
    queueService.remove(id)
      .then(
        () => { dispatch('getAll'); },
        () => { },
      );
  },
  removeBulk(vx, params) {
    const { dispatch } = vx;
    /* eslint-disable no-console */
    // console.log('--vuex removeBulk--', params);
    return queueService.removeBulk(params)
      .then(
        (response) => { dispatch('getAll'); return response; },
        response => response,
      );
  },
  removeSecondaryProvider(vx, params) {
    const { dispatch } = vx;
    const { queueId, providerId } = params;
    queueService.removeSecondaryProvider(queueId, providerId)
      .then(
        () => { dispatch('getAll'); },
        () => { },
      );
  },
  setQueueForPrinting({ commit }, payload) {
    commit('SET_QUEUE_FOR_PRINTING', payload);
  },
  checkPatient({ commit }, payload) {
    commit('setLoading', true, { root: true });
    return queueService.checkPatient(payload)
      .then((response) => {
        commit('setLoading', false, { root: true });
        return response;
      });
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  SOCKET_QUEUE_NEW(_state, queue) {
    _state.outpatients = {
      ..._state.outpatients,
      [queue._id]: queue,
    };
  },
  SOCKET_QUEUE_DELETED(_state, queue) {
    const { [queue._id]: omit, ...res } = _state.outpatients;
    _state.outpatients = { ...res };
  },
  LOAD_OUTPATIENTS(_state, outpatientArray) {
    _state.outpatients = outpatientArray.reduce((acc, patient) => {
      return { ...acc, [patient._id]: patient };
    }, {});
  },
  getAllRequest(_state) {
    _state.all = Object.assign({}, _state.all, { loading: true });
  },
  getAllSuccess(_state, queues) {
    /* eslint-disable no-console */
    // console.log('getAllSuccess', queues);
    // _state.all = { items: queues };
    _state.all = Object.assign({}, _state.all, { items: queues, loading: false });
  },
  getAllFailure(_state, error) {
    /* eslint-disable no-console */
    console.error('failed', error);
    _state.all = Object.assign({}, _state.all, { error });
  },
  setAllParams(_state, params) {
    /* eslint-disable no-console */
    // console.log('*****setAllParams', params);
    // _state.all = { items: queues };
    _state.all = Object.assign({}, _state.all, { params });
  },
  UPDATE_OFFLINE(_state, params) {
    /* eslint-disable no-console */
    // console.log('*****update_offline', params);
    const items = _state.all.items.map((d) => {
      let item = Object.assign({}, d);
      if (item._id === params.id) {
        item = Object.assign({}, item, params.data, { changed: true });
      }
      return item;
    });
    _state.all = Object.assign({}, _state.all, { items });
  },
  UPDATE_ALL_OFFLINE(_state, params) {
    /* eslint-disable no-console */
    // console.log('*****update_all_offline', params);
    _state.all = Object.assign({}, _state.all, { items: params });
  },
  SET_QUEUE_FOR_PRINTING(_state, payload) {
    _state.queueForPrint = payload ? { ...payload } : null;
  },
  resetAllQueueData(_state, payload) {
    _state.all = payload;
  },
  SOCKET_NOTIFICATION(_state, payload) {
    if (payload.module && payload.module.name === 'queue') {
      _state.notificationUpdates = {
        since: (new Date()).valueOf(),
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
