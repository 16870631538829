import _ from 'lodash';
import get from 'lodash/get';
import round from 'lodash/round';

export default {
  methods: {
    payFormatter(input) {
      // console.log('formatter in => ' + input);
      if (this.region.code === 'ID') {
        const pattern = new RegExp(this.region.oneKRegex, 'g');
        const number = input.toString().replace(pattern, '');
        // console.log('formatter to  => ' + number);
        if (number.includes(',')) {
          const temp = number.match(/^\d+\,?\d{0,2}/);
          return this.separateThousand(temp);
        }
        return number;
      }

      const pattern = new RegExp(this.region.oneKRegex, 'g');
      const decimalValue = input.toString().replace(pattern, '').replace(this.region.decimal, '.');
      const number = decimalValue.replace(/,/g, '');
      // console.log('formatter to  => ' + number);
      if (number.includes('.')) {
        const temp = number.match(/^\d+\.?\d{0,2}/);
        return this.separateThousand(temp);
      }
      return number;
    },
    to2Decimal(value) {
      return parseFloat(Math.round(value * 100) / 100).toFixed(2);
    },
    thousandSeparator(value, separator = ',') {
      return value
        ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator)
        : '';
    },
    toCurrency(value) {
      if (!value && value !== 0) return '';
      console.log(`toCurrency => ${value}`);
      const withDecimal = parseFloat(value).toString().replace('.', this.region.decimal || '.');
      const oneK = `${this.separateThousand(withDecimal, this.region.thousandSeparator)}`;
      return oneK;
    },
    toDiscountCurrency(value, type) {
      if (type !== '%') return this.toCurrency(value);
      return value;
    },
    toPercentage(value) {
      return `${parseFloat(Math.round(value * 10000) / 100).toFixed(2)}%`;
    },
    separateThousand(value, separator = ',') {
      if (this.region.code === undefined) {
        const parts = value.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        const result = parts.join('.');
        return result;
      }
      const decimalSeparator = get(this, 'region.decimal', '.');
      const parts = value.toString().split(decimalSeparator);
      // console.debug('separateThousand/parts:', parts);
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, separator || this.region.thousandSeparator);
      const result = parts.join(decimalSeparator);
      // console.debug('separateThousand/result:', result);
      return result;
    },
    toProperCurrency(value = 0, isCambodia = false) {
      const withDecimal = parseFloat(value).toFixed(2).replace('.', this.region.decimal || '.');
      if (isCambodia === true) {
        return `R${this.separateThousand(withDecimal, this.region.thousandSeparator)}`;
      }
      return `${this.region.currency}${this.separateThousand(withDecimal, this.region.thousandSeparator)}`;
    },
    currencyToDecimal(dutyValue = '', decimalPlaces = null) {
      const value = _.chain(dutyValue)
        .invoke('replace', /,/g, '')
        .thru(item => parseFloat(item))
        .defaultTo(dutyValue)
        .value();
      const pattern = new RegExp(this.region.oneKRegex, 'g');
      const decimalValue = value.toString().replace(pattern, '').replace(this.region.decimal, '.');
      const number = decimalValue.replace(/,/g, '');
      // console.log('back to number => ' + number);
      if (decimalPlaces) {
        return `${round(number, decimalPlaces)}`;
      }
      return number;
    },
    currencyToDecimalString(value = '') {
      const pattern = new RegExp(this.region.oneKRegex, 'g');
      const decimalValue = value.toString().replace(pattern, '').replace(this.region.decimal, '.');
      return decimalValue;
    },
  },
  filters: {
    thousandSeparator(value, separator = ',') {
      return value
        ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator)
        : '';
    },
    to2Decimal(value, decimal = '.') {
      if (value !== '') {
        return parseFloat(value).toFixed(2).replace('.', decimal);
      }
      return '-';
    },
    toVirtualCurrency(value, vcExchangeRate, convertToVirtualCurrency) {
      if (convertToVirtualCurrency) {
        return (parseFloat(value) * (1 / (vcExchangeRate || 1))).toFixed(2);
      }
      return value;
    },
    shouldHideCurrencyIfConvertingToVC(currencySymbol, convertToVirtualCurrency) {
      if (convertToVirtualCurrency) {
        return '';
      }
      return currencySymbol;
    },
  },
};
