<template>
  <patient-label
    :clinic="generalConfig"
    :patient="patient"
    :printLabelConfig="printLabelConfig"
  />
</template>

<script>
import PatientLabel from '@/components/Print/PatientLabel.vue';
import computedMixins from '@/components/mixins/computed';
import { mapGetters } from 'vuex';

export default {
  name: 'PrintPatientLabel',
  components: {
    PatientLabel,
  },
  mixins: [computedMixins],
  watch: {
    patientConfig() {
      this.printLabelConfig = this.patientConfig.printLabel;
    },
  },
  data() {
    return {
      printLabelConfig: {},
    };
  },
  computed: {
    ...mapGetters('config', {
      generalConfig: 'general',
      patientConfig: 'patient',
    }),
    ...mapGetters('patient', {
      patient: 'selectedPatient',
    }),
  },
};
</script>
