import api from './api';

export default {
  translateErrorMsg(error) {
    return error;
  },
  fetchAll(params) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);

    Object.keys(params).forEach((key) => {
      q.push(`${key}=${params[key]}`);
    });

    const url = `/corporate/?${q.join('&')}`;
    return api.get(url);
  },
  getById(id) {
    const url = `/corporate/${id}`;
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`Error: ${response.data.message}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },
  createNew(data) {
    return api.post('/corporate', data);
  },
  updateCorporate(id, data) {
    return api.put(`/corporate/update/${id}`, data);
  },
  updateBulkCorporate(data) {
    return api.put('/corporate/updateBulk', data);
  },
  delete(id) {
    return api.delete(`/corporate/${id}`);
  },
  deleteBulk(ids) {
    return api.post('/corporate/deleteBulk', ids);
  },
  getCorporate(id) {
    return api.get(`/corporate/${id}`);
  },
  getAllPricingScheme(params = {}) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);

    Object.keys(params).forEach((key) => {
      q.push(`${key}=${params[key]}`);
    });

    const url = `/corporate/scheme?${q.join('&')}`;
    return api.get(url);
  },
  getPricingSchemeDetail(id) {
    const url = `/corporate/scheme-detail/${id}`;
    return api.get(url);
  },
};
