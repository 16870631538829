import messageService from '@/services/message.service';

import {
  LOAD_MESSAGES,
  SET_TOTAL_COUNT,
  SET_FILTERS,
  SET_CURSOR,
} from './type.mutation';

const state = {
  all: [],
  total: 0,
  filters: {
    filter: '',
    clinicCode: '',
  },
  cursor: {
    page: 1,
    limit: 10,
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  [LOAD_MESSAGES](_state, payload) {
    _state.all = payload;
  },
  [SET_TOTAL_COUNT](_state, payload) {
    _state.total = payload;
  },
  [SET_FILTERS](_state, payload) {
    _state.filters = payload;
  },
  [SET_CURSOR](_state, payload) {
    _state.cursor = payload;
  },
};

const actions = {
  setQueryFilters(
    { commit },
    {
      filter, clinicCode, page, limit,
    },
  ) {
    commit(SET_FILTERS, { filter, clinicCode });
    commit(SET_CURSOR, { page, limit });
  },
  loadMessages(
    { commit, state: _state },
  ) {
    const query = {
      ..._state.filters,
      ..._state.cursor,
    };
    return messageService.list(query)
      .then((response) => {
        commit(LOAD_MESSAGES, response.data);
        commit(SET_TOTAL_COUNT, response.total);
      });
  },
  createDCMessage(
    { commit, dispatch },
    {
      thread, recipients, ccRecipients, sender, subject, message,
    },
  ) {
    commit('setLoading', true, { root: true });
    return messageService.messageDC(thread, recipients, ccRecipients, sender, subject, message)
      .then((response) => {
        if (response.success) {
          dispatch('loadMessages');
        }
        commit('setLoading', false, { root: true });
        return response;
      });
  },
  setMessagesAsRead(
    { commit, dispatch },
    { ids },
  ) {
    commit('setLoading', true, { root: true });
    return messageService.setMessagesAsRead({ ids })
      .then((response) => {
        if (response.success) {
          dispatch('loadMessages');
        }
        commit('setLoading', false, { root: true });
        return response;
      });
  },
  archiveMessages(
    { commit, dispatch },
    { ids },
  ) {
    commit('setLoading', true, { root: true });
    return messageService.archiveMessages({ ids })
      .then((response) => {
        if (response.success) {
          dispatch('loadMessages');
        }
        commit('setLoading', false, { root: true });
        return response;
      });
  },
  flagMessages(
    { commit, dispatch },
    { ids },
  ) {
    commit('setLoading', true, { root: true });
    return messageService.flagMessages({ ids })
      .then((response) => {
        if (response.success) {
          dispatch('loadMessages');
        }
        commit('setLoading', false, { root: true });
        return response;
      });
  },
  unarchiveMessages(
    { commit, dispatch },
    { ids },
  ) {
    commit('setLoading', true, { root: true });
    return messageService.unarchiveMessages({ ids })
      .then((response) => {
        if (response.success) {
          dispatch('loadMessages');
        }
        commit('setLoading', false, { root: true });
        return response;
      });
  },
  unflagMessages(
    { commit, dispatch },
    { ids },
  ) {
    commit('setLoading', true, { root: true });
    return messageService.unflagMessages({ ids })
      .then((response) => {
        if (response.success) {
          dispatch('loadMessages');
        }
        commit('setLoading', false, { root: true });
        return response;
      });
  },
  resetAll({ commit }) {
    commit(LOAD_MESSAGES, []);
  },
};

const getters = {
  all: _state => _state.all,
  total: _state => _state.total,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
