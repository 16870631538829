/* eslint-disable */
import PurchaseOrderService from '@/services/purchaseorder.service';

const SET_PURCHASE_ORDER = 'SET_PURCHASE_ORDER';
const SET_PRINT = 'SET_PRINT';

const state = {
  selectedPurchaseOrder: {},
  print: {},
};

/* eslint-disable no-param-reassign */
const mutations = {
  [SET_PURCHASE_ORDER](_state, payload) {
    _state.selectedPurchaseOrder = payload;
  },
  [SET_PRINT](_state, payload) {
    _state.print = payload;
  },
};

const actions = {
  setSelectedPurchaseOrder({ commit }, payload) {
    commit(SET_PURCHASE_ORDER, payload);
  },
  // eslint-disable-next-line no-shadow
  createNewOrder({ commit }, newOrder) {
    return PurchaseOrderService.createNew(newOrder)
      .then((response) => {
        if (response.ok) {
          commit(SET_PURCHASE_ORDER, response.data);
        }
        return response;
      });
  },
  patchOrder({ commit }, { id, ...params}) {
    return PurchaseOrderService.patchOrder(id, params)
      .then((response) => {
        if (response.ok) {
          commit(SET_PURCHASE_ORDER, response.data);
        }
        return response;
      });
  },
  // eslint-disable-next-line no-shadow
  updateOrder({ commit }, updateOrder) {
    return PurchaseOrderService.updateOrder(updateOrder._id, updateOrder)
      .then((response) => {
        if (response.ok) {
          commit(SET_PURCHASE_ORDER, response.data);
        }
        return response;
      });
  },
  savePOPDFFile({ commit }, { id, key, imageFile }) {
    return PurchaseOrderService.savePOPDFFile(id, { key }, imageFile)
  },
  savePOImageFile({ commit }, { id, key, imageFile }) {
    return PurchaseOrderService.savePOImageFile(id, { key }, imageFile)
  },
  print({ commit }, payload) {
    commit(SET_PRINT, payload);
  },
  // bulkUpdateOrder({ commit }, data) {
  //   return PurchaseOrderService.updateBulkOrder(data)
  //     .then(response => response);
  // },
  // bulkDeleteOrder({ commit }, data) {
  //   return PurchaseOrderService.deleteBulk(data)
  //     .then(response => response);
  // },
  // deleteOrder({ commit }, id) {
  //   return PurchaseOrderService.delete(id)
  //     .then((response) => {
  //       if (response.ok) {
  //         commit(SET_PURCHASE_ORDER, {});
  //       }
  //       return response;
  //     });
  // },
  // getOrder({ commit }, id) {
  //   return PurchaseOrderService.getOrder(id)
  //     .then((response) => {
  //       if (response.ok) {
  //         commit(SET_PURCHASE_ORDER, response.data);
  //       }
  //       return response;
  //     });
  // },
};

const getters = {
  selectedPurchaseOrder: _state => _state.selectedPurchaseOrder,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
