<template>
  <vue-good-table :columns="spec.columns" :rows="spec[type].rows">
    <template slot="table-column" slot-scope="props">
      <span v-if="props.column.i18nId">
        {{ $t(props.column.i18nId) }}
      </span>
      <span v-else>
        {{ props.column.label }}
      </span>
    </template>
    <template slot="table-row" slot-scope="props">
      <template v-if="props.column.field == 'item'">
        <span>{{
          $t(`occupationalService.integrationReport.${props.row.key}`)
        }}</span>
      </template>
      <template v-else-if="props.column.field == 'value'">
        <span v-if="has(data,props.row.key)" >
          {{ data[props.row.key] }}
        </span>
      </template>
    </template>
  </vue-good-table>
</template>

<script>
import _ from "lodash";
export default {
  name: 'IntegrationReportComponents',
  props: {
    type: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      has:_.has,
      spec: {
        columns: [
          {
            label: 'Item',
            field: 'item',
            i18nId: 'occupationalService.integrationReport.item',
            thClass: 'text-center w-50',
            tdClass: 'text-center',
            sortable: false,
          },
          {
            label: 'Value',
            field: 'value',
            i18nId: 'occupationalService.integrationReport.value',
            thClass: 'text-center',
            tdClass: 'text-center',
            sortable: false,
          },
        ],
        personal: {
          rows: [
            { key: 'year' },
            { key: 'month' },
            { key: 'employeeNo' },
            { key: 'seniority' },
            { key: 'allergyHistory' },
            { key: 'betelNut' },
            { key: 'alcohol' },
            { key: 'travelHistory' },
          ],
        },
        nmq: {
          rows: [
            { key: 'year' },
            { key: 'month' },
            { key: 'q1' },
            { key: 'q2' },
            { key: 'q3' },
            { key: 'q4' },
            { key: 'other' },
            { key: 'description' },
            { key: 'neck' },
            { key: 'leftShoulder' },
            { key: 'rightShoulder' },
            { key: 'upperBack' },
            { key: 'lowerBack' },
            { key: 'leftArm' },
            { key: 'rightArm' },
            { key: 'leftWrist' },
            { key: 'rightWrist' },
            { key: 'leftTight' },
            { key: 'rightTight' },
            { key: 'leftKnee' },
            { key: 'rightKnee' },
            { key: 'leftAnkle' },
            { key: 'rightAnkle' },
          ],
        },
        workload: {
          rows: [
            { key: 'year' },
            { key: 'month' },
            { key: 'I01' },
            { key: 'I02' },
            { key: 'I03' },
            { key: 'I04' },
            { key: 'I05' },
            { key: 'I06' },
            { key: 'iScore' },
            { key: 'iRisk' },
            { key: 'J01' },
            { key: 'J02' },
            { key: 'J03' },
            { key: 'J04' },
            { key: 'J05' },
            { key: 'J06' },
            { key: 'J07' },
            { key: 'jScore' },
            { key: 'jRisk' },
            { key: 'monthlyOvertimeHoursLevel' },
            { key: 'jobTypeAssessmentLevel' },
            { key: 'workloadLevel' },
            { key: 'cardiovascularDiseaseRiskLevel' },
            { key: 'abnormalWorkloadPromotesDiseaseRiskLevel' },
          ],
        },
        healthCare: {
          rows: [
            { key: 'year' },
            { key: 'month' },
            { key: 'employeeNo' },
            { key: 'employeeName' },
            { key: 'departmentCode' },
            { key: 'departmentName' },
            { key: 'jobPosition' },
            { key: 'gender' },
            { key: 'birthOfDate' },
            { key: 'age' },
            { key: 'ageRange' },
            { key: 'H01' },
            { key: 'H02' },
            { key: 'H03' },
            { key: 'H04' },
            { key: 'H05' },
            { key: 'H06' },
            { key: 'H07' },
            { key: 'H08' },
            { key: 'H09' },
            { key: 'H10' },
            { key: 'H11' },
            { key: 'H12' },
            { key: 'H13' },
            { key: 'H14' },
            { key: 'H15' },
            { key: 'H16' },
            { key: 'H17' },
            { key: 'H18' },
            { key: 'H19' },
            { key: 'H20' },
            { key: 'H21' },
            { key: 'H22' },
            { key: 'H23' },
            { key: 'H24' },
            { key: 'H25' },
            { key: 'H26' },
            { key: 'H27' },
            { key: 'H28' },
            { key: 'H29' },
            { key: 'H30' },
            { key: 'H31' },
            { key: 'H32' },
            { key: 'H33' },
            { key: 'H34' },
            { key: 'H35' },
            { key: 'H36' },
            { key: 'H37' },
            { key: 'framinghamRiskScore' },
            { key: 'cardiovascularDiseaseRiskLevel' },
            { key: 'adultBloodPressureClassification' },
            { key: 'bmiClassification' },
            { key: 'metabolicSyndromeClassification' },
            { key: 'chronicDiseaseGroupClassification' },
          ],
        },
      },
    };
  },
};
</script>
