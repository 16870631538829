<template>
  <div>
    <typing-note-print
      :generalConfig="general"
      :integratedTestConfig="integratedTest"
      :print="print.note"
      :featureFlags="featureFlags"
    />
  </div>
</template>

<script>
import TypingNotePrint from '@/components/Print/TypingNotePrint.vue';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'TypingNote',
  components: {
    TypingNotePrint,
  },
  computed: {
    ...mapState('note', ['print']),
    ...mapGetters('config', ['general', 'integratedTest', 'featureFlags']),
  },
};
</script>
