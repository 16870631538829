/* eslint-disable no-param-reassign, no-console */
import Vue from 'vue';
import IpdInvoiceService from '@/services/ipd-invoice.service';

import {
  SET_IPD_INVOICE,
  SET_SELECTED_SECTION,
  FETCH_ALL_IPD_INVOICES
} from './type.mutation';

const state = {
  ipdInvoiceMappedById: {},
  activeSection: {},
  ipdInvoiceMappedByInvoiceId: {},
  ipdInvoices: []
};

const mutations = {
  [SET_IPD_INVOICE](_state, invoice) {
    const admissionId = (typeof invoice.admission === 'string') ? invoice.admission : invoice.admission._id;
    _state.ipdInvoiceMappedById = {
      ..._state.ipdInvoiceMappedById,
      [admissionId]: invoice,
    };
  },
  [SET_SELECTED_SECTION](_state, section) {
    _state.activeSection = section;
  },
  [FETCH_ALL_IPD_INVOICES](_state, payload) {
    _state.ipdInvoices = payload;
    payload.forEach((inv) => {
      Vue.set(_state.ipdInvoiceMappedByInvoiceId, inv._id, inv);
    });
  },
};

const actions = {
  fetchIpdInvoice({ commit }, admissionId) {
    return IpdInvoiceService.fetch(admissionId)
      .then((resp) => {
        const ipdInvoice = resp.data;
        commit(SET_IPD_INVOICE, ipdInvoice);
        const { sections } = ipdInvoice;
        if (sections && sections.length) {
          const openSections = sections.filter(s => s.status === 'draft');
          const activeSection = openSections[0];
          if (activeSection) {
            commit(SET_SELECTED_SECTION, activeSection);
          }
        }
        return ipdInvoice;
      });
  },
  fetchAllIpdInvoices({ commit }, params = {}) {
    return IpdInvoiceService.list(params)
      .then((resp) => {
        const payload = resp.data;
        commit(FETCH_ALL_IPD_INVOICES, payload);
      })
  },
  updateOneSection({ commit }, { invoice, sectionId }) {
    return IpdInvoiceService.updateOneSection(invoice._id, { invoice, sectionId })
      .then((resp) => {
        console.log('=== xy: got resp for updated ipd-invoice', resp);
        commit(SET_IPD_INVOICE, resp.data);
        return resp.data;
      });
  },
  updateIpdInvoice({ commit }, invoice) {
    if (invoice.payments.length > 0 && typeof invoice.payments[0] === 'object') {
      invoice.payments = invoice.payments.map(x => x._id);
    }
    return IpdInvoiceService.update(invoice._id, invoice)
      .then((resp) => {
        commit(SET_IPD_INVOICE, resp.data);
        return resp.data;
      });
  },
  setActiveSection({ commit }, section) {
    commit(SET_SELECTED_SECTION, section);
  },
  addItemsToActiveSection({ commit }, payload) {
    return IpdInvoiceService.addItemsToActiveSection(payload.admissionId, payload)
      .then((resp) => {
        commit(SET_IPD_INVOICE, resp.data);
        return resp.data;
      });
  },
};

const getters = {
  ipdInvoices: _state => _state.ipdInvoices,
  ipdInvoiceMappedById: _state => _state.ipdInvoiceMappedById,
  ipdInvoiceMappedByInvoiceId: _state => _state.ipdInvoiceMappedByInvoiceId,
  activeSection: _state => _state.activeSection,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
