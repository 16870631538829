var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isDataReady
    ? _c(
        "div",
        { staticClass: "discharge-summary" },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-6" }, [
              _c("img", {
                style: {
                  marginLeft: _vm.logoMarginLeft + "px",
                  width: _vm.logoWidth + "px"
                },
                attrs: {
                  src: _vm.generalConfig.logoURL,
                  alt: _vm.generalConfig.clinicName
                }
              })
            ])
          ]),
          _c("h5", { staticClass: "xlabel" }, [_vm._v("Discharge Summary")]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-6" }, [
              _c("span", { staticClass: "xlabel nd" }, [
                _vm._v("Patient Name:")
              ]),
              _c("span", { staticClass: "xvalue" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.selectedPatient.title) +
                    " " +
                    _vm._s(_vm.selectedPatient.name) +
                    "\n        " +
                    _vm._s(_vm.selectedPatient.surname) +
                    "\n      "
                )
              ])
            ]),
            _c("div", { staticClass: "col-6" }, [
              _c("span", { staticClass: "xlabel nd" }, [
                _vm._v("Date of Birth:")
              ]),
              _c("span", { staticClass: "xvalue" }, [_vm._v(_vm._s(_vm.dob))])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("span", { staticClass: "xlabel nd" }, [
                _vm._v("Hospital Number:")
              ]),
              _c("span", { staticClass: "xvalue" }, [
                _vm._v(
                  _vm._s(_vm.selectedPatient.givenIdPrefix) +
                    _vm._s(_vm.selectedPatient.givenId)
                )
              ])
            ])
          ]),
          _c("div", { staticClass: "line-separation bold" }),
          _c("div", { staticClass: "row" }, [
            _vm._m(0),
            _c("div", { staticClass: "col-3" }, [
              _c("span", { staticClass: "xvalue lb" }, [
                _vm._v(_vm._s(_vm.dischargeForm.dischargeDiagnosis))
              ])
            ]),
            _vm._m(1),
            _c(
              "div",
              { staticClass: "col" },
              [
                _vm._l(_vm.dischargeForm.primaryDiagnosis, function(item) {
                  return _c("div", { key: "primary_" + item.value }, [
                    _vm._v("\n        " + _vm._s(item.text) + "\n      ")
                  ])
                }),
                _vm._l(_vm.dischargeForm.secondaryDiagnosis, function(item) {
                  return _c("div", { key: "sec_" + item.value }, [
                    _vm._v("\n        " + _vm._s(item.text) + "\n      ")
                  ])
                })
              ],
              2
            )
          ]),
          _c("div", { staticClass: "line-separation" }),
          _c("div", { staticClass: "row" }, [
            _vm._m(2),
            _c("div", { staticClass: "col-3" }, [
              _c("span", { staticClass: "xvalue" }, [
                _vm._v(_vm._s(_vm._f("ddMonthYYYY")(_vm.admission.createdAt)))
              ])
            ]),
            _vm._m(3),
            _c("div", { staticClass: "col-3" }, [
              _c("span", { staticClass: "xvalue" }, [
                _vm._v(
                  _vm._s(
                    _vm._f("ddMonthYYYYFromX")(_vm.dischargeForm.finalisedTime)
                  )
                )
              ])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _vm._m(4),
            _c("div", { staticClass: "col" }, [
              _c("span", { staticClass: "xvalue lb" }, [
                _vm._v(_vm._s(_vm.dischargeForm.presentComplain))
              ])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _vm._m(5),
            _vm._m(6),
            _c("div", { staticClass: "col" }, [
              _c("span", { staticClass: "xvalue lb" }, [
                _vm._v(_vm._s(_vm.dischargeForm.allergies))
              ])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _vm._m(7),
            _c("div", { staticClass: "col" }, [
              _c("span", { staticClass: "xvalue lb" }, [
                _vm._v(_vm._s(_vm.dischargeForm.medicalHistory))
              ])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _vm._m(8),
            _c("div", { staticClass: "col" }, [
              _c("span", { staticClass: "xvalue lb" }, [
                _vm._v(_vm._s(_vm.dischargeForm.surgicalHistory))
              ])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _vm._m(9),
            _c("div", { staticClass: "col" }, [
              _c("span", { staticClass: "xvalue lb" }, [
                _vm._v(_vm._s(_vm.dischargeForm.preAdmissionMedication))
              ])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c("p", { staticClass: "xlabel" }, [
                _vm._v("Inpatient Management and Issues:")
              ]),
              _c("div", { staticClass: "xvalue lb" }, [
                _vm._v(_vm._s(_vm.dischargeForm.inpatientManagementIssue))
              ])
            ])
          ]),
          _c("p", { staticClass: "xlabel mt-4" }, [
            _vm._v("Inpatient Medications:")
          ]),
          _vm.inpatientMedecines.length > 0
            ? _c("table", { staticClass: "table ipt-med-table" }, [
                _vm._m(10),
                _c(
                  "tbody",
                  _vm._l(_vm.inpatientMedecines, function(med) {
                    return _c("tr", { key: med._id }, [
                      _c("td", [
                        _vm._v(_vm._s(_vm.getRequestDate(med.dateRequested)))
                      ]),
                      _c("td", [_vm._v(_vm._s(med.name))]),
                      _c("td", [_vm._v(_vm._s(_vm.getAdminQty(med)))])
                    ])
                  }),
                  0
                )
              ])
            : _vm._e(),
          _c("div", { staticClass: "row" }, [
            _vm._m(11),
            _c("div", { staticClass: "col" }, [
              _c("div", { staticClass: "xvalue lb" }, [
                _vm._v(_vm._s(_vm.dischargeForm.surgicalSummary))
              ])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _vm._m(12),
            _c("div", { staticClass: "col" }, [
              _c("div", { staticClass: "xvalue lb" }, [
                _vm._v(_vm._s(_vm.dischargeForm.finalClinicalSummary))
              ])
            ])
          ]),
          _c("div", { staticClass: "line-separation" }),
          _c("div", { staticClass: "row" }, [
            _vm._m(13),
            _c("div", { staticClass: "col" }, [
              _c("div", { staticClass: "xvalue lb" }, [
                _vm._v(_vm._s(_vm.dischargeForm.dischargeOrders))
              ])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _vm._m(14),
            _c("div", { staticClass: "col-3" }, [
              _c("span", { staticClass: "xvalue" }, [
                _vm._v(
                  _vm._s(
                    _vm._f("ddMonthYYYY")(_vm.dischargeForm.followUpApptDate)
                  )
                )
              ])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _vm._m(15),
            _c("div", { staticClass: "col-3" }, [
              _c("span", { staticClass: "xvalue" }, [
                _vm._v(
                  _vm._s(_vm.dischargeForm.medicalCertificate ? "Yes" : "No")
                )
              ])
            ])
          ]),
          _vm.dischargeForm.medicalCertificate
            ? _c("div", { staticClass: "row" }, [
                _vm._m(16),
                _c(
                  "div",
                  { staticClass: "col-3" },
                  _vm._l(_vm.dischargeForm.letters, function(letter) {
                    return _c("p", { key: letter.id }, [
                      _vm._v("\n        " + _vm._s(letter.name) + "\n      ")
                    ])
                  }),
                  0
                )
              ])
            : _vm._e(),
          _c("div", { staticClass: "line-separation bold" }),
          _c("div", { staticClass: "row mb-4" }, [
            _c("div", { staticClass: "col" }, [
              _c("span", { staticClass: "xlabel" }, [_vm._v("Prepared by:")]),
              _c("span", { staticClass: "xvalue" }, [
                _vm._v(
                  _vm._s(_vm.dischargeForm.finalisedUser.name) +
                    " on " +
                    _vm._s(
                      _vm._f("ddMonthYYYYHHmmFromX")(
                        _vm.dischargeForm.finalisedTime
                      )
                    )
                )
              ])
            ])
          ]),
          _c("div", { staticClass: "line-separation bold" }),
          _vm.dischargeForm.medicalCertificate
            ? _vm._l(_vm.dischargeForm.letters, function(letter, idx) {
                return _c("div", { key: "letter_" + idx }, [
                  _c("div", { key: idx, staticClass: "page-break-before" }),
                  _c("div", { staticClass: "letter-container" }, [
                    _c("div", { staticClass: "clinicLogo" }, [
                      _c("img", {
                        style: {
                          marginLeft: _vm.logoMarginLeft + "px",
                          width: _vm.logoWidth + "px"
                        },
                        attrs: {
                          src: _vm.generalConfig.logoURL,
                          alt: _vm.generalConfig.clinicName
                        }
                      })
                    ]),
                    _c("h5", [_vm._v(_vm._s(letter.name))]),
                    _c("div", { staticClass: "letter-content" }, [
                      _vm._v(_vm._s(letter.text))
                    ])
                  ])
                ])
              })
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-3" }, [
      _c("span", { staticClass: "xlabel" }, [_vm._v("Discharge Diagnosis:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-1" }, [
      _c("span", { staticClass: "xlabel" }, [_vm._v("ICD10:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-3" }, [
      _c("span", { staticClass: "xlabel" }, [_vm._v("Date of Admission:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-3" }, [
      _c("span", { staticClass: "xlabel" }, [_vm._v("Date of Discharge:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-3" }, [
      _c("span", { staticClass: "xlabel" }, [_vm._v("Presenting Complaint:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-3" }, [
      _c("span", { staticClass: "xlabel" }, [_vm._v("Past Medical History:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-3" }, [
      _c("span", { staticClass: "xlabel" }, [_vm._v("Allergies:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-3 offset-3" }, [
      _c("span", { staticClass: "xlabel" }, [_vm._v("Medical History:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-3 offset-3" }, [
      _c("span", { staticClass: "xlabel" }, [_vm._v("Surgical History:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-3 offset-3" }, [
      _c("span", { staticClass: "xlabel" }, [
        _vm._v("Pre-admission Medications:")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Date")]),
        _c("th", [_vm._v("Medicine Name")]),
        _c("th", [_vm._v("Quantity(Administered)")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-3" }, [
      _c("span", { staticClass: "xlabel" }, [_vm._v("Surgical Summary:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-3" }, [
      _c("span", { staticClass: "xlabel" }, [_vm._v("Final Clinical Summary:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-3" }, [
      _c("span", { staticClass: "xlabel" }, [_vm._v("Discharge Orders:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-3" }, [
      _c("span", { staticClass: "xlabel" }, [_vm._v("Follow-Up Appointment:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-3" }, [
      _c("span", { staticClass: "xlabel" }, [_vm._v("Medical Certificate:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-3" }, [
      _c("span", { staticClass: "xlabel" }, [_vm._v("List")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }