export const SET_TCM_CONSULTATION_LIST = 'SET_TCM_CONSULTATION_LIST';
export const SET_TCM_SELECTED_CONSULTATION_HISTORY = 'SET_TCM_SELECTED_CONSULTATION_HISTORY';
export const SET_TCM_ACTIVE_HISTORY_PRESCRIPTION = 'SET_TCM_ACTIVE_HISTORY_PRESCRIPTION';
export const SET_TCM_ACTIVE_CONSULTATION = 'SET_TCM_ACTIVE_CONSULTATION';
export const SET_TCM_ACTIVE_PRESCRIPTION = 'SET_TCM_ACTIVE_PRESCRIPTION';
export const SET_TCM_ACTIVE_VISITRECORD = 'SET_TCM_ACTIVE_VISITRECORD';
export const SET_TCM_CONSULTATION_HISTORY_LIST = 'SET_TCM_CONSULTATION_HISTORY_LIST';
export const SET_TCM_OPEN_CONSULTATION_LIST = 'SET_TCM_OPEN_CONSULTATION_LIST';
export const UPDATE_VISITRECORD_FORM = 'UPDATE_VISITRECORD_FORM';
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
export const SET_REFER_ROOT_SHORTCUTS = 'SET_REFER_ROOT_SHORTCUTS';
export const ADD_NEW_PS = 'ADD_NEW_PS';
export const UPDATE_INVOICE = 'UPDATE_INVOICE';
export const ADD_LINE_ITEM = 'ADD_LINE_ITEM';
export const SET_INVOICES = 'SET_INVOICES';
export const SAVE_INVOICES = 'SAVE_INVOICES';
export const SAVE_INVOICE = 'SAVE_INVOICE';
export const UPDATE_INVOICE_GST = 'UPDATE_INVOICE_GST';
export const UPDATE_INVOICE_GST_X_RATE = 'UPDATE_INVOICE_GST_X_RATE';
export const REMOVE_LINE_ITEM = 'REMOVE_LINE_ITEM';
export const REMOVE_ALL_LINE_ITEMS = 'REMOVE_ALL_LINE_ITEMS';
export const BULK_UPDATE_INVOICE = 'BULK_UPDATE_INVOICE';
export const SET_PRINT = 'SET_PRINT';
export const SET_PRINTABLE_DATA = 'SET_PRINTABLE_DATA';
export const SET_PRINT_TREATMENT_RECORD_CONSULTATION = 'SET_PRINT_TREATMENT_RECORD_CONSULTATION';
export const SET_TCM_SELECTED_CONSULTATION_BY_QUEUE = 'SET_TCM_SELECTED_CONSULTATION_BY_QUEUE';
