<template>
  <div>
    <TaxInvoiceMakaung
      v-if="featureFlags.isMaKuang"
      :isCategorized='false'
      :selectedPatient="selectedPatient"
      :print="print"
      :general="generalConfig"
      :printConfig="invoiceConfig.printing"
      :virtualCurrencyConfig="virtualCurrencyConfig"
      :convertToVirtualCurrency="convertToVirtualCurrency"
      :includeGST="invoiceConfig.includeGST"
      :invoiceConfig="invoiceConfig"
      :roundOff="roundingMethod"
      :redeem="redeem"
      :multipleICD10Enabled="patientConfig.multipleICD10Recording"
      :inventoryConfig="inventoryConfig"
    />
    <tax-invoice-tph
      v-else-if="featureFlags.tphInvoice"
      :isCategorized='false'
      :selectedPatient="selectedPatient"
      :print="print"
      :general="generalConfig"
      :printConfig="invoiceConfig.printing"
      :virtualCurrencyConfig="virtualCurrencyConfig"
      :convertToVirtualCurrency="convertToVirtualCurrency"
      :includeGST="invoiceConfig.includeGST"
      :roundOff="roundingMethod"
      :redeem="redeem"
      :multipleICD10Enabled="patientConfig.multipleICD10Recording"/>
    <tax-invoice-component
      v-else
      :isCategorized='false'
      :selectedPatient="selectedPatient"
      :print="print"
      :general="generalConfig"
      :printConfig="invoiceConfig.printing"
      :virtualCurrencyConfig="virtualCurrencyConfig"
      :convertToVirtualCurrency="convertToVirtualCurrency"
      :includeGST="invoiceConfig.includeGST"
      :roundOff="roundingMethod"
      :redeem="redeem"
      :multipleICD10Enabled="patientConfig.multipleICD10Recording"
    />
  </div>
</template>

<script>
import TaxInvoiceComponent from '@/components/Print/TaxInvoiceComponent.vue';
import TaxInvoiceTph from '@/components/Print/TaxInvoiceTph.vue';
import computedMixins from '@/components/mixins/computed';
import { mapGetters, mapActions } from 'vuex';
import TaxInvoiceMakaung from '@/components/Print/TaxInvoiceMakung.vue'

export default {
  name: 'PrintCategorizedTaxInvoice',
  components: {
    TaxInvoiceComponent,
    TaxInvoiceTph,
    TaxInvoiceMakaung
  },
  mixins: [computedMixins],
  data() {
    return {
      convertToVirtualCurrency: false,
    };
  },
  computed: {
    ...mapGetters('patient', ['selectedPatient']),
    ...mapGetters('invoice', ['print']),
    ...mapGetters('config', {
      generalConfig: 'general',
      invoiceConfig: 'invoice',
      patientConfig: 'patient',
      featureFlags: 'featureFlags',
      inventoryConfig: 'inventory',
      virtualCurrencyConfig: 'virtualCurrency',
    }),
    roundingMethod() {
      let roundingMethod;
      if (typeof this.print.roundingEnabled === 'undefined') {
        // Use what is on Config when indv setting is not defined
        roundingMethod = this.invoiceConfig.roundOff;
      } else {
        // TODO: for now set to 0.05 rounding when enabled
        // wait for rounding method to be saved to database too
        roundingMethod = this.print.roundingEnabled ? 'ROUND0P05' : 'NONE';
      }
      return roundingMethod;
    },
    redeem() {
      return this.print.items.filter(i => i.redeemedPoint > 0)
        .reduce((a, b) => {
          a.point += b.redeemedPoint;
          a.amount += b.redeemAmountAfterTax;
          return a;
        }, { point: 0, amount: 0 });
    },
  },
  mounted() {
    if (this.$route.query && this.$route.query['virtual-currency']) {
      this.convertToVirtualCurrency = true;
    }
  },
};
</script>
