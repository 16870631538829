import { SET_SELECTED_HR_NOTIFICATION } from "./type.mutation";
import hrNotificationService from "@/services/hr-notification.service";
const state = {
  selectedHrNotification: {}
};

/* eslint-disable no-param-reassign */
const mutations = {
  [SET_SELECTED_HR_NOTIFICATION](_state, payload) {
    _state.selectedHrNotification = payload;
  }
};

const actions = {
  pinByUser({ commit }, pin) {
    commit("setLoading", true, { root: true });
    return hrNotificationService.pinByUser(pin).then(response => {
      commit("setLoading", false, { root: true });
      return response;
    });
  },
  getHrNotificationById({ commit }, id) {
    commit("setLoading", true, { root: true });
    return hrNotificationService.getHrNotificationById(id).then(response => {
      if (response.ok) {
        commit(SET_SELECTED_HR_NOTIFICATION, response.data);
      }
      commit("setLoading", false, { root: true });
      return response;
    });
  },
  create({ commit }, payload) {
    commit("setLoading", true, { root: true });
    return hrNotificationService.createNew(payload).then(response => {
      commit("setLoading", false, { root: true });
      return response;
    });
  },
  update({ commit }, payload) {
    commit("setLoading", true, { root: true });
    return hrNotificationService
      .updateHrNotification(payload._id, payload)
      .then(response => {
        commit("setLoading", false, { root: true });
        return response;
      });
  },
  sendHrNotification({ commit, dispatch }, payload) {
    commit("setLoading", true, { root: true });
    return hrNotificationService
      .sendHrNotification(payload._id)
      .then(response => {
        commit("setLoading", false, { root: true });
        dispatch("receiveHrNotification/getUnCompletedCount", null, {
          root: true
        });
        return response;
      });
  },
  uploadPdf({ commit }, pdfFile) {
    commit("setLoading", true, { root: true });
    return hrNotificationService.uploadPdf(pdfFile).then(response => {
      commit("setLoading", false, { root: true });
      return response;
    });
  },
  deleteHrNotification({ commit }, id) {
    commit("setLoading", true, { root: true });
    return hrNotificationService.deleteHrNotification(id).then(response => {
      commit("setLoading", false, { root: true });
      return response;
    });
  }
};

const getters = {
  selectedHrNotification: _state => _state.selectedHrNotification
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
