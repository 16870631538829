var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "item-container",
      style: { color: _vm.type === "classified" ? "green" : "" }
    },
    [
      _c("div", { staticClass: "row mb-3" }, [
        _c("div", { staticClass: "col-9" }, [
          _vm.patientConfig.printPrescriptionHeader.clinicLogo
            ? _c("img", {
                style: {
                  marginLeft: _vm.logoMarginLeft + "px",
                  width: _vm.logoWidth + "px"
                },
                attrs: { src: _vm.generalConfig.logoURL }
              })
            : _vm._e(),
          _vm.patientConfig.printPrescriptionHeader.clinicName
            ? _c("h5", { staticClass: "clinic-name" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.generalConfig.clinicName) +
                    "\n      "
                )
              ])
            : _vm._e(),
          _vm.patientConfig.printPrescriptionHeader.clinicAddress
            ? _c("p", { staticClass: "clinic-address" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.generalConfig.address) + "\n      "
                )
              ])
            : _vm._e()
        ]),
        _vm.type === "classified"
          ? _c("div", { staticClass: "col3" }, [
              _c("div", { staticClass: "classified-medicine-tip" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("prescriptionConfig.classifiedMedicineTip")) +
                    "\n      "
                )
              ])
            ])
          : _vm._e()
      ]),
      _c("h3", { staticClass: "mb-5 mt-3 text-center text-uppercase" }, [
        _vm._v(
          "\n    " + _vm._s(_vm.$t("general.prescriptionFormTitle")) + "\n  "
        )
      ]),
      _c("div", { staticClass: "row mb-4" }, [
        _c("div", { staticClass: "col-6" }, [
          _vm.patientConfig.printPrescriptionHeader.patientName
            ? _c("div", { staticClass: "row mb-2" }, [
                _c("div", { staticClass: "col-3 form-label" }, [
                  _vm._v(_vm._s(_vm.$t("prescriptionConfig.patientName")) + ":")
                ]),
                _c("div", { staticClass: "col-9" }, [
                  _vm._v(_vm._s(_vm.selectedPrescription.patient.name))
                ])
              ])
            : _vm._e(),
          _vm.patientConfig.printPrescriptionHeader.patientID
            ? _c("div", { staticClass: "row mb-2" }, [
                _c("div", { staticClass: "col-3 form-label" }, [
                  _vm._v(_vm._s(_vm.$t("prescriptionConfig.patientId")) + ":")
                ]),
                _c("div", { staticClass: "col-9" }, [
                  _vm._v(
                    _vm._s(_vm.selectedPrescription.patient.givenIdPrefix) +
                      _vm._s(_vm.selectedPrescription.patient.givenId)
                  )
                ])
              ])
            : _vm._e(),
          _vm.patientConfig.printPrescriptionHeader.patientAge
            ? _c("div", { staticClass: "row mb-2" }, [
                _c("div", { staticClass: "col-3 form-label" }, [
                  _vm._v(_vm._s(_vm.$t("prescriptionConfig.patientAge")) + ":")
                ]),
                _c("div", { staticClass: "col-9" }, [
                  _vm._v(_vm._s(_vm.selectedPrescription.patient.age))
                ])
              ])
            : _vm._e(),
          _vm.patientConfig.printPrescriptionHeader.patientVN
            ? _c("div", { staticClass: "row mb-2" }, [
                _c("div", { staticClass: "col-3 form-label" }, [
                  _vm._v(_vm._s(_vm.$t("prescriptionConfig.patientVN")) + ":")
                ]),
                _c("div", { staticClass: "col-9" }, [
                  _vm._v(
                    _vm._s(
                      _vm.selectedPrescription.queue &&
                        _vm.selectedPrescription.queue.call_number
                    )
                  )
                ])
              ])
            : _vm._e(),
          _vm.patientConfig.printPrescriptionHeader.diagnosis
            ? _c("div", { staticClass: "row mb-2" }, [
                _c("div", { staticClass: "col-3 form-label" }, [
                  _vm._v(_vm._s(_vm.$t("prescriptionConfig.diagnosis")) + ":")
                ]),
                _c("div", { staticClass: "col-9" })
              ])
            : _vm._e(),
          _vm.patientConfig.printPrescriptionHeader.patientSkinTest
            ? _c("div", { staticClass: "row mb-2" }, [
                _c("div", { staticClass: "col-3 form-label" }, [
                  _vm._v(
                    _vm._s(_vm.$t("prescriptionConfig.patientSkinTest")) + ":"
                  )
                ]),
                _c("div", { staticClass: "col-9" })
              ])
            : _vm._e(),
          _vm.patientConfig.printPrescriptionHeader.allergies
            ? _c("div", { staticClass: "row mb-2" }, [
                _c("div", { staticClass: "col-3 form-label" }, [
                  _vm._v(_vm._s(_vm.$t("prescriptionConfig.allergies")) + ":")
                ]),
                _c("div", { staticClass: "col-9" })
              ])
            : _vm._e()
        ]),
        _c("div", { staticClass: "col-6" }, [
          _vm.patientConfig.printPrescriptionHeader.patientGender
            ? _c("div", { staticClass: "row mb-2" }, [
                _c("div", { staticClass: "col-3 form-label" }, [
                  _vm._v(
                    _vm._s(_vm.$t("prescriptionConfig.patientGender")) + ":"
                  )
                ]),
                _c("div", { staticClass: "col-9" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("translateGender")(
                        _vm.selectedPrescription.patient.gender
                      )
                    )
                  )
                ])
              ])
            : _vm._e(),
          _vm.patientConfig.printPrescriptionHeader.patientWeight
            ? _c("div", { staticClass: "row mb-2" }, [
                _c("div", { staticClass: "col-3 form-label" }, [
                  _vm._v(
                    _vm._s(_vm.$t("prescriptionConfig.patientWeight")) + ":"
                  )
                ]),
                _c("div", { staticClass: "col-9" })
              ])
            : _vm._e(),
          _vm.patientConfig.printPrescriptionHeader.patientTelephone
            ? _c("div", { staticClass: "row mb-2" }, [
                _c("div", { staticClass: "col-3 form-label" }, [
                  _vm._v(
                    _vm._s(_vm.$t("prescriptionConfig.patientTelephone")) + ":"
                  )
                ]),
                _c("div", { staticClass: "col-9" }, [
                  _vm._v(_vm._s(_vm.selectedPrescription.patient.mobile))
                ])
              ])
            : _vm._e(),
          _vm.patientConfig.printPrescriptionHeader.serviceType
            ? _c("div", { staticClass: "row mb-2" }, [
                _c("div", { staticClass: "col-3 form-label" }, [
                  _vm._v(_vm._s(_vm.$t("prescriptionConfig.serviceType")) + ":")
                ]),
                _c("div", { staticClass: "col-9" }, [
                  _vm._v(
                    _vm._s(
                      _vm.selectedPrescription.queue &&
                        _vm.selectedPrescription.queue.service &&
                        _vm.selectedPrescription.queue.service.name
                    )
                  )
                ])
              ])
            : _vm._e(),
          _vm.patientConfig.printPrescriptionHeader.invoiceDate
            ? _c("div", { staticClass: "row mb-2" }, [
                _c("div", { staticClass: "col-3 form-label" }, [
                  _vm._v(_vm._s(_vm.$t("prescriptionConfig.invoiceDate")) + ":")
                ]),
                _c("div", { staticClass: "col-9" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("formatDayTime")(
                        _vm.selectedPrescription.createdAt
                      )
                    )
                  )
                ])
              ])
            : _vm._e()
        ])
      ]),
      _c("div", { staticClass: "medicine-content-title" }, [_vm._v("Rp.")]),
      _c(
        "div",
        { staticClass: "mb-4" },
        [
          _c(
            "div",
            {
              staticClass: "row med-header",
              style: { borderColor: _vm.type === "classified" ? "green" : "" }
            },
            [
              _c("div", { staticClass: "col text-uppercase" }, [
                _vm._v(_vm._s(_vm.$t("prescriptionConfig.medName")))
              ]),
              _c("div", { staticClass: "col text-uppercase" }, [
                _vm._v(_vm._s(_vm.$t("prescriptionConfig.usage")))
              ]),
              _c("div", { staticClass: "col text-uppercase" }, [
                _vm._v(_vm._s(_vm.$t("prescriptionConfig.dosage")))
              ]),
              _c("div", { staticClass: "col text-uppercase" }, [
                _vm._v(_vm._s(_vm.$t("prescriptionConfig.frequency")))
              ]),
              _c("div", { staticClass: "col text-uppercase" }, [
                _vm._v(_vm._s(_vm.$t("prescriptionConfig.duration")))
              ]),
              _c("div", { staticClass: "col text-uppercase" }, [
                _vm._v(_vm._s(_vm.$t("prescriptionConfig.qty")))
              ]),
              _c("div", { staticClass: "col text-uppercase" }, [
                _vm._v(_vm._s(_vm.$t("prescriptionConfig.precautions")))
              ])
            ]
          ),
          _vm._l(_vm.items, function(item) {
            return _c(
              "div",
              {
                key: item._id,
                staticClass: "row med-body",
                style: { borderColor: _vm.type === "classified" ? "green" : "" }
              },
              [
                _c("div", { staticClass: "col" }, [_vm._v(_vm._s(item.name))]),
                _c("div", { staticClass: "col" }, [
                  _vm._v(_vm._s(item.dosageUsage))
                ]),
                _c("div", { staticClass: "col" }, [
                  _vm._v(_vm._s(item.dosageDose + " " + item.dosageUnit))
                ]),
                _c("div", { staticClass: "col" }, [
                  _vm._v(_vm._s(item.dosageFreq))
                ]),
                _c("div", { staticClass: "col" }, [
                  _vm._v(_vm._s(item.duration))
                ]),
                _c("div", { staticClass: "col" }, [
                  _vm._v(_vm._s(item.qty) + " " + _vm._s(item.unit))
                ]),
                _c("div", { staticClass: "col" }, [
                  _c("div", [_vm._v(_vm._s(item.precaution1))]),
                  _c("div", [_vm._v(_vm._s(item.precaution2))])
                ])
              ]
            )
          })
        ],
        2
      ),
      _c("div", { staticClass: "mb-5 row med-footer" }, [
        _c("div", { staticClass: "col-6 form-label" }, [
          _c("div", { staticClass: "footer-item" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("prescriptionConfig.audit")) +
                ":\n      "
            )
          ]),
          _c("div", { staticClass: "footer-item" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("prescriptionConfig.prepare")) +
                ":\n      "
            )
          ]),
          _c("div", { staticClass: "footer-item" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("prescriptionConfig.checkAndDispense")) +
                ":\n      "
            )
          ])
        ]),
        _c("div", { staticClass: "col-6 form-label" }, [
          _c(
            "div",
            { staticClass: "footer-item" },
            [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("prescriptionConfig.price")) +
                  ": \n        "
              ),
              !!_vm.itemTotalPrice
                ? [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.region.currency) +
                        _vm._s(
                          _vm._f("thousandSeparator")(
                            _vm._f("to2Decimal")(
                              _vm.itemTotalPrice,
                              _vm.region.decimal
                            ),
                            _vm.region.thousandSeparator
                          )
                        ) +
                        "\n        "
                    )
                  ]
                : _vm._e()
            ],
            2
          ),
          _c("div", { staticClass: "footer-item" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("prescriptionConfig.physician")) +
                ":\n      "
            )
          ])
        ])
      ]),
      _vm.type === "classified"
        ? _c("div", { staticStyle: { "page-break-after": "always" } })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }