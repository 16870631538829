<template>
  <div class="container-lg">
    <div class="background">
      <img
        class="background-img"
        v-if="backgroundImageURL"
        :src="backgroundImageURL"
      />
    </div>
    <div class="content" :class="{ 'has-bg-image': backgroundImageURL }">
      <header class="mb-1">
        <img
          v-if="!backgroundImageURL"
          class="logo"
          :src="generalConfig.logoURL"
          :alt="generalConfig.clinicName"
        />
        <div>
          <strong>{{ $t('general.date') }}:</strong>
          {{ now | formatVDDate({ format: 'DD/MM/YYYY' }) }}
        </div>
      </header>
      <section>
        <h3 class="text-center text-uppercase">
          {{ $t('tcm.treatmentRecord') }}
        </h3>
        <div class="patient-info mt-4">
          <table class="table table-bordered">
            <tr>
              <td>
                <label>{{ $t('general.name') }}:</label>
                {{ selectedPatient.name }}
              </td>
              <td>
                <label>{{ $t('general.gender') }}:</label>
                {{ patientGender }}
              </td>
            </tr>
            <tr>
              <td>
                <label>{{ $t('general.chineseName') }}:</label>
                {{ selectedPatient.localName }}
              </td>
              <td>
                <label>{{ $t('general.idDocNumber') }}:</label>
                {{ selectedPatient.nric }}
              </td>
            </tr>
          </table>
        </div>
        <div class="treatment-record mt-2">
          <table class="table table-bordered">
            <tr v-if="treatmentRecord && treatmentRecord.medicalComplaint">
              <td>
                <strong>{{ $t('tcm.medicalComplaint') }}:</strong>
              </td>
              <td>
                <div v-if="getFieldType('medicalComplaint') === 'richtext'">
                  <rich-text-editor
                    :html="treatmentRecord.medicalComplaint"
                    :viewing="true"
                    :printing="true"
                  />
                </div>
                <div v-else>{{ treatmentRecord.medicalComplaint }}</div>
              </td>
            </tr>
            <tr>
              <td style="width: 25%;">
                <strong>{{ $t('tcm.visitDate') }}:</strong>
              </td>
              <td>
                {{
                  consultation.visitDate
                    | formatVDDate({ format: 'DD/MM/YYYY', sourceFormat: 'x' })
                }}
              </td>
            </tr>
            <tr>
              <td>
                <strong>{{ $t('tcm.physicianTherapist') }}:</strong>
              </td>
              <td>
                {{ physicianAndTherapist }}
              </td>
            </tr>
            <tr>
              <td>
                <strong>{{ $t('general.visitLocation') }}:</strong>
              </td>
              <td>
                {{ clinicName }}
              </td>
            </tr>
            <tr v-for="(value, field) in filteredTreatmentRecord" :key="field">
              <td>
                <strong class="text-capitalize"
                  >{{ getTranslatedField(field) }}:</strong
                >
              </td>
              <td>
                <div v-if="getFieldType(field) === 'richtext'">
                  <rich-text-editor
                    :html="value"
                    :viewing="true"
                    :printing="true"
                  />
                </div>
                <div v-else>{{ value }}</div>
              </td>
            </tr>
          </table>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters } from 'vuex';
import DateFormatter from '@/components/mixins/dateformatter';
import Transformer from '@/components/mixins/transformer';
import JsonSchemaForm from '@/components/FormBuilder/JsonSchemaForm.vue';
import RichTextEditor from '@/components/NoteEditorWidget/RichTextEditor.vue';

export default {
  name: 'TreatmentRecord',
  mixins: [DateFormatter, Transformer],
  components: {
    JsonSchemaForm,
    RichTextEditor,
  },
  props: {
    consultation: { type: Object, required: true },
  },
  computed: {
    ...mapGetters('config', {
      generalConfig: 'general',
      patientConfig: 'patient',
      treatmentRecordForm: 'treatmentRecordForm',
    }),
    ...mapGetters('patient', ['selectedPatient']),
    visitRecord() {
      return this.consultation && this.consultation.visitRecord;
    },
    treatmentRecord() {
      return this.visitRecord && this.visitRecord.treatmentRecord;
    },
    filteredTreatmentRecord() {
      if (!this.treatmentRecord) {
        return {};
      }
      return Object.fromEntries(
        Object.entries(this.visitRecord.treatmentRecord).filter(
          ([field, val]) => !this.skippedFields.has(field),
        ),
      );
    },
    patientGender() {
      return this.translateGender(this.selectedPatient.gender);
    },
    physicianAndTherapist() {
      return this.consultation.doctor && this.consultation.doctor.name;
    },
    clinicName() {
      return this.consultation.clinic && this.consultation.clinic.name;
    },
    backgroundImageURL() {
      return (
        this.patientConfig.printTreatmentRecord &&
        this.patientConfig.printTreatmentRecord.backgroundImageURL
      );
    },
  },
  data() {
    return {
      now: moment(),
      // Skip medicalComplaint due to custom placement
      skippedFields: new Set(['medicalComplaint']),
    };
  },
  methods: {
    getFieldSchema(field) {
      return (
        this.treatmentRecordForm &&
        this.treatmentRecordForm.schema &&
        this.treatmentRecordForm.schema.properties &&
        this.treatmentRecordForm.schema.properties[field]
      );
    },
    getFieldType(field) {
      const fieldSchema = this.getFieldSchema(field);
      return fieldSchema && fieldSchema.type;
    },
    getTranslatedField(field) {
      const fallbackField = _.startCase(field);
      const fieldSchema = this.getFieldSchema(field);

      if (!fieldSchema) {
        return fallbackField;
      }

      if (fieldSchema.i18nId) {
        return this.$t(fieldSchema.i18nId);
      }

      if (fieldSchema.label) {
        return fieldSchema.label;
      }

      return fallbackField;
    },
  },
};
</script>
<style lang="scss" scoped>
// Fixed header & footer spec of template background image
$background-header-height: 120px;

.background {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .background-img {
    width: 100%;
    height: 100%;
  }
}

.logo {
  max-height: $background-header-height;
}

.content {
  z-index: 10;
  padding-left: 10px;
  padding-right: 10px;

  &.has-bg-image {
    padding-top: $background-header-height;
  }
}

.table {
  th,
  td {
    padding: 0.5rem;
  }

  &.table-bordered {
    th,
    td {
      border-color: black !important;
    }
  }
}

.patient-info {
  label {
    font-weight: bold;
    margin-bottom: 0;
  }

  .table {
    border-collapse: separate !important;

    &.table-bordered {
      border: 4px solid black;
    }
  }
}

// Override rich text editor styles to blend in with the treatment record table
#xy-note-editor {
  border: 0;
  margin: 0;
  padding: 0;
  background: transparent;

  /deep/ .ProseMirror {
    padding: 0;
    line-height: 1;
  }
}
</style>
