<template>
  <!-- eslint-disable max-len -->
  <div id="fullForm">
    <b-form v-if="formData">
      <div :class="['note-form-inner', { printContainer: isPrinting }, metadata.formClass || '']">
        <template v-for="(elRow, idx) in groups">
          <div class="row" :key="idx">
            <div
              v-for="(qns, i) in elRow"
              :key="i"
              :class="`formDataElement ${formData[qns].colSize || 'col-sm-6'}`"
              >
              <div v-if="formData[qns].type === 'table'">
                <b-table
                  striped
                  hover
                  :items="formData[qns].answer"
                  thead-class="d-none"
                ></b-table>
              </div>
              <div v-else-if="formData[qns].dynamictable"
                class="dynamic-table-container"
                >
                <dynamic-table
                  :item="formData[qns]"
                  :qns="qns"
                  :viewing="viewing"
                  :updateTypingFormData="updateTypingFormData"
                  :updateTick="updateTick"
                  :formData="formData"
                  :printing="isPrinting"
                />
              </div>
              <div v-else-if="formData[qns].type === 'metadata'"></div>
              <div v-else-if="formData[qns].type === 'misc-gdcalc'" :data-tick="tick"
                class="gd-calc-container"
                >
                <gd-calc-widget
                  :item="formData[qns]"
                  :qns="qns"
                  :viewing="viewing"
                  :updateTypingFormData="updateTypingFormData"
                  :tick="tick"
                  />
              </div>
              <div v-else-if="formData[qns].type === 'noanswer-nil'" :data-tick="tick"
                >
                <noanswer-nil
                  :item="formData[qns]"
                  :qns="qns"
                  :viewing="viewing"
                  :printing="isPrinting"
                  :updateTypingFormData="updateTypingFormData"
                  />
              </div>
              <div v-else-if="formData[qns].type === 'oneclick-button'" :data-tick="tick"
                class="oneclick-button-container"
                >
                <one-click-button
                  :item="formData[qns]"
                  :qns="qns"
                  :viewing="viewing"
                  :print="isPrinting"
                  :formData="formData"
                  :updateTypingFormData="updateTick"
                />
              </div>
              <div v-else-if="formData[qns].type === 'richtext'"
                class="rich-text-container"
                >
                <rich-text-editor
                  :qns="qns"
                  :html="formData[qns].answer"
                  :updateTypingFormData="updateTypingFormData"
                  :viewing="viewing"
                  :printing="isPrinting"
                  />
              </div>
              <div v-else-if="formData[qns].type === 'range-link'"  :data-tick="tick"
                >
                <date-range-link-input
                  :item="formData[qns]"
                  :viewing="viewing" :printing="isPrinting" :formData="formData"
                  @update="setLinkedObjectAnswer"
                  :qns="qns"
                  :updateTick="updateTick"
                />
              </div>
              <div v-else-if="formData[qns].type === 'masscheck'" :data-tick="tick">
                <mass-check :item="formData[qns]" @setMassCheckFlag="setMassCheckFlag"
                  :viewing="viewing" :printing="isPrinting" :formData="formData"
                  />
              </div>
              <div v-else-if="formData[qns].type === 'link-input'" :data-tick="tick">
                <link-input-widget :item="formData[qns]"
                  :viewing="viewing" :printing="isPrinting" :formData="formData"
                  :getClass="getClass"
                  :rowIdx="i"
                  @update="setLinkedObjectAnswer"
                  :qns="qns"
                  />
              </div>
              <div class="form-row" v-else-if="formData[qns].type === 'link'">
                <div class="col-sm-4">Vuemotion Image:</div>
                <b-link class="col" :href="formData[qns].answer">Link</b-link>
              </div>
              <b-form-group
                v-else-if="formData[qns].type === 'input'"
                :label="formData[qns].label"
                :disabled="
                  viewing ||
                    formData[qns].disabled ||
                    formData[qns].display === 'hide'
                "
                :class="getClass(formData, i, qns)"
                :id="`${qns}group`"
              >
                <b-form-input
                  :id="`${qns}input`"
                  :value="
                    formData[qns].dateFormat &&
                    formData[qns].dateFormat.length > 0
                      ? parseDateValue(
                          formData[qns].answer,
                          formData[qns].dateFormat
                        )
                      : formData[qns].answer
                  "
                  :type="formData[qns].inputType"
                  @input="updateTypingFormData($event, qns)"
                  :plaintext="formData[qns].plaintext"
                  :readonly="formData[qns].readonly"
                  :placeholder="formData[qns].placeholder"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                v-else-if="formData[qns].type === 'textarea'"
                :label="formData[qns].label"
                :disabled="
                  formData[qns].disabled || formData[qns].display === 'hide'
                "
                :class="getClass(formData, i, qns)"
                :id="`${qns}group`"
              >
                <text-editor
                  v-if="formData[qns].texteditor === true"
                  :qns="qns"
                  :value="formData[qns].answer"
                  :rows="formData[qns].rows"
                  :updateTypingFormData="updateTypingFormData"
                  :disabled="viewing || formData[qns].disabled"
                  :id="`${qns}textarea`"
                />
                <typed-form
                  v-else
                  :qns="qns"
                  :value="formData[qns].answer"
                  :rows="formData[qns].rows"
                  :updateTypingFormData="updateTypingFormData"
                  :disabled="viewing || formData[qns].disabled"
                />
              </b-form-group>
              <div
                v-else-if="formData[qns].type == 'empty_cell'"
                :class="getClass(formData, i, qns)"
                :style="formData[qns].style"
              >
                &nbsp;
              </div>
              <div
                v-else-if="formData[qns].type == 'readonly'"
                :class="getClass(formData, i, qns)"
                :style="formData[qns].style"
              >
                <span>{{ formData[qns].label }}</span>
              </div>
              <hr v-else-if="formData[qns].type == 'hr'" />
              <br v-else-if="formData[qns].type == 'br'" />
              <b-form-group
                v-else-if="formData[qns].type === 'link-search'"
                :class="getClass(formData, i, qns)"
                >
                <el-autocomplete
                  v-model="formData[qns].answer"
                  :debounce="500"
                  :fetch-suggestions="
                    (queryString, cb) =>
                      searchQuery(queryString, cb, formData[qns].searchType)
                  "
                  @select="item => updateSearchLink(item, formData[qns].linkTo)"
                  :placeholder="formData[qns].placeholder"
                  class="procedure-picker"
                  :disabled="viewing || formData[qns].disabled"
                />
              </b-form-group>
              <b-card
                class="col-12 mb-3"
                v-else-if="formData[qns].type == 'formattedResult'"
              >
                <div
                  v-for="(res, index) in formData[qns].value"
                  :key="`formatted-${index}`"
                  class="mt-3"
                >
                  <span v-html="formatResults(res)"></span>
                </div>
              </b-card>
              <b-form-group
                v-else-if="formData[qns].type === 'check_with_main_checkbox'"
                :key="formData[qns].lastChangedKey || +new Date()"
                :class="getClass(formData, i, qns)"
                :disabled="viewing || formData[qns].disabled"
                :id="`${qns}group`"
              >
                <span>{{ formData[qns].label }}</span>
                <b-form-checkbox
                  :checked="formData[qns].mainAnswer"
                  class="ml-2"
                  style="display:inline;"
                  :value="formData[qns].mainCheckbox.value"
                  @change="(e) => mainCheckboxChanged(e, qns, formData[qns].mainCheckbox.effects)"
                  >{{ formData[qns].mainCheckbox.text }}</b-form-checkbox
                >
                <div v-if="formData[qns].description">
                  {{ formData[qns].description }}
                </div>
                <b-form-checkbox-group
                  :checked="formData[qns].answer"
                  :options="formData[qns].options"
                  :stacked="formData[qns].stacked"
                  @input="updateTypingFormData($event, qns)"
                ></b-form-checkbox-group>
              </b-form-group>
              <b-button
                v-else-if="formData[qns].type === 'button'"
                v-b-modal="`${formData[qns].map}modal`"
                :dataevent="
                  formData[qns].clickevent ? formData[qns].clickevent : ''
                "
                :mapid="formData[qns].map"
                class="form-btn"
                @click="handleEvent"
                :id="`${qns}button`"
                :disabled="viewing || formData[qns].disabled"
                >{{ formData[qns].label }}</b-button
              >
              <b-modal
                v-else-if="formData[qns].type === 'modal'"
                :id="`${qns}modal`"
                :title="formData[qns].label"
                hide-footer
              >
                <legend style="font-size: 16px;">
                  <b>User Verification</b>
                </legend>
                <digital-signature
                  v-if="formData[qns].modalId === 'digitalsignature'"
                  @digitalSignedHandler="
                    user => handleDigitalSignatureAuthorise(user, qns)
                  "
                />
              </b-modal>
              <b-form-group
                v-else
                :label="formData[qns].label"
                :class="getClass(formData, i, qns)"
                :disabled="viewing || formData[qns].disabled"
                :id="`${qns}group`"
              >
                <b-form-select
                  v-if="formData[qns].type === 'select'"
                  v-model="formData[qns].answer"
                  :options="
                    formData[qns].populateKey &&
                    formData[qns].populateKey === 'provider'
                      ? providersDropDownOptions
                      : formData[qns].populateKey &&
                        formData[qns].populateKey === 'therapist'
                      ? therapistsDropDownOptions
                      : formData[qns].options
                  "
                  :stacked="formData[qns].stacked"
                  @input="updateTypingFormData($event, qns)"
                  plain
                >
                  <template #first v-if="formData[qns].answer">
                    <option :value="formData[qns].answer" disabled>{{
                      formData[qns].answer
                    }}</option>
                  </template>
                </b-form-select>

                <b-form-checkbox-group
                  v-if="formData[qns].type === 'check'"
                  :checked="formData[qns].answer"
                  :options="formData[qns].options"
                  @input="updateTypingFormData($event, qns)"
                  :id="`${qns}check`"
                ></b-form-checkbox-group>

                <b-form-radio-group
                  v-else-if="formData[qns].type === 'radio'"
                  :checked="formData[qns].answer"
                  :options="formData[qns].options"
                  @change="
                    e =>
                      mainRadioChanged(
                        e,
                        qns,
                        formData[qns].mainRadioboxControl
                      )
                  "
                  :id="`${qns}radio`"
                ></b-form-radio-group>

                <span v-if="formData[qns].noteeditor">
                  <div
                    class="editor"
                    v-html="formData[qns].answer || '<br>'"
                    v-if="editorId !== i"
                    @click="editorFocus(i)"
                  />
                  <note-editor
                    v-else-if="editorId === i"
                    class="editor"
                    :pos="i"
                    :html="formData[qns].answer"
                    :updateTypingFormData="updateTypingFormData"
                    :editorBlur="editorBlur"
                  />
                </span>
              </b-form-group>
              <b-form-group
                v-if="formData[qns].type === 'search'"
                :class="getClass(formData, i, qns)"
                >
                <el-autocomplete
                  v-if="formData[qns].type === 'search'"
                  v-model="formData[qns].answer"
                  :debounce="500"
                  :fetch-suggestions="
                    (queryString, cb) =>
                      searchQuery(queryString, cb, formData[qns].searchType)
                  "
                  :placeholder="formData[qns].placeholder"
                  class="procedure-picker"
                  :disabled="viewing || formData[qns].disabled"
                />
              </b-form-group>
              <div
                v-if="formData[qns].type === 'signaturePad'"
                :class="' mb-4'"
              >
                <signature-form
                  :formData="formData[qns]"
                  :noteEditor="true"
                  :qns="qns"
                  :containerName="containerName"
                  :viewing="viewing"
                />
              </div>
            </div>
          </div>
        </template>
      </div>
    </b-form>
    <div v-if="editor.requireSignature" class="row">
      <div class="col-sm-6">
        <signature-form
          :patientForm="editor"
          :noteEditor="true"
          :containerName="containerName"
          :viewing="viewing"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console */
import _ from 'lodash';
import NoteEditor from '@/components/NoteEditor.vue';
import TypedForm from '@/components/TypedForm.vue';
import TextEditor from '@/components/TextEditor.vue';
import SignatureForm from '@/components/Patient/SignatureForm.vue';
import DigitalSignature from '@/components/FormBuilder/DigitalSignature.vue';
import DateFormatter from '@/components/mixins/dateformatter';
import api from '@/services/api';
import moment from 'moment';
import DynamicTable from '@/components/NoteEditorWidget/DynamicTable.vue';
import MassCheck from '@/components/NoteEditorWidget/MassCheck.vue';
import GDCalcWidget from '@/components/NoteEditorWidget/Misc/GDCalcWidget.vue';
import OneClickButton from '@/components/NoteEditorWidget/OneClickButton.vue';
import RichTextEditor from '@/components/NoteEditorWidget/RichTextEditor.vue';
import NoAnswerNil from '@/components/NoteEditorWidget/Misc/NoAnswerNil.vue';
import LinkInputWidget from '@/components/NoteEditorWidget/LinkInput.vue';
import DateRangeLinkInput from '@/components/NoteEditorWidget/DateRangeLinkInput.vue';

export default {
  name: 'TypingNoteEditor',
  components: {
    NoteEditor,
    TypedForm,
    TextEditor,
    SignatureForm,
    DigitalSignature,
    DynamicTable,
    MassCheck,
    'gd-calc-widget': GDCalcWidget,
    OneClickButton,
    RichTextEditor,
    'noanswer-nil': NoAnswerNil,
    LinkInputWidget,
    DateRangeLinkInput,
  },
  computed: {
    groups() {
      if (this.source !== 'print') {
        // When not in printint mode, show the old style
        const validOrder = this.formDataOrder.filter(qnsIdx => this.formData[qnsIdx] !== undefined);
        return [validOrder];
      }
      const ret = [];
      let lastRowLength = 0;
      const defaultRowLength = 6;
      const defaultRowMaxLength = 12;
      let lastRow = [];
      this.formDataOrder.forEach((qnsIdx) => {
        const qns = this.formData[qnsIdx];
        if (qnsIdx === 'metadata' || qns.type === 'metadata') return;
        let thisRowLength = 0;
        const { colSize } = qns;
        if (!colSize) {
          // default Size: 6
          thisRowLength = defaultRowLength;
        } else if (colSize === 'col') {
          thisRowLength = defaultRowMaxLength;
        } else {
          // get number of cols from colSize
          const lastEleOfSize = colSize.split('-').slice(-1)[0];
          const colLen = parseFloat(lastEleOfSize);
          if (colLen >= 1 && colLen <= 12) {
            thisRowLength = colLen;
          } else {
            console.log('Fatal ', colSize);
            thisRowLength = defaultRowMaxLength;
          }
        }
        if (lastRowLength + thisRowLength > defaultRowMaxLength) {
          ret.push(lastRow.slice());
          if (thisRowLength >= defaultRowMaxLength) {
            ret.push([qnsIdx]);
            lastRow = [];
            lastRowLength = 0;
          } else {
            lastRowLength = thisRowLength;
            lastRow = [qnsIdx];
          }
        } else if (lastRowLength + thisRowLength === defaultRowMaxLength) {
          lastRow.push(qnsIdx);
          ret.push(lastRow.slice());
          lastRowLength = 0;
          lastRow = [];
        } else {
          lastRow.push(qnsIdx);
          lastRowLength += thisRowLength;
        }
      });
      return ret;
    },
    isPrinting() {
      return this.source === 'print';
    },
    metadata() {
      const defaultOptions = {
        formClass: '',
      };
      const { metadata } = this.formData;
      if (!metadata) return defaultOptions;
      return metadata.options;
    },
  },
  mixins: [DateFormatter],
  data() {
    return {
      digitalSignedUser: {
        name: null,
      },
      therapistsDropDownOptions: [],
      providersDropDownOptions: [],
      // NOTE: LeiLi use this prop to force UI refresh for masscheck component
      tick: 0,
    };
  },
  props: {
    editor: {
      type: Object,
    },
    formDataOrder: {
      type: Array,
    },
    formData: {
      type: Object,
    },
    viewing: {
      type: Boolean,
    },
    updateTypingFormData: {
      type: Function,
    },
    mainCheckboxChanged: {
      type: Function,
    },
    mainRadioChanged: {
      type: Function,
    },
    editorId: {
      type: Number,
    },
    editorFocus: {
      type: Function,
    },
    editorBlur: {
      type: Function,
    },
    dataevent: {
      type: String,
    },
    map: {
      type: String,
    },
    containerName: {
      type: String,
    },
    source: {
      type: String,
    },
  },
  methods: {
    setMassCheckFlag(affectedObj, newFlag) {
      console.log('we will set flag');
      affectedObj.forEach((qnsIdx) => {
        const qnsItem = this.formData[qnsIdx];
        console.log(qnsItem);
        if (!qnsItem) return;
        const { type, options } = qnsItem;

        // checkbox, if to check, set answer to all options.values
        // else set answer to []
        let newAnswer;
        if (type === 'check') {
          if (newFlag) {
            newAnswer = options.map(opt => opt.value);
          } else {
            newAnswer = [];
          }
        }
        // qnsItem.answer = newAnswer;
        this.updateTypingFormData(newAnswer, qnsIdx);
        console.log(`will set ${qnsIdx} answer to flag: ${newFlag} `, newAnswer);
      });
      this.tick += 1;
    },
    handleDigitalSignatureAuthorise(user, qns) {
      this.digitalSignedUser = user || '';
      const targetElement = this.formData[qns].map;
      this.updateTypingFormData(this.digitalSignedUser.name, targetElement);
      document.getElementById(`${targetElement}input`).value = this.formData[targetElement].answer;
      this.$root.$emit('bv::hide::modal', `${qns}modal`);
    },
    formatResults(res) {
      if (!res || res === '') return res;
      const output = res.split('\\.br\\').join('<br>');
      return output;
    },
    /**
     * @function handleEvent
     * @event click
     * @param event Is event from click event
     * @var eventValue is the function name
     * @var mapId is the taget qns ID
     * */
    handleEvent(event) {
      const eventValue = event.target.getAttribute('dataevent');
      const mapId = event.target.getAttribute('mapid');
      if (eventValue) this[eventValue](mapId);
    },
    searchQuery(queryString, cb, arg) {
      const q = String(queryString).trim();
      if (q.length === 0) {
        cb([]);
      } else if (arg === 'icd10') {
        const fields = 'class_code,preferred_label_english,preferred_label_chinese,is_heading';

        api.get(`/icd10?qs=${q}&fields=${fields}`)
          .then(res => res.json())
          .then((data) => {
            const params = data.map(link => ({ value: this.icd10Representation(link), link }));
            cb(params);
          });
      } else if (arg === 'providers') {
        const fields = 'name, role, licenseNumber';
        api.get(`/provider/search?qs=${q}&fields=${fields}`)
          .then(res => res.json())
          .then((data) => {
            const params = data.map(link => ({ value: link.name, link }));
            cb(params);
          });
      } else if (arg === 'inventory') {
        const fields = 'name';
        api.get(`/inventory?qs=${q}&fields=${fields}`)
          .then(res => res.json())
          .then((data) => {
            const params = data.map(link => ({ value: link.name, link }));
            cb(params);
          });
      }
    },
    updateSearchLink(searchResult, linkTo) {
      const [field, qnsIdx] = linkTo.split(',');
      if (!field || !qnsIdx || !this.formData[qnsIdx]) return false;
      const { link } = searchResult;
      if (!link || link[field] === undefined || link[field] === null) return false;
      this.updateTypingFormData(link[field], qnsIdx);
      return true;
    },
    icd10Representation(item) {
      if (item.class_code.indexOf('-') > -1) {
        return '';
      }
      return `${item.class_code} - ${item.preferred_label_english} ${item.preferred_label_chinese ? `- ${item.preferred_label_chinese}` : ''}`;
    },
    providersRepresentation(item) {
      if (item.name.indexOf('-') > -1) {
        return '';
      }
      return `${item.name} - ${item.role}`;
    },
    clear(map) {
      document.getElementById(`${map}input`).value = '';
      this.updateTypingFormData('', map);
    },
    recordTime(map) {
      console.log('map ', map);
      this.updateTypingFormData(moment(), map);
      document.getElementById(`${map}input`).value = this.parseDateValue(new Date(), this.formData[map].dateFormat);
    },
    providerOptions() {
      const fields = 'name, role';
      api.get(`/provider?fields=${fields}`)
        .then(res => res.json())
        .then(data => data.map(
          link => ((link.role === 'provider') ? this.providersDropDownOptions.push({ value: link.names, text: link.name })
            : this.therapistsDropDownOptions.push({ value: link.names, text: link.name })),
        ));
    },
    getClass(formData, i, qns) {
      let className = '';
      if (formData[qns].display === 'hide') className += 'd-none ';
      if (formData[qns].required) className += 'col-form-label-required ';
      if (formData[qns].class) className += `${formData.class} `;
      if (i === formData.length - 1) className += 'mb-0';
      return className;
    },
    updateTick(answer, qns) {
      this.tick += 1;
      this.updateTypingFormData(answer, qns);
    },
    setLinkedObjectAnswer(linkedQnsIds, value) {
      linkedQnsIds.forEach((qnsIdx) => {
        const target = this.formData[qnsIdx];
        if (!target || _.isEmpty(target)) return;
        const answer = value;
        if (target.type === 'misc-gdcalc') {
          const newAnswer = _.cloneDeep(target.answer);
          const lmpEDDDateIndex = 1;
          newAnswer[lmpEDDDateIndex] = moment(new Date(value)).add(280, 'days');
          this.updateTypingFormData(newAnswer, qnsIdx);
        } else {
          this.updateTypingFormData(answer, qnsIdx);
        }
      });
      this.tick += 1;
    },
  },
  created() {
    let selectPopulateKeyExists = false;
    this.formDataOrder.forEach((element) => {
      if (!this.formData || !this.formData[element]) return;
      if (Object.keys(this.formData[element]).includes('populateKey') && this.formData[element].type === 'select') {
        selectPopulateKeyExists = true;
      }
    });

    if (selectPopulateKeyExists) this.providerOptions();
  },
};
</script>

<style lang="scss">
#fullForm .el-autocomplete{
  width: 100%;
}
#fullForm button.form-btn {
  width: 100%;
}
#fullForm .col-form-label-required legend:after {
  color: red;
  content: " *";
}
#fullForm .note-form-inner {
  padding: 10px;
  &.normal {
    .row {
      margin: 0;
    }
    .formDataElement {
      padding-left: 0;
      padding-right: 0;
      :disabled {
        input[type="date"] {
          &::-webkit-calendar-picker-indicator {
            width: 0px;
            padding: 0px;
            margin: 0px;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" src="../components/NoteEditorWidget/editor.scss"></style>
