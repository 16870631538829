/* eslint-disable no-param-reassign */
import phq9Service from '@/services/phq9.service';

import {
  ADD_RECORD_TIME,
  SET_RECORD_TIME,
  ADD_PATIENT_HEALTH_QUESTIONNAIRE,
  SET_PATIENT_HEALTH_QUESTIONNAIRE,
} from './type.mutation';

export const state = {
  recordedDateTime: [],
  patientHealthQuestionnaires: {},
};

export const mutations = {
  [SET_RECORD_TIME](_state, dateTime) {
    _state.recordedDateTime = dateTime;
  },
  [ADD_RECORD_TIME](_state, dateTime) {
    _state.recordedDateTime.push(dateTime);
  },
  [ADD_PATIENT_HEALTH_QUESTIONNAIRE](_state, payload) {
    _state.patientHealthQuestionnaires = { ..._state.patientHealthQuestionnaires, ...payload };
  },
  [SET_PATIENT_HEALTH_QUESTIONNAIRE](_state, payload) {
    _state.patientHealthQuestionnaires = payload;
  },
};

export const actions = {
  recordPHQ9({ commit }, payload) {
    return phq9Service.create(payload)
      .then((response) => {
        commit(ADD_RECORD_TIME, payload.recordTime);
        commit(ADD_PATIENT_HEALTH_QUESTIONNAIRE, payload.patientHealthQuestionnaires);
        return response.data;
      });
  },
  fetchPHQ9({ commit }, params = {}) {
    return phq9Service.list(params)
      .then((response) => {
        commit(SET_RECORD_TIME, response.data.recordedDateTime);
        commit(SET_PATIENT_HEALTH_QUESTIONNAIRE, response.data.patientHealthQuestionnaires);
        return response.data;
      });
  },
  page({ commit }, payload) {
    commit(SET_RECORD_TIME, payload);
  },
};

export const getters = {
  recordedDateTime: _state => _state.recordedDateTime,
  patientHealthQuestionnairesData: _state => _state.patientHealthQuestionnaires,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
