import _ from 'lodash';

import patientService from '@/services/patient.service';
import otBookingService from '@/services/otbooking.service';

import { userHasAccessToFn } from '@/helpers/acl';

import {
  SET_ALL_PATIENTS,
  SET_PATIENT,
  UPDATE_PATIENT,
  SET_QUESTIONNAIRE,
  SET_ADULTFORM,
  SET_CHILDFORM,
  SET_VITALS,
  UPDATE_VITALS,
  SET_TAGS,
  UPDATE_TAGS,
  UPDATE_FORM_VALIDITY,
  SET_PRINT,
  SET_PROFILE_PIC,
  SET_SELECTED_OT,
  SET_SLIDEBAR_OPEN,
  SET_COMPILED_REPORT_PRINT,
  SET_INTEGRATION_REPORT_PRINT,
} from './type.mutation';

const state = {
  all: [],
  selectedPatient: {
    corpInsurance: [],
    bgrimmHQ: {
      pregnant: '',
      hasReceipt: null,
    },
    bgrimmConsent: {
      photographed: null,
      disclosed: '',
      disclosedOthers: '',
      finRepPatientSig: '',
      finRepWitness1Sig: '',
      finRepDate: '',
      finRepWitness2Sig: '',
      interpreterStatement: '',
      interpreterName: '',
      interpreterSig: '',
      patientRepSig: '',
      patientRepRel: '',
      patientRepSigDate: '',
      patientRepID: '',
      patientRepContact: '',
      patientNotConsentReason: '',
    },
  },
  bgrimmfinRepPatientSig: '',
  bgrimmfinRepWitness1Sig: '',
  bgrimmfinRepWitness2Sig: '',
  bgrimmInterpreterSig: '',
  bgrimmPatientRepSig: '',
  bgrimmPatientSig: '',
  bgrimmAuthorisedPersonSig: '',
  vitals: [],
  tags: [],
  formValid: false,
  print: {},
  selectedOT: null,
  slidebarOpened: true,
  compiledReportPrint: {},
  integrationReportPrint: {},
};

/* eslint-disable no-param-reassign */
const mutations = {
  [SET_COMPILED_REPORT_PRINT](_state, payload) {
    _state.compiledReportPrint = payload;
  },
  [SET_INTEGRATION_REPORT_PRINT](_state, payload) {
    _state.integrationReportPrint = payload;
  },
  [SET_ALL_PATIENTS](_state, payload) {
    _state.all = payload;
  },
  [SET_PATIENT](_state, payload) {
    const patientData = { ...payload };
    const toReplace = [
      'bgrimmPatientSigURL',
      'bgrimmAuthorisedPersonSigURL',
    ];
    // FIXME: this is bad
    toReplace.forEach((field) => {
      const url = payload[field];
      if (url) {
        const urlObject = new URL(url);
        if (urlObject && urlObject.hostname && urlObject.hostname.indexOf('amazonaws') === -1) {
          patientData[field] = `${window.location.origin}/api/${payload[field.replace('URL', '')]}`;
        }
      }
    });
    _state.selectedPatient = patientData;
  },
  [UPDATE_PATIENT](_state, payload) {
    _state.selectedPatient = { ..._state.selectedPatient, [payload.key]: payload.value };
  },
  [SET_QUESTIONNAIRE](_state, payload) {
    _state.selectedPatient.formData = { ..._state.selectedPatient.formData, ...payload };
  },
  [SET_ADULTFORM](_state, payload) {
    _state.selectedPatient.formData = { ..._state.selectedPatient.formData, ...payload };
  },
  [SET_CHILDFORM](_state, payload) {
    _state.selectedPatient.formData = { ..._state.selectedPatient.formData, ...payload };
  },
  [UPDATE_VITALS](_state, payload) {
    _state.vitals = [..._state.vitals, payload];
  },
  [SET_VITALS](_state, payload) {
    _state.vitals = payload;
  },
  [SET_TAGS](_state, payload) {
    _state.tags = payload;
  },
  [UPDATE_TAGS](_state, payload) {
    _state.tags = [...new Set([..._state.tags, ...payload])];
  },
  [UPDATE_FORM_VALIDITY](_state, payload) {
    _state.formValid = payload;
  },
  [SET_PRINT](_state, payload) {
    _state.print = payload;
  },
  [SET_PROFILE_PIC](_state, payload) {
    _state.selectedPatient = {
      ...(_state.selectedPatient),
      profilePicKey: payload.profilePicKey || '',
      profilePicURL: payload.profilePicURL || '',
    };
  },
  [SET_SELECTED_OT](_state, payload) {
    _state.selectedOT = payload ? _.cloneDeep(payload) : null;
  },
  [SET_SLIDEBAR_OPEN](_state, flag) {
    _state.slidebarOpened = flag;
  },
};

const actions = {
  // eslint-disable-next-line no-unused-vars
  socket_otbookingUpdated({ dispatch, commit, state: _state }, payload) {
    if (_state.selectedOT && _state.selectedOT._id === payload._id) {
      commit(SET_SELECTED_OT, payload);
    }
    // dispatch('resortIPDTasks');
  },
  // TODO: -- martin -- need to clean this up as audit logs and other places are calling this too
  fetchAllPatients({ commit }, param = {}) {
    return patientService.fetchAll(param)
      .then((response) => {
        commit(SET_ALL_PATIENTS, response.data);
      });
  },
  resetAll({ commit }) {
    commit(SET_ALL_PATIENTS, []);
  },
  setSelectedPatient({ commit }, payload) {
    commit(SET_PATIENT, payload);
  },
  setPatient({ commit }, payload) {
    commit(UPDATE_PATIENT, payload);
  },
  setSelectedOT({ commit }, payload) {
    if (!payload) {
      return commit(SET_SELECTED_OT, null);
    }
    return otBookingService.getById(payload)
      .then((response) => {
        const ot = (response.success) ? response.data : null;
        return commit(SET_SELECTED_OT, ot);
      })
      .catch(() => commit(SET_SELECTED_OT, {}));
  },
  setQuestionnaire({ commit }, payload) {
    commit(SET_QUESTIONNAIRE, payload);
  },
  setAdultRegistration({ commit }, payload) {
    commit(SET_ADULTFORM, payload);
  },
  setChildRegistration({ commit }, payload) {
    commit(SET_CHILDFORM, payload);
  },
  // eslint-disable-next-line no-shadow
  createNewPatient({ commit }, newPatient) {
    commit('setLoading', true, { root: true });
    return patientService.createNew(newPatient)
      .then((response) => {
        if (response.ok) {
          commit(SET_PATIENT, response.data);
          if (response.data.tags && response.data.tags.length > 0) {
            commit(UPDATE_TAGS, response.data.tags);
          }
        }
        commit('setLoading', false, { root: true });
        return response;
      });
  },
  createNewPatientwithSignatures({ commit }, { id, signatures }) {
    commit('setLoading', true, { root: true });
    return patientService.createWithSignatures(id, signatures);
  },
  validatePatinetMobile({ commit }, payload) {
    commit('setLoading', true, { root: true });
    return patientService.validatePatinetMobile(payload)
      .then((response) => {
        commit('setLoading', false, { root: true });
        return response;
      });
  },
  // eslint-disable-next-line no-shadow
  updatePatient({ commit }, updatePatient) {
    commit('setLoading', true, { root: true });
    return patientService.updatePatient(updatePatient._id, updatePatient)
      .then((response) => {
        if (response.ok) {
          commit(SET_PATIENT, response.data);
          if (response.data.tags && response.data.tags.length > 0) {
            commit(UPDATE_TAGS, response.data.tags);
          }
        }
        commit('setLoading', false, { root: true });
        return response;
      });
  },
  updateEmployeePatient({ commit }, updatePatient) {
    commit('setLoading', true, { root: true });
    return patientService.updateEmployeePatient(updatePatient._id, updatePatient)
      .then((response) => {
        if (response.ok) {
          commit(SET_PATIENT, response.data);
          if (response.data.tags && response.data.tags.length > 0) {
            commit(UPDATE_TAGS, response.data.tags);
          }
        }
        commit('setLoading', false, { root: true });
        return response;
      });
  },
  patchPatient({ commit }, updatePatient) {
    commit('setLoading', true, { root: true });
    const { _id } = updatePatient;
    delete updatePatient._id;

    return patientService.patchPatient(_id, updatePatient)
      .then((response) => {
        if (response.ok) {
          commit(SET_PATIENT, response.data);
          if (response.data.tags && response.data.tags.length > 0) {
            commit(UPDATE_TAGS, response.data.tags);
          }
        }
        commit('setLoading', false, { root: true });
        return response;
      });
  },
  getPatientVitals({ commit }, patientId) {
    return patientService.getPatientVitals(patientId)
      .then((response) => {
        if (response.ok) {
          commit(SET_VITALS, response.data);
        } else {
          // Clear the vitals so that the user will not see stale data from another patient
          commit(SET_VITALS, []);
        }
        return response;
      }, (error) => {
        // Clear the vitals so that the user will not see stale data from another patient
        commit(SET_VITALS, []);
        return error;
      });
  },
  createNewVitals({ commit }, newVital) {
    commit('setLoading', true, { root: true });
    return patientService.createVital(newVital)
      .then((response) => {
        if (response.ok) {
          commit(UPDATE_VITALS, response.data);
        }
        commit('setLoading', false, { root: true });
        return response;
      });
  },
  getTags({ commit }) {
    commit('setLoading', true, { root: true });
    return patientService.getTags()
      .then((response) => {
        if (response.ok) {
          commit(UPDATE_TAGS, response.data);
        }
        commit('setLoading', false, { root: true });
        return response;
      });
  },
  updateFormValidity({ commit }, payload) {
    commit(UPDATE_FORM_VALIDITY, payload);
  },
  deletePatient({ commit }, id) {
    commit('setLoading', true, { root: true });
    return patientService.delete(id)
      .then((response) => {
        if (response.ok) {
          commit(SET_PATIENT, {});
        }
        commit('setLoading', false, { root: true });
        return response;
      });
  },
  bulkDeletePatient({ commit }, data) {
    commit('setLoading', true, { root: true });
    return patientService.bulkDelete(data)
      .then((response) => {
        commit('setLoading', false, { root: true });
        return response;
      });
  },
  getPatient({ commit }, id) {
    // console.log('patient action getPatient...');
    commit('setLoading', true, { root: true });
    return patientService.getPatient(id)
      .then((response) => {
        if (response.ok) {
          commit(SET_PATIENT, response.data);

          if(userHasAccessToFn('occupationalService_list')){
            patientService.getEmployeeAllergyHistory(id).then(res => {
              if(res.ok){
                return res.json()
              }
            }).then(data => {
              let allergyTypeList = ['medicine', 'food']

              allergyTypeList.forEach(type => {
                if (_.has(data,type) && data[type]) {
                  commit(UPDATE_PATIENT, {
                    key: `${type}Allergies`,
                    value: data[type === 'medicine' ? 'drug' : type]
                  });
                  commit(UPDATE_PATIENT, {
                    key: `${type === 'medicine' ? 'drug' : type}AllergiesSelect`,
                    value: 'Yes'
                  });
                }
              })
            });
          }
        }
        
        commit('setLoading', false, { root: true });
        return response;
      });
  },
  updateProfilePic({ commit }, { patient, image }) {
    return patientService.updatePatientProfilePic(patient._id, image)
      .then((response) => {
        if (response.ok) {
          commit(SET_PROFILE_PIC, response.data);
        }
        return response;
      });
  },
  setSlidebarOpened({ commit }, flag) {
    commit(SET_SLIDEBAR_OPEN, flag);
  },
};

const getters = {
  all: _state => _state.all,
  patientObjects: _state => _state.all
    .reduce((final, patient) => ({
      ...final,
      [patient._id]: patient,
    }), {}),
  selectedPatient: _state => _state.selectedPatient,
  vitals: _state => _state.vitals,
  tags: _state => _state.tags,
  formValid: _state => _state.formValid,
  selectedOT: _state => _state.selectedOT,
  slidebarOpened: _state => _state.slidebarOpened,
  compiledReportPrint: _state => _state.compiledReportPrint,
  integrationReportPrint: _state => _state.integrationReportPrint,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
