import moment from 'moment';

export default {
  methods: {
    formatPatient(value) {
      return value ? `${value.name} - ${(value.givenIdPrefix || '') + value.givenId}` : '';
    },
    formatPatientId(value) {
      return value ? `${value.givenIdPrefix || ''}${value.givenId}`: ''
    },
    formatAge(momentDOB) {
      let age = 'unknown age';
      if (!momentDOB.isValid()) return age;
      const ageYears = moment().diff(momentDOB, 'years');
      const ageMonth = moment().diff(momentDOB, 'months');
      const ageDays = moment().diff(momentDOB, 'days');
      if (ageYears >= 1) {
        age = `${ageYears} ${this.$t('general.yrsOld')}`;
      } else if (ageMonth >= 1) {
        age = `${ageMonth} ${this.$t('general.mthsOld')}`;
      } else if (ageDays >= 0) {
        age = `${ageDays} ${this.$t('general.daysOld')}`;
      } else {
        age = 'unknown age';
      }
      return age;
    },
  },
};
