import api from './api';

export default {
  translateErrorMsg(error) {
    if (error.code === 11000) {
      error = 'Duplicate Error: Please use different information and try again.';
    }
    return error;
  },
  fetchAll(params) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);

    Object.keys(params).forEach((key) => {
      q.push(`${key}=${params[key]}`);
    });

    const url = `/order/?${q.join('&')}`;
    return api.get(url);
  },
  findLatestRate(id) {
    const url = `/order/latest-rate/${id}`;
    return api.get(url);
  },
  createNew(data) {
    return api.post('/order', data);
  },
  createNewWithImage(data, imageFile) {
    return api.multiPartPost(
      '/order',
      data,
      { picture: imageFile },
    );
  },
  updateOrder(id, data) {
    return api.put(`/order/update/${id}`, data);
  },
  updateOrderWithImage(id, data, imageFile) {
    return api.multiPartPut(
      `/order/update/${id}`,
      data,
      { picture: imageFile },
    );
  },
  updateBulkOrder(data) {
    return api.put('/order/updateBulk', data);
  },
  delete(id) {
    return api.delete(`/order/${id}`);
  },
  deleteBulk(ids) {
    return api.post('/order/deleteBulk', ids);
  },
  getOrder(id) {
    return api.get(`/order/${id}`);
  },
  count(query) {
    const params = Object.keys(query)
      .map(k => `${k}=${query[k]}`)
      .join('&');
    return api.get(`/order/count?${params}`);
  },
};
