export const BEFORE_FETCH_ALL_INVOICES = 'BEFORE_FETCH_ALL_INVOICES';
export const FETCH_ALL_INVOICES = 'FETCH_ALL_INVOICES';
export const SET_INVOICE = 'SET_INVOICE';
export const ADD_LINE_ITEM = 'ADD_LINE_ITEM';
export const ADD_LETTER = 'ADD_LETTER';
export const EDIT_LETTER = 'editLetter';
export const REMOVE_LINE_ITEM = 'REMOVE_LINE_ITEM';
export const UPDATE_ICD10 = 'UPDATE_ICD10';
export const ADD_ICD10 = 'ADD_ICD10';
export const REMOVE_ICD10 = 'REMOVE_ICD10';
export const REMOVE_LETTER = 'REMOVE_LETTER';
export const UPDATE_INVOICE = 'UPDATE_INVOICE';
export const SAVE_INVOICE = 'SAVE_INVOICE';
export const SAVE_INVOICE_PROVIDER = 'SAVE_INVOICE_PROVIDER';
export const PATCH_INVOICE = 'PATCH_INVOICE';
export const SET_PRINT = 'SET_PRINT';
export const SET_PRINT_PACKAGE_TRANSFER_RECEIPT = 'SET_PRINT_PACKAGE_TRANSFER_RECEIPT';
export const SET_RECENT_CHANGE = 'SET_RECENT_CHANGE';
export const VOID_INVOICE = 'VOID_INVOICE';
export const SET_BASE_VERSION = 'SET_BASE_VERSION';
export const SET_SELECTED_CORP_INV = 'SET_SELECTED_CORP_INV';
export const UPDATE_INVOICE_GST = 'UPDATE_INVOICE_GST';
export const SET_STATS = 'SET_STATS';
export const PATCH_STATS = 'PATCH_STATS';
export const UNREDEEM_PACKAGE_ITEM = 'UNREDEEM_PACKAGE_ITEM';
export const UPDATE_INVOICE_GST_X_RATE = 'UPDATE_INVOICE_GST_X_RATE';
export const UPDATE_INVOICE_LOCK_STATUS = 'UPDATE_INVOICE_LOCK_STATUS';
export const SET_XERO_TOKEN = 'SET_XERO_TOKEN';