export const SET_APPOINTMENT = 'SET_APPOINTMENT';
export const UPDATE_APPOINTMENT = 'UPDATE_APPOINTMENT';
export const UPDATE_EMAIL_CONTENT = 'UPDATE_EMAIL_CONTENT';
export const UPDATE_SMS_CONTENT = 'UPDATE_SMS_CONTENT';
export const UPDATE_NOTIFY_CONFIG = 'UPDATE_NOTIFY_CONFIG';

export const SET_SCHEDULING_TEMPLATE = 'SET_SCHEDULING_TEMPLATE';
export const SET_SELECTED_SLOT = 'SET_SELECTED_SLOT';
export const SET_ALL_WEEKLY_SCHEDULE_TEMPLATES = 'SET_ALL_WEEKLY_SCHEDULE_TEMPLATES';
export const UPDATE_WEEKLY_SCHEDULE_TEMPLATE = 'UPDATE_WEEKLY_SCHEDULE_TEMPLATE';
export const PATCH_WEEKLY_SCHEDULE_TEMPLATE = 'PATCH_WEEKLY_SCHEDULE_TEMPLATE';

export const SET_ALL_WEEKLY_SCHEDULES = 'SET_ALL_WEEKLY_SCHEDULES';
export const SET_SELECTED_SCHEDULE = 'SET_SELECTED_SCHEDULE';
