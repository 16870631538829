<template>
  <div class="container-fluid">
    <div class="previewPrint">
      <div class="row">
        <div class="col">
          <integration-report-components :type="integrationReportPrint.type" :data="integrationReportPrint.data"></integration-report-components>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import IntegrationReportComponents from '@/components/Consultation/IntegrationReportComponents.vue';

const { mapGetters: mapPatientGetters } = createNamespacedHelpers('patient');
export default {
  name: 'IntegrationReport',
  components: {
    IntegrationReportComponents,
  },
  mounted() {
    setTimeout(() => {
      window.print();
    }, 2000);
  },
  computed: {
    ...mapPatientGetters({ integrationReportPrint: 'integrationReportPrint' }),
  },
};
</script>

<style scoped src="../../assets/print.css"></style>
