var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.selectedItemForPrint
        ? [
            _vm.featureFlags.bgrimm
              ? _vm._l(_vm.medicalItems, function(item) {
                  return _c(
                    "div",
                    { key: item.id },
                    [
                      _c("drug-label-component", {
                        attrs: {
                          disableAutoPrint: true,
                          printLocal: _vm.printLocal,
                          medItem: item,
                          generalConfig: _vm.general,
                          patient: _vm.print.selectedPatient
                        }
                      }),
                      _c("div", {
                        staticStyle: {
                          "page-break-after": "always",
                          "break-after": "always"
                        }
                      })
                    ],
                    1
                  )
                })
              : _vm._l(_vm.selectedItemForPrint.items, function(item) {
                  return _c(
                    "div",
                    { key: item.id },
                    [
                      _vm.featureFlags.drclinique
                        ? _c(
                            "div",
                            { staticClass: "container-fluid" },
                            [
                              _c("medical-label-dr-clinique", {
                                attrs: {
                                  disableAutoPrint: true,
                                  clinicName: _vm.general.clinicName,
                                  clinicAddress: _vm.general.address,
                                  medName: item.medicine.name,
                                  medQty: item.quantity,
                                  medStrength: item.medicine.strength,
                                  medUnit: item.medicine.unit,
                                  medDosage: item.dosage,
                                  medExpiry: (item.batch || {}).expiry,
                                  medPrecautionOne: item.precaution1,
                                  medPrecautionTwo: item.precaution2,
                                  patientName:
                                    _vm.selectedItemForPrint.patient.name,
                                  patientNric:
                                    _vm.selectedItemForPrint.patient.nric
                                }
                              })
                            ],
                            1
                          )
                        : [
                            _c(
                              "div",
                              { staticClass: "container-fluid" },
                              [
                                !item.adHocCompounding
                                  ? _c("medical-label-component", {
                                      attrs: {
                                        disableAutoPrint: true,
                                        clinicName: _vm.general.clinicName,
                                        clinicAddress: _vm.general.address,
                                        clinicNumber: _vm.general.telephone,
                                        medName: item.medicine.name,
                                        medQty: item.quantity,
                                        medStrength: item.medicine.strength,
                                        medUnit: item.medicine.unit,
                                        medDosage: item.dosage,
                                        medExpiry: (item.batch || {}).expiry,
                                        selectedBatch: item.batch,
                                        medPrecautionOne: item.precaution1,
                                        medPrecautionTwo: item.precaution2,
                                        patientName:
                                          _vm.selectedItemForPrint.patient.name,
                                        patientNric:
                                          _vm.selectedItemForPrint.patient
                                            .givenId,
                                        patientDetails:
                                          _vm.selectedItemForPrint.patient
                                      }
                                    })
                                  : _c("medical-label-component", {
                                      attrs: {
                                        disableAutoPrint: true,
                                        clinicName: _vm.general.clinicName,
                                        clinicAddress: _vm.general.address,
                                        clinicNumber: _vm.general.telephone,
                                        medName: item.adHocCompoundName,
                                        medQty: item.quantity,
                                        medStrength: item.medicine.strength,
                                        medUnit: item.adHocCompoundUnit,
                                        medDosage: item.adHocCompoundDosage,
                                        medExpiry: "",
                                        selectedBatch: item.batch,
                                        medPrecautionOne:
                                          item.adHocCompoundPrecaution1,
                                        medPrecautionTwo:
                                          item.adHocCompoundPrecaution2,
                                        patientName:
                                          _vm.selectedItemForPrint.patient.name,
                                        patientNric:
                                          _vm.selectedItemForPrint.patient
                                            .givenId,
                                        patientDetails:
                                          _vm.selectedItemForPrint.patient
                                      }
                                    })
                              ],
                              1
                            )
                          ]
                    ],
                    2
                  )
                })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }