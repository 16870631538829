<template>
  <b-dropdown-item
    v-if="userHasAccess"
    :to="to">
    <slot></slot>
  </b-dropdown-item>
</template>

<script>
import { userHasAccessToFn } from '@/helpers/acl';

export default {
  name: 'AclNavItemDropdownItem',
  props: {
    to: [String, Object],
  },
  computed: {
    userHasAccess() {
      let hasAccess = true;
      const { route } = this.$router.resolve(this.to, this.$route, this.append);
      const routeLink = route.matched.find(href => href.name === route.name);
      // console.log('> ACLNAVITEM', route)
      if (routeLink && routeLink.props.default && routeLink.props.default.aclReqFunction) {
        // console.log('> ACLNAVITEM', routeLink.props.default.aclReqFunction)
        const reqFn = routeLink.props.default.aclReqFunction;
        hasAccess = userHasAccessToFn(reqFn);
      } else {
        hasAccess = true; // if no set reqFunction on router, assume it is a public link
      }
      return hasAccess;
    },
  },
};
</script>
