<template>
  <transition name="modal">
    <div class="modal-mask" :class="{ fading: fadingBg }">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-body">
            <v-icon name="spinner" pulse scale="2.0"></v-icon>
            <p v-if="htmlText.length > 0" v-html="htmlText"></p>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: 'loading',
  props: {
    show: {
      default: true,
    },
    htmlText: {
      default: '',
    },
    fadingBg: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-mask {
  &.fading {
    background-color: rgba(255, 255, 255, 0.5);
  }
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background-color: rgba(255, 255, 255, .6);
  display: table;
  // transition: opacity .3s ease;
  color: rgba(0, 0, 0, .33);
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  background-color: transparent;

  &.isEmbedded {
    vertical-align: unset;
    margin-top: 420px;
  }
}
.modal-container {
  max-width: 75vw;
  max-height: 80vh;
  margin: 0px auto;
  padding: 10px 15px;
  background-color: transparent;
  border-radius: 2px;
  // box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
  overflow-y: auto;
  &.isEmbedded {
    margin-top: 10%;
  }
}
.modal-header {
  // border-bottom: 0;
  padding: 0px;
  margin-bottom: 20px;
  /deep/ h3 {
    // text-decoration: line-through;
    margin-top: 10px;
  }
}
.modal-body {
  font-size: 18px;
  font-weight: normal;
  margin: auto;
  text-align: center;
  -webkit-text-stroke-width: 2px;
  background-color: transparent;
}
.modal-footer {
  border-top: none;
}
.modal-default-button {
  float: right;
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.btn-close {
  float: right;
  color: #cccccc;
  background-color: transparent;
  z-index: 99;
  right: -15px;
  top: -6px;
  position: relative;
  font-weight: bolder;
  outline: none;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
