export const BEFORE_FETCH_ALL_LETTERS = 'BEFORE_FETCH_ALL_LETTERS';
export const FETCH_ALL_LETTERS = 'FETCH_ALL_LETTERS';
export const CREATE_NEW_LETTER = 'createNewLetter';
export const EDIT_LETTER = 'editLetter';

export const SET_LETTER = 'SET_LETTER';
export const REMOVE_LINE_ITEM = 'REMOVE_LINE_ITEM';
export const UPDATE_LETTER = 'UPDATE_LETTER';
export const SAVE_LETTER = 'SAVE_LETTER';
export const SET_PRINT = 'SET_PRINT';
