<template>
  <div id="main-card">
    <div id="square-box">
      <div id="header">
        <div id="header-img">
          <img
            src="../assets/makuang_logo.png"
            id="makuang-logo"
            alt="makuang-logo"
          />
        </div>
        <div id="header-text">PRESCRIPTION</div>
      </div>
      <div id="header-2">
        <div class="patient dateOfPrint">
          Date of Print: <u>{{ printPayload.dateOfPrint }}</u>
        </div>
        <div class="patient visitBranch">
          Visit Branch : <u>{{ printPayload.visitBranch }}</u>
        </div>
      </div>
      <div id="content-inside-square-box">
        <div class="patient">
          <strong> (Name) </strong> <u>{{ printPayload.name }}</u>
          <strong> (NRIC/Passport) </strong>
          <u>{{ printPayload.nric }}</u>
          <strong> (D.O.B.) </strong>
          <u>{{ printPayload.dateOfBirth }}</u>
          has been prescribed the following herbal medication on
          {{ printPayload.dateOfPrescription }}:
        </div>
        <div class="blank-empty-space-2"></div>
        <vue-good-table
          :columns="columns"
          :rows="printPayload.items"
          id="prescription-table"
        />
        <div class="blank-empty-space-2"></div>
        <div id="weight">
          Total Weight: {{ printPayload.weightPerPacket }} g per packet
        </div>
        <div class="blank-empty-space-2"></div>
        <div>
          * This prescription should be consumed under the advice of a licensed
          TCM physician. Ma Kuang does not encourage the purchase of this herbal
          prescription from external sources other than our clinics.
        </div>
        <div>Remarks:</div>
        <div id="blank-square-box"></div>
        <div>
          Prescribed By: (Name of TCM physician)
          <u>{{ printPayload.nameOfPrescriber }}</u>
        </div>
      </div>
    </div>
    <b-button
      class="btn print-button"
      variant="primary"
      @click.prevent="printForm"
      >Print Prescription</b-button
    >
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import printJS from 'print-js';

export default {
  name: 'Prescription',
  created() {
    // 1. Check if the printPayload is empty
    const printPayload = this.$store.state.tcmConsultation
      .tcmPrescriptionPrintPayload;

    // 2. If the printPayload is empty, redirect them to the home page.
    if (!printPayload) {
      this.$router.push('/patient/list');
    }
  },
  computed: {
    ...mapGetters('tcmConsultation', ['printPayload']),
  },
  data() {
    return {
      columns: [
        {
          label: 'CODE',
          field: 'givenId',
          sortable: false,
          tdClass: 'text-center',
        },
        {
          label: 'MEDICINE-CHI',
          field: 'chineseName',
          sortable: false,
          tdClass: 'text-center',
        },
        {
          label: 'MEDICINE',
          field: 'name',
          sortable: false,
          tdClass: 'text-center',
        },
        {
          label: 'QUANTITY',
          field: 'quantity',
          sortable: false,
          tdClass: 'text-center',
        },
        {
          label: 'NOTE',
          field: 'description',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
    };
  },
  methods: {
    printForm() {
      printJS('square-box', 'html');
    },
  },
};
</script>

<style scoped>
/* Hide the print screen if the screen is too small */
@media screen and (min-width: 768px) {
  .blank-empty-space-2 {
    height: 0.5cm;
  }

  #blank-square-box {
    height: 10cm;
  }

  #content-inside-square-box {
    margin: 2%;
  }

  #header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1%;
    max-height: 20%;
  }

  #header-2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 2%;
    margin-right: 2%;
  }

  #header-text {
    width: 50%;
    justify-content: start;
    text-align: start;
  }

  #main-card {
    display: flex;
    font-size: x-large;
    flex-direction: column;
    margin: 0% 5%;
  }

  #makuang-logo {
    max-width: 30%;
    margin: 1%;
  }
  .patient {
    margin-bottom: 1%;
  }

  #prescription-table {
    margin-top: 2%;
    margin-bottom: 2%;
    width: fit-content;
  }

  .print-button {
    width: fit-content;
    margin-top: 2%;
    padding: 0.5%;
  }
  #square-box {
    border: 1px solid black;
  }

  #weight {
    margin-top: 2%;
    margin-bottom: 2%;
  }
}

@media screen and (max-width: 767px) {
  #main-card {
    visibility: hidden;
  }
}
</style>
