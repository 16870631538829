import NursingRestraintService from '@/services/nursingrestraint.service';

import dummyRestrainers from './defaults/dummy.json'
import dummyProviders from './defaults/provider.json'
import filterDefaults from './defaults/filters.json'


import {
  SET_ALL_RESTRAINERS,
  ADD_RESTRAINER,
  SET_FILTERS,
} from './type.mutation';

const state = {
  providers: dummyProviders,
  restrainers: dummyRestrainers,
  filters: filterDefaults,
};

/* eslint-disable no-param-reassign */
const mutations = {
  [SET_ALL_RESTRAINERS](_state, payload) {
    _state.restrainers = [...payload];
  },
  [ADD_RESTRAINER](_state, payload) {
    _state.restrainers = [
      payload,
      ...(_state.restrainers),
    ];
  },
  [SET_FILTERS](_state, payload) {
    _state.filters = { ...payload };
  },
};

const actions = {
  loadRestrainers({ commit }, param = {}) {
    // TODO: chan - should get restrainers on backend
    return NursingRestraintService.fetchAll(param)
      .then((response) => {
        commit(SET_ALL_RESTRAINERS, response.data);
        // commit(SET_PROVIDER_BY_USERID, response.data);
        console.log(response);
        return response.data;
      });
  },
  addRestrainer({ commit }, payload) {
    payload.entryBy = payload.recordedBy.id || payload.recordedBy._id;
    return NursingRestraintService.create(payload)
      .then((response) => {
        if (response.success) {
          commit(ADD_RESTRAINER, response.data);
        }
        commit('setLoading', false, { root: true });
        return response;
      });
  },
  setFilters({ commit }, payload) {
    commit(SET_FILTERS, payload);
  },
  resetAll({ commit }) {
    commit(SET_ALL_RESTRAINERS, dummyRestrainers);
    commit(SET_FILTERS, filterDefaults);
  },
};

const getters = {
  providerOptions: _state => _state.providers.map(provider => ({
    value: provider._id,
    text: provider.name,
  })),
  allRestrainers: _state => _state.restrainers,
  currentRestrainers: _state => _state.restrainers.slice(
    (_state.filters.page - 1) * _state.filters.max_docs, // start
    ((_state.filters.page - 1) * _state.filters.max_docs) + _state.filters.max_docs, // records
  ).reverse(),
  lastPage: _state => Math.ceil((_state.restrainers.length || 0) / _state.filters.max_docs),
  filters: _state => _state.filters,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
