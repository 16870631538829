export const userHasAccessToFn = (fnID) => {
  let hasAccess = true;
  if (fnID) {
    const userAccessFNIDs = localStorage.getItem('acl__fnids');
    if (userAccessFNIDs) {
      const userAccess = JSON.parse(userAccessFNIDs);
      hasAccess = userAccess.includes(fnID);
    } else {
      hasAccess = false; // user has empty function access
    }
  } else {
    hasAccess = true; // no function requirement passed, assume allow
  }
  return hasAccess;
};

export const userHasSomeAccessToFns = (fnIDs = []) => {
  let hasAccess = true;
  if (fnIDs.length > 0) {
    const userAccessFNIDs = localStorage.getItem('acl__fnids');
    if (userAccessFNIDs) {
      const userAccess = JSON.parse(userAccessFNIDs);
      if (fnIDs.some(reqAccess => userAccess.includes(reqAccess))) {
        hasAccess = true;
      } else {
        hasAccess = false; // user access has none of the required functions
      }
    } else {
      hasAccess = false; // empty user access functions
    }
  } else {
    hasAccess = true; // no passed required functions
  }
  return hasAccess;
};

export const userHasAllAccessToFns = (fnIDs = []) => {
  let hasAccess = true;
  if (fnIDs.length > 0) {
    const userAccessFNIDs = localStorage.getItem('acl__fnids');
    if (userAccessFNIDs) {
      const userAccess = JSON.parse(userAccessFNIDs);
      if (fnIDs.every(reqAccess => userAccess.includes(reqAccess))) {
        hasAccess = true;
      } else {
        hasAccess = false; // user does not meet all of the required functions
      }
    } else {
      hasAccess = false; // empty user access functions
    }
  } else {
    hasAccess = true; // no passed required functions
  }
  return hasAccess;
};

export const userHasAccessToChildFn = (childFnId) => {
  let hasAccess = true;
  if (childFnId) {
    const userAccessChildrenFNIDs = localStorage.getItem('acl__childrenFnIds');
    if (userAccessChildrenFNIDs) {
      const userAccess = JSON.parse(userAccessChildrenFNIDs);
      hasAccess = userAccess[childFnId];
    } else {
      hasAccess = false; // user has empty function access
    }
  } else {
    hasAccess = true; // no function requirement passed, assume allow
  }
  return hasAccess;
};
