import ipdTaskService from '@/services/ipdtask.service';
import admissionService from '@/services/admission.service';
import constant from '@/services/constant';

import {
  SET_ADMISSIONS,
  SET_ADMISSION,
  DELETE_ADMISSION,
  SET_SOCKET_ONLINE,
  SET_SOCKET_OFFLINE,
  RESET
} from './type.mutation';

const state = {
  admissions: {},
  online: false,
};

/* eslint-disable no-param-reassign */
const mutations = {
  [RESET](_state) {
    _state.admissions = {};
    _state.online = false;
  },
  [SET_SOCKET_ONLINE](_state) {
    _state.online = true;
  },
  [SET_SOCKET_OFFLINE](_state) {
    _state.online = false;
  },
  [SET_ADMISSIONS](_state, taskList) {
    _state.admissions = taskList
      .reduce((acc, task) => ({
        ...acc,
        [task._id]: task,
      }), {});
  },
  [SET_ADMISSION](_state, admission) {
    _state.admissions = {
      ..._state.admissions,
      [admission._id]: admission,
    };
  },
  [DELETE_ADMISSION](_state, admissionID) {
    const { [admissionID]: deleted, ...admissions } = _state.admissions;
    _state.admissions = { ...admissions };
  },
};

const actions = {
  socket_admissionUpdated({ dispatch, state: _state }, admissionID) {
    if (_state.online) {
      dispatch('loadNursingAdmission', { id: admissionID });
    }
  },
  socket_admissionNew({ dispatch, state: _state }, admissionID) {
    if (_state.online) {
      dispatch('loadNursingAdmission', { id: admissionID });
    }
  },
  socket_admissionDeleted({ commit, state: _state }, admissionID) {
    if (_state.online) {
      commit(DELETE_ADMISSION, admissionID);
    }
  },
  reset({ commit }) {
    commit(RESET);
  },
  setSocketOnline({ commit }) {
    commit(SET_SOCKET_ONLINE);
  },
  setSocketOffline({ commit }) {
    commit(SET_SOCKET_OFFLINE);
  },
  loadNursingAdmission({ commit }, { id }) {
    return admissionService.nursingShow(id, {}).then((response) => {
      const admit = response.data;
      const mappedData = {
        _id: admit._id,
        area: admit.area ? admit.area.name : '',
        room: admit.room ? admit.room.name : '',
        bed: admit.bed ? (admit.room.beds.find(b => b.uuid === admit.bed)).name || '' : '',
        provider: admit.provider ? admit.provider.name : '',
        picture: admit.patient ? admit.patient.profilePicURL : '',
        patientID: admit.patient ? admit.patient._id : '',
        name: admit.patient ? admit.patient.name : '',
        status: constant.ADMISSION_STATUS_LABEL[admit.admissionStatus],
        tags: admit.patient ? admit.patient.tags : [],
        tasks: admit.tasks ? admit.tasks.filter(task => task.status === 'PENDING' && !task.isMedicationExecution) : [],
        medextasks: admit.tasks ? admit.tasks.filter(task => task.status === 'PENDING' && task.isMedicationExecution) : [],
        admissionInvoice: admit.admissionInvoice || { items: [] },
      };
      commit(SET_ADMISSION, mappedData);
    }).catch(() => {
      commit(SET_ADMISSION, {});
    });
  },
  loadNursingAdmissions({ commit }, params) {
    return admissionService.nursingList(params).then((response) => {
      const mappedData = response.data.map(admit => ({
        _id: admit._id,
        area: admit.area ? admit.area.name : '',
        room: admit.room ? admit.room.name : '',
        bed: admit.bed ? (admit.room.beds.find(b => b.uuid === admit.bed)).name || '' : '',
        provider: admit.provider ? admit.provider.name : '',
        picture: admit.patient ? admit.patient.profilePicURL : '',
        patientID: admit.patient ? admit.patient._id : '',
        name: admit.patient ? admit.patient.name : '',
        status: constant.ADMISSION_STATUS_LABEL[admit.admissionStatus],
        tags: admit.patient ? admit.patient.tags : [],
        tasks: admit.tasks ? admit.tasks.filter(task => task.status === 'PENDING' && !task.isMedicationExecution) : [],
        medextasks: admit.tasks ? admit.tasks.filter(task => task.status === 'PENDING' && task.isMedicationExecution) : [],
        admissionInvoice: admit.admissionInvoice || { items: [] },
      }));
      commit(SET_ADMISSIONS, mappedData);
    }).catch(() => {
      commit(SET_ADMISSIONS, []);
    });
  },
};

const getters = {
  admissions: _state => _state.admissions,
  admissionList: _state => Object.values(_state.admissions),
  // pendingIPDTasks: _state => Object.keys(_state.admissions)
  //   .map(key => _state.admissions[key])
  //   .filter(task => task.status === 'PENDING')
  //   .reduce((acc, task) => ({
  //     ...acc,
  //     [task._id]: task,
  //   }), {}),
  // completedIPDTasks: _state => Object.keys(_state.admissions)
  //   .map(key => _state.admissions[key])
  //   .filter(task => task.status === 'COMPLETED')
  //   .reduce((acc, task) => ({
  //     ...acc,
  //     [task._id]: task,
  //   }), {}),
  // canceledIPDTasks: _state => Object.keys(_state.admissions)
  //   .map(key => _state.admissions[key])
  //   .filter(task => task.status === 'CANCELED')
  //   .reduce((acc, task) => ({
  //     ...acc,
  //     [task._id]: task,
  //   }), {}),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
