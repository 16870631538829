

<template>
    <div class="row">
        <div class="col-12">
              <vue-signature-pad
                class="signature-canvas"
                :width="width"
                :height="height"
                :ref="id"
                :saveType="saveType"
                :options="{onBegin: () => {$refs[id].resizeCanvas()}}"></vue-signature-pad>
                <div class="signature-context">
                  <slot></slot>
                </div>
        </div>
        <div class="col-12 signature-btns" v-if="!hideSign">
            <button class="btn btn-info" :disabled="viewing"  @click="undo($event)">{{$t('general.clear')}}</button>&nbsp;
            <button class="btn btn-success"  :disabled="viewing" @click="accept($event)">{{$t('setting.accept')}}</button>
        </div>
    </div>
</template>

<script>
/**
 * Reference: https://github.com/neighborhood999/vue-signature-pad
 */


import VueSignaturePad from 'vue-signature-pad';

export default {
  name: 'VdSignaturePad',
  props: {
    width: {
      default: '500px',
    },
    height: {
      default: '500px',
    },
    id: {
      default: 'signaturePad',
    },
    hideSign: {
      default: false,
    },
    viewing: {
      default: false,
    },
    // options: {
    //   type: Object,
    //   default: () => {
    //     this.$refs.signaturePad.resizeCanvas();
    //   },
    // },
    saveType: {
      type: String,
      default: 'image/png',
    },
    disabled: { type: Boolean, default: false },
  },
  components: {
    VueSignaturePad,
  },
  methods: {
    undo(event) {
      event.preventDefault();
      this.$refs[this.id].undoSignature();
      this.$emit('undo');
    },
    accept(event) {
      event.preventDefault();
      const { isEmpty, data } = this.$refs[this.id].saveSignature();
      this.$emit('accept', { isEmpty, data });
    },
  },
};
</script>

<style lang="scss" scoped>
  .signature-context {
    &::before {
      content: '';
      display: block;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      border-top: 1px solid #E5E5E5;
    }
    width: 90%;
    padding-left: 8px;
    padding-right: 8px;
    bottom: 0;
    position: absolute;
    margin-bottom: 18px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
</style>
<style scoped scss>
  .signature-canvas {
    border: 1px solid #666;
    margin-bottom: 15px;
  }
</style>
