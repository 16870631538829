var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "container-fluid py-4",
      class: { previewPrint: _vm.preview }
    },
    [
      _c("div", { staticClass: "row mb-3" }, [
        _c("div", { staticClass: "col-12" }, [
          _vm.printLetterHeaderConfig.clinicLogo[_vm.tabIndex]
            ? _c("img", {
                style: {
                  marginLeft: _vm.logoMarginLeft + "px",
                  width: _vm.logoWidth + "px"
                },
                attrs: { src: this.companyClinicLogo }
              })
            : _vm._e()
        ])
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-6" }, [
          _vm.printLetterHeaderConfig.clinicName[_vm.tabIndex]
            ? _c("h5", [_vm._v(_vm._s(_vm.clinic.clinicName))])
            : _vm._e(),
          _vm.printLetterHeaderConfig.clinicAddress[_vm.tabIndex]
            ? _c("p", { staticClass: "mb-1 address-font" }, [
                _vm._v(_vm._s(_vm.letterAddress))
              ])
            : _vm._e(),
          _vm.printLetterHeaderConfig.clinicLocalName[_vm.tabIndex]
            ? _c("h5", [_vm._v(_vm._s(_vm.clinic.clinicLocalName))])
            : _vm._e(),
          _vm.printLetterHeaderConfig.clinicLocalAddress[_vm.tabIndex]
            ? _c("p", { staticClass: "mb-1 address-font" }, [
                _vm._v(_vm._s(_vm.clinic.clinicLocalAddress))
              ])
            : _vm._e(),
          _vm.printLetterHeaderConfig.clinicPhone[_vm.tabIndex]
            ? _c("p", { staticClass: "mb-3 address-font" }, [
                _vm._v("Tel: " + _vm._s(_vm.clinic.telephone))
              ])
            : _vm._e(),
          _vm.printLetterHeaderConfig.todayDate[_vm.tabIndex]
            ? _c("p", { staticClass: "mb-3 address-font" }, [
                _vm._v("Date: " + _vm._s(_vm._f("formatDateTime")(new Date())))
              ])
            : _vm._e(),
          _vm.printLetterHeaderConfig.todayDateBE[_vm.tabIndex]
            ? _c("p", { staticClass: "mb-3 address-font" }, [
                _vm._v(
                  "Date: " +
                    _vm._s(
                      _vm._f("formatVDDate")(new Date(), {
                        lang: "th",
                        format: "YYYY/MM/DD HH:mm A"
                      })
                    )
                )
              ])
            : _vm._e()
        ]),
        _c("div", { staticClass: "col-2" }),
        _c("div", { staticClass: "col-4" }, [
          _vm.printLetterHeaderConfig.patientName[_vm.tabIndex]
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-4 address-font" }, [
                  _vm._v("Name:")
                ]),
                _c("div", { staticClass: "col-8 address-font" }, [
                  _vm._v(_vm._s(_vm.patient.name))
                ])
              ])
            : _vm._e(),
          _vm.printLetterHeaderConfig.patientLocalName[_vm.tabIndex]
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-4 address-font" }, [
                  _vm._v("Patient Local Name:")
                ]),
                _c("div", { staticClass: "col-8 address-font" }, [
                  _vm._v(_vm._s(_vm.patient.localName))
                ])
              ])
            : _vm._e(),
          _vm.printLetterHeaderConfig.nricfin[_vm.tabIndex]
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-4 address-font" }, [
                  _vm._v("NRIC:")
                ]),
                _c("div", { staticClass: "col-8 address-font" }, [
                  _vm._v(_vm._s(_vm.patient.nric))
                ])
              ])
            : _vm._e(),
          _vm.printLetterHeaderConfig.patientAge[_vm.tabIndex]
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-4 address-font" }, [
                  _vm._v("Age:")
                ]),
                _c("div", { staticClass: "col-8 address-font" }, [
                  _vm._v(_vm._s(_vm.patient.age))
                ])
              ])
            : _vm._e(),
          _vm.printLetterHeaderConfig.patientID[_vm.tabIndex]
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-4 address-font" }, [
                  _vm._v("Clinic ID:")
                ]),
                _c("div", { staticClass: "col-8 address-font" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.patient.givenIdPrefix
                          ? _vm.patient.givenIdPrefix + _vm.patient.givenId
                          : _vm.patient.givenId
                      ) +
                      "\n        "
                  )
                ])
              ])
            : _vm._e(),
          _vm.printLetterHeaderConfig.patientDOB[_vm.tabIndex]
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-4 address-font" }, [
                  _vm._v("Date of Birth:")
                ]),
                _c("div", { staticClass: "col-8 address-font" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("formatVDDate")(_vm.patient.dateOfBirth, {
                        format: "YYYY/MM/DD",
                        lang: _vm.$i18n.locale
                      })
                    )
                  )
                ])
              ])
            : _vm._e(),
          _vm.printLetterHeaderConfig.patientSex[_vm.tabIndex]
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-4 address-font" }, [
                  _vm._v("Sex:")
                ]),
                _c("div", { staticClass: "col-8 address-font" }, [
                  _vm._v(_vm._s(_vm.patient.gender))
                ])
              ])
            : _vm._e()
        ])
      ]),
      _c("div", {
        staticClass: "mb-3",
        staticStyle: { "border-top-style": "solid" }
      }),
      _c("h3", { staticClass: "mb-3 text-center" }, [
        _c("u", [_vm._v(_vm._s(_vm.name))])
      ]),
      _vm.preview
        ? _c(
            "div",
            {
              staticClass:
                "row p-4 mb-3 d-flex justify-content-center content-box",
              on: { click: _vm.goToLetters }
            },
            [_vm._m(0)]
          )
        : _c("div", { staticClass: "mb-3" }, [
            _c("div", { domProps: { innerHTML: _vm._s(_vm.textWithBr) } })
          ]),
      _c("div", {
        staticClass: "mb-3",
        staticStyle: { "border-top-style": "solid" }
      }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-6" }, [
          _vm.printLetterFooterConfig.clinicName[_vm.tabIndex]
            ? _c("h5", { staticClass: "footer-font" }, [
                _vm._v(_vm._s(_vm.clinic.clinicName))
              ])
            : _vm._e(),
          _vm.printLetterFooterConfig.clinicAddress[_vm.tabIndex]
            ? _c("p", { staticClass: "footer-font" }, [
                _vm._v(_vm._s(_vm.letterAddress))
              ])
            : _vm._e(),
          _vm.printLetterFooterConfig.clinicLocalName[_vm.tabIndex]
            ? _c("h5", { staticClass: "footer-font" }, [
                _vm._v(_vm._s(_vm.clinic.clinicLocalName))
              ])
            : _vm._e(),
          _vm.printLetterFooterConfig.clinicLocalAddress[_vm.tabIndex]
            ? _c("p", { staticClass: "footer-font" }, [
                _vm._v(_vm._s(_vm.clinic.clinicLocalAddress))
              ])
            : _vm._e(),
          _vm.printLetterFooterConfig.clinicPhone[_vm.tabIndex]
            ? _c("p", { staticClass: "footer-font" }, [
                _vm._v("Tel: " + _vm._s(_vm.clinic.telephone))
              ])
            : _vm._e(),
          _vm.printLetterFooterConfig.todayDate[_vm.tabIndex]
            ? _c("p", { staticClass: "footer-font" }, [
                _vm._v("Date: " + _vm._s(_vm._f("formatDateTime")(new Date())))
              ])
            : _vm._e(),
          _vm.printLetterFooterConfig.todayDateBE[_vm.tabIndex]
            ? _c("p", { staticClass: "footer-font" }, [
                _vm._v(
                  "Date: " +
                    _vm._s(
                      _vm._f("formatVDDate")(new Date(), {
                        lang: "th",
                        format: "YYYY/MM/DD HH:mm A"
                      })
                    )
                )
              ])
            : _vm._e()
        ]),
        _c("div", { staticClass: "col-2" }),
        _c("div", { staticClass: "col-4" }, [
          _vm.printLetterFooterConfig.printedDateTime[_vm.tabIndex]
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-4 footer-font" }, [
                  _vm._v("Printed at:")
                ]),
                _c("div", { staticClass: "col-8 footer-font" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("formatVDDate")(new Date(), {
                        lang: _vm.$i18n.locale,
                        format: "YYYY/MM/DD HH:mm A"
                      })
                    )
                  )
                ])
              ])
            : _vm._e(),
          _vm.printLetterFooterConfig.patientName[_vm.tabIndex]
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-4 footer-font" }, [
                  _vm._v("Name:")
                ]),
                _c("div", { staticClass: "col-8 footer-font" }, [
                  _vm._v(_vm._s(_vm.patient.name))
                ])
              ])
            : _vm._e(),
          _vm.printLetterFooterConfig.patientLocalName[_vm.tabIndex]
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-4 footer-font" }, [
                  _vm._v("Patient Local Name:")
                ]),
                _c("div", { staticClass: "col-8 footer-font" }, [
                  _vm._v(_vm._s(_vm.patient.localName))
                ])
              ])
            : _vm._e(),
          _vm.printLetterFooterConfig.nricfin[_vm.tabIndex]
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-4 footer-font" }, [
                  _vm._v("NRIC:")
                ]),
                _c("div", { staticClass: "col-8 footer-font" }, [
                  _vm._v(_vm._s(_vm.patient.nric))
                ])
              ])
            : _vm._e(),
          _vm.printLetterFooterConfig.patientAge[_vm.tabIndex]
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-4 footer-font" }, [
                  _vm._v("Age:")
                ]),
                _c("div", { staticClass: "col-8 footer-font" }, [
                  _vm._v(_vm._s(_vm.patient.age))
                ])
              ])
            : _vm._e(),
          _vm.printLetterFooterConfig.patientID[_vm.tabIndex]
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-4 footer-font" }, [
                  _vm._v("Clinic ID:")
                ]),
                _c("div", { staticClass: "col-8 footer-font" }, [
                  _vm._v(_vm._s(_vm.patient.givenId))
                ])
              ])
            : _vm._e(),
          _vm.printLetterFooterConfig.patientDOB[_vm.tabIndex]
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-4 footer-font" }, [
                  _vm._v("Date of Birth:")
                ]),
                _c("div", { staticClass: "col-8 footer-font" }, [
                  _vm._v(_vm._s(_vm._f("formatDate")(_vm.patient.dateOfBirth)))
                ])
              ])
            : _vm._e(),
          _vm.printLetterFooterConfig.patientSex[_vm.tabIndex]
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-4 footer-font" }, [
                  _vm._v("Sex:")
                ]),
                _c("div", { staticClass: "col-8 footer-font" }, [
                  _vm._v(_vm._s(_vm.patient.gender))
                ])
              ])
            : _vm._e()
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "p-4" }, [
      _c("p", { staticClass: "address-font" }, [
        _vm._v("(Click to configure content)")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }