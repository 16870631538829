var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container mb-5" }, [
    _c("div", { staticClass: "row mb-3" }, [
      _c("div", { staticClass: "col-10" }, [
        _c("img", {
          staticStyle: { width: "300px", "margin-left": "0" },
          attrs: { src: _vm.generalConfig.logoURL }
        })
      ]),
      _c(
        "div",
        {
          staticClass: "col-2",
          staticStyle: { "margin-top": "70px", "margin-bottom": "50px" }
        },
        [
          _c(
            "p",
            {
              staticClass: "mb-1 mt-3 address-font",
              staticStyle: { "font-weight": "600", "font-size": "20px" }
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("general.creditNotes")) +
                  "\n      "
              )
            ]
          )
        ]
      )
    ]),
    _c("div", { staticClass: "row mb-5" }, [
      _c("div", { staticClass: "col-7" }, [
        _c("p", { staticClass: "mb-1 fontWeight address-font" }, [
          _vm._v(
            "\n         " +
              _vm._s(_vm.$t("general.customer")) +
              " : " +
              _vm._s(_vm.patient.name || "-") +
              "\n      "
          )
        ]),
        _c("p", { staticClass: "mb-1 fontWeight address-font" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("general.InvoiceNumber")) +
              ": " +
              _vm._s(_vm.creditNote.invoice.invoiceNo || "-") +
              "\n      "
          )
        ]),
        _c("p", { staticClass: "mb-1 fontWeight address-font" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("general.CreditDate")) +
              ": " +
              _vm._s(_vm.formatTime(_vm.creditNote.date * 1000)) +
              "\n      "
          )
        ]),
        _c("p", { staticClass: "mb-1 fontWeight address-font" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("general.VisitBranch")) +
              ": " +
              _vm._s(_vm.clinicsById[_vm.creditNote.clinic].name || "-") +
              "\n      "
          )
        ]),
        _c("p", { staticClass: "mb-1 fontWeight address-font" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("general.TelFax")) +
              ": " +
              _vm._s(_vm.generalConfig.telephone) +
              "\n      "
          )
        ]),
        _c("p", { staticClass: "mb-1 fontWeight address-font" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("general.AddressInvoice")) +
              " : " +
              _vm._s(_vm.clinicsById[_vm.creditNote.clinic].address || "-") +
              "\n      "
          )
        ])
      ]),
      _c("div", { staticClass: "col-5" }, [
        _c("p", { staticClass: "mb-1 fontWeight address-font" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("general.CustomerIC")) +
              " : " +
              _vm._s(_vm.patient.nric || "-") +
              "\n      "
          )
        ]),
        _c("p", { staticClass: "mb-1 fontWeight address-font" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("general.PrintDate")) +
              " : " +
              _vm._s(_vm.formatTime(Date.now())) +
              "\n      "
          )
        ]),
        _c("p", { staticClass: "mb-1 fontWeight address-font" }, [
          _vm._v(
            "\n         " +
              _vm._s(_vm.$t("general.ConsulatantAsst")) +
              " :\n        " +
              _vm._s(
                (_vm.creditNote.invoice.therapist &&
                  _vm.creditNote.invoice.therapist.name) ||
                  "-"
              ) +
              "\n      "
          )
        ]),
        _c("p", { staticClass: "mb-1 fontWeight address-font" }, [
          _vm._v("Pages : " + _vm._s(_vm.pageCount))
        ])
      ])
    ]),
    _c("div", { staticClass: "row mb-3" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "table",
          { staticClass: "table" },
          [
            _c("thead", [
              _c("tr", [
                _c(
                  "td",
                  { staticClass: "font-weight-bold", attrs: { colspan: "2" } },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("general.InvoiceDescription")) + " "
                    )
                  ]
                ),
                _c("td", { staticClass: "font-weight-bold" }, [
                  _vm._v(_vm._s(_vm.$t("general.QuantityInvoice")))
                ]),
                _c("td", { staticClass: "font-weight-bold" }, [
                  _vm._v(_vm._s(_vm.$t("general.AmountInvoice")))
                ])
              ])
            ]),
            _vm._l(_vm.unCategorized, function(category, idx) {
              return _c(
                "tbody",
                { key: category.key, staticStyle: { "border-top": "0px" } },
                [
                  _c("tr", [
                    _c(
                      "td",
                      {
                        staticStyle: { "font-weight": "600" },
                        attrs: { colspan: "2" }
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(idx == "undefined" ? "Others" : idx) +
                            "\n            "
                        )
                      ]
                    ),
                    _c("td"),
                    _c("td")
                  ]),
                  _vm._l(category, function(items, idxS) {
                    return _c("tr", { key: items.key }, [
                      _c("td", { attrs: { colspan: "2" } }, [
                        _vm._v(_vm._s(idxS))
                      ]),
                      _c("td", [_vm._v(_vm._s(_vm.getTotalQuantity(items)))]),
                      _c("td", [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm._f("thousandSeparator")(
                                _vm._f("to2Decimal")(
                                  _vm.getTotalPricePerInvoiceCategory(items),
                                  _vm.region.decimal
                                ),
                                _vm.region.thousandSeparator
                              )
                            ) +
                            "\n            "
                        )
                      ])
                    ])
                  })
                ],
                2
              )
            }),
            _c("tfoot", [
              _c("tr", [
                _c("td", { staticClass: "font-weight-bold" }, [
                  _vm._v(_vm._s(_vm.$t("general.DiscountInvoice")))
                ]),
                _c("td", { attrs: { colspan: "2" } }, [_vm._v(" ")]),
                _c("td", [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.region.currency) +
                      _vm._s(
                        _vm._f("thousandSeparator")(
                          _vm._f("to2Decimal")(
                            (
                              Number(_vm.creditNote.invoice.totalDiscount) +
                              _vm.creditNote.invoice.promotionDiscount
                            ).toFixed(2),
                            _vm.region.decimal
                          ),
                          _vm.region.thousandSeparator
                        )
                      ) +
                      "\n            "
                  )
                ])
              ]),
              _c("tr", [
                _c("td", { staticClass: "font-weight-bold" }, [
                  _vm._v(_vm._s(_vm.$t("general.TotalAmountInvoice")))
                ]),
                _c("td", { attrs: { colspan: "2" } }, [_vm._v(" ")]),
                _c("td", [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.region.currency) +
                      _vm._s(
                        _vm._f("thousandSeparator")(
                          _vm._f("to2Decimal")(
                            Math.abs(
                              _vm.creditNote.invoice.total -
                                _vm.creditNote.invoice.totalTax
                            ).toFixed(2),
                            _vm.region.decimal
                          ),
                          _vm.region.thousandSeparator
                        )
                      ) +
                      "\n            "
                  )
                ])
              ]),
              _c("tr", [
                _c("td", { staticClass: "font-weight-bold" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(
                        _vm.creditNote.invoice.taxName +
                          " " +
                          _vm.invoiceConfig.taxPercentageAmount +
                          " %"
                      ) +
                      "\n            "
                  )
                ]),
                _c("td", { attrs: { colspan: "2" } }, [_vm._v(" ")]),
                _c("td", [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.region.currency) +
                      _vm._s(
                        _vm._f("thousandSeparator")(
                          _vm._f("to2Decimal")(
                            _vm.creditNote.invoice.totalTax,
                            _vm.region.decimal
                          ),
                          _vm.region.thousandSeparator
                        )
                      ) +
                      "\n            "
                  )
                ])
              ]),
              _c("tr", [
                _c("td", { staticClass: "font-weight-bold" }, [
                  _vm._v(_vm._s(_vm.$t("general.RoundingAdjustment")))
                ]),
                _c("td", { attrs: { colspan: "2" } }, [_vm._v(" ")]),
                _c("td", [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.region.currency) +
                      _vm._s(
                        _vm._f("thousandSeparator")(
                          _vm._f("to2Decimal")(
                            _vm.creditNote.invoice.roundingAdjustment,
                            _vm.region.decimal
                          ),
                          _vm.region.thousandSeparator
                        )
                      ) +
                      "\n            "
                  )
                ])
              ]),
              _c("tr", [
                _c("td", { staticClass: "font-weight-bold" }, [
                  _vm._v(_vm._s(_vm.$t("general.TotalDue")))
                ]),
                _c("td", { attrs: { colspan: "2" } }, [_vm._v(" ")]),
                _c("td", [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.region.currency) +
                      _vm._s(
                        _vm._f("thousandSeparator")(
                          _vm._f("to2Decimal")(
                            _vm.creditNote.invoice.total,
                            _vm.region.decimal
                          ),
                          _vm.region.thousandSeparator
                        )
                      ) +
                      "\n            "
                  )
                ])
              ]),
              _c("tr", [
                _c("td", { staticClass: "font-weight-bold" }, [
                  _vm._v(_vm._s(_vm.$t("general.ModeOfCredit")))
                ])
              ]),
              _c("tr", [
                _c("td", { staticClass: "font-weight-bold" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(
                        _vm.creditNote.paymentType
                          ? _vm.creditNote.paymentType
                          : _vm.creditNote.mode
                      ) +
                      "\n            "
                  )
                ]),
                _c("td", { attrs: { colspan: "2" } }, [_vm._v(" ")]),
                _c("td", [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.region.currency) +
                      _vm._s(
                        _vm._f("thousandSeparator")(
                          _vm._f("to2Decimal")(
                            _vm.creditNote.creditTotal,
                            _vm.region.decimal
                          ),
                          _vm.region.thousandSeparator
                        )
                      ) +
                      "\n            "
                  )
                ])
              ])
            ])
          ],
          2
        ),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-2 font-weight-bold" }, [
            _vm._v(_vm._s(_vm.$t("general.ReasonForRefund")))
          ]),
          _c(
            "div",
            { staticClass: "row col-10" },
            _vm._l(_vm.refundReasonOptions, function(item) {
              return _c(
                "div",
                { key: item, staticClass: "col-3" },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: { value: item },
                      model: {
                        value: _vm.creditNote.refundReason,
                        callback: function($$v) {
                          _vm.$set(_vm.creditNote, "refundReason", $$v)
                        },
                        expression: "creditNote.refundReason"
                      }
                    },
                    [_vm._v(_vm._s(item))]
                  )
                ],
                1
              )
            }),
            0
          )
        ]),
        _c("div", { staticClass: "row mt-5" }, [
          _c("div", { staticClass: "col-8" }, [
            _vm._v(_vm._s(_vm.$t("general.AgreeReceiveRefund")))
          ]),
          _c("div", { staticClass: "col-4" }, [
            _vm._v(_vm._s(_vm.$t("general.CustomerSignature")))
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }