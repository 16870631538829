<template>
  <div :class="['autoitem' , {disabled: item.link && item.link.is_heading}]">
    {{ item.value || '' }}
  </div>
</template>

<script>
export default {
  name: 'ICD10AutoCompleteSlot',
  props: {
    item: {
      type: Object,
      default: function fn() {},
    },
  },
};
</script>
<style scoped lang="scss">
.autoitem {
  margin:0 -20px;
  padding:0 20px;

  &.disabled {
    background: #F5F7FA;
    border-color: #E4E7Ed;
    color: #c0c4cc;
    cursor: not-allowed;
  }
}
</style>
