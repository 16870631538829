
import api from '@/services/api';

export default {
  list(params) {
    const q = [];
    Object.keys(params).forEach((key) => {
      q.push(`${params[key]}`);
    });
    const url = `/packages/${q.join('/')}`;
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`List Error: ${response}, url: ${url}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },
  listProviderPackage(params) {
    const q = [];
    Object.keys(params).forEach((key) => {
      q.push(`${params[key]}`);
    });
    const url = `/packages/providerPackage/${q.join('/')}`;
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`List Error: ${response}, url: ${url}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },

  getById(inventoryId) {
    const url = `/packages/${inventoryId}`;
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`Error: ${response.data.message}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },
  signRedeemPreProduct(packageId, itemId, {
    signature, // string
    signatureDate, // number
  }) {
    const url = `/packages/preredeem/${packageId}/${itemId}/sign`;
    return new Promise((resolve, reject) => {
      api.put(url, {
        signature, signatureDate,
      }).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`Error: ${response.data.message}`),
              { data: null, success: false },
            );
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },
  redeemPreProduct(packageId, itemId, redeemBy, inventoryId) {
    const url = `/packages/preredeem/${packageId}/${itemId}/${inventoryId}/?redeemBy=${redeemBy}`;
    return new Promise((resolve, reject) => {
      api.put(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`Error: ${response.data.message}`),
              { data: null, success: false },
            );
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },

  undoPreRedeem(packageId, itemId, inventoryId) {
    const url = `/packages/undo-preredeem/${packageId}/${itemId}/${inventoryId}`;
    return new Promise((resolve, reject) => {
      api.put(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`Error: ${response.data.message}`),
              { data: null, success: false },
            );
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },

  update(id, payload) {
    const url = `/packages/${id}`;
    return new Promise((resolve, reject) => {
      api.put(url, payload).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`Error: ${response.data.message}`),
              { data: null, success: false },
            );
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },
  itemList(params) {
    const q = [];
    Object.keys(params).forEach((key) => {
      q.push(`${params[key]}`);
    });
    const url = `/packages/listitems/${q.join('/')}`;
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`List Error: ${response}, url: ${url}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },
  itemListProvider(params) {
    const q = [];
    Object.keys(params).forEach((key) => {
      q.push(`${params[key]}`);
    });
    const url = `/packages/listitemsProvider/${q.join('/')}`;
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`List Error: ${response}, url: ${url}`),
              { data: null, success: false },
            );
          }
        },
      );
    });
  },
  redeemItemsProduct(payload, patientId, redeemBy, invoiceId, userId) {
    const url = `/packages/redeem/${patientId}/${invoiceId}/${userId}?redeemBy=${redeemBy}`;
    const items = { items: payload };
    return new Promise((resolve, reject) => {
      api.put(url, items).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`Error: ${response.data.message}`),
              { data: null, success: false },
            );
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },
  redeemItemsProductProvider(payload, providerId, redeemBy, invoiceId, userId) {
    const url = `/packages/redeemProvider/${providerId}/${invoiceId}/${userId}?redeemBy=${redeemBy}`;
    const items = { items: payload };
    return new Promise((resolve, reject) => {
      api.put(url, items).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`Error: ${response.data.message}`),
              { data: null, success: false },
            );
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },

  transferItemsProduct(payload, patientId, toPatientId, redeemBy, clinic) {
    const url = `/packages/transfer/${patientId}/${toPatientId}?redeemBy=${redeemBy}`;
    const items = { items: payload, clinic };
    return new Promise((resolve, reject) => {
      api.post(url, items).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`Error: ${response.data.message}`),
              { data: null, success: false },
            );
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },
  transferItemsProductProvider(payload, providerId, toProviderId, redeemBy, clinic) {
    const url = `/packages/transferProviderPackage/${providerId}/${toProviderId}?redeemBy=${redeemBy}`;
    const items = { items: payload, clinic };
    return new Promise((resolve, reject) => {
      api.post(url, items).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`Error: ${response.data.message}`),
              { data: null, success: false },
            );
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },
  count(params) {
    const q = [];
    Object.keys(params).forEach((key) => {
      q.push(`${params[key]}`);
    });
    const url = `/packages/count/${q.join('/')}`;
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            resolve({ data: 0, message: '', success: true });
          }
        },
      );
    });
  },
  countByProvider(params) {
    const q = [];
    Object.keys(params).forEach((key) => {
      q.push(`${params[key]}`);
    });
    const url = `/packages/packageCount/${q.join('/')}`;
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            resolve({ data: 0, message: '', success: true });
          }
        },
      );
    });
  },
  redeemInvoiceItemsProduct(payload, patientId, redeemBy, invoiceId, clinic, userId) {
    const url = `/packages/redeem/invoice/${patientId}/${invoiceId}/${clinic}/${userId}?redeemBy=${redeemBy}`;
    const items = { items: payload };
    return new Promise((resolve, reject) => {
      api.put(url, items).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`Error: ${response.data.message}`),
              { data: null, success: false },
            );
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },
  redeemInvoiceItemsProductProvider(payload, providerId, redeemBy, invoiceId, clinic, userId) {
    const url = `/packages/redeemProvider/invoice/${providerId}/${invoiceId}/${clinic}/${userId}?redeemBy=${redeemBy}`;
    const items = { items: payload };
    return new Promise((resolve, reject) => {
      api.put(url, items).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`Error: ${response.data.message}`),
              { data: null, success: false },
            );
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },
};
