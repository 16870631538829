<template>
  <b-nav-item-dropdown
    v-if="hasAccessToAtLeastOneDropdown"
    :id="idName"
    :text="text"
    left
    :class="className"
  >
    <template v-for="(item, index) in accessibleDropdown">
      <b-dropdown-divider v-if="typeof item == 'string'" :key="index">
      </b-dropdown-divider>
      <b-dropdown-item
        v-else
        :key="index"
        :to="item.to"
        :data-automation="item['data-automation']"
      >
        {{ item.label }}
      </b-dropdown-item>
    </template>
  </b-nav-item-dropdown>
</template>

<script>
import { userHasAccessToFn } from "@/helpers/acl";

export default {
  name: "AclNavItemDropdown",
  props: {
    to: [String, Object],
    "data-automation": [String, Object],
    className: [String, Object],
    idName: [String],
    text: [String],
    dropdown: [Array]
  },
  methods: {
    hasAccessTo(navURL) {
      let hasAccess = true;
      const { route } = this.$router.resolve(navURL, this.$route, this.append);
      const routeLink = route.matched.find(href => href.name === route.name);
      // console.log('> ACLNAVITEMDROPDOWN', route)
      // console.log('> ACLNAVITEMDROPDOWN (MATCHED)', route)
      if (
        routeLink &&
        routeLink.props.default &&
        routeLink.props.default.aclReqFunction
      ) {
        // console.log('> ACLNAVITEMDROPDOWN', routeLink.props.default.aclReqFunction)
        const reqFn = routeLink.props.default.aclReqFunction;
        hasAccess = userHasAccessToFn(reqFn);
      } else {
        hasAccess = true; // if no set reqFunction on router, assume it is a public link
      }
      return hasAccess;
    }
  },
  computed: {
    accessibleDropdown() {
      const output = this.dropdown.filter(
        navitem => typeof navitem === "string" || this.hasAccessTo(navitem.to)
      );
      if (typeof output[output.length - 1] === "string") {
        output.pop();
      }
      return output;
    },
    hasAccessToAtLeastOneDropdown() {
      return (
        this.accessibleDropdown.filter(navitem => typeof navitem !== "string")
          .length > 0
      );
    }
  }
};
</script>
