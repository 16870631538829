import api from './api';

export default {
  create(payload) {
    const url = '/health-screening';
    return api.post(url, payload);
  },
  fetchAll(params) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);

    // FIXME: URL encoding
    Object.keys(params).forEach((key) => {
      q.push(`${key}=${encodeURIComponent(params[key])}`);
    });

    const url = `/health-screening/?${q.join('&')}`;
    return api.get(url);
  },
  fetchAllByPatient(patientId, params) {
    const t = `t=${new Date().getTime()}`;
    const q = [];
    q.push(t);

    // FIXME: URL encoding
    Object.keys(params).forEach((key) => {
      q.push(`${key}=${encodeURIComponent(params[key])}`);
    });

    const url = `/health-screening/patient/${patientId}?${q.join('&')}`;
    return api.get(url);
  },
  getHealthScreening(id) {
    return api.get(`/health-screening/${id}`);
  },
  updateHealthScreening(id, data) {
    return api.put(`/health-screening/${id}`, data);
  },

  patchHealthScreening(id, data) {
    return api.patch(`/health-screening/${id}`, data);
  },

  patchPersonalDetails(id, data) {
    return api.patch(`/health-screening/personalDetails/${id}`, data);
  },

  getTestResultsStatus(id) {
    return api.get(`/health-screening/getTestResultsStatus/${id}`);
  },

  sendReportLink(id, type = 'email') {
    return api.get(`/health-screening/sendReport/${id}/${type.toLowerCase()}/`);
  },

  previewReport(id) {
    const url = `/health-screening/report-preview/${id}`;
    return new Promise((resolve, reject) => {
      api.get(url).then(
        (response) => {
          if (response.ok) {
            const data = response.body;
            resolve({ data, message: '', success: true });
          } else {
            reject(
              new Error(`Error: ${response.data.message}`),
              { data: null, success: false },
            );
          }
        },
        (response) => {
          reject(
            new Error(`Request Error: ${response}, url: ${url}`),
            { data: null, success: false },
          );
        },
      );
    });
  },
};
