<template>
  <div>
    <treatment-record :consultation="treatmentRecordConsultationToPrint" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import TreatmentRecord from '@/components/TCMConsultation/Print/TreatmentRecord.vue';

export default {
  name: 'TcmPrintTreatmentRecord',
  components: {
    TreatmentRecord,
  },
  computed: {
    ...mapGetters('tcmConsultation', ['treatmentRecordConsultationToPrint']),
  },
  mounted() {
    setTimeout(() => {
      window.print();
    }, 2000);
  },
};
</script>
