export const SET_ACTIVE_ADMISSION = 'SET_ACTIVE_ADMISSION';
export const ADD_ADMISSION_ITEMS = 'ADD_ADMISSION_ITEMS';
export const SET_ADMISSION_ITEMS = 'SET_ADMISSION_ITEMS';
export const SET_FANILISED_ITEMS = 'SET_FANILIZED_ITEMS';
export const REMOVE_ADMISSION_ITEM = 'REMOVE_ADMISSION_ITEM';
export const UPDATE_ADMISSION_ITEM = 'UPDATE_ADMISSION_ITEM';
export const SET_ADMISSION_INVOICE = 'SET_ADMISSION_INVOICE';
export const SET_SELECTED_ADMISSION_ID = 'SET_SELECTED_ADMISSION_ID';
export const ADD_FANILISED_ITEMS = 'ADD_FANILIZED_ITEMS';
export const SET_IPD_TAB = 'SET_IPD_TAB';
export const SET_ADMINISTRATION_LOG = 'SET_ADMINISTRATION_LOG';
export const REMOVE_ADMINISTRATION_LOG = 'REMOVE_ADMINISTRATION_LOG';
export const SET_PROVIDER_ADMISSIONS = 'SET_PROVIDER_ADMISSIONS';
