import {
  SET_ALL_BILLINGGROUP,
  SET_BILLINGGROUP_OBJECT
} from './type.mutation';

import billingGroupService from '@/services/billing-group.service'

const state = {
  allBillingGroups: [],
  billingGroupObject: {},
};

const mutations = {
  [SET_ALL_BILLINGGROUP](_state, payload) {
    // eslint-disable-next-line no-param-reassign
    _state.allBillingGroups = payload;
  },
  [SET_BILLINGGROUP_OBJECT](_state, payload) {
    _state.billingGroupObject = payload
  }
};

const actions = {
  fetchAllBillingGroups({ commit }, param = {}) {
    return billingGroupService.fetchSimplify(param).then( resp => {
      commit(SET_ALL_BILLINGGROUP, resp.data)
      const convertedData = resp.data.reduce((result, item) => ({
        ...result,
        [item.code]: item,
      }), {})
      commit(SET_BILLINGGROUP_OBJECT, convertedData)
      return resp.data
    })
  }
};

const getters = {
  allBillingGroups: _state => _state.allBillingGroups,
  billingGroupObject: _state => _state.billingGroupObject

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
