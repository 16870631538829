var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "editor" },
    [
      _c("editor-menu-bubble", {
        attrs: { editor: _vm.editor, id: "editor-menu-bubble" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var commands = ref.commands
              var isActive = ref.isActive
              var menu = ref.menu
              return [
                _c(
                  "div",
                  {
                    staticClass: "menububble",
                    class: { "is-active": menu.isActive },
                    style:
                      "left: " +
                      menu.left +
                      "px; bottom: " +
                      menu.bottom +
                      "px;"
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "menububble__button",
                        class: { "is-active": isActive.bold() },
                        on: { click: commands.bold }
                      },
                      [_c("v-icon", { attrs: { name: "bold" } })],
                      1
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "menububble__button",
                        class: { "is-active": isActive.italic() },
                        on: { click: commands.italic }
                      },
                      [_c("v-icon", { attrs: { name: "italic" } })],
                      1
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "menububble__button",
                        class: { "is-active": isActive.underline() },
                        on: { click: commands.underline }
                      },
                      [_c("v-icon", { attrs: { name: "underline" } })],
                      1
                    )
                  ]
                )
              ]
            }
          }
        ])
      }),
      _c("editor-content", { attrs: { editor: _vm.editor } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }