
export default {
  sockets: {
    connect() {
      /* eslint-disable no-console */
      console.log('VDSOCKET CONNECTED');
      if (this.$route.meta && this.$route.meta.socketRoom) {
        let room = '';
        if (this.$route.meta.socketRoomNameIsDynamic) {
          room = this.$route[this.$route.meta.socketRoomNamePath];
        } else  {
          room = this.$route.meta.socketRoomNamePath;
        }
        this.$socket.emit('JOIN_ROOM', { room, username: this.user.name })
      }
    },
    connecting() {
      /* eslint-disable no-console */
      // console.log('VDSOCKET CONNECTING...');
    },
    disconnect() {
      /* eslint-disable no-console */
      // console.log('VDSOCKET DISCONNECTED!');
    },
    ping() {
      /* eslint-disable no-console */
      // console.log('VDSOCKET PING!');
    },
    pong(d) {
      /* eslint-disable no-console */
      // console.log('VDSOCKET PONG!', d);
    },
    // following is moved to user vuex store
    // USER_JOINED(d) {
    //   /* eslint-disable no-console */
    //   console.log('VDSOCKET JOINED', d);
    //   this.flash(`${d.username} joined this page`, 'success', { timeout: 5000 })
    // },
  },
};
