var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "invoices-container" },
    _vm._l(_vm.formattedInvoices, function(invoice, idx) {
      return _c("div", { key: idx, staticClass: "invoice-item" }, [
        _c(
          "header",
          { staticClass: "inv-header columns is-align-items-center" },
          [
            _c("div", { staticClass: "logo column is-6" }, [
              _c("img", {
                attrs: {
                  src: _vm.generalConfig.logoURL,
                  alt: _vm.generalConfig.clinicName
                }
              })
            ]),
            _c("div", { staticClass: "inv-title column is-6 is-uppercase" }, [
              _c("h5", [_vm._v(_vm._s(_vm.title))])
            ])
          ]
        ),
        _c("div", { staticClass: "inv-neck" }, [
          _c("div", { staticClass: "columns is-gapless" }, [
            _c("div", { staticClass: "column is-6" }, [
              _c("div", { staticClass: "inv-item" }, [
                _c("span", { staticClass: "name is-uppercase" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("tcm.patientName")) +
                      ":\n            "
                  )
                ]),
                _c("span", { staticClass: "value" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.patient.name) +
                      "\n            "
                  )
                ])
              ])
            ]),
            _c("div", { staticClass: "column is-6" })
          ]),
          _c("div", { staticClass: "columns is-gapless" }, [
            _c("div", { staticClass: "column is-6" }, [
              _c("div", { staticClass: "inv-item" }, [
                _c("span", { staticClass: "name is-uppercase" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("tcm.IC")) +
                      ":\n            "
                  )
                ]),
                _c("span", { staticClass: "value" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.patient.nric) +
                      "\n            "
                  )
                ])
              ])
            ]),
            _c("div", { staticClass: "column is-6" }, [
              _vm.mode !== "patient"
                ? _c("div", { staticClass: "inv-item" }, [
                    _c("span", { staticClass: "name" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("tcm.age")) +
                          ":\n            "
                      )
                    ]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.patient.age) +
                          "\n            "
                      )
                    ])
                  ])
                : _vm._e()
            ])
          ]),
          _c("div", { staticClass: "columns is-gapless" }, [
            _c("div", { staticClass: "column is-6" }, [
              _c("div", { staticClass: "inv-item" }, [
                _c("span", { staticClass: "name is-uppercase" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("tcm.regNo")) +
                      ":\n            "
                  )
                ]),
                _c("span", { staticClass: "value" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm._f("pad")(invoice.invoiceNo)) +
                      "\n            "
                  )
                ])
              ])
            ]),
            _c("div", { staticClass: "column is-6" }, [
              _c("div", { staticClass: "inv-item" }, [
                _c("span", { staticClass: "name is-uppercase" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("tcm.clinicName")) +
                      ":\n            "
                  )
                ]),
                _c("span", { staticClass: "value" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(invoice.clinic.name) +
                      "\n            "
                  )
                ])
              ])
            ])
          ]),
          _c("div", { staticClass: "columns is-gapless" }, [
            _c("div", { staticClass: "column is-6" }, [
              _c("div", { staticClass: "inv-item" }, [
                _c("span", { staticClass: "name is-uppercase" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("tcm.doctor")) +
                      ":\n            "
                  )
                ]),
                _c("span", { staticClass: "value" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(
                        invoice.provider ? invoice.provider.name || "" : ""
                      ) +
                      "\n            "
                  )
                ])
              ])
            ]),
            _c("div", { staticClass: "column is-6" }, [
              _c("div", { staticClass: "inv-item" }, [
                _c("span", { staticClass: "name is-uppercase" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("tcm.CA")) +
                      ":\n            "
                  )
                ]),
                _c("span", { staticClass: "value" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.user.name) +
                      "\n            "
                  )
                ])
              ])
            ])
          ]),
          _c("div", { staticClass: "columns is-gapless" }, [
            _c("div", { staticClass: "column is-6" }, [
              _c("div", { staticClass: "inv-item" }, [
                _c("span", { staticClass: "name is-uppercase" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("tcm.visitDate")) +
                      ":\n            "
                  )
                ]),
                _c("span", { staticClass: "value" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm._f("dateFormat_DDMMYYYY")(invoice.visitDate)) +
                      "\n            "
                  )
                ])
              ])
            ]),
            _c("div", { staticClass: "column is-6" }, [
              _c("div", { staticClass: "inv-item" }, [
                _c("span", { staticClass: "name is-uppercase" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("tcm.printDate")) +
                      ":\n            "
                  )
                ]),
                _c("span", { staticClass: "value" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.printDate) +
                      "\n            "
                  )
                ])
              ])
            ])
          ]),
          _vm.mode === "patient"
            ? _c("div", { staticClass: "columns is-gapless" }, [
                _c("div", { staticClass: "column is-6" }, [
                  _c("div", { staticClass: "inv-item" }, [
                    _c("span", { staticClass: "name is-uppercase" }, [
                      _vm._v("\n              diagnosis:\n            ")
                    ]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(invoice.diseaseName) +
                          "\n            "
                      )
                    ])
                  ])
                ])
              ])
            : _vm._e()
        ]),
        _c("div", { staticClass: "inv-body" }, [
          _c("table", { staticClass: "table is-bordered is-fullwidth" }, [
            _c("thead", [
              _c("tr", [
                _c("th", [
                  _c("div", { staticClass: "name-en is-uppercase" }, [
                    _vm._v(_vm._s(_vm.$t("tcm.code")))
                  ]),
                  _vm.mode === "patient"
                    ? _c("div", { staticClass: "name-cn" }, [_vm._v("代码")])
                    : _vm._e()
                ]),
                _c("th", [
                  _c("div", { staticClass: "name-en is-uppercase" }, [
                    _vm._v(_vm._s(_vm.$t("tcm.medicineName")))
                  ]),
                  _vm.mode === "patient"
                    ? _c("div", { staticClass: "name-cn" }, [_vm._v("药名")])
                    : _vm._e()
                ]),
                _c("th", [
                  _c("div", { staticClass: "name-en is-uppercase" }, [
                    _vm._v(_vm._s(_vm.$t("tcm.medicineNameEn")))
                  ]),
                  _vm.mode === "patient"
                    ? _c("div", { staticClass: "name-cn" }, [
                        _vm._v("药名(英)")
                      ])
                    : _vm._e()
                ]),
                _vm.isDosageDisplayable
                  ? _c("th", [
                      _c("div", { staticClass: "name-en is-uppercase" }, [
                        _vm._v(_vm._s(_vm.$t("tcm.dailyDosage")))
                      ]),
                      _vm.mode === "patient"
                        ? _c("div", { staticClass: "name-cn" }, [
                            _vm._v("日量")
                          ])
                        : _vm._e()
                    ])
                  : _vm._e(),
                _c("th", [
                  _c("div", { staticClass: "name-en is-uppercase" }, [
                    _vm._v(_vm._s(_vm.$t("tcm.remarks")))
                  ]),
                  _vm.mode === "patient"
                    ? _c("div", { staticClass: "name-cn" }, [_vm._v("备注")])
                    : _vm._e()
                ])
              ])
            ]),
            _c(
              "tbody",
              _vm._l(invoice.printableItems, function(item, index) {
                return _c("tr", { key: index }, [
                  _c("td", [_vm._v(_vm._s(item.givenId))]),
                  _c("td", [_vm._v(_vm._s(item.name))]),
                  _c("td", [_vm._v(_vm._s(item.chineseName))]),
                  _vm.isDosageDisplayable
                    ? _c("td", [
                        _vm._v(
                          _vm._s(_vm.getPrintDailyDosage(item, invoice)) +
                            " " +
                            _vm._s(_vm.$t("tcm.gram"))
                        )
                      ])
                    : _vm._e(),
                  _c("td", [_vm._v(_vm._s(item.remarks))])
                ])
              }),
              0
            )
          ])
        ]),
        _vm.mode === "patient"
          ? _c("div", { staticClass: "inv-sig columns is-gapless" }, [
              _c("div", { staticClass: "column is-4" }),
              _c("div", { staticClass: "column is-8" }, [
                _c("div", { staticClass: "sig is-uppercase" }, [
                  _vm._v(
                    "SIGNATURE TCM PHAYSICIAN " + _vm._s(invoice.provider.name)
                  )
                ])
              ])
            ])
          : _vm._e(),
        _vm.isDosageDisplayable
          ? _c("div", { staticClass: "inv-footer" }, [
              _vm.mode === "patient"
                ? _c("div", { staticClass: "is-uppercase" }, [
                    _vm._v("instruction")
                  ])
                : _vm._e(),
              _vm.langCNTR || _vm.mode === "patient"
                ? _c("div", [
                    _vm._v(
                      "\n        服法：每日 " +
                        _vm._s(invoice.packagePerDay) +
                        " 包；共 " +
                        _vm._s(invoice.totalDays) +
                        " 天；每日量 " +
                        _vm._s(invoice.powderDosage) +
                        " 克;\n      "
                    )
                  ])
                : _vm._e(),
              _vm.mode === "patient"
                ? _c("div", [
                    _vm._v(
                      "\n        Dosage: " +
                        _vm._s(invoice.packagePerDay) +
                        " pack(s) per day, " +
                        _vm._s(invoice.totalDays) +
                        " day(s), total " +
                        _vm._s(invoice.totalPackages) +
                        " pack(s), " +
                        _vm._s(invoice.powderDosage) +
                        "g per day;\n      "
                    )
                  ])
                : _vm._e(),
              _vm.langEn && _vm.mode !== "patient"
                ? _c("div", [
                    _vm._v(
                      "\n        Dosage: " +
                        _vm._s(invoice.packagePerDay) +
                        " Package(s)/Day For " +
                        _vm._s(invoice.totalDays) +
                        " Day(s); Daily dosage: " +
                        _vm._s(invoice.powderDosage) +
                        " g;\n      "
                    )
                  ])
                : _vm._e()
            ])
          : _vm._e(),
        idx !== _vm.invoices.length - 1
          ? _c("div", {
              staticStyle: {
                "break-after": "always",
                "page-break-after": "always"
              }
            })
          : _vm._e()
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }