var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "fullForm" } },
    [
      _vm.formData
        ? _c("b-form", [
            _c(
              "div",
              {
                class: [
                  "note-form-inner",
                  { printContainer: _vm.isPrinting },
                  _vm.metadata.formClass || ""
                ]
              },
              [
                _vm._l(_vm.groups, function(elRow, idx) {
                  return [
                    _c(
                      "div",
                      { key: idx, staticClass: "row" },
                      _vm._l(elRow, function(qns, i) {
                        return _c(
                          "div",
                          {
                            key: i,
                            class:
                              "formDataElement " +
                              (_vm.formData[qns].colSize || "col-sm-6")
                          },
                          [
                            _vm.formData[qns].type === "table"
                              ? _c(
                                  "div",
                                  [
                                    _c("b-table", {
                                      attrs: {
                                        striped: "",
                                        hover: "",
                                        items: _vm.formData[qns].answer,
                                        "thead-class": "d-none"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm.formData[qns].dynamictable
                              ? _c(
                                  "div",
                                  { staticClass: "dynamic-table-container" },
                                  [
                                    _c("dynamic-table", {
                                      attrs: {
                                        item: _vm.formData[qns],
                                        qns: qns,
                                        viewing: _vm.viewing,
                                        updateTypingFormData:
                                          _vm.updateTypingFormData,
                                        updateTick: _vm.updateTick,
                                        formData: _vm.formData,
                                        printing: _vm.isPrinting
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm.formData[qns].type === "metadata"
                              ? _c("div")
                              : _vm.formData[qns].type === "misc-gdcalc"
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "gd-calc-container",
                                    attrs: { "data-tick": _vm.tick }
                                  },
                                  [
                                    _c("gd-calc-widget", {
                                      attrs: {
                                        item: _vm.formData[qns],
                                        qns: qns,
                                        viewing: _vm.viewing,
                                        updateTypingFormData:
                                          _vm.updateTypingFormData,
                                        tick: _vm.tick
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm.formData[qns].type === "noanswer-nil"
                              ? _c(
                                  "div",
                                  { attrs: { "data-tick": _vm.tick } },
                                  [
                                    _c("noanswer-nil", {
                                      attrs: {
                                        item: _vm.formData[qns],
                                        qns: qns,
                                        viewing: _vm.viewing,
                                        printing: _vm.isPrinting,
                                        updateTypingFormData:
                                          _vm.updateTypingFormData
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm.formData[qns].type === "oneclick-button"
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "oneclick-button-container",
                                    attrs: { "data-tick": _vm.tick }
                                  },
                                  [
                                    _c("one-click-button", {
                                      attrs: {
                                        item: _vm.formData[qns],
                                        qns: qns,
                                        viewing: _vm.viewing,
                                        print: _vm.isPrinting,
                                        formData: _vm.formData,
                                        updateTypingFormData: _vm.updateTick
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm.formData[qns].type === "richtext"
                              ? _c(
                                  "div",
                                  { staticClass: "rich-text-container" },
                                  [
                                    _c("rich-text-editor", {
                                      attrs: {
                                        qns: qns,
                                        html: _vm.formData[qns].answer,
                                        updateTypingFormData:
                                          _vm.updateTypingFormData,
                                        viewing: _vm.viewing,
                                        printing: _vm.isPrinting
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm.formData[qns].type === "range-link"
                              ? _c(
                                  "div",
                                  { attrs: { "data-tick": _vm.tick } },
                                  [
                                    _c("date-range-link-input", {
                                      attrs: {
                                        item: _vm.formData[qns],
                                        viewing: _vm.viewing,
                                        printing: _vm.isPrinting,
                                        formData: _vm.formData,
                                        qns: qns,
                                        updateTick: _vm.updateTick
                                      },
                                      on: { update: _vm.setLinkedObjectAnswer }
                                    })
                                  ],
                                  1
                                )
                              : _vm.formData[qns].type === "masscheck"
                              ? _c(
                                  "div",
                                  { attrs: { "data-tick": _vm.tick } },
                                  [
                                    _c("mass-check", {
                                      attrs: {
                                        item: _vm.formData[qns],
                                        viewing: _vm.viewing,
                                        printing: _vm.isPrinting,
                                        formData: _vm.formData
                                      },
                                      on: {
                                        setMassCheckFlag: _vm.setMassCheckFlag
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm.formData[qns].type === "link-input"
                              ? _c(
                                  "div",
                                  { attrs: { "data-tick": _vm.tick } },
                                  [
                                    _c("link-input-widget", {
                                      attrs: {
                                        item: _vm.formData[qns],
                                        viewing: _vm.viewing,
                                        printing: _vm.isPrinting,
                                        formData: _vm.formData,
                                        getClass: _vm.getClass,
                                        rowIdx: i,
                                        qns: qns
                                      },
                                      on: { update: _vm.setLinkedObjectAnswer }
                                    })
                                  ],
                                  1
                                )
                              : _vm.formData[qns].type === "link"
                              ? _c(
                                  "div",
                                  { staticClass: "form-row" },
                                  [
                                    _c("div", { staticClass: "col-sm-4" }, [
                                      _vm._v("Vuemotion Image:")
                                    ]),
                                    _c(
                                      "b-link",
                                      {
                                        staticClass: "col",
                                        attrs: {
                                          href: _vm.formData[qns].answer
                                        }
                                      },
                                      [_vm._v("Link")]
                                    )
                                  ],
                                  1
                                )
                              : _vm.formData[qns].type === "input"
                              ? _c(
                                  "b-form-group",
                                  {
                                    class: _vm.getClass(_vm.formData, i, qns),
                                    attrs: {
                                      label: _vm.formData[qns].label,
                                      disabled:
                                        _vm.viewing ||
                                        _vm.formData[qns].disabled ||
                                        _vm.formData[qns].display === "hide",
                                      id: qns + "group"
                                    }
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: qns + "input",
                                        value:
                                          _vm.formData[qns].dateFormat &&
                                          _vm.formData[qns].dateFormat.length >
                                            0
                                            ? _vm.parseDateValue(
                                                _vm.formData[qns].answer,
                                                _vm.formData[qns].dateFormat
                                              )
                                            : _vm.formData[qns].answer,
                                        type: _vm.formData[qns].inputType,
                                        plaintext: _vm.formData[qns].plaintext,
                                        readonly: _vm.formData[qns].readonly,
                                        placeholder:
                                          _vm.formData[qns].placeholder
                                      },
                                      on: {
                                        input: function($event) {
                                          return _vm.updateTypingFormData(
                                            $event,
                                            qns
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm.formData[qns].type === "textarea"
                              ? _c(
                                  "b-form-group",
                                  {
                                    class: _vm.getClass(_vm.formData, i, qns),
                                    attrs: {
                                      label: _vm.formData[qns].label,
                                      disabled:
                                        _vm.formData[qns].disabled ||
                                        _vm.formData[qns].display === "hide",
                                      id: qns + "group"
                                    }
                                  },
                                  [
                                    _vm.formData[qns].texteditor === true
                                      ? _c("text-editor", {
                                          attrs: {
                                            qns: qns,
                                            value: _vm.formData[qns].answer,
                                            rows: _vm.formData[qns].rows,
                                            updateTypingFormData:
                                              _vm.updateTypingFormData,
                                            disabled:
                                              _vm.viewing ||
                                              _vm.formData[qns].disabled,
                                            id: qns + "textarea"
                                          }
                                        })
                                      : _c("typed-form", {
                                          attrs: {
                                            qns: qns,
                                            value: _vm.formData[qns].answer,
                                            rows: _vm.formData[qns].rows,
                                            updateTypingFormData:
                                              _vm.updateTypingFormData,
                                            disabled:
                                              _vm.viewing ||
                                              _vm.formData[qns].disabled
                                          }
                                        })
                                  ],
                                  1
                                )
                              : _vm.formData[qns].type == "empty_cell"
                              ? _c(
                                  "div",
                                  {
                                    class: _vm.getClass(_vm.formData, i, qns),
                                    style: _vm.formData[qns].style
                                  },
                                  [_vm._v("\n               \n            ")]
                                )
                              : _vm.formData[qns].type == "readonly"
                              ? _c(
                                  "div",
                                  {
                                    class: _vm.getClass(_vm.formData, i, qns),
                                    style: _vm.formData[qns].style
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.formData[qns].label))
                                    ])
                                  ]
                                )
                              : _vm.formData[qns].type == "hr"
                              ? _c("hr")
                              : _vm.formData[qns].type == "br"
                              ? _c("br")
                              : _vm.formData[qns].type === "link-search"
                              ? _c(
                                  "b-form-group",
                                  { class: _vm.getClass(_vm.formData, i, qns) },
                                  [
                                    _c("el-autocomplete", {
                                      staticClass: "procedure-picker",
                                      attrs: {
                                        debounce: 500,
                                        "fetch-suggestions": function(
                                          queryString,
                                          cb
                                        ) {
                                          return _vm.searchQuery(
                                            queryString,
                                            cb,
                                            _vm.formData[qns].searchType
                                          )
                                        },
                                        placeholder:
                                          _vm.formData[qns].placeholder,
                                        disabled:
                                          _vm.viewing ||
                                          _vm.formData[qns].disabled
                                      },
                                      on: {
                                        select: function(item) {
                                          return _vm.updateSearchLink(
                                            item,
                                            _vm.formData[qns].linkTo
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.formData[qns].answer,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData[qns],
                                            "answer",
                                            $$v
                                          )
                                        },
                                        expression: "formData[qns].answer"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm.formData[qns].type == "formattedResult"
                              ? _c(
                                  "b-card",
                                  { staticClass: "col-12 mb-3" },
                                  _vm._l(_vm.formData[qns].value, function(
                                    res,
                                    index
                                  ) {
                                    return _c(
                                      "div",
                                      {
                                        key: "formatted-" + index,
                                        staticClass: "mt-3"
                                      },
                                      [
                                        _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.formatResults(res)
                                            )
                                          }
                                        })
                                      ]
                                    )
                                  }),
                                  0
                                )
                              : _vm.formData[qns].type ===
                                "check_with_main_checkbox"
                              ? _c(
                                  "b-form-group",
                                  {
                                    key:
                                      _vm.formData[qns].lastChangedKey ||
                                      +new Date(),
                                    class: _vm.getClass(_vm.formData, i, qns),
                                    attrs: {
                                      disabled:
                                        _vm.viewing ||
                                        _vm.formData[qns].disabled,
                                      id: qns + "group"
                                    }
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.formData[qns].label))
                                    ]),
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        staticClass: "ml-2",
                                        staticStyle: { display: "inline" },
                                        attrs: {
                                          checked: _vm.formData[qns].mainAnswer,
                                          value:
                                            _vm.formData[qns].mainCheckbox.value
                                        },
                                        on: {
                                          change: function(e) {
                                            return _vm.mainCheckboxChanged(
                                              e,
                                              qns,
                                              _vm.formData[qns].mainCheckbox
                                                .effects
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formData[qns].mainCheckbox.text
                                          )
                                        )
                                      ]
                                    ),
                                    _vm.formData[qns].description
                                      ? _c("div", [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.formData[qns].description
                                              ) +
                                              "\n              "
                                          )
                                        ])
                                      : _vm._e(),
                                    _c("b-form-checkbox-group", {
                                      attrs: {
                                        checked: _vm.formData[qns].answer,
                                        options: _vm.formData[qns].options,
                                        stacked: _vm.formData[qns].stacked
                                      },
                                      on: {
                                        input: function($event) {
                                          return _vm.updateTypingFormData(
                                            $event,
                                            qns
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm.formData[qns].type === "button"
                              ? _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "b-modal",
                                        rawName: "v-b-modal",
                                        value: _vm.formData[qns].map + "modal",
                                        expression:
                                          "`${formData[qns].map}modal`"
                                      }
                                    ],
                                    staticClass: "form-btn",
                                    attrs: {
                                      dataevent: _vm.formData[qns].clickevent
                                        ? _vm.formData[qns].clickevent
                                        : "",
                                      mapid: _vm.formData[qns].map,
                                      id: qns + "button",
                                      disabled:
                                        _vm.viewing ||
                                        _vm.formData[qns].disabled
                                    },
                                    on: { click: _vm.handleEvent }
                                  },
                                  [_vm._v(_vm._s(_vm.formData[qns].label))]
                                )
                              : _vm.formData[qns].type === "modal"
                              ? _c(
                                  "b-modal",
                                  {
                                    attrs: {
                                      id: qns + "modal",
                                      title: _vm.formData[qns].label,
                                      "hide-footer": ""
                                    }
                                  },
                                  [
                                    _c(
                                      "legend",
                                      { staticStyle: { "font-size": "16px" } },
                                      [_c("b", [_vm._v("User Verification")])]
                                    ),
                                    _vm.formData[qns].modalId ===
                                    "digitalsignature"
                                      ? _c("digital-signature", {
                                          on: {
                                            digitalSignedHandler: function(
                                              user
                                            ) {
                                              return _vm.handleDigitalSignatureAuthorise(
                                                user,
                                                qns
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _c(
                                  "b-form-group",
                                  {
                                    class: _vm.getClass(_vm.formData, i, qns),
                                    attrs: {
                                      label: _vm.formData[qns].label,
                                      disabled:
                                        _vm.viewing ||
                                        _vm.formData[qns].disabled,
                                      id: qns + "group"
                                    }
                                  },
                                  [
                                    _vm.formData[qns].type === "select"
                                      ? _c("b-form-select", {
                                          attrs: {
                                            options:
                                              _vm.formData[qns].populateKey &&
                                              _vm.formData[qns].populateKey ===
                                                "provider"
                                                ? _vm.providersDropDownOptions
                                                : _vm.formData[qns]
                                                    .populateKey &&
                                                  _vm.formData[qns]
                                                    .populateKey === "therapist"
                                                ? _vm.therapistsDropDownOptions
                                                : _vm.formData[qns].options,
                                            stacked: _vm.formData[qns].stacked,
                                            plain: ""
                                          },
                                          on: {
                                            input: function($event) {
                                              return _vm.updateTypingFormData(
                                                $event,
                                                qns
                                              )
                                            }
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              _vm.formData[qns].answer
                                                ? {
                                                    key: "first",
                                                    fn: function() {
                                                      return [
                                                        _c(
                                                          "option",
                                                          {
                                                            attrs: {
                                                              disabled: ""
                                                            },
                                                            domProps: {
                                                              value:
                                                                _vm.formData[
                                                                  qns
                                                                ].answer
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.formData[
                                                                  qns
                                                                ].answer
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    },
                                                    proxy: true
                                                  }
                                                : null
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: _vm.formData[qns].answer,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.formData[qns],
                                                "answer",
                                                $$v
                                              )
                                            },
                                            expression: "formData[qns].answer"
                                          }
                                        })
                                      : _vm._e(),
                                    _vm.formData[qns].type === "check"
                                      ? _c("b-form-checkbox-group", {
                                          attrs: {
                                            checked: _vm.formData[qns].answer,
                                            options: _vm.formData[qns].options,
                                            id: qns + "check"
                                          },
                                          on: {
                                            input: function($event) {
                                              return _vm.updateTypingFormData(
                                                $event,
                                                qns
                                              )
                                            }
                                          }
                                        })
                                      : _vm.formData[qns].type === "radio"
                                      ? _c("b-form-radio-group", {
                                          attrs: {
                                            checked: _vm.formData[qns].answer,
                                            options: _vm.formData[qns].options,
                                            id: qns + "radio"
                                          },
                                          on: {
                                            change: function(e) {
                                              return _vm.mainRadioChanged(
                                                e,
                                                qns,
                                                _vm.formData[qns]
                                                  .mainRadioboxControl
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e(),
                                    _vm.formData[qns].noteeditor
                                      ? _c(
                                          "span",
                                          [
                                            _vm.editorId !== i
                                              ? _c("div", {
                                                  staticClass: "editor",
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.formData[qns]
                                                        .answer || "<br>"
                                                    )
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.editorFocus(i)
                                                    }
                                                  }
                                                })
                                              : _vm.editorId === i
                                              ? _c("note-editor", {
                                                  staticClass: "editor",
                                                  attrs: {
                                                    pos: i,
                                                    html:
                                                      _vm.formData[qns].answer,
                                                    updateTypingFormData:
                                                      _vm.updateTypingFormData,
                                                    editorBlur: _vm.editorBlur
                                                  }
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                ),
                            _vm.formData[qns].type === "search"
                              ? _c(
                                  "b-form-group",
                                  { class: _vm.getClass(_vm.formData, i, qns) },
                                  [
                                    _vm.formData[qns].type === "search"
                                      ? _c("el-autocomplete", {
                                          staticClass: "procedure-picker",
                                          attrs: {
                                            debounce: 500,
                                            "fetch-suggestions": function(
                                              queryString,
                                              cb
                                            ) {
                                              return _vm.searchQuery(
                                                queryString,
                                                cb,
                                                _vm.formData[qns].searchType
                                              )
                                            },
                                            placeholder:
                                              _vm.formData[qns].placeholder,
                                            disabled:
                                              _vm.viewing ||
                                              _vm.formData[qns].disabled
                                          },
                                          model: {
                                            value: _vm.formData[qns].answer,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.formData[qns],
                                                "answer",
                                                $$v
                                              )
                                            },
                                            expression: "formData[qns].answer"
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.formData[qns].type === "signaturePad"
                              ? _c(
                                  "div",
                                  { class: " mb-4" },
                                  [
                                    _c("signature-form", {
                                      attrs: {
                                        formData: _vm.formData[qns],
                                        noteEditor: true,
                                        qns: qns,
                                        containerName: _vm.containerName,
                                        viewing: _vm.viewing
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ]
                })
              ],
              2
            )
          ])
        : _vm._e(),
      _vm.editor.requireSignature
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-sm-6" },
              [
                _c("signature-form", {
                  attrs: {
                    patientForm: _vm.editor,
                    noteEditor: true,
                    containerName: _vm.containerName,
                    viewing: _vm.viewing
                  }
                })
              ],
              1
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }