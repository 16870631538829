<template> <!-- this is being used only in CanvasEditorContainer.vue -->
    <!-- <b-modal
        class="bv-modal-example"
        header-class="label"
        size="xl"
    > -->
    <div>

        <div class="modal-header">
            <span>{{ $t('general.uploadManager') }}</span>
        </div>

        <div class="default">
            <b-table
                striped
                hover
                :fields="fields"
                :items="filesMapped"
                v-if="filesMapped.length > 0"
            >
                <div v-for="(row, i) in filesMapped" :key="i">
                    <span class="fileName">
                        <span>
                            <b-input data-automation="upload-manager-file-name" class="mb-2 mr-sm-2 mb-sm-0" size="sm" :value="row.value" @input="updateUploadLineItem($event, row.item.uuid, 'name')" />
                        </span>
                    </span>
                    <span class="category">
                        <span>
                            <b-form-select data-automation="upload-manager-category" class="mb-2 mr-sm-2 mb-sm-0" size="sm" :value="row.value" @change="updateUploadLineItem($event, row.item.uuid, 'categoryId')" :options="categoriesOptions"></b-form-select>
                        </span>
                    </span>
                </div>
            </b-table>
        </div>

        <div class="modal-footer space-between">
            <!-- <span><b-button variant="outline-danger" @click="closeUploadManager">Add More Files</b-button></span> -->
            <!-- <span> -->
                <b-button variant="danger" data-automation="upload-manager-close" @click="closeUploadManager">Close</b-button>
                <b-button variant="success" data-automation="upload-manager-upload" @click="uploadFiles" :disabled="uploading">Upload</b-button>
            <!-- </span> -->
        </div>
    </div>
    <!-- </b-modal> -->
</template>

<script>
export default {
  name: 'UploadManager',
  props: ['showUploadManager', 'closeUploadManager', 'files', 'uploadFiles', 'updateUploadLineItem', 'categoriesOptions', 'uploading'],
  components: {},
  mixins: [],
  data() {
    return {
      fields: [
        'fileName',
        'category',
        'type (Page)',
        'size',
        'status',
      ],
    };
  },
  watch: {

  },
  computed: {
    filesMapped() {
      const output = (this.files || []).map((elem) => {
        const { file } = elem;
        return {
          fileName: elem.name, 'type (Page)': file.type, category: elem.categoryId, size: file.size, status: elem.status, uuid: elem.uuid,
        };
      });
      return output;
    },
    // filteredCategoriesOptions() {
    //   let output = this.categoriesOptions;
    //   const userAccessChildrenFNIDs = localStorage.getItem('acl__childrenFnIds');
    //   if (userAccessChildrenFNIDs && this.categoriesOptions) {
    //     const userAccess = JSON.parse(userAccessChildrenFNIDs);
    //     output = this.categoriesOptions.filter(c => userAccess[c.value]);
    //   }
    //   return output;
    // },
  },
  methods: {
    uploadFile(file, i) { // for one file only
      // let url = `${process.env.VUE_APP_apiUrl}/note/uploadNotes?invoiceId=${123}`;
      // var xhr = new XMLHttpRequest()
      // let formData = new FormData()

      // xhr.open('POST', url, true)
      // xhr.setRequestHeader('X-CSRF', localStorage.getItem('xsrf'));
      // xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('access_token')}`);
      // xhr.setRequestHeader('Accept', 'application/json');
      // xhr.setRequestHeader('Cache-Control', 'no-cache');
      // const orgCode = localStorage.getItem('orgCode');
      // xhr.setRequestHeader('x-org', orgCode);
      // const xclinic = localStorage.getItem('xclinic');
      // xhr.setRequestHeader('x-clinic', xclinic);

      // // Add following event listener
      // xhr.upload.addEventListener("progress", function(e) {
      //     console.log('got progress! e.loaded, e.total are ', e.loaded, e.total);
      //     updateProgress(i, (e.loaded * 100.0 / e.total) || 100)
      // })

      // xhr.addEventListener('readystatechange', function(e) {
      //     if (xhr.readyState == 4 && xhr.status == 200) {
      //         // Done. Inform the user
      //     }
      //     else if (xhr.readyState == 4 && xhr.status != 200) {
      //         // Error. Inform the user
      //     }
      // })
      // formData.append('myFile', file) // the file here is each File {} 'file list' thing
      // console.log('file, formData is ', file, formData);
      // xhr.send(formData, { haha: '123' })
    },
  },
  created() {
  },
  mounted() {
    console.log('UploadManager2 - im mounted!');
  },
};
</script>

<style lang="scss" scoped>
.space-between {
    display: flex;
    justify-content: space-between;
}
</style>
