import taskService from '@/services/task.service';

import {
  SET_ALL_TASKS,
  SET_TASK,
  SET_DRUG_APPROVAL_TASK,
  SET_TASK_BY_USER,
  SET_TODAYS_TASKS,
} from './type.mutation';

const state = {
  all: [],
  selectedTask: null,
  drugApprovalTask: [],
  taskByUser: [],
  todaysTasks: {},
};

/* eslint-disable no-param-reassign */
const mutations = {
  [SET_ALL_TASKS](_state, payload) {
    _state.all = payload;
  },
  [SET_TASK](_state, payload) {
    _state.selectedTask = payload ? { ...payload } : null;
  },
  [SET_DRUG_APPROVAL_TASK](_state, payload) {
    _state.drugApprovalTask = payload;
  },
  [SET_TASK_BY_USER](_state, payload) {
    _state.taskByUser = payload;
  },
  [SET_TODAYS_TASKS](_state, taskList) {
    _state.todaysTasks = taskList
      .filter(task => (!task.isCompleted && !task.isRejected))
      .reduce((acc, task) => ({
        ...acc,
        [task._id]: task,
      }), {});
  },
  SOCKET_TODO_NEW(_state, todo) {
    _state.todaysTasks = {
      ..._state.todaysTasks,
      [todo._id]: todo,
    };
  },
  SOCKET_TODO_DELETE(_state, todo) {
    const { [todo._id]: omit, ...res } = _state.todaysTasks;
    _state.todaysTasks = { ...res };
  },
};

const actions = {
  listTasks({ commit }, param = {}) {
    return taskService.listPaginated(param).then(
      (response) => {
        console.log('Tasks >>>> ', response.data);
        const tasks = response.data;
        // commit(SET_ALL_TASKS, tasks);
        return tasks;
      },
    ).catch((error) => {
      console.log('Error in fetching tasks ', error);
      commit(SET_ALL_TASKS, []);
      throw error;
    });
  },
  clearTasks({ commit }) {
    commit(SET_ALL_TASKS, []);
  },
  listDrugApprovalTask({ commit }, params = {}) {
    return taskService.list(params)
      .then((response) => {
        commit(SET_DRUG_APPROVAL_TASK, response.data);
        return response.data;
      }).catch((error) => {
        commit(SET_DRUG_APPROVAL_TASK, []);
        throw error;
      });
  },
  setSelectedTask({ commit }, payload) {
    commit(SET_TASK, payload);
  },
  unsetSelectedTask({ commit }) {
    commit(SET_TASK, null);
  },
  createNewTask({ commit }, payload) {
    commit('setLoading', true, { root: true });
    return taskService.create(payload)
      .then((response) => {
        if (response.success) {
          commit(SET_TASK, response.data);
        }
        commit('setLoading', false, { root: true });
        return response;
      });
  },
  createInvoiceTask({ commit }, payload) {
    commit('setLoading', true, { root: true });
    return taskService.createInvoiceTask(payload)
      .then((response) => {
        if (response.success) {
          commit(SET_TASK, response.data);
        }
        commit('setLoading', false, { root: true });
        return response;
      });
  },
  updateTask({ commit }, payload) {
    commit('setLoading', true, { root: true });
    return taskService.update(payload._id, payload)
      .then((response) => {
        if (response.success) {
          commit(SET_TASK, response.data);
        }
        commit('setLoading', false, { root: true });
        return response;
      });
  },
  destroyTask({ commit }, payload) {
    commit('setLoading', true, { root: true });
    return taskService.destroy(payload._id)
      .then((response) => {
        if (response.success) {
          commit(SET_TASK, null);
        }
        commit('setLoading', false, { root: true });
        return response;
      });
  },
  listTasksByUser({ commit }, param = {}) {
    return taskService.list(param).then(
      (response) => {
        const tasks = response.data;
        commit(SET_TASK_BY_USER, tasks);
        return tasks;
      },
    ).catch((error) => {
      commit(SET_TASK_BY_USER, []);
      throw error;
    });
  },
  listTodaysTask({ commit }, param = {}) {
    return taskService.list(param).then(
      (response) => {
        const tasks = response.data;
        commit(SET_TODAYS_TASKS, tasks);
        return tasks;
      },
    ).catch((error) => {
      commit(SET_TODAYS_TASKS, []);
      throw error;
    });
  },
};

const getters = {
  all: _state => _state.all,
  selectedTask: _state => _state.selectedTask,
  drugApprovalTask: _state => _state.drugApprovalTask,
  taskByUser: _state => _state.taskByUser,
  todaysTasks: _state => _state.todaysTasks,
  todaysTaskCount: _state => Object.keys(_state.todaysTasks).length,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
