var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.medicalItems, function(item) {
      return _c(
        "div",
        { key: item.id },
        [
          _vm.featureFlags.bgrimm
            ? [
                _c("drug-label-component", {
                  attrs: {
                    disableAutoPrint: true,
                    doctorName: "good",
                    doctorSurname: "welldone",
                    printLocal: _vm.printLocal,
                    medItem: item,
                    generalConfig: _vm.general,
                    patient: _vm.selectedPatient
                  }
                }),
                _c("div", {
                  staticStyle: {
                    "page-break-after": "always",
                    "break-after": "always"
                  }
                })
              ]
            : _vm.featureFlags.drclinique
            ? [
                _c(
                  "div",
                  { staticClass: "container-fluid" },
                  [
                    _c("medical-label-dr-clinique", {
                      attrs: {
                        disableAutoPrint: true,
                        clinicName: _vm.general.clinicName,
                        clinicAddress: _vm.general.address,
                        medName: item.name,
                        medQty: item.qty,
                        medStrength: item.strength,
                        medUnit: item.unit,
                        medDosage: item.dosage,
                        medExpiry: item.selectedBatch.expiry || "",
                        medPrecautionOne: item.precaution1,
                        medPrecautionTwo: item.precaution2,
                        patientName: _vm.selectedPatient.name,
                        patientNric: _vm.selectedPatient.nric,
                        patientDetails: _vm.selectedPatient
                      }
                    })
                  ],
                  1
                )
              ]
            : [
                _c(
                  "div",
                  { staticClass: "container-fluid" },
                  [
                    !item.adHocCompounding
                      ? _c("medical-label-component", {
                          attrs: {
                            disableAutoPrint: true,
                            clinicName: _vm.general.clinicName,
                            clinicAddress: _vm.general.address,
                            medName: item.name,
                            medQty: item.qty,
                            medStrength: item.strength,
                            medUnit: item.unit,
                            medDosage: item.dosage,
                            medExpiry: item.selectedBatch.expiry || "",
                            selectedBatch: item.selectedBatch,
                            medPrecautionOne: item.precaution1,
                            medPrecautionTwo: item.precaution2,
                            patientName: _vm.selectedPatient.name,
                            patientNric: _vm.selectedPatient.nric,
                            patientDetails: _vm.selectedPatient
                          }
                        })
                      : _c("medical-label-component", {
                          attrs: {
                            disableAutoPrint: true,
                            clinicName: _vm.general.clinicName,
                            clinicAddress: _vm.general.address,
                            medName: item.adHocCompoundName,
                            medQty: item.qty,
                            medStrength: item.strength,
                            medUnit: item.adHocCompoundUnit,
                            medDosage: item.adHocCompoundDosage,
                            medExpiry: item.selectedBatch.expiry || "",
                            selectedBatch: item.selectedBatch,
                            medPrecautionOne: item.adHocCompoundPrecaution1,
                            medPrecautionTwo: item.adHocCompoundPrecaution2,
                            patientName: _vm.selectedPatient.name,
                            patientNric: _vm.selectedPatient.nric,
                            patientDetails: _vm.selectedPatient
                          }
                        })
                  ],
                  1
                )
              ]
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }