/* eslint-disable no-param-reassign */
import service from '@/services/discharge.service';

import {
  SET_DISPENSE_ITEM,
  DISPENSE_ITEM,
  UPDATE_ITEM,
  REMOVE_ITEM,
  SET_DISCHARGE_FORM,
  SET_ORDER_DISPENSE,
  SET_DISCHARGE_PHARMACY,
  CLEAR_DISPENSE_MEDICINES,
  UPDATE_PHARMACY_ITEM,
  ADD_DISCHARGE_PHARMACY,
  SET_PRINT,
} from './type.mutation';

const state = {
  dischargeMedicines: [],
  dispensed: false,
  dischargeForm: {},
  dischargePharmacies: [],
  print: {},
};

const mutations = {
  [SET_DISPENSE_ITEM](_state, payload) {
    _state.dischargeMedicines = payload || [];
  },
  [DISPENSE_ITEM](_state, payload) {
    _state.dischargeMedicines.push(payload);
  },
  [UPDATE_ITEM](_state, payload) {
    _state.dischargeMedicines = _state.dischargeMedicines.map((item) => {
      if (item.uuid === payload.uuid) item = payload;
      return item;
    });
  },
  [REMOVE_ITEM](_state, uuid) {
    _state.dischargeMedicines = _state.dischargeMedicines
      .filter(item => item.uuid !== uuid);
  },
  [SET_DISCHARGE_FORM](_state, payload) {
    _state.dischargeForm = payload || {};
  },
  [SET_ORDER_DISPENSE](_state, payload) {
    _state.dispensed = !!payload;
  },
  [ADD_DISCHARGE_PHARMACY](_state, payload) {
    _state.dischargePharmacies.push(payload);
  },
  [SET_DISCHARGE_PHARMACY](_state, payload) {
    _state.dischargePharmacies = payload;
  },
  [CLEAR_DISPENSE_MEDICINES](_state) {
    _state.dischargeMedicines = [];
  },
  [UPDATE_PHARMACY_ITEM](_state, payload) {
    _state.dischargePharmacies = _state.dischargePharmacies.map((pharmacy) => {
      if (pharmacy._id === payload._id) pharmacy = payload;
      return pharmacy;
    });
  },
  [SET_PRINT](_state, payload) {
    _state.print = payload;
  },
};

const actions = {
  dispenseMedicine({ commit }, item) {
    commit(DISPENSE_ITEM, item);
  },
  updateItem({ commit }, item) {
    commit(UPDATE_ITEM, item);
  },
  removeItem({ commit }, uuid) {
    commit(REMOVE_ITEM, uuid);
  },
  saveDischargeForm({ commit }, payload) {
    return service.saveFinalise(payload)
      .then((response) => {
        commit(SET_DISCHARGE_FORM, payload.dischargeForm);
        return response.data;
      });
  },
  orderDispense({ commit }, payload) {
    commit(SET_ORDER_DISPENSE, true);
    commit(ADD_DISCHARGE_PHARMACY, payload);
    // commit(CLEAR_DISPENSE_MEDICINES);
  },
  preDischarge({ commit }, payload) {
    return service.preDischarge(payload)
      .then((response) => {
        commit(SET_ORDER_DISPENSE, true);
        return response.data;
      });
  },
  cancelPreDischarge({ commit }, payload) {
    return service.cancelPreDischarge(payload.admission, payload.close)
      .then((response) => {
        commit(SET_ORDER_DISPENSE, false);
        return response.data;
      });
  },
  saveOrderItem({ commit }, payload) {
    return service.create(payload);
  },
  updatePharmacyItem({ commit }, payload) {
    return service.updatePharmacyItem(payload._id, payload.items)
      .then((response) => {
        commit(UPDATE_PHARMACY_ITEM, payload);
        return response.data;
      });
  },
  fetchDischargePharmacies({ commit }, params = {}) {
    // temp code
    return service.fetchPharmacyItems(params)
      .then((response) => {
        commit(SET_DISCHARGE_PHARMACY, response.data);
        return response.data;
      });
  },
  fetchDischarge({ commit }, params) {
    return service.list(params)
      .then((response) => {
        commit(SET_DISPENSE_ITEM, response.data.dischargeMedicines);
        commit(SET_ORDER_DISPENSE, response.data.dispensed);
        commit(SET_DISCHARGE_FORM, response.data.dischargeForm);
        return response.data;
      });
  },
  setPrint({ commit }, payload) {
    commit(SET_PRINT, payload);
  },
};

const getters = {
  dischargeMedicines: _state => _state.dischargeMedicines,
  dischargeForm: _state => _state.dischargeForm,
  dispensed: _state => _state.dispensed,
  dischargePharmacies: _state => _state.dischargePharmacies,
  dischargeForPrint: _state => _state.print,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
