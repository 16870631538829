var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "conuid print-body", class: { previewPrint: _vm.preview } },
    [
      _c("div", { staticClass: "row no-gutters" }, [
        _c("div", { staticClass: "col-7 label-container" }, [
          _c("div", { staticClass: "row no-gutters" }, [
            _c("div", { staticClass: "col-12 heading-font" }, [
              _vm.printLabelConfig.printLabel.clinicName
                ? _c("h5", [_vm._v(_vm._s(_vm.clinic.clinicName))])
                : _vm._e(),
              _vm.printLabelConfig.printLabel.clinicAddress
                ? _c("p", { staticClass: "mb-2 address-font" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.labelAddress) +
                        "\n          "
                    )
                  ])
                : _vm._e(),
              _c("div", { staticClass: "border-bottom mb-2 line" })
            ])
          ]),
          _c("div", { staticClass: "text-font" }, [
            _vm.printLabelConfig.printLabel.patientName
              ? _c("div", { staticClass: "row no-gutters" }, [
                  _c("div", { staticClass: "col-2 s-label" }, [_vm._v("NAME")]),
                  _c("div", { staticClass: "col-10" }, [
                    _vm._v(": "),
                    _c("strong", [_vm._v(_vm._s(_vm.patient.name))])
                  ])
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "row no-gutters" },
              [
                _vm.printLabelConfig.printLabel.age
                  ? [
                      _c("div", { staticClass: "col-2 s-label" }, [
                        _vm._v("AGE")
                      ]),
                      _c("div", { staticClass: "col-3 s-text" }, [
                        _vm._v(": " + _vm._s(_vm.age))
                      ])
                    ]
                  : _vm._e(),
                _vm.printLabelConfig.printLabel.nricfin
                  ? [
                      _c("div", { staticClass: "col-3 l-label" }, [
                        _vm._v("NRIC / PASSPORT")
                      ]),
                      _c("div", { staticClass: "col-4" }, [
                        _vm._v(": " + _vm._s(_vm.patient.nric))
                      ])
                    ]
                  : _vm._e()
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "row no-gutters" },
              [
                _vm.printLabelConfig.printLabel.patientDOB
                  ? [
                      _c("div", { staticClass: "col-2 s-label" }, [
                        _vm._v("DOB")
                      ]),
                      _c("div", { staticClass: "col-3 s-text" }, [
                        _vm._v(
                          ": " +
                            _vm._s(
                              _vm._f("formatVDDate")(_vm.patient.dateOfBirth, {
                                format: "DD/MM/YYYY",
                                lang: _vm.$i18n.locale
                              })
                            )
                        )
                      ])
                    ]
                  : _vm._e(),
                _vm.printLabelConfig.printLabel.nationality
                  ? [
                      _c("div", { staticClass: "col-3 l-label" }, [
                        _vm._v("NATIONALITY")
                      ]),
                      _c("div", { staticClass: "col-4" }, [
                        _vm._v(": " + _vm._s(_vm.patient.nationality))
                      ])
                    ]
                  : _vm._e()
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "row no-gutters" },
              [
                _vm.printLabelConfig.printLabel.patientID
                  ? [
                      _c("div", { staticClass: "col-2 s-label" }, [
                        _vm._v("PATIENTID")
                      ]),
                      _c("div", { staticClass: "col-3 s-text" }, [
                        _vm._v(
                          "\n            : " +
                            _vm._s(
                              _vm.patient.givenIdPrefix
                                ? _vm.patient.givenIdPrefix +
                                    _vm.patient.givenId
                                : _vm.patient.givenId
                            ) +
                            "\n          "
                        )
                      ])
                    ]
                  : _vm._e(),
                _vm.printLabelConfig.printLabel.patientSex
                  ? [
                      _c("div", { staticClass: "col-1 l-label" }, [
                        _vm._v("SEX")
                      ]),
                      _c("div", { staticClass: "col-1" }, [
                        _vm._v(": " + _vm._s(_vm.patient.gender))
                      ])
                    ]
                  : _vm._e()
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "row no-gutters" },
              [
                _vm.printLabelConfig.printLabel.patientTest
                  ? [
                      _c("div", { staticClass: "col-2 s-label" }, [
                        _vm._v("TEST")
                      ]),
                      _c("div", { staticClass: "col-3 s-text" }, [
                        _vm._v(": " + _vm._s(_vm.patient.test))
                      ])
                    ]
                  : _vm._e(),
                _vm.printLabelConfig.printLabel.mobile
                  ? [
                      _c("div", { staticClass: "col-3 l-label" }, [
                        _vm._v("MOBILE NUMBER")
                      ]),
                      _c("div", { staticClass: "col-4" }, [
                        _vm._v(": " + _vm._s(_vm.patient.mobile))
                      ])
                    ]
                  : _vm._e()
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "row no-gutters" },
              [
                _vm.printLabelConfig.printLabel.address
                  ? [
                      _c("div", { staticClass: "col-2 s-label" }, [
                        _vm._v("ADDRESS")
                      ]),
                      _c("div", { staticClass: "col-10" }, [
                        _vm._v(": " + _vm._s(_vm.patient.address))
                      ])
                    ]
                  : _vm._e()
              ],
              2
            ),
            _c("div", { staticClass: "row no-gutters" }, [
              _c("div", { staticClass: "col-2 s-label" }, [_vm._v("DATE")]),
              _c("div", { staticClass: "col-10" }, [
                _vm._v(
                  ": " +
                    _vm._s(
                      _vm._f("formatVDDate")(new Date(), {
                        format: "DD/MM/YYYY HH:mm A",
                        lang: _vm.$i18n.locale
                      })
                    )
                )
              ])
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }