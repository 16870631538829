<template>
  <div class="container-fluid"
    :class="{'previewPrint': preview}">
    <div class="row">
      <div class="col-5">
        <div class="row">
          <div class="col-12">
            <h5 v-if="printLabelConfig.printLabel.clinicName">{{ clinic.clinicName }}</h5>
            <p v-if="printLabelConfig.printLabel.clinicAddress"
              class="mb-2 address-font">
              {{ clinic.address || ''}}
            </p>
            <div class="border-bottom mb-2"></div>
          </div>
        </div>
        <div class="row col-12" v-if="printLabelConfig.printLabel.patientName">
          <div>NAME</div>
          <div>&nbsp;:&nbsp;<strong>{{ patient.name }}</strong></div>
        </div>
        <!-- <div class="row" v-if="printLabelConfig.printLabel.nricfin">
          <div class="col-3">NRIC</div>
          <div class="col-9">:&nbsp;{{ patient.nric }}</div>
        </div> -->
        <div class="row col-12" v-if="printLabelConfig.printLabel.patientID">
          <div>CN</div>
          <div>&nbsp;:&nbsp;{{ patient.givenIdPrefix ?
                patient.givenIdPrefix + patient.givenId
                : patient.givenId }}</div>
        </div>
        <div class="row col-12">
          <template v-if="printLabelConfig.printLabel.patientDOB">
            <div>DOB</div>
            <div>&nbsp;:&nbsp;
              <span v-if="patient.dateOfBirth == null || patient.dateOfBirth == ''"></span>
              <span v-else>
                  {{ patient.dateOfBirth | formatVDDate({ format: 'DD MMM YYYY', lang: $i18n.locale }) }} / {{ age || ' ' }}
              </span>
            </div>
          </template>
          <!-- <template v-if="printLabelConfig.printLabel.patientSex">
            <div class="col-1">SEX</div>
            <div class="col-2">:&nbsp;{{ patient.gender }}</div>
          </template> -->
        </div>
        <!-- <div class="row" v-if="printLabelConfig.printLabel.nationality">
            <div class="col-3">{{$t('general.nationality').toUpperCase()}}</div>
            <div class="col-3">
              :&nbsp;{{ patient.nationality }}
            </div>
        </div> -->
        <div class="row col-12">
          <div>DATE</div>
          <div>&nbsp;:&nbsp;{{new Date() | formatVDDate({ format: 'DD MMM YYYY hh:mm:ss A', sourceFormat: 'X', lang: $i18n.locale,  }) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { createNamespacedHelpers } from 'vuex';
import dateformatter from '@/components/mixins/dateformatter';
import patientMixin from '@/components/mixins/patient';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

export default {
  name: 'LabLabel',
  mixins: [dateformatter, patientMixin],
  props: {
    clinic: {
      type: Object,
      default() {
        return { };
      },
    },
    patient: {
      type: Object,
      default() {
        return { };
      },
    },
    preview: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapConfigGetters({ printLabelConfig: 'patient' }),
    momentDOB() {
      return moment(this.patient.dateOfBirth);
    },
    age() {
      return this.formatAge(this.momentDOB);
    },
  },
  mounted() {
    if (!this.preview) {
      setTimeout(() => { window.print(); }, 2000);
    }
  },
};
</script>

<style scoped src="../../assets/print.css"></style>
<style scoped>
  .previewPrint {
    background-color: white;
    padding: 20px;
    padding-left: 35px;
    min-height: 50em;
  }
  .container-fluid, .address-font{
    font-size: 0.9em !important;
  }
  h5{
    font-size: 1.10rem;
}

</style>
