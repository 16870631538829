<template>
  <div class="container-fluid">
    <package-transfer-receipt-print
      :selectedPatient="selectedPatient"
      :patientConfig="patient"
      :generalConfig="general"
      :selectedPackage="selectedPackage"
    />
  </div>
</template>

<script>
import PackageTransferReceiptPrint from '@/components/Print/PackageTransferReceiptPrint.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'PackageTransferReceipt',
  components: {
    PackageTransferReceiptPrint,
  },
  data() {
    return {
      selectedPackage: {},
    };
  },
  computed: {
    ...mapGetters('patient', ['selectedPatient']),
    ...mapGetters('invoice', ['printPackageTransferReceipt']),
    ...mapGetters('config', ['general', 'patient']),
  },
  mounted() {
    this.selectedPackage = {
      packageName: this.printPackageTransferReceipt.packageName,
      itemName: this.printPackageTransferReceipt.name,
      transferFrom: this.selectedPatient.name,
      transferTo: this.printPackageTransferReceipt.transferTo,
      dateTime: this.printPackageTransferReceipt.transferDate,
    };
  },
};
</script>
