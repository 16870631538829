<template>
  <div class="container-fluid">
    <div class="previewPrint">
      <div class="row">
        <div class="col">
          <compiled-report-table-components :compiledData="compiledReportPrint"></compiled-report-table-components>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import CompiledReportTableComponents from '@/components/OccupationalService/CompiledReportTableComponents.vue';

const { mapGetters: mapPatientGetters } = createNamespacedHelpers('patient');

export default {
  name: 'CompiledReport',
  components: {
    CompiledReportTableComponents,
  },
  mounted() {
    setTimeout(function() {
      window.print();
    }, 2000);
  },
  computed: {
    ...mapPatientGetters({ compiledReportPrint: 'compiledReportPrint' }),
  },
};
</script>

<style scoped src="../../assets/print.css"></style>
