var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row no-gutters form-group mb-2" }, [
      _c("label", { staticClass: "col-sm-3" }, [
        _vm._v(_vm._s(_vm.item.label))
      ]),
      _c("div", { staticClass: "col-sm-9" }, [
        !_vm.showNil
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.firstIpt,
                  expression: "firstIpt"
                }
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                placeholder: "NIL",
                disabled: _vm.viewing || _vm.printing
              },
              domProps: { value: _vm.firstIpt },
              on: {
                change: _vm.updateAnswer,
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.firstIpt = $event.target.value
                }
              }
            })
          : _c("label", { staticClass: "col-sm-3" }, [_vm._v("NIL")])
      ])
    ]),
    !_vm.showNil
      ? _c("div", { staticClass: "row no-gutters form-group mb-2" }, [
          _c("label", { staticClass: "col-sm-3" }),
          _c("div", { staticClass: "col-sm-9" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.secondIpt,
                  expression: "secondIpt"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text", disabled: _vm.viewing || _vm.printing },
              domProps: { value: _vm.secondIpt },
              on: {
                change: _vm.updateAnswer,
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.secondIpt = $event.target.value
                }
              }
            })
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }