import Vue from 'vue';
import Router from 'vue-router';
// TODO: fix print is broken when using router code split
import Print from './views/Print/index.vue';
import TaxInvoiceTemplate from './views/Print/TaxInvoice.vue';
import CategorizedTaxInvoiceTemplate from './views/Print/CategorizedTaxInvoice.vue';
import DischargeSummaryTemplate from './views/Print/DischargeSummary.vue';
import PatientLabelTemplate from './views/Print/PatientLabel.vue';
import LabLabelTemplate from './views/Print/LabLabel.vue';
import PatientConsultNote from './views/Print/PatientConsultNote.vue';
import OccupationalPatientConsultNote from './views/Print/OccupationalPatientConsultNote.vue';
import PrintMedicalLabel from './views/Print/MedicalLabel.vue';
import PrintAllMedicalLabels from './views/Print/allMedicalLabels.vue';
import PrintPackingList from './views/Print/packingList.vue';
import PatientLetter from './views/Print/PatientLetter.vue';
import PrintQueueCard from './views/Print/QueueCard.vue';
import PrintQueueCardMaKuang from './views/Print/QueueCardMaKuang.vue';
import PharmacyMedLabelSingle from './views/Pharmacy/MedLabelSingle.vue';
import PharmacyMedLabelAll from './views/Pharmacy/MedLabelAll.vue';
import IndividualCorpInvoice from './views/CorporateInvoice/individual-print.vue';
import BulkCorpInsurance from './views/CorporateInvoice/bulk-print.vue';
import CreditDebitPrintIndex from './views/Print/CreditDebit.vue';
import FeedbackPrintIndex from './views/Print/Feedback.vue';
import PackageTransferReceipt from './views/Print/PackageTransferReceipt.vue';
import ClassificationInvoices from './views/Print/ClassificationInvoices.vue';
import TypingNote from './views/Print/TypingNote.vue';
import PurchaseOrder from './views/Print/PurchaseOrder.vue';
import PrescriptionForm from './views/Print/PrescriptionForm.vue';
import TcmPrintInvoices from './views/Print/TcmPrintInvoices.vue';
import TcmPrintHerbalMedicineOrder from './views/Print/TcmPrintHerbalMedicineOrder.vue';
import TcmPrintTreatmentRecord from './views/Print/TcmPrintTreatmentRecord.vue';
import CompiledReport from "./views/Print/CompiledReport.vue";
import IntegrationReport from './views/Print/IntegrationReport.vue';
import WipeCache from './views/WipeCache.vue';
import Prescription from './views/Prescription.vue';

import {
  ACLAllReqFunctions,
  ACLSomeReqFunctions,
  ACLReqFunction,
  // ACLRouteGuardViaURLPATH,
} from './router_guards';

const routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error);
};

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [{
    path: '/',
    component: () => import(/* webpackPrefetch: true */ './views/Landing/index.vue'),
    props: {
      navigation: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('./views/Login.vue'),
    props: {
      navigation: false,
    },
  },
  {
    path: '/twoFactor',
    name: 'twofactor',
    component: () => import('./views/TwoFactor.vue'),
    props: {
      navigation: false,
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('./views/Logout.vue'),
    props: {
      navigation: false,
    },
  },
  {
    path: '/xero',
    name: 'xero',
    component: () => import('./views/XeroRedirect.vue'),
    props: {
      navigation: false,
    },
  },
  {
    path: '/landingpage',
    name: 'landing',
    component: () => import(/* webpackPrefetch: true */ './views/Landing/index.vue'),
    props: {
      navigation: true,
    },
  },
  {
    path: '/queue/list',
    name: 'queue',
    component: () => import(/* webpackPrefetch: true */ './views/Queue/Index.vue'),
    alias: '/queue',
    props: {
      navigation: true,
      aclReqFunction: 'queue_list',
    },
    beforeEnter: ACLReqFunction('queue_list'),
  },
  {
    path: '/queue/qrcode-scanner',
    name: 'queueQRCodeScanner',
    component: () => import('./views/Queue/qrcode.vue'),
    props: {
      navigation: true,
    },
  },
  {
    path: '/admission/list',
    name: 'admission',
    component: () => import(/* webpackPrefetch: true */ './views/Admission/Index.vue'),
    alias: '/admission',
    props: {
      navigation: true,
      // aclReqFunction: 'admission_list',
    },
    // beforeEnter: ACLReqFunction('admission_list'),
  },
  {
    path: '/queue-board',
    name: 'queueDashboard',
    component: () => import('./views/QueueDashboard/Index.vue'),
    props: {
      navigation: false,
    },
    beforeEnter: ACLReqFunction('queue_dashboard'),
  },
  {
    path: '/appointment',
    name: 'appointment',
    component: () => import('./views/Appointment/index.vue'),
    props: {
      navigation: true,
    },
    children: [{
      path: '/appointment/list',
      name: 'appointmentList',
      component: () => import('./views/Appointment/list.vue'),
      beforeEnter: ACLReqFunction('appointmentList'),
      props: {
        aclReqFunction: 'appointmentList',
      },
    },
    {
      path: '/appointment/new',
      name: 'appointmentNew',
      component: () => import('./views/Appointment/new.vue'),
      beforeEnter: ACLSomeReqFunctions(['appointmentNew', 'patient_create_queue_appointment']),
      props: {
        aclReqFunction: 'appointmentNew',
      },
    },
    {
      path: '/appointment/:id',
      name: 'appointmentEdit',
      component: () => import('./views/Appointment/edit.vue'),
      props: true,
    },
    ],
  },
  {
    path: '/waitlist',
    name: 'waitlist',
    component: () => import('./views/Waitlist/index.vue'),
    props: {
      navigation: true,
    },
    children: [{
      path: '/waitlist/list',
      name: 'waitlistList',
      component: () => import('./views/Waitlist/list.vue'),
      beforeEnter: ACLReqFunction('appointmentList'),
      props: {
        aclReqFunction: 'appointmentList',
      },
    }],
  },
  {
    path: '/scheduler',
    name: 'scheduler',
    component: () => import('./views/Scheduler/index.vue'),
    props: {
      navigation: true,
    },
    children: [
      {
        path: '/scheduler/templates/list',
        name: 'schedulerTemplatesList',
        component: () => import('./views/Scheduler/Templates/list.vue'),
      },
      {
        path: '/scheduler/templates/edit/:id?',
        name: 'schedulerTemplatesNew',
        component: () => import('./views/Scheduler/Templates/edit.vue'),
      },
      {
        path: '/scheduler/rollout/view/:id?',
        name: 'schedulerRolloutview',
        component: () => import('./views/Scheduler/Rollout/view.vue'),
      },
    ],
  },
  {
    path: '/patient',
    name: 'patient',
    component: () => import('./views/Patient/index.vue'),
    props: {
      navigation: true,
    },
    children: [{
      path: '/patient/list',
      name: 'patient-list',
      component: () => import('./views/Patient/list.vue'),
      beforeEnter: ACLReqFunction('patient_list'),
      props: {
        aclReqFunction: 'patient_list',
      },
    },
    {
      path: 'new',
      name: 'patient-new',
      component: () => import('./views/Patient/new.vue'),
      beforeEnter: ACLReqFunction('patient_create'),
      props: {
        aclReqFunction: 'patient_create',
      },
    }, {
      path: 'importEmployeesAsPatients',
      name: 'import-employees-as-patients',
      component: () => import('./views/Patient/importEmployeesAsPatients.vue'),
      beforeEnter: ACLReqFunction('Employeepreregistartionaspatient'),
      props: {
        aclReqFunction: 'Employeepreregistartionaspatient',
      },
    },
    {
      path: 'edit/:id',
      name: 'patient-edit',
      component: () => import('./views/Patient/edit.vue'),
      beforeEnter: ACLReqFunction('patient_edit'),
    },
    {
      path: 'detail/:id/:tab?/:invoiceId?',
      name: 'patient-detail',
      meta: {
        socketRoom: true,
        socketRoomNameIsDynamic: true,
        socketRoomNamePath: 'fullPath',
      },
      component: () => import('./views/Patient/detail.vue'),
      beforeEnter: ACLReqFunction('patient_detail'),
    },
    ],
  },
  {
    path: '/occupationalService',
    name: 'occupationalService',
    component: () => import('./views/OccupationalService/index.vue'),
    props: {
      navigation: true,
      aclReqFunction: 'occupationalService_list',
    },
    children: [{
      path: '/occupationalService/list',
      name: 'occupationalService_list',
      component: () => import('./views/OccupationalService/list.vue'),
      beforeEnter: ACLReqFunction('occupationalService_list'),
      props: {
        aclReqFunction: 'occupationalService_list',
      },
    },
    ],
  },
  {
    path: '/print',
    name: 'print',
    component: Print,
    props: {
      navigation: false,
    },
    children: [
      {
        path: 'tax-invoice',
        name: 'taxInvoice',
        component: TaxInvoiceTemplate,
      },
      {
        path: 'categorized-tax-invoice',
        name: 'categorizedTaxInvoice',
        component: CategorizedTaxInvoiceTemplate,
      },
      {
        path: 'discharge-summary',
        name: 'dischargeSummary',
        component: DischargeSummaryTemplate,
      },
      {
        path: 'patient-label',
        name: 'patientLabel',
        component: PatientLabelTemplate,
      },
      {
        path: 'patient-consult-note',
        name: 'patientConsultNote',
        component: PatientConsultNote,
      },
      {
        path: 'occupational-patient-consult-note',
        name: 'occupationalPatientConsultNote',
        component: OccupationalPatientConsultNote,
      },
      {
        path: 'lab-label',
        name: 'labLabel',
        component: LabLabelTemplate,
      },
      {
        path: 'medical-label',
        name: 'medicalLabel',
        component: PrintMedicalLabel,
      },
      {
        path: 'all-medical-labels',
        name: 'allMedicalLabels',
        component: PrintAllMedicalLabels,
      },
      {
        path: 'packing-list',
        name: 'packingList',
        component: PrintPackingList,
      },
      {
        path: 'letter',
        name: 'patientLetter',
        component: PatientLetter,
      },
      {
        path: 'pharmacy-medlabel/:id',
        name: 'printmedlabelsingle',
        component: PharmacyMedLabelSingle,
      },
      {
        path: 'pharmacy-medlabel-all',
        name: 'printmedlabelall',
        component: PharmacyMedLabelAll,
      },
      {
        path: 'queue-card',
        name: 'queueCard',
        component: PrintQueueCard,
      },
      {
        path: 'queue-card-ma-kuang',
        name: 'queueCardMaKuang',
        component: PrintQueueCardMaKuang,
      },
      {
        path: 'individual-corp',
        name: 'individualCorp',
        component: IndividualCorpInvoice,
      },
      {
        path: 'bulk-corp-insurance',
        name: 'bulkCorpInsurance',
        component: BulkCorpInsurance,
      },
      {
        path: 'classification-invoices',
        name: 'classificationInvoices',
        component: ClassificationInvoices,
      },
      {
        path: 'creditdebit',
        name: 'CreditDebitPrint',
        component: CreditDebitPrintIndex,
      },
      {
        path: 'feedback',
        name: 'FeedbackPrint',
        component: FeedbackPrintIndex,
      },
      {
        path: 'package-transfer-receipt',
        name: 'PackageTransferReceipt',
        component: PackageTransferReceipt,
      },
      {
        path: 'typing-note',
        name: 'TypingNote',
        component: TypingNote,
      },
      {
        path: 'purchase-order-print',
        name: 'PurchaseOrderPrint',
        component: PurchaseOrder,
      },
      {
        path: 'prescription-form',
        name: 'PrescriptionForm',
        component: PrescriptionForm,
      },
      {
        path: 'tcm-invoices',
        name: 'tcmPrintInvoices',
        component: TcmPrintInvoices,
      },
      {
        path: 'tcm-print-herbal-medicine-order',
        name: 'tcmPrintHerbalMedicineOrder',
        component: TcmPrintHerbalMedicineOrder,
      },
      {
        path: 'tcm-treatment-record',
        name: 'tcmPrintTreatmentRecord',
        component: TcmPrintTreatmentRecord,
      },
      {
        path: 'compiled-report-print',
        name: 'compiledReportPrint',
        component: CompiledReport,
      },
      {
        path: 'integration-report-print',
        name: 'integrationReportPrint',
        component: IntegrationReport,
      },
    ],
  },
  {
    path: '/payment',
    name: 'payment',
    component: () => import('./views/Payment/index.vue'),
    props: {
      navigation: true,
    },
    children: [{
      path: 'list',
      name: 'payment-list',
      component: () => import('./views/Payment/list.vue'),
      beforeEnter: ACLReqFunction('payment_main'),
    },
    {
      path: 'new',
      name: 'payment-new',
      component: () => import('./views/Payment/new.vue'),
      beforeEnter: ACLReqFunction('payment_new'),
    },
    ],
  },
  {
    path: '/letterbuilder',
    name: 'letterbuilder',
    component: () => import('./views/LetterBuilder/main.vue'),
    props: {
      navigation: true,
    },
    children: [
      {
        path: 'list',
        name: 'letterbuilder-list',
        component: () => import('./views/LetterBuilder/list.vue'),
        beforeEnter: ACLReqFunction('letterbuilder_view'),
        props: {
          aclReqFunction: 'letterbuilder_view',
        },
      },
      {
        path: 'edit/:id',
        name: 'letterbuilder-form',
        component: () => import('./views/LetterBuilder/form.vue'),
        beforeEnter: ACLReqFunction('letterbuilder_add_update'),
        props: {
          aclReqFunction: 'letterbuilder_add_update',
        },
      },
    ],
  },
  {
    path: '/chartingbuilder',
    name: 'chartingbuilder',
    component: () => import('./views/ChartingBuilder/main.vue'),
    props: {
      navigation: true,
    },
    children: [
      {
        path: 'list',
        name: 'chartingbuilder-list',
        component: () => import('./views/ChartingBuilder/list.vue'),
        beforeEnter: ACLReqFunction('chartingbuilder_view'),
        props: {
          aclReqFunction: 'chartingbuilder_view',
        },
      },
    ],
  },
  {
    path: '/inventory',
    name: 'inventory',
    component: () => import('./views/Inventory/main.vue'),
    props: {
      navigation: true,
    },
    children: [{
      path: 'list',
      name: 'inventory-list',
      component: () => import('./views/Inventory/list.vue'),
      beforeEnter: ACLReqFunction('inventory_list'),
      props: {
        aclReqFunction: 'inventory_list',
      },
    },
    {
      path: 'new',
      name: 'inventory-new',
      component: () => import('./views/Inventory/new.vue'),
      beforeEnter: ACLReqFunction('inventory_new'),
      props: {
        aclReqFunction: 'inventory_new',
      },
    },
    {
      path: 'edit/:id',
      name: 'inventory-edit',
      component: () => import('./views/Inventory/edit.vue'),
      beforeEnter: ACLReqFunction('inventory_new'),
      props: {
        aclReqFunction: 'inventory_new',
      },
    },
    {
      path: 'ImportInventory',
      name: 'import-inventory',
      component: () => import('./views/Inventory/ImportInventory.vue'),
      beforeEnter: ACLReqFunction('inventory_import'),
      props: {
        aclReqFunction: 'inventory_import',
      },
    },
    ],
  },
  {
    path: '/transfer',
    name: 'inventory-transfer',
    component: () => import('./views/Inventory/main.vue'),
    props: {
      navigation: true,
    },
    children: [{
      path: 'list',
      name: 'inventory-transfer-list',
      component: () => import('./views/InventoryTransfer/list.vue'),
      beforeEnter: ACLReqFunction('inventory_list'),
      props: {
        aclReqFunction: 'inventory_list',
      },
    },
    {
      path: 'new',
      name: 'inventory-transfer-new',
      component: () => import('./views/InventoryTransfer/new.vue'),
      beforeEnter: ACLReqFunction('inventory_new'),
      props: {
        aclReqFunction: 'inventory_new',
      },
    },
    {
      path: 'edit/:id',
      name: 'inventory-transfer-edit',
      component: () => import('./views/InventoryTransfer/edit.vue'),
      beforeEnter: ACLReqFunction('inventory_edit'),
      props: {
        aclReqFunction: 'inventory_edit',
      },
    },
    {
      path: 'edit/:id',
      name: 'inventory-edit',
      component: () => import('./views/Inventory/edit.vue'),
      beforeEnter: ACLReqFunction('inventory_new'),
      props: {
        aclReqFunction: 'inventory_new',
      },
    },
    {
      path: 'ImportInventory',
      name: 'import-inventory',
      component: () => import('./views/Inventory/ImportInventory.vue'),
      beforeEnter: ACLReqFunction('inventory_import'),
      props: {
        aclReqFunction: 'inventory_import',
      },
    },
    ],
  },
  {
    path: '/health-screening',
    name: 'healthScreening',
    component: () => import('./views/HealthScreening/index.vue'),
    props: {
      navigation: true,
      aclReqFunction: 'health_screening',
    },
  },
  {
    path: '/pharmacy',
    name: 'pharmacy',
    component: () => import('./views/Pharmacy/Index.vue'),
    beforeEnter: ACLReqFunction('pharmacy_list'),
    props: {
      navigation: true,
      aclReqFunction: 'pharmacy_list',
    },
  },
  {
    path: '/integrated-test',
    name: 'integratedTest',
    component: () => import('./views/IntegratedTest/index.vue'),
    beforeEnter: ACLReqFunction('integrated_test_list'),
    props: {
      navigation: true,
      aclReqFunction: 'integrated_test_list',
    },
  },
  {
    path: '/lab-tests',
    name: 'labTest',
    component: () => import('./views/LabTest/index.vue'),
    beforeEnter: ACLReqFunction('lab_test_list'),
    props: {
      navigation: true,
      aclReqFunction: 'lab_test_list',
    },
  },
  {
    path: '/lab-tests-create',
    name: 'labTestCreate',
    component: () => import('./views/LabTest/create.vue'),
    beforeEnter: ACLReqFunction('lab_test_order_create'),
    props: {
      navigation: true,
      aclReqFunction: 'lab_test_order_create',
    },
  },
  {
    path: '/campaign',
    name: 'campaign',
    component: () => import('./views/Campaign/index.vue'),
    beforeEnter: ACLReqFunction('campaign_list'),
    props: {
      navigation: true,
      aclReqFunction: 'campaign_list',
    },
    children: [{
      path: 'upsert',
      name: 'campaign-upsert',
      component: () => import('./views/Campaign/upsert.vue'),
      beforeEnter: ACLReqFunction('campaign_create'),
      props: {
        navigation: true,
        aclReqFunction: 'campaign_create',
      },
    }, {
      path: 'list',
      name: 'campaign-list',
      component: () => import('./views/Campaign/list.vue'),
      beforeEnter: ACLReqFunction('campaign_list'),
      props: {
        navigation: true,
        aclReqFunction: 'campaign_list',
      },
    }],
  },
  {
    path: '/marketing',
    name: 'marketing',
    component: () => import('./views/Marketing/index.vue'),
    props: {
      navigation: true,
    },
    children: [{
      path: 'upsert',
      name: 'marketing-upsert',
      component: () => import('./views/Marketing/upsert.vue'),
      props: {
        navigation: true,
      },
    }, {
      path: 'list',
      name: 'marketing-list',
      component: () => import('./views/Marketing/list.vue'),
      props: {
        navigation: true,
      },
    }, {
      path: 'mass-sending',
      name: 'marketing-mass-sending',
      component: () => import('./views/Marketing/massSending.vue'),
      props: {
        navigation: true,
      },
    }, {
      path: 'mass-sending-new',
      name: 'marketing-mass-sending-new',
      component: () => import('./views/Marketing/massSendingNew.vue'),
      props: {
        navigation: true,
      },
    }, {
      path: 'mass-sending-template-new',
      name: 'marketing-mass-sending-template-new',
      component: () => import('./views/Marketing/massSendingTemplateNew.vue'),
      props: {
        navigation: true,
      },
    }],
  },
  {
    path: '/reports',
    name: 'reports',
    component: () => import('./views/Reports/index.vue'),
    props: {
      navigation: true,
    },
    children: [{
      path: 'list',
      name: 'list',
      component: () => import('./views/Reports/list.vue'),
      beforeEnter: ACLReqFunction('reports_main'),
      props: {
        aclReqFunction: 'reports_main',
      },
    },
    {
      path: 'queue-list',
      name: 'queue-list',
      component: () => import('./views/Reports/Patient/QueueList.vue'),
      beforeEnter: ACLReqFunction('reports_queue_list'),
    },
    {
      path: 'patient-surveys',
      name: 'patient-surveys',
      component: () => import('./views/Reports/Patient/PatientSurveys.vue'),
    },
    {
      path: 'appointment-list',
      name: 'appointment-list',
      component: () => import('./views/Reports/Patient/AppointmentList.vue'),
    },
    {
      path: 'outstanding-patient-payment',
      name: 'outstanding-patient-payment',
      component: () => import('./views/Reports/Patient/OutstandingPatientPayment.vue'),
    },
    {
      path: 'health-check-data-overall-report',
      name: 'health-check-data-overall-report',
      component: () => import('./views/Reports/Patient/HealthCheckDataOverallReport.vue'),
    },
    {
      path: 'birthday-report',
      name: 'birthday-report',
      component: () => import('./views/Reports/Patient/BirthdayReport.vue'),
    },
    {
      path: 'basic-invoice',
      name: 'basic-invoice',
      component: () => import('./views/Reports/Financial/BasicInvoice.vue'),
      beforeEnter: ACLReqFunction('reports_basic_invoice'),
    },
    {
      path: 'collection',
      name: 'collection',
      component: () => import('./views/Reports/Financial/Collection.vue'),
      beforeEnter: ACLReqFunction('reports_collection'),
    },
    {
      path: 'providerInvoice',
      name: 'providerInvoice',
      component: () => import('./views/Reports/Financial/ProviderInvoice.vue'),
    },
    {
      path: 'cash-transaction',
      name: 'cash-transaction',
      component: () => import('./views/Reports/Financial/CashTransaction.vue'),
      beforeEnter: ACLReqFunction('reports_cash_transaction'),
    },
    {
      path: 'corporate-billing',
      name: 'corporate-billing',
      component: () => import('./views/Reports/Financial/CorpBilling.vue'),
      beforeEnter: ACLReqFunction('reports_corporate_billing'),
    },
    {
      path: 'corporate-admin-fee',
      name: 'corporate-admin-fee',
      component: () => import('./views/Reports/Financial/CorpAdminFee.vue'),
      beforeEnter: ACLReqFunction('reports_corporate_admin_fee'),
    },
    {
      path: 'commission',
      name: 'commission',
      component: () => import('./views/Reports/Financial/ConsolidatedCommission.vue'),
      beforeEnter: ACLReqFunction('reports_commission'),
    },
    {
      path: 'provider-commission',
      name: 'provider-commission',
      component: () => import('./views/Reports/Financial/ProviderCommission.vue'),
      beforeEnter: ACLReqFunction('reports_provider_commission_v2'),
    },
    {
      path: 'corporate-admin-fee',
      name: 'corporate-admin-fee',
      component: () => import('./views/Reports/Financial/CorpAdminFee.vue'),
      beforeEnter: ACLReqFunction('reports_admin_fee'),
    },
    {
      path: 'consolidated-summary',
      name: 'consolidated-summary',
      component: () => import('./views/Reports/Financial/ConsolidatedSummary/summary.vue'),
      beforeEnter: ACLReqFunction('reports_consolidated_summary'),
    },
    {
      path: 'credit-debit',
      name: 'credit-debit',
      component: () => import('./views/Reports/Financial/CreditDebit.vue'),
      beforeEnter: ACLReqFunction('reports_credit_debit_note'),
    },
    {
      path: 'detailed-invoice',
      name: 'detailed-invoice',
      component: () => import('./views/Reports/Financial/DetailedInvoice.vue'),
      beforeEnter: ACLReqFunction('reports_detailed_invoice_list'),
    },
    {
      path: 'basic-sales',
      name: 'basic-sales',
      component: () => import('./views/Reports/Financial/BasicSales.vue'),
      beforeEnter: ACLReqFunction('reports_basic_sales_mix'),
    },
    {
      path: 'detailed-sales',
      name: 'detailed-sales',
      component: () => import('./views/Reports/Financial/DetailedSales.vue'),
      beforeEnter: ACLReqFunction('reports_detailed_sales_mix'),
    },
    {
      path: 'quickbook-bill',
      name: 'quickbook-bill',
      component: () => import('./views/Reports/Financial/TVICQuickBookBill.vue'),
      // beforeEnter: ACLReqFunction('quickbook-bill'),
    },
    {
      path: 'quickbook-invoice',
      name: 'quickbook-invoice',
      component: () => import('./views/Reports/Financial/TVICQuickBookInvoice.vue'),
      // beforeEnter: ACLReqFunction('quickbook-invoice'),
    },
    {
      path: 'smg-sap-report',
      name: 'smg-sap-report',
      component: () => import('./views/Reports/Financial/SMGSAPReport.vue'),
      beforeEnter: ACLReqFunction('smg_sap_report'),
    },
    {
      path: 'referral-report',
      name: 'referral-report',
      component: () => import('./views/Reports/Financial/ReferralReport.vue'),
      beforeEnter: ACLReqFunction('reports_referral_report'),
    },
    {
      path: 'patient-registers',
      name: 'patient-registers',
      component: () => import('./views/Reports/Patient/PatientRegisters.vue'),
    },
    {
      path: 'inventory-movement',
      name: 'inventory-movement',
      component: () => import('./views/Reports/Inventory/InventoryMovement.vue'),
      beforeEnter: ACLReqFunction('reports_inventory_movement'),
    },
    {
      path: 'inventory-stock-batch',
      name: 'inventory-stock-batch',
      component: () => import('./views/Reports/Inventory/InventoryStockBatch.vue'),
      beforeEnter: ACLReqFunction('reports_inventory_stock_batch'),
    },
    {
      path: 'package-transfer',
      name: 'package-transfer',
      component: () => import('./views/Reports/Inventory/PackageTransfer.vue'),
      beforeEnter: ACLReqFunction('reports_inventory_stock_batch'),
    },
    {
      path: 'medical-notes',
      name: 'medical-notes',
      component: () => import('./views/Reports/Notes/MedicalNotes.vue'),
    },
    {
      path: 'inventory-stock-taking',
      name: 'inventory-stock-taking',
      component: () => import('./views/Reports/Inventory/InventoryStockTaking.vue'),
      beforeEnter: ACLReqFunction('reports_inventory_stock_taking'),
    },
    {
      path: 'membership-point',
      name: 'membership-point',
      component: () => import('./views/Reports/Patient/MembershipPoint.vue'),
    },
    {
      path: 'prospect',
      name: 'prospect',
      component: () => import('./views/Reports/Patient/ProspectReport.vue'),
    },
    {
      path: 'outstanding-packages',
      name: 'outstanding-packages',
      component: () => import('./views/Reports/Patient/OutstandingPackagesReport.vue'),
      beforeEnter: ACLReqFunction('reports_outstanding_packages'),
    },
    {
      path: 'unuploaded-employee-summaries',
      name: 'unuploaded-employee-summaries',
      component: () => import('./views/Reports/Patient/UnuploadedEmployeeSummariesReport.vue'),
      beforeEnter: ACLReqFunction('occupationalService_list'),
    },
    {
      path: 'drugorder',
      name: 'drugorder',
      component: () => import('./views/Reports/Inventory/DrugOrder.vue'),
      beforeEnter: ACLReqFunction('reports_drug_order'),
    },
    {
      path: 'teleconsult',
      name: 'teleconsult',
      component: () => import('./views/Reports/Financial/Teleconsult.vue'),
    },
    {
      path: 'virtual-currency-usage-report',
      name: 'virtual-currency-usage-report',
      component: () => import('./views/Reports/Financial/VirtualCurrencyUsageReport.vue'),
      beforeEnter: ACLReqFunction('reports_virtual_currency_usage'),
    },
    {
      path: 'financial-counselling-report',
      name: 'financial-counselling-report',
      component: () => import('./views/Reports/Financial/financialCounsellingReport.vue'),
    },
    ],
  },
  {
    path: '/provider',
    name: 'provider',
    component: () => import('./views/Provider/index.vue'),
    props: {
      navigation: true,
    },
    children: [
      {
        path: 'list',
        name: 'provider-list',
        component: () => import('./views/Provider/list.vue'),
        beforeEnter: ACLReqFunction('provider_list'),
        props: {
          aclReqFunction: 'provider_list',
        },
      },
      {
        path: 'upsert',
        name: 'provider-upsert',
        component: () => import('./views/Provider/upsert.vue'),
        beforeEnter: ACLAllReqFunctions(['provider_new', 'provider_edit']),
        props: {
          aclReqFunction: 'provider_new',
        },
      },
      {
        path: 'profile/:id/:tab?/:invoiceId?',
        name: 'provider-profile',
        meta: {
          socketRoom: true,
          socketRoomNameIsDynamic: true,
          socketRoomNamePath: 'fullPath',
        },
        component: () => import('./views/Provider/profile.vue'),
        beforeEnter: ACLAllReqFunctions(['provider_new', 'provider_edit']),
        props: {
          aclReqFunction: 'provider_new',
        },
      },
    ],
  },
  {
    path: '/room',
    name: 'room',
    component: () => import('./views/Room/index.vue'),
    props: {
      navigation: true,
    },
    children: [
      {
        path: 'list',
        name: 'room-list',
        component: () => import('./views/Room/list.vue'),
        beforeEnter: ACLReqFunction('rooms_view'),
        props: {
          aclReqFunction: 'rooms_view',
        },
      },
      {
        path: 'upsert',
        name: 'room-upsert',
        component: () => import('./views/Room/upsert.vue'),
        beforeEnter: ACLReqFunction('rooms_add_update'),
        props: {
          aclReqFunction: 'rooms_add_update',
        },
      },
    ],
  },
  {
    path: '/ipd-room',
    name: 'ipd-room',
    component: () => import('./views/IpdRoom/index.vue'),
    props: {
      navigation: true,
    },
    children: [
      {
        path: 'list',
        name: 'ipd-room-list',
        component: () => import('./views/IpdRoom/list.vue'),
        beforeEnter: ACLReqFunction('ipd_rooms_view'),
        props: {
          aclReqFunction: 'ipd_rooms_view',
        },
      },
      {
        path: 'upsert',
        name: 'ipd-room-upsert',
        component: () => import('./views/IpdRoom/upsert.vue'),
        beforeEnter: ACLReqFunction('ipd_rooms_add_update'),
        props: {
          aclReqFunction: 'ipd_rooms_add_update',
        },
      },
    ],
  },
  {
    path: '/area',
    name: 'area',
    component: () => import('./views/Area/index.vue'),
    props: {
      navigation: true,
    },
    children: [
      {
        path: 'list',
        name: 'area-list',
        component: () => import('./views/Area/list.vue'),
        beforeEnter: ACLReqFunction('area_view'),
        props: {
          aclReqFunction: 'area_view',
        },
      },
      {
        path: 'upsert',
        name: 'area-upsert',
        component: () => import('./views/Area/upsert.vue'),
        beforeEnter: ACLReqFunction('area_add_update'),
        props: {
          aclReqFunction: 'area_add_update',
        },
      },
    ],
  },
  {
    path: '/ipd-pharmacy',
    name: 'ipd-pharmacy',
    component: () => import('./views/IpdPharmacy/index.vue'),
    props: {
      navigation: true,
    },
    children: [
      {
        path: 'list',
        name: 'ipd-pharmacy-list',
        component: () => import('./views/IpdPharmacy/list.vue'),
        beforeEnter: ACLReqFunction('ipd_pharmacy_list'),
        props: {
          aclReqFunction: 'ipd_pharmacy_list',
        },
      },
    ],
  },
  {
    path: '/Order',
    name: 'order',
    component: () => import('./views/Order/main.vue'),
    props: {
      navigation: true,
    },
    children: [{
      path: 'list',
      name: 'order-list',
      component: () => import('./views/Order/list.vue'),
      beforeEnter: ACLReqFunction('order_list'),
      props: {
        aclReqFunction: 'order_list',
      },
    },
    {
      path: 'new',
      name: 'order-new',
      component: () => import('./views/Order/new.vue'),
      beforeEnter: ACLReqFunction('order_new'),
      props: true,
    },
    {
      path: 'edit/:id',
      name: 'order-edit',
      component: () => import('./views/Order/edit.vue'),
      beforeEnter: ACLReqFunction('order_edit'),
    },
    ],
  },
  {
    path: '/purchaseorder',
    name: 'purchase-order',
    component: () => import('./views/PurchaseOrder/main.vue'),
    props: {
      navigation: true,
    },
    children: [
      {
        path: 'list',
        name: 'purchase-order-list',
        component: () => import('./views/PurchaseOrder/list.vue'),
        beforeEnter: ACLReqFunction('purchaseorder_list'),
        props: {
          aclReqFunction: 'purchaseorder_list',
        },
      },
      {
        path: 'new',
        name: 'purchase-order-new',
        component: () => import('./views/PurchaseOrder/form.vue'),
        // beforeEnter: ACLReqFunction('purchase_order_new'),
        props: {
          isNew: true,
          // aclReqFunction: 'purchase_order_new',
        },
      },
      {
        path: 'edit/:id',
        name: 'purchase-order-edit',
        component: () => import('./views/PurchaseOrder/edit.vue'),
        // beforeEnter: ACLReqFunction('purchase_order_edit'),
      },
    ],
  },
  {
    path: '/corporate',
    name: 'corporate',
    component: () => import('./views/Corporate/main.vue'),
    props: {
      navigation: true,
    },
    children: [{
      path: 'list',
      name: 'corporate-list',
      component: () => import('./views/Corporate/list.vue'),
      beforeEnter: ACLReqFunction('corporate_main'),
      props: {
        aclReqFunction: 'corporate_main',
      },
    },
    {
      path: 'new',
      name: 'corporate-new',
      component: () => import('./views/Corporate/new.vue'),
      beforeEnter: ACLReqFunction('corporate_create'),
    },
    {
      path: 'edit/:id',
      name: 'corporate-edit',
      component: () => import('./views/Corporate/edit.vue'),
    },
    ],
  },
  {
    path: '/corporate-invoice',
    name: 'corporate-invoice',
    component: () => import('./views/CorporateInvoice/index.vue'),
    props: {
      navigation: true,
    },
    children: [
      {
        path: 'list',
        name: 'corporate-invoice-list',
        component: () => import('./views/CorporateInvoice/list.vue'),
        beforeEnter: ACLReqFunction('corporate-invoice_list'),
        props: {
          aclReqFunction: 'corporate-invoice_list',
        },
      },
      {
        path: 'classification-list',
        name: 'corporate-invoice-classification-list',
        component: () => import('./views/CorporateInvoice/classification-list.vue'),
        beforeEnter: ACLReqFunction('japanese_insurance_form'),
      },
    ],
  },
  {
    path: '/doctor-remuneration',
    name: 'doctor-remuneration-index',
    component: () => import('./views/CorporateInvoice/doctor-remuneration.vue'),
    beforeEnter: ACLReqFunction('doctor-remuneration-report'),
    props: {
      navigation: true,
      aclReqFunction: 'doctor-remuneration-report',
    },
  },

  {
    path: '/adjustment',
    name: 'adjustment',
    component: () => import('./views/Adjustment/main.vue'),
    props: {
      navigation: true,
    },
    children: [{
      path: 'list',
      name: 'adjustment-list',
      component: () => import('./views/Adjustment/list.vue'),
      beforeEnter: ACLReqFunction('inventory_adjustment_view'),
      props: {
        aclReqFunction: 'inventory_adjustment_view',
      },
    },
    {
      path: 'new',
      name: 'adjustment-new',
      component: () => import('./views/Adjustment/new.vue'),
      beforeEnter: ACLReqFunction('inventory_adjustment_create'),
      props: {
        aclReqFunction: 'inventory_adjustment_create',
      },
    },
    {
      path: 'edit/:id',
      name: 'adjustment-edit',
      component: () => import('./views/Adjustment/edit.vue'),
      beforeEnter: ACLReqFunction('inventory_adjustment_edit'),
      props: {
        aclReqFunction: 'inventory_adjustment_edit',
      },
    },
    ],
  },
  {
    path: '/auditlog',
    name: 'audit-log',
    component: () => import('./views/AuditLog/main.vue'),
    props: {
      navigation: true,
    },
    children: [{
      path: 'list',
      name: 'audit-log-list',
      component: () => import('./views/AuditLog/list.vue'),
      beforeEnter: ACLReqFunction('audit_log_view'),
      props: {
        aclReqFunction: 'audit_log_view',
      },
    },
    ],
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('./views/ACL/User/index.vue'),
    props: {
      navigation: true,
    },
    children: [{
      path: '/user/list',
      name: 'user-list',
      component: () => import('./views/ACL/User/list.vue'),
      beforeEnter: ACLReqFunction('user_list'),
      props: {
        aclReqFunction: 'user_list',
      },
    },
    {
      path: '/user/new',
      name: 'user-new',
      component: () => import('./views/ACL/User/new.vue'),
      props: true,
      beforeEnter: ACLReqFunction('user_new'),
    },
    ],
  },
  {
    path: '/role',
    name: 'role',
    component: () => import('./views/ACL/Role/index.vue'),
    props: {
      navigation: true,
    },
    children: [{
      path: '/role/list',
      name: 'role-list',
      component: () => import('./views/ACL/Role/list.vue'),
      beforeEnter: ACLReqFunction('role_list'),
      props: {
        aclReqFunction: 'role_list',
      },
    },
    {
      path: '/role/new',
      name: 'role-new',
      component: () => import('./views/ACL/Role/new.vue'),
      props: true,
      beforeEnter: ACLReqFunction('role_new'),
    },
    {
      path: '/role/edit/:id',
      name: 'role-edit',
      component: () => import('./views/ACL/Role/edit.vue'),
      props: true,
    },
    ],
  },
  {
    path: '/profile',
    name: 'user-profile',
    component: () => import('./views/User/index.vue'),
    props: {
      navigation: true,
    },
    children: [
      {
        path: '/profile/notifications-settings',
        name: 'notifications-settings',
        component: () => import('./views/User/notifications-settings.vue'),
        props: true,
      },
      {
        path: '/profile/password',
        name: 'password-change',
        component: () => import('./views/User/password.vue'),
        props: true,
      },
    ],
  },
  {
    path: '/password-resets/:token/:orgCode',
    name: 'passwordReset',
    component: () => import('./views/ACL/password_reset.vue'),
    props: {
      navigation: false,
    },
  },
  {
    path: '/forbidden',
    name: 'forbidden',
    component: () => import('./views/Forbidden.vue'),
    props: {
      navigation: false,
    },
  },
  {
    path: '/config',
    name: 'config',
    component: () => import('./views/Config/index.vue'),
    beforeEnter: ACLReqFunction('system_setup_main'),
    props: {
      navigation: true,
      aclReqFunction: 'system_setup_main',
    },
  },
  {
    path: '/medservice',
    name: 'medservice',
    component: () => import('./views/MedService/index.vue'),
    props: {
      navigation: true,
    },
    children: [
      {
        path: 'list',
        name: 'medservice-list',
        component: () => import('./views/MedService/list.vue'),
        beforeEnter: ACLReqFunction('medservices_view'),
        props: {
          aclReqFunction: 'medservices_view',
        },
      },
      {
        path: 'upsert',
        name: 'medservice-upsert',
        component: () => import('./views/MedService/upsert.vue'),
        beforeEnter: ACLReqFunction('medservices_add_update'),
        props: {
          aclReqFunction: 'medservices_add_update',
        },
      },
    ],
  },
  {
    path: '/task',
    name: 'task',
    component: () => import('./views/Task/index.vue'),
    props: {
      navigation: true,
    },
    children: [
      {
        path: 'main',
        name: 'task-main',
        component: () => import('./views/Task/mainTab.vue'),
      },
      {
        path: 'upsert/:type?',
        name: 'task-upsert',
        component: () => import('./views/Task/upsert.vue'),
        beforeEnter: ACLReqFunction('task_add_update'),
        props: {
          aclReqFunction: 'task_add_update',
        },
      },
    ],
  },
  {
    path: '/receive-hr-notification-detail',
    name: 'receive-hr-notification-detail',
    component: () => import('./views/ReceiveHrNotification/detailView.vue'),
    props: {
      navigation: true,
    },
  },
  {
    path: '/supplier',
    name: 'supplier',
    component: () => import('./views/Supplier/index.vue'),
    props: {
      navigation: true,
    },
    children: [
      {
        path: 'list',
        name: 'supplier-list',
        component: () => import('./views/Supplier/list.vue'),
        beforeEnter: ACLReqFunction('suppliers_view'),
        props: {
          aclReqFunction: 'suppliers_view',
        },
      },
      {
        path: 'upsert',
        name: 'supplier-upsert',
        component: () => import('./views/Supplier/upsert.vue'),
        beforeEnter: ACLReqFunction('suppliers_add_update'),
        props: {
          aclReqFunction: 'suppliers_add_update',
        },
      },
    ],
  },
  {
    path: '/stockcard',
    component: () => import('./views/StockCard/index.vue'),
    beforeEnter: ACLReqFunction('inventory_stockcard'),
    props: {
      navigation: true,
      aclReqFunction: 'inventory_stockcard',
    },
    children: [
      {
        path: 'list',
        name: 'stockcard-list',
        component: () => import('./views/StockCard/list.vue'),
        beforeEnter: ACLReqFunction('inventory_stockcard'),
        props: {
          aclReqFunction: 'inventory_stockcard',
        },
      },
      {
        path: 'create',
        name: 'stockcard-create',
        component: () => import('./views/StockCard/create.vue'),
        beforeEnter: ACLReqFunction('inventory_stockcard_create'),
        props: {
          aclReqFunction: 'inventory_stockcard_create',
        },
      },
    ],
  },
  {
    path: '/transfer',
    component: () => import('./views/Transfer/index.vue'),
    props: {
      navigation: true,
    },
    children: [
      {
        path: 'point',
        name: 'transfer-point',
        component: () => import('./views/Transfer/point.vue'),
        beforeEnter: ACLReqFunction('transfer_credit'),
        props: {
          aclReqFunction: 'transfer_credit',
        },
      },
      {
        path: 'package',
        name: 'transfer-package',
        component: () => import('./views/Transfer/package.vue'),
        beforeEnter: ACLReqFunction('transfer_package'),
        props: {
          aclReqFunction: 'transfer_package',
        },
      },
    ],
  },
  {
    path: '/messages',
    component: () => import('./views/Messages/index.vue'),
    props: {
      navigation: true,
    },
    children: [
      {
        path: 'list',
        name: 'message-list',
        component: () => import('./views/Messages/list.vue'),
        props: {
        },
      },
    ],
  },
  {
    path: '/ot',
    name: 'ot',
    component: () => import('./views/OT/index.vue'),
    props: {
      navigation: true,
    },
    // children: [{
    //   path: '/user/list',
    //   name: 'user-list',
    //   component: () => import('./views/ACL/User/list.vue'),
    //   beforeEnter: ACLReqFunction('user_list'),
    //   props: {
    //     aclReqFunction: 'user_list',
    //   },
    // },
    // {
    //   path: '/user/new',
    //   name: 'user-new',
    //   component: () => import('./views/ACL/User/new.vue'),
    //   props: true,
    //   beforeEnter: ACLReqFunction('user_new'),
    // },
    // ],
  },
  {
    path: '/hr',
    name: 'human-resource',
    component: () => import('./views/HumanResource/index.vue'),
    props: {
      navigation: true,
    },
    children: [
      {
        path: 'dashboard',
        name: 'hr-dashboard',
        component: () => import('./views/HumanResource/settings.vue'),
        beforeEnter: ACLReqFunction('hr_dashboard'),
        props: {
          aclReqFunction: 'hr_dashboard',
        },
      },
      {
        path: 'settings',
        name: 'hr-settings',
        component: () => import('./views/HumanResource/settings.vue'),
        beforeEnter: ACLReqFunction('hr_setting'),
        props: {
          aclReqFunction: 'hr_setting',
        },
      },
      {
        path: 'internal-notification',
        name: 'hr-internal-notification',
        component: () => import('./views/HumanResource/Notification/list.vue'),
        beforeEnter: ACLReqFunction('hr_internal_notification'),
        props: {
          navigation: true,
          aclReqFunction: 'hr_internal_notification',
        },
      },
      {
        path: 'internal-notification-new',
        name: 'hr-internal-notification-new',
        component: () => import('./views/HumanResource/Notification/new.vue'),
        beforeEnter: ACLReqFunction('hr_internal_notification'),
        props: {
          navigation: true,
          aclReqFunction: 'hr_internal_notification',
        },
      },
      {
        path: 'internal-notification-edit/:id',
        name: 'hr-internal-notification-edit',
        component: () => import('./views/HumanResource/Notification/edit.vue'),
        beforeEnter: ACLReqFunction('hr_internal_notification'),
        props: {
          navigation: true,
          aclReqFunction: 'hr_internal_notification',
        },
      },
    ],
  },
  {
    path: '/exchange-rate',
    name: 'exchange-rate',
    component: () => import('./views/Config/index.vue'),
    beforeEnter: ACLReqFunction('exchange_rate_edit'),
    props: {
      navigation: true,
      aclReqFunction: 'exchange_rate_edit',
    },
  },
  {
    path: '/nursing',
    component: () => import('./views/Nursing/index.vue'),
    beforeEnter: ACLReqFunction('nursing_list'),
    props: {
      navigation: true,
      aclReqFunction: 'nursing_list',
    },
    children: [
      {
        path: 'list',
        name: 'nursing-list',
        component: () => import('./views/Nursing/list.vue'),
        beforeEnter: ACLReqFunction('nursing_list'),
        props: {
          aclReqFunction: 'nursing_list',
        },
      },
    ],
  },
  {
    path: '/bed/list',
    name: 'bed',
    component: () => import(/* webpackPrefetch: true */ './views/Bed/list.vue'),
    alias: '/bed',
    props: {
      navigation: true,
      // aclReqFunction: 'bed_list',
    },
    // beforeEnter: ACLReqFunction('bed_list'),
  },
  {
    path: '/tele',
    name: 'TeleConsultPublic',
    component: () => import('./views/TeleConsult.vue'),
    props: {
      navigation: false,
    },
  },
  {
    path: '/smsLogs',
    name: 'smsLogs',
    component: () => import('./views/SmsLogs/main.vue'),
    props: {
      navigation: true,
    },
    children: [{
      path: '/smsLogs/list',
      name: 'smsLogs-list',
      component: () => import('./views/SmsLogs/list.vue'),
      beforeEnter: ACLReqFunction('sms_audit_log_view'),
      props: {
        aclReqFunction: 'sms_audit_log_view',
      },
    },
    ],
  },
  {
    path: '/lineLogs',
    name: 'lineLogs',
    component: () => import('./views/LineLogs/main.vue'),
    props: {
      navigation: true,
    },
    children: [{
      path: '/lineLogs/list',
      name: 'lineLogs-list',
      component: () => import('./views/LineLogs/list.vue'),
      props: {
        navigation: true,
      },
    },
    ],
  },
  {
    path: '/emailLogs',
    name: 'emailLogs',
    component: () => import('./views/EmailLogs/main.vue'),
    props: {
      navigation: true,
    },
    children: [{
      path: '/emailLogs/list',
      name: 'emailLogs-list',
      component: () => import('./views/EmailLogs/list.vue'),
      beforeEnter: ACLReqFunction('email_audit_log_view'),
      props: {
        aclReqFunction: 'email_audit_log_view',
      },
    },
    ],
  },
  {
    path: '/customer/appointment/:id',
    name: 'CustomerAppointment',
    component: () => import('./views/CustomerAppointment.vue'),
    props: {
      navigation: false,
      withoutLogin: true,
    },
  },
  {
    path: '/customer/qrcodeReminder/:id',
    name: 'CustomerQRCodeReminder',
    component: () => import('./views/CustomerQRCodeReminder.vue'),
    props: {
      navigation: false,
      withoutLogin: true,
    },
  },
  {
    path: '/provider-invoice/provider-list',
    name: 'ProviderInvoice',
    component: () => import('./views/Provider/list.vue'),
    props: {
      navigation: true,
    },
  },
  {
    path: '/external/questionnaire/login',
    name: 'QuestionnaireLogin',
    component: () => import('./views/External/Questionnaire/login.vue'),
    props: {
      navigation: false,
    },
  },
  {
    path: '/external/questionnaire/index',
    name: 'QuestionnaireIndex',
    component: () => import('./views/External/Questionnaire/index.vue'),
    props: {
      navigation: false,
    },
  },
  {
    path: '/wipe-cache',
    name: 'WipeCache',
    component: WipeCache,
    props: {
      navigation: false,
    },
  },
  {
    path: '/print-prescription',
    name: 'PrintTcmPrescription',
    component: Prescription,
    props: {
      navigation: true,
    },
  },
  ],
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login', '/twoFactor', '/logout', '/forbidden', '/tele', '/test-report', '/lab-tests-report'];
  const publicRoot = '/external';
  const authRequired = !to.path.includes(publicRoot) && !publicPages.includes(to.path) && to.name !== 'passwordReset' && to.name !== 'CustomerQRCodeReminder' && to.name !== 'CustomerAppointment';
  const loggedIn = to.path !== '/twoFactor' ? localStorage.getItem('user') : null;
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});

export default router;
