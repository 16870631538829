<template>
  <div class="discharge-summary" v-if="isDataReady">
    <div class="row">
      <div class="col-6">
        <img :src="generalConfig.logoURL" :alt="generalConfig.clinicName"
          :style="{ marginLeft:`${logoMarginLeft}px`, width:`${logoWidth}px` }"
        >
      </div>
    </div>
    <h5 class="xlabel">Discharge Summary</h5>
    <div class="row">
      <div class="col-6">
        <span class="xlabel nd">Patient Name:</span>
        <span class="xvalue">
          {{ selectedPatient.title }} {{ selectedPatient.name }}
          {{ selectedPatient.surname }}
        </span>
      </div>
      <div class="col-6">
        <span class="xlabel nd">Date of Birth:</span>
        <span class="xvalue">{{ dob }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <span class="xlabel nd">Hospital Number:</span>
        <span class="xvalue">{{ selectedPatient.givenIdPrefix }}{{ selectedPatient.givenId }}</span>
      </div>
    </div>
    <div class="line-separation bold"></div>
    <div class="row">
      <div class="col-3">
        <span class="xlabel">Discharge Diagnosis:</span>
      </div>
      <div class="col-3">
        <span class="xvalue lb">{{ dischargeForm.dischargeDiagnosis }}</span>
      </div>
      <div class="col-1">
        <span class="xlabel">ICD10:</span>
      </div>
      <div class="col">
        <div v-for="item in dischargeForm.primaryDiagnosis" :key="`primary_${item.value}`">
          {{ item.text }}
        </div>
        <div v-for="item in dischargeForm.secondaryDiagnosis" :key="`sec_${item.value}`">
          {{ item.text }}
        </div>
      </div>
    </div>
    <div class="line-separation"></div>
    <div class="row">
      <div class="col-3">
        <span class="xlabel">Date of Admission:</span>
      </div>
      <div class="col-3">
        <span class="xvalue">{{ admission.createdAt| ddMonthYYYY }}</span>
      </div>
      <div class="col-3">
        <span class="xlabel">Date of Discharge:</span>
      </div>
      <div class="col-3">
        <span class="xvalue">{{ dischargeForm.finalisedTime | ddMonthYYYYFromX }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <span class="xlabel">Presenting Complaint:</span>
      </div>
      <div class="col">
        <span class="xvalue lb">{{ dischargeForm.presentComplain }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <span class="xlabel">Past Medical History:</span>
      </div>
      <div class="col-3">
        <span class="xlabel">Allergies:</span>
      </div>
      <div class="col">
        <span class="xvalue lb">{{ dischargeForm.allergies }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-3 offset-3">
        <span class="xlabel">Medical History:</span>
      </div>
      <div class="col">
        <span class="xvalue lb">{{ dischargeForm.medicalHistory }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-3 offset-3">
        <span class="xlabel">Surgical History:</span>
      </div>
      <div class="col">
        <span class="xvalue lb">{{ dischargeForm.surgicalHistory }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-3 offset-3">
        <span class="xlabel">Pre-admission Medications:</span>
      </div>
      <div class="col">
        <span class="xvalue lb">{{ dischargeForm.preAdmissionMedication }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <p class="xlabel">Inpatient Management and Issues:</p>
        <div class="xvalue lb">{{ dischargeForm.inpatientManagementIssue}}</div>
      </div>
    </div>
    <p class="xlabel mt-4">Inpatient Medications:</p>
    <table class="table ipt-med-table" v-if="inpatientMedecines.length > 0">
      <thead>
        <tr>
          <th>Date</th>
          <th>Medicine Name</th>
          <th>Quantity(Administered)</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="med in inpatientMedecines" :key="med._id">
          <td>{{ getRequestDate(med.dateRequested) }}</td>
          <td>{{ med.name }}</td>
          <td>{{ getAdminQty(med) }}</td>
        </tr>
      </tbody>
    </table>
    <div class="row">
      <div class="col-3">
        <span class="xlabel">Surgical Summary:</span>
      </div>
      <div class="col">
        <div class="xvalue lb">{{ dischargeForm.surgicalSummary }}</div>
      </div>
    </div>
    <!-- hide for now
    <div class="row">
      <div class="col-3">
        <span class="xlabel">Laboratory Results:</span>
      </div>
      <div class="col">
        <div class="xvalue"></div>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <span class="xlabel">Radiology Results:</span>
      </div>
      <div class="col">
        <div class="xvalue"></div>
      </div>
    </div>
    -->
    <div class="row">
      <div class="col-3">
        <span class="xlabel">Final Clinical Summary:</span>
      </div>
      <div class="col">
        <div class="xvalue lb">{{ dischargeForm.finalClinicalSummary }}</div>
      </div>
    </div>
    <div class="line-separation"></div>
    <div class="row">
      <div class="col-3">
        <span class="xlabel">Discharge Orders:</span>
      </div>
      <div class="col">
        <div class="xvalue lb">{{ dischargeForm.dischargeOrders }}</div>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <span class="xlabel">Follow-Up Appointment:</span>
      </div>
      <div class="col-3">
        <span class="xvalue">{{ dischargeForm.followUpApptDate | ddMonthYYYY }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <span class="xlabel">Medical Certificate:</span>
      </div>
      <div class="col-3">
        <span class="xvalue">{{ dischargeForm.medicalCertificate ? "Yes": "No" }}</span>
      </div>
    </div>
    <div class="row" v-if="dischargeForm.medicalCertificate">
      <div class="col-3">
        <span class="xlabel">List</span>
      </div>
      <div class="col-3">
        <p v-for="letter in dischargeForm.letters" :key="letter.id">
          {{ letter.name }}
        </p>
      </div>
    </div>
    <div class="line-separation bold"></div>
    <div class="row mb-4">
      <div class="col">
        <span class="xlabel">Prepared by:</span>
        <span class="xvalue">{{ dischargeForm.finalisedUser.name }} on {{ dischargeForm.finalisedTime | ddMonthYYYYHHmmFromX }}</span>
      </div>
    </div>
    <div class="line-separation bold"></div>
    <!-- letters list -->
    <template v-if="dischargeForm.medicalCertificate">
      <div v-for="(letter, idx) in dischargeForm.letters" :key="`letter_${idx}`">
        <div class="page-break-before" :key="idx"></div>
        <div class="letter-container">
          <div class="clinicLogo">
            <img :src="generalConfig.logoURL" :alt="generalConfig.clinicName"
              :style="{ marginLeft:`${logoMarginLeft}px`, width:`${logoWidth}px` }"
            >
          </div>
          <h5>{{ letter.name }}</h5>
          <div class="letter-content">{{ letter.text }}</div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import dateFormatter from '@/components/mixins/dateformatter';

export default {
  name: 'PrintDischargeSummary',
  mixins: [dateFormatter],
  data() {
    return {
      dischargeForm: {},
      discharge: {},
      admission: {},
    };
  },
  methods: {
    getRequestDate(value) {
      return moment(value, 'X').format('DD MMM YYYY');
    },
    getAdminQty(item) {
      if (item.administeredTime) {
        // controlled med
        return (+item.administeredQty) || 0;
      }
      // refer to administrationLog component for data structure
      let qtyGiven = 0;
      if (item.administrationLogs) {
        qtyGiven = Object.keys(item.administrationLogs)
          .map((time) => {
            if (item.administrationLogs[time]) {
              return Object.keys(item.administrationLogs[time])
                .reduce((a, date) => a + (+item.administrationLogs[time][date].dosage), 0);
            }
            return 0;
          })
          .reduce((a, b) => a + b, 0);
      }
      return +qtyGiven;
    },
  },
  computed: {
    ...mapGetters('patient', ['selectedPatient']),
    ...mapGetters('discharge', ['dischargeForPrint']),
    ...mapGetters('config', {
      generalConfig: 'general',
      invoiceConfig: 'invoice',
      patientConfig: 'patient',
      featureFlags: 'featureFlags',
      virtualCurrencyConfig: 'virtualCurrency',
    }),
    dob() {
      return moment(this.selectedPatient.dateOfBirth, 'YYYYMMDD').format('DD MMM YYYY');
    },
    isDataReady() {
      return Object.keys(this.dischargeForm).length > 0;
    },
    logoMarginLeft() {
      if (localStorage.getItem('printSetup')) {
        return JSON.parse(localStorage.getItem('printSetup')).logoMarginLeft;
      }
      return 0;
    },
    logoWidth() {
      if (localStorage.getItem('printSetup')) {
        return JSON.parse(localStorage.getItem('printSetup')).logoWidth;
      }
      return 300;
    },
  },
  mounted() {
    const { dischargeForm, admission, inpatientMedecines } = this.dischargeForPrint;
    this.dischargeForm = dischargeForm;
    this.admission = admission;
    this.discharge = this.dischargeForPrint;
    this.inpatientMedecines = inpatientMedecines.filter(item => this.getAdminQty(item) > 0);
    if (!this.preview) {
      setTimeout(() => {
        window.print();
      }, 3000);
    }
  },
};
</script>
<style lang="scss" scoped>
@page {
  size: A4;
  margin: 5mm;
}
.discharge-summary {
  padding: 2rem;
  .row:not(:first-child) {
    margin-top: 1rem;
  }
  .page-break-before {
    page-break-before: always;
  }
  .line-separation {
    border-bottom: 1px solid black;
    margin-top: .5rem;
    margin-bottom: .5rem;
    &.bold {
      border-bottom: 2px solid black;
    }
  }
  .lb {
    white-space: pre-wrap;
    display: block;
    padding-left: 0 !important;
  }
  .xlabel {
    text-decoration: underline;
    &.nd {
      text-decoration: none;
    }
  }
  .xlabel + .xvalue {
    padding-left: 1rem;
  }
  .letter-container {
    padding: 4rem 2rem;
    h5 {
      margin-bottom: 2rem;
      font-size: 1.25rem;
    }
    .letter-content {
      white-space: pre-wrap;
    }
  }
}
</style>
