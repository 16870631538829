import moment from 'moment';

// eslint-disable-next-line func-names
moment.fn.toBuddhistYear = function (format = 'LLLL') {
  const christianYear = this.format('YYYY');
  const buddhishYear = (parseInt(christianYear, 10) + 543).toString();
  return this
    .format(format
      // Replace to show thai short month
      .replace(/MMM|MM|M/, 'MMM')
      // As we force thai month to use short string
      // So we delete '/' from that format as well
      .replace(/\//g, ' ')
      .replace('YYYY', buddhishYear)
      .replace('YY', buddhishYear.substring(2, 4)))
    .replace(christianYear, buddhishYear);
};

export default moment;
