import moment from 'moment';

import announcementService from '@/services/announcement.service';

import {
  SET_ALL_ANNOUNCEMENT,
  SET_ANNOUNCEMENT,
  SET_ALL_TAG,
  SET_ALL_TEMPLATE,
} from './type.mutation';

const state = {
  all: [],
  selectedAnnouncement: null,
  allTags: [],
  allTemplates: [],
};

/* eslint-disable no-param-reassign */
const mutations = {
  [SET_ALL_ANNOUNCEMENT](_state, payload) {
    _state.all = payload;
  },
  [SET_ANNOUNCEMENT](_state, payload) {
    _state.selectedAnnouncement = payload ? { ...payload } : null;
  },
  [SET_ALL_TAG](_state, payload) {
    _state.allTags = payload;
  },
  [SET_ALL_TEMPLATE](_state, payload) {
    _state.allTemplates = payload;
  },
};

const actions = {
  listAnnouncements({ commit }, param = {}) {
    return announcementService.list(param)
      .then((response) => {
        commit(SET_ALL_ANNOUNCEMENT, response.data);
      });
  },
  resetAll({ commit }) {
    commit(SET_ALL_ANNOUNCEMENT, []);
    commit(SET_ANNOUNCEMENT, null);
  },
  setSelectedAnnouncement({ commit }, payload) {
    commit(SET_ANNOUNCEMENT, payload);
  },
  unsetSelectedRoom({ commit }) {
    commit(SET_ANNOUNCEMENT, null);
  },
  createNewAnnouncement({ commit }, payload) {
    commit('setLoading', true, { root: true });
    return announcementService.create(payload)
      .then((response) => {
        if (response.success) {
          commit(SET_ANNOUNCEMENT, response.data);
        }
        commit('setLoading', false, { root: true });
        return response;
      });
  },
  updateAnnouncement({ commit }, payload) {
    commit('setLoading', true, { root: true });
    return announcementService.update(payload._id, payload)
      .then((response) => {
        if (response.success) {
          commit(SET_ANNOUNCEMENT, response.data);
        }
        commit('setLoading', false, { root: true });
        return response;
      });
  },
  destroyAnnouncement({ commit }, payload) {
    commit('setLoading', true, { root: true });
    return announcementService.destroy(payload._id)
      .then((response) => {
        if (response.success) {
          commit(SET_ANNOUNCEMENT, null);
        }
        commit('setLoading', false, { root: true });
        return response;
      });
  },
  listAllTags({ commit }, param = {}) {
    return announcementService.listTags(param)
      .then((response) => {
        commit(SET_ALL_TAG, response.data);
      });
  },
  createNewTag({ commit }, payload) {
    commit('setLoading', true, { root: true });
    return announcementService.createTag(payload)
      .then((response) => {
        commit('setLoading', false, { root: true });
        return response;
      });
  },
  listAllTemplates({ commit }, param = {}) {
    return announcementService.listTemplates(param)
      .then((response) => {
        commit(SET_ALL_TEMPLATE, response.data);
      });
  },
  createNewMassTemplate({ commit }, payload) {
    commit('setLoading', true, { root: true });
    return announcementService.createTemplate(payload)
      .then((response) => {
        commit('setLoading', false, { root: true });
        return response;
      });
  },
  getTagPatientList({ commit }, payload) {
    commit('setLoading', true, { root: true });
    const params = {
      from: payload.tagObject.register_date_from,
      to: moment(payload.tagObject.register_date_to, 'X').add(1, 'days').unix(),
      filter_tag_id: payload.tagObject._id
    };
    if (payload.tagObject.birth_date_from) {
      params.birth_date_from = moment(payload.tagObject.birth_date_from).format('YYYYMMDD');
    }
    if (payload.tagObject.birth_date_to) {
      params.birth_date_to = moment(payload.tagObject.birth_date_to).format('YYYYMMDD');
    }
    if (payload.tagObject.gender && payload.tagObject.gender !== 'N') {
      params.gender = payload.tagObject.gender;
    }
    if (payload.tagObject.conditions && payload.tagObject.conditions.length > 0 && payload.tagObject.conditions[0] !== '') {
      params.icd10 = `${payload.tagObject.conditions.join(',')},`;
    }
    if (payload.tagObject.insurances && payload.tagObject.insurances.length > 0 && payload.tagObject.insurances[0] !== '') {
      params.insurance = payload.tagObject.insurances.map((insurance) => `${insurance.company_id}`).join(',') + ",";
    }
    return announcementService.listTagPatient(params)
      .then((response) => {
        commit('setLoading', false, { root: true });
        return response;
      });
  },
  sendMassMessage({ commit }, payload) {
    commit('setLoading', true, { root: true });
    announcementService.sendMassMessage(payload)
      .then((response) => {
        commit('setLoading', false, { root: true });
        return response;
      });
  },
};

const getters = {
  all: _state => _state.all,
  filtered: _state => ({
    start, end, status, search,
  }) => (_state.all
    .filter(item => (start ? moment(item.start).startOf('day').valueOf() <= start : true))
    .filter(item => (
      end && item.end
        ? moment(item.end).endOf('day').valueOf() >= end
        : true))
    .filter(item => (status ? item.status === status : true))
    .filter((item) => {
      const searchRegExp = new RegExp(search, 'i');
      return search
        ? item.code.match(searchRegExp)
        || item.title.match(searchRegExp)
        : true;
    })
  ),
  selectedAnnouncement: _state => _state.selectedAnnouncement,
  allTags: _state => _state.allTags,
  allTemplates: _state => _state.allTemplates,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
