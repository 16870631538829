var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticStyle: { "enable-background": "new 0 0 508 508" },
      attrs: {
        version: "1.1",
        id: "Capa_1",
        xmlns: "http://www.w3.org/2000/svg",
        x: "0px",
        y: "0px",
        height: _vm.size,
        width: _vm.size,
        viewBox: "0 0 508 508",
        "xml:space": "preserve"
      }
    },
    [
      _c("g", [
        _c("path", {
          staticStyle: {
            opacity: "0.4",
            fill: "#E92B5F",
            "enable-background": "new"
          },
          attrs: {
            d:
              "M363.249,348.592c-11.358-14.152-32.018-16.437-46.187-5.095\n\tl-28.764,23.011V254.082c0-18.162-14.711-32.873-32.873-32.873s-32.873,14.711-32.873,32.873v112.426l-28.764-23.011\n\tc-14.514-10.897-35.125-7.972-46.022,6.542c-10.421,13.872-8.268,33.465,4.931,44.74l82.183,65.746\n\tc12.015,9.615,29.076,9.615,41.091,0l82.183-65.746C372.305,383.438,374.59,362.761,363.249,348.592z"
          }
        }),
        _c("path", {
          staticStyle: { fill: "#E92B5F" },
          attrs: {
            d:
              "M68.212,77.388C33.038,90.702,0.986,113.385,0,145.6c0.378,17.801,1.693,35.585,3.945,53.255\n\tc1.972,17.226,17.538,29.586,34.763,27.613c0.411-0.049,0.822-0.099,1.233-0.164l98.62-15.615\n\tc12.393-1.989,22.42-11.16,25.477-23.34l12.821-50.953l0,0c49.129-9.435,99.556-10.092,148.916-1.972l9.204,1.479l12.985,51.282\n\tc3.057,12.18,13.084,21.351,25.477,23.34l98.62,15.615c17.127,2.663,33.169-9.089,35.832-26.216\n\tc0.066-0.411,0.115-0.822,0.164-1.233c2.252-17.669,3.567-35.454,3.945-53.255c-0.986-32.873-32.873-53.912-68.212-68.212\n\tC334.814,32.188,181.131,34.324,68.212,77.388z"
          }
        })
      ]),
      _c("g"),
      _c("g"),
      _c("g"),
      _c("g"),
      _c("g"),
      _c("g"),
      _c("g"),
      _c("g"),
      _c("g"),
      _c("g"),
      _c("g"),
      _c("g"),
      _c("g"),
      _c("g"),
      _c("g")
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }