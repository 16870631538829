import { cloneDeep } from 'lodash';

import fetChartService from '@/services/fet-chart.service';
import {
  SET_ACTIVE_FET_CHART,
  SET_FET_LIST,
  UPDATE_FET_LIST,
} from './type.mutation';

const state = {
  activeFetChart: {},
  fetList: [],
};

const mutations = {
  [SET_ACTIVE_FET_CHART](_state, payload) {
    // eslint-disable-next-line no-param-reassign
    _state.activeFetChart = payload;
  },
  [SET_FET_LIST](_state, payload) {
    // eslint-disable-next-line no-param-reassign
    _state.fetList = payload;
  },
  [UPDATE_FET_LIST](_state, payload) {
    // eslint-disable-next-line no-param-reassign
    _state.fetList = _state.fetList.map((s) => {
      if (s._id !== payload._id) {
        return s;
      }
      return payload;
    });
  },
};

const actions = {
  fetchFetChartList({ commit }, params = {}) {
    return fetChartService.list(params)
      .then((resp) => {
        commit(SET_FET_LIST, resp.data);
        commit(SET_ACTIVE_FET_CHART, resp.data[0] || {});
        return resp.data;
      });
  },
  setActiveFetChart({ commit }, payload) {
    commit(SET_ACTIVE_FET_CHART, payload);
  },
  createFetChart({ commit }, data) {
    return fetChartService.create(data)
      .then((resp) => {
        const chart = resp.data;
        commit(SET_ACTIVE_FET_CHART, chart);
        // commit(ADD_TO_FET_LIST, chart);
        return chart;
      });
  },
  updateNotes({ commit }, data) {
    return fetChartService.updateNotes(data)
      .then((resp) => {
        const chart = resp.data;
        commit(SET_ACTIVE_FET_CHART, chart);
        commit(UPDATE_FET_LIST, chart);
        return chart;
      });
  },
  updateTreatmentAndProceduresNote({ commit }, data) {
    return fetChartService.updateTreatmentAndProceduresNote(data)
      .then((resp) => {
        const chart = resp.data;
        commit(SET_ACTIVE_FET_CHART, chart);
        commit(UPDATE_FET_LIST, chart);
        return chart;
      });
  },
  updateBetaHCGDate({ commit }, data) {
    return fetChartService.updateBetaHCGDate(data)
      .then((resp) => {
        const chart = resp.data;
        commit(SET_ACTIVE_FET_CHART, chart);
        commit(UPDATE_FET_LIST, chart);
        return chart;
      });
  },
  createVisitItem({ commit }, data) {
    return fetChartService.createVisitItem(data)
      .then((resp) => {
        const chart = resp.data;
        commit(SET_ACTIVE_FET_CHART, chart);
        commit(UPDATE_FET_LIST, chart);
        return chart;
      });
  },
  updateVisitItem({ commit }, data) {
    return fetChartService.updateVisitItem(data)
      .then((resp) => {
        const chart = resp.data;
        commit(SET_ACTIVE_FET_CHART, chart);
        commit(UPDATE_FET_LIST, chart);
        return chart;
      });
  },
  patchFetChart({ commit }, payload) {
    const data = cloneDeep(payload);
    const { id } = data;
    delete data.id;
    delete data.createdAt;
    return fetChartService.patch(id, data)
      .then((resp) => {
        const chart = resp.data;
        commit(SET_ACTIVE_FET_CHART, chart);
        commit(UPDATE_FET_LIST, chart);
        return chart;
      });
  },
};

const getters = {
  activeFetChart: _state => _state.activeFetChart,
  fetList: _state => _state.fetList,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
